import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAlerts from "../utility/alerts";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { OwnerHeader } from "../pages/OwnerHeader";
export const SideNavBar = ({ onChildMessage,active, child,user_type = "root" }) => {
  ////////////// HOOKS //////////////

  const navigate = useNavigate();
  const customnav = useNavigate();

  const { pathname } = useLocation();
  const Alert = useAlerts();

  const { alertList } = useSelector((state) => state.alerts);

  ////////////// CONSTANTS //////////////

  const [collapsed, setCollapsed] = useState(true);
  
  // console.log("COLLAPSENAME",collName)
  const [selectedOption, setSelectedOption] = useState("");

  /////////////// METHODS ///////////////

  const handleCollapse = () => {
    onChildMessage(!collapsed);
    setCollapsed(!collapsed);
  };
  const handleSelectedOption = (selected) => {
    if (selectedOption === selected) {
      setSelectedOption("");
    } else {
      setSelectedOption(selected);
    }
  };

  const SIDEBAR_NAV_ITEMS = {
    root: [
      {
        name: "Dashboard",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/",
      },
      {
        name: "Finance",
        icon_path: `/assets/svgs/finance${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Ledger",
            icon_path: `/assets/svgs/finance-ledger${collapsed ? "" : ""}.svg`,
            route: "/finance-ledger",
          },
          {
            name: "Invoices",
            icon_path: `/assets/svgs/finance-invoice-other${collapsed ? "" : ""}.svg`,
            route: "/finance-invoice",
          },
          {
            name: "Payment History",
            icon_path: `/assets/svgs/finance-payment-history${collapsed ? "" : ""}.svg`,
            route: "/finance-payment-history",
          },
          {
            name: "Deposit",
            icon_path: `/assets/svgs/finance-bank-deposit${collapsed ? "" : ""}.svg`,
            route: "/finance-deposit",
          },
          {
            name: "Purchase Orders",
            icon_path: `/assets/svgs/finance-purchase-order${collapsed ? "" : ""}.svg`,
            route: "/finance-purchase-order",
          },
          {
            name: "Budget",
            icon_path: `/assets/svgs/finance-budget${collapsed ? "" : ""}.svg`,
            route: "/finance-budget",
          },
          {
            name: "Accounts",
            icon_path: `/assets/svgs/finance-bank-account${collapsed ? "" : ""}.svg`,
            route: "/finance-account",
          },
        ],
        route: "#",
      },
      {
        name: "Issue Tracker",
        icon_path: `/assets/svgs/support-issues${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/issue-tracker",
      },
      {
        name: "Tenants",
        icon_path: `/assets/svgs/users-tenant${
          collapsed ? "" : ""
        }.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/tenant",
      },
      {
        name: "Vendors",
        icon_path: `/assets/svgs/users-vendor${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/vendor",
      },
      {
        name: "Units",
        icon_path: `/assets/svgs/unit${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/unit",
      },
      {
        name: "Properties",
        icon_path: `/assets/svgs/property${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/property",
      },
      {
        name: "Leases",
        icon_path: `/assets/svgs/lease${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Leases",
            icon_path: `/assets/svgs/lease${collapsed ? "" : ""}.png`,
            route: "/lease",
          },
          {
            name: "Moving",
            icon_path: `/assets/svgs/tenant-moving${collapsed ? "" : ""}.svg`,
            route: "/lease-moving",
          },
          {
            name: "Evictions",
            icon_path: `/assets/svgs/lease-eviction${collapsed ? "" : ""}.svg`,
            route: "/lease-eviction",
          },
        ],
        route: "#",
      },
      {
        name: "Applications",
        icon_path: `/assets/svgs/application${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/application",
      },
      {
        name: "Assets",
        icon_path: `/assets/svgs/assets${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/assets/materials",
      },
      {
        name: "Customer",
        icon_path: `/assets/svgs/users-customer${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/customer",
      },
      {
        name: "Changelog",
        icon_path: `/assets/svgs/admin-changelog${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/changelog",
      },
      {
        name: "Staff",
        icon_path: `/assets/svgs/users-staff${collapsed ? "" : ""}.svg`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Staff",
            icon_path: `/assets/svgs/users-staff${collapsed ? "" : ""}.svg`,
            route: "/staff",
          },
          {
            name: "Staff Role",
            icon_path: `/assets/svgs/staff-roles${collapsed ? "" : ""}.svg`,
            route: "/staff-role",
          },
          {
            name: "Staff Department",
            icon_path: `/assets/svgs/staff-department${collapsed ? "" : ""}.svg`,
            route: "/staff-department",
          },

        ],
        route: "#",
      },
      {
        name: "Directories",
        icon_path: `/assets/svgs/system-directory${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/directory-listing",
      },
      {
        name: "Forum",
        icon_path: `/assets/svgs/system-form${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/forum-post",
      },
      
     
      {
        name: "Communication",
        icon_path: `/assets/svgs/system-communication${
          collapsed ? "" : ""
        }.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/communication",
      },
      {
        name: "Files",
        icon_path: `/assets/svgs/system-files${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/under-development",
      },
      {
        name: "Reports",
        icon_path: `/assets/svgs/reports${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/under-development",
      },
      {
        name: "Knowledgebase",
        icon_path: `/assets/svgs/knowledgebase${
          collapsed ? "" : ""
        }.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/under-development",
      },
      {
        name: "Site Settings",
        icon_path: `/assets/svgs/system-settings${collapsed ? "" : ""}.svg`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Folder Template",
            icon_path: `/assets/svgs/system-template${collapsed ? "" : ""}.svg`,
            route: "/tree-view",
          },
          {
            name: "Add Folder Template",
            icon_path: `/assets/svgs/system-template${collapsed ? "" : ""}.svg`,
            route: "/add-template",
          },
          {
            name: "Editable Lists",
            icon_path: `/assets/svgs/system-lists${collapsed ? "" : ""}.png`,
            route: "/editableList",
          },
        ],
        route: "#",

      },
      // {
      //   name: "Capital Improvements",
      //   icon_path: `/assets/svgs/capital-improvements${
      //     collapsed ? "-l" : ""
      //   }.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/#7",
      // },
      
      // {
      //   name: "Front Desk",
      //   icon_path: `/assets/svgs/users-front-desk${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/#9",
      // },
      // {
      //   name: "Security",
      //   icon_path: `/assets/svgs/security${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/#10",
      // },
      // {
      //   name: "Staff",
      //   icon_path: `/assets/svgs/system-files-staff${
      //     collapsed ? "-l" : ""
      //   }.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/#11",
      // },
      // {
      //   name: "General Application Settings",
      //   icon_path: `/assets/svgs/unit${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/general-applications",
      // },
      
      
      
      // {
      //   name: "Community Forum & User Guides",
      //   icon_path: `/assets/svgs/system-forum-guides${
      //     collapsed ? "-l" : ""
      //   }.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/community",
      // },
    ],
    staff: [
      {
        name: "Dashboard",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/",
      },
      {
        name: "Staff Profile",
        icon_path: `/assets/svgs/staff-information${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Issue Tracker",
        icon_path: `/assets/svgs/support-issues${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Units",
        icon_path: `/assets/svgs/unit${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Tenants",
        icon_path: `/assets/svgs/users-tenant${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/tenant",
      },
      {
        name: "Leases",
        icon_path: `/assets/svgs/lease${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        route: "#",
        dropdown: [
          {
            name: "Leases",
            icon_path: `/assets/svgs/lease-view${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Moving",
            icon_path: `/assets/svgs/tenant-moving${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Evictions",
            icon_path: `/assets/svgs/lease-eviction${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
      },
      {
        name: "Applications",
        icon_path: `/assets/svgs/application${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Front Desk",
        icon_path: `/assets/svgs/users-front-desk${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Package Delivery",
            icon_path: `/assets/svgs/front-desk-delivery${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Call Log",
            icon_path: `/assets/svgs/front-desk-call-log${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Guest Log",
            icon_path: `/assets/svgs/front-desk-guest-log${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Security",
        icon_path: `/assets/svgs/security${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Security Log",
            icon_path: `/assets/svgs/security-log${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Package Delivery",
            icon_path: `/assets/svgs/front-desk-delivery${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Call Log",
            icon_path: `/assets/svgs/front-desk-call-log${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Guest Log",
            icon_path: `/assets/svgs/front-desk-guest-log${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Vendors",
        icon_path: `/assets/svgs/users-vendor${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Assets",
        icon_path: `/assets/svgs/assets${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Keys",
            icon_path: `/assets/svgs/assets-keys${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Loanable Items",
            icon_path: `/assets/svgs/assets-loaned-items${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Communication",
        icon_path: `/assets/svgs/system-communication${
          collapsed ? "-l" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Files",
        icon_path: `/assets/svgs/system-files${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Reports",
        icon_path: `/assets/svgs/reports${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Community & Support",
        icon_path: `/assets/svgs/system-forum-guides${
          collapsed ? "-l" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
    ],
    super_admin: [
      {
        name: "Dashboard",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin",
      },
      {
        name: "Plans",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/plans",
      },
      {
        name: "Finance",
        icon_path: `/assets/svgs/finance${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,

        dropdown: [
          {
            name: "Ledger",
            icon_path: `/assets/svgs/finance-ledger${collapsed ? "" : ""}.png`,
            route: "/admin/finance-ledger",
          },
          {
            name: "Invoices",
            icon_path: `/assets/svgs/finance-invoice-other${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/finance-invoice-other",
          },
          {
            name: "Payment History",
            icon_path: `/assets/svgs/finance-payment-history${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/finance-payment-history",
          },
          {
            name: "Purchase Orders",
            icon_path: `/assets/svgs/finance-purchase-order${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/finance-purchase-order",
          },
          {
            name: "Budget",
            icon_path: `/assets/svgs/finance-budget${collapsed ? "" : ""}.png`,
            route: "/admin/finance-budget",
          },
          {
            name: "Accounts",
            icon_path: `/assets/svgs/finance-account-credit${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/finance-accounts",
          },
        ],
        route: "#",
      },
      {
        name: "Leads",
        icon_path: `/assets/svgs/users-lead${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/users-lead",
      },
      {
        name: "Support",
        icon_path: `/assets/svgs/support-issues${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Support Tickets",
            icon_path: `/assets/svgs/support-ticket${collapsed ? "" : ""}.png`,
            route: "/admin/support-ticket",
          },
          {
            name: "Let's Connect",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.letsconnect.at/pages/login",
          },
          {
            name: "GetScree.me",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "/admin/support-getscreenme",
          },
        ],
        route: "#",
      },
      {
        name: "Marketing",
        icon_path: `/assets/svgs/admin-marketing${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "SMS-it",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://decontrolpanel.smsit.ai/users/login",
          },
          {
            name: "Reach Out",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.reachout.ai/login",
          },
          {
            name: "WiserNotify",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.reachout.ai/login",
          },
          {
            name: "Mailtimer.io",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.mailtimer.io/login",
          },
          {
            name: "Nicereply",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://admin.nicereply.com/admin/login",
          },
          {
            name: "Review Management",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://reviews.saviodesigns.com/",
          },
          {
            name: "LinkChest | SEO Buddy",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://seobuddy.com/login",
          },
          {
            name: "Content Writers",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            dropdown_included: true,
            downIndicotor: <IoIosArrowDown />,
            upIndicotor: <IoIosArrowUp />,
            dropdown: [
              {
                name: "Lead Scripts",
                icon_path: `/assets/svgs/finance-ledger${
                  collapsed ? "-l" : ""
                }.svg`,
                route: "https://app.leadscripts.co/account/login",
              },
              {
                name: "Word Hero",
                icon_path: `/assets/svgs/finance-ledger${
                  collapsed ? "-l" : ""
                }.svg`,
                route: "https://app.wordhero.co/login",
              },
              {
                name: "HelloScribe",
                icon_path: `/assets/svgs/finance-ledger${
                  collapsed ? "-l" : ""
                }.svg`,
                route: "https://app.wordhero.co/login",
              },
            ],
            route: "/admin/admin-marketing-content-writers",
          },
        ],
        route: "#",
      },
      // {
      //   name: "NameEdit",
      //   icon_path: `/assets/svgs/users-admin${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "/admin/edit-names",
      // },
      {
        name: "Site Security & Monitoring",
        icon_path: `/assets/svgs/admin-site-security${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "Password Settings",
            icon_path: `/assets/svgs/system-lock${collapsed ? "" : ""}.png`,
            route: "/admin/system-lock",
          },
          {
            name: "Droptrim",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://www.droptrim.com/login",
          },
          {
            name: "Screpy",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.screpy.com/login",
          },
          {
            name: "CloudFilt",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://app.cloudfilt.com/login",
          },
          {
            name: "Beagle Security",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://beaglesecurity.com/login",
          },
          {
            name: "Havoc Shield",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://platform.havocshield.com/login",
          },
          {
            name: "InsecureWeb",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "https://darkwebmonitor.saviodesigns.com/",
          },
        ],
        route: "#",
      },
      {
        name: "Website Settings",
        icon_path: `/assets/svgs/users-admin${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/website-settings",
      },
      {
        name: "Show Settings",
        icon_path: `/assets/svgs/users-admin${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/show-settings",
      },

      {
        name: "Permissions",
        icon_path: `/assets/svgs/users-admin${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/permissions",
      },

      {
        name: "Staff",
        icon_path: `/assets/svgs/users-admin-staff${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/users-staff-admin",
      },
      {
        name: "Vendors",
        icon_path: `/assets/svgs/users-vendor-admin${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/users-vendors-admin",
      },
      {
        name: "Customer Users",
        icon_path: `/assets/svgs/tenant-information${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        route: "#",
        dropdown: [
          {
            name: "Owners",
            icon_path: `/assets/svgs/users-owner${collapsed ? "" : ""}.png`,
            route: "/admin/users-owner",
          },
          {
            name: "Renters",
            icon_path: `/assets/svgs/users-tenant${collapsed ? "" : ""}.png`,
            route: "/admin/users-tenant",
          },
          {
            name: "Staff",
            icon_path: `/assets/svgs/users-staff${collapsed ? "" : ""}.png`,
            route: "/admin/users-staff",
          },
          {
            name: "Vendors",
            icon_path: `/assets/svgs/users-vendors${collapsed ? "" : ""}.png`,
            route: "/admin/users-vendors",
          },
        ],
      },
      {
        name: "Forum",
        icon_path: `/assets/svgs/forum${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-forum",
      },
      {
        name: "Communication",
        icon_path: `/assets/svgs/system-communication${
          collapsed ? "" : ""
        }.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-message",
      },
      {
        name: "Files",
        icon_path: `/assets/svgs/system-files${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-files",
      },
      {
        name: "Reports",
        icon_path: `/assets/svgs/reports${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/reports",
      },
      {
        name: "Community & Support",
        icon_path: `/assets/svgs/system-forum-guides${
          collapsed ? "-l" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-forum-guides",
      },
      {
        name: "Site Design & Content",
        icon_path: `/assets/svgs/admin-cms${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        dropdown: [
          {
            name: "CMS",
            icon_path: `/assets/svgs/admin-cms${collapsed ? "" : ""}.png`,
            route: "/admin/admin-cms",
          },
          {
            name: "Plans",
            icon_path: `/assets/svgs/plans${collapsed ? "" : ""}.png`,
            route: "/admin/plans",
          },
          {
            name: "Feature Request & Roadmap",
            icon_path: `/assets/svgs/system-roadmap${collapsed ? "" : ""}.png`,
            route: "/admin/admin-roadmap",
          },
          {
            name: "Changelog",
            icon_path: `/assets/svgs/admin-changelog${collapsed ? "" : ""}.png`,
            route: "/admin/admin-changelog",
          },
          {
            name: "Footer",
            icon_path: `/assets/svgs/admin-footer${collapsed ? "" : ""}.png`,
            route: "/admin/admin-footer",
          },
          {
            name: "Storage & Usage",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "" : ""}.png`,
            route: "/admin/plans-storage",
          },
          {
            name: "WiserNotify",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "/admin/admin-wisernotify",
          },
          {
            name: "Lubenda",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "https://www.iubenda.com/en/login",
          },
          {
            name: "Usetiful (Site Tour)",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "https://www.usetiful.com/login",
          },
        ],
        route: "#",
      },
      {
        name: "Integration Partners",
        icon_path: `/assets/svgs/system-integrations${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "TransUnion Smart Move",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "https://teliportme.com/login",
          },
          {
            name: "Teliportme",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "https://teliportme.com/login",
          },
          {
            name: "Quickbooks",
            icon_path: `/assets/svgs/plans-storage${collapsed ? "-l" : ""}.svg`,
            route: "https://teliportme.com/login",
          },
        ],
        route: "#",
      },
      {
        name: "Backup",
        icon_path: `/assets/svgs/system-backup${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-backup",
      },

      {
        name: "Knowledge Base",
        icon_path: `/assets/svgs/knowledgebase${collapsed ? "" : ""}.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/system-knowledgebase",
      },
      {
        name: "Testing",
        icon_path: `/assets/svgs/system-backup${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/admin-testing",
      },
      {
        name: "Activity",
        icon_path: `/assets/svgs/system-backup${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/admin/admin-activity",
      },
      // {
      //   name: "Site Settings",
      //   icon_path: `/assets/svgs/system-settings${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: false,
      //   dropdown: [],
      //   route: "#",
      // },
      {
        name: "Site Settings",
        icon_path: `/assets/svgs/system-settings${collapsed ? "" : ""}.png`,
        dropdown_included: true,
        downIndicotor: <IoIosArrowDown />,
        upIndicotor: <IoIosArrowUp />,
        dropdown: [
          {
            name: "User Roles",
            icon_path: `/assets/svgs/users-user-roles${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/users-user-roles",
          },
          {
            name: "Editable Lists",
            icon_path: `/assets/svgs/system-lists${collapsed ? "" : ""}.png`,
            route: "/admin/system-lists",
          },
          {
            name: "Email Templates",
            icon_path: `/assets/svgs/system-template${collapsed ? "" : ""}.png`,
            route: "/admin/system-template",
          },
          {
            name: "Tax & Fee Settings",
            icon_path: `/assets/svgs/finance-tax${collapsed ? "" : ""}.png`,
            route: "/admin/finance-tax",
          },
          {
            name: "Payment Gateways",
            icon_path: `/assets/svgs/finance-payment-gateways${
              collapsed ? "" : ""
            }.png`,
            route: "/admin/finance-payment-gateways",
          },
          {
            name: "Languages",
            icon_path: `/assets/svgs/system-language${collapsed ? "" : ""}.png`,
            route: "/admin/system-language",
          },
        ],
        route: "#",
      },
    ],
    tenant: [
      {
        name: "Dashboard",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/tenantDashboard",
      },
      {
        name: "Tenant Profile",
        icon_path: `/assets/svgs/tenant-information${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/tenant-profile",
      },
      {
        name: "Rental Information",
        icon_path: `/assets/svgs/property-information${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Finance",
        icon_path: `/assets/svgs/finance${collapsed ? "" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Ledger",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Invoices",
            icon_path: `/assets/svgs/finance-invoice-other${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Payment History",
            icon_path: `/assets/svgs/finance-payment-history${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Payment Accounts",
            icon_path: `/assets/svgs/finance-payment-gateways${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Issue Tracker",
        icon_path: `/assets/svgs/support-issues${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Leases",
        icon_path: `/assets/svgs/lease${collapsed ? "" : ""}.svg`,
        dropdown_included: true,
        route: "#",
        dropdown: [
          {
            name: "Leases",
            icon_path: `/assets/svgs/lease-view${collapsed ? "" : ""}.svg`,
            route: "#",
          },
          {
            name: "Moving",
            icon_path: `/assets/svgs/tenant-moving${collapsed ? "" : ""}.svg`,
            route: "#",
          },
          {
            name: "Evictions",
            icon_path: `/assets/svgs/lease-eviction${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
        ],
      },
      {
        name: "Loaned Items",
        icon_path: `/assets/svgs/assets-loanable-items${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Keys",
            icon_path: `/assets/svgs/assets-keys${collapsed ? "" : ""}.svg`,
            route: "#",
          },
          {
            name: "Items",
            icon_path: `/assets/svgs/assets-loaned-items${
              collapsed ? "" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Forum",
        icon_path: `/assets/svgs/system-forum${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Communication",
        icon_path: `/assets/svgs/system-communication${
          collapsed ? "" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Files",
        icon_path: `/assets/svgs/system-files${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Reports",
        icon_path: `/assets/svgs/reports${collapsed ? "" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Knowledgebase",
        icon_path: `/assets/svgs/knowledgebase${
          collapsed ? "" : ""
        }.png`,
        dropdown_included: false,
        dropdown: [],
        route: "/#16",
      },
    ],
    owner: [
      {
        name: "Dashboard",
        icon_path: `/assets/svgs/system-dashboard${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Finance",
        icon_path: `/assets/svgs/finance${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Ledger",
            icon_path: `/assets/svgs/finance-ledger${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Invoices",
            icon_path: `/assets/svgs/finance-invoice-other${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Payment History",
            icon_path: `/assets/svgs/finance-payment-history${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
          {
            name: "Accounts",
            icon_path: `/assets/svgs/finance-bank-account${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Issue Tracker",
        icon_path: `/assets/svgs/support-issues${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/#4",
      },
      {
        name: "Tenants",
        icon_path: `/assets/svgs/users-tenant${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/tenant",
      },
      {
        name: "Vendors",
        icon_path: `/assets/svgs/users-vendor${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/#8",
      },
      {
        name: "Units",
        icon_path: `/assets/svgs/unit${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/#8",
      },
      {
        name: "Properties",
        icon_path: `/assets/svgs/property${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "/#8",
      },
      {
        name: "Leases",
        icon_path: `/assets/svgs/lease${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        route: "#",
        dropdown: [
          {
            name: "Leases",
            icon_path: `/assets/svgs/lease-view${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Moving",
            icon_path: `/assets/svgs/tenant-moving${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Evictions",
            icon_path: `/assets/svgs/lease-eviction${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
      },
      {
        name: "Applications",
        icon_path: `/assets/svgs/application${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Assets",
        icon_path: `/assets/svgs/assets${collapsed ? "-l" : ""}.svg`,
        dropdown_included: true,
        dropdown: [
          {
            name: "Keys",
            icon_path: `/assets/svgs/assets-keys${collapsed ? "-l" : ""}.svg`,
            route: "#",
          },
          {
            name: "Loanable Items",
            icon_path: `/assets/svgs/assets-loaned-items${
              collapsed ? "-l" : ""
            }.svg`,
            route: "#",
          },
        ],
        route: "#",
      },
      {
        name: "Communication",
        icon_path: `/assets/svgs/system-communication${
          collapsed ? "-l" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Files",
        icon_path: `/assets/svgs/system-files${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Reports",
        icon_path: `/assets/svgs/reports${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Community & Support",
        icon_path: `/assets/svgs/system-forum-guides${
          collapsed ? "-l" : ""
        }.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      {
        name: "Site Settings",
        icon_path: `/assets/svgs/system-settings${collapsed ? "-l" : ""}.svg`,
        dropdown_included: false,
        dropdown: [],
        route: "#",
      },
      // {
      //   name: "Site Settings",
      //   icon_path: `/assets/svgs/assets${collapsed ? "-l" : ""}.svg`,
      //   dropdown_included: true,
      //   dropdown: [
      //     {
      //       name: "Keys",
      //       icon_path: `/assets/svgs/assets-keys${collapsed ? "-l" : ""}.svg`,
      //       route: "#",
      //     },
      //     {
      //       name: "Loanable Items",
      //       icon_path: `/assets/svgs/assets-loaned-items${
      //         collapsed ? "-l" : ""
      //       }.svg`,
      //       route: "#",
      //     },
      //   ],
      //   route: "#",
      // },
    ],
  };

  ////////////// STATES //////////////

  ////////////// HANDLERS //////////////

  ////////////// UseEffect Hooks //////////////

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  useEffect(() => {
    if (alertList?.length) {
      alertList.map((alertEl) => {
        Alert.show(alertEl);
        Alert.remove(alertEl);
      });
    }
  }, [alertList]);

  return (
    <>
      <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
        <i className="fas fa-times" />
        <div className="logo-wrap">
          <div className="logo-inner light-logo">
            <img className="logo" src="/assets/img/logo-light.png" alt="" />
            <img
              className="logo-text"
              src="/assets/img/logo-text-light.png"
              alt=""
            />
          </div>
          <div className="logo-inner dark-logo">
            <img className="logo" src="/assets/img/logo-dark.png" alt="" />
            <img
              className="logo-text"
              src="/assets/img/logo-text-dark.png"
              alt=""
            />
          </div>
        </div>
        {/* side bar */}
        <div className="sidebar-menu-wrap">
          <div className="menu-collapse-trigger" onClick={handleCollapse}>
            <span />
            <span />
            <span />
          </div>
          <ul className="navigation pl-0">
            {SIDEBAR_NAV_ITEMS[user_type].map((navItem, i) => (
              <li key={i}>
                <a
                  className={
                    active === navItem.name ||
                    (selectedOption === navItem.name &&
                      navItem.dropdown_included === true)
                      ? "active text-decoration-none "
                      : "text-decoration-none"
                  }
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log(navItem);
                    if (navItem.route.indexOf("https") > -1) {
                      window.location.replace(navItem.route);
                    } else {
                      navigate(navItem.route);
                    }

                    handleSelectedOption(navItem.name);
                  }}
                >
                  <span className="menu-icon">
                    <embed
                      src={navItem.icon_path}
                      alt=""
                      title={navItem.name}
                      className="w-10 img-class"
                    />
                  </span>{" "}
                  <span className="menu-text flex justify-between items-center w-100 pl-3">
                    {collapsed === true ? navItem.name : ""}

                    {selectedOption === navItem.name &&
                    navItem.dropdown_included === true
                    
                      ? navItem.downIndicotor
                      :navItem.upIndicotor}
                  </span>
                </a>
                <ul
                  className={
                    selectedOption === navItem.name &&
                    navItem.dropdown_included === true 
                      ? "pl-0"
                      : "d-none"
                  }
                >
                  {navItem.dropdown.map((subItem) => (
                    <li>
                      <a
                        className={
                          child === subItem.name ?
                          "active text-decoration-none":
                            "text-decoration-none"
                          }
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (subItem.route.indexOf("https") > -1) {
                            window.location.replace(subItem.route);
                          } else {
                            navigate(subItem.route);
                          }
                        }}
                      >
                        <span className="menu-icon">
                          <embed
                            src={subItem.icon_path}
                            alt={subItem.name}
                            title={subItem.name}
                            className="w-10 img-class"
                          />
                        </span>
                         <span className="menu-text">{collapsed === true ? subItem.name:""}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
