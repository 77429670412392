import React, { useState } from "react";
import { ProfileSelect } from "../Dashboard/ProfileSelect";

export const Header1 = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  return (
    <div>
      <section className="header-bar">
        <div className="row align-items-center ">
          <div className="col-xl-5 col-md-12 col-2">
            <div className="property-item">
              <div className="property-img">
                <img src="/assets/admin/img/property.png" alt="" />
              </div>
              {/* <div
                className="property-select p-2 w-100 account-switcher-dropdown"
                onMouseEnter={handePropertyDropdown}
                onMouseLeave={handePropertyDropdown}
              >
                <span className="property-switcher"> {propertyName} </span>
                <div
                  className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                    showPropertyDropdown ? "" : "hidden"
                  }`}
                  id="style-14"
                >
                  <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                    <span>Properties: </span>
                  </div>
                  {propertyOptions.map((v, i) => (
                    <div key={i} className="ml-2">
                      <span
                        onClick={handlePropertyName}
                        className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                      >
                        {v}
                      </span>
                    </div>
                  ))}
                  <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                    <span>Staff: </span>
                  </div>
                  {staffOptions.map((v, i) => (
                    <div key={i} className="ml-2">
                      <span
                        onClick={handlePropertyName}
                        className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                      >
                        {v}
                      </span>
                    </div>
                  ))}
                </div>
              </div> */}
              <p className="font-bold text-2xl">Header 1</p>
            </div>
            <div className="mobile-logo">
              <img
                className="img-fluid logo-light"
                src="/assets/admin/img/logo-light.png"
                alt=""
              />
              <img
                className="img-fluid logo-dark"
                src="/assets/admin/img/logo-dark.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-7 col-md-12 col-10">
            <div className="header-bar-right items-start">
              <ul className="header-icons">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-language-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-activity-stream-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-message-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink2"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-notifications-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink3"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
              </ul>
              <ProfileSelect cookies={cookies} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export const Header2 = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  return (
    <div>
      <section className="header-bar">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-12 col-2">
            <div className="property-item">
              <div className="property-img">
                <img src="/assets/admin/img/property.png" alt="" />
              </div>
              {/* <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div> */}
              <p className="font-bold text-2xl">Header 2</p>
            </div>
            <div className="mobile-logo">
              <img
                className="img-fluid logo-light"
                src="/assets/admin/img/logo-light.png"
                alt=""
              />
              <img
                className="img-fluid logo-dark"
                src="/assets/admin/img/logo-dark.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-7 col-md-12 col-10">
            <div className="header-bar-right items-start">
              <ul className="header-icons">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-language-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-activity-stream-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-message-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink2"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-notifications-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink3"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
              </ul>
              <ProfileSelect cookies={cookies} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export const Header3 = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  return (
    <div>
      <section className="header-bar">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-12 col-2">
            <div className="property-item">
              <div className="property-img">
                <img src="/assets/admin/img/property.png" alt="" />
              </div>
              {/* <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div> */}
              <p className="font-bold text-2xl">Header 3</p>
            </div>
            <div className="mobile-logo">
              <img
                className="img-fluid logo-light"
                src="/assets/admin/img/logo-light.png"
                alt=""
              />
              <img
                className="img-fluid logo-dark"
                src="/assets/admin/img/logo-dark.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-7 col-md-12 col-10">
            <div className="header-bar-right items-start">
              <ul className="header-icons">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-language-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-activity-stream-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-message-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink2"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-notifications-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink3"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
              </ul>
              <ProfileSelect cookies={cookies} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const Header4 = ({ cookies,styling }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  return (
    <div style={styling}>
      <section className="header-bar" >
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-12 col-2">
            <div className="property-item">
              <div className="property-img">
                <img src="/assets/admin/img/property.png" alt="" />
              </div>
              {/* <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div> */}
              <p className="font-bold text-2xl">Header 4</p>
            </div>
            <div className="mobile-logo">
              <img
                className="img-fluid logo-light"
                src="/assets/admin/img/logo-light.png"
                alt=""
              />
              <img
                className="img-fluid logo-dark"
                src="/assets/admin/img/logo-dark.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-7 col-md-12 col-10">
            <div className="header-bar-right items-start ">
              <ul className="header-icons">
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-language-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-activity-stream-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-message-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink2"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/svgs/system-notifications-l.svg"
                      className="p-2"
                      alt=""
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink3"
                  >
                    <span className="triangle-arrow" />
                  </div>
                </li>
              </ul>
              <ProfileSelect cookies={cookies} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
