import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import imgUrl from "../ImageUrl";

export const Accordions = ({data}) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      {data?.map((board,index)=>(
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
        <AccordionSummary
          expandIcon={<i className="fa fa-angle-down"></i>}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
          className="bg-gray-200"
        >
          <div className="flex items-center">
            <img src={imgUrl+"/"+board?.icon} alt={board?.title} className="w-1/12"/>
            <h4 className="font-bold">{board?.title}</h4>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{ __html: board?.board }} />
        </AccordionDetails>
      </Accordion>
      ))}
    </>
  );
};
