import { CKEditor } from "ckeditor4-react";
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Swal from "sweetalert2";
import ApiRequest from "../../utility/http";
import imgUrl from "../ImageUrl";
import { Tooltip } from "../Loader/Tooltip";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Spinner from "../Spinner/Spinner";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";


export const PropertyBoards = ({ data, handleBoardTrue, hiddenData,p_id }) => {
  const initialBoardData = {
    board_type_id: "",
    title: "",
    icon: "",
    board: "",
    link: "",
    link_show_in: "",
    order_number: "",
    files: []
  };
  const picTypes = useAllowedPicMimeTypes();
  const fileTypes = useAllowedMimeTypes();
  const [indexEdit, setIndexEdit] = useState();
  // // console.log('@indexEdit',indexEdit);
  const [bulkAction, setBulkAction] = useState({
    bulk_action: "",
  });
  const [propertyBoard, setPropertyBoard] = useState([]);
  const [propertyBoards, setPropertyBoards] = useState([]);
  const [boardTypes, setBoardTypes] = useState([]);
  const [propertyBoardPicture, setPropertyBoardPicture] = useState([]);
  const [propertyBoardFiles, setPropertyBoardFiles] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loader, setLoader] = useState();
  const [viewForm, setViewForm] = useState(false);

  console.log("@propertyBoard", propertyBoard);
  console.log("@propertyBoards", propertyBoards);
  console.log("@propertyBoardPicture", propertyBoardPicture);

  let fileApi = "/public/api/auth/upload-file";
  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
    ...restProps
  }) => {
    const confirmEdit = (image) => {
      Swal.fire({
        title: "Are you sure you want to edit this image?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // Modify the image object here
          const editedImage = { ...image, title: "New Title" };
          onEditClick(editedImage);
        }
      });
    };

    return image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="property image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                type="button"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
            {/* <button
                  type="button"
                  title="Edit image"
                  className="logo w-5 mx-auto my-1"
                  onClick={() => confirmEdit(image)}
                >
                  <img src="/assets/img/system-edit.png" alt="edit" />
                </button> */}
          </div>
        </div>
      </div>
    ) : (
      <img {...restProps} />
    );
  };
  const fileComponent = ({
    file = null,
    title = null,
    key = 0,
    inputFieldId = "",
    trashAble = false,
    editAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
  }) =>
    file ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
      >
        <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
          <div title={title} key={key} className="block min-w-[600rem] px-2">
            <a href={`${imgUrl}/${file}`} target="_blank">
              {title}
            </a>
          </div>
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
          >
            
            {editAble ? (
              <img
                title="Edit file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/system-edit.png"
                onClick={(e) => {
                  onEditClick();
                }}
                alt=""
              />
            ) : null}
            {trashAble ? (
              <img
                title="Remove file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((input_file) => {
                      if (input_file !== file) {
                        dataTransfer.items.add(input_file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
   
  const notes = () => {
    const data = [];
    data.push(propertyBoard);
    const array = [...data];
    // console.log('@array',array);
    // const cleanText = new DOMParser().parseFromString(editorData, "text/html")
    //   .body.textContent;
    array[0].board = editorData;
    setPropertyBoard(array);
  };

  const bulkData = () => {
    // setPropertyBoard({...propertyBoard,notes:editorData});
    notes();
    const newBoard = { ...propertyBoard, order_number: propertyBoards.length + 1 };
    const newDataArray = [newBoard];
    handleBoardTrue((prevArray) => [...prevArray, ...newDataArray]);
    setPropertyBoards((prevArray) => [...prevArray, ...newDataArray]);
    setPropertyBoard(initialBoardData);
    setEditorData(null);
    setPropertyBoardPicture("");
    setShowForm(false);
    // setBulkSpaces("");
  };
  

  const editSpacesData = () => {
    const array = [...propertyBoards];
    array[indexEdit] = propertyBoard;
    // const cleanText = new DOMParser().parseFromString(editorData, "text/html")
    //   .body.textContent;
    array[indexEdit].board = editorData;
    setPropertyBoards(array);

    handleBoardTrue(array);
    setShowForm(false);
    setIndexEdit(undefined);
    setPropertyBoard(initialBoardData);
    setPropertyBoardPicture([]);
    setEditorData(null);
  };
  const indexFind = (index) => {
    // var index = $(this).attr('data-index');
    // var modal = $(this).attr('data-bs-toggle');
    // const findIndex = parkingSpaces.length -1 - index ;
    setIndexEdit(index);
    setViewForm(false);
    setShowForm(true);
    setPropertyBoard(propertyBoards[index]);
    setPropertyBoardPicture(propertyBoards[index]?.icon);
    setEditorData(propertyBoards[index]?.board);
    // myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    // // console.log("index",findIndex);
    // // console.log("modal",modal);
  };
  const viewData = (index) => {
    setShowForm(false);
    setPropertyBoard(propertyBoards[index]);
    setEditorData(propertyBoards[index]?.board);
    setPropertyBoardPicture(propertyBoards[index]?.icon);
    setViewForm(true);
  };
  const deleteData = (index) => {
    setShowForm(false);
    setViewForm(false);
    const newArray = [...propertyBoards];
    newArray.splice(index, 1);
    setPropertyBoards(newArray);
    setPropertyBoard(initialBoardData);
    handleBoardTrue(newArray);
  };
  const handleOrderChange = (id, direction) => {
    const index = propertyBoards?.findIndex(item => item.id === id);
    const newListItems = [...propertyBoards].map(({ id, order_number }) => ({ id, order_number }));
    const currentItem = newListItems[index];

    if (direction === "up" && index > 0) {
        const previousItem = newListItems[index - 1];
        currentItem.order_number = previousItem.order_number;
        previousItem.order_number = index + 1;
        newListItems[index - 1] = currentItem;
        newListItems[index] = previousItem;
    } else if (direction === "down" && index < newListItems.length - 1) {
        const nextItem = newListItems[index + 1];
        currentItem.order_number = nextItem.order_number;
        nextItem.order_number = index + 1;
        newListItems[index + 1] = currentItem;
        newListItems[index] = nextItem;
    }

    reArrangeData(newListItems);
};
  const reArrangeData = async(data)=>{
    setLoader(undefined)
    const response = await ApiRequest.post("public/api/auth/rearrange-property-boards",{
      property_id:p_id,
      data:data
    })
    if(response?.status == 200)
    {
      setPropertyBoards(response?.data?.data)
      setLoader("response");
    }


  }
  const sortedData = propertyBoards?.sort((a, b) => a.order_number - b.order_number);
  const showUpArrow = (row) => {
    return sortedData?.length > 1 && row.order_number !== 1;
  };

  const showDownArrow = (row) => {
    return sortedData?.length > 1 && row.order_number !== sortedData.length;
  };
  const columns = [
    {
      name: "",
      cell: (row) => (
        <div>
          {showUpArrow(row) && (
              <i className="fa fa-sort-up text-blue-700 cursor-pointer mx-2" onClick={() => {
                if (!row?.id) {
                  Swal.fire({
                    icon:"info",
                    title:"Please Click on the Save Information First!",
                    customClass:{
                      confirmButton:"btn3"
                    }
                  });
                }
                else{handleOrderChange(row.id, "up")}
              }}></i>
          )}
          {showDownArrow(row) && (
              <i className="fa fa-sort-down text-blue-700 cursor-pointer mx-2" onClick={() => {
                if (!row?.id) {
                  Swal.fire({
                    icon:"info",
                    title:"Please Click on the Save Information First!",
                    customClass:{
                      confirmButton:"btn3"
                    }
                  });
                }
                else{handleOrderChange(row.id, "down")}
              }}></i>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Icon",
      selector: (data) => (
        <>
          <img src={`${imgUrl}/${data.icon}`} alt="" width={100} />
        </>
      ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (data) => data.title,
      sortable: true,
    },
    {
      name: "Type",
      selector: (data) => boardTypes?.find((type)=>type.id == data.board_type_id)?.type,
      sortable: true,
    },

    {
      name: "Options",
      cell: (row, index) => {
        return (
          <>
            {/* <div className="flex"> */}
            {hiddenData != true ? (
              <div className="flex">
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-bs-toggle="modal"
                  data-bs-target={`#ViewForm`}
                  data-index={index}
                  onClick={(e) => {
                    viewData(index);
                  }}
                >
                  <img src="/assets/svgs/system-view.svg" />
                </button>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0 mx-3"
                  data-bs-target={`#staticBackdrop`}
                  data-index={index}
                  onClick={(e) => {
                    indexFind(index);
                  }}
                >
                  <img src="/assets/img/system-edit.png" />
                </button>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-bs-target={`#staticBackdrop`}
                  data-index={index}
                  onClick={(e) => {
                    deleteData(index);
                  }}
                >
                  <img src="/assets/svgs/system-trashcan.svg" />
                </button>
              </div>
            ) : (
              <>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-index={index}
                  data-bs-toggle="modal"
                  data-bs-target={`#ViewForm`}
                  onClick={(e) => {
                    viewData(index);
                  }}
                >
                  <img src="/assets/svgs/system-view.svg" />
                </button>
              </>
            )}
            {/* </div> */}

            {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
          </>
        );
      },
    },
  ];
  
  const tableData = {
    columns: columns,
    data: sortedData,
  };

  const exportPDF = () => {
    const property = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, property, size);

    doc.setFontSize(15);

    const title = "Property Boards";
    const headers = [
      [
        "Title",
        "Icon",
        "Board",
      ],
    ];

    const data = sortedData.map((elt) => [elt.title,imgUrl+'/'+elt.icon,elt.board]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Property Board.pdf");
  };
  const exportCSV = () => {
    // Get data from data table
    const TotalData = sortedData.map((elt) => [elt.title,imgUrl+'/'+elt.icon,elt.board]);
    // Convert the data to a CSV string

    const headers = 
      [
        "Title",
        "Icon",
        "Board",
      ]
    ;
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers);
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "PropertyBoard.csv");
    link.click();
  };

  const getBoardTypes = async()=>{
    const response = await ApiRequest.get('public/api/auth/get-board-types');
    if(response.status == 200)
    {
      setBoardTypes(response?.data?.data?.board_types);
    }
  }

  useEffect(()=>{
    getBoardTypes();
  },[])
  useEffect(() => {
    setPropertyBoard(initialBoardData);
    setPropertyBoards(data);
    setLoader("response")
  }, [data]);

  return (
    <>
      <div className="md:items-center w-full mt-4">
        <div className="flex">
          <img
            className="logo w-12 mr-5"
            src="/assets/img/properties/system-boards.png"
            alt=""
          />
          <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
            Property Boards:
          </label>
          {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="This is a great place to post House Rules and other information you want everyone in your property to have access to. The Boards will appear on their dashboard."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"This is a great place to post House Rules and other information you want everyone in your property to have access to. The Boards will appear on their dashboard."}/>
        </div>
      </div>

      {showForm == false ? (
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="flex justify-end w-full mt-4">
            <div className="w-auto mt-2 ">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <i class="fa fa-plus"></i> Add Board
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showForm == true ? (
        <>
        <div className="md:flex flex-column w-[90%] m-auto">
        <div className="md:flex md:items-center w-1/2 mt-3">
            <div className="md:w-2/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Board Type:
              </label>
            </div>
            <div className="md:w-2/5">
              <select
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`p-board-title`}
                type="text"
                placeholder="Title"
                value={propertyBoard?.board_type_id}
                onChange={(e) => {
                  //   boards[index].title = e.target.value;
                  const value = parseInt(e.target.value);
                  setPropertyBoard({ ...propertyBoard, board_type_id:value,title:"",icon:"",board:"",link:"",link_show_in:"",files:[] });
                  setEditorData("");
                  //   setFormData({ ...formData, boards: boards });
                }}
              >
                <option value="">Select Type</option>
                {boardTypes?.map((type)=>(
                  <option value={type?.id}>{type?.type}</option>
                ))}
                </select>
            </div>
          </div>
        </div>
        {propertyBoard?.board_type_id == 1 ?(<div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Title:
              </label>
            </div>
            <div className="md:w-2/5">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`p-board-title`}
                type="text"
                placeholder="Title"
                value={propertyBoard?.title}
                onChange={(e) => {
                  //   boards[index].title = e.target.value;
                  setPropertyBoard({ ...propertyBoard, title: e.target.value });
                  //   setFormData({ ...formData, boards: boards });
                }}
              />
            </div>
            <div className="flex justify-end md:w-1/5">
              <div className="md:w-1/3">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Icon:
                </label>
              </div>
            </div>
            <div className="md:w-1/2">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload icon
                </span>
                <input
                  accept={picTypes}
                  id={`p-board-icon`}
                  type="file"
                  hidden={true}
                  onChange={async (e) => {
                    let file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener("load", async () => {
                      const imgBase64 = reader.result;
                      try {
                        console.log(imgBase64);
                        const res = await ApiRequest.post(
                          "/public/api/auth/upload-image",
                          { image: imgBase64 }
                        );
                        // console.log("res", res?.data?.data?.image);
                        setPropertyBoard({
                          ...propertyBoard,
                          icon: res?.data?.data?.image,
                        });
                        setPropertyBoardPicture(res?.data?.data?.image);
                        // setFeatureIcon(res?.data?.data?.image);
                      } catch (err) {
                        console.log(err);
                      }
                    });
                    reader.readAsDataURL(file);

                    // console.log("here 142");
                    // const pictures = [...propertyBoardPicture];
                    // pictures[index] = getImagesAndURLs(e)[0];
                    // setPropertyBoardPicture(pictures);
                    // setPropertyBoard({propertyBoard,icon:pictures});
                    // setFormData({ ...formData, boards: boards });
                  }}
                />
              </label>
            </div>
          </div>
          {propertyBoardPicture != null && propertyBoardPicture != "" ? (
            <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
              <div className="container w-full">
                <div
                  id="image-block"
                  className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                >
                  {imageComponent({
                    image: propertyBoardPicture,
                    src: imgUrl + "/" + propertyBoardPicture,
                    inputFieldId: `tenant-item-verification-picture`,
                    trashAble: !hiddenData,
                    onTrashClick: () => {
                      //   const pictures = [...propertyBoardPicture];
                      //   pictures = null;

                      setPropertyBoardPicture(null);
                      setPropertyBoard({ ...propertyBoard, icon: null });
                      //   setFormData({ ...formData, boards: boards });
                    },
                  })}
                </div>
              </div>
            </section>
          ) : null}
          <div className="w-full">
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Board:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                  initData={editorData}
                  onChange={(e) => {
                    // boards[index].board = e.editor.getData();
                    setEditorData(e.editor.getData());
                    // setFormData({ ...formData, boards: boards });
                  }}
                />
              </div>
            </div>
            <div className="md:flex mt-3">
            <div className="w-1/5"></div>
            <div className="md:w-1/3">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload File (s)
                </span>
                <input
                  accept={fileTypes}
                  multiple={true}
                  id={`p-board-file`}
                  type="file"
                  hidden={true}
                  onChange={(e) => {
                    let files = e.target.files;
                    let newImages = [];
                    let maxOrderNumber = 0;
                  
                    // Find the highest order number in the current images
                    propertyBoardFiles?.forEach((imageObject) => {
                      if (imageObject.order_number > maxOrderNumber) {
                        maxOrderNumber = imageObject.order_number;
                      }
                    });
                  
                    // Calculate the total number of images (existing images + new images)
                    const totalImages = propertyBoardFiles.length + files.length;
                  
                    // Loop through each file and convert to base64
                    files.forEach((file, i) => {
                      const reader = new FileReader();
                      reader.addEventListener("load", async () => {
                        const fileBase64 = reader.result;
                        try {
                          const res = await ApiRequest.post(fileApi, {
                            file: fileBase64,
                          });
                  
                          // Add the new image to the array with a unique order number
                          newImages.push({
                            file: res?.data?.data?.file,
                            title: res?.data?.data?.file,
                            url:fileBase64
                          });
                  
                          // If all new images have been processed, update the state
                          if (newImages.length === files.length) {
                            let fileLists = [...propertyBoardFiles];
                            fileLists = [...fileLists, ...newImages];
                            setPropertyBoardFiles(fileLists);
                            let boardCopy = [...propertyBoardFiles];
                            boardCopy.files = fileLists.map(
                              (picObj) => ({
                                file: picObj.file,
                                title: picObj.title,
                              })
                            );
                            setPropertyBoard({ ...propertyBoard, files: boardCopy?.files });
                          }
                        } catch (err) {
                          // console.log(err);
                        }
                      });
                      reader.readAsDataURL(file);
                    });
                  }}
                />
              </label>
            </div>
            <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyBoardFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
          </div>
          <div >
              {propertyBoardFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyBoardFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyBoardFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyBoard.files = fileLists;
                                setPropertyBoardFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyBoardFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyBoard.files[fileIndex].title = title;
                                    setPropertyBoardFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex justify-end w-full mt-4">
                <div className="w-auto mt-2 ">
                  <button
                    className="btn3"
                    type="button"
                    onClick={() => {
                      if (indexEdit !== undefined) {
                        editSpacesData();
                      } else {
                        bulkData();
                      }
                    }}
                  >
                    {indexEdit !== undefined ? (
                      <>
                        <i className="fa fa-edit"></i>Update
                      </>
                    ) : (
                      <>
                        {" "}
                        <i className="fa fa-save"></i>Save
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>):null}
        {propertyBoard?.board_type_id == 2 ? (<div className="md:flex flex-column md:items-center w-[90%] m-auto">
        <div className="w-full">
        <div className="md:flex md:items-center w-full mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Title:
              </label>
            </div>
            <div className="md:w-2/5">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`p-board-title`}
                type="text"
                placeholder="Title"
                value={propertyBoard?.title}
                onChange={(e) => {
                  //   boards[index].title = e.target.value;
                  setPropertyBoard({ ...propertyBoard, title: e.target.value });
                  //   setFormData({ ...formData, boards: boards });
                }}
              />
            </div>
            <div className="flex justify-end md:w-1/5">
              <div className="md:w-1/3">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Icon:
                </label>
              </div>
            </div>
            <div className="md:w-1/2">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload icon
                </span>
                <input
                  accept={picTypes}
                  id={`p-board-icon`}
                  type="file"
                  hidden={true}
                  onChange={async (e) => {
                    let file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener("load", async () => {
                      const imgBase64 = reader.result;
                      try {
                        console.log(imgBase64);
                        const res = await ApiRequest.post(
                          "/public/api/auth/upload-image",
                          { image: imgBase64 }
                        );
                        // console.log("res", res?.data?.data?.image);
                        setPropertyBoard({
                          ...propertyBoard,
                          icon: res?.data?.data?.image,
                        });
                        setPropertyBoardPicture(res?.data?.data?.image);
                        // setFeatureIcon(res?.data?.data?.image);
                      } catch (err) {
                        console.log(err);
                      }
                    });
                    reader.readAsDataURL(file);

                    // console.log("here 142");
                    // const pictures = [...propertyBoardPicture];
                    // pictures[index] = getImagesAndURLs(e)[0];
                    // setPropertyBoardPicture(pictures);
                    // setPropertyBoard({propertyBoard,icon:pictures});
                    // setFormData({ ...formData, boards: boards });
                  }}
                />
              </label>
            </div>
          </div>
          {propertyBoardPicture != null && propertyBoardPicture != "" ? (
            <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
              <div className="container w-full">
                <div
                  id="image-block"
                  className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                >
                  {imageComponent({
                    image: propertyBoardPicture,
                    src: imgUrl + "/" + propertyBoardPicture,
                    inputFieldId: `tenant-item-verification-picture`,
                    trashAble: !hiddenData,
                    onTrashClick: () => {
                      //   const pictures = [...propertyBoardPicture];
                      //   pictures = null;

                      setPropertyBoardPicture(null);
                      setPropertyBoard({ ...propertyBoard, icon: null });
                      //   setFormData({ ...formData, boards: boards });
                    },
                  })}
                </div>
              </div>
            </section>
          ) : null}
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Link:
              </label>
            </div>
            <div className="md:w-full">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`u-board-link`}
                type="text"
                placeholder="Link"
                value={propertyBoard?.link}
                onChange={(e) => {
                  //   boards[index].link = e.target.value;
                  setPropertyBoard({ ...propertyBoard, link: e.target.value });
                  //   setFormData({ ...formData, boards: boards });
                }}
              />
            </div>
          </div>
          <div className="flex items-center mt-3 space-x-4">
            <label className="block capitalize w-1/5 tracking-wide text-gray-700 font-bold mb-2">
              Show in:
            </label>
            <div className="w-full flex">
              <label className="flex items-center space-x-1 text-gray-700">
                <input
                  type="radio"
                  name="showIn"
                  value="newTab"
                  className="form-radio"
                  checked={propertyBoard.link_show_in === "newTab"}
                  onChange={(e) => {
                      setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                    }}
                />
                <span>New Tab</span>
              </label>
              <label className="flex items-center mx-2  space-x-1 text-gray-700">
                <input
                  type="radio"
                  name="showIn"
                  value="newWindow"
                  className="form-radio"
                  checked={propertyBoard.link_show_in === "newWindow"}
                  onChange={(e) => {
                      setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                    }}
                />
                <span>New Window</span>
              </label>
              <label className="flex items-center space-x-1 text-gray-700">
                <input
                  type="radio"
                  name="showIn"
                  value="sameTab"
                  className="form-radio"
                  checked={propertyBoard.link_show_in === "sameTab"}
                  onChange={(e) => {
                      setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                    }}
                />
                <span>Same Tab</span>
              </label>
              <label className="flex items-center mx-2  space-x-1 text-gray-700">
                <input
                  type="radio"
                  name="showIn"
                  value="popUp"
                  className="form-radio"
                  checked={propertyBoard.link_show_in === "popUp"}
                  onChange={(e) => {
                      setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                    }}
                />
                <span>Pop-up</span>
              </label>
            </div>
          </div>
        </div>
      </div>):null}
      {propertyBoard?.board_type_id == 3 ? (<div className="md:flex flex-column md:items-center w-[90%] m-auto">
        <div className="w-full">
        <div className="md:flex md:items-center w-full mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Title:
              </label>
            </div>
            <div className="md:w-2/5">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`p-board-title`}
                type="text"
                placeholder="Title"
                value={propertyBoard?.title}
                onChange={(e) => {
                  //   boards[index].title = e.target.value;
                  setPropertyBoard({ ...propertyBoard, title: e.target.value });
                  //   setFormData({ ...formData, boards: boards });
                }}
              />
            </div>
            <div className="flex justify-end md:w-1/5">
              <div className="md:w-1/3">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Icon:
                </label>
              </div>
            </div>
            <div className="md:w-1/2">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload icon
                </span>
                <input
                  accept={picTypes}
                  id={`p-board-icon`}
                  type="file"
                  hidden={true}
                  onChange={async (e) => {
                    let file = e.target.files[0];
                    const reader = new FileReader();
                    reader.addEventListener("load", async () => {
                      const imgBase64 = reader.result;
                      try {
                        console.log(imgBase64);
                        const res = await ApiRequest.post(
                          "/public/api/auth/upload-image",
                          { image: imgBase64 }
                        );
                        // console.log("res", res?.data?.data?.image);
                        setPropertyBoard({
                          ...propertyBoard,
                          icon: res?.data?.data?.image,
                        });
                        setPropertyBoardPicture(res?.data?.data?.image);
                        // setFeatureIcon(res?.data?.data?.image);
                      } catch (err) {
                        console.log(err);
                      }
                    });
                    reader.readAsDataURL(file);

                    // console.log("here 142");
                    // const pictures = [...propertyBoardPicture];
                    // pictures[index] = getImagesAndURLs(e)[0];
                    // setPropertyBoardPicture(pictures);
                    // setPropertyBoard({propertyBoard,icon:pictures});
                    // setFormData({ ...formData, boards: boards });
                  }}
                />
              </label>
            </div>
          </div>
          {propertyBoardPicture != null && propertyBoardPicture != "" ? (
            <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
              <div className="container w-full">
                <div
                  id="image-block"
                  className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                >
                  {imageComponent({
                    image: propertyBoardPicture,
                    src: imgUrl + "/" + propertyBoardPicture,
                    inputFieldId: `tenant-item-verification-picture`,
                    trashAble: !hiddenData,
                    onTrashClick: () => {
                      //   const pictures = [...propertyBoardPicture];
                      //   pictures = null;

                      setPropertyBoardPicture(null);
                      setPropertyBoard({ ...propertyBoard, icon: null });
                      //   setFormData({ ...formData, boards: boards });
                    },
                  })}
                </div>
              </div>
            </section>
          ) : null}
          <div className="md:flex mt-3">
            <div className="w-1/5"></div>
            <div className="md:w-1/3">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload File
                </span>
                <input
                  accept={fileTypes}
                  multiple={false}
                  id={`p-board-file`}
                  type="file"
                  hidden={true}
                  onChange={(e) => {
                    let files = e.target.files;
                    let newImages = [];
                    let maxOrderNumber = 0;
                  
                    // Find the highest order number in the current images
                    propertyBoardFiles?.forEach((imageObject) => {
                      if (imageObject.order_number > maxOrderNumber) {
                        maxOrderNumber = imageObject.order_number;
                      }
                    });
                  
                    // Calculate the total number of images (existing images + new images)
                    const totalImages = propertyBoardFiles.length + files.length;
                  
                    // Loop through each file and convert to base64
                    files.forEach((file, i) => {
                      const reader = new FileReader();
                      reader.addEventListener("load", async () => {
                        const fileBase64 = reader.result;
                        try {
                          const res = await ApiRequest.post(fileApi, {
                            file: fileBase64,
                          });
                  
                          // Add the new image to the array with a unique order number
                          newImages.push({
                            file: res?.data?.data?.file,
                            title: res?.data?.data?.file,
                            url:fileBase64
                          });
                  
                          // If all new images have been processed, update the state
                          if (newImages.length === files.length) {
                            let fileLists = [...propertyBoardFiles];
                            fileLists = [...fileLists, ...newImages];
                            setPropertyBoardFiles(fileLists);
                            let boardCopy = [...propertyBoardFiles];
                            boardCopy.files = fileLists.map(
                              (picObj) => ({
                                file: picObj.file,
                                title: picObj.title,
                              })
                            );
                            setPropertyBoard({ ...propertyBoard, files: boardCopy?.files });
                          }
                        } catch (err) {
                          // console.log(err);
                        }
                      });
                      reader.readAsDataURL(file);
                    });
                  }}
                />
              </label>
            </div>
          </div>
          <div >
              {propertyBoardFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyBoardFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyBoardFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyBoard.files = fileLists;
                                setPropertyBoardFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyBoardFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyBoard.files[fileIndex].title = title;
                                    setPropertyBoardFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
        </div>
      </div>):null}
        </>
      ) : null}


        <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(true);
                    setPropertyBoard([]);
                    setPropertyBoardPicture([]);
                    setEditorData(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="md:flex md:items-center w-full mt-3">
                    {propertyBoard.title != "" ? (
                      <>
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Title:
                          </label>
                        </div>
                        <div className="md:w-2/5">
                          {/* <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-board-title`}
                        type="text"
                        placeholder="Title"
                        disabled
                        value={propertyBoard?.title}
                        onChange={(e) => {
                        //   boards[index].title = e.target.value;
                          setPropertyBoard({...propertyBoard,title:e.target.value});
                        //   setFormData({ ...formData, boards: boards });
                        }}
                      /> */}
                          <span>{propertyBoard?.title}</span>
                        </div>
                      </>
                    ) : null}
                    {propertyBoard.board_type_id != "" ? (
                      <>
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Type:
                          </label>
                        </div>
                        <div className="md:w-2/5">
                          {/* <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-board-title`}
                        type="text"
                        placeholder="Title"
                        disabled
                        value={propertyBoard?.title}
                        onChange={(e) => {
                        //   boards[index].title = e.target.value;
                          setPropertyBoard({...propertyBoard,title:e.target.value});
                        //   setFormData({ ...formData, boards: boards });
                        }}
                      /> */}
                          <span>{boardTypes?.find((type)=>type?.id == propertyBoard?.board_type_id)?.type}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                    {propertyBoardPicture != null &&
                  propertyBoardPicture != "" ? (
                      <>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-1/3">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Icon:
                            </label>
                          </div>
                        </div>
                        {/* <div className="md:w-1/2"> */}
                          {/* <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload icon
                        </span>
                        <input
                          accept={picTypes}
                          id={`p-board-icon`}
                          type="file"
                          hidden={true}
                          onChange={async (e) => {
                            let file = e.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const imgBase64 = reader.result;
                              try {
                                console.log(imgBase64);
                                const res = await ApiRequest.post(
                                  "/public/api/auth/upload-image",
                                  { image: imgBase64 }
                                );
                                // console.log("res", res?.data?.data?.image);
                                setPropertyBoardPicture(res?.data?.data?.image)
                                // setFeatureIcon(res?.data?.data?.image);
                              } catch (err) {
                                console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);

                            // console.log("here 142");
                            const pictures = [...propertyBoardPicture];
                            // pictures[index] = getImagesAndURLs(e)[0];
                            // setPropertyBoardPicture(pictures);
                            setPropertyBoard({propertyBoard,icon:pictures});
                            // setFormData({ ...formData, boards: boards });
                          }}
                        />
                      </label> */}
                        {/* </div> */}
                      </>
                    ) : null}
                  {propertyBoardPicture != null &&
                  propertyBoardPicture != "" ? (
                    
                    <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                      <div className="container w-full">
                        <div
                          id="image-block"
                          className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                        >
                          {imageComponent({
                            image: propertyBoard.icon,
                            src: imgUrl + "/" + propertyBoard.icon,
                            inputFieldId: `tenant-item-verification-picture`,
                            trashAble: false,
                            onTrashClick: () => {
                              const pictures = [...propertyBoardPicture];
                              pictures = null;

                              setPropertyBoardPicture(pictures);
                              setPropertyBoard({
                                ...propertyBoard,
                                icon: pictures,
                              });
                              //   setFormData({ ...formData, boards: boards });
                            },
                          })}
                        </div>
                      </div>
                    </section>
                  ) : null}
                  {propertyBoard?.files != "" ?
                  
                  <div >
                    <div className="md:flex md:items-center w-full mt-3">

                    <div className="md:w-1/5"></div>
                    <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            File:
                          </label>
                        </div>
                    </div>
                  {propertyBoardFiles.length > 0 ? (
                    <div>
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <div className="container w-full px-3 m-auto">
                          <div
                            id={`p-measurement-documents-block`}
                            className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                          >
                            {propertyBoardFiles?.map(
                              (fileObject, fileIndex) =>
                                fileComponent({
                                  file: fileObject?.file,
                                  title: fileObject?.title,
                                  key: fileIndex,
                                  inputFieldId: `p-measurement-files`,
                                  trashAble: false,
                                  editAble: false,
                                  onTrashClick: () => {
                                    const fileLists = [
                                      ...propertyBoardFiles,
                                    ];
                                    fileLists.splice(fileIndex, 1);
                                    propertyBoard.files = fileLists;
                                    setPropertyBoardFiles(fileLists);
                                  },
                                  onEditClick: () => {
                                    const fileLists = [...propertyBoardFiles];
                                    const currentTitle = fileLists[fileIndex].title || "";
                                  
                                    Swal.fire({
                                      title: "Edit File",
                                      html: `
                                        <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                      `,
                                      focusConfirm: false,
                                      preConfirm: () => {
                                        const title = document.getElementById('swal-input1').value;
                                        return title;
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        const title = result.value;
                                        fileLists[fileIndex].title = title;
                                        propertyBoard.files[fileIndex].title = title;
                                        setPropertyBoardFiles(fileLists);
                                      }
                                    });
                                  }
                                })
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </div>
                  :null}
                  {propertyBoard.board != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Board:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <div dangerouslySetInnerHTML={{ __html: propertyBoard?.board }} />
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyBoard([]);
                                setPropertyBoardPicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(true);
                                setPropertyBoard([]);
                                setPropertyBoardPicture([]);
                                setEditorData(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
            </div>
          </div>
        </div>
      <hr />

      {propertyBoards?.length > 0?<>
      <div className="flex flex-row-reverse mt-3">
        <img
          className="logo w-8 mr-3"
          src="/assets/img/properties/system-download-pdf.png"
          alt="PDF"
          onClick={exportPDF}
          style={{ cursor: "pointer" }}
        />
        <img
          className="logo w-8 mr-3"
          src="/assets/img/properties/system-download-csv.png"
          alt="CSV"
          onClick={exportCSV}
          style={{ cursor: "pointer" }}
        />
      </div>
      {loader == undefined ?<Spinner/> :<DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          className="striped"
          data={sortedData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          highlightOnHover
          sortField={indexEdit}
          sortDirection="asc"
        />
      </DataTableExtensions>}
      </>:null}
      {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  );
};
