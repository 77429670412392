import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../utility/http";

export const materialBrand = createAsyncThunk(
  "materialmeasurement/materialbrand",
  async () => {
    try {
      const response = await ApiRequest.get(
        "public/api/auth/get-material-brands"
      );
      return response.data;
    } catch (error) {
      console.log("materialBrand api has error");
    }
  }
);
export const materialType = createAsyncThunk(
    "materialmeasurement/materialtype",
    async () => {
      try {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-types"
        );
        return response.data;
      } catch (error) {
        console.log("materialtype api has error");
      }
    }
  );
  export const materialIdentifier = createAsyncThunk(
    "materialmeasurement/materialidentifier",
    async () => {
      try {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-identifiers"
        );
        return response.data;
      } catch (error) {
        console.log("materialIdentifier api has error");
      }
    }
  );