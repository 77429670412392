import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import { Tooltip } from '../Loader/Tooltip';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';
import useAllowedMimeTypes from '../genericFunctions.js/mimeType';
import { DatePicker } from '../genericFunctions.js/DatePicker';
import CurrencyInput from '../genericFunctions.js/CurrencyInput';

export const OtherLivings = ({data,handleOtherLivingTrue,hiddenData,ownerSettings}) => {

    const initialOtherLivingData = {
        status: '',
        firstname: "",
        middlename: "",
        lastname: "",
        date_of_birth: "",
        gender_id: '',
        user_relationship_id: '',
        other_relationship: "",
        additional_deposit: "",
        additional_rent: "",
        reason_for_movein: "",
        public_note: "",
        private_note: "",
        pictures: [],
        files: [],
        private_files: [],
      };
      const picTypes = useAllowedPicMimeTypes();
      const fileTypes = useAllowedMimeTypes();
      
    const [tenantOtherLiving, setTenantOtherLiving] = useState([]);
    const [tenantOtherLivings, setTenantOtherLivings] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    console.log('tenantOtherLiving',tenantOtherLivings);
    const [tenantOtherLivingPicture, setTenantOtherLivingPicture] = useState([]);
    const [tenantOtherLivingFile, setTenantOtherLivingFile] = useState([]);
    const [tenantOtherLivingPrivateFile, setTenantOtherLivingPrivateFile] =
      useState([]);
      const [showForm,setShowForm] = useState(false);
      const [viewForm,setViewForm] = useState(false);
      const [editorData,setEditorData] = useState("");
      const [editorData1,setEditorData1] = useState("");
      const [editorData2,setEditorData2] = useState("");
      const [editorData3,setEditorData3] = useState("");
      const [age, setAge] = useState([]);
      let fileApi = "/public/api/auth/upload-file";
      let imageApi = "/public/api/auth/upload-image";
      const [indexEdit,setIndexEdit ]= useState();
      // // console.log('@indexEdit',indexEdit);
      const [bulkAction , setBulkAction] = useState({
        bulk_action:""
      });
      const [genderPreference, setGenderPreference] = useState([]);
      const [userRelationship, setUserRelationship] = useState([]);
      const myDivRef = useRef(null);
      const notes = ()=>{
        const data = [];
        data.push(tenantOtherLiving);
        const array = [...data];
        // console.log('@array',array);
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
        // const cleanText2 = new DOMParser().parseFromString(editorData2, 'text/html').body.textContent;
        // const cleanText3 = new DOMParser().parseFromString(editorData3, 'text/html').body.textContent;
        // array[0].other_relationship = cleanText;
        array[0].reason_for_movein = editorData1;
        array[0].public_note = editorData2;
        array[0].private_note = editorData3;
        setTenantOtherLiving(array);
      }
      const bulkData = ()=>{
        notes();
        //   tenantOtherLiving({...tenantOtherLiving,notes:editorData11}); 
          const newDataArray  = [];
          newDataArray.push(tenantOtherLiving);
          handleOtherLivingTrue(prevArray => [...prevArray, ...newDataArray]);
          setTenantOtherLivings(prevArray => [...prevArray, ...newDataArray]);
          setTenantOtherLiving(initialOtherLivingData);
          setTenantOtherLivingPicture([]);
          setTenantOtherLivingFile([]);
          setTenantOtherLivingPrivateFile([]);
          // setEditorData('');
          setShowForm(false);
          // setBulkSpaces("");
        }
      
      const editSpacesData = () =>{
          const array = [...tenantOtherLivings];
          array[indexEdit] = tenantOtherLiving;
          // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
          // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
          // const cleanText2 = editorData2?.replace(/<\/?[^>]+(>|$)/g, "");
          // const cleanText3 = editorData3?.replace(/<\/?[^>]+(>|$)/g, "");
          // array[indexEdit].other_relationship = cleanText;
          array[indexEdit].reason_for_movein = editorData1;
          array[indexEdit].public_note = editorData2;
          array[indexEdit].private_note = editorData3;
          setTenantOtherLivings(array);
      
          handleOtherLivingTrue(array);
          setShowForm(false);
          setIndexEdit(undefined);
          setTenantOtherLiving(initialOtherLivingData);
          setTenantOtherLivingPicture([]);
          setTenantOtherLivingFile([]);
          setTenantOtherLivingPrivateFile([]);
  
        }
        const approveData = (index) =>{
          // console.log('trueDAta',true);
            const array = [...tenantOtherLivings];
            array[index].status = 1;
            setTenantOtherLivings(array);
        
            handleOtherLivingTrue(array);
            setShowForm(false);
            setIndexEdit(undefined);
            setTenantOtherLiving(initialOtherLivingData);
            setTenantOtherLivingPicture([]);
            setTenantOtherLivingFile([]);
            setTenantOtherLivingPrivateFile([]);
    
    
          }
        const rejectData = (index) =>{
          console.log('trueDAta',true);
            const array = [...tenantOtherLivings];
            array[index].status = 0;
            setTenantOtherLivings(array);
        
            handleOtherLivingTrue(array);
            setShowForm(false);
            setIndexEdit(undefined);
            setTenantOtherLiving(initialOtherLivingData);
            setTenantOtherLivingPicture([]);
            setTenantOtherLivingFile([]);
            setTenantOtherLivingPrivateFile([]);
    
    
          }
        const indexFind = (index)=>
        {
          // var index = $(this).attr('data-index');
          // var modal = $(this).attr('data-bs-toggle');
          // const findIndex = parkingSpaces.length -1 - index ;
          setIndexEdit(index);
          setShowForm(true);
          setTenantOtherLiving(tenantOtherLivings[index]);
          setTenantOtherLivingFile(tenantOtherLivings[index]?.files)
          setTenantOtherLivingPicture(tenantOtherLivings[index]?.pictures);
          setTenantOtherLivingPrivateFile(tenantOtherLivings[index]?.private_files);
          setEditorData(tenantOtherLivings[index]?.other_relationship)
          setEditorData1(tenantOtherLivings[index]?.reason_for_movein)
          setEditorData2(tenantOtherLivings[index]?.public_note)
          setEditorData3(tenantOtherLivings[index]?.private_note)
          myDivRef.current.scrollIntoView({ behavior: 'smooth' });
          // // console.log("index",findIndex);
          // // console.log("modal",modal);
        }
        const viewData = (index)=>{
          setShowForm(false)
          setTenantOtherLiving(tenantOtherLivings[index]);
          setTenantOtherLivingFile(tenantOtherLivings[index]?.files)
          setTenantOtherLivingPicture(tenantOtherLivings[index]?.pictures);
          setTenantOtherLivingPrivateFile(tenantOtherLivings[index]?.private_files);
          setEditorData(tenantOtherLivings[index]?.other_relationship)
          setEditorData1(tenantOtherLivings[index]?.reason_for_movein)
          setEditorData2(tenantOtherLivings[index]?.public_note)
          setEditorData3(tenantOtherLivings[index]?.private_note)
          setViewForm(true);
          myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        const deleteData = (index)=>{
          setShowForm(false);
          setViewForm(false);
          const newArray = [...tenantOtherLivings];
          newArray.splice(index, 1);
          setTenantOtherLivings(newArray);
          handleOtherLivingTrue(newArray);
        }
        const formatDate = (date) => {
            date = new Date(date);
            const year = date.getFullYear();
            const day = ("0" + date.getDate()).slice(-2);
            const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
            const TotalDate = day + "-" + month + "-" + year;
            return TotalDate;
          };
          function stringToDate(dateString) {
            // console.warn("dateString",dateString)
            if (dateString === null || dateString === undefined || dateString >= 0)
              return;
            let parts = dateString.split("-");
            let day = parts[0];
            let month = parts[1]; // months are 0-based
            let year = parts[2];
            let date = year + "-" + month + "-" + day;
            // console.warn("UnformatDate",date)
            return date;
          }
          const calculate_age = (dob1) => {
            var today = new Date();
            var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age_now--;
            }
            console.log(age_now);
            return age_now;
          };
          const calculate_days_until_next_birthday = (dob) => {
          const today = new Date();
          const birthDate = new Date(dob);
        
          // Check if the birthdate is today
          if (
            today.getDate() === birthDate.getDate() &&
            today.getMonth() === birthDate.getMonth()
          ) {
            return <span style={{ color: "#009900" }}>Today</span>
          }
        
          // Calculate the next birthday
          const nextBirthday = new Date(
            today.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );
          if (today > nextBirthday) {
            nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
          }
        
          // Calculate the difference in days
          const timeDiff = nextBirthday.getTime() - today.getTime();
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
        
          // Apply color based on days left
          let color;
          if (daysLeft <= 365 && daysLeft >= 315) {
            color = "#FF0000"
          } else if (daysLeft <= 314 && daysLeft >= 265) {
            color = "#FF9999"
          } else if (daysLeft <= 264 && daysLeft >= 215) {
            color = "#FF8000"
          } else if (daysLeft <= 214 && daysLeft >= 165) {
            color = "#FFB266"
          }
          else if (daysLeft <= 164 && daysLeft >= 115) {
            color = "#FFFF00"
          }
          else if (daysLeft <= 114 && daysLeft >= 65) {
            color = "#FFFF66"
          }
          else if (daysLeft <= 64 && daysLeft >= 5) {
            color = "#33FFFF"
          }
          
           else {
            color = "green";
          }
          if(isNaN(daysLeft))
          {
            return "";
          }
          return (
            <span style={{ color: color }}>
              {daysLeft} {daysLeft === 1 ? "day" : "days"}
            </span>
          );
        };
          function ShowFormatDate(data, format) {
            if (data === null || data === undefined)
              return;
            const date = new Date(stringToDate(data));
            const day = date.getDate();
            const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
            const year = date.getFullYear();
          
            const formattedDay = day < 10 ? '0' + day : day;
            const formattedMonth = month < 10 ? '0' + month : month;
          
            format = format.replace('dd', formattedDay);
            format = format.replace('DD', formattedDay);
            format = format.replace('d', day);
            format = format.replace('D', day);
            format = format.replace('mm', formattedMonth);
            format = format.replace('MM', formattedMonth);
            format = format.replace('m', month);
            format = format.replace('M', month);
            format = format.replace('yyyy', year);
            format = format.replace('YYYY', year);
          
            return format;
          }
          function removeCurrencyAndCommas(str) {
            if (str === null) {
              return '';
            }
            // Remove currency symbols ($, €, £, ¥, etc.)
            str = str.replace(/[$€£¥]/g, '');
            
            // Remove commas
            str = str.replace(/,/g, '');
            
            // Return the modified string
            return str;
          }
          function convertCurrencyToFormat(currency, number) {
            let formattedCurrency = Intl.NumberFormat(undefined, {
              style: 'currency',
              currency: currency,
            }).format(number);
          
            return formattedCurrency;
          }
      const columns = [
          {
            name: "Picture",
            selector: (data) => (
                <>
                {data?.pictures?.length > 0 ? <img className='logo w-1/2' src={`${imgUrl}/${data?.pictures[0].picture}`} alt="" />:null}
                </>
            ),
            sortable: true,
          },
          {
            name: "First Name",
            selector: (data) => data?.firstname,
            sortable: true,
          },
          {
            name: "Last Name",
            selector: (data) => data?.lastname,
            sortable: true,
          },
          {
            name: "Date",
            selector: (data) => data?.date_of_birth != "" ? ShowFormatDate(data?.date_of_birth,ownerSetting?.date_format):"",
            sortable: true,
          },
          {
            name: "Age",
            selector: (data) => {
              const dateOfBirth = stringToDate(data?.date_of_birth);
              const age = calculate_age(dateOfBirth);
            
              if (isNaN(age)) {
                return "";
              } else {
                return (<span className='flex flex-column'><span className='text-center'>{age}</span>{calculate_days_until_next_birthday(dateOfBirth)}</span>);
              }
            },
            sortable: true,
          },
          {
            name: "Gender",
            selector: (data) => genderPreference.map((row)=>{
                if(data?.gender_id == row?.id)
                {
                    return row?.gender
                }
            }),
            sortable: true,
          },
          {
            name: "Relationship",
            selector: (data) => userRelationship.map((row)=>{
                if(data?.user_relationship_id == row?.id)
                {
                    return row?.relationship
                }
            }),
            sortable: true,
          },
          {
            name: "Deposit",
            selector: (data) => data?.additional_deposit != "" ? convertCurrencyToFormat(ownerSetting?.currency_format,data?.additional_deposit):"",
            sortable: true,
          },
          {
            name: "Extra Rent",
            selector: (data) => data?.additional_rent !="" ? convertCurrencyToFormat(ownerSetting?.currency_format,data?.additional_rent):"",
            sortable: true,
          },
          {
            name: "Status",
            selector: (data) => data?.status == 0 ? <span class="status-inactive uppercase">INACTIVE</span>: data?.status == 1 ? <span class="status-active uppercase">ACTIVE</span> : data?.status == 2 ?<span class="status-warning uppercase">Request Pending</span>:null,
            sortable: true,
          },
          // {
          //   name: "Handicap",
          //   selector: (data) => data.handicap,
          // },
          // {
          //   name: "Assignee",
          //   selector : (data) => 'UnAssign'
          // },
          // {
          //   name: "Status",
          //   selector: (row) => row.is_active===1?"Active":"Inactive",
          // },
          {
            name: "Options",
            cell: (row,index) => {
              return (
                <>
                  {hiddenData != true ?(<div className="flex">
                {/* <button type="button" class="bg-transparent border-none md:w-1/2  p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/2  p-0 mx-2"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button> */}
                {/* <button type="button" class="bg-transparent border-none md:w-1/6 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
                */}
                {row.status == 2 ?
                    <>
                    <button type="button" class="bg-transparent border-none md:w-1/2  p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/2  p-0 mx-2"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                      <button
                        type="button"
                        className="bg-transparent border-none md:w-1/2 p-0"
                        data-bs-target={`#staticBackdrop`}
                        onClick={(e) => {
                          rejectData(index);
                        }}
                      >
                        <img src="/assets/img/system-reject.png" alt="Reject" />
                      </button>
                      <button
                        type="button"
                        className="bg-transparent border-none md:w-1/2 p-0 mx-2"
                        data-bs-target={`#staticBackdrop`}
                        onClick={(e) => {
                          approveData(index);
                        }}
                      >
                        <img src="/assets/img/system-approve.png" alt="Approve" />
                      </button>
                    </>
                   : <>
                   <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                    data-bs-target={`#ViewOtherLivingForm`}
                    data-bs-toggle='modal'
                    data-index={index}
                    onClick={(e)=>{
                      viewData(index);
                    }}
                    >
        
                        <img src='/assets/svgs/system-view.svg' />
                      
                    </button>
                    <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mx-2"
                    data-bs-target={`#staticBackdrop`}
                    data-index={index}
                    onClick={(e)=>{
                      indexFind(index);
                    }}
                    >
        
                        <img src='/assets/img/system-edit.png' />
                      
                    </button>
                   </>
                }
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/2  p-0"
                data-bs-target={`#ViewOtherLivingForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
                  {/* <div className="md:w-1/12">
                  <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                    <i class="fa-regular fa-eye mr-6"></i>
                  </button>
                  </div> */}
                </>
              );
            },
          },
        ];
      const tableData = {
          columns : columns,
          data : tenantOtherLivings,
        };
        const exportPDF = ()=>{
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "portrait"; // portrait or landscape
      
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
      
          doc.setFontSize(15);
      
          const title = "Tenant Other Livings";
          const headers = [["First Name", "Last Name","Date","Age","Gender","Relationship","Deposit","Extra Rent","Status"]];
      
          const data = tenantOtherLivings.map(elt => 
            [
              elt.firstname,
              elt.lastname,
              elt.date_of_birth,
              calculate_age(stringToDate(elt.date_of_birth)),
              genderPreference.find((row)=>elt.gender_id == row?.id)?.gender,
              userRelationship?.find((row)=>elt.user_relationship_id == row?.id)?.relationship,
              elt.additional_deposit,
              elt.additional_rent,
              elt.status == 1 ? 'Active' : elt.status == 0 ? "Inactive" : elt.status == 2 ? "Request Pending" : null
            ])
      
          let content = {
            startY: 50,
            head: headers,
            body: data
          };
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
          doc.save("Tenant Other Livings.pdf")
        }
        const exportCSV = () => {
          // Get data from data table
          const TotalData = tenantOtherLivings.map(elt => 
            [
              elt.firstname,
              elt.lastname,
              elt.date_of_birth,
              calculate_age(stringToDate(elt.date_of_birth)),
              genderPreference.find((row)=>elt.gender_id == row?.id)?.gender,
              userRelationship?.find((row)=>elt.user_relationship_id == row?.id)?.relationship,
              elt.additional_deposit,
              elt.additional_rent,
              elt.status == 1 ? 'Active' : elt.status == 0 ? "Inactive" : elt.status == 2 ? "Request Pending" : null
            ])
          // Convert the data to a CSV string
      
          const headers = ["First Name", "Last Name","Date","Age","Gender","Relationship","Deposit","Extra Rent","Status"];
          // let content = {
          //   head:headers,
          //   body:TotalData,
          // }
          TotalData.unshift(headers)
          const csv = Papa.unparse(TotalData);
          // Create a blob of the data
          const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          // Create a link to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Tenant Other Livings.csv");
          link.click();
        };
        const imageComponent = ({
            image = {},
            key = 0,
            src = "",
            inputFieldId = "",
            trashAble = false,
            onTrashClick = () => {},
            onEditClick = () => {},
            ...restProps
          }) => {
            const confirmEdit = (image) => {
              Swal.fire({
                title: 'Are you sure you want to edit this image?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
              }).then((result) => {
                if (result.isConfirmed) {
                  // Modify the image object here
                  const editedImage = { ...image, title: 'New Title' };
                  onEditClick(editedImage);
                }
              });
            };
          
            return image ? (
              <div
                onMouseEnter={(e) => {
                  e.currentTarget.childNodes[0].childNodes[1].hidden = false;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.childNodes[0].childNodes[1].hidden = true;
                }}
                className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
              >
                <div className="relative h-fit w-full">
                  <img
                    title={image?.title}
                    key={key}
                    alt="property image"
                    className="border block object-contain w-80 h-auto rounded-lg"
                    src={src}
                  />
                  <div
                    hidden={true}
                    className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
                  >
                    {trashAble ? (
                      <img
                        title="Remove image"
                        type="button"
                        className="logo w-5 mx-auto my-1"
                        src="/assets/img/trash-icon.png"
                        onClick={(e) => {
                          const fileInputField = document.getElementById(inputFieldId);
                          let dataTransfer = new DataTransfer();
          
                          if (fileInputField) {
                            fileInputField.files.forEach((file) => {
                              if (file !== image) {
                                dataTransfer.items.add(file);
                              }
                            });
                            fileInputField.files = dataTransfer.files;
                          }
                          onTrashClick(e);
                        }}
                        alt=""
                      />
                    ) : null}
                    {/* <button
                      type="button"
                      title="Edit image"
                      className="logo w-5 mx-auto my-1"
                      onClick={() => confirmEdit(image)}
                    >
                      <img src="/assets/img/system-edit.png" alt="edit" />
                    </button> */}
                  </div>
                </div>
              </div>
            ) : (
              <img {...restProps} />
            );
          };
          const fileComponent = ({
            file = null,
            title = null,
            key = 0,
            inputFieldId = "",
            trashAble = false,
            editAble = false,
            onTrashClick = () => {},
            onEditClick = () => {},
          }) =>
            file ? (
              <div
                onMouseEnter={(e) => {
                  e.currentTarget.childNodes[0].childNodes[1].hidden = false;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.childNodes[0].childNodes[1].hidden = true;
                }}
                className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
              >
                <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
                  <div title={title} key={key} className="block min-w-[600rem] px-2">
                    <a href={`${imgUrl}/${file}`} target="_blank">
                      {title}
                    </a>
                  </div>
                  <div
                    hidden={true}
                    className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
                  >
                    
                    {editAble ? (
                      <img
                        title="Edit file"
                        className="logo w-5 my-auto mx-1"
                        src="/assets/img/system-edit.png"
                        onClick={(e) => {
                          onEditClick();
                        }}
                        alt=""
                      />
                    ) : null}
                    {trashAble ? (
                      <img
                        title="Remove file"
                        className="logo w-5 my-auto mx-1"
                        src="/assets/img/trash-icon.png"
                        onClick={(e) => {
                          const fileInputField = document.getElementById(inputFieldId);
                          let dataTransfer = new DataTransfer();
          
                          if (fileInputField) {
                            fileInputField.files.forEach((input_file) => {
                              if (input_file !== file) {
                                dataTransfer.items.add(input_file);
                              }
                            });
                            fileInputField.files = dataTransfer.files;
                          }
                          onTrashClick(e);
                        }}
                        alt=""
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null;
           


          const getGenderPreferences = async () => {
            const response = await ApiRequest.get(
              "/public/api/auth/get-genders"
            );
            if (response.status == 200) {
              setGenderPreference(response?.data?.data?.genders);
            }
          };
          const getUserRelationships = async () => {
            const response = await ApiRequest.get(
              "/public/api/auth/get-users-relationships"
            );
            if (response.status == 200) {
              setUserRelationship(response?.data?.data?.relationships);
            }
          };

useEffect(() => {
    getUserRelationships();
    getGenderPreferences();
    setTenantOtherLiving(initialOtherLivingData);
    setTenantOtherLivings(data);
    setOwnerSetting(ownerSettings)
}, [])
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewOtherLivingForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewOtherLivingFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Other Living
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantOtherLiving([]);
                    setTenantOtherLivingFile([]);
                    setTenantOtherLivingPicture([]);
                    setTenantOtherLivingPrivateFile([]);
                    setEditorData(null);
                    setEditorData1(null);
                    setEditorData2(null);
                    setEditorData3(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex">
                  <div className="w-4/12 mr-3">
                      {tenantOtherLiving?.pictures?.length > 0 ?
                      (<>
                      <img className="logo w-full" src={`${imgUrl}/${tenantOtherLiving?.pictures[0]?.picture}`} alt="" />
                      </>)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                      </div>
                    <div className="w-8/12">
                      <div className="flex">
                        {tenantOtherLiving?.firstname != "" ? <span className='font-bold capitalize'>{tenantOtherLiving?.firstname}</span>:null}
                        {tenantOtherLiving?.middlename != "" ? <span className='font-bold capitalize ml-2'>{tenantOtherLiving?.middlename}</span>:null}
                        {tenantOtherLiving?.lastname != "" ? <span className='font-bold capitalize ml-2'>{tenantOtherLiving?.lastname}</span>:null}
                      </div>
                      {tenantOtherLiving?.status !== "" && (
                          <div className="flex mt-2">
                            {tenantOtherLiving?.status === 1 && <span className='status-active uppercase'> Active</span>}
                            {tenantOtherLiving?.status === 0 && <span className='status-inactive uppercase'>Inactive</span>}
                            {tenantOtherLiving?.status === 2 && <span className='status-warning uppercase'> Request Pending</span>}
                          </div>
                        )}
                      <div className="flex mt-2">
                        {tenantOtherLiving?.user_relationship_id != "" ? 
                          <>
                          <span className='font-bold'>{userRelationship?.map((row)=>{
                          if(tenantOtherLiving?.user_relationship_id == row?.id)
                          {
                            return row?.relationship;
                          }
                        })}</span>
                        {tenantOtherLiving?.gender_id != "" ? <span className='ml-2'>|</span>:null}
                        </>:null}
                          {tenantOtherLiving?.gender_id != "" ? 
                          <span className='ml-2 font-bold'>{genderPreference?.map((row)=>{
                          if(tenantOtherLiving?.gender_id == row?.id)
                          {
                            return row?.gender;
                          }
                        })}</span>:null}
                          
                        </div>
                        {tenantOtherLiving?.date_of_birth != "" ?
                        <div className='flex mt-2'>
                          <span className='font-bold'>{ShowFormatDate(tenantOtherLiving.date_of_birth,ownerSetting?.date_format)}</span> 
                          <span className='ml-2 font-bold'>( {calculate_days_until_next_birthday(stringToDate(tenantOtherLiving?.date_of_birth))} )</span>
                          </div>
                          :null}
                          <div className="flex mt-2">
                          {tenantOtherLiving?.additional_deposit != "" ? <><span className='capitalize'>Deposit: {convertCurrencyToFormat(ownerSetting?.currency_format,tenantOtherLiving?.additional_deposit)}</span>{tenantOtherLiving?.additional_rent != "" ? <span className='ml-2'>|</span> :null}</>:null}
                          {tenantOtherLiving?.additional_rent != "" ? <><span className='capitalize ml-2'>Rent: {convertCurrencyToFormat(ownerSetting?.currency_format,tenantOtherLiving?.additional_rent)}</span></>:null}
                        </div>
                        
                    </div>
                  </div>
                  {tenantOtherLiving?.reason_for_movein != "" ?
                  <>
                    <hr />
                    <h6>Reason:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantOtherLiving?.reason_for_movein  }} />
                    </div>

                  </>:null}
                  {tenantOtherLiving?.public_note != "" ?
                  <>
                    <hr />
                    <h6>Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantOtherLiving?.public_note  }} />
                    </div>

                  </>:null}
                  {tenantOtherLiving?.files?.length > 0 ?
                  <>
                    <hr />
                    <h6>Files:</h6>
                    {tenantOtherLiving?.files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                  {tenantOtherLiving?.private_note != "" ?
                  <>
                    <hr />
                    <h6>Private Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantOtherLiving?.private_note  }} />
                    </div>

                  </>:null}
                  {tenantOtherLiving?.private_files?.length > 0 ?
                  <>
                    <hr />
                    <h6>Private Files:</h6>
                    {tenantOtherLiving?.private_files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                  
                </div>
              </div>
              :null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantOtherLiving([]);
                                setTenantOtherLivingFile([]);
                                setTenantOtherLivingPicture([]);
                                setTenantOtherLivingPrivateFile([]);
                                setEditorData(null);
                                setEditorData1(null);
                                setEditorData2(null);
                                setEditorData3(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-others-living.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Others Living:
              </label>
              <Tooltip data={"This is an area to add information regarding others who are living with the Tenant. These are people who do not have a lease and are NOT considered Co-Tenants."}/>
            </div>
          </div>

         { showForm == false ? 
         <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true)
                  }}
                >
                  <i class="fa fa-plus"></i> Add Person
                </button>
              </div>
            </div>
          </div>
        :null}
              {showForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/3">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status: <span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-status`}
                          required={false}
                          value={tenantOtherLiving?.status}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,status:parseInt(e.target.value)});
                          }}
                        >
                          <option value="">Select Status</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="2">Request Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          First Name:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4 mx-2">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-firstname`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={tenantOtherLiving?.firstname}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,firstname:e.target.value})
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Middle Name:
                        </label>
                      </div>
                      <div className="md:w-2/4 mx-2">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-middlename`}
                          type="text"
                          placeholder="Ben"
                          required={false}
                          value={tenantOtherLiving?.middlename}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,middlename:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Name:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-lastname`}
                          type="text"
                          placeholder="Deo"
                          required={false}
                          value={tenantOtherLiving?.lastname}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,lastname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4 mx-3">
                        <DatePicker
                          value={stringToDate(tenantOtherLiving?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            const FullAge = [...age];
                            const TotalAge = calculate_age(e);
                            FullAge[0] = TotalAge;
                            setAge(FullAge);
                            setTenantOtherLiving({...tenantOtherLiving,date_of_birth:date});
                          }}
                        />
                      </div>
                      {age[0] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {age[0]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Gender:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-gender`}
                          required={true}
                          value={tenantOtherLiving?.gender_id}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,gender_id:parseInt(e.target.value)})
                          }}
                        >
                          <option value=" ">Select Gender</option>
                          {genderPreference.map((row) => (
                            <option value={row?.id}>{row?.gender}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Relationship:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`other-living-user_relationship_id`}
                          value={tenantOtherLiving?.user_relationship_id}
                          required={true}
                          onChange={(e) => {
                            setTenantOtherLiving({...tenantOtherLiving,user_relationship_id:parseInt(e.target.value)});
                          }}
                        >
                          <option value=" ">Select Relationship</option>
                          {userRelationship.map((relation) => (
                            <option value={relation?.id}>
                              {relation?.relationship}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      {tenantOtherLiving?.user_relationship_id == 6 ? (
                      <div className="md:flex mt-3">
                        <div className="md:w-1/2">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Other Type Description:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`other-living-lastname`}
                            type="text"
                            placeholder="Description"
                            required={false}
                            value={tenantOtherLiving?.other_relationship}
                            onChange={(e) => {
                              setTenantOtherLiving({...tenantOtherLiving,other_relationship:e.target.value});
                            }}
                          />
                        </div>
                      </div>
                    ) : null}    
                    </div>
                  </div>
                  
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Additional Deposit:
                        </label>
                      </div>
                      <div className="md:w-1/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="additional-deposit"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantOtherLiving?.additional_deposit != null ? tenantOtherLiving?.additional_deposit : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantOtherLiving({ ...tenantOtherLiving, additional_deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantOtherLiving({ ...tenantOtherLiving, additional_deposit: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Additional Rent:
                        </label>
                      </div>
                      <div className="md:w-1/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="additional-rent"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantOtherLiving?.additional_rent != null ? tenantOtherLiving?.additional_rent : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantOtherLiving({ ...tenantOtherLiving, additional_rent: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantOtherLiving({ ...tenantOtherLiving, additional_rent: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reason For Person Wanting to Move-in:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData1}
                        onChange={(e) => {
                          setEditorData1(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pictures:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id={`other-living-pictures`}
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantOtherLivingPicture?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantOtherLivingPicture.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1 + tenantOtherLivingPicture.length,
                                    url:imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...tenantOtherLivingPicture];
                                    pictureLists = [...pictureLists, ...newImages];
                                    setTenantOtherLivingPicture(pictureLists);
                                    let tenantCopy = [...tenantOtherLivingPicture];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        order_number: picObj.order_number,
                                        is_cover: picObj.is_cover,
                                      })
                                    );
                                    setTenantOtherLiving({ ...tenantOtherLiving, pictures: tenantCopy?.pictures });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantOtherLivingPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div >
              {tenantOtherLivingPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantOtherLivingPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantOtherLivingPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantOtherLiving.pictures = pictureLists;
                                setTenantOtherLivingPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantOtherLivingPicture({
                                  ...tenantOtherLiving,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Files:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id={`other-living-files`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantOtherLivingFile?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantOtherLivingFile.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    title: res?.data?.data?.file,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...tenantOtherLivingFile];
                                    fileLists = [...fileLists, ...newImages];
                                    setTenantOtherLivingFile(fileLists);
                                    let tenantCopy = [...tenantOtherLivingFile];
                                    tenantCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        title: picObj.title,
                                      })
                                    );
                                    setTenantOtherLiving({ ...tenantOtherLiving, files: tenantCopy?.files });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantOtherLivingFile?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
              {tenantOtherLivingFile.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantOtherLivingFile?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantOtherLivingFile,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantOtherLiving.files = fileLists;
                                setTenantOtherLivingFile(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...tenantOtherLivingFile];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    tenantOtherLiving.files[fileIndex].title = title;
                                    setTenantOtherLivingFile(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData2}
                        onChange={(e) => {
                          setEditorData2(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Private Files:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id={`other-living-private-files`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantOtherLivingPrivateFile?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantOtherLivingPrivateFile.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    title: res?.data?.data?.file,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...tenantOtherLivingPrivateFile];
                                    fileLists = [...fileLists, ...newImages];
                                    setTenantOtherLivingPrivateFile(fileLists);
                                    let tenantCopy = [...tenantOtherLivingPrivateFile];
                                    tenantCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        title: picObj.title,
                                      })
                                    );
                                    setTenantOtherLiving({ ...tenantOtherLiving, private_files: tenantCopy?.files });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantOtherLivingPrivateFile?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
              {tenantOtherLivingPrivateFile.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-private-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantOtherLivingPrivateFile?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-private-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantOtherLivingPrivateFile,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantOtherLiving.private_files = fileLists;
                                setTenantOtherLivingPrivateFile(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...tenantOtherLivingPrivateFile];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    tenantOtherLiving.private_files[fileIndex].title = title;
                                    setTenantOtherLivingPrivateFile(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Private Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData3}
                        onChange={(e) => {
                          setEditorData3(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex flex-column md:items-center w-full m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/tenant-allergies.svg"
                    alt=""
                  />
                </div>

                <div className="md:w-1/5 flex">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Known Allergies:
                  </label>
                  <Tooltip data={"It can be beneficial to know certain allergies (like bee stings or nuts) that can have potential life-threatening implications. This information can help you be a better landlord and to be able to respond better in an emergency."}/>
                </div>
                <div className="md:w-4/5 ml-2">
                  <CKEditor
                    // initData={editorData?.known_allergies}
                    // onChange={(e) => {
                    //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    //   setEditorData({
                    //     ...editorData,
                    //     known_allergies: cleanText,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <hr />

              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="assets/img/tenants/system-emergency-information.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Other Emergency Info:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    // initData={editorData2?.other_emergency_info}
                    // onChange={(e) => {
                    //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    //   setEditorData2({
                    //     ...editorData2,
                    //     other_emergency_info: cleanText,
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if (tenantOtherLiving.status != 0 && tenantOtherLiving.status != 1 && tenantOtherLiving.status != 2 || !tenantOtherLiving.firstname ||!tenantOtherLiving.lastname ||!tenantOtherLiving.gender_id || !tenantOtherLiving.user_relationship_id) {
                              Toast.fire({
                                    icon: 'warning',
                                    title: 'Please fill in the required fields'
                                  })
                            }
                            else{
                              
                              editSpacesData();
                            }
                          } else {
                            if (tenantOtherLiving.status != 0 && tenantOtherLiving.status != 1 && tenantOtherLiving.status != 2 || !tenantOtherLiving.firstname ||!tenantOtherLiving.lastname ||!tenantOtherLiving.gender_id || !tenantOtherLiving.user_relationship_id) {
                              Toast.fire({
                                    icon: 'warning',
                                    title: 'Please fill in the required fields'
                                  })
                            } 
                            // else if (!tenantOtherLiving.lastname) {
                            //   Swal.fire({
                            //     icon: 'warning',
                            //     title: 'Last name is required',
                            //     showCancelButton: false,
                            //     confirmButtonText: 'Yes',
                            //   });
                            // } else if (!tenantOtherLiving.gender_id) {
                            //   Swal.fire({
                            //     icon: 'warning',
                            //     title: 'Gender is required',
                            //     showCancelButton: false,
                            //     confirmButtonText: 'Yes',
                            //   });
                            // } else if (!tenantOtherLiving.user_relationship_id) {
                            //   Swal.fire({
                            //     icon: 'warning',
                            //     title: 'User relationship is required',
                            //     showCancelButton: false,
                            //     confirmButtonText: 'Yes',
                            //   });
                            // } 
                            else {
                              bulkData();
                            }
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>
              :null}
              

          
          <hr />
          {tenantOtherLivings?.length > 0 ?<>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantOtherLivings}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions></>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>
          <hr /> */}
    </>
  )
}
