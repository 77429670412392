import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const LeaseMovingPropertyUnitForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-property-unit-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Move-in Walkthrough" {...a11yProps(0)} />
          <Tab label="Move-out Notices" {...a11yProps(1)} />
          <Tab label="Move-out Checklist" {...a11yProps(2)} />
          <Tab label="Move-out Walkthrough" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-in Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-to-do.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Walkthrough List:
                  </label>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Load Template
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save as Template
                    </button>
                  </div>
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="md:flex flex-column mt-3">
                <div className="md:w-full">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Move-in Walkthrough Agreement Text:
                  </label>
                </div>
                <div className="md:w-full">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tenant Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Walkthrough Agent Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex items-center mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/tenant-move-out-notice.svg"
                    alt=""
                  />
                </div>
                <div className="md:w-1/3">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Move-out Notice Text:
                  </label>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-full">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-move-out-checklist.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Checklist:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex flex-column mt-3">
                <div className="md:w-1/3">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Move-out Notice Text:
                  </label>
                </div>
                <div className="md:w-full">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Load Template
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save as Template
                    </button>
                  </div>
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-end.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-to-do.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Walkthrough List:
                  </label>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Load Template
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save as Template
                    </button>
                  </div>
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="md:flex flex-column mt-3">
                <div className="md:w-full">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Move-out Walkthrough Agreement Text:
                  </label>
                </div>
                <div className="md:w-full">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
