import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import pdf from "../Images/pdf.png";
import csv from "../Images/csv.png";
import 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './JQueryDatatable.css';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const FinalTable = () => {
    const tableRef = useRef(null);
    const data = [
        { name: "John Doe", position: "Developer", office: "New York", age: 32, start_date: "2019/05/15", salary: "$103,600",company:"Develpify Studio" },
        { name: "Jane Smith", position: "Manager", office: "San Francisco", age: 28, start_date: "2018/12/20", salary: "$144,200" ,company:"Develpify Studio"},
        { name: "Bob Johnson", position: "Salesman", office: "Los Angeles", age: 44, start_date: "2016/11/02", salary: "$81,000",company:"Develpify Studio" },
        { name: "Alice Lee", position: "Marketing", office: "Chicago", age: 35, start_date: "2017/04/01", salary: "$92,400",company:"Develpify Studio" },
        { name: "Tom Wilson", position: "Engineer", office: "Seattle", age: 29, start_date: "2018/08/01", salary: "$123,600",company:"Develpify Studio" },
      ];
    
      const columns = [
        { title: "Name", data: "name" },
        { title: "Position", data: "position" },
        { title: "Office", data: "office" },
        { title: "Age", data: "age" },
        { title: "Start date", data: "start_date" },
        { title: "Salary", data: "salary" },
        { title: "Company", data: "company" },
      ];
      useEffect(() => {
          $(tableRef.current).DataTable({
            dom: 'Bfrtip',
              buttons: [{extend:'csv', text: `<img src="/assets/img/properties/system-download-csv.png" alt="CSV Icon" style="max-width:8%">`,}, {extend:'pdf', text: `<img src="/assets/img/properties/system-download-pdf.png" alt="Pdf Icon" style="max-width:8%">`,},'copy','print'],
              data:data,
              columns:columns,
            });
        }, []);
  return (
    <div>
        <table ref={tableRef} id="tableRef" className='stripe hover'></table>

    </div>
  )
}
