import React, {useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import {useDispatch} from "react-redux";
import {registerUser} from "../../store/auth/authThunk";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const notify = (msg) => toast.error(msg);

export const RegisterPage = () => {
  const [role, setRole] = useState('2');
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [address_line1, setAddress_line1] = useState();
  const [address_line2, setAddress_line2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [zip_code, setZipCode] = useState();
  const [phone_country_code, setPhoneCountryCode] = useState();
  const [phone_number, setPhoneNumber] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let error = useSelector(state => state.auth.error);

  const addBodyClass = className => document.body.classList.add(className);
  const removeBodyClass = className => document.body.classList.remove(className);
  const addHtmlClass = className => {
    let tag = document.getElementsByTagName('html')[0];
    tag.classList.add(className);
  }
  const removeHtmlClass = className => {
    let tag = document.getElementsByTagName('html')[0];
    tag.classList.remove(className);
  }
  React.useEffect(() => {
    addHtmlClass('h-full');
    addBodyClass('h-full');
    document.getElementById('root').classList.add('h-full');
    return () => {
      removeBodyClass('h-full');
      removeHtmlClass('h-full');
      document.getElementById('root').classList.remove('h-full');
    }
  }, [])

  const register = async (e) => {
    if (!firstname || !lastname || !email || !password || !role || !address_line1 || !address_line2 || !city || !state || !country || !zip_code || !phone_country_code || !phone_number) {
      notify('Please fill all required fields.');
      return
    }
    await setIsSubmitting(true);
    e.preventDefault();
    await dispatch(registerUser({
      firstname,
      lastname,
      email,
      password,
      role_id: role,
      address_line1,
      address_line2,
      city,
      state,
      country,
      zip_code,
      phone_country_code,
      phone_number
    }));
    await setIsSubmitting(false);
    if (error) {
      notify(JSON.stringify(error))
    }
    navigate('/login');
  }

  return (
      <div className="mt-10 sm:mt-0 h-full bg-cover bg-center bg-no-repeat"
           style={{'background': "url('https://picsum.photos/1440')"}}>
        <Toaster/>
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-1">
          </div>
          <div className="mt-3 md:mt-0 md:col-span-4">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          First name
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          required
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setFirstname(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex">
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                          Last name
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          required
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setLastname(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <div className="flex">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                          Email Address
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="email-address"
                          id="email-address"
                          autoComplete="email"
                          required
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <div className="flex">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="password"
                          name="password"
                          id="password"
                          autoComplete="password"
                          required
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex">
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                          Role
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <select
                          id="role"
                          name="role"
                          autoComplete="role"
                          required
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={e => setRole(e.target.value)}
                          value={role}
                      >
                        <option value='2'>Owner</option>
                        <option value='3'>Owner Unlimited</option>
                        <option value='4'>Tenant</option>
                        <option value='5'>Co-Tenant</option>
                        <option value='6'>Vendor</option>
                        <option value='7'>Issue Tracker Staff</option>
                      </select>
                    </div>

                    <div className="col-span-6">
                      <div className="flex">
                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                          Address Line 1
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="street-address"
                          id="street-address"
                          autoComplete="street-address"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setAddress_line1(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6">
                      <div className="flex">
                        <label htmlFor="street-address-2" className="block text-sm font-medium text-gray-700">
                          Address Line 2
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="street-address-2"
                          id="street-address-2"
                          autoComplete="street-address-2"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setAddress_line2(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="city"
                          id="city"
                          autoComplete="city"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setCity(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="state"
                          id="state"
                          autoComplete="state"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setState(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="country"
                          id="country"
                          autoComplete="country"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setCountry(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="postal-code"
                          id="postal-code"
                          autoComplete="postal-code"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setZipCode(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="phone-code" className="block text-sm font-medium text-gray-700">
                          Phone Code
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="phone-code"
                          id="phone-code"
                          autoComplete="phone-code"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setPhoneCountryCode(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div className="flex">
                        <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <span className="ml-3 text-red-600">*</span>
                      </div>
                      <input
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          autoComplete="phone-number"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={e => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  {isSubmitting ? <button type="button"
                                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          disabled="disabled">
                    <svg className="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              strokeWidth="4"/>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    Saving User...
                  </button> : <button
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={e => register(e)}
                  >
                    Save
                  </button>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  )
}
