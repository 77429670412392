import React, { useState } from "react";
import imgUrl from "../ImageUrl";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";

export const UnitHistory = () => {
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
      };
      const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          "aria-controls": `simple-tabpanel-${index}`,
        };
      };
  return (
    <>
    <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
            <Tab label="Tenants" {...a11yProps(0)} />
            <Tab label="Maintenance History" {...a11yProps(1)} />
            <Tab label="Capital Improvements History" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
            <h2>Tenants</h2>
        <div className="flex">
        <div class="w-1/2 p-4">
          <div class="flex">
            <div class="w-1/4">
              <img
                src="assets/img/user2.png"
                alt="Image"
                className="h-26 w-26 object-cover"
              />
            </div>
            <div class="w-3/4 ml-4">
              <div class="text-left">
                <h5 class="text-gray-600 font-semibold text-md block">
                    Cathy Moreno   
                </h5>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Lease Start: May 4, 2023  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Lease End: Present  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Monthly Rent: $1,450  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Reason for Moving:   
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div class="w-1/2 p-4">
          <div class="flex">
            <div class="w-1/4">
              <img
                src="assets/img/user1.png"
                alt="Image"
                className="h-26 w-26 object-cover"
              />
            </div>
            <div class="w-3/4 ml-4">
              <div class="text-left">
                <h5 class="text-gray-600 font-semibold text-md block">
                    Sean Sapp   
                </h5>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Lease Start: April 12, 2022  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Lease End: April 13, 2023  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Monthly Rent: $1,400  
                </span>
              </div>
              <div class="text-left mt-1">
                <span class="text-gray-600 font-semibold text-sm block">
                    Reason for Moving: New Job  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <h2>Maintenance History</h2>
            <span>This would show history of maintenance Issues for this unit from the future Issue Tracker Module.</span>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
            <h2>Capital Improvements History</h2>
            <span>This would show history of Capital Improvements for this unit from the future Capital Improvements Module.</span>
        </TabPanel>
      
    </>
  );
};
