import { CKEditor } from 'ckeditor4-react';
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Select from 'react-select';
import ApiRequest from '../../utility/http';
import { NumericFormat } from 'react-number-format';
import CurrencyInput from '../genericFunctions.js/CurrencyInput';
// import '../OwnerCommunication/select2.css';
export const PropertyLocations = ({data,handleLocationTrue,hiddenData,ownerSettings}) => {

    const initialPropertyLocationData = {
        location: "",
        description: "",
        can_reserve: 0,
        requires_approval: 0,
        deposit: '',
        fee: '',
        different_options_per_role: 0,
        roles: [],
        roles_basis: []
    }
    console.log('ownerSettings',ownerSettings);
    const [propertyLocation,setPropertyLocation] = useState([]);
    const [propertyLocations,setPropertyLocations] = useState([]);
    // const [ownerSettings, setOwnerSetting] = useState([]);
    const [role,setRole] = useState([]);
    const [selectedRoles,setSelectedRoles] = useState([]);
    const [selectedRolesTable,setSelectedRolesTable] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [viewForm,setViewForm] =useState(false);
    const [indexEdit,setIndexEdit ]= useState();
    const [disabled,setDisabled] = useState(false);
    console.log('@selectedRoles',selectedRoles);
    console.log('@propertyLocation',propertyLocation);
    console.log('@propertyLocations',propertyLocations);
    const [editorData,setEditorData] = useState("");

    const handleSelectChange = (selectedRoles) => {
        const selectedOptions = selectedRoles?.map((row)=>({
            role_id:row?.value
        }));

        setSelectedRoles(selectedRoles);
        
        setPropertyLocation({...propertyLocation,roles:selectedOptions});
        
    }


    const getRoles = async () => {
      const response = await ApiRequest.get('/public/api/auth/get-all-roles');
      if (response.status === 200) {
        const allRole = response?.data?.data?.roles?.map((row) => ({
          value: row?.id,
          label: row?.role,
        }));
        const filterRole = allRole?.filter(role => role.label !== 'Super Admin' && role.label !== "Owner-Unlimited" && role.label !== "Owner" && role.label !== "Renter" && role.label !== "Issue Tracker Staff" && role.label !== "Co-Tenant");
        setRole(filterRole);
        setSelectedRolesTable(response?.data?.data?.roles)
      }
    };
    function removeCurrencyAndCommas(str) {
      if (str === null) {
        return '';
      }
      // Remove currency symbols ($, €, £, ¥, etc.)
      str = str.replace(/[$€£¥]/g, '');
      
      // Remove commas
      str = str.replace(/,/g, '');
      
      // Return the modified string
      return str;
    }
    function convertCurrencyToFormat(currency, number) {
      let formattedCurrency = Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
      }).format(number);
    
      return formattedCurrency;
    }
    
    
    
      const notes = ()=>{
        const data = [];
        data.push(propertyLocation);
        const array = [...data];
        // console.log('@array',array);
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[0].description = cleanText;
        setPropertyLocation(array);
      }
      const bulkData = ()=>{
        notes();
        const newDataArray  = [];
        newDataArray.push(propertyLocation);
        setShowForm(false)
        handleLocationTrue(prevArray => [...prevArray, ...newDataArray])
        setPropertyLocations(prevArray => [...prevArray, ...newDataArray]);
        setPropertyLocation(initialPropertyLocationData);
        setEditorData('');
        setSelectedRoles([])
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...propertyLocations];
        array[indexEdit] = propertyLocation;
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[indexEdit].description = cleanText;
        setPropertyLocations(array);
        setShowForm(false)
        handleLocationTrue(array);
        setIndexEdit(undefined);
        setPropertyLocation(initialPropertyLocationData);
        setSelectedRoles([])
      }
      const indexFind = (index) => {
        let data = selectedRolesTable?.map((row) => ({
          value: row?.id,
          label: row?.role,
        }));
      
        let dataRole = propertyLocations[index]?.roles?.map((role) => {
          const matchingData = data.find((value) => role.role_id === value?.value);
          return matchingData ? { value: matchingData.value, label: matchingData.label } : null;
        });
      
        setSelectedRoles(dataRole);
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setPropertyLocation(propertyLocations[index]);
        setEditorData(propertyLocations[index]?.description);
      }
      
      
      const viewData = (index)=>{
        setShowForm(false)
        setPropertyLocation(propertyLocations[index]);
        setEditorData(propertyLocations[index]?.description);
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...propertyLocations];
        newArray.splice(index, 1);
        setPropertyLocations(newArray);
        setPropertyLocation(initialPropertyLocationData);
        handleLocationTrue(newArray);
      }
      const columns = [
        {
          name: "Name",
          selector: (data) => data.location,
          sortable: true,
        },
        {
          name: "Description",
          selector: (data) => data.description,
          sortable: true,
        },
        {
          name: "Location Reserved",
          selector: (data) => data.can_reserve == 1 ? 'Yes':'No',
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
              {/* <div className="flex"> */}
                {hiddenData != true ? (
                  <div className="flex">
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-bs-target={`#ViewPropertyLocationForm`}
                      data-bs-toggle='modal'
                      data-index={index}
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0 mx-3"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        indexFind(index);
                      }}
                    >
                      <img src="/assets/img/system-edit.png" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        deleteData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-trashcan.svg" />
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-index={index}
                      data-bs-target={`#ViewPropertyLocationForm`}
                      data-bs-toggle='modal'
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                  </>
                )}
              {/* </div> */}

              {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
            </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : propertyLocations,
      };
    useEffect(() => {
      setPropertyLocation(initialPropertyLocationData);
      setPropertyLocations(data);
      // setOwnerSetting(ownerSettings);
      getRoles();
    }, [])
    
  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewPropertyLocationForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewPropertyLocationFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setPropertyLocation([]);
                    setEditorData(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? 
          <div className='mb-6'>
    
          {propertyLocation?.name != '' ?<div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name:                  
                  </label>
                </div>
                <div className="md:w-4/5">
                  {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-location-name"
                    type="text"
                    placeholder="Location Name"
                    value={propertyLocation.location}
                    disabled
                    onChange={(e) => {
                      setPropertyLocation({
                        ...propertyLocation,
                        location: e.target.value,
                      });
                    }}
                  /> */}
                  <span>{propertyLocation.location}</span>
                </div>
              </div>:null}
              {editorData!=''?<div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  {/* <CKEditor
                    initData={editorData}
                    // onChange={(e) => {
                    //   setEditorData( e.editor.getData());
                    // }}
                    config={{
                      readOnly:true,
                    }}
                  /> */}
                  <span>{editorData}</span>
                </div>
              </div>:null}
              {propertyLocation?.can_reserve == 1 ?<div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        This Location Can be Reserved:
                      </label>
                      {/* <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.can_reserve}
                          checked={propertyLocation?.can_reserve == 1}
                          disabled
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            setPropertyLocation({...propertyLocation,can_reserve:isChecked});
                          }}
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label> */}
                      {propertyLocation.can_reserve == 1 ? <i className='fa fa-check'></i> : <i className='fa fa-remove'></i>}
                    </div>

                      {propertyLocation?.can_reserve != 0 ?<div className="w-full">
                        <label className="input-label text-gray-700 font-bold">
                          By:
                        </label>

                        {/* <Select
                        className='w-full'
                        options={role}
                        value={selectedRoles}
                        onChange={handleSelectChange}
                        isMulti
                        isDisabled
                        /> */}
                        {propertyLocation?.roles?.map((row, index) => {
                          const labels = role
                            ?.filter((role) => role.value === row?.role_id)
                            .map((role) => role.label);

                          return <span key={index}>{labels.join(', ')}</span>;
                        })}

                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                      </div>:null}
                  </div>:null}
              {propertyLocation?.can_reserve != 0 ?<>
              <div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        Reservation Requires Approval:
                      </label>
                      {/* <label class="inline-flex relative items-center cursor-pointer"> */}
                        {/* <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.requires_approval}
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            setPropertyLocation({...propertyLocation,requires_approval:isChecked});
                          }}
                        /> */}
                        {propertyLocation?.requires_approval == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i> }
                        {/* <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label> */}
                    </div>

                      <div className="w-1/2">
                        <label className="input-label text-gray-700 font-bold">
                          Deposit:
                        </label>

                        {/* <input
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-type"
                          required={false}
                          placeholder="$100"
                          value={propertyLocation?.deposit}
                          disabled
                          onChange={(e) => {
                            setPropertyLocation({...propertyLocation,deposit:parseInt(e.target.value)})
                          }}
                        /> */}
                        <span className='w-1/2'>{propertyLocation?.deposit ? convertCurrencyToFormat(ownerSettings?.currency_format, propertyLocation?.deposit.toFixed(2)) : ''}</span>
                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                        
                      </div>
                  </div>
              <div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}
            <div className="w-1/2">
                        <label className="input-label text-gray-700 font-bold">
                          Fee:
                        </label>

                        {/* <input
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-type"
                          required={false}
                          placeholder="$100"
                          value={propertyLocation?.fee}
                          disabled
                          onChange={(e) => {
                            setPropertyLocation({...propertyLocation,fee:parseInt(e.target.value)})
                          }}
                        /> */}
                        <span className='w-1/2'>{propertyLocation?.fee ? convertCurrencyToFormat(ownerSettings?.currency_format, propertyLocation?.fee.toFixed(2)) : ''}</span>
                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                        </div>

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        Set Different Options Based on User Role:
                      </label>
                      {/* <label class="inline-flex relative items-center cursor-pointer"> */}
                        {/* <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.different_options_per_role}
                          checked={propertyLocation?.different_options_per_role == 1}
                          disabled
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            const dataRoleBasis = selectedRoles.map((row)=>({
                              role_id: row.value,
                              requires_approval: '',
                              deposit: '',
                              fee: ''
                          }));
                            setPropertyLocation({...propertyLocation,different_options_per_role:isChecked,roles_basis:dataRoleBasis});
                          }}
                        /> */}
                        {propertyLocation?.different_options_per_role == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i>  }
                        {/* <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label> */}
                    </div>

                      
                  
          </div>
          </>:null}     
          {propertyLocation?.different_options_per_role != 0 ?
          <>
                    {propertyLocation?.roles?.map((row,index)=>(
                      <>
                      <label className="input-label text-gray-700 font-bold mt-3">
                      For {role.map((role)=>{
                        if(role.value == row?.role_id)
                        {
                          return role.label;
                        }
                      })}:
                    </label>
                    <div className="md:flex md:items-center mt-3">
                    <input type="hidden" value={propertyLocation?.roles_basis[index]?.row?.role_id}/>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <label className="input-label text-gray-700 font-bold">
                        Reservation Requires Approval:
                      </label>
                      {/* <label class="inline-flex relative items-center cursor-pointer"> */}
                        {/* <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.roles_basis[index]?.requires_approval}
                          checked={propertyLocation?.roles_basis[index]?.requires_approval == 1}
                          disabled
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            const newRolesBasis = [...propertyLocation.roles_basis];
                            newRolesBasis[index] = { ...newRolesBasis[index], requires_approval: isChecked };
                            setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                          }}
                        /> */}
                        {propertyLocation?.roles_basis[index]?.requires_approval == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i>  }
                        {/* <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label> */}
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Deposit:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="deposit"
                            type="text"
                            placeholder="$100"
                            required={false}
                            disabled
                            value={propertyLocation?.roles_basis[index]?.deposit}
                            onChange={(e) => {
                                // const isChecked = e.target.checked === true ? 1 : 0;
                                const newRolesBasis = [...propertyLocation.roles_basis];
                                newRolesBasis[index] = { ...newRolesBasis[index], deposit: parseInt(e.target.value) };
                                setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                              }}
                          /> */}
                          <span>{propertyLocation?.roles_basis[index]?.deposit ? convertCurrencyToFormat(ownerSettings?.currency_format, propertyLocation?.roles_basis[index]?.deposit.toFixed(2)) : ''}</span>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                           Fee:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="fee"
                            type="text"
                            placeholder="$100"
                            required={false}
                            disabled
                            value={propertyLocation?.roles_basis[index]?.fee}
                            onChange={(e) => {
                                // const isChecked = e.target.checked === true ? 1 : 0;
                                const newRolesBasis = [...propertyLocation.roles_basis];
                                newRolesBasis[index] = { ...newRolesBasis[index], fee: parseInt(e.target.value) };
                                setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                              }}
                          /> */}
                          <span>{propertyLocation?.roles_basis[index]?.fee ? convertCurrencyToFormat(ownerSettings?.currency_format, propertyLocation?.roles_basis[index]?.fee.toFixed(2)) : ''}</span>
                        </div>
                      </div>
                      </div></>))}
                    </>:null}
                    {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                      <div className="flex justify-end w-full mt-4">
                        <div className="w-1/4 pl-5 ml-5 mt-2 ">
                          <button
                            type="button"
                            className="btn3"
                            onClick={() => {
                              setViewForm(false);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div> */}
                    </div>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setEditorData(null);
                                setPropertyLocation([]);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Properties Locations:
              </label>
            </div>
          </div>
    {showForm == false ? 
          <div className="md:flex flex-column md:items-center w-full m-auto" >
                <div className="flex flex-row-reverse w-full mt-4">
                <div className="w-auto mt-2 ">
                    <button
                    type="button"
                    hidden={hiddenData}
                    className="btn3"
                    onClick={() => {
                     setShowForm(true);
                     setEditorData(" ");
                     setPropertyLocation(initialPropertyLocationData);
                    }}
                    >
                    <i class="fa fa-plus"></i>Add Property Location
                    </button>
                </div>
                </div>
            </div>:null}
          {showForm == true ? 
          <div className='mb-6'>
          <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name: <span>*</span>                  
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-location-name"
                    type="text"
                    placeholder="Location Name"
                    value={propertyLocation?.location}
                    onChange={(e) => {
                      setPropertyLocation({
                        ...propertyLocation,
                        location: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData}
                    onChange={(e) => {
                      setEditorData( e.editor.getData());
                    }}
                  />
                </div>
              </div>
              <div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        This Location Can be Reserved:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.can_reserve}
                          checked={propertyLocation?.can_reserve == 1}
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            setPropertyLocation({...propertyLocation,can_reserve:isChecked});
                          }}
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                      {propertyLocation?.can_reserve != 0 ?<div className="w-full">
                        <label className="input-label text-gray-700 font-bold">
                          By:
                        </label>

                        <Select
                        className='w-full'
                        options={role}
                        value={selectedRoles}
                        onChange={handleSelectChange}
                        isMulti
                        />
                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                      </div>:null}
                  </div>
              {propertyLocation?.can_reserve != 0 ?<>
              <div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        Reservation Requires Approval:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.requires_approval}
                          disabled={disabled}
                          checked={propertyLocation?.requires_approval == 1}
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            setPropertyLocation({...propertyLocation,requires_approval:isChecked});
                          }}
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                      <div className="w-full">
                        <label className="input-label text-gray-700 font-bold">
                          Deposit:
                        </label>

                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-deposit"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={propertyLocation?.deposit != null ? propertyLocation?.deposit : ""}
                          disabled={disabled}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setPropertyLocation({ ...propertyLocation, deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setPropertyLocation({ ...propertyLocation, deposit: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                        
                      </div>
                  </div>
              <div className="form-input-wrapper mt-3">
                    {/* <div className="w-full border-2">
							
						</div> */}
            <div className="w-full">
                        <label className="input-label text-gray-700 font-bold">
                          Fee:
                        </label>

                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={propertyLocation?.fee != null ? propertyLocation?.fee : ""}
                          disabled={disabled}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setPropertyLocation({ ...propertyLocation, fee: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setPropertyLocation({ ...propertyLocation, fee: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                          {/* {propertyParkingSpaces.map((propertyType) => (
                                              <option value={propertyType?.id}>
                                                {propertyType?.space_number}
                                              </option>
                                            ))} */}
                        </div>

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        Set Different Options Based on User Role:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.different_options_per_role}
                          checked={propertyLocation?.different_options_per_role == 1}
                          onChange={(e) => {
                            const role_basis_data = selectedRoles.map((role)=>{
                              return {
                                role_id:role.value,
                                requires_approval:'',
                                fee:'',
                                deposit:'',
                              }
                            })
                            const isChecked = e.target.checked === true ? 1 : 0;
                            setDisabled(e.target.checked);
                            const data = isChecked ? role_basis_data : [];
                            setPropertyLocation({...propertyLocation,different_options_per_role:isChecked,fee:0,deposit:0,requires_approval:0,roles_basis:data});
                          }}
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                      
                  
          </div>
          </>:null}     
          {propertyLocation?.different_options_per_role != 0 ?
          <>
                    {propertyLocation?.roles?.map((row,index)=>(
                      <>
                      <label className="input-label text-gray-700 font-bold mt-3">
                          For {role.map((role)=>{
                            if(role.value == row?.role_id)
                            {
                              return role.label;
                            }
                          })}:
                        </label>
                    <div className="md:flex md:items-center mt-3">
                        
                    <input type="hidden" value={propertyLocation?.roles_basis[index]?.row?.role_id}/>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <label className="input-label text-gray-700 font-bold">
                        Reservation Requires Approval:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          class="sr-only peer"
                          value={propertyLocation?.roles_basis[index]?.requires_approval}
                          checked={propertyLocation?.roles_basis[index]?.requires_approval == 1}
                          onChange={(e) => {
                            const isChecked = e.target.checked === true ? 1 : 0;
                            const newRolesBasis = [...propertyLocation.roles_basis];
                            newRolesBasis[index] = { ...newRolesBasis[index], requires_approval: isChecked };
                            setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                          }}
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Deposit:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-role-deposit"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={propertyLocation?.roles_basis[index]?.deposit != null ? propertyLocation?.roles_basis[index]?.deposit : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            const newRolesBasis = [...propertyLocation.roles_basis];
                            newRolesBasis[index] = { ...newRolesBasis[index], deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) };
                            setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;

                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);

                              // Update the input value and move the cursor position
                              const newRolesBasis = [...propertyLocation.roles_basis];
                              newRolesBasis[index] = { ...newRolesBasis[index], deposit: updatedValue };
                              setPropertyLocation({ ...propertyLocation, roles_basis: newRolesBasis }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />

                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="deposit"
                            type="text"
                            placeholder="$100"
                            required={false}
                            value={propertyLocation?.roles_basis[index]?.deposit ? convertCurrencyToFormat(ownerSettings?.currency_format, propertyLocation?.roles_basis[index]?.deposit.toFixed(2)) : ''}
                            onChange={(e) => {
                                // const isChecked = e.target.checked === true ? 1 : 0;
                                const newRolesBasis = [...propertyLocation.roles_basis];
                                newRolesBasis[index] = { ...newRolesBasis[index], deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) };
                                setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                              }}
                          /> */}
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                           Fee:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-role-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={propertyLocation?.roles_basis[index]?.fee != null ? propertyLocation?.roles_basis[index]?.fee : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            const newRolesBasis = [...propertyLocation.roles_basis];
                            newRolesBasis[index] = { ...newRolesBasis[index], fee: parseFloat(removeCurrencyAndCommas(e.target.value)) };
                            setPropertyLocation({...propertyLocation, roles_basis: newRolesBasis });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;

                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);

                              // Update the input value and move the cursor position
                              const newRolesBasis = [...propertyLocation.roles_basis];
                              newRolesBasis[index] = { ...newRolesBasis[index], fee: updatedValue };
                              setPropertyLocation({ ...propertyLocation, roles_basis: newRolesBasis }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                        </div>
                      </div>
                      </div></>))}
                    </>:null}
                    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  onClick={() => {
                    if (indexEdit !== undefined) {
                      editSpacesData();
                    } else {
                      bulkData();
                    }
                  }}
                >
                  {indexEdit !== undefined ? (
                    <><i className='fa fa-edit'></i>Update</>
                  ) : (
                    <><i className='fa fa-save'></i>Save</>
                  )}
                </button>
              </div>
            </div>
          </div>
                    </div>:null}
         
                    {/* <div className="flex flex-row-reverse mt-3">
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div> */}
      {propertyLocations?.length > 0 ?
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={propertyLocations}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped mt-3"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        :null}
        <hr />

    </>
  )
}
