import React from 'react'
import { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import imgUrl from '../ImageUrl';
import ApiRequest from '../../utility/http';
import Spinner from '../Spinner/Spinner';
export const OwnerSettings = () => {
    const initialOwnerSettingsData = {
        date_format: "",
        time_format: "",
        time_zone_id: "",
        currency_symbol: "",
        currency_format: "",
        phone_number_format: ""
    }
    const [ownerSettings,setOwnerSettings] = useState();
    const [loader,setLoader] = useState();
    const [allTimeZone,setAllTimeZone] = useState([]);

    const getOwnerSettings = async()=>{
        setLoader(undefined)
        const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings')
        if(response.status == 200)
        {
        //   setAllOwnerSettings(response?.data?.data?.settings);
          setOwnerSettings(response?.data?.data?.owners_settings)
          setLoader(response)
        }
      }
    

    const getTimeZone = async()=>{
        const response = await ApiRequest.get("/public/api/auth/get-timezones")
        if(response.status == 200)
        {
            setAllTimeZone(response?.data?.data?.timezones)
        }
    }
    
    //   const indexFind = (id) => {
    //     const editType = allOwnerSettings.find(ownerSettings => ownerSettings.id === id);
    //     // console.log("editType", editType);
        
    //   }
    //   const deleteData =async(id)=>{
    //     const { isConfirmed } = await Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'This will Delete the Material Coverage Unit. Do you want to proceed?',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes',
    //       cancelButtonText: 'Cancel',
    //     });
      
    //     // If user confirms, Inactivate the Nearby
    //     if (isConfirmed) {
    //       setLoader(undefined);
    //       try {
    //         // const ArrayId = [];
    //         // ArrayId.push(id);
    //         const response = await ApiRequest.delete(
    //           `/public/api/auth/delete-material-coverage-unit?id=${id}`
              
    //         );
    //       getOwnerSettings();
      
    //         // Show success message
    //         if(response.status == 200)
    //         {
    //           Swal.fire({
    //           title: 'Success!',
    //           text: 'The Material Coverage Unit have been Deleted.',
    //           icon: 'success',
    //           confirmButtonText: 'OK',
    //         });
    //       }
      
    //       } catch (error) {
    //         // Show error message
    //         Swal.fire({
    //           title: 'Error!',
    //           text: 'An error occurred while Deleting the Material Coverage Unit. Please try again later.',
    //           icon: 'error',
    //           confirmButtonText: 'OK',
    //         });
    //       } finally {
    //         setLoader('');
    //       }
    //     }
    //   }
    // const moveUp = ()=>{
    //     console.log('up');
    // }
    // const moveDown = ()=>{
    //     console.log('Down');
    // }
    // const handleOrderChange = (id, direction) => {
    //     const index = allOwnerSettings?.findIndex(item => item.id === id);
    //     const newListItems = [...allOwnerSettings];
    //     const currentItem = newListItems[index];
    
    //     if (direction === "up" && index > 0) {
    //         const previousItem = newListItems[index - 1];
    //         currentItem.order_number = previousItem.order_number;
    //         previousItem.order_number = index + 1;
    //         newListItems[index - 1] = currentItem;
    //         newListItems[index] = previousItem;
    //     } else if (direction === "down" && index < newListItems.length - 1) {
    //         const nextItem = newListItems[index + 1];
    //         currentItem.order_number = nextItem.order_number;
    //         nextItem.order_number = index + 1;
    //         newListItems[index + 1] = currentItem;
    //         newListItems[index] = nextItem;
    //     }
    
    //     setAllOwnerSettings(newListItems);
    // };
    
    // const sortedData = allOwnerSettings?.sort((a, b) => a.order_number - b.order_number);
      const AddOwnerSettings = async ()=>{

        setLoader(undefined)
        // const sortedFeatures = allOwnerSettings?.sort((a, b) => b.order_number - a.order_number);
        // const lastOrderNumber = sortedFeatures[0]?.order_number + 1;
        // const payload = {
        //   unit:ownerSettings,
        //   order_number:lastOrderNumber
        // }
        const editPayload = {
            date_format: ownerSettings?.date_format,
            time_format: ownerSettings?.time_format,
            time_zone_id: ownerSettings?.time_zone_id,
            currency_symbol: ownerSettings?.currency_symbol,
            currency_format: ownerSettings?.currency_format,
            phone_number_format: ownerSettings?.phone_number_format
        }
        const response = await ApiRequest.post('/public/api/auth/set-current-owner-settings',
        editPayload
        )
        if(response?.status == 200)
        {
          getOwnerSettings();
        //   setOwnerSettings('')
        }
      }
      useEffect(() => {
        setOwnerSettings(initialOwnerSettingsData);
        getTimeZone();
        getOwnerSettings();
      }, [])
      
  return (
    <>
    
        <div class="md:flex md:items-center w-full mt-4 mx-2">
          <div class="md:w-2/5">
            <label class="block capitalize text-gray-700 font-bold mb-2">
           Owner Settings
            </label>
            <span class="block text-gray-700 font-light mb-2 mr-2">
            Set Date, Time, Number and Currency Formatting
            </span>
          </div>
          <div class="md:flex-1 mr-4 pr-4 mb-3">
            <div class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
              {loader == undefined ? <Spinner/> :
              <div className="flex">
                <form
                onSubmit={AddOwnerSettings}
                >
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Date Format:
                      </label>
                    </div>
                    <div class="md:w-1/2">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettings"
                        type="text"
                        value={ownerSettings?.date_format}
                        placeholder="d-m-y"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,date_format:e.target.value});
                        }}
                      />
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Time Format:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettingsImage"
                        type="text"
                        value={ownerSettings?.time_format}
                        placeholder="H:m:i A"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,time_format:e.target.value});
                        }}
                      />
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Time Zone:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <select
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettingsFile"
                        type="text"
                        value={ownerSettings?.time_zone_id}
                        placeholder="10"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,time_zone_id:parseInt(e.target.value)});
                        }}
                      >
                        <option value="">Select Time Zone</option>
                        {allTimeZone?.map((zone)=>(
                            <option value={zone?.id}>{zone?.timezone}</option>
                        ))}
                        </select>
                    </div>
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Currency Symbol:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettingsImage"
                        type="text"
                        value={ownerSettings?.currency_symbol}
                        placeholder="$"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,currency_symbol:e.target.value});
                        }}
                      />
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Currency Format:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <select
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettingsFile"
                        type="number"
                        value={ownerSettings?.currency_format}
                        placeholder="10"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,currency_format:parseInt(e.target.value)});
                        }}
                      >
                        <option value="">Select Currency Format</option>
                        <option value="000,000.00">000,000.00</option>
                        </select>
                    </div>
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Number Format:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <select
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ownerSettingsFile"
                        type="number"
                        value={ownerSettings?.phone_number_format}
                        placeholder="10"
                        onChange={(e)=>{
                          setOwnerSettings({...ownerSettings,phone_number_format:parseInt(e.target.value)});
                        }}
                      >
                        <option value="">Select Number Format</option>
                        <option value="(000) 000-0000">(000) 000-0000</option>
                        </select>
                    </div>
                  </div>
                    <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                            AddOwnerSettings();
                        }}
                      >
                        Update
                      </button>
                </form>
              </div>}
              
            </div>
          </div>
        </div>
  </>
  )
}
