import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const LeaseMovingTenantForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-property-unit-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Move-in Walkthrough" {...a11yProps(0)} />
          <Tab label="Move-out Notices" {...a11yProps(1)} />
          <Tab label="Move-out Checklist" {...a11yProps(2)} />
          <Tab label="Move-out Walkthrough" {...a11yProps(3)} />
          <Tab label="Vacating" {...a11yProps(4)} />
          <Tab label="Deposit Return" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-in Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex items-center flex-column">
                <div className="w-auto">
                  <button className="btn btn3">View Walkthrough Report</button>
                </div>
                <div className="w-auto flex">
                  <div className="flex mr-4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Date of Walkthrough:
                    </label>
                    <span>MM/DD/YYYY</span>
                  </div>
                  <div className="flex">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Time:
                    </label>
                    <span>Time</span>
                  </div>
                </div>
                <div className="w-auto-flex">
                  <div className="flex">
                    <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Performed By:
                    </label>
                    <span>AGENT_NAME</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-move-out-notice.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Notice:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex mt-3">
                <span>
                  We are sorry to see you go! If you are planning on moving out,
                  please use this form be- low to submit your official notice.
                  Please note, there might be fees/penalties that apply if you
                  are moving out prior to your lease expiration or if you do not
                  give enough notice. See your lease for details. Please note,
                  if you have any co-tenants that are planning to move out as
                  well, they need to submit a separate move-out notice.
                </span>
              </div>
              <div className="w-auto flex flex-column items-center mt-3">
                <div className="flex">
                  <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Lease Expiry Date:
                  </label>
                  <span>MM/DD/YYYY</span>
                </div>
                <div className="flex">
                  <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Days of notice required for move out:
                  </label>
                  <span>Days</span>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2 mr-2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Planned Move Out Date{" "}
                      <span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2 mr-2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reason for Leaving
                      <span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Message<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Request Move-Out Change:
                  </label>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2 mr-2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Change Requested
                      <span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mr-2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      New Move-Out Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Message<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-move-out-checklist.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Checklist:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex mt-3">
                <span>
                  As you prepare to Move-Out, please refer to this checklist to
                  make sure you are all ready for the move-out inspection. This
                  will maximize your Deposit return and ensure you are ready to
                  move onto the next phase of your journey! Also, please make
                  sure you submit your new address so we can mail your deposit
                  return and forward any mail. We wish you all the best
                </span>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/tenant-moving-address.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Move-out Address:
                  </label>
                </div>
              </div>

              <div className="md:flex md:items-center w-full mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.moveout_address1}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address1:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address1: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address2"
                    type="text"
                    placeholder="Unit #5"
                    // value={formData?.moveout_address2}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address2:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address2: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_country"
                      type="text"
                      //   value={formData?.moveout_country}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_country:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_country: e.target.value,
                      //     });
                      //     getStates(e.target.value)
                      //   }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_state"
                      required={false}
                      //   value={formData?.moveout_state}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_state:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_state: e.target.value,
                      //     });
                      //     getCities(e.target.value)
                      //   }}
                    >
                      <option value="">Select State</option>
                      {/* {states?.map((state)=>(
                        <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_city"
                      type="text"
                      //   value={formData?.moveout_city}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     const zip = cities?.map((col) =>{
                      //       if(col?.id == e.target.value)
                      //       {
                      //         return col?.zip_code
                      //       }
                      //     })
                      //     setCreateLog({...createLog,moveout_city:1})
                      //     const filteredArr = zip.filter((value) => value !== undefined);
                      //     const zipCode = filteredArr.join(', ');
                      //     setFormData({
                      //       ...formData,
                      //       moveout_city: e.target.value,
                      //       moveout_zip_code:zipCode
                      //     });
                      //   }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_zip_code"
                      type="text"
                      placeholder="90210"
                      required={false}
                      //   value={formData?.moveout_zip_code}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_zip_code:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_zip_code: e.target.value,
                      //     });
                      //   }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.moveout_number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    // maxLength={ownerSettings?.phone_number_format?.length}
                    // value={formData?.moveout_number}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number: e.target.value,
                    //   });
                    // }}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    maxLength={ownerSettings?.phone_number_format?.length}
                    value={formatPhoneNumber(formData?.moveout_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number:1});
                      setFormData({
                        ...formData,
                        moveout_number: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  } */}
                </div>
                {/* <div className="md:w-1/6">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div> */}
                <div className="md:w-1/5 pl-3">
                  <select
                    required={false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number_type"
                    // value={formData?.moveout_number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number_type:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number_type: e.target.value,
                    //   });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_email"
                    type="email"
                    placeholder="john.doe@email.com"
                    // value={formData?.moveout_email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_email:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_email: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-end.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex items-center flex-column">
                <div className="w-auto">
                  <button className="btn btn3">View Walkthrough Report</button>
                </div>
                <div className="w-auto flex">
                  <div className="flex mr-4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Date of Walkthrough:
                    </label>
                    <span>MM/DD/YYYY</span>
                  </div>
                  <div className="flex">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Time:
                    </label>
                    <span>Time</span>
                  </div>
                </div>
                <div className="w-auto-flex">
                  <div className="flex">
                    <label className="block uppercase tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Performed By:
                    </label>
                    <span>AGENT_NAME</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-vacating.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Vacating:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/assets-keys.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                    Keys Returned:
                  </label>
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/assets-loanable-items.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                    Loanable Items Returned:
                  </label>
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/tenant-moving-address.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Move-out Address:
                  </label>
                </div>
              </div>

              <div className="md:flex md:items-center w-full mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.moveout_address1}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address1:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address1: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address2"
                    type="text"
                    placeholder="Unit #5"
                    // value={formData?.moveout_address2}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address2:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address2: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_country"
                      type="text"
                      //   value={formData?.moveout_country}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_country:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_country: e.target.value,
                      //     });
                      //     getStates(e.target.value)
                      //   }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_state"
                      required={false}
                      //   value={formData?.moveout_state}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_state:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_state: e.target.value,
                      //     });
                      //     getCities(e.target.value)
                      //   }}
                    >
                      <option value="">Select State</option>
                      {/* {states?.map((state)=>(
                        <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_city"
                      type="text"
                      //   value={formData?.moveout_city}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     const zip = cities?.map((col) =>{
                      //       if(col?.id == e.target.value)
                      //       {
                      //         return col?.zip_code
                      //       }
                      //     })
                      //     setCreateLog({...createLog,moveout_city:1})
                      //     const filteredArr = zip.filter((value) => value !== undefined);
                      //     const zipCode = filteredArr.join(', ');
                      //     setFormData({
                      //       ...formData,
                      //       moveout_city: e.target.value,
                      //       moveout_zip_code:zipCode
                      //     });
                      //   }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_zip_code"
                      type="text"
                      placeholder="90210"
                      required={false}
                      //   value={formData?.moveout_zip_code}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_zip_code:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_zip_code: e.target.value,
                      //     });
                      //   }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.moveout_number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    // maxLength={ownerSettings?.phone_number_format?.length}
                    // value={formData?.moveout_number}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number: e.target.value,
                    //   });
                    // }}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    maxLength={ownerSettings?.phone_number_format?.length}
                    value={formatPhoneNumber(formData?.moveout_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number:1});
                      setFormData({
                        ...formData,
                        moveout_number: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  } */}
                </div>
                {/* <div className="md:w-1/6">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div> */}
                <div className="md:w-1/5 pl-3">
                  <select
                    required={false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number_type"
                    // value={formData?.moveout_number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number_type:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number_type: e.target.value,
                    //   });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_email"
                    type="email"
                    placeholder="john.doe@email.com"
                    // value={formData?.moveout_email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_email:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_email: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-security-deposit-return.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Deposit Return:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end items-center w-full mt-4">
              <div className="w-1/12 mt-2 ">
                <img src="/assets/svgs/system-print-with-attachments.svg" className="w-8" alt="" />
              </div>
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center mt-3 w-1/3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Vacated Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                  </div>
                </div>
                <div className="flex items-center mt-3 w-1/3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date Mailed:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {/* <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                  </div>
                </div>
                <div className="flex items-center mt-3 w-1/3">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tracking Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="1234"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="flex mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl italic font-bold mb-2">
                  Deposit Received:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3">
                    <span>04/02/2022</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>AJ Harte</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <hr />
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3"></div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>Total Deposits:</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="flex mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl italic font-bold mb-2">
                  Fund Withheld:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3">
                    <span>John Deo</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>Description</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <hr />
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3"></div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span className="uppercase">Total WithHeld:</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="flex mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl italic font-bold mb-2">
                  Balance:
                </label>
              </div>
              <div className="flex mt-3">
                <span className="mr-1">Total Due Owner_Or_Tenant:</span>
                <span>Balance</span>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
