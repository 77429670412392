import React from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const FinancePayInvoicesForm = () => {
  return (
    <>
      <form>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-3">
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">4-23-2023 Rent (May 2023) $1,600</span>
                </label>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">2-13-2022 Rent (March 2023) $364.23</span>
                </label>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">5-12-2021 Broken Lamp $153.34</span>
                </label>
              </div>
            </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Payment Date:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Method:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reference:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Deposit Info:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Select a different Budget for each invoice being paid:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-column mt-3 space-x-4">
                <label className="block capitalize w-2/5 tracking-wide text-gray-700 font-bold mb-2">
                  Use Payment to pay invoices:
                </label>
                <div className="w-full flex">
                  <label className="flex items-center space-x-2 mr-4 mt-2 text-gray-700">
                    <input
                      type="radio"
                      name="showIn"
                      value="newTab"
                      className="form-radio"
                      //   checked={unitBoard.link_show_in === "newTab"}
                      //   onChange={(e) => {
                      //       setUnitBoard({ ...unitBoard, link_show_in: e.target.value });
                      //     }}
                    />
                    <span>Oldest First</span>
                  </label>
                  <label className="flex items-center  space-x-1 mt-2 text-gray-700">
                    <input
                      type="radio"
                      name="showIn"
                      value="newWindow"
                      className="form-radio"
                      //   checked={unitBoard.link_show_in === "newWindow"}
                      //   onChange={(e) => {
                      //       setUnitBoard({ ...unitBoard, link_show_in: e.target.value });
                      //     }}
                    />
                    <span>Newest First</span>
                  </label>
                </div>
              </div>
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Payment Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Payment Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            
            <div class="flex justify-end items-center mt-3">
              <div class="flex justify-between items-center gap-4">
                <button type="button" class="btn btn3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
