import { createSlice } from "@reduxjs/toolkit";
import { getUnitMeasurement, getUnitMeasurementTypes } from "./Thunk";
const GetUnitMeasurement = createSlice({
  name: "getMeasurementUnit",
  initialState: {
    status: null,
    measurementUnit: {},
   UnitMeasurementTypes:{}
  },
  extraReducers: {
    [getUnitMeasurement.pending]:(state) => {
      state.status = "loading";
    },
    [getUnitMeasurement.fulfilled]: (state,{payload}) => {
      state.status = "success";
      state.measurementUnit = payload;
    },
    [getUnitMeasurement.rejected]: (state, _error) => {
      state.status = "failed";
      state.measurementUnit = {};
    },
    [getUnitMeasurementTypes.pending]:(state) => {
        state.status = "loading";
      },
      [getUnitMeasurementTypes.fulfilled]: (state,{payload}) => {
        state.status = "success";
        state.UnitMeasurementTypes = payload;
      },
      [getUnitMeasurementTypes.rejected]: (state, _error) => {
        state.status = "failed";
        state.UnitMeasurementTypes = {};
      },
  },
});

export default GetUnitMeasurement.reducer;
