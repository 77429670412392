import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../utility/http";
export const properties = createAsyncThunk("auth/properties", async () => {
  try {
    const response = await ApiRequest.get("public/api/auth/get-my-properties");
    return response.data;
  } catch (error) {
    console.log("properties api has error");
  }
});
export const propertiesTypes = createAsyncThunk(
  "auth/propertiesTypes",
  async () => {
    try {
      const response = await ApiRequest.get(
        "public/api/auth/get-all-properties-types"
      );
      return response.data;
    } catch (error) {
      console.log("propertiesTypes api has error");
    }
  }
);


export const showProperty = createAsyncThunk(
  "auth/showPropterty",
  async (id) => {
    try {
      const response = await ApiRequest.get(
       `/public/api/auth/get-property?property_id=${id}`
      );
      return response.data;
    } catch (error) {
      console.log("propertiesTypes api has error");
    }
  }
);
export const editProperty = createAsyncThunk(
  "auth/editPropterty",
  async (id) => {
    try {
      const response = await ApiRequest.get(
       `/public/api/auth/get-property?property_id=${id}`
      );
      return response.data;
    } catch (error) {
      console.log("propertiesTypes api has error");
    }
  }
);