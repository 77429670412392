import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import imgUrl from "../ImageUrl";
export const CarouselWithLightbox = ({data}) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [Files, setFiles] = useState([]);

  const sideBar = () => {
    const imageSidebar = $(".image-sidebar");
    const lightBox = $(".lightbox-container");
    if (imageSidebar.hasClass("open")) {
      imageSidebar.css("display", "none");
      lightBox.css({ width: "100vw" });
    } else {
      imageSidebar.css({
        height: "100vh",
        background: "#6c757d",
        color: "white",
        width: "300px",
        position: "fixed",
        right: "0",
        top: "0",
        overflow: "hidden  scroll",
        display: " block",
        // "grid-template-columns":" repeat(2, 1fr)",
        // "grid-gap":" 10px",
        padding: "10px",
        "padding-bottom": "200px",
        "z-index": "99999999",
      });
      lightBox.css({ width: "calc(100vw - 300px)" });
    }
    imageSidebar.toggleClass("open");
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const selectImage = (index) => {
    setSelectedImageIndex(index);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowLightbox(true);
  };

  const handlePrevClick = () => {
    if (selectedImageIndex === 0) {
      return;
    }

    setSelectedImageIndex(selectedImageIndex - 1);
  };

  const handleNextClick = () => {
    if (selectedImageIndex === Files.length - 1) {
      return;
    }

    setSelectedImageIndex(selectedImageIndex + 1);
  };

  const [scale, setScale] = useState(1);
  const handleZoomIn = () => {
    if (scale <= 2) {
      setScale(scale + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "ArrowRight") {
      // move to next image
      if (Files.length > selectedImageIndex + 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      }
    } else if (e.key === "ArrowLeft") {
      // move to previous image
      if (0 < selectedImageIndex) {
        setSelectedImageIndex(selectedImageIndex - 1);
      }
    } else if (e.key === "+" || e.key === "=") {
      // zoom in
      handleZoomIn();
    } else if (e.key === "-") {
      // zoom out
      handleZoomOut();
    } else if (e.key === "Esc" || e.key === "Escape") {
      // zoom out
      setShowLightbox(false);
    }
  };
  useEffect(() => {
    setFiles(data);
  }, [data]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });
  const [isSlideshowActive, setIsSlideshowActive] = useState(false);
  useEffect(() => {
    const handleSlideshow = () => {
      // move to next image
      // const currentIndex = images.findIndex((image) => image === selectedImage);
      if (selectedImageIndex < Files.length - 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      } else {
        setSelectedImageIndex(0);
      }
    };

    let intervalId;

    if (isSlideshowActive) {
      intervalId = setInterval(handleSlideshow, 3000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedImageIndex, isSlideshowActive]);
  return (
    <>
      <Carousel>
        {Files?.map((image, index) => (
          <div key={index} onClick={(e) => {
            e.preventDefault();
            handleImageClick(index);
          }}>
            <img src={imgUrl+"/"+image?.picture} alt={`Image ${index}`} />
          </div>
        ))}
      </Carousel>

      {showLightbox && (
        <>
          <div className={`image-sidebar`}>
            <div className="image-sidebar-header ">
              {/* <button className="menu-icon" onClick={sideBar}>
              <i className="fa fa-bars"></i>
            </button> */}
              <h2 className="text-white text-center">All Images</h2>
            </div>
            <div
              className="image-sidebar-content"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "10px",
                width: "100%",
              }}
            >
              {Files?.map((image, index) => (
                <img
                  key={index}
                  src={`https://apis.rentblaze.com/public/uploads/${image?.picture}`}
                  alt=""
                  className={`img-sidebar-image ${
                    selectedImageIndex === index
                      ? "border border-slate-50 p-3"
                      : ""
                  }`}
                  onClick={() => selectImage(index)}
                />
              ))}
            </div>
          </div>
          <div className="lightbox-container">
            <div className="lightbox">
              <span>{`${selectedImageIndex + 1}/${Files?.length}`}</span>
              <div className="flex flex-row-reverse justify-between items-center mr-4">
                <div className="flex justify-between items-center gap-4">
                  <button type="button" onClick={handleZoomIn} title="Zoom In">
                    <i className="fa fa-search-plus"></i>
                  </button>
                  <button
                    type="button"
                    onClick={handleZoomOut}
                    title="Zoom Out"
                  >
                    <i className="fa fa-search-minus"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsSlideshowActive(!isSlideshowActive)}
                  >
                    {isSlideshowActive ? (
                      <i className="fa fa-pause"></i>
                    ) : (
                      <i className="fa fa-play"></i>
                    )}
                  </button>
                  <button
                    type="button"
                    className="menu-icon"
                    onClick={sideBar}
                    title="All images"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowLightbox(false)}
                    title="Close"
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
              </div>
              <div className="light">
                <button type="button" onClick={handlePrevClick} title="Prev">
                  <i className="fa fa-angle-left"></i>
                </button>
                <img
                  src={`https://apis.rentblaze.com/public/uploads/${Files[selectedImageIndex]?.picture}`}
                  alt={`Image ${selectedImageIndex}`}
                  style={{ transform: `scale(${scale})` }}
                />
                <button type="button" onClick={handleNextClick} title="Next">
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
