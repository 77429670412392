export class CustomError extends Error {
	constructor({
		name = "Error",
		code = null,
		message = null,
		response = null,
		stack = null,
  }) {
    super();
		this.name = name;
    this.code = code;
		this.message = message;
		this.response = response;
		this.stack = stack;
	}
}
