import { CKEditor } from "ckeditor4-react";
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Swal from "sweetalert2";
import ApiRequest from "../../utility/http";
import imgUrl from "../ImageUrl";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";

export const Notes = ({ data, handleNoteTrue, hiddenData, type }) => {
  const initialNoteData = {
    title:"",
    note:"",
    property_manager_sharing:"",
    staff_sharing:"",
    tenant_sharing:"",
    files:[]
  };
  const fileTypes = useAllowedMimeTypes();
  let fileApi = "/public/api/auth/upload-file";
  const [indexEdit, setIndexEdit] = useState();
  // // console.log('@indexEdit',indexEdit);
  const [bulkAction, setBulkAction] = useState({
    bulk_action: "",
  });
  const [propertyNote, setPropertyNote] = useState([]);
  const [propertyNoteFiles, setPropertyNoteFiles] = useState([]);
  const [propertyNotes, setPropertyNotes] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);



  const fileComponent = ({
    file = null,
    title = null,
    key = 0,
    inputFieldId = "",
    trashAble = false,
    editAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
  }) =>
    file ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
      >
        <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
          <div title={title} key={key} className="block min-w-[600rem] px-2">
            <a href={`${imgUrl}/${file}`} target="_blank">
              {title}
            </a>
          </div>
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
          >
            
            {editAble ? (
              <img
                title="Edit file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/system-edit.png"
                onClick={(e) => {
                  onEditClick();
                }}
                alt=""
              />
            ) : null}
            {trashAble ? (
              <img
                title="Remove file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((input_file) => {
                      if (input_file !== file) {
                        dataTransfer.items.add(input_file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
   

  const notes = () => {
    const data = [];
    data.push(propertyNote);
    const array = [...data];
    // console.log('@array',array);
    const cleanText = new DOMParser().parseFromString(editorData, "text/html")
      .body.textContent;
    array[0].note = cleanText;
    setPropertyNote(array);
  };

  const bulkData = () => {
    // setPropertyNote({...propertyNote,notes:editorData});
    notes();
    const newDataArray = [];
    newDataArray.push(propertyNote);
    handleNoteTrue((prevArray) => [...prevArray, ...newDataArray]);
    setPropertyNotes((prevArray) => [...prevArray, ...newDataArray]);
    setPropertyNote(initialNoteData);
    setEditorData(null);
    setShowForm(false);
    // setBulkSpaces("");
  };

  const editSpacesData = () => {
    const array = [...propertyNotes];
    array[indexEdit] = propertyNote;
    const cleanText = new DOMParser().parseFromString(editorData, "text/html")
      .body.textContent;
    array[indexEdit].note = cleanText;
    setPropertyNotes(array);

    handleNoteTrue(array);
    setShowForm(false);
    setIndexEdit(undefined);
    setPropertyNote(initialNoteData);
    setEditorData(null);
  };
  const indexFind = (index) => {
    // var index = $(this).attr('data-index');
    // var modal = $(this).attr('data-bs-toggle');
    // const findIndex = parkingSpaces.length -1 - index ;
    setIndexEdit(index);
    setViewForm(false);
    setShowForm(true);
    setPropertyNote(propertyNotes[index]);
    setEditorData(propertyNotes[index]?.note);
    // myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    // // console.log("index",findIndex);
    // // console.log("modal",modal);
  };
  const viewData = (index) => {
    setShowForm(false);
    setPropertyNote(propertyNotes[index]);
    setEditorData(propertyNotes[index]?.note);
    setViewForm(true);
  };
  const deleteData = (index) => {
    setShowForm(false);
    setViewForm(false);
    const newArray = [...propertyNotes];
    newArray.splice(index, 1);
    setPropertyNotes(newArray);
    setPropertyNote(initialNoteData);
    handleNoteTrue(newArray);
  };
  const columns = [
    
    {
      name: "Note Name",
      selector: (data) => data.title,
      sortable: true,
    },
    {
      name: "",
      selector: (data) => data.files?.length > 0 ? <img src="/assets/svgs/system-paperclip.png" className='w-1/6' alt="" title='File Uploaded'/> :"",
      sortable: true,
    },
    {
      name: "PM",
      selector: (data) => data.property_manager_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : data.property_manager_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : data.property_manager_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : "",
      sortable: true,
    },
    {
      name: "Staff",
      selector: (data) => data.staff_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : data.staff_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : data.staff_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : "",
      sortable: true,
    },
    {
      name: "Tenants",
      selector: (data) => data.tenant_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : data.tenant_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : data.tenant_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : "",
      sortable: true,
    },

    {
      name: "Options",
      cell: (row, index) => {
        return (
          <>
            {/* <div className="flex"> */}
            {hiddenData != true ? (
              <div className="flex">
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-bs-toggle="modal"
                  data-bs-target={`#${type}`}
                  data-index={index}
                  onClick={(e) => {
                    viewData(index);
                  }}
                >
                  <img src="/assets/svgs/system-view.svg" />
                </button>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0 mx-3"
                  data-bs-target={`#staticBackdrop`}
                  data-index={index}
                  onClick={(e) => {
                    indexFind(index);
                  }}
                >
                  <img src="/assets/img/system-edit.png" />
                </button>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-bs-target={`#staticBackdrop`}
                  data-index={index}
                  onClick={(e) => {
                    deleteData(index);
                  }}
                >
                  <img src="/assets/svgs/system-trashcan.svg" />
                </button>
              </div>
            ) : (
              <>
                <button
                  type="button"
                  class="bg-transparent border-none md:w-1/12 p-0"
                  data-index={index}
                  data-bs-toggle="modal"
                  data-bs-target={`#${type}`}
                  onClick={(e) => {
                    viewData(index);
                  }}
                >
                  <img src="/assets/svgs/system-view.svg" />
                </button>
              </>
            )}
            {/* </div> */}

            {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
          </>
        );
      },
    },
  ];
  const tableData = {
    columns: columns,
    data: propertyNotes,
  };

//   const exportPDF = () => {
//     const unit = "pt";
//     const size = "A4"; // Use A1, A2, A3 or A4
//     const orientation = "portrait"; // portrait or landscape

//     const marginLeft = 40;
//     const doc = new jsPDF(orientation, unit, size);

//     doc.setFontSize(15);

//     const title = "Property Notes";
//     const headers = [
//       [
//         "Title",
//         "Icon",
//         "Note",
//       ],
//     ];

//     const data = propertyNotes.map((elt) => [elt.title,imgUrl+'/'+elt.icon,elt.board]);

//     let content = {
//       startY: 50,
//       head: headers,
//       body: data,
//     };
//     doc.text(title, marginLeft, 40);
//     doc.autoTable(content);
//     doc.save("Property Note.pdf");
//   };
//   const exportCSV = () => {
//     // Get data from data table
//     const TotalData = propertyNotes.map((elt) => [elt.title,imgUrl+'/'+elt.icon,elt.board]);
//     // Convert the data to a CSV string

//     const headers = 
//       [
//         "Title",
//         "Icon",
//         "Note",
//       ]
//     ;
//     // let content = {
//     //   head:headers,
//     //   body:TotalData,
//     // }
//     TotalData.unshift(headers);
//     const csv = Papa.unparse(TotalData);
//     // Create a blob of the data
//     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
//     // Create a link to trigger the download
//     const link = document.createElement("a");
//     link.href = window.URL.createObjectURL(blob);
//     link.setAttribute("download", "PropertyNote.csv");
//     link.click();
//   };

  useEffect(() => {
    setPropertyNote(initialNoteData);
    setPropertyNotes(data);
  }, []);

  return (
    <>
      {/* <div className="md:items-center w-full mt-4">
        <div className="flex">
          <img
            className="logo w-12 mr-5"
            src="/assets/img/properties/system-boards.png"
            alt=""
          />
          <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" title="This is a great place to post House Rules and other information you want everyone in your property to have access to. The Notes will appear on their dashboard.">
            Property Notes:
          </label>
        </div>
      </div> */}

      {showForm == false ? (
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="flex justify-end w-full mt-4">
            <div className="w-auto mt-2 ">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <i class="fa fa-plus"></i> Add Note
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {showForm == true ? (
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Note Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-title"
                      type="text"
                      placeholder="Title"
                      value={propertyNote?.title}
                      onChange={(e) => {
                        setPropertyNote({...propertyNote,title:e.target.value});
                      }}
                    />
                  </div>
          </div>
          <div className="w-full">
            <div className="md:flex mt-3">
              <div className="md:w-1/5"></div>
              <div className="md:w-4/5">
                <CKEditor
                  initData={editorData}
                  onChange={(e) => {
                    // boards[index].board = e.editor.getData();
                    setEditorData(e.editor.getData());
                    // setFormData({ ...formData, boards: boards });
                  }}
                />
              </div>
            </div>

            
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto mt-3">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Add Attachments
                    </span>
                    <input
                      id="property-notes-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyNoteFiles?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyNoteFiles.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyNoteFiles];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyNoteFiles(fileLists);
                                let propertyCopy = [...propertyNoteFiles];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setPropertyNote({ ...propertyNote, files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyNoteFiles?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
                </div>
                <div >
              {propertyNoteFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyNoteFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyNoteFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyNote.files = fileLists;
                                setPropertyNoteFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyNoteFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyNote.files[fileIndex].title = title;
                                    setPropertyNoteFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Sharing:
                    </label>
                  </div>
                  <div className="md:w-2/5  md:items-center flex">
                  <div className="w-1/2">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Property Manager
                      </label>
                    </div>
                    <div className="w-1/2">

                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-property-manager-sharing"
                      type="text"
                      placeholder="Title"
                      value={propertyNote?.property_manager_sharing}
                      onChange={(e) => {
                          setPropertyNote({...propertyNote,property_manager_sharing:e.target.value});
                      }}
                    >
                      <option value="">Select</option>
                      <option value="editor">Editor</option>
                      <option value="view">View</option>
                      <option value="hidden">Hidden</option>
                      </select>
                    </div>
                    
                  </div>

                </div>
                <div className="md:flex md:items-center w-full mt-3">
                <div className="w-1/5"></div>
                <div className="md:w-2/5  md:items-center flex">
                  <div className="w-1/2">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Staff
                      </label>
                    </div>
                    <div className="w-1/2">

                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-staff-sharing"
                      type="text"
                      placeholder="Title"
                      value={propertyNote?.staff_sharing}
                      onChange={(e) => {
                          setPropertyNote({...propertyNote,staff_sharing:e.target.value});
                      }}
                    >
                      <option value="">Select</option>
                      <option value="editor">Editor</option>
                      <option value="view">View</option>
                      <option value="hidden">Hidden</option>
                      </select>
                    </div>
                    
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="w-1/5"></div>
                  <div className="md:w-2/5  md:items-center flex">
                  <div className="w-1/2">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Tenants
                      </label>
                    </div>
                    <div className="w-1/2">

                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-tenant-sharing"
                      type="text"
                      placeholder="Title"
                      value={propertyNote?.tenant_sharing}
                      onChange={(e) => {
                          setPropertyNote({...propertyNote,tenant_sharing:e.target.value});
                      }}
                    >
                      <option value="">Select</option>
                      <option value="editor">Editor</option>
                      <option value="view">View</option>
                      <option value="hidden">Hidden</option>
                      </select>
                    </div>
                    
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex justify-end w-full mt-4">
                <div className="w-auto mt-2 ">
                  <button
                    className="btn3"
                    type="button"
                    onClick={() => {
                      if (indexEdit !== undefined) {
                        editSpacesData();
                      } else {
                        bulkData();
                      }
                    }}
                  >
                    {indexEdit !== undefined ? (
                      <>
                        <i className="fa fa-edit"></i>Update
                      </>
                    ) : (
                      <>
                        {" "}
                        <i className="fa fa-save"></i>Save
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
        </div>
      ) : null}


        <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`${type}`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby={`${type}Label`}
          aria-hidden="true"
        >
          <div class="modal-dialog relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(true);
                    setPropertyNote([]);
                    setEditorData(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  
                  {propertyNote.title != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Note Name:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                          initData={editorData}
                        //   onChange={(e) => {
                        //     // boards[index].board = e.editor.getData();
                        //     setPropertyNote({...propertyNote,board:e.editor.getData()});
                        //     // setFormData({ ...formData, boards: boards });
                        //   }}
                        config={{
                            readOnly:true
                        }}
                        /> */}
                          <span>{propertyNote?.title}</span>
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyNote([]);
                                setPropertyNotePicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {propertyNote.note != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Note:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                          initData={editorData}
                        //   onChange={(e) => {
                        //     // boards[index].board = e.editor.getData();
                        //     setPropertyNote({...propertyNote,board:e.editor.getData()});
                        //     // setFormData({ ...formData, boards: boards });
                        //   }}
                        config={{
                            readOnly:true
                        }}
                        /> */}
                          <span>{editorData}</span>
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyNote([]);
                                setPropertyNotePicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {propertyNote.property_manager_sharing != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            PM:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                          initData={editorData}
                        //   onChange={(e) => {
                        //     // boards[index].board = e.editor.getData();
                        //     setPropertyNote({...propertyNote,board:e.editor.getData()});
                        //     // setFormData({ ...formData, boards: boards });
                        //   }}
                        config={{
                            readOnly:true
                        }}
                        /> */}
                          <span>{propertyNote?.property_manager_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : propertyNote?.property_manager_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : propertyNote?.property_manager_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : ""}</span>
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyNote([]);
                                setPropertyNotePicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {propertyNote.staff_sharing != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Staff:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                          initData={editorData}
                        //   onChange={(e) => {
                        //     // boards[index].board = e.editor.getData();
                        //     setPropertyNote({...propertyNote,board:e.editor.getData()});
                        //     // setFormData({ ...formData, boards: boards });
                        //   }}
                        config={{
                            readOnly:true
                        }}
                        /> */}
                          <span>{propertyNote?.staff_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : propertyNote?.staff_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : propertyNote?.staff_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : ""}</span>
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyNote([]);
                                setPropertyNotePicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {propertyNote.tenant_sharing != "" ? (
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Tenant:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                          initData={editorData}
                        //   onChange={(e) => {
                        //     // boards[index].board = e.editor.getData();
                        //     setPropertyNote({...propertyNote,board:e.editor.getData()});
                        //     // setFormData({ ...formData, boards: boards });
                        //   }}
                        config={{
                            readOnly:true
                        }}
                        /> */}
                          <span>{propertyNote?.tenant_sharing == "editor" ? <img src="/assets/img/system-edit-green.png" className="md:w-1/12 text-red-600" /> : propertyNote?.tenant_sharing == "view" ? <img src="/assets/img/system-view-yellow.png" className="md:w-1/12 text-red-600" /> : propertyNote?.tenant_sharing == "hidden" ? <i className="fa fa-remove text-red-600"></i> : ""}</span>
                        </div>
                      </div>
                      {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                          <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                              className="btn3"
                              type="button"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyNote([]);
                                setPropertyNotePicture([]);
                                setEditorData(null);
                              }}
                            >
                              <i className="fa fa-remove"></i>Close
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}


                </div>
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(true);
                                setPropertyNote([]);
                                setEditorData(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
            </div>
          </div>
        </div>

      {propertyNotes?.length > 0?<>
      <hr />
      {/* <div className="flex flex-row-reverse mt-3">
        <img
          className="logo w-8 mr-3"
          src="/assets/img/properties/system-download-pdf.png"
          alt="PDF"
          onClick={exportPDF}
          style={{ cursor: "pointer" }}
        />
        <img
          className="logo w-8 mr-3"
          src="/assets/img/properties/system-download-csv.png"
          alt="CSV"
          onClick={exportCSV}
          style={{ cursor: "pointer" }}
        />
      </div> */}
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          className="striped"
          data={propertyNotes}
          pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
          fixedHeader
          fixedHeaderScrollHeight="500px"
          highlightOnHover
          sortField={indexEdit}
          sortDirection="asc"
        />
      </DataTableExtensions>
      </>:null}
      {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  );
};
