import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorPage = () => {
    const goBack = ()=>{
        window.history.back();
    }
  return (
    <>
    <div class="w-full h-screen flex flex-col items-center justify-center">
        <div class="flex flex-col items-center justify-center">
            <p class="text-5xl md:text-6xl lg:text-7xl font-bold tracking-wider text-gray-600 mt-8">403</p>
            <p class="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-600 mt-2">Access Denied</p>
            <p class="md:text-lg xl:text-xl text-gray-500 mt-4">Sorry, but you don't have to access this page.</p>
            <a class="md:text-lg xl:text-xl text-gray-500 mt-4 cursor-pointer" onClick={()=>{goBack()}}> <i className='fa fa-arrow-left'></i> Go back to Your Account</a>
        </div>
    </div>
    </>
  )
}
