import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import useAlerts from "../../utility/alerts";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
export const MoveOutEditForm = ({
  //   initialData = {},
  verticalScroll = 0,
  moveOutReasons,
  setMoveOutReasons,
  apiIdMoveOutReasons,
}) => {
  //  CONSTANTS
  console.log("moveOutReasons", moveOutReasons)
  const { id } = useParams();
  const dispatch = useDispatch();
  /////// local states

  // const [reason, setReason] = useState("");

  const [tabValue, setTabValue] = useState(0);
  const Alert = useAlerts();
  //   //  METHODS
  // useEffect(() => {
  //   const getreasons = async () => {
  //     try {
  //       const res = await ApiRequest.get(
  //         "/public/api/auth/get-moveout-reasons"
  //       );
  //       const filteredReason = res?.data?.data?.reasons?.filter((item, i) => {
  //         return item.id == id;
  //       });
  //       console.log(filteredReason);
  //       setReason(() => filteredReason[0]);
  //       // console.log(res.data.data.reasons)
  //     } catch (err) {
  //       console.log("api is not running", err);
  //     }
  //   };
  //   getreasons();
  // }, []);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleSubmitMoveOutReason = async (e) => {
    e.preventDefault();
    try {
      const res = await ApiRequest.post("/public/api/auth/moveout-reason", {
        reason:moveOutReasons,
        id:apiIdMoveOutReasons,
      });
      if (res.status == 200) {
        await Alert.addSuccess("Moveout reason is edited!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* edit moveout reason Form  */}
      {/* <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Moveout reasons"{...a11yProps(0)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>*/}
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitMoveOutReason}
        >
         <div className="md:flex flex-column md:items-center w-100 m-auto">
          <div className="md:flex md:items-center w-full mt-6">
            <div className="md:w-1/3">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Moveout Reason:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/2">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={moveOutReasons}
                  required={true}
                  onChange={(e) => {
                    setMoveOutReasons(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit move out reason"
                hidden={false}
                className="ml-6 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
      {/* </TabPanel> */}
    </>
  );
};
