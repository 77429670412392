import React from 'react'
import { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import imgUrl from '../ImageUrl';
import Spinner from '../Spinner/Spinner';
import ApiRequest from '../../utility/http';
export const PetTypes = () => {
    const [petTypes,setPetTypes] = useState();
    const [petRent,setPetRent] = useState();
    const [petDeposit,setPetDeposit] = useState();
    const [petShots,setPetShots] = useState([]);
    const [loader,setLoader] = useState();
    const [editPetTypes,setEditPetTypes] = useState();
    const [editPetRent,setEditPetRent] = useState();
    const [editPetDeposit,setEditPetDeposit] = useState();
    const [editPetShots,setEditPetShots] = useState([]);
    const [editOrder,setEditOrder] = useState();
    const [editID,setEditID] = useState();
    const [allPetTypes,setAllPetTypes] = useState([]);
    console.warn("PET SHOTS",petShots);
    const getPetTypes = async()=>{
        const response = await ApiRequest.get('/public/api/auth/get-my-pets-types')
        if(response.status == 200)
        {
          setAllPetTypes(response?.data?.data?.types);
          setLoader(response);
        }
      }
      const initialShots = [];
      const addMore = ()=>{
        let newArray = [];
        newArray?.push(initialShots)
        setPetShots(prevArray => [...prevArray, ...newArray]);
      }
      const editMore = ()=>{
        let newArray = [];
        newArray?.push(initialShots)
        setEditPetShots(prevArray => [...prevArray, ...newArray]);
      }
      function convertStringToArray(str) {
        return str.split("___");
      }
      const indexFind = (id) => {
        const editType = allPetTypes.find(petTypes => petTypes.id === id);
        // console.log("editType", editType);
        setEditPetTypes(editType?.type);
        setEditPetRent(editType?.default_rent);
        setEditPetDeposit(editType?.default_deposit);
        setEditPetShots(convertStringToArray(editType?.default_shots));
        setEditOrder(editType?.order_number);
        setEditID(editType?.id);
      }

      const orderChange = (id, direction) => {
        const newListItems = [...allPetTypes];
        const currentItem = newListItems.find(item => item.id === id);
        const currentIndex = newListItems.indexOf(currentItem);
      
        if (direction === "up" && currentIndex > 0) {
          const previousItem = newListItems[currentIndex - 1];
          const newOrder = currentItem.order_number - 1;
          if (previousItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(0, currentIndex).filter(item => item.order_number >= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number += 1;
            });
          }
          previousItem.order_number = newOrder;
          currentItem.order_number = newOrder + 1;
          newListItems[currentIndex - 1] = currentItem;
          newListItems[currentIndex] = previousItem;
        } else if (direction === "down" && currentIndex < newListItems.length - 1) {
          const nextItem = newListItems[currentIndex + 1];
          const newOrder = currentItem.order_number + 1;
          if (nextItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(currentIndex + 1).filter(item => item.order_number <= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number -= 1;
            });
          }
          nextItem.order_number = newOrder;
          currentItem.order_number = newOrder - 1;
          newListItems[currentIndex + 1] = currentItem;
          newListItems[currentIndex] = nextItem;
        }
      
        reArrangeData(newListItems);
        // console.log('newListItems',newListItems);
      };
      
      
      const deleteData =async(id)=>{
        const { isConfirmed } = await Swal.fire({
          title: 'Are you sure?',
          text: 'This will Delete the PetTypes. Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        });
      
        // If user confirms, Inactivate the Nearby
        if (isConfirmed) {
          setLoader(undefined);
          try {
            // const ArrayId = [];
            // ArrayId.push(id);
            const response = await ApiRequest.delete(
              `/public/api/auth/delete-my-pet-type?id=${id}`
              
            );
            // orderChange();
          getPetTypes();
            // Show success message
            if(response.status == 200)
            {
              Swal.fire({
              title: 'Success!',
              text: 'The PetTypes have been Deleted.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          }
      
          } catch (error) {
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'An error occurred while Deleting the PetTypes. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } finally {
            setLoader('');
          }
        }
      }

    const handleOrderChange = (id, direction) => {
        const index = allPetTypes?.findIndex(item => item.id === id);
        const newListItems = [...allPetTypes];
        const currentItem = newListItems[index];
    
        if (direction === "up" && index > 0) {
            const previousItem = newListItems[index - 1];
            currentItem.order_number = previousItem.order_number;
            previousItem.order_number = index + 1;
            newListItems[index - 1] = currentItem;
            newListItems[index] = previousItem;
        } else if (direction === "down" && index < newListItems.length - 1) {
            const nextItem = newListItems[index + 1];
            currentItem.order_number = nextItem.order_number;
            nextItem.order_number = index + 1;
            newListItems[index + 1] = currentItem;
            newListItems[index] = nextItem;
        }
    
        reArrangeData(newListItems);
    };
    const reArrangeData = async(Data)=>{
      setLoader(undefined);
      const response = await ApiRequest.post('/public/api/auth/rearrange-admin-settings-lists',{
          identifier:'types',
          data:Data,
      }
      );
      if(response.status == 200)
      {
          getPetTypes();
      }
  }
    const sortedData = allPetTypes?.sort((a, b) => a.order_number - b.order_number);
    function convertArrayToString(arr) {
      return arr.join("___");
    }
      const AddPetTypes = async ()=>{
        setLoader(undefined)
        const sortedPetTypess = allPetTypes.sort((a, b) => b.order_number - a.order_number);
        const lastOrderNumber = sortedPetTypess[0]?.order_number;
        const payload = {
          type:petTypes,
          default_deposit:petDeposit,
          default_rent:petRent,
          default_shots:convertArrayToString(petShots),
        //   order_number:lastOrderNumber != undefined ? lastOrderNumber + 1 : 1
        }
        const editPayload = {
          id:editID,
          type:editPetTypes,
          default_deposit:editPetDeposit,
          default_rent:editPetRent,
          default_shots:convertArrayToString(editPetShots),
        //   order_number:editOrder
        }
        const response = await ApiRequest.post('/public/api/auth/add-my-pets-type',
        editID == undefined ? payload : editPayload
        )
        if(response?.status == 200)
        {
          getPetTypes();
          setPetTypes('')
          setEditID(undefined)
        }
      }
      useEffect(() => {
        getPetTypes();
        petShots.push(initialShots);
      }, [])
      // const highestOrderNumber = sortedData.reduce((maxOrderNumber, row) => {
      //   return row.order_number > maxOrderNumber ? row.order_number : maxOrderNumber;
      // }, 0);
  return (
    <>
    
        <div class="md:flex md:items-center w-full mt-4 mx-2">
          <div class="md:w-2/5">
            <label class="block capitalize text-gray-700 font-bold mb-2">
                Pet Types:
            </label>
            <span class="block text-gray-700 font-light mb-2 mr-2">
            Lists the Types of Pets allowed in their rentals.
            </span>
          </div>
          <div class="md:flex-1 mr-4 pr-4 mb-3">
            <div class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
              <div className="flex">
                <form>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div className="w-6/12 flex md:items-center">
                      <div class="md:w-1/5">
                        <label class="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div class="md:w-4/5 mr-4">
                        <input
                          class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="petTypes"
                          type="text"
                          value={petTypes}
                          placeholder="Dog"
                          onChange={(e)=>{
                            setPetTypes(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                  <div className="w-6/12 flex md:items-center">
                      <div class="md:w-1/5">
                        <label class="block capitalize text-gray-700 font-bold mb-2">
                          Rent:
                        </label>
                      </div>
                      <div class="md:w-4/5 mr-4">
                        <input
                          class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="petRent"
                          type="number"
                          value={petRent}
                          placeholder="10"
                          onChange={(e)=>{
                            setPetRent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-6/12 flex md:items-center">
                      <div class="md:w-1/4">
                        <label class="block capitalize text-gray-700 font-bold mb-2">
                          Deposit:
                        </label>
                      </div>
                      <div class="md:w-4/5 mr-4">
                        <input
                          class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="petDeposit"
                          type="number"
                          value={petDeposit}
                          placeholder="100"
                          onChange={(e)=>{
                            setPetDeposit(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {petShots?.map((row,index)=>{
                    const shots = [...petShots];
                    return(
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                  <div className="w-6/12 flex md:items-center">
                      <div class="md:w-1/5">
                        <label class="block capitalize text-gray-700 font-bold mb-2">
                          Shot:
                        </label>
                      </div>
                      <div class="md:w-4/5 mr-4">
                        <input
                          class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`shots-${index}`}
                          type="text"
                          value={row}
                          placeholder="Rabies"
                          onChange={(e)=>{
                            shots[index] = e.target.value;
                            setPetShots(shots);
                          }}
                        />
                      </div>
                    </div>
                   
                  </div> )})}
                    <div className="w-6/12 flex md:items-center mt-2">
                    <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                          addMore();
                        }}
                      >
                        Add Shot
                      </button>
                    </div>
                    <div class="md:flex flex justify-end mt-2">
                      <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                          AddPetTypes();
                        }}
                      >
                        Save
                      </button>
                    </div>
                </form>
              </div>
              <hr />
              {loader == undefined ? <Spinner/> :<>
              {allPetTypes?.map((type)=>(
                <>
              <div class="flex justify-between">
                  <div class="flex-initial">
                      <div className="flex">
                      {/* {type?.order_number != 1 ? (
                                <i className="fa fa-sort-up text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(type?.id, 'up')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )}
                            {type?.order_number < highestOrderNumber ? (
                                <i className="fa fa-sort-down text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(type?.id, 'down')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )} */}
                          {/* <img src={`${imgUrl}/${type?.icon}`} alt="Icon" className="logo w-6 mx-2"/> */}
                          <span>{type?.type}</span>
                      </div>
                  </div>
                  <div class="flex-initial">
                      <div className="flex">
                          <img src="/assets/img/system-edit.png" alt="Edit" title="Edit" data-bs-target={`#petTypesModal`} data-bs-toggle="modal" className="logo w-6 mx-2 cursor-pointer" onClick={()=>{
                            indexFind(type?.id)
                          }}/>
                          {/* <img src="/assets/svgs/system-trashcan.svg" alt="Delete" title="Delete" onClick={()=>{deleteData(type?.id)}} className="logo w-6 mx-2 cursor-pointer"/> */}
                      </div>
                  </div>
              </div>
                  <hr />
              </>
              ))}
              </>}
            </div>
          </div>
        </div>
        <div
            class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id={`petTypesModal`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog relative w-auto pointer-events-none">
              <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                  <h5
                    class="text-xl font-medium leading-normal text-gray-800"
                    id="exampleModalLabel"
                  >
                    Edit
                  </h5>
                  <button
                    type="button"
                    class="btn2-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{setEditID(undefined)}}
                  ><i className='fa fa-remove'></i></button>
                </div>
                <div class="modal-body relative p-4">
                 
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-1">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`number-type`}
                    type="text"
                    value={editPetTypes}
                    placeholder="Dog"
                    onChange={(e) => {
                    setEditPetTypes(e.target.value)
                    }}
                   
                  />
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-1">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Rent:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`pet-rent`}
                    type="number"
                    value={editPetRent}
                    placeholder="Dog"
                    onChange={(e) => {
                    setEditPetRent(e.target.value)
                    }}
                   
                  />
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-1">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Deposit:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`pet-deposit`}
                    type="number"
                    value={editPetDeposit}
                    placeholder="Dog"
                    onChange={(e) => {
                    setEditPetDeposit(e.target.value)
                    }}
                   
                  />
                </div>
              </div>
              </div>
              <h4>Shots:</h4>
            {editPetShots?.map((row,index)=>{
              const shots = [...editPetShots];
            return(
            <div className="md:flex md:items-center w-full mt-1">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Shot:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`pet-shots-${index}`}
                    type="text"
                    value={row}
                    placeholder="Rabies"
                    onChange={(e) => {
                    shots[index] = e.target.value;
                    setEditPetShots(shots)
                    }}
                   
                  />
                </div>
              </div>
              </div>)})}
              <div class="md:flex flex justify-end mt-2">
                      <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                          editMore();
                        }}
                      >
                        Add Shot
                      </button>
                    </div>
            {/* <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Icon:
                  </label>
                </div>

                    <label className="bg-blue-500 text-white py-2 px-4 rounded ml-2 cursor-pointer w-28">
                        Add Icon
                        <input
                      accept="image/*"
                      id={`number-type-pic`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // console.log("res", res?.data?.data?.image);
                            // formData?.dl_id_picture = res?.data?.data?.image
                            setEditPetTypesIcon(res?.data?.data?.image);
                            // setDlidPicture(res?.data?.data?.image)
                            // setFeatureIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);

                        // console.log("here 142");
                        // const pictures = [...propertyAssistantPictures];
                        // pictures[index] = getImagesAndURLs(e)[0];
                        // setPropertyAssistantPictures(pictures);
                        // setPropertyContacts(contacts);
                        // setFormData({...formData, contacts : contacts})
                      }}
                    />
                      </label>
                     
              </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <img src={`${imgUrl}/${editPetTypesIcon}`} alt="" />
              </div> */}
          </div>
        </div>
                </div>
                <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  
                  <button
                    type="button"
                    class="btn2"
                    data-bs-dismiss="modal"
                    onClick={()=>{AddPetTypes()}}
                    // disabled={disabled}
                  >
                    Update
                  </button>
                  
                  
                  
                </div>
              </div>
            </div>
          </div>
  </>
  )
}
