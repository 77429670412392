import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { viewPrefix } from "../../store/admin/adminThunk";
import { adminPrefix, adminSuffix } from "../../store/admin/adminThunk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { parkingGetPrefix } from "../../store/admin/parking/thunk";
export const EditPrefixSuffixForm = ({
  //   initialData = {},
  verticalScroll = 0,
  updateFormData,
}) => {
  //  CONSTANTS
  const { id } = useParams();
  const dispatch = useDispatch();
  /////// local states
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  console.log("suffix", suffix);
  const [reason, setReason] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [parkingPrefix, setParkingPrefix] = useState("");
  const [parkingSuffix, setParkingSuffix] = useState("");
console.log("parkingSuffix", parkingSuffix)
console.log("parkingPrefix", parkingPrefix)

  /////// Use selctores
  const prefixes = useSelector((state) => {
    return state.adminPrefix.Prefixes.data?.prefixes;
  });
  const suffixes = useSelector((state) => {
    return state.adminSuffix.Suffixes.data?.suffixes;
  });
  const parkingPrefixes = useSelector((state) => {
    return state.parkingPrefix.parkingPrefixes.data?.prefixes;
  });
  console.log("parkingPrefixes", parkingPrefixes);
  //// filter data
  const filterPrefixes = prefixes?.filter((item) => {
    return item.id == id;
  });
  const filterSuffixes = suffixes?.filter((item) => {
    return item.id == id;
  });
  const filterParkingPrefixes = parkingPrefixes?.filter((item, i) => {
    return item.id == id;
  });

  console.log(filterParkingPrefixes);
  const [tabValue, setTabValue] = useState(0);

  //   //  METHODS
  useEffect(() => {
    dispatch(adminPrefix());
    dispatch(adminSuffix());
    setPrefix(() => filterPrefixes && filterPrefixes[0]?.prefix);
    setSuffix(() => filterSuffixes && filterSuffixes[0]?.suffix);
    setParkingPrefix(
      () => filterParkingPrefixes && filterParkingPrefixes[0].prefix
    );
    dispatch(parkingGetPrefix());
  }, []);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleSubmitNamePrefix = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/name-prefix", { prefix, id });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitNameSuffix = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/name-suffix", { suffix, id });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitParkingPrefix = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/parking-space-prefix", {
        prefix: parkingPrefix,
        id,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitParkingSuffix = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/parking-space-suffix", {
        suffix: parkingPrefix,
        id,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitMoveOutReason = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/moveout-reason", { reason });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitEthnicities = async (e) => {
    e.preventDefault();
    try {
      await ApiRequest.post("/public/api/auth/ethnicity", { ethnicity });
    } catch (err) {
      console.log(err);
    }
  };

 
  return (
    <>
      {/* Add Property Form  */}

      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Name settings" {...a11yProps(0)} />
        <Tab label="Parking space settings" {...a11yProps(1)} />
        <Tab label="Moveout settings" {...a11yProps(2)} />
        <Tab label="Ethnicities settings" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitNamePrefix}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Name Prefix:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={prefix}
                  required={true}
                  onChange={(e) => {
                    setPrefix(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit name prefix"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitNameSuffix}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Name Suffix:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={suffix}
                  onChange={(e) => {
                    setSuffix(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit name suffix"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded	"
              />
            </div>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitParkingPrefix}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Parking Prefix:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={parkingPrefix}
                  onChange={(e) => {
                    setParkingPrefix(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit parking prefix"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitParkingSuffix}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Parking Suffix:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={parkingSuffix}
                  onChange={(e) => {
                    setParkingSuffix(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit parking suffix"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded	"
              />
            </div>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitMoveOutReason}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Moveout reason:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={reason}
                  required={true}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Add moveout reason"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitEthnicities}
        >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Ethnicity:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={ethnicity}
                  required={true}
                  onChange={(e) => {
                    setEthnicity(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Add  Ethnicity"
                hidden={false}
                className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
      </TabPanel>
    </>
  );
};
