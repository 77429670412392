import { createAsyncThunk } from "@reduxjs/toolkit";

import ApiRequest from "../../utility/http";
export const userRolesThunk = createAsyncThunk("auth/Profile", async () => {
  try {
    console.log("here 7 user auth");
    const response = await ApiRequest.get(
      "/public/api/auth/get-all-roles"
    );
    console.log(response?.data);
    return response?.data;
  } catch (error) {
    console.log("userRoles api has error");
  }
});
