import { CKEditor } from 'ckeditor4-react'
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Swal from 'sweetalert2'
import ApiRequest from '../../utility/http'
import imgUrl from '../ImageUrl'
import { Tooltip } from '../Loader/Tooltip';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';
import useAllowedMimeTypes from '../genericFunctions.js/mimeType';
import { DatePicker } from '../genericFunctions.js/DatePicker';

export const PropertyMaterials = ({data,handleMaterialTrue,hiddenData,ownerSettings,PropertyID}) => {
  const initialMaterialData = {
        material_type_id: "",
        material_id: "",
        property_location_id: "",
        last_installed: "",
        installed_by: null,
        install_notes: "",
        pictures: [],
        files: []
  };
  const picTypes = useAllowedPicMimeTypes();
  const fileTypes = useAllowedMimeTypes();
  const formElement = document.getElementById("add-property-form");
  const DateCalculator = (date) => {
    const currentDate = new Date();
    const cDate = new Date(date);
    const diffInMilliseconds = currentDate - cDate;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInMonths / 12;
    const years = Math.floor(diffInYears);
    const months = Math.floor((diffInYears - years) * 12);
    const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
    const StringYear = years === 1 ? "year" : "years";
    const StringMonth = months === 1 ? "month" : "months";
    const StringDay = days === 1 ? "day" : "days";
    var result;
    if (days === 0 && years !== 0 && months !== 0) {
      result = years + " " + StringYear + ", " + months + " " + StringMonth;
    } else if (years === 0 && months !== 0 && days !== 0) {
      result = months + " " + StringMonth + ", " + days + " " + StringDay;
    } else if (months === 0 && years !== 0 && days !== 0) {
      result = years + " " + StringYear + ", " + days + " " + StringDay;
    } else if (days === 0 && months === 0 && years !== 0) {
      result = years + " " + StringYear;
    } else if (days === 0 && years === 0 && months !== 0) {
      result = months + " " + StringMonth;
    } else if (years === 0 && months === 0 && days !== 0) {
      result = days + " " + StringDay;
    } else if (years !== 0 || months !== 0 || days !== 0) {
      result =
        years +
        " " +
        StringYear +
        ", " +
        months +
        " " +
        StringMonth +
        ", " +
        days +
        " " +
        StringDay;
    } else {
      result = "";
    }
    return result;
  };
  const myDivRef = useRef(null);
  const [indexEdit,setIndexEdit ]= useState();
  // // console.log('@indexEdit',indexEdit);
  const [bulkAction , setBulkAction] = useState({
    bulk_action:""
  });

    const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
        const TotalDate = day + "-" + month + "-" + year;
        return TotalDate;
      };
      function stringToDate(dateString) {
        // console.warn("dateString",dateString)
        if (dateString === null || dateString === undefined || dateString >= 0)
          return;
        let parts = dateString.split("-");
        let day = parts[0];
        let month = parts[1]; // months are 0-based
        let year = parts[2];
        let date = year + "-" + month + "-" + day;
        // console.warn("UnformatDate",date)
        return date;
      }
      function ShowFormatDate(data, format) {
        if (data === null || data === undefined)
          return;
        const date = new Date(stringToDate(data));
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
        const year = date.getFullYear();
      
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
      
        format = format.replace('dd', formattedDay);
        format = format.replace('DD', formattedDay);
        format = format.replace('d', day);
        format = format.replace('D', day);
        format = format.replace('mm', formattedMonth);
        format = format.replace('MM', formattedMonth);
        format = format.replace('m', month);
        format = format.replace('M', month);
        format = format.replace('yyyy', year);
        format = format.replace('YYYY', year);
      
        return format;
      }
      function removeCurrencyAndCommas(str) {
        // Remove currency symbols ($, €, £, ¥, etc.)
        str = str.replace(/[$€£¥]/g, '');
        
        // Remove commas
        str = str.replace(/,/g, '');
        
        // Return the modified string
        return str;
      }
      function convertCurrencyToFormat(currency, number) {
        let formattedCurrency = Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
        }).format(number);
      
        return formattedCurrency;
      }
      const imageComponent = ({
        image = {},
        key = 0,
        src = "",
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
        ...restProps
      }) => {
        const confirmEdit = (image) => {
          Swal.fire({
            title: 'Are you sure you want to edit this image?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              // Modify the image object here
              const editedImage = { ...image, title: 'New Title' };
              onEditClick(editedImage);
            }
          });
        };
      
        return image ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
          >
            <div className="relative h-fit w-full">
              <img
                title={image?.title}
                key={key}
                alt="property image"
                className="border block object-contain w-80 h-auto rounded-lg"
                src={src}
              />
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove image"
                    type="button"
                    className="logo w-5 mx-auto my-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((file) => {
                          if (file !== image) {
                            dataTransfer.items.add(file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
                {/* <button
                  type="button"
                  title="Edit image"
                  className="logo w-5 mx-auto my-1"
                  onClick={() => confirmEdit(image)}
                >
                  <img src="/assets/img/system-edit.png" alt="edit" />
                </button> */}
              </div>
            </div>
          </div>
        ) : (
          <img {...restProps} />
        );
      };
      const fileComponent = ({
        file = null,
        title = null,
        key = 0,
        inputFieldId = "",
        trashAble = false,
        editAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
      }) =>
        file ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
          >
            <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
              <div title={title} key={key} className="block min-w-[600rem] px-2">
                <a href={`${imgUrl}/${file}`} target="_blank">
                  {title}
                </a>
              </div>
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
              >
                
                {editAble ? (
                  <img
                    title="Edit file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/system-edit.png"
                    onClick={(e) => {
                      onEditClick();
                    }}
                    alt=""
                  />
                ) : null}
                {trashAble ? (
                  <img
                    title="Remove file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((input_file) => {
                          if (input_file !== file) {
                            dataTransfer.items.add(input_file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null;
       
    const [propertyMaterials, setPropertyMaterials] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    const [propertyMaterial, setPropertyMaterial] = useState([]);
    const [propertyMaterialPictures, setPropertyMaterialPictures] = useState([]);
    const [propertyMaterialFiles, setPropertyMaterialFiles] = useState([]);
    const [propertyMaterialLocations, setPropertyMaterialLocations] = useState(
        []
        );
        const [propertyMaterialTypes, setPropertyMaterialTypes] = useState([]);
        const [propertyAllMaterials, setPropertyAllMaterials] = useState([]);
    
      const [propertyMaterialBrands, setPropertyMaterialBrands] = useState([]);
      const [propertyMaterialIdentifiers, setPropertyMateriaIdentifiers] = useState(
        []
      );
      const [newMaterialType, setNewMaterialType] = useState({
        id: null,
        type: "",
      });
      const [newMaterialLocation, setNewMaterialLocation] = useState({
        id: null,
        location: "",
      });
      const [newMeasuremnetType, setNewMeasuremnetType] = useState({
        id: null,
        type: "",
      });
      const [newMaterialBrand, setNewMaterialBrand] = useState({
        id: null,
        brand: "",
      });
      const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
        id: null,
        identifier: "",
      });
      const [result, setResult] = useState([]);
      const [showForm, setShowForm] = useState(false);
      const [viewForm, setViewForm] = useState(false);
      const [dateTaken, setDateTaken] = useState(new Date());
      const [editorData, setEditorData] = useState("");
      console.warn("result",result);
      console.warn("property Materials",propertyMaterials)
    let imageApi = "/public/api/auth/upload-image";
    let fileApi = "/public/api/auth/upload-file";
    const notes = ()=>{
      const data = [];
      data.push(propertyMaterial);
      const array = [...data];
      // console.log('@array',array);
      const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      array[0].install_notes = cleanText;
      setPropertyMaterial(array);
    }
    const bulkData = ()=>{
      notes();
      // setPropertyMaterial({...propertyMaterial,notes:editorData}); 
      const newDataArray  = [];
      newDataArray.push(propertyMaterial);
      handleMaterialTrue(prevArray => [...prevArray, ...newDataArray]);
      setPropertyMaterials(prevArray => [...prevArray, ...newDataArray]);
      setPropertyMaterial(initialMaterialData);
      setEditorData(null);
      setPropertyMaterialFiles("");
      setPropertyMaterialPictures("");
      setShowForm(false);
      // setBulkSpaces("");
    }
  
  const editSpacesData = () =>{
      const array = [...propertyMaterials];
      array[indexEdit] = propertyMaterial;
      const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      array[indexEdit].install_notes = cleanText;
      setPropertyMaterials(array);
  
      handleMaterialTrue(array);
      setShowForm(false);
      setIndexEdit(undefined);
      setPropertyMaterial(initialMaterialData);
      setEditorData(null)
      setPropertyMaterialFiles([]);
      setPropertyMaterialPictures([]);
    }
    const indexFind = (index)=>
    {
      // var index = $(this).attr('data-index');
      // var modal = $(this).attr('data-bs-toggle');
      // const findIndex = parkingSpaces.length -1 - index ;
      setIndexEdit(index);
      setViewForm(false);
      setShowForm(true);
      setPropertyMaterial(propertyMaterials[index]);
      setPropertyMaterialFiles(propertyMaterials[index]?.files)
      setPropertyMaterialPictures(propertyMaterials[index]?.pictures)
      setEditorData(propertyMaterials[index]?.install_notes)
      myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      // // console.log("index",findIndex);
      // // console.log("modal",modal);
    }
    const viewData = (index)=>{
      setShowForm(false);
      setPropertyMaterial(propertyMaterials[index]);
      setPropertyMaterialFiles(propertyMaterials[index]?.files)
      setPropertyMaterialPictures(propertyMaterials[index]?.pictures)
      setEditorData(propertyMaterials[index]?.install_notes)
      setViewForm(true);
    }
    const deleteData = (index)=>{
      setShowForm(false);
        setViewForm(false);
        setIndexEdit(undefined);
      const newArray = [...propertyMaterials];
      newArray.splice(index, 1);
      setPropertyMaterials(newArray);
      setPropertyMaterial(initialMaterialData);
      handleMaterialTrue(newArray);
    }
  const columns = [
      {
        name: "Name",
        selector: (data) => propertyAllMaterials.find((material)=>material.id == data.material_id)?.name,
        sortable: true,
      },
      {
        name: "Type",
        selector: (data) => propertyMaterialTypes.map((row)=>{
          if(data.material_type_id == row?.id)
          {
            return row?.type
          }
        }),
        sortable: true,
      },
      {
        name: "Location",
        selector: (data) => propertyMaterialLocations.map((row)=>{
          if(data.property_location_id == row?.id)
          {
            return row?.location;
          }
        }),
        sortable: true,
      },
      {
        name: "Brand",
        selector: (data) => propertyAllMaterials.find((material)=>material.id == data.material_id)?.material_brand,
        sortable: true,
      },
      {
        name: "Color",
        selector: (data) => propertyAllMaterials.find((material)=>material.id == data.material_id)?.color,
        sortable: true,
      },
      {
        name: "Identifier",
        selector: (data) => propertyAllMaterials.find((material)=>material.id == data.material_id)?.material_identifier,
        sortable: true,
      },
      {
        name: "Vendor",
        selector: (data) => data.material_vendor_id,
        sortable: true,
      },
      {
        name: "Last Installed",
        selector: 'dateTaken',
        sortable: true,
        cell: (row) => (
          <div className='flex flex-column items-center justify-center'> {/* Added justify-center */}
            <span>
              {row.last_installed !== "" ? ShowFormatDate(row?.last_installed, ownerSetting?.date_format) : ""}
            </span>
            {row?.last_installed ? <span>{DateCalculator(stringToDate(row.last_installed))}</span> : null}
          </div>
        )
      },
      
      // {
      //   name: "Handicap",
      //   selector: (data) => data.handicap,
      // },
      // {
      //   name: "Assignee",
      //   selector : (data) => 'UnAssign'
      // },
      // {
      //   name: "Status",
      //   selector: (row) => row.is_active===1?"Active":"Inactive",
      // },
      {
        name: "Options",
        cell: (row,index) => {
          return (
            <>
              {/* <div className="flex"> */}
                {hiddenData != true ? (
                  <div className="flex">
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0"
                      data-bs-target={`#ViewMaterialForm`}
                      data-bs-toggle = 'modal'
                      data-index={index}
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0 mx-3"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        indexFind(index);
                      }}
                    >
                      <img src="/assets/img/system-edit.png" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        deleteData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-trashcan.svg" />
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-index={index}
                      data-bs-target={`#ViewMaterialForm`}
                      data-bs-toggle = 'modal'
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                  </>
                )}
              {/* </div> */}

              {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
            </>
          );
        },
      },
    ];
  const tableData = {
      columns : columns,
      data : propertyMaterials,
    };
    
    const exportPDF = ()=>{
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
  
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
  
      doc.setFontSize(15);
  
      const title = "Property Materials";
      const headers = [["Name", "Type","Location","Brand","Color","Identifier","Vendor","Last Installed"]];
  
      const data = propertyMaterials.map(material => {
        // Get the material name
        const name = propertyAllMaterials.find((row)=>row.id == material.material_id)?.name;
      
        // Get the material type
        const type = propertyMaterialTypes
          .map(row => row?.id === material.material_type_id ? row?.type : undefined)
          .filter(Boolean);
      
        // Get the material location
        const location = propertyMaterialLocations
          .map(row => row?.id === material.property_location_id ? row?.location : undefined)
          .filter(Boolean);
      
        // Get the material brand
        const brand = propertyAllMaterials.find((row)=>row.id == material.material_id)?.material_brand;
      
        // Get the material color
        const color = propertyAllMaterials.find((row)=>row.id == material.material_id)?.color;
      
        // Get the material identifier
        const identifier = propertyAllMaterials.find((row)=>row.id == material.material_id)?.material_identifier;
      
        // Get the material vendor ID
        const vendorId = material.material_vendor_id;
      
        // Get the date the material was last installed
        const lastInstalled = material?.last_installed ? `${stringToDate(material.last_installed)}\n${DateCalculator(stringToDate(material.last_installed))}` : null;
      
        // Return an array with the material data
        return [name, type, location, brand, color, identifier, vendorId, lastInstalled];
      });
  
      let content = {
        startY: 50,
        head: headers,
        body: data
      };
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Property Materials.pdf")
    }
    const exportCSV = () => {
      // Get data from data table
      const TotalData = propertyMaterials.map(material => {
        // Get the material name
        const name = propertyAllMaterials.find((row)=>row.id == material.material_id)?.name;
      
        // Get the material type
        const type = propertyMaterialTypes
          .map(row => row?.id === material.material_type_id ? row?.type : undefined)
          .filter(Boolean);
      
        // Get the material location
        const location = propertyMaterialLocations
          .map(row => row?.id === material.property_location_id ? row?.location : undefined)
          .filter(Boolean);
      
        // Get the material brand
        const brand = propertyAllMaterials.find((row)=>row.id == material.material_id)?.material_brand;
      
        // Get the material color
        const color = propertyAllMaterials.find((row)=>row.id == material.material_id)?.color;
      
        // Get the material identifier
        const identifier = propertyAllMaterials.find((row)=>row.id == material.material_id)?.material_identifier;
      
        // Get the material vendor ID
        const vendorId = material.material_vendor_id;
      
        // Get the date the material was last installed
        const lastInstalled = material?.last_installed ? `${stringToDate(material.last_installed)}\n${DateCalculator(stringToDate(material.last_installed))}` : null;
      
        // Return an array with the material data
        return [name, type, location, brand, color, identifier, vendorId, lastInstalled];
      });
      // Convert the data to a CSV string
  
      const headers = ["Name", "Type","Location","Brand","Color","Identifier","Vendor","Last Installed"];
      // let content = {
      //   head:headers,
      //   body:TotalData,
      // }
      TotalData.unshift(headers)
      const csv = Papa.unparse(TotalData);
      // Create a blob of the data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      // Create a link to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "Property Materials.csv");
      link.click();
    };
    const getPropertyMaterialTypes = async () => {
        const response = await ApiRequest.get("public/api/auth/get-material-types");
        if (response.status == 200) {
          setPropertyMaterialTypes(response.data?.data);
        }
      };
    const getPropertyAllMaterials = async (id) => {
        
          const response = await ApiRequest.get(`/public/api/auth/get-my-materials?type_id=${id}`);
          if (response.status == 200) {
            setPropertyAllMaterials(response.data?.data);
          }
      };
      const getPropertyMaterialLocations = async () => {
        const response = await ApiRequest.get(
          `/public/api/auth/get-properties-locations?property_id=${PropertyID}`
        );
        if (response.status == 200) {
          setPropertyMaterialLocations(response?.data?.data);
        }
      };
      const getPropertyMaterialBrands = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-brands"
        );
        if (response.status == 200) {
          setPropertyMaterialBrands(response.data?.data);
        }
      };
    
      const getPropertyMateriaIdentifiers = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-identifiers"
        );
        if (response.status == 200) {
          setPropertyMateriaIdentifiers(response.data?.data);
        }
      };
    
      const addEditMaterialType = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-type",
          payload
        );
        if (response.status == 200) {
          getPropertyMaterialTypes();
        }
      };
    
      const addEditMaterialLocation = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/properties-location",
          payload
        );
        if (response.status == 200) {
          getPropertyMaterialLocations();
        }
      };
    
      const addEditMaterialBrand = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-brand",
          payload
        );
        if (response.status == 200) {
          getPropertyMaterialBrands();
        }
      };
    
      const addEditMaterialIdentifier = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-identifier",
          payload
        );
        if (response.status == 200) {
          getPropertyMateriaIdentifiers();
        }
      };
      useEffect(()=>{
        getPropertyMaterialLocations();
      },[PropertyID])
      useEffect(() => {
        getPropertyMateriaIdentifiers();
        getPropertyMaterialBrands();
        getPropertyMaterialLocations();
        getPropertyMaterialTypes();
        setPropertyMaterial(initialMaterialData);
        setPropertyMaterials(data);
        setOwnerSetting(ownerSettings);
      }, [])
      
  return (
    <>
    {/* Add more */}
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewMaterialForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewMaterialFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setPropertyMaterial([]);
                    setPropertyMaterialFiles([]);
                    setPropertyMaterialPictures([]);
                    setEditorData(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? <>
    {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-materials.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Property Materials:
              </label>
            </div>
          </div> */}


                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    {propertyMaterial.material_id != '' ? <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Name:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-name`}
                            type="text"
                            disabled={true}
                            value={propertyMaterial?.name}
                            placeholder="Main Paint"
                            // onChange={(e) => {
                            //   setPropertyMaterial({...propertyMaterial,name:e.target.value});
                            // }}
                          /> */}
                          <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.name}</span>
                        </div>
                      </div>
                    </div>:null}
                    {propertyMaterial.material_type_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-type`}
                          value={
                            propertyMaterial?.material_type_id ||
                            propertyMaterialTypes[0]
                          }
                          disabled={true}
                          // onChange={(e) => {
                          //   const value = parseInt(e.target.value);
                          //   setPropertyMaterial({...propertyMaterial,material_type_id:value});
                          // }}
                        >
                          {propertyMaterialTypes.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select> */}
                        <span>{propertyMaterialTypes?.map((type)=>{
                          if(propertyMaterial?.material_type_id == type?.id)
                          {
                            return type?.type
                          }
                        })}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-type-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-type-field`}
                          type="text"
                          value={newMaterialType.type}
                          hidden={true}
                          placeholder="Jhon"
                          onInput={(e) => {
                            setNewMaterialType({
                              ...newMaterialType,
                              type: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialType.id
                                ? newMaterialType
                                : { type: newMaterialType.type };
                              addEditMaterialType(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>:null}
                    {propertyMaterial.property_location_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Location:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-location`}
                          value={
                            propertyMaterial?.property_location_id ||
                            propertyMaterialLocations[0]
                          }
                          disabled={true}
                          // onChange={(e) => {
                          //   const value = parseInt(e.target.value);
                          //   setPropertyMaterial({...propertyMaterial,property_location_id:value});
                          // }}
                        >
                          {propertyMaterialLocations.map((row) => (
                            <option value={row?.id}>{row?.location}</option>
                          ))}
                        </select> */}
                        <span>{propertyMaterialLocations?.map((row)=>{
                          if(propertyMaterial?.property_location_id == row?.id)
                          {
                            return row?.location
                          }
                        })}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-location-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-location-field`}
                          type="text"
                          value={newMaterialLocation.location}
                          placeholder="Jhon"
                          hidden={true}
                          onInput={(e) => {
                            setNewMaterialLocation({
                              ...newMaterialLocation,
                              location: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialLocation.id
                                ? newMaterialLocation
                                : { location: newMaterialLocation.location };
                              addEditMaterialLocation(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>:null}
                    {propertyMaterial.material_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Brand:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-brand`}
                          value={
                            propertyMaterial?.material_brand_id ||
                            propertyMaterialBrands[0]
                          }
                          disabled={true}
                          // onChange={(e) => {
                          //   const value = parseInt(e.target.value);
                          //   setPropertyMaterial({...propertyMaterial,material_brand_id:value});
                          // }}
                        >
                          {propertyMaterialBrands.map((brand) => (
                            <option value={brand?.id}>{brand?.brand}</option>
                          ))}
                        </select> */}
                        <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.material_brand}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-brand-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-brand-field`}
                          type="text"
                          value={newMaterialBrand.brand}
                          hidden={true}
                          placeholder="Jhon"
                          onInput={(e) => {
                            setNewMaterialBrand({
                              ...newMaterialBrand,
                              brand: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialBrand.id
                                ? newMaterialBrand
                                : { brand: newMaterialBrand.brand };
                              addEditMaterialBrand(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>:null}
                    {propertyMaterial.material_id != '' ? <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Color:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-color`}
                            type="text"
                            value={propertyMaterial?.color}
                            disabled={true}
                            placeholder="Black"
                            // onChange={(e) => {
                              
                            //   setPropertyMaterial({...propertyMaterial,color:e.target.value});
                              
                            // }}
                          /> */}
                          <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.color}</span>
                        </div>
                      </div>
                    </div>:null}
                    {propertyMaterial.material_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Identifier:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-identifier`}
                          value={
                            propertyMaterial?.material_identifier_id ||
                            propertyMaterialIdentifiers[0]
                          }
                          disabled={true}
                          // onChange={(e) => {
                          //   const value = parseInt(e.target.value);
                          //   setPropertyMaterial({...propertyMaterial,material_identifier_id:value});
                          // }}
                        >
                          {propertyMaterialIdentifiers.map((identifier) => (
                            <option value={identifier?.id}>
                              {identifier?.identifier}
                            </option>
                          ))}
                        </select> */}
                        <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.material_identifier}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-identifier-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-identifier-field`}
                          type="text"
                          value={newMaterialIdentifier.identifier}
                          hidden={true}
                          placeholder="Jhon"
                          onInput={(e) => {
                            setNewMaterialIdentifier({
                              ...newMaterialIdentifier,
                              identifier: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialIdentifier.id
                                ? newMaterialIdentifier
                                : {
                                    identifier:
                                      newMaterialIdentifier.identifier,
                                  };
                              addEditMaterialIdentifier(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>:null}
                    {propertyMaterial.material_vendor_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Vendor:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-vendor`}
                          value={propertyMaterial?.material_vendor_id}
                          disabled={true}
                          // onChange={(e) => {
                          //   const value = e.target.value;
                          //   setPropertyMaterial({...propertyMaterial,material_vendor_id:value});
                          // }}
                        >
                          <option value={"vendor_1"}>vendor 1</option>
                          <option value={"vendor_2"}>vendor 2</option>
                          <option value={"vendor_3"}>vendor 3</option>
                        </select> */}
                        <span>{propertyMaterial?.material_vendor_id}</span>
                      </div>
                    </div>:null}
                    {propertyMaterial.last_installed != '' ? <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Installed:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`material-last-installed`}
                          type="date"
                          value={stringToDate(propertyMaterial?.last_installed)}
                          disabled={true}
                          // placeholder="18293213"
                          required={false}
                          // onChange={(e) => {
                          //   const date = formatDate(e.target.value);
                          //   const Result = [...result];
                          //   const TotalDate = DateCalculator(e.target.value);
                          //   console.warn("TotalDate", TotalDate);
                          //   Result[0] = TotalDate;
                          //   console.warn("RESult", Result);
                          //   setResult(Result);
                          //   propertyMaterial.last_installed = date;
                          //   setDateTaken(e.target.value);
                          //   setPropertyMaterial({...propertyMaterial,last_installed: date});
                          // }}
                        /> */}
                        <span>{ShowFormatDate(propertyMaterial?.last_installed,ownerSetting?.date_format)}</span>
                      </div>
                      {propertyMaterial?.last_installed ? (
                        <div className="md:w-2/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            {result[0] !== "" ? (
                              <span className="text-gray-600">
                                {result[0]}
                              </span>
                            ) : null}
                          </label>
                        </div>
                      ) : null}
                    </div>:null}
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Purchased:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`material-last-installed`}
                          type="date"
                          value={stringToDate(propertyMaterial?.last_purchased)}
                          // placeholder="18293213"
                          required={false}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            // const Result = [...result];
                            // const TotalDate = DateCalculator(e.target.value);
                            // console.warn("TotalDate", TotalDate);
                            // Result[0] = TotalDate;
                            // console.warn("RESult", Result);
                            // setResult(Result);
                            propertyMaterial.last_purchased = date;
                            // setDateTaken(e.target.value);
                            setPropertyMaterial({...propertyMaterial,last_purchased: date});
                          }}
                        /> */}
                        <span>{ShowFormatDate(propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.last_purchased,ownerSetting?.date_format)}</span>
                      </div>
                      {/* {propertyMaterial?.last_purchased ? (
                        <div className="md:w-2/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            {result[0] !== "" ? (
                              <span className="text-gray-600">
                                {result[0]}
                              </span>
                            ) : null}
                          </label>
                        </div>
                      ) : null} */}
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Unit Purchased:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-amount`}
                            type="number"
                            value={propertyMaterial?.amount}
                            placeholder="$25"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,amount:e.target.value});
                              
                            }}
                          /> */}
                          <span>{convertCurrencyToFormat(ownerSetting?.currency_format,propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.units_purchased)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Price per Unit:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-price`}
                            type="text"
                            value={propertyMaterial?.price}
                            placeholder="$35"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,price:e.target.value});
                              
                            }}
                          /> */}
                          <span>{convertCurrencyToFormat(ownerSetting?.currency_format,propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.price_per_unit)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Coverage Purchased:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-coverage`}
                            type="text"
                            value={propertyMaterial?.coverage}
                            placeholder="Coverage"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,coverage:e.target.value});
                              
                            }}
                          /> */}
                          <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.coverage_purchased}</span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-1/2 md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Material On Hand:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-material_on_hand`}
                            type="text"
                            value={propertyMaterial?.material_on_hand}
                            placeholder="Material On Hand"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,material_on_hand:e.target.value});
                              
                            }}
                          /> */}
                          <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.material_on_hand}</span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-1/2 md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Coverage On Hand:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          {/* <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-coverage_on_hand`}
                            type="text"
                            value={propertyMaterial?.coverage_on_hand}
                            placeholder="Coverage On Hand"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,coverage_on_hand:e.target.value});
                              
                            }}
                          /> */}
                          <span>{propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.coverage_on_hand}</span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Storage Location:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-identifier`}
                          value={
                            propertyMaterial?.storage_location
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setPropertyMaterial({...propertyMaterial,storage_location:value});
                          }}
                        >
                          <option value="">Select Location</option>
                          {propertyMaterialLocations.map((location) => (
                            <option value={location?.id}>
                              {location?.location}
                            </option>
                          ))}
                        </select> */}
                        <span>{propertyMaterialLocations?.map((location)=>{
                          if(propertyAllMaterials?.find((material)=>material?.id == propertyMaterial?.material_id)?.property_location_id == location?.id)
                          {
                            return location?.location
                          }
                        })}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-storage-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-storage-field`}
                          type="text"
                          value={newMaterialLocation.location}
                          placeholder="Jhon"
                          hidden={true}
                          onInput={(e) => {
                            setNewMaterialLocation({
                              ...newMaterialLocation,
                              location: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialLocation.id
                                ? newMaterialLocation
                                : { location: newMaterialLocation.location };
                              addEditMaterialLocation(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>

                {/* Property Material Pictures */}

              {propertyMaterial.pictures.length > 0 ? 
              <>

                {/* Gallery  */}

                <div className="md:flex flex-column w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Install Pictures:
                    </label>
                  </div>
                  {/* <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload pictures
                      </span>
                      <input
                        accept={picTypes}
                        id={`p-material-pictures`}
                        type="file"
                        multiple={true}
                        hidden={true}
                        disabled={true}
                        // onChange={(e) => {
                        //   let files = e.target.files;
                        //   let newImages = [];
                        //   let maxOrderNumber = 0;
                        
                        //   // Find the highest order number in the current images
                        //   propertyMaterialPictures?.forEach((imageObject) => {
                        //     if (imageObject.order_number > maxOrderNumber) {
                        //       maxOrderNumber = imageObject.order_number;
                        //     }
                        //   });
                        
                        //   // Calculate the total number of images (existing images + new images)
                        //   const totalImages = propertyMaterialPictures.length + files.length;
                        
                        //   // Loop through each file and convert to base64
                        //   files.forEach((file, i) => {
                        //     const reader = new FileReader();
                        //     reader.addEventListener("load", async () => {
                        //       const imgBase64 = reader.result;
                        //       try {
                        //         const res = await ApiRequest.post(imageApi, {
                        //           image: imgBase64,
                        //         });
                        
                        //         // Add the new image to the array with a unique order number
                        //         newImages.push({
                        //           picture: res?.data?.data?.image,
                        //           order_number: maxOrderNumber + i + 1 + propertyMaterialPictures.length,
                        //           url:imgBase64
                        //         });
                        
                        //         // If all new images have been processed, update the state
                        //         if (newImages.length === files.length) {
                        //           let pictureLists = [...propertyMaterialPictures];
                        //           pictureLists = [...pictureLists, ...newImages];
                        //           setPropertyMaterialPictures(pictureLists);
                        //           let materialCopy = [...propertyMaterialPictures];
                        //           materialCopy.pictures = pictureLists.map(
                        //             (picObj) => ({
                        //               picture: picObj.picture,
                        //               order_number: picObj.order_number,
                        //             })
                        //           );
                        //           setPropertyMaterial({ ...propertyMaterial, pictures: materialCopy?.pictures });
                        //         }
                        //       } catch (err) {
                        //         // console.log(err);
                        //       }
                        //     });
                        //     reader.readAsDataURL(file);
                        //   });
                        // }}
                        
                        
                        
                        
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const imagesCount =
                            propertyMaterialPictures?.length || 0;
                          return imagesCount > 1
                            ? imagesCount + " Pictures"
                            : imagesCount == 1
                            ? imagesCount + " Picture"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div> */}
                </div>
              {propertyMaterialPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMaterialPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: false,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMaterialPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                propertyMaterial.pictures = pictureLists;
                                setPropertyMaterialPictures(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setPropertyMaterial({
                                  ...propertyMaterial,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
            </>:null}


                {/* Material Files */}

                {/* Gallery  */}

                {propertyMaterial.files.length > 0 ? <>
                    <div className="md:flex flex-column w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Install Files:
                    </label>
                  </div>
                  {/* <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id={`p-material-files`}
                        type="file"
                        accept={fileTypes}
                        multiple={true}
                        hidden={true}
                        disabled={true}
                        // onChange={(e) => {
                        //   let files = e.target.files;
                        //   let newImages = [];
                        //   let maxOrderNumber = 0;
                        
                        //   // Find the highest order number in the current images
                        //   propertyMaterialFiles?.forEach((imageObject) => {
                        //     if (imageObject.order_number > maxOrderNumber) {
                        //       maxOrderNumber = imageObject.order_number;
                        //     }
                        //   });
                        
                        //   // Calculate the total number of images (existing images + new images)
                        //   const totalImages = propertyMaterialFiles.length + files.length;
                        
                        //   // Loop through each file and convert to base64
                        //   files.forEach((file, i) => {
                        //     const reader = new FileReader();
                        //     reader.addEventListener("load", async () => {
                        //       const fileBase64 = reader.result;
                        //       try {
                        //         const res = await ApiRequest.post(fileApi, {
                        //           file: fileBase64,
                        //         });
                        
                        //         // Add the new image to the array with a unique order number
                        //         newImages.push({
                        //           file: res?.data?.data?.file,
                        //           // order_number: maxOrderNumber + i + 1 + propertyMaterialFiles.length,
                        //           url:fileBase64
                        //         });
                        
                        //         // If all new images have been processed, update the state
                        //         if (newImages.length === files.length) {
                        //           let fileLists = [...propertyMaterialFiles];
                        //           fileLists = [...fileLists, ...newImages];
                        //           setPropertyMaterialFiles(fileLists);
                        //           let materialCopy = [...propertyMaterialFiles];
                        //           materialCopy.files = fileLists.map(
                        //             (picObj) => ({
                        //               file: picObj.file,
                        //               // order_number: picObj.order_number,
                        //             })
                        //           );
                        //           setPropertyMaterial({ ...propertyMaterial, files: materialCopy?.files });
                        //         }
                        //       } catch (err) {
                        //         // console.log(err);
                        //       }
                        //     });
                        //     reader.readAsDataURL(file);
                        //   });
                        // }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyMaterialFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div> */}
                </div>
                  {propertyMaterialFiles.length > 0 ? (
                    <div>
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <div className="container w-full px-3 m-auto">
                          <div
                            id={`p-measurement-documents-block`}
                            className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                          >
                            {propertyMaterialFiles?.map(
                              (fileObject, fileIndex) =>
                                fileComponent({
                                  file: fileObject?.file,
                                  title: fileObject?.title,
                                  key: fileIndex,
                                  inputFieldId: `p-measurement-files`,
                                  trashAble: false,
                                  onTrashClick: () => {
                                    const fileLists = [
                                      ...propertyMaterialFiles,
                                    ];
                                    fileLists.splice(fileIndex, 1);
                                    propertyMaterial.files = fileLists;
                                    setPropertyMaterialFiles(fileLists);
                                  },
                                })
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </div>
               </>:null}

                {editorData != '' ? 
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Install Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                          initData={editorData}
                          config={{
                            readOnly: true,
                          }}
                          // onChange={(e) => {
                          //   setPropertyMaterial({...propertyMaterial,notes:e.editor.getData()});
                          // }}
                        /> */}
                        <span>{editorData}</span>
                      </div>
                    </div>
                  </div>
                </div>:null}
                
          </>
          :null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyMaterial([]);
                                setPropertyMaterialFiles([]);
                                setPropertyMaterialPictures([]);
                                setEditorData(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-materials.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Property Materials:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title='Keep track of the materials you use in your property! Paint finishes/brands/colors and any other material you use can be tracked here! If you want to keep track of materials in a specific unit, the same tool is available under the unit!'
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"Keep track of the materials you use in your property! Paint finishes/brands/colors and any other material you use can be tracked here! If you want to keep track of materials in a specific unit, the same tool is available under the unit!"}/>
            </div>
          </div>
    {showForm == false ?
    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Material
                </button>
              </div>
            </div>
          </div>
          :null}
          {showForm == true ? <>
    {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-materials.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Property Materials:
              </label>
            </div>
          </div> */}


                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                  <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-type`}
                          value={propertyMaterial?.material_type_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setPropertyMaterial({...propertyMaterial,material_type_id:value});
                            getPropertyAllMaterials(e.target.value);
                          }}
                        >
                          <option value="">Select Type</option>
                          {propertyMaterialTypes.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-type-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-type-field`}
                          type="text"
                          value={newMaterialType.type}
                          hidden={true}
                          placeholder="Wall"
                          onInput={(e) => {
                            setNewMaterialType({
                              ...newMaterialType,
                              type: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialType.id
                                ? newMaterialType
                                : { type: newMaterialType.type };
                              addEditMaterialType(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Name:
                          </label>
                        </div>
                        <div className="md:w-1/5">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-name`}
                            value={propertyMaterial?.material_id}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              setPropertyMaterial({...propertyMaterial,material_id:value});
                            }}
                          >
                            <option value="">Select Material</option>
                            {propertyAllMaterials.map((name) => (
                              <option value={name?.id}>{name?.name}</option>
                            ))}
                          </select>
                      </div>
                    </div>
                    
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Location:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-location`}
                          value={
                            propertyMaterial?.property_location_id ||
                            propertyMaterialLocations[0]
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setPropertyMaterial({...propertyMaterial,property_location_id:value});
                          }}
                        >
                          <option value="">Select Location</option>
                          {propertyMaterialLocations.map((row) => (
                            <option value={row?.id}>{row?.location}</option>
                          ))}
                        </select>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-location-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-location-field`}
                          type="text"
                          value={newMaterialLocation.location}
                          placeholder="Location"
                          hidden={true}
                          onInput={(e) => {
                            setNewMaterialLocation({
                              ...newMaterialLocation,
                              location: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialLocation.id
                                ? newMaterialLocation
                                : { location: newMaterialLocation.location };
                              addEditMaterialLocation(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Install by:
                          </label>
                        </div>
                        <div className="md:w-1/5">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-install-by`}
                            value={propertyMaterial?.installed_by}
                            // onChange={(e) => {
                            //   const value = parseInt(e.target.value);
                            //   setPropertyMaterial({...propertyMaterial,installed_by:value});
                            // }}
                          >
                            <option value="">Select Material</option>
                            {/* {propertyAllMaterials.map((name) => (
                              <option value={name?.id}>{name?.name}</option>
                            ))} */}
                          </select>
                      </div>
                    </div>
                    {/* <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Brand:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-brand`}
                          value={
                            propertyMaterial?.material_brand_id ||
                            propertyMaterialBrands[0]
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setPropertyMaterial({...propertyMaterial,material_brand_id:value});
                          }}
                        >
                          <option value="">Select Brand</option>
                          {propertyMaterialBrands.map((brand) => (
                            <option value={brand?.id}>{brand?.brand}</option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-brand-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-brand-field`}
                          type="text"
                          value={newMaterialBrand.brand}
                          hidden={true}
                          placeholder="Brand"
                          onInput={(e) => {
                            setNewMaterialBrand({
                              ...newMaterialBrand,
                              brand: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialBrand.id
                                ? newMaterialBrand
                                : { brand: newMaterialBrand.brand };
                              addEditMaterialBrand(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Color:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-color`}
                            type="text"
                            value={propertyMaterial?.color}
                            placeholder="Black"
                            onChange={(e) => {
                              
                              setPropertyMaterial({...propertyMaterial,color:e.target.value});
                              
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Identifier:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-identifier`}
                          value={
                            propertyMaterial?.material_identifier_id ||
                            propertyMaterialIdentifiers[0]
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setPropertyMaterial({...propertyMaterial,material_identifier_id:value});
                          }}
                        >
                          <option value="">Select Identifier</option>
                          {propertyMaterialIdentifiers.map((identifier) => (
                            <option value={identifier?.id}>
                              {identifier?.identifier}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `p-material-new-identifier-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-new-identifier-field`}
                          type="text"
                          value={newMaterialIdentifier.identifier}
                          hidden={true}
                          placeholder="Identifier"
                          onInput={(e) => {
                            setNewMaterialIdentifier({
                              ...newMaterialIdentifier,
                              identifier: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialIdentifier.id
                                ? newMaterialIdentifier
                                : {
                                    identifier:
                                      newMaterialIdentifier.identifier,
                                  };
                              addEditMaterialIdentifier(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Vendor:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-vendor`}
                          value={propertyMaterial?.material_vendor_id}
                          onChange={(e) => {
                            const value = e.target.value;
                            setPropertyMaterial({...propertyMaterial,material_vendor_id:value});
                          }}
                        >
                          <option value="">Select Vendor</option>
                          <option value={"vendor_1"}>vendor 1</option>
                          <option value={"vendor_2"}>vendor 2</option>
                          <option value={"vendor_3"}>vendor 3</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Installed:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <DatePicker
                          value={stringToDate(propertyMaterial?.last_installed)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            const Result = [...result];
                            const TotalDate = DateCalculator(e);
                            console.warn("TotalDate", TotalDate);
                            Result[0] = TotalDate;
                            console.warn("RESult", Result);
                            setResult(Result);
                            propertyMaterial.last_installed = date;
                            setDateTaken(e);
                            setPropertyMaterial({...propertyMaterial,last_installed: date});
                          }}
                        />
                      </div>
                      {propertyMaterial?.last_installed ? (
                        <div className="md:w-2/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            {result[0] !== "" ? (
                              <span className="text-gray-600">
                                {result[0]}
                              </span>
                            ) : null}
                          </label>
                        </div>
                      ) : null}
                    </div>
                    
                  </div>
                </div>

                {/* Property Material Pictures */}

                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Install Pictures:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload pictures
                      </span>
                      <input
                        accept={picTypes}
                        id={`p-material-pictures`}
                        type="file"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          let newImages = [];
                          let maxOrderNumber = 0;
                        
                          // Find the highest order number in the current images
                          propertyMaterialPictures?.forEach((imageObject) => {
                            if (imageObject.order_number > maxOrderNumber) {
                              maxOrderNumber = imageObject.order_number;
                            }
                          });
                        
                          // Calculate the total number of images (existing images + new images)
                          const totalImages = propertyMaterialPictures.length + files.length;
                        
                          // Loop through each file and convert to base64
                          files.forEach((file, i) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const imgBase64 = reader.result;
                              try {
                                const res = await ApiRequest.post(imageApi, {
                                  image: imgBase64,
                                });
                        
                                // Add the new image to the array with a unique order number
                                newImages.push({
                                  picture: res?.data?.data?.image,
                                  order_number: maxOrderNumber + i + 1 + propertyMaterialPictures.length,
                                  url:imgBase64
                                });
                        
                                // If all new images have been processed, update the state
                                if (newImages.length === files.length) {
                                  let pictureLists = [...propertyMaterialPictures];
                                  pictureLists = [...pictureLists, ...newImages];
                                  setPropertyMaterialPictures(pictureLists);
                                  let materialCopy = [...propertyMaterialPictures];
                                  materialCopy.pictures = pictureLists.map(
                                    (picObj) => ({
                                      picture: picObj.picture,
                                      order_number: picObj.order_number,
                                    })
                                  );
                                  setPropertyMaterial({ ...propertyMaterial, pictures: materialCopy?.pictures });
                                }
                              } catch (err) {
                                // console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                          });
                        }}
                        
                        
                        
                        
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const imagesCount =
                            propertyMaterialPictures?.length || 0;
                          return imagesCount > 1
                            ? imagesCount + " Pictures"
                            : imagesCount == 1
                            ? imagesCount + " Picture"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>

                {/* Gallery  */}

                <div >
              {propertyMaterialPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMaterialPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMaterialPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                propertyMaterial.pictures = pictureLists;
                                setPropertyMaterialPictures(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setPropertyMaterial({
                                  ...propertyMaterial,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>


                {/* Material Files */}

                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Install Files:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id={`p-material-files`}
                        type="file"
                        accept={fileTypes}
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          let newImages = [];
                          let maxOrderNumber = 0;
                        
                          // Find the highest order number in the current images
                          propertyMaterialFiles?.forEach((imageObject) => {
                            if (imageObject.order_number > maxOrderNumber) {
                              maxOrderNumber = imageObject.order_number;
                            }
                          });
                        
                          // Calculate the total number of images (existing images + new images)
                          const totalImages = propertyMaterialFiles.length + files.length;
                        
                          // Loop through each file and convert to base64
                          files.forEach((file, i) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const fileBase64 = reader.result;
                              try {
                                const res = await ApiRequest.post(fileApi, {
                                  file: fileBase64,
                                });
                        
                                // Add the new image to the array with a unique order number
                                newImages.push({
                                  file: res?.data?.data?.file,
                                  title: res?.data?.data?.file,
                                  url:fileBase64
                                });
                        
                                // If all new images have been processed, update the state
                                if (newImages.length === files.length) {
                                  let fileLists = [...propertyMaterialFiles];
                                  fileLists = [...fileLists, ...newImages];
                                  setPropertyMaterialFiles(fileLists);
                                  let materialCopy = [...propertyMaterialFiles];
                                  materialCopy.files = fileLists.map(
                                    (picObj) => ({
                                      file: picObj.file,
                                      title: picObj.title,
                                    })
                                  );
                                  setPropertyMaterial({ ...propertyMaterial, files: materialCopy?.files });
                                }
                              } catch (err) {
                                // console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyMaterialFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>

                {/* Gallery  */}

                <div >
              {propertyMaterialFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMaterialFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMaterialFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyMaterial.files = fileLists;
                                setPropertyMaterialFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMaterialFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyMaterial.files[fileIndex].title = title;
                                    setPropertyMaterialFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>

                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Install Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={editorData}
                          onChange={(e) => {
                            setEditorData(e.editor.getData());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  onClick={() => {
                    if (indexEdit !== undefined) {
                      editSpacesData();
                    } else {
                      bulkData();
                    }
                  }}
                >
                  {indexEdit !== undefined ? (
                    <><i className='fa fa-edit'></i>Update</>
                  ) : (
                    <><i className='fa fa-save'></i>Save</>
                  )}
                </button>
              </div>
            </div>
          </div>
          </>
          :null}
         
              
          <hr />
          {propertyMaterials?.length >0 ?<><div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={propertyMaterials}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection='asc'
        />
        </DataTableExtensions></>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  )
}
