import $ from 'jquery';
import { useEffect } from 'react';

function ScrollToTop() {
//   useEffect(() => {
//     const $scrollButton = $('#scroll-to-top-btn');

//     $(window).scroll(function() {
//       if ($(this).scrollTop() > 20) {
//         $scrollButton.css('opacity', '1');
//       } else {
//         $scrollButton.css('opacity', '0');
//       }
//     });

//     $scrollButton.click(function(e) {
//       e.preventDefault();
//       $('html, body').animate({ scrollTop: 0 }, 500);
//     });
//   }, []);

  return (
    <button type="button" id="scroll-to-top-btn">
      <i className="fa fa-chevron-up"></i>
    </button>
  );
}

export default ScrollToTop;
