import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";


const CommunicationTable = ({userRoles, status}) => {
    // console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
// const [unit,setUnit] = useState([]);
// const [loader,setLoader]= useState();
// const showUnit = async ()=>{
//     const response = await ApiRequest.get(
//         "public/api/auth/get-my-tenants"
//     );
//     setLoader(response.status)
//     if(response.status == 200)
//     {
//         setUnit(response?.data?.data)
//         console.log("Response table",response);
//     }
// }
// useEffect(() => {
//   showUnit();
// }, [])

const data = [
    "131231",
    'nvvxcvsd',
    '1234323',
    'dfsdfsd',
    '534534',

]

  const columns = [
    {
      name: "Message ID",
      selector : (data) => "message"
      
    },
    {
      name: "Sent",
      selector : (data) => 'lastname'
    },
    {
      name: "Sender",
      selector : (data) => 'firstname'
    },
    {
      name: "Recipient",
      selector : (data) => "4/11/01"
    },
    {
      name: "Subject",
      selector : (data) => "Tenant"
    },
    {
      name: "Viewed",
      selector : (data) => "Room A7"
    },
    // {
    //   name: "Options",
    //   cell: (data) => {
    //     return (
    //       <>
    //            <Link to={`/edit-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
    //           <i class="fa-solid fa-pen mr-6"></i>
    //           <span>Edit</span>
    //         </button>
    //         </Link>
    //         <Link to={`/show-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
    //             <i class="fa-regular fa-eye mr-6"></i>
    //             <span>View</span>
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

//   const data =[
//     {
//         id:"355821647853265",
//         sent:"5/24/2022 TIME",
//         sender:"Sapp, Sean (UNIT A)",
//         recipient:"SYSTEM",
//         subject:"Question on Rent Due Date",
//         viewed:"5/24/2022 TIME",
//       },
//     {
//         id:"745852146932145",
//         sent:"5/24/2022 TIME",
//         sender:"SYSTEM",
//         recipient:"Sapp, Sean (UNIT A)",
//         subject:"Information requested about the pool ",
//         viewed:"Not Viewed",
//       },
//     {
//         id:"985632452185635",
//         sent:"5/24/2022 TIME",
//         sender:"Knabenshue, Chris(OWNER)",
//         recipient:"SYSTEM",
//         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
//         viewed:"Not Viewed",
//       },

//   ];
  
const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelected = (state) => {
  setSelectedRows(state.selectedRows);
};

const handleLogSelectedRows = () => {
  console.log(selectedRows);
};
 

  return (
    <>
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
          onSelectedRowsChange={handleRowSelected}
        />
        <button onClick={handleLogSelectedRows}>Log Selected Rows</button>
        {/* <div class="flex justify-between justify-items-center text-center gap-x-8 mt-3 mb-4">
            <div class="flex-none ">
              {" "}
              <button class="bg-gray-300 px-4 flex justify-center align-items-center rounded text-black-800">
                <i class="fa-solid fa-bolt text-2xl	 mr-4"></i>
                <span>Bulk Actions</span>
              </button>
            </div> */}

            {/* <div class="bg-indigo-300 p-2 rounded  unit-wrapper">
              <div class="flex flex-wrap gap-x-3">
                <p class="flex-auto">
                  Total Active Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Active Rented Units:{" "}
                  <span class="text-green-600">28</span>
                </p>
                <p class="flex-auto">
                  Total Active Unrented Units:{" "}
                  <span class="text-red-600">28</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Rented Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Unrented Units: <span>28</span>
                </p>
              </div>
            </div> */}
          {/* </div> */}
        </>
  );
};

export default CommunicationTable;
