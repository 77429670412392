import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import Swal from 'sweetalert2';
import imgUrl from '../ImageUrl';
import CurrencyInput from '../genericFunctions.js/CurrencyInput';

export const Employments = ({data,handleEmploymentTrue,hiddenData,ownerSettings}) => {
    const initialEmploymentData = {
        company_name: "",
        position: "",
        annual_salary: "",
        start_year: "",
        end_year: "",
        present: '',
        address_line1: "",
        address_line2: "",
        country: "",
        city: "",
        state: "",
        zip_code: "",
        website: "",
        supervisor: "",
        number: "",
        number_type: "",
        leaving_reason: "",
        notes: "",
      };
    const [indexEdit,setIndexEdit ]= useState();
    const [ownerSetting,setOwnerSetting ]= useState();
    const [editorData,setEditorData ]= useState("");
    const [editorData1,setEditorData1 ]= useState("");
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [showForm,setShowForm] = useState(false);
    const [viewForm,setViewForm] = useState(false);
    const [tenantEmployment, setTenantEmployment] = useState([]);
    const [tenantEmployments, setTenantEmployments] = useState([]);
    console.log('tenantEmployment',tenantEmployments);
    const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const getCountries = async () => {
      const response = await ApiRequest.get("public/api/auth/get-countries");
      if (response.status == 200) {
        setCountries(response.data?.data?.countries);
      }
    };
    const getStates = async (id) => {
      const response = await ApiRequest.get(`public/api/auth/get-states?country_id=${id}`);
      if (response.status == 200) {
        setStates(response.data?.data?.states);
      }
    };
    const getCities = async (data) => {
      const state = states?.map((col) =>{
        if(col?.id == data)
        {
          return col?.state_code
        }
      })
      const filteredArr = state.filter((value) => value !== undefined);
      const stateCode = filteredArr.join(', ');
    
      
      const response = await ApiRequest.get(`public/api/auth/get-cities?state_code=${stateCode}`);
      
      if (response.status == 200) {
        setCities(response.data?.data?.cities);
      }
    };
    const [city,setCity] = useState([]);
    const [zip,setZip] = useState([]);
    const [state,setState] = useState([]);
    const getState = async (id) => {
      const response = await ApiRequest.get(`/public/api/auth/get-state?id=${id}`)
      if(response?.status == 200)
      {
        setState(response?.data?.data?.state?.state);
      }
    }
    const getCity = async (id) => {
      const response = await ApiRequest.get(`/public/api/auth/get-city?id=${id}`)
      if(response?.status == 200)
      {
        setCity(response?.data?.data?.city?.city);
        setZip(response?.data?.data?.zip_code)
      }
    }
      
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 80;
    const years = [...Array(currentYear - startYear + 1).keys()]
      .map((x, i) => i + startYear)
      .reverse();

      function removeNonNumeric(inputString) {
        var numericString = inputString.replace(/\D/g, "");
        return numericString;
      }
      const formatPhoneNumber = (number, format) => {
        const digitsOnly = number?.replace(/\D/g, '') || '';
        let formattedNumber = '';
        let digitIndex = 0;
      
        for (let i = 0; i < format?.length; i++) {
          if (format[i] === '#') {
            formattedNumber += digitsOnly[digitIndex] || '';
            digitIndex++;
          } else if (format[i] === '_') {
            formattedNumber += ' ';
          } else {
            formattedNumber += format[i];
          }
        }
      
        return formattedNumber;
      };
      function removeCurrencyAndCommas(str) {
        if (str === null) {
          return '';
        }
        // Remove currency symbols ($, €, £, ¥, etc.)
        str = str.replace(/[$€£¥]/g, '');
        
        // Remove commas
        str = str.replace(/,/g, '');
        
        // Return the modified string
        return str;
      }
      function convertCurrencyToFormat(currency, number) {
        let formattedCurrency = Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
        }).format(number);
      
        return formattedCurrency;
      }
    const myDivRef = useRef(null);
    const notes = ()=>{
      const data = [];
      data.push(tenantEmployment);
      const array = [...data];
      // console.log('@array',array);
      // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
      array[0].leaving_reason = editorData ;
      array[0].notes = editorData1;
      setTenantEmployment(array);
    }
    const bulkData = ()=>{
        // setTenantEmployment({...tenantEmployment,notes:editorData}); 
        notes();
        const newDataArray  = [];
        newDataArray.push(tenantEmployment);
        handleEmploymentTrue(prevArray => [...prevArray, ...newDataArray]);
        setTenantEmployments(prevArray => [...prevArray, ...newDataArray]);
        setTenantEmployment(initialEmploymentData);
        setEditorData('');
        setShowForm(false);
        setStates(undefined)
        setCities(undefined)
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...tenantEmployments];
        array[indexEdit] = tenantEmployment;
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
        array[indexEdit].leaving_reason = editorData;
        array[indexEdit].notes = editorData1;
        setTenantEmployments(array);
    
        handleEmploymentTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantEmployment(initialEmploymentData);

      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        getStates(tenantEmployments[index]?.country)
        getCities(tenantEmployments[index]?.state)
        setIndexEdit(index);
        setShowForm(true);
        setViewForm(false);
        setTenantEmployment(tenantEmployments[index]);
        setEditorData(tenantEmployments[index]?.leaving_reason)
        setEditorData1(tenantEmployments[index]?.notes)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        getState(tenantEmployments[index]?.country)
        getCity(tenantEmployments[index]?.state)
        setShowForm(false)
        setTenantEmployment(tenantEmployments[index]);
        setEditorData(tenantEmployments[index]?.leaving_reason)
        setEditorData1(tenantEmployments[index]?.notes)
        setViewForm(true);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...tenantEmployments];
        newArray.splice(index, 1);
        setTenantEmployments(newArray);
        handleEmploymentTrue(newArray);
      }
    const columns = [
        {
          name: "Company",
          selector: (data) => data.company_name,
          sortable: true,
        },
        {
          name: "Title",
          selector: (data) => data.position,
          sortable: true,
        },
        {
          name: "Start Year",
          selector: (data) => data.start_year,
          sortable: true,
        },
        {
          name: "End Year",
          selector: (data) => data.present == 0 ? data.end_year : "Present",
          sortable: true,
        },
        {
          name: "Annual Salary",
          selector: (data) => data.annual_salary != "" ?convertCurrencyToFormat(ownerSetting?.currency_format,data.annual_salary):"",
          sortable: true,
        },
        {
          name: "Employment Notes",
          selector: (data) => {
            const cleanText = new DOMParser().parseFromString(data?.notes, 'text/html').body.textContent;
            return cleanText;
          },
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData != true ?(<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewEmploymentForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
               
                 </div>):(<>
                  <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewEmploymentForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                 </>)}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : tenantEmployments,
      };
      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Tenant Employments";
        const headers = [["Company", "Title","Start Year","End Year","Annual Salary","Employment Notes"]];
    
        const data = tenantEmployments.map(elt => [elt.company_name,elt.position ,elt.start_year,elt.present == 0 ? elt.end_year : "Present",elt.annual_salary,elt.notes]);
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Employments.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = tenantEmployments.map(elt => [elt.company_name,elt.position ,elt.start_year,elt.present == 0 ? elt.end_year : "Present",elt.annual_salary,elt.notes]);
        // Convert the data to a CSV string
    
        const headers = ["Company", "Title","Start Year","End Year","Annual Salary","Employment Notes"];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Employments.csv");
        link.click();
      };

useEffect(() => {
  getNumberType();
  getCountries();
  setOwnerSetting(ownerSettings)
setTenantEmployment(initialEmploymentData);
setTenantEmployments(data);
}, [])

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
  return (
    <>
        <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewEmploymentForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewEmploymentFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Employments
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantEmployment([]);
                    setEditorData("");
                    setEditorData1("");
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">

              {viewForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex flex-column items-center justify-center">
                    {tenantEmployment?.company_name != "" ?
                    <div className="flex">
                      <h4 className='font-bold capitalize'>{tenantEmployment?.company_name}</h4>
                    </div>:null}
                    <div className="flex italic">
                      {tenantEmployment?.supervisor != "" ? 
                      <>
                      <span className='font-bold capitalize'>{tenantEmployment?.supervisor}</span>
                      {tenantEmployment?.position != "" ? <span className='ml-2'>|</span> :null}
                      </>:null}
                      {tenantEmployment?.position != "" ?<span className='font-bold capitalize ml-2'>{tenantEmployment?.position}</span> :null}
                    </div>
                    {tenantEmployment?.annual_salary != "" ? <div className='flex'>
                      <span>{convertCurrencyToFormat(ownerSetting?.currency_format,tenantEmployment?.annual_salary)}</span>
                    </div>:null}
                    {tenantEmployment?.website != "" ?<div className="flex">
                     <a className='text-black no-underline' href={tenantEmployment?.website} target='_blank'>{tenantEmployment?.website}</a>
                    </div>:null}
                    {tenantEmployment?.address_line1 != "" ?<div className="flex">
                     <span className='font-bold'>{tenantEmployment?.address_line1}</span>
                    </div>:null}
                    {tenantEmployment?.address_line2 != "" ?<div className="flex">
                     <span className='font-bold'>{tenantEmployment?.address_line2}</span>
                    </div>:null}
                    <div className="flex">
                        {tenantEmployment?.city != "" ?
                        <><span>{city}</span> {tenantEmployment?.state != ""?<span className='ml-2'>|</span>:null}</>:null}
                        {tenantEmployment?.state != "" ?<span className='ml-2'>{state}</span>:null}
                        </div>
                        {tenantEmployment?.zip_code != "" ?<div className="flex">
                        <span>{zip}</span>
                        </div>:null}
                        {tenantEmployment?.country != "" ?<div className="flex">
                        <span>{countries?.map((country)=>{
                              if(tenantEmployment?.country == country?.id)
                              {
                                return country?.country
                              }
                            })}</span>
                        </div>:null}
                    <div className="flex">
                    {tenantEmployment?.start_year != ""?
                    <span className='font-bold'>{tenantEmployment?.start_year}</span>
                    :null}
                    {tenantEmployment?.present != 0 || tenantEmployment?.end_year != "" ? <span className='ml-2'>to</span>:null}
                    {tenantEmployment?.present != 0 || tenantEmployment?.end_year != ""?
                    <span className='font-bold ml-2'>{tenantEmployment?.present == 1 ? "Present" : tenantEmployment?.end_year}</span>
                    :null}
                    </div>
                    {tenantEmployment?.number != "" ?<div className="flex">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(tenantEmployment?.number_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${tenantEmployment?.number}`}>{tenantEmployment?.number_type == 8 ? tenantEmployment?.number : formatPhoneNumber(tenantEmployment?.number,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                  </div>
                  {tenantEmployment?.leaving_reason !== "" && (
                    <>
                        <hr />
                        <h5>Reason: </h5>
                      <div className="flex mt-2">
                      <div dangerouslySetInnerHTML={{ __html: tenantEmployment?.leaving_reason }} />
                      </div>
                      </>
                    )}
                    {tenantEmployment?.notes !== "" && (
                      <>
                        <hr />
                        <h5>Notes: </h5>
                      <div className="flex mt-2">
                      <div dangerouslySetInnerHTML={{ __html: tenantEmployment?.notes }} />
                      </div>
                      </>
                    )}

                  
                </div>
              </div>
              :null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantEmployment([]);
                                setEditorData("");
                                setEditorData1("");
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-employer.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Employment:
              </label>
            </div>
          </div>
         {showForm == false ? 
         <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Employment
                </button>
              </div>
            </div>
          </div>
          :null}
              {showForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Company Name:<span title='Field is Required'>*</span>
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-company-name`}
                        type="text"
                        placeholder="J Height"
                        value={tenantEmployment?.company_name}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Title/Position:
                          <span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-position`}
                          type="text"
                          placeholder="CEO"
                          required={true}
                          value={tenantEmployment?.position}
                          onChange={(e) => {
                            setTenantEmployment({...tenantEmployment,position:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Annual Salary:<span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="annual-salary"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantEmployment?.annual_salary != null ? tenantEmployment?.annual_salary : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantEmployment({ ...tenantEmployment, annual_salary: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantEmployment({ ...tenantEmployment, annual_salary: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Start Year:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          required={true}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-start-year`}
                          value={tenantEmployment?.start_year}
                          onChange={(e) => {
                            setTenantEmployment({...tenantEmployment,start_year:e.target.value});
                          }}
                        >
                          <option value=" ">Select Year</option>
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          End Year:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          required={true}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-end-year`}
                          value={tenantEmployment?.end_year || tenantEmployment?.present}
                          onChange={(e) => {
                            const PresentValue =
                              e.target.value == 1 ? 1 : 0;
                            const EndYearValue =
                              e.target.value == 1
                                ? 0
                                : e.target.value;
                            // employments[index].end_year = EndYearValue;
                            // employments[index].present = PresentValue;
                            setTenantEmployment({...tenantEmployment,end_year:EndYearValue,present:PresentValue});
                          }}
                        >
                          <option value="">Select Year</option>
                          <option value={1}>Present</option>
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Address Line 1:<span title="Field is required.">*</span>
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-address-line-1`}
                        type="text"
                        placeholder="6284 Locust Road"
                        required={true}
                        value={tenantEmployment?.address_line1}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,address_line1:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Address Line 2:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-address-line-2`}
                        type="text"
                        placeholder="Unit #5"
                        required={true}
                        value={tenantEmployment?.address_line2}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,address_line2:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Country: <span title='Field is required'>*</span>
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={tenantEmployment?.country}
                            onChange={(e) => {
                              setTenantEmployment({...tenantEmployment,country:e.target.value});
                              getStates(e.target.value)
                            }}
                          >
                            <option value=" ">Select Country</option>
                            {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State: <span title='Field is required'>*</span>
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-state"
                            required={false}
                            value={tenantEmployment?.state}
                            onChange={(e) => {
                              setTenantEmployment({...tenantEmployment,state:e.target.value});
                              getCities(e.target.value)
                            }}
                          >
                            <option value="">Select State</option>
                            {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                      <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City: <span title='Field is required'>*</span>
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={tenantEmployment?.city}
                            onChange={(e) => {
                              const zip = cities?.map((col) =>{
                                if(col?.id == e.target.value)
                                {
                                  return col?.zip_code
                                }
                              })
                              const filteredArr = zip.filter((value) => value !== undefined);
                              const zipCode = filteredArr.join(', ');
                              setTenantEmployment({...tenantEmployment,city:e.target.value,zip_code:zipCode});
                            }}
                          >
                            <option value=" ">Select City</option>
                            {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                          </select>
                        </div>
                      </div>
                      
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip: <span title='Field is required'>*</span>
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-zip"
                            type="text"
                            placeholder="90210"
                            required={false}
                            value={tenantEmployment?.zip_code}
                            onChange={(e) => {
                              setTenantEmployment({...tenantEmployment,zip_code:e.target.value});
                            }}
                          />
                        </div>
                      </div>

                      </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Website:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-website`}
                        type="url"
                        placeholder="www.john-doe.com"
                        value={tenantEmployment?.website}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,website:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Supervisor:<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-supervisor`}
                        type="text"
                        placeholder="Ben"
                        value={tenantEmployment?.supervisor}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,supervisor:e.target.value})
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number:<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      {tenantEmployment?.number_type == 8 ? 
                      <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-number`}
                      type="tel"
                      required={true}
                      placeholder="(999) 999-9999"
                      value={tenantEmployment?.number}
                      maxLength={ownerSetting?.phone_number_format?.length}
                      onChange={(e) => {
                        setTenantEmployment({...tenantEmployment,number:e.target.value});
                      }}
                    />
                      :
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-number`}
                        type="tel"
                        required={true}
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(tenantEmployment?.number,ownerSetting?.phone_number_format)}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,number:removeNonNumeric(e.target.value)});
                        }}
                      />}
                    </div>
                    {/* <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type: <span title="Field is required">*</span>
                      </label>
                    </div> */}
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={true}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-number-type`}
                        value={tenantEmployment?.number_type}
                        onChange={(e) => {
                            setTenantEmployment({...tenantEmployment,number_type:e.target.value});
                          }}
                      >
                        <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reason For Leaving/other Comments:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Employment Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData1}
                        onChange={(e) => {
                            setEditorData1(e.editor.getData());
                            // setTenantEmployment({...tenantEmployment,leaving_reason:editorData,notes:editorData1});  
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if(!tenantEmployment?.company_name || !tenantEmployment?.position || !tenantEmployment?.annual_salary || !tenantEmployment?.start_year || (!tenantEmployment?.end_year && !tenantEmployment?.present) || !tenantEmployment?.address_line1 || !tenantEmployment?.city || !tenantEmployment?.state || !tenantEmployment?.zip_code || !tenantEmployment?.supervisor || !tenantEmployment?.number || !tenantEmployment?.number_type)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{
                            editSpacesData();
                            }
                          } else {
                            if(!tenantEmployment?.company_name || !tenantEmployment?.position || !tenantEmployment?.annual_salary || !tenantEmployment?.start_year || (!tenantEmployment?.end_year && !tenantEmployment?.present) || !tenantEmployment?.address_line1 || !tenantEmployment?.city || !tenantEmployment?.state || !tenantEmployment?.zip_code || !tenantEmployment?.supervisor || !tenantEmployment?.number || !tenantEmployment?.number_type)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{
                              bulkData();
                            }
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>
              :null}
              
          

          <hr />
          {tenantEmployments?.length > 0 ?<>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantEmployments}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>
          <hr /> */}
    </>
  )
}
