import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ApiRequest from "../../utility/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";
import Spinner from "../Spinner/Spinner";


const ExpandedComponent = ({ data }) => {
  const [application,setApplication] = useState([]);
  const [appStatus,setAppStatus]= useState();
  const [loader,setLoader]= useState();
  const PropertyID=localStorage.getItem("Property ID");
  const showApplication = async ()=>{
      const response = await ApiRequest.get(
          `/public/api/auth/get-applications?property_id=${PropertyID}`
      );
      setLoader(response.status)
      if(response.status == 200)
      {
          setApplication(response?.data?.data?.applications)
          console.log("Response table",response);
      }
  }
  const showStatus = async() =>{
    const response = await ApiRequest.get('/public/api/auth/get-units-statuses');
    if(response.status == 200)
    {
      setAppStatus(response?.data?.data)
    }
  }
  useEffect(() => {
    showApplication();
    showStatus();
  }, [])
  
  
    const columns = [
      {
        name: "Date Submitted",
        selector : (data) => data.created_at
        
      },
      {
        name: "Prospect Last",
        selector : (data) => data.lastname
      },
      {
        name: "Prospect First",
        selector : (data) => data.firstname
      },
      {
        name: "Job Title",
        selector : (data) => data.position
      },
      {
        name: "Employer",
        selector : (data) => data.supervisor
      },
      {
        name: "Annual Salary",
        selector : (data) => data.annual_salary
      },
      {
        name: "Co-Signer",
        selector : (data) => data.has_cosigners == 1 ? "Yes" : "No"
      },
      {
        name: "Pets",
        selector : (data) => data?.pet_id != "" || null ? "Yes" : "No"
      },
      {
        name: "Kids",
        selector : (data) => data.children_id != "" || null ? "Yes" : "No"
      },
      {
        name: "Others Living",
        selector : (data) => data.other_living_id != "" || null ? "Yes" : "No"
      },
      {
        name: "Last Updated",
        selector : (data) => data?.updated_at
      },
      {
        name: "Status",
        selector: (data) => {
          const activeStatus = appStatus?.find(item => item.id === data.application_status_id);
          return activeStatus ? activeStatus.status : null;
        }
        
      },
      // {
      //   name: "Options",
      //   cell: (data) => {
      //     return (
      //       <>
      //            <Link to={`/edit-application/${data.id}`} style={{textDecoration:"none"}}>
      //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
      //           <i class="fa-solid fa-pen mr-6"></i>
      //           <span>Edit</span>
      //         </button>
      //         </Link>
      //         <Link to={`/show-application/${data.id}`} style={{textDecoration:"none"}}>
      //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
      //             <i class="fa-regular fa-eye mr-6"></i>
      //             <span>View</span>
      //           </button>
      //         </Link>
      //       </>
      //     );
      //   },
      // },
    ];
    return(
      <>
      {loader === undefined ? <Spinner/>
        
        :
        <>
      <DataTable data={application} columns={columns} selectableRows  selectableRowsHighlight/>
      </>}
      </>
    );

};

const ApplicationTable = ({data}) => {
    // console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const unit = allProperties?.map((a) => ({ ...a }));
//   const unit = allPropertiesTypes?.map((a) => ({ ...a }));
const [loader,setLoader]= useState();



//   const data =[
//     {
//         id:"355821647853265",
//         sent:"5/24/2022 TIME",
//         sender:"Sapp, Sean (UNIT A)",
//         recipient:"SYSTEM",
//         subject:"Question on Rent Due Date",
//         viewed:"5/24/2022 TIME",
//       },
//     {
//         id:"745852146932145",
//         sent:"5/24/2022 TIME",
//         sender:"SYSTEM",
//         recipient:"Sapp, Sean (UNIT A)",
//         subject:"Information requested about the pool ",
//         viewed:"Not Viewed",
//       },
//     {
//         id:"985632452185635",
//         sent:"5/24/2022 TIME",
//         sender:"Knabenshue, Chris(OWNER)",
//         recipient:"SYSTEM",
//         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
//         viewed:"Not Viewed",
//       },

//   ];
  
const [application,setApplication] = useState([]);
const [appStatus,setAppStatus]= useState();
const [propertyId,setPropertyId]= useState([]);
const PropertyID=localStorage.getItem("Property ID");
const showApplication = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        `/public/api/auth/get-applications?property_id=${PropertyID}`
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        setApplication(response?.data?.data?.units)
        console.log("Response table",response);
    }
}
const showStatus = async() =>{
  const response = await ApiRequest.get('/public/api/auth/get-applications-statuses');
  if(response.status == 200)
  {
    setAppStatus(response?.data?.data)
  }
}
useEffect(() => {
  showApplication();
  showStatus();
  // setPropertyId(data)
}, [data])


  const columns = [
    {
      name: "Listing Start",
      selector : (data) => '08/02/2023'
      
    },
    {
      name: "Unit",
      selector : (data) => data.name
    },
    {
      name: "Monthly Rent",
      selector : (data) => data.monthly_rent
    },
    {
      name: "Security Deposit",
      selector : (data) => data.security_deposit
    },
    {
      name: "Type",
      selector : (data) => 'Public'
    },
    
    {
      name: "Status",
      selector : (data) => {
        const activeStatus = appStatus?.find(item => item.id === data.is_active);
          return activeStatus ? activeStatus.status : null;
        }
    },
    // {
    //   name: "Options",
    //   cell: (data) => {
    //     return (
    //       <>
    //            <Link to={`/edit-application/${data.id}`} style={{textDecoration:"none"}}>
    //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
    //           <i class="fa-solid fa-pen mr-6"></i>
    //           <span>Edit</span>
    //         </button>
    //         </Link>
    //         <Link to={`/show-application/${data.id}`} style={{textDecoration:"none"}}>
    //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
    //             <i class="fa-regular fa-eye mr-6"></i>
    //             <span>View</span>
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];
 
const tableData = {
  columns : columns,
  data : application,
};
// const exportPDF = ()=>{
//   const unit = "pt";
//   const size = "A4"; // Use A1, A2, A3 or A4
//   const orientation = "portrait"; // portrait or landscape

//   const marginLeft = 40;
//   const doc = new jsPDF(orientation, unit, size);

//   doc.setFontSize(15);

//   const title = "Applications";
//   const headers = [["Date Submitted", "Prospect Last","Prospect First","Job Title","Employer","Annual Salary","Co-Signer","Pets","Kids","Others Living","Last Updated","Status"]];

//   const data = application.map(elt => [elt.created_at,elt.lastname,elt.firstname,elt.position,elt.supervisor,elt.annual_salary,elt.has_cosigners == 1 ? "Yes" : "No",elt.pet_id != "" || null ? "Yes" : "No",elt.children_id != "" || null ? "Yes" : "No",elt.other_living_id != "" || null ? "Yes" : "No",elt.updated_at, appStatus?.map((item, index) => {
//     if (elt.application_status_id === item.id) {
//       console.log("item.staus",item.staus);
//       return (status = item.staus);
//     }
//   }),elt.application_status_id = status])

//   let content = {
//     startY: 50,
//     head: headers,
//     body: data
//   };
//   doc.text(title, marginLeft, 40);
//   doc.autoTable(content);
//   doc.save("data.pdf")
// }
// const exportCSV = () => {
//   // Get data from data table
//   const TotalData =application.map(elt => [elt.created_at,elt.lastname,elt.firstname,elt.position,elt.supervisor,elt.annual_salary,elt.has_cosigners == 1 ? "Yes" : "No",elt.pet_id != "" || null ? "Yes" : "No",elt.children_id != "" || null ? "Yes" : "No",elt.other_living_id != "" || null ? "Yes" : "No",elt.updated_at, appStatus?.map((item, index) => {
//     if (elt.application_status_id === item.id) {
//       return (item.staus);
//     }
//   })])
//   // Convert the data to a CSV string

//   const headers = [["Date Submitted", "Prospect Last","Prospect First","Job Title","Employer","Annual Salary","Co-Signer","Pets","Kids","Others Living","Last Updated","Status"]];
//   // let content = {
//   //   head:headers,
//   //   body:TotalData,
//   // }
//   TotalData.unshift(headers)
//   const csv = Papa.unparse(TotalData);
//   // Create a blob of the data
//   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
//   // Create a link to trigger the download
//   const link = document.createElement("a");
//   link.href = window.URL.createObjectURL(blob);
//   link.setAttribute("download", "data.csv");
//   link.click();
// };
  return (
    <>
        {loader === undefined ? <Loading/>
        
      :
      <>
      {/* <div className="flex flex-row-reverse mt-3">
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div> */}
      <DataTableExtensions {...tableData}>
        <DataTable
        className="striped"
          columns={columns}
          data={application}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
        </DataTableExtensions>
        <div class="flex justify-between justify-items-center text-center gap-x-8 mt-3 mb-4">
            <div class="flex-none ">
              {" "}
              <button class="bg-gray-300 px-4 flex justify-center align-items-center rounded text-black-800">
                <i class="fa-solid fa-bolt text-2xl	 mr-4"></i>
                <span>Bulk Actions</span>
              </button>
            </div>

            <div class="bg-indigo-300 p-2 rounded  unit-wrapper">
              <div class="flex flex-wrap gap-x-3">
                <p class="flex-auto">
                  Total Active Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Active Rented Units:{" "}
                  <span class="text-green-600">28</span>
                </p>
                <p class="flex-auto">
                  Total Active Unrented Units:{" "}
                  <span class="text-red-600">28</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Rented Units: <span>28</span>
                </p>
                <p class="flex-auto">
                  Total Unrented Units: <span>28</span>
                </p>
              </div>
            </div>
          </div>
        </>
    }
    </>
  );
};

export default ApplicationTable;
