import { createSlice } from "@reduxjs/toolkit";
import { viewPrefix } from "./adminThunk";
const viewPrefixSlice = createSlice({
  name: "viewprefixSlice",
  initialState: {
    status: null,
    viewprefixSuffix: {},
  },
  extraReducers: {
    [viewPrefix.pending]: (state) => {
      state.status = "loading";
    },
    [viewPrefix.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.viewPrefix = payload;
    },
    [viewPrefix.rejected]: (state, _error) => {
      state.status = "failed";
      state.viewPrefix = {};
    },
  },
});

export default viewPrefixSlice.reducer;
