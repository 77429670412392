import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { convertToFormData } from "../../utility/helperFunctions";


import { EditTenantForm } from "../../components/tenant/EditTenantForm";
import ApiRequest from "../../utility/http";
import { OwnerHeader } from "../OwnerHeader";
import { Footer } from "../../components/Dashboard/Footer";

export const EditTenantPage = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();

  // CONSTANTS

  const initialData = {
    enable_portal: "",
    send_notifications: "",
    user_prefix_id: "",
    firstname: "",
    middlename: "",
    lastname: "",
    user_suffix_id: "",
    user_role_type_id:5,
    addresses: [],
    numbers: [],
    email: "",
    date_of_birth: "",
    dln_id: "",
    dl_id_picture: "",
    dln_id_issue_country: "",
    dln_id_issue_state: "",
    dln_id_issue_notes: "",
    gender_id: "",
    ethnicity_id: "",
    smoker: "",
    pictures: [],
    emergencies_contacts: [],
    known_allergies: "",
    other_emergency_info: "",
    notifications: [],
    user_files: [],
    user_notes: "",
    user_staff_files: [],
    user_staff_notes: "",
    user_manager_files: [],
    user_manager_notes: "",
    user_owner_files: [],
    user_owner_notes: "",
    interactions: [],
    keys_trackings: [],
    loanable_items_trackings: [],
    vehicles: [],
    childrens: [],
    pets: [],
    other_livings: [],
    employments: [],
    references: [],
    moveout_address1: "",
    moveout_address2: "",
    moveout_city: "",
    moveout_country: "",
    moveout_state: "",
    moveout_zip_code: "",
    moveout_number: "",
    moveout_number_type: "",
    moveout_email: "",
    moveout_date: "",
    moveout_reason_id: ""
};
// console.log(initialData)

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [formData, setFormData] = useState({});
  // console.log("formData",formData)
  const { alertList } = useSelector((state) => state.alerts);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  // const updateFormData = async (data = {}) => {
  //   if (JSON.stringify(formData) !== JSON.stringify(data)) {
  //     const newData = { ...formData, ...data };
  //     setFormData(newData);

  //     const apiBody = convertToFormData(newData);
  //     const response = await ApiRequest.post(
  //       "public/api/auth/property",
  //       apiBody
  //     );
  //     if (response == 200) {
  //       debugger;
  //     } else {
  //       debugger;
  //     }
  //   }
  // };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  useEffect(() => {
    setFormData(initialData);
  }, []);
  const [collapsed,setCollapsed] = useState(true)
const handleChildMessage = (message) => {
setCollapsed(message)
};
  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  const [firstName,setFirstName] = useState([])
  const [lastName,setLastName] = useState([])

  const handleFirstName = (message)=>{
    setFirstName(message);
  }
  const handleLastName = (message)=>{
    setLastName(message);
  }

  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/table.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Tenants" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e) }
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>

          <div className="px-5">
          <section className="search-wraper">
            <div className="row">
              <div className="col-xl-4 order-lg-2">
                <div className="search-right-side-wrap flex justify-end">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-8 order-lg-1">
                <div
                  // className={`welcome-wrap ${
                  //   showPropertyDropdown ? "z-0" : ""
                  // }`}
                >
                  <div className="welcome-inner flex align-items-center">
                    <img
                className="logo w-12 mr-5"
                src="/assets/svgs/users-tenant.svg"
                alt=""
                /> 
                <h4>
                <h2>Editing {firstName} {lastName}</h2>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <EditTenantForm
              initialData={
                Object.keys(formData).length ? formData : initialData
              }
              verticalScroll={verticalScroll}
              handleFirstName={handleFirstName}
              handleLastName={handleLastName}
              // updateFormData={updateFormData}
            />
          </div>
          <div className="bottom-gap" />
        </div>

        {showFooter?<Footer/>:null}
        {/* <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Reserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </div>
  );
};
