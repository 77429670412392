import React from "react";

const DirectoryListing = () => {
  return (
    <>
      <div className="w-[90%] m-auto mt-5 flex flex-col">
      <div className="w-4/5">
        <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
          Tenant Directory (OWNER/STAFF VIEW)
        </label>
        <div className="border-2  border-black rounded-3xl p-5  mt-5">
          <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
            TENANT_FIRST TENANT_LAST UNIT
          </label>
          <div className="flex gap-5 mt-2 items-start">
            <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
            <div className="flex flex-col gap-1">
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                NUMBER_1
              </label>
              <div className="flex items-center gap-2 w-1/2">
                <img src="/assets/svgs/system-message.svg" className="w-1/4" alt="" />
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  E-Mail
                </label>
              </div>
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                Employee/Job_Title
              </label>
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                Website
              </label>
              <div className="flex w-1/12 gap-2">
                <img src="/assets/svgs/system-social-linkdin.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-facebook.svg"
                  className=""
                  alt=""
                />
                <img
                  src="/assets/svgs/system-social-instagram.svg"
                  className=""
                  alt=""
                />
                <img src="/assets/svgs/system-social-twitter.svg" className="" alt="" />
                <img src="/assets/svgs/system-social-youtube.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-snapchat.svg"
                  className=""
                  alt=""
                />
                <img
                  src="/assets/svgs/system-social-whatsapp.svg"
                  className=""
                  alt=""
                />
                <img src="/assets/svgs/system-social-etsy.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-Pinterest.svg"
                  className=""
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="block tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Age | <span>Date_Of_Birth(with birthday counter)</span>
                </label>
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  About
                </label>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">
              Kids:
            </label>
            <div className="flex gap-5 mt-2">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col">
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  First_Name
                </label>
                <label className="block tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Age | <span>Date_Of_Birth <span className="capitalize">(with birthday counter)</span></span>
                </label>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">
              Pets:
            </label>
            <div className="flex mt-2 gap-5">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col gap-1">
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Pet_Name
                </label>
                <div className="flex gap-3">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Type
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Breed
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    color
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Age
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl capitalize font-bold ">
              Others Living With Them:
            </label>
            <div className="flex mt-2 gap-5">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col gap-1">
                <div className="flex gap-3">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    First_Name
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Last_Name
                  </label>
                </div>
                <div>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Relationship
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="w-[90%] m-auto mt-5 flex flex-col">
      <div className="w-4/5">
        <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
          Tenant Directory (Tenant VIEW)
        </label>
        <div className="border-2  border-black rounded-3xl p-5  mt-5">
          <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
            TENANT_FIRST TENANT_LAST UNIT
          </label>
          <div className="flex gap-5 mt-2 items-start">
            <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
            <div className="flex flex-col gap-1">
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                NUMBER_1
              </label>
              <div className="flex items-center gap-2 w-1/2">
                <img src="/assets/svgs/system-message.svg" className="w-1/4" alt="" />
                <label className="block w-full tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  E-Mail
                </label>
              </div>
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                Website
              </label>
              <div className="flex w-1/12 gap-2">
                <img src="/assets/svgs/system-social-linkdin.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-facebook.svg"
                  className=""
                  alt=""
                />
                <img
                  src="/assets/svgs/system-social-instagram.svg"
                  className=""
                  alt=""
                />
                <img src="/assets/svgs/system-social-twitter.svg" className="" alt="" />
                <img src="/assets/svgs/system-social-youtube.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-snapchat.svg"
                  className=""
                  alt=""
                />
                <img
                  src="/assets/svgs/system-social-whatsapp.svg"
                  className=""
                  alt=""
                />
                <img src="/assets/svgs/system-social-etsy.svg" className="" alt="" />
                <img
                  src="/assets/svgs/system-social-Pinterest.svg"
                  className=""
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="block tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Day/month of date of birth w/birthday counter                </label>
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  About:
                </label>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">
              Kids:
            </label>
            <div className="flex gap-5 mt-2">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col">
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  First_Name
                </label>
                <label className="block tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                Day/month of date of birth w/birthday counter                
                </label>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">
              Pets:
            </label>
            <div className="flex mt-2 gap-5">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col gap-1">
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Pet_Name
                </label>
                <div className="flex gap-3">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Type
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Breed
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    color
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Age
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr className="border-2 border-black mt-2" />
          <div className="mt-5">
            <label className="block w-1/2 tracking-wide text-gray-700 text-xl capitalize font-bold ">
              Others Living With Them:
            </label>
            <div className="flex mt-2 gap-5">
              <img src="/assets/svgs/property.svg" className="w-1/6" alt="" />
              <div className="flex flex-col gap-1">
                <div className="flex gap-3">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    First_Name
                  </label>
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    Last_Name
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
       <div className="w-[90%] m-auto mt-5 flex flex-col">
        <div className="w-4/5">
          <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
            staff Directory (Owner/staff VIEW)
          </label>
          <div className="border-2  border-black rounded-3xl p-5  mt-5">
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              TENANT_FIRST staff_LAST UNIT
            </label>
            <div className="flex gap-5 mt-2 items-start">
              <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
              <div className="flex flex-col gap-1">
              <label className="block w-9/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  Department
                </label>
                <label className="block w-9/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  Job title
                </label>
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  NUMBER_1
                </label>
                <div className="flex items-center gap-2 w-1/2">
                  <img src="/assets/svgs/system-message.svg" className="w-1/4" alt="" />
                  <label className="block w-full tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    E-Mail
                  </label>
                </div>
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Website
                </label>
                <div className="flex w-1/12 gap-2">
                  <img
                    src="/assets/svgs/system-social-linkdin.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-facebook.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-instagram.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-twitter.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-youtube.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-snapchat.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-whatsapp.svg"
                    className=""
                    alt=""
                  />
                  <img src="/assets/svgs/system-social-etsy.svg" className="" alt="" />
                  <img
                    src="/assets/svgs/system-social-Pinterest.svg"
                    className=""
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    About:
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] m-auto mt-5 flex flex-col">
        <div className="w-4/5">
          <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
            staff Directory (Tenant VIEW)
          </label>
          <div className="border-2  border-black rounded-3xl p-5  mt-5">
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              TENANT_FIRST staff_LAST
            </label>
            <div className="flex gap-5 mt-2 items-start">
              <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
              <div className="flex flex-col gap-1">
              <label className="block w-9/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  Department
                </label>
                <label className="block w-9/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  Job title
                </label>
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  NUMBER_1
                </label>
                <div className="flex items-center gap-2 w-1/2">
                  <img src="/assets/svgs/system-message.svg" className="w-1/4" alt="" />
                  <label className="block w-full tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    E-Mail
                  </label>
                </div>
                <div className="flex flex-col gap-2">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                    About:
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] m-auto mt-5 flex flex-col">
        <div className="w-4/5">
          <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
            vendor Directory
          </label>
          <div className="border-2  border-black rounded-3xl p-5  mt-5">
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              company
            </label>
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              vendor_FIRST vendor_LAST
            </label>
            <div className="flex gap-5 mt-2 items-start">
              <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
              <div className="flex flex-col gap-1">
                <label className="block w-8/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  Job title
                </label>
                <label className="block w-8/12 text-center tracking-wide text-gray-700 text-xl uppercase font-bold ">
                  category
                </label>
                <div className="flex justify-between">
                <div>
                <div className="flex items-center justify-between">
                <label className="block w-1/2 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  NUMBER_1
                </label>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1 w-full">
                    <img
                      src="/assets/svgs/system-message.svg"
                      className="w-1/4"
                      alt=""
                    />
                    <label className="block w-full tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                      E-Mail
                    </label>
                  </div>
                </div>
                <div className="flex justify-between">
                <label className="block w-10/12 tracking-wide text-gray-700 text-xl uppercase font-semibold ">
                  Website
                </label>
                </div>
                </div>
                <div className="flex flex-col">
                  <label className=" tracking-wide text-gray-700 text-base uppercase font-semibold">address_line_1</label>
                  <label className=" tracking-wide text-gray-700 text-base uppercase font-semibold">address_line_1</label>
                  <label className=" tracking-wide text-gray-700 text-base uppercase font-semibold">city/state</label>
                  <label className=" tracking-wide text-gray-700 text-base uppercase font-semibold">zip</label>
                </div>
                </div>
                <div className="flex w-1/12 mt-2 gap-2">
                  <img
                    src="/assets/svgs/system-social-linkdin.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-facebook.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-instagram.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-twitter.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-youtube.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-snapchat.svg"
                    className=""
                    alt=""
                  />
                  <img
                    src="/assets/svgs/system-social-whatsapp.svg"
                    className=""
                    alt=""
                  />
                  <img src="/assets/svgs/system-social-etsy.svg" className="" alt="" />
                  <img
                    src="/assets/svgs/system-social-Pinterest.svg"
                    className=""
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] m-auto mt-5 flex flex-col">
        <div className="w-4/5">
          <label className="block tracking-wide text-center text-gray-700 text-2xl uppercase font-bold ">
            Parking Directory
          </label>
          <div className="border-2  border-black rounded-3xl p-5  mt-5">
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              Parking_space
            </label>
            <label className="block tracking-wide text-center text-gray-700 text-xl uppercase font-bold ">
              tenat_FIRST tenant_LAST unit
            </label>
            <div className="flex gap-5 mt-2 w-full">
              <img src="/assets/svgs/property.svg" className="w-1/4" alt="" />
              <div className="flex flex-col w-full text-center">
                <label className="block tracking-wide text-gray-700 text-lg uppercase font-semibold">car_year</label>
                <label className="block tracking-wide text-gray-700 text-lg uppercase font-semibold">car_make</label>
                <label className="block tracking-wide text-gray-700 text-lg uppercase font-semibold">car_model</label>
                <label className="block tracking-wide text-gray-700 text-lg uppercase font-semibold">car_color</label>
              </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default DirectoryListing;
