import { CKEditor } from "ckeditor4-react";
import React from "react";

export const MessageTemplate = () => {
  return (
    <>
      <div className="flex-col w-[90%] m-[auto] mt-5 mb-20">
        <div className="flex items-center w-1/3 justify-between">
          <img
            src="/assets/svgs/system-message-write.svg"
            className="w-1/6 mr-4"
            alt=""
          />
          <label className="w-11/12 capitalize tracking-wider text-gray-700 text-2xl font-bold">
            Rent blaze system update
          </label>
        </div>
        <div className="flex-col mt-4">
          <div>
            <label className="block capitalize tracking-wide text-gray-700 text-lg font-bold">
              send message to:
            </label>
          </div>
          <div className="flex w-1/3">
            <input className="mr-4" type="checkbox" />
            <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
              all users
            </label>
          </div>
          <div className="flex justify-between w-1/3">
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                admin
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                admin staff
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                leads
              </label>
            </div>
          </div>
          <div className="flex justify-between w-1/3">
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                owners
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                staff
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                vendors
              </label>
            </div>
          </div>
          <div className="flex justify-between w-1/3">
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                tenants
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                prospects
              </label>
            </div>
            <div className="flex w-1/3">
              <input className="mr-4" type="checkbox" />
              <label className="block capitalize tracking-wide text-gray-700 text-base font-semibold">
                Renters
              </label>
            </div>
          </div>
        </div>
        <hr className="border-t-4 border-black mt-4" />
        <div className="flex mt-4 items-center">
          <label className="capitalize tracking-wider text-gray-700 text-lg mr-3 font-bold">
            Subject
          </label>
          <input
            type="text"
            placeholder="New Updates on Rent Blaze!"
            className="border-2 border-black p-3 w-full rounded-md font-lg font-bold"
          />
        </div>
        <div className="flex mt-10">
          <label className="capitalize tracking-wider text-gray-700 text-lg font-bold">
            Above Changelog:
          </label>
          <div className="w-[80%] m-auto">
            <CKEditor />
          </div>
        </div>
        <div className="flex mt-10">
          <label className="capitalize tracking-wider text-gray-700 text-lg font-bold">
            Under Changelog:
          </label>
          <div className="w-[80%] m-auto">
            <CKEditor />
          </div>
        </div>
        <div className="flex mt-10 items-center w-1/3 justify-between float-right">
          <button>Save Draft</button>
          <button>Send</button>
        </div>
      </div>
    </>
  );
};
