export const Footer = () => {
    return (
        <footer className="footer-area h-20 bottom-0 absolute w-100 visible opacity-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xxl-4 order-xxl-1">
                        <ul className="footer-menu block">
                            <li>
                                <a href="#" className="text-xs ml-3 text-decoration-none">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#" className="text-xs text-decoration-none">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xxl-4 order-xxl-3">
                        <ul className="footer-text-list flex-col items-end">
                            <li className="text-xs mr-12 mb-2 mt-2 mb-1">ChangeLog</li>
                            <li className="text-xs">Feature Requests & Roadmap</li>
                        </ul>
                    </div>
                    <div className="col-xxl-4 order-xxl-2">
                        <p className="font-bold text-white text-center text-xs">Version: 1.0.1</p>
                        <p className="copyright text-xs">
                            Copyright 2021 - {new Date().getFullYear()} Rent Blaze, LLC. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
