import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import ReactSignatureCanvas from "react-signature-canvas";
import Webcam from "react-webcam";
import "../../components/button.css";
import ApiRequest from "../../utility/http";
import Swal from "sweetalert2";
import imgUrl from "../ImageUrl";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { Tooltip } from "../Loader/Tooltip";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";

export const LoanableItems = ({data,handleItemTrue,hiddenData,ownerSettings}) => {

    const initialItemData = {
        loanable_item_id: "",
        loanable_item_serial_number_id: "",
        assigned_date: "",
        assigned_verification_picture: "",
        assigned_verification_signature:"",
        is_lost_damaged: 0,
        lost_damaged_date: "",
        send_replacement_cost_invoice: 0,
        replacement_additional_fees: 0,
        replacement_reason: "",
        is_returned: 0,
        returned_date: "",
        returned_verification_picture: "",
        returned_verification_signature:"",
        notes: "",
        replacement_files: [],
        item_given_pictures: [],
        item_return_pictures: [],
      };
      const picTypes = useAllowedPicMimeTypes();
      const fileTypes = useAllowedMimeTypes();
      const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user",
      };
      
      let imageApi = "/public/api/auth/upload-image";
      let fileApi = "/public/api/auth/upload-file";
      const [indexEdit,setIndexEdit ]= useState();
      // // console.log('@indexEdit',indexEdit);
      const [bulkAction , setBulkAction] = useState({
        bulk_action:""
      });
      const [showForm,setShowForm] = useState(false);
      const [viewForm,setViewForm] = useState(false);
      const [tenantItem, setTenantItem] = useState([]);
      const [ownerSetting,setOwnerSetting] = useState(false);
      console.log('@tenantItem',tenantItem);
      const [tenantItems, setTenantItems] = useState([]);
  const [tenantItemFiles, setTenantItemFiles] = useState([]);
  const [tenantItemPicture, setTenantItemPicture] = useState([]);
  const [tenantItemRetrunVerificationPicture,setTenantItemRetrunVerificationPicture] = useState([]);
  const [tenantItemGivenPicture, setTenantItemGivenPicture] = useState([]);
  const [tenantItemReturnPicture, setTenantItemReturnPicture] = useState([]);;
    const [editorData,setEditorData] = useState("");
    const [ip, setIp] = useState("");
    const [itemImgSrc, setItemImgSrc] = useState(null);
    const [returnItemImgSrc, setReturnItemImgSrc] = useState(null);
    const ItemWebcamRef = React.useRef(null);
    const returnItemWebcamRef = React.useRef(null);
    const ItemTenantCapture = React.useCallback(async () => {
      const imageSrc = ItemWebcamRef.current.getScreenshot();
      setItemImgSrc(imageSrc);
    
      // Block camera access
      try {
        const stream = ItemWebcamRef.current.stream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => (track.enabled = false));
      } catch (error) {
        console.error("Error blocking camera access:", error);
      }
    
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
    
      // Create an image element
      const img = new Image();
      img.src = imageSrc;
    
      // Draw the image on the canvas
      img.onload = async function () {
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);
    
        // Add the timestamp
        const timestamp =
          new Date().toUTCString() + " IP Address " + ip;
        ctx.font = "10px Arial";
        ctx.fillStyle = "white";
        ctx.textAlign = "right";
        ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
        const imageData = canvas.toDataURL();
    
        const response = await ApiRequest.post(imageApi, { image: imageData });
        if (response.status === 200) {
          const value = response?.data?.data?.image;
          setTenantItem((prevTenantItem) => ({
            ...prevTenantItem,
            assigned_verification_picture: value,
          }));
        }
      };
    }, [ItemWebcamRef, setItemImgSrc, ip, setTenantItem]);
    const [showCamera,setShowCamera] = useState(false)
    const [showReturnCamera,setShowReturnCamera] = useState(false)
    
    const ReturnItemTenantCapture =  React.useCallback(async () => {
      const imageSrc = returnItemWebcamRef.current.getScreenshot();
      setReturnItemImgSrc(imageSrc);
    
      // Block camera access
      try {
        const stream = returnItemWebcamRef.current.stream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => (track.enabled = false));
      } catch (error) {
        console.error("Error blocking camera access:", error);
      }
    
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
    
      // Create an image element
      const img = new Image();
      img.src = imageSrc; // Use the 'imageSrc' variable directly
    
      // Draw the image on the canvas
      img.onload = async function () {
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.drawImage(img, 0, 0);
    
        // Add the timestamp
        const timestamp = new Date().toUTCString() + " IP Address " + ip;
        ctx.font = "10px Arial";
        ctx.fillStyle = "white";
        ctx.textAlign = "right";
        ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
        const imageData = canvas.toDataURL();
    
        const response = await ApiRequest.post(imageApi, { image: imageData });
        if (response.status === 200) {
          const value = response?.data?.data?.image;
          setTenantItem((prevTenantItem) => ({
            ...prevTenantItem,
            returned_verification_picture: value,
          }));
        }
      };
    }, [returnItemWebcamRef, setReturnItemImgSrc, ip, setTenantItem]);
    
    const ItemVerification = useRef(null);
    const ItemVerificationClear = () => {
      ItemVerification.current.clear();
    };
    const ItemVerificationSave = async () => {
      const ItemVerificationData = ItemVerification.current
        .getCanvas()
        .toDataURL();
      const res = await ApiRequest.post(imageApi, {
        image: ItemVerificationData,
      });
      console.warn("ItemVerificationData", res);
    };
    const ItemVerificationReturn = useRef(null);
    const ItemVerificationReturnClear = () => {
      ItemVerificationReturn.current.clear();
    };
    const ItemVerificationReturnSave = async () => {
      const ItemVerificationReturnData = ItemVerificationReturn.current
        .getCanvas()
        .toDataURL();
      const res = await ApiRequest.post(imageApi, {
        image: ItemVerificationReturnData,
      });
      console.warn("ItemVerificationReturnData", res);
    };
    const [loanableItem, setLoanableItem] = useState([]);
    
    const [newSerialNumber, setNewSerialNumber] = useState({
        id: null,
        loanable_item_id: null,
        serial_numbers: "",
      });
    const getMyLoanableItems = async () => {
        const response = await ApiRequest.get(
          "/public/api/auth/get-my-loanable-items"
        );
        if (response.status == 200) {
          setLoanableItem(response?.data?.data);
        }
      };
      const addSerialNumber = async (payload) => {
        const response = await ApiRequest.post(
          "/public/api/auth/add-loanable-item-serial-number",
          payload
        );
        if (response.status == 200) {
          getMyLoanableItems();
        }
      };



      const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
        const TotalDate = day + "-" + month + "-" + year;
        return TotalDate;
      };
      function stringToDate(dateString) {
        // console.warn("dateString",dateString)
        if (dateString === null || dateString === undefined || dateString >= 0)
          return;
        let parts = dateString.split("-");
        let day = parts[0];
        let month = parts[1]; // months are 0-based
        let year = parts[2];
        let date = year + "-" + month + "-" + day;
        // console.warn("UnformatDate",date)
        return date;
      }
      function ShowFormatDate(data, format) {
        if (data === null || data === undefined)
          return;
        const date = new Date(stringToDate(data));
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
        const year = date.getFullYear();
      
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
      
        format = format.replace('dd', formattedDay);
        format = format.replace('DD', formattedDay);
        format = format.replace('d', day);
        format = format.replace('D', day);
        format = format.replace('mm', formattedMonth);
        format = format.replace('MM', formattedMonth);
        format = format.replace('m', month);
        format = format.replace('M', month);
        format = format.replace('yyyy', year);
        format = format.replace('YYYY', year);
      
        return format;
      }
      function removeCurrencyAndCommas(str) {
        if (str === null) {
          return '';
        }
        // Remove currency symbols ($, €, £, ¥, etc.)
        str = str.replace(/[$€£¥]/g, '');
        
        // Remove commas
        str = str.replace(/,/g, '');
        
        // Return the modified string
        return str;
      }
      function convertCurrencyToFormat(currency, number) {
        let formattedCurrency = Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
        }).format(number);
      
        return formattedCurrency;
      }

      const imageComponent = ({
        image = {},
        key = 0,
        src = "",
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
        ...restProps
      }) => {
        const confirmEdit = (image) => {
          Swal.fire({
            title: 'Are you sure you want to edit this image?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              // Modify the image object here
              const editedImage = { ...image, title: 'New Title' };
              onEditClick(editedImage);
            }
          });
        };
      
        return image ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
          >
            <div className="relative h-fit w-full">
              <img
                title={image?.title}
                key={key}
                alt="property image"
                className="border block object-contain w-80 h-auto rounded-lg"
                src={src}
              />
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove image"
                    type="button"
                    className="logo w-5 mx-auto my-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((file) => {
                          if (file !== image) {
                            dataTransfer.items.add(file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
                {/* <button
                  type="button"
                  title="Edit image"
                  className="logo w-5 mx-auto my-1"
                  onClick={() => confirmEdit(image)}
                >
                  <img src="/assets/img/system-edit.png" alt="edit" />
                </button> */}
              </div>
            </div>
          </div>
        ) : (
          <img {...restProps} />
        );
      };
      const fileComponent = ({
        file = null,
        title = null,
        key = 0,
        inputFieldId = "",
        trashAble = false,
        editAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
      }) =>
        file ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
          >
            <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
              <div title={title} key={key} className="block min-w-[600rem] px-2">
                <a href={`${imgUrl}/${file}`} target="_blank">
                  {title}
                </a>
              </div>
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
              >
                
                {editAble ? (
                  <img
                    title="Edit file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/system-edit.png"
                    onClick={(e) => {
                      onEditClick();
                    }}
                    alt=""
                  />
                ) : null}
                {trashAble ? (
                  <img
                    title="Remove file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((input_file) => {
                          if (input_file !== file) {
                            dataTransfer.items.add(input_file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null;
       
        useEffect(() => {
            fetch("https://api.ipify.org?format=json")
              .then((res) => res.json())
              .then((data) => setIp(data.ip))
              .catch((error) => console.error(error));
          }, []);
          const formElement = document.getElementById("add-tenant-form");
          const myDivRef = useRef(null);
          const notes = ()=>{
            const data = [];
            data.push(tenantItem);
            const array = [...data];
            // console.log('@array',array);
            const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");
            array[0].notes = cleanText;
            setTenantItem(array);
          }
          const bulkData = ()=>{
            //   setTenantItem({...tenantItem,notes:editorData}); 
            notes();
              const newDataArray  = [];
              newDataArray.push(tenantItem);
              handleItemTrue(prevArray => [...prevArray, ...newDataArray]);
              setTenantItems(prevArray => [...prevArray, ...newDataArray]);
              setTenantItem(initialItemData);
              setTenantItemFiles([]);
              // setEditorData('');
              setShowForm(false);
              setShowCamera(false)
              setShowReturnCamera(false)
              setIndexEdit(undefined);
              // setBulkSpaces("");
            }
          
          const editSpacesData = () =>{
              const array = [...tenantItems];
              array[indexEdit] = tenantItem;
              const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");
              array[indexEdit].notes = cleanText;
              setTenantItems(array);
          
              handleItemTrue(array);
              setShowForm(false);
              setIndexEdit(undefined);
              setTenantItem(initialItemData);
              setTenantItemFiles([]);
              setTenantItemReturnPicture([]);
              setTenantItemGivenPicture([]);
      
      
            }
            const indexFind = (index)=>
            {
              // var index = $(this).attr('data-index');
              // var modal = $(this).attr('data-bs-toggle');
              // const findIndex = parkingSpaces.length -1 - index ;
              setIndexEdit(index);
              setShowForm(true);
              setTenantItem(tenantItems[index]);
              setTenantItemFiles(tenantItems[index]?.replacement_files)
              setTenantItemReturnPicture(tenantItems[index]?.item_return_pictures)
              setTenantItemGivenPicture(tenantItems[index]?.item_given_pictures)
              setEditorData(tenantItems[index]?.notes)
              myDivRef.current.scrollIntoView({ behavior: 'smooth' });
              // // console.log("index",findIndex);
              // // console.log("modal",modal);
            }
            const viewData = (index)=>{
              setShowForm(false)
              setTenantItem(tenantItems[index]);
              setTenantItemFiles(tenantItems[index]?.replacement_files)
              setTenantItemReturnPicture(tenantItems[index]?.item_return_pictures)
              setTenantItemGivenPicture(tenantItems[index]?.item_given_pictures)
              setEditorData(tenantItems[index]?.notes)
              setViewForm(true);
              myDivRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            const deleteData = (index)=>{
              setShowForm(false);
              setShowCamera(false)
              setShowReturnCamera(false)
              setIndexEdit(undefined);
              setViewForm(false);
              const newArray = [...tenantItems];
              newArray.splice(index, 1);
              setTenantItems(newArray);
              handleItemTrue(newArray);
            }

           
            


            const columns = [
              {
                name: "Date Loaned",
                selector: (data) => <div className="flex">
                {data?.assigned_date != "" ? ShowFormatDate(data?.assigned_date,ownerSetting?.date_format):""}{data?.assigned_verification_picture != '' || data?.assigned_verification_signature !="" ? <img src="/assets/img/system-verified.png" className="md:w-1/12 ml-1" alt="Verified" />:null}{data?.item_given_pictures?.length > 0 ? <img src="/assets/img/system-camera.png" className="md:w-1/12 ml-1" alt="Camera" />:null}
                </div>,
                sortable: true,
              },
              {
                name: "",
                selector: (data) => loanableItem.map((row)=>{
                  if(row?.id == data?.loanable_item_id)
                  {
                    return <img src={`${imgUrl}/${row?.pictures[0]?.picture}`} alt="" className="w-1/2" />
                  }
                }),
                sortable: true,
              },
              {
                name: "Item",
                selector: (data) => loanableItem.map((row)=>{
                  if(data?.loanable_item_id == row?.id)
                  {
                      return ( <div className="flex"><span>{row?.name}</span><img src="/assets/img/system-tooltip.png" alt="Tooltip" className="w-1/12 ml-1" title={`${row?.description}`}/></div> );
                  }
                })
              },
              {
                name: "Serial Number",
                selector: (data) => loanableItem.map((row)=>{
                  if(data?.loanable_item_id == row?.id)
                  {
                      return row?.serial_numbers.map((col)=>{
                        if(data?.loanable_item_serial_number_id == col?.id)
                        {
                            return col?.serial_number;
                        }
                      })
                  }
                }),
                sortable: true,
              },
              {
                name: "Replacement Cost",
                selector: (data) => loanableItem.map((row)=>{
                  if(data?.loanable_item_id == row?.id)
                  {
                      return convertCurrencyToFormat(ownerSetting?.currency_format,row?.replacement_cost);
                  }
                }),
                sortable: true,
              },
              {
                name: "Date Returned",
                selector: (data) => <div className="flex flex-column">
                {data.is_lost_damaged != 0 ? <span className="text-red-700 uppercase font-bold">{data?.is_lost_damaged == 1 ? "Lost" : data?.is_lost_damaged == 2 ? "Destroyed" :data?.is_lost_damaged == 3 ? "Damaged" :""}</span> : null}
              
                <div className="flex">
                {data?.lost_damaged_date == "" ? (data.returned_date != "" ? ShowFormatDate(data.returned_date,ownerSetting?.date_format) : "") :ShowFormatDate(data.lost_damaged_date,ownerSetting?.date_format)}{data?.returned_verification_picture != "" || data?.returned_verification_signature ? <img src="/assets/img/system-verified.png" className="md:w-1/12 ml-1" alt="Verified" />:null}{data?.item_return_pictures?.length > 0 ? <img src="/assets/img/system-camera.png" className="md:w-1/12 ml-1" alt="Camera" />:null}
                </div>
                </div>,
                sortable: true,
              },
              {
                name: "Notes",
                selector: (data) => data?.notes,
                sortable: true,
              },
              // {
              //   name: "Parking Space",
              //   selector: (data) => data.property_parking_space_id
              // },
              // {
              //   name: "Status",
              //   selector: (data) => data.status == 0 ? "Inactive": "Active"
              // },
              // {
              //   name: "Handicap",
              //   selector: (data) => data.handicap,
              // },
              // {
              //   name: "Assignee",
              //   selector : (data) => 'UnAssign'
              // },
              // {
              //   name: "Status",
              //   selector: (row) => row.is_active===1?"Active":"Inactive",
              // },
              {
                name: "Options",
                cell: (row,index) => {
                  return (
                    <>
                      {hiddenData != true ? (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewLoanableForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mx-3"
                // data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6 p-0"
                // data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
               
                 </div>):(<>
                  <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewLoanableForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                 </>)}
                      {/* <div className="md:w-1/12">
                      <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                        <i class="fa-regular fa-eye mr-6"></i>
                      </button>
                      </div> */}
                    </>
                  );
                },
              },
            ];
          const tableData = {
              columns : columns,
              data : tenantItems,
            };
            
            const exportPDF = ()=>{
              const unit = "pt";
              const size = "A4"; // Use A1, A2, A3 or A4
              const orientation = "portrait"; // portrait or landscape
          
              const marginLeft = 40;
              const doc = new jsPDF(orientation, unit, size);
          
              doc.setFontSize(15);
          
              const title = "Tenant Loanable Items";
              const headers = [["Date Given","Key","Replacement Cost",'Date Returned','Notes']];
          
              const data = tenantItems.map(elt => {
                const assignedItem = loanableItem.find(row => row?.id === elt.loanable_item_id);
                // console.log("@ASSIGNED KEY",assignedItem);
              
                return [
                  elt.assigned_date,
                  assignedItem?.name || null,
                  assignedItem?.replacement_cost || null,
                  elt.returned_date,
                  elt.notes
                ];
              })
          
              let content = {
                startY: 50,
                head: headers,
                body: data
              };
              doc.text(title, marginLeft, 40);
              doc.autoTable(content);
              doc.save("Tenant Loanable Items.pdf")
            }
            const exportCSV = () => {
              // Get data from data table
              const TotalData = tenantItems.map(elt => {
                const assignedItem = loanableItem.find(row => row?.id === elt.loanable_item_id);
                // console.log("@ASSIGNED KEY",assignedItem);
              
                return [
                  elt.assigned_date,
                  assignedItem?.name || null,
                  assignedItem?.replacement_cost || null,
                  elt.returned_date,
                  elt.notes
                ];
              })
              // Convert the data to a CSV string
          
              const headers = ["Date Given","Key","Replacement Cost",'Date Returned','Notes'];
              // let content = {
              //   head:headers,
              //   body:TotalData,
              // }
              TotalData.unshift(headers)
              const csv = Papa.unparse(TotalData);
              // Create a blob of the data
              const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
              // Create a link to trigger the download
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.setAttribute("download", "Tenant Loanable Items.csv");
              link.click();
            };

      useEffect(() => {
        getMyLoanableItems();
        setTenantItem(initialItemData);
        setTenantItems(data);
        setOwnerSetting(ownerSettings);
      }, [])
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    
  return (
    <>
     <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewLoanableForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewLoanableFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantItem([]);
                    setTenantItemFiles([]);
                    setTenantItemPicture([]);
                    setTenantItemReturnPicture([]);
                    setTenantItemGivenPicture([]);
                    setTenantItemRetrunVerificationPicture([]);
                    setEditorData(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    {tenantItem.loanable_item_id != '' ? 
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Item:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_id`}
                          disabled
                          value={tenantItem?.loanable_item_id}
                          onChange={(e) => {
                            setTenantItem({...tenantItem,loanable_item_id:parseInt(e.target.value)});
                          }}
                        >
                            <option value='' selected>Select Item</option>
                          {loanableItem.map((item) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))}
                        </select> */}
                        <span>{loanableItem?.map((item)=>{
                          if(tenantItem?.loanable_item_id == item?.id)
                          {
                            return item?.name
                          }
                        })}</span>
                      </div>
                    </div>:null}
                    {tenantItem.loanable_item_serial_number_id != '' ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Serial Number:
                        </label>
                      </div>
                      <div className="md:w-1/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_serial_number_id`}
                          disabled
                          value={tenantItem?.loanable_item_serial_number_id}
                          onChange={(e) => {
                            
                            setTenantItem({...tenantItem,loanable_item_serial_number_id:parseInt(e.target.value)});
                            
                          }}
                        >
                          {loanableItem.map((item) =>
                            item?.id == tenantItem?.loanable_item_id
                              ? item?.serial_numbers.map((number) => (
                                  <option value={number?.id}>
                                    {number?.serial_number}
                                  </option>
                                ))
                              : null
                          )}
                        </select> */}
                        <span>{loanableItem.map((item) =>
                            item?.id == tenantItem?.loanable_item_id
                              ? item?.serial_numbers.map((number) => {
                                  if(tenantItem?.loanable_item_serial_number_id == number?.id)
                                  {
                                    return number?.serial_number;
                                  }
                                })
                              : null
                          )}</span>
                      </div>
                      {/* <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `loanable_item_serial_number_input`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("loanable-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div> */}
                      {/* <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_serial_number_input`}
                          type="text"
                          value={newSerialNumber.serial_numbers}
                          hidden={true}
                          placeholder="Ae123"
                          onInput={(e) => {
                            const serialNo = [];
                            serialNo.push(e.target.value);
                            setNewSerialNumber({
                              ...newSerialNumber,
                              serial_numbers: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newSerialNumber.id
                                ? newSerialNumber
                                : {
                                    loanable_item_id: tenantItem?.loanable_item_id,
                                    serial_number:
                                      newSerialNumber.serial_numbers,
                                  };
                              addSerialNumber(payload);
                              console.warn("payload", payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div> */}
                    </div>:null}
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    {tenantItem.assigned_date != '' ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date Given:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`assigned_date`}
                          type="date"
                          disabled
                          placeholder="Steve"
                          required={false}
                          value={stringToDate(tenantItem?.assigned_date)}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            setTenantItem({...tenantItem,assigned_date:date});
                            
                          }}
                        /> */}
                        <span>{ShowFormatDate(tenantItem?.assigned_date,ownerSetting?.date_format)}</span>
                      </div>
                    </div>:null}
                    {/* {tenantItem.item_given_pictures?.length > 0 ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-7/12">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Given Pictures
                            </span>
                            <input
                              accept={picTypes}
                              id={`t-return-pictures`}
                              type="file"
                              multiple={true}
                              disabled
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemGivenPicture?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemGivenPicture.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const imgBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(imageApi, {
                                        image: imgBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        picture: res?.data?.data?.image,
                                        order_number: maxOrderNumber + i + 1 + tenantItemGivenPicture.length,
                                        url:imgBase64,
                                        is_cover: i === 0 ? 1 : 0,
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let pictureLists = [...tenantItemGivenPicture];
                                        pictureLists = [...pictureLists, ...newImages];
                                        setTenantItemGivenPicture(pictureLists);
                                        let tenantCopy = [...tenantItemGivenPicture];
                                        tenantCopy.pictures = pictureLists.map(
                                          (picObj) => ({
                                            picture: picObj.picture,
                                            order_number: picObj.order_number,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, item_given_pictures: tenantCopy?.pictures });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>:null} */}
                  </div>

                  {/* <div className="flex flex-row-reverse justify-between items-center mt-3">
                    <div className="flex justify-between items-center gap-4">
                      <button
                        type="button"
                        class="btn3"
                        data-bs-toggle="modal"
                        data-bs-target={`#staticItemVerificationBackdrop`}
                      >
                        Add Verification
                      </button>
                    </div>
                  </div> */}
                  {/* <div
                    class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                    id={`staticItemVerificationBackdrop`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticItemVerificationBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog relative w-auto pointer-events-none">
                      <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                          <h5
                            class="text-xl font-medium leading-normal text-gray-800"
                            id="exampleModalLabel"
                          >
                            Add Verification
                          </h5>
                          <button
                            type="button"
                            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            disabled
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            
                            onClick={async()=>{
                              const canvas = document.createElement("canvas");
                              const ctx = canvas.getContext("2d");

                              // Create an image element
                              const img = new Image();
                              const image = "";
                              img.src = itemImgSrc;

                              // Draw the image on the canvas
                              img.onload = async function () {
                                canvas.width = img.naturalWidth;
                                canvas.height = img.naturalHeight;
                                ctx.drawImage(img, 0, 0);

                                // Add the timestamp
                                const timestamp = new Date().toUTCString() + " IP Address " + ip;
                                ctx.font = "10px Arial";
                                ctx.fillStyle = "white";
                                ctx.textAlign = "right";
                                ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
                                const imageData = canvas.toDataURL();
                              // console.warn("image",image)
                              const response = await ApiRequest.post(imageApi, { image: imageData });
                              if (response.status == 200) {
                                const value = response?.data?.data?.image;
                                setTenantItem({...tenantItem,returned_verification_picture:value});
                                  
                              }

                              }
                            }
                            }
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Given Pictures:
                    </label>
                  <div >
                    {/* <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantItemGivenPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div> */}
                    
              {tenantItemGivenPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemGivenPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantItemGivenPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantItem.pictures = pictureLists;
                                setTenantItemGivenPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantItem({
                                  ...tenantItem,
                                  item_given_pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
        
                  </div>
                  <div class="w-full">
                    <hr />
                    <div className="h5 text-center">Given Verification</div>
                          {tenantItem.loanable_item_id != '' ? <>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Item Loaned:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              {/* <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-loaned`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={loanableItem?.map((col) =>
                                  tenantItem?.loanable_item_id == col?.id
                                    ? col?.name.split(",").join("")
                                    : ""
                                )}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              /> */}
                              <span>
                                {loanableItem?.map((item)=>{
                                  if(tenantItem?.loanable_item_id == item?.id)
                                  {
                                    return item?.name
                                  }
                                })}
                              </span>
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3 mb-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Replacement Cost:
                              </label>
                            </div>
                            <div className="md:w-1/2">
                              {/* <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-replacement-cost`}
                                type="text"
                                placeholder="Steve"
                                disabled={true}
                                required={false}
                                value={loanableItem?.map((col) =>
                                    tenantItem?.loanable_item_id == col?.id
                                    ? col?.replacement_cost
                                    : " "
                                )}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              /> */}
                              <span>
                                {loanableItem?.map((item)=>{
                                  if(tenantItem?.loanable_item_id == item?.id)
                                  {
                                    return item?.replacement_cost
                                  }
                                })}
                              </span>
                            </div>
                          </div>
                          </>:null}
                          {tenantItem.assigned_verification_signature != "" ?
                          
                          <>
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">

                            <img src={`${imgUrl}/${tenantItem?.assigned_verification_signature}`} alt="Tenant Signature" />
                          </div>
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <span>Tenant Signature</span>
                          </div>
                            </>:null
                          }
                          {/* {tenantItem.assigned_verification_signature == ""?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              disabled
                              onClick={ItemVerificationClear}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Clear
                            </button>
                            <button
                            disabled
                              type="button"
                              onClick={async()=>{

                                const itemVerificationData = ItemVerification.current.getCanvas().toDataURL();
                                const res = await ApiRequest.post(imageApi, { image: itemVerificationData });
                                if(res.status == 200)
                                {
                                  const value = res?.data?.data?.image
                                  setTenantItem({...tenantItem,assigned_verification_signature:value});
                                  
                               
                                }
                              }}
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Save
                            </button>
                          </div>:<div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              disabled
                              onClick={()=>{
                                setTenantItem({...tenantItem,assigned_verification_signature:''})
                              }}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Edit
                            </button>
                        </div>} */}
                          {/* <div className="md:flex md:items-center w-full mt-3 ">
                            <div className="md:w-2/4 flex flex-row-reverse">
                                <button 
                                className="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded"
                                onClick ={clear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div> */}
                          <div className="md:items-center w-full mt-3 ">
                            {tenantItem.assigned_verification_picture != ""?
                            
                            <>
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">

                            <img src={`${imgUrl}/${tenantItem?.assigned_verification_picture}`} alt="Tenant Picture" />
                            </div>
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                              <span>Tenant Picture</span>
                            </div>
                            </>:null
                            }
                          </div>
                          {/* {tenantItem.assigned_verification_picture == ""?
                          <div className="flex justify-center">
                            <button
                              class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={ItemTenantCapture}
                              type="button"
                              disabled
                            >
                              Capture photo
                            </button>
                          </div>:
                          <div className="flex justify-center">
                          <button
                            class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={()=>{
                                setTenantItem({...tenantItem,assigned_verification_picture:''});
                            }}
                            type="button"
                            disabled
                          >
                            Take Again
                          </button>
                        </div>
                          } */}
                        <hr />
                        </div>
                  {/* <div >
                    {tenantItemPicture[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <span>Verification Picture</span>
                        <div className="container w-full px-3 m-auto">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image: tenantItemPicture[index]?.image?.picture,
                              src: tenantItemPicture[index]?.url,
                              inputFieldId: `tenant-item-verification-picture`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictures = [...tenantItemPicture];
                                pictures[index] = { ...initialImageData };
                                loanable_items_trackings[
                                  index
                                ].assigned_verification_picture = {
                                  ...initialImageData,
                                };
                                setTenantItemPicture(pictures);
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div> */}

                  <div className="md:flex md:items-center mt-3">
                    {tenantItem.is_lost_damaged != '' ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Lost, Damaged, or Destroyed:
                        </label>
                      </div>
                      <div className="md:w-1/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`item-is_lost_damaged`}
                          value={tenantItem?.is_lost_damaged}
                          disabled
                          onChange={(e) => {
                            const is_lost = e.target.value === "0" ? 0 : e.target.value;
                            
                            setTenantItem({...tenantItem,is_lost_damaged:is_lost});
                            
                          }}
                        >
                          <option value="0" selected>Select Option</option>
                          <option value="Lost">Lost</option>
                          <option value="Damage">Damage</option>
                          <option value="Destroyed">Destroyed</option>
                        </select> */}
                        {tenantItem?.is_lost_damaged == 0 ? <i className="fa fa-remove"></i> :<span>{tenantItem?.is_lost_damaged == 1 ? "Lost" : tenantItem?.is_lost_damaged == 2 ? "Destroyed" :tenantItem?.is_lost_damaged == 3 ? "Damaged" :""}</span>}
                      </div>
                    </div>:null}
                    {tenantItem?.is_lost_damaged != "0" ? 
                    <>
                    
                    {tenantItem?.lost_damaged_date != '' ?<div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Date:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`lost_damaged_date`}
                          type="date"
                          placeholder="123"
                          disabled
                          required={false}
                          value={stringToDate(tenantItem?.lost_damaged_date)}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            
                            setTenantItem({...tenantItem,lost_damaged_date:date});
                            
                          }}
                        /> */}
                        <span>{tenantItem?.lost_damaged_date}</span>
                      </div>
                    </div>:null}
                    </>
                     : null}
                  </div>
                  {tenantItem?.is_lost_damaged != "0" ? (
                    <>
                      <div className="md:flex md:items-center mt-3">
                        {tenantItem.send_replacement_cost_invoice != 1 ? <div className="md:flex md:w-1/2">
                          <div className="md:w-4/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Auto-Generate & Send Replacement Cost Invoice:
                            </label>
                          </div>
                          {/* <label class="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value={tenantItem?.send_replacement_cost_invoice}
                              disabled
                              onChange={(e) => {
                                const checkedValue =
                                  e.target.checked == true ? 1 : 0;
                               
                                setTenantItem({...tenantItem,send_replacement_cost_invoice:checkedValue});
                                
                              }}
                              class="sr-only peer"
                            />
                            <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.8 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label> */}
                          {tenantItem?.send_replacement_cost_invoice == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i> }
                        </div>:null}
                        {tenantItem.replacement_additional_fees != '' ? <div className="md:flex md:items-center w-full md:w-2/4 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Damage Fees or Additional Fees:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            {/* <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`replacement_additional_fees`}
                              type="text"
                              placeholder="123"
                              disabled
                              required={false}
                              value={tenantItem?.replacement_additional_fees}
                              onChange={(e) => {
                                // loanable_items_trackings[
                                //   index
                                // ].replacement_additional_fees = e.target.value;
                                setTenantItem({...tenantItem,replacement_additional_fees:e.target.value});
                                
                              }}
                            /> */}
                            <span>{convertCurrencyToFormat(ownerSetting?.currency_format,tenantItem?.replacement_additional_fees)}</span>
                          </div>
                        </div>:null}
                        {tenantItem.replacement_reason != '' ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6 ml-3">
                          <div className="md:w-1/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Reason:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            {/* <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="replacement_reason"
                              type="text"
                              placeholder="Reason"
                              disabled
                              required={false}
                              value={tenantItem?.replacement_reason}
                              onChange={(e) => {
                                setTenantItem({...tenantItem,replacement_reason:e.target.value});
                                
                              }}
                            /> */}
                            <span>{tenantItem?.replacement_reason}</span>
                          </div>
                        </div>:null}
                      </div>
                      {tenantItem.replacement_files.length > 0 ? <>
                      {/* <div className="flex flex-row-reverse md:items-center md:justify-start md:w-full mt-3">
                        <div className="md:w-1/5">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Files
                            </span>
                            <input
                              id={`t-key-files`}
                              type="file"
                              accept={fileTypes}
                              multiple={true}
                              disabled
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemFiles?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemFiles.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const fileBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(fileApi, {
                                        file: fileBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        file: res?.data?.data?.file,
                                        // order_number: maxOrderNumber + i + 1 + tenantItemFiles.length,
                                        url:fileBase64
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let fileLists = [...tenantItemFiles];
                                        fileLists = [...fileLists, ...newImages];
                                        setTenantItemFiles(fileLists);
                                        let tenantCopy = [...tenantItemFiles];
                                        tenantCopy.files = fileLists.map(
                                          (picObj) => ({
                                            file: picObj.file,
                                            // order_number: picObj.order_number,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, replacement_files: tenantCopy?.files });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                        <div className="md:w-1/2">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              {(() => {
                                const filesCount =
                                  tenantItemFiles?.length || 0;
                                return filesCount > 1
                                  ? filesCount + " Documents"
                                  : filesCount == 1
                                  ? filesCount + " Document"
                                  : null;
                              }).call()}
                            </span>
                          </label>
                        </div>
                      </div> */}
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Files:
                        </label>

                      <div >
              {tenantItemFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantItemFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantItem.replacement_files = fileLists;
                                setTenantItemFiles(fileLists);
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                    </>:null}
                    </>
                  ) : null}
                  <div className="md:flex md:items-center mt-3">
                    {tenantItem.returned_date != '' ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date Returned:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="assigned_date"
                          type="date"
                          placeholder="Steve"
                          disabled
                          required={false}
                          value={stringToDate(tenantItem?.returned_date)}
                          onChange={(e) => {
                            setTenantItem({...tenantItem,returned_date:formatDate(e.target.value)});
                            
                          }}
                        /> */}
                        <span>{ShowFormatDate(tenantItem?.returned_date,ownerSetting?.date_format)}</span>
                      </div>
                    </div>:null}
                    {/* {tenantItem.item_return_pictures.length > 0 ? <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-7/12">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Return Pictures
                            </span>
                            <input
                              accept={picTypes}
                              id={`t-return-pictures`}
                              type="file"
                              multiple={true}
                              disabled
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemReturnPicture?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemReturnPicture.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const imgBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(imageApi, {
                                        image: imgBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        picture: res?.data?.data?.image,
                                        order_number: maxOrderNumber + i + 1 + tenantItemReturnPicture.length,
                                        url:imgBase64,
                                        is_cover: i === 0 ? 1 : 0,
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let pictureLists = [...tenantItemReturnPicture];
                                        pictureLists = [...pictureLists, ...newImages];
                                        setTenantItemReturnPicture(pictureLists);
                                        let tenantCopy = [...tenantItemReturnPicture];
                                        tenantCopy.pictures = pictureLists.map(
                                          (picObj) => ({
                                            picture: picObj.picture,
                                            order_number: picObj.order_number,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, item_return_pictures: tenantCopy?.pictures });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>:null} */}
                  </div>
                  {/* <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Verification
                            </span>
                            <input
                              accept={picTypes}
                              id={`item-return_verification_picture`}
                              type="file"
                              hidden={true}
                              onChange={async (e) => {
                                let file = e.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", async () => {
                                  const imgBase64 = reader.result;
                                  try {
                                    console.log(imgBase64);
                                    const res = await ApiRequest.post(
                                      "/public/api/auth/upload-image",
                                      { image: imgBase64 }
                                    );
                                    // console.log("res", res?.data?.data?.image);
                                    loanable_items_trackings[
                                      index
                                    ].returened_verification_picture =
                                      res?.data?.data?.image;
                                    // setFeatureIcon(res?.data?.data?.image);
                                  } catch (err) {
                                    console.log(err);
                                  }
                                });
                                reader.readAsDataURL(file);

                                // console.log("here 142");
                                const pictures = [...tenantItemPicture];
                                pictures[index] = getImagesAndURLs(e)[0];
                                setTenantItemRetrunVerificationPicture(
                                  pictures
                                );
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Return Pictures:
                        </label>
                  <div >
                    {/* <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantItemReturnPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div> */}
                    {tenantItemReturnPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemReturnPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantItemReturnPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantItem.item_return_pictures = pictureLists;
                                setTenantItemReturnPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantItemReturnPicture({
                                  ...tenantItem,
                                  item_return_pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
                  </div>
                  {/* <div >
                    {tenantItemRetrunVerificationPicture[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[30%]">
                        <span>Return Verification Picture</span>
                        <div className="container w-full">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image:
                                tenantItemRetrunVerificationPicture[index]
                                  ?.image?.picture,
                              src: tenantItemRetrunVerificationPicture[index]
                                ?.url,
                              inputFieldId: `tenant-item-return-verification-picture`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictures = [
                                  ...tenantItemRetrunVerificationPicture,
                                ];
                                pictures[index] = { ...initialImageData };
                                loanable_items_trackings[
                                  index
                                ].returened_verification_picture = {
                                  ...initialImageData,
                                };
                                setTenantItemRetrunVerificationPicture(
                                  pictures
                                );
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div> */}
                  {/* <div className="flex flex-row-reverse justify-between items-center mt-3">
                    <div className="flex justify-between items-center gap-4">
                      <button
                        type="button"
                        class="btn3"
                        data-bs-toggle="modal"
                        data-bs-target={`#staticItemReturnVerificationBackdrop`}
                      >
                        Add Verification
                      </button>
                    </div>
                  </div>
                  <div
                    class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                    id={`staticItemReturnVerificationBackdrop`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticItemReturnVerificationBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog relative w-auto pointer-events-none">
                      <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                          <h5
                            class="text-xl font-medium leading-normal text-gray-800"
                            id="exampleModalLabel"
                          >
                            Add Verification
                          </h5>
                          <button
                            type="button"
                            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            disabled
                            onClick={async()=>{
                              const canvas = document.createElement("canvas");
                              const ctx = canvas.getContext("2d");

                              // Create an image element
                              const img = new Image();
                              const image = "";
                              img.src = returnItemImgSrc;

                              // Draw the image on the canvas
                              img.onload = async function () {
                                canvas.width = img.naturalWidth;
                                canvas.height = img.naturalHeight;
                                ctx.drawImage(img, 0, 0);

                                // Add the timestamp
                                const timestamp = new Date().toUTCString() + " IP Address " + ip;
                                ctx.font = "10px Arial";
                                ctx.fillStyle = "white";
                                ctx.textAlign = "right";
                                ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
                                const imageData = canvas.toDataURL();
                              // console.warn("image",image)
                              const response = await ApiRequest.post(imageApi, { image: imageData });
                              if (response.status == 200) {
                                const value = response?.data?.data?.image;
                                setTenantItem({...tenantItem,returned_verification_picture:value});
                                  
                              }

                              }
                            }
                            }
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
                  <div class="w-full">
                    <hr />
                    <div className="h5 text-center">Return Verification</div>
                          {tenantItem.loanable_item_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Item Loaned:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              {/* <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-loaned-return`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={loanableItem?.map((col) =>
                                  tenantItem?.loanable_item_id == col?.id
                                    ? col?.name.split(",").join("")
                                    : ""
                                )}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              /> */}
                              <span>{loanableItem?.map((item)=>{
                                if(tenantItem?.loanable_item_id == item?.id)
                                {
                                  return item?.name
                                }
                              })}</span>
                            </div>
                          </div>:null}
                          {tenantItem.is_lost_damaged != '' ? <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Lost,Destroyed or Damaged:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              {/* <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-lost-return`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={tenantItem?.is_lost_damaged}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              /> */}
                              {tenantItem?.is_lost_damaged == 0 ? <i className="fa fa-remove"></i> :<span>{tenantItem?.is_lost_damaged == 1 ? "Lost" : tenantItem?.is_lost_damaged == 2 ? "Destroyed" :tenantItem?.is_lost_damaged == 3 ? "Damaged" :""}</span>}
                            </div>
                          </div>:null}
                          {tenantItem.loanable_item_id != '' ? <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Replacement Cost:
                              </label>
                            </div>
                            <div className="md:w-1/2">
                              {/* <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-replacement-cost-return`}
                                type="text"
                                placeholder="Steve"
                                disabled={true}
                                required={false}
                                value={loanableItem?.map((col) =>
                                    tenantItem?.loanable_item_id == col?.id
                                    ? col?.replacement_cost
                                    : " "
                                )}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              /> */}
                              <span>{loanableItem?.map((item)=>{
                                if(tenantItem?.loanable_item_id == item?.id)
                                {
                                  return item?.replacement_cost
                                }
                              })}</span>
                            </div>
                          </div>:null}
                          {tenantItem.replacement_reason != '' ? 
                          <>
                          {/* <div className="md:flex md:items-center w-full mt-3">
                            </div> */}
                          <div className="md:flex md:items-center w-full mt-3 mb-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Notes:
                              </label>
                          </div>
                            <div className="md:w-full">
                              {/* <CKEditor
                                initData={tenantItem?.replacement_reason}
                                config={{
                                  readOnly: true,
                                }}
                                // onChange={(e) => {
                                //   loanable_items_trackings[index].returned_date =
                                //     e.target.value;
                                //   setTenantItem(loanable_items_trackings);
                                //   setFormData({
                                //     ...formData,
                                //     loanable_items_trackings: loanable_items_trackings,
                                //   });
                                // }}
                              /> */}
                              <span>{tenantItem?.replacement_reason}</span>
                            </div>
                          </div>
                          </>
                          :null}
                          {tenantItem.returned_verification_signature != '' ?
                          
                          <>
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">

                            <img src={`${imgUrl}/${tenantItem?.returned_verification_signature}`} alt="Tenant Signature" />
                          </div>
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <span>Tenant Signature</span>
                          </div>
                            </>:null
                          }
                          {/* {tenantItem.returned_verification_signature == '' ?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              disabled
                              onClick={ItemVerificationReturnClear}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              disabled
                              onClick={async()=>{

                                const itemVerificationReturnData = ItemVerificationReturn.current.getCanvas().toDataURL();
                                const res = await ApiRequest.post(imageApi, { image: itemVerificationReturnData });
                                if(res.status == 200)
                                {
                                  const value = res?.data?.data?.image
                                  setTenantItem({...tenantItem,returned_verification_signature:value});
                                  
                               
                                }
                              }}
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Save
                            </button>
                          </div>:
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                          <button
                            type="button"
                            disabled
                            onClick={()=>{
                                setTenantItem({...tenantItems,returned_verification_signature:''})
                            }}
                            class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          >
                            Edit
                          </button>
                          </div>} */}
                          {/* <div className="md:flex md:items-center w-full mt-3 ">
                            <div className="md:w-2/4 flex flex-row-reverse">
                                <button 
                                className="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded"
                                onClick ={clear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div> */}
                          <div className="md:items-center w-full mt-3 ">
                            {tenantItem.returned_verification_picture != '' ?
                            
                            <>
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">

                            <img src={`${imgUrl}/${tenantItem?.returned_verification_picture}`} alt="Tenant Picture" />
                            </div>
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <span>Tenant Picture</span>
                          </div>
                            </>:null
                            }
                          </div>
                          {/* {tenantItem.returned_verification_picture == '' ?
                          
                          <div className="flex justify-center">
                            <button
                              class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={ReturnItemTenantCapture}
                              type="button"
                              disabled
                            >
                              Capture photo
                            </button>
                          </div> :
                          <div className="flex justify-center">
                          <button
                            class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={()=>{
                                setTenantItem({...tenantItem,returned_verification_picture:''})
                            }}
                            disabled
                            type="button"
                          >
                            Take Again
                          </button>
                        </div>
                          } */}
                        <hr />
                        </div>
                  {tenantItem.notes != '' ? <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      {/* <CKEditor
                        initData={editorData}
                        // onChange={(e) => {
                        //   setEditorData(e.editor.getData());
                        // }}
                        config={{
                          readOnly:true
                        }}
                      /> */}
                      <span>{tenantItem?.notes}</span>
                    </div>
                  </div>:null}
                </div>
              </div>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantItem([]);
                                setTenantItemFiles([]);
                                setTenantItemPicture([]);
                                setTenantItemReturnPicture([]);
                                setTenantItemGivenPicture([]);
                                setTenantItemRetrunVerificationPicture([]);
                                setEditorData(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/assets-loanable-items.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Loanable Items:
              </label>
              <Tooltip data={"Track items such as remotes that you loan out to the Tenant, when they are returned (or if they are lost/damaged), and process lost/damaged item invoices"}/>
            </div>
          </div>

          {showForm == false ?
          <div className="flex flex-row-reverse justify-between items-center mt-3">
            <div className="flex justify-between items-center gap-4">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true);
                  setTenantItem(initialItemData);
                  setEditorData(" ")
                }}
              >
                <i class="fa fa-plus"></i> Add Item
              </button>
            </div>
          </div>
           :null}
              {showForm == true ? <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Item: <span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_id`}
                          value={tenantItem?.loanable_item_id}
                          onChange={(e) => {
                            setTenantItem({...tenantItem,loanable_item_id:parseInt(e.target.value)});
                          }}
                        >
                            <option value='' selected>Select Item</option>
                          {loanableItem.map((item) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Serial Number:
                        </label>
                      </div>
                      <div className="md:w-1/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_serial_number_id`}
                          value={tenantItem?.loanable_item_serial_number_id}
                          onChange={(e) => {
                            
                            setTenantItem({...tenantItem,loanable_item_serial_number_id:parseInt(e.target.value)});
                            
                          }}
                        >
                          <option value="">Select</option>
                          {loanableItem.map((item) =>
                            item?.id == tenantItem?.loanable_item_id
                              ? item?.serial_numbers.map((number) => (
                                  <option value={number?.id}>
                                    {number?.serial_number}
                                  </option>
                                ))
                              : null
                          )}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `loanable_item_serial_number_input`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("loanable-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`loanable_item_serial_number_input`}
                          type="text"
                          value={newSerialNumber.serial_numbers}
                          hidden={true}
                          placeholder="Ae123"
                          onInput={(e) => {
                            const serialNo = [];
                            serialNo.push(e.target.value);
                            setNewSerialNumber({
                              ...newSerialNumber,
                              serial_numbers: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newSerialNumber.id
                                ? newSerialNumber
                                : {
                                    loanable_item_id: tenantItem?.loanable_item_id,
                                    serial_number:
                                      newSerialNumber.serial_numbers,
                                  };
                              addSerialNumber(payload);
                              console.warn("payload", payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date Given: <span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <DatePicker
                          value={stringToDate(tenantItem?.assigned_date)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            setTenantItem({...tenantItem,assigned_date:date});
                            
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-7/12">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Given Pictures
                            </span>
                            <input
                              accept={picTypes}
                              id={`t-return-pictures`}
                              type="file"
                              multiple={true}
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemGivenPicture?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemGivenPicture.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const imgBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(imageApi, {
                                        image: imgBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        picture: res?.data?.data?.image,
                                        order_number: maxOrderNumber + i + 1 + tenantItemGivenPicture.length,
                                        url:imgBase64,
                                        is_cover: i === 0 ? 1 : 0,
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let pictureLists = [...tenantItemGivenPicture];
                                        pictureLists = [...pictureLists, ...newImages];
                                        setTenantItemGivenPicture(pictureLists);
                                        let tenantCopy = [...tenantItemGivenPicture];
                                        tenantCopy.pictures = pictureLists.map(
                                          (picObj) => ({
                                            picture: picObj.picture,
                                            order_number: picObj.order_number,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, item_given_pictures: tenantCopy?.pictures });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row-reverse justify-between items-center mt-3">
                    <div className="flex justify-between items-center gap-4">
                      <button
                        type="button"
                        class="btn3"
                        data-bs-toggle="modal"
                        data-bs-target={`#staticItemVerificationBackdrop`}
                      >
                        Add Verification
                      </button>
                      {tenantItem?.assigned_verification_picture || tenantItem?.assigned_verification_signature != "" ? <i className="fa fa-check text-green-600"></i> : null}
                    </div>
                  </div>
                  <div
                      class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                      id={`staticItemVerificationBackdrop`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticItemVerificationBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-xl w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5
                            class="text-xl font-medium leading-normal text-gray-800"
                            id="exampleModalLabel"
                          >
                            Add Verification
                          </h5>
                          <button
                            type="button"
                            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body relative p-4">
                        <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        I confirm that I have received the following item(s), on loan, until the end of my lease/term. I acknowledge that I will be responsible for the replacement cost/damages for any items that were deemed lost or returned damaged beyond expected wear and tear. 
                        </label>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Item Loaned:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-loaned`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={loanableItem?.map((col) => col?.id === tenantItem?.loanable_item_id ? col?.name : null).filter(Boolean).join(", ")}

                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3 mb-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Replacement Cost:
                              </label>
                            </div>
                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-replacement-cost`}
                                type="text"
                                placeholder="Steve"
                                disabled={true}
                                required={false}
                                value={convertCurrencyToFormat(ownerSetting?.currency_format,loanableItem?.map((col) => col?.id === tenantItem?.loanable_item_id ? col?.replacement_cost : null).filter(Boolean).join(", "))}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          {tenantItem.assigned_verification_signature == "" ?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">

                          <ReactSignatureCanvas
                            ref={ItemVerification}
                            canvasProps={{
                              width: 450,
                              height: 200,
                              className: "sigCanvas",
                            }}
                            backgroundColor="#e5e7eb"
                            />
                            </div>
                            :
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <img src={`${imgUrl}/${tenantItem?.assigned_verification_signature}`} alt="Tenant Signature" />
                            </div>
                          }
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <span>Tenant Signature</span>
                          </div>
                          {tenantItem.assigned_verification_signature == ""?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              onClick={ItemVerificationClear}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={async()=>{

                                const itemVerificationData = ItemVerification.current.getCanvas().toDataURL();
                                const res = await ApiRequest.post(imageApi, { image: itemVerificationData });
                                if(res.status == 200)
                                {
                                  const value = res?.data?.data?.image
                                  setTenantItem({...tenantItem,assigned_verification_signature:value});
                                  
                               
                                }
                              }}
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Save
                            </button>
                          </div>:<div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              onClick={()=>{
                                setTenantItem({...tenantItem,assigned_verification_signature:''})
                              }}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Edit
                            </button>
                        </div>}
                          {/* <div className="md:flex md:items-center w-full mt-3 ">
                            <div className="md:w-2/4 flex flex-row-reverse">
                                <button 
                                className="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded"
                                onClick ={clear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div> */}
                          {tenantItem.assigned_verification_picture == ""?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            
                            {showCamera == false ? 
                            <div className="flex justify-center">
                              <button
                                class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                onClick={()=>{
                                  setShowCamera(true)
                                }}
                                type="button"
                              >
                                Capture photo
                              </button>
                            </div>:
                            <Webcam
                              audio={false}
                              height={600}
                              screenshotFormat="image/jpeg"
                              ref={ItemWebcamRef}
                              width={600}
                              videoConstraints={videoConstraints}
                            />}
                            </div>
                            :
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <img src={`${imgUrl}/${tenantItem?.assigned_verification_picture}`} alt="Tenant Signature" />
                            </div>
                            }
                            <>
                          {tenantItem.assigned_verification_picture == ""?
                          <div className="flex justify-center">
                            {showCamera == true ? <button
                              class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={ItemTenantCapture}
                              type="button"
                            >
                              Take photo
                            </button>:null}
                          </div>:
                          <div className="flex justify-center">
                          <button
                            class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={()=>{
                                setTenantItem({...tenantItem,assigned_verification_picture:''});
                            }}
                            type="button"
                          >
                            Take Again
                          </button>
                        </div>
                          }</>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            
                            // onClick={async()=>{
                            //   const canvas = document.createElement("canvas");
                            //   const ctx = canvas.getContext("2d");

                            //   // Create an image element
                            //   const img = new Image();
                            //   const image = "";
                            //   img.src = itemImgSrc;

                            //   // Draw the image on the canvas
                            //   img.onload = async function () {
                            //     canvas.width = img.naturalWidth;
                            //     canvas.height = img.naturalHeight;
                            //     ctx.drawImage(img, 0, 0);

                            //     // Add the timestamp
                            //     const timestamp = new Date().toUTCString() + " IP Address " + ip;
                            //     ctx.font = "10px Arial";
                            //     ctx.fillStyle = "white";
                            //     ctx.textAlign = "right";
                            //     ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
                            //     const imageData = canvas.toDataURL();
                            //   // console.warn("image",image)
                            //   const response = await ApiRequest.post(imageApi, { image: imageData });
                            //   if (response.status == 200) {
                            //     const value = response?.data?.data?.image;
                            //     setTenantItem({...tenantItem,assigned_verification_picture:value});
                                  
                            //   }

                            //   }
                            // }
                            // }
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div >
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantItemGivenPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                    
              {tenantItemGivenPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemGivenPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantItemGivenPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantItem.pictures = pictureLists;
                                setTenantItemGivenPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantItem({
                                  ...tenantItem,
                                  item_given_pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
        
                  </div>
                  {/* <div >
                    {tenantItemPicture[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <span>Verification Picture</span>
                        <div className="container w-full px-3 m-auto">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image: tenantItemPicture[index]?.image?.picture,
                              src: tenantItemPicture[index]?.url,
                              inputFieldId: `tenant-item-verification-picture`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictures = [...tenantItemPicture];
                                pictures[index] = { ...initialImageData };
                                loanable_items_trackings[
                                  index
                                ].assigned_verification_picture = {
                                  ...initialImageData,
                                };
                                setTenantItemPicture(pictures);
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div> */}

                  <hr />
                  <h5 className="mt-2">Item Return: </h5>

                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-3/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Lost, Damaged, or Destroyed:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`item-is_lost_damaged`}
                          value={tenantItem?.is_lost_damaged}
                          onChange={(e) => {
                            const is_lost = parseInt(e.target.value);
                            setTenantItem({...tenantItem,is_lost_damaged:is_lost});
                            
                          }}
                        >
                          <option value={0} selected>Select Option</option>
                          <option value={1}>Lost</option>
                          <option value={3}>Damaged</option>
                          <option value={2}>Destroyed</option>
                        </select>
                      </div>
                    </div>
                    {tenantItem?.is_lost_damaged != 0 ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Date:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <DatePicker
                            value={stringToDate(tenantItem?.lost_damaged_date)}
                            onChange={(e) => {
                              const date = formatDate(e);
                              
                              setTenantItem({...tenantItem,lost_damaged_date:date});
                              
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {tenantItem?.is_lost_damaged != "0" ? (
                    <>
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:flex md:w-1/2">
                          <div className="md:w-full">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Auto-Generate & Send Replacement Cost Invoice:
                            </label>
                          </div>
                          <label class="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value={tenantItem?.send_replacement_cost_invoice}
                              onChange={(e) => {
                                const checkedValue =
                                  e.target.checked == true ? 1 : 0;
                               
                                setTenantItem({...tenantItem,send_replacement_cost_invoice:checkedValue});
                                
                              }}
                              class="sr-only peer"
                            />
                            <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.8 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                        </div>
                        
                      </div>
                      <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-7/12 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Damage Fees or Additional Fees:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                          <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="replacement-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantItem?.replacement_additional_fees != null ? tenantItem?.replacement_additional_fees : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantItem({ ...tenantItem, replacement_additional_fees: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantItem({ ...tenantItem, replacement_additional_fees: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-6/12 mb-6 ml-3">
                          <div className="md:w-1/4 flex">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Reason:
                            </label>
                              <Tooltip data={" It is helpful to give a reason or explanation for the damage fee or the additional fee you are charging them, EXAMPLE: You had to replace a broken faceplate/etc."}/>
                          </div>
                          <div className="md:w-2/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="replacement_reason"
                              type="text"
                              placeholder="Reason"
                              required={false}
                              value={tenantItem?.replacement_reason}
                              onChange={(e) => {
                                setTenantItem({...tenantItem,replacement_reason:e.target.value});
                                
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row-reverse md:items-center md:justify-start md:w-full mt-3">
                        <div className="md:w-1/5">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Files
                            </span>
                            <input
                              id={`t-key-files`}
                              type="file"
                              accept={fileTypes}
                              multiple={true}
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemFiles?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemFiles.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const fileBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(fileApi, {
                                        file: fileBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        file: res?.data?.data?.file,
                                        title: res?.data?.data?.file,
                                        url:fileBase64
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let fileLists = [...tenantItemFiles];
                                        fileLists = [...fileLists, ...newImages];
                                        setTenantItemFiles(fileLists);
                                        let tenantCopy = [...tenantItemFiles];
                                        tenantCopy.files = fileLists.map(
                                          (picObj) => ({
                                            file: picObj.file,
                                            title: picObj.title,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, replacement_files: tenantCopy?.files });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                        <div className="md:w-1/2">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              {(() => {
                                const filesCount =
                                  tenantItemFiles?.length || 0;
                                return filesCount > 1
                                  ? filesCount + " Documents"
                                  : filesCount == 1
                                  ? filesCount + " Document"
                                  : null;
                              }).call()}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div>
              {tenantItemFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantItemFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantItem.replacement_files = fileLists;
                                setTenantItemFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...tenantItemFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    tenantItem.replacement_files[fileIndex].title = title;
                                    setTenantItemFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                    </>
                  ) : null}
                  <div className="md:flex md:items-center mt-3">
                    {tenantItem?.lost_damaged_date == "" ?
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date Returned:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <DatePicker
                          value={stringToDate(tenantItem?.returned_date)}
                          onChange={(e) => {
                            setTenantItem({...tenantItem,returned_date:formatDate(e)});
                            
                          }}
                        />
                      </div>
                    </div>:null}
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-7/12">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Return Pictures
                            </span>
                            <input
                              accept={picTypes}
                              id={`t-return-pictures`}
                              type="file"
                              multiple={true}
                              hidden={true}
                              onChange={(e) => {
                                let files = e.target.files;
                                let newImages = [];
                                let maxOrderNumber = 0;
                              
                                // Find the highest order number in the current images
                                tenantItemReturnPicture?.forEach((imageObject) => {
                                  if (imageObject.order_number > maxOrderNumber) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                });
                              
                                // Calculate the total number of images (existing images + new images)
                                const totalImages = tenantItemReturnPicture.length + files.length;
                              
                                // Loop through each file and convert to base64
                                files.forEach((file, i) => {
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const imgBase64 = reader.result;
                                    try {
                                      const res = await ApiRequest.post(imageApi, {
                                        image: imgBase64,
                                      });
                              
                                      // Add the new image to the array with a unique order number
                                      newImages.push({
                                        picture: res?.data?.data?.image,
                                        order_number: maxOrderNumber + i + 1 + tenantItemReturnPicture.length,
                                        url:imgBase64,
                                        is_cover: i === 0 ? 1 : 0,
                                      });
                              
                                      // If all new images have been processed, update the state
                                      if (newImages.length === files.length) {
                                        let pictureLists = [...tenantItemReturnPicture];
                                        pictureLists = [...pictureLists, ...newImages];
                                        setTenantItemReturnPicture(pictureLists);
                                        let tenantCopy = [...tenantItemReturnPicture];
                                        tenantCopy.pictures = pictureLists.map(
                                          (picObj) => ({
                                            picture: picObj.picture,
                                            order_number: picObj.order_number,
                                          })
                                        );
                                        setTenantItem({ ...tenantItem, item_return_pictures: tenantCopy?.pictures });
                                      }
                                    } catch (err) {
                                      // console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <div className="flex justify-between items-center gap-4">
                          <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                              Add Verification
                            </span>
                            <input
                              accept={picTypes}
                              id={`item-return_verification_picture`}
                              type="file"
                              hidden={true}
                              onChange={async (e) => {
                                let file = e.target.files[0];
                                const reader = new FileReader();
                                reader.addEventListener("load", async () => {
                                  const imgBase64 = reader.result;
                                  try {
                                    console.log(imgBase64);
                                    const res = await ApiRequest.post(
                                      "/public/api/auth/upload-image",
                                      { image: imgBase64 }
                                    );
                                    // console.log("res", res?.data?.data?.image);
                                    loanable_items_trackings[
                                      index
                                    ].returened_verification_picture =
                                      res?.data?.data?.image;
                                    // setFeatureIcon(res?.data?.data?.image);
                                  } catch (err) {
                                    console.log(err);
                                  }
                                });
                                reader.readAsDataURL(file);

                                // console.log("here 142");
                                const pictures = [...tenantItemPicture];
                                pictures[index] = getImagesAndURLs(e)[0];
                                setTenantItemRetrunVerificationPicture(
                                  pictures
                                );
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div> */}
                  <div >
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantItemReturnPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                    {tenantItemReturnPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantItemReturnPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantItemReturnPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantItem.item_return_pictures = pictureLists;
                                setTenantItemReturnPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantItemReturnPicture({
                                  ...tenantItem,
                                  item_return_pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
                  </div>
                  {/* <div >
                    {tenantItemRetrunVerificationPicture[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[30%]">
                        <span>Return Verification Picture</span>
                        <div className="container w-full">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image:
                                tenantItemRetrunVerificationPicture[index]
                                  ?.image?.picture,
                              src: tenantItemRetrunVerificationPicture[index]
                                ?.url,
                              inputFieldId: `tenant-item-return-verification-picture`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictures = [
                                  ...tenantItemRetrunVerificationPicture,
                                ];
                                pictures[index] = { ...initialImageData };
                                loanable_items_trackings[
                                  index
                                ].returened_verification_picture = {
                                  ...initialImageData,
                                };
                                setTenantItemRetrunVerificationPicture(
                                  pictures
                                );
                                setTenantItem(loanable_items_trackings);
                                setFormData({
                                  ...formData,
                                  loanable_items_trackings:
                                    loanable_items_trackings,
                                });
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div> */}
                  <div className="flex flex-row-reverse justify-between items-center mt-3">
                    <div className="flex justify-between items-center gap-4">
                      <button
                        type="button"
                        class="btn3"
                        data-bs-toggle="modal"
                        data-bs-target={`#staticItemReturnVerificationBackdrop`}
                      >
                        Add Verification
                      </button>
                      {tenantItem?.returned_verification_picture || tenantItem?.returned_verification_signature != "" ? <i className="fa fa-check text-green-600"></i> : null}
                    </div>
                  </div>
                  <div
                      class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                      id={`staticItemReturnVerificationBackdrop`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticItemReturnVerificationBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-xl w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5
                            class="text-xl font-medium leading-normal text-gray-800"
                            id="exampleModalLabel"
                          >
                            Add Verification
                          </h5>
                          <button
                            type="button"
                            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body relative p-4">
                              <label className="block tracking-wide text-gray-700 font-bold mb-2">
                              I confirm that I have returned the following item(s). I acknowledge that I am responsible for the replacement cost/damages for any items that were deemed lost or returned damaged beyond expected wear and tear. 
                                </label>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Item Loaned:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-loaned-return`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={loanableItem?.map((col) => col?.id === tenantItem?.loanable_item_id ? col?.name : null).filter(Boolean).join(", ")}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Lost/Destroyed:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-lost-return`}
                                type="text"
                                placeholder="Steve"
                                required={false}
                                disabled={true}
                                value={tenantItem?.is_lost_damaged == 0 ? "" : tenantItem?.is_lost_damaged == 1 ? "Lost" : tenantItem?.is_lost_damaged == 2 ? "Destroyed" :tenantItem?.is_lost_damaged == 3 ? "Damaged" :""}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Replacement Cost:
                              </label>
                            </div>
                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`item-replacement-cost-return`}
                                type="text"
                                placeholder="Steve"
                                disabled={true}
                                required={false}
                                value={convertCurrencyToFormat(ownerSetting?.currency_format,loanableItem?.map((col) => col?.id === tenantItem?.loanable_item_id ? col?.replacement_cost : null).filter(Boolean).join(", "))}
                                // onChange={(e) => {
                                //   const Date = formatDate(e.target.value)
                                //   notifications[index].posting_date = Date;
                                //   setTenantInteraction(notifications);
                                //   setFormData({
                                //     ...formData,
                                //     notifications: notifications,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Notes:
                              </label>
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full mt-3 mb-3">
                            <div className="md:w-full">
                              <CKEditor
                                initData={tenantItem?.replacement_reason}
                                config={{
                                  readOnly: true,
                                }}
                                // onChange={(e) => {
                                //   loanable_items_trackings[index].returned_date =
                                //     e.target.value;
                                //   setTenantItem(loanable_items_trackings);
                                //   setFormData({
                                //     ...formData,
                                //     loanable_items_trackings: loanable_items_trackings,
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          {tenantItem.returned_verification_signature == '' ?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                          <ReactSignatureCanvas
                            ref={ItemVerificationReturn}
                            canvasProps={{
                              width: 450,
                              height: 200,
                              className: "sigCanvas",
                            }}
                            backgroundColor="#e5e7eb"
                          /></div> :
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <img src={`${imgUrl}/${tenantItem?.returned_verification_signature}`} alt="Tenant Signature" />
                            </div>
                          }
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <span>Tenant Signature</span>
                          </div>
                          {tenantItem.returned_verification_signature == '' ?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <button
                              type="button"
                              onClick={ItemVerificationReturnClear}
                              class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              onClick={async()=>{

                                const itemVerificationReturnData = ItemVerificationReturn.current.getCanvas().toDataURL();
                                const res = await ApiRequest.post(imageApi, { image: itemVerificationReturnData });
                                if(res.status == 200)
                                {
                                  const value = res?.data?.data?.image
                                  setTenantItem({...tenantItem,returned_verification_signature:value});
                                  
                               
                                }
                              }}
                              class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            >
                              Save
                            </button>
                          </div>:
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                          <button
                            type="button"
                            onClick={()=>{
                                setTenantItem({...tenantItems,returned_verification_signature:''})
                            }}
                            class="inline-block px-6 mx-3 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          >
                            Edit
                          </button>
                          </div>}
                          {/* <div className="md:flex md:items-center w-full mt-3 ">
                            <div className="md:w-2/4 flex flex-row-reverse">
                                <button 
                                className="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded"
                                onClick ={clear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div> */}
                            {tenantItem.returned_verification_picture == '' ?
                          <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            {showReturnCamera == false ? 
                            <div className="flex justify-center">
                            <button
                              class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={()=>{
                                setShowReturnCamera(true)
                              }}
                              type="button"
                            >
                              Capture photo
                            </button>
                              </div>
                              :
                            <Webcam
                              audio={false}
                              height={600}
                              screenshotFormat="image/jpeg"
                              ref={returnItemWebcamRef}
                              width={600}
                              videoConstraints={videoConstraints}
                            />}
                          </div>
                          :
                            <div className="md:flex md:items-center w-full mt-3 flex justify-center">
                            <img src={`${imgUrl}/${tenantItem?.returned_verification_picture}`} alt="Tenant Signature" />
                            </div>
                            }
                            <>
                          {tenantItem.returned_verification_picture == '' ?
                          
                          <div className="flex justify-center">
                            {showReturnCamera == true ?  
                            <button
                              class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                              onClick={ReturnItemTenantCapture}
                              type="button"
                            >
                              Take photo
                            </button>
                            :null}
                          </div> :
                          <div className="flex justify-center">
                          <button
                            class="mt-2  inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={()=>{
                                setTenantItem({...tenantItem,returned_verification_picture:''})
                            }}
                            type="button"
                          >
                            Take Again
                          </button>
                        </div>
                          }
                          </>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            
                            // onClick={async()=>{
                            //   const canvas = document.createElement("canvas");
                            //   const ctx = canvas.getContext("2d");

                            //   // Create an image element
                            //   const img = new Image();
                            //   const image = "";
                            //   img.src = returnItemImgSrc;

                            //   // Draw the image on the canvas
                            //   img.onload = async function () {
                            //     canvas.width = img.naturalWidth;
                            //     canvas.height = img.naturalHeight;
                            //     ctx.drawImage(img, 0, 0);

                            //     // Add the timestamp
                            //     const timestamp = new Date().toUTCString() + " IP Address " + ip;
                            //     ctx.font = "10px Arial";
                            //     ctx.fillStyle = "white";
                            //     ctx.textAlign = "right";
                            //     ctx.fillText(timestamp, canvas.width - 10, canvas.height - 10);
                            //     const imageData = canvas.toDataURL();
                            //   // console.warn("image",image)
                            //   const response = await ApiRequest.post(imageApi, { image: imageData });
                            //   if (response.status == 200) {
                            //     const value = response?.data?.data?.image;
                            //     setTenantItem({...tenantItem,returned_verification_picture:value});
                                  
                            //   }

                            //   }
                            // }
                            // }
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row-reverse justify-between items-center mt-3">
                    <div className="flex justify-between items-center gap-4">
                    <button className="btn3"
                      type="button"
                      onClick={() => {
                        
                        if (indexEdit !== undefined) {
                          // setTenantKey({...tenantKey,notes:editorData});
                          if(tenantItem?.loanable_item_id == '' && tenantItem?.assigned_date == '')
                          {
                            Toast.fire({
                              title:"Please Select Loanable Item and Date",
                              icon:"warning",
                              
                            })
                          }
                          else if(tenantItem?.loanable_item_id == '')
                          {
                            Toast.fire({
                              title:"Please Select Loanable Item",
                              icon:"warning",
                              
                            })
                          }
                          else if(tenantItem?.assigned_date =='')
                          {
                            Toast.fire({
                              title:"Please Select Date",
                              icon:"warning",
                              
                            })
                          }
                          else
                          {
                          editSpacesData();
                        }
                        } else {
                          // setTenantKey({...tenantKey,notes:editorData});
                          if(tenantItem?.loanable_item_id == '' && tenantItem?.assigned_date == '')
                          {
                            Toast.fire({
                              title:"Please Select Loanable Item and Date",
                              icon:"warning",
                              
                            })
                          }
                          else if(tenantItem?.loanable_item_id == '')
                          {
                            Toast.fire({
                              title:"Please Select Loanable Item",
                              icon:"warning",
                              
                            })
                          }
                          else if(tenantItem?.assigned_date =='')
                          {
                            Toast.fire({
                              title:"Please Select Date",
                              icon:"warning",
                              
                            })
                          }
                          else
                          {
                          bulkData();
                          }
                        }
                      }}>
                        {indexEdit !== undefined ? (
                          <><i className='fa fa-edit'></i>Update</>

                ) : (
                    <> <i className='fa fa-save'></i>Save Item Information</>

                )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>:null}
              
              <hr />

{tenantItems?.length > 0 ?
<>
 <div className="flex flex-row-reverse mt-3">
<img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
<img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
</div>
<DataTableExtensions {...tableData}>

<DataTable
className="striped"
columns={columns}
data={tenantItems}
pagination
paginationRowsPerPageOptions={[10, 25, 50, 100]}
fixedHeader
fixedHeaderScrollHeight="500px"
highlightOnHover
sortFieldId={indexEdit}
sortDirection = "asc"
/>
</DataTableExtensions>
{/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
<div className="md:w-2/4">
  <label className="block capitalize text-gray-700 font-bold mb-2">
    Bulk Actions:
  </label>
</div>
<div className="md:w-3/4">
  <select
    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
    id="bulk-actions"
    value={bulkAction.bulk_action}
    onChange={(e) => {
      setBulkAction({ ...bulkAction, bulk_action: e.target.value });
    }}
  >
    <option selected disabled>Select</option>
    <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
    <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
  </select>
</div>
<div className="w-2/4 pl-5 ml-5 mt-2 ">
   <button
      type="button"
      className="btn3"
      onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
    >
      <i className="fa fa-arrow-alt-circle-right"></i>
      GO
    </button>
</div>
</div> */}
</>:null} 
    </>
  )
}
