import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import TabPanel from '../../property/TabPanel';
import { CKEditor } from 'ckeditor4-react';

export const AddPlanForm = () => {

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
        <form
        className="w-full"
        id="add-property-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Plan Information" {...a11yProps(0)} />
          <Tab label="Features" {...a11yProps(1)} />
          <Tab label="Price" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
                <div className="md:flex md:items-center w-1/2">
                <div className="md:w-1/2">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Plan Name:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="plan-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2">
                <div className="md:w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="plan-type"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  >
                    <option value="">Select Type</option>
                    </select>
                </div>
                </div>
                <div className="md:flex md:items-center w-1/5 ml-2">
                <div className="md:w-2/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Active:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
            </div>
            <div className="md:flex w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Plan Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    // initData={editorData?.mortage_notes}
                    // onChange={(e) => {
                    //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    //   setEditorData({
                    //     ...editorData,
                    //     mortage_notes: cleanText,
                    //   });
                    // }}
                  />
                </div>
            </div>
            <div className="md:flex md:items-center w-full mt-6">
            <div className="md:flex md:items-center w-1/3">
                <div className="md:w-2/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Featured:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
                <div className="md:flex md:items-center w-1/2 ml-2">
                <div className="md:w-1/2">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Featured Title:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-full">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="plan-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2">
                <div className="md:w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Color:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input id="nativeColorPicker1" type="color" value="#6590D5" />
                </div>
                </div>

            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:flex md:items-center w-1/8">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Storage:
                    </label>
                  </div>
                  <div className="md:w-1/6 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-1/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
                  <div className="md:w-1/3 ml-2">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  >
                    <option value="">Select Storage</option>
                    </select>
                  </div>
              </div>
              
            </div>
            <div className="md:flex md:items-center w-full mt-6">
              <div className="md:flex md:items-center w-full">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Properties:
                    </label>
                  </div>
                  <div className="md:w-1/6 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-1/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
                  <div className="md:flex md:items-center w-2/5 ml-2">
                <div className="md:w-3/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Property Managers:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
                <div className="md:w-1/6 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-1/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
              </div>
            </div>
              <div className="md:flex md:items-center w-full mt-6">
              <div className="md:flex md:items-center w-full">
              <div className="md:flex md:items-center w-2/5 ml-2">
                <div className="md:w-3/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Companies:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
                <div className="md:w-1/8 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-2/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
              <div className="md:flex md:items-center w-2/5 ml-2">
                <div className="md:w-3/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Co-Owners:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
                <div className="md:w-1/8 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-2/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
                  </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-6">
              <div className="md:flex md:items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Units:
                    </label>
                  </div>
                  <div className="md:w-1/6 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-1/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
                  </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-6">
              <div className="md:flex md:items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tenants:
                    </label>
                  </div>
                  <div className="md:w-1/6 ml-2">
                    <div className="md:flex flex-wrap md:items-center">
                      <div className="md:w-1/5 form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                          // id={`feature_type-${index}`}
                          type="checkbox"
                          />
                      </div>
                      <div className="md:w-auto">
                        <img
                          // src={`${imgUrl}/${feature.icon}`}
                          width={"30px"}
                          height={"auto"}
                        ></img>
                      </div>
                  </div>
                  </div>
                  <div className="md:w-1/5 ml-2">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="storage-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                  </div>
                  </div>
                  </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="md:flex md:items-center w-full mt-6">
            <div className="md:flex md:items-center w-1/2">
                <div className="md:w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Monthly:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="plan-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                </div>
                </div>
            <div className="md:flex md:items-center w-1/2 ml-2">
                <div className="md:w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Yearly:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="plan-name"
                    type="text"
                    placeholder="999"
                    // value={formData?.name}
                    required={false}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, name: e.target.value });
                    // }}
                  />
                </div>
                </div>
            </div>
        </div>
        </TabPanel>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn3"
                //   onClick={() => {
                //     if (indexEdit !== undefined) {
                //       editSpacesData();
                //     } else {
                //       bulkData();
                //     }
                //   }}
                >
                    <><i className='fa fa-save'></i>Save</>
                  
                </button>
              </div>
            </div>
            </div>
        </form>
    </>
  )
}
