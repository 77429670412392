import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import RolesTable from "../../components/userTable/RolesTable";
import { userRolesThunk } from "../../store/userRoles/Thunk";
import { CKEditor } from "ckeditor4-react";
import DataTable from "react-data-table-component";
import { useTable, usePagination, useSortBy} from "react-table";
import { Header1 } from "../../components/Header/Header";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { FiEdit2 } from "react-icons/fi";
import pdf from "../../Images/pdf.png";
import csv from "../../Images/csv.png";
export const Budget = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const userRoles = useSelector((state) => {
    return state?.UserRoles?.Roles?.data?.roles;
  });
  // const status = useSelector((state) => {
  //   return state?.UserRoles?.status;
  // });
  const dispatch = useDispatch();

  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);

    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  
  const columns = useMemo(
    () => [
      {
        Header: "Budget Account",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Line Item",
        accessor: "col2",
      },
      {
        Header: "Type",
        accessor: "col3",
      },
      {
        Header: "PREVIOUS_YEAR Balance",
        accessor: "col4",
      },
      {
        Header: "SELECTED_YEAR Budget",
        accessor: "col5",
      },
      {
        Header: "Expenses to Date",
        accessor: "col6",
      },
      {
        Header: "Income to Date",
        accessor: "col7",
      },
      {
        Header: "Balance to Date",
        accessor: "col8",
      },
      {
        Header: "Action",
        Cell: ({ cell }) => (
          <div className="flex justify-center align-items-center">
            <button
              class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalLgView"
            >
              {/* <i class="fa-solid fa-eyes mr-6"></i> */}
              <span>
                <GrView />
              </span>
            </button>
            <button
              class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalLg"
            >
              {/* <i class="fa-solid fa-pen mr-6"></i> */}
              <span>
                <FiEdit2 />
              </span>
            </button>
            <button class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800">
              {/* <i class="fa-regular fa-trash mr-6"></i> */}
              <span>
                <MdDeleteOutline />
              </span>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      {
        col1: "Maintenance",
        col2: "General Supplies",
        col3: "Expense",
        col4: "-$5665",
        col5: "-$5665",
        col6: "-$5665",
        col7: "-$5665",
        col8: "-$5665",
      },
      // {
      //   // col1: 'Expense',
      //   col1: 'General Supplies',
      // },
      // {
      //   // col1: 'PREVIOUS_YEAR Balance',
      //   col1: 'expense',
      // }
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
    // useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  return (
    <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/assets/admin/css/main.css" />
        <link
          rel="stylesheet"
          href="/assets/admin/css/fontawesome-all.min.css"
        />
        <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/slick.css" />
        <link rel="stylesheet" href="/assets/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Finance" user_type="super_admin" child="Budget" onChildMessage={handleChildMessage}/>
      <div
        className={`main-content ${collapsed ? "collapsed" : ""}`}
        onScroll={(e) => handleContentScroll(e)}
      >
            {/* header bar */}
        <Header1 />
    
       
        {/* search wraper  */}
        <section className="search-wraper">
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                  <h4>
                    Welcome Back <span>Anddy’s Makeover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*     View modal     */}
        <div
          class="modal fade fixed top-0 left-0 w-full h-full  outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalLgView"
          tabindex="-1"
          aria-labelledby="exampleModalLgLabel"
          aria-modal="true"
          aria-hidden="true"
          role="dialog"
        >
          <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              
                <button
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative pb-4 pt-4 px-4">
                <div className="flex justify-end align-items-center">
                  <div className="flex justify-between align-items-center">
                    <img
                      src={pdf}
                      alt=""
                      className="w-10 h-10 cursor-pointer	"
                    />
                    <img
                      src={csv}
                      alt=""
                      className="w-10 h-10 cursor-pointer	"
                    />
                    <button class="bg-gray-300 py-1 mr-2 w-40 flex justify-center align-items-center rounded text-black-800">
                      <span>Edit</span>
                    </button>
                  </div>
                </div>
                <div className="flex  align-items-center">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/finance-budget.png"
                    alt=""
                  />
                  <h2 className="text-3xl font-bold text-black py-3">
                    Budgeted_Account
                  </h2>
                </div>
                <div className="flex justify-between mt-3">
                  <div>
                    <p className="font-bold	">TYPE</p>
                    <p className="font-bold	">
                      Tracked in Budget Total :(Yes/No){" "}
                    </p>
                  </div>
                  <div>
                    <p className="font-bold	">Budget:Budget</p>
                    <p className="font-bold	">Balance : Budget_Balance</p>
                  </div>
                </div>

                <div className="mt-3">
                  <p className="text-2xl">Description : DESCRIPTION</p>
                  <p className="text-2xl	">
                    Access given to staff : ACCESS_STAFF
                  </p>
                </div>

                <div className="mt-8">
                  <p className="text-2xl m-0 mb-3">Line Items:</p>
                  <div className="text-2xl flex gap-3">
                    <p className=" text-2xl font-bold">Budget: Budget</p>
                    <p className=" text-2xl font-bold">Balance: Balance</p>
                  </div>
                  <div className="text-2xl flex gap-3">
                    <p className=" text-2xl font-bold">Budget: Child_Budget</p>
                    <p className=" text-2xl font-bold">
                      Balance: Child_Balance
                    </p>
                  </div>
                  <div className="text-2xl flex gap-3">
                    <p className=" text-2xl font-bold">Budget: Budget</p>
                    <p className=" text-2xl font-bold">Balance: Balance</p>
                  </div>
                </div>

                {/* <form className="w-full" id="add-property-form">
                  <div className="md:flex md:items-center gap-3 w-full mt-6">
                    <div className="md:w-5/12 md:flex items-center gap-3">
                      <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                        Name:
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa_zip_code-property-name"
                        type="text"
                        required={true}
                        value="Autogenrated"
                        // placeholder="Amount"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center gap-3 w-full mt-6">
                    <div className="md:flex md:items-center w-full gap-4 w-full mt-6">
                      <div className="md:w-full md:flex gap-3 mb-3  ">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Transaction Notes
                        </label>
                        <div className="w-100">
                          <CKEditor
                            initData={""}
                            // disabled
                            // value={contact?.info}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex md:items-center gap-3 w-full mt-6">
                    <div className="md:w-5/12 md:flex items-center gap-3 mb-3">
                      <label className=" w-2/4 block capitalize text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                      <select
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        aria-label=".form-select-lg example"
                      >
                        <option selected>Type</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center gap-3 w-full mt-6">
                    <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Track in budget total:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                        {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                {/* <input type="checkbox" value="" class="sr-only peer" />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                  <div className="md:flex md:items-center gap-3 w-full mt-6">
                    <div className="md:w-5/12 md:flex items-center gap-3 mb-3">
                      <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                        Give Access to:
                      </label>
                      <select
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        aria-label=".form-select-lg example"
                      >
                        <option selected>Give Access to</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex justify-content-end mt-4">
                    <input
                      id={"form-submit-button"}
                      type={"submit"}
                      value="Save"
                      hidden={false}
                      className="py-3 px-10 border-solid border-2  border-red-200 rounded"
                    />
                  </div>
                </form>} */}
              </div>
            </div>
          </div>
        </div>
        {/* Selected year modal */}
        <section>
          <div className="flex justify-between align-items-center  w-full">
            {/* Actions button*/}
            <div className="flex justify-between align-items-center  w-full">
              <div className="flex justify-start align-items-center">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/svgs/finance-budget.png"
                  alt=""
                />
                <h2 className="text-3xl font-bold text-black py-3">
                  Selected year budget
                </h2>
              </div>
              <div className="flex justify-between gap-2">
                <div className="md:auto md:flex items-center gap-3">
                  {/* <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                  Give Access to:
                </label> */}
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-2leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    aria-label=".form-select-lg example"
                  >
                    <option selected>Select Year</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <button
                  class="bg-gray-300 py-1 bg-gray-200 px-2 flex justify-center align-items-center rounded text-black-800"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalLg"
                >
                  <span>Add Budget Account</span>
                </button>

                <div className="md:auto md:flex items-center gap-3">
                  {/* <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                  Give Access to:
                </label> */}
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-2leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    aria-label=".form-select-lg example"
                  >
                    <option selected>Line Item</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="modal fade fixed top-0 left-0 w-full h-full  outline-none overflow-x-hidden overflow-y-auto"
              id="exampleModalLg"
              tabindex="-1"
              aria-labelledby="exampleModalLgLabel"
              aria-modal="true"
              aria-hidden="true"
              role="dialog"
            >
              <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    {/* <h5
                      class="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModalLgLabel"
                    >
                      Add Role
                    </h5> */}
                    <button
                      type="button"
                      class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body relative pb-4 pt-2 px-4">
                    <div className="flex  align-items-center">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/finance-budget.png"
                        alt=""
                      />
                      <h2 className="text-3xl font-bold text-black py-3">
                        Add/Edit budget account
                      </h2>
                    </div>
                    <form className="w-full" id="add-property-form">
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:w-5/12 md:flex items-center gap-3 mb-3">
                          <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Name:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            required={true}
                            value="Autogenrated"
                            // placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:flex md:items-center w-full gap-4 w-full mt-6">
                          <div className="md:w-full md:flex gap-3 mb-3  ">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Transaction Notes
                            </label>
                            <div className="w-100">
                              <CKEditor
                                initData={""}
                                // disabled
                                // value={contact?.info}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:w-5/12 md:flex items-center gap-3 mb-3">
                          <label className=" w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Type:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Type</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Track in budget total:
                          </label>
                          <label class="inline-flex relative items-center cursor-pointer">
                            {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                            <input
                              type="checkbox"
                              value=""
                              class="sr-only peer"
                            />
                            <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:w-5/12 md:flex items-center gap-3 mb-3">
                          <label className="w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Give Access to:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Give Access to</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex justify-content-end mt-4">
                        <input
                          id={"form-submit-button"}
                          type={"submit"}
                          value="Save"
                          hidden={false}
                          className="py-3 px-10 border-solid border-2  border-red-200 rounded"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="flex flex-col">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-x-auto">
                <table {...getTableProps()} class="min-w-full">
                  <thead class="bg-gray-50 border-b ">
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column) => (
                              // Apply the header cell props
                              <th
                                {...column.getHeaderProps()}
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-center"
                              >
                                {
                                  // Render the header
                                  column.render("Header")
                                }
                              </th>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </thead>
                  {/* Apply the table body props */}
                  <tbody {...getTableBodyProps()}>
                    {
                      // Loop over the table rows
                      page.map((row) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr
                            {...row.getRowProps()}
                            class="border-b transition duration-300 ease-in-out hover:bg-gray-100  "
                          >
                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => {
                                // Apply the cell props
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap  text-center"
                                  >
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </td>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                <div className="pagination flex justify-end items-center mt-4">
                  <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
                  >
                    {"<<"}
                  </button>{" "}
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
                  >
                    {"<"}
                  </button>{" "}
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
                  >
                    {">"}
                  </button>{" "}
                  <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
                  >
                    {">>"}
                  </button>{" "}
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <span>
                    {" "}
                    Go to page:{" "}
                    <input
                      type="number"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(page);
                      }}
                      style={{ width: "100px" }}
                    />
                  </span>{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <DataTable
          columns={columns}
          data={userRoles}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          responsive
          sortable={true}
          defaultSortFieldId={1}
        /> */}
        {/* <RolesTable userRoles={userRoles} status={status} /> */}
        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>
  );
};
