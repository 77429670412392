import React, { useState } from "react";
import { Card } from "./Card";
import { List } from "./List"; // Make sure to import your List component

export const ContactDetails = ({ data, ownerSettings }) => {
  const [view, setView] = useState("card");

  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <>
      <div className="flex justify-content-end mb-4">
        <button
        type="button"
          className={`flex flex-column items-center focus:outline-none ${
            view === "card" ? "text-blue-500" : "text-black"
          }`}
          onClick={() => handleViewChange("card")}
        >
          <i className={`fa fa-grid ${view === "card" ? "text-blue-500" : ""}`}></i>
          <span>Card</span>
        </button>
        <div className="w-px bg-gray-200 h-10 mx-4"></div>
        <button
        type="button"
          className={`flex flex-column items-center focus:outline-none ${
            view === "list" ? "text-blue-500" : "text-black"
          }`}
          onClick={() => handleViewChange("list")}
        >
          <i className={`fa fa-list ${view === "list" ? "text-blue-500" : ""}`}></i>
          <span>List</span>
        </button>
      </div>
      <div className="md:flex w-[90%] m-auto">
        {view === "card" ? <Card data={data} ownerSetting={ownerSettings} /> : <List data={data} ownerSetting={ownerSettings} />}
      </div>
    </>
  );
};
