import React from "react";
import "./style.css";

const CustomLoader = () => {
    return (
      <div class="loader-container">
        <div class="loader">
          <div class="cube1"></div>
          <div class="cube2"></div>
        </div>
      </div>

    );
  };

export default CustomLoader;
