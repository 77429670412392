import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import { Tooltip } from '../Loader/Tooltip';
// import { convertToGMT } from '../genericFunctions.js/TimeZone';
import useAllowedMimeTypes from '../genericFunctions.js/mimeType';
import { DatePicker } from '../genericFunctions.js/DatePicker';
export const Interactions = ({data,handleInteractionTrue,hiddenData,ownerSettings}) => {
    let fileApi = "/public/api/auth/upload-file";
    const fileTypes = useAllowedMimeTypes();
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [timeZone,setTimeZone] = useState([]);
    const [interaction,setInteraction] = useState([]);
    console.log('ownerSettings',ownerSettings);
    const [interactions,setInteractions] = useState([]);
    console.warn('interactions',interaction);
    const [interactionFiles,setInteractionFiles] = useState([]);
    const [interactionType, setInteractionType] = useState([]);
    const [showForm,setShowForm] = useState(false);
    const [viewForm,setViewForm] =useState(false);
    const [editorData,setEditorData] = useState("");
    const [ownerSetting,setOwnerSetting] = useState("");
    const [editorData1,setEditorData1] = useState("");
    const initialInteractionData = {
        date: "",
        time: "",
        interaction_type_id: "",
        other_type_description: "",
        notes: "",
        files: [],
      };
    const myDivRef = useRef(null);
    const notes = ()=>{
      const data = [];
      data.push(interaction);
      const array = [...data];
      // console.log('@array',array);
      const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
      array[0].other_type_description = cleanText;
      array[0].notes = editorData1;
      setInteraction(array);
    }
    const bulkData = ()=>{
        // setInteraction({...interaction,notes:editorData1,other_type_description:editorData1}); 
        notes();
        const newDataArray  = [];
        newDataArray.push(interaction);
        handleInteractionTrue(prevArray => [...prevArray, ...newDataArray]);
        setInteractions(prevArray => [...prevArray, ...newDataArray]);
        setInteraction(initialInteractionData);
        setInteractionFiles([]);
        // setEditorData('');
        setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...interactions];
        array[indexEdit] = interaction;
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
        array[indexEdit].other_type_description = cleanText;
        array[indexEdit].notes = editorData1;
        setInteractions(array);
    
        handleInteractionTrue(array);
        setShowForm(false);
        setEditorData(null)
        setEditorData1(null)
        setIndexEdit(undefined);
        setInteraction(initialInteractionData);
        setInteractionFiles([]);

      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setShowForm(true);
        setInteraction(interactions[index]);
        setInteractionFiles(interactions[index]?.files)
        setEditorData(interactions[index]?.other_type_description)
        setEditorData1(interactions[index]?.notes)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setInteraction(interactions[index]);
        setInteractionFiles(interactions[index]?.files)
        setEditorData(interactions[index]?.other_type_description)
        setEditorData1(interactions[index]?.notes)
        // setInteractionPictures(interactions[index]?.pictures)
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...interactions];
        newArray.splice(index, 1);
        setInteractions(newArray);
        setInteraction(initialInteractionData)
        handleInteractionTrue(newArray);
      }
      function convertTo12HourFormat(time) {
        // Splitting the time into hours and minutes
        var [hours, minutes] = time.split(':');
      
        // Parsing the hours and minutes into integers
        hours = parseInt(hours);
        minutes = parseInt(minutes);
      
        // Determining the period (AM or PM)
        var period = (hours >= 12) ? 'PM' : 'AM';
      
        // Converting the hours to 12-hour format
        if (hours === 0) {
          hours = 12; // 12 midnight
        } else if (hours > 12) {
          hours -= 12;
        }
      
        // Formatting the time with leading zeros for single-digit hours and minutes
        var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':00';
      
        // Combining the formatted time with the period
        formattedTime += ' ' + period;
        console.log("Format Time",formattedTime)
        return formattedTime;
      }
      
    const columns = [
        {
          name: "Date",
          selector: (data) => data.date!= "" ? ShowFormatDate(data.date,ownerSetting?.date_format):"",
          sortable: true,
        },
        {
          name: "Time",
          selector: (data) => data.time != "" ? ShowFormatTime(convertTo12HourFormat(reverseConvertToGMT(data.time)),ownerSetting?.time_format):"",
          sortable: true,
        },
        {
          name: "Type",
          selector: (data) => interactionType.map((row)=>{
            if(data.interaction_type_id == row?.id)
            {
                return row?.type
            }
          }),
          sortable: true,
        },
        {
          name: "",
          selector: (data) => data?.files?.length > 0 ? <img src="/assets/svgs/system-paperclip.png" className='w-1/6' alt="" title='File Uploaded'/> : '' ,
          sortable: true,
        },
        {
          name: "Notes",
          selector: (data) => {
            const cleanData = new DOMParser().parseFromString(data.notes, 'text/html').body.textContent
            return cleanData;
          },
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData != true ?(<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewInteractionForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
               
                 </div>):(<>
                  <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewInteractionForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                 </>)}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : interactions,
      };
      
      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Emergency Contacts";
        const headers = [["Date", "Time","Type","Notes"]];
    
        const data = interactions.map(elt => [elt.date,elt.time,interactionType.map((row)=>{
          if(elt.interaction_type_id == row?.id)
          {
            return row?.type
          }
        }) ,elt.notes])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("data.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = interactions.map(elt => [elt.date,elt.time,interactionType.map((row)=>{
            if(elt.interaction_type_id == row?.id)
            {
              return row?.type
            }
          }) ,elt.notes])
        // Convert the data to a CSV string
    
        const headers = [["Last Name", "First Name","Relationship","Number 1"]];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "data.csv");
        link.click();
      };
      const fileComponent = ({
        file = null,
        key = 0,
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
      }) =>
        file ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
          >
            <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
              <div
                title={file}
                key={key}
                className="block min-w-[600rem] px-2"
              >
                <a href={`${imgUrl}/${file}`} target="_blank">{file}</a>
              </div>
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
    
                      if (fileInputField) {
                        fileInputField.files.forEach((input_file) => {
                          if (input_file !== file) {
                            dataTransfer.items.add(input_file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null;
       
        const getInteractionTypes = async () => {
            const response = await ApiRequest.get(
              "/public/api/auth/get-interactions-types"
            );
            if (response.status == 200) {
              setInteractionType(response?.data?.data?.interactions_types);
            }
          };
          function stringToTime(timeString) {
            // console.warn("timeString",timeString)
            if (timeString === null || timeString === undefined || timeString >= 0)
              return;
            let parts = timeString.split(":");
            let ampm = timeString.split(" ");
            let hours = parts[0];
            let minutes = parts[1];
            let dayNight = ampm[1];
        
            let time = hours + ":" + minutes + " " + dayNight;
            let isPM = false;
        
            if (time.endsWith("am")) {
              isPM = false;
              time = time.replace(/\s(am|pm)/, "");
            } else {
              isPM = true;
              time = time.replace(/\s(am|pm)/, "");
              let timeArr = time.split(":");
              timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
              time = timeArr.join(":");
            }
            return time;
          }
      const formatAMPM = (date) => {
        // const time = date.toLocaleTimeString();
        const formattedDate = new Date();
        formattedDate.setHours(date.getHours());
        formattedDate.setMinutes(date.getMinutes());
        formattedDate.setSeconds(date.getSeconds());

        var Hours = formattedDate.getHours();
        var ampm = Hours >= 12 ? "pm" : "am";
        Hours = Hours % 12;
        Hours = Hours ? Hours : 12;
        Hours = ("0" + Hours).slice(-2);
        const minutes = ("0" + formattedDate.getMinutes()).slice(-2);
        const second = ("0" + formattedDate.getSeconds()).slice(-2);

        const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

        return TotalTime;
      };
      
      function convertToGMT(time, gmt, timeZone) {
        // Extract the GMT offset from the GMT string
        const gmtOffsetMatch = gmt.match(/([+-])(\d{2}):(\d{2})/);
        const gmtSign = gmtOffsetMatch[1] === '+' ? -1 : 1;
        const gmtHours = parseInt(gmtOffsetMatch[2], 10);
        const gmtMinutes = parseInt(gmtOffsetMatch[3], 10);
      
        // Get the current local date and time
        const currentDate = new Date();
      
        // Split the provided time into hours and minutes
        const [hours, minutes] = time.split(':').map(Number);
      
        // Set the current local date and time to the specified hours and minutes
        currentDate.setHours(hours);
        currentDate.setMinutes(minutes);
      
        // Get the local time zone offset in minutes
        const localOffset = currentDate.getTimezoneOffset();
      
        // Calculate the target offset in minutes
        const targetOffset = gmtSign * (gmtHours * 60 + gmtMinutes) - localOffset;
      
        // Create a new Date object with the adjusted time zone
        const targetTime = new Date(currentDate.getTime() + targetOffset * 60 * 1000);
      
        // Convert the target time to the specified time zone
        const targetOffsetMs = targetTime.getTimezoneOffset() * 60 * 1000;
        const convertedTime = new Date(targetTime.getTime() + targetOffsetMs);
      
        // Return the converted time
        console.warn('convertedTime',convertedTime)
        return formatAMPM(convertedTime);
      }
      function reverseConvertToGMT(date) {
        const aa = "Fri, 18 Oct 2013 " + date + " GMT";
        date = new Date(aa);
        const time = date.toLocaleTimeString(undefined, { hour12: false, hour: '2-digit', minute: '2-digit' });
        console.log("time",time);
        return time;
      }
      
      const gmtRow = timeZone?.find(row => ownerSetting?.time_zone_id === row?.id);
      const gmt = gmtRow ? gmtRow.name : null;
    

      const timeRow = timeZone?.find(row => ownerSetting?.time_zone_id === row?.id);
      const time = timeRow ? timeRow.timezone : null;
      console.warn('gmt', gmt);
      console.warn('time', time);
      
      const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
        const TotalDate = day + "-" + month + "-" + year;
        return TotalDate;
      };
      function stringToDate(dateString) {
        // console.warn("dateString",dateString)
        if (dateString === null || dateString === undefined || dateString >= 0)
          return;
        let parts = dateString.split("-");
        let day = parts[0];
        let month = parts[1]; // months are 0-based
        let year = parts[2];
        let date = year + "-" + month + "-" + day;
        // console.warn("UnformatDate",date)
        return date;
      }
      function ShowFormatDate(data, format) {
        if (data === null || data === undefined)
          return;
        const date = new Date(stringToDate(data));
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
        const year = date.getFullYear();
      
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
      
        format = format.replace('dd', formattedDay);
        format = format.replace('DD', formattedDay);
        format = format.replace('d', day);
        format = format.replace('D', day);
        format = format.replace('mm', formattedMonth);
        format = format.replace('MM', formattedMonth);
        format = format.replace('m', month);
        format = format.replace('M', month);
        format = format.replace('yyyy', year);
        format = format.replace('YYYY', year);
      
        return format;
      }
      function ShowFormatTime(data, format) {
        if (data === null || data === undefined || !isValidTimeString(data)) {
          return "Invalid time";
        }
      
        const time = parseTime(data);
        const dateObj = new Date(time);
      
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();
      
        const formattedHours = formatDigits(hours % 12 || 12);
        const amPm = hours < 12 ? 'AM' : 'PM';
        const formattedMinutes = formatDigits(minutes);
        const formattedSeconds = formatDigits(seconds);
      
        format = format.replace('HH', formattedHours);
        format = format.replace('H', formattedHours);
        format = format.replace('hh', formattedHours);
        format = format.replace('h', formattedHours);
        format = format.replace('mm', formattedMinutes);
        format = format.replace('MM', formattedMinutes);
        format = format.replace('m', formattedMinutes);
        format = format.replace('M', formattedMinutes);
        format = format.replace('ss', formattedSeconds);
        format = format.replace('SS', formattedSeconds);
        format = format.replace('s', formattedSeconds);
        format = format.replace('S', formattedSeconds);
        format = format.replace('I', formattedSeconds);
        format = format.replace('i', formattedSeconds);
        format = format.replace('A', amPm);
        format = format.replace('a', amPm);
        format = format.replace('tt', amPm);
        format = format.replace('TT', amPm);
      
        return format;
      }
      
      function isValidTimeString(timeString) {
        const timeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9])(:[0-5][0-9])? [AP]M$/i;
        return timeRegex.test(timeString);
      }
      
      function parseTime(timeString) {
        const [time, amPm] = timeString.split(' ');
        const [hours, minutes, seconds] = time.split(':').map(Number);
      
        let parsedHours = hours % 12;
        if (/pm/i.test(amPm)) {
          parsedHours += 12;
        }
      
        return new Date().setHours(parsedHours, minutes, seconds || 0, 0);
      }
      
      function formatDigits(value) {
        return value.toString().padStart(2, '0');
      }
      

      const getTimeZone = async ()=>{
        const response = await ApiRequest.get("/public/api/auth/get-timezones")
        if(response?.status == 200)
        {
          setTimeZone(response?.data?.data?.timezones)
        }
      }

  useEffect(() => {
    getTimeZone();
    getInteractionTypes();
    setInteraction(initialInteractionData);
    setInteractions(data)
    setOwnerSetting(ownerSettings)
  }, [])
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewInteractionForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewInteractionFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  View
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setInteraction([]);
                    setInteractionFiles([]);
                    setEditorData(null);
                    setEditorData1(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? 
   <> 
   <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div>

              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    {interaction.date != '' ? 
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="date"
                          type="date"
                          placeholder="Steve"
                          required={false}
                          disabled={true}
                          value={stringToDate(interaction?.date)}
                          onChange={(e) => {
                            setInteraction({...interaction,date:formatDate(e.target.value)});
                          }}
                        /> */}
                        <span>{ShowFormatDate(interaction?.date,ownerSetting?.date_format)}</span>
                      </div>
                    </div>
                    :null}
                    {interaction.time != ''?
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-2">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Time:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        {/* <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="time"
                          type="time"
                          placeholder="Steve"
                          required={false}
                          disabled={true}
                          value={stringToTime(interaction?.time)}
                          onChange={(e) => {
                            setInteraction({...interaction,time:formatAMPM(e.target.value)});
                          }}
                        /> */}
                        <span>{ShowFormatTime(convertTo12HourFormat(reverseConvertToGMT(interaction.time)),ownerSetting?.time_format)}</span>
                      </div>
                    </div>
                    :null}
                    {interaction.interaction_type_id != ''?<div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction_type_id`}
                          value={interaction?.interaction_type_id}
                          disabled={true}
                          onChange={(e) => {
                            setInteraction({...interaction,interaction_type_id:parseInt(e.target.value)});
                          }}
                        >
                          {interactionType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select> */}
                        <span>{interactionType?.map((row)=>{
                          if(interaction?.interaction_type_id == row?.id)
                          {
                            return row?.type
                          }
                        })}</span>
                      </div>
                    </div>:null}
                  </div>
                  {interaction?.interaction_type_id === 7 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                          initData={interaction.other_type_description}
                          config={{
                            readOnly:true
                          }}
                          // onChange={(e) => {
                          //   setEditorData(e.editor.getData());
                          // }}
                        /> */}
                        <span>{editorData}</span>
                      </div>
                    </div>
                  ) : null}
                  {interaction?.notes != '' ?
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      {/* <CKEditor
                        initData={interaction?.notes}
                        config={{
                          readOnly:true
                        }}
                        // onChange={(e) => {
                        //     setEditorData1(e.editor.getData());
                        // }}
                      /> */}
                      <div dangerouslySetInnerHTML={{ __html: interaction?.notes }} />
                    </div>
                  </div>
                  :null}
                  {interaction.files.length > 0 ?
                  <>

                      <div className="md:flex flex-column w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                  <div className="flex md:items-center md:justify-start md:w-6/12 mt-3">
                    <div className="md:w-3/4">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Files:
                      </label>
                    </div>
                    {/* <div className="md:w-3/4">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload Files
                        </span>
                        <input
                          id={`t-interation-files`}
                          type="file"
                          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                          multiple={true}
                          hidden={true}
                          disabled={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            interactionFiles?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = interactionFiles.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    // order_number: maxOrderNumber + i + 1 + interactionFiles.length,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...interactionFiles];
                                    fileLists = [...fileLists, ...newImages];
                                    setInteractionFiles(fileLists);
                                    let interactionCopy = [...interactionFiles];
                                    interactionCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        // order_number: picObj.order_number,
                                      })
                                    );
                                    setInteraction({ ...interaction, files: interactionCopy?.files});
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              interactionFiles.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div> */}
                  </div>
                  {interactionFiles.length > 0 ? (
                    <div>
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <div className="container w-full px-3 m-auto">
                          <div
                            id={`p-measurement-documents-block`}
                            className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                          >
                            {interactionFiles?.map(
                              (fileObject, fileIndex) =>
                                fileComponent({
                                  file: fileObject?.file,
                                  key: fileIndex,
                                  inputFieldId: `p-measurement-files`,
                                  trashAble: false,
                                  onTrashClick: () => {
                                    const fileLists = [
                                      ...interactionFiles,
                                    ];
                                    fileLists.splice(fileIndex, 1);
                                    interaction.files = fileLists;
                                    setInteractionFiles(fileLists);
                                  },
                                })
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </div>
                </>:null}
                </div>
              </div>
              
              </>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setInteraction([]);
                                setInteractionFiles([]);
                                setEditorData(null);
                                setEditorData1(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
              <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
              <Tooltip data={"This tool will help you keep track of any interaction you have with the Prospect/Tenant. This can be helpful to determine follow-ups and patterns of encounters."}/>
            </div>
          </div>
    {showForm == false ?
    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Interaction
                </button>
              </div>
            </div>
          </div>:null}
          
   {showForm == true ? 
   <> 


              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date: <span title='Field is required'>*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <DatePicker
                          value={interaction && interaction.date ? stringToDate(interaction.date) : null}
                          onChange={(date) => {
                            setInteraction(prevInteraction => ({
                              ...prevInteraction,
                              date: formatDate(date),
                            }));
                          }}
                        />


                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-2">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Time: <span title='Field is required'>*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="time"
                          type="time"
                          placeholder="Steve"
                          required={false}
                          value={interaction?.time == "" ? "" :reverseConvertToGMT(interaction?.time)}
                          onChange={(e) => {
                            const convertedTime = convertToGMT(e.target.value, gmt, time);
                            setInteraction({...interaction, time: convertedTime});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type: <span title='Field is required'>*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction_type_id`}
                          value={interaction?.interaction_type_id}
                          onChange={(e) => {
                            setInteraction({...interaction,interaction_type_id:parseInt(e.target.value)});
                          }}
                        >
                          <option value=" ">Select Type</option>
                          {interactionType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {interaction?.interaction_type_id === 7 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                      <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="other"
                          type="text"
                          placeholder="Other"
                          required={false}
                          value={editorData}
                          onChange={(e) => {
                            setEditorData(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData1}
                        onChange={(e) => {
                            setEditorData1(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:items-center md:justify-start md:w-8/12 mt-3">
                    <div className="md:w-3/4">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Files:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload Files
                        </span>
                        <input
                          id={`t-interation-files`}
                          type="file"
                          accept={`${fileTypes}`}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            interactionFiles?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = interactionFiles.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    // order_number: maxOrderNumber + i + 1 + interactionFiles.length,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...interactionFiles];
                                    fileLists = [...fileLists, ...newImages];
                                    setInteractionFiles(fileLists);
                                    let interactionCopy = [...interactionFiles];
                                    interactionCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        // order_number: picObj.order_number,
                                      })
                                    );
                                    setInteraction({ ...interaction, files: interactionCopy?.files});
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              interactionFiles.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div>
              {interactionFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {interactionFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...interactionFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                interaction.files = fileLists;
                                setInteractionFiles(fileLists);
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if(!interaction?.date || !interaction?.time || !interaction?.interaction_type_id)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{
                              editSpacesData();
                            }
                            } else {
                            if(!interaction?.date || !interaction?.time || !interaction?.interaction_type_id)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{
                              bulkData()
                            };
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
              </>:null}
   

          {/* <div className="flex flex-row-reverse mt-3">
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div> */}
      {interactions?.length > 0 ?<DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={interactions}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
          
          <hr />
    </>
  )
}
