import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../../utility/http";
export const getFeatures = createAsyncThunk("auth/features", async () => {
  try {
    console.log("here 5");
    const response = await ApiRequest.get("/public/api/auth/get-all-features");
    return response.data;
  } catch (error) {
    console.log("features api has error");
  }
});
export const addEditFeaturesApi = createAsyncThunk(
  "auth/addEditFeatures",
  async ({ feature, id }) => {
    console.log("feature 13", feature);
    console.log("id 16", id);
    try {
      const response = await ApiRequest.post("/public/api/auth/feature", {
        feature,
        id,
      });
      return response.data;
    } catch (error) {
      console.log("addEditFeatures api has error");
    }
  }
);
