import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import RolesTable from "../../components/userTable/RolesTable";
import { userRolesThunk } from "../../store/userRoles/Thunk";
import { CKEditor } from "ckeditor4-react";
import DataTable from "react-data-table-component";
import { Header3 } from "../../components/Header/Header";
import { MdDeleteOutline } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { FiEdit2 } from "react-icons/fi";
export const Ledger = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");

  const userRoles = useSelector((state) => {
    return state?.UserRoles?.Roles?.data?.roles;
  });
  const status = useSelector((state) => {
    return state?.UserRoles?.status;
  });
  const dispatch = useDispatch();
  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);

    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);
  useEffect(() => {
    dispatch(userRolesThunk());
  }, []);
  const columns = [
    {
      name: "System ID",
      selector: (row) => "T-541253652145874",
    },
    {
      name: "Date",
      selector: (row) => {
        return "General: 12/28/2020";
      },
    },
    {
      name: "Transaction ID",
      selector: (row) => {
        return "INV45124";
      },
    },
    {
      name: "Transaction",
      selector: (row) => {
        return "Edwards, James—Rent (Unit B) ";
      },
    },
    {
      name: "Description",
      selector: (row) => {
        return " 2023 June ";
      },
    },
    {
      name: "Amount",
      selector: (row) => {
        return "$1,000.00";
      },
    },
    {
      name: "Budget",
      selector: (row) => {
        return "Rent";
      },
    },
    {
      name: "Account",
      selector: (row) => {
        return "US BANK";
      },
    },
    {
      name: "Type",
      selector: (row) => {
        return "Rent";
      },
    },
   

    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800">
              {/* <i class="fa-solid fa-eyes mr-6"></i> */}
              <span>
                <GrView />
              </span>
            </button>
            <button class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800">
              {/* <i class="fa-solid fa-pen mr-6"></i> */}
              <span>
                <FiEdit2 />
              </span>
            </button>
            <button class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800">
              {/* <i class="fa-regular fa-trash mr-6"></i> */}
              <span>
                <MdDeleteOutline />
              </span>
            </button>
          </>
        );
      },
    },
  ];
   const [collapsed,setCollapsed] = useState(true)
const handleChildMessage = (message) => {
setCollapsed(message)
};
  return (
    <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/assets/admin/css/main.css" />
        <link rel="stylesheet" href="/assets/admin/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/slick.css" />
        <link rel="stylesheet" href="/assets/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Finance" user_type="super_admin" child="Ledger" onChildMessage={handleChildMessage}/>
      <div
        className={`main-content ${collapsed ? "collapsed" : ""}`}
        onScroll={(e) => handleContentScroll(e)}
      >
        {/* header bar */}
     <Header3/>
        {/* search wraper  */}
        <section className="search-wraper">
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                  <h4>
                    Welcome Back <span>Anddy’s Makeover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* service wraper */}
        <section>
          <div className="flex justify-between align-items-center">
            <div className="flex justify-between align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-ledger.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">ledger</h2>
            </div>

            <button
              class="bg-gray-300 py-1 mr-2  w-64 flex justify-center align-items-center rounded text-black-800"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalLg"
            >
              <span>Add Transaction</span>
            </button>

            <div
              class="modal fade fixed top-0 left-0 w-full h-full  outline-none overflow-x-hidden overflow-y-auto"
              id="exampleModalLg"
              tabindex="-1"
              aria-labelledby="exampleModalLgLabel"
              aria-modal="true"
              aria-hidden="true"
              role="dialog"
            >
              <div class="modal-dialog modal-xl relative w-auto pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    {/* <h5
                      class="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModalLgLabel"
                    >
                      Add Role
                    </h5> */}
                    <button
                      type="button"
                      class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body relative  pb-4 pt-2px-4">
                    <div className="flex  align-items-center">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/finance-ledger.png"
                        alt=""
                      />
                      <h2 className="text-3xl font-bold text-black py-3">
                        Add a transaction
                      </h2>
                    </div>
                    <form className="w-full" id="add-property-form">
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="md:w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Type:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Income</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            ID:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            required={true}
                            value="Autogenrated"
                            // placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                      <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="md:w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Vendor:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Vendor</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>

                        <label className="block capitalize text-gray-700 mb-2 w-auto text-center">
                          -OR-
                        </label>

                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Transaction Name:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            required={true}

                            // placeholder="Amount"
                          />
                        </div>
                        <div className="md:w-2/5 md:flex items-center gap-3 mb-3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Description:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            required={true}

                            // placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                      <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="md:w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Amount:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            required={true}

                            // placeholder="Amount"
                          />
                        </div>
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3 ">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Account:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Account</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3  ">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Budget:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Account</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Category:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Category</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center gap-3 w-full mt-6">
                      <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="md:w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Purchase Order:
                          </label>

                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Purchase Order</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="md:w-3/12 md:flex items-center gap-3 mb-3 ">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Work Order:
                          </label>
                          <select
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            aria-label=".form-select-lg example"
                          >
                            <option selected>Work Order</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full w-full mt-6">
                      <div className="md:w-3/12 md:flex items-center gap-3 mb-3">
                          <label className="md:w-2/4 block capitalize text-gray-700 font-bold mb-2">
                            Transaction files
                          </label>
                          <div className="w-100">
                            <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                Upload Files
                              </span>
                              <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                accept="image/*"
                                type="file"
                                // multiple={true}
                                hidden={true}
                                // value={features.icon}
                                onChange={async (e) => {}}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full gap-4 w-full mt-6">
                        <div className="md:w-9/12 md:flex gap-3 mb-3  ">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Transaction Notes
                          </label>
                          <div className="w-100">
                            <CKEditor
                              initData={""}
                              // disabled
                              // value={contact?.info}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="m-auto ">
                        <div className="md:flex md:items-center gap-3 w-full mt-6">
                         
                          <div className="md:w-7/5">
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="hoa_zip_code-property-name"
                              type="text"
                              required={true}
                              placeholder="Amount"
                            />
                          </div>
                          <div>
                            <div>
                              <select
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Location</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <div>
                              <select
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                aria-label=".form-select-lg example"
                              >
                                <option selected>Role</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="flex justify-content-end mt-4">
                        <input
                          id={"form-submit-button"}
                          type={"submit"}
                          value="Save"
                          hidden={false}
                          className="py-3 px-10 border-solid border-2  border-red-200 rounded"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <RolesTable userRoles={userRoles} status={status} /> */}
        <DataTable
          columns={columns}
          data={userRoles}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          responsive
          sortable={true}
          defaultSortFieldId={1}
        />

        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>
  );
};
