import { createSlice } from "@reduxjs/toolkit";
import { loginUser, registerUser, setUserProfile } from "./authThunk";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: null,
    accessToken: null,
    refreshToken: null,
    tokenExpiryTime: null,
    tokenType: null,
    currentUser: null,
    registeredUser: null,
    error: null,
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.status = "loading";
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log("payload",  payload)
      state.status = "success";
      state.accessToken = payload.access_token;
      state.refreshToken = payload.refresh_token;
      state.tokenExpiryTime = payload.expires_in;
      state.tokenType = payload.token_type;
    },
    [loginUser.rejected]: (state, _error) => {
      state.status = "failed";
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenExpiryTime = null;
      state.tokenType = null;
    },
    [setUserProfile.pending]: (state) => {
      console.log("here 38===============");
      state.status = "loading";
    },
    [setUserProfile.fulfilled]: (state, { payload }) => {
      console.log("here 41 ===============");
      state.status = "success";
      state.currentUser = payload;
    },
    [setUserProfile.rejected]: (state, _error) => {
      console.log("here 45===============");
      state.status = "failed";
      state.currentUser = null;
    },
    [registerUser.pending]: (state) => {
      state.status = "loading";
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.status = "success";
      if (!payload.status === 200) {
        state.registerUser = payload.data;
      } else {
        state.error = payload.data;
      }
    },
    [registerUser.rejected]: (state, _error) => {
      state.status = "failed";
      state.registerUser = null;
    },
  },
});

export default authSlice.reducer;
