import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import { useSelector } from "react-redux";
import ApiRequest from "../../utility/http";
import { AdminForm } from "../../components/property/AdminForm";
import { PrefixSuffixTabs } from "../../components/admin/Prefix-Suffix-tabs";
import { Link } from "react-router-dom";
export const ViewPrefixSuffix = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");

  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };

  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

    const [collapsed,setCollapsed] = useState(true)
const handleChildMessage = (message) => {
setCollapsed(message)
};
  return (
    <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/assets/admin/css/main.css" />
        <link rel="stylesheet" href="/assets/admin/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/slick.css" />
        <link rel="stylesheet" href="/assets/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Admin" user_type="super_admin" onChildMessage={handleChildMessage}/>
      <div
        className={`main-content ${collapsed ? "collapsed" : ""}`}
        onScroll={(e) => handleContentScroll(e)}
      >
        {/* header bar */}
        <section className="header-bar">
          <div className="row align-items-center">
            <div className="col-xl-5 col-md-12 col-2">
              <div className="property-item">
                <div className="property-img">
                  <img src="/assets/admin/img/property.png" alt="" />
                </div>
                <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mobile-logo">
                <img
                  className="img-fluid logo-light"
                  src="/assets/admin/img/logo-light.png"
                  alt=""
                />
                <img
                  className="img-fluid logo-dark"
                  src="/assets/admin/img/logo-dark.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-10">
              <div className="header-bar-right items-start">
                <ul className="header-icons">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-language-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-activity-stream-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink3"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-message-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-notifications-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink3"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                </ul>
                <ProfileSelect cookies={cookies} />
              </div>
            </div>
          </div>
        </section>
        {/* search wraper  */}
        <section className="search-wraper">
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                  <h4>
                    Welcome Back <span>Anddy’s Makeover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* service wraper */}
        <div className="px-5">
          <div className="flex justify-between align-items-center">
            <div className="flex justify-between align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/property.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                View prefix and suffix
              </h2>
            </div>
            <Link
              to={`/admin/website-settings`}
              style={{ textDecoration: "none" }}
            >
              <button class="bg-gray-300 py-1 mr-2  w-64 flex justify-center align-items-center rounded text-black-800">
                <i class="fa-solid fa-pen mr-6"></i>
                <span>Add new prefix and suffix</span>
              </button>
            </Link>
          </div>
          <PrefixSuffixTabs />
          {/* <Table prefixes={prefixes} loading={loading}/> */}
        </div>

        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>
  );
};
