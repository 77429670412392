 import React,{ useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import { useSelector } from "react-redux";
import { stepConnectorClasses } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ApiRequest from "../../utility/http";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const Permissions = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");
  const [allPermissions, setAllPermissons] = useState();
  const [roles, setRoles] = useState();
  const { alertList } = useSelector((state) => state.alerts);
  const [userRoles, setUserRoles] = useState("");
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = useState([]);
  const handleChangeUserRoles = (event) => {
    setUserRoles(event.target.value);
  };
  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);
 

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  useEffect(() => {
    const getAllPermissions = async () => {
      try {
        const response = await ApiRequest.get(
          "/public/api/auth/get-all-permissions"
        );
        console.log("getAllPermissions", response.data.data.permissions);
        setAllPermissons(() => response.data.data.permissions);
        const getRoles = await ApiRequest.get("/public/api/auth/get-all-roles");
        setRoles(getRoles.data.data.roles);
      } catch (err) {
        console.log("api is not working");
      }
    };
    getAllPermissions();
  }, []);
  return (
    <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/admin/css/main.css" />
        <link rel="stylesheet" href="/admin/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/admin/css/slick.css" />
        <link rel="stylesheet" href="/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Admin" user_type="super_admin" />
      <div
        className="main-content collapsed"
        onScroll={(e) => handleContentScroll(e)}
      >
        {/* header bar */}
        <section className="header-bar">
          <div className="row align-items-center">
            <div className="col-xl-5 col-md-12 col-2">
              <div className="property-item">
                <div className="property-img">
                  <img src="/admin/img/property.png" alt="" />
                </div>
                <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mobile-logo">
                <img
                  className="img-fluid logo-light"
                  src="/admin/img/logo-light.png"
                  alt=""
                />
                <img
                  className="img-fluid logo-dark"
                  src="/admin/img/logo-dark.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-10">
              <div className="header-bar-right items-start">
                <ul className="header-icons">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-language-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-activity-stream-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink3"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-message-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-notifications-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink3"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li>
                </ul>
                <ProfileSelect cookies={cookies} />
              </div>
            </div>
          </div>
        </section>
        {/* search wraper  */}
        <section className="search-wraper">
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                  <h4>
                    Welcome Back <span>Anddy’s Makeover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* service wraper */}
        <div className="px-5">
          <div className="flex align-items-center">
            <img
              className="logo w-12 mr-5"
              src="/assets/img/properties/property.png"
              alt=""
            />
          </div>
          <div class="flex justify-start gap-5  px-3 mt-3">
            <div class="flex justify-start flex-column mt-3">
              <h2 className="  text-black pt-3">User roles</h2>
              <div class="mb-3 xl:w-96">
                <FormControl size="small" sx={{ m: 1, minWidth: "100%" }}>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={userRoles}
                    onChange={handleChangeUserRoles}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {roles?.map((item, i) => {
                      return <MenuItem value={item.role}>{item.role}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
         
              </div>
            </div>
            <div class="flex justify-start flex-column mt-3">
              <h2 className="text-black pt-3">User permissions</h2>
              <div class="mb-3 xl:w-96">
                <div>
                  <FormControl size="small" sx={{ m: 1, width: "100%" }}>
                    {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      // input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {allPermissions &&
                        allPermissions?.map((item, i) => (
                          <MenuItem key={i} value={item.permission}>
                            <Checkbox
                              checked={personName.indexOf(item.permission) > -1}
                            />
                            <ListItemText primary={item.permission} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>
  );
};
