import React, { useEffect } from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../property/TabPanel";
import { CKEditor } from "ckeditor4-react";
import { Tooltip } from "../Loader/Tooltip";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";
import ApiRequest from "../../utility/http";
import { Interactions } from "../tenant/Interactions";
import { Legal } from "./Legal";
import FolderStructure from "../../tree/FolderStructure";
import { Notes } from "../property/Notes";

export const LeaseEvictionForm = () => {
  const [securedFields, setSecuredFields] = useState({
    ssn: "password",
    dln_id: "password",
  });
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const [ownerSettings, setOwnerSettings] = useState([]);

  const getOwnerSettings = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-current-owner-settings"
    );
    if (response?.status == 200) {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  };
  useEffect(() => {
    getOwnerSettings();
  }, []);
  return (
    <>
      <form
        className="w-full"
        id="add-Eviction-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Eviction Information" {...a11yProps(0)} />
          <Tab label="Eviction Notices" {...a11yProps(1)} />
          <Tab label="Interactions" {...a11yProps(2)} />
          <Tab label="Legal" {...a11yProps(3)} />
          <Tab label="Notes & Files" {...a11yProps(4)} />
          <Tab label="Eviction Expenses" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-eviction.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Start an Eviction Case:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Lease<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lease-eviction"
                      type="text"
                      placeholder="Title"
                      required={false}
                      // value={formData?.addresses[0]?.address_line1}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].address_line1 = e.target.value;
                      //   setCreateLog({...createLog,address_line1:1});
                      //   setTenantAddress(addresses);
                      //   setFormData({ ...formData, addresses: addresses });
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex flex-row-reverse justify-between items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Start Process
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-eviction.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Eviction Portal:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Activate in Portal for Tenant(s):
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-4/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Activated for Tenant(s) on:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    {/* <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Case Reason:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Case Status:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Case Overview:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Resolution:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Eviction Overview:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Resolution Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>

              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Resolution Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-eviction-notice.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Eviction Notice:
              </label>
            </div>
          </div>
          <div class="flex flex-row-reverse justify-between items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">
                Add Notice
              </button>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div class="flex flex-row-reverse justify-between items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Load Template
                  </button>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Title:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="Eviction-notice-title"
                    type="text"
                    placeholder="Title"
                    required={false}
                    // value={formData?.addresses[0]?.address_line1}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].address_line1 = e.target.value;
                    //   setCreateLog({...createLog,address_line1:1});
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    {/* <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  /> */}
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Eviction Notice:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                    // initData={editorData3?.property_owner_notes}
                    // onChange={(e) => {
                    //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    //   setEditorData3({
                    //     ...editorData3,
                    //     property_owner_notes: cleanText,
                    //   });
                    // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Eviction Files */}

          <div className="md:flex md:items-center w-[90%] m-auto mt-3">
            <div className="md:w-2/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Eviction Notice Files:
              </label>
            </div>
            <div className="md:w-auto">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload files
                </span>
                <input
                  id={`p-measurement-files`}
                  type="file"
                  // accept={fileTypes}
                  multiple={true}
                  hidden={true}
                  // onChange={(e) => {
                  //   let files = e.target.files;
                  //   let newImages = [];
                  //   let maxOrderNumber = 0;

                  //   // Find the highest order number in the current images
                  //   propertyMeasurementFiles?.forEach((imageObject) => {
                  //     if (imageObject.order_number > maxOrderNumber) {
                  //       maxOrderNumber = imageObject.order_number;
                  //     }
                  //   });

                  //   // Calculate the total number of images (existing images + new images)
                  //   const totalImages = propertyMeasurementFiles.length + files.length;

                  //   // Loop through each file and convert to base64
                  //   files.forEach((file, i) => {
                  //     const reader = new FileReader();
                  //     reader.addEventListener("load", async () => {
                  //       const fileBase64 = reader.result;
                  //       try {
                  //         const res = await ApiRequest.post(fileApi, {
                  //           file: fileBase64,
                  //         });

                  //         // Add the new image to the array with a unique order number
                  //         newImages.push({
                  //           file: res?.data?.data?.file,
                  //           title: res?.data?.data?.file,
                  //           url:fileBase64
                  //         });

                  //         // If all new images have been processed, update the state
                  //         if (newImages.length === files.length) {
                  //           let fileLists = [...propertyMeasurementFiles];
                  //           fileLists = [...fileLists, ...newImages];
                  //           setPropertyMeasurementFiles(fileLists);
                  //           let measurementsCopy = [...propertyMeasurementFiles];
                  //           measurementsCopy.files = fileLists.map(
                  //             (picObj) => ({
                  //               file: picObj.file,
                  //               title: picObj.title,
                  //             })
                  //           );
                  //           setPropertyMeasurement({ ...propertyMeasurement, files: measurementsCopy?.files });
                  //         }
                  //       } catch (err) {
                  //         // console.log(err);
                  //       }
                  //     });
                  //     reader.readAsDataURL(file);
                  //   });
                  // }}
                />
              </label>
            </div>
            {/* <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount =
                        propertyMeasurementFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
          </div>

          {/* Gallery  */}

          {/* <div >
              {propertyMeasurementFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMeasurementFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyMeasurement.files = fileLists;
                                setPropertyMeasurementFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMeasurementFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyMeasurement.files[fileIndex].title = title;
                                    setPropertyMeasurementFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
          <div className="md:flex md:items-center w-[90%] m-auto mt-3">
            <div className="md:w-2/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Eviction Notice Pictures:
              </label>
            </div>
            <div className="md:w-auto">
              <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                  Upload pictures
                </span>
                <input
                  // accept={picTypes}
                  id={`p-measurement-pictures`}
                  type="file"
                  multiple={true}
                  hidden={true}
                  // onChange={(e) => {
                  //   let files = e.target.files;
                  //   let newImages = [];
                  //   let maxOrderNumber = 0;

                  //   // Find the highest order number in the current images
                  //   propertyMeasurementPictures?.forEach((imageObject) => {
                  //     if (imageObject.order_number > maxOrderNumber) {
                  //       maxOrderNumber = imageObject.order_number;
                  //     }
                  //   });

                  //   // Calculate the total number of images (existing images + new images)
                  //   const totalImages = propertyMeasurementPictures.length + files.length;

                  //   // Loop through each file and convert to base64
                  //   files.forEach((file, i) => {
                  //     const reader = new FileReader();
                  //     reader.addEventListener("load", async () => {
                  //       const imgBase64 = reader.result;
                  //       try {
                  //         const res = await ApiRequest.post(imageApi, {
                  //           image: imgBase64,
                  //         });

                  //         // Add the new image to the array with a unique order number
                  //         newImages.push({
                  //           picture: res?.data?.data?.image,
                  //           order_number: maxOrderNumber + i + 1 + propertyMeasurementPictures.length,
                  //           url:imgBase64
                  //         });

                  //         // If all new images have been processed, update the state
                  //         if (newImages.length === files.length) {
                  //           let pictureLists = [...propertyMeasurementPictures];
                  //           pictureLists = [...pictureLists, ...newImages];
                  //           setPropertyMeasurementPictures(pictureLists);
                  //           let measurementsCopy = [...propertyMeasurementPictures];
                  //           measurementsCopy.pictures = pictureLists.map(
                  //             (picObj) => ({
                  //               picture: picObj.picture,
                  //               order_number: picObj.order_number,
                  //             })
                  //           );
                  //           setPropertyMeasurement({ ...propertyMeasurement, pictures: measurementsCopy?.pictures });
                  //         }
                  //       } catch (err) {
                  //         // console.log(err);
                  //       }
                  //     });
                  //     reader.readAsDataURL(file);
                  //   });
                  // }}

                  // onChange={(e) => {
                  //   let files = e.target.files;
                  //   files.forEach((file) => {
                  //     const reader = new FileReader();
                  //     reader.addEventListener("load", async () => {
                  //       const imgBase64 = reader.result;
                  //       try {
                  //         // console.log(imgBase64);
                  //         const res = await ApiRequest.post(imageApi, {
                  //           image: imgBase64,
                  //         });
                  //         // console.log("res", res?.data?.data?.image);
                  //         // console.log("index", index);
                  //         // let index = [index];
                  //         // [...index].sort()
                  //         propertyMeasurements[index].pictures.push({
                  //           picture: res?.data?.data?.image,
                  //         });
                  //         setFormData({
                  //           ...formData,
                  //           measurements: measurements,
                  //         });

                  //         // console.log(
                  //           "propertyMeasurements",
                  //           propertyMeasurements
                  //         );

                  //         // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                  //         // // console.warn("base64converted files",formData)
                  //       } catch (err) {
                  //         // console.log(err);
                  //       }
                  //     });
                  //     reader.readAsDataURL(file);
                  //   });
                  //   const pictureLists = [...propertyMeasurementPictures];
                  //   pictureLists[index] = getImagesAndURLs(e);
                  //   setPropertyMeasurementPictures(pictureLists);
                  //   // propertyMeasurements[index].pictures =
                  //   // 		getImagesAndURLs(e)
                  //   // // console.warn("pictureList",pictureLists);
                  //   // setFormData({...formData, measurements: measurements})
                  // }}
                />
              </label>
            </div>
            {/* <div className="md:w-1/5">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const imagesCount =
                        propertyMeasurementPictures?.length || 0;
                      return imagesCount > 1
                        ? imagesCount + " Pictures"
                        : imagesCount == 1
                        ? imagesCount + " Picture"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
          </div>

          {/* Gallery  */}

          {/* <div >
              {propertyMeasurementPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMeasurementPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                propertyMeasurement.pictures = pictureLists;
                                setPropertyMeasurementPictures(
                                  pictureLists
                                );
                                // setPropertyMeasurements(measurements)
                                setPropertyMeasurement({
                                  ...propertyMeasurement,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
          <div class="flex justify-evenly items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">
                Save as Template
              </button>
              <button type="button" class="btn btn3">
                Save as Draft
              </button>
              <button type="button" class="btn btn3 px-5">
                Publish
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Interactions ownerSettings={ownerSettings} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Legal ownerSettings={ownerSettings} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-notes-and-files.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Notes & Files:
              </label>
              <Tooltip
                data={
                  " You can add notes and files with different levels of access in order to keep and share the information that you need to!"
                }
              />
            </div>
          </div>
          <FolderStructure/>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/system-notes-teal.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Notes:
                  </label>
                </div>
                {/* <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData?.property_tenant_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                        ...editorData,
                        property_tenant_notes: cleanText,
                      });
                    }}
                  />
                </div> */}
              </div>
                <Notes/>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}></TabPanel>
      </form>
    </>
  );
};
