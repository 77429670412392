import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";
import { Calendar } from "./Calendar";
export const CalendarSchedulerFrom = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [securedFields, setSecuredFields] = useState({
    dln_id: "password",
  });
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Shifts" {...a11yProps(1)} />
          <Tab label="Scheduler" {...a11yProps(2)} />
          <Tab label="Time of Request" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Calendar />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              /> */}
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Create Shifts:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Shift Type<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Start<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      End<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Staff per Shift:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex items-center mt-4">
                <div className="md:items-center w-1/5 mt-4">
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-files.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto mt-4">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-public-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyPublicDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyPublicDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyPublicDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyPublicDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyPublicDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyPublicDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyPublicDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-public-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_files = fileLists;
                            setPropertyPublicDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_files[fileIndex].title = title;
                                setPropertyPublicDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/staff-schedule.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Scheduler:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center justify-content-evenly mt-3">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Overview/Time Off Request
                </label>
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Schedule Shift
                </label>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Schedule For</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-content-end w-1/2 mt-3 mr-1">
                  <button className="btn btn3">List</button>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="flex flex-column w-1/2">
                  <div className="md:items-center w-full mt-5">
                    <div className="flex items-center">
                      <div className="w-auto item-center mr-3">
                        <label className="flex items-center mr-3">
                          <input
                            type="checkbox"
                            className="form-checkbox mb-2"
                            //   checked={sharingValue?.pms == 2}
                            //   value = {sharingValue?.pms}
                            //   onChange={(e)=>{
                            //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                            //   }}
                          />
                          <span className="ml-2 text-xl text-gray-700 mb-2 uppercase font-bold">
                            Shifts:
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="flex w-full mt-3 mr-1">
                      <div className="flex-column mt-3">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Monday, November 15
                        </label>
                        <label className="block capitalize tracking-wide text-gray-700 font-bold italic mb-2">
                          Dinner Shift 5:00PM-9:00PM
                        </label>
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="flex items-center mr-3">
                              <input
                                type="checkbox"
                                className="form-checkbox mb-2 mr-1"
                                //   checked={sharingValue?.pms == 2}
                                //   value = {sharingValue?.pms}
                                //   onChange={(e)=>{
                                //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                                //   }}
                              />
                              <div className="w-full">
                                <select
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-2"
                                  id={`employment-company-name`}
                                  type="text"
                                  placeholder="J Height"
                                  // value={tenantEmployment?.company_name}
                                  // onChange={(e) => {
                                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                                  // }}
                                >
                                  <option value="">McClean, Mateo</option>
                                </select>
                              </div>
                              <img
                                src="/assets/svgs/system-trashcan.svg"
                                className="w-6"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center mt-1">
                          <div className="w-auto item-center mr-3">
                            <label className="flex items-center mr-3">
                              <input
                                type="checkbox"
                                className="form-checkbox mb-2 mr-1"
                                //   checked={sharingValue?.pms == 2}
                                //   value = {sharingValue?.pms}
                                //   onChange={(e)=>{
                                //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                                //   }}
                              />
                              <div className="w-full">
                                <select
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-2"
                                  id={`employment-company-name`}
                                  type="text"
                                  placeholder="J Height"
                                  // value={tenantEmployment?.company_name}
                                  // onChange={(e) => {
                                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                                  // }}
                                >
                                  <option value="">McClean, Mateo</option>
                                </select>
                              </div>
                              <img
                                src="/assets/svgs/system-trashcan.svg"
                                className="w-6"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="flex w-full mt-3 mr-1">
                      <div className="flex-column mt-3">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Tuesday, November 16
                        </label>
                        <label className="block capitalize tracking-wide text-gray-700 font-bold italic mb-2">
                          Lunch Shift 10:00AM-2:00PM
                        </label>
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="flex items-center mr-3">
                              <input
                                type="checkbox"
                                className="form-checkbox mb-2 mr-1"
                                //   checked={sharingValue?.pms == 2}
                                //   value = {sharingValue?.pms}
                                //   onChange={(e)=>{
                                //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                                //   }}
                              />
                              <div className="w-full">
                                <select
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-2"
                                  id={`employment-company-name`}
                                  type="text"
                                  placeholder="J Height"
                                  // value={tenantEmployment?.company_name}
                                  // onChange={(e) => {
                                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                                  // }}
                                >
                                  <option value="">McClean, Mateo</option>
                                </select>
                              </div>
                              <img
                                src="/assets/svgs/system-trashcan.svg"
                                className="w-6"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                        <label className="block capitalize tracking-wide text-gray-700 font-bold italic mb-2 mt-2">
                          Dinner Shift 05:00PM-9:00PM
                        </label>
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="flex items-center mr-3">
                              <input
                                type="checkbox"
                                className="form-checkbox mb-2 mr-1"
                                //   checked={sharingValue?.pms == 2}
                                //   value = {sharingValue?.pms}
                                //   onChange={(e)=>{
                                //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                                //   }}
                              />
                              <div className="w-full">
                                <select
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-2"
                                  id={`employment-company-name`}
                                  type="text"
                                  placeholder="J Height"
                                  // value={tenantEmployment?.company_name}
                                  // onChange={(e) => {
                                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                                  // }}
                                >
                                  <option value="">McClean, Mateo</option>
                                </select>
                              </div>
                              <img
                                src="/assets/svgs/system-trashcan.svg"
                                className="w-6"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="flex w-full mt-3 mr-1">
                      <div className="flex-column mt-3">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          WednesDay, November 17
                        </label>
                        <label className="block capitalize tracking-wide text-gray-700 font-bold italic mb-2">
                          Dinner Shift 5:00PM-9:00PM
                        </label>
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="flex items-center mr-3">
                              <input
                                type="checkbox"
                                className="form-checkbox mb-2 mr-1"
                                //   checked={sharingValue?.pms == 2}
                                //   value = {sharingValue?.pms}
                                //   onChange={(e)=>{
                                //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                                //   }}
                              />
                              <div className="w-full">
                                <select
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-2"
                                  id={`employment-company-name`}
                                  type="text"
                                  placeholder="J Height"
                                  // value={tenantEmployment?.company_name}
                                  // onChange={(e) => {
                                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                                  // }}
                                >
                                  <option value="">McClean, Mateo</option>
                                </select>
                              </div>
                              <img
                                src="/assets/svgs/system-trashcan.svg"
                                className="w-6"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column w-1/2">
                  <div className="flex w-full">
                    <div className="w-3/5">
                      <div className="md:items-center w-full mt-5">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="ml-2 text-xl text-gray-700 mb-2 font-bold">
                              Staff:
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="md:items-center w-full mt-5">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <span className="ml-2 mb-2">(shifts / hours)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full mt-1">
                    <div className="w-3/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="ml-2 mb-2">
                              Kruska, CJ (Cook):
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <span className="ml-2 mb-2">0 / 0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full mt-1">
                    <div className="w-3/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="ml-2 mb-2">
                              Long, Donna (Waitress):
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <span className="ml-2 mb-2">1 / 4</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full mt-1">
                    <div className="w-3/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="ml-2 mb-2">
                              McClean, Mateo (Cook):
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <span className="ml-2 mb-2">1 / 4</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full mt-1">
                    <div className="w-3/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <label className="ml-2 mb-2">
                              Sapp, Sean (Cook):
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <div className="md:items-center w-full mt-1">
                        <div className="flex items-center">
                          <div className="w-auto item-center mr-3">
                            <span className="ml-2 mb-2">1 / 4</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:w-1/3">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      E-mail Shifts:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      //   value={formData?.enable_portal}
                      //   onChange={(e) => {
                      //     const checkedValue = e.target.checked === true ? 1 : 0;
                      //     setFormData({
                      //       ...formData,
                      //       enable_portal: checkedValue,
                      //     });
                      //   }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/3">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      E-mail Schedule:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      //   value={formData?.enable_portal}
                      //   onChange={(e) => {
                      //     const checkedValue = e.target.checked === true ? 1 : 0;
                      //     setFormData({
                      //       ...formData,
                      //       enable_portal: checkedValue,
                      //     });
                      //   }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/2">
                  <div className="w-auto">
                    <button type="button" class="btn btn3">
                      Save as Draft
                    </button>
                  </div>
                  <div className="w-auto">
                    <button type="button" class="btn btn3">
                      Publish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/staff-request-time-off.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Time of Request:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div class="flex w-full justify-between">
                  <div className="w-1/2">
                    <label class="flex items-center">
                      <input
                        type="radio"
                        class="form-radio"
                        name="radio-group"
                        value="option1"
                      />
                      <span class="ml-2">Request Full Days Off</span>
                    </label>
                  </div>
                  <div className="w-1/2">
                    <label class="flex items-center">
                      <input
                        type="radio"
                        class="form-radio"
                        name="radio-group"
                        value="option2"
                      />
                      <span class="ml-2">
                        Request Times & Repeated Days Off
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center mt-3 mr-1 w-1/4">
                  <label className="flex items-center mr-3">
                    <span className="ml-2">All Day</span>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      //   checked={sharingValue?.pms == 2}
                      //   value = {sharingValue?.pms}
                      //   onChange={(e)=>{
                      //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                      //   }}
                    />
                  </label>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Start<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      End<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    {/* <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Shift Type<span title="Field is Required">*</span>:
                    </label> */}
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Comments:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    Post Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
