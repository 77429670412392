import React, { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";

export const AddStaffDepartmentForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="flex md:items-center mt-5">
              <div className="md:flex md:items-center w-full md:w-4/12">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Status:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  >
                    <option value="">Select Status</option>
                    {/* <option value={1}>Active</option>
                          <option value={0}>Inactive</option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex md:items-center mt-3">
              <div className="md:flex md:items-center w-4/5">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Department Number:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  />
                </div>
              </div>
            </div>
            <div className="flex md:items-center mt-3">
              <div className="md:flex md:items-center w-4/5">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Name<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Description:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.dln_id_issue_notes}
                // onChange={(e) => {
                //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                //   setEditorData({
                //     ...editorData,
                //     dln_id_issue_notes: cleanText,
                //   });
                // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:w-1/2">
                <div className="md:w-auto">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Does this Department Oversee Other Departments?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    //   value={formData?.enable_portal}
                    //   onChange={(e) => {
                    //     const checkedValue = e.target.checked === true ? 1 : 0;
                    //     setFormData({
                    //       ...formData,
                    //       enable_portal: checkedValue,
                    //     });
                    //   }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
            <div class="flex justify-end items-center mt-3">
              <div class="flex items-center gap-4">
                <button type="button" class="btn btn3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
