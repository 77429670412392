import { CKEditor } from "ckeditor4-react";
import React from "react";

export const AddForumForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-forum-form"
        // onSubmit={formSubmitHandler}
      >
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Pin
                </button>
              </div>
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  <img src="/assets/svgs/system-trashcan.svg" alt="Delete" className="w-full h-6" />
                </button>
              </div>
            </div>
          </div>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-5">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Title <span title="Field is Required">*</span>:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="moveout_address1"
                  type="text"
                  // placeholder="6284 Locust Road"
                  required={false}
                  // value={formData?.moveout_address1}
                  // onChange={(e) => {
                  //   setCreateLog({...createLog,moveout_address1:1});
                  //   setFormData({
                  //     ...formData,
                  //     moveout_address1: e.target.value,
                  //   });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Space:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-5">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Tags:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="moveout_address1"
                  type="text"
                  // placeholder="6284 Locust Road"
                  required={false}
                  // value={formData?.moveout_address1}
                  // onChange={(e) => {
                  //   setCreateLog({...createLog,moveout_address1:1});
                  //   setFormData({
                  //     ...formData,
                  //     moveout_address1: e.target.value,
                  //   });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Cover Picture:
                  </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Image
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex w-full mt-3">
                <CKEditor/>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Attachments:
                  </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Files
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Restrict Article To:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
              <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email on Post:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
      </form>
    </>
  );
};
