import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { useState } from 'react';
import './Calender.css';
export const Calendar = () => {
    const [events, setEvents] = useState([
        { id: '1', title: 'Event 1', start: '2023-08-15' },
        { id: '2', title: 'Event 2', start: '2023-08-16' },
        { id: '3', title: 'Event 3', start: '2023-08-17' }
      ])
      const [view, setView] = useState('dayGridMonth');

        const handleViewChange = (newView) => {
            setView(newView);
        };

        const headerToolbar = {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        };
      const handleDrop = (event) => {
        const newEvent = {
          id: Math.random().toString(36).substring(7),
          title: event.draggedEl.innerText,
          start: event.dateStr
        }
        setEvents([...events, newEvent])
      }
      const handleDayCellContent = (arg) => {
        const { dayEl } = arg;
        if (dayEl) {
          const date = arg.date;
          const currentMonth = arg.view.currentStart.getMonth();
          const cellMonth = date.getMonth();
          if (currentMonth !== cellMonth) {
            dayEl.style.backgroundColor = 'gray';
          }
        }
      };
      
      return (
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          headerToolbar={headerToolbar}
          events={events}
          editable={true}
          droppable={true}
          drop={handleDrop}
        //   dayCellContent={handleDayCellContent}
        eventClick={(info) => {
            console.log(info.event.title);
          }}
          datesSet={(info) => {
            console.log(info.startStr, info.endStr);
          }}
          viewDidMount={(info) => {
            console.log(info.view.type);
          }}
          buttonText={{
            dayGridMonth: 'Month',
            timeGridWeek: 'Week',
            timeGridDay: 'Day',
            listMonth: 'List'
          }}
          views={{
            dayGridMonth: {
              buttonText: 'Month',
            },
            timeGridWeek: {
              buttonText: 'Week',
            },
            timeGridDay: {
              buttonText: 'Day',
            },
            listMonth: {
              buttonText: 'List',
            },
          }}
          view={view}
          onView={(view) => handleViewChange(view)}
    
        />
      )
    }