import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import { ShotsType } from './ShotsType';
import { DatePicker } from '../genericFunctions.js/DatePicker';

export const ProfileShots = ({data,handleShotTrue,hiddenData,ownerSettings,petType,petTypes}) => {


    const initialShotData = {
        type: "",
        is_shot_applied: 1,
        applied_date: "",
        expiry_date: "",
        proof_picture: "",
        is_approved_by_owner: 0,
        is_rejected_by_owner: 0,
        rejected_reason: "",
      };
      const initialShotTypeData = {
        type: "",
        is_shot_applied: 0,
        applied_date: "",
        expiry_date: "",
        proof_picture: "",
        is_approved_by_owner: 0,
        is_rejected_by_owner: 0,
        rejected_reason: "",
      };

    const [shots,setShots] = useState([]);
    const [shot,setShot] = useState([]);
    const [type,setType] = useState([]);
    const [types,setTypes] = useState([]);
    console.log('@Shotstypes',types);
    console.log('@Shot',shot);
    const [tenantShotPicture,setTenantShotPicture] = useState([]);
    const [editText, setEditText] = useState(false);
    const [text, setText] = useState('Click me to edit!');
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
   
    const [showForm,setShowForm] = useState(false);


    // const handleShotTypeTrue = (message)=>{
    //   setTypes(message);
    // }
    
    // const handleTextChange = (event) => {
    //   setText(event.target.innerText);
    // };
    // console.log("@tenantShotPicture",tenantShotPicture);
    // console.log("@shots",shots);

    const myDivRef = useRef(null);
    const bulkData = ()=>{
      // typeAdd()
      //   shot({...shot,notes:editorData1,other_type_description:editorData1}); 
        const newDataArray  = [];
        newDataArray.push(shot);
        types.forEach(typeObj => {
    const typeIndex = shots.findIndex(shotObj => shotObj.type === typeObj.type);
    if (typeIndex === -1) {
      newDataArray.push(typeObj);
    }
  });
        // shots?.forEach(obj=>{
        //   let index = types?.findIndex(el=> el.type == obj.type);
        //   if(index === -1)
        //   {
        //   }
        // })
        setShots(prevArray => [...prevArray, ...newDataArray]);
        handleShotTrue(prevArray => [...prevArray, ...newDataArray]);
        setShot(initialShotData);
        setTenantShotPicture([]);
        // setEditorData('');
        setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...shots];
        array[indexEdit] = shot;
        setShots(array);
    
        handleShotTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setShot(initialShotData);
        setTenantShotPicture([]);


      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setShowForm(true);
        setShot(shots[index]);
        setTenantShotPicture(shots[index]?.proof_picture);
        // setEditorData(shots[index]?.notes)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      function remainingExpiryDays(expiryDate) {
        // Get the current date and the expiry date
        const currentDate = new Date();
        const expiry = new Date(expiryDate);
      
        // Calculate the time difference between the current date and the expiry date
        const timeDiff = expiry.getTime() - currentDate.getTime();
      
        // Convert the time difference from milliseconds to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
        // Return the number of days remaining until the expiry date
        return daysDiff;
      }
      function ShowFormatDate(data, format) {
        if (data === null || data === undefined)
          return;
        const date = new Date(stringToDate(data));
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
        const year = date.getFullYear();
      
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
      
        format = format.replace('dd', formattedDay);
        format = format.replace('DD', formattedDay);
        format = format.replace('d', day);
        format = format.replace('D', day);
        format = format.replace('mm', formattedMonth);
        format = format.replace('MM', formattedMonth);
        format = format.replace('m', month);
        format = format.replace('M', month);
        format = format.replace('yyyy', year);
        format = format.replace('YYYY', year);
      
        return format;
      }
      const columns = [
        {
          name: "Date",
          selector: (data) => data.applied_date != "" ? ShowFormatDate(data?.applied_date,ownerSettings?.date_format) : "",
          sortable: true,
        },
        {
          name: "Type",
          selector: (data) => data.type,
          sortable: true,
        },
        {
          name: "Required",
          selector: (data) => remainingExpiryDays(stringToDate(data.expiry_date)) <= 0 ? "Required" : "",
          sortable: true,
        },
        {
          name: "Expires",
          selector: (data) => (
            <div>
            {data.expiry_date}
            <br />
            
            {remainingExpiryDays(stringToDate(data.expiry_date)) > 0 ? remainingExpiryDays(stringToDate(data.expiry_date)) + " "+ "days" : "Expired"}
            </div>
            ),
            sortable: true,
        },
        {
          name: "Proof",
          selector: (data) => 
            data.proof_picture ? 
              (<a download target="_blank" href={imgUrl+"/"+data.proof_picture} >
            <i className='fa fa-download' />
            </a>):null,
            sortable: true,
        },
        // {
        //   name: "License Plate",
        //   selector: (data) => data.license_plate
        // },
        // {
        //   name: "Parking Space",
        //   selector: (data) => data.property_parking_space_id
        // },
        // {
        //   name: "Status",
        //   selector: (data) => data.status == 0 ? "Inactive": "Active"
        // },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        // {
        //   name: "Options",
        //   cell: (row,index) => {
        //     return (
        //       <>
        //         <div className="flex">
        //         {/* <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mr-5"
        //         data-bs-target={`#staticBackdrop`}
        //         data-index={index}
        //         onClick={(e)=>{
        //           indexFind(index);
        //         }}
        //         >
    
        //             <img src='/assets/img/system-edit.png' />
                  
        //         </button> */}
        //         {remainingExpiryDays(stringToDate(row.expiry_date)) <= 0 ? <button type="button" hidden={hiddenData} class="bg-transparent border-none md:w-1/6 p-0"
        //         data-bs-target={`#staticBackdrop`}
        //         data-index={index}
        //         // onClick={(e)=>{
        //         //   indexFind(index);
        //         // }}
        //         >
    
        //           <img src='/assets/img/system-reject.png' />
                  
        //         </button>:null}
               
               
        //          </div>
        //         {/* <div className="md:w-1/12">
        //         <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
        //           <i class="fa-regular fa-eye mr-6"></i>
        //         </button>
        //         </div> */}
        //       </>
        //     );
        //   },
        // },
      ];
      const shotApplied = shots?.filter(row => row.is_shot_applied === 1);
      // console.log('shotApplied',shotApplied);
    const tableData = {
        columns : columns,
        data : shotApplied,
      };
      const deleteData = ()=>{
        setShots("Deleted");
      }
      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Shot";
        const headers = [["Date", "Type","Required","Expires",'Proof']];
    
        const data = shotApplied.map(elt => [elt.pictures[0]?.picture,elt.year,elt.color,elt.license_plate,elt.property_parking_space_id,elt.status == 0 ? "Inactive":"Active"])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("data.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = shotApplied.map(elt => [elt.pictures[0]?.picture,elt.year,elt.color,elt.license_plate,elt.property_parking_space_id,elt.status == 0 ? "Inactive":"Active"])
        // Convert the data to a CSV string
    
        const headers = [["Date", "Type","Required","Expires",'Proof']];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "data.csv");
        link.click();
      };

    const imageComponent = ({
        image = {},
        key = 0,
        src = "",
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
        ...restProps
      }) => {
        const confirmEdit = (image) => {
          Swal.fire({
            title: 'Are you sure you want to edit this image?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              // Modify the image object here
              const editedImage = { ...image, title: 'New Title' };
              onEditClick(editedImage);
            }
          });
        };
      
        return image ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
          >
            <div className="relative h-fit w-full">
              <img
                title={image?.title}
                key={key}
                alt="property image"
                className="border block object-contain w-80 h-auto rounded-lg"
                src={src}
              />
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove image"
                    type="button"
                    className="logo w-5 mx-auto my-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((file) => {
                          if (file !== image) {
                            dataTransfer.items.add(file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
                {/* <button
                  type="button"
                  title="Edit image"
                  className="logo w-5 mx-auto my-1"
                  onClick={() => confirmEdit(image)}
                >
                  <img src="/assets/img/system-edit.png" alt="edit" />
                </button> */}
              </div>
            </div>
          </div>
        ) : (
          <img {...restProps} />
        );
      };

    const formatDate = (date) => {
        date = new Date(date);
        const year = date.getFullYear();
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
        const TotalDate = day + "-" + month + "-" + year;
        return TotalDate;
      };
      function stringToDate(dateString) {
        // console.warn("dateString",dateString)
        if (dateString === null || dateString === undefined || dateString >= 0)
          return;
        let parts = dateString.split("-");
        let day = parts[0];
        let month = parts[1]; // months are 0-based
        let year = parts[2];
        let date = year + "-" + month + "-" + day;
        // console.warn("UnformatDate",date)
        return date;
      }
      const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age_now--;
        }
        console.log(age_now);
        return age_now;
      };

      const typeTrue = shots?.filter(shot=>shot.is_shot_applied === 0);
      function convertStringToArray(str) {
        return str.split("___");
      }
      const setDefaultShots = () => {
        const type = petTypes.find((type) => petType === type?.id);
      
        if (type) {
          const defaultShots = convertStringToArray(type.default_shots);
      
          const newArray = defaultShots.map((shot) => ({
            ...initialShotTypeData,
            type: shot,
          }));
      
          setTypes(newArray);
        } else {
          setTypes([]);
        }
      };
      const [newType, setNewType] = useState('');
      const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        if (selectedType == "other") {
          setShot({...shot, type: selectedType});
          setNewType('');
        } else {
          setShot({...shot, type: selectedType});
        }
      };
    

      
      useEffect(() => {
        setShot(initialShotData);
        // setType(initialShotTypeData);
        setTypes(data?.filter(shot=>shot.is_shot_applied === 0));
        setDefaultShots();
        setShots(data);
      }, [petType])
      

  return (
    <>
    <div className="md:flex md:items-center mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/tenant-pets-shots.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Shots:
                      </label>
                    </div>
                  </div>
                      {/* <ShotsType typeData={data} handleShotTypeTrue={handleShotTypeTrue}/> */}
                      
                     {hiddenData == false ? <>
                        <div className="md:flex md:items-center mt-4">
                          <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Date:
                              </label>
                            </div>
                            <div className="md:w-3/4">
                              <DatePicker
                                value={shot?.applied_date == "" ? "" :stringToDate(shot?.applied_date)}
                                onChange={(e) => {
                                  // pets[index].shots[i].applied_date = e.target.value;
                                  const date = formatDate(e);
                                  setShot({...shot,applied_date:date});
                                }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Type:
                              </label>
                            </div>
                            <div className="md:w-3/4">
                            {shot?.type == "other" ? 
                            <input
                              type="text"
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              placeholder="Enter a new type"
                              value={newType}
                              onChange={(e) => setNewType(e.target.value)}
                              onKeyPress={(e)=>{
                                if (e.key === "Enter") {
                                  if (newType) {
                                    const newShot = {
                                      ...initialShotTypeData,
                                      type: newType
                                    };
                                    const updatedTypes = [...types, newShot];
                                    setTypes(updatedTypes);
                                    setShot({...shot,type:""});
                                    setNewType('');
                                  }}
                              }}
                            />:
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={shot?.type}
                                onChange={(e)=>{
                                  handleTypeChange(e)
                                }}
                              >
                                <option value="">Select Type</option>
                                {types?.map((row)=>(
                                  <option value={row?.type}>{row?.type}</option>
                                  ))}
                                  <option value="other">Other</option>
                              </select>}
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                            <div className="md:w-2/4 mr-1">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Expires:
                              </label>
                            </div>
                            <div className="md:w-3/4">
                              <DatePicker
                                value={shot?.expiry_date ==""?"":stringToDate(shot?.expiry_date)}
                                onChange={(e) => {
                                  const date = formatDate(e);
                                  setShot({...shot,expiry_date:date})
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row-reverse justify-between items-center mt-3">
                          <div className="flex justify-between items-center gap-4">
                            <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                Upload Proof
                              </span>
                              <input
                                accept="image/*"
                                id={`proof-picture`}
                                type="file"
                                hidden={true}
                                onChange={async (e) => {
                                  let file = e.target.files[0];
                                  const reader = new FileReader();
                                  reader.addEventListener("load", async () => {
                                    const imgBase64 = reader.result;
                                    try {
                                      console.log(imgBase64);
                                      const res = await ApiRequest.post(
                                        "/public/api/auth/upload-image",
                                        { image: imgBase64 }
                                      );
                                      // console.log("res", res?.data?.data?.image);
                                      tenantShotPicture.push(res?.data?.data?.image);
                                      // setFeatureIcon(res?.data?.data?.image);
                                      setShot({...shot,proof_picture:res?.data?.data?.image});
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  });
                                  reader.readAsDataURL(file);

                                  // console.log("here 142");
                                  const pictures = [...tenantShotPicture];
                                }}
                              />
                            </label>
                          </div>
                        </div>
                        {tenantShotPicture && tenantShotPicture.length > 0 ? (
                          <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[30%]">
                            <div className="container w-full">
                              <div
                                id="image-block"
                                className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                              >
                                {imageComponent({
                                  image: tenantShotPicture,
                                  src: imgUrl +'/'+tenantShotPicture,
                                  inputFieldId: `tenant-proof-picture`,
                                  trashAble: true,
                                  onTrashClick: () => {
                                    const pictureLists = [
                                      ...tenantShotPicture,
                                    ];
                                    pictureLists.splice(0, 1)
                                    shot.proof_picture = pictureLists;
                                    setTenantShotPicture(
                                      pictureLists
                                    );
                                    // setPropertyMaterials(measurements)
                                    const data = pictureLists.length > 0 ? pictureLists : "";
                                    setShot({
                                      ...shot,
                                      proof_picture: data,
                                    });
                                  },
                                })}
                              </div>
                            </div>
                          </section>
                        ) : null}
                      </>:null}
                  {hiddenData == false ?
                  <>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                    <div className="flex justify-end w-full mt-4">
                      <div className="w-1/4 pl-5 ml-5 mt-2 ">
                        <button
                          type="button"
                          className="btn3"
                          onClick={() => {
                            if (indexEdit !== undefined) {
                              editSpacesData();
                            } else {
                              bulkData();
                            }
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </>
                  :null}
          {shots?.length > 0 ? 
          <>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-5" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-5" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={shotApplied}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        </>:null}
          
    </>
  )
}
