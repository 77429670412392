import { CKEditor } from "ckeditor4-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import ApiRequest from "../../utility/http";
import imgUrl from "../ImageUrl";

export const Changelog = () => {
  const initialChangelogData = {
    date: "",
    title: "",
    version: "",
    status: "",
  };
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };

  const [changelog, setChangelog] = useState([]);
  const [viewChangelog, setViewChangelog] = useState([]);
  const [editChangelog, setEditChangelog] = useState([]);
  const [changelogs, setChangelogs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [indexEdit, setIndexEdit] = useState();
  const [addChangelog,setAddChangelog] = useState([]);
  const [addChangelogs,setAddChangelogs] = useState([]);
  console.log('addChangelogs',addChangelogs);

  const [changelogType,setChangelogType] = useState([]);

  const getChangelogType = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-changelog-categories');
    if(response.status == 200)
    {
      setChangelogType(response?.data?.data?.categories);
    }
  }

  const addChangeLogData=()=>{
    const filteredData = changelogType?.filter((type)=>type.id == addChangelog);
    setAddChangelogs((prevArray)=>[...prevArray, ...filteredData]);
    setAddChangelog('');

  }
  const deleteLog = (index)=>{
    const newArray = [...addChangelogs];
    newArray.splice(index, 1);
    setAddChangelogs(newArray);
  }
  const bulkData = (newData) => {
    let newArrayData = { ...changelog, status: newData };
    const newDataArray = [newArrayData];
    // newDataArray.push(changelog);
    // handleChangelogTrue(prevArray => [...prevArray, ...newDataArray]);
    setChangelogs((prevArray) => [...prevArray, ...newDataArray]);
    setChangelog(initialChangelogData);
    // setEditorData('');
    setShowForm(false);
    // setBulkSpaces("");
  };

  const editSpacesData = () => {
    const array = [...changelogs];
    array[indexEdit] = changelog;
    // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
    // // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
    // array[indexEdit].other_type_description = cleanText;
    // array[indexEdit].notes = editorData1;
    setChangelogs(array);

    // handleChangelogTrue(array);
    setShowForm(false);
    setIndexEdit(undefined);
    setChangelog(initialChangelogData);
  };
  const indexFind = (index) => {
    // var index = $(this).attr('data-index');
    // var modal = $(this).attr('data-bs-toggle');
    // const findIndex = parkingSpaces.length -1 - index ;
    setIndexEdit(index);
    setShowForm(true);
    setChangelog(changelogs[index]);
    // myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    // // console.log("index",findIndex);
    // // console.log("modal",modal);
  };
  const viewData = (index) => {
    setShowForm(false);
    setViewChangelog(changelogs[index]);
    // setChangelogPictures(changelogs[index]?.pictures)
    setViewForm(true);
  };
  const deleteData = (index) => {
    setShowForm(false);
    setViewForm(false);
    const newArray = [...changelogs];
    newArray.splice(index, 1);
    setChangelogs(newArray);
    setChangelog(initialChangelogData);
    // handleChangelogTrue(newArray);
  };

  const columns = [
    {
      name: "Date",
      selector: (data) => data.date,
      sortable: true,
    },
    {
      name: "Title",
      selector: (data) => data.title,
      sortable: true,
    },
    {
      name: "Version",
      selector: (data) => data.version,
      sortable: true,
    },
    {
      name: "Status",
      selector: (data) => data.status,
      sortable: true,
    },
    // {
    //   name: "Handicap",
    //   selector: (data) => data.handicap,
    // },
    // {
    //   name: "Assignee",
    //   selector : (data) => 'UnAssign'
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Options",
      cell: (row, index) => {
        return (
          <>
            <div className="flex">
              <button
                type="button"
                class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewChangeLogForm`}
                data-bs-toggle="modal"
                data-index={index}
                onClick={(e) => {
                  viewData(index);
                }}
              >
                <img src="/assets/svgs/system-view.svg" />
              </button>
              <button
                type="button"
                class="bg-transparent border-none md:w-1/12  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e) => {
                  indexFind(index);
                }}
              >
                <img src="/assets/img/system-edit.png" />
              </button>
              <button
                type="button"
                class="bg-transparent border-none md:w-1/12 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e) => {
                  deleteData(index);
                }}
              >
                <img src="/assets/svgs/system-trashcan.svg" />
              </button>
            </div>
            {/* <div className="md:w-1/12">
            <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
              <i class="fa-regular fa-eye mr-6"></i>
            </button>
            </div> */}
          </>
        );
      },
    },
  ];
  const tableData = {
    columns: columns,
    data: changelogs,
  };

  useEffect(() => {
    setChangelog(initialChangelogData);
    getChangelogType();
  }, []);
  return (
    <>
    {showForm == false ?
    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  // hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Changelog
                </button>
              </div>
            </div>
          </div>:null}
      {showForm == true ? (<div className="flex-col w-[90%] m-[auto] mt-5 mb-20">
        <div className="flex items-center w-1/3 justify-between">
          <img
            src="/assets/svgs/admin-changelog.svg"
            className="w-1/6 mr-4"
            alt=""
          />
          <label className="w-11/12 capitalize tracking-wider text-gray-700 text-2xl font-bold">
            Changelog
          </label>
        </div>
        <div className="flex-col mt-4">
          <div className="flex items-center">
            <label className="block w-1/6 capitalize tracking-wide text-gray-700 text-lg font-bold">
              versioning guide:
            </label>
            <label className="block w-1/3 capitalize tracking-wide text-gray-700 text-base font-semibold">
              Major Upgrades or Modules Added
            </label>
            <ul className="flex items-center w-1/2 justify-between list-disc">
              <li className="capitalize tracking-wide text-gray-700 text-base font-semibold">
                Minor Upgrades to Modules & features
              </li>
              <li className="capitalize tracking-wide text-gray-700 text-base font-semibold">
                patches & Fixes
              </li>
            </ul>
          </div>
          <div className="flex justify-between w-full mt-5">
            <div className="flex w-1/3 items-center">
              <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                Date:
              </label>
              <DatePicker
                className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5"
                type="date"
                value={changelog?.date}
                onChange={(e) => {
                  const date = formatDate(e);
                  setChangelog({ ...changelog, date: date });
                }}
              />
            </div>
            <div className="flex w-1/3 items-center">
              <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                Title*:
              </label>
              <input
                className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5"
                type="text"
                value={changelog?.title}
                onChange={(e) => {
                  setChangelog({ ...changelog, title: e.target.value });
                }}
              />
            </div>
            <div className="flex w-1/4 items-center">
              <label className="block w-1/3 capitalize tracking-wide text-gray-700 text-base font-semibold">
                Version*:
              </label>
              <input
                className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5"
                type="input"
                value={changelog?.version}
                onChange={(e) => {
                  setChangelog({ ...changelog, version: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex justify-between w-full mt-5 items-center">
            <div className="flex w-1/2 items-center">
              <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                Category:
              </label>
              <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5"
              
              value={addChangelog}
              onChange={(e)=>{
                setAddChangelog(e.target.value);
              }}
              >
                
                <option value="">Select</option>
                {changelogType?.map((type)=>(
                  <option value={type?.id}>{type?.category}</option>
                ))}
              </select>
            </div>
            <div className="w-1/2">
              <button className="btn3" onClick={addChangeLogData}>Add</button>
            </div>
          </div>
        </div>
        {addChangelogs?.map((changeLog,index)=>(
        <div className="flex mt-10">
          <div className="flex-col items-center w-1/12">
            <label className="capitalize tracking-wider text-gray-700 text-lg font-bold">
              {changeLog?.category}
            </label>
            <img src={`${imgUrl}/${changeLog?.icon}`} className="w-1/2 mt-5" alt="" />
            <img
              src="/assets/svgs/system-trashcan.svg"
              className="w-1/2 mt-10"
              alt=""
              onClick={()=>{
                deleteLog(index);
              }}
            />
          </div>
          <div className="w-[80%] m-auto">
            <CKEditor />
          </div>
        </div>))}
        <div className="flex mt-10 items-center justify-between">
          <div className="flex items-center">
            <label className="capitalize tracking-wider text-gray-700 text-base font-semibold mr-5">
              {" "}
              Create Update E-mail on Publish:
            </label>
            <label class="inline-flex relative items-center cursor-pointer">
              <input type="checkbox" class="sr-only peer" />
              <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
          <div className="w-1/2 justify-between float-right flex ">
            <div className="flex w-1/2 items-center">
              <button
                className="btn3"
                onClick={(e) => {
                  bulkData("Draft");
                }}
              >
                Save Draft
              </button>
            </div>
            <div className="flex w-1/2 items-center">
              <button
                className="btn3"
                onClick={(e) => {
                  bulkData("Published");
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>):null}
      <div
        class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id={`ViewChangeLogForm`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="ViewChangeLogFormLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl w-auto pointer-events-none">
          <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                class="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                View
              </h5>
              <button
                type="button"
                class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setViewForm(false);
                  setViewChangelog([]);
                }}
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
            <div class="modal-body relative p-4">
              {viewForm == true ? (
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/admin-changelog.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Changelog:
                      </label>
                    </div>
                  </div>

                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                    <div className="w-full">
                      <div className="flex justify-between w-full mt-5">
                        {viewChangelog?.date ? (
                          <div className="flex w-1/3 items-center">
                            <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                              Date:
                            </label>
                            {viewChangelog?.date}
                          </div>
                        ) : null}
                        {viewChangelog?.title ? (
                          <div className="flex w-1/3 items-center">
                            <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                              Title*:
                            </label>
                            {viewChangelog?.title}
                          </div>
                        ) : null}
                        {viewChangelog?.version ? (
                          <div className="flex w-1/4 items-center">
                            <label className="block w-1/3 capitalize tracking-wide text-gray-700 text-base font-semibold">
                              Version*:
                            </label>
                            {viewChangelog?.version}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex  w-full mt-5">
                        {viewChangelog?.status ? (
                          <div className="flex w-1/3 items-center">
                            <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                              Status:
                            </label>
                            {viewChangelog?.status}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                class="btn3"
                onClick={() => {
                  setViewForm(false);
                  setViewChangelog([]);
                }}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {changelogs?.length > 0 ? (
        <DataTableExtensions {...tableData}>
          <DataTable
            className="striped"
            columns={columns}
            data={changelogs}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            highlightOnHover
            sortFieldId={indexEdit}
            sortDirection="asc"
          />
        </DataTableExtensions>
      ) : null}
    </>
  );
};
