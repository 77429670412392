import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { Tab, Tabs } from "@mui/material";
import { CKEditor } from "ckeditor4-react";

export const KnowledgebaseSettingForm = () => {

  const [showTopic,setShowTopic] = useState(false);  
  const [showQuestion,setShowQuestion] = useState(false);  
  const [showCategory,setShowCategory] = useState(false);  
  const [showGuide,setShowGuide] = useState(false);  
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lead-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Settings" {...a11yProps(0)} />
          <Tab label="Frequently Asked Questions" {...a11yProps(1)} />
          <Tab label="User Guides" {...a11yProps(2)} />
          <Tab label="Help Forum" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="flex flex-col w-[90%] m-auto">
            <div className="flex flex-col mt-5">
              <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-xl font-bold">
                general settings
              </label>
              <div className="flex mt-5">
                <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-lg font-semibold">
                  Welcome message
                </label>
                <div className="w-4/5 m-auto">
                  <CKEditor />
                </div>
              </div>
              <div className="flex mt-5 w-1/2 items-center">
                <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  add staff to knowledgebase editors/moderaters:
                </label>
                <select className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className=" flex-col w-[90%] m-auto mt-5">
            <div className="flex flex-col">
              <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-xl font-bold">
                frequently asked questions
              </label>
              <div className="flex mt-5">
                <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Description :
                </label>
                <div className="w-4/5 m-auto">
                  <CKEditor />
                </div>
              </div>
            </div>
            <div className="flex-col mt-5">
              <div>
                <label className="block w-1/12 uppercase tracking-wide text-gray-700 text-lg font-bold">
                  Faqs:
                </label>
              </div>
              {showTopic == false ? (<div className="flex justify-end">
                <button className="btn btn3" onClick={()=>{setShowTopic(true)}}>Add Topic</button>
              </div>):null}
            </div>
            {showTopic ? (<div className="flex-col mt-10">
              <div className="flex w-full ">
                <div className="flex w-9/12 items-center">
                  <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                    Topic*:
                  </label>
                  <input className="block appearance-none w-6/12 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className="flex w-1/4 items-center">
                  <label className="block w-1/6 capitalize tracking-wide text-gray-700 text-base font-semibold">
                    icon*:
                  </label>
                  <button className="btn btn3">Upload Icon</button>
                </div>
              </div>
              <div className="flex mt-5 items-center w-3/5 ">
                <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Access*:
                </label>
                <select className="block appearance-none w-1/3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={(e)=>{setShowTopic(false)}} className="btn btn3">Save</button>
              </div>
            </div>):null}
            <hr />
            {showQuestion == false ? (<div className="flex justify-end mt-10">
              <button className="btn btn3" onClick={()=>{setShowQuestion(true)}}>Add Question</button>
            </div>):null}
            {showQuestion ? (<div className="flex-col mt-10">
              <div className="flex mt-5 items-center w-9/12 ">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  topic*:
                </label>
                <select className="block appearance-none w-1/3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex mt-5">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  question*:
                </label>
                <div className="w-9/12 m-auto">
                  <CKEditor />
                </div>
              </div>
              <div className="flex mt-5">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  answer*:
                </label>
                <div className="w-9/12 m-auto">
                  <CKEditor />
                </div>
              </div>
              <div className="flex mt-5 items-center w-9/12 ">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  topic*:
                </label>
                <select className="block appearance-none w-1/3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex justify-end">
                <button type="button" className="btn btn3" onClick={()=>{setShowQuestion(false)}}>Save</button>
              </div>
            </div>):null}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className=" flex-col w-[90%] m-auto mt-5">
            <div className="flex flex-col">
              <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-xl font-bold">
                User Guides:
              </label>
              <div className="flex mt-5">
                <label className="block w-1/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Description :
                </label>
                <div className="w-4/5 m-auto">
                  <CKEditor />
                </div>
              </div>
            </div>
            <div className="flex-col mt-5">
              <div>
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-lg font-bold">
                  Guides:
                </label>
              </div>
              {showCategory == false ? (<div className="flex justify-end">
                <button className="btn btn3" onClick={()=>{setShowCategory(true)}}>Add category</button>
              </div>):null}
            </div>
            {showCategory?(<div className="flex-col mt-10">
              <div className="flex w-full ">
                <div className="flex w-9/12 items-center">
                  <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                    Category*:
                  </label>
                  <input className="block appearance-none ml-5 w-6/12 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
                <div className="flex w-1/4 items-center">
                  <label className="block w-1/6 capitalize tracking-wide text-gray-700 text-base font-semibold">
                    icon*:
                  </label>
                  <button className="btn btn3">Upload Icon</button>
                </div>
              </div>
              <div className="flex mt-5 items-center w-9/12 ">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Access*:
                </label>
                <select className="block appearance-none ml-5 w-1/3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex justify-end">
                <button className="btn btn3" onClick={()=>{
                    setShowCategory(false)
                }}>Save</button>
              </div>
            </div>):null}
            <hr />
            {showGuide == false ? (<div className="flex justify-end mt-10">
              <button className="btn btn3" onClick={()=>{setShowGuide(true)}}>Add Guide</button>
            </div>):null}
            {showGuide ? (<div className="flex-col mt-10">
              <div className="flex mt-5 items-center w-9/12 ">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Category*:
                </label>
                <select className="block appearance-none ml-6 w-6/12 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex mt-5">
                <label className="block w-1/12 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  guide*:
                </label>
                <div className="w-9/12 m-auto">
                  <CKEditor />
                </div>
              </div>
              <div className="flex mt-5 items-center w-4/5 ">
                <label className="block w-2/5 capitalize tracking-wide text-gray-700 text-base font-semibold">
                  Restrict Access further:
                </label>
                <select className="block appearance-none ml-3 w-1/3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
              <div className="flex justify-end">
                <button className="btn btn3" onClick={()=>{setShowGuide(false)}}>Save</button>
              </div>
            </div>):null}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}></TabPanel>
      </form>
    </>
  );
};
