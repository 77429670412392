import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";

export const AddSafetyForm = () => {
  const [safety, setSafety] = useState([]);
  const initialData = {
    expiration_date_toggle: "",
    batteries: "",
    content_kit: "",
    tested_component: "",
  };

  useEffect(() => {
    setSafety(initialData);
  }, []);
  return (
    <>
      <div className="md:flex flex-column md:items-center w-[90%] m-auto">
        <div className="w-full">
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-3/5 mr-2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Name:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-2/5 mt-3">
              <div className="md:w-1/6">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Status:
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                >
                  <option value="">Select</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Type:
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                >
                  <option value="">Select</option>
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center w-1/2  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Show to Tenant
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    // value={formData?.hoa_show_to_tenant}
                    // onChange={(e) => {
                    //   const checkedValue = e.target.checked === true ? 1 : 0;
                    //   setFormData({
                    //     ...formData,
                    //     hoa_show_to_tenant: checkedValue,
                    //   });
                    // }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          <div className="md:flex mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Description:
              </label>
            </div>
            <div className="md:w-4/5">
              <CKEditor
              // initData={editorData?.dln_id_issue_notes}
              // onChange={(e) => {
              //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
              //   setEditorData({
              //     ...editorData,
              //     dln_id_issue_notes: cleanText,
              //   });
              // }}
              />
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-4/5 mr-2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Brand:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-4/5 mr-2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Model:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-4/5 mr-2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Serial Number:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-2/5 mr-2 mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Vendor:
                </label>
              </div>
              <div className="md:w-4/5">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                >
                  <option value="">Select</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
              <div className="md:w-3/5 mr-1">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Date Purchased/Installed:
                </label>
              </div>
              <div className="md:w-4/5">
                <DatePicker
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Cost:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-1/3  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Does this item have an expiration date?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={safety?.expiration_date_toggle}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setSafety({
                        ...safety,
                        expiration_date_toggle: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          {safety?.expiration_date_toggle == 1 ? (
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Expiration Date:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <DatePicker
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-4/5 mr-2 mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Website:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-2/5 mr-2 mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Location:
                </label>
              </div>
              <div className="md:w-4/5">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                >
                  <option value="">Select</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Location Notes:
              </label>
            </div>
            <div className="md:w-4/5">
              <CKEditor
              // initData={editorData?.dln_id_issue_notes}
              // onChange={(e) => {
              //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
              //   setEditorData({
              //     ...editorData,
              //     dln_id_issue_notes: cleanText,
              //   });
              // }}
              />
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
              <div className="md:w-3/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Last Checked/Tested:
                </label>
              </div>
              <div className="md:w-4/5">
                <DatePicker
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`lease-date-to-send`}
                  // value={propertyMaterial?.material_type_id}
                  // onChange={(e) => {
                  //   const value = parseInt(e.target.value);
                  //   setPropertyMaterial({
                  //     ...propertyMaterial,
                  //     material_type_id: value,
                  //   });
                  //   getPropertyAllMaterials(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-1/2  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Add/Assign Reminder?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    // value={safety?.expiration_date_toggle}
                    // onChange={(e) => {
                    //   const checkedValue = e.target.checked === true ? 1 : 0;
                    //   setSafety({
                    //     ...safety,
                    //     expiration_date_toggle: checkedValue,
                    //   });
                    // }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/2  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Does this use batteries?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={safety?.batteries}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setSafety({
                        ...safety,
                        batteries: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          {safety?.batteries == 1 ? (
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Batteries Needed:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
                <div className="md:w-3/5 mr-1">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Batteries Last Changed:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <DatePicker
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/3  mt-3">
                <div className="md:flex md:w-full">
                  <div className="md:w-auto show-to-tenant">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                      Add/Assign Reminder?
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      // value={safety?.expiration_date_toggle}
                      // onChange={(e) => {
                      //   const checkedValue = e.target.checked === true ? 1 : 0;
                      //   setSafety({
                      //     ...safety,
                      //     expiration_date_toggle: checkedValue,
                      //   });
                      // }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/2  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    is this a kit that has contents that need replenishing?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={safety?.content_kit}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setSafety({
                        ...safety,
                        content_kit: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          {safety?.content_kit == 1 ? (
            <>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Contents:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-3/5 mr-1">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Last Replenished:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Replenished Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2  mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                        Add/Assign Reminder?
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={safety?.expiration_date_toggle}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setSafety({
                        //     ...safety,
                        //     expiration_date_toggle: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-1/2  mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto show-to-tenant">
                  <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                    Does that contain any other components that need to be
                    tested/changed?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={safety?.tested_component}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setSafety({
                        ...safety,
                        tested_component: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          {safety?.tested_component == 1 ?(<>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-3/5 mr-1">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Last Serviced:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <DatePicker
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Service Notes:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.dln_id_issue_notes}
                // onChange={(e) => {
                //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                //   setEditorData({
                //     ...editorData,
                //     dln_id_issue_notes: cleanText,
                //   });
                // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2  mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-1">
                        Add/Assign Reminder?
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={safety?.expiration_date_toggle}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setSafety({
                        //     ...safety,
                        //     expiration_date_toggle: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
            <div class="flex justify-end items-center mt-3">
              <div class="flex justify-between items-center gap-4">
                <button type="button" class="btn btn3">
                  Add Another
                </button>
              </div>
            </div>
          </>):null}
          <div className="md:flex mt-4">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-xl text-gray-700 font-bold mb-2">
                  Safety Item Pictures:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload pictures
                  </span>
                  <input
                    // accept={picTypes}
                    id={`p-measurement-pictures`}
                    type="file"
                    multiple={true}
                    hidden={true}
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   let newImages = [];
                    //   let maxOrderNumber = 0;
                    
                    //   // Find the highest order number in the current images
                    //   propertyMeasurementPictures?.forEach((imageObject) => {
                    //     if (imageObject.order_number > maxOrderNumber) {
                    //       maxOrderNumber = imageObject.order_number;
                    //     }
                    //   });
                    
                    //   // Calculate the total number of images (existing images + new images)
                    //   const totalImages = propertyMeasurementPictures.length + files.length;
                    
                    //   // Loop through each file and convert to base64
                    //   files.forEach((file, i) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const imgBase64 = reader.result;
                    //       try {
                    //         const res = await ApiRequest.post(imageApi, {
                    //           image: imgBase64,
                    //         });
                    
                    //         // Add the new image to the array with a unique order number
                    //         newImages.push({
                    //           picture: res?.data?.data?.image,
                    //           order_number: maxOrderNumber + i + 1 + propertyMeasurementPictures.length,
                    //           url:imgBase64
                    //         });
                    
                    //         // If all new images have been processed, update the state
                    //         if (newImages.length === files.length) {
                    //           let pictureLists = [...propertyMeasurementPictures];
                    //           pictureLists = [...pictureLists, ...newImages];
                    //           setPropertyMeasurementPictures(pictureLists);
                    //           let measurementsCopy = [...propertyMeasurementPictures];
                    //           measurementsCopy.pictures = pictureLists.map(
                    //             (picObj) => ({
                    //               picture: picObj.picture,
                    //               order_number: picObj.order_number,
                    //             })
                    //           );
                    //           setPropertyMeasurement({ ...propertyMeasurement, pictures: measurementsCopy?.pictures });
                    //         }
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    // }}
                    
                    
                    
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   files.forEach((file) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const imgBase64 = reader.result;
                    //       try {
                    //         // console.log(imgBase64);
                    //         const res = await ApiRequest.post(imageApi, {
                    //           image: imgBase64,
                    //         });
                    //         // console.log("res", res?.data?.data?.image);
                    //         // console.log("index", index);
                    //         // let index = [index];
                    //         // [...index].sort()
                    //         propertyMeasurements[index].pictures.push({
                    //           picture: res?.data?.data?.image,
                    //         });
                    //         setFormData({
                    //           ...formData,
                    //           measurements: measurements,
                    //         });

                    //         // console.log(
                    //           "propertyMeasurements",
                    //           propertyMeasurements
                    //         );

                    //         // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                    //         // // console.warn("base64converted files",formData)
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    //   const pictureLists = [...propertyMeasurementPictures];
                    //   pictureLists[index] = getImagesAndURLs(e);
                    //   setPropertyMeasurementPictures(pictureLists);
                    //   // propertyMeasurements[index].pictures =
                    //   // 		getImagesAndURLs(e)
                    //   // // console.warn("pictureList",pictureLists);
                    //   // setFormData({...formData, measurements: measurements})
                    // }}
                  />
                </label>
              </div>
              {/* <div className="md:w-1/5">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const imagesCount =
                        propertyMeasurementPictures?.length || 0;
                      return imagesCount > 1
                        ? imagesCount + " Pictures"
                        : imagesCount == 1
                        ? imagesCount + " Picture"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
            </div>

            {/* Gallery  */}

            {/* <div >
              {propertyMeasurementPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMeasurementPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                propertyMeasurement.pictures = pictureLists;
                                setPropertyMeasurementPictures(
                                  pictureLists
                                );
                                // setPropertyMeasurements(measurements)
                                setPropertyMeasurement({
                                  ...propertyMeasurement,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
          <div className="md:flex mt-4">
            <div className="md:w-1/3">
              <div className="flex">
                {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Safety Item Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyOwnerDocumentsWithUrl(fileLists);
                      //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                      //           propertyCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>

          {/* Gallery  */}

          {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          <div className="md:flex mt-3">
            <div className="md:w-1/5">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                Safety Item Notes:
              </label>
            </div>
            <div className="md:w-4/5">
              <CKEditor
              // initData={editorData?.dln_id_issue_notes}
              // onChange={(e) => {
              //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
              //   setEditorData({
              //     ...editorData,
              //     dln_id_issue_notes: cleanText,
              //   });
              // }}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Save</button>
            </div>
          </div>
    </>
  );
};
