import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../../utility/http";
export const getEthnicities = createAsyncThunk("auth/ethnicities", async () => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-ethnicities");
    return response.data;
  } catch (error) {
    console.log("ethnicities api has error");
  }
});



