import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import ApiRequest from "../../utility/http";
import { AdminForm } from "../../components/property/AdminForm";
import { useDispatch, useSelector } from "react-redux";
import { Header4 } from "../../components/Header/Header";
import { adminPrefix } from "../../store/admin/adminThunk";
import { adminSuffix } from "../../store/admin/adminThunk";
import {
  TableNamePrefix,
  TableNameSuffix,
  TableParkingPrefix,
  TableParkingSuffix,
  MoveOutReasonTable,
  EthnicitiesTable,
} from "../../components/PrefixSuffixTable";
import { EditPrefixSuffixForm } from "../../components/admin/Prefix-Suffix-edit-form";
import {
  NameEditForm,
  NameSuffixEditForm,
} from "../../components/admin/NameEdit";
import {
  ParkingPrefixEditForm,
  ParkingSuffixEditForm,
} from "../../components/admin/ParkingEdit";
import { MoveOutEditForm } from "../../components/admin/MoveoutreasonEdit";
import { EthnicitiesEditForm } from "../../components/admin/EthnicitiesEdit";
import { FeaturesTable } from "../../components/PrefixSuffixTable";
import { FeaturesEditForm } from "../../components/admin/FeaturesEdit";

import Spinner from "../../components/Spinner/Spinner";
import imgUrl from "../../components/ImageUrl";
import { NumberType } from "../../components/admin/EditAbleList/NumberType";
import { PictureType } from "../../components/admin/EditAbleList/PictureType";
import { FileType } from "../../components/admin/EditAbleList/FileType";
import { Nearby } from "../../components/admin/EditAbleList/Nearby";
import { Ethnicity } from "../../components/admin/EditAbleList/Ethnicity";
import { Prefix } from "../../components/admin/EditAbleList/Prefix";
import { Suffix } from "../../components/admin/EditAbleList/Suffix";
import { ParkingPrefix } from "../../components/admin/EditAbleList/ParkingPrefix";
import { ParkingSuffix } from "../../components/admin/EditAbleList/ParkingSufix";
import { MoveOutReason } from "../../components/admin/EditAbleList/MoveOutReason";
import { Feature } from "../../components/admin/EditAbleList/Feature";
import { Utility } from "../../components/admin/EditAbleList/Utility";
import { MaterialSellingUnit } from "../../components/admin/EditAbleList/MaterialSellingUnit";
import { MaterialCoverageUnit } from "../../components/admin/EditAbleList/MaterialCoverageUnit";
import { ChangeLogCategory } from "../../components/admin/EditAbleList/ChangeLogCategory";
import { GeneralIcon } from "../../components/admin/EditAbleList/GeneralIcon";
import { EvictionReason } from "../../components/admin/EditAbleList/EvictionReason";
import { UnitFeature } from "../../components/admin/EditAbleList/UnitFeature";
import { WebsiteSetting } from "../../components/admin/EditAbleList/WebsiteSetting";
import { AdminVendor } from "../../components/admin/EditAbleList/AdminVendor";
import { CustomerVendor } from "../../components/admin/EditAbleList/CustomerVendor";
import { PropertyType } from "../../components/admin/EditAbleList/PropertyType";
import { UnitType } from "../../components/admin/EditAbleList/UnitType";
import { VehicleMake } from "../../components/admin/EditAbleList/VehicleMake";
import { VehicleModel } from "../../components/admin/EditAbleList/VehicleModel";
import { Countries } from "../../components/admin/EditAbleList/Countries";
import { States } from "../../components/admin/EditAbleList/States";
import { Cities } from "../../components/admin/EditAbleList/Cities";
export const EditableList = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");

  const [namePrefix, setNamePrefix] = useState();
  const [nameSuffix, setNameSuffix] = useState();
  const [parkingPrefix, setParkingPrefix] = useState();
  const [parkingSuffix, setParkingSuffix] = useState();
  const [moveOutReasons, setMoveOutReasons] = useState();
  const [etch, setEtch] = useState();
  const [features, setFeatures] = useState();
  const [featureIcon, setFeatureIcon] = useState();
  const [orderNumber, setOrderNumber] = useState();



  const [displayNamePrefixForm, setDisplayNamePrefixForm] = useState(false);
  const [displayNameSuffixForm, setDisplayNameSuffixForm] = useState(false);
  const [displayParkingPrefixForm, setDisplayParkingPrefixForm] =
    useState(false);
  const [displayParkingSuffixForm, setDisplayParkingSuffixForm] =
    useState(false);
  const [displayMoveOutForm, setDisplayMoveOutForm] = useState(false);
  const [displayEtchForm, setDisplayEtchForm] = useState(false);
  const [displayFeaturesForm, setDisplayFeaturesForm] = useState(false);
  const [apiIdNamePrefix, setApiIdNamePrefix] = useState();
  const [apiIdNameSuffix, setApiIdNameSuffix] = useState();
  const [apiIdParkingPrefix, setApiIdParkingPrefix] = useState();
  const [apiIdParkingSuffix, setApiIdParkingSuffix] = useState();
  const [apiIdMoveOutReasons, setApiIdMoveOutReasons] = useState();
  const [apiIEtch, setApiIEtch] = useState();
  const [apiIdFeatures, setApiIdFeatures] = useState();
  // const [parkingSuffix, setParkingSuffix] = useState();
  const [moveout, setMoveOut] = useState();
  const [Ethnicities, setEthnicitiest] = useState();
  
  const dispatch = useDispatch();
  const prefixes = useSelector(
    (state) => state?.adminPrefix?.Prefixes?.data?.prefixes
  );
  const suffixes = useSelector(
    (state) => state.adminSuffix.Suffixes?.data?.suffixes
  );
  const loading = useSelector((state) => state.adminPrefix?.status);
  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };

  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  // const handleCallApis = () => {
  //   dispatch(adminPrefix());
  //   dispatch(adminSuffix());
  //   // dispatch(propertiesTypes());

  //   const getData = async () => {
  //     try {
  //       const resPrefix = await ApiRequest.get(
  //         "/public/api/auth/get-parking-space-prefixes"
  //       );
  //       const resSuffix = await ApiRequest.get(
  //         "/public/api/auth/get-parking-space-suffixes"
  //       );
  //       const moveReason = await ApiRequest.get(
  //         "/public/api/auth/get-moveout-reasons"
  //       );
  //       const Ethnicities = await ApiRequest.get(
  //         "/public/api/auth/get-ethnicities"
  //       );
  //       setEthnicitiest(Ethnicities?.data.data.ethnicities);
  //       setMoveOut(moveReason?.data.data.reasons);
  //       // setParkingPrefix(resPrefix?.data.data.prefixes);
  //       // setParkingSuffix(resSuffix.data.data.suffixes);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getData();
  // };



  useEffect(() => {
    dispatch(adminPrefix());
    dispatch(adminSuffix());
    // dispatch(propertiesTypes());

    const getData = async () => {
      try {
        const resPrefix = await ApiRequest.get(
          "/public/api/auth/get-parking-space-prefixes"
        );
        const resSuffix = await ApiRequest.get(
          "/public/api/auth/get-parking-space-suffixes"
        );
        const moveReason = await ApiRequest.get(
          "/public/api/auth/get-moveout-reasons"
        );
        const Ethnicities = await ApiRequest.get(
          "/public/api/auth/get-ethnicities"
        );
        setEthnicitiest(Ethnicities?.data.data.ethnicities);
        setMoveOut(moveReason?.data.data.reasons);
        // setParkingPrefix(resPrefix?.data.data.prefixes);
        // setParkingSuffix(resSuffix.data.data.suffixes);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);


  // const getEthnicities = async()=>{await ApiRequest.get(
  //   "/public/api/auth/get-ethnicities"
  // )};
  // useEffect(() => {
  //   getEthnicities();

  // }, [])
  


  const [collapsed,setCollapsed] = useState(true)
const handleChildMessage = (message) => {
setCollapsed(message)
};

  return (
    <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/assets/admin/css/main.css" />
        <link rel="stylesheet" href="/assets/admin/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/slick.css" />
        <link rel="stylesheet" href="/assets/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Site Settings" child="Editable Lists" user_type="super_admin" onChildMessage={handleChildMessage}/>
      <div
        className={`main-content ${collapsed ? "collapsed" : ""}`}
        style={{padding:"0 0"}}
        onScroll={(e) => handleContentScroll(e)}
      >
        {/* header bar */}
      <Header4 styling={{padding:"0 50px"}}/>
        {/* search wraper  */}
        <section className="search-wraper" style={{padding:"0 50px"}}>
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              {/* <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                
                </div>
              </div> */}
              <div className="flex align-items-center">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-lists.png"
                    alt=""
                  />
                  <h2 className="text-3xl font-bold text-black py-3">
                    Editable Lists
                  </h2>
                </div>
            </div>
          </div>
        </section>
        {/* service wraper */}
        {/* <div className="pr-5" style={{padding:"0 50px"}}>
          <div className="flex align-items-center">
            <img
              className="logo w-12 mr-5"
              src="/assets/svgs/system-lists.png"
              alt=""
            />
            <h2 className="text-3xl font-bold text-black py-3">
              Editable Lists
            </h2>
          </div>
        </div> */}
        {/* {
          loading !=="success"?<Spinner/>:<section>
          <section>
          <div className="flex mt-4">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Name prefix </p>
        <TableNamePrefix
                // loading={loading}
                setDisplayNamePrefixForm={setDisplayNamePrefixForm}
                // handleCallApis={handleCallApis}
                setNamePrefix={setNamePrefix}
                setApiIdNamePrefix={setApiIdNamePrefix}
              />
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayNamePrefixForm ? (
                <NameEditForm
                  namePrefix={namePrefix}
                  setNamePrefix={setNamePrefix}
                  apiIdNamePrefix={apiIdNamePrefix}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Name suffix </p>
          <TableNameSuffix
                loading={loading}
                setDisplayNameSuffixForm={setDisplayNameSuffixForm}
                // handleCallApis={handleCallApis}
                setNameSuffix={setNameSuffix}
                setApiIdNameSuffix={setApiIdNameSuffix}
              />
              
           
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayNameSuffixForm ? (
                <NameSuffixEditForm
                  nameSuffix={nameSuffix}
                  setNameSuffix={setNameSuffix}
                  apiIdNameSuffix={apiIdNameSuffix}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Parking prefix </p>
             <TableParkingPrefix
                loading={loading}
                setDisplayParkingPrefixForm={setDisplayParkingPrefixForm}
                // handleCallApis={handleCallApis}
                setParkingPrefix={setParkingPrefix}
                setApiIdParkingPrefix={setApiIdParkingPrefix}
              />
              
            
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayParkingPrefixForm ? (
                <ParkingPrefixEditForm
                  parkingPrefix={parkingPrefix}
                  setParkingPrefix={setParkingPrefix}
                  apiIdParkingPrefix={apiIdParkingPrefix}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Parking suffix</p>
           <TableParkingSuffix
                loading={loading}
                setDisplayParkingSuffixForm={setDisplayParkingSuffixForm}
                // handleCallApis={handleCallApis}
                setParkingSuffix={setParkingSuffix}
                setApiIdParkingSuffix={setApiIdParkingSuffix}
              />
              
            
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayParkingSuffixForm ? (
                <ParkingSuffixEditForm
                  parkingSuffix={parkingSuffix}
                  setParkingSuffix={setParkingSuffix}
                  apiIdParkingSuffix={apiIdParkingSuffix}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Moveout reasons</p>
           <MoveOutReasonTable
                loading={loading}
                setDisplayMoveOutForm={setDisplayMoveOutForm}
                // handleCallApis={handleCallApis}
                setMoveOutReasons={setMoveOutReasons}
                setApiIdMoveOutReasons={setApiIdMoveOutReasons}
              />
              
             
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayMoveOutForm ? (
                <MoveOutEditForm
                  moveOutReasons={moveOutReasons}
                  setMoveOutReasons={setMoveOutReasons}
                  apiIdMoveOutReasons={apiIdMoveOutReasons}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Ethnicities</p>
              
             <EthnicitiesTable
                loading={loading}
                setDisplayEtchForm={setDisplayEtchForm}
                // handleCallApis={handleCallApis}
                setEtch={setEtch}
                setApiIEtch={setApiIEtch}
              />
           
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayEtchForm ? (
                <EthnicitiesEditForm
                  etch={etch}
                  setEtch={setEtch}
                  apiIEtch={apiIEtch}
                />
              ) : null}
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="flex">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <p className="text-2xl bold">Features</p>
             <FeaturesTable
                loading={loading}
                setDisplayFeaturesForm={setDisplayFeaturesForm}
                // handleCallApis={handleCallApis}
                setFeatures={setFeatures}
                setOrderNumber={setOrderNumber}
                setFeatureIcon={setFeatureIcon}
                setApiIdFeatures={setApiIdFeatures}
              />
           
            </div>
            <div className="col-xl-6 col-md-12 col-lg-6 px-4 py-8">
              {displayFeaturesForm ? (
                <FeaturesEditForm
                features={features}
                orderNumber={orderNumber}
                featureIcon={featureIcon}
                  setFeatures={setFeatures}
                  setOrderNumber={setOrderNumber}
                  setFeatureIcon={setFeatureIcon}
                  apiIdFeatures={apiIdFeatures}
                />
              ) : null}
            </div>
          </div>
        </section>
          </section>
        } */}
        <div class="md:flex flex-column md:items-center w-full bg-gray-200 m-auto">
        <div class="w-full mt-2">
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              Assets:
            </h4>
            <MaterialCoverageUnit/>
            <MaterialSellingUnit/>
      </div>
      <div class="w-full mt-2">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              ChangeLog:
            </h4>
          <ChangeLogCategory/>
      </div>
      <div class="w-full">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl">
              General:
            </h4>
        <Cities/>
        <Countries/>
        <Ethnicity/>
        <FileType/>
        <GeneralIcon/>
        <NumberType/>
        <ParkingSuffix/>
        <ParkingPrefix/>
        <PictureType/>
        <Prefix/>
        <States/>
        <Suffix/>
        

        
        <VehicleMake/>
        <VehicleModel/>
        <WebsiteSetting/>
        
      </div>
      
      
      <div class="w-full mt-2">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              Lease:
            </h4>  
        <EvictionReason/>
        <MoveOutReason/>
      </div>
      <div class="w-full mt-2">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              Property:
            </h4>  
            <Feature/>
        <Nearby/>
        <PropertyType/>

      </div>
      <div class="w-full mt-2">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              Unit:
            </h4>  
            <UnitFeature/>
            <UnitType/>
            <Utility/>

      </div>
      <div class="w-full mt-2">
      <hr />
        <h4 class="block capitalize text-gray-700 font-bold m-2 text-2xl mt-2">
              Vendor:
            </h4>  
            <AdminVendor/>
            <CustomerVendor/>

      </div>
      </div>
        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>
  );
};
