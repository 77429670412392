import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
export const PlanTable = () => {
    const columns = [
        {
            name: "Plan",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Type",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Featured",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Storage",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Properties",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Units",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Tenants",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Active Subscribers",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Total Subscribers",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Monthly Prices",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Annual Prices",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Annual Income",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Status",
            // selector: (row) => row.unique_id,
            sortable: true,
          },
        {
            name: "Options",
            // selector: (row) => row.unique_id,
            sortable: true,
          },

    ];
    const tableData = {
        columns : columns,
        data : '',
        fixedHeader: true,
        fixedHeaderScrollHeight: '500px',
        sortFieldId: 1,
        sortDirection:"asc"
      }
  return (
    <>
    <DataTableExtensions filter={false} {...tableData}>
        <DataTable
        className="striped"
          // columns={columns}
          // data={newArray1}
          // pagination
          // fixedHeader
          // fixedHeaderScrollHeight="500px"
          selectableRows
          pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
          selectableRowsHighlight
          highlightOnHover
        //   onSelectedRowsChange={handleRowSelected}
          // defaultSortFieldId={1}
        />
        </DataTableExtensions>
        {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
        {/* <div class="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ?<div className="md:flex md:items-center w-full md:w-8/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected value=' '>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"MAKE ACTIVE"}>MAKE ACTIVE</option>
                <option value={"MAKE INACTIVE"}>MAKE INACTIVE</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-1/2 ml-1">
               <button
                  type="button"
                  className="btn2"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE INACTIVE" ? bulkMakeInActive :  bulkAction?.bulk_action == "DELETE" ? bulkDelete : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
        <div class="bg-indigo-300 p-2 rounded unit-wrapper">
              <div class="flex flex-wrap gap-x-4">
                <p class="flex-auto">
                  Total Active Units: <span>{countUnitActive}</span>
                </p>
                <p class="flex-auto">
                  Total Active Rented Units:{" "}
                  <span class="text-green-600">{countUnitRentedActive}</span>
                </p>
                <p class="flex-auto">
                  Total Active Unrented Units:{" "}
                  <span class="text-red-600">{countUnitUnRentedActive}</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Units: <span>{totalUnit?.length}</span>
                </p>
                <p class="flex-auto">
                  Total Rented Units: <span>{countRentedUnit}</span>
                </p>
                <p class="flex-auto">
                  Total Unrented Units: <span>{countUnRentedUnit}</span>
                </p>
              </div>
            </div>
            </div> */}
    </>
  )
}
