import { createSlice } from "@reduxjs/toolkit";
import {getEthnicities} from "./Thunk";
const EthnicitiesSlice = createSlice({
  name: "ethnicities",
  initialState: {
    status: null,
    ethnicities: {},
  },
  extraReducers: {
    [getEthnicities.pending]: (state) => {
      state.status = "loading";
    },
    [getEthnicities.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.ethnicities = payload;
    },
    [getEthnicities.rejected]: (state, _error) => {
      state.status = "failed";
      state.ethnicities = {};
    }
  },
});

export default EthnicitiesSlice.reducer;
