import React from 'react'
import { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import imgUrl from '../ImageUrl';
import Spinner from '../Spinner/Spinner';
import ApiRequest from '../../utility/http';
export const MaterialIdentifiers = () => {
    const [materialIdentifiers,setMaterialIdentifiers] = useState();
    const [loader,setLoader] = useState();
    const [editMaterialIdentifiers,setEditMaterialIdentifiers] = useState();
    const [editOrder,setEditOrder] = useState();
    const [editID,setEditID] = useState();
    const [allMaterialIdentifiers,setAllMaterialIdentifiers] = useState([]);
    const getMaterialIdentifiers = async()=>{
        const response = await ApiRequest.get('/public/api/auth/get-material-identifiers')
        if(response.status == 200)
        {
          setAllMaterialIdentifiers(response?.data?.data);
          setLoader(response);
        }
      }
    
      const indexFind = (id) => {
        const editIdentifier = allMaterialIdentifiers.find(materialIdentifiers => materialIdentifiers.id === id);
        // console.log("editIdentifier", editIdentifier);
        setEditMaterialIdentifiers(editIdentifier?.identifier);
        setEditOrder(editIdentifier?.order_number);
        setEditID(editIdentifier?.id);
      }

      const orderChange = (id, direction) => {
        const newListItems = [...allMaterialIdentifiers];
        const currentItem = newListItems.find(item => item.id === id);
        const currentIndex = newListItems.indexOf(currentItem);
      
        if (direction === "up" && currentIndex > 0) {
          const previousItem = newListItems[currentIndex - 1];
          const newOrder = currentItem.order_number - 1;
          if (previousItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(0, currentIndex).filter(item => item.order_number >= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number += 1;
            });
          }
          previousItem.order_number = newOrder;
          currentItem.order_number = newOrder + 1;
          newListItems[currentIndex - 1] = currentItem;
          newListItems[currentIndex] = previousItem;
        } else if (direction === "down" && currentIndex < newListItems.length - 1) {
          const nextItem = newListItems[currentIndex + 1];
          const newOrder = currentItem.order_number + 1;
          if (nextItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(currentIndex + 1).filter(item => item.order_number <= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number -= 1;
            });
          }
          nextItem.order_number = newOrder;
          currentItem.order_number = newOrder - 1;
          newListItems[currentIndex + 1] = currentItem;
          newListItems[currentIndex] = nextItem;
        }
      
        reArrangeData(newListItems);
        console.log('newListItems',newListItems);
      };
      
      
      const deleteData =async(id)=>{
        const { isConfirmed } = await Swal.fire({
          title: 'Are you sure?',
          text: 'This will Delete the MaterialIdentifiers. Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        });
      
        // If user confirms, Inactivate the Nearby
        if (isConfirmed) {
          setLoader(undefined);
          try {
            // const ArrayId = [];
            // ArrayId.push(id);
            const response = await ApiRequest.delete(
              `/public/api/auth/delete-my-material-identifier?id=${id}`
              
            );
            // orderChange();
          getMaterialIdentifiers();
            // Show success message
            if(response.status == 200)
            {
              Swal.fire({
              title: 'Success!',
              text: 'The MaterialIdentifiers have been Deleted.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          }
      
          } catch (error) {
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'An error occurred while Deleting the MaterialIdentifiers. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } finally {
            setLoader('');
          }
        }
      }

    const handleOrderChange = (id, direction) => {
        const index = allMaterialIdentifiers?.findIndex(item => item.id === id);
        const newListItems = [...allMaterialIdentifiers];
        const currentItem = newListItems[index];
    
        if (direction === "up" && index > 0) {
            const previousItem = newListItems[index - 1];
            currentItem.order_number = previousItem.order_number;
            previousItem.order_number = index + 1;
            newListItems[index - 1] = currentItem;
            newListItems[index] = previousItem;
        } else if (direction === "down" && index < newListItems.length - 1) {
            const nextItem = newListItems[index + 1];
            currentItem.order_number = nextItem.order_number;
            nextItem.order_number = index + 1;
            newListItems[index + 1] = currentItem;
            newListItems[index] = nextItem;
        }
    
        reArrangeData(newListItems);
    };
    const reArrangeData = async(Data)=>{
      setLoader(undefined);
      const response = await ApiRequest.post('/public/api/auth/rearrange-admin-settings-lists',{
          identifier:'identifiers',
          data:Data,
      }
      );
      if(response.status == 200)
      {
          getMaterialIdentifiers();
      }
  }
    const sortedData = allMaterialIdentifiers?.sort((a, b) => a.order_number - b.order_number);

      const AddMaterialIdentifiers = async ()=>{
        setLoader(undefined)
        const sortedMaterialIdentifierss = allMaterialIdentifiers.sort((a, b) => b.order_number - a.order_number);
        const lastOrderNumber = sortedMaterialIdentifierss[0]?.order_number;
        const payload = {
          identifier:materialIdentifiers,
        //   order_number:lastOrderNumber != undefined ? lastOrderNumber + 1 : 1
        }
        const editPayload = {
          id:editID,
          identifier:editMaterialIdentifiers,
        //   order_number:editOrder
        }
        const response = await ApiRequest.post('/public/api/auth/material-identifier',
        editID == undefined ? payload : editPayload
        )
        if(response?.status == 200)
        {
          getMaterialIdentifiers();
          setMaterialIdentifiers('')
          setEditID(undefined)
        }
      }
      useEffect(() => {
        getMaterialIdentifiers();
      }, [])
    //   const highestOrderNumber = sortedData.reduce((maxOrderNumber, row) => {
    //     return row.order_number > maxOrderNumber ? row.order_number : maxOrderNumber;
    //   }, 0);
  return (
    <>
    
        <div class="md:flex md:items-center w-full mt-4 mx-2">
          <div class="md:w-2/5">
            <label class="block capitalize text-gray-700 font-bold mb-2">
                    Materials: Identifier
            </label>
            <span class="block text-gray-700 font-light mb-2 mr-2">
            Identifier of material being tracked.
            </span>
          </div>
          <div class="md:flex-1 mr-4 pr-4 mb-3">
            <div class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
              <div className="flex">
                <form>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/5">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Identifier:
                      </label>
                    </div>
                    <div class="md:w-4/5 mr-4">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="materialIdentifiers"
                        type="text"
                        value={materialIdentifiers}
                        placeholder="Wall"
                        onChange={(e)=>{
                          setMaterialIdentifiers(e.target.value);
                        }}
                      />
                    </div>
                    <div class="md:flex-1 ml-52 flex justify-end">
                    {/* <label className="bg-blue-500 text-white py-2 px-4 rounded ml-2 cursor-pointer w-28">
                        Add Icon
                        <input
                      accept="image/*"
                      id={`number-type-pic`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // console.log("res", res?.data?.data?.image);
                            // formData?.dl_id_picture = res?.data?.data?.image
                            setMaterialIdentifiersIcon(res?.data?.data?.image);
                            // setDlidPicture(res?.data?.data?.image)
                            // setFeatureIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);

                        // console.log("here 142");
                        // const pictures = [...propertyAssistantPictures];
                        // pictures[index] = getImagesAndURLs(e)[0];
                        // setPropertyAssistantPictures(pictures);
                        // setPropertyContacts(contacts);
                        // setFormData({...formData, contacts : contacts})
                      }}
                    />
                      </label> */}
                      <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                          AddMaterialIdentifiers();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <hr />
              {loader == undefined ? <Spinner/> :<>
              {allMaterialIdentifiers?.map((identifier)=>(
                <>
              <div class="flex justify-between">
                  <div class="flex-initial">
                      <div className="flex">
                      {/* {identifier?.order_number != 1 ? (
                                <i className="fa fa-sort-up text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(identifier?.id, 'up')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )}
                            {identifier?.order_number < highestOrderNumber ? (
                                <i className="fa fa-sort-down text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(identifier?.id, 'down')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )} */}
                          {/* <img src={`${imgUrl}/${identifier?.icon}`} alt="Icon" className="logo w-6 mx-2"/> */}
                          <span>{identifier?.identifier}</span>
                      </div>
                  </div>
                  <div class="flex-initial">
                      <div className="flex">
                          <img src="/assets/img/system-edit.png" alt="Edit" title="Edit" data-bs-target={`#materialIdentifiersModal`} data-bs-toggle="modal" className="logo w-6 mx-2 cursor-pointer" onClick={()=>{
                            indexFind(identifier?.id)
                          }}/>
                          {/* <img src="/assets/svgs/system-trashcan.svg" alt="Delete" title="Delete" onClick={()=>{deleteData(identifier?.id)}} className="logo w-6 mx-2 cursor-pointer"/> */}
                      </div>
                  </div>
              </div>
                  <hr />
              </>
              ))}
              </>}
            </div>
          </div>
        </div>
        <div
            class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id={`materialIdentifiersModal`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog relative w-auto pointer-events-none">
              <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                  <h5
                    class="text-xl font-medium leading-normal text-gray-800"
                    id="exampleModalLabel"
                  >
                    Edit
                  </h5>
                  <button
                    type="button"
                    class="btn2-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{setEditID(undefined)}}
                  ><i className='fa fa-remove'></i></button>
                </div>
                <div class="modal-body relative p-4">
                 
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Identifier:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`number-identifier`}
                    type="text"
                    value={editMaterialIdentifiers}
                    placeholder="Wall"
                    onChange={(e) => {
                    setEditMaterialIdentifiers(e.target.value)
                    }}
                   
                  />
                </div>
              </div>
              </div>
            {/* <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Icon:
                  </label>
                </div>

                    <label className="bg-blue-500 text-white py-2 px-4 rounded ml-2 cursor-pointer w-28">
                        Add Icon
                        <input
                      accept="image/*"
                      id={`number-identifier-pic`}
                      identifier="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // console.log("res", res?.data?.data?.image);
                            // formData?.dl_id_picture = res?.data?.data?.image
                            setEditMaterialIdentifiersIcon(res?.data?.data?.image);
                            // setDlidPicture(res?.data?.data?.image)
                            // setFeatureIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);

                        // console.log("here 142");
                        // const pictures = [...propertyAssistantPictures];
                        // pictures[index] = getImagesAndURLs(e)[0];
                        // setPropertyAssistantPictures(pictures);
                        // setPropertyContacts(contacts);
                        // setFormData({...formData, contacts : contacts})
                      }}
                    />
                      </label>
                     
              </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <img src={`${imgUrl}/${editMaterialIdentifiersIcon}`} alt="" />
              </div> */}
          </div>
        </div>
                </div>
                <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  
                  <button
                    type="button"
                    class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal"
                    onClick={()=>{AddMaterialIdentifiers()}}
                    // disabled={disabled}
                  >
                    Update
                  </button>
                  
                  
                  
                </div>
              </div>
            </div>
          </div>
  </>
  )
}
