import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { convertToFormData } from "../../utility/helperFunctions";
import { editProperty } from "../../store/properties/propertiesThunk";
import { showProperty } from "../../store/properties/propertiesThunk";
import { ShowPropertyForm } from "../../components/property/ShowPropertyForm";
import ApiRequest from "../../utility/http";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { OwnerHeader } from "../OwnerHeader";
import { Footer } from "../../components/Dashboard/Footer";
export const ShowPropertyPage = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();
  const dispatch = useDispatch();
  const { id } = useParams();
  const ShowProperty = useSelector(
    (state) => state.showProperty.showProperty.data
  );
  console.log("27", ShowProperty);
  // CONSTANTS

  const initialData = {
    name: "",
    type_id: "",
    is_active: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
    picture_template_id: "",
    pictures: [],
    embed: "",
    managers: {},
    contacts: [],
    security_firstname: "",
    security_lastname: "",
    security_company: "",
    security_number1: "",
    security_number1_type: "",
    security_number2: "",
    security_number2_type: "",
    security_number3: "",
    security_number3_type: "",
    security_email: "",
    security_info: "",
    security_picture: "",
    wifi_network: "",
    wifi_password: "",
    alarm_code: "",
    gate_code: "",
    door_code: "",
    mailbox_number: "",
    other_info: "",
    no_of_floors: "",
    has_stairs: "",
    has_elevator: "",
    description: "",
    features:[],
    nearbys:[],
    other_property_features: "",
    measurements: [
      // name: "",
      // location: "",
      // measurement_unit: "",
      // length: "",
      // width: "",
      // height: "",
      // depth: "",
      // area: "",
      // volume: "",
      // pictures: [],
      // files: [],
      // notes: "",
    ],
    materials: [
      // {
      // 	material_type_id: 3,
      // 	material_location_id: 3,
      // 	material_brand_id: 2,
      // 	material_identifier_id: 1,
      // 	material_vendor_id: 1,
      // 	last_installed: "12-06-2022",
      // 	notes: "materials notes",
      // 	pictures: [
      // 		{
      // 			picture: "62a6e8396cbf0.png",
      // 			order_number: "1",
      // 		},
      // 		{
      // 			picture: "62a6e8396cbf0.png",
      // 			order_number: "2",
      // 		},
      // 	],
      // 	files: [
      // 		{
      // 			file: "62a6e8396cbf0.png",
      // 		},
      // 	],
      // },
      // {
      // 	material_type_id: 2,
      // 	material_location_id: 3,
      // 	material_brand_id: 1,
      // 	material_identifier_id: 1,
      // 	material_vendor_id: 1,
      // 	last_installed: "04-12-2021",
      // 	notes: "materials notesss",
      // 	pictures: [
      // 		{
      // 			picture: "62a6e8396cbf0.png",
      // 			order_number: "1",
      // 		},
      // 	],
      // 	files: [
      // 		{
      // 			file: "62a6e8396cbf0.png",
      // 		},
      // 		{
      // 			file: "62a6e8396cbf0.png",
      // 		},
      // 	],
      // },
    ],
    hoa_show_to_tenant: "",
    hoa_show_to_property_manager: "",
    hoa_company: "",
    hoa_contact_firstname: "",
    hoa_contact_lastname: "",
    hoa_contact_title: "",
    hoa_address_line1: "",
    hoa_address_line2: "",
    hoa_city: "",
    hoa_country: "",
    hoa_state: "",
    hoa_zip_code: "",
    hoa_number1: "",
    hoa_number1_type: "",
    hoa_number2: "",
    hoa_number2_type: "",
    hoa_number3: "",
    hoa_number3_type: "",
    hoa_email: "",
    hoa_website: "",
    hoa_fee: "",
    hoa_fee_show_to_tenant: "",
    hoa_fee_show_to_property_manager: "",
    hoa_due_date: "",
    hoa_tenant_notes: "",
    hoa_property_manager_notes: "",
    hoa_owner_notes: "",
    hoa_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    hoa_contacts:[],
    hoa_interactions:[],
    mortage_contacts:[],
    mortage_interactions:[],
    builder_contacts:[],
    builder_interactions:[],
    
    mortage_company: "",
    mortage_contact_firstname: "",
    mortage_contact_lastname: "",
    mortage_contact_title: "",
    mortage_address_line1: "",
    mortage_address_line2: "",
    mortage_city: "",
    mortage_country: "",
    mortage_state: "",
    mortage_zip_code: "",
    mortage_number1: "",
    mortage_number1_type: "",
    mortage_number2: "",
    mortage_number2_type: "",
    mortage_number3: "",
    mortage_number3_type: "",
    mortage_email: "",
    mortage_website: "",
    mortage_minimum_payment: "",
    mortage_due_date: 0,
    mortage_balance: "",
    mortage_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    mortage_notes: "",
    builder_company: "",
    builder_date: "",
    builder_firstname: "",
    builder_lastname: "",
    builder_title: "",
    builder_address_line1: "",
    builder_address_line2: "",
    builder_country: "",
    builder_city: "",
    builder_state: "",
    builder_zip_code: "",
    builder_number1: "",
    builder_number1_type: "",
    builder_number2: "",
    builder_number2_type: "",
    builder_number3: "",
    builder_number3_type: "",
    builder_email: "",
    builder_website: "",
    builder_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    builder_notes: "",
    insurances: [
      // {
      // 	company: "ins company",
      // 	property_insurance_type_id: 1,
      // 	policy_number: "478521369",
      // 	start_date: "02-01-2022",
      // 	end_date: "03-11-2022",
      // 	contact_firstname: "fname",
      // 	contact_lastname: "lname",
      // 	contact_title: "ctitle",
      // 	address_line1: "ia1",
      // 	address_line2: "ia2",
      // 	city: "ic",
      // 	state: "is",
      // 	zip_code: "iz",
      // 	number1: "46465",
      // 	number1_type: "mobile",
      // 	number2: "",
      // 	number2_type: "",
      // 	number3: "",
      // 	number3_type: "",
      // 	email: "i@a.a",
      // 	premium: "ip",
      // 	frequency: "if",
      // 	due_date: "31-12-2022",
      // 	deductible: "",
      // 	files: [
      // 		{
      // 			file: "62a6e8396cbf0.png",
      // 		},
      // 		{
      // 			file: "62a6e8396cbf0.png",
      // 		},
      // 	],
      // 	notes: "i notes",
      // },
    ],
    insurances_claims: [
      // {
      // 	date_of_claim: "",
      // 	case_number: "",
      // 	title: "",
      // 	description: "",
      // 	status: "",
      // 	agent_firstname: "",
      // 	agent_lastname: "",
      // 	agent_title: "",
      // 	number1: "",
      // 	number1_type: "",
      // 	number2: "",
      // 	number2_type: "",
      // 	number3: "",
      // 	number3_type: "",
      // 	email: "",
      // 	claim_files: [
      // 		// {
      // 		// 	file: "62a6e8396cbf0.png",
      // 		// },
      // 		// {
      // 		// 	file: "62a6e8396cbf0.png",
      // 		// },
      // 	],
      // 	notes: "",
      // 	date_closed: "",
      // 	claim_resolution_files: [
      // 		// {
      // 		// 	file: "62a6e8396cbf0.png",
      // 		// },
      // 		// {
      // 		// 	file: "62a6e8396cbf0.png",
      // 		// },
      // 	],
      // 	resolution_notes: "",
      // },
    ],
    parking_spaces : [],
    payment_info_company: "",
    payment_info_firstname: "",
    payment_info_lastname: "",
    payment_info_address_line1: "",
    payment_info_address_line2: "",
    payment_info_country: "",
    payment_info_city: "",
    payment_info_state: "",
    payment_info_zip_code: "",
    payment_info_number: "",
    payment_info_email: "",
    payment_info_other_payment_information: "",
    notices: [
      // {
      // 	title: "notice title",
      // 	notice: "notice notice",
      // 	show_as_popup: 1,
      // },
      // {
      // 	title: "notice title 1",
      // 	notice: "notice notice 1",
      // 	show_as_popup: 0,
      // },
      // {
      // 	title: "notice title 2",
      // 	notice: "notice notice 2",
      // 	show_as_popup: 1,
      // },
    ],
    boards: [
      // {
      // 	title: "board title",
      // 	icon: "",
      // 	show_as_popup: 1,
      // },
      // {
      // 	title: "board title 1",
      // 	icon: "",
      // 	show_as_popup: 0,
      // },
    ],
    property_staff_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    property_staff_notes: "",
    property_manager_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    property_manager_notes: "",
    property_tenant_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    property_tenant_notes: "",
    property_owner_files: [
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
      // {
      // 	file: "62a6e8396cbf0.png",
      // },
    ],
    property_owner_notes: "",
  };

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [formData, setFormData] = useState({});

  const { alertList } = useSelector((state) => state.alerts);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  const updateFormData = async (data = {}) => {
    console.log("data 429", data);
    console.log("...formData line 33 ",formData)
    if (JSON.stringify(formData) !== JSON.stringify(data)) {
      console.log("formData line 332 ",formData)

      const newData = {
        id,
        ...formData,
        ...data,
        // ...data?.boards?.map((obj, i) => {
        //    showProperty?.boards?.map((item, i) => {
        //      obj.id = item.id;
        //   });
        // }),
        // ...(data.builder_files.id = showProperty.builder_files.id),
        // ...(data.features.id = showProperty.features.id),
        // ...(data.files.id = showProperty.files.id),
        // ...(data.hoa_files.id = showProperty.hoa_files.id),
        // ...(data.insurance_claims.id = showProperty.insurance_claims.id),
        // ...(data.insurances.id = showProperty.insurances.id),
        // ...(data.managers.id = showProperty.managers.id),
        // ...(data.managers_files.id = showProperty.managers_files.id),
        // ...(data.materials.id = showProperty.materials.id),
        // ...(data.measurements.id = showProperty.measurements.id),
        // ...(data.mortage_files.id = showProperty.mortage_files.id),
        // ...(data.notices.id = showProperty.notices.id),
        // ...(data.notifications.id = showProperty.notifications.id),
        // ...(data.owners_files.id = showProperty.owners_files.id),
        // ...(data.parking_spaces.id = showProperty.parking_spaces.id),
        // ...(data.pictures.id = showProperty.pictures.id),
        // ...(data.staff_files.id = showProperty.staff_files.id),
      };
      console.log("newData", newData)
      setFormData(newData);
      const apiBody = convertToFormData(newData);
      console.log("apiBody === line 463", apiBody);
      const response = await ApiRequest.post(
        "public/api/auth/property",
        apiBody
      );
      if (response == 200) {
        debugger;
      } else {
        debugger;
      }
    }
  };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);
  console.log("initialData", initialData);

  useEffect(() => {
    setFormData(initialData);
  }, []);

  useEffect(() => {
    if (alertList?.length) {
      alertList.map((alertEl) => {
        Alert.show(alertEl);
        Alert.remove(alertEl);
      });
    }
  }, [alertList]);

  useEffect(() => {
    dispatch(editProperty(id));
    dispatch(showProperty(id));
  }, []);
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/table.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Properties" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>
          <div className="flex flex-row-reverse items-center gap-2">
            <a href={`/edit-property/${id}`}>
                <button className="btn2">
                <i className="fa fa-edit"></i> Edit Property
                </button>
            </a>
              </div>
          <div className="px-5">
            
            <ShowPropertyForm
              initialData={
                Object.keys(formData).length ? formData : initialData
              }
              verticalScroll={verticalScroll}
              updateFormData={updateFormData}
              ShowProperty={ShowProperty}
            />
          </div>
          <div className="bottom-gap" />
        </div>
        {showFooter ? <Footer/> : null}
        {/* <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Serserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </div>
  );
};
