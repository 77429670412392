import { CKEditor } from 'ckeditor4-react'
import React from 'react'

export const AddIssueTrackerForm = () => {
  return (
    <>
    <div className="flex-col w-[90%] m-[auto] mt-5 mb-20">
      <div className="flex items-center w-1/2 justify-between">
        <img src="/assets/svgs/support-issues.svg" className="w-1/6" alt="" />
        <label className="w-9/12 capitalize tracking-wider text-gray-700 text-2xl font-bold">
          Report an Issue
        </label>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center w-full">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Type*:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
            <option></option>
          </select>
        </div>
        <div className="flex items-center w-full ">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Location*:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option></option>
          </select>
        </div>
      </div>
      <div className="flex items-center w-1/2 mt-4">
        <div className="flex items-center w-full ">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            complaints*:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
            <option></option>
          </select>
        </div>
      </div>
      <div className="flex items-center mt-4 justify-between">
        <div className="flex items-center w-full ">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Subject*:
          </label>
          <input
            type="text"
            className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          />
        </div>
        <div className="flex items-center w-full ">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Priority*:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option></option>
          </select>
        </div>
      </div>
      <div className="flex mt-8 w-full justify-between">
        <label className="block tracking-wide text-gray-700 text-base font-bold">
          Description*:
        </label>
        <div className="w-10/12 m-auto">
          <CKEditor />
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center w-1/2 justify-between ">
          <img src="svgs/system-pictures.svg" className="w-1/6" alt="" />
          <label className="block tracking-wide text-gray-700 text-base font-bold">
            Pictures:
          </label>
          <button className="btn btn3">Upload Pictures</button>
        </div>
        <div className="flex items-center w-1/2 justify-between">
          <img src="svgs/system-files.svg" className="w-1/6" alt="" />
          <label className="block tracking-wide text-gray-700 text-base font-bold">
            Files:
          </label>
          <button className="btn btn3">Upload Files</button>
        </div>
      </div>
      <div className="flex items-center w-1/3 justify-between mt-4">
        <label>Permission to Enter if not Home ?</label>
        <label class="inline-flex relative items-center cursor-pointer">
          <input type="checkbox" class="sr-only peer" />
          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      </div>
      <div className="flex  w-1/2 items-center mt-4">
        <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">Share with:</label>
        <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
            <option></option>
        </select>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center w-full">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Assign to:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
            <option></option>
          </select>
        </div>
        <div className="flex items-center w-full ">
          <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
            Ticket Status:
          </label>
          <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option></option>
          </select>
        </div>
      </div>
      <div className="flex items-center float-right mt-4">
        <button className="btn btn3">Submit</button>
      </div>
    </div>
    </>
  )
}
