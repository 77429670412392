import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../../utility/http";
export const getMoveOutReasons = createAsyncThunk("auth/moveOutReasons", async () => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-moveout-reasons");
    return response.data;
  } catch (error) {
    console.log("moveOutReasons api has error");
  }
});



