import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";
import { Calendar } from "./Calendar";
import { PropertyLocations } from "../property/PropertyLocations";

export const CalendarForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [securedFields, setSecuredFields] = useState({
    dln_id: "password",
  });
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Space Reservation Request" {...a11yProps(1)} />
          <Tab label="Calendars" {...a11yProps(2)} />
          <Tab label="Shift Types" {...a11yProps(3)} />
          <Tab label="Time Off Requests" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/calendar-reservation.svg"
                alt=""
              /> */}
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Add Event:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Title<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`staff-status`}
                      required={false}
                      //   value={tenantChildren?.status}
                      //   onChange={(e) => {
                      //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                      //   }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center mt-3 mr-1 w-1/4">
                  <label className="flex items-center mr-3">
                    <span className="ml-2">All Day</span>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      //   checked={sharingValue?.pms == 2}
                      //   value = {sharingValue?.pms}
                      //   onChange={(e)=>{
                      //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                      //   }}
                    />
                  </label>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Start<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      End<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/4 mt-3 mr-1">
                  {/* <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div> */}
                  <div className="md:w-full">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Location:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Other:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="Other"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Calendar:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Add Groups/People:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                <div className="md:w-1/12">
                  <img
                    src="/assets/svgs/system-files.svg"
                    alt="Files"
                    className="w-1/2"
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Files:
                  </label>
                </div>
                <div className="md:w-auto">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id={`p-measurement-files`}
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyMeasurementFiles?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyMeasurementFiles.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyMeasurementFiles];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyMeasurementFiles(fileLists);
                      //           let measurementsCopy = [...propertyMeasurementFiles];
                      //           measurementsCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setPropertyMeasurement({ ...propertyMeasurement, files: measurementsCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount =
                        propertyMeasurementFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
              </div>

              {/* Gallery  */}

              {/* <div >
              {propertyMeasurementFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMeasurementFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyMeasurement.files = fileLists;
                                setPropertyMeasurementFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMeasurementFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyMeasurement.files[fileIndex].title = title;
                                    setPropertyMeasurementFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/calendar-reservation.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Space Reservation Request:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Name of Event<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`staff-status`}
                      required={false}
                      //   value={tenantChildren?.status}
                      //   onChange={(e) => {
                      //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                      //   }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center mt-3 mr-1 w-1/4">
                  <label className="flex items-center mr-3">
                    <span className="ml-2">All Day</span>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      //   checked={sharingValue?.pms == 2}
                      //   value = {sharingValue?.pms}
                      //   onChange={(e)=>{
                      //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                      //   }}
                    />
                  </label>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Start<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/6 mt-3 mr-1">
                  <span>to</span>
                </div>
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      End<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Location:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Event Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PropertyLocations />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/calendar-settings.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Calendar Settings:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex mt-3">
                {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/svgs/calendar-settings.svg"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Calendars:
                </label>
              </div>
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    New
                  </button>
                </div>
              </div>

              <div class="flex w-full items-center mt-3">
                <div className="w-1/12 mr-2">
                  <input type="text" disabled className="bg-green-600 w-1/2" />
                </div>
                <div className="w-1/12">
                  <img
                    src="/assets/svgs/calendar-maintenance-event.svg"
                    className="w-1/2"
                    alt=""
                  />
                </div>
                <div className="w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Maintenance:
                  </label>
                </div>
                <div className="w-1/4">
                  <span className="italic">Tenants, Staff</span>
                </div>
                <div className="w-1/12">
                  <div className="flex">
                    <img
                      src="/assets/svgs/system-edit.svg"
                      className="w-1/3 mr-1"
                      alt=""
                    />
                    <img
                      src="/assets/svgs/system-trashcan.svg"
                      className="w-1/3"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/calendar-settings.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Calendar Settings:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex mt-3">
                {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/calendar-settings.svg"
                alt=""
              /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Shift Types:
                </label>
              </div>
              <div class="flex justify-end items-center mt-3">
                <div class="flex items-center gap-4">
                  <button type="button" class="btn btn3">
                    New
                  </button>
                </div>
              </div>
              <div class="flex w-full items-center mt-3">
                <div className="w-1/12 mr-2">
                  <input type="text" disabled className="bg-green-600 w-1/2" />
                </div>
                {/* <div className="w-1/12">
                  <img
                    src="/assets/svgs/calendar-maintenance-event.svg"
                    className="w-1/2"
                    alt=""
                  />
                </div> */}
                <div className="w-1/4">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Dinner:
                  </label>
                </div>
                <div className="w-1/4">
                  <span className="italic">All Staff</span>
                </div>
                <div className="w-1/12">
                  <div className="flex">
                    <img
                      src="/assets/svgs/system-edit.svg"
                      className="w-1/3 mr-1"
                      alt=""
                    />
                    <img
                      src="/assets/svgs/system-trashcan.svg"
                      className="w-1/3"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/staff-request-time-off.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Time Off Requests:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="flex items-center mt-3 w-full">
                <span className="italic">
                  Prevent Staff from deleting their approved requests:
                </span>
              </div>
              <div className="flex items-center mt-3 mr-1 w-full">
                <label className="flex items-center mr-3">
                  <span className="ml-2">Always</span>
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                </label>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/4 mt-3">
                  {/* <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Location:
                    </label>
                  </div> */}
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  {/* <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Location:
                    </label>
                  </div> */}
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-auto mt-3">
                  <span>Prior to the Approved Time Off Request Date</span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
