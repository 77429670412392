import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./TabPanel";

import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ParkingSpaceTable from "./ParkingSpaceTable";
import { useDispatch, useSelector } from "react-redux";
import { propertiesTypes } from "../../store/properties/propertiesThunk";
import {
  materialType,
  materialBrand,
} from "../../store/properties/materialmeasurementsThunk";
import imgUrl from "../ImageUrl";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loading";
import "../../components/button.css";
import { PropertyMaterials } from "./PropertyMaterials";
import { PropertyMeasurements } from "./PropertyMeasurements";
import FrontTree from "../../tree/FrontTree";
import { PropertyNotifications } from "./PropertyNotifications";
import { PropertyAssistanceInformations } from "./PropertyAssistanceInformations";
import { PropertySecurityContacts } from "./PropertySecurityContacts";
import { PropertyLocations } from "./PropertyLocations";
import { PropertyBoards } from "./PropertyBoards";
import { InfoContactForm } from "./InfoContactForm";
import { HoaInteractions } from "./HoaInteractions";
import { MortgageContactForm } from "./MortgageContactForm";
import { MortgageInteractions } from "./MortgageInteractions";
import { BuilderContactForm } from "./BuilderContactForm";
import { BuilderInteractions } from "./BuilderInteractions";
import { Lightbox } from "../lightbox/Lightbox";
import { Notes } from "./Notes";
import { Interactions } from "../tenant/Interactions";
import Swal from "sweetalert2";
import { Accordions } from "../multiPurposeComponent/Accordions";
import { Tooltip } from "../Loader/Tooltip";
import { Card } from "../multiPurposeComponent/Card";
import { ContactDetails } from "../multiPurposeComponent/ContactDetails";
import { CarouselWithLightbox } from "../carousel/CarouselWithLightbox";
export const ShowPropertyForm = ({
  initialData = {},
  validationData = {},
  verticalScroll = 0,
  updateFormData,
}) => {
  //  CONSTANTS

  const formElement = document.getElementById("add-property-form");
  const formSubmitButton = document.getElementById("form-submit-button");

  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialPictureData = {
    picutres: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
      order_number: "",
    },
  };

  const initialContactData = {
    firstname: "",
    lastname: "",
    title: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    email: "",
    info: "",
    picture: null,
  };

  const initialSpaceData = {
    parking_space_prefix_id: "",
    space_number: "",
    parking_space_suffix_id: "",
    compact: 0,
    tandem: 0,
    electric_vehicle_charging: 0,
    handicap: 0,
  };
  const initialFeatureData = null;

  const initialMeasurementData = {
    name: "",
    location: "",
    measurement_unit: "inch",
    measurement_type_id: "",
    property_location_id: "",
    measurement_unit_id: 1,
    length: "",
    width: "",
    height: "",
    depth: "",
    area: "",
    volume: "",
    amount: "",
    pictures: [],
    files: [],
    notes: "",
  };

  const initialMaterialData = {
    material_type_id: "",
    material_location_id: "",
    material_brand_id: "",
    material_identifier_id: "",
    material_vendor_id: "",
    last_installed: "",
    pictures: [
      // {
      // 	picture: null,
      // 	order_number: "",
      // },
    ],
    files: [
      // {
      // 	file: null,
      // },
    ],
    notes: "",
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };

  const initialBoardData = {
    title: "",
    icon: "",
    board: "",
  };

  // STATES

  const [lastEndLine, setLastEndLine] = useState();
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyMaterialTypes, setPropertyMaterialTypes] = useState([]);
  const [propertyMeasurementUnit, setPropertyMeasurementUnit] = useState([]);
  const [propertyMeasurementType, setPropertyMeasurementType] = useState([]);
  const [propertyMaterialLocations, setPropertyMaterialLocations] = useState(
    []
  );
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [propertyMaterialBrands, setPropertyMaterialBrands] = useState([]);
  const [propertyMaterialIdentifiers, setPropertyMateriaIdentifiers] = useState(
    []
  );
  const [parkingSpacePrefix, setParkingSpacePrefix] = useState([]);
  const [parkingSpaceSuffix, setParkingSpaceSuffix] = useState([]);
  const [files, setFiles] = useState([]);
  console.log("finalfiles", files);
  const [validation, setValidation] = useState({});
  // console.log('validations',validation);

  const [newMaterialType, setNewMaterialType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialLocation, setNewMaterialLocation] = useState({
    id: null,
    location: "",
  });
  const [newMeasuremnetType, setNewMeasuremnetType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialBrand, setNewMaterialBrand] = useState({
    id: null,
    brand: "",
  });
  const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
    id: null,
    identifier: "",
  });

  const [formData, setFormData] = useState();
  const [editorData, setEditorData] = useState();
  const [editorData1, setEditorData1] = useState();
  const [editorData2, setEditorData2] = useState();
  const [editorData3, setEditorData3] = useState();
  const [loading, setLoading] = useState();
  console.log(loading);
  console.log("This is result", formData);
  console.log("This is editor result", editorData);
  console.log("This is editor1 result", editorData1);
  console.log("This is editor2 result", editorData2);
  const [securedFields, setSecuredFields] = useState({
    propertyWifiPassword: false,
    propertyAlarmCode: false,
    propertyGateCode: false,
    propertyDoorCode: false,
  });

  const [propertyFormImagesWithUrl, setPropertyFormImagesWithUrl] = useState(
    []
  );
  const [features, setFeatures] = useState([]);
  const [addFeature, setAddFeature] = useState([]);
  const [checkFeature, setCheckFeature] = useState();

  const [nearbys, setNearbys] = useState([]);
  const [addNearby, setAddNearby] = useState([]);
  const [checkNearby, setCheckNearby] = useState();

  const [propertyContacts, setPropertyContacts] = useState([]);
  const [propertyAssistantPictures, setPropertyAssistantPictures] = useState(
    []
  );
  const [parkingSpaces, setParkingSpaces] = useState([]);
  const [parkingSpace, setParkingSpace] = useState({
    space_number: "",
    parking_space_prefix_id: "",
    parking_space_suffix_id: "",
    compact: 0,
    tandem: 0,
    electric_vehicle_charging: 0,
    handicap: 0,
  });
  const [bulkSpaces, setBulkSpaces] = useState({
    spaces_add: "",
    starting_number: "",
    prefix: "",
    suffix: "",
  });
  console.warn("parkingSpaces", parkingSpaces);
  console.warn("Parking Space", parkingSpace);
  const [floorData] = useState(() => {
    const numberArray = [];
    for (let i = 1; i <= 200; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });
  const [dueDate] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 31; i++) {
      optionsArray.push(i);
    }
    // for (let x = 1; x<=14; x++)
    // {
    //   optionsArray.push(x + " studio");
    // }
    return optionsArray;
  });
  const [propertyMeasurements, setPropertyMeasurements] = useState([]);
  const [propertyMeasurementPictures, setPropertyMeasurementPictures] =
    useState([]);
  const [propertyMeasurementFiles, setPropertyMeasurementFiles] = useState([]);

  const [propertyMaterials, setPropertyMaterials] = useState([]);
  const [propertyMaterialPictures, setPropertyMaterialPictures] = useState([]);
  const [propertyMaterialFiles, setPropertyMaterialFiles] = useState([]);

  const [propertyHoaFiles, setPropertyHoaFiles] = useState([]);
  const [propertyMortageFiles, setPropertyMortageFiles] = useState([]);
  const [propertyBuilderFiles, setPropertyBuilderFiles] = useState([]);

  const [propertyHoaContacts, setPropertyHoaContacts] = useState([]);
  const [propertyHoaInteractions, setPropertyHoaInteractions] = useState([]);
  const [propertyMortageContacts, setPropertyMortageContacts] = useState([]);
  const [propertyMortageInteractions, setPropertyMortageInteractions] = useState([]);
  const [propertyBuilderContacts, setPropertyBuilderContacts] = useState([]);
  const [propertyBuilderInteractions, setPropertyBuilderInteractions] = useState([]);

  const [propertyNotification, setPropertyNotification] = useState([]);
  const [propertyBoards, setPropertyBoards] = useState([]);
  const [propertyBoardPicture, setPropertyBoardPicture] = useState([]);

  const [
    propertyClaimResolutionDocumentsWithUrl,
    setPropertyClaimResolutionDocumentsWithUrl,
  ] = useState([]);

  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [propertyManagerDocumentsWithUrl, setPropertyManagerDocumentsWithUrl] =
    useState([]);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [propertyOwnerDocumentsWithUrl, setPropertyOwnerDocumentsWithUrl] =
    useState([]);
  const [propertySecurityPicture, setPropertySecurityPicture] = useState([]);
  // console.log("PropertySecurityPicture",propertySecurityPicture)
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [result, setResult] = useState();
  const [dateTaken, setDateTaken] = useState(new Date());
  const [handleFile,setHandleFile] = useState();
  const [templateID,setTemplateID] = useState();
  const [propertySecurityContacts, setPropertySecurityContacts] = useState([]);
  const [propertyLocations,setPropertyLocations] = useState([]);
  const [publicNotes,setPublicNotes] = useState([]);
  const [staffNotes,setStaffNotes] = useState([]);
  const [managerNotes,setManagerNotes] = useState([]);
  const [ownerNotes,setOwnerNotes] = useState([]);
  // const [propertyBoards,setPropertyBoards] = useState([]);
  const handleNoteTrue = (message)=>{
    setPublicNotes(message)
  }
  const handleStaffNoteTrue = (message)=>{
    setStaffNotes(message)
  }
  const handleManagerNoteTrue = (message)=>{
    setManagerNotes(message)
  }
  const handleOwnerNoteTrue = (message)=>{
    setOwnerNotes(message)
  }
  const handleFiles = (message)=>{
    setHandleFile(message);
  }
  const handlePicturesData = (message) => {
    setPropertyFormImagesWithUrl(message);
    let propertyCopy = [...message]; // Use the updated 'message' parameter instead of 'propertyFormImagesWithUrl'
    propertyCopy.pictures = message.map((picObj, index) => ({
      picture: picObj.picture,
      title: picObj.title,
      caption: picObj.caption,
      order_number: index + 1, // Update the order number to ensure consecutive numbers
      is_cover: picObj.is_cover,
    }));
    setFormData({ ...formData, pictures: propertyCopy?.pictures });
  };
  const getPictureTemplateID = (message)=>{
    setTemplateID(message);
  }
  const handleNotificationTrue = (message)=>{
    setPropertyNotification(message)
  }
  const handleHoaContactTrue = (message)=>{
    setPropertyHoaContacts(message)
  }
  const handleHoaInteractionTrue = (message)=>{
    setPropertyHoaInteractions(message)
  }
  const handleMortageContactTrue = (message)=>{
    setPropertyMortageContacts(message)
  }
  const handleMortageInteractionTrue = (message)=>{
    setPropertyMortageInteractions(message)
  }
  const handleBuilderContactTrue = (message)=>{
    setPropertyBuilderContacts(message)
  }
  const handleBuilderInteractionTrue = (message)=>{
    setPropertyBuilderInteractions(message)
  }
  const handleBoardTrue=(message)=>{
    setPropertyBoards(message);
  }
  const handleContactTrue=(message)=>{
    setPropertyContacts(message);
  }
  const handleSecurityContactTrue=(message)=>{
    setPropertySecurityContacts(message);
  }
  const handleLocationTrue=(message)=>{
    setPropertyLocations(message);
  }
  //  METHODS
  const DateCalculator = (date) => {
    const currentDate = new Date();
    const cDate = new Date(date);
    const diffInMilliseconds = currentDate - cDate;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInMonths / 12;
    const years = Math.floor(diffInYears);
    const months = Math.floor((diffInYears - years) * 12);
    const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
    const StringYear = years === 1 ? "year" : "years";
    const StringMonth = months === 1 ? "month" : "months";
    const StringDay = days === 1 ? "day" : "days";
    var result;
    if (days === 0 && years !== 0 && months !== 0) {
      result = years + " " + StringYear + ", " + months + " " + StringMonth;
    } else if (years === 0 && months !== 0 && days !== 0) {
      result = months + " " + StringMonth + ", " + days + " " + StringDay;
    } else if (months === 0 && years !== 0 && days !== 0) {
      result = years + " " + StringYear + ", " + days + " " + StringDay;
    } else if (days === 0 && months === 0 && years !== 0) {
      result = years + " " + StringYear;
    } else if (days === 0 && years === 0 && months !== 0) {
      result = months + " " + StringMonth;
    } else if (years === 0 && months === 0 && days !== 0) {
      result = days + " " + StringDay;
    } else if (years !== 0 || months !== 0 || days !== 0) {
      result =
        years +
        " " +
        StringYear +
        ", " +
        months +
        " " +
        StringMonth +
        ", " +
        days +
        " " +
        StringDay;
    } else {
      result = "";
    }
    return result;
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const SingleSpaceData = () => {
    parkingSpaces.push(parkingSpace);
    const parking_spaces = [...parkingSpaces];
    setFormData({ ...formData, parking_spaces: parking_spaces });
    setParkingSpace({
      space_number: "",
      parking_space_prefix_id: "",
      parking_space_suffix_id: "",
      compact: 0,
      tandem: 0,
      electric_vehicle_charging: 0,
      handicap: 0,
    });
  };
  const bulkData = () => {
    for (var i = 0; i < bulkSpaces.spaces_add; i++) {
      parkingSpaces.push(initialSpaceData);
      const parking_spaces = [...parkingSpaces];
      setFormData({ ...formData, parking_spaces: parking_spaces });
    }
  };
  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };

  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";

  const base64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { image: imgBase64 });
        console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        formData.pictures.push({ picture: res?.data?.data?.image });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.image}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const HOAbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.hoa_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Mortagebase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.mortage_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Builderbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.builder_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Publicbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_tenant_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Staff64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_staff_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Managerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_manager_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Ownerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const MeasurementPicbase64 = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { file: imgBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.measurements.pictures.push({ picture: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        // console.warn("base64converted files",formData)
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const removeByAttr = (arr, attr, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);

    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

    return TotalTime;
  };
  function stringToTime(timeString) {
    console.warn("timeString", timeString);
    if (timeString === null || timeString === undefined || timeString >= 0)
      return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1];
    let dayNight = ampm[1];

    let time = hours + ":" + minutes + " " + dayNight;
    let isPM = false;

    if (time.endsWith("am")) {
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time;
  }
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    console.warn("DateString", dateString);
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    console.warn("UnformatDate", date);
    return date;
  }
  function ShowFormatDate(data, format) {
    if (data === null || data === undefined)
      return;
    const date = new Date(stringToDate(data));
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const year = date.getFullYear();
  
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    format = format.replace('dd', formattedDay);
    format = format.replace('DD', formattedDay);
    format = format.replace('d', day);
    format = format.replace('D', day);
    format = format.replace('mm', formattedMonth);
    format = format.replace('MM', formattedMonth);
    format = format.replace('m', month);
    format = format.replace('M', month);
    format = format.replace('yyyy', year);
    format = format.replace('YYYY', year);
  
    return format;
  }
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };
  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, '') || '';
    let formattedNumber = '';
    let digitIndex = 0;
  
    for (let i = 0; i < format?.length; i++) {
      if (format[i] === '#') {
        formattedNumber += digitsOnly[digitIndex] || '';
        digitIndex++;
      } else if (format[i] === '_') {
        formattedNumber += ' ';
      } else {
        formattedNumber += format[i];
      }
    }
  
    return formattedNumber;
  };
  function removeCurrencyAndCommas(str) {
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  function convertCurrencyToFormat(currency, number) {
    let formattedCurrency = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
    }).format(number);
  
    return formattedCurrency;
  }

  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="property image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;

  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };

  const handleMeasurementTrue=(message)=>{
    setPropertyMeasurements(message);
  }
  const handleMaterialTrue=(message)=>{
    setPropertyMaterials(message);
  }
  const handleTrue=(message)=>{
    setParkingSpaces(message);
  }
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }
  const getProperty = async () => {
    const response = await ApiRequest.get(
      `public/api/auth/get-property?property_id=${id}`
    );
    setLoading(response?.status);

    if (response.status === 200) {
      var formData2 = {
        name: response?.data?.data?.name,
        address_line_1: response?.data?.data?.address_line_1,
        address_line_2: response?.data?.data?.address_line_2,
        is_active: response?.data?.data?.is_active,
        city: response?.data?.data?.city,
        state: response?.data?.data?.state,
        type_id: response?.data?.data?.type_id,
        zip_code: response?.data?.data?.zip_code,
        embed: response?.data?.data?.embed,
        picture_template_id: response?.data?.data?.picture_template_id,

        security_firstname: response?.data?.data?.security_firstname,
        security_lastname: response?.data?.data?.security_lastname,
        security_company: response?.data?.data?.security_company,
        security_number1: response?.data?.data?.security_number1,
        security_number1_type: response?.data?.data?.security_number1_type,
        security_number2: response?.data?.data?.security_number2,
        security_number2_type: response?.data?.data?.security_number2_type,
        security_number3: response?.data?.data?.security_number3,
        security_number3_type: response?.data?.data?.security_number3_type,
        security_email: response?.data?.data?.security_email,
        security_info: editorData?.security_info,
        security_picture: response?.data?.data?.security_picture,
        wifi_network: response?.data?.data?.wifi_network,
        wifi_password: response?.data?.data?.wifi_password,
        alarm_code: response?.data?.data?.alarm_code,
        gate_code: response?.data?.data?.gate_code,
        door_code: response?.data?.data?.door_code,
        mailbox_number: response?.data?.data?.mailbox_number,
        other_info: response?.data?.data?.other_info,

        no_of_floors: response?.data?.data?.no_of_floors,
        has_elevator: response?.data?.data?.has_elevator,
        has_stairs: response?.data?.data?.has_stairs,
        description: response?.data?.data?.description,
        other_property_features: response?.data?.data?.other_property_features,
        features: formData?.features,

        hoa_show_to_tenant: response?.data?.data?.hoa_show_to_tenant,
        hoa_show_to_property_manager:
          response?.data?.data?.hoa_show_to_property_manager,
        hoa_company: response?.data?.data?.hoa_company,
        hoa_contact_firstname: response?.data?.data?.hoa_contact_firstname,
        hoa_contact_lastname: response?.data?.data?.hoa_contact_lastname,
        hoa_contact_title: response?.data?.data?.hoa_contact_title,
        hoa_address_line1: response?.data?.data?.hoa_address_line1,
        hoa_address_line2: response?.data?.data?.hoa_address_line2,
        hoa_city: response?.data?.data?.hoa_city,
        hoa_state: response?.data?.data?.hoa_state,
        hoa_zip_code: response?.data?.data?.hoa_zip_code,
        hoa_number1: response?.data?.data?.hoa_number1,
        hoa_number1_type: response?.data?.data?.hoa_number1_type,
        hoa_number2: response?.data?.data?.hoa_number2,
        hoa_number2_type: response?.data?.data?.hoa_number2_type,
        hoa_number3: response?.data?.data?.hoa_number3,
        hoa_number3_type: response?.data?.data?.hoa_number3_type,
        hoa_email: response?.data?.data?.hoa_email,
        hoa_website: response?.data?.data?.hoa_website,
        hoa_fee: response?.data?.data?.hoa_fee,
        hoa_fee_show_to_property_manager:
          response?.data?.data?.hoa_fee_show_to_property_manager,
        hoa_fee_show_to_tenant: response?.data?.data?.hoa_fee_show_to_tenant,
        hoa_due_date: response?.data?.data?.hoa_due_date,
        hoa_owner_notes: response?.data?.data?.hoa_owner_notes,
        hoa_property_manager_notes:
          response?.data?.data?.hoa_property_manager_notes,
        hoa_tenant_notes: response?.data?.data?.hoa_tenant_notes,
        hoa_files: response?.data?.data?.hoa_files,

        mortage_company: response?.data?.data?.mortage_company,
        mortage_contact_firstname:
          response?.data?.data?.mortage_contact_firstname,
        mortage_contact_lastname:
          response?.data?.data?.mortage_contact_lastname,
        mortage_contact_title: response?.data?.data?.mortage_contact_title,
        mortage_address_line1: response?.data?.data?.mortage_address_line1,
        mortage_address_line2: response?.data?.data?.mortage_address_line2,
        mortage_city: response?.data?.data?.mortage_city,
        mortage_state: response?.data?.data?.mortage_state,
        mortage_zip_code: response?.data?.data?.mortage_zip_code,
        mortage_number1: response?.data?.data?.mortage_number1,
        mortage_number1_type: response?.data?.data?.mortage_number1_type,
        mortage_number2: response?.data?.data?.mortage_number2,
        mortage_number2_type: response?.data?.data?.mortage_number2_type,
        mortage_number3: response?.data?.data?.mortage_number3,
        mortage_number3_type: response?.data?.data?.mortage_number3_type,
        mortage_email: response?.data?.data?.mortage_email,
        mortage_website: response?.data?.data?.mortage_website,
        mortage_minimum_payment: response?.data?.data?.mortage_minimum_payment,
        mortage_due_date: response?.data?.data?.mortage_due_date,
        mortage_balance: response?.data?.data?.mortage_balance,
        mortage_files: response?.data?.data?.mortage_files,
        mortage_notes: editorData?.mortage_notes,

        builder_company: response?.data?.data?.builder_company,
        builder_date: response?.data?.data?.builder_date,
        builder_firstname: response?.data?.data?.builder_firstname,
        builder_lastname: response?.data?.data?.builder_lastname,
        builder_title: response?.data?.data?.builder_title,
        builder_address_line1: response?.data?.data?.builder_address_line1,
        builder_address_line2: response?.data?.data?.builder_address_line2,
        builder_city: response?.data?.data?.builder_city,
        builder_state: response?.data?.data?.builder_state,
        builder_zip_code: response?.data?.data?.builder_zip_code,
        builder_number1: response?.data?.data?.builder_number1,
        builder_number1_type: response?.data?.data?.builder_number1_type,
        builder_number2: response?.data?.data?.builder_number2,
        builder_number2_type: response?.data?.data?.builder_number2_type,
        builder_number3: response?.data?.data?.builder_number3,
        builder_number3_type: response?.data?.data?.builder_number3_type,
        builder_email: response?.data?.data?.builder_email,
        builder_website: response?.data?.data?.builder_website,
        builder_files: response?.data?.data?.builder_files,
        builder_notes: response?.data?.data?.builder_notes,

        payment_info_company: response?.data?.data?.payment_info_company,
        payment_info_firstname: response?.data?.data?.payment_info_firstname,
        payment_info_lastname: response?.data?.data?.payment_info_lastname,
        payment_info_address_line1:
          response?.data?.data?.payment_info_address_line1,
        payment_info_address_line2:
          response?.data?.data?.payment_info_address_line2,
        payment_info_city: response?.data?.data?.payment_info_city,
        payment_info_state: response?.data?.data?.payment_info_state,
        payment_info_zip_code: response?.data?.data?.payment_info_zip_code,
        payment_info_number: response?.data?.data?.payment_info_number,
        payment_info_email: response?.data?.data?.payment_info_email,
        payment_info_other_payment_information:
          response?.data?.data?.payment_info_other_payment_information,

        notifications: response?.data?.data?.notifications,
        boards: response?.data?.data?.boards,
        pictures: response?.data?.data?.pictures,
        property_files: response?.data?.data?.property_tenant_files,
        property_notes: response?.data?.data?.property_tenant_notes,
        property_staff_files: response?.data?.data?.property_staff_files,
        property_staff_notes: response?.data?.data?.property_staff_notes,
        property_manager_files: response?.data?.data?.property_manager_files,
        property_manager_notes: response?.data?.data?.property_manager_notes,
        property_owner_files: response?.data?.data?.property_owner_files,
        property_owner_notes: response?.data?.data?.property_owner_notes,
        parking_spaces: response?.data?.data?.parking_spaces,
        contacts: response?.data?.data?.contacts,
        measurements: response?.data?.data?.measurements,
        materials: response?.data?.data?.materials,
      };
      setFormData({
        ...formData,
        name: response?.data?.data?.name,
        ...formData,
        address_line_1: response?.data?.data?.address_line_1,
        ...formData,
        address_line_2: response?.data?.data?.address_line_2,
        ...formData,
        is_active: response?.data?.data?.is_active,
        ...formData,
        city: response?.data?.data?.city,
        ...formData,
        country: response?.data?.data?.country,
        ...formData,
        state: response?.data?.data?.state,
        ...formData,
        type_id: response?.data?.data?.type_id,
        ...formData,
        zip_code: response?.data?.data?.zip_code,
        ...formData,
        embed: response?.data?.data?.embed,
        ...formData,
        picture_template_id: response?.data?.data?.picture_template_id,

        ...formData,
        security_firstname: response?.data?.data?.security_firstname,
        ...formData,
        security_lastname: response?.data?.data?.security_lastname,
        ...formData,
        security_company: response?.data?.data?.security_company,
        ...formData,
        security_number1: response?.data?.data?.security_number1,
        ...formData,
        security_number1_type: response?.data?.data?.security_number1_type,
        ...formData,
        security_number2: response?.data?.data?.security_number2,
        ...formData,
        security_number2_type: response?.data?.data?.security_number2_type,
        ...formData,
        security_number3: response?.data?.data?.security_number3,
        ...formData,
        security_number3_type: response?.data?.data?.security_number3_type,
        ...formData,
        security_email: response?.data?.data?.security_email,

        ...formData,
        security_picture: response?.data?.data?.security_picture,
        ...formData,
        wifi_network: response?.data?.data?.wifi_network,
        ...formData,
        wifi_password: response?.data?.data?.wifi_password,
        ...formData,
        alarm_code: response?.data?.data?.alarm_code,
        ...formData,
        gate_code: response?.data?.data?.gate_code,
        ...formData,
        door_code: response?.data?.data?.door_code,
        ...formData,
        mailbox_number: response?.data?.data?.mailbox_number,

        ...formData,
        no_of_floors: response?.data?.data?.no_of_floors,
        ...formData,
        has_elevator: response?.data?.data?.has_elevator,
        ...formData,
        has_stairs: response?.data?.data?.has_stairs,

        ...formData,
        features: response?.data?.data?.features,
        ...formData,
        nearbys: response?.data?.data?.nearbys,

        ...formData,
        hoa_show_to_tenant: response?.data?.data?.hoa_show_to_tenant,
        ...formData,
        hoa_show_to_property_manager:
          response?.data?.data?.hoa_show_to_property_manager,
        ...formData,
        hoa_company: response?.data?.data?.hoa_company,
        ...formData,
        hoa_contact_firstname: response?.data?.data?.hoa_contact_firstname,
        ...formData,
        hoa_contact_lastname: response?.data?.data?.hoa_contact_lastname,
        ...formData,
        hoa_contact_title: response?.data?.data?.hoa_contact_title,
        ...formData,
        hoa_address_line1: response?.data?.data?.hoa_address_line1,
        ...formData,
        hoa_address_line2: response?.data?.data?.hoa_address_line2,
        ...formData,
        hoa_city: response?.data?.data?.hoa_city,
        ...formData,
        hoa_country: response?.data?.data?.hoa_country,
        ...formData,
        hoa_state: response?.data?.data?.hoa_state,
        ...formData,
        hoa_zip_code: response?.data?.data?.hoa_zip_code,
        ...formData,
        hoa_number1: response?.data?.data?.hoa_number1,
        ...formData,
        hoa_number1_type: response?.data?.data?.hoa_number1_type,
        ...formData,
        hoa_number2: response?.data?.data?.hoa_number2,
        ...formData,
        hoa_number2_type: response?.data?.data?.hoa_number2_type,
        ...formData,
        hoa_number3: response?.data?.data?.hoa_number3,
        ...formData,
        hoa_number3_type: response?.data?.data?.hoa_number3_type,
        ...formData,
        hoa_email: response?.data?.data?.hoa_email,
        ...formData,
        hoa_website: response?.data?.data?.hoa_website,
        ...formData,
        hoa_fee: response?.data?.data?.hoa_fee,
        ...formData,
        hoa_fee_show_to_property_manager:
          response?.data?.data?.hoa_fee_show_to_property_manager,
        ...formData,
        hoa_fee_show_to_tenant: response?.data?.data?.hoa_fee_show_to_tenant,
        ...formData,
        hoa_due_date: response?.data?.data?.hoa_due_date,

        ...formData,
        hoa_files: response?.data?.data?.hoa_files,

        ...formData,
        mortage_company: response?.data?.data?.mortage_company,
        ...formData,
        mortage_contact_firstname:
          response?.data?.data?.mortage_contact_firstname,
        ...formData,
        mortage_contact_lastname:
          response?.data?.data?.mortage_contact_lastname,
        ...formData,
        mortage_contact_title: response?.data?.data?.mortage_contact_title,
        ...formData,
        mortage_address_line1: response?.data?.data?.mortage_address_line1,
        ...formData,
        mortage_address_line2: response?.data?.data?.mortage_address_line2,
        ...formData,
        mortage_city: response?.data?.data?.mortage_city,
        ...formData,
        mortage_country: response?.data?.data?.mortage_country,
        ...formData,
        mortage_state: response?.data?.data?.mortage_state,
        ...formData,
        mortage_zip_code: response?.data?.data?.mortage_zip_code,
        ...formData,
        mortage_number1: response?.data?.data?.mortage_number1,
        ...formData,
        mortage_number1_type: response?.data?.data?.mortage_number1_type,
        ...formData,
        mortage_number2: response?.data?.data?.mortage_number2,
        ...formData,
        mortage_number2_type: response?.data?.data?.mortage_number2_type,
        ...formData,
        mortage_number3: response?.data?.data?.mortage_number3,
        ...formData,
        mortage_number3_type: response?.data?.data?.mortage_number3_type,
        ...formData,
        mortage_email: response?.data?.data?.mortage_email,
        ...formData,
        mortage_website: response?.data?.data?.mortage_website,
        ...formData,
        mortage_minimum_payment: response?.data?.data?.mortage_minimum_payment,
        ...formData,
        mortage_due_date: response?.data?.data?.mortage_due_date,
        ...formData,
        mortage_balance: response?.data?.data?.mortage_balance,
        ...formData,
        mortage_files: response?.data?.data?.mortage_files,

        ...formData,
        builder_company: response?.data?.data?.builder_company,
        ...formData,
        builder_date: response?.data?.data?.builder_date,
        ...formData,
        builder_firstname: response?.data?.data?.builder_firstname,
        ...formData,
        builder_lastname: response?.data?.data?.builder_lastname,
        ...formData,
        builder_title: response?.data?.data?.builder_title,
        ...formData,
        builder_address_line1: response?.data?.data?.builder_address_line1,
        ...formData,
        builder_address_line2: response?.data?.data?.builder_address_line2,
        ...formData,
        builder_city: response?.data?.data?.builder_city,
        ...formData,
        builder_country: response?.data?.data?.builder_country,
        ...formData,
        builder_state: response?.data?.data?.builder_state,
        ...formData,
        builder_zip_code: response?.data?.data?.builder_zip_code,
        ...formData,
        builder_number1: response?.data?.data?.builder_number1,
        ...formData,
        builder_number1_type: response?.data?.data?.builder_number1_type,
        ...formData,
        builder_number2: response?.data?.data?.builder_number2,
        ...formData,
        builder_number2_type: response?.data?.data?.builder_number2_type,
        ...formData,
        builder_number3: response?.data?.data?.builder_number3,
        ...formData,
        builder_number3_type: response?.data?.data?.builder_number3_type,
        ...formData,
        builder_email: response?.data?.data?.builder_email,
        ...formData,
        builder_website: response?.data?.data?.builder_website,
        ...formData,
        builder_files: response?.data?.data?.builder_files,

        ...formData,
        payment_info_company: response?.data?.data?.payment_info_company,
        ...formData,
        payment_info_firstname: response?.data?.data?.payment_info_firstname,
        ...formData,
        payment_info_lastname: response?.data?.data?.payment_info_lastname,
        ...formData,
        payment_info_address_line1:
          response?.data?.data?.payment_info_address_line1,
        ...formData,
        payment_info_address_line2:
          response?.data?.data?.payment_info_address_line2,
        ...formData,
        payment_info_city: response?.data?.data?.payment_info_city,
        ...formData,
        payment_info_country: response?.data?.data?.payment_info_country,
        ...formData,
        payment_info_state: response?.data?.data?.payment_info_state,
        ...formData,
        payment_info_zip_code: response?.data?.data?.payment_info_zip_code,
        ...formData,
        payment_info_number: response?.data?.data?.payment_info_number,
        ...formData,
        payment_info_email: response?.data?.data?.payment_info_email,

        ...formData,
        notifications: response?.data?.data?.notifications,
        ...formData,
        boards: response?.data?.data?.boards,
        ...formData,
        pictures: response?.data?.data?.pictures,
        ...formData,
        property_files: response?.data?.data?.property_tenant_files,

        ...formData,
        property_staff_files: response?.data?.data?.property_staff_files,

        ...formData,
        property_manager_files: response?.data?.data?.property_manager_files,

        ...formData,
        property_owner_files: response?.data?.data?.property_owner_files,

        ...formData,
        parking_spaces: response?.data?.data?.parking_spaces,
        ...formData,
        contacts: response?.data?.data?.contacts,
        ...formData,
        measurements: response?.data?.data?.measurements,
        ...formData,
        materials: response?.data?.data?.materials,
      });
      setPropertyContacts(response?.data?.data?.contacts);
      setPropertyNotification(response?.data?.data?.notifications);
      setPropertyBoards(response?.data?.data?.boards);
      setPropertyMeasurements(response?.data?.data?.measurements);
      setPropertyMaterials(response?.data?.data?.materials);
      setParkingSpaces(response?.data?.data?.parking_spaces);
      setPropertyPublicDocumentsWithUrl(response?.data?.data?.files)
      setPropertyStaffDocumentsWithUrl(response?.data?.data?.staff_files)
      setPropertyManagerDocumentsWithUrl(response?.data?.data?.managers_files)
      setPropertyOwnerDocumentsWithUrl(response?.data?.data?.owners_files)
      setPropertyHoaFiles(response?.data?.data?.hoa_files)
      setPropertyMortageFiles(response?.data?.data?.mortage_files)
      setPropertyBuilderFiles(response?.data?.data?.builder_files)
      setEditorData({
        ...editorData,
        property_tenant_notes: response?.data?.data?.property_notes,
        ...editorData,
        payment_info_other_payment_information:
          response?.data?.data?.payment_info_other_payment_information,
        ...editorData,
        builder_notes: response?.data?.data?.builder_notes,
        ...editorData,
        mortage_notes: response?.data?.data?.mortage_notes,
        ...editorData,
        hoa_owner_notes: response?.data?.data?.hoa_owner_notes,
        ...editorData,
        description: response?.data?.data?.description,
        ...editorData,
        other_info: response?.data?.data?.other_info,
        ...editorData,
        security_info: response?.data?.data?.security_info,
      });
      setEditorData1({
        ...editorData1,
        hoa_property_manager_notes:
          response?.data?.data?.hoa_property_manager_notes,
        ...editorData1,
        other_property_features: response?.data?.data?.other_property_features,
        ...editorData1,
        property_manager_notes: response?.data?.data?.property_manager_notes,
      });
      setEditorData2({
        ...editorData2,
        hoa_tenant_notes: response?.data?.data?.hoa_tenant_notes,
        ...editorData2,
        property_staff_notes: response?.data?.data?.property_staff_notes,
      });
      setEditorData3({
        ...editorData3,
        property_owner_notes: response?.data?.data?.property_owner_notes,
      });
      setCheckFeature(response?.data?.data?.features);
      setHandleFile(response?.data?.data?.files);
      setTemplateID(response?.data?.data?.file_template_id);
      setPropertyFormImagesWithUrl(response?.data?.data?.pictures)
      setPropertySecurityContacts(response?.data?.data?.securities_ontacts)
      setParkingSpaces(response?.data?.data?.parking_spaces);
      setPropertyLocations(response?.data?.data?.property_locations)
      setCheckNearby(response?.data?.data?.nearbys);
      setPropertyHoaContacts(response?.data?.data?.hoa_contacts);
      setPropertyHoaInteractions(response?.data?.data?.hoa_interactions);
      setPropertyMortageContacts(response?.data?.data?.mortage_contacts);
      setPropertyMortageInteractions(response?.data?.data?.mortage_interactions);
      setPropertyBuilderContacts(response?.data?.data?.builder_contacts);
      setPropertyBuilderInteractions(response?.data?.data?.builder_interactions);
      // setPropertyMeasurementFiles(response?.data?.data?.measurements?.files)
      getCities(response?.data?.data?.city)
      getStates(response?.data?.data?.state)
      getHoaCities(response?.data?.data?.hoa_city)
      getHoaStates(response?.data?.data?.hoa_state)
      getBuilderCities(response?.data?.data?.builder_city)
      getBuilderStates(response?.data?.data?.builder_state)
      getMortageCities(response?.data?.data?.mortage_city)
      getMortageStates(response?.data?.data?.mortage_state)
      getPaymentCities(response?.data?.data?.payment_info_city)
      getPaymentStates(response?.data?.data?.payment_info_state)
      setPublicNotes(response?.data?.data?.property_notes)
      setStaffNotes(response?.data?.data?.property_staff_notes)
      setManagerNotes(response?.data?.data?.property_managers_notes)
      setOwnerNotes(response?.data?.data?.property_owners_notes)
    }

    console.warn("This  is response final", formData2);
  };

  const { id } = useParams();
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log("formData 360", formData);
      const res = await ApiRequest.post("/public/api/auth/property", {
        id: id,
        name: formData?.name,
        address_line_1: formData?.address_line_1,
        address_line_2: formData?.address_line_2,
        is_active: formData?.is_active,
        city: formData?.city,
        state: formData?.state,
        type_id: formData?.type_id,
        zip_code: formData?.zip_code,
        embed: formData?.embed,
        picture_template_id: formData?.picture_template_id,

        security_firstname: formData?.security_firstname,
        security_lastname: formData?.security_lastname,
        security_company: formData?.security_company,
        security_number1: formData?.security_number1,
        security_number1_type: formData?.security_number1_type,
        security_number2: formData?.security_number2,
        security_number2_type: formData?.security_number2_type,
        security_number3: formData?.security_number3,
        security_number3_type: formData?.security_number3_type,
        security_email: formData?.security_email,
        security_info: editorData?.security_info,
        security_picture: formData?.security_picture,
        wifi_network: formData?.wifi_network,
        wifi_password: formData?.wifi_password,
        alarm_code: formData?.alarm_code,
        gate_code: formData?.gate_code,
        door_code: formData?.door_code,
        mailbox_number: formData?.mailbox_number,
        other_info: editorData?.other_info,

        no_of_floors: formData?.no_of_floors,
        has_elevator: formData?.has_elevator,
        has_stairs: formData?.has_stairs,
        description: editorData?.description,
        other_property_features: editorData1?.other_property_features,
        features: formData?.features,

        hoa_show_to_tenant: formData?.hoa_show_to_tenant,
        hoa_show_to_property_manager: formData?.hoa_show_to_property_manager,
        hoa_company: formData?.hoa_company,
        hoa_contact_firstname: formData?.hoa_contact_firstname,
        hoa_contact_lastname: formData?.hoa_contact_lastname,
        hoa_contact_title: formData?.hoa_contact_title,
        hoa_address_line1: formData?.hoa_address_line1,
        hoa_address_line2: formData?.hoa_address_line2,
        hoa_city: formData?.hoa_city,
        hoa_state: formData?.hoa_state,
        hoa_zip_code: formData?.hoa_zip_code,
        hoa_number1: formData?.hoa_number1,
        hoa_number1_type: formData?.hoa_number1_type,
        hoa_number2: formData?.hoa_number2,
        hoa_number2_type: formData?.hoa_number2_type,
        hoa_number3: formData?.hoa_number3,
        hoa_number3_type: formData?.hoa_number3_type,
        hoa_email: formData?.hoa_email,
        hoa_website: formData?.hoa_website,
        hoa_fee: formData?.hoa_fee,
        hoa_fee_show_to_property_manager:
          formData?.hoa_fee_show_to_property_manager,
        hoa_fee_show_to_tenant: formData?.hoa_fee_show_to_tenant,
        hoa_due_date: formData?.hoa_due_date,
        hoa_owner_notes: editorData?.hoa_owner_notes,
        hoa_property_manager_notes: editorData1?.hoa_property_manager_notes,
        hoa_tenant_notes: editorData2?.hoa_tenant_notes,
        hoa_files: formData?.hoa_files,

        mortage_company: formData?.mortage_company,
        mortage_contact_firstname: formData?.mortage_contact_firstname,
        mortage_contact_lastname: formData?.mortage_contact_lastname,
        mortage_contact_title: formData?.mortage_contact_title,
        mortage_address_line1: formData?.mortage_address_line1,
        mortage_address_line2: formData?.mortage_address_line2,
        mortage_city: formData?.mortage_city,
        mortage_state: formData?.mortage_state,
        mortage_zip_code: formData?.mortage_zip_code,
        mortage_number1: formData?.mortage_number1,
        mortage_number1_type: formData?.mortage_number1_type,
        mortage_number2: formData?.mortage_number2,
        mortage_number2_type: formData?.mortage_number2_type,
        mortage_number3: formData?.mortage_number3,
        mortage_number3_type: formData?.mortage_number3_type,
        mortage_email: formData?.mortage_email,
        mortage_website: formData?.mortage_website,
        mortage_minimum_payment: formData?.mortage_minimum_payment,
        mortage_due_date: formData?.mortage_due_date,
        mortage_balance: formData?.mortage_balance,
        mortage_files: formData?.mortage_files,
        mortage_notes: editorData?.mortage_notes,

        builder_company: formData?.builder_company,
        builder_date: formData?.builder_date,
        builder_firstname: formData?.builder_firstname,
        builder_lastname: formData?.builder_lastname,
        builder_title: formData?.builder_title,
        builder_address_line1: formData?.builder_address_line1,
        builder_address_line2: formData?.builder_address_line2,
        builder_city: formData?.builder_city,
        builder_state: formData?.builder_state,
        builder_zip_code: formData?.builder_zip_code,
        builder_number1: formData?.builder_number1,
        builder_number1_type: formData?.builder_number1_type,
        builder_number2: formData?.builder_number2,
        builder_number2_type: formData?.builder_number2_type,
        builder_number3: formData?.builder_number3,
        builder_number3_type: formData?.builder_number3_type,
        builder_email: formData?.builder_email,
        builder_website: formData?.builder_website,
        builder_files: formData?.builder_files,
        builder_notes: editorData?.builder_notes,

        payment_info_company: formData?.payment_info_company,
        payment_info_firstname: formData?.payment_info_firstname,
        payment_info_lastname: formData?.payment_info_lastname,
        payment_info_address_line1: formData?.payment_info_address_line1,
        payment_info_address_line2: formData?.payment_info_address_line2,
        payment_info_city: formData?.payment_info_city,
        payment_info_state: formData?.payment_info_state,
        payment_info_zip_code: formData?.payment_info_zip_code,
        payment_info_number: formData?.payment_info_number,
        payment_info_email: formData?.payment_info_email,
        payment_info_other_payment_information:
          editorData?.payment_info_other_payment_information,

        notifications: formData?.notifications,
        boards: formData?.boards,
        pictures: formData?.pictures,
        property_files: formData?.property_tenant_files,
        property_notes: editorData?.property_tenant_notes,
        property_staff_files: formData?.property_staff_files,
        property_staff_notes: editorData2?.property_staff_notes,
        property_manager_files: formData?.property_manager_files,
        property_manager_notes: editorData1?.property_manager_notes,
        property_owner_files: formData?.property_owner_files,
        property_owner_notes: editorData3?.property_owner_notes,
        parking_spaces: formData?.parking_spaces,
        contacts: formData?.contacts,
        measurements: formData?.measurements,
        materials: formData?.materials,
      });
      console.log("Result formData", formData);
      if (res.status == 200) {
        console.log("Added");
      }
    } catch (err) {
      validationData = {
        errName: err.response.data.name[0],
        // errType : err.response.data.type_id[0],
        // errActive : err.response.data.is_active[0],
        errAddress: err.response.data.address_line_1[0],
        errCity: err.response.data.city[0],
        errState: err.response.data.state[0],
        errZipcode: err.response.data.zip_code[0],
      };
      setValidation({
        name: validationData.errName,
        address_line_1: validationData.errAddress,
        city: validationData.errCity,
        state: validationData.errState,
        zip_code: validationData.errZipcode,
      });
      // setValidation(validationData.errName)
      console.log(validationData.errName);
      // console.log(err.response.data.name[0]);
      //   console.log('Wrong');
    }
  };
  // console.log('Result formData',formData);
  // APIs

  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state?.properties?.propertiesTypes?.data
  );
  // const getPropertyTypes = async () => {
  // 	const response = await ApiRequest.get(
  // 		"public/api/auth/get-all-properties-types"
  // 	);
  // 	if (response.status == 200) {
  // 		setPropertyTypes(response.data?.data);
  // 	}
  // };
  const materialDispatch = useDispatch();
  const materialState = useSelector((state) => state);
  // console.log("Materialstate",materialState)
  const getPropertyMaterialTypes = async () => {
    const response = await ApiRequest.get("public/api/auth/get-material-types");
    if (response.status == 200) {
      setPropertyMaterialTypes(response.data?.data);
    }
  };
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
 
  const [hoaStates, setHoaStates] = useState([]);
  const [hoaCities, setHoaCities] = useState([]);
  console.log('HOASTATES',hoaStates)
  console.log('hoaCities',hoaCities)

  const [builderStates, setBuilderStates] = useState([]);
  const [builderCities, setBuilderCities] = useState([]);

  const [mortageStates, setMortageStates] = useState([]);
  const [mortageCities, setMortageCities] = useState([]);

  const [paymentStates, setPaymentStates] = useState([]);
  const [paymentCities, setPaymentCities] = useState([]);

  const getCountries = async () => {
    const response = await ApiRequest.get("public/api/auth/get-countries");
    if (response.status == 200) {
      setCountries(response.data?.data?.countries);
    }
  };
  const getStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-state?id=${id}`);
    if (response.status == 200) {
      setStates(response.data?.data?.state);
    }
  };
  const getCities = async (id) => {
    
    const response = await ApiRequest.get(`public/api/auth/get-city?id=${id}`);
    
    if (response.status == 200) {
      setCities(response.data?.data?.city);
    }
  };
  const getHoaStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-state?id=${id}`);
    if (response.status == 200) {
      setHoaStates(response.data?.data?.state);
    }
  };
  const getHoaCities = async (id) => {
    
    const response = await ApiRequest.get(`public/api/auth/get-city?id=${id}`);
    
    if (response.status == 200) {
      setHoaCities(response.data?.data?.city);
    }
  };
  const getBuilderStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-state?id=${id}`);
    if (response.status == 200) {
      setBuilderStates(response.data?.data?.state);
    }
  };
  const getBuilderCities = async (id) => {
    
    const response = await ApiRequest.get(`public/api/auth/get-city?id=${id}`);
    
    if (response.status == 200) {
      setBuilderCities(response.data?.data?.city);
    }
  };
  const getMortageStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-state?id=${id}`);
    if (response.status == 200) {
      setMortageStates(response.data?.data?.state);
    }
  };
  const getMortageCities = async (id) => {
    
    const response = await ApiRequest.get(`public/api/auth/get-city?id=${id}`);
    
    if (response.status == 200) {
      setMortageCities(response.data?.data?.city);
    }
  };
  const getPaymentStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-state?id=${id}`);
    if (response.status == 200) {
      setPaymentStates(response.data?.data?.state);
    }
  };
  const getPaymentCities = async (id) => {
    
    const response = await ApiRequest.get(`public/api/auth/get-city?id=${id}`);
    
    if (response.status == 200) {
      setPaymentCities(response.data?.data?.city);
    }
  };
  const [numberType, setNumberType] = useState([]);
  const getNumberType = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-number-types')
    if(response.status == 200)
    {
      setNumberType(response?.data?.data?.number_types);
    }
  }
  const getFeatures = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-features-for-properties");
    if (response.status == 200) {
      setFeatures(response?.data?.data?.features);
    }
  };
  const getNearbys = async () => {
    const response = await ApiRequest.get("public/api/auth/get-nearbys");
    if (response.status == 200) {
      setNearbys(response?.data?.data?.nearbys);
    }
  };
  const getPropertyMeasurementUnits = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurements-units"
    );
    if (response.status == 200) {
      setPropertyMeasurementUnit(response.data?.data?.measurements_units);
    }
  };
  const getMeasurementTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurement-types"
    );
    if (response.status == 200) {
      setPropertyMeasurementType(response?.data?.data);
    }
  };
  const addEditMeasurementType = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/measurement-type",
      payload
    );
    if (response.status == 200) {
      getMeasurementTypes();
    }
  };

  const getPropertyMaterialLocations = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-properties-locations"
    );
    if (response.status == 200) {
      setPropertyMaterialLocations(response?.data?.data);
    }
  };

  const getPropertyMaterialBrands = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-brands"
    );
    if (response.status == 200) {
      setPropertyMaterialBrands(response.data?.data);
    }
  };

  const getPropertyMateriaIdentifiers = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-identifiers"
    );
    if (response.status == 200) {
      setPropertyMateriaIdentifiers(response.data?.data);
    }
  };

  const addEditMaterialType = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-type",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialTypes();
    }
  };

  const addEditMaterialLocation = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/properties-location",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialLocations();
    }
  };

  const addEditMaterialBrand = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-brand",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialBrands();
    }
  };

  const addEditMaterialIdentifier = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-identifier",
      payload
    );
    if (response.status == 200) {
      getPropertyMateriaIdentifiers();
    }
  };
  const getParkingSpacePrefixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-prefixes"
    );
    if (response.status == 200) {
      setParkingSpacePrefix(response?.data?.data?.prefixes);
    }
  };
  const getParkingSpaceSuffixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-suffixes"
    );
    if (response.status == 200) {
      setParkingSpaceSuffix(response?.data?.data?.suffixes);
    }
  };

  //  useEffect HOOKS

  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const propertyPictures = [];
      propertyFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        propertyPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });

      // const contacts = [
      //   ...propertyContacts
      //     .map((contact, index) => {
      //       const pictures = [...propertyAssistantPictures];
      //       contact.picture = pictures[index]?.image?.picture;
      //       return contact;
      //     })
      //     .filter(
      //       (contact) =>
      //         JSON.stringify(contact) !== JSON.stringify(initialContactData)
      //     ),
      // ];

      // const measurements = [
      //   ...propertyMeasurements
      //     .map((measurement, index) => {
      //       const pictures = [...propertyMeasurementPictures];
      //       measurement.pictures = pictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...propertyMeasurementFiles];
      //       measurement.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return measurement;
      //     })
      //     .filter(
      //       (measurement) =>
      //         JSON.stringify(measurement) !==
      //         JSON.stringify(initialMeasurementData)
      //     ),
      // ];
      // console.log("Measurement Data", measurements);

      // const materials = [
      //   ...propertyMaterials
      //     .map((material, index) => {
      //       const pictures = [...propertyMaterialPictures];
      //       material.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...propertyMaterialFiles];
      //       material.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return material;
      //     })
      //     .filter(
      //       (material) =>
      //         JSON.stringify(material) !== JSON.stringify(initialMaterialData)
      //     ),
      // ];

      // const notifications = [
      //   ...propertyNotification.filter(
      //     (notifications) =>
      //       JSON.stringify(notifications) !== JSON.stringify(initialNoticeData)
      //   ),
      // ];

      // const boards = [
      //   ...propertyBoards
      //     .map((board, index) => {
      //       const pictures = [...propertyBoardPicture];
      //       board.icon = pictures[index]?.image?.picture;
      //     })
      //     .filter(
      //       (board) =>
      //         JSON.stringify(board) !== JSON.stringify(initialBoardData)
      //     ),
      // ];

      // const parking_spaces = [
      // 	...parkingSpace.filter(
      // 		(parking_space)=> JSON.stringify(parking_space)!== JSON.stringify(initialSpaceData)
      // 		),
      // ];
      // const addFeatures = [
      // 	...addFeature.filter(
      // 		(feature)=> JSON.stringify(feature)!== JSON.stringify(initialFeatureData)
      // 		),
      // ];

      data.pictures = [...propertyPictures];

      // if (JSON.stringify(initialData?.contacts) !== JSON.stringify(contacts)) {
      //   data.contacts = contacts;
      // }

      // if (
      //   JSON.stringify(initialData?.measurements) !==
      //   JSON.stringify(measurements)
      // ) {
      //   data.measurements = measurements;
      // }

      // if (
      //   JSON.stringify(initialData?.materials) !== JSON.stringify(materials)
      // ) {
      //   data.materials = materials;
      // }

      data.hoa_files = [
        ...propertyHoaFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.mortage_files = [
        ...propertyMortageFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.builder_files = [
        ...propertyBuilderFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];

      // if (
      //   JSON.stringify(initialData?.notifications) !==
      //   JSON.stringify(notifications)
      // ) {
      //   data.notifications = notifications;
      // }

      // if (JSON.stringify(initialData?.boards) !== JSON.stringify(boards)) {
      //   data.boards = boards;
      // }
      // if (JSON.stringify(initialData?.parking_spaces) !== JSON.stringify(parking_spaces)) {
      // 	data.parking_spaces = parking_spaces;
      // }
      // if (JSON.stringify(initialData?.addFeatures) !== JSON.stringify(addFeatures)) {
      // 	data.addFeatures = addFeatures;
      // }

      data.property_tenant_files = [
        ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.property_staff_files = [
        ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.property_manager_files = [
        ...propertyManagerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.property_owner_files = [
        ...propertyOwnerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);

  useEffect(() => {
    if (formElement) {
      formSubmitButton.click();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    // getPropertyTypes();
    getCountries();
    getOwnerSettings();
    // getStates();
    // getCities();
    // getHoaCities();
    // getHoaStates();
    // getBuilderCities();
    // getBuilderStates();
    // getMortageCities();
    // getMortageStates();
    // getPaymentCities();
    // getPaymentStates();
    getPropertyMaterialTypes();
    getPropertyMaterialLocations();
    getPropertyMaterialBrands();
    getMeasurementTypes();
    getFeatures();
    getNumberType();
    getNearbys();
    getParkingSpacePrefixes();
    getParkingSpaceSuffixes();
    getPropertyMateriaIdentifiers();
    getPropertyMeasurementUnits();
    dispatch(propertiesTypes());
    materialDispatch(materialType());
    getProperty();
    setFormData(initialData);
    setPropertyFormImagesWithUrl(
      initialData?.pictures?.map((obj) => ({
        image: {
          picture: obj?.picture,
          title: "",
          caption: "",
          is_cover: "",
        },
        url: obj?.picture,
      }))
    );

    // if (initialData?.contacts?.length) {
    //   setPropertyContacts(initialData.contacts);
    //   setPropertyAssistantPictures(
    //     initialData.contacts.map((contact) => contact?.picture)
    //   );
    // } else {
    //   setPropertyContacts([{ ...initialContactData }]);
    //   setPropertyAssistantPictures([{ ...initialImageData }]);
    // }

    // setPropertySecurityPicture([{ ...initialPictureData }]);

    // if (initialData?.measurements?.length) {
    //   setPropertyMeasurements(initialData.measurements);
    //   setPropertyMeasurementPictures(
    //     initialData.measurements.map((measurement) =>
    //       measurement?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setPropertyMeasurementFiles(
    //     initialData.measurements.map((measurement) => measurement?.files)
    //   );
    // } else {
    //   setPropertyMeasurements([{ ...initialMeasurementData }]);
    //   setPropertyMeasurementPictures([[]]);
    //   setPropertyMeasurementFiles([[]]);
    // }

    // if (initialData?.materials?.length) {
    //   setPropertyMaterials(initialData?.materials);
    //   setPropertyMaterialPictures(
    //     initialData.materials.map((material) =>
    //       material?.pictures?.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setPropertyMaterialFiles(
    //     initialData.materials.map((material) => material?.files)
    //   );
    // } else {
    //   setPropertyMaterials([{ ...initialMaterialData }]);
    //   setPropertyMaterialPictures([[]]);
    //   setPropertyMaterialFiles([[]]);
    // }

    setPropertyHoaFiles(
      initialData?.hoa_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyMortageFiles(
      initialData?.mortage_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyBuilderFiles(
      initialData?.builder_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );

    // setPropertyNotification(
    //   initialData?.notifications?.length
    //     ? initialData?.notifications
    //     : [{ ...initialNoticeData }]
    // );

    // if (initialData?.boards?.length) {
    //   setPropertyBoardPicture(initialData.boards.map((board) => board?.icon));
    //   setPropertyBoards(initialData?.boards);
    // } else {
    //   setPropertyBoards([{ ...initialBoardData }]);
    //   setPropertyBoardPicture([]);
    // }
    // setParkingSpace(
    // 	initialData?.parking_spaces?.length
    // 		? initialData?.parking_spaces
    // 		: [{ ...initialSpaceData }]
    // );
    // setAddFeature(
    // 	initialData?.addFeatures?.length
    // 		? initialData?.addFeatures
    // 		: [{ ...initialFeatureData }]
    // );

    setPropertyPublicDocumentsWithUrl(
      initialData?.property_tenant_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyStaffDocumentsWithUrl(
      initialData?.property_staff_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyManagerDocumentsWithUrl(
      initialData?.property_manager_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyOwnerDocumentsWithUrl(
      initialData?.property_owner_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
  }, []);

  useEffect(() => {
    if (formElement) {
      formElement.addEventListener("keypress", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });

      return () => formElement.removeEventListener("keypress", () => {});
    }
  }, [formElement]);

  return (
    <>
      {/* Add Property Form  */}
      {loading === undefined ? (
        <Loading />
      ) : (
        <>
         
        <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/property.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                View {formData?.name}
              </h2>
            </div>
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={formSubmitHandler}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Property Information" {...a11yProps(0)} />
            <Tab label="Notifications" {...a11yProps(1)} />
            <Tab label="Property Boards" {...a11yProps(2)} />
            <Tab label="Notes & Files" {...a11yProps(3)} />
            {/* <Tab label="Property Managers" {...a11yProps(1)} /> */}
            <Tab label="Property Assistance Information" {...a11yProps(4)} />
            {/* <Tab label="Property Security Information" {...a11yProps(5)} /> */}
            <Tab label="Property Measurements" {...a11yProps(5)} />
            <Tab label="Property Materials" {...a11yProps(6)} />
            <Tab label="Parking Spaces" {...a11yProps(7)} />
            <Tab label="Listing Information" {...a11yProps(8)} />
            <Tab label="HOA Information" {...a11yProps(9)} />
            <Tab label="Mortage Information" {...a11yProps(10)} />
            <Tab label="Builder Information" {...a11yProps(11)} />
            {/* <Tab label="Insurance Claims" {...a11yProps(10)} /> */}
            <Tab
              label="Payment Information"
              {...a11yProps(12)}
            />
            <Tab label="Staff" {...a11yProps(13)} />

            {/* <Tab label="Staff Files" {...a11yProps(15)} />
					<Tab label="Property Manager Files" {...a11yProps(16)} />
					<Tab label="Owner Files" {...a11yProps(1)} /> */}
          </Tabs>
          <TabPanel value={tabValue} index={0}>
              <div className="flex justify-content-end">
                <span className="status-active">Active</span>
              </div>
            <div className="md:flex md:items-center w-[90%] m-auto">
              <div className="w-1/3">
                <CarouselWithLightbox data={propertyFormImagesWithUrl}/>
              </div>
              <div className="w-1/3">
                <div className="flex flex-column">
                {formData.name != '' ?<span className="mb-1">{formData?.name}</span>:null}
              
              {formData.type_id != '' ?
              
                  <span className="mb-1">{state?.map((property_Type) =>{
                    if(formData?.type_id == property_Type?.id)
                    {
                      return property_Type?.type
                    }
                  })}</span>
                :null}
              {formData.address_line_1 != '' ?<span className="mb-1">{formData?.address_line_1}</span>:null}
              {formData.address_line_2 != '' ?
                  <span className="mb-1">{formData?.address_line_2}</span>
                :null}
         
                
                <div className="flex">
                  {formData.city != '' ?
                    <span className="mb-1">{cities?.id == formData?.city ? cities?.city : ''},&nbsp;</span>
                  :null}
                {formData.state != '' ?<span className="mb-1">{states?.id == formData?.state ? states?.state : ''}&nbsp;</span>:null}
                {formData.zip_code != '' ?
                    <span className="mb-1">{formData?.zip_code}</span>
                 :null}
                </div>
                 {formData.country != '' ?
                    <span className="mb-1">{countries?.map((country)=>{
                      if(formData?.country == country?.id){
                        return country?.country
                      }
                    })}</span>:null}
                </div>
              </div>
              <div className="w-1/3">
                <div className="flex items-center justify-content-end">
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold ml-1">
                    Built:
                  </label>
                  <span>{formData?.builder_date}</span>
                </div>
              </div>
            </div>

            <hr />

            {/* Property Gadgets */}

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                {formData.wifi_network != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                  <div className="md:w-1/12">
                    <img src="/assets/svgs/system-wifi.svg" className="w-1/2" alt="Wifi Network" title="Wifi Network" />
                  </div>
                  <div className="md:w-4/5">
                    <span>{formData?.wifi_network}</span>
                  </div>
                </div>:null}
                {formData.wifi_password != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-wifi-password.svg" className="w-1/2" alt="Wifi Password" title="Wifi Password" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyWifiPassword == true ?<span>{formData?.wifi_password}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyWifiPassword:
                            securedFields.propertyWifiPassword === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyWifiPassword === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.alarm_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-alarm-code.svg" className="w-1/2" alt="Alarm Code" title="Alarm Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyAlarmCode == true ?<span>{formData?.alarm_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyAlarmCode:
                            securedFields.propertyAlarmCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyAlarmCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.gate_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-gate-code.svg" className="w-1/2" alt="Gate Code" title="Gate Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyGateCode == true ?<span>{formData?.gate_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyGateCode:
                            securedFields.propertyGateCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyGateCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.door_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-door-code.svg" className="w-1/2" alt="Door Code" title="Door Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyDoorCode == true ?<span>{formData?.door_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0 "
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyDoorCode:
                            securedFields.propertyDoorCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyDoorCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.mailbox_number != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-mailbox.svg" className="w-1/2" alt="Mailbox Info" title="Mailbox Info" />
                  </div>
                  <div className="md:w-4/5">
                  
                    <span>{formData?.mailbox_number}</span>
                  </div>
                </div>:null}
                {editorData?.other_info != '' ?<div className="md:flex mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/property-information.svg" className="w-1/2" alt="Other Property Info" title="Other Property Info" />
                  </div>
                  <div className="md:w-4/5">
                    <span>{editorData?.other_info}</span>
                  </div>
                </div>:null}
                <hr />
              {propertyLocations?.length > 0 ?<PropertyLocations data={propertyLocations} handleLocationTrue={handleLocationTrue} hiddenData={true} ownerSettings={ownerSettings}/>:null}
              </div>
            </div>
          </TabPanel>

          {/* Property Managers */}
          {/* <TabPanel value={tabValue} index={1}>
					<div className="mt-4">
						<div className="flex align-items-center">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/listings-about-manager-on-premises.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Property Managers:
							</label>
						</div>
						<div className="w-[90%] m-auto">
							<div className="w-1/4 pl-5 ml-5 mt-2">
								<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
									<button
										type="button"
										className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500"
									>
										+ Add
									</button>
								</label>
							</div>
						</div>
					</div>
				</TabPanel> */}

          {/* Notification */}
          <TabPanel value={tabValue} index={1}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-notifications.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Notifications:
                </label>
              </div>
            </div>

            {propertyNotification?.map((row, index) => {
              const notifications = [...propertyNotification];
              return (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Title:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="p-notice-title"
                        type="text"
                        placeholder="Title"
                        disabled
                        value={row?.title}
                        onChange={(e) => {
                          notifications[index].title = e.target.value;
                          setPropertyNotification(notifications);
                          setFormData({
                            ...formData,
                            notifications: notifications,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Notification:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.notification}
                          config={{
                            readOnly: true,
                          }}
                          onChange={(e) => {
                            notifications[index].notification =
                              e.editor.getData();
                            setPropertyNotification(notifications);
                            setFormData({
                              ...formData,
                              notifications: notifications,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="md:flex md:items-center mt-3">
                      <div className="w-full flex justify-start gap-3">
                        <label className="input-label text-gray-700 font-bold">
                          Pop-Up Notification:
                        </label>

                        <label class="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={row?.is_popup_notification}
                            checked={row?.is_popup_notification === 1}
                            disabled
                            onChange={(e) => {
                              const checkedValue =
                                e.target.checked === true ? 1 : 0;
                              notifications[index].is_popup_notification =
                                checkedValue;
                              setPropertyNotification(notifications);
                              setFormData({
                                ...formData,
                                notifications: notifications,
                              });
                            }}
                            class="sr-only peer"
                          />
                          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <button class="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded">
                          Post Now
                        </button>
                      </div>
                      <div className="md:w-1/5">
                        <button
                          class="bg-gray-200 hover:bg-gray-400 text-dark font-bold py-2 px-4 border border-gray-400 rounded"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop-${index}`}
                        >
                          Post Later
                        </button>
                      </div>
                      <div
                        class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                        id={`staticBackdrop-${index}`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog relative w-auto pointer-events-none">
                          <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                              <h5
                                class="text-xl font-medium leading-normal text-gray-800"
                                id="exampleModalLabel"
                              >
                                Post Later
                              </h5>
                              <button
                                type="button"
                                class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body relative p-4">
                              {/* <div className="md:flex md:items-center w-full mt-3">
															<ReactDatePicker
																className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
																id="notification-due-date"
																selected={
																	new Date(row?.posting_date) == "Invalid Date"
																		? new Date()
																		: new Date(row?.posting_date)
																}
																maxDate={new Date()}
																onChange={(date) => {
																	// console.log("Notification Date",date);
																	setFormData({ ...formData, notifications: date });
																}}
															/>
														</div> */}
                              {/* <div className="md:flex md:items-center w-full mt-3">
															<label className="input-label text-gray-700 font-bold">
																Posting Date:
															</label>
															<input 
															className="form-control" 
															type="date"
															value={row?.posting_date}
															onChange={(e)=>{
																const totalDate = formatDate(e.target.value)
																console.log("Date of the input",totalDate)
																// setDate(e.target.value)
																notifications[index].posting_date = totalDate;
																setPropertyNotification(notifications);
																setFormData({...formData, notifications: notifications})

															}}
															/>
														</div>
														<div className="md:flex md:items-center w-full mt-3">
														<label className="input-label text-gray-700 font-bold">
															Posting Time:
														</label>
															<input className="form-control" 
															type="time"
															value={row?.posting_time}
															onChange = {(e)=>{
																console.warn("Time bro",e.target.value)
																const TotalTime = formatAMPM(e.target.value)
																console.log("Time is money",TotalTime);
																notifications[index].posting_time = TotalTime;
																setPropertyNotification(notifications);
																setFormData({...formData, notifications: notifications})
															}}
															
															/>
														</div> */}
                            {/*
                              <div className="md:flex md:items-center w-full mt-3">
                                <div className="md:w-1/4">
                                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                    Date:
                                  </label>
                                </div>
                                <div className="md:w-1/2">
                                  <input
                                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="date"
                                    type="date"
                                    disabled
                                    placeholder="Steve"
                                    required={false}
                                    value={stringToDate(row?.posting_date)}
                                    onChange={(e) => {
                                      const Date = formatDate(e.target.value);
                                      notifications[index].posting_date = Date;
                                      setPropertyNotification(notifications);
                                      setFormData({
                                        ...formData,
                                        notifications: notifications,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="md:flex md:items-center w-full mt-3">
                                <div className="md:w-1/4">
                                  <label className="block capitalize text-gray-700 font-bold mb-2">
                                    Time:
                                  </label>
                                </div>
                                <div className="md:w-2/4">
                                  <input
                                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="time"
                                    type="time"
                                    disabled
                                    placeholder="Steve"
                                    required={false}
                                    value={stringToTime(row?.posting_time)}
                                    onChange={(e) => {
                                      const tTime = formatAMPM(e.target.value);
                                      notifications[index].posting_time = tTime;
                                      setPropertyNotification(notifications);
                                      setFormData({
                                        ...formData,
                                        notifications: notifications,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                              <button
                                type="button"
                                class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}

            {/* Add more */}

            {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex justify-end w-full mt-4">
                <div className="w-1/4 pl-5 ml-5 mt-2 ">
                  <button
                    type="button"
                    className="btn3"
                    disabled
                    onClick={() => {
                      setPropertyNotification([
                        ...propertyNotification,
                        initialNoticeData,
                      ]);
                    }}
                  >
                    <i class="fa fa-plus"></i> Add Notification
                  </button>
                </div>
              </div>
            </div>

            <hr /> */}
            <PropertyNotifications data={propertyNotification} handleNotificationTrue={handleNotificationTrue} hiddenData={true} ownerSettings={ownerSettings}/>
          </TabPanel>
          {/* Property Boards */}
          <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4 mb-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-boards.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Property Boards:
              </label>
                <Tooltip data={"This is a great place to post House Rules and other information you want everyone in your property to have access to. The Boards will appear on their dashboard."}/>
            </div>
          </div>
            <Accordions data={propertyBoards}/>
                        {/* <PropertyBoards data={propertyBoards} handleBoardTrue={handleBoardTrue} hiddenData={true} p_id={id}/> */}
          </TabPanel>
          {/* Property Public Files */}
          <TabPanel value={tabValue} index={3}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-notes-and-files.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Notes & Files:
                </label>
              </div>
            </div>
            {formData.property_tenant_files != '' ? <div className="md:flex mt-4">
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-files.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Public Files:
                  </label>
                </div>
              </div>
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="property-public-files"
                        type="file"
                        disabled
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Publicbase64Converter(file, index);
                          });
                          setPropertyPublicDocumentsWithUrl(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyPublicDocumentsWithUrl?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>:null}

            {/* Gallery  */}

            {/* <div >
              {propertyPublicDocumentsWithUrl.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id="property-tenant-documents-block"
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyPublicDocumentsWithUrl.map(
                          (fileObject, index) =>
                            fileComponent({
                              file: fileObject?.file,
                              key: index,
                              inputFieldId: "property-public-files",
                              trashAble: false,
                              onTrashClick: () => {
                                let files = [...propertyPublicDocumentsWithUrl];
                                let file = [...formData.property_tenant_files];
                                files.splice(index, 1);
                                file.splice(index, 1);
                                setPropertyPublicDocumentsWithUrl(files);
                                setFormData(file);
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
            <FrontTree handleFiles={handleFiles} folderData={handleFile} getPictureTemplateID = {getPictureTemplateID} setPictureTemplateID = {templateID}/>
            {editorData?.property_tenant_notes != '' ? 
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-red.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Public Notes:
                    </label>
                  </div>
                  {/* <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData?.property_tenant_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          property_tenant_notes: e.editor.getData(),
                        });
                      }}
                    /> 
                    <span>{editorData?.property_tenant_notes}</span>
                  </div> */}
                </div>
                <Notes data={publicNotes} handleNoteTrue={handleNoteTrue} hiddenData={true} type={"Public"}/>
              </div>
            </div>:null}

            <hr />
            {formData.property_staff_files != '' ?
            <div className="md:flex mt-4">
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-files-staff.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Staff Files:
                  </label>
                </div>
              </div>
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="property-staff-files"
                        type="file"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        disabled
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Staff64Converter(file, index);
                          });
                          setPropertyStaffDocumentsWithUrl(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyStaffDocumentsWithUrl?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>:null}

            {/* Gallery  */}

            <div >
            {propertyStaffDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-staff-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyStaffDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-staff-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_staff_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_staff_files = fileLists;
                            setPropertyStaffDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_staff_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_staff_files[fileIndex].title = title;
                                setPropertyStaffDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            {editorData2?.property_staff_notes != '' ?
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-staff.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Staff Notes:
                    </label>
                  </div>
                  {/* <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData2?.property_staff_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData2({
                          ...editorData2,
                          property_staff_notes: e.editor.getData(),
                        });
                      }}
                    /> 
                    <span>{editorData2?.property_staff_notes}</span>
                  </div> */}
                </div>
                <Notes data={staffNotes} handleNoteTrue={handleStaffNoteTrue} hiddenData={true} type={"Staff"}/>
              </div>
            </div>:null}

            <hr />
            {formData.property_manager_files != '' ?
            <div className="md:flex mt-4">
              <div className="md:w-2/3">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-files-property-manager.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Property Manager Files:
                  </label>
                </div>
              </div>
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="property-manager-files"
                        type="file"
                        disabled
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Managerbase64Converter(file, index);
                          });
                          setPropertyManagerDocumentsWithUrl(
                            getFilesAndURLs(e)
                          );
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyManagerDocumentsWithUrl?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>:null}

            {/* Gallery  */}

            <div >
            {propertyManagerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-manager-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyManagerDocumentsWithUrl.map(
                        (fileObject, fileIndex) =>
                          fileComponent({
                            file: fileObject?.file,
                            title: fileObject?.title,
                            key: fileIndex,
                            inputFieldId: "property-manager-files",
                            trashAble: false,
                            editAble: false,
                            onTrashClick: () => {
                              const fileLists = [
                                ...formData?.property_manager_files,
                              ];
                              fileLists.splice(fileIndex, 1);
                              formData.property_manager_files = fileLists;
                              setPropertyManagerDocumentsWithUrl(fileLists);
                            },
                            onEditClick: () => {
                              const fileLists = [...formData?.property_manager_files];
                              const currentTitle = fileLists[fileIndex].title || "";
                            
                              Swal.fire({
                                title: "Edit File",
                                html: `
                                  <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                `,
                                focusConfirm: false,
                                preConfirm: () => {
                                  const title = document.getElementById('swal-input1').value;
                                  return title;
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  const title = result.value;
                                  fileLists[fileIndex].title = title;
                                  formData.property_manager_files[fileIndex].title = title;
                                  setPropertyManagerDocumentsWithUrl(fileLists);
                                }
                              });
                            }
                          })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            {editorData1?.property_manager_notes != '' ? 
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-property-manager.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Manager Notes:
                    </label>
                  </div>
                  {/* <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData1?.property_manager_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData1({
                          ...editorData1,
                          property_manager_notes: e.editor.getData(),
                        });
                      }}
                    /> 
                    <span>{editorData1?.property_manager_notes}</span>
                    </div> */}
                </div>
                <Notes data={managerNotes} handleNoteTrue={handleManagerNoteTrue} hiddenData={true} type={"Manager"}/>
              </div>
            </div>:null}

            <hr />
            {formData.property_owner_files != '' ? 
            <div className="md:flex mt-4">
              <div className="md:w-1/3">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-files-private.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Owner Files:
                  </label>
                </div>
              </div>
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="property-owner-files"
                        type="file"
                        disabled
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Ownerbase64Converter(file, index);
                          });
                          setPropertyOwnerDocumentsWithUrl(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyOwnerDocumentsWithUrl?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>:null}

            {/* Gallery  */}

            <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            {editorData3?.property_owner_notes != '' ? 
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-private.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Owner Notes:
                    </label>
                  </div>
                  {/* <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData3?.property_owner_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData3({
                          ...editorData3,
                          property_owner_notes: e.editor.getData(),
                        });
                      }}
                    /> *
                    <span>{editorData3?.property_owner_notes}</span>
                  </div> */}
                </div>
                <Notes data={ownerNotes} handleNoteTrue={handleOwnerNoteTrue} hiddenData={true} type={"Owner"}/>
              </div>
            </div>:null}
          </TabPanel>
          {/* Property Assistant */}
          <TabPanel value={tabValue} index={4}>
            
              <ContactDetails data={propertyContacts} ownerSettings={ownerSettings}/>
              {/* <PropertyAssistanceInformations data={propertyContacts} handleContactTrue={handleContactTrue} hiddenData={true} ownerSettings={ownerSettings}/> */}

          <PropertySecurityContacts data={propertySecurityContacts} handleSecurityContactTrue={handleSecurityContactTrue} hiddenData={true} ownerSettings={ownerSettings}/>
         
          </TabPanel>
          {/* Property Security */}
          {/* <TabPanel value={tabValue} index={5}>
					
				</TabPanel> */}

          {/* Property Measurements */}
          <TabPanel value={tabValue} index={5}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-measurements.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Measurements:
                </label>
              </div>
            </div>

            {propertyMeasurements?.length > 0 ?
            <PropertyMeasurements measurementData={propertyMeasurements} handleMeasurementTrue={handleMeasurementTrue} hiddenData={true} propertyID={id}/>:null}
          </TabPanel>
          {/* Property Materials */}
          <TabPanel value={tabValue} index={6}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-materials.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Materials:
                </label>
              </div>
            </div> */}

            {propertyMaterials?.length > 0 ?<PropertyMaterials data={propertyMaterials} handleMaterialTrue={handleMaterialTrue} hiddenData={true} ownerSettings={ownerSettings} PropertyID={id}/>:null}
          </TabPanel>
          {/* Parking Spaces */}

          <TabPanel value={tabValue} index={7}>
          <ParkingSpaceTable data={parkingSpaces} handleTrue={handleTrue} hiddenData={true}/>
            <hr />
          </TabPanel>
          {/* Property Features */}
          <TabPanel value={tabValue} index={8}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/listings.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Listing Information:
                </label>
              </div>
            </div>

            
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                {formData.no_of_floors != '' ? 
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/12">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Floors:
                    </label>
                  </div>
                  <div className="md:w-1/12">
                    <span>{formData?.no_of_floors}</span>
                  </div>
                  {formData?.has_stairs == 1 ?
                  <div className="md:w-1/12">
                     <img src="/assets/svgs/listings-about-stairs.svg" className="w-1/2"></img>
                  </div>:null}
                  {formData?.has_elevator == 1 ?
                  <div className="md:w-1/12">
                     <img src="/assets/svgs/listings-about-elevator.svg" className="w-1/2"></img>
                  </div>:null}
                </div>:null}
            
                {editorData?.description != '' ?
                <div className="md:flex mt-3">
                    <span>{editorData?.description}</span>
                  </div>
                :null}
                
                
                
              </div>
            </div>
            <hr />
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/listings-about-luxury.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Feature:
                      </label>
                    </div>
                </div>
                
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                      {formData?.features?.length > 0 ?
                      <div className="md:flex flex-wrap md:items-center mt-3">
                      {features
                        .filter((feature) => formData?.features?.some((item) => item.feature_id === feature.id))
                        .map((feature, index) => {
                          return (
                            <div key={index} className="md:w-1/5">
                              <div className="md:w-auto">
                                <img src={`${imgUrl}/${feature.icon}`} width={"30px"} height={"auto"} alt={feature.feature} />
                                <label className="input-label text-gray-700 mb-2">{feature.feature}</label>
                              </div>
                            </div>
                          );
                        })}
                      </div>:null}
                      {editorData1?.other_property_features != '' ?
                      <div className="md:flex flex-column mt-3">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Other Property Features:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          {/* <CKEditor
                            initData={editorData1?.other_property_features}
                            config={{
                              readOnly: true,
                            }}
                            onChange={(e) => {
                              setEditorData1({
                                ...editorData1,
                                other_property_features: e.editor.getData(),
                              });
                            }}
                          /> */}
                          <span>{editorData1?.other_property_features}</span>
                        </div>
                      </div>:null}
                  </div>
                  </div>
                
                </>
            <hr />
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/listings-nearby.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Nearby:
                      </label>
                    </div>
                </div>
                
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                      {formData?.nearbys?.length > 0 ?
                      <div className="md:flex flex-wrap md:items-center mt-3">
                      {nearbys
                        .filter((nearby) => formData?.nearbys?.some((item) => item.nearby_id === nearby.id))
                        .map((nearby, index) => {
                          return (
                            <div key={index} className="md:w-1/5">
                              <div className="md:w-auto">
                                <img src={`${imgUrl}/${nearby.icon}`} width={"30px"} height={"auto"} alt={nearby.nearby} />
                                <label className="input-label text-gray-700 mb-2">{nearby.name}</label>
                              </div>
                            </div>
                          );
                        })}
                      </div>:null}
                  </div>
                  </div>
                
                </>
                <hr />
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/system-pictures-and-embed.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Property Pictures & Embed:
                      </label>
                    </div>
                </div>
                
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full flex">
                        <div className="w-1/2">

                          {formData.pictures != '' ? 
                          <>
                            {/* <Lightbox data={propertyFormImagesWithUrl} handleFiles={handlePicturesData} hiddenData={true}/> */}
                            <CarouselWithLightbox data={propertyFormImagesWithUrl}/>
                          </>:null}
                        </div>
                        <div className="w-1/2">
                          {formData.embed != '' ? 
                          <div className="md:items-center w-full mt-4">
                            
                              <span>{formData?.embed}</span>
                            
                          </div>:null}
                        </div>

                
                  </div>
                  </div>
                  </>
                

            <hr />
          </TabPanel>
          {/* Property HOA Information */}
          <TabPanel value={tabValue} index={9}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-hoa.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  HOA Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                
                <div className="md:flex justify-content-end md:items-center mt-3">
                <div className="md:w-1/2 flex md:items-center">
                    
                  <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Visible to:
                      </label>
                  </div>
                    {formData?.hoa_show_to_tenant == 1 ? <span className="status-blue">Tenant</span> : null }
                    {formData?.hoa_show_to_property_manager == 1 ? <span className="status-warning">Property Manager</span> : null }
                  </div>
                  
                </div>

                <div className="flex mt-3">
                  <div className="w-1/2 flex flex-column">
                  {formData.hoa_company != '' ? <span>{formData?.hoa_company}</span>:null}
                  {formData.hoa_address_line1 != '' ?<span className="mt-1">{formData?.hoa_address_line1}</span>:null}
                  {formData.hoa_address_line2 != '' ?<span className="mt-1">{formData?.hoa_address_line2}</span>:null}
                  <div className="flex">
                  {formData.hoa_city != '' ?
                    <span className="mt-1">{hoaCities?.id == formData?.hoa_city ? hoaCities?.city : ''},&nbsp;</span>
                  :null}
                {formData.hoa_state != '' ?<span className="mt-1">{hoaStates?.id == formData?.hoa_state ? hoaStates?.state : ''}&nbsp;</span>:null}
                {formData.hoa_zip_code != '' ?
                    <span className="mt-1">{formData?.hoa_zip_code}</span>
                 :null}
                </div>
                 {formData.country != '' ?
                    <span className="mt-1">{countries?.map((country)=>{
                      if(formData?.hoa_country == country?.id){
                        return country?.country
                      }
                    })}</span>:null}
                  </div>
                  <div className="w-1/2 flex flex-column justify-content-center">
                  {formData.hoa_fee != '' ?<span>{formData?.hoa_fee ? convertCurrencyToFormat(ownerSettings?.currency_format,formData?.hoa_fee):""}</span>:null}
                      {formData.hoa_due_date != '' ?<span>{formData?.hoa_due_date}</span>:null}
                  </div>
                </div>

                <div className="flex flex-column mt-3">
                {formData?.hoa_number1 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.hoa_number1_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.hoa_number1}`}>{formData?.hoa_number1_type == 8 ? formData?.hoa_number1: formatPhoneNumber(formData?.hoa_number1,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.hoa_number2 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.hoa_number2_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.hoa_number2}`}>{formData?.hoa_number2_type == 8 ? formData?.hoa_number2: formatPhoneNumber(formData?.hoa_number2,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.hoa_number3 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.hoa_number3_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.hoa_number3}`}>{formData?.hoa_number3_type == 8 ? formData?.hoa_number3: formatPhoneNumber(formData?.hoa_number3,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData.hoa_email != '' ?
                  <a className="text-black" href={`mailto:${formData?.hoa_email}`} target="_blank">{formData?.hoa_email}</a>
                :null}
                {formData.hoa_website != '' ?
                  <a className="text-black" href={`${formData?.hoa_website}`} target="_blank">{formData?.hoa_website}</a>
                :null}
                </div>

                
                
                
                </div>
                </div>
                <hr />
                <div className="md:items-center w-full mt-4">
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/property-hoa-information.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                      HOA Contacts:
                    </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
                </div>
              <ContactDetails data={propertyHoaContacts} ownerSettings={ownerSettings}/>
             <hr />
              {/* <InfoContactForm data={propertyHoaContacts} handleContactTrue={handleHoaContactTrue} hiddenData={true} ownerSettings={ownerSettings}/> */}
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyHoaInteractions} handleInteractionTrue={handleHoaInteractionTrue} hiddenData={true} ownerSettings={ownerSettings}/>
                
                <div className="md:items-center w-full mt-4">
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-and-files.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                      HOA Notes and Files:
                    </label>
               </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                  {/* <div className="md:flex md:items-center md:justify-around md:w-3/5">
                    {formData?.hoa_show_to_tenant ? (
                      <div className="md:flex md:w-2/5">
                        <div className="md:flex md:justify-end md:items-center pr-5 md:w-4/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Show to Tenant:
                          </label>
                        </div>
                        {/* <label class="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={formData?.hoa_fee_show_to_tenant}
                            checked={formData?.hoa_fee_show_to_tenant === 1}
                            disabled
                            onChange={(e) => {
                              const checkedValue =
                                e.target.checked === true ? 1 : 0;
                              setFormData({
                                ...formData,
                                hoa_fee_show_to_tenant: checkedValue,
                              });
                            }}
                            class="sr-only peer"
                          />
                          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label> 
                        {formData?.hoa_fee_show_to_tenant == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i> }
                      </div>
                    ) : null}
                    {formData?.hoa_show_to_property_manager ? (
                      <div className="md:flex md:w-3/5">
                        <div className="md:flex md:justify-end md:items-center pr-5 md:w-4/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Show to Property Manager:
                          </label>
                        </div>
                        {/* <label class="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={formData?.hoa_fee_show_to_property_manager}
                            checked={
                              formData?.hoa_fee_show_to_property_manager === 1
                            }
                            disabled
                            onChange={(e) => {
                              const checkedValue =
                                e.target.checked === true ? 1 : 0;
                              setFormData({
                                ...formData,
                                hoa_fee_show_to_property_manager: checkedValue,
                              });
                            }}
                            class="sr-only peer"
                          />
                          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label> 
                        {formData?.hoa_fee_show_to_property_manager == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i> }
                      </div>
                    ) : null}
                  </div> */}
                
                {formData?.hoa_show_to_tenant ? (
                  <div className="md:flex mt-3">
                    {editorData2?.hoa_tenant_notes != '' ?
                    <>
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Tenant Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      {/* <CKEditor
                        initData={editorData2?.hoa_tenant_notes}
                        config={{
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          setEditorData2({
                            ...editorData2,
                            hoa_tenant_notes: e.editor.getData(),
                          });
                        }}
                      /> */}
                      <span>{editorData2?.hoa_tenant_notes}</span>
                    </div>
                    </>:null}
                  </div>
                ) : null}
                {formData?.hoa_show_to_property_manager ? (
                  <div className="md:flex mt-3">
                    {editorData1?.hoa_property_manager_notes != '' ?<>
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Property Manager Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      {/* <CKEditor
                        initData={editorData1?.hoa_property_manager_notes}
                        config={{
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          setEditorData1({
                            ...editorData1,
                            hoa_property_manager_notes: e.editor.getData(),
                          });
                        }}
                      /> */}
                      <span>{editorData1?.hoa_property_manager_notes}</span>
                    </div>
                    </>:null}
                  </div>
                ) : null}

                {editorData?.hoa_owner_notes != '' ? 
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Owner's Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData?.hoa_owner_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          hoa_owner_notes: e.editor.getData(),
                        });
                      }}
                    /> */}
                    <span>{editorData?.hoa_owner_notes}</span>
                  </div>
                </div>:null}

                {/* HOA Files */}

                {formData.hoa_files != '' ? 
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Files:
                    </label>
                  </div>
                  {/* <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="p-HOA-files"
                        type="file"
                        disabled
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            HOAbase64Converter(file, index);
                          });
                          setPropertyHoaFiles(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount = propertyHoaFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div> */}
                </div>:null}

                {/* Gallery  */}

                <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyHoaFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-HOA-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyHoaFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-HOA-files",
                              trashAble: false,
                              editAble: false,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.hoa_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.hoa_files = fileLists;
                                setPropertyHoaFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.hoa_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.hoa_files[fileIndex].title = title;
                                    setPropertyHoaFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>
              </div>
              </div>
          </TabPanel>
          {/* Property Mortage Information */}
          <TabPanel value={tabValue} index={10}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-mortgage.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Mortgage Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                

                <div className="flex mt-3">
                  <div className="w-1/2 flex flex-column">
                  {formData.mortage_company != '' ? <span>{formData?.mortage_company}</span>:null}
                  {formData.mortage_address_line1 != '' ?<span className="mt-1">{formData?.mortage_address_line1}</span>:null}
                  {formData.mortage_address_line2 != '' ?<span className="mt-1">{formData?.mortage_address_line2}</span>:null}
                  <div className="flex">
                  {formData.mortage_city != '' ?
                    <span className="mt-1">{mortageCities?.id == formData?.mortage_city ? mortageCities?.city : ''},&nbsp;</span>
                  :null}
                {formData.mortage_state != '' ?<span className="mt-1">{mortageStates?.id == formData?.mortage_state ? mortageStates?.state : ''}&nbsp;</span>:null}
                {formData.mortage_zip_code != '' ?
                    <span className="mt-1">{formData?.mortage_zip_code}</span>
                 :null}
                </div>
                 {formData.country != '' ?
                    <span className="mt-1">{countries?.map((country)=>{
                      if(formData?.mortage_country == country?.id){
                        return country?.country
                      }
                    })}</span>:null}
                  </div>
                  <div className="w-1/2 flex flex-column justify-content-center">
                  {formData.mortage_minimum_payment != '' ?<span>{formData?.mortage_minimum_payment ? convertCurrencyToFormat(ownerSettings?.currency_format,formData?.mortage_minimum_payment):""}</span>:null}
                  {formData.mortage_balance != '' ?<span>{formData?.mortage_balance ? convertCurrencyToFormat(ownerSettings?.currency_format,formData?.mortage_balance):""}</span>:null}
                      {formData.mortage_due_date != '' ?<span>{formData?.mortage_due_date}</span>:null}
                  </div>
                </div>

                <div className="flex flex-column mt-3">
                {formData?.mortage_number1 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.mortage_number1_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.mortage_number1}`}>{formData?.mortage_number1_type == 8 ? formData?.mortage_number1: formatPhoneNumber(formData?.mortage_number1,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.mortage_number2 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.mortage_number2_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.mortage_number2}`}>{formData?.mortage_number2_type == 8 ? formData?.mortage_number2: formatPhoneNumber(formData?.mortage_number2,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.mortage_number3 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.mortage_number3_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.mortage_number3}`}>{formData?.mortage_number3_type == 8 ? formData?.mortage_number3: formatPhoneNumber(formData?.mortage_number3,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData.mortage_email != '' ?
                  <a className="text-black" href={`mailto:${formData?.mortage_email}`} target="_blank">{formData?.mortage_email}</a>
                :null}
                {formData.mortage_website != '' ?
                  <a className="text-black" href={`${formData?.mortage_website}`} target="_blank">{formData?.mortage_website}</a>
                :null}
                </div>

                
                
                
                </div>
                </div>
                <hr />
                <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/property-mortgage-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Mortgage Contacts:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
          </div>
              <ContactDetails data={propertyMortageContacts} ownerSettings={ownerSettings}/>
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyMortageInteractions} handleInteractionTrue={handleMortageInteractionTrue} hiddenData={true} ownerSettings={ownerSettings}/>
              
              <div className="md:items-center w-full mt-4">
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-and-files.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                      Mortgage Notes and Files:
                    </label>
               </div>
                </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                

                {formData.mortage_files != '' ?<div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Files:
                    </label>
                  </div>
                  {/* <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="p-mortage-files"
                        type="file"
                        disabled
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Mortagebase64Converter(file, index);
                          });
                          setPropertyMortageFiles(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount = propertyMortageFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div> */}
                </div>:null}


                <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyMortageFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-mortage-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyMortageFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-mortage-files",
                              trashAble: false,
                              editAble: false,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.mortage_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.mortage_files = fileLists;
                                setPropertyMortageFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.mortage_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.mortage_files[fileIndex].title = title;
                                    setPropertyMortageFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>

                {editorData?.mortage_notes != '' ?
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData?.mortage_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          mortage_notes: e.editor.getData(),
                        });
                      }}
                    /> */}
                    <span>{editorData?.mortage_notes}</span>
                  </div>
                </div>:null}
              </div>
            </div>
          </TabPanel>
          {/* Property Builder Information */}
          <TabPanel value={tabValue} index={11}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-builder.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Builder Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                

                <div className="flex mt-3">
                  <div className="w-1/2 flex flex-column">
                  {formData.builder_company != '' ? <span>{formData?.builder_company}</span>:null}
                  {formData.builder_address_line1 != '' ?<span className="mt-1">{formData?.builder_address_line1}</span>:null}
                  {formData.builder_address_line2 != '' ?<span className="mt-1">{formData?.builder_address_line2}</span>:null}
                  <div className="flex">
                  {formData.builder_city != '' ?
                    <span className="mt-1">{builderCities?.id == formData?.builder_city ? builderCities?.city : ''},&nbsp;</span>
                  :null}
                {formData.builder_state != '' ?<span className="mt-1">{builderStates?.id == formData?.builder_state ? builderStates?.state : ''}&nbsp;</span>:null}
                {formData.builder_zip_code != '' ?
                    <span className="mt-1">{formData?.builder_zip_code}</span>
                 :null}
                </div>
                 {formData.country != '' ?
                    <span className="mt-1">{countries?.map((country)=>{
                      if(formData?.builder_country == country?.id){
                        return country?.country
                      }
                    })}</span>:null}
                  </div>
                </div>

                <div className="flex flex-column mt-3">
                {formData?.builder_number1 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.builder_number1_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.builder_number1}`}>{formData?.builder_number1_type == 8 ? formData?.builder_number1: formatPhoneNumber(formData?.builder_number1,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.builder_number2 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.builder_number2_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.builder_number2}`}>{formData?.builder_number2_type == 8 ? formData?.builder_number2: formatPhoneNumber(formData?.builder_number2,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData?.builder_number3 != ""?<div className="flex mt-1">
                  <span>{numberType?.map((row)=>{
                  if(formData?.builder_number3_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className=" ml-2 text-black no-underline" href={`tel:${formData?.builder_number3}`}>{formData?.builder_number3_type == 8 ? formData?.builder_number3: formatPhoneNumber(formData?.builder_number3,ownerSettings?.phone_number_format)}</a>
                </div>:null}
                {formData.builder_email != '' ?
                  <a className="text-black" href={`mailto:${formData?.builder_email}`} target="_blank">{formData?.builder_email}</a>
                :null}
                {formData.builder_website != '' ?
                  <a className="text-black" href={`${formData?.builder_website}`} target="_blank">{formData?.builder_website}</a>
                :null}
                </div>

                
                
                
                </div>
                </div>
                <hr />
                <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/property-builder-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Builder Contacts:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
          </div>
              <ContactDetails data={propertyBuilderContacts} ownerSettings={ownerSettings}/>
              <hr />
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyBuilderInteractions} handleInteractionTrue={handleBuilderInteractionTrue} hiddenData={true} ownerSettings={ownerSettings}/>

                {/* Builder Files */}
                <div className="md:items-center w-full mt-4">
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-notes-and-files.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                      Builder Notes and Files:
                    </label>
               </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">

                {formData.builder_files != '' ?
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Builder Files:
                    </label>
                  </div>
                  {/* <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="p-builder-files"
                        disabled
                        type="file"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Builderbase64Converter(file, index);
                          });
                          setPropertyBuilderFiles(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount = propertyBuilderFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div> */}
                </div>:null}
                <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyBuilderFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-builder-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyBuilderFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-builder-files",
                              trashAble: false,
                              editAble: false,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.builder_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.builder_files = fileLists;
                                setPropertyBuilderFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.builder_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.builder_files[fileIndex].title = title;
                                    setPropertyBuilderFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>

                {editorData?.builder_notes != '' ? <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Builder Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    {/* <CKEditor
                      initData={editorData?.builder_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          builder_notes: e.editor.getData(),
                        });
                      }}
                    /> */}
                    <span>{editorData?.builder_notes}</span>
                  </div>
                </div>:null}
              </div>
            </div>
          </TabPanel>
          {/* Property Insurance Claim */}
          {/* <TabPanel value={tabValue} index={11}> */}
          {/* <div className="md:flex flex-column mt-4">
					<div className="w-full">
						<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
							Insurance Claims:
						</label>
					</div>

					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="flex md:justify-start w-full mt-3">
							<div className="md:w-1/5">
								<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
									Claim Resolution Files:
								</label>
							</div>
							<div className="md:w-1/5">
								<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
									<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
										Upload files
									</span>
									<input
										id="property-insurance-claim-files"
										type="file"
										accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
										multiple={true}
										hidden={true}
										onChange={(e) => {
											setPropertyClaimResolutionDocumentsWithUrl(
												getFilesAndURLs(e)
											);
										}}
									/>
								</label>
							</div>
							<div className="md:w-1/2">
								<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
									<span className="text-gray-600">
										{(() => {
											const filesCount =
												propertyClaimResolutionDocumentsWithUrl?.length || 0;
											return filesCount > 1
												? filesCount + " Documents"
												: filesCount == 1
												? filesCount + " Document"
												: null;
										}).call()}
									</span>
								</label>
							</div>
						</div>
					</div>
				</div> */}

          {/* Gallery  */}
          {/* 
					<div >
						{propertyClaimResolutionDocumentsWithUrl.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-insurance-claim-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyClaimResolutionDocumentsWithUrl.map(
												(fileObject, index) =>
													fileComponent({
														file: fileObject?.file,
														key: index,
														inputFieldId: "property-insurance-claim-files",
														trashAble: false,
														onTrashClick: () => {
															let files = [
																...propertyClaimResolutionDocumentsWithUrl,
															];
															files.splice(index, 1);
															setPropertyClaimResolutionDocumentsWithUrl(files);
														},
													})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div> */}
          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Claim Resolution Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={"hi how are you?"}
										style={{ color: "red" }}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Tenant Payment */}
          <TabPanel value={tabValue} index={12}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/finance-dollar-bill.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Payment Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                

                <div className="flex mt-3">
                  <div className="w-1/2 flex flex-column">
                  {formData.payment_info_company != '' ? <span>{formData?.payment_info_company}</span>:null}
                  {formData.payment_info_address_line1 != '' ?<span className="mt-1">{formData?.payment_info_address_line1}</span>:null}
                  {formData.payment_info_address_line2 != '' ?<span className="mt-1">{formData?.payment_info_address_line2}</span>:null}
                  <div className="flex">
                  {formData.payment_info_city != '' ?
                    <span className="mt-1">{paymentCities?.id == formData?.payment_info_city ? paymentCities?.city : ''},&nbsp;</span>
                  :null}
                {formData.payment_info_state != '' ?<span className="mt-1">{paymentStates?.id == formData?.payment_info_state ? paymentStates?.state : ''}&nbsp;</span>:null}
                {formData.payment_info_zip_code != '' ?
                    <span className="mt-1">{formData?.payment_info_zip_code}</span>
                 :null}
                </div>
                 {formData.country != '' ?
                    <span className="mt-1">{countries?.map((country)=>{
                      if(formData?.payment_info_country == country?.id){
                        return country?.country
                      }
                    })}</span>:null}
                  </div>
                </div>

                <div className="flex flex-column mt-3">
                {formData?.payment_info_number1 != ""?<div className="flex mt-1">
                <a className="text-black" href={`tel:${formData?.payment_info_number}`}>{formatPhoneNumber(formData?.payment_info_number,ownerSettings?.phone_number_format)}</a></div>:null}
                {formData.payment_info_email != '' ?
                  <a className="text-black" href={`mailto:${formData?.payment_info_email}`} target="_blank">{formData?.payment_info_email}</a>
                :null}
                </div>
                <div className="flex flex-column mt-3">
                  <label className="block capitalize tracking-wide text-gray-700 italic font-bold mb-2">
                    Other Payment Information:
                  </label>
                  <span>{editorData?.payment_info_other_payment_information}</span>
                </div>

                
                
                
                </div>
                </div>
                <hr />

                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/finance-payment-gateways.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Payment Processing Options:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-payment-processing-option"
                      disabled
                      onChange={(e) => {
                        // TODO on payment
                      }}
                    >
                      <option>Stripe</option>
                      <option>RentBlaze</option>
                    </select>
                  </div>
                </div>

          </TabPanel>
          {/* Staff */}
          <TabPanel value={tabValue} index={13}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/users-staff.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Staff:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="md:flex md:items-center w-full">
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Auto-generate Employee Numbers:
                    </label>
                  </div>
                  <div className="md:w-1/5 form-check form-switch p-0">
                    <input
                      className="form-check-input appearance-none bg-gray-200 border border-gray-200 w-25 rounded-full text-gray-700 py-3 px-4 pr-8 m-0 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="checkbox"
                      disabled
                      role="switch"
                      id="p-staff"
                      // TODO staff check
                    />
                  </div>
                </div>
              </div>
            </div>
            <button id={"form-submit-button"} type={"submit"} hidden={true} disabled={true} className="btn3 mt-6">Submit</button>
          </TabPanel>

          {/* Property Staff Files */}
          {/* <TabPanel value={tabValue} index={15}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Staff Files:
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-staff-files"
											type="file"
											accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyStaffDocumentsWithUrl(getFilesAndURLs(e));
												const files =[...propertyStaffDocumentsWithUrl];
												console.warn(files);
												setFormData({...formData, property_staff_files : getFilesAndURLs(e)})
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyStaffDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>


					<div >
						{propertyStaffDocumentsWithUrl.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-staff-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyStaffDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-staff-files",
													trashAble: false,
													onTrashClick: () => {
														let files = [...propertyStaffDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyStaffDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Staff Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={formData?.property_staff_notes}
										onChange={(e) => {
											setFormData({
												...formData,
												property_staff_notes: e.editor.getData(),
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Manager Files */}
          {/* <TabPanel value={tabValue} index={16}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Property Manager Files:
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-manager-files"
											type="file"
											accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyManagerDocumentsWithUrl(getFilesAndURLs(e));
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyManagerDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>

				

					<div >
						{propertyManagerDocumentsWithUrl.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-manager-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyManagerDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-manager-files",
													trashAble: false,
													onTrashClick: () => {
														let files = [...propertyManagerDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyManagerDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Property Manager Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={formData?.property_manager_notes}
										onChange={(e) => {
											setFormData({
												...formData,
												property_manager_notes: e.editor.getData(),
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Owner Files */}
          {/* <TabPanel value={tabValue} index={17}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Owner Files
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-owner-files"
											type="file"
											accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyOwnerDocumentsWithUrl(getFilesAndURLs(e));
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyOwnerDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>


					<div >
						{propertyOwnerDocumentsWithUrl.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-owner-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyOwnerDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-owner-files",
													trashAble: false,
													onTrashClick: () => {
														let files = [...propertyOwnerDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyOwnerDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
					<div className="w-full">
						<div className="md:flex mt-3">
							<div className="md:w-1/5">
								<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
									Owner Notes:
								</label>
							</div>
							<div className="md:w-4/5">
								<CKEditor
									initData={formData?.property_owner_notes}
									onChange={(e) => {
										setFormData({
											...formData,
											property_owner_notes: e.editor.getData(),
										});
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<input id={"form-submit-button"} type={"submit"} hidden={false} />
				</TabPanel> */}
        </form>
        </>
      )}
    </>
  );
};
