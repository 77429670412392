import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ApiRequest from "../../utility/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import {
  properties,
  propertiesTypes,
} from "../../store/properties/propertiesThunk";
import Loading from "../Loader/Loading";
import { Link, useParams } from "react-router-dom";
import { style } from "@mui/system";
import Swal from "sweetalert2";

const Table = ({filterText,data}) => {
  const [loading,setLoading] = useState('loading');
  const [selectFilter,setSelectFilter] = useState();
  const [newArray1,setNewArray1] = useState([]);
  const [totalProperty,setTotalProperty] = useState();
  const [totalUnit,setTotalUnit] = useState();
  const [unitStatus,setUnitStatus] = useState();
  const [preferenceData,setPreferenceData] = useState();
  // console.log('preferenceData',preferenceData);
  console.log('@newArray1',newArray1);
  // console.log('@unitStatus',unitStatus);
  const dispatch = useDispatch();
  // const allProperties = useSelector(
  //   (state) => state?.properties?.properties?.data
  // );
  // console.log("allProperties", allProperties)
  // const loadingData = useSelector((state) => state.properties.status);

  // useEffect(() => {
  //   setLoading(loadingData);
  // }, [loadingData]);
  // useEffect(() => {
  //   setNewArray1(allProperties);
  // }, [allProperties]);



  const showProperty = async ()=>{
    setLoading(undefined);
      const response = await ApiRequest.get(
          "public/api/auth/get-my-properties"
      );
      setLoading(response.status)
      if(response.status == 200)
      {
          setNewArray1(response?.data?.data)
          setTotalProperty(response?.data?.data)
          // console.log("Response table",response);
      }
  }
  const showActiveProperty = async ()=>{
    setLoading(undefined);
      const response = await ApiRequest.get(
          "public/api/auth/get-my-properties"
      );
      setLoading(response.status)
      if(response.status == 200)
      {
           setNewArray1(response?.data?.data?.filter(obj=>obj.is_active == 1));
          // console.log("Response table",response);
      }
  }
  const showInActiveProperty = async ()=>{
    setLoading(undefined);
      const response = await ApiRequest.get(
          "public/api/auth/get-my-properties"
      );
      setLoading(response.status)
      if(response.status == 200)
      {
           setNewArray1(response?.data?.data?.filter(obj=>obj.is_active == 0));
          // console.log("Response table",response);
      }
  }

  const allPropertiesTypes = useSelector(
    (state) => state?.properties?.propertiesTypes?.data
  );

  
  
  // console.warn("newArray1",newArray1)
  const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
  const [bulkAction , setBulkAction] = useState({
    bulk_action:""
  });
  const makeInActive = async (id) => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Inactivate</strong> the Property. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
    const arrayId = []
    arrayId.push(id);
    // If user confirms, Inactivate the property
    if (isConfirmed) {
      setLoading('loading');
      try {
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.post('/public/api/auth/mark-properties-as-inactive',{
          ids:arrayId,
      });
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Property have been <strong>Inactivated</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Inactivating</strong> the Property. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const makeActive = async (id) => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Activate</strong> the Property. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, activate the property
    const arrayId = []
    arrayId.push(id);
    if (isConfirmed) {
      setLoading('loading');
      try {
        const response = await ApiRequest.post('/public/api/auth/mark-properties-as-active',{
          ids:arrayId          
      });
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Property have been <strong>Activated</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Activating</strong> the Property. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const duplicateProperty = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Duplicate Property',
        html: `
          <input id="swal-input1" class="swal2-input" placeholder="Name">
        `,
        focusConfirm: false,
        showCancelButton: true, // added this to show the cancel button
        confirmButtonClass:'btn2',
        customClass: {
          cancelButton: "bg-red-700"
        },
        cancelButtonText: 'Cancel', // added this to change the text of the cancel button
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
          ];
        },
      });
  
      if (result.isConfirmed) {
        const name = result.value[0];
        console.log('@name',name);
        const response = await ApiRequest.post(`/public/api/auth/duplicate-property?property_id=${id}&name=${name}`)
        if (response.status === 200) {
          showProperty();
          Swal.fire({
            title: 'Success!',
            html: 'The Property have been <strong>Duplicated</strong>.',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonClass:'btn2',
          });
        }
      }
      else { // added this block to handle the cancel button
        Swal.fire({
          title: 'Cancelled',
          html: 'The Property <strong>Duplication</strong> have been cancelled.',
          icon: 'info',
          confirmButtonText: 'OK',
          confirmButtonClass:'btn2',
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        html: 'An error occurred while <strong>Duplicating</strong> the Property.',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonClass:'btn2',
      });
    }
  };

  const units = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-my-units');
    if(response.status == 200)
    {
      setTotalUnit(response?.data?.data);
    }
  }
  const unitStatuses= async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-units-statuses');
    if(response.status == 200)
    {
      setUnitStatus(response?.data?.data);
    }
  }
  
  const deleteProperty = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Delete</strong> the Property. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, Inactivate the property
    if (isConfirmed) {
      setLoading('loading');
      try {
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.delete(
          `public/api/auth/delete-property?property_id=${id}`
          
        );
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Property have been <strong>Deleted</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Deleting</strong> the Property. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  }
		
  const exportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Properties";
    const headers = [["System ID", "Property Name","Status","Address Line 1","Address Line 2","City","State","Zip Code","Total Units","Property Manager","Rented Units","Unrented Units"]];

    const data = filteredData.map(elt => [elt.unique_id, elt.name, elt.status==1?"Active":"Inactive",elt.address_line_1,elt.address_line_2,elt.city_name,elt.state_name,elt.zip_code,elt.units_count])

    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Property.pdf")
  }
  const exportCSV = () => {
    // Get data from data table
    const TotalData = filteredData.map(elt => [elt.unique_id, elt.name, elt.status==1?"Active":"Inactive",elt.address_line_1,elt.address_line_2,elt.city_name,elt.state_name,elt.zip_code,elt.units_count])
    // Convert the data to a CSV string

    const headers = ["System ID", "Property Name","Status","Address Line 1","Address Line 2","City","State","Zip Code","Total Units","Property Manager","Rented Units","Unrented Units"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Property.csv");
    link.click();
  };
  const bulkExportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Properties";
    const headers = [["System ID", "Property Name","Status","Address Line 1","Address Line 2","City","State","Zip Code","Total Units","Property Manager","Rented Units","Unrented Units"]];

    const data = selectedRows.map(elt => [elt.unique_id, elt.name, elt.status==1?"Active":"Inactive",elt.address_line_1,elt.address_line_2,elt.city_name,elt.state_name,elt.zip_code,elt.units_count])

    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Property.pdf")
  }
  const bulkExportCSV = () => {
    // Get data from data table
    const TotalData = selectedRows.map(elt => [elt.unique_id, elt.name, elt.status==1?"Active":"Inactive",elt.address_line_1,elt.address_line_2,elt.city_name,elt.state_name,elt.zip_code,elt.units_count])
    // Convert the data to a CSV string

    const headers = ["System ID", "Property Name","Status","Address Line 1","Address Line 2","City","State","Zip Code","Total Units","Property Manager","Rented Units","Unrented Units"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Property.csv");
    link.click();
  };
  const bulkMakeInActive = async () => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Inactivate</strong> the Property/Properties. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, Inactivate the property
    const arrayId = []
    selectedRows?.map((row)=>{
      arrayId.push(row.id);
    })
    // const commaSeparatedIds = arrayId.join(',');
    console.log("ARRAYID",arrayId);
    if (isConfirmed) {
      setLoading('loading');
      try {
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.post('/public/api/auth/mark-properties-as-inactive',{
          ids:arrayId,
      });
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Property/Properties have been <strong>Inactivated</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Inactivating</strong> the Property/Properties. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const bulkMakeActive = async () => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Activate</strong> the Property/Properties. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, activate the property
    const arrayId = []
    selectedRows?.map((row)=>{
      arrayId.push(row.id);
    })
    
    // const commaSeparatedIds = arrayId.join(',');

    if (isConfirmed) {
      setLoading('loading');
      try {
        const response = await ApiRequest.post('/public/api/auth/mark-properties-as-active',{
          ids:arrayId       
      });
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Property/Properties have been <strong>Activated</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Activating</strong> the Property/Properties. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const bulkDelete = async () => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Delete</strong> the Properties. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, activate the property
    const arrayId = []
    selectedRows?.map((row)=>{
      arrayId.push(row.id);
    })
     console.log('arrayId',arrayId);
    // const commaSeparatedIds = arrayId.join(',');

    if (isConfirmed) {
      setLoading('loading');
      try {
        const response = await ApiRequest.post('public/api/auth/delete-properties',{
          ids:arrayId       
      });
      showProperty()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Properties have been <strong>Deleted</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Deleting</strong> the Property. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const filteredColumns = [
    preferenceData?.system_id === 1
    ? {
        name: "System ID",
        selector: (row) => row.unique_id,
        sortable: preferenceData?.system_id === 1,
      }
    : null,
  preferenceData?.name === 1
    ? {
        name: "Property Name",
        selector: (row) => row.name,
        sortable: preferenceData?.name === 1,
      }
    : null,
  preferenceData?.address === 1
    ? {
        name: "Address",
        selector: (row) => (<><span>{row.address_line_1}</span> <br/> <span>{row.address_line_2}</span></>),
        sortable: preferenceData?.address === 1,
      }
    : null,
  preferenceData?.city === 1
    ? {
        name: "City",
        selector: (row) => row.city_name,
        sortable: preferenceData?.city === 1,
      }
    : null,
  preferenceData?.state === 1
    ? {
        name: "State",
        selector: (row) => row.state_name,
        sortable: preferenceData?.state === 1,
      }
    : null,
  
  preferenceData?.zip_code === 1
    ? {
        name: "Zip Code",
        selector: (row) => row.zip_code,
        sortable: preferenceData?.zip_code === 1,
      }
    : null,
  preferenceData?.manager === 1
    ? {
        name: "Property Manager(s)",
        sortable: preferenceData?.manager === 1,
        // selector: (row) => ,
      }
    : null,
  preferenceData?.total_units === 1
    ? {
        name: "Total Units",
        selector: (row) => row.units_count,
        sortable: preferenceData?.total_units === 1,
      }
    : null,
  preferenceData?.rented_units === 1
    ? {
        name: "Rented Units",
        selector: (row) => "1",
        sortable: preferenceData?.rented_units === 1,
      }
    : null,
  preferenceData?.unrented_units === 1
    ? {
        name: "Unrented Units",
        selector: (row) => "2",
        sortable: preferenceData?.unrented_units === 1,
      }
    : null,
  preferenceData?.issues === 1
    ? {
        name: "Issues",
        selector: (row) => "0",
        sortable: preferenceData?.issues === 1,
      }
    : null,
    preferenceData?.status === 1
    ? {
        name: "Status",
        selector: (row) => row.is_active == 1 ? <span class="status-active">
          <button className="uppercase" type="button" onClick={()=>{
          makeInActive(row.id)
        }}>
          Active
          </button>
          </span> 
          : 
        <span class="status-inactive">
          <button className="uppercase" 
          type="button" onClick={()=>{
                  makeActive(row.id)
                }}>
                  Inactive
                  </button>
          </span>,
        sortable: preferenceData?.status === 1,
      }
    : null,
    // {
    //   name: "Property Type",
    //   selector: (row) => {
    //     return newArray2?.map((item, index) => {
    //       if (row.type_id === item.id) {
    //         return (row.name = item.type);
    //       }
    //     });
    //   },
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
           <div className="flex">
            <Link to={`/show-property/${row.id}`}>
            
                <button type="button" class="bg-transparent border-none md:w-6  p-0" title="View">
    
                    <img src='/assets/img/system-view.png' />
                  
                </button>
            </Link>
            <Link to={`/edit-property/${row.id}`}>

                <button type="button" class="bg-transparent border-none md:w-6 p-0"  title="Edit">
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
            </Link>
                <button type="button" class="bg-transparent border-none md:w-6 p-0 mx-1" title="Duplicate" onClick={()=>{
                  duplicateProperty(row.id);
                }}>
    
                  <img src='/assets/img/system-duplicate.png' />
                  
                </button>
               
               

                <button type="button" class="bg-transparent border-none md:w-6 p-0" title="Message">
    
                  <img src='/assets/img/system-message.png' />
                  
                </button>
               
               

                <button type="button" onClick={()=>{deleteProperty(row.id)}} class="bg-transparent border-none md:w-6 p-0" title="Delete">
    
                  <img src='/assets/img/system-trashcan.png' />
                  
                </button>

                {/* {row.is_active == 1 ?
                <button type="button" onClick={()=>{
                  makeInActive(row.id)
                }} class="bg-green-700 border-none font-bold md:w-12 p-0">
    
                  ACTIVE
                  
                </button>
                :
                <button type="button" onClick={()=>{
                  makeActive(row.id)
                }} class="bg-red-700 border-none font-bold md:w-16 p-0">
    
                  INACTIVE
                  
                </button>} */}
               
               
                 </div>
          </>
        );
      },
    },
  ];

  const columns = filteredColumns.filter((column) => column !== null && column.selector !== null);

  useEffect(() => {
    setPreferenceData(data);
  }, [data])

  useEffect(() => {
    units();
    unitStatuses();
    showProperty();
    dispatch(propertiesTypes());
  }, []);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleLogSelectedRows = () => {
    console.log(selectedRows);
  };
  let filteredData = '';
  filteredData = newArray1?.filter(item =>
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.unique_id && item.unique_id.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.address_line_1 && item.address_line_1.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.address_line_2 && item.address_line_2.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.city_name && item.city_name.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.state_name && item.state_name.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.zip_code && item.zip_code.toLowerCase().includes(filterText.toLowerCase()))
  );
  
  const filtration = (data) => {
    // let filter;
    if (data === 0) {
      showInActiveProperty();
    } else if (data === 1) {
      // filter = Property.filter(obj => obj.is_active === 1);
      showActiveProperty();
    } else if (data === 3) {
      // filter = Property;
      showProperty();
    } else {
      // filter = Property;
      showProperty();
    }
  };

  
  const countActive = totalProperty?.map(obj => obj.is_active).filter(isActive => isActive === 1).length;
  const countUnitActive = totalUnit?.map(obj => obj.is_active).filter(isActive => isActive === 1).length;
  const countRentedUnit = totalUnit?.map(obj => obj.unit_status_id).filter(isActive => isActive === 3).length;
  const countUnRentedUnit = totalUnit?.map(obj => obj.unit_status_id).filter(isActive => isActive !== 3).length;
  const filteredArrayLength = totalUnit?.filter(obj => {
    return unitStatus?.some(status => {
      return obj.unit_status_id === 3 && obj.is_active === 1;
    });
  });
  
  const countUnitRentedActive = filteredArrayLength?.length;
  const filteredUnRentedArrayLength = totalUnit?.filter(obj => {
    return unitStatus?.some(status => {
      return obj.unit_status_id !== 3 && obj.is_active === 1;
    });
  });
  
  const countUnitUnRentedActive = filteredUnRentedArrayLength?.length;
  const tableData = {
    columns : columns,
    data : filteredData,
    fixedHeader: true,
    fixedHeaderScrollHeight: '500px',
    sortFieldId: 1,
    sortDirection:"asc"
  };
  return (
    <>
      {loading === undefined ? (
        <Loading />
      ) : (
        <>
        <div className="flex md:w-full mt-3">
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-5 mt-2 data">{countActive} of {totalProperty?.length} Active Properties</span>
            <img className="w-28 mr-5 cursor-pointer" src="/assets/img/system-upgrade-button.png" alt="Upgrade" />
          </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <span className="mr-5 data">Total Open Issue Tickets: <span className="text-red-600">2</span></span>
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} />
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} />
        </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <label className="block capitalize text-gray-700 font-bold mb-2 mr-2 mt-2">Filter: </label>
        <select
        className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        value={selectFilter}
        id="filtrationData"
        onChange={(e)=>{
          setSelectFilter(parseInt(e.target.value));
          filtration(parseInt(e.target.value));
        }}
        >
          <option value="3">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        </div>
        </div>
      </div>
      <DataTableExtensions filter={false} {...tableData}>
        <DataTable
        className="striped"
          // columns={columns}
          // data={newArray1}
          // pagination
          // fixedHeader
          // fixedHeaderScrollHeight="500px"
          selectableRows
          pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={handleRowSelected}
          // defaultSortFieldId={1}
        />
        </DataTableExtensions>
        {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
        <div class="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ?<div className="md:flex md:items-center w-full md:w-8/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected value=' '>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"MAKE ACTIVE"}>MAKE ACTIVE</option>
                <option value={"MAKE INACTIVE"}>MAKE INACTIVE</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-1/2 ml-1">
               <button
                  type="button"
                  className="btn2"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE INACTIVE" ? bulkMakeInActive :  bulkAction?.bulk_action == "DELETE" ? bulkDelete : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
        <div class="bg-indigo-300 p-2 rounded unit-wrapper">
              <div class="flex flex-wrap gap-x-4">
                <p class="flex-auto">
                  Total Active Units: <span>{countUnitActive}</span>
                </p>
                <p class="flex-auto">
                  Total Active Rented Units:{" "}
                  <span class="text-green-600">{countUnitRentedActive}</span>
                </p>
                <p class="flex-auto">
                  Total Active Unrented Units:{" "}
                  <span class="text-red-600">{countUnitUnRentedActive}</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Units: <span>{totalUnit?.length}</span>
                </p>
                <p class="flex-auto">
                  Total Rented Units: <span>{countRentedUnit}</span>
                </p>
                <p class="flex-auto">
                  Total Unrented Units: <span>{countUnRentedUnit}</span>
                </p>
              </div>
            </div>
            </div>
        
        </>
      )}
    </>
  );
};

export default Table;