import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";

const LeadTable = ({ userRoles, status }) => {
  // console.log("userRoles", userRoles)
  //   const dispatch = useDispatch();
  //   const allProperties = useSelector(
  //     (state) => state.properties.properties.data
  //   );
  //   const loading = useSelector((state) => state.properties.status);
  //   const allPropertiesTypes = useSelector(
  //     (state) => state.properties.propertiesTypes.data
  //   );

  //   const newArray1 = allProperties?.map((a) => ({ ...a }));
  //   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
  // const [unit,setUnit] = useState([]);
  // const [loader,setLoader]= useState();
  // const showUnit = async ()=>{
  //     const response = await ApiRequest.get(
  //         "public/api/auth/get-my-tenants"
  //     );
  //     setLoader(response.status)
  //     if(response.status == 200)
  //     {
  //         setUnit(response?.data?.data)
  //         console.log("Response table",response);
  //     }
  // }
  // useEffect(() => {
  //   showUnit();
  // }, [])

  const data = ["131231", "nvvxcvsd", "1234323", "dfsdfsd", "534534"];

  const columns = [
    {
      name: "System ID",
      selector: (data) => "3543423465432543",
    },
    {
      name: "Last Name",
      selector: (data) => 'Smith',
    },
    {
      name: "First Name",
      selector: (data) => "John",
    },
    {
      name: "Title",
      selector: (data) => 'Property Manager',
    },
    {
      name: "Company",
      selector: (data) => "ABC Realty",
    },
    {
      name: "E-mail",
      selector: (data) => "john@abc.com",
    },
    {
      name: "Number",
      selector: (data) => "617-795-4588",
    },
    {
      name: "Date Added",
      selector: (data) => {
        return ( <>
        <span>02/01/2020</span>
        </>)
    },
    },
    {
      name: "Referred Form",
      selector: (data) => "LeadScripts",
    },
    {
      name: "Last Contact",
      selector: (data) => "08/15/2022",
    },
    {
      name: "Method",
      selector: (data) => "Email",
    },
    {
      name: "Status",
      selector: (data) => <span className="status-active uppercase">Active</span>,
    },
    {
      name: "Options",
      selector: (data) => (
        <div className="flex">
          <button
            type="button"
            className="bg-transparent border-none md:w-6  p-0"
          >
            <img src="/assets/img/system-view.png" title="View Lead" />
          </button>
          <Link to={'/edit-lead'}>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/img/system-edit.png" title="Edit Lead" />
          </button>
          </Link>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-message.svg" title="Message" />
          </button>
        </div>
      ),
    },
    // {
    //   name: "Options",
    //   cell: (data) => {
    //     return (
    //       <>
    //            <Link to={`/edit-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
    //           <i class="fa-solid fa-pen mr-6"></i>
    //           <span>Edit</span>
    //         </button>
    //         </Link>
    //         <Link to={`/show-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
    //             <i class="fa-regular fa-eye mr-6"></i>
    //             <span>View</span>
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

  //   const data =[
  //     {
  //         id:"355821647853265",
  //         sent:"5/24/2022 TIME",
  //         sender:"Sapp, Sean (UNIT A)",
  //         recipient:"SYSTEM",
  //         subject:"Question on Rent Due Date",
  //         viewed:"5/24/2022 TIME",
  //       },
  //     {
  //         id:"745852146932145",
  //         sent:"5/24/2022 TIME",
  //         sender:"SYSTEM",
  //         recipient:"Sapp, Sean (UNIT A)",
  //         subject:"Information requested about the pool ",
  //         viewed:"Not Viewed",
  //       },
  //     {
  //         id:"985632452185635",
  //         sent:"5/24/2022 TIME",
  //         sender:"Knabenshue, Chris(OWNER)",
  //         recipient:"SYSTEM",
  //         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
  //         viewed:"Not Viewed",
  //       },

  //   ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleLogSelectedRows = () => {
    console.log(selectedRows);
  };

  return (
    <>
      <div className="flex md:w-full mt-3">
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-2 mt-2 data">Total Leads: 5</span>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-2 mt-2 data">Total Active: <span className="text-green-600">5</span></span>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-2 mt-2 data">Total Customers:  <span className="text-yellow-600">0</span></span>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-2 mt-2 data">Total Opted Out:  <span className="text-red-600">0</span></span>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
        onSelectedRowsChange={handleRowSelected}
      />
      {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
      <div className="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ? (
          <div className="md:flex md:items-center w-full md:w-10/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                // value={bulkAction.bulk_action}
                // onChange={(e) => {
                //   setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                // }}
              >
                <option selected disabled>
                  Select
                </option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-1/2 ml-1 ">
              <button
                type="button"
                className="btn2"
                //   onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE PROSPECT ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE PROSPECT INACTIVE" ? bulkMakeInActive : bulkAction?.bulk_action == "DELETE" ? bulkDelete : bulkAction?.bulk_action == "MERGE" ? bulkMakeMerge :  null}
              >
                <i className="fa fa-arrow-alt-circle-right"></i>
                GO
              </button>
            </div>
          </div>
        ) : null}
        {/* <div class="bg-indigo-300 p-2 rounded  unit-wrapper">
          <div class="flex flex-wrap gap-x-3">
            <p class="flex-auto">
              Total Types: <span>5</span>
            </p>
            <p class="flex-auto">
              Total Active By Type: <span class="text-green-600">5</span>
            </p>
            <p class="flex-auto">
              Total Inactive By Type <span class="text-red-600">0</span>
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default LeadTable;
