import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import Spinner from '../Spinner/Spinner';
import Select from 'react-select';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';
import useAllowedMimeTypes from '../genericFunctions.js/mimeType';
export const ProfileVehicles = ({data,handleVehicleTrue,hiddenData}) => {

    const initialVehicleData = {
        status: 0,
        year: "",
        vehicle_make_id: null,
        vehicle_model_id: null,
        color: "",
        vin: "",
        license_plate: "",
        assign_parking_space: 0,
        property_parking_space_id: null,
        pictures: [],
        files: [],
        notes: "",
      };

      const picTypes = useAllowedPicMimeTypes();
      const fileTypes = useAllowedMimeTypes();

    let fileApi = "/public/api/auth/upload-file";
    let imageApi = "/public/api/auth/upload-image";
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });

    const [tenantVehicle, setTenantVehicle] = useState([]);
    console.log("@tenantVehicle",tenantVehicle);
    const [tenantVehicles, setTenantVehicles] = useState([]);
    const [tenantVehicleFiles, setTenantVehicleFiles] = useState([]);
    const [tenantVehiclePicture, setTenantVehiclePicture] = useState([]);
    const [vehicleMake, setVehicleMake] = useState([]);
    const [vehicleModel, setVehicleModel] = useState([]);
    const [vehicleMakeTable, setVehicleMakeTable] = useState([]);
    console.log("@vehicleMake",vehicleMake);
    console.log("@vehicleMakeTable",vehicleMakeTable);
    const [vehicleModelTable, setVehicleModelTable] = useState([]);
    const [propertyParkingSpaces, setPropertyParkingSpaces] = useState([]);
    const [showForm,setShowForm] = useState(false);
    const [viewForm,setViewForm] = useState(false);
    const [editorData,setEditorData] = useState();
    const [loading,setLoading] = useState();
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [selectedModelOptions, setSelectedModelOptions] = useState();
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 50;
    const Years50 = currentYear - 50;
    const years50 = [...Array(currentYear - startYear + 1).keys()]
        .map((i) => ({ label: startYear + i, value: startYear + i }))
        .reverse();
  
    const myDivRef = useRef(null);
    const notes = ()=>{
      const data = [];
      data.push(tenantVehicle);
      const array = [...data];
      // console.log('@array',array);
      // const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");

      array[0].notes = editorData;

      setTenantVehicle(array);
    }
    const bulkData = ()=>{
      notes();
      //   tenantVehicle({...tenantVehicle1,other_type_description:editorData1}); 
        const newDataArray  = [];
        newDataArray.push(tenantVehicle);
        handleVehicleTrue(prevArray => [...prevArray, ...newDataArray]);
        setTenantVehicles(prevArray => [...prevArray, ...newDataArray]);
        setTenantVehicle(initialVehicleData);
        setTenantVehiclePicture([]);
        setTenantVehicleFiles([]);
        // setEditorData('');
        setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...tenantVehicles];
        array[indexEdit] = tenantVehicle;
        // const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");
        array[indexEdit].notes = editorData;
        setTenantVehicles(array);
    
        handleVehicleTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantVehicle(initialVehicleData);
        setTenantVehiclePicture([]);
        setTenantVehicleFiles([]);


      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setShowForm(true);
        setTenantVehicle(tenantVehicles[index]);
        setTenantVehicleFiles(tenantVehicles[index]?.files)
        setTenantVehiclePicture(tenantVehicles[index]?.pictures);
        setSelectedOptions(tenantVehicles[index]?.vehicle_make_id)
        setSelectedYear(tenantVehicles[index]?.year)
        setSelectedModelOptions(tenantVehicles[index]?.vehicle_model_id)
        setEditorData(tenantVehicles[index]?.notes)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setTenantVehicle(tenantVehicles[index]);
        setTenantVehicleFiles(tenantVehicles[index]?.files)
        setTenantVehiclePicture(tenantVehicles[index]?.pictures);
        setEditorData(tenantVehicles[index]?.notes)
        setViewForm(true);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...tenantVehicles];
        newArray.splice(index, 1);
        setTenantVehicles(newArray);
        handleVehicleTrue(newArray);
      }
      const columns = [
        {
          name: "Picture",
          selector: (data) => (
              <>
              <img className='logo w-1/2' src={`${imgUrl}/${data.pictures[0]?.picture}`} alt="" />
              </>
          ),
          sortable: true,
        },
        {
          name: "Year",
          selector: (data) => data.year,
          sortable: true,
        },
        {
          name: "Make",
          selector: (data) => vehicleMakeTable?.map((row)=>{
            if(data.vehicle_make_id == row?.id)
            {
                return row?.make;
            }
          }),
          sortable: true,
        },
        {
          name: "Model",
          selector: (data) => vehicleModelTable?.map((row)=>{
            if(data.vehicle_model_id == row?.id)
            {
                return row?.model;
            }
          }),
          sortable: true,
        },
        {
          name: "Color",
          selector: (data) => data.color,
          sortable: true,
        },
        {
          name: "License Plate",
          selector: (data) => data.license_plate,
          sortable: true,
        },
        {
          name: "Parking Space",
          selector: (data) => propertyParkingSpaces?.map((row)=>{
            if(data?.property_parking_space_id == row?.id)
            {
              return row?.space_number
            }
          }),
          sortable: true,
        },
        {
          name: "Status",
          selector: (data) => {
            return (
              <>
              {data.status == 1 ?
              <span class="status-active uppercase">
    
                ACTIVE
                
              </span>
              :
              data.status == 0 ?
              <span class="status-inactive uppercase">
    
                INACTIVE
                
              </span>:null}
              </>
            );
          },
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData != true ? (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewVehicleForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                {/* <button type="button" class="bg-transparent border-none md:w-1/6 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button> */}
               
               
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewVehicleForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : tenantVehicles,
      };
      const exportPDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Tenant Vehicles";
        const headers = [
          ["Year", "Make", "Model", "Color", "License Plate", "Parking Space", "Status"],
        ];
        const data = tenantVehicles.map((elt) => [
          // elt.pictures[0]?.picture ? `<img src="${elt.pictures[0]?.picture}" />`:null,
          elt.year,
          vehicleMakeTable.find((row) => elt.vehicle_make_id === row.id)?.make || '',
          vehicleModelTable.find((row) => elt.vehicle_model_id === row.id)?.model || '',
          elt.color,
          elt.license_plate,
          elt.property_parking_space_id,
          elt.status === 0 ? "Inactive" : elt.status === 1 ? "Active" : null,
        ]);
        let content = {
          startY: 50,
          head: headers,
          body: data,
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Vehicles.pdf");
      };
      
      const exportCSV = () => {
        // Get data from data table
        const TotalData = tenantVehicles.map((elt) => [
          // elt.pictures[0]?.picture ? `<img src="${elt.pictures[0]?.picture}" />`:null,
          elt.year,
          vehicleMakeTable.find((row) => elt.vehicle_make_id === row.id)?.make || '',
          vehicleModelTable.find((row) => elt.vehicle_model_id === row.id)?.model || '',
          elt.color,
          elt.license_plate,
          elt.property_parking_space_id,
          elt.status === 0 ? "Inactive" : elt.status === 1 ? "Active" : null,
        ]);
        // Convert the data to a CSV string
    
        const headers = ["Year","Make","Model",'Color','License Plate','Parking Space','Status'];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Vehicles.csv");
        link.click();
      };
      
      const imageComponent = ({
          image = {},
          key = 0,
          src = "",
          inputFieldId = "",
          trashAble = false,
          onTrashClick = () => {},
          onEditClick = () => {},
          ...restProps
        }) => {
          const confirmEdit = (image) => {
            Swal.fire({
              title: 'Are you sure you want to edit this image?',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel'
            }).then((result) => {
              if (result.isConfirmed) {
                // Modify the image object here
                const editedImage = { ...image, title: 'New Title' };
                onEditClick(editedImage);
              }
            });
          };
        
          return image ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
            >
              <div className="relative h-fit w-full">
                <img
                  title={image?.title}
                  key={key}
                  alt="property image"
                  className="border block object-contain w-80 h-auto rounded-lg"
                  src={src}
                />
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
                >
                  {trashAble ? (
                    <img
                      title="Remove image"
                      type="button"
                      className="logo w-5 mx-auto my-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
        
                        if (fileInputField) {
                          fileInputField.files.forEach((file) => {
                            if (file !== image) {
                              dataTransfer.items.add(file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                  {/* <button
                    type="button"
                    title="Edit image"
                    className="logo w-5 mx-auto my-1"
                    onClick={() => confirmEdit(image)}
                  >
                    <img src="/assets/img/system-edit.png" alt="edit" />
                  </button> */}
                </div>
              </div>
            </div>
          ) : (
            <img {...restProps} />
          );
        };
        const fileComponent = ({
          file = null,
          key = 0,
          inputFieldId = "",
          trashAble = false,
          onTrashClick = () => {},
        }) =>
          file ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
            >
              <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
                <div
                  title={file}
                  key={key}
                  className="block min-w-[600rem] px-2"
                >
                  <a href={`${imgUrl}/${file}`} target="_blank">{file}</a>
                </div>
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
                >
                  {trashAble ? (
                    <img
                      title="Remove file"
                      className="logo w-5 my-auto mx-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
      
                        if (fileInputField) {
                          fileInputField.files.forEach((input_file) => {
                            if (input_file !== file) {
                              dataTransfer.items.add(input_file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null;
         

          const getVehicleMakes = async () => {
            const response = await ApiRequest.get(
              "/public/api/auth/get-vehicles-makes"
            );
            if (response.status === 200) {
              const options = response?.data?.data?.makes?.map((row) => ({
                value: row?.id,
                label: row?.make,
              }));
              setVehicleMake(options);
              setVehicleMakeTable(response?.data?.data?.makes)
              setLoading(response);
            }
          };
          const getVehicleModels = async (vehicle_make_id,year) => {
            setLoading(undefined);
            const response = await ApiRequest.get(
              `/public/api/auth/get-vehicles-models?vehicle_make_id=${vehicle_make_id}&year=${year}`
            );
            if (response.status === 200) {
              const options = response?.data?.data?.models?.map((row) => ({
                value: row?.id,
                label: row?.model,
              }));
              setVehicleModel(options);
              setVehicleModelTable(response?.data?.data?.models)
              setLoading(response);
            }
          };
          const handleSelectMakeChange=(selectedOptions)=>{
            setSelectedOptions(selectedOptions)

            setTenantVehicle({...tenantVehicle,vehicle_make_id : selectedOptions.value});

            getVehicleModels(selectedOptions.value,tenantVehicle?.year)
          }
          const handleSelectModelChange=(selectedModelOptions)=>{
            setSelectedModelOptions(selectedModelOptions)

            setTenantVehicle({...tenantVehicle,vehicle_model_id : selectedModelOptions.value});

            // getVehicleModels(selectedModelOptions.value) 
          }
          const handleSelectYearChange=(selectedYear)=>{
            setSelectedYear(selectedYear)

            setTenantVehicle({...tenantVehicle,year : selectedYear.value});

            // getVehicleModels(selectedModelOptions.value) 
            getVehicleModels(tenantVehicle?.vehicle_make_id,selectedYear.value)
          }
          const PropertyID = parseInt(localStorage.getItem('Property ID'))
          const getPropertyParkingSpaces = async ()=>{
            const response = await ApiRequest.get(`public/api/auth/get-parking-spaces-by-property?property_id=${PropertyID}`);
            if(response.status == 200){
              setPropertyParkingSpaces(response?.data?.data);
            }
          }

          useEffect(() => {
            getPropertyParkingSpaces();
          }, [PropertyID]);
          useEffect(() => {
              getVehicleMakes();
              // getVehicleModels(tenantVehicle?.vehicle_make_id);
              setTenantVehicle(initialVehicleData);
              setTenantVehicles(data);
          }, [])
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })


  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewVehicleForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewVehicleFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Vehicle
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantVehicle([]);
                    setTenantVehicleFiles([]);
                    setTenantVehiclePicture([]);
                    setEditorData(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex">
                  <div className="w-4/12 mr-3">
                      {tenantVehicle?.pictures?.length > 0 ?
                      (<>
                      <img className="logo w-full" src={`${imgUrl}/${tenantVehicle?.pictures[0]?.picture}`} alt="" />
                      </>)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                      </div>
                    <div className="w-8/12">
                      <div className="flex">
                        <span className='font-bold'>{vehicleMakeTable?.map((make)=>{
                              if(tenantVehicle?.vehicle_make_id == make?.id)
                              {
                                return make?.make
                              }
                            })}</span>
                      <span className='mx-2'>{vehicleModelTable?.map((model)=>{
                            if(tenantVehicle?.vehicle_model_id == model?.id)
                            {
                              return model?.model
                            }
                          })}</span>
                      </div>
                      
                      <div className="flex mt-2">
                      {tenantVehicle?.year != "" ?
                        <span>{tenantVehicle?.year}</span>:null}
                        {tenantVehicle?.color != "" ? <><span className='ml-2'>| </span>
                        <span className='ml-2'>{tenantVehicle?.color}</span>
                        </>:null}
                      </div>
                      {tenantVehicle?.status !== "" && (
                          <div className="flex mt-2">
                            {tenantVehicle?.status === 1 && <span className='status-active uppercase'> Active</span>}
                            {tenantVehicle?.status === 0 && <span className='status-inactive uppercase'>Inactive</span>}
                          </div>
                        )}
                      <div className="flex mt-2">
                      {tenantVehicle?.vin != "" ?
                        <span>{tenantVehicle?.vin}</span>:null}
                        {tenantVehicle?.license_plate != "" ? <><span className='ml-2'>| </span>
                        <span className='ml-2'>{tenantVehicle?.license_plate}</span>
                        </>:null}
                      </div>
                      {tenantVehicle?.property_parking_space_id != "" ? 
                      <div className="flex">
                        <span>Assigned Space: {propertyParkingSpaces?.map((space)=>{
                          if(tenantVehicle?.property_parking_space_id == space?.id)
                          {
                            return space?.space_number
                          }
                        })}</span>
                      </div>:null}
                    </div>
                  </div>
                  {tenantVehicle?.notes != "" ?
                  <>
                    <hr />
                    <h6>Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantVehicle?.notes }} />
                    </div>

                  </>:null}
                  {tenantVehicle?.files?.length > 0 ? 
                  <>
                    <hr />
                    <h6>Files:</h6>
                    {tenantVehicle?.files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                </div>
              </div>
              :null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantVehicle([]);
                                setTenantVehicleFiles([]);
                                setTenantVehiclePicture([]);
                                setEditorData(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/assets-vehicles.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Vehicles:
              </label>
            </div>
          </div>

          {showForm == false ? 
          <div className="flex flex-row-reverse justify-between items-center mt-3">
            <div className="flex justify-between items-center gap-4">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true)
                }}
              >
                <i class="fa fa-plus"></i> Add Vehicle
              </button>
            </div>
          </div>
          :null}
               
              {showForm == true ? 
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  {/* <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-status`}
                          value={tenantVehicle?.status}
                          required={false}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,status:parseInt(e.target.value)})
                          }}
                        >
                          <option value=" ">Select Status</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="md:flex md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Year:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-year`}
                          value={tenantVehicle?.year}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,year:e.target.value});
                          }}
                        >
                          {years50.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select> */}
                        <Select 
                        options={years50}
                        value={years50.find(year=>year.value === tenantVehicle?.year)}
                        onChange={handleSelectYearChange}
                        isSearchable
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Make:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                          <Select
                            options={vehicleMake}
                            value={vehicleMake.find(option => option.value === tenantVehicle.vehicle_make_id)}
                            onChange={handleSelectMakeChange}
                            isSearchable
                          />

                          {/* <option value=" ">Select Vehicle Make</option>
                          {vehicleMake.map((make) => (
                            <option value={make?.id}>{make?.make}</option>
                          ))}
                        </select2> */}
                      </div>
                    </div>
                    
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Model:
                        </label>
                      </div>
                  {loading == undefined ? <Spinner className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3"/> :
                      <div className="md:w-3/4">
                        {/* <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-vehicle-model-id`}
                          value={tenantVehicle?.vehicle_model_id}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,vehicle_model_id:parseInt(e.target.value)});
                          }}
                        >
                           <option value="">Select Vehicle Model</option>
                          {vehicleModel.map((model) => (
                            <option value={model?.id}>{model?.model}</option>
                          ))}
                        </select> */}
                        <Select
                            // className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            options={vehicleModel}
                            value={vehicleModel.find(option => option.value === tenantVehicle.vehicle_model_id)}
                            onChange={handleSelectModelChange}
                            isSearchable
                          />
                    </div>}
                      </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Color:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-color`}
                          type="text"
                          placeholder="Black"
                          required={false}
                          value={tenantVehicle?.color}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,color:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          VIN:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-vin`}
                          type="text"
                          placeholder="123"
                          required={false}
                          value={tenantVehicle?.vin}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,vin:e.target.value})
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          License Plate:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`vehicle-license-plate`}
                          type="text"
                          placeholder="123"
                          required={false}
                          value={tenantVehicle?.license_plate}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,license_plate:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-input-wrapper">
              

                    <div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold">
                        Assign Parking Space:
                      </label>
                      <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        value={tenantVehicle?.assign_parking_space}
                        checked={localStorage.getItem('Property ID') && tenantVehicle?.assign_parking_space === 1}
                        onChange={(e) => {
                          const propertyId = localStorage.getItem('Property ID');
                          if (!propertyId) {
                            Toast.fire({
                              icon:"warning",
                              text:"Please Select the Property!"
                            });
                            return; // Exit the function without updating the checkbox state
                          }
                          else{

                            const isChecked = e.target.checked === true ? 1 : 0;
                            setTenantVehicle({...tenantVehicle, assign_parking_space: isChecked});
                          }
                        }}
                      />

                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    {tenantVehicle?.assign_parking_space === 1 ? (
                      <div className="w-full">
                        <label className="input-label text-gray-700 font-bold">
                          Space:
                        </label>

                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-property-type"
                          required={false}
                          placeholder="Name"
                          value={tenantVehicle?.property_parking_space_id}
                          onChange={(e) => {
                            setTenantVehicle({...tenantVehicle,property_parking_space_id:parseInt(e.target.value)})
                          }}
                        >
                          <option value="">Select Parking Space</option>
                          {propertyParkingSpaces
                                  .filter((propertyType) => propertyType?.is_assigned !== 1)
                                  .map((propertyType) => (
                                    <option value={propertyType?.id}>
                                      {propertyType?.space_number}
                                    </option>
                                  ))}

                        </select>
                      </div>
                    ) : null}
                  </div> */}
                  <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Vehicle Pictures:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id={`vehicle-pictures`}
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantVehiclePicture?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantVehiclePicture.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1 + tenantVehiclePicture.length,
                                    url:imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...tenantVehiclePicture];
                                    pictureLists = [...pictureLists, ...newImages];
                                    setTenantVehiclePicture(pictureLists);
                                    let tenantCopy = [...tenantVehiclePicture];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        order_number: picObj.order_number,
                                        is_cover: picObj.is_cover,
                                      })
                                    );
                                    setTenantVehicle({ ...tenantVehicle, pictures: tenantCopy?.pictures });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantVehiclePicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div>
              {tenantVehiclePicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantVehiclePicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantVehiclePicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantVehicle.pictures = pictureLists;
                                setTenantVehiclePicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantVehiclePicture({
                                  ...tenantVehicle,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Vehicle Files:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id={`vehicle-files`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantVehicleFiles?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantVehicleFiles.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    // order_number: maxOrderNumber + i + 1 + tenantVehicleFiles.length,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...tenantVehicleFiles];
                                    fileLists = [...fileLists, ...newImages];
                                    setTenantVehicleFiles(fileLists);
                                    let tenantCopy = [...tenantVehicleFiles];
                                    tenantCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        // order_number: picObj.order_number,
                                      })
                                    );
                                    setTenantVehicle({ ...tenantVehicle, files: tenantCopy?.files });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantVehicleFiles?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div>
              {tenantVehicleFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantVehicleFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantVehicleFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantVehicle.files = fileLists;
                                setTenantVehicleFiles(fileLists);
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  {/* <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Vehicle Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            
                            editSpacesData();
                          } else {
                            
                              bulkData();
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>
              :null}
              
              
<hr />
          {tenantVehicles?.length > 0 ?<>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantVehicles}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions></>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
         
    </>
  )
}
