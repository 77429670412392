import { createSlice } from "@reduxjs/toolkit";
import { showProperty } from "./propertiesThunk";
const ShowPropertySlice = createSlice({
  name: "showproperty",
  initialState: {
    status: null,
    showProperty: {},
  },
  extraReducers: {
    [showProperty.pending]: (state) => {
      state.status = "loading";
    },
    [showProperty.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.showProperty = payload;
    },
    [showProperty.rejected]: (state, _error) => {
      state.status = "failed";
      state.showProperty = {};
    },
  },
});

export default ShowPropertySlice.reducer;
