import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import "../../components/button.css"
import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { convertToFormData } from "../../utility/helperFunctions";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
// import { PropertyForm } from "../../components/property/PropertyForm";
import { UnitForm } from "../../components/UnitForm/UnitForm";
import ApiRequest from "../../utility/http";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { FiEdit2 } from "react-icons/fi";
import pdf from "../../Images/pdf.png";
import csv from "../../Images/csv.png";
import UnitTable from "./UnitTable";
import { OwnerHeader } from "../OwnerHeader";
import { Footer } from "../../components/Dashboard/Footer";
export const ShowUnites = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();

  // CONSTANTS

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);

  const { alertList } = useSelector((state) => state.alerts);
const [searchValue,setSearchValue]=useState();
  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);

  
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        select: (data) => data.id // select is the "key" in the data
      },
      {
        Header: "Unit Name",
        select: (data) => data.name
      },
      {
        Header: "Action",
        Cell: (row) => (
          <>
          <Link to={`/edit-property/${row.id}`} style={{textDecoration:"none"}}>
            <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
            </Link>
            <Link to={`/show-property/${row.id}`} style={{textDecoration:"none"}}>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
                <span>Delete</span>
              </button>
            </Link>
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      {
        id: "Maintenance",
        name: "General Supplies",
      },
      {
        id: "Maintenance",
        name: "General Supplies",
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      initialState: { pageIndex: 0 },
    },

    useGlobalFilter,
    usePagination,
    useRowSelect,
    useExportData,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state,
    setGlobalFilter,
    exportData,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize, selectedRowIds } = state;

  console.log("globalFilter", globalFilter);

  //   useEffect(()=>{
  //     setGlobalFilter("this is filter")
  //   },[])
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  const [filterText, setFilterText] = useState('');
  const [propertyId, setPropertyId] = useState(null);
  const [preferenceData,setPreferenceData] = useState();
  const handlePropertyID = (message) => {
    setPropertyId(message)
    };
    const addPereferences = async()=>{
      const response = await ApiRequest.post('/public/api/auth/table-preference',{
        table:'my_units_table',
        data:JSON.stringify(preferenceData)
      })
      if(response.status === 200)
      {
        // setPreferenceData(response?.data?.data)
        getPreferenceData()
      }
    }
    const getPreferenceData = async () => {
      const response = await ApiRequest.get('/public/api/auth/get-table-preferences');
      console.log(response);
      if (response.status === 200) {
        console.log("@RESPONSE OF PP",response?.data?.data?.tables_preferences[0]?.my_units_table);
        const data = JSON.parse(response?.data?.data?.tables_preferences[0]?.my_units_table);
        // const dataString = JSON.parse({data});
        setPreferenceData(data);
      }
    }
    useEffect(() => {
      getPreferenceData()
    }, [])
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/table.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Units" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>
          {/* search wraper  */}
          <section className="search-wraper">
            <div className="row">
              <div className="col-xl-8 order-lg-2">
                <div className="search-right-side-wrap">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="serch-bar-side">
                    <div className="form-group search">
                      <input
                        type="search"
                        name
                        id
                        value={filterText}
                        placeholder="Type here to Search..."
                        onChange={(e)=>{
                          setFilterText(e.target.value);
                        }}
                        
                      />
                      <i className="fas fa-search" />
                    </div>
                  </div>
                  <button type="button" class="bg-transparent border-none md:w-6 p-0 mx-2" data-bs-toggle="modal"
                  data-bs-target={`#UnitTable`} title="Preferences">
    
                  <img src='/assets/svgs/system-preferences.svg' />
                  
                </button>
                <div
                  class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                  id={`UnitTable`}
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="UnitTableLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5
                          class="text-xl font-medium leading-normal text-gray-800"
                          id="exampleModalLabel"
                        >
                          Preferences
                        </h5>
                        <button
                          type="button"
                          class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                          data-bs-dismiss="modal"
                          aria-label="Save"
                        ></button>
                      </div>
                      <div class="modal-body relative p-4">
                        <div className="h4 text-center">Show/Hide Columns</div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`system_id`}
                              type="checkbox"
                              checked={preferenceData?.system_id === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  system_id: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="system_id">
                              System ID
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`name`}
                              type="checkbox"
                              checked={preferenceData?.name === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  name: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="name">
                              Unit Name
                            </label>
                          </div>
                          
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`type`}
                              type="checkbox"
                              checked={preferenceData?.type === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  type: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="type">
                              Type
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`status`}
                              type="checkbox"
                              checked={preferenceData?.status === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  status: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="status">
                              Status
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`issue`}
                              type="checkbox"
                              checked={preferenceData?.issue === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  issue: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="issue">
                              Issues
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`last`}
                              type="checkbox"
                              checked={preferenceData?.last === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  last: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="last">
                              Tenant Last
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`first`}
                              type="checkbox"
                              checked={preferenceData?.first === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  first: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="first">
                              Tenant First
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`monthly_rent`}
                              type="checkbox"
                              checked={preferenceData?.monthly_rent === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  monthly_rent: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="monthly_rent">
                              Monthly Rent
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`lease_start`}
                              type="checkbox"
                              checked={preferenceData?.lease_start === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  lease_start: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="lease_start">
                              Lease Start
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`lease_expiry`}
                              type="checkbox"
                              checked={preferenceData?.lease_expiry === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  lease_expiry: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="lease_expiry">
                              Lease Expiry
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                         <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`balance`}
                              type="checkbox"
                              checked={preferenceData?.balance === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  balance: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="balance">
                              Balance
                            </label>
                          </div>
                         <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`stages`}
                              type="checkbox"
                              checked={preferenceData?.stages === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  stages: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="stages">
                              Stages
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                         <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`last_inspection`}
                              type="checkbox"
                              checked={preferenceData?.last_inspection === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  last_inspection: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="last_inspection">
                              Last inspection
                            </label>
                          </div>
                        </div>
                        </div>
                      <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                          type="button"
                          onClick={()=>{
                            addPereferences();
                          }}
                          class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button>
                        {/* <button
                          type="button"
                          class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          // onClick={bulkData}
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {" "}
                </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-4 order-lg-1">
                <div
                  className={`welcome-wrap ${
                    showPropertyDropdown ? "z-0" : ""
                  }`}
                >
                  <div className="welcome-inner flex align-items-center">
                    <img
                className="logo w-12 mr-5"
                src="/assets/svgs/unit.svg"
                alt=""
                /> 
                <h4>
                <span>Units</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* filert table */}
          <div className="flex justify-between items-center mb-3 ">
            <div></div>
            {/* <div className="flex justify-between items-center gap-2">
              <button
                onClick={() => {
                  exportData("csv", true);
                }}
              >
                <img src={csv} alt="" className="w-20 h-15 object-cover" />
              </button>
              <button
                onClick={() => {
                  exportData("pdf", true);
                }}
              > */}
                {/* <img src={pdf} alt="" className="w-20 h-15 object-cover" /> */}
              {/* </button>{" "} */}
              {/* <div className="w-full flex justify-start items-center gap-2">
                <label className="input-label w-100 text-gray-700 w-auto">
                  Show:
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  //   id="hoa-state"
                  value={globalFilter || " "}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                >
                      <option value=" ">All</option>
                  <option value="Maintenance">Maintenance</option>
                
                  <option value="Expense">Expense</option>
                  <option value="dsfdsf">dsfdsf</option>
                </select>
              </div> */}
              {/* <div className="w-full flex justify-start items-center gap-2">
                <label className="input-label w-100 text-gray-700 w-auto">
                  Filter:
                </label>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  //   id="hoa-state"
                  value={globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                >
                  <option value="Maintenance">Maintenance</option>
                  <option value="Expense">Expense</option>
                  <option value="dsfdsf">dsfdsf</option>
                </select>
              </div> */}
               {/* <div className="w-full flex justify-start items-center gap-2">
               <label className="input-label w-100 text-gray-700 w-auto">
                            Search:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} */}
                            {/* // value="Autogenrated"
                            // placeholder="Amount"
                          /> */}
                          {/* <button className="bg-gray-200 py-1 px-2 rounded" onClick={()=>{
                            setGlobalFilter(()=>searchValue)
                          }}>Go</button>
                        </div> */}
                
                      
            {/* </div> */}
            <div className="flex justify-between items-center gap-2">
            <Link to={"/add-units"}>
                <button className="btn2">
                <i className="fa fa-plus"></i> Add Units
                </button>
            </Link>
              </div>
          </div>
                  <UnitTable filterText={filterText} data={propertyId} Data={preferenceData}/>
          {/* <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-x-auto">
                </div>
              </div>
            </div>
            <div className="pagination flex justify-end items-center mt-4">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {">>"}
              </button>{" "}
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                {" "}
                Go to page:{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div> */}

          <div className="bottom-gap" />
        </div>

        {showFooter?<Footer/>:null}
      </div>
    </div>
  );
};
