import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { setUserProfile } from "../../store/auth/authThunk";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import useAlerts from "../utility/alerts";
import { Footer } from "../components/Dashboard/Footer"
import {ProfileSelect} from "../components/Dashboard/ProfileSelect";
import { checkAuthentication } from "../utility/auth";
import { SideNavBar } from "../components/SideNavBar";
import ApiRequest from "../utility/http";

export const OwnerHeader = ({setPropertyId,styling}) => {

  const [propertyOptions,setPropertyOptions] = useState([]);
    const authenticated = checkAuthentication();

	////////////// HOOKS //////////////

	// const { pathname, hash, state, key, search } = useLocation();
	const dispatch = useDispatch();
	const Alert = useAlerts();
	const navigate = useNavigate();

	const { alertList } = useSelector((state) => state.alerts);

	////////////// CONSTANTS //////////////

	const staffOptions = ['Overview (All)', 'White Oak (Property Manager)']
	// const propertyOptions = ['Overview (All)', 'New Heights', 'Riverbridge']
  
  const properties = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-my-properties')
    if(response.status == 200)
    {
      setPropertyOptions(response?.data?.data);
    }
  }

  useEffect(() => {
    properties();
  }, [])
  

	////////////// STATES //////////////

	let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
	let [propertyName, setPropertyName] = useState('Select')
	// let [propertyId, setPropertyId] = useState(null)

  console.log("@PROPERTYNAME",propertyName)

	////////////// HANDLERS //////////////

	const handleContentScroll = (e) => {
		// let show =
		// 	e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
		// if (show) setShowFooter(() => (showFooter = show));
		// else setShowFooter(() => (showFooter = false));
	};
	const handePropertyDropdown = () => {
		setShowPropertyDropdown(!showPropertyDropdown)
	}
  const handlePropertyName = (e) => {
    const dataId = e.target.getAttribute('data-id');
    setPropertyName(e.target.innerHTML);
    setPropertyId(dataId);
    localStorage.setItem("Property ID",dataId);
    localStorage.setItem("Property Name",e.target.innerHTML);
  }
  

	////////////// UseEffect Hooks //////////////

	useEffect(() => {
		const jquery = document.createElement("script");
		jquery.src = "/admin/js/jquery-3.6.0-min.js";
		jquery.async = true;
		document.body.appendChild(jquery);
		const bootstrap = document.createElement("script");
		bootstrap.src = "/admin/js/bootstrap.min.js";
		bootstrap.async = true;
		document.body.appendChild(bootstrap);
		const main = document.createElement("script");
		main.src = "/assets/js/main.js";
		main.async = true;
		document.body.appendChild(main);
		return () => {
			document.body.removeChild(jquery);
			document.body.removeChild(bootstrap);
			document.body.removeChild(main);
		};
	}, []);

	useEffect(() => {
		if (!authenticated) navigate("/login");
		else {
			// dispatch(setUserProfile());
		}
	}, []);

	// useEffect(() => {
	// 	if (alertList?.length) {
	// 		alertList.map((alertEl) => {
	// 			Alert.show(alertEl);
	// 			Alert.remove(alertEl);
	// 		});
	// 	}
	// }, [alertList]);
  const tenantLogout=()=>{
    const accessToken = localStorage.getItem('old_token')
    const Name = localStorage.getItem('OldName');
    const Role = localStorage.getItem('OldRole');
    const RoleName = localStorage.getItem('OldRoleName');
    const url = localStorage.getItem('current_url');
    localStorage.setItem('access_token',accessToken);
    localStorage.setItem('Name',Name);
    localStorage.setItem('Role',Role);
    localStorage.setItem('Role Name',RoleName);
    localStorage.removeItem('old_token')
    localStorage.removeItem('OldName')
    localStorage.removeItem('OldRole')
    localStorage.removeItem('OldRoleName')
    localStorage.removeItem('redirect_url')
    localStorage.removeItem('current_url')
    window.location.href = url;
  }
  return (
    <div style={styling}>

{localStorage.getItem('old_token') ? (
  <div className="flex items-center justify-between bg-yellow-400 p-2 rounded-lg mt-2">
    <img
      src="/assets/img/users-impersonate.png"
      className="h-8 pr-2 object-contain"
      alt="Logo"
    />
    <div className="text-lg font-bold">You are Currently Impersonating {localStorage.getItem('Name')}</div>
    <button
      className="btn2 ml-2 bg-red-600"
      onClick={() => {
        tenantLogout();
      }}
    >
      End
    </button>
  </div>
) : null}

      <section className="header-bar" id="top">
            <div className="row align-items-center">
              <div className="col-xl-5 col-md-5 col-5">
                <div className="property-item">
                  <div className="property-img">
                    <img src="/assets/img/property.png" alt="" />
                  </div>
                  <div
                    className="property-select p-2 w-100 account-switcher-dropdown"
                    onMouseEnter={handePropertyDropdown}
                    onMouseLeave={handePropertyDropdown}
                    id="scrollbar-style"
                  >
                    <span className="property-switcher"> {localStorage.getItem("Property Name") ? localStorage.getItem("Property Name") : localStorage.getItem("Name")} </span>
                    <div
                      className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-90 bg-white overflow-y-auto overflow-x-hidden account-switcher-dropdown ${
                        showPropertyDropdown ? "" : "hidden"
                      }`}
                      id="style-14"
                    >
                      <div className="ml-2 my-3 font-bold property-switcher rounded-t py-1 px-4">
                        <span>Properties: </span>
                      </div>
                      {propertyOptions.map((v, i) => (
                        <div key={i} className="ml-5">
                          <span
                            onClick={handlePropertyName}
                            className="rounded-t py-1 px-4 hover:bg-gray-400 block whitespace-no-wrap text-decoration-none cursor-pointer"
                            data-id={v.id}
                          >
                            {v.name}
                          </span>
                        </div>
                      ))}
                      <div className="ml-2 my-3 font-bold property-switcher rounded-t py-1 px-4">
                        <span>Staff: </span>
                      </div>
                      {staffOptions.map((v, i) => (
                        <div key={i} className="ml-5">
                          <span
                            // onClick={handlePropertyName}
                            className="rounded-t py-1 px-4 hover:bg-gray-400 block whitespace-no-wrap text-decoration-none cursor-pointer"
                          >
                            {v}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                    <div class="property-logo p-2 ml-2 w-1/4 border">
                  <Link to={'/property'}>
                      <img src="/assets/svgs/property.png" alt=""/>
                  </Link>
                    </div>
                </div>
                <div className="mobile-logo">
                  <img
                    className="img-fluid logo-light"
                    src="/assets/img/logo-light.png"
                    alt=""
                  />
                  <img
                    className="img-fluid logo-dark"
                    src="/assets/img/logo-dark.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xl-7 col-md-7 col-7">
                <div className="header-bar-right items-start">
                  <ul className="header-icons">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/assets/svgs/system-message-l.svg"
                          className="p-2"
                          alt=""
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink1"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/assets/svgs/system-dashboard-l.svg"
                          className="p-2"
                          alt=""
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink2"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink3"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="/assets/svgs/system-notifications-l.svg"
                          className="p-2"
                          alt=""
                        />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink3"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                  </ul>
                  <ProfileSelect/>
                </div>
              </div>
            </div>
          </section>
    </div>
  )
}
