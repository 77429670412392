import React, { useEffect, useRef } from 'react';
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/material_red.css';

export const DatePicker = ({ value, onChange }) => {
  const inputRef = useRef(null);
  const instanceRef = useRef(null);

  useEffect(() => {
    instanceRef.current = Flatpickr(inputRef.current, {
      dateFormat: 'Y-m-d', // Specify the desired date format here
      onChange: selectedDates => {
        if (typeof onChange === 'function') {
          onChange(selectedDates[0]);
        }
      }
    });

    return () => {
      if (instanceRef.current) {
        instanceRef.current.destroy();
      }
    };
  }, [onChange]);

  useEffect(() => {
    if (instanceRef.current && value) {
      instanceRef.current.setDate(value, false);
    }
  }, [value]);

  return (
    <input
      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      ref={inputRef}
      type="text"
      placeholder="Select a date"
    />
  );
};
