import React from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const LeaseRentForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-lease-rent-form"
        // onSubmit={formSubmitHandler}
      >
        {/* <div className="md:items-center w-full mt-4">
          <div className="flex">
            <img
              className="logo w-12 mr-5"
              src="/assets/svgs/lease-rent-increase-decrease.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Increase/Decrease Rent:
            </label>
          </div>
        </div> */}
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-3">
              <div className="flex items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Change:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="time"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="flex items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    ______:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    // placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="flex items-center w-1/2">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Starting Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="time"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Reason for Change:
                </label>
              </div>
              <div className="md:w-3/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`employment-company-name`}
                  type="text"
                  // placeholder="J Height"
                  // value={tenantEmployment?.company_name}
                  // onChange={(e) => {
                  //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                  // }}
                />
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Message:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.dln_id_issue_notes}
                // onChange={(e) => {
                //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                //   setEditorData({
                //     ...editorData,
                //     dln_id_issue_notes: cleanText,
                //   });
                // }}
                />
              </div>
            </div>
            <div className="md:flex justify-content-center mt-3">
              <div className="md:w-auto">
                <button className="btn btn3">Add Signature</button>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-auto">
                <button className="btn btn3">Update PDF File(s)</button>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="w-1/3">
              <div className="md:w-auto">
                <button className="btn btn3">Print Individual Notices</button>
              </div>
              </div>
              <div className="w-1/3">
              <div className="md:w-auto">
                <button className="btn btn3">Preview</button>
              </div>
              </div>
              <div className="w-1/3">
              <div className="md:w-auto">
                <button className="btn btn3">Publish</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
