import { createSlice } from "@reduxjs/toolkit";
import { userRolesThunk } from "./Thunk";
const userRolesSlice = createSlice({
  name: "userRoles",
  initialState: {
    status: null,
    Roles: {},
  },
  extraReducers: {
    [userRolesThunk.pending]: (state) => {
      state.status = "loading";
    },
    [userRolesThunk.fulfilled]: (state, { payload }) => {
      console.log("payload 14", payload);
      state.status = "success";
      state.Roles = payload;
    },
    [userRolesThunk.rejected]: (state, _error) => {
      state.status = "failed";
      state.Roles = {};
    },
  },
});

export default userRolesSlice.reducer;
