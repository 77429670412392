import { createSlice } from "@reduxjs/toolkit";
import { adminSuffix } from "./adminThunk";
const suffixSlice = createSlice({
  name: "adminSuffix",
  initialState: {
    status: null,
    Suffixes: {},

  },
  extraReducers: {
    [adminSuffix.pending]: (state) => {
      state.status = "loading";
    },
    [adminSuffix.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.Suffixes = payload;
    },
    [adminSuffix.rejected]: (state, _error) => {
      state.status = "failed";
      state.Suffixes = {};
    },

  

    
  },
});

export default suffixSlice.reducer;
