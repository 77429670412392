import React from 'react'

export const ContextMenu = ({ position, onClose, onDownload,onSharing, onView, onRename, onDelete }) => {
    const { x, y } = position;
  
    const handleContextMenuClick = (event) => {
      event.stopPropagation();
    };
  
    const handleMenuOptionClick = (action) => {
      onClose();
      switch (action) {
        case "sharing":
          onSharing();
          break;
        case "download":
          onDownload();
          break;
        case "view":
          onView();
          break;
        case "rename":
          onRename();
          break;
        case "delete":
          onDelete();
          break;
        default:
          break;
      }
    };
  
    return (
      <div
        className="context-menu"
        style={{ top: y, left: x }}
        onClick={handleContextMenuClick}
      >
        <ul>
          <li onClick={() => handleMenuOptionClick("sharing")}>View/Edit Sharing</li>
          <li onClick={() => handleMenuOptionClick("download")}>Download</li>
          <li onClick={() => handleMenuOptionClick("view")}>View</li>
          <li onClick={() => handleMenuOptionClick("rename")}>Rename</li>
          <li onClick={() => handleMenuOptionClick("delete")}>Delete</li>
        </ul>
      </div>
    );
  };