import { createSlice } from "@reduxjs/toolkit";
import {getMoveOutReasons} from "./Thunk";
const MoveOutReasonsSlice = createSlice({
  name: "moveout",
  initialState: {
    status: null,
    moveOutReasons: {},
  },
  extraReducers: {
    [getMoveOutReasons.pending]: (state) => {
      state.status = "loading";
    },
    [getMoveOutReasons.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.moveOutReasons = payload;
    },
    [getMoveOutReasons.rejected]: (state, _error) => {
      state.status = "failed";
      state.moveOutReasons = {};
    }
  },
});

export default MoveOutReasonsSlice.reducer;
