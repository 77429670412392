import { ThreeDots } from 'react-loader-spinner'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../store/user/thunk";
import useAlerts from "../../utility/alerts";
import { useNavigate } from "react-router-dom";
import { checkAuthentication } from "../../utility/auth"
import { setUserProfile } from "../../store/auth/authThunk";
import ApiRequest from '../../utility/http';
export const ProcessingPage = () => {
    const authenticated = checkAuthentication();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Alert = useAlerts();
    const [Role,setRole] = useState()
    const { alertList } = useSelector((state) => state.alerts);
    const { status } = useSelector((state) => state.auth);
    const userP = useSelector((state) => {
      return state;
    });
    // console.log("userP", userP);
    const userStatus = useSelector((state) => {
      return state?.userProfile?.status;
    });
    useEffect(() => {
      getUser();

    }, [])
    
    const getUser = async () => {
          const res  = await ApiRequest.get('/public/api/auth/get-all-roles');
          const response = await dispatch(userProfile());
          setRole(response?.payload?.assigned_roles[0]?.role_id)
          if(response.meta.requestStatus == "fulfilled")
          {
            console.log(response)
            // Role = response?.payload?.assigned_roles[0]?.role_id
            const roleArray = []
            res?.data?.data?.roles.map((row)=>{
              response?.payload?.assigned_roles?.map((role)=>{
                if(row?.id == role?.role_id)
                {
                  if (!roleArray.includes(row?.role)) {
                    roleArray.push(row?.role);
                  }
                }
              })
            })
            localStorage.setItem("Role Name",roleArray);
            setRole(response?.payload?.assigned_roles[0]?.role_id)
            const array = [];
            response?.payload?.assigned_roles?.forEach((role) => {
              const roleId = role?.role_id;
              if (!array.includes(roleId)) {
                array.push(roleId);
              }
            });
            localStorage.setItem("Role", array);
            localStorage.setItem("MultiRole", array?.length > 1 ? "Yes":"No");
            localStorage.setItem("Name",response?.payload?.firstname+" "+response?.payload?.lastname);
            if (authenticated){
              console.log("Authenticated",authenticated)
            if(response?.payload?.assigned_roles[0]?.role_id == 1)
            {
              navigate("/admin")
              await Alert.addSuccess("Welcome To RentBlaze! Admin");
              }  // if admin
            if(response?.payload?.assigned_roles[0]?.role_id == 2){
              navigate("/")
              await Alert.addSuccess("Welcome To RentBlaze! Owner");
            }
            if(response?.payload?.assigned_roles[0]?.role_id == 5){
              navigate("/tenantDashboard")
              await Alert.addSuccess("Welcome To RentBlaze! Tenant");
            }
          } 
            // console.log("responses",response)
            
          }
        };
      
  return (
    <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
    <span>Authentication</span>
    <ThreeDots 
height="80" 
width="80" 
radius="9"
color="#4fa94d" 
ariaLabel="three-dots-loading"
wrapperStyle={{}}
wrapperClassName=""
visible={true}
/>
</div>
  )
}
