import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { checkAuthentication } from "../../utility/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserProfile } from "../../store/auth/authThunk";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { Footer } from "../../components/Dashboard/Footer";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import pdf from "../../Images/pdf.png"
import csv from "../../Images/csv.png"
import "../../components/button.css"


import "./style.css";
import Table from "../../components/DataTable/PropertyDataTable";
import { OwnerHeader } from "../OwnerHeader";
import ApiRequest from "../../utility/http";
export const PropertyTable = ({ cookies }) => {
  const authenticated = checkAuthentication();


  const [preferenceData,setPreferenceData] = useState();
  console.log("@PREFERENCE",preferenceData);
  const initialTableData = {
    system_id:1,
    name:1,
    address:1,
    city:1,
    state:1,
    status:1,
    zip_code:1,
    manager:1,
    total_units:1,
    rented_units:1,
    unrented_units:1,
    issues:1,
  }
  // useEffect(() => {
  //   setPreferenceData(initialTableData)
  // }, [])
  
  ////////////// HOOKS //////////////

  // const { pathname, hash, state, key, search } = useLocation();
  const dispatch = useDispatch();
  const Alert = useAlerts();
  const navigate = useNavigate();

  const { alertList } = useSelector((state) => state.alerts);

  ////////////// CONSTANTS //////////////

  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];

  ////////////// STATES //////////////

  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");

  ////////////// HANDLERS //////////////
  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };

  ////////////// UseEffect Hooks //////////////

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  useEffect(() => {
    if (!authenticated) navigate("/login");
    else {
      // dispatch(setUserProfile());
    }
  }, []);

  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  const [filterText, setFilterText] = useState('');
  const addPereferences = async()=>{
    const response = await ApiRequest.post('/public/api/auth/table-preference',{
      table:'my_properties_table',
      data:JSON.stringify(preferenceData)
    })
    if(response.status === 200)
    {
      // setPreferenceData(response?.data?.data)
      getPreferenceData()
    }
  }
  const getPreferenceData = async () => {
    const response = await ApiRequest.get('/public/api/auth/get-table-preferences');
    console.log(response);
    if (response.status === 200) {
      console.log("@RESPONSE OF PP",response?.data?.data?.tables_preferences[0]?.my_properties_table);
      const data = JSON.parse(response?.data?.data?.tables_preferences[0]?.my_properties_table);
      // const dataString = JSON.parse({data});
      setPreferenceData(data);
    }
  }
  useEffect(() => {
    getPreferenceData()
  }, [])
  
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/table.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Properties" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>
          {/* search wraper  */}
          <section className="search-wraper">
            <div className="row">
              <div className="col-xl-8 order-lg-2">
                <div className="search-right-side-wrap">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="serch-bar-side">
                    <div className="form-group search">
                      <input
                        type="search"
                        name
                        id
                        value={filterText}
                        placeholder="Type here to Search..."
                        onChange={(e)=>{
                          setFilterText(e.target.value);
                        }}
                      />
                      <i className="fas fa-search" />
                    </div>
                  </div>
                  <button type="button" class="bg-transparent border-none md:w-6 p-0 mx-2" data-bs-toggle="modal"
                  data-bs-target={`#PropertyTable`} title="Preferences">
    
                  <img src='/assets/svgs/system-preferences.svg' />
                  
                </button>
                <div
                  class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                  id={`PropertyTable`}
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="PropertyTableLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5
                          class="text-xl font-medium leading-normal text-gray-800"
                          id="exampleModalLabel"
                        >
                          Page Preferences
                        </h5>
                        <button
                          type="button"
                          class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                          data-bs-dismiss="modal"
                          aria-label="Save"
                        ></button>
                      </div>
                      <div class="modal-body relative p-4">
                        <div className="h4 text-center">Show/Hide Columns</div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`system_id`}
                              type="checkbox"
                              checked={preferenceData?.system_id === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  system_id: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="system_id">
                              System ID
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`name`}
                              type="checkbox"
                              checked={preferenceData?.name === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  name: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="name">
                              Property Name
                            </label>
                          </div>
                          
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`address`}
                              type="checkbox"
                              checked={preferenceData?.address === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  address: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="address">
                              Address
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`city`}
                              type="checkbox"
                              checked={preferenceData?.city === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  city: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="city">
                              City
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`state`}
                              type="checkbox"
                              checked={preferenceData?.state === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  state: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="state">
                              State
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`zip_code`}
                              type="checkbox"
                              checked={preferenceData?.zip_code === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  zip_code: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="zip_code">
                              Zip Code
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`manager`}
                              type="checkbox"
                              checked={preferenceData?.manager === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  manager: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="manager">
                              Property Manager
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`total_units`}
                              type="checkbox"
                              checked={preferenceData?.total_units === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  total_units: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="total_units">
                              Total Units
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`rented_unit`}
                              type="checkbox"
                              checked={preferenceData?.rented_units === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  rented_units: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="rented_unit">
                              Rented Units
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`unrented_unit`}
                              type="checkbox"
                              checked={preferenceData?.unrented_units === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  unrented_units: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="unrented_unit">
                              Unrented Units
                            </label>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                         <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`issues`}
                              type="checkbox"
                              checked={preferenceData?.issues === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  issues: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="issues">
                              Issues
                            </label>
                          </div>
                         <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`status`}
                              type="checkbox"
                              checked={preferenceData?.status === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setPreferenceData({
                                  ...preferenceData,
                                  status: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2" htmlFor="status">
                              Status
                            </label>
                          </div>
                        </div>
                        </div>
                      <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                          type="button"
                          onClick={()=>{
                            addPereferences();
                          }}
                          class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button>
                        {/* <button
                          type="button"
                          class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          // onClick={bulkData}
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {" "}
                </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-4 order-lg-1">
                <div
                  className={`welcome-wrap ${
                    showPropertyDropdown ? "z-0" : ""
                  }`}
                >
                  <div className="welcome-inner flex align-items-center">
                    <img
                className="logo w-12 mr-5"
                src="/assets/svgs/property.svg"
                alt=""
                /> 
                <h4>
                <span>Properties</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* order table area  */}
          {/* <div className="actions-wrapper">
            <div className="upgrade-side">
              <p className="active-propertied">3 of 5 Active Properties</p>
              <button className="upgrade-btn">
              <i class="fa-sharp fa-solid fa-up"></i>Upgrade
              </button>
              <p className="total-issues">Total Open Issues: <span  className="text-red-600"> 3</span></p>
            </div>
            <div className="filter-side">
              <div className="dowloader">
                <img src={pdf} alt="" />
              </div>
              <div className="dowloader">
              <img src={csv} alt="" />
              </div>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-regular fa-list mr-2"></i>
                <span>Show</span>
              </button>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-light fa-filter mr-2"></i>
                <span>Filter</span>
              </button>
            </div>
          </div> */}
          {/* <section className="order-table-area">
            <div className="table-responsive">
              <table className="table">
                <thead border-bottom>
                  <tr>
                    <th></th>
                    <th>Order ID</th>
                    <th className="status text-center">Status</th>
                    <th className="sku-qnt text-center">SKU Quantity</th>
                    <th className="item-qnt text-center">Item Quantity</th>
                    <th className="order-value text-center">Order Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first-td">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded checkbtn"
                      />
                    </td>
                    <td className="order-id flex  justify-items-center	">
                      <span class="mt-0.5">#16512</span>
                    </td>
                    <td className="status text-center">
                      <span className="tbl-btn">Approved</span>
                    </td>
                    <td className="sku-qnt text-center">2</td>
                    <td className="item-qnt text-center">350</td>
                    <td className="order-value text-center">$3595.79</td>
                  </tr>
                  <tr>
                    <td className="first-td">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded checkbtn"
                      />
                    </td>
                    <td className="order-id">
                      {" "}
                      <span class="mt-0.5">#16512</span>
                    </td>
                    <td className="status text-center">
                      <span className="tbl-btn btn2">Pending</span>
                    </td>
                    <td className="sku-qnt text-center">2</td>
                    <td className="item-qnt text-center">350</td>
                    <td className="order-value text-center">$3595.79</td>
                  </tr>
                  <tr>
                    <td className="first-td">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded checkbtn"
                      />
                    </td>
                    <td className="order-id">
                      {" "}
                      <span class="mt-0.5">#16512</span>
                    </td>
                    <td className="status text-center">
                      <span className="tbl-btn btn2">Pending</span>
                    </td>
                    <td className="sku-qnt text-center">2</td>
                    <td className="item-qnt text-center">350</td>
                    <td className="order-value text-center">$3595.79</td>
                  </tr>
                  <tr>
                    <td className="first-td">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded checkbtn"
                      />
                    </td>
                    <td className="order-id">
                      {" "}
                      <span class="mt-0.5">#16512</span>
                    </td>
                    <td className="status text-center">
                      <span className="tbl-btn">Approved</span>
                    </td>
                    <td className="sku-qnt text-center">2</td>
                    <td className="item-qnt text-center">350</td>
                    <td className="order-value text-center">$3595.79</td>
                  </tr>
                  <tr>
                    <td className="first-td">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded checkbtn"
                      />
                    </td>
                    <td className="order-id">
                      {" "}
                      <span class="mt-0.5">#16512</span>
                    </td>
                    <td className="status text-center">
                      <span className="tbl-btn btn2">Pending</span>
                    </td>
                    <td className="sku-qnt text-center">2</td>
                    <td className="item-qnt text-center">350</td>
                    <td className="order-value text-center">$3595.79</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section> */}
          <div className="flex flex-row-reverse justify-between items-center mb-3 ">
              <div className="flex justify-between items-center gap-4">
                <Link to={"/properties/add"}>
                  <button className="btn2">
                    <i class="fa fa-plus"></i> Add Property
                    </button>
                </Link>
                  </div>
          </div>
          <Table filterText={filterText} data={preferenceData}/>
          
            {/* <div class="flex-none ">
              {" "}
              <button class="bg-gray-300 px-4 flex justify-center align-items-center rounded text-black-800">
                <i class="fa-solid fa-bolt text-2xl	 mr-4"></i>
                <span>Bulk Actions</span>
              </button>
            </div> */}

            
          
        
          <div className="bottom-gap" />
        </div>
        {showFooter ? <Footer/> : null}
      </div>
    </div>
  );
};
