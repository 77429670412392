import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Swal from "sweetalert2";
import { userProfile } from "../../store/user/thunk";
// import TenantPic from "/assets/svgs/users-tenant.svg";
// import ProspectPic from "/assets/svgs/users-prospect.svg";


const TenantTable = ({filterText,data}) => {
    // console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
const navigate = useNavigate();
const [unit,setUnit] = useState([]);
const [tenant,setTenant] = useState([]);
const [prospect,setProspect] = useState([]);
const [loader,setLoader]= useState();
const [selectFilter,setSelectFilter] = useState();
const [totalTenant,setTotalTenant] = useState();
const [bulkAction , setBulkAction] = useState({
  bulk_action:""
});
const [preferenceData,setPreferenceData] = useState();
const showUnit = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-tenants"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        // setUnit(response?.data?.data)
        setUnit([...response?.data?.data?.tenants, ...response?.data?.data?.prospects])
        setProspect(response?.data?.data?.prospects)
        setTenant(response?.data?.data?.tenants)
        setTotalTenant([...response?.data?.data?.tenants, ...response?.data?.data?.prospects])
        console.log("Response table",response);
    }
}
const showActiveUnit = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-tenants"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        //  setUnit(response?.data?.data?.filter(obj=>obj.is_active == 1));
        setUnit(response?.data?.data?.tenants.filter(obj => obj.is_active === 1));
        // setProspect(response?.data?.data?.prospects.filter(obj=>obj.is_active == 1))
        // setTenant(response?.data?.data?.tenants.filter(obj=>obj.is_active == 1))
        console.log("Response table",response);
    }
}
const showInActiveUnit = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-tenants"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        //  setUnit(response?.data?.data?.filter(obj=>obj.is_active == 0));
        setUnit(response?.data?.data?.tenants.filter(obj => obj.is_active === 0));
        // setUnit(prevState => prevState.filter(obj => obj.is_active === 0));
        // setUnit([...response?.data?.data?.tenants, ...response?.data?.data?.prospects])
        //  setProspect(response?.data?.data?.prospects.filter(obj=>obj.is_active == 0))
        // setTenant(response?.data?.data?.tenants.filter(obj=>obj.is_active == 0))
        console.log("Response table",response);
    }
}
const allTenants = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-tenants"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        //  setUnit(response?.data?.data?.filter(obj=>obj.is_active == 0));
        setUnit(response?.data?.data?.tenants);
        // setUnit(prevState => prevState.filter(obj => obj.is_active === 0));
        // setUnit([...response?.data?.data?.tenants, ...response?.data?.data?.prospects])
        //  setProspect(response?.data?.data?.prospects.filter(obj=>obj.is_active == 0))
        // setTenant(response?.data?.data?.tenants.filter(obj=>obj.is_active == 0))
        console.log("Response table",response);
    }
}
const allProspects = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-tenants"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        //  setUnit(response?.data?.data?.filter(obj=>obj.is_active == 0));
        setUnit(response?.data?.data?.prospects);
        // setUnit(prevState => prevState.filter(obj => obj.is_active === 0));
        // setUnit([...response?.data?.data?.tenants, ...response?.data?.data?.prospects])
        //  setProspect(response?.data?.data?.prospects.filter(obj=>obj.is_active == 0))
        // setTenant(response?.data?.data?.tenants.filter(obj=>obj.is_active == 0))
        console.log("Response table",response);
    }
}
const bulkMakeInActive = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Inactivate</strong> the Prospect(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, Inactivate the Unit
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  const filteredArrayId = arrayId.filter(id => selectedRows.find(row => row.id === id)?.user_type_id === 7);
  console.log("ARRAYID",filteredArrayId);
  if(filteredArrayId?.length == 0){
    Swal.fire({
      title: 'Error!',
      html: 'This action is only available to <strong>Prospects</strong>, not <strong>Tenants</strong>',
      icon: 'error',
      confirmButtonText: 'OK',
    });
    return;
  }
  if (isConfirmed) {
    setLoader(undefined);
    try {
      // const ArrayId = [];
      // ArrayId.push(id);
      const response = await ApiRequest.post('/public/api/auth/mark-users-as-inactive',{
        ids:filteredArrayId,
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Prospect(s) have been <strong>Inactivated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Inactivating</strong> the Prospect(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const bulkMakeActive = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Activate</strong> the Prospect(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, activate the Unit
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  const filteredArrayId = arrayId.filter(id => selectedRows.find(row => row.id === id)?.user_type_id === 7);
  if(filteredArrayId?.length == 0){
    Swal.fire({
      title: 'Error!',
      html: 'This action is only available to <strong>Prospects</strong>, not <strong>Tenants</strong>',
      icon: 'error',
      confirmButtonText: 'OK',
    });
    return;
  }
  if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('/public/api/auth/mark-users-as-active',{
        ids:filteredArrayId          
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Prospect(s) have been <strong>Activated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Activating</strong> the Prospect(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const bulkMakeMerge = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Merge</strong> the Prospect(s)/Tenant(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, Inactivate the Unit
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  // const filteredArrayId = arrayId.filter(id => selectedRows.find(row => row.id === id)?.user_type_id === 7);

// Check if there are any ids with user_type_id equal to 5
const hasProspects = arrayId.some(id => selectedRows.find(row => row.id === id)?.user_type_id === 7);
const hasProspectsArray = arrayId.filter(id => selectedRows.find(row => row.id === id)?.user_type_id === 7);

// Check if there are any ids with user_type_id equal to 7
const hasTenants = arrayId.some(id => selectedRows.find(row => row.id === id)?.user_type_id === 5);
const hasTenantsArray = arrayId.filter(id => selectedRows.find(row => row.id === id)?.user_type_id === 5);

// If there are no prospects or tenants, show an error message and return
console.warn('@hasTenant',hasTenants)
console.warn('@hasProspect',hasProspects)
if (!hasProspects && !hasTenants) {
  Swal.fire({
    title: 'Error!',
    html: 'No <strong>Prospects</strong> or <strong>Tenants</strong> selected.',
    icon: 'error',
    confirmButtonText: 'OK',
  });
  return;
}

// If there are only prospects or tenants, show an error message and return
if ((hasProspects && !hasTenants) || (!hasProspects && hasTenants)) {
  Swal.fire({
    title: 'Error!',
    html: 'You need to select both <strong>Prospect</strong> and <strong>Tenant</strong> to merge them together.',
    icon: 'error',
    confirmButtonText: 'OK',
  });
  return;
}
  // console.log("ARRAYID",filteredArrayId);
  // if(filteredArrayId?.length == 0){
  //   Swal.fire({
  //     title: 'Error!',
  //     html: 'This action is only available to Prospects, not Tenants',
  //     icon: 'error',
  //     confirmButtonText: 'OK',
  //   });
  //   return;
  // }
  if (isConfirmed) {
    setLoader(undefined);
    try {
      // const ArrayId = [];
      // ArrayId.push(id);
      const response = await ApiRequest.post('/public/api/auth/merge-tenant',{
        tenant_id:hasTenantsArray[0],
        prospect_renter_id:hasProspectsArray[0],
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The <strong>Prospect</strong>(s) and <strong>Tenant</strong>(s) have been Merge.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Merging</strong> the <strong>Prospect</strong>(s) and <strong>Tenant</strong>(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const bulkDelete = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Delete</strong> the <strong>Inactive</strong> Tenant(s)/Prospect(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, activate the property
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  const filteredArrayId = arrayId.filter(id => selectedRows.find(row => row.id === id)?.is_active === 0);
  
   console.log('arrayId',filteredArrayId);
  // const commaSeparatedIds = arrayId.join(',');

  if(filteredArrayId?.length < 1)
  {
    Swal.fire({
      title: 'Error!',
      html: 'First <strong>Inactivate</strong> the Tenant(s)/Prospect(s)',
      icon: 'warning',
      confirmButtonText: 'OK',
    });
  }
  if(filteredArrayId?.length > 0){
    if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('/public/api/auth/delete-owners-users',{
        ids:filteredArrayId       
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'Only the <strong>Inactivate</strong> Tenant(s)/Prospect(s) have been <strong>Deleted</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Deleting</strong> the Tenant(s)/Prospect(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }}
};
const deleteTenant = async (id,active) => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Delete</strong> the Tenant/Prospect. Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, activate the property
  const arrayId = []
  // selectedRows?.map((row)=>{
    if(active !== 0)
    {
      arrayId.push(id)
    }
  // })
  //  console.log('arrayId',arrayId);
  // const commaSeparatedIds = arrayId.join(',');

  if(active == 1)
      {
        Swal.fire({
        title: 'Error!',
        html: 'First <strong>Inactivate</strong> This Tenant/Prospect.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  if(active == 0){if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('/public/api/auth/delete-owners-users',{
        ids:arrayId       
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Tenant/Prospect have been <strong>Deleted</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Deleting</strong> the Tenant/Prospect. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }}
};
useEffect(() => {
  showUnit();
  // setUnit([...tenant, ...prospect]);
}, [])
useEffect(() => {
  setPreferenceData(data);
}, [data])
const exportPDF = ()=>{
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Tenants";
  const headers = [["System ID", "Last","First","Email","Date of Birth","Type","Status"]];

  const data = filteredData.map(elt => [elt.system_id, elt.lastname,elt.firstname,elt.email,elt.date_of_birth,elt.user_type,elt.is_active == 1 ? "Active" :"Inactive"])

  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Tenants.pdf")
}
const exportCSV = () => {
  // Get data from data table
  const TotalData = filteredData.map(elt => [elt.system_id, elt.lastname,elt.firstname,elt.email,elt.date_of_birth,elt.user_type,elt.is_active == 1 ? "Active" :"Inactive"])
  // Convert the data to a CSV string

  const headers = ["System ID", "Last","First","Email","Date of Birth","Type","Status"];
  // let content = {
  //   head:headers,
  //   body:TotalData,
  // }
  TotalData.unshift(headers)
  const csv = Papa.unparse(TotalData);
  // Create a blob of the data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  // Create a link to trigger the download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "Tenants.csv");
  link.click();
};
const [selectedRows, setSelectedRows] = useState([]);
const handleRowSelected = (state) => {
  setSelectedRows(state.selectedRows);
};
const bulkExportPDF = ()=>{
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Tenants";
  const headers = [["System ID", "Last","First","Email","Date of Birth","Type","Status"]];

  const data = selectedRows.map(elt => [elt.system_id, elt.lastname,elt.firstname,elt.email,elt.date_of_birth,elt.user_type,elt.is_active == 1 ? "Active" :"Inactive"])

  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Tenants.pdf")
}
const bulkExportCSV = () => {
  // Get data from data table
  const TotalData = selectedRows.map(elt => [elt.system_id, elt.lastname,elt.firstname,elt.email,elt.date_of_birth,elt.user_type,elt.is_active == 1 ? "Active" :"Inactive"])
  // Convert the data to a CSV string

  const headers = ["System ID", "Last","First","Email","Date of Birth","Type","Status"];
  // let content = {
  //   head:headers,
  //   body:TotalData,
  // }
  TotalData.unshift(headers)
  const csv = Papa.unparse(TotalData);
  // Create a blob of the data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  // Create a link to trigger the download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "Tenants.csv");
  link.click();
};
const dispatch = useDispatch();
const impersonation = async (data)=>{
  const response = await ApiRequest.post('/public/api/auth/impersonation', {
    email: data
  });
  if(response?.status == 200)
  {
    const oldAccessToken = localStorage.getItem('access_token');
    const name = localStorage.getItem('Name');
    const roleIDs = localStorage.getItem('Role');
    const role_name = localStorage.getItem('Role Name');
    localStorage.setItem('current_url', window.location.pathname);
    localStorage.setItem('OldName', name);
    localStorage.setItem('OldRole', roleIDs);
    localStorage.setItem('OldRoleName', role_name);
    localStorage.setItem('old_token', oldAccessToken);
    localStorage.setItem('access_token', response?.data?.token);
    window.location.href = '/impersonation';
  }
}
// const impersonation = async(data) => {
  
//   getTenantToken(data);
//   const rolesResponse = await ApiRequest.get('/public/api/auth/get-all-roles');
  
//   if (rolesResponse.status === 200) {
    
    
//     const res2 = await dispatch(userProfile());
    
//     if (res2.meta.requestStatus === "fulfilled") {
//       const roleArray = [];
      
//       rolesResponse?.data?.data?.roles.map((role) => {
//         res2?.payload?.assigned_roles?.map((assignedRole) => {
//           if (role?.id === assignedRole?.role_id) {
//             roleArray.push(role?.role);
//             const name = localStorage.getItem('Name');
//             localStorage.setItem('OldName', name);
//             localStorage.setItem('Name', `${res2?.payload?.firstname} ${res2?.payload?.lastname}`);
//             localStorage.setItem("Role Name", role?.role);
//             localStorage.setItem("Role", role?.id);
//           }
//         });
//       });
//     }
//     console.error('@res',res2);
//     // localStorage.setItem('redirect_url', '/tenantDashboard');
//     // window.location.href = localStorage.getItem('redirect_url');
//   }
// };


// const getUser = async () => {
//   const res  = await ApiRequest.get('/public/api/auth/get-all-roles');
//   const response = await dispatch(userProfile());
//   setRole(response?.payload?.assigned_roles[0]?.role_id)
//   if(response.meta.requestStatus == "fulfilled")
//   {
//     console.log(response)
//     // Role = response?.payload?.assigned_roles[0]?.role_id
//     const roleArray = []
//     res?.data?.data?.roles.map((row)=>{
//       response?.payload?.assigned_roles?.map((role)=>{
//         if(row?.id == role?.role_id)
//         {
//           roleArray.push(row?.role);
//         }
//       })
//     })
//     localStorage.setItem("Role Name",roleArray);
//     setRole(response?.payload?.assigned_roles[0]?.role_id)
//     const array = [];
//     const roles = response?.payload?.assigned_roles?.map((role)=>{
//       array.push(role?.role_id);
//     })
//     localStorage.setItem("Role", array);
//     localStorage.setItem("MultiRole", response?.payload?.assigned_roles.length > 1 ? "Yes":"No");
//     localStorage.setItem("Name",response?.payload?.firstname+" "+response?.payload?.lastname);
//     if (authenticated){
//       console.log("Authenticated",authenticated)
//     if(response?.payload?.assigned_roles[0]?.role_id == 1)
//     {
//       navigate("/admin")
//       await Alert.addSuccess("Welcome To RentBlaze! Admin");
//       }  // if admin
//     if(response?.payload?.assigned_roles[0]?.role_id == 2){
//       navigate("/")
//       await Alert.addSuccess("Welcome To RentBlaze! Owner");
//     }
//     if(response?.payload?.assigned_roles[0]?.role_id == 5){
//       navigate("/tenant-profile")
//       await Alert.addSuccess("Welcome To RentBlaze! Tenant");
//     }
//   } 
//     // console.log("responses",response)
    
//   }
// };
function stringToDate(dateString) {
  // console.warn("dateString",dateString)
  if (dateString === null || dateString === undefined || dateString >= 0)
    return;
  let parts = dateString.split("-");
  let day = parts[0];
  let month = parts[1]; // months are 0-based
  let year = parts[2];
  let date = year + "-" + month + "-" + day;
  // console.warn("UnformatDate",date)
  return date;
}
const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  console.log(age_now);
  return age_now;
};
const calculate_days_until_next_birthday = (dob) => {
          const today = new Date();
          const birthDate = new Date(dob);
        
          // Check if the birthdate is today
          if (
            today.getDate() === birthDate.getDate() &&
            today.getMonth() === birthDate.getMonth()
          ) {
            return <span style={{ color: "#009900" }}>Today</span>
          }
        
          // Calculate the next birthday
          const nextBirthday = new Date(
            today.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );
          if (today > nextBirthday) {
            nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
          }
        
          // Calculate the difference in days
          const timeDiff = nextBirthday.getTime() - today.getTime();
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
        
          // Apply color based on days left
          let color;
          if (daysLeft <= 365 && daysLeft >= 315) {
            color = "#FF0000"
          } else if (daysLeft <= 314 && daysLeft >= 265) {
            color = "#FF9999"
          } else if (daysLeft <= 264 && daysLeft >= 215) {
            color = "#FF8000"
          } else if (daysLeft <= 214 && daysLeft >= 165) {
            color = "#FFB266"
          }
          else if (daysLeft <= 164 && daysLeft >= 115) {
            color = "#FFFF00"
          }
          else if (daysLeft <= 114 && daysLeft >= 65) {
            color = "#FFFF66"
          }
          else if (daysLeft <= 64 && daysLeft >= 5) {
            color = "#33FFFF"
          }
          
           else {
            color = "green";
          }
          if(isNaN(daysLeft))
          {
            return "";
          }
          return (
            <span style={{ color: color }}>
              {daysLeft} {daysLeft === 1 ? "day" : "days"}
            </span>
          );
        };
function ShowFormatDate(data, format) {
  if (data === null || data === undefined)
    return;
  const date = new Date(stringToDate(data));
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
  const year = date.getFullYear();

  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;

  format = format.replace('dd', formattedDay);
  format = format.replace('DD', formattedDay);
  format = format.replace('d', day);
  format = format.replace('D', day);
  format = format.replace('mm', formattedMonth);
  format = format.replace('MM', formattedMonth);
  format = format.replace('m', month);
  format = format.replace('M', month);
  format = format.replace('yyyy', year);
  format = format.replace('YYYY', year);

  return format;
}
  const filteredColumns = [
    preferenceData?.system_id === 1
    ?{
      name: "System ID",
      selector : (data) => data.system_id,
      sortable: true,
      
    }:null,
    preferenceData?.last === 1
    ?{
      name: "Last",
      selector : (data) => data.lastname,
      sortable: true,
    }:null,
    preferenceData?.first === 1
    ?{
      name: "First",
      selector : (data) => data.firstname,
      sortable: true,
    }:null,
    preferenceData?.dob === 1
    ?{
      name: "DOB",
      selector : (data) => {
              const dateOfBirth = stringToDate(data?.date_of_birth);
            
              
                return (<span className='flex flex-column'><span className='text-center'>{dateOfBirth}</span>{calculate_days_until_next_birthday(dateOfBirth)}</span>);
             
    },
      sortable: true,
    }:null,
    preferenceData?.type === 1
    ?{
      name: "Type",
      selector : (data) => data.user_type_id == 5 ? <img src="/assets/svgs/users-tenant.png" title="Tenant" alt="Tenant" className="w-1/4" /> : <img src="/assets/svgs/users-prospect.png" title="Prospect" className="w-1/4" alt="Prospect" />,
      sortable: true,
    }:null,
    preferenceData?.unit === 1
    ?{
      name: "Unit",
      selector : (data) => "Room A7",
      sortable: true,
    }:null,
    preferenceData?.issue === 1
    ?{
      name: "Issues",
      selector : (data) => "1",
      sortable: true, 
    }:null,
    preferenceData?.lease_start === 1
    ?{
      name: "Lease Start",
      selector : (data) => "03/23/2020",
      sortable: true,
    }:null,
    preferenceData?.lease_expiry === 1
    ?{
      name: "Lease Expiry",
      selector : (data) => "MONTHLY",
      sortable: true,
    }:null,
    preferenceData?.monthly_rent === 1
    ?{
      name: "Monthly Rent",
      selector : (data) => "$1600",
      sortable: true,
    }:null,
    preferenceData?.balance === 1
    ?{
      name: "Balance",
      selector : (data) => "+$42.56",
      sortable: true,
    }:null,
    preferenceData?.email === 1
    ?{
      name: "Email",
      selector : (data) => (<a className="text-black no-underline" href={`mailto:${data.email}`}>{data.email}</a>),
      sortable: true,
    }:null,
    preferenceData?.number === 1
    ?{
      name: "Number",
      selector : (data) => "818-454-4847",
      sortable: true,
    }:null,
    preferenceData?.co_tenant === 1
    ?{
      name: "Co-Tenant(s)",
      selector : (data) => "",
      sortable: true,
    }:null,
    preferenceData?.status === 1
    ?{
      name: "Status",
      selector: (row) => {
        return (
          <>
          {row.is_active == 1 ?
          <span type="button" className="status-active uppercase">

            ACTIVE
            
          </span>
          :
          <span type="button" className="status-inactive uppercase">

            INACTIVE
            
          </span>}
          </>
        );
      },
      sortable: true,
    }:null,
    {
      name: "Options",
      cell: (data) => {
        return (
          <>
               {/* <Link to={`/edit-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
            <button className="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
              <i className="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
            </Link>
            <Link to={`/show-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
              <button className="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i className="fa-regular fa-eye mr-6"></i>
                <span>View</span>
              </button>
            </Link> */}
            {data.user_type_id == 5 ? 
            <div className="flex">
            <Link to={`/show-users-tenant/${data.id}`}>
            
                <button type="button" className="bg-transparent border-none md:w-6  p-0">
    
                    <img src='/assets/img/system-view.png'title="View Tenant" />
                  
                </button>
            </Link>
            <Link to={`/edit-users-tenant/${data.id}`}>

                <button type="button" className="bg-transparent border-none md:w-6 p-0">
    
                    <img src='/assets/img/system-edit.png' title="Edit Tenant"/>
                  
                </button>
            </Link>
                <button type="button" className="bg-transparent border-none md:w-6 p-0 mx-1" onClick={()=>{
                  impersonation(data.email)
                }}>
                  
    
                  <img src='/assets/img/users-impersonate.png' title="Impersonation"/>
                  
                </button>
                <Link to={`/tenant-history/${data.id}`}>

                <button type="button" className="bg-transparent border-none md:w-6 p-0 mx-1">
    
                  <img src='/assets/img/tenant-details-history.png' title="Tenant History"/>
                  
                </button>
                </Link>
               
               

                <button type="button" className="bg-transparent border-none md:w-6 p-0">
    
                  <img src='/assets/img/system-message.png' />
                  
                </button>
               
               

                <button type="button" className="bg-transparent border-none md:w-6 p-0" onClick={()=>{
                  deleteTenant(data.id,data.is_active)
                }}>
    
                  <img src='/assets/img/system-trashcan.png' title="Delete Tenant"/>
                  
                </button>             
               
                 </div>:<div className="flex">
            <Link to={`/show-users-prospect/${data.id}`}>
            
                <button type="button" className="bg-transparent border-none md:w-6  p-0">
    
                    <img src='/assets/img/system-view.png' />
                  
                </button>
            </Link>
            <Link to={`/edit-users-prospect/${data.id}`}>

                <button type="button" className="bg-transparent border-none md:w-6 p-0">
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
            </Link>
                <button type="button" className="bg-transparent border-none md:w-6 p-0 mx-1" onClick={()=>{
                  impersonation(data.email)
                }}>
                  
    
                  <img src='/assets/img/users-impersonate.png' />
                  
                </button>
                <button type="button" className="bg-transparent border-none md:w-6 p-0 mx-1">
    
                  <img src='/assets/img/tenant-details-history.png' />
                  
                </button>
               
               

                <button type="button" className="bg-transparent border-none md:w-6 p-0" onClick={()=>{
                  deleteTenant(data.id,data.is_active)
                }}>
    
                  <img src='/assets/img/system-message.png' />
                  
                </button>
               
               

                <button type="button" className="bg-transparent border-none md:w-6 p-0">
    
                  <img src='/assets/img/system-trashcan.png' />
                  
                </button>             
               
                 </div>}
          </>
        );
      },
    },
  ];  
  const columns = filteredColumns?.filter((column) => column !== null && column.selector !== null);

  
const filteredData = unit?.filter(item =>
  item.firstname.toLowerCase().includes(filterText.toLowerCase()) ||
  item.lastname.toLowerCase().includes(filterText.toLowerCase()) ||
  item.email.toLowerCase().includes(filterText.toLowerCase()) 
);
console.warn('filteredData',filteredData);
const filtration = (data) => {
  // let filter;
  if (data === 0) {
    showInActiveUnit();
  } else if (data === 1) {
    // filter = unit.filter(obj => obj.is_active === 1);
    showActiveUnit();
  }
  else if (data === 2) {
    // filter = unit.filter(obj => obj.is_active === 1);
    allTenants();
  } else if (data === 3) {
    // filter = unit;
    showUnit();
  } 
  else if (data === 4) {
    // filter = unit;
    allProspects();
  } 
  else {
    // filter = unit;
    showUnit();
  }
};
const tableData = {
  columns : columns,
  data : filteredData,
  pagination: true,
  fixedHeader: true,
  fixedHeaderScrollHeight: '500px',
  sortFieldId: 1,
  sortDirection:"asc",
};
 
const countActive = tenant?.map(obj => obj.is_active).filter(isActive => isActive === 1).length;
const countProspectActive = prospect?.map(obj => obj.is_active).filter(isActive => isActive === 1).length;
const countInActive = tenant?.map(obj => obj.is_active).filter(isActive => isActive === 0).length;
const countProspectInActive = prospect?.map(obj => obj.is_active).filter(isActive => isActive === 0).length;
  return (
    <>
        {loader === undefined ? <Loading/>
        
      :
      <>
      <div className="flex md:w-full mt-3">
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-2 mt-2 data">{countActive} of {totalTenant?.length} Active Tenants</span>
            <img className="w-28 mr-3 cursor-pointer" src="/assets/img/system-upgrade-button.png" alt="Upgrade" />
          </div>
        </div>
        <div className="flex-1">
        <div className="flex flex-column">
        <span className="data">Total Monthly Incoming Rent: <span className="text-green-600">$3,100</span></span>
        <span className="data">Outstanding Balance: <span className="text-red-600">$400</span></span>
        </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <span className="mr-2 data">Total Open Issue Tickets: <span className="text-red-600">1</span></span>
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} />
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} />
        </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <label className="block capitalize text-gray-700 font-bold mb-2 mr-2 mt-2">Filter: </label>
        <select
        className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        value={selectFilter}
        id="filtrationData"
        onChange={(e)=>{
          setSelectFilter(parseInt(e.target.value));
          filtration(parseInt(e.target.value));
        }}
        >
          <option value="3">All</option>
          <option value="2">Tenants</option>
          <option value="1">Active Tenants</option>
          <option value="0">Inactive Tenants</option>
          <option value="4">Prospects</option>
        </select>
        </div>
        </div>
      </div>


      <DataTableExtensions filter={false} {...tableData}>
        <DataTable
        className="striped"
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={handleRowSelected}
        />
        </DataTableExtensions>
        <div className="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ?<div className="md:flex md:items-center w-full md:w-10/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"MAKE PROSPECT ACTIVE"}>MAKE PROSPECT ACTIVE</option>
                <option value={"MAKE PROSPECT INACTIVE"}>MAKE PROSPECT INACTIVE</option>
                <option value={"DELETE"}>DELETE</option> 
                <option value={"MERGE"}>MERGE</option> 
              </select>
            </div>
            <div className="w-1/2 ml-1 ">
               <button
                  type="button"
                  className="btn2"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE PROSPECT ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE PROSPECT INACTIVE" ? bulkMakeInActive : bulkAction?.bulk_action == "DELETE" ? bulkDelete : bulkAction?.bulk_action == "MERGE" ? bulkMakeMerge :  null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
          <div className="bg-indigo-300 p-2 rounded  unit-wrapper mb-6">
              <div className="flex flex-wrap gap-x-3">
                <p className="flex-auto">
                  Total Monthly Incoming Rent: <span>$3,100</span>
                </p>
                <br/>
                <p className="flex flex-column">
                  <div>
                  Total Prospects:{" "}
                  <span className="text-green-600">{prospect?.length}</span>
                  </div>
                  <div className="mt-2">
                  Active:{" "}
                  <span className="text-red-600 mx-1">{countProspectActive}</span>
                  Inactive:{" "}
                  <span className="text-red-600">{countProspectInActive}</span>
                  </div>
                </p>
                <p className="flex flex-column">
                  <div>
                  Total Tenants:{" "}
                  <span className="text-green-600">{tenant?.length}</span>
                  </div>
                  <div className="mt-2">
                  Active:{" "}
                  <span className="text-red-600 mx-1">{countActive}</span>
                  Inactive:{" "}
                  <span className="text-red-600">{countInActive}</span>
                  </div>
                </p>
                
              </div>
            </div>
            </div>
        {/* <div className="flex justify-between justify-items-center text-center gap-x-8 mt-3 mb-4">
            <div className="flex-none ">
              {" "}
              <button className="bg-gray-300 px-4 flex justify-center align-items-center rounded text-black-800">
                <i className="fa-solid fa-bolt text-2xl	 mr-4"></i>
                <span>Bulk Actions</span>
              </button>
            </div>

            <div className="bg-indigo-300 p-2 rounded  unit-wrapper">
              <div className="flex flex-wrap gap-x-3">
                <p className="flex-auto">
                  Total Monthly Incoming Rent: <span>$3,100</span>
                </p>
                <p className="flex flex-column">
                  <div>
                  Total Prospects:{" "}
                  <span className="text-green-600">1</span>
                  </div>
                  <div className="mt-2">
                  Active:{" "}
                  <span className="text-red-600 mx-1">0</span>
                  Inactive:{" "}
                  <span className="text-red-600">1</span>
                  </div>
                </p>
                <p className="flex flex-column">
                  <div>
                  Total Tenants:{" "}
                  <span className="text-green-600">3</span>
                  </div>
                  <div className="mt-2">
                  Active:{" "}
                  <span className="text-red-600 mx-1">3</span>
                  Inactive:{" "}
                  <span className="text-red-600">0</span>
                  </div>
                </p>
                
              </div>
            </div>
          </div> */}
        </>
    }
    </>
  );
};

export default TenantTable;
