import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";
import imgUrl from "../ImageUrl";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

const FinanceInvoiceTable = ({ userRoles, status }) => {
  // console.log("userRoles", userRoles)
  //   const dispatch = useDispatch();
  //   const allProperties = useSelector(
  //     (state) => state.properties.properties.data
  //   );
  //   const loading = useSelector((state) => state.properties.status);
  //   const allPropertiesTypes = useSelector(
  //     (state) => state.properties.propertiesTypes.data
  //   );

  //   const newArray1 = allProperties?.map((a) => ({ ...a }));
  //   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
  // const [unit,setUnit] = useState([]);
  // const [loader,setLoader]= useState();
  // const showUnit = async ()=>{
  //     const response = await ApiRequest.get(
  //         "public/api/auth/get-my-tenants"
  //     );
  //     setLoader(response.status)
  //     if(response.status == 200)
  //     {
  //         setUnit(response?.data?.data)
  //         console.log("Response table",response);
  //     }
  // }
  // useEffect(() => {
  //   showUnit();
  // }, [])

  const [bulkAction, setBulkAction] = useState({
    bulk_action: "",
  });
  const [collectPayment, setCollectPayment] = useState(false);
  const [refund, setRefund] = useState(false);

  console.log("bulkAction", bulkAction);
  console.log("collectPayment", collectPayment);

  const data = ["131231", "nvvxcvsd", "1234323", "dfsdfsd", "534534"];

  const columns = [
    {
      name: "System ID",
      selector: (data) => "I-8978732982137",
    },
    {
      name: "Invoice Date",
      selector: (data) => "6/05/2022",
    },
    {
      name: "Due Date",
      selector: (data) => "6/07/2022",
    },
    {
      name: "Type",
      selector: (data) => {
        return (
          <img
            src="/assets/svgs/finance-invoice-property.svg"
            alt=""
            title="Property"
          />
        );
      },
    },
    {
      name: "Invoice ID",
      selector: (data) => "INV34543",
    },
    {
      name: "Name",
      selector: (data) => "Sapp, Sean",
    },
    {
      name: "Reference",
      selector: (data) => "Unit D",
    },
    {
      name: "Description",
      selector: (data) => "2023 June",
    },
    {
      name: "",
      selector: (data) => {
        return (<img src="/assets/svgs/system-paperclip.svg" className="w-1/6"/>)
      },
    },
    {
      name: "Budget",
      selector: (data) => {
        return <span className="font-bold">$1000.00</span>;
      },
    },
    {
      name: "Amount",
      selector: (data) => {
        return <span className="font-bold">$1000.00</span>;
      },
    },
    {
      name: "Paid Amount",
      selector: (data) => {
        return <span className="font-bold text-green-600">$0.00</span>;
      },
    },
    {
      name: "Due Amount",
      selector: (data) => {
        return <span className="font-bold text-red-600">$1000.00</span>;
      },
    },
    {
      name: "Status",
      selector: (data) => {
        return <span className="status-active">PAID</span>;
      },
    },
    {
      name: "Notes",
      selector: (data) => "For Luna",
    },
    {
      name: "Options",
      selector: (data) => (
        <div className="flex">
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
            data-bs-target={`#ViewTrackPaymentInvoice`}
            data-bs-toggle="modal"
            onClick={(e) => {
              setCollectPayment(true);
            }}
          >
            <img
              src="/assets/svgs/finance-dollar-bill.svg"
              title="Collect Payments"
            />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/img/system-view.png" title="View Invoice" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/img/system-edit.png" title="Edit Invoice" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
            data-bs-target={`#ViewIssueRefund`}
            data-bs-toggle="modal"
            onClick={(e) => {
              setRefund(true);
            }}
          >
            <img src="/assets/svgs/finance-refund.svg" title="Refund" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-download.svg" title="Download" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-print.svg" title="Print" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-trashcan.svg" title="Delete" />
          </button>
        </div>
      ),
    },
    // {
    //   name: "Options",
    //   cell: (data) => {
    //     return (
    //       <>
    //            <Link to={`/edit-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
    //           <i class="fa-solid fa-pen mr-6"></i>
    //           <span>Edit</span>
    //         </button>
    //         </Link>
    //         <Link to={`/show-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
    //             <i class="fa-regular fa-eye mr-6"></i>
    //             <span>View</span>
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

  //   const data =[
  //     {
  //         id:"355821647853265",
  //         sent:"5/24/2022 TIME",
  //         sender:"Sapp, Sean (UNIT A)",
  //         recipient:"SYSTEM",
  //         subject:"Question on Rent Due Date",
  //         viewed:"5/24/2022 TIME",
  //       },
  //     {
  //         id:"745852146932145",
  //         sent:"5/24/2022 TIME",
  //         sender:"SYSTEM",
  //         recipient:"Sapp, Sean (UNIT A)",
  //         subject:"Information requested about the pool ",
  //         viewed:"Not Viewed",
  //       },
  //     {
  //         id:"985632452185635",
  //         sent:"5/24/2022 TIME",
  //         sender:"Knabenshue, Chris(OWNER)",
  //         recipient:"SYSTEM",
  //         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
  //         viewed:"Not Viewed",
  //       },

  //   ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleLogSelectedRows = () => {
    console.log(selectedRows);
  };

  return (
    <>
      <div className="flex items-center md:w-full mt-3 mb-3">
        <div className="flex-1">
          <div className="flex flex-column">
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Tenant Payments:{" "}
                <span className="text-green-400">$3,050.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Staff Payments:{" "}
                <span className="text-green-400">$60.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Payments to Vendors:{" "}
                <span className="text-green-400">$3,050.00</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-column">
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Tenant Due:{" "}
                <span className="text-red-400">$1,250.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Staff Due: <span className="text-red-400">$0.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Due to Vendors:{" "}
                <span className="text-red-400">$1,250.00</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-column">
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Tenant Past Due:{" "}
                <span className="text-red-400">$250.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Staff Past Due:{" "}
                <span className="text-red-400">$0.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Past Due to Vendors:{" "}
                <span className="text-red-400">$250.00</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <img
              className="w-8 mr-3 cursor-pointer"
              src="/assets/img/properties/system-download-csv.png"
              alt="CSV"
            />
            <img
              className="w-8 mr-3 cursor-pointer"
              src="/assets/img/properties/system-download-pdf.png"
              alt="PDF"
            />
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
        onSelectedRowsChange={handleRowSelected}
      />
      {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
      <div className="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ? (
          <div className="md:flex md:items-center w-full md:w-1/2 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option value="">Select</option>
                <option value={"PAY MULTIPLE INVOICES"}>
                  PAY MULTIPLE INVOICES
                </option>
                <option value={"PUBLISH"}>PUBLISH</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"DOWNLOAD"}>DOWNLOAD</option>
                <option value={"PRINT"}>PRINT</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-1/2 ml-1 ">
              <button
                type="button"
                className="btn2"
                //   onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE PROSPECT ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE PROSPECT INACTIVE" ? bulkMakeInActive : bulkAction?.bulk_action == "DELETE" ? bulkDelete : bulkAction?.bulk_action == "MERGE" ? bulkMakeMerge :  null}
              >
                <i className="fa fa-arrow-alt-circle-right"></i>
                GO
              </button>
            </div>
          </div>
        ) : null}
        <div class="bg-indigo-300 p-2 rounded  unit-wrapper">
          <div class="flex flex-column flex-wrap gap-x-3">
            <p class="flex-auto">Total Invoice: 5</p>
            <p class="flex-auto">
              Total Paid Invoices: <span className="text-green-600">3</span>
            </p>
            <p class="flex-auto">
              Total Unpaid Invoices: <span className="text-red-600">2</span>
            </p>
            <p class="flex-auto">
              Total Draft Invoices: <span className="text-yellow-600">1</span>
            </p>
          </div>
        </div>
      </div>

      {bulkAction?.bulk_action == "PAY MULTIPLE INVOICES" ? (
        <>
          <form>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/svgs/finance-pay-multiple-invoices.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Track Payment for Invoices: INVOICE_NUMBERS
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Payment Date:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <DatePicker
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Method:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reference:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <input
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Deposit Info:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Budget:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:flex md:w-full">
                      <div className="md:w-auto show-to-tenant">
                        <label className="block tracking-wide text-gray-700 font-bold mb-2">
                          Select a different Budget for each invoice being paid:
                        </label>
                      </div>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          // value={formData?.hoa_show_to_tenant}
                          // onChange={(e) => {
                          //   const checkedValue = e.target.checked === true ? 1 : 0;
                          //   setFormData({
                          //     ...formData,
                          //     hoa_show_to_tenant: checkedValue,
                          //   });
                          // }}
                          class="sr-only peer"
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Amount:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <input
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`lease-date-to-send`}
                        // value={propertyMaterial?.material_type_id}
                        // onChange={(e) => {
                        //   const value = parseInt(e.target.value);
                        //   setPropertyMaterial({
                        //     ...propertyMaterial,
                        //     material_type_id: value,
                        //   });
                        //   getPropertyAllMaterials(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-column mt-3 space-x-4">
                  <label className="block capitalize w-2/5 tracking-wide text-gray-700 font-bold mb-2">
                    Use Payment to pay invoices:
                  </label>
                  <div className="w-full flex">
                    <label className="flex items-center space-x-2 mr-4 mt-2 text-gray-700">
                      <input
                        type="radio"
                        name="showIn"
                        value="newTab"
                        className="form-radio"
                        //   checked={unitBoard.link_show_in === "newTab"}
                        //   onChange={(e) => {
                        //       setUnitBoard({ ...unitBoard, link_show_in: e.target.value });
                        //     }}
                      />
                      <span>Oldest First</span>
                    </label>
                    <label className="flex items-center  space-x-1 mt-2 text-gray-700">
                      <input
                        type="radio"
                        name="showIn"
                        value="newWindow"
                        className="form-radio"
                        //   checked={unitBoard.link_show_in === "newWindow"}
                        //   onChange={(e) => {
                        //       setUnitBoard({ ...unitBoard, link_show_in: e.target.value });
                        //     }}
                      />
                      <span>Newest First</span>
                    </label>
                  </div>
                </div>
                <div className="md:flex mt-4">
                  <div className="md:w-1/3">
                    <div className="flex">
                      {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Invoice Files:
                      </label>
                    </div>
                  </div>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                    <div className="flex md:items-center md:justify-start md:w-2/3">
                      <div className="md:w-1/2">
                        <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                            Upload files
                          </span>
                          <input
                            id="property-owner-files"
                            type="file"
                            // accept={fileTypes}
                            multiple={true}
                            hidden={true}
                            // onChange={(e) => {
                            //   let files = e.target.files;
                            //   let newImages = [];
                            //   let maxOrderNumber = 0;

                            //   // Find the highest order number in the current images
                            //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                            //     if (imageObject.order_number > maxOrderNumber) {
                            //       maxOrderNumber = imageObject.order_number;
                            //     }
                            //   });

                            //   // Calculate the total number of images (existing images + new images)
                            //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                            //   // Loop through each file and convert to base64
                            //   files.forEach((file, i) => {
                            //     const reader = new FileReader();
                            //     reader.addEventListener("load", async () => {
                            //       const fileBase64 = reader.result;
                            //       try {
                            //         const res = await ApiRequest.post(fileApi, {
                            //           file: fileBase64,
                            //         });

                            //         // Add the new image to the array with a unique order number
                            //         newImages.push({
                            //           file: res?.data?.data?.file,
                            //           title: res?.data?.data?.file,
                            //           url:fileBase64
                            //         });

                            //         // If all new images have been processed, update the state
                            //         if (newImages.length === files.length) {
                            //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                            //           fileLists = [...fileLists, ...newImages];
                            //           setPropertyOwnerDocumentsWithUrl(fileLists);
                            //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                            //           propertyCopy.files = fileLists.map(
                            //             (picObj) => ({
                            //               file: picObj.file,
                            //               title: picObj.title,
                            //             })
                            //           );
                            //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                            //         }
                            //       } catch (err) {
                            //         // console.log(err);
                            //       }
                            //     });
                            //     reader.readAsDataURL(file);
                            //   });
                            // }}
                          />
                        </label>
                      </div>
                      {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                    </div>
                  </div>
                </div>

                {/* Gallery  */}

                {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                    // initData={editorData?.dln_id_issue_notes}
                    // onChange={(e) => {
                    //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    //   setEditorData({
                    //     ...editorData,
                    //     dln_id_issue_notes: cleanText,
                    //   });
                    // }}
                    />
                  </div>
                </div>
                <div class="flex justify-end items-center mt-3">
                  <div class="flex justify-between items-center gap-4">
                    <button type="button" class="btn btn3">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : null}

      <div
        class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id={`ViewTrackPaymentInvoice`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="ViewTrackPaymentLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl w-auto pointer-events-none">
          <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                class="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Track Payment for Invoice #INVOICE_NUMBER
              </h5>
              <button
                type="button"
                class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setCollectPayment(false);
                }}
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
            <div class="modal-body relative p-4">
              {collectPayment ? (
                <>
                  <form>
                    <div className="md:items-center w-full mt-4">
                      <div className="flex">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/svgs/finance-dollar-bill.svg"
                          alt=""
                        />
                        <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                          Track Payment for Invoice #INVOICE_NUMBER
                        </label>
                      </div>
                    </div>
                    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                      <div className="w-full">
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Payment Date:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <DatePicker
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Method:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Reference:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Deposit Info:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Budget:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Amount:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex mt-4">
                          <div className="md:w-1/3">
                            <div className="flex">
                              {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Invoice Files:
                              </label>
                            </div>
                          </div>
                          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                            <div className="flex md:items-center md:justify-start md:w-2/3">
                              <div className="md:w-1/2">
                                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                    Upload files
                                  </span>
                                  <input
                                    id="property-owner-files"
                                    type="file"
                                    // accept={fileTypes}
                                    multiple={true}
                                    hidden={true}
                                    // onChange={(e) => {
                                    //   let files = e.target.files;
                                    //   let newImages = [];
                                    //   let maxOrderNumber = 0;

                                    //   // Find the highest order number in the current images
                                    //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                                    //     if (imageObject.order_number > maxOrderNumber) {
                                    //       maxOrderNumber = imageObject.order_number;
                                    //     }
                                    //   });

                                    //   // Calculate the total number of images (existing images + new images)
                                    //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                                    //   // Loop through each file and convert to base64
                                    //   files.forEach((file, i) => {
                                    //     const reader = new FileReader();
                                    //     reader.addEventListener("load", async () => {
                                    //       const fileBase64 = reader.result;
                                    //       try {
                                    //         const res = await ApiRequest.post(fileApi, {
                                    //           file: fileBase64,
                                    //         });

                                    //         // Add the new image to the array with a unique order number
                                    //         newImages.push({
                                    //           file: res?.data?.data?.file,
                                    //           title: res?.data?.data?.file,
                                    //           url:fileBase64
                                    //         });

                                    //         // If all new images have been processed, update the state
                                    //         if (newImages.length === files.length) {
                                    //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                                    //           fileLists = [...fileLists, ...newImages];
                                    //           setPropertyOwnerDocumentsWithUrl(fileLists);
                                    //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                                    //           propertyCopy.files = fileLists.map(
                                    //             (picObj) => ({
                                    //               file: picObj.file,
                                    //               title: picObj.title,
                                    //             })
                                    //           );
                                    //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                                    //         }
                                    //       } catch (err) {
                                    //         // console.log(err);
                                    //       }
                                    //     });
                                    //     reader.readAsDataURL(file);
                                    //   });
                                    // }}
                                  />
                                </label>
                              </div>
                              {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                            </div>
                          </div>
                        </div>

                        {/* Gallery  */}

                        {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
                        <div className="md:flex mt-3">
                          <div className="md:w-1/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Invoice Notes:
                            </label>
                          </div>
                          <div className="md:w-4/5">
                            <CKEditor
                            // initData={editorData?.dln_id_issue_notes}
                            // onChange={(e) => {
                            //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                            //   setEditorData({
                            //     ...editorData,
                            //     dln_id_issue_notes: cleanText,
                            //   });
                            // }}
                            />
                          </div>
                        </div>
                        <div class="flex justify-end items-center mt-3">
                          <div class="flex justify-between items-center gap-4">
                            <button type="button" class="btn btn3">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : null}
            </div>
            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                class="btn3"
                onClick={() => {
                  setCollectPayment(false);
                }}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id={`ViewIssueRefund`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="ViewTrackPaymentLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl w-auto pointer-events-none">
          <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                class="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalLabel"
              >
                Issue Refund or Account Credit
              </h5>
              <button
                type="button"
                class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setRefund(false);
                }}
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
            <div class="modal-body relative p-4">
              {refund ? (
                <>
                  <form>
                    <div className="md:items-center w-full mt-4">
                      <div className="flex">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/svgs/finance-refund.svg"
                          alt=""
                        />
                        <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                          Issue Refund or Account Credit
                        </label>
                      </div>
                    </div>
                    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                      <div className="w-full">
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Date:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <DatePicker
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Type:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Type:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-full mr-2 mt-3">
                            <div className="md:w-1/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Reason:
                              </label>
                            </div>
                            <div className="md:w-4/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Method:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Reference:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Reference:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:flex md:w-full">
                              <div className="md:w-auto show-to-tenant">
                                <label className="block tracking-wide text-gray-700 font-bold mb-2 mr-3">
                                  Track Credit in Budget:
                                </label>
                              </div>
                              <label class="inline-flex relative items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  // value={formData?.hoa_show_to_tenant}
                                  // onChange={(e) => {
                                  //   const checkedValue = e.target.checked === true ? 1 : 0;
                                  //   setFormData({
                                  //     ...formData,
                                  //     hoa_show_to_tenant: checkedValue,
                                  //   });
                                  // }}
                                  class="sr-only peer"
                                />
                                <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Paid From:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Budget:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Budget:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              >
                                <option value="">Select</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                            <div className="md:w-2/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Amount:
                              </label>
                            </div>
                            <div className="md:w-2/5">
                              <input
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id={`lease-date-to-send`}
                                // value={propertyMaterial?.material_type_id}
                                // onChange={(e) => {
                                //   const value = parseInt(e.target.value);
                                //   setPropertyMaterial({
                                //     ...propertyMaterial,
                                //     material_type_id: value,
                                //   });
                                //   getPropertyAllMaterials(e.target.value);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="md:flex mt-4">
                          <div className="md:w-1/3">
                            <div className="flex">
                              {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Refund/Credit Public Files:
                              </label>
                            </div>
                          </div>
                          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                            <div className="flex md:items-center md:justify-start md:w-2/3">
                              <div className="md:w-1/2">
                                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                    Upload files
                                  </span>
                                  <input
                                    id="property-owner-files"
                                    type="file"
                                    // accept={fileTypes}
                                    multiple={true}
                                    hidden={true}
                                    // onChange={(e) => {
                                    //   let files = e.target.files;
                                    //   let newImages = [];
                                    //   let maxOrderNumber = 0;

                                    //   // Find the highest order number in the current images
                                    //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                                    //     if (imageObject.order_number > maxOrderNumber) {
                                    //       maxOrderNumber = imageObject.order_number;
                                    //     }
                                    //   });

                                    //   // Calculate the total number of images (existing images + new images)
                                    //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                                    //   // Loop through each file and convert to base64
                                    //   files.forEach((file, i) => {
                                    //     const reader = new FileReader();
                                    //     reader.addEventListener("load", async () => {
                                    //       const fileBase64 = reader.result;
                                    //       try {
                                    //         const res = await ApiRequest.post(fileApi, {
                                    //           file: fileBase64,
                                    //         });

                                    //         // Add the new image to the array with a unique order number
                                    //         newImages.push({
                                    //           file: res?.data?.data?.file,
                                    //           title: res?.data?.data?.file,
                                    //           url:fileBase64
                                    //         });

                                    //         // If all new images have been processed, update the state
                                    //         if (newImages.length === files.length) {
                                    //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                                    //           fileLists = [...fileLists, ...newImages];
                                    //           setPropertyOwnerDocumentsWithUrl(fileLists);
                                    //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                                    //           propertyCopy.files = fileLists.map(
                                    //             (picObj) => ({
                                    //               file: picObj.file,
                                    //               title: picObj.title,
                                    //             })
                                    //           );
                                    //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                                    //         }
                                    //       } catch (err) {
                                    //         // console.log(err);
                                    //       }
                                    //     });
                                    //     reader.readAsDataURL(file);
                                    //   });
                                    // }}
                                  />
                                </label>
                              </div>
                              {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                            </div>
                          </div>
                        </div>

                        {/* Gallery  */}

                        {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
                        <div className="md:flex mt-3">
                          <div className="md:w-1/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Refund/Credit Public Notes:
                            </label>
                          </div>
                          <div className="md:w-4/5">
                            <CKEditor
                            // initData={editorData?.dln_id_issue_notes}
                            // onChange={(e) => {
                            //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                            //   setEditorData({
                            //     ...editorData,
                            //     dln_id_issue_notes: cleanText,
                            //   });
                            // }}
                            />
                          </div>
                        </div>
                        <div className="md:flex mt-4">
                          <div className="md:w-1/3">
                            <div className="flex">
                              {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Refund/Credit Internal Files:
                              </label>
                            </div>
                          </div>
                          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                            <div className="flex md:items-center md:justify-start md:w-2/3">
                              <div className="md:w-1/2">
                                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                    Upload files
                                  </span>
                                  <input
                                    id="property-owner-files"
                                    type="file"
                                    // accept={fileTypes}
                                    multiple={true}
                                    hidden={true}
                                    // onChange={(e) => {
                                    //   let files = e.target.files;
                                    //   let newImages = [];
                                    //   let maxOrderNumber = 0;

                                    //   // Find the highest order number in the current images
                                    //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                                    //     if (imageObject.order_number > maxOrderNumber) {
                                    //       maxOrderNumber = imageObject.order_number;
                                    //     }
                                    //   });

                                    //   // Calculate the total number of images (existing images + new images)
                                    //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                                    //   // Loop through each file and convert to base64
                                    //   files.forEach((file, i) => {
                                    //     const reader = new FileReader();
                                    //     reader.addEventListener("load", async () => {
                                    //       const fileBase64 = reader.result;
                                    //       try {
                                    //         const res = await ApiRequest.post(fileApi, {
                                    //           file: fileBase64,
                                    //         });

                                    //         // Add the new image to the array with a unique order number
                                    //         newImages.push({
                                    //           file: res?.data?.data?.file,
                                    //           title: res?.data?.data?.file,
                                    //           url:fileBase64
                                    //         });

                                    //         // If all new images have been processed, update the state
                                    //         if (newImages.length === files.length) {
                                    //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                                    //           fileLists = [...fileLists, ...newImages];
                                    //           setPropertyOwnerDocumentsWithUrl(fileLists);
                                    //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                                    //           propertyCopy.files = fileLists.map(
                                    //             (picObj) => ({
                                    //               file: picObj.file,
                                    //               title: picObj.title,
                                    //             })
                                    //           );
                                    //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                                    //         }
                                    //       } catch (err) {
                                    //         // console.log(err);
                                    //       }
                                    //     });
                                    //     reader.readAsDataURL(file);
                                    //   });
                                    // }}
                                  />
                                </label>
                              </div>
                              {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                            </div>
                          </div>
                        </div>

                        {/* Gallery  */}

                        {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
                        <div className="md:flex mt-3">
                          <div className="md:w-1/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Refund/Credit Internal Notes:
                            </label>
                          </div>
                          <div className="md:w-4/5">
                            <CKEditor
                            // initData={editorData?.dln_id_issue_notes}
                            // onChange={(e) => {
                            //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                            //   setEditorData({
                            //     ...editorData,
                            //     dln_id_issue_notes: cleanText,
                            //   });
                            // }}
                            />
                          </div>
                        </div>
                        <div class="flex justify-end items-center mt-3">
                          <div class="flex justify-between items-center gap-4">
                            <button type="button" class="btn btn3">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : null}
            </div>
            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                class="btn3"
                onClick={() => {
                  setRefund(false);
                }}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceInvoiceTable;
