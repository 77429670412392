import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";

// import Loading from "../Loader/Loading";
// import Loading from "../Loader/Loading";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
const TestingTable2 = ({userRoles, status}) => {
    console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));

  const columns = [
    {
      name: "Date",
      selector : (data) => data.date
      
    },
    {
      name: "User",
      selector : (data) => data.user
    },
    {
      name: "IP",
      selector : (data) => data.ip
    },
    {
      name: "Section",
      selector : (data) => data.section
    },
    {
      name: "Event",
      selector : (data) => data.event
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },

  ];

  const data = [
    {
        date:"20:13:20 2/10/2022",
        user:"Chris",
        ip:"78.890.098.0",
        section:"Office",
        event:"Chris edited Invoice : INV91",
    },
    {
        date:"21:23:50 28/10/2022",
        user:"Steve",
        ip:"99.213.0.123",
        section:"Billing",
        event:"Steve has added payment to invoice : INV16",
    },
    {
        date:"14:00:49 17/12/2022",
        user:"Chris",
        ip:"123.989.09.2",
        section:"Expenses",
        event:"Chris Created a new Invoice : INV290",
    },
    {
        date:"16:14:28 29/12/2022",
        user:"Chris",
        ip:"98.129.09.9",
        section:"Office",
        event:"Chris has marked the invoice INV9 as Void",
    },
  ];

 

  return (
    <>
     
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
        />
    
    </>
  );
};

export default TestingTable2;