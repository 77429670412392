import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../components/property/TabPanel";

import ActivityTable from "./ActivityTable";
import TestingTable2 from "./TestingTable2";
export const ActivityTabs = ({}) => {
  //  CONSTANTS



  const [tabValue, setTabValue] = useState(0);

  //   //  METHODS
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      {/* Add Property Form  */}

      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="ACTIVITY" {...a11yProps(0)} />
        <Tab label="EMAIL" {...a11yProps(1)} />
        <Tab label="LOGINS" {...a11yProps(2)} />
        <Tab label="SETTINGS" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {/* <ActivityTable /> */}
        <TestingTable2/>
      </TabPanel>
    </>
  );
};
