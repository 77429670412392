import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ProfileSelect } from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer";
import { SideNavBar } from "../../components/SideNavBar";
import Loading from "../../components/Loader/Loading";

export const AdminDashboardPage = ({ cookies }) => {
  const staffOptions = ["Overview (All)", "White Oak (Property Manager)"];
  const propertyOptions = ["Overview (All)", "New Heights", "Riverbridge"];

  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  let [propertyName, setPropertyName] = useState("Property 1");

  const handleContentScroll = (e) => {
    // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    // if (show) setShowFooter(() => showFooter = show)
    // else setShowFooter(() => showFooter = false)
  };
  const handePropertyDropdown = () => {
    setShowPropertyDropdown(!showPropertyDropdown);
  };
  const handlePropertyName = (e) => {
    setPropertyName(e.target.innerHTML);
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/admin/js/main.js";
    main.async = true;
    document.body.appendChild(main);

    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);
  const [collapsed,setCollapsed] = useState(true)
const handleChildMessage = (message) => {
setCollapsed(message)
};
  return (
    <>
    {isLoading ? <Loading/>:
      <div className="main-dashboard">
      <Helmet>
        <link rel="stylesheet" href="/assets/admin/css/main.css" />
        <link rel="stylesheet" href="/assets/admin/css/fontawesome-all.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/admin/css/slick.css" />
        <link rel="stylesheet" href="/assets/admin/css/responsive.css" />
      </Helmet>
      <SideNavBar active="Admin" user_type="super_admin" onChildMessage={handleChildMessage}/>
      <div
        className={`main-content ${collapsed ? "collapsed" : ""}`}
        onScroll={(e) => handleContentScroll(e)}
      >
        {/* header bar */}
        <section className="header-bar">
          <div className="row align-items-center">
            <div className="col-xl-5 col-md-12 col-2">
              <div className="property-item">
                <div className="property-img">
                  <img src="/assets/admin/img/property.png" alt="" />
                </div>
                <div
                  className="property-select p-2 w-100 account-switcher-dropdown"
                  onMouseEnter={handePropertyDropdown}
                  onMouseLeave={handePropertyDropdown}
                >
                  <span className="property-switcher"> {propertyName} </span>
                  <div
                    className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${
                      showPropertyDropdown ? "" : "hidden"
                    }`}
                    id="style-14"
                  >
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Properties: </span>
                    </div>
                    {propertyOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                    <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4">
                      <span>Staff: </span>
                    </div>
                    {staffOptions.map((v, i) => (
                      <div key={i} className="ml-2">
                        <span
                          onClick={handlePropertyName}
                          className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none"
                        >
                          {v}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mobile-logo">
                <img
                  className="img-fluid logo-light"
                  src="/assets/admin/img/logo-light.png"
                  alt=""
                />
                <img
                  className="img-fluid logo-dark"
                  src="/assets/admin/img/logo-dark.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-10">
              <div className="header-bar-right items-start">
                <ul className="header-icons">
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-dashboard-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                     className="dropdown-menu h-40    overflow-y-auto"
                      aria-labelledby="dropdownMenuLink1"
                    >
                      <span className="triangle-arrow" />
                      <div className="px-2">
                        <p className="text-3xl bold mb-3">Recent Activity </p>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-message-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu h-40    overflow-y-auto"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      <span className="triangle-arrow" />
                      <div className="px-2">
                        <p className="text-3xl bold mb-3">Recent Activity </p>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink3"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-language-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu h-40  overflow-y-auto"
                      aria-labelledby="dropdownMenuLink3"
                    >
                      <span className="triangle-arrow" />
                      <div className="px-2">
                        <p className="text-3xl bold mb-3">Recent Activity </p>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                        <div className="flex justify-content-start items-center mb-3">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className="w-10 h-10 rounded-full mr-3" />
                            <span>michellemonty updated <b>Day 2</b>s description</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/svgs/system-notifications-l.svg"
                        className="p-2"
                        alt=""
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink3"
                    >
                      <span className="triangle-arrow" />
                    </div>
                  </li> */}
                </ul>
                <ProfileSelect cookies={cookies} />
              </div>
            </div>
          </div>
        </section>
        {/* search wraper  */}
        <section className="search-wraper">
          <div className="row">
            <div className="col-xl-8 order-lg-2">
              <div className="search-right-side-wrap">
                <div className="mobile-menu-trigger">
                  <span />
                  <span />
                  <span />
                </div>
                <div className="serch-bar-side">
                  <div className="form-group search">
                    <input
                      type="search"
                      name
                      id
                      placeholder="Type here to Search..."
                    />
                    <i className="fas fa-search" />
                  </div>
                </div>
                <label className="theme-switch">
                  <span className="switch-dark">dark</span>
                  <input type="checkbox" id="check-slider" />
                  <span className="check-slider round" />
                  <span className="switch-light">light</span>
                </label>
              </div>
            </div>
            <div className="col-xl-4 order-lg-1">
              <div
                className={`welcome-wrap ${showPropertyDropdown ? "z-0" : ""}`}
              >
                <div className="welcome-inner">
                  <h4>
                    Welcome Back <span>Anddy’s Makeover</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* service wraper */}
        <section className="service-area">
          <div className="row">
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon1.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>13</h3>
                  <h5>Number of Owners</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-2">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon2.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>235</h3>
                  <h5>Number of Leads</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-3">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon2.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>44</h3>
                  <h5>Number of Tenants</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-4">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon3.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>25</h3>
                  <h5>Open Tickets</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-5">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon4.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>07</h3>
                  <h5>Income to Date</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-6">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon5.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>02</h3>
                  <h5>Monthly Income</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-7">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon6.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>39</h3>
                  <h5>Income/Expense Graph</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            {/* single service  */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-6">
              <div className="single-service service-style-8">
                <div className="service-content">
                  <img
                    src="/assets/admin/img/service/service-icon7.png"
                    alt=""
                    className="service-icon"
                  />
                  <h3>09</h3>
                  <h5>Last 5 Tickets</h5>
                </div>
                <div className="service-bottom">
                  <a href="#" className="learn-more">
                    Learn More <img src="/assets/admin/img/long-arrow.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* slider area  */}
        <section className="property-slider-area">
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider1.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>04</h4>
              <p>Units</p>
            </div>
          </div>
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider2.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>02</h4>
              <p>Tenants</p>
            </div>
          </div>
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider3.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>01</h4>
              <p>Property Managers</p>
            </div>
          </div>
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider4.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>02</h4>
              <p>Staf</p>
            </div>
          </div>
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider5.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>02</h4>
              <p>Vendor</p>
            </div>
          </div>
          {/* single property-slider  */}
          <div className="single-property-slider">
            <div className="p-slider-icon">
              <img src="/assets/admin/img/property-slider/p-slider1.png" alt="" />
            </div>
            <div className="p-slider-content">
              <h4>04</h4>
              <p>Units</p>
            </div>
          </div>
        </section>
        {/* data chart area  */}
        <section className="data-chart-area">
          <div className="row">
            <div className="col-xl-7">
              <div className="chart-wrap">
                <div id="chartdiv" />
              </div>
            </div>
            <div className="col-xl-5">
              <div className="data-table">
                <div className="data-table-heading">
                  <h5>Last S Maintenance Requests &amp; Concerns</h5>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-bg">
                        <td>08/30/2021</td>
                        <td>Test name who submit</td>
                        <td className="text-end">
                          <i className="fas fa-eye" />
                        </td>
                      </tr>
                      <tr>
                        <td>08/30/2021</td>
                        <td>Checking Maintenance Ticket form Tenant End</td>
                        <td className="text-end">
                          <i className="fas fa-eye" />
                        </td>
                      </tr>
                      <tr className="tr-bg">
                        <td>08/30/2021</td>
                        <td>Checking from Admin to Tenant</td>
                        <td className="text-end">
                          <i className="fas fa-eye-slash" />
                        </td>
                      </tr>
                      <tr>
                        <td>08/30/2021</td>
                        <td>Test name who submit</td>
                        <td className="text-end">
                          <i className="fas fa-eye" />
                        </td>
                      </tr>
                      <tr className="tr-bg">
                        <td>08/30/2021</td>
                        <td>Ice Maker Stopped Functioning</td>
                        <td className="text-end">
                          <i className="fas fa-eye" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="data-table-pagination">
                  <div className="rows-per-page">
                    <h6>Rows per page:</h6>
                    <select>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                  <div className="pagination-part">
                    <h6>1-5 of 13</h6>
                    <span>
                      <i className="fas fa-angle-left" />{" "}
                      <i className="fas fa-angle-right" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* order table area  */}
        <section className="order-table-area">
          <div className="row table-heading align-items-center">
            <div className="col-lg-9">
              <h5>Amazon vendor order processing</h5>
            </div>
            <div className="col-lg-3">
              <div className="table-search">
                <input type="search" name id placeholder="Search" />
                <i className="fas fa-search" />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th className="status text-center">Status</th>
                  <th className="sku-qnt text-center">SKU Quantity</th>
                  <th className="item-qnt text-center">Item Quantity</th>
                  <th className="order-value text-center">Order Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="order-id">#16512</td>
                  <td className="status text-center">
                    <span className="tbl-btn">Approved</span>
                  </td>
                  <td className="sku-qnt text-center">2</td>
                  <td className="item-qnt text-center">350</td>
                  <td className="order-value text-center">$3595.79</td>
                </tr>
                <tr>
                  <td className="order-id">#16512</td>
                  <td className="status text-center">
                    <span className="tbl-btn btn2">Pending</span>
                  </td>
                  <td className="sku-qnt text-center">2</td>
                  <td className="item-qnt text-center">350</td>
                  <td className="order-value text-center">$3595.79</td>
                </tr>
                <tr>
                  <td className="order-id">#16512</td>
                  <td className="status text-center">
                    <span className="tbl-btn btn2">Pending</span>
                  </td>
                  <td className="sku-qnt text-center">2</td>
                  <td className="item-qnt text-center">350</td>
                  <td className="order-value text-center">$3595.79</td>
                </tr>
                <tr>
                  <td className="order-id">#16512</td>
                  <td className="status text-center">
                    <span className="tbl-btn">Approved</span>
                  </td>
                  <td className="sku-qnt text-center">2</td>
                  <td className="item-qnt text-center">350</td>
                  <td className="order-value text-center">$3595.79</td>
                </tr>
                <tr>
                  <td className="order-id">#16512</td>
                  <td className="status text-center">
                    <span className="tbl-btn btn2">Pending</span>
                  </td>
                  <td className="sku-qnt text-center">2</td>
                  <td className="item-qnt text-center">350</td>
                  <td className="order-value text-center">$3595.79</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        {/* accordion area  */}
        <section className="accordion-area">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Libero atque ullam at sint possimus impedit veritatis quos,
                  veniam odit culpa velit earum, officiis excepturi
                  necessitatibus!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Magni beatae rerum unde non molestias consequuntur doloremque,
                  deleniti, placeat alias velit dolor repudiandae? Velit
                  dignissimos voluptatem tempora, voluptas necessitatibus
                  facilis hic asperiores sit voluptate temporibus officia!
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  When an unknown printer took a galley of type and scrambled it
                  to make a type specimen book.
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Molestias quasi possimus incidunt numquam accusamus et
                  deserunt tenetur expedita quisquam perspiciatis dignissimos,
                  debitis beatae nostrum hic, odit, itaque sapiente repudiandae
                  fugit! Id in, maxime tempore reiciendis veritatis harum rerum
                  deserunt sapiente perferendis debitis ab cupiditate neque.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  It has survived not only five centuries.
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Molestias quasi possimus incidunt numquam accusamus et
                  deserunt tenetur expedita quisquam perspiciatis dignissimos,
                  debitis beatae nostrum hic, odit, itaque sapiente repudiandae
                  fugit! Id in, maxime tempore reiciendis veritatis harum rerum
                  deserunt sapiente perferendis debitis ab cupiditate neque.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  But also the leap into electronic typesetting, remaining
                  essentially unchanged.
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Molestias quasi possimus incidunt numquam accusamus et
                  deserunt tenetur expedita quisquam perspiciatis dignissimos,
                  debitis beatae nostrum hic, odit, itaque sapiente repudiandae
                  fugit! Id in, maxime tempore reiciendis veritatis harum rerum
                  deserunt sapiente perferendis debitis ab cupiditate neque.
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bottom-gap" />
      </div>
      <Footer />
    </div>}
    </>
  );
};
