import React, { useState, useEffect } from 'react';
import ApiRequest from '../../utility/http';

const useAllowedMimeTypes = () => {
  const [allowedMimeTypes, setAllowedMimeTypes] = useState('');

  const mimeTypes = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    pdf: 'application/pdf',
    txt: 'text/plain',
    zip: 'application/zip',
    tar: 'application/x-tar',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpg: 'video/mpeg',
    mpeg: 'video/mpeg',
    wav: 'audio/wav',
    wma: 'audio/x-ms-wma',
    aac: 'audio/aac',
    ogg: 'audio/ogg',
    flac: 'audio/flac',
    alac: 'audio/alac',
    aiff: 'audio/aiff',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    tiff: 'image/tiff',
    gif: 'image/gif',
    raw: 'application/octet-stream',
    eps: 'application/postscript',
    svg: 'image/svg+xml',
    heic: 'image/heic',
    psd: 'image/vnd.adobe.photoshop',
    ai: 'application/postscript'
  };

  useEffect(() => {
    const fetchFileTypes = async () => {
      try {
        const response = await ApiRequest.get('public/api/auth/get-allowed-files-types');
        if (response.status === 200) {
          const allowedTypes = response.data?.data?.allowed_files_types || [];
          const allowedMimeTypes = allowedTypes.map(fileType => mimeTypes[fileType.type]).join(',');
          setAllowedMimeTypes(allowedMimeTypes);
        }
      } catch (error) {
        console.error('Error fetching file types:', error);
      }
    };

    fetchFileTypes();
  }, []);

  return allowedMimeTypes;
};

export default useAllowedMimeTypes;
