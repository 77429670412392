import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const AddForumSettingForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const [notification, setNotification] = useState(false);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-forum-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Notifications" {...a11yProps(0)} />
          <Tab label="Space" {...a11yProps(1)} />
          <Tab label="Other Settings" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              /> */}
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Notifications:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:w-full">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-2">
                      Receive From Notifications:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={notification}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setNotification(checkedValue);
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              {notification ? (
                <>
                  <div className="flex items-center mt-3 space-x-4">
                    <div className="w-full flex">
                      <label className="flex items-center space-x-1 text-gray-700">
                        <input
                          type="radio"
                          name="showIn"
                          value="newTab"
                          className="form-radio"
                          // checked={propertyBoard.link_show_in === "newTab"}
                          // onChange={(e) => {
                          //     setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                          //   }}
                        />
                        <span>All New Posts</span>
                      </label>
                      <label className="flex items-center mx-2  space-x-1 text-gray-700">
                        <input
                          type="radio"
                          name="showIn"
                          value="newWindow"
                          className="form-radio"
                          // checked={propertyBoard.link_show_in === "newWindow"}
                          // onChange={(e) => {
                          //     setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                          //   }}
                        />
                        <span>All New Posts and Comments</span>
                      </label>
                      <label className="flex items-center space-x-1 text-gray-700">
                        <input
                          type="radio"
                          name="showIn"
                          value="sameTab"
                          className="form-radio"
                          // checked={propertyBoard.link_show_in === "sameTab"}
                          // onChange={(e) => {
                          //     setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                          //   }}
                        />
                        <span>Only Post I Make or Comment on</span>
                      </label>
                      <label className="flex items-center mx-2  space-x-1 text-gray-700">
                        <input
                          type="radio"
                          name="showIn"
                          value="popUp"
                          className="form-radio"
                          // checked={propertyBoard.link_show_in === "popUp"}
                          // onChange={(e) => {
                          //     setPropertyBoard({ ...propertyBoard, link_show_in: e.target.value });
                          //   }}
                        />
                        <span>Only For Spaces/Posts I Follow</span>
                      </label>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Frequency <span title="Field is Required">*</span>:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              /> */}
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Add a Space:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
            <div className="md:flex md:items-center w-full mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Space Name <span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    // placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.moveout_address1}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address1:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address1: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Icon:
                  </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Access <span title="Field is Required">*</span>:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="flex items-center mt-3 w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Moderators:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <select
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="deposit_amount"
                          type="text"
                          placeholder="Name"
                          required={false}
                          // value={formData?.moveout_address1}
                          // onChange={(e) => {
                          //   setCreateLog({...createLog,moveout_address1:1});
                          //   setFormData({
                          //     ...formData,
                          //     moveout_address1: e.target.value,
                          //   });
                          // }}
                        >
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              /> */}
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Other Settings:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:w-full">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-2">
                      Allow Attachments in Comments:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={notification}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setNotification(checkedValue);
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Welcome Message:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
      </form>
    </>
  );
};
