import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ProfileSelect} from "../../components/Dashboard/ProfileSelect";
import { Footer } from "../../components/Dashboard/Footer"
import {SideNavBar} from "../../components/SideNavBar";
import CommunityTable from "./CommunityTable";
import TestingTable from "./TestingTable";

export const SystemForumGuides = ({ cookies }) => {

    const staffOptions = ['Overview (All)', 'White Oak (Property Manager)']
	const propertyOptions = ['Overview (All)', 'New Heights', 'Riverbridge']


    let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
	let [propertyName, setPropertyName] = useState('Property 1')


    const handleContentScroll = (e) => {
        // let show = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
        // if (show) setShowFooter(() => showFooter = show)
        // else setShowFooter(() => showFooter = false)
    }
    const handePropertyDropdown = () => {
		setShowPropertyDropdown(!showPropertyDropdown)
	};
	const handlePropertyName = (e) => {
		setPropertyName(e.target.innerHTML)
	};
    useEffect(() => {
        const jquery = document.createElement('script');
        jquery.src = '/assets/admin/js/jquery-3.6.0-min.js';
        jquery.async = true;
        document.body.appendChild(jquery);
        const bootstrap = document.createElement('script');
        bootstrap.src = '/assets/admin/js/bootstrap.min.js';
        bootstrap.async = true;
        document.body.appendChild(bootstrap);
        const main = document.createElement('script');
        main.src = '/assets/admin/js/main.js';
        main.async = true;
        document.body.appendChild(main);

        return () => {
            document.body.removeChild(jquery);
            document.body.removeChild(bootstrap);
            document.body.removeChild(main);
        }

    }, [])
    const [collapsed,setCollapsed] = useState(true)
    const handleChildMessage = (message) => {
    setCollapsed(message)
    };
    return (
        <div className="main-dashboard">
            <Helmet>
                <link rel="stylesheet" href="/assets/admin/css/main.css"/>
                <link rel="stylesheet" href="/assets/admin/css/fontawesome-all.min.css"/>
                <link rel="stylesheet" href="/assets/admin/css/bootstrap.min.css"/>
                <link rel="stylesheet" href="/assets/admin/css/slick.css"/>
                <link rel="stylesheet" href="/assets/admin/css/responsive.css"/>
            </Helmet>
            <SideNavBar active="Admin" user_type="super_admin" onChildMessage={handleChildMessage}/>
            <div className={`main-content ${collapsed ? "collapsed" : ""}`} onScroll={e => handleContentScroll(e)}>
                {/* header bar */}
                <section className="header-bar">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-md-12 col-2">
                            <div className="property-item">
                                <div className="property-img">
                                    <img src="/assets/admin/img/property.png" alt=""/>
                                </div>
                                <div className="property-select p-2 w-100 account-switcher-dropdown" onMouseEnter={handePropertyDropdown} onMouseLeave={handePropertyDropdown}>
                                    <span className="property-switcher"> { propertyName } </span>
                                    <div className={`absolute text-gray-700 pt-1 z-50 w-100 p-2.5 h-40 bg-white overflow-y-scroll overflow-x-hidden ${showPropertyDropdown ? '' : 'hidden'}`} id="style-14">
                                        <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4"><span>Properties: </span></div>
                                        {propertyOptions.map((v,i) =>  (
                                            <div key={i} className='ml-2'>
                                                <span
                                                    onClick={handlePropertyName}
                                                    className='rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none'
                                                >
                                                    { v }
                                                </span>
                                            </div>
                                        ))}
                                        <div className="ml-2 my-3 rounded-t bg-gray-200 py-2 px-4"><span>Staff: </span></div>
                                        {staffOptions.map((v,i) =>  (
                                            <div key={i} className='ml-2'>
                                                <span
                                                    onClick={handlePropertyName}
                                                    className='rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-decoration-none'
                                                >
                                                    { v }
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-logo">
                                <img className="img-fluid logo-light" src="/assets/admin/img/logo-light.png" alt=""/>
                                <img className="img-fluid logo-dark" src="/assets/admin/img/logo-dark.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-12 col-10">
                            <div className="header-bar-right items-start">
                                <ul className="header-icons">
                                    <li className="dropdown">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src="/assets/svgs/system-language-l.svg" className="p-2" alt="" />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                            <span className="triangle-arrow"/>
                                        </div>
                                    </li>
                                    <li className="dropdown">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink2"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src="/assets/svgs/system-activity-stream-l.svg" className="p-2" alt="" />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                            <span className="triangle-arrow"/>
                                        </div>
                                    </li>
                                    <li className="dropdown">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink3"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img src="/assets/svgs/system-message-l.svg" className="p-2" alt="" />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                            <span className="triangle-arrow"/>
                                        </div>
                                    </li>
                                    <li className="dropdown">
                                       <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink4"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                       >
                                            <img src="/assets/svgs/system-notifications-l.svg" className="p-2" alt="" />
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                                            <span className="triangle-arrow"/>
                                        </div>
                                    </li>
                                </ul>
                                <ProfileSelect cookies={cookies}/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* search wraper  */}
                <section className="search-wraper">
                    <div className="row">
                        <div className="col-xl-8 order-lg-2">
                            <div className="search-right-side-wrap">
                                <div className="mobile-menu-trigger">
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                                <div className="serch-bar-side">
                                    <div className="form-group search">
                                        <input type="search" name id placeholder="Type here to Search..."/>
                                        <i className="fas fa-search"/>
                                    </div>
                                </div>
                                <label className="theme-switch">
                                    <span className="switch-dark">dark</span>
                                    <input type="checkbox" id="check-slider"/>
                                    <span className="check-slider round"/>
                                    <span className="switch-light">light</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-xl-4 order-lg-1">
                            <div className={`welcome-wrap ${showPropertyDropdown ? 'z-0' : ''}`}>
                                <div className="welcome-inner">
                                    <h4>Welcome Back <span>Anddy’s Makeover</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* service wraper */}
                <section>
                    <div className="flex justify-between align-items-center">
                        <div className="flex justify-between align-items-center">
                        
                        <h2 className="text-3xl font-bold text-black py-3">Community Link</h2>
                        </div>

                        <button
                        class="bg-gray-300 py-1 mr-2  w-64 flex justify-center align-items-center rounded text-black-800"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLg"
                        >
                        <span>Add Community Link</span>
                        </button>

                        <div
                        class="modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                        id="exampleModalLg"
                        tabindex="-1"
                        aria-labelledby="exampleModalLgLabel"
                        aria-modal="true"
                        aria-hidden="true"
                        role="dialog"
                        >
                        <div class="modal-dialog modal-lg relative w-auto pointer-events-none">
                            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                <h5
                                class="text-xl font-medium leading-normal text-gray-800"
                                id="exampleModalLgLabel"
                                >
                                Add Community Link
                                </h5>
                                <button
                                type="button"
                                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ></button>
                            </div>
                            <div class="modal-body relative p-4">
                                <form className="w-full" id="add-property-form">
                                <div className=" m-auto ">
                                    <div className="md:flex md:items-center w-full mt-6">
                                    <div className="md:w-1/5">
                                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                        To:<span title="Field is required."></span>
                                        </label>
                                    </div>
                                    <div className="md:w-1/5">
                                        <select name="" id="" className="form-control">
                                            <option value="" selected disabled>Select To</option>
                                            <option value="One">One</option>
                                            <option value="Two">Two</option>
                                            <option value="Three">Three</option>
                                        </select>
                                    </div>
                                    
                                    {/* <input
                                        id={"form-submit-button"}
                                        type={"submit"}
                                        value="Add name prefix"
                                        hidden={false}
                                        className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
                                    /> */}
                                    </div>
                                    <div className="md:flex md:items-center w-full mt-6">
                                    <div className="md:w-1/5">
                                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                        Subject: <span title="Field is required."></span>
                                        </label>
                                    </div>
                                    <div className="w-full">
                                        <input type="text" className="form-control" defaultValue={"Subject"} />
                                    </div>
                                    
                                    {/* <input
                                        id={"form-submit-button"}
                                        type={"submit"}
                                        value="Add name prefix"
                                        hidden={false}
                                        className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
                                    /> */}
                                    </div>
                                    <div class="flex  mt-4">
                                    <div class="mb-3 w-100 ">
                                        <label
                                        for="exampleFormControlTextarea1"
                                        className="block capitalize tracking-wide text-gray-700 font-bold mb-2"
                                        >
                                        Message
                                        </label>
                                        <textarea
                                        class="
                    form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        placeholder="Message"
                                        ></textarea>
                                    </div>

                                    </div>
                                    <div className="md:flex md:items-center w-full mt-6">
                                    <div className="md:flex md:items-center w-full mt-6">
                                    <div className="md:w-1/5">
                                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                        Files:<span title="Field is required."></span>
                                        </label>
                                    </div>
                                    <div className="md:w-1/5">
                                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                                        Uplaod Files
                                        </span>
                                        <input
                                        accept="image/*"
                                        type="file"
                                        multiple={true}
                                        hidden={true}
                                        // onChange={(e) => {
                                        //   const pictureLists = [...propertyMeasurementPictures];
                                        //   pictureLists[index] = getImagesAndURLs(e);
                                        //   setPropertyMeasurementPictures(pictureLists);
                                        // }}
                                        />
                                    </label>
                                    </div>
                                    
                                    {/* <input
                                        id={"form-submit-button"}
                                        type={"submit"}
                                        value="Add name prefix"
                                        hidden={false}
                                        className="ml-12 py-3 px-6 border-solid border-2  border-red-200 rounded"
                                    /> */}
                                    </div>
                                    </div>
                                    </div>
                                    <div className="card w-100 mt-3">
                                        <div className="card-header">
                                            <h2 className="text-center">Tokens</h2>
                                            <p><small>You can use the following tokens in your message to personalize your message to the recipients. Be sure to copy the token exactly as it is written for it to work!</small></p>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <span>
                                                    Tenant First Name: {'{TENANT_FIRST}'}
                                                    </span>
                                                    <br />
                                                    <span>
                                                    Tenant Last Name: {'{TENANT_LAST}'}
                                                    </span>
                                                    <br />
                                                    <span>
                                                    Tenant Monthly Rent: {'{MONTHLY_RENT}'}
                                                    </span>
                                                    <br />
                                                    <span>
                                                    Lease Start: {'{LEASE_START}'}
                                                    </span>
                                                    <br />
                                                    <span>
                                                    Lease End: {'{LEASE_END}'}
                                                    </span>
                                                    
                                                </div>
                                                <div className="col-md-6">
                            
                                                    <span>
                                                    Tenant Car: {'{CAR}'}
                                                    </span>
                                                    <br />
                                                    <span>
                                                    Tenant Parking Spot: {'{PARKING_SPOT}'}

                                                    </span>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
        </section>
        {/* <CommunityTable/> */}
        <TestingTable/>


                <div className="bottom-gap"/>
            </div>
            <Footer />
            
        </div>

    )
}
