import React from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const CommunicationBanner = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-app-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-4">
              <div className="md:w-1/12">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Name <span title="Field is required">*</span>:
                </label>
              </div>
              <div className="md:w-3/5">
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`banner-name`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-1/2 mt-4">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Audience:
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`banner-name`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                >
                  <option value="">Select</option>
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-4">
              <div className="flex md:items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Start Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker 
                  
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Start Time:
                  </label>
                </div>
                <div className="md:w-3/5">
                <input
                  type="time"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`start-time`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                />
              </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-4">
              <div className="flex md:items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    End Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker 
                  
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/2">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    End Time:
                  </label>
                </div>
                <div className="md:w-3/5">
                <input
                  type="time"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`end-time`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                />
              </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-1/2 mt-4">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Banner Color:
                </label>
              </div>
              <div className="md:w-3/5">
                <input
                  type="color"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`banner-color`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-1/2 mt-4">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Display On:
                </label>
              </div>
              <div className="md:w-3/5">
                <select
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`display-on`}
                  placeholder="Name"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                >
                  <option value="">Select</option>
                  <option value="Dashboard Only">Dashboard Only</option>
                  <option value="All Pages">All Pages</option>
                </select>
              </div>
            </div>
            <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Announcement:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        // initData={editorData}
                        // onChange={(e) => {
                        //   setEditorData(e.editor.getData());
                        // }}
                      />
                    </div>
                  </div>
                <div className="flex justify-evenly w-full mt-4">
                    <button className="btn3" type="button">Preview</button>
                    <button className="btn3" type="button">Save</button>
                </div>
          </div>
        </div>
      </form>
    </>
  );
};
