import React, { useEffect } from 'react'
import { useState } from 'react'

export const ShotsType = ({typeData,handleShotTypeTrue,pType,pTypes}) => {

  const initialShotData = {
    type: "",
    is_shot_applied: 0,
    applied_date: "",
    expiry_date: "",
    proof_picture: "",
    is_approved_by_owner: 0,
    is_rejected_by_owner: 0,
    rejected_reason: "",
  };

    const [type,setType]=useState([]);
    const [editType,setEditType]=useState([]);
    const [types,setTypes]=useState([]);
    const [editIndex, setEditIndex] = useState(null);

    console.log('@types',types);
    const addData = ()=>{
        const newArray = [];
        newArray.push(type);
        setTypes(prevArray => [...prevArray, ...newArray]);
        handleShotTypeTrue(prevArray => [...prevArray, ...newArray])
        setType(initialShotData);
    }
    const handleDoubleClick = (index) => {
        setEditIndex(index);
        setEditType(types[index]?.type);
      };
    const handleEdit = (index) => {
        const newItems = [...types];
        newItems[index].type = editType;
        setTypes(newItems);
        handleShotTypeTrue(newItems)
        setEditIndex(null);
      };
    
      const handleDelete = (index) => {
        const newItems = [...types];
        newItems.splice(index, 1);
        setTypes(newItems);
        handleShotTypeTrue(newItems)
      };
      function convertStringToArray(str) {
        return str.split("___");
      }
      
      const setDefaultShots = () => {
        const data = pTypes?.map((type) => {
          if (pType == type?.id) {
            return convertStringToArray(type?.default_shots);
          }
        });
      
        let newArray = [];
      
        if (data && data.length) { // Add a check to ensure data has a valid value and length
          data.forEach((types) => {
            if (types) {
              types.forEach((type) => {
                const newData = {
                  ...initialShotData,
                  type: type,
                };
                newArray.push(newData);
              });
            }
          });
        }
      
        setTypes(newArray);
        handleShotTypeTrue(newArray) 
      };
      
      useEffect(() => {
        setType(initialShotData);
        setTypes(typeData?.filter((type) => type.is_shot_applied === 0));
        setDefaultShots();
      }, [pType]);
      
      
      
  return (
    <>
    <div className="md:flex md:items-center mt-4">
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Required Shots:
                    </label>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="type"
                          type="text"
                          value={type?.type}
                          placeholder="FC1"
                          required={false}
                          onChange={(e) => {
                            setType({...type,type:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-auto">
                        <div className="flex justify-between items-center gap-4">
                          <button className="btn3" type='button'
                          onClick={addData}
                          >Add</button>
                        </div>
                      </div>
                    </div>
                    
                        {/* {editText == false ?
                        <div
                            onClick={()=>{
                                setEditText(true);
                            }}
                            >
                            {text}
                        </div>:null}
                        {editText == true ?
                        <input type="text" value={text} onChange={(e)=>{
                            setText(e.target.value);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setEditText(false);
                            }
                          }}
                        />:null} */}
                  </div>
                  <ul>
                        {types?.map((item, index) => (
                            
                            <div className='flex md:w-1/2 mb-3'>
                            <button onClick={() => handleDelete(index)} type="button" class="bg-transparent border-none md:w-6 mx-2  p-0">   
                                <img src='/assets/svgs/system-trashcan.svg' />
                            </button>
                            <li style={{listStyle:'none'}} key={index}>
                            {editIndex === index ? (
                                <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                                value={editType}
                                onChange={(e) => {
                                    setEditType(e.target.value);
                                }}
                                onKeyPress={(e)=>{
                                    if (e.key === "Enter")
                                    {
                                        e.preventDefault();
                                        handleEdit(index)
                                    }
                                }}
                                />
                            ) : (
                                <div onDoubleClick={() => handleDoubleClick(index)}>{item?.type}</div>
                            )}
                            
                            </li>
                            </div>
                        ))}
                        </ul>
    </>
  )
}
