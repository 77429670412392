import React from 'react'

export const MoveinandOutForm = () => {
  return (
    <>
        <div className="w-[90%] flex flex-col m-auto mt-5">
      <div className="flex">
        <div className="flex w-10/12">
          <div className="flex items-start w-1/6">
            <img src="/assets/svgs/property.svg" className="w-full" alt="" />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex w-full">
              <div className="flex flex-col w-1/4 ml-3 gap-2">
                <div>
                  <label className="block tracking-wide w-full text-gray-700 text-3xl font-bold">
                    New heights
                  </label>
                </div>
                <label className="block tracking-wide w-full text-gray-700 text-lg font-semibold">
                  8320 North Peyton way
                </label>
                <label className="block tracking-wide w-full text-gray-700 text-lg font-semibold">
                  west hills ca 91304
                </label>
              </div>
              <div className="flex flex-col w-9/12 gap-3">
                <label className="block tracking-wide uppercase w-full text-gray-700 text-3xl font-extrabold">
                  Move in/out inspection form
                </label>
                <div className="flex w-full items-center">
                  <label className="block tracking-wide uppercase w-1/3 text-gray-700 text-lg font-semibold">
                    move-In inspection
                  </label>
                  <input
                    type="text"
                    className="block appearance-none w-3/5 border-b-2  text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:border-gray-500"
                  />
                </div>
                <div className="flex w-full items-center">
                  <label className="block tracking-wide uppercase w-1/3 text-gray-700 text-lg font-semibold">
                    move-out inspection
                  </label>
                  <input
                    type="text"
                    className="block appearance-none w-3/5 border-b-2 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:border-gray-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-3 items-center">
              <label className="block tracking-wide w-1/4 uppercase  text-gray-700 text-2xl font-bold ml-2">
                Tenants(s)
              </label>
              <input className="block appearance-none w-3/5 border-b-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:border-gray-500" />
            </div>
          </div>
        </div>
        <div className="w-1/6 flex flex-col border-2 border-yellow-500 bg-yellow-100 p-2">
          <label className="block tracking-wide w-1/3 uppercase  text-gray-700 text-2xl font-bold">
            unit:
          </label>
          <label className="block tracking-wide w-1/3 uppercase text-gray-700 text-2xl font-bold">
            deposit(s):
          </label>
          <label className="block tracking-wide w-1/3 capitalize text-gray-700 text-lg font-semibold">
            secuity:
          </label>
          <label className="block tracking-wide w-1/3 capitalize text-gray-700 text-lg font-semibold">
            Pet:
          </label>
          <label className="block tracking-wide w-1/3 capitalize text-gray-700 text-lg font-semibold">
            other:
          </label>
        </div>
      </div>
      <div className="mt-5 w-full flex gap-5">
        <div className="w-1/2">
          <div className="flex justify-center">
            <label className="block tracking-wide w-1/3 uppercase text-center text-gray-700 text-xl font-bold">
              move-in
            </label>
          </div>
          <table className="w-full table-auto">
            <thead className="bg-green-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>bedroom</td>
                <td>n</td>
                <td>s</td>
                <td>o</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>doors/knobs/locks/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Flooring/Baseboards</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Walls/Ceilings/paint</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Windows Covering/Films</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Windows/lock/screens.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Light/Fan/Fan Blades</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Switches/Outlets/Vents</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>closet shelves/Rods/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-auto">
            <thead className="bg-green-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>bathroom</td>
                <td>n</td>
                <td>s</td>
                <td>o</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>doors/knobs/locks/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Flooring/Baseboards</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Walls/Ceilings/paint</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Windows Coverings/Films</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Windows/lock/screens</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Light/Exhaust Fan/Vents</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Switches/Outlets</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Toilet/Toilet Plunger</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>
                  shower/Tub/TubShroom<sup>&reg;</sup>
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Shower Door/curtain/Rail</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>
                  sink/Faucets/SinkShroom<sup>&reg;</sup>
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Plumbing/Drains</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Towel Bar(s)/TP Holder</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Mirrors/Medicine Cabinets</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Cabinets/Counters</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Trash Can/Accessories</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-auto">
            <thead className="bg-green-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>Others Areas:</td>
                <td>n</td>
                <td>s</td>
                <td>o</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>Pantry/Fridges/Freezers</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Common Areas</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Laundry Storage</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-green-100 text-center">
                <td>Garage Storage</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Patio/Backyard (Room A)</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-full bg-green-100">
            <label className="block tracking-wide p-2 capitalize text-gray-700 text-xl font-bold">
              Other Notes:
            </label>
            <textarea className="w-full bg-green-100 p-2 focus:outline-none" rows={5}></textarea>
          </div>
          <div className="flex flex-col mt-3 gap-2">
            <div className="flex justify-between">
              <div className="flex items-center">
                <input type="checkbox" className="w-6 h-6 " />
                <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                  Safety Equipment and Supplies Shown
                </label>
              </div>
              <div className="flex items-center">
                <input type="checkbox" className="w-6 h-6 " />
                <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                  Pics Taken
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <input type="checkbox" className="w-6 h-6" />
              <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                Addendum(s):
              </label>
              <input
                type="text"
                className="block ml-5 appearance-none w-3/5 focus:border-gray-500 border-b-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
              />
            </div>
            <div className="flex items-center">
              <label className="block tracking-wide capitalize text-gray-700 text-lg font-semibold">
                Tenant(s) Signature:
              </label>
              <input
                type="text"
                className="block ml-4 appearance-none w-3/5 border-b-2 focus:border-gray-500 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none"
              />
            </div>
            <div className="flex items-center">
              <label className="block tracking-wide capitalize text-gray-700 text-lg font-semibold">
                Landlord Signature:
              </label>
              <input
                type="text"
                className="block ml-5 appearance-none w-3/5 border-b-2 focus:border-gray-500 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex justify-center">
            <label className="block tracking-wide w-1/3 uppercase text-center text-gray-700 text-xl font-bold">
              move-out
            </label>
          </div>
          <table className="w-full table-auto">
            <thead className="bg-blue-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>bedroom</td>
                <td>s</td>
                <td>o</td>
                <td>d</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>doors/knobs/locks/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Flooring/Baseboards</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Walls/Ceilings/paint</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Windows Covering/Films</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Windows/lock/screens.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Light/Fan/Fan Blades</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Switches/Outlets/Vents</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>closet shelves/Rods/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-auto">
            <thead className="bg-blue-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>bathroom</td>
                <td>s</td>
                <td>o</td>
                <td>d</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>doors/knobs/locks/etc.</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Flooring/Baseboards</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Walls/Ceilings/paint</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Windows Coverings/Films</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Windows/lock/screens</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Light/Exhaust Fan/Vents</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Switches/Outlets</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Toilet/Toilet Plunger</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>
                  shower/Tub/TubShroom<sup>&reg;</sup>
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Shower Door/curtain/Rail</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>
                  sink/Faucets/SinkShroom<sup>&reg;</sup>
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Plumbing/Drains</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Towel Bar(s)/TP Holder</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Mirrors/Medicine Cabinets</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Cabinets/Counters</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Trash Can/Accessories</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-auto">
            <thead className="bg-blue-300 h-12 text-center text-xl capitalize font-bold">
              <tr>
                <td>Others Areas:</td>
                <td>s</td>
                <td>o</td>
                <td>d</td>
                <td>commments:</td>
              </tr>
            </thead>
            <tbody className="capitalize text-lg font-bold">
              <tr className="h-12 text-center">
                <td>Pantry/Fridges/Freezers</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Common Areas</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Laundry Storage</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 bg-blue-100 text-center">
                <td>Garage Storage</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
              <tr className="h-12 text-center">
                <td>Patio/Backyard (Room A)</td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6 bg-transparent" type="checkbox" />
                </td>
                <td>
                  <input className="w-6 h-6" type="checkbox" />
                </td>
                <td>
                  <input type="text" className="block ml-5 bg-transparent appearance-none focus:border-gray-500 border-none border-gray-200 text-gray-700 py-3 px-2 pr-4 leading-tight focus:outline-none " />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-full bg-blue-100">
            <label className="block tracking-wide p-2 capitalize text-gray-700 text-xl font-bold">
              Other Notes:
            </label>
            <textarea className="w-full bg-blue-100 p-2 focus:outline-none" rows={5}></textarea>
          </div>
          <div className="flex flex-col mt-1 border-l-4 border-gray-800 pl-2 ">
            <div className="flex items-center">
              <label className="block tracking-wide capitalize w-1/2 text-gray-700 text-lg font-semibold">
                Tenant Forwarding Address:
              </label>
              <input
                type="text"
                className="block ml-5 appearance-none w-3/5 focus:border-gray-500 border-b-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
              />
            </div>
            <div className="flex justify-between mt-1">
              <div className="flex items-center">
                <input type="checkbox" className="w-6 h-6 " />
                <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                  Safety Equipment and Supplies Shown
                </label>
              </div>
              <div className="flex items-center">
                <input type="checkbox" className="w-6 h-6 " />
                <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                  Pics Taken
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <input type="checkbox" className="w-6 h-6" />
              <label className="block tracking-wide ml-5 capitalize text-gray-700 text-lg font-semibold">
                Addendum(s):
              </label>
              <input
                type="text"
                className="block ml-5 appearance-none w-3/5 focus:border-gray-500 border-b-2 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none "
              />
            </div>
            <div className="flex items-center">
              <label className="block tracking-wide capitalize text-gray-700 text-lg font-semibold">
                Tenant(s) Signature:
              </label>
              <input
                type="text"
                className="block ml-4 appearance-none w-3/5 border-b-2 focus:border-gray-500 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none"
              />
            </div>
            <div className="flex items-center">
              <label className="block tracking-wide capitalize text-gray-700 text-lg font-semibold">
                Landlord Signature:
              </label>
              <input
                type="text"
                className="block ml-5 appearance-none w-3/5 border-b-2 focus:border-gray-500 border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
