import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import pdf from "../../Images/pdf.png";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/button.css";
import "./UnitForm.css";
import { properties } from "../../store/properties/propertiesThunk";
import { useDispatch, useSelector } from "react-redux";
import { getUnitesStatuses } from "../../store/units/unitThunk";
import {
  getUnitMeasurement,
  getUnitMeasurementTypes,
} from "../../store/measurement/Thunk";
import { useParams } from "react-router-dom";
import imgUrl from "../ImageUrl";
import Loading from "../Loader/Loading";
import { UnitMeasurements } from "./UnitMeasurements";
import { UnitMaterials } from "./UnitMaterials";
import { UnitBoards } from "./UnitBoards";
import { UnitNotifications } from "./UnitNotifications";
import FrontTree from "../../tree/FrontTree";
import { UnitLocations } from "./UnitLocations";
import useAlerts from "../../utility/alerts";
import { Lightbox } from "../lightbox/Lightbox";
import { Notes } from "../property/Notes";
import { Tooltip } from "../Loader/Tooltip";
import Swal from "sweetalert2";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";
import FolderStructure from "../../tree/FolderStructure";
export const EditUnitForm = ({
  initialData = {},
  verticalScroll = 0,
  updateFormData,
  handleName
}) => {
  //  CONSTANTS
  // console.log("initialData",initialData)
  const formElement = document.getElementById("add-property-form");
  const formSubmitButton = document.getElementById("form-submit-button");
  const Alert = useAlerts();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.properties?.properties?.data);
  const measurementUnit = useSelector(
    (state) =>
      state?.GetUnitMeasurement?.measurementUnit?.data?.measurements_units
  );
  const measurementType = useSelector(
    (state) => state?.GetUnitMeasurement?.UnitMeasurementTypes?.data
  );
  console.log("measurementUnit", measurementUnit);
  console.log("measurementType", measurementType);

  const [features,setFeatures] = useState([]);
	const [addFeature, setAddFeature] = useState([]);
	const [utilities, setUtilities] = useState();
  const [ckEditor, setCkEditor] = useState();
  const [ckEditor1, setCkEditor1] = useState();
  const [ckEditor2, setCkEditor2] = useState();
  const [ckEditor3, setCkEditor3] = useState();
  const [uniNotesFilesCkEditor, setUniNotesFilesCkEditor] = useState();
  const [files, setFiles] = useState();
  const [handleFile,setHandleFile] = useState();
  const [templateID,setTemplateID] = useState();
  const [unitLocations,setUnitLocations] = useState([]);
  console.log("HandleFile",handleFile);
  console.log("templateID",templateID);
  // console.log('handleFile',handleFile);
  const handleFiles = (message)=>{
    setHandleFile(message);
  }
  const handlePicturesData = (message) => {
    setPropertyFormImagesWithUrl(message);
    let unitCopy = [...message]; // Use the updated 'message' parameter instead of 'propertyFormImagesWithUrl'
    unitCopy.pictures = message.map((picObj, index) => ({
      picture: picObj.picture,
      title: picObj.title,
      caption: picObj.caption,
      order_number: index + 1, // Update the order number to ensure consecutive numbers
      is_cover: picObj.is_cover,
    }));
    setFormData({ ...formData, pictures: unitCopy?.pictures });
  };
  const getPictureTemplateID = (message)=>{
    setTemplateID(message);
  }
  const handleLocationTrue=(message)=>{
    setUnitLocations(message);
  }
  console.log("files", files);
  console.log(ckEditor);
  console.log(uniNotesFilesCkEditor);

  const unitStatuses = useSelector(
    (state) => state?.unitStatuses?.unitStatutes?.data
  );
  console.log("unitStatuses", unitStatuses);

  const [floorData] = useState(() => {
    const numberArray = [];
    for (let i = -5; i <= 200; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });
  const [bedRoomsData] = useState(() => {
    const optionsArray = ["Shared","Other","Studio"];
    for (let i = 1; i <= 200; i++) {
      optionsArray.push(i + " bedrooms");
    }
    // for (let x = 1; x<=14; x++)
    // {
    //   optionsArray.push(x + " studio");
    // }
    return optionsArray;
  });
  const [bathRoomsData] = useState(() => {
    const optionsArray = [];
    for (let i = 0.5; i <= 50; i+=0.5) {
      optionsArray.push(i + " bathrooms");
    }
    return optionsArray;
  });
  const handleMeasurementTrue=(message)=>{
    setUnitMeasurements(message);
  }
  const handleMaterialTrue=(message)=>{
    setUnitMaterials(message);
  }
  const handleBoardTrue=(message)=>{
    setUnitBoardsData(message);
  }
  const handleNotificationTrue=(message)=>{
    setUnitNotices(message);
  }
  const handleNoteTrue = (message)=>{
    setPublicNotes(message)
  }
  const handleStaffNoteTrue = (message)=>{
    setStaffNotes(message)
  }
  const handleManagerNoteTrue = (message)=>{
    setManagerNotes(message)
  }
  const handleOwnerNoteTrue = (message)=>{
    setOwnerNotes(message)
  }
  
  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialContactData = {
    firstname: "",
    lastname: "",
    title: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    email: "",
    info: "",
    picture: null,
  };
  const initialAddUnitData = {
    unitname: "",
    listingactive: "",
    property: "",
    floor: "",
    status: "",
    type: "",
    bedroom: "",
    bathroom: "",
    stairstounit: "",
    stairsinunit: "",
    elevatortounit: "",
    elevatorinunit: "",
    wifinetwork: "",
    wifipassword: "",
    unitalarmcode: "",
    unitgatecode: "",
    unitdoorcode: "",
    unitmailinfo: "",
    unitpropertyinfo: "",
  };

  const initialMeasurementData = {
    name: "",
    location: "",
    type: "",
    measurement_unit: "ft",
    measurement_unit_id:1,
    unit_location_id:1,
    length: "",
    width: "",
    height: "",
    depth: "",
    area: "",
    volume: "",
    pictures: [],
    files: [],
    notes: "",
  };

  const initialMaterialData = {
    material_type_id: "",
    material_location_id: "",
    material_brand_id: "",
    material_identifier_id: "",
    material_vendor_id: "",
    last_installed: "",
    pictures: [
      // {
      // 	picture: null,
      // 	order_number: "",
      // },
    ],
    files: [
      // {
      // 	file: null,
      // },
    ],
    notes: "",
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };

  const initialBoardData = {
    title: "",
    icon: "",
    board: "",
  };

  // STATES

  const [lastEndLine, setLastEndLine] = useState();
  const [unitTypes, setUnitTypes] = useState([]);
  const [addUnit, setAddUnites] = useState([]);
  const [unitMeasurementsType, setUnitMeasurementsTypes] = useState([]);
  const [genderPreference, setGenderPreference] = useState([]);
  console.log("unitMeasurementsType", unitMeasurementsType);
  const [noticePopUp, setNoticePopUp] = useState([]);
  const [unitMaterialTypes, setUnitMaterialTypes] = useState([]);
  console.log("unitMaterialTypes", unitMaterialTypes);
  const [unitMaterialLocations, setUnitMaterialLocations] = useState([]);
  const [unitMaterialBrands, setUnitMaterialBrands] = useState([]);
  const [unitMaterialIdentifiers, setUnitMateriaIdentifiers] = useState([]);
const [loading,setLoading] = useState();
const [propertyParkingSpaces, setPropertyParkingSpaces] = useState([]);
const [parkingSpacePrefix, setParkingSpacePrefix] = useState([]);
const [parkingSpaceSuffix, setParkingSpaceSuffix] = useState([]);
  const [newUnitType, setNewUnitType] = useState({
    id: null,
    type: "",
  });

  const [newMaterialType, setNewMaterialType] = useState({
    id: null,
    type: "",
  });

  console.log("newMaterialType", newMaterialType);
  const [newMaterialLocation, setNewMaterialLocation] = useState({
    id: null,
    location: "",
  });
  const [newMaterialBrand, setNewMaterialBrand] = useState({
    id: null,
    brand: "",
  });
  const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
    id: null,
    identifier: "",
  });

  const [formData, setFormData] = useState();
  const [securedFields, setSecuredFields] = useState({
    propertyWifiPassword: "password",
    propertyAlarmCode: "password",
    propertyGateCode: "password",
    propertyDoorCode: "password",
  });
  console.log("formData", formData);

  const [propertyFormImagesWithUrl, setPropertyFormImagesWithUrl] = useState(
    []
  );

  const [propertyContacts, setPropertyContacts] = useState([]);
  const [propertyAssistantPictures, setPropertyAssistantPictures] = useState(
    []
  );

  const [unitMeasurements, setUnitMeasurements] = useState([]);

  const [measurementsTypes, setMeasurementsTypes] = useState([]);
  console.log("measurementsTypes", measurementsTypes);
  const [unitMeasurementsPictures, setUnitMeasurementsPictures] = useState([]);
  const [unitMeasurementsFiles, setUnitMeasurementsFiles] = useState([]);

  const [unitMaterials, setUnitMaterials] = useState([]);
  const [unitMaterialPictures, setUnitMaterialPictures] = useState([]);
  const [unitMaterialFiles, setUnitMaterialFiles] = useState([]);

  const [propertyHoaFiles, setPropertyHoaFiles] = useState([]);
  const [propertyMortageFiles, setPropertyMortageFiles] = useState([]);
  const [propertyBuilderFiles, setPropertyBuilderFiles] = useState([]);
  const [unitPublicFiles, setUnitPublicFiles] = useState([]);
  const [unitStaffFiles, setUnitStaffFiles] = useState([]);
  const [unitNotesAndFiles, setUnitNotesAndFiles] = useState([]);
  const [unitListingInfo, setUnitListingInfo] = useState([]);
  const [allUtilities, setAllUtilities] = useState([]);
  const [utilitiesIncluded, setUtilitiesIncluded] = useState([]);
  const [utilitiesAddition, setUtilitiesAddition] = useState([]);


  const [unitNotices, setUnitNotices] = useState([]);
  const [unitBoardsData, setUnitBoardsData] = useState([]);
  console.log("@unitPublicFiles", unitPublicFiles);
  const [
    propertyClaimResolutionDocumentsWithUrl,
    setPropertyClaimResolutionDocumentsWithUrl,
  ] = useState([]);

  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [unitManagerFiles, setUnitManagerFiles] = useState([]);
  console.log("unitManagerFiles", unitManagerFiles);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [unitOnwerFiles, setUnitOnwerFiles] = useState([]);
  console.log("unitOnwerFiles", unitOnwerFiles);
  const [unitBoardsIcon, setUnitBoardsIcon] = useState();
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [result,setResult] = useState();
  const [publicNotes,setPublicNotes] = useState([]);
  const [staffNotes,setStaffNotes] = useState([]);
  const [managerNotes,setManagerNotes] = useState([]);
  const [ownerNotes,setOwnerNotes] = useState([]);
	const [dateTaken,setDateTaken] = useState(new Date())
  const PropertyID =parseInt(localStorage.getItem("Property ID"));
  //  METHODS
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);
  
    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;
  
    return TotalTime;
    };
    function stringToTime(timeString) {
    console.warn("timeString",timeString)
    if(timeString === null || timeString === undefined || timeString >= 0) return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1]; 
    let dayNight = ampm[1];

    let time = hours+":"+minutes+" "+dayNight
    let isPM = false;

    if(time.endsWith("am")){
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time

  }
    const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
    };
    function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if(dateString === null || dateString === undefined || dateString >= 0) return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year+"-"+month +"-"+day;
    // console.warn("UnformatDate",date)
    return date;
  }
  const DateCalculator = (date)=>{
		const currentDate = new Date();
		const cDate = new Date(date);
		const diffInMilliseconds = currentDate - cDate;
		const diffInSeconds = diffInMilliseconds / 1000;
		const diffInMinutes = diffInSeconds / 60;
		const diffInHours = diffInMinutes / 60;
		const diffInDays = diffInHours / 24;
		const diffInMonths = diffInDays / 30.44;
		const diffInYears = diffInMonths / 12;
		const years = Math.floor(diffInYears);
		const months = Math.floor((diffInYears - years) * 12);
		const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
		const StringYear = years === 1 ? 'year' : "years";
		const StringMonth = months === 1 ? 'month' : "months";
		const StringDay = days === 1 ? 'day' : "days";
		var result;
		if (days === 0 && years !== 0 && months !== 0) {
			result = years+" "+StringYear+", "+months+" "+StringMonth;
		  } 
		  else if (years === 0 && months !== 0 && days !== 0) {
			result = months+" "+StringMonth+", "+ days+" "+ StringDay;
		  } 
		  else if (months === 0 && years !== 0 && days !== 0) {
			result = years+" "+ StringYear+", "+ days +" "+StringDay;
		  } 
		  else if(days === 0 && months === 0 && years !==0)
		  {
			result = years +" "+StringYear
		  }
		  else if(days === 0 && years === 0 && months !==0)
		  {
			result = months+" "+ StringMonth
		  }
		  else if(years === 0 && months === 0 && days !==0)
		  {
			result = days+" "+ StringDay
		  }
		  else if (years !== 0 || months !== 0 || days !== 0) {
			result = years+" "+ StringYear+", "+months +" "+StringMonth+", "+ days +" "+StringDay;
		  } 

		  else {
			result = "";
		  } 
		  return result;
	}
  const removeByAttr = (arr, attr, value)=>{
		var i = arr.length;
		while(i--){
		   if( arr[i] 
			   && arr[i].hasOwnProperty(attr) && (arr[i][attr] === value ) ){ 
	
			   arr.splice(i,1);
	
		   }
		}
		return arr;
	}
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const base64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        formData?.unit_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };

  const staffFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        console.log("res", res?.data?.data.file);
        //  console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()
        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        //  setUnitPublicFiles({...unitPublicFiles, unit_files:{file:res?.data?.data?.file}});
        formData?.unit_staff_files?.push({ file: res?.data?.data?.file });

        //  setFormData({...formData, unit_files :unitPublicFiles})
        // console.log("base64converted files",files)
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };

  const unitManagerFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        console.log("res", res?.data?.data.file);

        formData?.unit_manager_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };
  const unitOnwerFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        console.log("res", res?.data?.data.file);

        formData?.unit_manager_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };
  function removeCurrencyAndCommas(str) {
    if (str === null) {
      return '';
    }
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  function convertCurrencyToFormat(currency, number) {
    let formattedCurrency = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
    }).format(number);
  
    return formattedCurrency;
  }
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };

  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit board icon"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={`${imgUrl}/${image}`}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;
     
  
  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };

  // APIs
  const getUnitTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-all-units-types"
    );
    if (response.status == 200) {
      setUnitTypes(response.data?.data);
    }
  };

  const getUnitMeasurementTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurement-types"
    );
    if (response.status == 200) {
      setUnitMeasurementsTypes(response?.data?.data);
    }
  };

  // const getGenderPreferences = async () => {
  //   window?.alert()
  //   const response = await ApiRequest.get(
  //     "public/api/auth/get-genders-preferences"
  //   );
  //   console.log("here")
  //   console.log("response?.data?.genders_preferences",response)
  //   if (response.status==200) {
  //     setGenderPreference(response?.data?.data);
  //   }
  // };
  const [smoking,setSmoking] = useState([]);
  const getSmoking = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-smoking-permissions')
    {
      setSmoking(response?.data?.data?.smoking_permissions);
    }
  }

  const getGenderPreferences2 = async () => {
    try {
      const res = await ApiRequest.get(
        "/public/api/auth/get-genders-preferences"
      );
      console.log("response?.data?.data", res?.data?.data?.genders_preferences);
      setGenderPreference(res?.data?.data?.genders_preferences);
    } catch (err) {
      console.log("get-genders-preferences api has error");
    }
  };
  const getFeatures = async ()=>{
		const response = await ApiRequest.get(
			"/public/api/auth/get-features-for-units"
		);
		if(response.status == 200)
		{
			setFeatures(response?.data?.data?.features)
		}
	}
  const getPropertyParkingSpaces = async ()=>{
    const response = await ApiRequest.get(`public/api/auth/get-parking-spaces-by-property?property_id=${PropertyID}`);
    if(response.status == 200){
      setPropertyParkingSpaces(response?.data?.data);
    }
  }
  const getParkingSpacePrefixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-prefixes"
    );
    if (response.status == 200) {
      setParkingSpacePrefix(response?.data?.data?.prefixes);
    }
  };
  const getParkingSpaceSuffixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-suffixes"
    );
    if (response.status == 200) {
      setParkingSpaceSuffix(response?.data?.data?.suffixes);
    }
  };
  const getUtilities = async () => {
    try {
      const res = await ApiRequest.get("/public/api/auth/get-all-utilities");
      console.log("res.data", res?.data?.data?.utilities);
      setAllUtilities(res?.data?.data?.utilities);
    } catch (err) {
      console.log("get-all-utilities api has error");
    }
  };
  const getUnitMaterialsTypes = async () => {
    const response = await ApiRequest.get("public/api/auth/get-material-types");
    if (response.status == 200) {
      setUnitMaterialTypes(response?.data?.data);
    }
  };
  const getUnitMaterialLocations = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-units-locations"
    );
    if (response.status == 200) {
      setUnitMaterialLocations(response.data?.data);
    }
  };

  const getUnitMaterialBrands = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-brands"
    );
    if (response.status == 200) {
      setUnitMaterialBrands(response.data?.data);
    }
  };

  const getUnitMateriaIdentifiers = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-identifiers"
    );
    if (response.status == 200) {
      setUnitMateriaIdentifiers(response.data?.data);
    }
  };

  const addEditUnitType = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/measurement-type",
      payload
    );
    if (response.status == 200) {
      getUnitMeasurementTypes();
    }
  };
  const addEditMaterialsType = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/material-type",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialsTypes();
    }
  };

  const addEditUnitLocation = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/units-location",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialLocations();
    }
  };

  const addEditMaterialBrand = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-brand",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialBrands();
    }
  };

  const addEditMaterialIdentifier = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-identifier",
      payload
    );
    if (response.status == 200) {
      getUnitMateriaIdentifiers();
    }
  };
  const {id} =useParams();
  handleName(formData?.name)
  const getApi = async ()=>{
        const response = await ApiRequest.get(`/public/api/auth/get-unit?unit_id=${id}`)
        setLoading(response.status)
        if (response.status === 200) {
            


            setFormData({
            ...formData, name: response?.data?.data?.name,
            ...formData, is_active: response?.data?.data?.is_active,
            ...formData, property_id: response?.data?.data?.property_id,
            ...formData, floor: response?.data?.data?.floor,
            ...formData, unit_status_id: response?.data?.data?.unit_status_id,
            ...formData, type_id: response?.data?.data?.type_id,
            ...formData, bedrooms: response?.data?.data?.bedrooms,
            ...formData, bathrooms: response?.data?.data?.bathrooms,
            ...formData, stairs_to_unit: response?.data?.data?.stairs_to_unit,
            ...formData, stairs_in_unit: response?.data?.data?.stairs_in_unit,
            ...formData, elevator_to_unit: response?.data?.data?.elevator_to_unit,
            ...formData, elevator_in_unit: response?.data?.data?.elevator_in_unit,
            ...formData, wifi_network: response?.data?.data?.wifi_network,
            ...formData, wifi_password: response?.data?.data?.wifi_password,
            ...formData, alarm_code: response?.data?.data?.alarm_code,
            ...formData, gate_code: response?.data?.data?.gate_code,
            ...formData, door_code: response?.data?.data?.door_code,
            ...formData, mailbox_info: response?.data?.data?.mailbox_info,
      
            ...formData, is_parking_space_assigned: response?.data?.data?.is_parking_space_assigned,
            ...formData, property_parking_space_id: response?.data?.data?.property_parking_space_id,
            ...formData, picture_template_id: response?.data?.data?.picture_template_id,
            ...formData, unit_files: response?.data?.data?.files,
            ...formData, unit_staff_files: response?.data?.data?.staff_files,
            ...formData, unit_manager_files: response?.data?.data?.managers_files,
            ...formData, unit_owner_files: response?.data?.data?.owners_files,
            ...formData, listing_title: response?.data?.data?.listing_title,
            ...formData, listing_description: response?.data?.data?.listing_description,
            ...formData, monthly_rent: response?.data?.data?.monthly_rent,
            ...formData, security_deposit: response?.data?.data?.security_deposit,
            ...formData, last_month_rent_required: response?.data?.data?.last_month_rent_required,
            ...formData, couples_allowed: response?.data?.data?.couples_allowed,
            ...formData, childern_allowed: response?.data?.data?.childern_allowed,
            ...formData, pets_allowed: response?.data?.data?.pets_allowed,
            ...formData, pets_deposit: response?.data?.data?.pets_deposit,
            ...formData, pets_rent: response?.data?.data?.pets_rent,
            ...formData, lgbtq_friendly: response?.data?.data?.lgbtq_friendly,
            ...formData, gender_preference_id: response?.data?.data?.gender_preference_id,
            ...formData, embed: response?.data?.data?.embed,
            ...formData, utilities:response?.data?.data?.utilities,
            ...formData, measurements: response?.data?.data?.measurements,
            ...formData, materials: response?.data?.data?.materials,
            ...formData, features: response?.data?.data?.features,
            ...formData, pictures:response?.data?.data?.pictures,
            ...formData,smoking_permission_id:response?.data?.data?.smoking_permission_id,
            
            })
            setCkEditor3({
                ...ckEditor3, unit_notes : response?.data?.data?.unit_notes,
                ...ckEditor3, other_unit_features: response?.data?.data?.other_unit_features,

            })
            setCkEditor({
                ...ckEditor, other_info : response?.data?.data?.other_info,
                ...ckEditor, pets_more_info: response?.data?.data?.pets_more_info,
                ...ckEditor, unit_staff_notes: response?.data?.data?.unit_staff_notes,
            })
            setCkEditor1({
              ...ckEditor1, unit_manager_notes: response?.data?.data?.unit_manager_notes,
              ...ckEditor1, listing_description: response?.data?.data?.listing_description,
            })
            setCkEditor2({
              ...ckEditor2, unit_owner_notes: response?.data?.data?.unit_owner_notes,

            })
            setUnitNotices(response?.data?.data?.notifications)
            setUnitBoardsData(response?.data?.data?.boards)
            setUnitMeasurements(response?.data?.data?.measurements)
            setUnitMaterials(response?.data?.data?.materials)
            setHandleFile(response?.data?.data?.files)
            setPropertyFormImagesWithUrl(response?.data?.data?.pictures)
            setTemplateID(response?.data?.data?.file_template_id)
            setUnitLocations(response?.data?.data?.unit_locations)
            setUnitPublicFiles(response?.data?.data?.files)
            setUnitStaffFiles(response?.data?.data?.staff_files)
            setUnitManagerFiles(response?.data?.data?.managers_files)
            setUnitOnwerFiles(response?.data?.data?.owners_files)
            setPublicNotes(response?.data?.data?.unit_notes)
            setStaffNotes(response?.data?.data?.unit_staff_notes)
            setManagerNotes(response?.data?.data?.unit_managers_notes)
            setOwnerNotes(response?.data?.data?.unit_owners_notes)
        }
  }

  const addUnitApi = async (payload) => {
    console.log("payload", payload);
    const response = await ApiRequest.post("/public/api/auth/unit", {
      id:parseInt(id),
      name: formData?.name,
      is_active: formData?.is_active,
      property_id: PropertyID,
      floor: formData?.floor,
      unit_status_id: formData?.unit_status_id,
      type_id: formData?.type_id,
      bedrooms: formData?.bedrooms,
      bathrooms: formData?.bathrooms,
      stairs_to_unit: formData?.stairs_to_unit,
      stairs_in_unit: formData?.stairs_in_unit,
      elevator_to_unit: formData?.elevator_to_unit,
      elevator_in_unit: formData?.elevator_in_unit,
      wifi_network: formData?.wifi_network,
      wifi_password: formData?.wifi_password,
      alarm_code: formData?.alarm_code,
      gate_code: formData?.gate_code,
      door_code: formData?.door_code,
      mailbox_info: formData?.mailbox_info,
      other_info: ckEditor?.other_info,
      unit_locations:unitLocations,

      is_parking_space_assigned: formData?.is_parking_space_assigned,
      property_parking_space_id: formData?.property_parking_space_id,
      picture_template_id: null,
      file_template_id: templateID,
      unit_files: handleFile,
      unit_staff_files: formData?.unit_staff_files,
      unit_manager_files: formData?.unit_manager_files,
      unit_owner_files: formData?.unit_owner_files,
      unit_staff_notes: staffNotes,
      unit_notes: publicNotes,
      unit_manager_notes: managerNotes,
      unit_owner_notes: ownerNotes,
      listing_title: formData?.listing_title,
      listing_description: ckEditor1?.listing_description,
      monthly_rent: formData?.monthly_rent,
      security_deposit: formData?.security_deposit,
      last_month_rent_required: formData?.last_month_rent_required,
      couples_allowed: formData?.couples_allowed,
      childern_allowed: formData?.childern_allowed,
      pets_allowed: formData?.pets_allowed,
      pets_deposit: formData?.pets_deposit,
      pets_rent: formData?.pets_rent,
      pets_more_info: ckEditor?.pets_more_info,
      lgbtq_friendly: formData?.lgbtq_friendly,
      smoking_permission_id:formData?.smoking_permission_id,
      gender_preference_id: formData?.gender_preference_id,
      other_unit_features: ckEditor3?.other_unit_features,
      embed: formData?.embed,
      notifications: unitNotices,
      features: formData?.features,
      pictures:formData?.pictures,
      boards:unitBoardsData,
      measurements:unitMeasurements,
      materials:unitMaterials,
      utilities_included_with_rent:formData?.utilities_included_with_rent,
      utilities_addition_to_rent:formData?.utilities_addition_to_rent,


    });
    console.log("response", response);
    if (response.status == 200) {
      console.log("true");
      Alert.addSuccess("Unit Edited Successfully");
    }
  };
  const formSubmitHandler = (e) => {
    addUnitApi();
    e.preventDefault();

    // updateFormData(formData);
  };
  //  useEffect HOOKS

  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const propertyPictures = [];
      propertyFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        propertyPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });

      const contacts = [
        ...propertyContacts
          .map((contact, index) => {
            const pictures = [...propertyAssistantPictures];
            contact.picture = pictures[index]?.image?.picture;
            return contact;
          })
          .filter(
            (contact) =>
              JSON.stringify(contact) !== JSON.stringify(initialContactData)
          ),
      ];

      // const measurements = [
      //   ...unitMeasurements
      //     .map((measurement, index) => {
      //       const pictures = [...unitMeasurementsPictures];
      //       measurement.pictures = pictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...unitMeasurementsFiles];
      //       measurement.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return measurement;
      //     })
      //     .filter(
      //       (measurement) =>
      //         JSON.stringify(measurement) !==
      //         JSON.stringify(initialMeasurementData)
      //     ),
      // ];

      // const materials = [
      //   ...unitMaterials
      //     .map((material, index) => {
      //       const pictures = [...unitMaterialPictures];
      //       material.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...unitMaterialFiles];
      //       material.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return material;
      //     })
      //     .filter(
      //       (material) =>
      //         JSON.stringify(material) !== JSON.stringify(initialMaterialData)
      //     ),
      // ];

      // const notices = [
      //   ...unitNotices.filter(
      //     (notice) =>
      //       JSON.stringify(notice) !== JSON.stringify(initialNoticeData)
      //   ),
      // ];

      // const boards = [
      //   ...unitBoardsData.filter(
      //     (board) => JSON.stringify(board) !== JSON.stringify(initialBoardData)
      //   ),
      // ];

      data.pictures = [...propertyPictures];

      if (JSON.stringify(initialData?.contacts) !== JSON.stringify(contacts)) {
        data.contacts = contacts;
      }

      // if (
      //   JSON.stringify(initialData?.measurements) !==
      //   JSON.stringify(measurements)
      // ) {
      //   data.measurements = measurements;
      // }

      // if (
      //   JSON.stringify(initialData?.materials) !== JSON.stringify(materials)
      // ) {
      //   data.materials = materials;
      // }

      data.hoa_files = [
        ...propertyHoaFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.mortage_files = [
        ...propertyMortageFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.builder_files = [
        ...propertyBuilderFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];

      // if (JSON.stringify(initialData?.notices) !== JSON.stringify(notices)) {
      //   data.notices = notices;
      // }

      // if (JSON.stringify(initialData?.boards) !== JSON.stringify(boards)) {
      //   data.boards = boards;
      // }

      data.property_tenant_files = [
        ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.property_staff_files = [
        ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.unit_manager_files = [
        ...unitManagerFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.unit_owner_files = [
        ...unitOnwerFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      data.unit_files = [
        ...unitPublicFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      data.unit_staff_files = [
        ...unitStaffFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);

  useEffect(() => {
    if (formElement) {
      formSubmitButton.click();
    }
  }, [triggerSubmit]);
  useEffect(() => {
    getPropertyParkingSpaces();
  }, [PropertyID])
  useEffect(() => {
    getOwnerSettings();
    getParkingSpacePrefixes();
    getParkingSpaceSuffixes();
    getGenderPreferences2();
    getUnitTypes();
    getSmoking();
    getApi();
    getFeatures();
    // addUnitApi();
    getUtilities();
    getUnitMeasurementTypes();
    getUnitMaterialsTypes();
    getUnitMaterialLocations();
    getUnitMaterialBrands();
    getUnitMateriaIdentifiers();

    setFormData(initialData);
    dispatch(properties());
    dispatch(getUnitMeasurement());
    // dispatch(getUnitMeasurementTypes());
    dispatch(getUnitesStatuses());
    // setPropertyFormImagesWithUrl(
    //   initialData?.pictures?.map((obj) => ({
    //     image: {
    //       picture: obj?.picture,
    //       title: "",
    //       caption: "",
    //       is_cover: "",
    //     },
    //     url: obj?.picture,
    //   }))
    // );

    // if (initialData?.contacts?.length) {
    //   setPropertyContacts(initialData.contacts);
    //   setPropertyAssistantPictures(
    //     initialData.contacts.map((contact) => contact?.picture)
    //   );
    // } else {
    //   setPropertyContacts([{ ...initialContactData }]);
    //   setPropertyAssistantPictures([{ ...initialImageData }]);
    // }

    // setUnitBoardsIcon({
    //   image: {
    //     picture: initialData?.security_picture,
    //     title: "",
    //     caption: "",
    //     is_cover: "",
    //   },
    //   url: initialData?.security_picture,
    // });

    // if (initialData?.measurements?.length) {
    //   setUnitMeasurements(initialData.measurements);
    //   setUnitMeasurementsPictures(
    //     initialData.measurements.map((measurement) =>
    //       measurement?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setUnitMeasurementsFiles(
    //     initialData.measurements.map((measurement) => measurement?.files)
    //   );
    // } else {
    //   setUnitMeasurements([{ ...initialMeasurementData }]);
    //   setUnitMeasurementsPictures([[]]);
    //   setUnitMeasurementsFiles([[]]);
    // }

    // if (initialData?.materials?.length) {
    //   setUnitMaterials(initialData?.materials);
    //   setUnitMaterialPictures(
    //     initialData.materials.map((material) =>
    //       material?.pictures?.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setUnitMaterialFiles(
    //     initialData.materials.map((material) => material?.files)
    //   );
    // } else {
    //   setUnitMaterials([{ ...initialMaterialData }]);
    //   setUnitMaterialPictures([[]]);
    //   setUnitMaterialFiles([[]]);
    // }
    // setUnitPublicFiles(
    //   initialData?.unit_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyHoaFiles(
    //   initialData?.hoa_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyMortageFiles(
    //   initialData?.mortage_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyBuilderFiles(
    //   initialData?.builder_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );

    // setUnitNotices(
    //   initialData?.notices?.length
    //     ? initialData?.notices
    //     : [{ ...initialNoticeData }]
    // );

    // setUnitBoardsData(
    //   initialData?.boards?.length
    //     ? initialData?.boards
    //     : [{ ...initialBoardData }]
    // );

    // setPropertyPublicDocumentsWithUrl(
    //   initialData?.property_tenant_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyStaffDocumentsWithUrl(
    //   initialData?.property_staff_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setUnitManagerFiles(
    //   initialData?.unit_manager_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setUnitOnwerFiles(
    //   initialData?.unit_owner_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
  }, []);

  useEffect(() => {
    if (formElement) {
      formElement.addEventListener("keypress", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });

      return () => formElement.removeEventListener("keypress", () => {});
    }
  }, [formElement]);
  console.log("formData?.unit_owner_notes", formData?.unit_owner_notes);
  return (
    <>
      {/* Add Property Form  */}
      {loading === undefined ? <Loading/> :
      <>
      
      {/* <div className="flex align-items-center">
            <img
                className="logo w-12 mr-5"
                src="/assets/img/units/unit.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">Editing {formData?.name}</h2>
            </div> */}

      <form
        className="w-full"
        id="add-property-form"
        onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Notifications" {...a11yProps(1)} />
          <Tab label="Unit Boards" {...a11yProps(2)} />
          <Tab label="Unit Notes & Files" {...a11yProps(3)} />
          <Tab label="Unit Measurements" {...a11yProps(4)} />
          <Tab label="Unit Materials" {...a11yProps(5)} />
          <Tab label="Listing Information" {...a11yProps(6)} />
        </Tabs>

        {/* add unit */}
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/unit-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                General information:
              </label>
            </div>
          </div>
          <div className="w-[90%] m-auto main-fields-wrapper mt-5">
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="w-full ">
                <label className=" input-label text-gray-700 font-bold">
                  Unit Name:<span title="Field is required.">*</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={formData?.name}
                  required={true}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>
              <div className="w-full">
                <label className="input-label text-gray-700 font-bold">
                  Listing Active On RentBlaze:
                </label>
                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.is_active}
                    checked={formData?.is_active === 1}
                    required={false}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        is_active: isChecked ? 1 : 0,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
            <div className="form-input-wrapper">
              <div className="w-full">
                <label className=" input-label text-gray-700 font-bold">
                  Property:
                </label>
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={localStorage.getItem('Property Name')}
                  required={false}
                  placeholder="Name"
                  disabled
                  // onChange={(e) => {
                  //   setFormData({ ...formData, property_id: e.target.value });
                  // }}
                />
                  {/* <option selected value=" ">Select Property</option>
                  {state?.map((property) => (
                    <option value={property?.id}>{property?.name}</option>
                  ))}
                </select> */}
              </div>
              <div className="w-full">
              <label className="input-label text-gray-700 flex">
                  <span className="font-bold">Floor:</span>
                  <span title="Field is required.">*</span>
                <Tooltip data={"Select what floor of the Property your Unit is on. For properties that are also the Unit, please select “0”."}/>
                </label>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.floor}
                  required={true}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({ ...formData, floor: e.target.value });
                  }}
                >
                  <option value=" ">Select Floor</option>
                  {floorData.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
              <div className="w-full">
                <label className="input-label text-gray-700 font-bold">
                  Stage:
                  <span title="Field is required.">*</span>
                </label>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.unit_status_id}
                  required={true}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      unit_status_id: e.target.value,
                    });
                  }}
                >
                  <option value=" ">Select Stage</option>
                  {unitStatuses?.map((unitStatus) => {
                    return (
                      <option value={unitStatus?.id}>
                        {unitStatus.status}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-input-wrapper">
              <div className="w-full">
                <label className=" input-label text-gray-700 font-bold">
                  Type:<span title="Field is required.">*</span>
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.type_id}
                  required={true}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({ ...formData, type_id: e.target.value });
                  }}
                >
                  <option value=" ">Select Type</option>
                  {unitTypes.map((unitType) => (
                    <option value={unitType?.id}>{unitType?.type}</option>
                  ))}
                </select>
              </div>
              <div className="w-full">
              <div className="w-full flex">
                <label className="input-label text-gray-700 font-bold flex">
                  Bedrooms:
                  <span title="Field is required.">*</span>
                  <Tooltip data={"Bedrooms: This is how many bedrooms come with this Unit, NOT the total number of bedrooms in the Property"}/>
                </label>
                </div>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.bedrooms}
                  required={false}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({ ...formData, bedrooms: e.target.value });
                  }}
                >
                  <option value=" ">Select</option>
                  {bedRoomsData?.map((bedRoomData) => (
                    <option value={bedRoomData}>{bedRoomData}</option>
                  ))}
                </select>
              </div>
              <div className="w-full">
                  <div className="w-full flex">
                      <label className="input-label text-gray-700 font-bold flex">
                      Bathrooms:
                      <span title="Field is required.">*</span>
                      <Tooltip data={"Bathrooms: This is how many bathrooms come with this Unit, NOT the total number of bathrooms in the Property"}/>
                    </label>
                  </div>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.bathrooms}
                  required={false}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({ ...formData, bathrooms: e.target.value });
                  }}
                >
                  <option value=" ">Select </option>
                  {bathRoomsData?.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              {/* <div className="w-full border-2">
							
						</div> */}

              <div className="w-full">
                <div className="flex items-center gap-2">
                <label className="input-label text-gray-700 font-bold">
                  Stairs to unit:
                  
                </label>

                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.stairs_to_unit}
                    checked={formData?.stairs_to_unit == 1}
                    class="sr-only peer"
                    onChange={(e) => {
                      const isChecked = e.target.checked;

                      setFormData({
                        ...formData,
                        stairs_to_unit: isChecked ? 1 : 0,
                      });
                    }}
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                </div>
              </div>
              <div className="w-full">
              <div className="flex items-center gap-2">
                <label className="input-label text-gray-700 font-bold">
                  Stairs in unit:
                  
                </label>

                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.stairs_in_unit}
                    checked={formData?.stairs_in_unit == 1}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        stairs_in_unit: isChecked ? 1 : 0,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              </div>
              <div className="w-full">
              <div className="flex items-center gap-2">
                <label className="input-label text-gray-700 font-bold">
                  Elevator to unit:
                  
                </label>

                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.elevator_to_unit}
                    checked={formData?.elevator_to_unit == 1}
                    class="sr-only peer"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        elevator_to_unit: isChecked ? 1 : 0,
                      });
                    }}
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              </div>
              <div className="w-full">
              <div className="flex items-center gap-2">
                <label className="input-label text-gray-700 font-bold">
                  Elevator in unit:
                  
                </label>

                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.elevator_in_unit}
                    checked={formData?.elevator_in_unit == 1}
                    class="sr-only peer"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        elevator_in_unit: isChecked ? 1 : 0,
                      });
                    }}
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              </div>
            </div>
            <div className="hr"></div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="md:flex md:items-center mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Wifi Network:
									</label>
								</div>
								<div className="md:w-4/5">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-wifi-network"
										type="text"
										placeholder="Wifi Name"
										value={formData?.wifi_network}
										onChange={(e) => {
											setFormData({
												...formData, wifi_network: e.target.value,
											});
										}}
									/>
								</div>
							</div>
            </div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="md:flex md:items-center mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Wifi Password:
									</label>
								</div>
								<div className="md:w-4/5 relative">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-wifi-password"
										type={securedFields.propertyWifiPassword}
										value = {formData?.wifi_password}
										placeholder="Wifi Password"
										onChange={(e) => {
											setFormData({
												...formData, wifi_password: e.target.value,
											});
										}}
									/>
									<span
										className="absolute right-0 top-0 py-3 px-3"
										onClick={() => {
											setSecuredFields({
												...securedFields,
												propertyWifiPassword:
													securedFields.propertyWifiPassword === "password"
														? "text"
														: "password",
											});
										}}
									>
										{securedFields.propertyWifiPassword === "password" ? (
											<i className="fas fa-eye"></i>
										) : (
											<i className="fas fa-eye-slash"></i>
										)}
									</span>
								</div>
							</div>
            </div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
            
							
							
              <div className="md:flex md:items-center mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Unit Alarm Code:
									</label>
								</div>
								<div className="md:w-4/5 relative">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-alarm-code"
										type={securedFields.propertyAlarmCode}
										value={formData?.alarm_code}
										placeholder="Alarm Code"
										onChange={(e) => {
											setFormData({
												...formData,
												alarm_code: e.target.value,
											});
										}}
									/>
									<span
										className="absolute right-0 top-0 py-3 px-3"
										onClick={() => {
											setSecuredFields({
												...securedFields,
												propertyAlarmCode:
													securedFields.propertyAlarmCode === "password"
														? "text"
														: "password",
											});
										}}
									>
										{securedFields.propertyAlarmCode === "password" ? (
											<i className="fas fa-eye"></i>
										) : (
											<i className="fas fa-eye-slash"></i>
										)}
									</span>
								</div>
							</div>
            </div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="md:flex md:items-center mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Unit Gate Code:
									</label>
								</div>
								<div className="md:w-4/5 relative">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-gate-code"
										type={securedFields.propertyGateCode}
										value={formData?.gate_code}
										placeholder="Gate Code"
										onChange={(e) => {
											setFormData({
												...formData,
												gate_code: e.target.value,
											});
										}}
									/>
									<span
										className="absolute right-0 top-0 py-3 px-3"
										onClick={() => {
											setSecuredFields({
												...securedFields,
												propertyGateCode:
													securedFields.propertyGateCode === "password"
														? "text"
														: "password",
											});
										}}
									>
										{securedFields.propertyGateCode === "password" ? (
											<i className="fas fa-eye"></i>
										) : (
											<i className="fas fa-eye-slash"></i>
										)}
									</span>
								</div>
							</div>
            </div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="md:flex md:items-center mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Unit Door Gate:
									</label>
								</div>
								<div className="md:w-4/5 relative">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-door-code"
										type={securedFields.propertyDoorCode}
										value={formData?.door_code}
										placeholder="Door Code"
										onChange={(e) => {
											setFormData({
												...formData,
												door_code: e.target.value,
											});
										}}
									/>
									<span
										className="absolute right-0 top-0 py-3 px-3"
										onClick={() => {
											setSecuredFields({
												...securedFields,
												propertyDoorCode:
													securedFields.propertyDoorCode === "password"
														? "text"
														: "password",
											});
										}}
									>
										{securedFields.propertyDoorCode === "password" ? (
											<i className="fas fa-eye"></i>
										) : (
											<i className="fas fa-eye-slash"></i>
										)}
									</span>
								</div>
							</div>
            </div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}
              <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/5 flex">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 ">
										Unit Mailbox Info:
									</label>
                  <Tooltip data={'If there is a specific mailbox for this unit, you can enter the information for it here.'}/>
								</div>
								<div className="md:w-4/5">
									<input
										className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
										id="p-mailbox-info"
										type="text"
										placeholder="Mailbox Info"
										value={formData?.mailbox_info}
										onChange={(e) => {
											setFormData({
												...formData,
												mailbox_info: e.target.value,
											});
										}}
									/>
								</div>
							</div>
            </div>
            <div className="w-full">
									<div className="md:flex mt-3">
                  <div className="md:w-1/5 flex">
											<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
												Other Unit Info:
											</label>
                      <Tooltip data={"If there is other information about the unit you want to share with your Tenants, make sure you list it here."}/>
										</div>
										<div className="md:w-4/5">
                    <CKEditor
                  initData={ckEditor?.other_info}
                  // onChange={(editor) =>
                  //   setFormData({
                  //     ...formData,
                  //     other_info: editor.getData()
                  //   })
                  // }
                  onChange={(e) => {
                    const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    setCkEditor({...ckEditor,other_info:cleanText})}}
                />
										</div>
									</div>
								</div>
            <div className="form-input-wrapper">
              {/* <div className="w-full border-2">
							
						</div> */}

{PropertyID ? <div className="w-full flex justify-start gap-3">
                <label className="input-label text-gray-700 font-bold">
                  Assign Parking Space:
         
                </label>
                <label class="inline-flex relative items-center cursor-pointer">
                  {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                  <input
                    type="checkbox"
                    value={formData?.is_parking_space_assigned}
                    checked={formData?.is_parking_space_assigned == 1}
                    class="sr-only peer"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        is_parking_space_assigned: isChecked ? 1 : 0,
                      });
                    }}
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>:null}


              {formData?.is_parking_space_assigned == 1 ?
              <div className="w-full">
                <label className="input-label text-gray-700 font-bold">
                  Space:
                </label>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-property-type"
                  value={formData?.property_parking_space_id}
                  required={false}
                  placeholder="Name"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      property_parking_space_id: e.target.value,
                    });
                  }}
                >
                      <option value="">Select Space</option>
                        {propertyParkingSpaces
                        ?.filter((propertyType) => propertyType?.is_assigned !== 1)
                        ?.map((propertyType) => {
                          const prefix = parkingSpacePrefix?.find(
                            (prefix) => propertyType?.parking_space_prefix_id == prefix?.id
                          )?.prefix;
                          const suffix = parkingSpaceSuffix?.find(
                            (suffix) => propertyType?.parking_space_suffix_id == suffix?.id
                          )?.suffix;
                          const spaceNumber = propertyType?.space_number;

                          const optionValue = propertyType?.id;
                          const optionText = (prefix ?? "") + (spaceNumber ?? "") + (suffix ?? "");

                          return (
                            <option value={optionValue} key={optionValue}>
                              {optionText}
                            </option>
                          );
                        })}

                </select>
              </div>:null}
            </div>
          </div>
          <hr />
              <UnitLocations data={unitLocations} handleLocationTrue={handleLocationTrue} hiddenData={false}/>

        </TabPanel>

        {/* Notification */}
        <TabPanel value={tabValue} index={1}>
            <UnitNotifications data={unitNotices} handleNotificationTrue={handleNotificationTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>

        {/* Property Assistant */}
        <TabPanel value={tabValue} index={2}>
        <UnitBoards data={unitBoardsData} handleBoardTrue={handleBoardTrue} hiddenData={false} u_id={id}/>
        </TabPanel>
        {/* Property Security */}
        <TabPanel value={tabValue} index={3}>
        <div className="md:items-center w-full mt-4">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-and-files.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Notes & Files:
							</label>
              <Tooltip data={"You can add notes and files with different levels of access in order to keep and share the information that you need to!"}/>
						</div>
					</div>
					<div className="md:flex mt-4">
					<div className="md:items-center w-full mt-4">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-files.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Unit Public Files:
							</label>
						</div>
					</div>
						{/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    // id={`p-measurement-pictures-${index}`}
                    type="file"
                    multiple={true}
                    hidden={true}
                    onChange={(e) => {
                      let files = e.target.files;
                      let newImages = [];
                      let maxOrderNumber = 0;
                    
                      // Find the highest order number in the current images
                      unitPublicFiles?.forEach((imageObject) => {
                        if (imageObject.order_number > maxOrderNumber) {
                          maxOrderNumber = imageObject.order_number;
                        }
                      });
                    
                      // Calculate the total number of images (existing images + new images)
                      const totalImages = unitPublicFiles.length + files.length;
                    
                      // Loop through each file and convert to base64
                      files.forEach((file, i) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            const res = await ApiRequest.post(fileApi, {
                              file: fileBase64,
                            });
                    
                            // Add the new image to the array with a unique order number
                            newImages.push({
                              file: res?.data?.data?.file,
                              url:fileBase64
                            });
                    
                            // If all new images have been processed, update the state
                            if (newImages.length === files.length) {
                              let fileLists = [...unitPublicFiles];
                              fileLists = [...fileLists, ...newImages];
                              setUnitPublicFiles(fileLists);
                              let unitCopy = [...unitPublicFiles];
                              unitCopy.files = fileLists.map(
                                (picObj) => ({
                                  file: picObj.file,
                                  // order_number: picObj.order_number,
                                })
                              );
                              setFormData({ ...formData, unit_files: unitCopy?.files });
                            }
                          } catch (err) {
                            // console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });
                    }}
                  />
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount = unitPublicFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
									</label>
								</div>
							</div>
						</div> */}
					</div>

          <FolderStructure handleFiles={handleFiles} folderData={handleFile} getPictureTemplateID = {getPictureTemplateID} setPictureTemplateID = {templateID} PropertyUnitID={id}/>
          
					{/* Gallery  */}

					{/* <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
              {unitPublicFiles.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id="p-HOA-documents-block"
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {unitPublicFiles.map((fileObject, index) =>
                          fileComponent({
                            file: fileObject?.file,
                            key: index,
                            inputFieldId: "unit-public-files",
                            trashAble: true,
                            onTrashClick: () => {
                              const fileLists = [
                                ...formData?.unit_files,
                              ];
                              fileLists.splice(index, 1);
                              formData.unit_files = fileLists;
                              setUnitPublicFiles(fileLists);
                            },
                          })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/8">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-red.png"
								alt=""
							/>
							</div>
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Notes:
									</label>
								</div>
								{/* <div className="md:w-4/5">
                <CKEditor
                  initData={ckEditor3?.unit_notes}
                  onChange={(e) => {
                    const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                    setCkEditor3({...ckEditor3,unit_notes:cleanText})
                  }}
                />
								</div> */}
							</div>
              <Notes data={publicNotes} handleNoteTrue={handleNoteTrue} hiddenData={false} type={"Public"}/>
						</div>
					</div>

        <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
        </TabPanel>
        {/* Property Features */}
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-measurements.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Unit Measurements:
              </label>
              <Tooltip data={"You can track the measurements of anything in your Unit here for quick reference! Windows, room sizes, hallways, etc. If there are measurements for your main Property, you can store them in the same tool under the Property!"}/>
            </div>
          </div>
          {/* {console.log(" unitMeasurements[0]", unitMeasurements[0]?.type)} */}

          <UnitMeasurements data={unitMeasurements} handleMeasurementTrue={handleMeasurementTrue} hiddenData={false} unitID={id}/>


        </TabPanel>
        {/* Property Measurements */}
        <TabPanel value={tabValue} index={5}>
          {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-measurements.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Unit Materials:
              </label>
            </div>
          </div> */}

          <UnitMaterials data={unitMaterials} handleMaterialTrue={handleMaterialTrue} hiddenData={false} ownerSettings={ownerSettings} unitID={id}/>

        </TabPanel>
        {/* Property Materials */}
        <TabPanel value={tabValue} index={6}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/listings.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Listing Information:
              </label>
            </div>
          </div>

          <>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="form-input-wrapper mt-4 mb-3">
                <div className="w-full">
                <label className=" input-label text-gray-700 w-1/4 flex">
                    <span className="font-bold">Listing Title:</span>
                    <Tooltip data={"This will be the title for your listing that is posted on Rent Blaze! We suggest you make it something unique and telling, but not too long!"}/>
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.listing_title}
                    required={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        listing_title: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="md:flex mt-3">
                  <div className="md:w-1/4 flex">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 flex">
                        Listing description:
                      </label>
                        <Tooltip data={"This will be the description of your listing that will appear on Rent Blaze! Feel free to go into detail and really “sell” your unit! Keep in mind, they will see your property/unit pictures and the other information selected on this listing page, so you don’t need to include them in your description."}/>
                    </div>
                    <div className="w-full ">
                      <CKEditor
                        initData={ckEditor1?.listing_description}
                        onChange={(e) => {
                          const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                          setCkEditor1({
                            ...ckEditor1,
                            listing_description: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-input-wrapper mt-4 mb-3">
                <div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Monthly Rent:
                  </label>
                  <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="hoa-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.monthly_rent != null ? formData?.monthly_rent : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, monthly_rent: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, monthly_rent: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                </div>
                <div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Security Deposit:
                  </label>
                  <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="hoa-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.security_deposit != null ? formData?.security_deposit : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, security_deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, security_deposit: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                </div>
              </div>
              <div className="form-input-wrapper mt-4 mb-3">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Last Month Rent Required:
                    <span title="Field is required.">*</span>
                  </label>

                  <label class="inline-flex relative items-center cursor-pointer">
                    {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                    <input
                      type="checkbox"
                      value={formData?.last_month_rent_required}
                      checked={formData?.last_month_rent_required === 1}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          last_month_rent_required: 1,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className="form-input-wrapper mt-4">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Utilities/etc. included with Rent:
   
                  </label>
                </div>
              </div>
              <div className="md:flex flex-wrap md:items-center mt-3">
                  {allUtilities?.sort((a,b)=>a.order_number - b.order_number)?.map((item, index) => {
                    const Utilities = [...allUtilities];
                    const utilitiesIndex = formData?.utilities?.length;
                    const isChecked = formData?.utilities?.find(
                      (utility) => utility.utility_id === item.id
                    );

                    return (
                      <div className="w-1/5" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox"
                            value={item?.id}
                            checked={isChecked ? true : false}
                            onChange={(e) => {
                              const isChecked = e.target.checked === true ? item.id : null;
                              var unCheckedValue =
                                e.target.checked === false ? item.id : "";
                              if (isChecked !== null) {
                                utilitiesIncluded.push({ utility_id: isChecked });
                              } else {
                                removeByAttr(utilitiesIncluded, "utility_id", unCheckedValue);
                              }
                              setFormData({
                                ...formData,
                                utilities_included_with_rent: utilitiesIncluded,
                              });
                            }}
                          />
                          <div className="md:w-auto">
                        <img  src={`${imgUrl}/${item.icon}`} width={"30px"} height={"auto"} className="object-cover rounded"></img>
                        <label className="input-label text-gray-700 mb-2">
                          {item.utility}
                        </label>
                        </div>
                        </div>
                      </div>
                    );
                  })}
                </div>


              {/* <div className="form-input-wrapper mt-4 mb-3">
                    <div className="w-full flex justify-start gap-3">
                      <div class="form-check">
                        <input
                          class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label className="input-label text-gray-700 font-bold">
                          Trash:
                        </label>
                      </div>
                    </div>
                    <div className="w-full flex justify-start gap-3">
                      <div class="form-check">
                        <input
                          class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label className="input-label text-gray-700 font-bold">
                          Internet:
                        </label>
                      </div>
                    </div>
                    <div className="w-full flex justify-start gap-3"></div>
                  </div> */}

              <div className="form-input-wrapper mt-4">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Utilities/etc. In Addition To Rent:
            
                  </label>
                </div>
              </div>
              <div className="md:flex flex-wrap md:items-center mt-3">
                  {allUtilities?.sort((a,b)=>a.order_number - b.order_number)?.map((item, index) => {
                    const Utilities = [...allUtilities];
                    const utilitiesIndex = formData?.utilities?.length;
                    const isChecked = formData?.utilities?.find(
                      (utility) => utility.utility_id === item.id
                    );

                    return (
                      <div className="w-1/5" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox"
                            value={item?.id}
                            checked={isChecked ? true : false}
                            onChange={(e) => {
                              const isChecked = e.target.checked === true ? item.id : null;
                              var unCheckedValue =
                                e.target.checked === false ? item.id : "";
                              if (isChecked !== null) {
                                utilitiesIncluded.push({ utility_id: isChecked });
                              } else {
                                removeByAttr(utilitiesIncluded, "utility_id", unCheckedValue);
                              }
                              setFormData({
                                ...formData,
                                utilities_addition_to_rent: utilitiesIncluded,
                              });
                            }}
                          />
                          <div className="md:w-auto">
                        <img  src={`${imgUrl}/${item.icon}`} width={"30px"} height={"auto"} className="object-cover rounded"></img>
                        <label className="input-label text-gray-700 mb-2">
                          {item.utility}
                        </label>
                        </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

              <div className="form-input-wrapper mt-2 mb-3">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Couples Allowed:
              
                  </label>

                  <label class="inline-flex relative items-center cursor-pointer">
                    {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                    <input
                      type="checkbox"
                      value={formData?.couples_allowed}
                      checked={formData?.couples_allowed === 1}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFormData({
                          ...formData,
                          couples_allowed: isChecked ? 1 : 0,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Childern Allowed:
        
                  </label>

                  <label class="inline-flex relative items-center cursor-pointer">
                    {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                    <input
                      type="checkbox"
                      value={formData?.childern_allowed}
                      checked={formData?.childern_allowed === 1}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFormData({
                          ...formData,
                          childern_allowed: isChecked ? 1 : 0,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Pets Allowed:
    
                  </label>

                  <label class="inline-flex relative items-center cursor-pointer">
                    {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                    <input
                      type="checkbox"
                      value={formData?.pets_allowed}
                      checked={formData?.pets_allowed === 1}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFormData({
                          ...formData,
                          pets_allowed: isChecked ? 1 : 0,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
             {formData?.pets_allowed == 1 ? 
              <>
              <div className="form-input-wrapper mt-4 mb-3">
                <div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Pet Deposit:
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.pets_deposit}
                    required={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        pets_deposit: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Pet Rent:
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.pets_rent}
                    required={true}
                    onChange={(e) => {
                      setFormData({ ...formData, pets_rent: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Info:
                      </label>
                    </div>
                    <div className="w-full ">
                      <CKEditor
                        // initData={formData?.pets_more_info}
                        // onChange={(e) => {
                        //  setFormData({...formData,pets_more_info:e.target.getData()})
                        // }}
                        initData={ckEditor?.pets_more_info}
                        onChange={(e) => {
                          const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                          setCkEditor({
                            ...ckEditor,
                            pets_more_info: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              </>
              :null}
              <div className="form-input-wrapper mt-4 mb-3">
              <div className="md:flex md:items-center w-full mt-3">

              <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Smoking:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="smoking"
                    value={formData?.smoking_permission_id}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        smoking_permission_id: parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value={null}>Select</option>
                    {smoking?.map((smoke)=>(

                    <option value={smoke?.id}>{smoke?.title}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
              </div>
              <div className="form-input-wrapper mt-4 mb-3">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    LGBTQ+ Friendly:
                  </label>

                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      value={formData?.lgbtq_friendly}
                      checked={formData?.lgbtq_friendly === 1}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setFormData({
                          ...formData,
                          lgbtq_friendly: isChecked ? 1 : 0,
                        });
                      }}
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label w-100 text-gray-700 font-bold">
                    Gender Preference:
                    <span title="Field is required.">*</span>
                  </label>

                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa-state"
                    required={true}
                    value={formData?.gender_preference_id}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        gender_preference_id: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Gender</option>
                    {genderPreference?.map((gender) => {
                      return (
                        <option value={gender?.id}>{gender?.gender}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-input-wrapper mt-4 mb-4">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 font-bold">
                    Unit Features:
                  </label>
                </div>
              </div>
              <div className="md:flex flex-wrap md:items-center mt-3">
								{features.map((feature,index) => (
									// const addFeatures = [...features];
									<div className="md:w-1/5 form-check">
										
									<input
										className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
										id={`feature_type-${index}`}
										type="checkbox"
										value={formData?.features}
                    checked={formData?.features && feature?.id === formData?.features[index]?.feature_id}
										onChange={(e) => {
                      var checkedValue = e.target.checked === true ? feature.id : "";
                      var unCheckedValue = e.target.checked === false ? feature.id:"";
                      // var val = ""
                      // console.warn("unCheckedValue",unCheckedValue)
                      // console.warn("CheckedValue",checkedValue)
                      // console.warn("checkFeature",checkFeature)
                      // addFeature.map((feature,index)=>{
                      // 	// console.warn("feature",feature)
                      // 	if(unCheckedValue === feature?.feature_id) 
                      // 	{
                      // 		val =  index
                      // 	};
                      // 	console.warn(val)
                      // })

                      if(checkedValue !== "")
                      {
                        addFeature.push({feature_id:checkedValue})
                      }
                      else
                      {
                        
                        removeByAttr(addFeature, "feature_id",unCheckedValue)
                      }
                      // var checkedIconValue = e.target.checked === true ? feature.icon : "";
                      // checkedValue === "" ? addFeature?.slice(val,1): addFeature?.push({feature_id:checkedValue});
                      // featureAdd.push({feature_name:checkedNameValue});
                      // featureAdd[index].icon = checkedIconValue;
                      setAddFeature(addFeature);
                      setFormData({...formData,features:addFeature})
                    
                  }}
									/>
									<div className="md:w-auto">
									<img  src={`${imgUrl}/${feature.icon}`} width={"30px"} height={"auto"}></img>
									<label className="input-label text-gray-700 mb-2">
										{feature.feature}
									</label>
									</div>
								</div>
								))}
							</div>
              <div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Other Unit Features:
                      </label>
                    </div>
                    <div className="w-full ">
                      <CKEditor
                        // initData={formData?.pets_more_info}
                        // onChange={(e) => {
                        //  setFormData({...formData,pets_more_info:e.target.getData()})
                        // }}
                        initData={ckEditor3?.other_unit_features}
                        onChange={(e) => {
                          const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                          setCkEditor3({
                            ...ckEditor3,
                            other_unit_features: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full m-auto mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Unit Pictures:
                  </label>
                </div>
                <div className="md:w-1/5">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload pictures
                    </span>
                    <input
                      accept="image/*"
                      id={`u-listing-pictures`}
                      type="file"
                      multiple={true}
                      hidden={true}
                            onChange={(e) => {
                              let files = e.target.files;
                              let newImages = [];
                              let maxOrderNumber = 0;
                            
                              // Find the highest order number in the current images
                              propertyFormImagesWithUrl?.forEach((imageObject) => {
                                if (imageObject.order_number > maxOrderNumber) {
                                  maxOrderNumber = imageObject.order_number;
                                }
                              });
                            
                              // Calculate the total number of images (existing images + new images)
                              const totalImages = propertyFormImagesWithUrl.length + files.length;
                            
                              // Loop through each file and convert to base64
                              files.forEach((file, i) => {
                                const reader = new FileReader();
                                reader.addEventListener("load", async () => {
                                  const imgBase64 = reader.result;
                                  try {
                                    const res = await ApiRequest.post(imageApi, {
                                      image: imgBase64,
                                    });
                            
                                    // Add the new image to the array with a unique order number
                                    newImages.push({
                                      picture: res?.data?.data?.image,
                                      title: res?.data?.data?.image,
                                      caption: res?.data?.data?.image,
                                      order_number: maxOrderNumber + i + 1,
                                      url: imgBase64,
                                      is_cover: i === 0 ? 1 : 0,
                                      picture_folder_id:null
                                    });
                            
                                    // If all new images have been processed, update the state
                                    if (newImages.length === files.length) {
                                      let pictureLists = [...propertyFormImagesWithUrl];
                                      pictureLists = [...pictureLists, ...newImages];
                            
                                      // Update the order number of the existing images
                                      pictureLists.forEach((imageObject, index) => {
                                        imageObject.order_number = index + 1;
                                      });
                            
                                      setPropertyFormImagesWithUrl(pictureLists);
                                      let unitCopy = [...propertyFormImagesWithUrl];
                                      unitCopy.pictures = pictureLists.map(
                                        (picObj) => ({
                                          picture: picObj.picture,
                                          title: picObj.title,
                                          caption: picObj.caption,
                                          order_number: picObj.order_number,
                                          is_cover: picObj.is_cover,
                                          picture_folder_id: picObj.picture_folder_id,
                                        })
                                      );
                                      setFormData({ ...formData, pictures: unitCopy?.pictures });
                                    }
                                  } catch (err) {
                                    // console.log(err);
                                  }
                                });
                                reader.readAsDataURL(file);
                              });
                            }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              propertyFormImagesWithUrl?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                    
              </div>
              <Lightbox data={propertyFormImagesWithUrl} handleFiles={handlePicturesData} hiddenData={false}/>
              
              {/* <div >
                    {formData?.pictures??.length ? (
                      <div>
                        <section className="overflow-hidden w-full text-gray-700 mt-4">
                          <div className="container w-full px-3 m-auto">
                            <div
                              id="images-block"
                              className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                            >
                              {formData?.pictures?.map((imageObject, index) =>
                                imageComponent({
                                  image: imageObject?.picture,
                                  key: index,
                                  inputFieldId: "property-images",
                                  trashAble: true,
                                  onTrashClick: () => {
                                    let images = [...formData?.pictures];
                                    let image = [...propertyFormImagesWithUrl]
                                    images.splice(index, 1);
                                    image.splice(index, 1);
                                    setPropertyFormImagesWithUrl(image);
                                    setFormData({...formData,pictures : images})
                                  },
                                })
                              )}
									</div>
								</div>
							</section>
						</div>
					) : null}
				</div> */}
              <hr/>
              <div className="md:flex flex-column md:items-center w-full m-auto">
					      <div className="md:w-full">
						<label className="block capitalize tracking-wide text-gray-700 text-xl w-1/5 font-bold mb-2 flex">
							Unit Embed:
              <Tooltip data={"Paste the embed code for your Unit’s Virtual Tour or video here!"}/>
						</label>
					</div>
					<div className="w-10/12 mx-auto">
						<textarea
							className="appearance-none block w-[90%] m-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="property-embed-text-area"
							value={formData?.embed}
							placeholder='<iframe src="https://yourlink.com"></iframe>'
							onChange={(e) => {
								setFormData({ ...formData, embed: e.target.value });
							}}
						/>
					</div>
				</div>
            </div>
          </>

          {/* Add more */}

          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <button
                    type="button"
                    className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500"
                    onClick={() => {
                      setUnitMaterials([...unitMaterials, initialMaterialData]);
                      setUnitMaterialPictures([
                        ...unitMaterialPictures,
                        [],
                      ]);
                      setUnitMaterialFiles([...unitMaterialFiles, []]);
                    }}
                  >
                    + Add Material
                  </button>
                </label>
              </div>
            </div>
          </div> */}

          <hr/>
        </TabPanel>
          <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
      </form>
      </>
      }
    </>
  );
};
