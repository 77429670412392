import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { CKEditor } from "ckeditor4-react";
import ApiRequest from "../../utility/http";
import Swal from "sweetalert2";
import imgUrl from "../ImageUrl";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";
export const UnitMeasurements = ({data,handleMeasurementTrue,hiddenData,unitID}) => {

    const [unitMeasurements,setUnitMeasurements] = useState([]);
    const [unitMeasurement,setUnitMeasurement] = useState([]);
    console.log("PROPERTY MEASUREMENTS",unitMeasurement);
    // console.error("@PROPERTY MEASUREMENTS",unitMeasurements);
    const [unitMeasurementPictures, setUnitMeasurementPictures] =
    useState([]);
    const [unitMeasurementFiles, setUnitMeasurementFiles] = useState([]);
    const [unitMeasurementUnit, setUnitMeasurementUnit] = useState([]);
    // // console.log('unitMeasurementUnit',unitMeasurementUnit);
  const [unitMeasurementType, setUnitMeasurementType] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [viewForm,setViewForm] =useState(false);
  const [editorData,setEditorData] = useState("");
    // console.log("editorData",editorData);
  const initialMeasurementData = {
    name: "",
    location: "",
    measurement_unit: "",
    measurement_type_id: "",
    unit_location_id: "",
    measurement_unit_id: "",
    length: "",
    width: "",
    height: "",
    depth: "",
    area: "",
    volume: "",
    amount: "",
    pictures: [],
    files: [],
    notes: "",
  };
  const picTypes = useAllowedPicMimeTypes();
  const fileTypes = useAllowedMimeTypes();
  const [editSpace,setEditSpace ]= useState();
  const [indexEdit,setIndexEdit ]= useState();
  // // console.log('@indexEdit',indexEdit);
  const [bulkAction , setBulkAction] = useState({
    bulk_action:""
  });
  const [newMeasuremnetType, setNewMeasuremnetType] = useState({
    id: null,
    type: "",
  });
  const [unitMeasurementLocations,setUnitMeasurementLocations] = useState([]);
  const [newMeasurementLocation, setNewMeasurementLocation] = useState({
    id: null,
    location: "",
  });
  const formElement = document.getElementById("add-unit-form");
  //Methods
  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
    ...restProps
  }) => {
    const confirmEdit = (image) => {
      Swal.fire({
        title: 'Are you sure you want to edit this image?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          // Modify the image object here
          const editedImage = { ...image, title: 'New Title' };
          onEditClick(editedImage);
        }
      });
    };
  
    return image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                type="button"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
            {/* <button
              type="button"
              title="Edit image"
              className="logo w-5 mx-auto my-1"
              onClick={() => confirmEdit(image)}
            >
              <img src="/assets/img/system-edit.png" alt="edit" />
            </button> */}
          </div>
        </div>
      </div>
    ) : (
      <img {...restProps} />
    );
  };
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const myDivRef = useRef(null);
  const fileComponent = ({
    file = null,
    title = null,
    key = 0,
    inputFieldId = "",
    trashAble = false,
    editAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
  }) =>
    file ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
      >
        <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
          <div title={title} key={key} className="block min-w-[600rem] px-2">
            <a href={`${imgUrl}/${file}`} target="_blank">
              {title}
            </a>
          </div>
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
          >
            
            {editAble ? (
              <img
                title="Edit file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/system-edit.png"
                onClick={(e) => {
                  onEditClick();
                }}
                alt=""
              />
            ) : null}
            {trashAble ? (
              <img
                title="Remove file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((input_file) => {
                      if (input_file !== file) {
                        dataTransfer.items.add(input_file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
    const notes = ()=>{
      const data = [];
      data.push(unitMeasurement);
      const array = [...data];
      console.log('@array',array);
      // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      array[0].notes = editorData;
      setUnitMeasurement(array);
    }
    const bulkData = ()=>{
      notes();
        // const array = [unitMeasurement];
        // array.notes = editorData; 
        // setUnitMeasurement(array);
        const newDataArray  = [];
        newDataArray.push(unitMeasurement);
        setShowForm(false)
        handleMeasurementTrue(prevArray => [...prevArray, ...newDataArray])
        setUnitMeasurements(prevArray => [...prevArray, ...newDataArray]);
        setUnitMeasurement(initialMeasurementData);
        setEditorData(null)
        setUnitMeasurementFiles("");
        setUnitMeasurementPictures("");
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...unitMeasurements];
        array[indexEdit] = unitMeasurement;
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[indexEdit].notes = editorData;
        setUnitMeasurements(array);
        setShowForm(false)
        handleMeasurementTrue(array);
        setIndexEdit(undefined);
        setUnitMeasurement(initialMeasurementData);
        setUnitMeasurementFiles([]);
        setUnitMeasurementPictures([]);
        setEditorData(null)
      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setUnitMeasurement(unitMeasurements[index]);
        setEditorData(unitMeasurements[index]?.notes);
        setUnitMeasurementFiles(unitMeasurements[index]?.files)
        setUnitMeasurementPictures(unitMeasurements[index]?.pictures)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setUnitMeasurement(unitMeasurements[index]);
        setUnitMeasurementFiles(unitMeasurements[index]?.files)
        setUnitMeasurementPictures(unitMeasurements[index]?.pictures)
        setEditorData(unitMeasurements[index]?.notes)
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        setIndexEdit(undefined);
        const newArray = [...unitMeasurements];
        newArray.splice(index, 1);
        setUnitMeasurements(newArray);
        setUnitMeasurement(initialMeasurementData);
        handleMeasurementTrue(newArray);
        setEditorData(null)
      }
    const columns = [
        {
          name: "Name",
          selector: (data) => data.name,
          sortable: true,
        },
        {
          name: "Type",
          selector: (data) => unitMeasurementType.map((row)=>{
            if(data.measurement_type_id == row?.id)
            {
              return row?.type
            }
          }),
          sortable: true,
        },
        {
          name: "Location",
          selector: (data) => unitMeasurementLocations.map((row)=>{
            if(data.unit_location_id == row?.id)
            {
              return row?.location
            }
          }),
          sortable: true,
        },
        {
          name: "Measurements",
          selector: (data) => {
            const unit = unitMeasurementUnit.find((row) => data.measurement_unit_id === row?.id)?.unit;
            let measurement = "";
            if (data.width && data.depth && data.height) {
              measurement = `W: ${data.width} ${unit}, H: ${data.height} ${unit} , D: ${data.depth} ${unit}, A: ${data.width * data.height} ${unit}² , V : ${data.height * data.width * data.depth} ${unit}³` ;
            } else if (data.width && data.height) {
              measurement = `W: ${data.width} ${unit}, H: ${data.height} ${unit}, A: ${data.width * data.height} ${unit}²`;
            } else if (data.width) {
              measurement = `W: ${data.width} ${unit}`;
            } else if (data.depth) {
              measurement = `D: ${data.depth} ${unit}`;
            } else if (data.height) {
              measurement = `H: ${data.height} ${unit}`;
            } else {
              measurement = `A: ${data.amount} ${unit}`;
            }
            return measurement;
          },
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
              {/* <div className="flex"> */}
                {hiddenData != true ? (
                  <div className="flex">
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-bs-target={`#ViewMeasurementForm`}
                      data-bs-toggle = 'modal'
                      data-index={index}
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0 mx-3"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        indexFind(index);
                      }}
                    >
                      <img src="/assets/img/system-edit.png" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        deleteData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-trashcan.svg" />
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-index={index}
                      data-bs-target={`#ViewMeasurementForm`}
                      data-bs-toggle = 'modal'
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                  </>
                )}
              {/* </div> */}

              {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
            </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : unitMeasurements,
      };
      
      const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
      
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
      
        doc.setFontSize(15);
      
        const title = "Unit Measurements";
        const headers = [["Name", "Type", "Location", "Measurements"]];
      
        const data = unitMeasurements.map((elt) => {
          const type = unitMeasurementType.find((row) => elt.measurement_type_id === row?.id)?.type;
          const location = unitMeasurementLocations.find((row) => elt.unit_location_id === row?.id)?.location;
          const unitM = unitMeasurementUnit.find((row) => elt.measurement_unit_id === row?.id)?.unit;
          let measurement = "";
          if (elt.width && elt.depth && elt.height) {
            measurement = `W: ${elt.width} ${unitM}, H: ${elt.height} ${unitM} , D: ${elt.depth} ${unitM}, A: ${elt.width * elt.height} ${unitM}² , V : ${elt.height * elt.width * elt.depth} ${unitM}³`;
          } else if (elt.width && elt.height) {
            measurement = `W: ${elt.width} ${unitM}, H: ${elt.height} ${unitM}, A: ${elt.width * elt.height} ${unitM}²`;
          } else if (elt.width) {
            measurement = `W: ${elt.width} ${unitM}`;
          } else if (elt.depth) {
            measurement = `D: ${elt.depth} ${unitM}`;
          } else if (elt.height) {
            measurement = `H: ${elt.height} ${unitM}`;
          } else {
            measurement = `A: ${elt.amount} ${unitM}`;
          }
          return [elt.name, type, location, measurement];
        });
      
        let content = {
          startY: 50,
          head: headers,
          body: data,
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Unit Measurements.pdf");
      };
      
      const exportCSV = () => {
        // Get data from data table
        const TotalData = unitMeasurements.map((elt) => {
          const type = unitMeasurementType.find((row) => elt.measurement_type_id === row?.id)?.type;
          const location = unitMeasurementLocations.find((row) => elt.unit_location_id === row?.id)?.location;
          const unitM = unitMeasurementUnit.find((row) => elt.measurement_unit_id === row?.id)?.unit;
          let measurement = "";
          if (elt.width && elt.depth && elt.height) {
            measurement = `W: ${elt.width} ${unitM}, H: ${elt.height} ${unitM} , D: ${elt.depth} ${unitM}, A: ${elt.width * elt.height} ${unitM}² , V : ${elt.height * elt.width * elt.depth} ${unitM}³`;
          } else if (elt.width && elt.height) {
            measurement = `W: ${elt.width} ${unitM}, H: ${elt.height} ${unitM}, A: ${elt.width * elt.height} ${unitM}²`;
          } else if (elt.width) {
            measurement = `W: ${elt.width} ${unitM}`;
          } else if (elt.depth) {
            measurement = `D: ${elt.depth} ${unitM}`;
          } else if (elt.height) {
            measurement = `H: ${elt.height} ${unitM}`;
          } else {
            measurement = `A: ${elt.amount} ${unitM}`;
          }
          return [elt.name, type, location, measurement];
        });
        // const TotalData = unitMeasurements.map(elt => [elt.name,unitMeasurementType.map((row)=>{
        //   if(elt.measurement_type_id == row?.id)
        //   {
        //     return row?.type
        //   }
        // }),unitMeasurementLocations.map((row)=>{
        //   if(elt.unit_location_id == row?.id)
        //   {
        //     return row?.location
        //   }
        // }),measurement])
        // Convert the data to a CSV string
    
        const headers = ["Name", "Type","Location","Measurements"];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Unit Measurements.csv");
        link.click();
      };
    //Apis

    const getUnitMeasurementUnits = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-measurements-units"
        );
        if (response.status == 200) {
          setUnitMeasurementUnit(response.data?.data?.measurements_units);
        }
      };
      const getMeasurementTypes = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-measurement-types"
        );
        if (response.status == 200) {
          setUnitMeasurementType(response?.data?.data);
        }
      };
      const addEditMeasurementType = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/measurement-type",
          payload
        );
        if (response.status == 200) {
          getMeasurementTypes();
        }
      };
      const getUnitMeasurementLocations = async () => {
        const response = await ApiRequest.get(
          `/public/api/auth/get-units-locations?unit_id=${unitID}`
        );
        if (response.status == 200) {
          setUnitMeasurementLocations(response?.data?.data);
        }
      };
      const addEditMeasurementLocation = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/units-location",
          payload
        );
        if (response.status == 200) {
          getUnitMeasurementLocations();
        }
      };


      useEffect(()=>{
        getUnitMeasurementLocations();
      },[unitID]);
useEffect(() => {
    getUnitMeasurementUnits();
    getMeasurementTypes();
    getUnitMeasurementLocations();
    setUnitMeasurement(initialMeasurementData);
    setUnitMeasurements(data);
}, [])
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

  return (
    
          <>
          <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewMeasurementForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewMeasurementFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Unit Measurements
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setUnitMeasurement([]);
                    setUnitMeasurementFiles([]);
                    setUnitMeasurementPictures([]);
                    setEditorData(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ?
            <>
           
           <div className="md:flex flex-column md:items-center w-[90%] m-auto " ref={myDivRef}>
             <div className="w-full">
               {unitMeasurement.name != '' ?<div className="md:flex md:items-center w-full mt-4">
                 <div className="md:flex md:items-center w-3/7 md:w-1/2">
                   <div className="md:w-1/5">
                     <label className="block capitalize text-gray-700 font-bold mb-2">
                       Name:
                     </label>
                   </div>
                   <div className="md:w-1/5"></div>
                   <div className="md:w-3/5">
                     {/* <input
                       className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                       id={`p-measurement-name`}
                       type="text"
                       value={unitMeasurement.name}
                       disabled={true}
                       placeholder="South Wall"
                      //  onChange={(e) => {
                      //    setUnitMeasurement({...unitMeasurement,name:e.target.value});
                      //  }}
                     /> */}
                     <span>{unitMeasurement.name}</span>
                   </div>
                 </div>
               </div>:null}
               {unitMeasurement.measurement_type_id != '' ?<div className="md:flex md:items-center w-full mt-3">
                 <div className="md:w-1/5">
                   <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Type:
                   </label>
                 </div>
                 <div className="md:w-2/5">
                   {/* <select
                     className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id={`p-measurement_type_id`}
                     value={
                       unitMeasurement.measurement_type_id ||
                       unitMeasurementType[0]
                     }
                     disabled={true}
                    //  onChange={(e) => {
                    //    setUnitMeasurement({...unitMeasurement,measurement_type_id:parseInt(e.target.value)});
                    //  }}
                   >
                    {unitMeasurementType.map((row) => (
                       <option value={row?.id}>{row?.type}</option>
                      ))}
                   </select> */}
                   <span>{unitMeasurementType?.map((row)=>{
                    if(unitMeasurement?.measurement_type_id == row?.id)
                    {
                      return row?.type
                    }
                   })}</span>
                 </div>
                 {/* <div className="p-3">
                   <label className="cursor-pointer">
                     <span
                       className="text-sky-500"
                       onClick={() => {
                         const inputField = document.getElementById(
                           `p-measurement-new-type-field`
                         );
                         formElement
                           .querySelectorAll("input")
                           .forEach((field) => {
                             if (
                               inputField != field &&
                               field.id.includes("measurement-new")
                             )
                               field.hidden = true;
                           });
                         inputField.hidden = !inputField.hidden;
                       }}
                     >
                       +
                     </span>
                   </label>
                 </div>
                 <div className="md:w-1/5">
                   <input
                     className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id={`p-measurement-new-type-field`}
                     type="text"
                     value={newMeasuremnetType.location}
                     placeholder="Jhon"
                     hidden={true}
                     onInput={(e) => {
                       setNewMeasuremnetType({
                         ...newMeasuremnetType,
                         type: e.target.value,
                       });
                     }}
                     onKeyPress={(e) => {
                       if (e.key === "Enter") {
                         e.preventDefault();
                         const payload = newMeasuremnetType.id
                           ? newMeasuremnetType
                           : { type: newMeasuremnetType.type };
                         addEditMeasurementType(payload);
                         e.target.hidden = true;
                       }
                     }}
                   />
                 </div> */}
               </div>:null}
               {unitMeasurement.unit_location_id != '' ?<div className="md:flex md:items-center w-full mt-3">
                 <div className="md:w-1/5">
                   <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Location:
                   </label>
                 </div>
                 <div className="md:w-2/5">
                   {/* <select
                     className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id={`p-measurement-location`}
                     value={
                       unitMeasurement.unit_location_id ||
                       unitMeasurementLocations[0]
                     }
                     disabled={true}
                     onChange={(e) => {
                       const value = parseInt(e.target.value);
                 
                           // setUnitMeasurement({...unitMeasurement})
                           setUnitMeasurement({...unitMeasurement,unit_location_id:value});
     
               
                     }}
                   >
                     {unitMeasurementLocations.map((row) => (
                       <option value={row?.id}>{row?.location}</option>
                     ))}
                   </select> */}
                   <span>{unitMeasurementLocations?.map((row)=>{
                    if(unitMeasurement?.unit_location_id == row?.id)
                    {
                      return row?.location
                    }
                   })}</span>
                 </div>
                 {/* <div className="p-3">
                   <label className="cursor-pointer">
                     <span
                       className="text-sky-500"
                       onClick={() => {
                         const inputField = document.getElementById(
                           `p-measurement-new-location-field`
                         );
                         formElement
                           .querySelectorAll("input")
                           .forEach((field) => {
                             if (
                               inputField != field &&
                               field.id.includes("measurement-new")
                             )
                               field.hidden = true;
                           });
                         inputField.hidden = !inputField.hidden;
                       }}
                     >
                       +
                     </span>
                   </label>
                 </div>
                 <div className="md:w-1/5">
                   <input
                     className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id={`p-measurement-new-location-field`}
                     type="text"
                     value={newMeasurementLocation.location}
                     placeholder="Jhon"
                     hidden={true}
                     onInput={(e) => {
                       setNewMeasurementLocation({
                         ...newMeasurementLocation,
                         location: e.target.value,
                       });
                     }}
                     onKeyPress={(e) => {
                       if (e.key === "Enter") {
                         e.preventDefault();
                         const payload = newMeasurementLocation.id
                           ? newMeasurementLocation
                           : { location: newMeasurementLocation.location };
                         addEditMeasurementLocation(payload);
                         e.target.hidden = true;
                       }
                     }}
                   />
                 </div> */}
               </div>:null}
               {unitMeasurement.measurement_unit_id != '' ?<div className="md:flex md:items-center w-full mt-3">
                 <div className="md:w-1/5">
                   <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Measurement Unit:
                   </label>
                 </div>
                 <div className="md:w-1/5">
                   {/* <select
                     className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                     id={`p-measurement-unit`}
                     value={
                       unitMeasurement.measurement_unit_id ||
                       unitMeasurementUnit[0]
                     }
                     disabled={true} 
                    //  onChange={(e) => {
                    //    unitMeasurement.measurement_unit =
                    //      e.target.value == 1                           
                    //       ? "inch"
                    //        : e.target.value == 2                           
                    //         ? "ft"
                    //        : e.target.value == 3                           
                    //         ? "yd"
                    //        : e.target.value == 4                            
                    //        ? "cm"
                    //        : e.target.value == 5                            
                    //        ? "m"
                    //        : e.target.value == 6                            
                    //        ? "cup"
                    //        : e.target.value == 7                            
                    //        ? "quart"
                    //        : e.target.value == 8                           
                    //         ? "lit"
                    //        : e.target.value == 9                           
                    //         ? "gal"
                    //        : e.target.value == 10
                    //        ? "onc"
                    //        : e.target.value == 11
                    //        ? "pnd"
                    //        : e.target.value == 12
                    //        ? "ton"
                    //        : e.target.value == 13
                    //        ? "mg"
                    //        : e.target.value == 14
                    //        ? "g"
                    //        : e.target.value == 15
                    //        ? "kg"
                    //        : "each";
                    //    setUnitMeasurement({...unitMeasurement,measurement_unit_id:parseInt(e.target.value)});
                       

                    //  }}
                  //  >
                     {/* {unitMeasurementUnit.map((unit) => (
                       <option value={unit?.id}>{unit?.unit}</option>
                     ))}
                   </select> */}
                   <span>{unitMeasurementUnit?.map((row)=>{
                    if(unitMeasurement?.measurement_unit_id == row?.id)
                    {
                      return row?.unit
                    }
                   })}</span>
                 </div>
               </div>:null}
               {unitMeasurement?.measurement_unit_id == "1" ||
           unitMeasurement?.measurement_unit_id == "2" ||
           unitMeasurement?.measurement_unit_id == "3" ||
           unitMeasurement?.measurement_unit_id == "4" ||
           unitMeasurement?.measurement_unit_id == "5" ? (
           <>
                   <div className="md:flex md:items-center w-full mt-3">
                     {unitMeasurement.length != '' ?<div className="md:flex md:justify-center md:items-center md:w-2/4">
                       <div className="md:w-3/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Length/Width:
                         </label>
                       </div>
                       <div className="md:flex md:items-end md:w-2/4">
                         {/* <input
                           className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           id={`p-measurement-length`}
                           type="number"
                           value={unitMeasurement?.length}
                           disabled={true}
                           step={0.5}
                           placeholder="0.0"
                          //  onChange={(e) => {
                          //    const value = parseInt(e.target.value)
                          //    setUnitMeasurement(
                          //      {...unitMeasurement,width:value,length:value});
                          //      // setUnitMeasurement({...unitMeasurement,length:parseInt(e.target.value)});
                          //  }}
                         /> */}
                         <span>{unitMeasurement?.length}</span>
                         <span className="text-sky-500">
                           {unitMeasurement.measurement_unit ||
                             unitMeasurementUnit[0]}
                         </span>
                       </div>
                     </div>:null}
                     {/* <div className="md:flex md:justify-center md:items-center md:w-1/4">
                       <div className="md:w-1/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Width:
                         </label>
                       </div>
                       <div className="md:flex md:items-end md:w-2/4">
                         <input
                           className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           id={`p-measurement-width`}
                           type="number"
                           value={measurement?.width}
                           step={0.5}
                           placeholder="0.0"
                           onChange={(e) => {
                             measurements[index].width = e.target.value;
                             setUnitMeasurements(measurements);
                             setFormData({
                               ...formData,
                               measurements: measurements,
                             });
                           }}
                         />
                         <span className="text-sky-500">
                           {measurement?.measurement_unit ||
                             unitMeasurementUnit[0]}
                         </span>
                       </div>
                     </div> */}
                     {unitMeasurement.height != '' ?<div className="md:flex md:justify-center md:items-center md:w-2/4">
                       <div className="md:w-1/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Height:
                         </label>
                       </div>
                       <div className="md:flex md:items-end md:w-2/4">
                         {/* <input
                           className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           id={`p-measurement-height`}
                           type="number"
                           value={unitMeasurement.height}
                           disabled={true}
                           step={0.5}
                           placeholder="0.0"
                          //  onChange={(e) => {
                          //      setUnitMeasurement({...unitMeasurement,height:parseInt(e.target.value)});
                          //  }}
                         /> */}
                         {unitMeasurement?.height}
                         <span className="text-sky-500">
                           {unitMeasurement.measurement_unit ||
                             unitMeasurementUnit[0]}
                         </span>
                       </div>
                     </div>:null}
                     {unitMeasurement.depth != '' ?<div className="md:flex md:justify-center md:items-center md:w-2/4">
                       <div className="md:w-1/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Depth:
                         </label>
                       </div>
                       <div className="md:flex md:items-end md:w-2/4">
                         {/* <input
                           className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           id={`p-measurement-depth`}
                           type="number"
                           value={unitMeasurement.depth}
                           disabled={true}
                           step={0.5}
                           placeholder="0.5"
                          //  onChange={(e) => {
                          //      setUnitMeasurement({...unitMeasurement,depth:parseInt(e.target.value)});
                          //  }}
                         /> */}
                         {unitMeasurement?.depth}
                         <span className="text-sky-500">
                           {unitMeasurement.measurement_unit ||
                             unitMeasurementUnit[0]}
                         </span>
                       </div>
                     </div>:null}
                   </div>
                   <div className="md:flex md:justify-around md:items-center w-full mt-3">
                     {unitMeasurement.length && unitMeasurement.width != '' ?<div className="md:flex md:justify-center md:items-center w-full md:w-1/3 mb-6">
                       <div className="md:w-1/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Area:
                         </label>
                       </div>
                       <div className="md:w-2/4 text-sky-500">
                         {(unitMeasurement.length || 0) *
                           (unitMeasurement.width || 0)}
                         <span>
                           {unitMeasurement.measurement_unit ||
                             unitMeasurementUnit[0]}
                           <sup>2</sup>
                         </span>
                       </div>
                     </div>:null}
                     {unitMeasurement.length && unitMeasurement.width && unitMeasurement.depth != '' ?<div className="md:flex md:justify-center md:items-center w-full md:w-1/3 mb-6">
                       <div className="md:w-1/4">
                         <label className="block capitalize text-gray-700 font-bold mb-2">
                           Volume:
                         </label>
                       </div>
                       <div className="md:w-2/4 text-sky-500">
                         {(unitMeasurement.length || 0) *
                           (unitMeasurement.width || 0) *
                           (unitMeasurement.height || 0)}
                         <span>
                           {unitMeasurement.measurement_unit ||
                             unitMeasurementUnit[0]}
                           <sup>3</sup>
                         </span>
                       </div>
                     </div>:null}
                   </div>
                   </>
                   ):null}
                   {unitMeasurement?.measurement_unit_id == "6" ||
           unitMeasurement?.measurement_unit_id == "7" ||
           unitMeasurement?.measurement_unit_id == "8" ||
           unitMeasurement?.measurement_unit_id == "9" ||
           unitMeasurement?.measurement_unit_id == "10" ||
           unitMeasurement?.measurement_unit_id == "11" ||
           unitMeasurement?.measurement_unit_id == "12" ||
           unitMeasurement?.measurement_unit_id == "13" ||
           unitMeasurement?.measurement_unit_id == "14" ||
           unitMeasurement?.measurement_unit_id == "15" ||
           unitMeasurement?.measurement_unit_id == "16" ? (
                 <div className="md:flex md:items-center w-full mt-4 mb-4">
                   {unitMeasurement.amount != '' ?<div className="md:flex md:items-center w-3/7 md:w-1/2">
                     <div className="md:w-1/5">
                       <label className="block capitalize text-gray-700 font-bold mb-2">
                         Amount:
                       </label>
                     </div>
                     {/* <div className="md:w-1/5"></div> */}
                     <div className="md:w-1/5">
                       {/* <input
                         className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                         id={`p-measurement-amount`}
                         type="text"
                         value={unitMeasurement.amount}
                         disabled={true}
                         placeholder="0"
                        //  onChange={(e) => {
                        //    setUnitMeasurement({...unitMeasurement,amount:parseInt(e.target.value)});
                        //  }}
                       /> */}
                     {unitMeasurement?.amount}
                     </div>
                     <span className="text-sky-500">
                       {unitMeasurement.measurement_unit ||
                         unitMeasurementUnit[0]}
                     </span>
                   </div>:null}
                 </div>):null}
             
             </div>
           </div>

           {/* Unit Measurement Pictures */}

          {unitMeasurement.pictures.length > 0 ? 
          <>

           {/* Gallery  */}

           <div className="md:flex flex-column w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
             {unitMeasurementPictures?.length ? (
               <div>
                 <div className="md:flex md:items-center w-full m-auto">
                   <div className="md:w-2/5">
                     <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                       Measurement Pictures:
                     </label>
                   </div>
                   {/* <div className="md:w-1/5">
                     <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                       <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                         Upload pictures
                       </span>
                       <input
                         accept={picTypes}
                         id={`p-measurement-pictures`}
                         type="file"
                         multiple={true}
                         hidden={true}
                         disabled={true}
                        //  onChange={(e) => {
                        //    let files = e.target.files;
                        //    let newImages = [];
                        //    let maxOrderNumber = 0;
                         
                        //    // Find the highest order number in the current images
                        //    unitMeasurementPictures?.forEach((imageObject) => {
                        //      if (imageObject.order_number > maxOrderNumber) {
                        //        maxOrderNumber = imageObject.order_number;
                        //      }
                        //    });
                         
                        //    // Calculate the total number of images (existing images + new images)
                        //    const totalImages = unitMeasurementPictures.length + files.length;
                         
                        //    // Loop through each file and convert to base64
                        //    files.forEach((file, i) => {
                        //      const reader = new FileReader();
                        //      reader.addEventListener("load", async () => {
                        //        const imgBase64 = reader.result;
                        //        try {
                        //          const res = await ApiRequest.post(imageApi, {
                        //            image: imgBase64,
                        //          });
                         
                        //          // Add the new image to the array with a unique order number
                        //          newImages.push({
                        //            picture: res?.data?.data?.image,
                        //            order_number: maxOrderNumber + i + 1 + unitMeasurementPictures.length,
                        //            url:imgBase64
                        //          });
                         
                        //          // If all new images have been processed, update the state
                        //          if (newImages.length === files.length) {
                        //            let pictureLists = [...unitMeasurementPictures];
                        //            pictureLists = [...pictureLists, ...newImages];
                        //            setUnitMeasurementPictures(pictureLists);
                        //            let measurementsCopy = [...unitMeasurementPictures];
                        //            measurementsCopy.pictures = pictureLists.map(
                        //              (picObj) => ({
                        //                picture: picObj.picture,
                        //                order_number: picObj.order_number,
                        //              })
                        //            );
                        //            setUnitMeasurement({ ...unitMeasurement, pictures: measurementsCopy?.pictures });
                        //          }
                        //        } catch (err) {
                        //          // console.log(err);
                        //        }
                        //      });
                        //      reader.readAsDataURL(file);
                        //    });
                        //  }}
                         
                         
                         
                         // onChange={(e) => {
                         //   let files = e.target.files;
                         //   files.forEach((file) => {
                         //     const reader = new FileReader();
                         //     reader.addEventListener("load", async () => {
                         //       const imgBase64 = reader.result;
                         //       try {
                         //         // console.log(imgBase64);
                         //         const res = await ApiRequest.post(imageApi, {
                         //           image: imgBase64,
                         //         });
                         //         // console.log("res", res?.data?.data?.image);
                         //         // console.log("index", index);
                         //         // let index = [index];
                         //         // [...index].sort()
                         //         unitMeasurements[index].pictures.push({
                         //           picture: res?.data?.data?.image,
                         //         });
                         //         setFormData({
                         //           ...formData,
                         //           measurements: measurements,
                         //         });
      
                         //         // console.log(
                         //           "unitMeasurements",
                         //           unitMeasurements
                         //         );
      
                         //         // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                         //         // // console.warn("base64converted files",formData)
                         //       } catch (err) {
                         //         // console.log(err);
                         //       }
                         //     });
                         //     reader.readAsDataURL(file);
                         //   });
                         //   const pictureLists = [...unitMeasurementPictures];
                         //   pictureLists[index] = getImagesAndURLs(e);
                         //   setUnitMeasurementPictures(pictureLists);
                         //   // unitMeasurements[index].pictures =
                         //   // 		getImagesAndURLs(e)
                         //   // // console.warn("pictureList",pictureLists);
                         //   // setFormData({...formData, measurements: measurements})
                         // }}
                       />
                     </label>
                   </div>
                   <div className="md:w-1/5">
                     <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                       <span className="text-gray-600">
                         {(() => {
                           const imagesCount =
                             unitMeasurementPictures?.length || 0;
                           return imagesCount > 1
                             ? imagesCount + " Pictures"
                             : imagesCount == 1
                             ? imagesCount + " Picture"
                             : null;
                         }).call()}
                       </span>
                     </label>
                   </div> */}
                 </div>
                 <section className="overflow-hidden w-full text-gray-700 mt-4">
                   <div className="container w-full px-3 m-auto">
                     <div
                       id={`p-measurement-images-block`}
                       className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                     >
                       {unitMeasurementPictures?.map(
                         (imageObject, imageIndex) =>
                           imageComponent({
                             image: imageObject?.picture,
                             key: imageIndex + "-" + imageIndex,
                             src: imgUrl +"/"+imageObject?.picture,
                             inputFieldId: `p-measurement-pictures`,
                             trashAble: false,
                             onTrashClick: () => {
                               const pictureLists = [
                                 ...unitMeasurementPictures,
                               ];
                               pictureLists.splice(imageIndex, 1);
                               unitMeasurement.pictures = pictureLists;
                               setUnitMeasurementPictures(
                                 pictureLists
                               );
                               // setUnitMeasurements(measurements)
                               setUnitMeasurement({
                                 ...unitMeasurement,
                                 pictures: pictureLists,
                               });
                             },
                           })
                       )}
                     </div>
                   </div>
                 </section>
               </div>
             ) : null}
           </div>
            </>:null}
           {/* Measurement Files */}

          {unitMeasurement.files.length > 0 ? <>

           {/* Gallery  */}

           <div >
             {unitMeasurementFiles.length > 0 ? (
               <div>
                 <div className="md:flex md:items-center w-[90%] m-auto">
                   <div className="md:w-1/5">
                     <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                       Measurement Files:
                     </label>
                   </div>
                   {/* <div className="md:w-1/5">
                     <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                       <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                         Upload files
                       </span>
                       <input
                         id={`p-measurement-files`}
                         type="file"
                         accept={fileTypes}
                         multiple={true}
                         hidden={true}
                         disabled={true}
                        //  onChange={(e) => {
                        //    let files = e.target.files;
                        //    let newImages = [];
                        //    let maxOrderNumber = 0;
                         
                        //    // Find the highest order number in the current images
                        //    unitMeasurementFiles?.forEach((imageObject) => {
                        //      if (imageObject.order_number > maxOrderNumber) {
                        //        maxOrderNumber = imageObject.order_number;
                        //      }
                        //    });
                         
                        //    // Calculate the total number of images (existing images + new images)
                        //    const totalImages = unitMeasurementFiles.length + files.length;
                         
                        //    // Loop through each file and convert to base64
                        //    files.forEach((file, i) => {
                        //      const reader = new FileReader();
                        //      reader.addEventListener("load", async () => {
                        //        const fileBase64 = reader.result;
                        //        try {
                        //          const res = await ApiRequest.post(fileApi, {
                        //            file: fileBase64,
                        //          });
                         
                        //          // Add the new image to the array with a unique order number
                        //          newImages.push({
                        //            file: res?.data?.data?.file,
                        //            // order_number: maxOrderNumber + i + 1 + unitMeasurementFiles.length,
                        //            url:fileBase64
                        //          });
                         
                        //          // If all new images have been processed, update the state
                        //          if (newImages.length === files.length) {
                        //            let fileLists = [...unitMeasurementFiles];
                        //            fileLists = [...fileLists, ...newImages];
                        //            setUnitMeasurementFiles(fileLists);
                        //            let measurementsCopy = [...unitMeasurementFiles];
                        //            measurementsCopy.files = fileLists.map(
                        //              (picObj) => ({
                        //                file: picObj.file,
                        //                // order_number: picObj.order_number,
                        //              })
                        //            );
                        //            setUnitMeasurement({ ...unitMeasurement, files: measurementsCopy?.files });
                        //          }
                        //        } catch (err) {
                        //          // console.log(err);
                        //        }
                        //      });
                        //      reader.readAsDataURL(file);
                        //    });
                        //  }}
                         
                       />
                     </label>
                   </div>
                   <div className="md:w-1/2">
                     <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                       <span className="text-gray-600">
                         {(() => {
                           const filesCount =
                             unitMeasurementFiles?.length || 0;
                           return filesCount > 1
                             ? filesCount + " Documents"
                             : filesCount == 1
                             ? filesCount + " Document"
                             : null;
                         }).call()}
                       </span>
                     </label>
                   </div> */}
                 </div>
                 <section className="overflow-hidden w-full text-gray-700 mt-4">
                   <div className="container w-full px-3 m-auto">
                     <div
                       id={`p-measurement-documents-block`}
                       className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                     >
                       {unitMeasurementFiles?.map(
                         (fileObject, fileIndex) =>
                           fileComponent({
                             file: fileObject?.file,
                             title: fileObject?.title,
                             key: fileIndex,
                             inputFieldId: `p-measurement-files`,
                             trashAble: false,
                             onTrashClick: () => {
                               const fileLists = [
                                 ...unitMeasurementFiles,
                               ];
                               fileLists.splice(fileIndex, 1);
                               unitMeasurement.files = fileLists;
                               setUnitMeasurementFiles(fileLists);
                             },
                           })
                       )}
                     </div>
                   </div>
                 </section>
               </div>
             ) : null}
           </div>
           </>:null}
           {unitMeasurement?.notes != '' ?
           <div className="md:flex flex-column md:items-center w-[90%] m-auto">
             <div className="w-full">
               <div className="md:flex mt-3">
                 <div className="md:w-1/5">
                   <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Measurement Notes:
                   </label>
                 </div>
                 <div className="md:w-4/5">
                   {/* <CKEditor
                     initData={unitMeasurement.notes}
                     config={{
                      readOnly: true,
                    }}
                    //  onChange={(e) => {
                    //    setEditorData(e.editor.getData());
                    //    setUnitMeasurement({...unitMeasurement,notes:e.editor.getData()});
                    //  }}
                   /> */}
                   <div dangerouslySetInnerHTML={{ __html: unitMeasurement?.notes }} />
                 </div>
               </div>
             </div>
           </div>:null}
           {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
           <div className="flex justify-end w-full mt-4">
             <div className="w-1/4 pl-5 ml-5 mt-2 ">
               <button
                 type="button"
                 className="btn3"
                 onClick={() => {
                  setViewForm(false);
                 }}
               >
                 Close
               </button>
             </div>
           </div>
         </div> */}
           </>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setUnitMeasurement([]);
                                setUnitMeasurementFiles([]);
                                setUnitMeasurementPictures([]);
                                setEditorData(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
          {showForm == false ? 
          <div className="md:flex flex-column md:items-center w-full m-auto" >
                <div className="flex flex-row-reverse w-full mt-4">
                <div className="w-auto mt-2 ">
                    <button
                    type="button"
                    className="btn3"
                    hidden={hiddenData}
                    onClick={() => {
                     setShowForm(true);
                    }}
                    >
                    <i class="fa fa-plus"></i>Add Measurement
                    </button>
                </div>
                </div>
            </div>:null}
          {showForm == true ? 
          <>
           
            <div className="md:flex flex-column md:items-center w-[90%] m-auto " ref={myDivRef}>
              <div className="w-full">
                <div className="md:flex md:items-center w-full mt-4">
                  <div className="md:flex md:items-center w-full md:w-full">
                    <div className="md:w-1/5">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Name: <span title="Field is Required">*</span>
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-measurement-name`}
                        type="text"
                        value={unitMeasurement.name}
                        placeholder="South Wall"
                        onChange={(e) => {
                          setUnitMeasurement({...unitMeasurement,name:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Type:<span title="Field is Required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-measurement_type_id`}
                      value={
                        unitMeasurement.measurement_type_id ||
                        unitMeasurementType[0]
                      }
                      onChange={(e) => {
                        setUnitMeasurement({...unitMeasurement,measurement_type_id:parseInt(e.target.value)});
                      }}
                    >
                    <option value=" ">Select Type</option>
                     {unitMeasurementType.map((row) => (
                        <option value={row?.id}>{row?.type}</option>
                       ))}
                    </select>
                  </div>
                  <div className="p-3">
                    <label className="cursor-pointer">
                      <span
                        className="text-sky-500"
                        onClick={() => {
                          const inputField = document.getElementById(
                            `p-measurement-new-type-field`
                          );
                          formElement
                            .querySelectorAll("input")
                            .forEach((field) => {
                              if (
                                inputField != field &&
                                field.id.includes("measurement-new")
                              )
                                field.hidden = true;
                            });
                          inputField.hidden = !inputField.hidden;
                        }}
                      >
                        +
                      </span>
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-measurement-new-type-field`}
                      type="text"
                      value={newMeasuremnetType.type}
                      placeholder="Wall"
                      hidden={true}
                      onInput={(e) => {
                        setNewMeasuremnetType({
                          ...newMeasuremnetType,
                          type: e.target.value,
                        });
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          const payload = newMeasuremnetType.id
                            ? newMeasuremnetType
                            : { type: newMeasuremnetType.type };
                          addEditMeasurementType(payload);
                          e.target.hidden = true;
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Location:<span title="Field is Required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-measurement-location`}
                      value={
                        unitMeasurement.unit_location_id ||
                        unitMeasurementLocations[0]
                      }
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                  
                            // setUnitMeasurement({...unitMeasurement})
                            setUnitMeasurement({...unitMeasurement,unit_location_id:value});
      
                
                      }}
                    >
                      <option value=" ">Select Location</option>
                      {unitMeasurementLocations.map((row) => (
                        <option value={row?.id}>{row?.location}</option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="p-3">
                    <label className="cursor-pointer">
                      <span 
                        className="text-sky-500"
                        onClick={() => {
                          const inputField = document.getElementById(
                            `p-measurement-new-location-field`
                          );
                          formElement
                            .querySelectorAll("input")
                            .forEach((field) => {
                              if (
                                inputField != field &&
                                field.id.includes("measurement-new")
                              )
                                field.hidden = true;
                            });
                          inputField.hidden = !inputField.hidden;
                        }}
                      >
                        +
                      </span>
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-measurement-new-location-field`}
                      type="text"
                      value={newMeasurementLocation.location}
                      placeholder="Jhon"
                      hidden={true}
                      onInput={(e) => {
                        setNewMeasurementLocation({
                          ...newMeasurementLocation,
                          location: e.target.value,
                        });
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          const payload = newMeasurementLocation.id
                            ? newMeasurementLocation
                            : { location: newMeasurementLocation.location };
                          addEditMeasurementLocation(payload);
                          e.target.hidden = true;
                        }
                      }}
                    />
                  </div> */}
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Measurement Unit:<span title="Field is Required">*</span>
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-measurement-unit`}
                      value={
                        unitMeasurement.measurement_unit_id ||
                        unitMeasurementUnit[0]
                      }
                      onChange={(e) => {
                        unitMeasurement.measurement_unit =
                          e.target.value == 1                           
                           ? "inch"
                            : e.target.value == 2                           
                             ? "ft"
                            : e.target.value == 3                           
                             ? "yd"
                            : e.target.value == 4                            
                            ? "cm"
                            : e.target.value == 5                            
                            ? "m"
                            : e.target.value == 6                            
                            ? "cup"
                            : e.target.value == 7                            
                            ? "quart"
                            : e.target.value == 8                           
                             ? "lit"
                            : e.target.value == 9                           
                             ? "gal"
                            : e.target.value == 10
                            ? "onc"
                            : e.target.value == 11
                            ? "pnd"
                            : e.target.value == 12
                            ? "ton"
                            : e.target.value == 13
                            ? "mg"
                            : e.target.value == 14
                            ? "g"
                            : e.target.value == 15
                            ? "kg"
                            : "each";
                        setUnitMeasurement({...unitMeasurement,measurement_unit_id:parseInt(e.target.value)});
                        

                      }}
                    >
                      <option value=" ">Select Unit</option>
                      {unitMeasurementUnit.map((unit) => (
                        <option value={unit?.id}>{unit?.unit}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {unitMeasurement?.measurement_unit_id == "1" ||
            unitMeasurement?.measurement_unit_id == "2" ||
            unitMeasurement?.measurement_unit_id == "3" ||
            unitMeasurement?.measurement_unit_id == "4" ||
            unitMeasurement?.measurement_unit_id == "5" ? (
            <>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:justify-center md:items-center md:w-2/4">
                        <div className="md:w-3/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Length/Width:
                          </label>
                        </div>
                        <div className="md:flex md:items-end md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-measurement-length`}
                            type="text"
                            value={unitMeasurement?.length}
                            placeholder="0.0"
                            onChange={(e) => {
                              const value = parseFloat(e.target.value)
                              setUnitMeasurement(
                                {...unitMeasurement,width:value,length:value,amount:''});
                                // setUnitMeasurement({...unitMeasurement,length:parseInt(e.target.value)});
                            }}
                            onKeyPress={(e) => {
                              if (e.key === '.') {
                                e.preventDefault();
                                const inputElement = e.target;
                                const inputValue = inputElement.value;
                                const selectionStart = inputElement.selectionStart;
                                const selectionEnd = inputElement.selectionEnd;
                            
                                // Insert the decimal point at the current cursor position
                                const updatedValue =
                                  inputValue.substring(0, selectionStart) +
                                  inputValue.substring(selectionEnd) +
                                  '.';
                            
                                // Update the input value and move the cursor position
                                setUnitMeasurement({ ...unitMeasurement, width: updatedValue, length: updatedValue }, () => {
                                  inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                                });
                              }
                            }}
                          />
                          <span className="text-sky-500">
                            {unitMeasurement.measurement_unit ||
                              unitMeasurementUnit[0]}
                          </span>
                        </div>
                      </div>
                      {/* <div className="md:flex md:justify-center md:items-center md:w-1/4">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Width:
                          </label>
                        </div>
                        <div className="md:flex md:items-end md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-measurement-width`}
                            type="number"
                            value={measurement?.width}
                            step={0.5}
                            placeholder="0.0"
                            onChange={(e) => {
                              measurements[index].width = e.target.value;
                              setUnitMeasurements(measurements);
                              setFormData({
                                ...formData,
                                measurements: measurements,
                              });
                            }}
                          />
                          <span className="text-sky-500">
                            {measurement?.measurement_unit ||
                              unitMeasurementUnit[0]}
                          </span>
                        </div>
                      </div> */}
                      <div className="md:flex md:justify-center md:items-center md:w-2/4">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Height:
                          </label>
                        </div>
                        <div className="md:flex md:items-end md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-measurement-height`}
                            type="text"
                            value={unitMeasurement.height}
                            placeholder="0.0"
                            onChange={(e) => {
                                setUnitMeasurement({...unitMeasurement,height:parseFloat(e.target.value)});
                            }}
                            onKeyPress={(e) => {
                              if (e.key === '.') {
                                e.preventDefault();
                                const inputElement = e.target;
                                const inputValue = inputElement.value;
                                const selectionStart = inputElement.selectionStart;
                                const selectionEnd = inputElement.selectionEnd;
                            
                                // Insert the decimal point at the current cursor position
                                const updatedValue =
                                  inputValue.substring(0, selectionStart) +
                                  inputValue.substring(selectionEnd) +
                                  '.';
                            
                                // Update the input value and move the cursor position
                                setUnitMeasurement({ ...unitMeasurement, height: updatedValue }, () => {
                                  inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                                });
                              }
                            }}
                          />
                          <span className="text-sky-500">
                            {unitMeasurement.measurement_unit ||
                              unitMeasurementUnit[0]}
                          </span>
                        </div>
                      </div>
                      <div className="md:flex md:justify-center md:items-center md:w-2/4">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Depth:
                          </label>
                        </div>
                        <div className="md:flex md:items-end md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-measurement-depth`}
                            type="text"
                            value={unitMeasurement.depth}
                            placeholder="0.5"
                            onChange={(e) => {
                                setUnitMeasurement({...unitMeasurement,depth:parseFloat(e.target.value)});
                            }}
                            onKeyPress={(e) => {
                              if (e.key === '.') {
                                e.preventDefault();
                                const inputElement = e.target;
                                const inputValue = inputElement.value;
                                const selectionStart = inputElement.selectionStart;
                                const selectionEnd = inputElement.selectionEnd;
                            
                                // Insert the decimal point at the current cursor position
                                const updatedValue =
                                  inputValue.substring(0, selectionStart) +
                                  inputValue.substring(selectionEnd) +
                                  '.';
                            
                                // Update the input value and move the cursor position
                                setUnitMeasurement({ ...unitMeasurement, depth: updatedValue }, () => {
                                  inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                                });
                              }
                            }}
                          />
                          <span className="text-sky-500">
                            {unitMeasurement.measurement_unit ||
                              unitMeasurementUnit[0]}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:justify-around md:items-center w-full mt-3">
                      <div className="md:flex md:justify-center md:items-center w-full md:w-1/3 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Area:
                          </label>
                        </div>
                        <div className="md:w-2/4 text-sky-500">
                          {(unitMeasurement.width || 0) *
                            (unitMeasurement.height || 0)}
                          <span>
                            {unitMeasurement.measurement_unit ||
                              unitMeasurementUnit[0]}
                            <sup>2</sup>
                          </span>
                        </div>
                      </div>
                      <div className="md:flex md:justify-center md:items-center w-full md:w-1/3 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Volume:
                          </label>
                        </div>
                        <div className="md:w-2/4 text-sky-500">
                          {(unitMeasurement.depth || 0) *
                            (unitMeasurement.width || 0) *
                            (unitMeasurement.height || 0)}
                          <span>
                            {unitMeasurement.measurement_unit ||
                              unitMeasurementUnit[0]}
                            <sup>3</sup>
                          </span>
                        </div>
                      </div>
                    </div>
                    </>
                    ):null}
                    {unitMeasurement?.measurement_unit_id == "6" ||
            unitMeasurement?.measurement_unit_id == "7" ||
            unitMeasurement?.measurement_unit_id == "8" ||
            unitMeasurement?.measurement_unit_id == "9" ||
            unitMeasurement?.measurement_unit_id == "10" ||
            unitMeasurement?.measurement_unit_id == "11" ||
            unitMeasurement?.measurement_unit_id == "12" ||
            unitMeasurement?.measurement_unit_id == "13" ||
            unitMeasurement?.measurement_unit_id == "14" ||
            unitMeasurement?.measurement_unit_id == "15" ||
            unitMeasurement?.measurement_unit_id == "16" ? (
                  <div className="md:flex md:items-center w-full mt-4 mb-4">
                    <div className="md:flex md:items-center w-3/7 md:w-1/2">
                      <div className="md:w-1/5">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Amount:
                        </label>
                      </div>
                      <div className="md:w-1/5"></div>
                      <div className="md:w-2/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-measurement-amount`}
                          type="number"
                          value={unitMeasurement.amount}
                          placeholder="0"
                          onChange={(e) => {
                            setUnitMeasurement({...unitMeasurement,amount:parseFloat(e.target.value),length:'',width:'',depth:'',height:''});
                          }}
                        />
                      </div>
                      <span className="text-sky-500">
                        {unitMeasurement.measurement_unit ||
                          unitMeasurementUnit[0]}
                      </span>
                    </div>
                  </div>):null}
              
              </div>
            </div>

            {/* Unit Measurement Pictures */}

            <div className="md:flex md:items-center w-[90%] m-auto mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Measurement Pictures:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload pictures
                  </span>
                  <input
                    accept={picTypes}
                    id={`p-measurement-pictures`}
                    type="file"
                    multiple={true}
                    hidden={true}
                    onChange={(e) => {
                      let files = e.target.files;
                      let newImages = [];
                      let maxOrderNumber = 0;
                    
                      // Find the highest order number in the current images
                      unitMeasurementPictures?.forEach((imageObject) => {
                        if (imageObject.order_number > maxOrderNumber) {
                          maxOrderNumber = imageObject.order_number;
                        }
                      });
                    
                      // Calculate the total number of images (existing images + new images)
                      const totalImages = unitMeasurementPictures.length + files.length;
                    
                      // Loop through each file and convert to base64
                      files.forEach((file, i) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            const res = await ApiRequest.post(imageApi, {
                              image: imgBase64,
                            });
                    
                            // Add the new image to the array with a unique order number
                            newImages.push({
                              picture: res?.data?.data?.image,
                              order_number: maxOrderNumber + i + 1 + unitMeasurementPictures.length,
                              url:imgBase64
                            });
                    
                            // If all new images have been processed, update the state
                            if (newImages.length === files.length) {
                              let pictureLists = [...unitMeasurementPictures];
                              pictureLists = [...pictureLists, ...newImages];
                              setUnitMeasurementPictures(pictureLists);
                              let measurementsCopy = [...unitMeasurementPictures];
                              measurementsCopy.pictures = pictureLists.map(
                                (picObj) => ({
                                  picture: picObj.picture,
                                  order_number: picObj.order_number,
                                })
                              );
                              setUnitMeasurement({ ...unitMeasurement, pictures: measurementsCopy?.pictures });
                            }
                          } catch (err) {
                            // console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });
                    }}
                    
                    
                    
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   files.forEach((file) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const imgBase64 = reader.result;
                    //       try {
                    //         // console.log(imgBase64);
                    //         const res = await ApiRequest.post(imageApi, {
                    //           image: imgBase64,
                    //         });
                    //         // console.log("res", res?.data?.data?.image);
                    //         // console.log("index", index);
                    //         // let index = [index];
                    //         // [...index].sort()
                    //         unitMeasurements[index].pictures.push({
                    //           picture: res?.data?.data?.image,
                    //         });
                    //         setFormData({
                    //           ...formData,
                    //           measurements: measurements,
                    //         });

                    //         // console.log(
                    //           "unitMeasurements",
                    //           unitMeasurements
                    //         );

                    //         // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                    //         // // console.warn("base64converted files",formData)
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    //   const pictureLists = [...unitMeasurementPictures];
                    //   pictureLists[index] = getImagesAndURLs(e);
                    //   setUnitMeasurementPictures(pictureLists);
                    //   // unitMeasurements[index].pictures =
                    //   // 		getImagesAndURLs(e)
                    //   // // console.warn("pictureList",pictureLists);
                    //   // setFormData({...formData, measurements: measurements})
                    // }}
                  />
                </label>
              </div>
              <div className="md:w-1/5">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const imagesCount =
                        unitMeasurementPictures?.length || 0;
                      return imagesCount > 1
                        ? imagesCount + " Pictures"
                        : imagesCount == 1
                        ? imagesCount + " Picture"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div>
            </div>

            {/* Gallery  */}

            <div >
              {unitMeasurementPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {unitMeasurementPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...unitMeasurementPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                unitMeasurement.pictures = pictureLists;
                                setUnitMeasurementPictures(
                                  pictureLists
                                );
                                // setUnitMeasurements(measurements)
                                setUnitMeasurement({
                                  ...unitMeasurement,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>

            {/* Measurement Files */}

            <div className="md:flex md:items-center w-[90%] m-auto">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Measurement Files:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload files
                  </span>
                  <input
                    id={`p-measurement-files`}
                    type="file"
                    accept={fileTypes}
                    multiple={true}
                    hidden={true}
                    onChange={(e) => {
                      let files = e.target.files;
                      let newImages = [];
                      let maxOrderNumber = 0;
                    
                      // Find the highest order number in the current images
                      unitMeasurementFiles?.forEach((imageObject) => {
                        if (imageObject.order_number > maxOrderNumber) {
                          maxOrderNumber = imageObject.order_number;
                        }
                      });
                    
                      // Calculate the total number of images (existing images + new images)
                      const totalImages = unitMeasurementFiles.length + files.length;
                    
                      // Loop through each file and convert to base64
                      files.forEach((file, i) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            const res = await ApiRequest.post(fileApi, {
                              file: fileBase64,
                            });
                    
                            // Add the new image to the array with a unique order number
                            newImages.push({
                              file: res?.data?.data?.file,
                              title: res?.data?.data?.file,
                              url:fileBase64
                            });
                    
                            // If all new images have been processed, update the state
                            if (newImages.length === files.length) {
                              let fileLists = [...unitMeasurementFiles];
                              fileLists = [...fileLists, ...newImages];
                              setUnitMeasurementFiles(fileLists);
                              let measurementsCopy = [...unitMeasurementFiles];
                              measurementsCopy.files = fileLists.map(
                                (picObj) => ({
                                  file: picObj.file,
                                  title: picObj.title,
                                })
                              );
                              setUnitMeasurement({ ...unitMeasurement, files: measurementsCopy?.files });
                            }
                          } catch (err) {
                            // console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });
                    }}
                    
                  />
                </label>
              </div>
              <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount =
                        unitMeasurementFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div>
            </div>

            {/* Gallery  */}

            <div >
              {unitMeasurementFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {unitMeasurementFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...unitMeasurementFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                unitMeasurement.files = fileLists;
                                setUnitMeasurementFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...unitMeasurementFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    unitMeasurement.files[fileIndex].title = title;
                                    setUnitMeasurementFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Measurement Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData}
                      onChange={(e) => {
                        setEditorData(e.editor.getData());
                        // setUnitMeasurement({...unitMeasurement,notes:e.editor.getData()});
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  onClick={() => {
                    if (indexEdit !== undefined) {
                      if(!unitMeasurement?.name?.trim() || !unitMeasurement?.measurement_type_id || !unitMeasurement?.unit_location_id || !unitMeasurement?.measurement_unit_id)
                      {
                        Toast.fire({
                          icon:'warning',
                          title:'Please fill in the required fields',
          
                        })
                      }
                      else{
                      editSpacesData();
                      }
                    } else {
                      if(!unitMeasurement?.name?.trim() || !unitMeasurement?.measurement_type_id || !unitMeasurement?.unit_location_id || !unitMeasurement?.measurement_unit_id)
                      {
                        Toast.fire({
                          icon:'warning',
                          title:'Please fill in the required fields',
          
                        })
                      }
                      else{

                        bulkData();
                      }
                    }
                  }}
                >
                  {indexEdit !== undefined ? (
                    <><i className='fa fa-edit'></i>Update</>
                  ) : (
                    <><i className='fa fa-save'></i>Save</>
                  )}
                </button>
              </div>
            </div>
          </div>
            </>
            :null}
            
            <hr/>
            {unitMeasurements?.length > 0 ?<>
            <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={unitMeasurements}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
          </>
  )
}
