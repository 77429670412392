import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withCookies } from "react-cookie";
import { PrivateRoute, PublicRoute } from "./router/withRouter";
import { mainRoutes } from "./router/routes";
import { Toaster } from "react-hot-toast";
import Roles from "./components/Roles";
import useAlerts from "./utility/alerts";
import { ErrorPage } from "./ErrorPage";
import ScrollToTop from "./pages/ScrollToTop";

const App = (props) => {
  const Alert = useAlerts();
  const withAuth = (WrappedComponent, allowedRoles, currentUserRole) => {
    return (props) => {
      if (!allowedRoles.includes(currentUserRole)) {
        Alert.addFailure("Permission Denied")
        // history.goBack();
        return <ErrorPage/> // or you can return an error component instead of null
      }
      // if(allowedRoles == "All")
      // {
      //   return <WrappedComponent {...props} />;
      // }
      return <WrappedComponent {...props} />;
    }
  }
  {
    console.log("props.cookies", Roles);
  }
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          {Object.keys(mainRoutes).map((key, index) => {
            const routeDetails = mainRoutes[key];
            const RouteWrapper = routeDetails.restricted
              ? PrivateRoute
              : PublicRoute;
              const Component = withAuth(routeDetails.component,routeDetails.roles, Roles);
              // const Component = routeDetails.component;
            // console.log("component", Component);
            return (
              // Roles == "All" ? 
              // [<Route
              //   exact
              //   path="/login"
              //   element={<LoginPage />}
              // />,
              // <Route
              //   exact
              //   path="/auth"
              //   element={<ProcessingPage />}
              // />]
              
              // :
              <Route
                key={index}
                path={routeDetails.path}
                element={
                  <RouteWrapper>
                    <Component
                      cookies={routeDetails.passCookies ? props.cookies : null}
                      />
                    {routeDetails.roles !== "All" && <ScrollToTop />}
                </RouteWrapper>
                }
              />
            );
          })}
          {/* 
          <Route
            exact
            path="/show-property/:id"
            element={<ShowPropertyPage />}
          />
          <Route
            exact
            path="/edit-property/:id"
            element={<EditPropertyPage />}
          />
          <Route exact path="/admin/prefix-suffix" element={<PrefixSuffix />} />
          <Route
            exact
            path="/admin/prefix-suffix/:id"
            element={<PrefixSuffix />}
          />

          <Route
            exact
            path="/admin/show-prefix-suffix"
            element={<ShowPrefixSuffix />}
          />
          <Route
            exact
            path="/admin/view-prefix-suffix/:id"
            element={<ViewPrefixSuffix />}
          />
          <Route
            exact
            path="/admin/show-prefix-suffix/admin/edit-prefix-suffix/:id"
            element={<EditPrefixSuffix />}
          /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default withCookies(App);
