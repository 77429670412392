import axios from "axios";
import { getAccessToken } from "../utility/auth";
console.log("getAccessToken", getAccessToken())

class AxiosRequest {
  constructor() {
    console.log('adasdsd: ', localStorage.getItem("access_token")); 
    this.axiosIns = axios.create({
      // You can add your headers here
      // ================================
      baseURL: "https://apis.rentblaze.com/",
      // timeout: 1000,
      headers: { Accept: "*/*", Authorization: `Bearer ${localStorage.getItem("access_token")}`,  "Content-Type": "application/json", },
      timeout: 60000,
    });
  }

  get = async (url) => await this.axiosIns.get(url);
  post = async (url, body) => await this.axiosIns.post(url, body);
  put = async (url, body) => await this.axiosIns.put(url, body);
  delete = async (url, body) => await this.axiosIns.delete(url, body);
}

const ApiRequest = new AxiosRequest();

export default ApiRequest;

