import React, { useEffect } from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../property/TabPanel";
import { CKEditor } from "ckeditor4-react";
import { Tooltip } from "../Loader/Tooltip";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";
import ApiRequest from "../../utility/http";

export const LeaseForm = () => {
  const [securedFields, setSecuredFields] = useState({
    ssn: "password",
    dln_id: "password",
  });
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const [ownerSettings, setOwnerSettings] = useState([]);

  const getOwnerSettings = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-current-owner-settings"
    );
    if (response?.status == 200) {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  };
  useEffect(() => {
    getOwnerSettings();
  }, []);
  return (
    <>
      <form
        className="w-full"
        id="add-lease-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Lease Information" {...a11yProps(0)} />
          <Tab label="New Lease Deposits & Fees" {...a11yProps(1)} />
          <Tab label="Co-signers" {...a11yProps(2)} />
          <Tab label="Other Lease Information" {...a11yProps(3)} />
          {/* <Tab label="Property Managers" {...a11yProps(1)} /> */}
          <Tab label="Moving In/Out info" {...a11yProps(4)} />
          {/* <Tab label="Property Security Information" {...a11yProps(5)} /> */}
          <Tab label="Signed Lease" {...a11yProps(5)} />
          <Tab label="Lease Enclosures" {...a11yProps(6)} />
          <Tab label="Lease Notices" {...a11yProps(7)} />
          <Tab label="Lease Addendums" {...a11yProps(8)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Lease Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-number`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Status:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-status`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-property`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Unit:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-unit`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Lease Start:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-start`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Lease Duration:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-duration`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-rent`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Prorated Rate:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-prorated-rate`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-2/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Flat Rate?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate Monthly Invoices?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date to Send:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Monthly Bills in Addition to Rent:
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mt-3">
                Monthly Taxes & Fees:
              </label>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-name`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Description:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-description`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-description`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent Due Date:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-rent-due-date`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent Grace Period (Days):
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-rent-grace-period`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Late Fee:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-late-fee`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate and Send Late Fee Invoice if Rent
                        isn't paid by grace period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-4 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-expense-eviction.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                New Lease Deposits & Fees:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <h5 className="block capitalize tracking-wide text-gray-700 font-bold italic mb-2">
                Security Deposit:
              </h5>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Required:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-amount`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-budget`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Generate Invoice:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <h5 className="block capitalize tracking-wide text-gray-700 font-bold italic mt-4 mb-2">
                First Month's Rent:
              </h5>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      First Month Rent Type:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-amount`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 ml-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-amount`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Generate Invoice:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <h5 className="block capitalize tracking-wide text-gray-700 font-bold italic mt-4 mb-2">
                Last Month's Rent:
              </h5>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Required:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-amount`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-budget`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Generate Invoice:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <h5 className="block capitalize tracking-wide text-gray-700 font-bold italic mt-4 mb-2">
                Other Lease Fees:
              </h5>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/4 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`other-lease-name`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/4 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Description:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`other-lease-description`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/4 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`other-lease-amount`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/3 ml-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`other-lease-budget`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/3 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Generate Invoice:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-co-signer.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Co-Signers:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-2/12 mb-6">
                  {/* <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Prefix:
                      </label>
                    </div> */}
                  <div className="md:w-full">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_prefix_id"
                      required={false}
                      // value={formData?.user_prefix_id}
                      // onChange={(e) => {
                      //   setFormData({
                      //     ...formData,
                      //     user_prefix_id: parseInt(e.target.value),
                      //   });
                      // }}
                    >
                      <option value=" ">Select</option>
                      {/* {prefix.map((row) => (
                          <option value={row?.id}>{row?.prefix}</option>
                        ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-3/12 mb-6 mx-2">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      First
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="firstname"
                      type="text"
                      placeholder="John"
                      required={false}
                      // value={formData?.firstname}
                      // onChange={(e) => {
                      //   setCreateLog({ ...createLog, firstname: 1 });
                      //   setFormData({ ...formData, firstname: e.target.value });
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-3/12 mb-6">
                  <div className="md:w-1/3">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Middle
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="middlename"
                      type="text"
                      placeholder="Ben"
                      required={false}
                      // value={formData?.middlename}
                      // onChange={(e) => {
                      //   setCreateLog({ ...createLog, middlename: 1 });
                      //   setFormData({
                      //     ...formData,
                      //     middlename: e.target.value,
                      //   });
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-2">
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Deo"
                      required={false}
                      // value={formData?.lastname}
                      // onChange={(e) => {
                      //   setCreateLog({ ...createLog, lastname: 1 });
                      //   setFormData({ ...formData, lastname: e.target.value });
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-2/12 mb-6">
                  {/* <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Suffix:
                      </label>
                    </div> */}
                  <div className="md:w-full">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_suffix_id"
                      required={false}
                      // value={formData?.user_suffix_id}
                      // onChange={(e) => {
                      //   setFormData({
                      //     ...formData,
                      //     user_suffix_id: parseInt(e.target.value),
                      //   });
                      // }}
                    >
                      <option value=" ">Select</option>
                      {/* {suffix.map((row) => (
                          <option value={row?.id}>{row?.suffix}</option>
                        ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-address-line-1"
                    type="text"
                    placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.addresses[0]?.address_line1}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].address_line1 = e.target.value;
                    //   setCreateLog({...createLog,address_line1:1});
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-address_line2"
                    type="text"
                    placeholder="Unit #5"
                    // value={formData?.addresses[0]?.address_line2}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   setCreateLog({...createLog,address_line2:1});
                    //   addresses[0].address_line2 = e.target.value;
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-country"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={formData?.addresses[0]?.country}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].country = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,country:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      //   getStates(e.target.value);
                      // }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-state"
                      required={false}
                      // value={formData?.addresses[0]?.state}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].state = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,state:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      //   getCities(e.target.value);
                      // }}
                    >
                      <option value=" ">Select State</option>
                      {/* {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-city"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={formData?.addresses[0]?.city}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,city:1})
                      //   const addresses = [...tenantAddress];
                      //   const zip = cities?.map((col) =>{
                      //     if(col?.id == e.target.value)
                      //     {
                      //       return col?.zip_code
                      //     }
                      //   })
                      //   const filteredArr = zip.filter((value) => value !== undefined);
                      //   const zipCode = filteredArr.join(', ');
                      //   addresses[0].city = e.target.value;
                      //   addresses[0].zip_code = zipCode;
                      //   setTenantAddress(addresses);
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });

                      // }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-zip"
                      type="text"
                      placeholder="90210"
                      required={false}
                      // value={formData?.addresses[0]?.zip_code}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].zip_code = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,zip_code:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      // }}
                    />
                  </div>
                </div>
              </div>
              {/* </>
                );
              })} */}
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[0]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[0]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[0].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[0]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    // value={formData?.numbers[0]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_1:1});
                    //   const phone = [...number];
                    //   phone[0].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[1]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[1]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[1].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[1]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2-type`}
                    // value={formData?.numbers[1]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_2:1});
                    //   const phone = [...number];
                    //   phone[1].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[2]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[2]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={formatPhoneNumber(number?.[2]?.phone_number,ownerSettings?.phone_number_format)}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = removeNonNumeric(e.target.value);
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3-type`}
                    // value={formData?.numbers[2]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_3:1});
                    //   const phone = [...number];
                    //   phone[2].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3 mb-6">
                <div className="flex md:items-center w-1/2 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`email`}
                      type="email"
                      placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    />
                  </div>
                </div>
                <div className="flex md:items-center w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Relationship:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`relationship`}
                      type="text"
                      placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date of Birth:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <DatePicker
                    // value={stringToDate(formData?.date_of_birth)}
                    // onChange={(e) => {
                    //   const date = formatDate(e);
                    //   setCreateLog({...createLog,date_of_birth:1});
                    //   setFormData({ ...formData, date_of_birth: date });
                    // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID:
                    </label>
                    <Tooltip
                      data={
                        "Please be careful when storing sensitive information. We take every precaution to safeguard this information, but please be advised there is always a risk when storing sensitive information."
                      }
                    />
                  </div>
                  <div className="md:w-3/4 relative">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id"
                      type={securedFields.dln_id}
                      // value={formData?.dln_id}
                      placeholder="18293213"
                      required={false}
                      // onChange={(e) => {
                      //   setFormData({ ...formData, dln_id: e.target.value });
                      //   setCreateLog({...createLog,dln_id:1});
                      // }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          dln_id:
                            securedFields.dln_id === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.dln_id === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID Country:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_country"
                      // value={formData?.dln_id_issue_country}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,dln_id_issue_country:1});
                      //   setFormData({
                      //     ...formData,
                      //     dln_id_issue_country: e.target.value,
                      //   });
                      //   getStates(e.target.value);
                      // }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6 ml-2">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID issue state:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_state"
                      // value={formData?.dln_id_issue_state}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,dln_id_issue_state:1});
                      //   setFormData({
                      //     ...formData,
                      //     dln_id_issue_state: e.target.value,
                      //   });
                      // }}
                    >
                      <option value=" ">Select State</option>
                      {/* {states?.map((state)=>(
                        <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      DL/ID Picture:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload ID
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* {dlidPicture[0]?.pictures?.picture != null ? (
                <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                  <div className="container w-full">
                    <div
                      id="image-block"
                      className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                    >
                      {imageComponent({
                        image: dlidPicture?.image?.picture,
                        src: dlidPicture?.url,
                        inputFieldId: "dlid-picture",
                        trashAble: true,
                        onTrashClick: () => {
                          formData.dl_id_picture = null;
                          setDlidPicture(null);
                        },
                      })}
                    </div>
                  </div>
                </section>
              ) : null} */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    DLN/ID Issue Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      SSN:
                    </label>
                    <Tooltip
                      data={
                        "Please be careful when storing sensitive information. We take every precaution to safeguard this information, but please be advised there is always a risk when storing sensitive information."
                      }
                    />
                  </div>
                  <div className="md:w-3/4 relative">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="ssn"
                      // type={securedFields.ssn}
                      // value={formData?.ssn}
                      placeholder="18293213"
                      required={false}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,ssn:1});
                      //   setFormData({ ...formData, ssn: e.target.value });
                      // }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          ssn:
                            securedFields.ssn === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.ssn === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-employer.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Co-Signer Current Employment:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Company Name:<span title="Field is Required">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                  <div className="md:w-2/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title/Position:
                      <span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-position`}
                      type="text"
                      placeholder="CEO"
                      required={true}
                      // value={tenantEmployment?.position}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,position:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Annual Salary:<span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="annual-salary"
                      required={false}
                      type="text"
                      // placeholder={`${ownerSettings?.currency_symbol} 100`}
                      // value={tenantEmployment?.annual_salary != null ? tenantEmployment?.annual_salary : ""}
                      // currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                      // currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                      // onChange={(e) => {
                      //   setTenantEmployment({ ...tenantEmployment, annual_salary: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                      // }}
                      // onKeyPress={(e)=>{
                      //   if (e.key === '.') {
                      //     e.preventDefault();
                      //     const inputElement = e.target;
                      //     const inputValue = inputElement.value;
                      //     const selectionStart = inputElement.selectionStart;
                      //     const selectionEnd = inputElement.selectionEnd;

                      //     // Insert the decimal point at the current cursor position
                      //     const updatedValue =
                      //       inputValue.substring(0, selectionStart) +
                      //       '.' +
                      //       inputValue.substring(selectionEnd);

                      //     // Update the input value and move the cursor position
                      //     setTenantEmployment({ ...tenantEmployment, annual_salary: updatedValue }, () => {
                      //       inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                      //     });
                      //   }
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Start Year:<span title="Field is required.">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      required={true}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-start-year`}
                      // value={tenantEmployment?.start_year}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,start_year:e.target.value});
                      // }}
                    >
                      <option value=" ">Select Year</option>
                      {/* {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      End Year:<span title="Field is required.">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      required={true}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-end-year`}
                      // value={tenantEmployment?.end_year || tenantEmployment?.present}
                      // onChange={(e) => {
                      //   const PresentValue =
                      //     e.target.value == 1 ? 1 : 0;
                      //   const EndYearValue =
                      //     e.target.value == 1
                      //       ? 0
                      //       : e.target.value;
                      //   // employments[index].end_year = EndYearValue;
                      //   // employments[index].present = PresentValue;
                      //   setTenantEmployment({...tenantEmployment,end_year:EndYearValue,present:PresentValue});
                      // }}
                    >
                      <option value="">Select Year</option>
                      <option value={1}>Present</option>
                      {/* {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-address-line-1`}
                    type="text"
                    placeholder="6284 Locust Road"
                    required={true}
                    // value={tenantEmployment?.address_line1}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,address_line1:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-address-line-2`}
                    type="text"
                    placeholder="Unit #5"
                    required={true}
                    // value={tenantEmployment?.address_line2}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,address_line2:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country: <span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="emergency-grid-city"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={tenantEmployment?.country}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,country:e.target.value});
                      //   getStates(e.target.value)
                      // }}
                    >
                      {/* <option value=" ">Select Country</option>
                            {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State: <span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="emergency-grid-state"
                      required={false}
                      // value={tenantEmployment?.state}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,state:e.target.value});
                      //   getCities(e.target.value)
                      // }}
                    >
                      {/* <option value="">Select State</option>
                            {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City: <span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="emergency-grid-city"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={tenantEmployment?.city}
                      // onChange={(e) => {
                      //   const zip = cities?.map((col) =>{
                      //     if(col?.id == e.target.value)
                      //     {
                      //       return col?.zip_code
                      //     }
                      //   })
                      //   const filteredArr = zip.filter((value) => value !== undefined);
                      //   const zipCode = filteredArr.join(', ');
                      //   setTenantEmployment({...tenantEmployment,city:e.target.value,zip_code:zipCode});
                      // }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>

                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip: <span title="Field is required">*</span>
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="emergency-grid-zip"
                      type="text"
                      placeholder="90210"
                      required={false}
                      // value={tenantEmployment?.zip_code}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,zip_code:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Website:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-website`}
                    type="url"
                    placeholder="www.john-doe.com"
                    // value={tenantEmployment?.website}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,website:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Supervisor:<span title="Field is required">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-supervisor`}
                    type="text"
                    placeholder="Ben"
                    // value={tenantEmployment?.supervisor}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,supervisor:e.target.value})
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:<span title="Field is required">*</span>
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {tenantEmployment?.number_type == 8 ?  */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-number`}
                    type="tel"
                    required={true}
                    placeholder="(999) 999-9999"
                    // value={tenantEmployment?.number}
                    // maxLength={ownerSetting?.phone_number_format?.length}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,number:e.target.value});
                    // }}
                  />
                  {/* :
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-number`}
                        type="tel"
                        required={true}
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(tenantEmployment?.number,ownerSetting?.phone_number_format)}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        onChange={(e) => {
                          setTenantEmployment({...tenantEmployment,number:removeNonNumeric(e.target.value)});
                        }}
                      />} */}
                </div>
                {/* <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type: <span title="Field is required">*</span>
                      </label>
                    </div> */}
                <div className="md:w-1/5 pl-3">
                  <select
                    required={true}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-number-type`}
                    // value={tenantEmployment?.number_type}
                    // onChange={(e) => {
                    //     setTenantEmployment({...tenantEmployment,number_type:e.target.value});
                    //   }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Employment Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData1}
                  // onChange={(e) => {
                  //     setEditorData1(e.editor.getData());
                  //     // setTenantEmployment({...tenantEmployment,leaving_reason:editorData,notes:editorData1});
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="md:flex mt-4">
            <div className="md:w-1/3">
              <div className="flex">
                {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Co-signer Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyOwnerDocumentsWithUrl(fileLists);
                      //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                      //           propertyCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>

          {/* Gallery  */}

          {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  {/* <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  /> */}
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Co-Signer Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData3?.property_owner_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData3({
                  //     ...editorData3,
                  //     property_owner_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  <i class="fa fa-plus"></i> Add Co-Signer
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-information.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Other Lease Information:
              </label>
            </div>
          </div>
          <div className="md:flex mt-4">
            <div className="md:w-1/3">
              <div className="flex">
                {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Lease Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyOwnerDocumentsWithUrl(fileLists);
                      //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                      //           propertyCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>

          {/* Gallery  */}

          {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  {/* <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  /> */}
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Lease Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData3?.property_owner_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData3({
                  //     ...editorData3,
                  //     property_owner_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-moving.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Moving In/Out Info:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex md:items-center w-1/2">
                <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Move-in Date:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <DatePicker
                    // value={stringToDate(formData?.date_of_birth)}
                    // onChange={(e) => {
                    //   const date = formatDate(e);
                    //   setCreateLog({...createLog,date_of_birth:1});
                    //   setFormData({ ...formData, date_of_birth: date });
                    // }}
                    />
                  </div>
                </div>
                <div className="flex md:items-center w-1/2">
                <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Time<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <input
                      type="time"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    // value={stringToDate(formData?.date_of_birth)}
                    // onChange={(e) => {
                    //   const date = formatDate(e);
                    //   setCreateLog({...createLog,date_of_birth:1});
                    //   setFormData({ ...formData, date_of_birth: date });
                    // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
              <div className="flex md:items-center w-1/2">
                <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Days of required notice prior to move out<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <input
                      type="text"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    // value={stringToDate(formData?.date_of_birth)}
                    // onChange={(e) => {
                    //   const date = formatDate(e);
                    //   setCreateLog({...createLog,date_of_birth:1});
                    //   setFormData({ ...formData, date_of_birth: date });
                    // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-co-signer.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Signed Lease:
              </label>
            </div>
          </div>
          <div className="md:flex mt-4">
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyOwnerDocumentsWithUrl(fileLists);
                      //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                      //           propertyCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  <i class="fa fa-plus"></i> Add Tenant To Lease
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-addendum.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Lease Enclosures:
              </label>
            </div>
          </div>
          <div class="flex flex-row-reverse justify-between items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Load Template</button>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-1 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-enclosures-name`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Description:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-enclosures-description`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex mt-4">
            <div className="md:w-1/3">
              <div className="flex">
                {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Enclosure Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      // accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      // onChange={(e) => {
                      //   let files = e.target.files;
                      //   let newImages = [];
                      //   let maxOrderNumber = 0;

                      //   // Find the highest order number in the current images
                      //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                      //     if (imageObject.order_number > maxOrderNumber) {
                      //       maxOrderNumber = imageObject.order_number;
                      //     }
                      //   });

                      //   // Calculate the total number of images (existing images + new images)
                      //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                      //   // Loop through each file and convert to base64
                      //   files.forEach((file, i) => {
                      //     const reader = new FileReader();
                      //     reader.addEventListener("load", async () => {
                      //       const fileBase64 = reader.result;
                      //       try {
                      //         const res = await ApiRequest.post(fileApi, {
                      //           file: fileBase64,
                      //         });

                      //         // Add the new image to the array with a unique order number
                      //         newImages.push({
                      //           file: res?.data?.data?.file,
                      //           title: res?.data?.data?.file,
                      //           url:fileBase64
                      //         });

                      //         // If all new images have been processed, update the state
                      //         if (newImages.length === files.length) {
                      //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                      //           fileLists = [...fileLists, ...newImages];
                      //           setPropertyOwnerDocumentsWithUrl(fileLists);
                      //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                      //           propertyCopy.files = fileLists.map(
                      //             (picObj) => ({
                      //               file: picObj.file,
                      //               title: picObj.title,
                      //             })
                      //           );
                      //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                      //         }
                      //       } catch (err) {
                      //         // console.log(err);
                      //       }
                      //     });
                      //     reader.readAsDataURL(file);
                      //   });
                      // }}
                    />
                  </label>
                </div>
                {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>

          {/* Gallery  */}

          {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  {/* <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  /> */}
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Enclosure:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData3?.property_owner_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData3({
                  //     ...editorData3,
                  //     property_owner_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-evenly items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Save as Template</button>
              <button type="button" class="btn btn3">Save as Draft</button>
              <button type="button" class="btn btn3 px-5">Publish</button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-notice.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Lease Notice:
              </label>
            </div>
          </div>
          <div class="flex flex-row-reverse justify-between items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Add Notice</button>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
                <div class="flex flex-row-reverse justify-between items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">Load Template</button>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Title:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="lease-notice-title"
                    type="text"
                    placeholder="Title"
                    required={false}
                    // value={formData?.addresses[0]?.address_line1}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].address_line1 = e.target.value;
                    //   setCreateLog({...createLog,address_line1:1});
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  {/* <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  /> */}
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Lease Notice:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData3?.property_owner_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData3({
                  //     ...editorData3,
                  //     property_owner_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              </div>
              
            </div>
          </div>
          

            {/* Lease Files */}

            <div className="md:flex md:items-center w-[90%] m-auto mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Lease Notice Files:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload files
                  </span>
                  <input
                    id={`p-measurement-files`}
                    type="file"
                    // accept={fileTypes}
                    multiple={true}
                    hidden={true}
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   let newImages = [];
                    //   let maxOrderNumber = 0;
                    
                    //   // Find the highest order number in the current images
                    //   propertyMeasurementFiles?.forEach((imageObject) => {
                    //     if (imageObject.order_number > maxOrderNumber) {
                    //       maxOrderNumber = imageObject.order_number;
                    //     }
                    //   });
                    
                    //   // Calculate the total number of images (existing images + new images)
                    //   const totalImages = propertyMeasurementFiles.length + files.length;
                    
                    //   // Loop through each file and convert to base64
                    //   files.forEach((file, i) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const fileBase64 = reader.result;
                    //       try {
                    //         const res = await ApiRequest.post(fileApi, {
                    //           file: fileBase64,
                    //         });
                    
                    //         // Add the new image to the array with a unique order number
                    //         newImages.push({
                    //           file: res?.data?.data?.file,
                    //           title: res?.data?.data?.file,
                    //           url:fileBase64
                    //         });
                    
                    //         // If all new images have been processed, update the state
                    //         if (newImages.length === files.length) {
                    //           let fileLists = [...propertyMeasurementFiles];
                    //           fileLists = [...fileLists, ...newImages];
                    //           setPropertyMeasurementFiles(fileLists);
                    //           let measurementsCopy = [...propertyMeasurementFiles];
                    //           measurementsCopy.files = fileLists.map(
                    //             (picObj) => ({
                    //               file: picObj.file,
                    //               title: picObj.title,
                    //             })
                    //           );
                    //           setPropertyMeasurement({ ...propertyMeasurement, files: measurementsCopy?.files });
                    //         }
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    // }}
                    
                  />
                </label>
              </div>
              {/* <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount =
                        propertyMeasurementFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
            </div>

            {/* Gallery  */}

            {/* <div >
              {propertyMeasurementFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMeasurementFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyMeasurement.files = fileLists;
                                setPropertyMeasurementFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMeasurementFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyMeasurement.files[fileIndex].title = title;
                                    setPropertyMeasurementFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
            <div className="md:flex md:items-center w-[90%] m-auto mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Lease Notice Pictures:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload pictures
                  </span>
                  <input
                    // accept={picTypes}
                    id={`p-measurement-pictures`}
                    type="file"
                    multiple={true}
                    hidden={true}
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   let newImages = [];
                    //   let maxOrderNumber = 0;
                    
                    //   // Find the highest order number in the current images
                    //   propertyMeasurementPictures?.forEach((imageObject) => {
                    //     if (imageObject.order_number > maxOrderNumber) {
                    //       maxOrderNumber = imageObject.order_number;
                    //     }
                    //   });
                    
                    //   // Calculate the total number of images (existing images + new images)
                    //   const totalImages = propertyMeasurementPictures.length + files.length;
                    
                    //   // Loop through each file and convert to base64
                    //   files.forEach((file, i) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const imgBase64 = reader.result;
                    //       try {
                    //         const res = await ApiRequest.post(imageApi, {
                    //           image: imgBase64,
                    //         });
                    
                    //         // Add the new image to the array with a unique order number
                    //         newImages.push({
                    //           picture: res?.data?.data?.image,
                    //           order_number: maxOrderNumber + i + 1 + propertyMeasurementPictures.length,
                    //           url:imgBase64
                    //         });
                    
                    //         // If all new images have been processed, update the state
                    //         if (newImages.length === files.length) {
                    //           let pictureLists = [...propertyMeasurementPictures];
                    //           pictureLists = [...pictureLists, ...newImages];
                    //           setPropertyMeasurementPictures(pictureLists);
                    //           let measurementsCopy = [...propertyMeasurementPictures];
                    //           measurementsCopy.pictures = pictureLists.map(
                    //             (picObj) => ({
                    //               picture: picObj.picture,
                    //               order_number: picObj.order_number,
                    //             })
                    //           );
                    //           setPropertyMeasurement({ ...propertyMeasurement, pictures: measurementsCopy?.pictures });
                    //         }
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    // }}
                    
                    
                    
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   files.forEach((file) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const imgBase64 = reader.result;
                    //       try {
                    //         // console.log(imgBase64);
                    //         const res = await ApiRequest.post(imageApi, {
                    //           image: imgBase64,
                    //         });
                    //         // console.log("res", res?.data?.data?.image);
                    //         // console.log("index", index);
                    //         // let index = [index];
                    //         // [...index].sort()
                    //         propertyMeasurements[index].pictures.push({
                    //           picture: res?.data?.data?.image,
                    //         });
                    //         setFormData({
                    //           ...formData,
                    //           measurements: measurements,
                    //         });

                    //         // console.log(
                    //           "propertyMeasurements",
                    //           propertyMeasurements
                    //         );

                    //         // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                    //         // // console.warn("base64converted files",formData)
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    //   const pictureLists = [...propertyMeasurementPictures];
                    //   pictureLists[index] = getImagesAndURLs(e);
                    //   setPropertyMeasurementPictures(pictureLists);
                    //   // propertyMeasurements[index].pictures =
                    //   // 		getImagesAndURLs(e)
                    //   // // console.warn("pictureList",pictureLists);
                    //   // setFormData({...formData, measurements: measurements})
                    // }}
                  />
                </label>
              </div>
              {/* <div className="md:w-1/5">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const imagesCount =
                        propertyMeasurementPictures?.length || 0;
                      return imagesCount > 1
                        ? imagesCount + " Pictures"
                        : imagesCount == 1
                        ? imagesCount + " Picture"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
            </div>

            {/* Gallery  */}

            {/* <div >
              {propertyMeasurementPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMeasurementPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                propertyMeasurement.pictures = pictureLists;
                                setPropertyMeasurementPictures(
                                  pictureLists
                                );
                                // setPropertyMeasurements(measurements)
                                setPropertyMeasurement({
                                  ...propertyMeasurement,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
            <div class="flex justify-evenly items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Save as Template</button>
              <button type="button" class="btn btn3">Save as Draft</button>
              <button type="button" class="btn btn3 px-5">Publish</button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-addendum.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Lease Addendum:
              </label>
            </div>
          </div>
          <div class="flex flex-row-reverse justify-between items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Add Addendum</button>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                  <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                  <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-4">
                <div className="flex items-center w-1/3 mr-2">
                <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent Adjustment:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                  <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mr-2">
                <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Addendum Fee:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                  <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  </div>
                </div>
                <div className="flex items-center w-1/3">
                <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Budget:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                  <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex flex-row-reverse justify-between items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">Load Template</button>
                </div>
              </div>
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Addendum:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      // initData={editorData}
                      // onChange={(e) => {
                      //   setEditorData(e.editor.getData());
                      //   // setPropertyMeasurement({...propertyMeasurement,notes:e.editor.getData()});
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Lease Files */}

          <div className="md:flex md:items-center w-[90%] m-auto mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Addendum Files:
                </label>
              </div>
              <div className="md:w-auto">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload files
                  </span>
                  <input
                    id={`p-measurement-files`}
                    type="file"
                    // accept={fileTypes}
                    multiple={true}
                    hidden={true}
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   let newImages = [];
                    //   let maxOrderNumber = 0;
                    
                    //   // Find the highest order number in the current images
                    //   propertyMeasurementFiles?.forEach((imageObject) => {
                    //     if (imageObject.order_number > maxOrderNumber) {
                    //       maxOrderNumber = imageObject.order_number;
                    //     }
                    //   });
                    
                    //   // Calculate the total number of images (existing images + new images)
                    //   const totalImages = propertyMeasurementFiles.length + files.length;
                    
                    //   // Loop through each file and convert to base64
                    //   files.forEach((file, i) => {
                    //     const reader = new FileReader();
                    //     reader.addEventListener("load", async () => {
                    //       const fileBase64 = reader.result;
                    //       try {
                    //         const res = await ApiRequest.post(fileApi, {
                    //           file: fileBase64,
                    //         });
                    
                    //         // Add the new image to the array with a unique order number
                    //         newImages.push({
                    //           file: res?.data?.data?.file,
                    //           title: res?.data?.data?.file,
                    //           url:fileBase64
                    //         });
                    
                    //         // If all new images have been processed, update the state
                    //         if (newImages.length === files.length) {
                    //           let fileLists = [...propertyMeasurementFiles];
                    //           fileLists = [...fileLists, ...newImages];
                    //           setPropertyMeasurementFiles(fileLists);
                    //           let measurementsCopy = [...propertyMeasurementFiles];
                    //           measurementsCopy.files = fileLists.map(
                    //             (picObj) => ({
                    //               file: picObj.file,
                    //               title: picObj.title,
                    //             })
                    //           );
                    //           setPropertyMeasurement({ ...propertyMeasurement, files: measurementsCopy?.files });
                    //         }
                    //       } catch (err) {
                    //         // console.log(err);
                    //       }
                    //     });
                    //     reader.readAsDataURL(file);
                    //   });
                    // }}
                    
                  />
                </label>
              </div>
              {/* <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount =
                        propertyMeasurementFiles?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
                </label>
              </div> */}
            </div>

            {/* Gallery  */}

            {/* <div >
              {propertyMeasurementFiles.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMeasurementFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `p-measurement-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMeasurementFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                propertyMeasurement.files = fileLists;
                                setPropertyMeasurementFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMeasurementFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    propertyMeasurement.files[fileIndex].title = title;
                                    setPropertyMeasurementFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
            <div class="flex justify-evenly items-center mt-3">
            <div class="flex justify-between items-center gap-4">
              <button type="button" class="btn btn3">Save as Template</button>
              <button type="button" class="btn btn3">Save as Draft</button>
              <button type="button" class="btn btn3 px-5">Publish</button>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
