import React from 'react'
import { useState,useEffect } from 'react';
import ApiRequest from '../../../utility/http';
import imgUrl from '../../ImageUrl';
import Spinner from '../../Spinner/Spinner';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
export const Utility = () => {
    const [utility,setUtility] = useState();
    const [loader,setLoader] = useState();
    const [utilityIcon,setUtilityIcon] = useState()
    const [editUtility,setEditUtility] = useState();
    const [editID,setEditID] = useState();
    const [editOrder,setEditOrder] = useState();
    const [editUtilityIcon,setEditUtilityIcon] = useState()
    const [allUtility,setAllUtility] = useState([]);
    const getUtility = async()=>{
        const response = await ApiRequest.get('/public/api/auth/get-all-utilities')
        if(response.status == 200)
        {
          setAllUtility(response?.data?.data?.utilities);
          setLoader(response);
        }
      }
    
      const indexFind = (id) => {
        const editType = allUtility.find(utility => utility.id === id);
        // console.log("editType", editType);
        setEditUtility(editType?.utility);
        setEditUtilityIcon(editType?.icon);
        setEditOrder(editType?.order_number)
        setEditID(editType?.id);
      }
      const orderChange = (id, direction) => {
        const newListItems = [...allUtility];
        const currentItem = newListItems.find(item => item.id === id);
        const currentIndex = newListItems.indexOf(currentItem);
      
        if (direction === "up" && currentIndex > 0) {
          const previousItem = newListItems[currentIndex - 1];
          const newOrder = currentItem.order_number - 1;
          if (previousItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(0, currentIndex).filter(item => item.order_number >= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number += 1;
            });
          }
          previousItem.order_number = newOrder;
          currentItem.order_number = newOrder + 1;
          newListItems[currentIndex - 1] = currentItem;
          newListItems[currentIndex] = previousItem;
        } else if (direction === "down" && currentIndex < newListItems.length - 1) {
          const nextItem = newListItems[currentIndex + 1];
          const newOrder = currentItem.order_number + 1;
          if (nextItem.order_number !== newOrder) {
            const itemsToUpdate = newListItems.slice(currentIndex + 1).filter(item => item.order_number <= newOrder && item.id !== id);
            itemsToUpdate.forEach(item => {
              item.order_number -= 1;
            });
          }
          nextItem.order_number = newOrder;
          currentItem.order_number = newOrder - 1;
          newListItems[currentIndex + 1] = currentItem;
          newListItems[currentIndex] = nextItem;
        }
      
        reArrangeData(newListItems);
        console.log('newListItems',newListItems);
      };
      const deleteData =async(id)=>{
        const { isConfirmed } = await Swal.fire({
          title: 'Are you sure?',
          text: 'This will Delete the Utility By. Do you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        });
      
        // If user confirms, Inactivate the Nearby
        if (isConfirmed) {
          setLoader(undefined);
          try {
            // const ArrayId = [];
            // ArrayId.push(id);
            const response = await ApiRequest.delete(
              `/public/api/auth/delete-utility?id=${id}`
              
            );
            orderChange();
          getUtility();
      
            // Show success message
            if(response.status == 200)
            {
              Swal.fire({
              title: 'Success!',
              text: 'The Utility By have been Deleted.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          }
      
          } catch (error) {
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'An error occurred while Deleting the Utility By. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          } finally {
            setLoader('');
          }
        }
      }
      const handleOrderChange = (id, direction) => {
        const index = allUtility?.findIndex(item => item.id === id);
        const newListItems = [...allUtility];
        const currentItem = newListItems[index];
    
        if (direction === "up" && index > 0) {
            const previousItem = newListItems[index - 1];
            currentItem.order_number = previousItem.order_number;
            previousItem.order_number = index + 1;
            newListItems[index - 1] = currentItem;
            newListItems[index] = previousItem;
        } else if (direction === "down" && index < newListItems.length - 1) {
            const nextItem = newListItems[index + 1];
            currentItem.order_number = nextItem.order_number;
            nextItem.order_number = index + 1;
            newListItems[index + 1] = currentItem;
            newListItems[index] = nextItem;
        }
    
        reArrangeData(newListItems);
    };
    const reArrangeData = async(Data)=>{
      setLoader(undefined);
      const response = await ApiRequest.post('/public/api/auth/rearrange-admin-settings-lists',{
          identifier:'utilities',
          data:Data,
      }
      );
      if(response.status == 200)
      {
          getUtility();
      }
  }
    const sortedData = allUtility?.sort((a, b) => a.order_number - b.order_number);
      const AddUtility = async ()=>{
        setLoader(undefined)
        const sortedUtilitys = allUtility.sort((a, b) => b.order_number - a.order_number);

// Retrieve the order_number of the first item in the sorted array
        const lastOrderNumber = sortedUtilitys[0]?.order_number;
        const payload = {
          utility:utility,
          icon:utilityIcon,
          order_number:lastOrderNumber != undefined ? lastOrderNumber + 1 : 1
        }
        const editPayload = {
            id:editID,
            utility:editUtility,
            icon:editUtilityIcon,
            order_number:editOrder
        }
        const response = await ApiRequest.post('/public/api/auth/utility',
        editID == undefined ? payload : editPayload
        )
        if(response?.status == 200)
        {
          getUtility();
          setUtility('');
          setUtilityIcon('');
          setEditID(undefined)
        }
      }
      useEffect(() => {
        getUtility();
      }, [])
      const highestOrderNumber = sortedData.reduce((maxOrderNumber, row) => {
        return row.order_number > maxOrderNumber ? row.order_number : maxOrderNumber;
      }, 0);
      const [currentPage, setCurrentPage] = useState(0);
      const utilitiesPerPage = 10; // Set the number of states to display per page
      const offset = currentPage * utilitiesPerPage;
      const currentUtilities = sortedData?.slice(offset, offset + utilitiesPerPage);
    
      const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
      };
  return (
    <>
    
        <div class="md:flex md:items-center w-full mt-4 mx-2">
          <div class="md:w-2/5">
            <label class="block capitalize text-gray-700 font-bold mb-2">
            Utilities:
            </label>
            <span class="block text-gray-700 font-light mb-2 mr-2">
            Lists the different types of utilities the system will use.
            </span>
          </div>
          <div class="md:flex-1 mr-4 pr-4 mb-3">
            <div class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
              <div className="flex">
                <form>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/5">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Utility:
                      </label>
                    </div>
                    <div class="md:w-4/5 mr-4">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="number-type-name"
                        type="text"
                        value={utility}
                        placeholder="Name"
                        onChange={(e)=>{
                          setUtility(e.target.value);
                        }}
                      />
                    </div>
                    <div class="md:flex-1 ml-52 flex justify-end">
                    <label className="bg-blue-500 text-white py-2 px-4 rounded ml-2 cursor-pointer w-28">
                        Add Icon
                        <input
                      accept="image/*"
                      id={`number-type-pic`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // console.log("res", res?.data?.data?.image);
                            // formData?.dl_id_file = res?.data?.data?.image
                            setUtilityIcon(res?.data?.data?.image);
                            // setDlidFile(res?.data?.data?.image)
                            // setUtilityIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);

                        // console.log("here 142");
                        // const files = [...propertyAssistantFiles];
                        // files[index] = getImagesAndURLs(e)[0];
                        // setPropertyAssistantFiles(files);
                        // setPropertyContacts(contacts);
                        // setFormData({...formData, contacts : contacts})
                      }}
                    />
                      </label>
                      <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                          AddUtility();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <hr />
              {loader == undefined ? <Spinner/> :<>
              {currentUtilities?.map((type)=>(
                <>
              <div class="flex justify-between">
                  <div class="flex-initial">
                      <div className="flex">
                      {type?.order_number != 1 ? (
                                <i className="fa fa-sort-up text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(type?.id, 'up')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )}
                            {type?.order_number < highestOrderNumber ? (
                                <i className="fa fa-sort-down text-blue-700 cursor-pointer mx-2" onClick={() => handleOrderChange(type?.id, 'down')}></i>
                            ) : (
                                <span className="w-6"></span>
                            )}
                          <img src={`${imgUrl}/${type?.icon}`} alt="Icon" className="logo w-6 mx-2"/>
                          <span>{type?.utility}</span>
                      </div>
                  </div>
                  <div class="flex-initial">
                      <div className="flex">
                          <img src="/assets/img/system-edit.png" alt="Edit" title="Edit" data-bs-target={`#utility`} data-bs-toggle="modal" className="logo w-6 mx-2 cursor-pointer" onClick={()=>{
                            indexFind(type?.id)
                          }}/>
                          <img src="/assets/svgs/system-trashcan.svg" alt="Delete" title="Delete" className="logo w-6 mx-2 cursor-pointer" onClick={()=>{
                            deleteData(type?.id)
                          }}/>
                      </div>
                  </div>
              </div>
                  <hr />
              </>
              ))}
              <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={Math.ceil(sortedData?.length / utilitiesPerPage)}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"status-active"}
                    nextLinkClassName={"status-active"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"} 
                    />
              </>}
            </div>
          </div>
        </div>
        <div
            class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id={`utility`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog relative w-auto pointer-events-none">
              <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                  <h5
                    class="text-xl font-medium leading-normal text-gray-800"
                    id="exampleModalLabel"
                  >
                    Edit
                  </h5>
                  <button
                    type="button"
                    class="btn2-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ><i className='fa fa-remove'></i></button>
                </div>
                <div class="modal-body relative p-4">
                 
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Utility:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`number-type`}
                    type="text"
                    value={editUtility}
                    placeholder="456"
                    onChange={(e) => {
                    setEditUtility(e.target.value)
                    }}
                   
                  />
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-full mb-6">
                <div className="md:w-1/2">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Icon:
                  </label>
                </div>

                    <label className="bg-blue-500 text-white py-2 px-4 rounded ml-2 cursor-pointer w-28">
                        Add Icon
                        <input
                      accept="image/*"
                      id={`number-type-pic`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // console.log("res", res?.data?.data?.image);
                            // formData?.dl_id_file = res?.data?.data?.image
                            setEditUtilityIcon(res?.data?.data?.image);
                            // setDlidFile(res?.data?.data?.image)
                            // setUtilityIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);

                        // console.log("here 142");
                        // const files = [...propertyAssistantFiles];
                        // files[index] = getImagesAndURLs(e)[0];
                        // setPropertyAssistantFiles(files);
                        // setPropertyContacts(contacts);
                        // setFormData({...formData, contacts : contacts})
                      }}
                    />
                      </label>
                     
              </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <img src={`${imgUrl}/${editUtilityIcon}`} alt="" />
              </div>
          </div>
        </div>
                </div>
                <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  
                  <button
                    type="button"
                    class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    data-bs-dismiss="modal"
                    onClick={()=>{AddUtility()}}
                    // disabled={disabled}
                  >
                    Update
                  </button>
                  
                  
                  
                </div>
              </div>
            </div>
          </div>
  </>
  )
}
