import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
// import { EditPrefixSuffixForm } from "../../components/admin/Prefix-Suffix-edit-form";
import ApiRequest from "../../utility/http";
import { MoveOutEditForm } from "../../components/admin/MoveoutreasonEdit";
import { EthnicitiesEditForm } from "../../components/admin/EthnicitiesEdit";
import { useParams } from "react-router-dom";
export const EthnicitiesEdit = ({ cookies }) => {
  const navigate = useNavigate();
  // // const dispatch = useDispatch();
  const Alert = useAlerts();
  const { id } = useParams();

  // // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  // const [formData, setFormData] = useState({});

  // const { alertList } = useSelector((state) => state.alerts);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/assets/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/assets/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Properties" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <section className="header-bar">
            <div className="row align-items-center">
              <div className="col-xl-3 col-md-12 col-2">
                <div className="property-item">
                  <div className="property-img">
                    <img src="/assets/img/property.png" alt="" />
                  </div>
                  <div className="property-select">
                    <select>
                      <option>New Heights</option>
                      <option>New Heights2</option>
                    </select>
                  </div>
                </div>
                <div className="mobile-logo">
                  <img
                    className="img-fluid logo-light"
                    src="/assets/img/logo-light.png"
                    alt=""
                  />
                  <img
                    className="img-fluid logo-dark"
                    src="/assets/img/logo-dark.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xl-9 col-md-12 col-10">
                <div className="header-bar-right">
                  <ul className="header-icons">
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src="/assets/img/topbar-icon1.png" alt="" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink1"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src="/assets/img/topbar-icon2.png" alt="" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink2"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink3"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src="/assets/img/topbar-icon3.png" alt="" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink3"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown right">
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink4"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src="/assets/img/topbar-icon4.png" alt="" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink4"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                    <li className="dropdown right">
                      <a
                        className="dropdown-toggle"
                        onClick={(e) => handleLogoutClick(e)}
                        role="button"
                        id="dropdownMenuLink5"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <LoginIcon className="text-gray-500 font-light" />
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink4"
                      >
                        <span className="triangle-arrow" />
                      </div>
                    </li>
                  </ul>
                  <div className="account-details">
                    <div className="account-author">
                      <img src="/assets/img/author.png" alt="" />
                    </div>
                    <select>
                      <option>Anddy’s Makeover</option>
                      <option>Anddy’s Makeover2</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="px-5">
            <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/property.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                Edit Ethnicities
              </h2>
            </div>
            <EthnicitiesEditForm />
          </div>
          <div className="bottom-gap" />
        </div>

        <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Serserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
