import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import {
  TableNamePrefix,
  TableNameSuffix,
  TableParkingPrefix,
  TableParkingSuffix,
  MoveOutReasonTable,
  EthnicitiesTable,
} from "../PrefixSuffixTable";
import { adminPrefix, adminSuffix } from "../../store/admin/adminThunk";
import { useDispatch, useSelector } from "react-redux";
export const PrefixSuffixTabs = ({}) => {
  //  CONSTANTS

  const [parkingPrefix, setParkingPrefix] = useState();
  const [parkingSuffix, setParkingSuffix] = useState();
  const [moveout, setMoveOut] = useState();
  const [Ethnicities, setEthnicitiest] = useState();

  const dispatch = useDispatch();
  const prefixes = useSelector(
    (state) => state.adminPrefix.Prefixes.data?.prefixes
  );
  const suffixes = useSelector(
    (state) => state.adminSuffix.Suffixes?.data?.suffixes
  );
  const loading = useSelector((state) => state.adminPrefix?.status);

  const [tabValue, setTabValue] = useState(0);

  //   //  METHODS
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  useEffect(() => {
    dispatch(adminPrefix());
    dispatch(adminSuffix());
    // dispatch(propertiesTypes());

    const getData = async () => {
      try {
        const resPrefix = await ApiRequest.get(
          "/public/api/auth/get-parking-space-prefixes"
        );
        const resSuffix = await ApiRequest.get(
          "/public/api/auth/get-parking-space-suffixes"
        );
        const moveReason = await ApiRequest.get(
          "/public/api/auth/get-moveout-reasons"
        );
        const Ethnicities = await ApiRequest.get(
          "/public/api/auth/get-ethnicities"
        );
        setEthnicitiest(Ethnicities?.data.data.ethnicities);
        setMoveOut(moveReason?.data.data.reasons);
        setParkingPrefix(resPrefix?.data.data.prefixes);
        setParkingSuffix(resSuffix.data.data.suffixes);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);
  return (
    <>
      {/* Add Property Form  */}

      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Name Prefix" {...a11yProps(0)} />
        <Tab label="Name Suffix" {...a11yProps(1)} />
        <Tab label="Name Parking Prefix" {...a11yProps(2)} />
        <Tab label="Name parking Suffix" {...a11yProps(3)} />
        <Tab label="Mover out reason" {...a11yProps(4)} />
        <Tab label="Ethnicities" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <TableNamePrefix prefixes={prefixes} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TableNameSuffix suffixes={suffixes} loading={loading} />
      </TabPanel>
      {/* <TabPanel value={tabValue} index={2}>
        <TableParkingPrefix prefixes={parkingPrefix} loading={loading} />
      </TabPanel> */}
      <TabPanel value={tabValue} index={3}>
        <TableParkingSuffix suffixes={parkingSuffix} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <MoveOutReasonTable moveout={moveout} loading={loading} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <EthnicitiesTable Ethnicities={Ethnicities} loading={loading} />
      </TabPanel>
    </>
  );
};
