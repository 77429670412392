import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import { Tooltip } from '../Loader/Tooltip';
import imgUrl from '../ImageUrl';

export const References = ({data,handleReferenceTrue,hiddenData,ownerSettings}) => {
    const initialReferenceData = {
        firstname: "",
        lastname: "",
        user_reference_relationship_id: "",
        other_reference_relationship: "",
        email:'',
        number1: "",
        number1_type: "",
        number2: "",
        number2_type: "",
        number3: "",
        number3_type: "",
        notes: "",
      };
    const [tenantReference, setTenantReference] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    const [tenantReferences, setTenantReferences] = useState([]);
    const [userReferenceRelationship, setUserReferenceRelationship] = useState(
        []
      );
      const [indexEdit,setIndexEdit ]= useState();
      const [editorData,setEditorData ]= useState("");
      const [editorData1,setEditorData1 ]= useState("");
      console.log('@tenantReference',tenantReference);
      console.log('@editorData1',editorData1);
      const [bulkAction , setBulkAction] = useState({
        bulk_action:""
      });
      const [showForm,setShowForm] = useState(false);
      const [viewForm,setViewForm] = useState(false);
      const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
      const getUserReferenceRelationships = async () => {
        const response = await ApiRequest.get(
          "/public/api/auth/get-users-references-relationships"
        );
        if (response.status == 200) {
          setUserReferenceRelationship(response?.data?.data?.relationships);
        }
      };
      const myDivRef = useRef(null);
      const notes = ()=>{
        const data = [];
        data.push(tenantReference);
        const array = [...data];
        // console.log('@array',array);
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
        // array[0].other_reference_relationship = cleanText;
        // console.warn("@CLEANTEXt",cleanText1);
        array[0].notes = editorData1;
        setTenantReference(array);
      }
      const bulkData = ()=>{
          notes();
          // setTenantReference({...tenantReference,notes:editorData}); 
          const newDataArray  = [];
          newDataArray.push(tenantReference);
          handleReferenceTrue(prevArray => [...prevArray, ...newDataArray]);
          setTenantReferences(prevArray => [...prevArray, ...newDataArray]);
          setTenantReference(initialReferenceData);
          setEditorData('');
          setShowForm(false);
          // setBulkSpaces("");
        }
      
      const editSpacesData = () =>{
          const array = [...tenantReferences];
          array[indexEdit] = tenantReference;
          // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
          // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
          // array[indexEdit].other_reference_relationship = cleanText;
          array[indexEdit].notes = editorData1;
          setTenantReferences(array);
      
          handleReferenceTrue(array);
          setShowForm(false);
          setIndexEdit(undefined);
          setTenantReference(initialReferenceData);
  
        }
        const indexFind = (index)=>
        {
          // var index = $(this).attr('data-index');
          // var modal = $(this).attr('data-bs-toggle');
          // const findIndex = parkingSpaces.length -1 - index ;
          setIndexEdit(index);
          setShowForm(true);
          setTenantReference(tenantReferences[index]);
          setEditorData(tenantReferences[index]?.other_reference_relationship)
          setEditorData1(tenantReferences[index]?.notes)
          myDivRef.current.scrollIntoView({ behavior: 'smooth' });
          // // console.log("index",findIndex);
          // // console.log("modal",modal);
        }
        const viewData = (index)=>{
          setShowForm(false)
          setTenantReference(tenantReferences[index]);
          setEditorData(tenantReferences[index]?.other_reference_relationship)
          setEditorData1(tenantReferences[index]?.notes)
          setViewForm(true);
          myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        const deleteData = (index)=>{
          setShowForm(false);
          setViewForm(false);
          const newArray = [...tenantReferences];
          newArray.splice(index, 1);
          setTenantReferences(newArray);
          handleReferenceTrue(newArray);
        }
        function removeNonNumeric(inputString) {
          var numericString = inputString.replace(/\D/g, "");
          return numericString;
        }
        const formatPhoneNumber = (number, format) => {
          const digitsOnly = number?.replace(/\D/g, '') || '';
          let formattedNumber = '';
          let digitIndex = 0;
        
          for (let i = 0; i < format?.length; i++) {
            if (format[i] === '#') {
              formattedNumber += digitsOnly[digitIndex] || '';
              digitIndex++;
            } else if (format[i] === '_') {
              formattedNumber += ' ';
            } else {
              formattedNumber += format[i];
            }
          }
        
          return formattedNumber;
        };
      const columns = [
          {
            name: "Last Name",
            selector: (data) => data.lastname,
            sortable: true,
          },
          {
            name: "First Name",
            selector: (data) => data.firstname,
            sortable: true,
          },
          {
            name: "Relationship",
            selector: (data) => userReferenceRelationship.map((row)=>{
              if(data.user_reference_relationship_id == row?.id)
              {
                return row?.relationship;
              }
            }),
            sortable: true,
          },
          {
            name: "Email",
            selector: (data) => data.number1 != "" ? <a className='text-black no-underline' href={`mailto:${data.email}`}>{data.email}</a> : "",
            sortable: true,
          },
          {
            name: "Number 1",
            selector: (data) => data.number1 != "" ? formatPhoneNumber(data.number1,ownerSetting?.phone_number_format) : "",
            sortable: true,
          },
          {
            name: "Reference Notes",
            selector: (data) => {
              const cleanText = new DOMParser().parseFromString(data?.notes, 'text/html').body.textContent;
              return cleanText;
            },
            sortable: true,
          },
          // {
          //   name: "Handicap",
          //   selector: (data) => data.handicap,
          // },
          // {
          //   name: "Assignee",
          //   selector : (data) => 'UnAssign'
          // },
          // {
          //   name: "Status",
          //   selector: (row) => row.is_active===1?"Active":"Inactive",
          // },
          {
            name: "Options",
            cell: (row,index) => {
              return (
                <>
                  {hiddenData != true ? (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewReferenceForm`}
                data-bs-toggle="modal"
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
               
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewReferenceForm`}
                data-bs-toggle="modal"
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
                  {/* <div className="md:w-1/12">
                  <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                    <i class="fa-regular fa-eye mr-6"></i>
                  </button>
                  </div> */}
                </>
              );
            },
          },
        ];
      const tableData = {
          columns : columns,
          data : tenantReferences,
        };
        const exportPDF = ()=>{
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "portrait"; // portrait or landscape
      
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
      
          doc.setFontSize(15);
      
          const title = "Tenant References";
          const headers = [["Last Name", "First Name","Relationship","Email","Number 1","Reference Notes"]];
      
          const data = tenantReferences.map(elt => [
            elt.lastname,
            elt.firstname,
            userReferenceRelationship.find((row)=>elt.user_reference_relationship_id == row?.id)?.relationship,
            elt.email,
            elt.number1,
            elt.notes
          ])
      
          let content = {
            startY: 50,
            head: headers,
            body: data
          };
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
          doc.save("Tenant References.pdf")
        }
        const exportCSV = () => {
          // Get data from data table
          const TotalData = tenantReferences.map(elt => [
            elt.lastname,
            elt.firstname,
            userReferenceRelationship.find((row)=>elt.user_reference_relationship_id == row?.id)?.relationship,
            elt.email,
            elt.number1,
            elt.notes
          ])
          // Convert the data to a CSV string
      
          const headers = ["Last Name", "First Name","Relationship","Email","Number 1","Reference Notes"];
          // let content = {
          //   head:headers,
          //   body:TotalData,
          // }
          TotalData.unshift(headers)
          const csv = Papa.unparse(TotalData);
          // Create a blob of the data
          const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          // Create a link to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Tenant References.csv");
          link.click();
        };
      useEffect(() => {
        getNumberType();
        getUserReferenceRelationships();
        setTenantReference(initialReferenceData);
        setTenantReferences(data);
        setOwnerSetting(ownerSettings)
      }, [])
      
  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewReferenceForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewReferenceFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Reference
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantReference([]);
                    setEditorData(null);
                    setEditorData1(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ? <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex">
                    {tenantReference?.firstname != "" ? <span className='font-bold capitalize'>{tenantReference?.firstname}</span>:null}
                    {tenantReference?.lastname != "" ? <span className='font-bold capitalize ml-2'>{tenantReference?.lastname}</span>:null}
                  </div>
                  {tenantReference?.user_reference_relationship_id != ""?
                  <div className='flex'>
                    <span>{userReferenceRelationship?.map((row)=>{
                          if(tenantReference?.user_reference_relationship_id == row?.id)
                          {
                            return row?.relationship
                          }
                        })}</span>
                  </div>:null}
                  {tenantReference?.email != "" ? <div className='flex mt-2'>
                    <a className='text-black no-underline' href={`mailto:${tenantReference?.email}`}>{tenantReference?.email}</a>
                  </div>:null}
                  {tenantReference?.number1 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(tenantReference?.number1_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${tenantReference?.number1}`}>{tenantReference?.number1_type == 8 ? tenantReference?.number1 : formatPhoneNumber(tenantReference?.number1,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {tenantReference?.number2 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(tenantReference?.number2_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${tenantReference?.number2}`}>{tenantReference?.number2_type == 8 ? tenantReference?.number2 : formatPhoneNumber(tenantReference?.number2,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {tenantReference?.number3 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(tenantReference?.number3_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${tenantReference?.number3}`}>{tenantReference?.number3_type == 8 ? tenantReference?.number3 : formatPhoneNumber(tenantReference?.number3,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {tenantReference?.notes != "" ?
                        <>
                          <hr />
                          <h5>Notes:</h5>
                          <div className="flex">
                          <div dangerouslySetInnerHTML={{ __html: tenantReference?.notes }} />
                          </div>
                        </>:null}


                  
                </div>
              </div>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantReference([]);
                                setEditorData(null);
                                setEditorData1(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-references.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                References:
              </label>
            </div>
          </div>
          {showForm == false ? 
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Reference
                </button>
              </div>
            </div>
          </div>:null}

              {showForm == true ? <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reference-firstname`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={tenantReference?.firstname}
                          onChange={(e) => {
                            setTenantReference({...tenantReference,firstname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reference-lastname`}
                          type="text"
                          placeholder="Deo"
                          required={false}
                          value={tenantReference?.lastname}
                          onChange={(e) => {
                            setTenantReference({...tenantReference,lastname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/3">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Relationship:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          required={false}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`relationship`}
                          value={tenantReference?.user_reference_relationship_id}
                          onChange={(e) => {
                            setTenantReference({...tenantReference,user_reference_relationship_id:parseInt(e.target.value)});
                          }}
                        >
                          <option value="">Select Relationship</option>
                          {userReferenceRelationship.map((relation) => (
                            <option value={relation?.id}>
                              {relation?.relationship}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      {tenantReference?.user_reference_relationship_id === 5 ? (
                      <div className="md:flex mt-3">
                        <div className="md:w-1/2">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Other Relationship Description:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reference-other`}
                          type="text"
                          required={false}
                          placeholder="Other Reference"
                          value={tenantReference?.other_reference_relationship}
                          onChange={(e) => {
                              setTenantReference({...tenantReference,other_reference_relationship:e.target.value});
                            }}
                        />
                        </div>
                      </div>
                    ) : null}
                    </div>
                  </div>
                  
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Email:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-email`}
                        type="email"
                        required={false}
                        placeholder="john.deo@gmail.com"
                        value={tenantReference?.email}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,email:e.target.value});
                          }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 1:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      {tenantReference?.number1_type == 8 ? 
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-1`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={tenantReference.number1}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number1:e.target.value});
                          }}
                      />:
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-1`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(tenantReference.number1,ownerSetting?.phone_number_format)}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number1:removeNonNumeric(e.target.value)});
                          }}
                      />
                      }
                    </div>
                    {/* <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div> */}
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-1-type`}
                        value={tenantReference?.number1_type}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number1_type:e.target.value});
                          }}
                      >
                        <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 2:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                    {tenantReference?.number2_type == 8 ? 
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-2`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={tenantReference.number2}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number2:e.target.value});
                          }}
                      />:
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-2`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(tenantReference.number2,ownerSetting?.phone_number_format)}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number2:removeNonNumeric(e.target.value)});
                          }}
                      />
                      }
                    </div>
                    {/* <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div> */}
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-2-type`}
                        value={tenantReference?.number2_type}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number2_type:e.target.value});
                          }}
                      >
                        <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 3:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                    {tenantReference?.number3_type == 8 ? 
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-3`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={tenantReference.number3}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number3:e.target.value});
                          }}
                      />:
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-3`}
                        type="tel"
                        required={false}
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(tenantReference.number3,ownerSetting?.phone_number_format)}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number3:removeNonNumeric(e.target.value)});
                          }}
                      />
                      }
                    </div>
                    {/* <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div> */}
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-3-type`}
                        value={tenantReference?.number3_type}
                        onChange={(e) => {
                            setTenantReference({...tenantReference,number3_type:e.target.value});
                          }}
                      >
                        <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5 flex">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reference Notes:
                      </label>
                      <Tooltip data={"Only the Owner/Property Manager(s) can see these notes. You can write down comments the reference said about the person/etc"}/>
                    </div>
                    <div className="md:w-4/5 ml-2">
                      <CKEditor
                        initData={editorData1}
                        onChange={(e) => {
                            setEditorData1(e.editor.getData());
                          }}
                      />
                    </div>
                  </div>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            editSpacesData();
                          } else {
                            bulkData();
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>:null}
             

         

          <hr />
          {tenantReferences?.length > 0 ?<><div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantReferences}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions></>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
          {/* <hr /> */}
    </>
  )
}
