import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";
import { MoveinandOutForm } from "./MoveinandOutForm";

export const LeaseMovingForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Move-in Walkthrough" {...a11yProps(0)} />
          <Tab label="Move-out Notices" {...a11yProps(1)} />
          <Tab label="Move-out Walkthrough" {...a11yProps(2)} />
          <Tab label="Tenant Vacating" {...a11yProps(3)} />
          <Tab label="Deposit Return" {...a11yProps(4)} />
          <Tab label="Move-in and Out" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-in Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Time:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Walkthrough Done by:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-to-do.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Walkthrough List:
                  </label>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <hr />
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <img
                      src="/assets/svgs/system-picture.svg"
                      alt="Picture"
                      className="w-1/3"
                    />
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Add Picture
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-notes.svg"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/support-ready-unit.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Add Ready-Unit Tickets:
                  </label>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                I agree that this walkthrough report is accurate and any issues
                noticed at time of move-in are reported. If Issues that require
                fixing are not done within 30 days of move-in, I under- stand
                that I can vacate the lease without penalty. Furthermore, any
                issues that are noted, but not in need of repair will not count
                against my security deposit upon move out.
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tenant Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Walkthrough Agent Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4 mb-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-move-out-notice.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Notice:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto mt-4">
            <div className="w-full">
              <div className="w-full mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                  Notice Submitted On:
                </label>
              </div>
              <div className="flex items-center mt-3 w-full">
                <div className="flex w-3/5 items-center">
                  <div className="flex items-center w-1/2">
                    <span className="mr-1">Date:</span>
                    <span className="font-bold mr-3">MM/DD/YYYY</span>
                  </div>
                  <div className="flex items-center w-1/2">
                    <span className="mr-1">Time:</span>
                    <span className="font-bold">HH:MM:MM</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-3 w-full">
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mr-2 mb-2">
                  Move-Out Reason:
                </label>
                <label className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Reason
                </label>
              </div>
              <div className="flex flex-column mt-3 w-full">
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mr-2 mb-2">
                  Message:
                </label>
                <span>
                  Autogenerated text from message .Autogenerated text from
                  message .Autogenerated text from message .Autogenerated text
                  from message .Autogenerated text from message .Autogenerated
                  text from message .Autogenerated text from message
                  .Autogenerated text from message .Autogenerated text from
                  message .Autogenerated text from message .Autogenerated text
                  from message .Autogenerated text from message .Autogenerated
                  text from message .Autogenerated text from message
                  .Autogenerated text from message .Autogenerated text from
                  message .Autogenerated text from message .Autogenerated text
                  from message .Autogenerated text from message .Autogenerated
                  text from message .Autogenerated text from message
                  .Autogenerated text from message .Autogenerated text from
                  message .Autogenerated text from message .Autogenerated
                  text.....
                </span>
              </div>
              <div className="flex items-center mt-3 w-full">
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mr-2 mb-2">
                  Planned Move-Out Date:
                </label>
                <label className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
                  MM/DD/YYYY
                </label>
              </div>
              <div className="flex items-center mt-3 w-full">
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mr-2 mb-2">
                  Deposits Tenant Paid:
                </label>
                <label className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
                  $21323
                </label>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  <i class="fa fa-plus"></i> Add Manual Move-Out Notice
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-end.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Walkthrough:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Time:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Walkthrough Done by:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-to-do.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Walkthrough List:
                  </label>
                </div>
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <div class="flex items-center mt-3">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Sample item</span>
                </label>
                <input
                  type="text"
                  class="appearance-none block w-4/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Input Field"
                />
              </div>
              <hr />
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <img
                      src="/assets/svgs/system-picture.svg"
                      className="w-1/3"
                      alt="Picture"
                    />
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Add Picture
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-notes.svg"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/support-ready-unit.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Add Ready-Unit Tickets:
                  </label>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                I agree that this walkthrough report is accurate and any issues
                noticed at time of move-in are reported. If Issues that require
                fixing are not done within 30 days of move-in, I under- stand
                that I can vacate the lease without penalty. Furthermore, any
                issues that are noted, but not in need of repair will not count
                against my security deposit upon move out.
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tenant Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Walkthrough Agent Signature:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        E-sign
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:w-full">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mr-2">
                      Tenant Not Present:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      // value={formData?.hoa_show_to_tenant}
                      // onChange={(e) => {
                      //   const checkedValue = e.target.checked === true ? 1 : 0;
                      //   setFormData({
                      //     ...formData,
                      //     hoa_show_to_tenant: checkedValue,
                      //   });
                      // }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-notes.svg"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Reason:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-vacating.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Tenant Vacating:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2 mr-2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Vacating Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Time:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="time"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/assets-keys.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                    Keys Returned:
                  </label>
                </div>
              </div>
              <hr />
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/assets-loanable-items.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                    Loanable Items Returned:
                  </label>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-moving-address.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-Out Address:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.moveout_address1}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address1:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address1: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address2"
                    type="text"
                    placeholder="Unit #5"
                    // value={formData?.moveout_address2}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_address2:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_address2: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_country"
                      type="text"
                      //   value={formData?.moveout_country}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_country:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_country: e.target.value,
                      //     });
                      //     getStates(e.target.value)
                      //   }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_state"
                      required={false}
                      //   value={formData?.moveout_state}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_state:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_state: e.target.value,
                      //     });
                      //     getCities(e.target.value)
                      //   }}
                    >
                      <option value="">Select State</option>
                      {/* {states?.map((state)=>(
                        <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_city"
                      type="text"
                      //   value={formData?.moveout_city}
                      placeholder="Albuquerque"
                      required={false}
                      //   onChange={(e) => {
                      //     const zip = cities?.map((col) =>{
                      //       if(col?.id == e.target.value)
                      //       {
                      //         return col?.zip_code
                      //       }
                      //     })
                      //     setCreateLog({...createLog,moveout_city:1})
                      //     const filteredArr = zip.filter((value) => value !== undefined);
                      //     const zipCode = filteredArr.join(', ');
                      //     setFormData({
                      //       ...formData,
                      //       moveout_city: e.target.value,
                      //       moveout_zip_code:zipCode
                      //     });
                      //   }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_zip_code"
                      type="text"
                      placeholder="90210"
                      required={false}
                      //   value={formData?.moveout_zip_code}
                      //   onChange={(e) => {
                      //     setCreateLog({...createLog,moveout_zip_code:1})
                      //     setFormData({
                      //       ...formData,
                      //       moveout_zip_code: e.target.value,
                      //     });
                      //   }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.moveout_number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    // maxLength={ownerSettings?.phone_number_format?.length}
                    // value={formData?.moveout_number}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number: e.target.value,
                    //   });
                    // }}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    maxLength={ownerSettings?.phone_number_format?.length}
                    value={formatPhoneNumber(formData?.moveout_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number:1});
                      setFormData({
                        ...formData,
                        moveout_number: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  } */}
                </div>
                {/* <div className="md:w-1/6">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div> */}
                <div className="md:w-1/5 pl-3">
                  <select
                    required={false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number_type"
                    // value={formData?.moveout_number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_number_type:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_number_type: e.target.value,
                    //   });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_email"
                    type="email"
                    placeholder="john.doe@email.com"
                    // value={formData?.moveout_email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,moveout_email:1});
                    //   setFormData({
                    //     ...formData,
                    //     moveout_email: e.target.value,
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-security-deposit-return.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Deposit Return:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <img src="/assets/svgs/system-print-with-attachments.svg" alt="" />
              </div>
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center mt-3 w-1/3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Vacated Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                  </div>
                </div>
                <div className="flex items-center mt-3 w-1/3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date Mailed:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center mt-3 w-1/3">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tracking Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="1234"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl italic font-bold mb-2">
                  Deposit Received:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3">
                    <span>04/02/2022</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>AJ Harte</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <hr />
              </div>
              <div className="md:flex md:items-center w-full mt-1">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="flex items-center mt-3 w-1/3"></div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>Total Deposits:</span>
                  </div>
                  <div className="flex items-center mt-3 w-1/3">
                    <span>$876</span>
                  </div>
                </div>
              </div>
              <div className="flex w-full mt-3">
                <img src="/assets/svgs/add.svg" alt="Add" className="w-1/12" />
                <span className="ml-2">Add Interest/etc</span>
              </div>
              <div className="flex mt-3">
                <label className="block capitalize tracking-wide text-gray-700 text-xl italic font-bold mb-2">
                  Deposit Deductions:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="deposit_name"
                      type="text"
                      placeholder="Name"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="deposit_amount"
                      type="text"
                      placeholder="Name"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Deduction Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex items-center mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Deduction Pictures:
                    </label>
                  </div>
                </div>
                <div className="md:w-1/4">
                  <div className="flex">
                    <button className="btn btn3" type="button">
                      Take
                    </button>
                  </div>
                </div>
                <div className="md:w-1/4">
                  <div className="flex">
                    <button className="btn btn3" type="button">
                      Upload
                    </button>
                  </div>
                </div>
                <div className="md:w-1/2">
                  <div className="flex">
                    <button className="btn btn3" type="button">
                      Choose from Walkthrough
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Total Deductions:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Balance:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_address1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    /> */}
                </div>
              </div>
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Other Deposit Return Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/system-notes.svg"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Deposit Return Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center mt-3 w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Deposit Status:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="deposit_amount"
                      type="text"
                      placeholder="Name"
                      required={false}
                      // value={formData?.moveout_address1}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,moveout_address1:1});
                      //   setFormData({
                      //     ...formData,
                      //     moveout_address1: e.target.value,
                      //   });
                      // }}
                    >
                      <option value="">Select</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Save as Draft
                </button>
              </div>
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <MoveinandOutForm/>
        </TabPanel>
      </form>
    </>
  );
};
