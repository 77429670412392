import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./TabPanel";

import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ParkingSpaceTable from "./ParkingSpaceTable";
import { useDispatch, useSelector } from "react-redux";
import { propertiesTypes } from "../../store/properties/propertiesThunk";
import {
  materialType,
  materialBrand,
} from "../../store/properties/materialmeasurementsThunk";
import imgUrl from "../ImageUrl";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loading";
import FrontTree from "../../tree/FrontTree";
import { PropertyMeasurements } from "./PropertyMeasurements";
import { PropertyMaterials } from "./PropertyMaterials";
import { PropertyBoards } from "./PropertyBoards";
import useAlerts from "../../utility/alerts";
import { PropertyNotifications } from "./PropertyNotifications";
import { PropertyAssistanceInformations } from "./PropertyAssistanceInformations";
import { PropertySecurityContacts } from "./PropertySecurityContacts";
import { PropertyLocations } from "./PropertyLocations";
import { InfoContactForm } from "./InfoContactForm";
import { HoaInteractions } from "./HoaInteractions";
// import { MortgageContactForm } from "./MortgageContactForm";
// import { BuilderContactForm } from "./BuilderContactForm" ;
import { MortgageInteractions } from "./MortgageInteractions";
import { BuilderInteractions } from "./BuilderInteractions";
import { Lightbox } from "../lightbox/Lightbox";
import { Notes } from "./Notes";
import { Tooltip } from "../Loader/Tooltip";
import { Interactions } from "../tenant/Interactions";
import Swal from "sweetalert2";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";
import FolderStructure from "../../tree/FolderStructure";

export const EditPropertyForm = ({
  initialData = {},
  validationData = {},
  verticalScroll = 0,
  updateFormData,
  handleName
}) => {
  //  CONSTANTS

  const formElement = document.getElementById("add-property-form");
  const formSubmitButton = document.getElementById("form-submit-button");

  const picTypes = useAllowedPicMimeTypes();
  const fileTypes = useAllowedMimeTypes();

  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialPictureData = {
    picutres: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
      order_number: "",
    },
  };

  const initialContactData = {
    firstname: "",
    lastname: "",
    title: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    email: "",
    info: "",
    picture: null,
  };
  const initialSecurityData = {
    firstname: "",
    lastname: "",
    title: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    email: "",
    info: "",
    picture: null,
  };
  const initialSpaceData = {
    parking_space_prefix_id: "",
    space_number: "",
    parking_space_suffix_id: "",
    compact: 0,
    tandem: 0,
    electric_vehicle_charging: 0,
    handicap: 0,
  };
  const initialFeatureData = null;

  const initialMeasurementData = {
    name: "",
    location: "",
    measurement_unit: "inch",
    measurement_type_id: "",
    property_location_id: "",
    measurement_unit_id: 1,
    length: "",
    width: "",
    height: "",
    depth: "",
    area: "",
    volume: "",
    amount: "",
    pictures: [],
    files: [],
    notes: "",
  };

  const initialMaterialData = {
    material_type_id: "",
    material_location_id: "",
    material_brand_id: "",
    material_identifier_id: "",
    material_vendor_id: "",
    last_installed: "",
    pictures: [
      // {
      // 	picture: null,
      // 	order_number: "",
      // },
    ],
    files: [
      // {
      // 	file: null,
      // },
    ],
    notes: "",
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };

  const initialBoardData = {
    title: "",
    icon: "",
    board: "",
  };

  // STATES
  const Alert = useAlerts();
  const { alertList } = useSelector((state) => state.alerts);
  const [lastEndLine, setLastEndLine] = useState();
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyMaterialTypes, setPropertyMaterialTypes] = useState([]);
  const [propertyMeasurementUnit, setPropertyMeasurementUnit] = useState([]);
  const [propertyMeasurementType, setPropertyMeasurementType] = useState([]);
  const [propertyMaterialLocations, setPropertyMaterialLocations] = useState(
    []
  );
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [propertyMaterialBrands, setPropertyMaterialBrands] = useState([]);
  const [propertyMaterialIdentifiers, setPropertyMateriaIdentifiers] = useState(
    []
  );
  const [files, setFiles] = useState([]);
  console.log("finalfiles", files);
  const [validation, setValidation] = useState({});
  // console.log('validations',validation);

  const [newMaterialType, setNewMaterialType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialLocation, setNewMaterialLocation] = useState({
    id: null,
    location: "",
  });
  const [newMeasuremnetType, setNewMeasuremnetType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialBrand, setNewMaterialBrand] = useState({
    id: null,
    brand: "",
  });
  const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
    id: null,
    identifier: "",
  });
  const [onlyYear,setOnlyYear] = useState(false);
  const [parkingSpacePrefix, setParkingSpacePrefix] = useState([]);
  const [parkingSpaceSuffix, setParkingSpaceSuffix] = useState([]);
  const [formData, setFormData] = useState();
  const [editorData, setEditorData] = useState();
  const [propertyLocations,setPropertyLocations] = useState([]);
  const [editorData1, setEditorData1] = useState();
  const [editorData2, setEditorData2] = useState();
  const [editorData3, setEditorData3] = useState();
  const [loading, setLoading] = useState();
  console.log(loading);
  console.log("This is result", formData);
  console.log("This is editor result", editorData);
  console.log("This is editor1 result", editorData1);
  console.log("This is editor2 result", editorData2);
  const [securedFields, setSecuredFields] = useState({
    propertyWifiPassword: "password",
    propertyAlarmCode: "password",
    propertyGateCode: "password",
    propertyDoorCode: "password",
  });

  const [propertyFormImagesWithUrl, setPropertyFormImagesWithUrl] = useState(
    []
  );
  const [features, setFeatures] = useState([]);
  const [addFeature, setAddFeature] = useState([]);
  const [checkFeature, setCheckFeature] = useState();

  const [nearbys, setNearbys] = useState([]);
  const [addNearby, setAddNearby] = useState([]);
  const [checkNearby, setCheckNearby] = useState();

  const [propertyContacts, setPropertyContacts] = useState([]);
  const [propertyAssistantPictures, setPropertyAssistantPictures] = useState(
    []
  );
  const [parkingSpaces, setParkingSpaces] = useState([]);
  const [parkingSpace, setParkingSpace] = useState({
    space_number: "",
    parking_space_prefix_id: "",
    parking_space_suffix_id: "",
    compact: 0,
    tandem: 0,
    electric_vehicle_charging: 0,
    handicap: 0,
  });
  const [bulkSpaces, setBulkSpaces] = useState({
    spaces_add: "",
    starting_number: "",
    prefix: "",
    suffix: "",
  });
  
  const [propertyMeasurements, setPropertyMeasurements] = useState([]);
  console.warn("propertyMeasurements", propertyMeasurements);
  const [propertyMeasurementPictures, setPropertyMeasurementPictures] =
    useState([]);
  const [propertyMeasurementFiles, setPropertyMeasurementFiles] = useState([]);

  const [propertyMaterials, setPropertyMaterials] = useState([]);
  const [propertyMaterialPictures, setPropertyMaterialPictures] = useState([]);
  const [propertyMaterialFiles, setPropertyMaterialFiles] = useState([]);

  const [propertyHoaFiles, setPropertyHoaFiles] = useState([]);
  const [propertyMortageFiles, setPropertyMortageFiles] = useState([]);
  const [propertyBuilderFiles, setPropertyBuilderFiles] = useState([]);


  const [propertyHoaContacts, setPropertyHoaContacts] = useState([]);
  const [propertyHoaInteractions, setPropertyHoaInteractions] = useState([]);
  const [propertyMortageContacts, setPropertyMortageContacts] = useState([]);
  const [propertyMortageInteractions, setPropertyMortageInteractions] = useState([]);
  const [propertyBuilderContacts, setPropertyBuilderContacts] = useState([]);
  const [propertyBuilderInteractions, setPropertyBuilderInteractions] = useState([]);

  const [propertyNotification, setPropertyNotification] = useState([]);
  const [propertyBoards, setPropertyBoards] = useState([]);
  const [propertyBoardPicture, setPropertyBoardPicture] = useState([]);

  const [
    propertyClaimResolutionDocumentsWithUrl,
    setPropertyClaimResolutionDocumentsWithUrl,
  ] = useState([]);
  const [publicNotes,setPublicNotes] = useState([]);
  const [staffNotes,setStaffNotes] = useState([]);
  const [managerNotes,setManagerNotes] = useState([]);
  const [ownerNotes,setOwnerNotes] = useState([]);
  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [propertyManagerDocumentsWithUrl, setPropertyManagerDocumentsWithUrl] =
    useState([]);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [propertyOwnerDocumentsWithUrl, setPropertyOwnerDocumentsWithUrl] =
    useState([]);
  const [propertySecurityPicture, setPropertySecurityPicture] = useState([]);
  const [propertySecurityContacts, setPropertySecurityContacts] = useState([]);
  const [propertySecurityPictures, setPropertySecurityPictures] = useState(
    []
  );
  // console.log("PropertySecurityPicture",propertySecurityPicture)
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [result, setResult] = useState();
  const [dateTaken, setDateTaken] = useState(new Date());
  const [floorData] = useState(() => {
    const numberArray = [];
    for (let i = 1; i <= 200; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });
  const [handleFile,setHandleFile] = useState();
  const [templateID,setTemplateID] = useState();
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }

    const [dueDate] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 31; i++) {
      optionsArray.push(i);
    }
    // for (let x = 1; x<=14; x++)
    // {
    //   optionsArray.push(x + " studio");
    // }
    return optionsArray;
  });
  // const [parkingSpaces, setParkingSpaces] = useState([]);
  const handleTrue=(message)=>{
    setParkingSpaces(message);
  }
  const handleNoteTrue = (message)=>{
    setPublicNotes(message)
  }
  const handleStaffNoteTrue = (message)=>{
    setStaffNotes(message)
  }
  const handleManagerNoteTrue = (message)=>{
    setManagerNotes(message)
  }
  const handleOwnerNoteTrue = (message)=>{
    setOwnerNotes(message)
  }
  const handleBoardTrue=(message)=>{
    setPropertyBoards(message);
  }
  const handleNotificationTrue = (message)=>{
    setPropertyNotification(message)
  }
  const handleHoaContactTrue = (message)=>{
    setPropertyHoaContacts(message)
  }
  const handleHoaInteractionTrue = (message)=>{
    setPropertyHoaInteractions(message)
  }
  const handleMortageContactTrue = (message)=>{
    setPropertyMortageContacts(message)
  }
  const handleMortageInteractionTrue = (message)=>{
    setPropertyMortageInteractions(message)
  }
  const handleBuilderContactTrue = (message)=>{
    setPropertyBuilderContacts(message)
  }
  const handleBuilderInteractionTrue = (message)=>{
    setPropertyBuilderInteractions(message)
  }
  console.log("HandleFile",handleFile);
  console.log("templateID",templateID);
  // console.log('handleFile',handleFile);
  const handlePicturesData = (message) => {
    setPropertyFormImagesWithUrl(message);
    let propertyCopy = [...message]; // Use the updated 'message' parameter instead of 'propertyFormImagesWithUrl'
    propertyCopy.pictures = message.map((picObj, index) => ({
      picture: picObj.picture,
      title: picObj.title,
      caption: picObj.caption,
      order_number: index + 1, // Update the order number to ensure consecutive numbers
      is_cover: picObj.is_cover,
    }));
    setFormData({ ...formData, pictures: propertyCopy?.pictures });
  };
  const handleFiles = (message)=>{
    setHandleFile(message);
  }
  const getPictureTemplateID = (message)=>{
    setTemplateID(message);
  }
  const handleContactTrue=(message)=>{
    setPropertyContacts(message);
  }
  const handleSecurityContactTrue=(message)=>{
    setPropertySecurityContacts(message);
  }
  const handleLocationTrue=(message)=>{
    setPropertyLocations(message);
  }
  //  METHODS
  const DateCalculator = (date) => {
    const currentDate = new Date();
    const cDate = new Date(date);
    const diffInMilliseconds = currentDate - cDate;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInMonths / 12;
    const years = Math.floor(diffInYears);
    const months = Math.floor((diffInYears - years) * 12);
    const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
    const StringYear = years === 1 ? "year" : "years";
    const StringMonth = months === 1 ? "month" : "months";
    const StringDay = days === 1 ? "day" : "days";
    var result;
    if (days === 0 && years !== 0 && months !== 0) {
      result = years + " " + StringYear + ", " + months + " " + StringMonth;
    } else if (years === 0 && months !== 0 && days !== 0) {
      result = months + " " + StringMonth + ", " + days + " " + StringDay;
    } else if (months === 0 && years !== 0 && days !== 0) {
      result = years + " " + StringYear + ", " + days + " " + StringDay;
    } else if (days === 0 && months === 0 && years !== 0) {
      result = years + " " + StringYear;
    } else if (days === 0 && years === 0 && months !== 0) {
      result = months + " " + StringMonth;
    } else if (years === 0 && months === 0 && days !== 0) {
      result = days + " " + StringDay;
    } else if (years !== 0 || months !== 0 || days !== 0) {
      result =
        years +
        " " +
        StringYear +
        ", " +
        months +
        " " +
        StringMonth +
        ", " +
        days +
        " " +
        StringDay;
    } else {
      result = "";
    }
    return result;
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const SingleSpaceData = () => {
    parkingSpaces.push(parkingSpace);
    const parking_spaces = [...parkingSpaces];
    setFormData({ ...formData, parking_spaces: parking_spaces });
    setParkingSpace({
      space_number: "",
      parking_space_prefix_id: "",
      parking_space_suffix_id: "",
      compact: 0,
      tandem: 0,
      electric_vehicle_charging: 0,
      handicap: 0,
    });
  };
  const bulkData = () => {
    for (var i = 0; i < bulkSpaces.spaces_add; i++) {
      parkingSpaces.push(initialSpaceData);
      const parking_spaces = [...parkingSpaces];
      setFormData({ ...formData, parking_spaces: parking_spaces });
    }
  };
  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };

  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";

  const base64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { image: imgBase64 });
        console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        formData.pictures.push({ picture: res?.data?.data?.image });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.image}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const HOAbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.hoa_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Mortagebase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.mortage_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Builderbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({files:res?.data?.data?.file});
        // setFiles(res?.data?.data?.file);
        formData.builder_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Publicbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_tenant_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Staff64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_staff_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Managerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_manager_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Ownerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.property_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const MeasurementPicbase64 = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { file: imgBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.measurements.pictures.push({ picture: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        // console.warn("base64converted files",formData)
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const removeByAttr = (arr, attr, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);

    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

    return TotalTime;
  };
  function stringToTime(timeString) {
    console.warn("timeString", timeString);
    if (timeString === null || timeString === undefined || timeString >= 0)
      return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1];
    let dayNight = ampm[1];

    let time = hours + ":" + minutes + " " + dayNight;
    let isPM = false;

    if (time.endsWith("am")) {
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time;
  }
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    console.warn("DateString", dateString);
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    console.warn("UnformatDate", date);
    return date;
  }
  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, '') || '';
    let formattedNumber = '';
    let digitIndex = 0;
  
    for (let i = 0; i < format?.length; i++) {
      if (format[i] === '#') {
        formattedNumber += digitsOnly[digitIndex] || '';
        digitIndex++;
      } else if (format[i] === '_') {
        formattedNumber += ' ';
      } else {
        formattedNumber += format[i];
      }
    }
  
    return formattedNumber;
  };
  function removeCurrencyAndCommas(str) {
    if (str === null) {
      return '';
    }
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  function convertCurrencyToFormat(currency, number) {
    let formattedCurrency = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
    }).format(number);
  
    return formattedCurrency;
  }
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };

  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="property image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;
     
    const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };
  const handleMeasurementTrue=(message)=>{
    setPropertyMeasurements(message);
  }
  const handleMaterialTrue=(message)=>{
    setPropertyMaterials(message);
  }
  handleName(formData?.name);
  const getProperty = async () => {
    const response = await ApiRequest.get(
      `public/api/auth/get-property?property_id=${id}`
    );
    setLoading(response?.status);

    if (response.status === 200) {
      var formData2 = {
        name: response?.data?.data?.name,
        address_line_1: response?.data?.data?.address_line_1,
        address_line_2: response?.data?.data?.address_line_2,
        is_active: response?.data?.data?.is_active,
        city: response?.data?.data?.city,
        state: response?.data?.data?.state,
        type_id: response?.data?.data?.type_id,
        zip_code: response?.data?.data?.zip_code,
        embed: response?.data?.data?.embed,
        picture_template_id: response?.data?.data?.picture_template_id,

        security_firstname: response?.data?.data?.security_firstname,
        security_lastname: response?.data?.data?.security_lastname,
        security_company: response?.data?.data?.security_company,
        security_number1: response?.data?.data?.security_number1,
        security_number1_type: response?.data?.data?.security_number1_type,
        security_number2: response?.data?.data?.security_number2,
        security_number2_type: response?.data?.data?.security_number2_type,
        security_number3: response?.data?.data?.security_number3,
        security_number3_type: response?.data?.data?.security_number3_type,
        security_email: response?.data?.data?.security_email,
        security_info: editorData?.security_info,
        security_picture: response?.data?.data?.security_picture,
        wifi_network: response?.data?.data?.wifi_network,
        wifi_password: response?.data?.data?.wifi_password,
        alarm_code: response?.data?.data?.alarm_code,
        gate_code: response?.data?.data?.gate_code,
        door_code: response?.data?.data?.door_code,
        mailbox_number: response?.data?.data?.mailbox_number,
        other_info: response?.data?.data?.other_info,

        no_of_floors: response?.data?.data?.no_of_floors,
        has_elevator: response?.data?.data?.has_elevator,
        has_stairs: response?.data?.data?.has_stairs,
        description: response?.data?.data?.description,
        other_property_features: response?.data?.data?.other_property_features,
        features: formData?.features,

        hoa_show_to_tenant: response?.data?.data?.hoa_show_to_tenant,
        hoa_show_to_property_manager:
          response?.data?.data?.hoa_show_to_property_manager,
        hoa_company: response?.data?.data?.hoa_company,
        hoa_contact_firstname: response?.data?.data?.hoa_contact_firstname,
        hoa_contact_lastname: response?.data?.data?.hoa_contact_lastname,
        hoa_contact_title: response?.data?.data?.hoa_contact_title,
        hoa_address_line1: response?.data?.data?.hoa_address_line1,
        hoa_address_line2: response?.data?.data?.hoa_address_line2,
        hoa_city: response?.data?.data?.hoa_city,
        hoa_state: response?.data?.data?.hoa_state,
        hoa_zip_code: response?.data?.data?.hoa_zip_code,
        hoa_number1: response?.data?.data?.hoa_number1,
        hoa_number1_type: response?.data?.data?.hoa_number1_type,
        hoa_number2: response?.data?.data?.hoa_number2,
        hoa_number2_type: response?.data?.data?.hoa_number2_type,
        hoa_number3: response?.data?.data?.hoa_number3,
        hoa_number3_type: response?.data?.data?.hoa_number3_type,
        hoa_email: response?.data?.data?.hoa_email,
        hoa_website: response?.data?.data?.hoa_website,
        hoa_fee: response?.data?.data?.hoa_fee,
        hoa_fee_show_to_property_manager:
          response?.data?.data?.hoa_fee_show_to_property_manager,
        hoa_fee_show_to_tenant: response?.data?.data?.hoa_fee_show_to_tenant,
        hoa_due_date: response?.data?.data?.hoa_due_date,
        hoa_owner_notes: response?.data?.data?.hoa_owner_notes,
        hoa_property_manager_notes:
          response?.data?.data?.hoa_property_manager_notes,
        hoa_tenant_notes: response?.data?.data?.hoa_tenant_notes,
        hoa_files: response?.data?.data?.hoa_files,

        mortage_company: response?.data?.data?.mortage_company,
        mortage_contact_firstname:
          response?.data?.data?.mortage_contact_firstname,
        mortage_contact_lastname:
          response?.data?.data?.mortage_contact_lastname,
        mortage_contact_title: response?.data?.data?.mortage_contact_title,
        mortage_address_line1: response?.data?.data?.mortage_address_line1,
        mortage_address_line2: response?.data?.data?.mortage_address_line2,
        mortage_city: response?.data?.data?.mortage_city,
        mortage_state: response?.data?.data?.mortage_state,
        mortage_zip_code: response?.data?.data?.mortage_zip_code,
        mortage_number1: response?.data?.data?.mortage_number1,
        mortage_number1_type: response?.data?.data?.mortage_number1_type,
        mortage_number2: response?.data?.data?.mortage_number2,
        mortage_number2_type: response?.data?.data?.mortage_number2_type,
        mortage_number3: response?.data?.data?.mortage_number3,
        mortage_number3_type: response?.data?.data?.mortage_number3_type,
        mortage_email: response?.data?.data?.mortage_email,
        mortage_website: response?.data?.data?.mortage_website,
        mortage_minimum_payment: response?.data?.data?.mortage_minimum_payment,
        mortage_due_date: response?.data?.data?.mortage_due_date,
        mortage_balance: response?.data?.data?.mortage_balance,
        mortage_files: response?.data?.data?.mortage_files,
        mortage_notes: editorData?.mortage_notes,

        builder_company: response?.data?.data?.builder_company,
        builder_date: response?.data?.data?.builder_date,
        builder_firstname: response?.data?.data?.builder_firstname,
        builder_lastname: response?.data?.data?.builder_lastname,
        builder_title: response?.data?.data?.builder_title,
        builder_address_line1: response?.data?.data?.builder_address_line1,
        builder_address_line2: response?.data?.data?.builder_address_line2,
        builder_city: response?.data?.data?.builder_city,
        builder_state: response?.data?.data?.builder_state,
        builder_zip_code: response?.data?.data?.builder_zip_code,
        builder_number1: response?.data?.data?.builder_number1,
        builder_number1_type: response?.data?.data?.builder_number1_type,
        builder_number2: response?.data?.data?.builder_number2,
        builder_number2_type: response?.data?.data?.builder_number2_type,
        builder_number3: response?.data?.data?.builder_number3,
        builder_number3_type: response?.data?.data?.builder_number3_type,
        builder_email: response?.data?.data?.builder_email,
        builder_website: response?.data?.data?.builder_website,
        builder_files: response?.data?.data?.builder_files,
        builder_notes: response?.data?.data?.builder_notes,

        payment_info_company: response?.data?.data?.payment_info_company,
        payment_info_firstname: response?.data?.data?.payment_info_firstname,
        payment_info_lastname: response?.data?.data?.payment_info_lastname,
        payment_info_address_line1:
          response?.data?.data?.payment_info_address_line1,
        payment_info_address_line2:
          response?.data?.data?.payment_info_address_line2,
        payment_info_city: response?.data?.data?.payment_info_city,
        payment_info_state: response?.data?.data?.payment_info_state,
        payment_info_zip_code: response?.data?.data?.payment_info_zip_code,
        payment_info_number: response?.data?.data?.payment_info_number,
        payment_info_email: response?.data?.data?.payment_info_email,
        payment_info_other_payment_information:
          response?.data?.data?.payment_info_other_payment_information,

        notifications: response?.data?.data?.notifications,
        boards: response?.data?.data?.boards,
        pictures: response?.data?.data?.pictures,
        property_files: response?.data?.data?.property_tenant_files,
        property_notes: response?.data?.data?.property_tenant_notes,
        property_staff_files: response?.data?.data?.property_staff_files,
        property_staff_notes: response?.data?.data?.property_staff_notes,
        property_manager_files: response?.data?.data?.property_manager_files,
        property_manager_notes: response?.data?.data?.property_manager_notes,
        property_owner_files: response?.data?.data?.property_owner_files,
        property_owner_notes: response?.data?.data?.property_owner_notes,
        parking_spaces: response?.data?.data?.parking_spaces,
        contacts: response?.data?.data?.contacts,
        measurements: response?.data?.data?.measurements,
        materials: response?.data?.data?.materials,
      };
      setFormData({
        ...formData,
        name: response?.data?.data?.name,
        ...formData,
        address_line_1: response?.data?.data?.address_line_1,
        ...formData,
        address_line_2: response?.data?.data?.address_line_2,
        ...formData,
        is_active: response?.data?.data?.is_active,
        ...formData,
        city: response?.data?.data?.city,
        ...formData,
        country: response?.data?.data?.country,
        ...formData,
        state: response?.data?.data?.state,
        ...formData,
        type_id: response?.data?.data?.type_id,
        ...formData,
        zip_code: response?.data?.data?.zip_code,
        ...formData,
        embed: response?.data?.data?.embed,
        ...formData,
        picture_template_id: response?.data?.data?.picture_template_id,

        ...formData,
        security_firstname: response?.data?.data?.security_firstname,
        ...formData,
        security_lastname: response?.data?.data?.security_lastname,
        ...formData,
        security_company: response?.data?.data?.security_company,
        ...formData,
        security_number1: response?.data?.data?.security_number1,
        ...formData,
        security_number1_type: response?.data?.data?.security_number1_type,
        ...formData,
        security_number2: response?.data?.data?.security_number2,
        ...formData,
        security_number2_type: response?.data?.data?.security_number2_type,
        ...formData,
        security_number3: response?.data?.data?.security_number3,
        ...formData,
        security_number3_type: response?.data?.data?.security_number3_type,
        ...formData,
        security_email: response?.data?.data?.security_email,

        ...formData,
        security_picture: response?.data?.data?.security_picture,
        ...formData,
        wifi_network: response?.data?.data?.wifi_network,
        ...formData,
        wifi_password: response?.data?.data?.wifi_password,
        ...formData,
        alarm_code: response?.data?.data?.alarm_code,
        ...formData,
        gate_code: response?.data?.data?.gate_code,
        ...formData,
        door_code: response?.data?.data?.door_code,
        ...formData,
        mailbox_number: response?.data?.data?.mailbox_number,

        ...formData,
        no_of_floors: response?.data?.data?.no_of_floors,
        ...formData,
        has_elevator: response?.data?.data?.has_elevator,
        ...formData,
        has_stairs: response?.data?.data?.has_stairs,

        ...formData,
        features: response?.data?.data?.features,
        ...formData,
        nearbys: response?.data?.data?.nearbys,

        ...formData,
        hoa_show_to_tenant: response?.data?.data?.hoa_show_to_tenant,
        ...formData,
        hoa_show_to_property_manager:
          response?.data?.data?.hoa_show_to_property_manager,
        ...formData,
        hoa_company: response?.data?.data?.hoa_company,
        ...formData,
        hoa_contact_firstname: response?.data?.data?.hoa_contact_firstname,
        ...formData,
        hoa_contact_lastname: response?.data?.data?.hoa_contact_lastname,
        ...formData,
        hoa_contact_title: response?.data?.data?.hoa_contact_title,
        ...formData,
        hoa_address_line1: response?.data?.data?.hoa_address_line1,
        ...formData,
        hoa_address_line2: response?.data?.data?.hoa_address_line2,
        ...formData,
        hoa_city: response?.data?.data?.hoa_city,
        ...formData,
        hoa_country: response?.data?.data?.hoa_country,
        ...formData,
        hoa_state: response?.data?.data?.hoa_state,
        ...formData,
        hoa_zip_code: response?.data?.data?.hoa_zip_code,
        ...formData,
        hoa_number1: response?.data?.data?.hoa_number1,
        ...formData,
        hoa_number1_type: response?.data?.data?.hoa_number1_type,
        ...formData,
        hoa_number2: response?.data?.data?.hoa_number2,
        ...formData,
        hoa_number2_type: response?.data?.data?.hoa_number2_type,
        ...formData,
        hoa_number3: response?.data?.data?.hoa_number3,
        ...formData,
        hoa_number3_type: response?.data?.data?.hoa_number3_type,
        ...formData,
        hoa_email: response?.data?.data?.hoa_email,
        ...formData,
        hoa_website: response?.data?.data?.hoa_website,
        ...formData,
        hoa_fee: response?.data?.data?.hoa_fee,
        ...formData,
        hoa_fee_show_to_property_manager:
          response?.data?.data?.hoa_fee_show_to_property_manager,
        ...formData,
        hoa_fee_show_to_tenant: response?.data?.data?.hoa_fee_show_to_tenant,
        ...formData,
        hoa_due_date: response?.data?.data?.hoa_due_date,

        ...formData,
        hoa_files: response?.data?.data?.hoa_files,

        ...formData,
        mortage_company: response?.data?.data?.mortage_company,
        ...formData,
        mortage_contact_firstname:
          response?.data?.data?.mortage_contact_firstname,
        ...formData,
        mortage_contact_lastname:
          response?.data?.data?.mortage_contact_lastname,
        ...formData,
        mortage_contact_title: response?.data?.data?.mortage_contact_title,
        ...formData,
        mortage_address_line1: response?.data?.data?.mortage_address_line1,
        ...formData,
        mortage_address_line2: response?.data?.data?.mortage_address_line2,
        ...formData,
        mortage_city: response?.data?.data?.mortage_city,
        ...formData,
        mortage_country: response?.data?.data?.mortage_country,
        ...formData,
        mortage_state: response?.data?.data?.mortage_state,
        ...formData,
        mortage_zip_code: response?.data?.data?.mortage_zip_code,
        ...formData,
        mortage_number1: response?.data?.data?.mortage_number1,
        ...formData,
        mortage_number1_type: response?.data?.data?.mortage_number1_type,
        ...formData,
        mortage_number2: response?.data?.data?.mortage_number2,
        ...formData,
        mortage_number2_type: response?.data?.data?.mortage_number2_type,
        ...formData,
        mortage_number3: response?.data?.data?.mortage_number3,
        ...formData,
        mortage_number3_type: response?.data?.data?.mortage_number3_type,
        ...formData,
        mortage_email: response?.data?.data?.mortage_email,
        ...formData,
        mortage_website: response?.data?.data?.mortage_website,
        ...formData,
        mortage_minimum_payment: response?.data?.data?.mortage_minimum_payment,
        ...formData,
        mortage_due_date: response?.data?.data?.mortage_due_date,
        ...formData,
        mortage_balance: response?.data?.data?.mortage_balance,
        ...formData,
        mortage_files: response?.data?.data?.mortage_files,

        ...formData,
        builder_company: response?.data?.data?.builder_company,
        ...formData,
        builder_date: response?.data?.data?.builder_date,
        ...formData,
        builder_firstname: response?.data?.data?.builder_firstname,
        ...formData,
        builder_lastname: response?.data?.data?.builder_lastname,
        ...formData,
        builder_title: response?.data?.data?.builder_title,
        ...formData,
        builder_address_line1: response?.data?.data?.builder_address_line1,
        ...formData,
        builder_address_line2: response?.data?.data?.builder_address_line2,
        ...formData,
        builder_city: response?.data?.data?.builder_city,
        ...formData,
        builder_country: response?.data?.data?.builder_country,
        ...formData,
        builder_state: response?.data?.data?.builder_state,
        ...formData,
        builder_zip_code: response?.data?.data?.builder_zip_code,
        ...formData,
        builder_number1: response?.data?.data?.builder_number1,
        ...formData,
        builder_number1_type: response?.data?.data?.builder_number1_type,
        ...formData,
        builder_number2: response?.data?.data?.builder_number2,
        ...formData,
        builder_number2_type: response?.data?.data?.builder_number2_type,
        ...formData,
        builder_number3: response?.data?.data?.builder_number3,
        ...formData,
        builder_number3_type: response?.data?.data?.builder_number3_type,
        ...formData,
        builder_email: response?.data?.data?.builder_email,
        ...formData,
        builder_website: response?.data?.data?.builder_website,
        ...formData,
        builder_files: response?.data?.data?.builder_files,

        ...formData,
        payment_info_company: response?.data?.data?.payment_info_company,
        ...formData,
        payment_info_firstname: response?.data?.data?.payment_info_firstname,
        ...formData,
        payment_info_lastname: response?.data?.data?.payment_info_lastname,
        ...formData,
        payment_info_address_line1:
          response?.data?.data?.payment_info_address_line1,
        ...formData,
        payment_info_address_line2:
          response?.data?.data?.payment_info_address_line2,
        ...formData,
        payment_info_city: response?.data?.data?.payment_info_city,
        ...formData,
        payment_info_country: response?.data?.data?.payment_info_country,
        ...formData,
        payment_info_state: response?.data?.data?.payment_info_state,
        ...formData,
        payment_info_zip_code: response?.data?.data?.payment_info_zip_code,
        ...formData,
        payment_info_number: response?.data?.data?.payment_info_number,
        ...formData,
        payment_info_email: response?.data?.data?.payment_info_email,

        ...formData,
        notifications: response?.data?.data?.notifications,
        ...formData,
        boards: response?.data?.data?.boards,
        ...formData,
        pictures: response?.data?.data?.pictures,
        ...formData,
        property_files: response?.data?.data?.property_tenant_files,

        ...formData,
        property_staff_files: response?.data?.data?.property_staff_files,

        ...formData,
        property_manager_files: response?.data?.data?.property_manager_files,

        ...formData,
        property_owner_files: response?.data?.data?.property_owner_files,

        ...formData,
        parking_spaces: response?.data?.data?.parking_spaces,
        ...formData,
        contacts: response?.data?.data?.contacts,
        ...formData,
        measurements: response?.data?.data?.measurements,
        ...formData,
        materials: response?.data?.data?.materials,
      });
      setPropertyContacts(response?.data?.data?.contacts);
      setPropertyNotification(response?.data?.data?.notifications);
      setPropertyBoards(response?.data?.data?.boards);
      setPropertyMeasurements(response?.data?.data?.measurements);
      setPropertyMaterials(response?.data?.data?.materials);
      setParkingSpaces(response?.data?.data?.parking_spaces);
      setPropertyPublicDocumentsWithUrl(response?.data?.data?.files)
      setPropertyStaffDocumentsWithUrl(response?.data?.data?.staff_files)
      setPropertyManagerDocumentsWithUrl(response?.data?.data?.managers_files)
      setPropertyOwnerDocumentsWithUrl(response?.data?.data?.owners_files)
      setPropertyHoaFiles(response?.data?.data?.hoa_files)
      setPropertyMortageFiles(response?.data?.data?.mortage_files)
      setPropertyBuilderFiles(response?.data?.data?.builder_files)
      setEditorData({
        ...editorData,
        property_tenant_notes: response?.data?.data?.property_notes,
        ...editorData,
        payment_info_other_payment_information:
          response?.data?.data?.payment_info_other_payment_information,
        ...editorData,
        builder_notes: response?.data?.data?.builder_notes,
        ...editorData,
        mortage_notes: response?.data?.data?.mortage_notes,
        ...editorData,
        hoa_owner_notes: response?.data?.data?.hoa_owner_notes,
        ...editorData,
        description: response?.data?.data?.description,
        ...editorData,
        other_info: response?.data?.data?.other_info,
        ...editorData,
        security_info: response?.data?.data?.security_info,
      });
      setEditorData1({
        ...editorData1,
        hoa_property_manager_notes:
          response?.data?.data?.hoa_property_manager_notes,
        ...editorData1,
        other_property_features: response?.data?.data?.other_property_features,
        ...editorData1,
        property_manager_notes: response?.data?.data?.property_manager_notes,
      });
      setEditorData2({
        ...editorData2,
        hoa_tenant_notes: response?.data?.data?.hoa_tenant_notes,
        ...editorData2,
        property_staff_notes: response?.data?.data?.property_staff_notes,
      });
      setEditorData3({
        ...editorData3,
        property_owner_notes: response?.data?.data?.property_owner_notes,
      });
      setCheckFeature(response?.data?.data?.features);
      setHandleFile(response?.data?.data?.files);
      setTemplateID(response?.data?.data?.file_template_id);
      setPropertySecurityContacts(response?.data?.data?.securities_ontacts)
      setParkingSpaces(response?.data?.data?.parking_spaces);
      setPropertyLocations(response?.data?.data?.property_locations)
      setCheckNearby(response?.data?.data?.nearbys);
      setPropertyFormImagesWithUrl(response?.data?.data?.pictures)
      setPropertyHoaContacts(response?.data?.data?.hoa_contacts);
      setPropertyHoaInteractions(response?.data?.data?.hoa_interactions);
      setPropertyMortageContacts(response?.data?.data?.mortage_contacts);
      setPropertyMortageInteractions(response?.data?.data?.mortage_interactions);
      setPropertyBuilderContacts(response?.data?.data?.builder_contacts);
      setPropertyBuilderInteractions(response?.data?.data?.builder_interactions);
      getStates(response?.data?.data?.country)
      getCities(response?.data?.data?.state)
      setPublicNotes(response?.data?.data?.property_notes)
      setStaffNotes(response?.data?.data?.property_staff_notes)
      setManagerNotes(response?.data?.data?.property_managers_notes)
      setOwnerNotes(response?.data?.data?.property_owners_notes)
      setOnlyYear(response?.data?.data?.builder_date?.length == 4 ? true : false)
      // setPropertyMeasurementFiles(response?.data?.data?.measurements?.files)
      
    }

    console.warn("This  is response final", formData2);
  };

  const { id } = useParams();
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log("formData 360", formData);
      const res = await ApiRequest.post("/public/api/auth/property", {
        id: id,
        name: formData?.name,
        address_line_1: formData?.address_line_1,
        address_line_2: formData?.address_line_2,
        is_active: formData?.is_active,
        city: formData?.city,
        country: formData?.country,
        state: formData?.state,
        type_id: formData?.type_id,
        zip_code: formData?.zip_code,
        embed: formData?.embed,
        picture_template_id: null,
        file_template_id: templateID,
        securities_contacts:propertySecurityContacts,
        // security_firstname: formData?.security_firstname,
        // security_lastname: formData?.security_lastname,
        // security_company: formData?.security_company,
        // security_number1: formData?.security_number1,
        // security_number1_type: formData?.security_number1_type,
        // security_number2: formData?.security_number2,
        // security_number2_type: formData?.security_number2_type,
        // security_number3: formData?.security_number3,
        // security_number3_type: formData?.security_number3_type,
        // security_email: formData?.security_email,
        // security_info: editorData?.security_info,
        // security_picture: formData?.security_picture,
        wifi_network: formData?.wifi_network,
        wifi_password: formData?.wifi_password,
        alarm_code: formData?.alarm_code,
        gate_code: formData?.gate_code,
        door_code: formData?.door_code,
        mailbox_number: formData?.mailbox_number,
        other_info: editorData?.other_info,
        property_locations:propertyLocations,

        no_of_floors: formData?.no_of_floors,
        has_elevator: formData?.has_elevator,
        has_stairs: formData?.has_stairs,
        description: editorData?.description,
        other_property_features: editorData1?.other_property_features,
        features: formData?.features,

        hoa_show_to_tenant: formData?.hoa_show_to_tenant,
        hoa_show_to_property_manager: formData?.hoa_show_to_property_manager,
        hoa_company: formData?.hoa_company,
        hoa_contact_firstname: formData?.hoa_contact_firstname,
        hoa_contact_lastname: formData?.hoa_contact_lastname,
        hoa_contact_title: formData?.hoa_contact_title,
        hoa_address_line1: formData?.hoa_address_line1,
        hoa_address_line2: formData?.hoa_address_line2,
        hoa_city: formData?.hoa_city,
        hoa_country: formData?.hoa_country,
        hoa_state: formData?.hoa_state,
        hoa_zip_code: formData?.hoa_zip_code,
        hoa_number1: formData?.hoa_number1,
        hoa_number1_type: formData?.hoa_number1_type,
        hoa_number2: formData?.hoa_number2,
        hoa_number2_type: formData?.hoa_number2_type,
        hoa_number3: formData?.hoa_number3,
        hoa_number3_type: formData?.hoa_number3_type,
        hoa_email: formData?.hoa_email,
        hoa_website: formData?.hoa_website,
        hoa_fee: formData?.hoa_fee,
        hoa_fee_show_to_property_manager:
          formData?.hoa_fee_show_to_property_manager,
        hoa_fee_show_to_tenant: formData?.hoa_fee_show_to_tenant,
        hoa_due_date: formData?.hoa_due_date,
        hoa_owner_notes: editorData?.hoa_owner_notes,
        hoa_property_manager_notes: editorData1?.hoa_property_manager_notes,
        hoa_tenant_notes: editorData2?.hoa_tenant_notes,
        hoa_files: formData?.hoa_files,

        mortage_company: formData?.mortage_company,
        mortage_contact_firstname: formData?.mortage_contact_firstname,
        mortage_contact_lastname: formData?.mortage_contact_lastname,
        mortage_contact_title: formData?.mortage_contact_title,
        mortage_address_line1: formData?.mortage_address_line1,
        mortage_address_line2: formData?.mortage_address_line2,
        mortage_city: formData?.mortage_city,
        mortage_country: formData?.mortage_country,
        mortage_state: formData?.mortage_state,
        mortage_zip_code: formData?.mortage_zip_code,
        mortage_number1: formData?.mortage_number1,
        mortage_number1_type: formData?.mortage_number1_type,
        mortage_number2: formData?.mortage_number2,
        mortage_number2_type: formData?.mortage_number2_type,
        mortage_number3: formData?.mortage_number3,
        mortage_number3_type: formData?.mortage_number3_type,
        mortage_email: formData?.mortage_email,
        mortage_website: formData?.mortage_website,
        mortage_minimum_payment: formData?.mortage_minimum_payment,
        mortage_due_date: formData?.mortage_due_date,
        mortage_balance: formData?.mortage_balance,
        mortage_files: formData?.mortage_files,
        mortage_notes: editorData?.mortage_notes,

        builder_company: formData?.builder_company,
        builder_date: formData?.builder_date,
        builder_firstname: formData?.builder_firstname,
        builder_lastname: formData?.builder_lastname,
        builder_title: formData?.builder_title,
        builder_address_line1: formData?.builder_address_line1,
        builder_address_line2: formData?.builder_address_line2,
        builder_city: formData?.builder_city,
        builder_country: formData?.builder_country,
        builder_state: formData?.builder_state,
        builder_zip_code: formData?.builder_zip_code,
        builder_number1: formData?.builder_number1,
        builder_number1_type: formData?.builder_number1_type,
        builder_number2: formData?.builder_number2,
        builder_number2_type: formData?.builder_number2_type,
        builder_number3: formData?.builder_number3,
        builder_number3_type: formData?.builder_number3_type,
        builder_email: formData?.builder_email,
        builder_website: formData?.builder_website,
        builder_files: formData?.builder_files,
        builder_notes: editorData?.builder_notes,

        payment_info_company: formData?.payment_info_company,
        payment_info_firstname: formData?.payment_info_firstname,
        payment_info_lastname: formData?.payment_info_lastname,
        payment_info_address_line1: formData?.payment_info_address_line1,
        payment_info_address_line2: formData?.payment_info_address_line2,
        payment_info_city: formData?.payment_info_city,
        payment_info_country: formData?.payment_info_country,
        payment_info_state: formData?.payment_info_state,
        payment_info_zip_code: formData?.payment_info_zip_code,
        payment_info_number: formData?.payment_info_number,
        payment_info_email: formData?.payment_info_email,
        payment_info_other_payment_information:
          editorData?.payment_info_other_payment_information,

        notifications: propertyNotification,
        boards: propertyBoards,
        pictures: formData?.pictures,
        property_files: handleFile,
        property_notes: publicNotes,
        property_staff_files: formData?.property_staff_files,
        property_staff_notes: staffNotes,
        property_manager_files: formData?.property_manager_files,
        property_manager_notes: managerNotes,
        property_owner_files: formData?.property_owner_files,
        property_owner_notes: ownerNotes,
        parking_spaces: parkingSpaces,
        contacts: propertyContacts,
        measurements: propertyMeasurements,
        materials: propertyMaterials,
      });
      console.log("Result formData", formData);
      if (res.status == 200) {
        Alert.addSuccess("Property Edited Successfully");
      }
    } catch (err) {
      console.log(err);
      Alert.addFailure(
        <ul>
              {err?.response?.data?.name ? <li>{err?.response?.data?.name}</li>:null}
            {err?.response?.data?.type_id ? <li>{err?.response?.data?.type_id}</li>:null}
              {err?.response?.data?.is_active ? <li>{err?.response?.data?.is_active}</li> :null}
              {err?.response?.data?.address_line_1 ? <li>{err?.response?.data?.address_line_1}</li> :null}
              {err?.response?.data?.city ? <li>{err?.response?.data?.city}</li> :null}
              {err?.response?.data?.state ? <li>{err?.response?.data?.state}</li> :null}
              {err?.response?.data?.zip_code ? <li>{err?.response?.data?.zip_code}</li> :null}
        </ul>
      )
    }
  };
  // console.log('Result formData',formData);
  // APIs

  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state?.properties?.propertiesTypes?.data
  );
  // const getPropertyTypes = async () => {
  // 	const response = await ApiRequest.get(
  // 		"public/api/auth/get-all-properties-types"
  // 	);
  // 	if (response.status == 200) {
  // 		setPropertyTypes(response.data?.data);
  // 	}
  // };
  const materialDispatch = useDispatch();
  const materialState = useSelector((state) => state);
  // console.log("Materialstate",materialState)
  const getPropertyMaterialTypes = async () => {
    const response = await ApiRequest.get("public/api/auth/get-material-types");
    if (response.status == 200) {
      setPropertyMaterialTypes(response.data?.data);
    }
  };
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const getCountries = async () => {
    const response = await ApiRequest.get("public/api/auth/get-countries");
    if (response.status == 200) {
      setCountries(response.data?.data?.countries);
    }
  };
  const getStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-states?country_id=${id}`);
    if (response.status == 200) {
      setStates(response.data?.data?.states);
    }
  };
  const getCities = async (data) => {
    const state = states?.map((col) =>{
      if(col?.id == data)
      {
        return col?.state_code
      }
    })
    const filteredArr = state.filter((value) => value !== undefined);
    const stateCode = filteredArr.join(', ');
    
    console.warn('data', data);
    console.warn('states', states);
    console.warn('state_code', stateCode);
    
    const response = await ApiRequest.get(`public/api/auth/get-cities?state_code=${stateCode}`);
    
    if (response.status == 200) {
      setCities(response.data?.data?.cities);
    }
  };
    const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
  const getFeatures = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-features-for-properties");
    if (response.status == 200) {
      setFeatures(response?.data?.data?.features);
    }
  };
  const getNearbys = async () => {
    const response = await ApiRequest.get("public/api/auth/get-nearbys");
    if (response.status == 200) {
      setNearbys(response?.data?.data?.nearbys);
    }
  };
  const getPropertyMeasurementUnits = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurements-units"
    );
    if (response.status == 200) {
      setPropertyMeasurementUnit(response.data?.data?.measurements_units);
    }
  };
  const getMeasurementTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurement-types"
    );
    if (response.status == 200) {
      setPropertyMeasurementType(response?.data?.data);
    }
  };
  const addEditMeasurementType = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/measurement-type",
      payload
    );
    if (response.status == 200) {
      getMeasurementTypes();
    }
  };

  const getPropertyMaterialLocations = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-properties-locations"
    );
    if (response.status == 200) {
      setPropertyMaterialLocations(response?.data?.data);
    }
  };

  const getPropertyMaterialBrands = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-brands"
    );
    if (response.status == 200) {
      setPropertyMaterialBrands(response.data?.data);
    }
  };

  const getPropertyMateriaIdentifiers = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-identifiers"
    );
    if (response.status == 200) {
      setPropertyMateriaIdentifiers(response.data?.data);
    }
  };

  const addEditMaterialType = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-type",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialTypes();
    }
  };

  const addEditMaterialLocation = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/properties-location",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialLocations();
    }
  };

  const addEditMaterialBrand = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-brand",
      payload
    );
    if (response.status == 200) {
      getPropertyMaterialBrands();
    }
  };

  const addEditMaterialIdentifier = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-identifier",
      payload
    );
    if (response.status == 200) {
      getPropertyMateriaIdentifiers();
    }
  };
  const getParkingSpacePrefixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-prefixes"
    );
    if (response.status == 200) {
      setParkingSpacePrefix(response?.data?.data?.prefixes);
    }
  };
  const getParkingSpaceSuffixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-suffixes"
    );
    if (response.status == 200) {
      setParkingSpaceSuffix(response?.data?.data?.suffixes);
    }
  };

  //  useEffect HOOKS

  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const propertyPictures = [];
      propertyFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        propertyPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });

      // const contacts = [
      //   ...propertyContacts
      //     .map((contact, index) => {
      //       const pictures = [...propertyAssistantPictures];
      //       contact.picture = pictures[index]?.image?.picture;
      //       return contact;
      //     })
      //     .filter(
      //       (contact) =>
      //         JSON.stringify(contact) !== JSON.stringify(initialContactData)
      //     ),
      // ];
      // const securities_contacts = [
      //   ...propertySecurityContacts
      //     .map((security, index) => {
      //       const pictures = [...propertySecurityPictures];
      //       security.picture = pictures[index]?.image?.picture;
      //       return security;
      //     })
      //     .filter(
      //       (security) =>
      //         JSON.stringify(security) !== JSON.stringify(initialSecurityData)
      //     ),
      // ];
      // const measurements = [
      //   ...propertyMeasurements
      //     .map((measurement, index) => {
      //       const pictures = [...propertyMeasurementPictures];
      //       measurement.pictures = pictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...propertyMeasurementFiles];
      //       measurement.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return measurement;
      //     })
      //     .filter(
      //       (measurement) =>
      //         JSON.stringify(measurement) !==
      //         JSON.stringify(initialMeasurementData)
      //     ),
      // ];
      // console.log("Measurement Data", measurements);

      // const materials = [
      //   ...propertyMaterials
      //     .map((material, index) => {
      //       const pictures = [...propertyMaterialPictures];
      //       material.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...propertyMaterialFiles];
      //       material.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return material;
      //     })
      //     .filter(
      //       (material) =>
      //         JSON.stringify(material) !== JSON.stringify(initialMaterialData)
      //     ),
      // ];

      // const notifications = [
      //   ...propertyNotification.filter(
      //     (notifications) =>
      //       JSON.stringify(notifications) !== JSON.stringify(initialNoticeData)
      //   ),
      // ];

      // const boards = [
      //   ...propertyBoards
      //     .map((board, index) => {
      //       const pictures = [...propertyBoardPicture];
      //       board.icon = pictures[index]?.image?.picture;
      //     })
      //     .filter(
      //       (board) =>
      //         JSON.stringify(board) !== JSON.stringify(initialBoardData)
      //     ),
      // ];

      // const parking_spaces = [
      // 	...parkingSpace.filter(
      // 		(parking_space)=> JSON.stringify(parking_space)!== JSON.stringify(initialSpaceData)
      // 		),
      // ];
      // const addFeatures = [
      // 	...addFeature.filter(
      // 		(feature)=> JSON.stringify(feature)!== JSON.stringify(initialFeatureData)
      // 		),
      // ];

      data.pictures = [...propertyPictures];

      // if (JSON.stringify(initialData?.contacts) !== JSON.stringify(contacts)) {
      //   data.contacts = contacts;
      // }
      // if (JSON.stringify(initialData?.securities_contacts) !== JSON.stringify(securities_contacts)) {
      //   data.securities_contacts = securities_contacts;
      // }
      // if (
      //   JSON.stringify(initialData?.measurements) !==
      //   JSON.stringify(measurements)
      // ) {
      //   data.measurements = measurements;
      // }

      // if (
      //   JSON.stringify(initialData?.materials) !== JSON.stringify(materials)
      // ) {
      //   data.materials = materials;
      // }

      // data.hoa_files = [
      //   ...propertyHoaFiles.map((fileObj) => ({ file: fileObj?.file })),
      // ];
      // data.mortage_files = [
      //   ...propertyMortageFiles.map((fileObj) => ({ file: fileObj?.file })),
      // ];
      // data.builder_files = [
      //   ...propertyBuilderFiles.map((fileObj) => ({ file: fileObj?.file })),
      // ];

      // if (
      //   JSON.stringify(initialData?.notifications) !==
      //   JSON.stringify(notifications)
      // ) {
      //   data.notifications = notifications;
      // }

      // if (JSON.stringify(initialData?.boards) !== JSON.stringify(boards)) {
      //   data.boards = boards;
      // }
      // if (JSON.stringify(initialData?.parking_spaces) !== JSON.stringify(parking_spaces)) {
      // 	data.parking_spaces = parking_spaces;
      // }
      // if (JSON.stringify(initialData?.addFeatures) !== JSON.stringify(addFeatures)) {
      // 	data.addFeatures = addFeatures;
      // }

      // data.property_tenant_files = [
      //   ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
      //     file: fileObj?.file,
      //   })),
      // ];
      // data.property_staff_files = [
      //   ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
      //     file: fileObj?.file,
      //   })),
      // ];
      // data.property_manager_files = [
      //   ...propertyManagerDocumentsWithUrl.map((fileObj) => ({
      //     file: fileObj?.file,
      //   })),
      // ];
      // data.property_owner_files = [
      //   ...propertyOwnerDocumentsWithUrl.map((fileObj) => ({
      //     file: fileObj?.file,
      //   })),
      // ];

      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);

  useEffect(() => {
    if (formElement) {
      formSubmitButton.click();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    // getPropertyTypes();
    getCountries();
    getPropertyMaterialTypes();
    getPropertyMaterialLocations();
    getPropertyMaterialBrands();
    getMeasurementTypes();
    getFeatures();
    getOwnerSettings();
    getNumberType();
    getNearbys();
    getParkingSpacePrefixes();
    getParkingSpaceSuffixes();
    getPropertyMateriaIdentifiers();
    getPropertyMeasurementUnits();
    dispatch(propertiesTypes());
    materialDispatch(materialType());
    getProperty();
    setFormData(initialData);
    setPropertyFormImagesWithUrl(
      initialData?.pictures?.map((obj) => ({
        image: {
          picture: obj?.picture,
          title: "",
          caption: "",
          is_cover: "",
        },
        url: obj?.picture,
      }))
    );

    // if (initialData?.contacts?.length) {
    //   setPropertyContacts(initialData.contacts);
    //   setPropertyAssistantPictures(
    //     initialData.contacts.map((contact) => contact?.picture)
    //   );
    // } else {
    //   setPropertyContacts([{ ...initialContactData }]);
    //   setPropertyAssistantPictures([{ ...initialImageData }]);
    // }
    // if (initialData?.securities_contacts?.length) {
    //   setPropertySecurityContacts(initialData.securities_contacts);
    //   setPropertySecurityPictures(
    //     initialData.securities_contacts.map((security) => security?.picture)
    //   );
    // } else {
    //   setPropertySecurityContacts([{ ...initialContactData }]);
    //   setPropertySecurityPictures([{ ...initialImageData }]);
    // }
    // setPropertySecurityPicture([{ ...initialPictureData }]);

    // if (initialData?.measurements?.length) {
    //   setPropertyMeasurements(initialData.measurements);
    //   setPropertyMeasurementPictures(
    //     initialData.measurements.map((measurement) =>
    //       measurement?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setPropertyMeasurementFiles(
    //     initialData.measurements.map((measurement) => measurement?.files)
    //   );
    // } else {
    //   setPropertyMeasurements([{ ...initialMeasurementData }]);
    //   setPropertyMeasurementPictures([[]]);
    //   setPropertyMeasurementFiles([[]]);
    // }

    // if (initialData?.materials?.length) {
    //   setPropertyMaterials(initialData?.materials);
    //   setPropertyMaterialPictures(
    //     initialData.materials.map((material) =>
    //       material?.pictures?.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setPropertyMaterialFiles(
    //     initialData.materials.map((material) => material?.files)
    //   );
    // } else {
    //   setPropertyMaterials([{ ...initialMaterialData }]);
    //   setPropertyMaterialPictures([[]]);
    //   setPropertyMaterialFiles([[]]);
    // }

    // setPropertyHoaFiles(
    //   initialData?.hoa_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyMortageFiles(
    //   initialData?.mortage_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyBuilderFiles(
    //   initialData?.builder_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );

    // setPropertyNotification(
    //   initialData?.notifications?.length
    //     ? initialData?.notifications
    //     : [{ ...initialNoticeData }]
    // );

    // if (initialData?.boards?.length) {
    //   setPropertyBoardPicture(initialData.boards.map((board) => board?.icon));
    //   setPropertyBoards(initialData?.boards);
    // } else {
    //   setPropertyBoards([{ ...initialBoardData }]);
    //   setPropertyBoardPicture([]);
    // }
    // setParkingSpace(
    // 	initialData?.parking_spaces?.length
    // 		? initialData?.parking_spaces
    // 		: [{ ...initialSpaceData }]
    // );
    // setAddFeature(
    // 	initialData?.addFeatures?.length
    // 		? initialData?.addFeatures
    // 		: [{ ...initialFeatureData }]
    // );

    // setPropertyPublicDocumentsWithUrl(
    //   initialData?.property_tenant_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyStaffDocumentsWithUrl(
    //   initialData?.property_staff_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyManagerDocumentsWithUrl(
    //   initialData?.property_manager_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setPropertyOwnerDocumentsWithUrl(
    //   initialData?.property_owner_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
  }, []);

  useEffect(() => {
    if (formElement) {
      formElement.addEventListener("keypress", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });

      return () => formElement.removeEventListener("keypress", () => {});
    }
  }, [formElement]);

  return (
    <>
      {/* Add Property Form  */}
      {loading === undefined ? (
        <Loading />
      ) : (
		<>
		{/* <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/property.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                Editing {formData?.name}
              </h2>
            </div> */}
      
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={formSubmitHandler}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Property Information" {...a11yProps(0)} />
            <Tab label="Notification" {...a11yProps(1)} />
            <Tab label="Property Boards" {...a11yProps(2)} />
            <Tab label="Notes & Files" {...a11yProps(3)} />
            {/* <Tab label="Property Managers" {...a11yProps(1)} /> */}
            <Tab label="Property Assistance Information" {...a11yProps(4)} />
            {/* <Tab label="Property Security Information" {...a11yProps(5)} /> */}
            <Tab label="Property Measurements" {...a11yProps(5)} />
            <Tab label="Property Materials" {...a11yProps(6)} />
            <Tab label="Parking Spaces" {...a11yProps(7)} />
            <Tab label="Listing Information" {...a11yProps(8)} />
            <Tab label="HOA Information" {...a11yProps(9)} />
            <Tab label="Mortage Information" {...a11yProps(10)} />
            <Tab label="Builder Information" {...a11yProps(11)} />
            {/* <Tab label="Insurance Claims" {...a11yProps(10)} /> */}
            <Tab
              label="Payment Information"
              {...a11yProps(12)}
            />
            <Tab label="Staff" {...a11yProps(13)} />

            {/* <Tab label="Staff Files" {...a11yProps(15)} />
					<Tab label="Property Manager Files" {...a11yProps(16)} />
					<Tab label="Owner Files" {...a11yProps(1)} /> */}
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="md:flex md:items-center w-full mt-6">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Property Name:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.name}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                    }}
                  />
                  {/* // <span className="text-danger">{validation.name}</span> */}
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Property Type:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-property-type"
                    value={formData?.type_id}
                    required={false}
                    placeholder="Name"
                    onChange={(e) => {
                      setFormData({ ...formData, type_id: e.target.value });
                    }}
                  >
                    {state?.map((property_Type) => (
                      <option value={property_Type?.id}>
                        {property_Type?.type}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-property-sub-type"
                    value={formData?.is_active}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, is_active: e.target.value });
                    }}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Not Active</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:<span title="Field is required.">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-address-line-1"
                    type="text"
                    value={formData?.address_line_1}
                    placeholder="Apt. suite no. 1"
                    required={false}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        address_line_1: e.target.value,
                      });
                    }}
                  />
                  {/* // <span className="text-danger">
                    {validation.address_line_1}
                  </span> */}
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-address-line-2"
                    type="text"
                    value={formData?.address_line_2}
                    placeholder="Jeff Street"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        address_line_2: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {/* <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:<span title="Field is required.">*</span>
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-city"
                      type="text"
                      value={formData?.city}
                      placeholder="Albuquerque"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, city: parseInt(e.target.value) });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:<span title="Field is required.">*</span>
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      value={formData?.state}
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, state: parseInt(e.target.value) });
                      }}
                    >
                      <option value={"New Mexico"}>New Mexico</option>
                      <option value={"Missouri"}>Missouri</option>
                      <option value={"Texas"}>Texas</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:<span title="Field is required.">*</span>
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-zip"
                      type="text"
                      value={formData?.zip_code}
                      placeholder="90210"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, zip_code: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div> */}
               <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="country"
                    type="text"
                    value={formData?.country}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, country: parseInt(e.target.value) });
                      getStates(e.target.value);
                    }}
                  >
                    <option value=" ">Select Country</option>
                    {countries?.map((country)=>(
                      <option value={country?.id}>{country?.country}</option>
                    ))}
                    </select>
                </div>
              </div>
              
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="state"
                    value={formData?.state}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, state: parseInt(e.target.value) });
                      getCities(e.target.value);
                    }}
                  >
                    <option value=" ">Select State</option>
                    {states?.map((state)=>(
                      <option value={state?.id}>{state?.state}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="city"
                    type="text"
                    value={formData?.city}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      const zip = cities?.map((col) =>{
                        if(col?.id == e.target.value)
                        {
                          return col?.zip_code
                        }
                      })
                      const filteredArr = zip.filter((value) => value !== undefined);
                      const zipCode = filteredArr.join(', ');

                      setFormData({ ...formData, city: parseInt(e.target.value),zip_code:zipCode });
                    }}
                  >
                    <option value=" ">Select City</option>
                    {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="zip-code"
                    type="text"
                    value={formData?.zip_code}
                    placeholder="90210"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, zip_code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            </div>

            <hr />

            {/* Property Gadgets */}

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Wifi Network:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-wifi-network"
                      type="text"
                      placeholder="Wifi Name"
                      value={formData?.wifi_network}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          wifi_network: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Wifi Password:
                    </label>
                  </div>
                  <div className="md:w-4/5 relative">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-wifi-password"
                      type={securedFields.propertyWifiPassword}
                      value={formData?.wifi_password}
                      placeholder="Wifi Password"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          wifi_password: e.target.value,
                        });
                      }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyWifiPassword:
                            securedFields.propertyWifiPassword === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.propertyWifiPassword === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Alarm Code:
                    </label>
                  </div>
                  <div className="md:w-4/5 relative">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-alarm-code"
                      type={securedFields.propertyAlarmCode}
                      value={formData?.alarm_code}
                      placeholder="Alarm Code"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          alarm_code: e.target.value,
                        });
                      }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyAlarmCode:
                            securedFields.propertyAlarmCode === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.propertyAlarmCode === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Gate Code:
                    </label>
                  </div>
                  <div className="md:w-4/5 relative">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-gate-code"
                      type={securedFields.propertyGateCode}
                      value={formData?.gate_code}
                      placeholder="Gate Code"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          gate_code: e.target.value,
                        });
                      }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyGateCode:
                            securedFields.propertyGateCode === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.propertyGateCode === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Door Gate:
                    </label>
                  </div>
                  <div className="md:w-4/5 relative">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-door-code"
                      type={securedFields.propertyDoorCode}
                      value={formData?.door_code}
                      placeholder="Door Code"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          door_code: e.target.value,
                        });
                      }}
                    />
                    <span
                      className="absolute right-0 top-0 py-3 px-3"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyDoorCode:
                            securedFields.propertyDoorCode === "password"
                              ? "text"
                              : "password",
                        });
                      }}
                    >
                      {securedFields.propertyDoorCode === "password" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-2/5 flex">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Property Mailbox Info:
                  </label>
                  {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="If there is a general box for the property that all the units would use, you can enter the location and other information here. If there are separate ones for the individual units, then you want to enter that in the Unit Module."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"If there is a general box for the property that all the units would use, you can enter the location and other information here. If there are separate ones for the individual units, then you want to enter that in the Unit Module."}/>
                </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-mailbox-info"
                      type="text"
                      placeholder="Mailbox Info"
                      value={formData?.mailbox_number}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mailbox_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex mt-3">
                <div className="md:w-1/5 flex">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Other Property Info:
                  </label>
                  {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="If there is other information about the property you want to share with your Tenants, make sure you list it here."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"If there is other information about the property you want to share with your Tenants, make sure you list it here."}/>
                </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.other_info}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          other_info: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
                <hr />
              <PropertyLocations data={propertyLocations} handleLocationTrue={handleLocationTrue} hiddenData={false}/>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>

          {/* Property Managers */}
          {/* <TabPanel value={tabValue} index={1}>
					<div className="mt-4">
						<div className="flex align-items-center">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/listings-about-manager-on-premises.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Property Managers:
							</label>
						</div>
						<div className="w-[90%] m-auto">
							<div className="w-1/4 pl-5 ml-5 mt-2">
								<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
									<button
										type="button"
										className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500"
									>
										+ Add
									</button>
								</label>
							</div>
						</div>
					</div>
				</TabPanel> */}

          {/* Notification */}
          <TabPanel value={tabValue} index={1}>
          <PropertyNotifications data={propertyNotification} handleNotificationTrue={handleNotificationTrue} hiddenData={false} ownerSettings={ownerSettings}/>
          <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Boards */}
          <TabPanel value={tabValue} index={2}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-boards.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Boards:
                </label>
              </div>
            </div> */}

            {/* {propertyBoards?.map((row, index) => {
              const boards = [...propertyBoards];
              return (
                <>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Title:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-board-title-${index}`}
                          type="text"
                          placeholder="Title"
                          value={row?.title}
                          onChange={(e) => {
                            boards[index].title = e.target.value;
                            setPropertyBoards(boards);
                            setFormData({ ...formData, boards: boards });
                          }}
                        />
                      </div>
                      <div className="flex justify-end md:w-1/5">
                        <div className="md:w-1/3">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Icon:
                          </label>
                        </div>
                      </div>
                      <div className="md:w-1/2">
                        <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                            Upload icon
                          </span>
                          <input
                            accept={picTypes}
                            id={`p-board-icon-${index}`}
                            type="file"
                            hidden={true}
                            onChange={async (e) => {
                              let file = e.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  console.log(imgBase64);
                                  const res = await ApiRequest.post(
                                    "/public/api/auth/upload-image",
                                    { image: imgBase64 }
                                  );
                                  // console.log("res", res?.data?.data?.image);
                                  boards[index].icon = res?.data?.data?.image;
                                  // setFeatureIcon(res?.data?.data?.image);
                                } catch (err) {
                                  console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);

                              // console.log("here 142");
                              const pictures = [...propertyBoardPicture];
                              pictures[index] = getImagesAndURLs(e)[0];
                              setPropertyBoardPicture(pictures);
                              setPropertyBoards(boards);
                              setFormData({ ...formData, boards: boards });
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    {formData?.boards[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                        <div className="container w-full">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image: formData?.boards[index]?.icon,
                              src: imgUrl + "/" + formData?.boards[index]?.icon,
                              inputFieldId: `p-board-icon-${index}`,
                              trashAble: true,
                              onTrashClick: () => {
                                formData.boards[index].icon = null;
                                setPropertyBoardPicture(null);
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                    <div className="w-full">
                      <div className="md:flex mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Board:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <CKEditor
                            initData={row?.board}
                            onChange={(e) => {
                              boards[index].board = cleanText;
                              setPropertyBoards(boards);
                              setFormData({ ...formData, boards: boards });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              );
            })} */}

            {/* Add more */}

            {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex justify-end w-full mt-4">
                <div className="w-1/4 pl-5 ml-5 mt-2 ">
                  <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setPropertyBoards([...propertyBoards, initialBoardData]);
                    }}
                  >
                    <i class="fa fa-plus"></i> Add Board
                  </button>
                </div>
              </div>
            </div> */}
            <PropertyBoards data={propertyBoards} handleBoardTrue={handleBoardTrue} hiddenData={false} p_id={id}/>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Public Files */}
          <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-notes-and-files.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Notes & Files:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/>
            </div>
          </div>
          <div className="md:flex mt-4">
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Public Files:
                </label>
              </div>
            </div>
            {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-public-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyPublicDocumentsWithUrl?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyPublicDocumentsWithUrl.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyPublicDocumentsWithUrl];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyPublicDocumentsWithUrl(fileLists);
                                let propertyCopy = [...propertyPublicDocumentsWithUrl];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    // order_number: picObj.order_number,
                                  })
                                );
                                setFormData({ ...formData, property_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyPublicDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
          </div>

          {/* Gallery  */}

          {/* <div >
            {propertyPublicDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, index) =>
                        fileComponent({
                          file: fileObject?.file,
                          key: index,
                          inputFieldId: "property-public-files",
                          trashAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_files,
                            ];
                            fileLists.splice(index, 1);
                            formData.property_files = fileLists;
                            setPropertyPublicDocumentsWithUrl(fileLists);
                          },
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
          {/* <FrontTree handleFiles={handleFiles} folderData={handleFile} getPictureTemplateID = {getPictureTemplateID} setPictureTemplateID = {templateID}/> */}
          <FolderStructure handleFiles={handleFiles} folderData={handleFile} getPictureTemplateID = {getPictureTemplateID} setPictureTemplateID = {templateID} PropertyUnitID={id}/>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/system-notes-teal.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Notes:
                  </label>
                </div>
                {/* <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData?.property_tenant_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                        ...editorData,
                        property_tenant_notes: cleanText,
                      });
                    }}
                  />
                </div> */}
              </div>
                <Notes data={publicNotes} handleNoteTrue={handleNoteTrue} hiddenData={false} type={"Public"}/>
            </div>
          </div>
          <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
        </TabPanel>
          {/* Property Assistant */}
          <TabPanel value={tabValue} index={4}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-help-information.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Assistance Information:
                </label>
              </div>
            </div> */}

            {/* {propertyContacts.map((contact, index) => {
              const contacts = [...propertyContacts];

              return (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            First Name:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-assistant-first-name-${index}`}
                            type="text"
                            value={contact?.firstname}
                            placeholder="Jhon"
                            onChange={(e) => {
                              contacts[index].firstname = e.target.value;
                              setPropertyContacts(contacts);
                              setFormData({ ...formData, contacts: contacts });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5"></div>
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Last Name:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-assistant-last-name-${index}`}
                            type="text"
                            value={contact?.lastname}
                            placeholder="Doe"
                            onChange={(e) => {
                              contacts[index].lastname = e.target.value;
                              setPropertyContacts(contacts);
                              setFormData({ ...formData, contacts: contacts });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Title:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-title-${index}`}
                          type="text"
                          value={contact?.title}
                          placeholder="Title"
                          onChange={(e) => {
                            contacts[index].title = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 1:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-1-${index}`}
                          type="text"
                          value={contact?.number1}
                          placeholder="123456789"
                          onChange={(e) => {
                            contacts[index].number1 = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-1-type-${index}`}
                          value={contact?.number1_type}
                          onChange={(e) => {
                            contacts[index].number1_type = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        >
                          <option value={"Office"}>Office</option>
                          <option value={"Phone"}>Phone</option>
                          <option value={"Home"}>Home</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 2:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-2-${index}`}
                          type="text"
                          value={contact?.number2}
                          placeholder="123456789"
                          onChange={(e) => {
                            contacts[index].number2 = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-2-type-${index}`}
                          value={contact?.number2_type}
                          onChange={(e) => {
                            contacts[index].number2_type = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        >
                          <option value={"Office"}>Office</option>
                          <option value={"Phone"}>Phone</option>
                          <option value={"Home"}>Home</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 3:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-3-${index}`}
                          type="text"
                          value={contact?.number3}
                          placeholder="123456789"
                          onChange={(e) => {
                            contacts[index].number3 = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-num-3-type-${index}`}
                          value={contact?.number3_type}
                          onChange={(e) => {
                            contacts[index].number3_type = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        >
                          <option value={"Office"}>Office</option>
                          <option value={"Phone"}>Phone</option>
                          <option value={"Home"}>Home</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-email-${index}`}
                          type="email"
                          value={contact?.email}
                          placeholder="jhon.doe@email.com"
                          onChange={(e) => {
                            contacts[index].email = e.target.value;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Info:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={contact?.info}
                          onChange={(e) => {
                            contacts[index].info = cleanText;
                            setPropertyContacts(contacts);
                            setFormData({ ...formData, contacts: contacts });
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex md:items-center md:justify-start md:w-2/5 mt-3">
                      <div className="md:w-1/2">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Contact Picture:
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                            Upload picture
                          </span>
                          <input
                            accept={picTypes}
                            id={`property-assistant-picture-${index}`}
                            type="file"
                            hidden={true}
                            onChange={async (e) => {
                              let file = e.target.files[0];
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  console.log(imgBase64);
                                  const res = await ApiRequest.post(
                                    "/public/api/auth/upload-image",
                                    { image: imgBase64 }
                                  );
                                  // console.log("res", res?.data?.data?.image);
                                  propertyContacts[index].picture =
                                    res?.data?.data?.image;
                                  // setFeatureIcon(res?.data?.data?.image);
                                } catch (err) {
                                  console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);

                              // console.log("here 142");
                              const pictures = [...propertyAssistantPictures];
                              pictures[index] = getImagesAndURLs(e)[0];
                              setPropertyAssistantPictures(pictures);
                              setPropertyContacts(contacts);
                              setFormData({ ...formData, contacts: contacts });
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    {formData?.contacts[index] ? (
                      <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                        <div className="container w-full">
                          <div
                            id="image-block"
                            className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                          >
                            {imageComponent({
                              image: formData?.contacts[index]?.picture,
                              src:
                                imgUrl +
                                "/" +
                                formData?.contacts[index]?.picture,
                              inputFieldId: "property-security-picture",
                              trashAble: true,
                              onTrashClick: () => {
                                formData.contacts[index].picture = null;
                                setPropertyAssistantPictures(null);
                              },
                            })}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div>
                  <hr />
                </div>
              );
            })} */}

            {/* Add more */}

            {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex justify-end w-full mt-4">
                <div className="w-2/4 pl-5 ml-5 mt-2 ">
                  <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setPropertyContacts([
                        ...propertyContacts,
                        initialContactData,
                      ]);
                      setPropertyAssistantPictures([
                        ...propertyAssistantPictures,
                        { ...initialImageData },
                      ]);
                    }}
                  >
                    <i class="fa fa-plus"></i> Add Another Contact
                  </button>
                </div>
              </div>
            </div> */}

              <PropertyAssistanceInformations data={propertyContacts} handleContactTrue={handleContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>

            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/security-information.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Security Information:
                </label>
              </div>
            </div> */}

            {/* {propertySecurityContacts?.map((row, index) => {
            const securities_contacts = [...propertySecurityContacts];

            return (<div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-3/7 md:w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      First Name:
                    </label>
                  </div>
                  <div className="md:w-1/5"></div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-security-first-name-${index}`}
                      type="text"
                      value={row?.firstname}
                      placeholder="Jhon"
                      onChange={(e) => {
                        securities_contacts[index].firstname = e.target.value;
                        setFormData({
                          ...formData,
                          securities_contacts: securities_contacts,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-3/7 md:w-1/2">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-security-last-name${index}`}
                      type="text"
                      value={row?.lastname}
                      placeholder="Doe"
                      onChange={(e) => {
                        securities_contacts[index].lastname = e.target.value;
                        setFormData({
                          ...formData,
                          securities_contacts: securities_contacts,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Title:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-title-${index}`}
                    type="text"
                    value={row?.title}
                    placeholder="Title"
                    onChange={(e) => {
                      securities_contacts[index].title = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-1-${index}`}
                    type="text"
                    value={row?.number1}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      securities_contacts[index].number1 = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-1-type-${index}`}
                    value={row?.number1_type}
                    onChange={(e) => {
                      securities_contacts[index].number1_type = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-2-${index}`}
                    type="text"
                    value={row?.number2}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      securities_contacts[index].number2 = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-2-type-${index}`}
                    value={row?.number2_type}
                    onChange={(e) => {
                      securities_contacts[index].number2_type = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-3-${index}`}
                    type="text"
                    value={row?.number3}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      securities_contacts[index].number3 = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-num-3-type-${index}`}
                    value={formData?.number3_type}
                    onChange={(e) => {
                      securities_contacts[index].number3_type = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`p-security-email-${index}`}
                    type="email"
                    value={formData?.email}
                    placeholder="jhon.doe@email.com"
                    onChange={(e) => {
                      securities_contacts[index].email = e.target.value;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Info:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={row?.info}
                    onChange={(e) => {
                      securities_contacts[index].info = cleanText;
                      setFormData({
                        ...formData,
                        securities_contacts: securities_contacts,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex md:items-center md:justify-start md:w-2/5 mt-3">
                <div className="md:w-1/2">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Security Picture:
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload picture
                    </span>
                    <input
                      accept={picTypes}
                      id={`property-security-picture-${index}`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post(
                              "/public/api/auth/upload-image",
                              { image: imgBase64 }
                            );
                            // // console.log("res", res?.data?.data?.image);
                            // formData.security_picture = res?.data?.data?.image;
                            propertySecurityContacts[index].picture =
                                  res?.data?.data?.image;
                            setFormData({
                              ...formData,
                              securities_contacts: securities_contacts,
                            });
                            // setFeatureIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                        // const pictureWithURL = getImagesAndURLs(e)[0];
                        // setPropertySecurityPicture(pictureWithURL);
                        const pictures = [...propertySecurityPictures];
                            pictures[index] = getImagesAndURLs(e)[0];
                            setPropertySecurityPictures(pictures);
                            setPropertySecurityContacts(securities_contacts);
                            setFormData({ ...formData, securities_contacts: securities_contacts });
                        // formData.security_picture = pictureWithURL.image.picture;
                        // setFormData({
                        // 	...formData,
                        // 	security_picture: pictureWithURL?.image?.picture,
                        // });
                      }}
                    />
                  </label>
                </div>
              </div>
              {propertySecurityPictures[index] ? (
                    <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                      <div className="container w-full">
                        <div
                          id="image-block"
                          className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                        >
                          {imageComponent({
                            image:
                              propertySecurityPictures[index]?.image?.picture,
                            src: propertySecurityPictures[index]?.url,
                            inputFieldId: `property-security-picture-${index}`,
                            trashAble: true,
                            onTrashClick: () => {
                              const pictures = [...propertySecurityPictures];
                              pictures[index] = { ...initialImageData };
                              securities_contacts[index].picture = { ...initialImageData };
                              setPropertySecurityPictures(pictures);
                              setPropertySecurityContacts(securities_contacts);
                              setFormData({ ...formData, securities_contacts: securities_contacts });
                            },
                          })}
                        </div>
                      </div>
                    </section>
                  ) : null}
            </div>
          </div>)})} */}
          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex flex-row-reverse w-full mt-4">
              <div className="w-2/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setPropertySecurityContacts([
                      ...propertySecurityContacts,
                      initialSecurityData,
                    ]);
                    setPropertySecurityPictures([
                      ...propertySecurityPictures,
                      { ...initialImageData },
                    ]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Security Contact
                </button>
              </div>
            </div>
          </div> */}
          <PropertySecurityContacts data={propertySecurityContacts} handleSecurityContactTrue={handleSecurityContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>
          <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Security */}
          {/* <TabPanel value={tabValue} index={5}>
					
				</TabPanel> */}

          {/* Property Measurements */}
          <TabPanel value={tabValue} index={5}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-measurements.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Measurements:
                </label>
                <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can track the measurements of anything in your property here for quick reference! Windows, room sizes, hallways, etc. If there are measurements for your individual unit, you can store them in the same tool under the unit!"
                    alt="Tooltip"
                  />
              </div>
            </div>

            <PropertyMeasurements measurementData={propertyMeasurements} handleMeasurementTrue={handleMeasurementTrue} hiddenData={false} propertyID={id}/>

            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Materials */}
          <TabPanel value={tabValue} index={6}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-materials.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Materials:
                </label>
              </div>
            </div> */}

            <PropertyMaterials data={propertyMaterials} handleMaterialTrue={handleMaterialTrue} hiddenData={false} ownerSettings={ownerSettings} PropertyID={id}/>

            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Parking Spaces */}

          <TabPanel value={tabValue} index={7}>
            {/* <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/listings-about-parking-spot.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Parking Spaces:
                </label>
              </div>
            </div> */}


            <ParkingSpaceTable data={parkingSpaces} handleTrue={handleTrue} hiddenData={false} id={id}/>
            {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
              <div className="md:w-2/4">
                <label className="block capitalize text-gray-700 font-bold mb-2">
                  Bulk Actions:
                </label>
              </div>
              <div className="md:w-3/4">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="bulk-actions"
                  value={formData?.bulk_action}
                  onChange={(e) => {
                    setFormData({ ...formData, bulk_action: e.target.value });
                  }}
                >
                  <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                  <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                  <option value={"DELETE"}>DELETE</option>
                  <option value={"UNASSIGN"}>UNASSIGN</option>
                </select>
              </div>
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <button
                    type="button"
                    className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500"
                  >
                    GO
                  </button>
                </label>
              </div>
            </div> */}

            {/* <hr /> */}
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Features */}
          <TabPanel value={tabValue} index={8}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/listings.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Listing Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Number of floors:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-num-of-floors"
                      value={formData?.no_of_floors}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          no_of_floors: e.target.value,
                        });
                      }}
                    >
                      {floorData.map((floor)=>(
                        <option value={floor}>{floor}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Stairs in Property:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.has_stairs}
                      checked={formData?.has_stairs === 1}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          has_stairs: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Elevator in Property:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.has_elevator}
                      checked={formData?.has_elevator === 1}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          has_elevator: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Description:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.description}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          description: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
                  <div className="md:w-1/5 mt-3">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Features:
                    </label>
                  </div>
                <div className="md:flex flex-wrap md:items-center mt-3">
                  {features.map((feature,index) => {
                    const featureIndex = formData?.features?.findIndex(item => item.feature_id === feature.id); // findIndex() method to get the index of the feature in formData.features array
    
                    return(
									// const addFeatures = [...features];
									<div className="md:w-1/5 form-check">
										
									<input
										className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
										id={`feature_type-${index}`}
										type="checkbox"
										value={formData?.features}
                                        // disabled
                    checked={featureIndex > -1}
										onChange={(e) => {
                      var checkedValue = e.target.checked === true ? feature.id : "";
                      var unCheckedValue = e.target.checked === false ? feature.id:"";
                      // var val = ""
                      // console.warn("unCheckedValue",unCheckedValue)
                      // console.warn("CheckedValue",checkedValue)
                      // console.warn("checkFeature",checkFeature)
                      // addFeature.map((feature,index)=>{
                      // 	// console.warn("feature",feature)
                      // 	if(unCheckedValue === feature?.feature_id) 
                      // 	{
                      // 		val =  index
                      // 	};
                      // 	console.warn(val)
                      // })

                      if(checkedValue !== "")
                      {
                        addFeature.push({feature_id:checkedValue})
                      }
                      else
                      {
                        
                        removeByAttr(addFeature, "feature_id",unCheckedValue)
                      }
                      // var checkedIconValue = e.target.checked === true ? feature.icon : "";
                      // checkedValue === "" ? addFeature?.slice(val,1): addFeature?.push({feature_id:checkedValue});
                      // featureAdd.push({feature_name:checkedNameValue});
                      // featureAdd[index].icon = checkedIconValue;
                      setAddFeature(addFeature);
                      setFormData({...formData,features:addFeature})
                    
                  }}
									/>
									<div className="md:w-auto">
									<img  src={`${imgUrl}/${feature.icon}`} width={"30px"} height={"auto"}></img>
									<label className="input-label text-gray-700 mb-2">
										{feature.feature}
									</label>
									</div>
								</div>
								)})}
                </div>
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Other Property Features:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData1?.other_property_features}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData1({
                          ...editorData1,
                          other_property_features: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* Property Pictures */}
                  <div className="md:w-1/5 mt-3">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Nearbys:
                    </label>
                  </div>
<div className="md:flex flex-wrap md:items-center mt-3">
                  {nearbys?.map((nearby,index) => {
                    const nearbyIndex = formData?.nearbys?.findIndex(item => item.nearby_id === nearby.id); // findIndex() method to get the index of the nearby in formData.nearbys array
    
                    return(
									// const addNearbys = [...nearbys];
									<div className="md:w-1/5 form-check">
										
									<input
										className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
										id={`nearby_type-${index}`}
										type="checkbox"
										value={formData?.nearbys}
                                        // disabled
                    checked={nearbyIndex > -1}
										onChange={(e) => {
                      var checkedValue = e.target.checked === true ? nearby.id : "";
                      var unCheckedValue = e.target.checked === false ? nearby.id:"";
                      // var val = ""
                      // console.warn("unCheckedValue",unCheckedValue)
                      // console.warn("CheckedValue",checkedValue)
                      // console.warn("checknearby",checknearby)
                      // addNearby.map((nearby,index)=>{
                      // 	// console.warn("nearby",nearby)
                      // 	if(unCheckedValue === nearby?.nearby_id) 
                      // 	{
                      // 		val =  index
                      // 	};
                      // 	console.warn(val)
                      // })

                      if(checkedValue !== "")
                      {
                        addNearby.push({nearby_id:checkedValue})
                      }
                      else
                      {
                        
                        removeByAttr(addNearby, "nearby_id",unCheckedValue)
                      }
                      // var checkedIconValue = e.target.checked === true ? nearby.icon : "";
                      // checkedValue === "" ? addNearby?.slice(val,1): addNearby?.push({nearby_id:checkedValue});
                      // nearbyAdd.push({nearby_name:checkedNameValue});
                      // nearbyAdd[index].icon = checkedIconValue;
                      setAddNearby(addNearby);
                      setFormData({...formData,nearbys:addNearby})
                    
                  }}
									/>
									<div className="md:w-auto">
									<img  src={`${imgUrl}/${nearby.icon}`} width={"30px"} height={"auto"}></img>
									<label className="input-label text-gray-700 mb-2">
										{nearby.name}
									</label>
									</div>
								</div>
								)})}
                </div>
                <div className="md:flex mt-4">
                  <div className="md:w-1/3">
                    <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                      Property Pictures:
                    </label>
                  </div>
                  <div className="md:flex flex-column md:items-start w-[90%] m-auto">
                    <div className="flex md:items-center md:justify-start md:w-2/3">
                      <div className="md:w-1/2">
                        <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                            Upload pictures
                          </span>
                          <input
                            accept={picTypes}
                            id="property-images"
                            type="file"
                            multiple={true}
                            hidden={true}
                            onChange={(e) => {
                              let files = e.target.files;
                              let newImages = [];
                              let maxOrderNumber = 0;
                            
                              // Find the highest order number in the current images
                              propertyFormImagesWithUrl?.forEach((imageObject) => {
                                if (imageObject.order_number > maxOrderNumber) {
                                  maxOrderNumber = imageObject.order_number;
                                }
                              });
                            
                              // Calculate the total number of images (existing images + new images)
                              const totalImages = propertyFormImagesWithUrl.length + files.length;
                            
                              // Loop through each file and convert to base64
                              files.forEach((file, i) => {
                                const reader = new FileReader();
                                reader.addEventListener("load", async () => {
                                  const imgBase64 = reader.result;
                                  try {
                                    const res = await ApiRequest.post(imageApi, {
                                      image: imgBase64,
                                    });
                            
                                    // Add the new image to the array with a unique order number
                                    newImages.push({
                                      picture: res?.data?.data?.image,
                                      title: res?.data?.data?.image,
                                      caption: res?.data?.data?.image,
                                      order_number: maxOrderNumber + i + 1,
                                      url: imgBase64,
                                      is_cover: i === 0 ? 1 : 0,
                                      picture_folder_id:null
                                    });
                            
                                    // If all new images have been processed, update the state
                                    if (newImages.length === files.length) {
                                      let pictureLists = [...propertyFormImagesWithUrl];
                                      pictureLists = [...pictureLists, ...newImages];
                            
                                      // Update the order number of the existing images
                                      pictureLists.forEach((imageObject, index) => {
                                        imageObject.order_number = index + 1;
                                      });
                            
                                      setPropertyFormImagesWithUrl(pictureLists);
                                      let propertyCopy = [...propertyFormImagesWithUrl];
                                      propertyCopy.pictures = pictureLists.map(
                                        (picObj) => ({
                                          picture: picObj.picture,
                                          title: picObj.title,
                                          caption: picObj.caption,
                                          order_number: picObj.order_number,
                                          is_cover: picObj.is_cover,
                                          picture_folder_id: picObj.picture_folder_id,
                                        })
                                      );
                                      setFormData({ ...formData, pictures: propertyCopy?.pictures });
                                    }
                                  } catch (err) {
                                    // console.log(err);
                                  }
                                });
                                reader.readAsDataURL(file);
                              });
                            }}
                          />
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                          <span className="text-gray-600">
                            {(() => {
                              const imagesCount =
                                propertyFormImagesWithUrl?.length || 0;
                              return imagesCount > 1
                                ? imagesCount + " Pictures"
                                : imagesCount == 1
                                ? imagesCount + " Picture"
                                : null;
                            }).call()}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gallery  */}

                {/* <div >
                  {formData?.pictures??.length ? (
                    <div>
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <div className="container w-full px-3 m-auto">
                          <div
                            id="images-block"
                            className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                          >
                            {formData.pictures.map((imageObject, index) =>
                              imageComponent({
                                image: formData?.pictures[index].picture,
                                key: index,
                                src:
                                  imgUrl +
                                  "/" +
                                  formData?.pictures[index].picture,
                                inputFieldId: "property-images",
                                trashAble: true,
                                onTrashClick: () => {
                                  let images = [...formData?.pictures];
                                  let image = [...propertyFormImagesWithUrl];
                                  images.splice(index, 1);
                                  image.splice(index, 1);
                                  setPropertyFormImagesWithUrl(image);
                                  setFormData({
                                    ...formData,
                                    pictures: images,
                                  });
                                },
                              })
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </div> */}
                    <Lightbox data={propertyFormImagesWithUrl} handleFiles={handlePicturesData} hiddenData={false}/>
                <hr />

                {/* Property Embed */}

                <div className="md:items-center w-full mt-4">
                <div className="md:w-full flex">
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                    Property Embed:
                  </label>
                  {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="Paste the embed code for your Property’s Virtual Tour or video here!"
                    alt="Tooltip"
                  /> */}
                   <Tooltip data={"Paste the embed code for your Property’s Virtual Tour or video here!"}/>
                </div>
                  <div className="w-10/12 mx-auto">
                    <textarea
                      className="appearance-none block w-[90%] m-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-embed-text-area"
                      value={formData?.embed}
                      placeholder='<iframe src="https://yourlink.com"></iframe>'
                      onChange={(e) => {
                        setFormData({ ...formData, embed: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <hr /> */}
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property HOA Information */}
          <TabPanel value={tabValue} index={9}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-hoa.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  HOA Information:
                </label>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-2/5 show-to-tenant">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Show to Tenant:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={formData?.hoa_show_to_tenant}
                        checked={formData?.hoa_show_to_tenant === 1}
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setFormData({
                            ...formData,
                            hoa_show_to_tenant: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-3/5 show-to-manager">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Show to Property Manager:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={formData?.hoa_show_to_property_manager}
                        checked={formData?.hoa_show_to_property_manager === 1}
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setFormData({
                            ...formData,
                            hoa_show_to_property_manager: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-6">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Company Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-hoa-company-name"
                      type="text"
                      value={formData?.hoa_company}
                      placeholder="J Heights"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_company: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="md:flex md:items-center w-full mt-6">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Contact First Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-hoa-firstname"
                      type="text"
                      value={formData?.hoa_contact_firstname}
                      placeholder="Jhon"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_contact_firstname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Contact Last Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-hoa-lastname"
                      type="text"
                      value={formData?.hoa_contact_lastname}
                      placeholder="Doe"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_contact_lastname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Contact Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-hoa-title"
                      type="text"
                      value={formData?.hoa_contact_title}
                      placeholder="Title"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_contact_title: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Address Line 1:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-address-line-1"
                      type="text"
                      value={formData?.hoa_address_line1}
                      placeholder="Apt. suite no. 1"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_address_line1: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      HOA Address Line 2:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-address-line-2"
                      type="text"
                      value={formData?.hoa_address_line2}
                      placeholder="Jeff Street"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_address_line2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_country"
                    type="text"
                    value={formData?.hoa_country}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, hoa_country: parseInt(e.target.value) });
                      getStates(e.target.value);
                    }}
                  >
                    <option value=" ">Select Country</option>
                    {countries?.map((country)=>(
                      <option value={country?.id}>{country?.country}</option>
                    ))}
                    </select>
                </div>
              </div>
              
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa-state"
                    value={formData?.hoa_state}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, hoa_state: parseInt(e.target.value) });
                      getCities(e.target.value);
                    }}
                  >
                    <option value=" ">Select State</option>
                    {states?.map((state)=>(
                      <option value={state?.id}>{state?.state}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa-city"
                    type="text"
                    value={formData?.hoa_city}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      const zip = cities?.map((col) =>{
                        if(col?.id == e.target.value)
                        {
                          return col?.zip_code
                        }
                      })
                      const filteredArr = zip.filter((value) => value !== undefined);
                      const zipCode = filteredArr.join(', ');

                      setFormData({ ...formData, hoa_city: parseInt(e.target.value),hoa_zip_code:zipCode });
                    }}
                  >
                    <option value=" ">Select City</option>
                    {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa-zip-code"
                    type="text"
                    value={formData?.hoa_zip_code}
                    placeholder="90210"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, hoa_zip_code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
                {/* <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        City:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa-city"
                        type="text"
                        value={formData?.hoa_city}
                        placeholder="Albuquerque"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hoa_city: parseInt(e.target.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        State:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa-state"
                        value={formData?.hoa_state}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hoa_state: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={"New Mexico"}>New Mexico</option>
                        <option value={"Missouri"}>Missouri</option>
                        <option value={"Texas"}>Texas</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Zip:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa-zip"
                        type="text"
                        value={formData?.hoa_zip_code}
                        placeholder="90210"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            hoa_zip_code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {formData?.hoa_number1_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-1"
                    type="text"
                    value={formData?.hoa_number1}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number1: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-1"
                    type="text"
                    value={formatPhoneNumber(formData?.hoa_number1,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number1: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-1-type"
                    value={formData?.hoa_number1_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number1_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.hoa_number2_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-2"
                    type="text"
                    value={formData?.hoa_number2}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number2: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-2"
                    type="text"
                    value={formatPhoneNumber(formData?.hoa_number2,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number2: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-2-type"
                    value={formData?.hoa_number2_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number2_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.hoa_number3_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-3"
                    type="text"
                    value={formData?.hoa_number3}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number3: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-3"
                    type="text"
                    value={formatPhoneNumber(formData?.hoa_number3,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number3: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-hoa-num-3-type"
                    value={formData?.hoa_number3_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        hoa_number3_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-email"
                      type="email"
                      value={formData?.hoa_email}
                      placeholder="jhon.doe@email.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Website:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-website"
                      type="url"
                      value={formData?.hoa_website}
                      placeholder="www.jhon-doe.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          hoa_website: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/property-hoa-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                HOA Contacts:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
          </div>
              <InfoContactForm data={propertyHoaContacts} handleContactTrue={handleHoaContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyHoaInteractions} handleInteractionTrue={handleHoaInteractionTrue} hiddenData={false} ownerSettings={ownerSettings}/>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-2/5">
                    <div className="md:w-1/2">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        HOA Fees:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                    <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="hoa-fee"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.hoa_fee != null ? formData?.hoa_fee : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, hoa_fee: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, hoa_fee: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:justify-around md:w-3/5">
                    {formData?.hoa_show_to_tenant ? (
                      <div className="md:flex md:w-2/5">
                        <div className="md:flex md:justify-end md:items-center pr-5 md:w-4/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Show to Tenant:
                          </label>
                        </div>
                        <label class="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={formData?.hoa_fee_show_to_tenant}
                            checked={formData?.hoa_fee_show_to_tenant === 1}
                            onChange={(e) => {
                              const checkedValue =
                                e.target.checked === true ? 1 : 0;
                              setFormData({
                                ...formData,
                                hoa_fee_show_to_tenant: checkedValue,
                              });
                            }}
                            class="sr-only peer"
                          />
                          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                    ) : null}
                    {formData?.hoa_show_to_property_manager ? (
                      <div className="md:flex md:w-3/5">
                        <div className="md:flex md:justify-end md:items-center pr-5 md:w-4/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Show to Property Manager:
                          </label>
                        </div>
                        <label class="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={formData?.hoa_fee_show_to_property_manager}
                            checked={
                              formData?.hoa_fee_show_to_property_manager === 1
                            }
                            onChange={(e) => {
                              const checkedValue =
                                e.target.checked === true ? 1 : 0;
                              setFormData({
                                ...formData,
                                hoa_fee_show_to_property_manager: checkedValue,
                              });
                            }}
                            class="sr-only peer"
                          />
                          <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Due Date:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa-due-date"
                    // type="date"
                    value={formData?.hoa_due_date}
                    // placeholder="18293213"
                    required={false}
                    onChange={(e) => {
                      const date = parseInt(e.target.value);
                      setFormData({ ...formData, hoa_due_date: date });
                    }}
                  >
                    <option value="">Select Date</option>
                    {dueDate.map((row)=>(
                      <option value={row}>{row}</option>
                    ))}
                  </select>
                  </div>
                </div>
                {formData?.hoa_show_to_tenant ? (
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5 flex" >
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tenant Notes:
                    </label>
                    {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="These notes will be available to be seen by your Tenant. They should be kept to notes pertaining your HOA"
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"These notes will be available to be seen by your Tenant. They should be kept to notes pertaining your HOA"}/>
                  </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData2?.hoa_tenant_notes}
                        onChange={(e) => {
                          const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData2({
                            ...editorData2,
                            hoa_tenant_notes: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {formData?.hoa_show_to_property_manager ? (
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5 flex">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Manager Notes:
                    </label>
                    {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="These notes will be available to be seen by your Property Manager(s). They should be kept to notes pertaining your HOA."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"These notes will be available to be seen by your Property Manager(s). They should be kept to notes pertaining your HOA."}/>
                  </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData1?.hoa_property_manager_notes}
                        onChange={(e) => {
                          const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData1({
                            ...editorData1,
                            hoa_property_manager_notes: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="md:flex mt-3">
                <div className="md:w-1/5 flex">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2" >
                    HOA Owner's Notes:
                  </label>
                  {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="Only you will be able to see these notes related to your HOA."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"Only you will be able to see these notes related to your HOA."}/>
                </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.hoa_owner_notes}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          hoa_owner_notes: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>

                {/* HOA Files */}

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    HOA Files:
                  </label>
                </div>
                <div className="md:w-1/5">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="p-HOA-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyHoaFiles?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyHoaFiles.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyHoaFiles];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyHoaFiles(fileLists);
                                let propertyCopy = [...propertyHoaFiles];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, hoa_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount = propertyHoaFiles?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>

              {/* Gallery  */}

              <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyHoaFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-HOA-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyHoaFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-HOA-files",
                              trashAble: true,
                              editAble: true,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.hoa_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.hoa_files = fileLists;
                                setPropertyHoaFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.hoa_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.hoa_files[fileIndex].title = title;
                                    setPropertyHoaFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Mortage Information */}
          <TabPanel value={tabValue} index={10}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-mortgage.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Mortgage Information:
                </label>
                {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="Only the Owner can see this section related to the Property’s Mortgage."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"Only the Owner can see this section related to the Property’s Mortgage."}/>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center w-full mt-6">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Company:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-mortage-company-name"
                      type="text"
                      value={formData?.mortage_company}
                      placeholder="J Heights"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_company: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="md:flex md:items-center w-full mt-6">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Contact First Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-mortage-firstname"
                      type="text"
                      value={formData?.mortage_contact_firstname}
                      placeholder="Jhon"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_contact_firstname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Contact Last Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-mortage-lastname"
                      type="text"
                      value={formData?.mortage_contact_lastname}
                      placeholder="Doe"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_contact_lastname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Contact Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-mortage-title"
                      type="text"
                      value={formData?.mortage_contact_title}
                      placeholder="Title"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_contact_title: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 1:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-address-line-1"
                      type="text"
                      value={formData?.mortage_address_line1}
                      placeholder="Apt. suite no. 1"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_address_line1: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 2:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-mortage-address-line-2"
                      type="text"
                      value={formData?.mortage_address_line2}
                      placeholder="Jeff Street"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_address_line2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        City:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa-city"
                        type="text"
                        value={formData?.mortage_city}
                        placeholder="Albuquerque"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mortage_city: parseInt(e.target.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        State:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="mortage-state"
                        value={formData?.mortage_state}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mortage_state: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={"New Mexico"}>New Mexico</option>
                        <option value={"Missouri"}>Missouri</option>
                        <option value={"Texas"}>Texas</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Zip:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="mortage-zip"
                        type="text"
                        value={formData?.mortage_zip_code}
                        placeholder="90210"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            mortage_zip_code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                 <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mortage_country"
                    type="text"
                    value={formData?.mortage_country}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, mortage_country: parseInt(e.target.value) });
                      getStates(e.target.value);
                    }}
                  >
                    <option value=" ">Select Country</option>
                    {countries?.map((country)=>(
                      <option value={country?.id}>{country?.country}</option>
                    ))}
                    </select>
                </div>
              </div>
              
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mortage-state"
                    value={formData?.mortage_state}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, mortage_state: parseInt(e.target.value) });
                      getCities(e.target.value);
                    }}
                  >
                    <option value=" ">Select State</option>
                    {states?.map((state)=>(
                      <option value={state?.id}>{state?.state}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mortage-city"
                    type="text"
                    value={formData?.mortage_city}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      const zip = cities?.map((col) =>{
                        if(col?.id == e.target.value)
                        {
                          return col?.zip_code
                        }
                      })
                      const filteredArr = zip.filter((value) => value !== undefined);
                      const zipCode = filteredArr.join(', ');

                      setFormData({ ...formData, mortage_city: parseInt(e.target.value),mortage_zip_code:zipCode });
                    }}
                  >
                    <option value=" ">Select City</option>
                    {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mortage-zip-code"
                    type="text"
                    value={formData?.mortage_zip_code}
                    placeholder="90210"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, mortage_zip_code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
                <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {formData?.mortage_number1_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-1"
                    type="text"
                    value={formData?.mortage_number1}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number1: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-1"
                    type="text"
                    value={formatPhoneNumber(formData?.mortage_number1,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number1: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-1-type"
                    value={formData?.mortage_number1_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number1_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.mortage_number2_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-2"
                    type="text"
                    value={formData?.mortage_number2}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number2: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-2"
                    type="text"
                    value={formatPhoneNumber(formData?.mortage_number2,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number2: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-2-type"
                    value={formData?.mortage_number2_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number2_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.mortage_number3_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-3"
                    type="text"
                    value={formData?.mortage_number3}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number3: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-3"
                    type="text"
                    value={formatPhoneNumber(formData?.mortage_number3,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number3: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-mortage-num-3-type"
                    value={formData?.mortage_number3_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mortage_number3_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-mortage-email"
                      type="email"
                      value={formData?.mortage_email}
                      placeholder="jhon.doe@email.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Website:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-mortage-website"
                      type="url"
                      value={formData?.mortage_website}
                      placeholder="www.jhon-doe.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          mortage_website: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/property-mortgage-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Mortgage Contacts:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
          </div>
              <InfoContactForm data={propertyMortageContacts} handleContactTrue={handleMortageContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyMortageInteractions} handleInteractionTrue={handleMortageInteractionTrue} hiddenData={false} ownerSettings={ownerSettings}/>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center md:w-2/5">
                    <div className="md:w-1/2">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Mortgage Minimun Payment:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                    <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="mortage-minimum-payment"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.mortage_minimum_payment != null ? formData?.mortage_minimum_payment : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, mortage_minimum_payment: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, mortage_minimum_payment: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:justify-center md:w-2/5">
                    <div className="md:w-1/2 mr-5">
                      <label className="block capitalize text-gray-700 text-end font-bold mb-2">
                        Due Date:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                    <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mortgage-due-date"
                    // type="date"
                    value={formData?.mortage_due_date}
                    // placeholder="18293213"
                    required={false}
                    onChange={(e) => {
                      const date = parseInt(e.target.value);
                      setFormData({ ...formData, mortage_due_date: date });
                    }}
                  >
                    <option value="">Select Date</option>
                    {dueDate.map((row)=>(
                      <option value={row}>{row}</option>
                    ))}
                  </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center md:w-2/5">
                    <div className="md:w-1/2">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Mortgage Balance:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                    <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="mortage-balance"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.mortage_balance != null ? formData?.mortage_balance : ""}
                          
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, mortage_balance: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, mortage_balance: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                    </div>
                  </div>
                </div>


                {/* Mortage Files */}

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Mortgage Files:
                  </label>
                </div>
                <div className="md:w-1/5">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="p-mortage-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyMortageFiles?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyMortageFiles.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyMortageFiles];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyMortageFiles(fileLists);
                                let propertyCopy = [...propertyMortageFiles];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, mortage_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount = propertyMortageFiles?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>

              {/* Gallery  */}

              <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyMortageFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-mortage-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyMortageFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-mortage-files",
                              trashAble: true,
                              editAble: true,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.mortage_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.mortage_files = fileLists;
                                setPropertyMortageFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.mortage_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.mortage_files[fileIndex].title = title;
                                    setPropertyMortageFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>

                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Mortgage Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.mortage_notes}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          mortage_notes: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Builder Information */}
          <TabPanel value={tabValue} index={11}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/property-builder.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Builder Information:
                </label>
                {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="Only the Owner can see this section related to the Property’s Builder."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"Only the Owner can see this section related to the Property’s Builder."}/>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center w-full mt-6">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Company:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-builder-company-name"
                      type="text"
                      value={formData?.builder_company}
                      placeholder="J Heights"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_company: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center md:w-2/5">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Built Date:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    {onlyYear == false ?
                    <DatePicker
                      value={stringToDate(formData?.builder_date)}
                      onChange={(e) => {
                        const date = formatDate(e);
                        setFormData({ ...formData, builder_date: date });
                      }}
                    />
                    
                    :
                    <select 
                    value={formData?.builder_date}
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => {
                      const date = e.target.value;
                      setFormData({ ...formData, builder_date: date });
                    }}
                    >
                      <option value="">Select Year</option>
                      {Array.from({ length: 2099 - 1900 + 1 }, (_, index) => (
                        <option key={index} value={(1900 + index).toString()}>
                          {(1900 + index).toString()}
                        </option>
                      ))}
                      </select>}
                    <div className="flex mt-2">
                      <input 
                    type="checkbox"
                    checked={onlyYear}
                    
                    id="only-year"
                    onChange={(e)=>{
                      const isChecked = e.target.checked ? true : false;
                      setOnlyYear(isChecked);
                    }} 
                     />
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mx-1" htmlFor="only-year">
                      Only Year
                    </label>
                    </div>
                  </div>
                </div>
              </div>
                {/* <div className="md:flex md:items-center w-full mt-4">
                  <div className="md:flex md:items-center w-3/7 md:w-1/2">
                    <div className="md:w-1/5">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        First Name:
                      </label>
                    </div>
                    <div className="md:w-1/5"></div>
                    <div className="md:w-3/5">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="p-builder-firstname"
                        type="text"
                        value={formData?.builder_firstname}
                        placeholder="Jhon"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            builder_firstname: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-3/7 md:w-1/2">
                    <div className="md:w-1/5"></div>
                    <div className="md:w-1/5">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Last Name:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="p-builder-lastname"
                        type="text"
                        value={formData?.builder_lastname}
                        placeholder="Doe"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            builder_lastname: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="property-builder-title"
                      type="text"
                      value={formData?.builder_title}
                      placeholder="Title"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_title: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 1:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-hoa-address-line-1"
                      type="text"
                      value={formData?.builder_address_line1}
                      placeholder="Apt. suite no. 1"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_address_line1: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 2:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-builder-address-line-2"
                      type="text"
                      value={formData?.builder_address_line2}
                      placeholder="Jeff Street"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_address_line2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="builder_country"
                    type="text"
                    value={formData?.builder_country}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, builder_country: parseInt(e.target.value) });
                      getStates(e.target.value);
                    }}
                  >
                    <option value=" ">Select Country</option>
                    {countries?.map((country)=>(
                      <option value={country?.id}>{country?.country}</option>
                    ))}
                    </select>
                </div>
              </div>
              
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="builder-state"
                    value={formData?.builder_state}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, builder_state: parseInt(e.target.value) });
                      getCities(e.target.value);
                    }}
                  >
                    <option value=" ">Select State</option>
                    {states?.map((state)=>(
                      <option value={state?.id}>{state?.state}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="builder-city"
                    type="text"
                    value={formData?.builder_city}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      const zip = cities?.map((col) =>{
                        if(col?.id == e.target.value)
                        {
                          return col?.zip_code
                        }
                      })
                      const filteredArr = zip.filter((value) => value !== undefined);
                      const zipCode = filteredArr.join(', ');

                      setFormData({ ...formData, builder_city: parseInt(e.target.value),builder_zip_code:zipCode });
                    }}
                  >
                    <option value=" ">Select City</option>
                    {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="builder-zip-code"
                    type="text"
                    value={formData?.builder_zip_code}
                    placeholder="90210"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, builder_zip_code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
                {/* <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        City:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="hoa-city"
                        type="text"
                        value={formData?.builder_city}
                        placeholder="Albuquerque"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            builder_city: parseInt(e.target.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        State:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="builder-state"
                        value={formData?.builder_state}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            builder_state: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={"New Mexico"}>New Mexico</option>
                        <option value={"Missouri"}>Missouri</option>
                        <option value={"Texas"}>Texas</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Zip:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="builder-zip"
                        type="text"
                        value={formData?.builder_zip_code}
                        placeholder="90210"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            builder_zip_code: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.builder_number1_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-1"
                    type="text"
                    value={formData?.builder_number1}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number1: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-1"
                    type="text"
                    value={formatPhoneNumber(formData?.builder_number1,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number1: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-1-type"
                    value={formData?.builder_number1_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number1_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.builder_number2_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-2"
                    type="text"
                    value={formData?.builder_number2}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number2: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-2"
                    type="text"
                    value={formatPhoneNumber(formData?.builder_number2,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number2: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-2-type"
                    value={formData?.builder_number2_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number2_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.builder_number3_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-3"
                    type="text"
                    value={formData?.builder_number3}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number3: e.target.value
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-3"
                    type="text"
                    value={formatPhoneNumber(formData?.builder_number3,ownerSettings?.phone_number_format)}
                    maxLength={ownerSettings?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number3: removeNonNumeric(e.target.value),
                      });
                    }}
                  />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-builder-num-3-type"
                    value={formData?.builder_number3_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        builder_number3_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-builder-email"
                      type="email"
                      value={formData?.builder_email}
                      placeholder="jhon.doe@email.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Website:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-builder-website"
                      type="url"
                      value={formData?.builder_website}
                      placeholder="www.jhon-doe.com"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          builder_website: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/property-builder-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Builder Contacts:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."
                    alt="Tooltip"
                  /> */}
                {/* <Tooltip data={"You can add notes and files with different levels of access. Public notes and files everyone, including Tenants, see. All Staff see the Staff Notes and Files. Only Property Managers see the Property Manager ones, and the Owner Notes and Files are Private to just the Owner."}/> */}
            </div>
          </div>
              <InfoContactForm data={propertyBuilderContacts} handleContactTrue={handleBuilderContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              <Interactions data={propertyBuilderInteractions} handleInteractionTrue={handleBuilderInteractionTrue} hiddenData={false} ownerSettings={ownerSettings}/>
                {/* Builder Files */}

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Builder Files:
                  </label>
                </div>
                <div className="md:w-1/5">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="p-builder-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyBuilderFiles?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyBuilderFiles.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyBuilderFiles];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyBuilderFiles(fileLists);
                                let propertyCopy = [...propertyBuilderFiles];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, builder_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount = propertyBuilderFiles?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>


              <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {propertyBuilderFiles.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="p-builder-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {propertyBuilderFiles.map((fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: "p-builder-files",
                              trashAble: true,
                              editAble: true,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...formData?.builder_files,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.builder_files = fileLists;
                                setPropertyBuilderFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...formData?.builder_files];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.builder_files[fileIndex].title = title;
                                    setPropertyBuilderFiles(fileLists);
                                  }
                                });
                              }
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>

                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Builder Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.builder_notes}
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          builder_notes: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Property Insurance Claim */}
          {/* <TabPanel value={tabValue} index={11}> */}
          {/* <div className="md:flex flex-column mt-4">
					<div className="w-full">
						<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
							Insurance Claims:
						</label>
					</div>

					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="flex md:justify-start w-full mt-3">
							<div className="md:w-1/5">
								<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
									Claim Resolution Files:
								</label>
							</div>
							<div className="md:w-1/5">
								<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
									<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
										Upload files
									</span>
									<input
										id="property-insurance-claim-files"
										type="file"
										accept={fileTypes}
										multiple={true}
										hidden={true}
										onChange={(e) => {
											setPropertyClaimResolutionDocumentsWithUrl(
												getFilesAndURLs(e)
											);
										}}
									/>
								</label>
							</div>
							<div className="md:w-1/2">
								<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
									<span className="text-gray-600">
										{(() => {
											const filesCount =
												propertyClaimResolutionDocumentsWithUrl?.length || 0;
											return filesCount > 1
												? filesCount + " Documents"
												: filesCount == 1
												? filesCount + " Document"
												: null;
										}).call()}
									</span>
								</label>
							</div>
						</div>
					</div>
				</div> */}

          {/* Gallery  */}
          {/* 
					<div >
						{propertyClaimResolutionDocumentsWithUrl?.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-insurance-claim-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyClaimResolutionDocumentsWithUrl.map(
												(fileObject, index) =>
													fileComponent({
														file: fileObject?.file,
														key: index,
														inputFieldId: "property-insurance-claim-files",
														trashAble: true,
														onTrashClick: () => {
															let files = [
																...propertyClaimResolutionDocumentsWithUrl,
															];
															files.splice(index, 1);
															setPropertyClaimResolutionDocumentsWithUrl(files);
														},
													})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div> */}
          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Claim Resolution Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={"hi how are you?"}
										style={{ color: "red" }}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Tenant Payment */}
          <TabPanel value={tabValue} index={12}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/finance-dollar-bill.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Payment Information:
                </label>
                {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="This information will appear on the invoices for your Tenants. You can list payment options and activate Payment Processors to allow them to pay directly through the system!"
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"This information will appear on the invoices for your Tenants. You can list payment options and activate Payment Processors to allow them to pay directly through the system!"}/>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="md:flex md:items-center w-full mt-6">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Company:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-tenant-company"
                    type="text"
                    placeholder="J Heights"
                    value={formData?.payment_info_company}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        payment_info_company: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-3/7 md:w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      First Name:
                    </label>
                  </div>
                  <div className="md:w-1/5"></div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-first-name"
                      type="text"
                      placeholder="Jhon"
                      value={formData?.payment_info_firstname}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_firstname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-3/7 md:w-1/2">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-last-name"
                      type="text"
                      placeholder="Doe"
                      value={formData?.payment_info_lastname}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_lastname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-tenant-address-line-1"
                    type="text"
                    placeholder="Apt. suite no. 1"
                    value={formData?.payment_info_address_line1}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        payment_info_address_line1: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-tenant-address-line-2"
                    type="text"
                    placeholder="Jeff Street"
                    value={formData?.payment_info_address_line2}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        payment_info_address_line2: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="payment_info_country"
                    type="text"
                    value={formData?.payment_info_country}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, payment_info_country: parseInt(e.target.value) });
                      getStates(e.target.value);
                    }}
                  >
                    <option value=" ">Select Country</option>
                    {countries?.map((country)=>(
                      <option value={country?.id}>{country?.country}</option>
                    ))}
                    </select>
                </div>
              </div>
              
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="payment_info-state"
                    value={formData?.payment_info_state}
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, payment_info_state: parseInt(e.target.value) });
                      getCities(e.target.value);
                    }}
                  >
                    <option value=" ">Select State</option>
                    {states?.map((state)=>(
                      <option value={state?.id}>{state?.state}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>
            <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="payment_info-city"
                    type="text"
                    value={formData?.payment_info_city}
                    placeholder="Albuquerque"
                    required={false}
                    onChange={(e) => {
                      const zip = cities?.map((col) =>{
                        if(col?.id == e.target.value)
                        {
                          return col?.zip_code
                        }
                      })
                      const filteredArr = zip.filter((value) => value !== undefined);
                      const zipCode = filteredArr.join(', ');

                      setFormData({ ...formData, payment_info_city: parseInt(e.target.value),payment_info_zip_code:zipCode });
                    }}
                  >
                    <option value=" ">Select City</option>
                    {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="payment_info-zip-code"
                    type="text"
                    value={formData?.payment_info_zip_code}
                    placeholder="90210"
                    required={false}
                    onChange={(e) => {
                      setFormData({ ...formData, payment_info_zip_code: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
              {/* <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-4/12">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-city"
                      type="text"
                      placeholder="Albuquerque"
                      value={formData?.payment_info_city}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_city: parseInt(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-state"
                      value={formData?.payment_info_state}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_state: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value=" ">Select State</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Texas">Texas</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-zip"
                      type="text"
                      placeholder="90210"
                      value={formData?.payment_info_zip_code}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_zip_code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Number:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-num"
                      type="tel"
                      placeholder="123456789"
                      value={formatPhoneNumber(formData?.payment_info_number,ownerSettings?.phone_number_format)}
                      maxLength={ownerSettings?.phone_number_format?.length}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_number: removeNonNumeric(e.target.value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Property Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-email"
                      type="email"
                      placeholder="jhon.doe@email.com"
                      value={formData?.payment_info_email}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          payment_info_email: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Other Payment Information:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={
                        editorData?.payment_info_other_payment_information
                      }
                      onChange={(e) => {
                        const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                          ...editorData,
                          payment_info_other_payment_information:
                            cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/finance-payment-gateways.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Payment Processing Options:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-tenant-payment-processing-option"
                      onChange={(e) => {
                        // TODO on payment
                      }}
                    >
                      <option>Stripe</option>
                      <option>RentBlaze</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>
          {/* Staff */}
          <TabPanel value={tabValue} index={13}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/users-staff.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Staff:
                </label>
                {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="If you already have specific employee numbers that you use for your staff, you can enter them manually and use them with Rent Blaze! If not, the system will autogenerate numbers for you."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"If you already have specific employee numbers that you use for your staff, you can enter them manually and use them with Rent Blaze! If not, the system will autogenerate numbers for you."}/>
              </div>
            </div>

            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="md:flex md:items-center w-full">
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Auto-generate Employee Numbers:
                    </label>
                  </div>
                  <div className="md:w-1/5 form-check form-switch p-0">
                    <input
                      className="form-check-input appearance-none bg-gray-200 border border-gray-200 w-25 rounded-full text-gray-700 py-3 px-4 pr-8 m-0 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="checkbox"
                      role="switch"
                      id="p-staff"
                      // TODO staff check
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
          </TabPanel>

          {/* Property Staff Files */}
          {/* <TabPanel value={tabValue} index={15}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Staff Files:
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-staff-files"
											type="file"
											accept={fileTypes}
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyStaffDocumentsWithUrl(getFilesAndURLs(e));
												const files =[...propertyStaffDocumentsWithUrl];
												console.warn(files);
												setFormData({...formData, property_staff_files : getFilesAndURLs(e)})
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyStaffDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>


					<div >
						{propertyStaffDocumentsWithUrl?.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-staff-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyStaffDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-staff-files",
													trashAble: true,
													onTrashClick: () => {
														let files = [...propertyStaffDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyStaffDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Staff Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={formData?.property_staff_notes}
										onChange={(e) => {
											setFormData({
												...formData,
												property_staff_notes: cleanText,
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Manager Files */}
          {/* <TabPanel value={tabValue} index={16}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Property Manager Files:
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-manager-files"
											type="file"
											accept={fileTypes}
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyManagerDocumentsWithUrl(getFilesAndURLs(e));
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyManagerDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>

				

					<div >
						{propertyManagerDocumentsWithUrl?.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-manager-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyManagerDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-manager-files",
													trashAble: true,
													onTrashClick: () => {
														let files = [...propertyManagerDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyManagerDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Property Manager Notes:
									</label>
								</div>
								<div className="md:w-4/5">
									<CKEditor
										initData={formData?.property_manager_notes}
										onChange={(e) => {
											setFormData({
												...formData,
												property_manager_notes: cleanText,
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<hr/>
				</TabPanel> */}
          {/* Property Owner Files */}
          {/* <TabPanel value={tabValue} index={17}>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
							<label className="block capitalize text-gray-700 text-xl font-bold mb-2">
								Owner Files
							</label>
						</div>
						<div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
											id="property-owner-files"
											type="file"
											accept={fileTypes}
											multiple={true}
											hidden={true}
											onChange={(e) => {
												setPropertyOwnerDocumentsWithUrl(getFilesAndURLs(e));
											}}
										/>
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
										<span className="text-gray-600">
											{(() => {
												const filesCount =
													propertyOwnerDocumentsWithUrl?.length || 0;
												return filesCount > 1
													? filesCount + " Documents"
													: filesCount == 1
													? filesCount + " Document"
													: null;
											}).call()}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>


					<div >
						{propertyOwnerDocumentsWithUrl?.length ? (
							<div>
								<section className="overflow-hidden w-full text-gray-700 mt-4">
									<div className="container w-full px-3 m-auto">
										<div
											id="property-owner-documents-block"
											className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
										>
											{propertyOwnerDocumentsWithUrl.map((fileObject, index) =>
												fileComponent({
													file: fileObject?.file,
													key: index,
													inputFieldId: "property-owner-files",
													trashAble: true,
													onTrashClick: () => {
														let files = [...propertyOwnerDocumentsWithUrl];
														files.splice(index, 1);
														setPropertyOwnerDocumentsWithUrl(files);
													},
												})
											)}
										</div>
									</div>
								</section>
							</div>
						) : null}
					</div>
					<div className="md:flex flex-column md:items-center w-[90%] m-auto">
					<div className="w-full">
						<div className="md:flex mt-3">
							<div className="md:w-1/5">
								<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
									Owner Notes:
								</label>
							</div>
							<div className="md:w-4/5">
								<CKEditor
									initData={formData?.property_owner_notes}
									onChange={(e) => {
										setFormData({
											...formData,
											property_owner_notes: cleanText,
										});
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<input id={"form-submit-button"} type={"submit"} hidden={false} />
				</TabPanel> */}
        </form>
		</>
      )}
    </>
  );
};
