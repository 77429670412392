import { createSlice } from "@reduxjs/toolkit";
import { setUserProfile } from "./authThunk";

const authUserProfileSlice = createSlice({
  name: "auth",
  initialState: {
    status: null,
    currentUser: null,
  },
  extraReducers: {
    [setUserProfile.pending]: (state) => {
      console.log("here 38===============");
      state.status = "loading";
    },
    [setUserProfile.fulfilled]: (state, { payload }) => {
      console.log("here 41 ===============");
      state.status = "success";
      state.currentUser = payload;
    },
    [setUserProfile.rejected]: (state, _error) => {
      console.log("here 45===============");
      state.status = "failed";
      state.currentUser = null;
    },
  },
});

export default authUserProfileSlice.reducer;
