import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { CKEditor } from "ckeditor4-react";
import { Tab, Tabs } from "@mui/material";

export const EditIssueTrackerForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Issue Subject" {...a11yProps(0)} />
          <Tab label="Internal Notes and Files" {...a11yProps(1)} />
          <Tab label="Issue Expenses" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="flex-col items-center w-[90%] m-[auto]">
            <div className="flex items-center w-1/3">
              <img
                src="/assets/svgs/support-maintenance.svg"
                className="w-1/4"
                alt=""
              />
              <label className="w-9/12 ml-2 uppercase tracking-wider text-gray-700 text-2xl font-bold">
                {"{"}issue subject{"}"}
              </label>
            </div>
            <div className="w-[90%] mt-5 m-auto p-4 bg-cyan-100 rounded-xl uppercase">
              <div className="flex items-center ">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    submitted:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    Date | time
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Priority:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Priority{"}"}
                  </p>
                </div>
                <div className="flex items-center w-1/5 ">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Status:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}status{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Ticket Number:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}AUTOGENERATED{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Assigned TO:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}assigned{"}"}
                  </p>
                </div>
                <div className="flex items-center w-1/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    costs:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}expenses{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    complaintant:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}complaintant{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    shared with:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}shared with{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center ">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    location:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}location{"}"} | {"{"}Unit_location{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Permission to enter?:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}yes/no{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  subject:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}subject{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Description:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center ">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    pictures:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}pictures{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Files:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Files{"}"}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex mt-8 w-full justify-between">
              <label className="block tracking-wide uppercase text-gray-700 text-lg font-bold">
                add reply:
              </label>
              <div className="w-10/12 m-auto">
                <CKEditor />
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center w-1/2 justify-between ">
                <img src="/assets/svgs/system-pictures.svg" className="w-1/6" alt="" />
                <label className="block tracking-wide text-gray-700 text-base font-bold">
                  Pictures:
                </label>
                <button className="btn btn3">Upload Pictures</button>
              </div>
              <div className="flex items-center w-1/2 justify-between">
                <img src="/assets/svgs/system-files.svg" className="w-1/6" alt="" />
                <label className="block tracking-wide text-gray-700 text-base font-bold">
                  Files:
                </label>
                <button className="btn btn3">Upload Files</button>
              </div>
            </div>
            <div className="flex items-center w-1/3 justify-between mt-4">
              <label className="block tracking-wide text-gray-700 text-base font-semibold">
                Permission to Enter if not Home ?
              </label>
              <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" class="sr-only peer" />
                <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div className="flex  w-1/2 items-center mt-4">
              <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
                Share with:
              </label>
              <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
                <option></option>
              </select>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center w-full">
                <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
                  Assign to:
                </label>
                <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5">
                  <option></option>
                </select>
              </div>
              <div className="flex items-center w-full ">
                <label className="block tracking-wide w-1/5 text-gray-700 text-base font-bold">
                  Ticket Status:
                </label>
                <select className="block appearance-none w-3/5 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option></option>
                </select>
              </div>
            </div>
            <div className="flex items-center float-right mt-4">
              <button className="btn btn3">Submit</button>
            </div>
          </div>
          <hr />
          <div className="flex-col items-center w-[90%] m-[auto]">
            <div className="flex items-center w-1/3 ">
              <img
                src="/assets/svgs/support-maintenance.svg"
                className="w-1/4"
                alt=""
              />
              <label className="w-11/12 ml-2 uppercase tracking-wider text-gray-700 text-2xl font-bold">
                {"{"}issue subject{"}"}
              </label>
            </div>
            <div className="w-[90%] mt-5 m-auto p-4 uppercase">
              <div className="flex items-center ">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    submitted:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    Date | time
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Priority:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Priority{"}"}
                  </p>
                </div>
                <div className="flex items-center w-1/5 ">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Status:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}status{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Ticket Number:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}AUTOGENERATED{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Assigned TO:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}assigned{"}"}
                  </p>
                </div>
                <div className="flex items-center w-1/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    costs:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}expenses{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    complaintant:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}complaintant{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    shared with:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}shared with{"}"}
                  </p>
                </div>
              </div>
              <div className="flex items-center ">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    location:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}location{"}"} | {"{"}Unit_location{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Permission to enter?:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                    {"{"}yes/no{"}"}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex items-center float-right ">
              <button className="btn btn3 mb-4">Add Reply</button>
            </div>
            <div className="w-[90%] mt-10 m-auto p-4 bg-cyan-100 rounded-xl uppercase">
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Reply:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  Date | time
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  From:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}Person_Replied{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Description:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Pictures:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Pictures{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Files:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Files{"}"}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[90%] mt-5 m-auto p-4 bg-teal-100 rounded-xl uppercase">
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Reply:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  Date | time
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  From:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}Person_Replied{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Description:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Pictures:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Pictures{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Files:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Files{"}"}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[90%] mt-5 m-auto p-4 bg-cyan-100 rounded-xl uppercase">
              <div className="flex items-center text-center ">
                <label className="block w-full tracking-wide text-gray-700 text-base font-bold">
                  ORiginal issue submitted
                </label>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  submitted:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  Date | time
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  complaintant:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}complaintant{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  subject:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}subject{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Description:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center">
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Pictures:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Pictures{"}"}
                  </p>
                </div>
                <div className="flex items-center w-2/5">
                  <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                    Files:
                  </label>
                  <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                    {"{"}Files{"}"}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex items-center w-full float-left mt-4 uppercase">
              <label className="block w-1/2 tracking-wide text-gray-700 text-xl font-bold">
                Add reply
              </label>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="flex-col items-center w-[90%] m-[auto] mt-5">
            <div className="flex items-center w-1/2 ">
              <img
                src="/assets/svgs/system-notes-and-files.svg"
                className="w-1/6"
                alt=""
              />
              <label className="w-11/12 ml-2 capitalize tracking-wider text-gray-700 text-2xl font-bold">
                internal notes and files:
              </label>
            </div>
            <div className="flex items-center mt-2 float-right  ">
              <button className="btn btn3">Add Notes and Files</button>
            </div>
            <div className="w-[90%] mt-14 m-auto p-4 bg-cyan-100 rounded-xl uppercase">
              <div className="flex items-center w-2/5">
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  Date | time
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  From:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}Person_Replied{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Notes:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  files:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}files{"}"}
                </p>
              </div>
            </div>
            <div className="w-[90%] mt-5 m-auto p-4 bg-teal-100 rounded-xl uppercase">
              <div className="flex items-center w-2/5">
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  Date | time
                </p>
              </div>
              <div className="flex items-center w-2/5">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  From:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base">
                  {"{"}Person_Replied{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  Notes:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}Description{"}"}
                </p>
              </div>
              <div className="flex items-center w-2/5 ">
                <label className="block w-1/2 tracking-wide text-gray-700 text-base font-bold">
                  files:
                </label>
                <p className="block w-1/2 tracking-wide text-gray-700 text-base ">
                  {"{"}files{"}"}
                </p>
              </div>
            </div>
            <hr />
            <div className="flex-col mt-8 w-full ">
              <label className="block tracking-wide uppercase text-gray-700 text-xl font-bold">
                add internal notes & files
              </label>
              <div className="w-10/12 m-auto mt-5">
                <CKEditor />
              </div>
            </div>
            <div className="flex items-center w-1/3 justify-between mt-5 ">
              <img src="/assets/svgs/system-files.svg" className="w-1/6" alt="" />
              <label className="block tracking-wide text-gray-700 text-base font-bold">
                Files:
              </label>
              <button className="btn btn3">Upload Files</button>
            </div>
            <div className="flex items-center float-right mt-4">
              <button className="btn btn3">Submit</button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="flex-col items-center w-[90%] m-[auto] mt-5">
            <div className="flex items-center w-1/3 ">
              <img
                src="/assets/svgs/finance-expense-ticket.svg"
                className="w-1/5"
                alt=""
              />
              <label className="w-11/12 ml-2 capitalize tracking-wider text-gray-700 text-2xl font-bold">
                issue expenses:
              </label>
            </div>
            <div className="flex items-center mt-2 float-right  ">
              <button className="btn btn3">Add Expense</button>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
