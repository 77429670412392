import { CKEditor } from 'ckeditor4-react';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useAlerts from '../../utility/alerts';
import ApiRequest from '../../utility/http';
import Loading from '../Loader/Loading';
export const AddAnApplicationForm = ({
    initialData = {},
}) => {
  // console.warn('initialData',initialData);
  const dispatch = useDispatch();
  const Alert = useAlerts();
  const { alertList } = useSelector((state) => state.alerts);
  const [yearData] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
  const [employerYearData] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
  const [minYearData] = useState(() => {
    const optionsArray = [];
    for (let i = 0; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
  function copyToClipboard(text) {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  const [copied, setCopied] = useState(false);
  const [loader, setLoader] = useState(undefined);
    const [formData,setFormData] = useState();
    console.log("formData",formData);
    const [editorData,setEditorData] = useState();
    console.log("EditoData",editorData);
    const [triggerSubmit, triggerFormSubmit] = useState(false);
    const [unit, setUnit] = useState();
    const [link, setLink] = useState();
    const formElement = document.getElementById("add-app-form");
    
    const formSubmitHandler = async (e) => {
      e.preventDefault();
      try {
        const res = await ApiRequest.post("/public/api/auth/application-link", {
          property_id: parseInt(localStorage.getItem("Property ID")),
          unit_id: formData?.unit_id,
          is_public : formData?.is_public,
          previous_housings_years: formData?.previous_housings_years,
          employers_years: formData?.employers_years,
          minimum_references: formData?.minimum_references,
          additional_information: editorData?.additional_information
        });
        console.log("Result formData", formData);
        if (res.status == 200) {
          console.log("Added");
          Alert.addSuccess("Application Added Successfully!")
          getLink();
        }
      } catch (err) {
        console.log(err);
        Alert.addFailure("Something Went Wrong!")
      }
    };
    const getLink = async()=>{
      const response = await ApiRequest.get(`/public/api/auth/get-application-link?unit_id=${formData?.unit_id}`);
      if(response.status === 200)
      {
        setLink(response?.data?.data);
      }
    }
    const getGeneralSetting= async()=>{
      setLoader(undefined);
        const response = await ApiRequest.get('/public/api/auth/get-applications-settings');
        console.log('res',response?.data?.data[0]?.previous_housings_years);
        if(response.status == 200)
        {
          setFormData({
            ...formData,previous_housings_years: response?.data?.data[0]?.previous_housings_years,
            ...formData,employers_years: response?.data?.data[0]?.employers_years,
            ...formData,minimum_references: response?.data?.data[0]?.minimum_references,
            ...formData,is_public:0,
          })
          setEditorData({...editorData,additional_information:response?.data?.data[0]?.additional_information})
          setLoader(response)
        }
    }
    const getUnit = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-my-units');
      if(response.status === 200)
      {
        setUnit(response?.data?.data);
      }
    }
    useEffect(() => {
        setFormData(initialData);
        setEditorData(initialData?.additional_information);
      }, [])
      
      
      useEffect(() => {
        const data = {...formData};
        if (JSON.stringify(data) !== JSON.stringify(initialData)) {
          console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }, [])
    
    useEffect(() => {

      getGeneralSetting();
      getUnit();
      getLink();
    }, [])
    
    
    return (
    <>
    {loader == undefined ? <Loading/> :
    <div>
      <form
      className="w-full"
      id="add-app-form"
      onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:w-8/12">
                  <div className="md:w-3/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Create Application for a currently Rented Unit?
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.enable_portal}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          enable_portal: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-4/12">
                  <div className="md:w-1/2">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Unit:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`units`}
                          value={formData?.unit_id}
                          onChange ={(e)=>{
                            setFormData({ ...formData, unit_id: parseInt(e.target.value) });
                          }}
                          >
                            <option value="">Select Unit</option>
                            {unit?.map((row) => (
                                <option value={row?.id}>{row?.name}</option>
                            ))}
                        </select>
                      </div>
                </div>
              </div>
            </div>
          </div>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:w-8/12">
                  <div className="md:w-3/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                     Add Application Publicly on Unit's Rent Blaze Listing?
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.is_public}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          is_public: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Years of Past Housing Information Required:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`past-housing-info`}
                          value={formData?.previous_housings_years}
                          onChange ={(e)=>{
                            setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                          }}
                          >
                            {yearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Years of Past Employer Information Required:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`past-employer-info`}
                          value={formData?.employers_years}
                          onChange ={(e)=>{
                            setFormData({ ...formData, employers_years: parseInt(e.target.value) });
                          }}
                          >
                            {employerYearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Minimum Required References:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`min-reference`}
                          value={formData?.minimum_references}
                          onChange ={(e)=>{
                            setFormData({ ...formData, minimum_references: parseInt(e.target.value) });
                          }}
                          >
                            {minYearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {/* <label className="block capitalize tracking-wide text-gray-700 font-bold mt-3">General Setting Instruction is :{editorData?.additional_information}.</label> */}
                    <div className="md:flex mt-5">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Additional Information Instructions:
									</label>
								</div>
								<div className="md:w-4/5">
									{/* <CKEditor
										initData={editorData?.additional_information}
										onChange={(e) => {
											setEditorData({...editorData,additional_information:e.editor.getData()});
										}}
									/> */}
                  <textarea
                    className="appearance-none block w-[90%] m-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="additional-info"
                    value={editorData?.additional_information?.replace(/(<([^>]+)>)/ig, '')}
                    placeholder="Unit #5"
                    onChange={(e) => {
                      setEditorData({...editorData,additional_information:e.target.value});
                    }}
                  />
								</div>
							</div>
              <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 mt-6">Submit</button>
              
              </form>
              <h6 className='text-gray-700 mt-4'>Link For the Application For this Unit:</h6>
              {link?.map((row, index) => (
                <div key={index} className="flex mt-6 w-full">
                  <div className='md:w-[90%]'>
                    <input type="text" className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={`http://portal.rentblaze.com/application/${row?.unique_id}`}  disabled={true}/>
                  </div>
                  <button
                    className='btn2 md:w-[20%]'
                    onClick={() => {
                      copyToClipboard(`http://portal.rentblaze.com/application/${row?.unique_id}`);
                      setCopied(true);
                      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
                    }}
                  >
                    {copied ? 'Copied!' : 'Copy Link'}
                  </button>
                </div>
              ))}
              
    </div>}
    </>
  )
}
