import { CKEditor } from "ckeditor4-react";
import React, { useState } from "react";
import Select from 'react-select';
import './select2.css';

export const AddCommunicationForm = () => {

  const options = [
    {
      label: 'Group 1',
      options: [
        { label: 'Option 1-1', value: '1-1' },
        { label: 'Option 1-2', value: '1-2' },
      ],
    },
    {
      label: 'Group 2',
      options: [
        { label: 'Option 2-1', value: '2-1' },
        { label: 'Option 2-2', value: '2-2' },
      ],
    },
    {
      label: 'Group 3',
      options: [
        { label: 'Option 3-1', value: '3-1' },
        { label: 'Option 3-2', value: '3-2' },
      ],
    },
  ];
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [group1SelectedOptions, setGroup1SelectedOptions] = useState([]);
  const [group2SelectedOptions, setGroup2SelectedOptions] = useState([]);
  const [group3SelectedOptions, setGroup3SelectedOptions] = useState([]);

  console.log(group1SelectedOptions)
  console.log(group2SelectedOptions)
  console.log(group3SelectedOptions)

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    // Check if each selected option is within each group
    const selectedOptionsInGroup1 = selectedOptions.filter(option => {
      const group1Option = options[0].options.find(
        group1Option => group1Option.value === option.value
      );
      return group1Option;
    });

    const selectedOptionsInGroup2 = selectedOptions.filter(option => {
      const group2Option = options[1].options.find(
        group2Option => group2Option.value === option.value
      );
      return group2Option;
    });

    const selectedOptionsInGroup3 = selectedOptions.filter(option => {
      const group3Option = options[2].options.find(
        group3Option => group3Option.value === option.value
      );
      return group3Option;
    });

    // Push selected options in each group into the corresponding array
    const group1OptionValues = selectedOptionsInGroup1.map(option => option.value);
    setGroup1SelectedOptions(group1OptionValues);

    const group2OptionValues = selectedOptionsInGroup2.map(option => option.value);
    setGroup2SelectedOptions(group2OptionValues);

    const group3OptionValues = selectedOptionsInGroup3.map(option => option.value);
    setGroup3SelectedOptions(group3OptionValues);
  };

  const filterOption = (option, inputValue) => {
    // Always show Group 1 options in the options list
    if (option.group === 'Group 1') {
      return true;
    }

    // Filter other options based on input value
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  return (
    <div>
      <form
        className="w-full"
        id="add-app-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center w-1/2 mt-5">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  To:
                </label>
              </div>
              <div className="md:w-4/5">
                <div>
                  <Select
                    options={options}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    isMulti
                    isSearchable
                    filterOption={filterOption} 
                  />
                    <div>Selected options in Group 1: {group1SelectedOptions.join(', ')}</div>
                    <div>Selected options in Group 2: {group2SelectedOptions.join(', ')}</div>
                    <div>Selected options in Group 3: {group3SelectedOptions.join(', ')}</div>

                   {/* <select onChange={(event) => setSelectedOption(event.target.value)}>
                      {options.map((option) => (
                        <option key={option.text} value={option.text}>
                          {option.text}
                        </option>
                      ))}
                    </select> */}
                </div>
              </div>
            </div>

            <div className="flex flex-row-reverse justify-between items-center mt-3">
              <div className="flex justify-between items-center gap-4">
                <button type="button" class="btn2">
                  Load Template
                </button>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-5">
              <div className="md:w-1/6">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Subject: <span>*</span>
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`past-housing-info`}
                  placeholder="Subject"
                  // value={formData?.previous_housings_years}
                  // onChange ={(e)=>{
                  //   setFormData({ ...formData, previous_housings_years: parseInt(e.target.value) });
                  // }}
                >
                  {/* {yearData?.map((year) => (
                              <option value={year}>{year}</option>
                          ))} */}
                </input>
              </div>
            </div>
            {/* <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Tokens:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    // initData={editorData?.other_info}
                    // onChange={(e) => {
                    //   setEditorData({
                    //     ...editorData,
                    //     other_info: e.editor.getData(),
                    //   });
                    // }}
                  />
                </div>
              </div> */}
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Message:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.other_info}
                // onChange={(e) => {
                //   setEditorData({
                //     ...editorData,
                //     other_info: e.editor.getData(),
                //   });
                // }}
                />
              </div>
            </div>
            <div className="flex md:items-center md:justify-start md:w-6/12 mt-3">
              <div className="md:w-3/4 flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Files:
                </label>
              </div>
              <div className="md:w-3/4">
                <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                    Upload Files
                  </span>
                  <input
                    id={`communication-files`}
                    type="file"
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    multiple={true}
                    hidden={true}
                    //   onChange={(e) => {
                    //     let files = e.target.files;
                    //     files.forEach((file) => {
                    //       const reader = new FileReader();
                    //       reader.addEventListener("load", async () => {
                    //         const fileBase64 = reader.result;
                    //         try {
                    //           console.log(fileBase64);
                    //           const res = await ApiRequest.post(fileApi, {
                    //             file: fileBase64,
                    //           });
                    //           // console.log("res", res?.data?.data?.file);
                    //           // console.log("index",index);
                    //           // let index = [index];
                    //           // [...index].sort()

                    //           tenantInteraction[index].files.push({
                    //             file: res?.data?.data?.file,
                    //           });
                    //           setFormData({
                    //             ...formData,
                    //             interactions: interactions,
                    //           });

                    //           // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                    //           // console.warn("base64converted files",formData)
                    //         } catch (err) {
                    //           console.log(err);
                    //         }
                    //       });
                    //       reader.readAsDataURL(file);
                    //     });
                    //     const fileLists = [...tenantInteractionFiles];
                    //     fileLists[index] = getFilesAndURLs(e);
                    //     setTenantInteractionFiles(fileLists);
                    //     // propertyMeasurements[index].files =
                    //     // 		getFilesAndURLs(e)
                    //     // setFormData({...formData, measurements : measurements})
                    //   }}
                  />
                </label>
              </div>
              <div className="md:w-1/2">
                <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  {/* <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantInteractionFiles[index]?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span> */}
                </label>
              </div>
            </div>

            {/* <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                    {tenantInteractionFiles[index]?.length ? (
                      <div>
                        <section className="overflow-hidden w-full text-gray-700 mt-4">
                          <div className="container w-full px-3 m-auto">
                            <div
                              id={`p-tenant-documents-block-${index}`}
                              className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                            >
                              {tenantInteractionFiles[index].map(
                                (fileObject, fileIndex) =>
                                  fileComponent({
                                    file: fileObject?.file,
                                    key: fileIndex,
                                    inputFieldId: `t-tenant-files-${index}`,
                                    trashAble: true,
                                    onTrashClick: () => {
                                      const fileLists = [
                                        ...tenantInteractionFiles,
                                      ];
                                      fileLists[index].splice(fileIndex, 1);
                                      interactions[index].files = fileLists;
                                      setTenantInteractionFiles(fileLists);
                                      setFormData({
                                        ...formData,
                                        interactions: interactions,
                                      });
                                    },
                                  })
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                    ) : null}
                  </div> */}
          </div>
        </div>

        <div className="flex flex-row-reverse justify-between items-center mt-3">
          <div className="flex justify-between items-center gap-4">
            <button type="button" class="btn2">
              Save as Template
            </button>
            <button type="button" class="btn2">
              Save as Draft
            </button>
            <button type="button" class="btn2 px-5">
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
