import React from 'react'

export const Tooltip = ({data}) => {
  return (
    <div className='custom2-tooltip'>
        <img
                className="logo w-4 h-4 ml-1 custom-tooltip"
                src="/assets/img/system-tooltip.png"
                alt=""
              />
        <div class="callout">
                <div class="callout-content">
                  <p>{data}</p>
                </div>
              </div>
    </div>
  )
}
