import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import "../../components/button.css";
// import { PDFExport } from 'react-pdf/renderer'
// import Loading from "../Loader/Loading";
// import Loading from "../Loader/Loading";
import { Link, useParams } from "react-router-dom";
import { style } from "@mui/system";
import $ from "jquery";
import Swal from "sweetalert2";
import Spinner from "../Spinner/Spinner";
import { Tooltip } from "../Loader/Tooltip";

// import { pdf } from "@react-pdf/renderer";
const ParkingSpaceTable = ({data,handleTrue,hiddenData,id}) => {
  const initialParkingData = {
    space_number: "",
    parking_space_prefix_id: '',
    parking_space_suffix_id: '',
    compact: 0,
    tandem: 0,
    electric_vehicle_charging: 0,
    handicap: 0,
  }
  const initialBulkData = {
    space_add:"",
    starting_number:"",
  }
  const [editSpace,setEditSpace ]= useState();
  const [indexEdit,setIndexEdit ]= useState();
  const [disabled,setDisabled] = useState(hiddenData);
  console.log('editSpace',editSpace);
  console.log('indexEdit',indexEdit);
  const [bulkSpaces, setBulkSpaces] = useState([]);
  const [parkingSpace, setParkingSpace] = useState([]);
  const [myArray, setMyArray] = useState([]);
  const [parkingSpaces, setParkingSpaces] = useState([]);
  const [filterSpaces, setFilterSpaces] = useState([]);
  console.warn('@filterSpaces',filterSpaces);
  
  const bulkData = () => {
    const newDataArray = [];
    const parkingSpaceNumber = parkingSpace?.space_number != "" ? parkingSpace?.space_number : bulkSpaces?.starting_number;
    setParkingSpace({ ...parkingSpace, space_number: parkingSpaceNumber });
  
    for (let i = 0; i < bulkSpaces?.spaces_add; i++) {
      const spaceNumber = i === 0 ? parkingSpaceNumber : parkingSpaceNumber + i; // Use strict equality operator (===) instead of ==
      const newParkingSpace = { ...parkingSpace, space_number: parseInt(spaceNumber) };
      newDataArray.push(newParkingSpace);
    }
  
    setParkingSpaces((prevArray) => [...prevArray, ...newDataArray]); // Append newDataArray to prevArray when setting parking spaces
    setFilterSpaces((prevArray) => [...prevArray, ...newDataArray]); // Append newDataArray to prevArray when setting filter spaces
    setBulkSpaces(initialBulkData);
    setParkingSpace(initialParkingData);
    setLoader("response");
  };
  
  
  const showCompact = ()=>{
    setLoader(undefined);
    const filteredData = parkingSpaces?.filter(obj => obj.compact == 1);
    setFilterSpaces(filteredData);
    setTimeout(() => {
      setLoader("response");
    }, 3000);
  }
  const showTandem = ()=>{
    const filteredData = parkingSpaces?.filter(obj => obj.tandem == 1);
    setFilterSpaces(filteredData);
    setTimeout(() => {
      setLoader("response");
    }, 3000);
  }
  const showEV = ()=>{
    const filteredData = parkingSpaces?.filter(obj => obj.electric_vehicle_charging == 1);
    setFilterSpaces(filteredData);
    setTimeout(() => {
      setLoader("response");
    }, 3000);
  }
  const showHandicap = ()=>{
    const filteredData = parkingSpaces?.filter(obj => obj.handicap == 1);
    setFilterSpaces(filteredData);
    setTimeout(() => {
      setLoader("response");
    }, 3000);
  }
  const showRegular = ()=>{
    const filteredData = parkingSpaces?.filter(obj => obj.handicap == 0 && obj.tandem == 0 && obj.electric_vehicle_charging == 0 && obj.compact == 0);
    setFilterSpaces(filteredData);
    setTimeout(() => {
      setLoader("response");
    }, 3000);
  }

  
  handleTrue(parkingSpaces);
  const [parkingSpacePrefix, setParkingSpacePrefix] = useState();
  const [selectFilter,setSelectFilter] = useState();
  const [parkingSpaceSuffix, setParkingSpaceSuffix] = useState([]);
  const [history, setHistory] = useState([]);
  const [loader, setLoader] = useState();
  const [bulkAction , setBulkAction] = useState({
    bulk_action:""
  });

  const getParkingSpacePrefixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-prefixes"
    );
    if (response.status == 200) {
      setParkingSpacePrefix(response?.data?.data?.prefixes);
      setLoader("response");
    }
  };
  const getAssignHistory = async(id)=>{
    const response = await ApiRequest.get(`/public/api/auth/get-parking-space-history?parking_space_id=${id}`)
    if(response?.status == 200)
    {
      setHistory(response?.data?.data);
      
    }
  }
  const showData = async()=>{
    setLoader(undefined)
    const response = await ApiRequest.get(`/public/api/auth/get-parking-spaces-by-property?property_id=${id}`)
    if(response?.status == 200)
    {
      setParkingSpaces(response?.data?.data)
      setLoader("response");
    }
  }
  const getParkingSpaceSuffixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-suffixes"
    );
    if (response.status == 200) {
      setParkingSpaceSuffix(response?.data?.data?.suffixes);
    }
  };
  const [dataTableKey, setDataTableKey] = useState(0);

  const filtration = (data) => {
    console.warn("datajashdkjha", data);
    console.warn("dataTableKey", dataTableKey);
    console.warn("datajashdkjhaLoader", loader);
    setLoader(undefined);
  
    if (data === "Compact") {
      showCompact();
    } else if (data === "Tandem") {
      showTandem();
    } else if (data === "Handicap") {
      showHandicap();
    } else if (data === "EV") {
      showEV();
    } else if (data === "Regular") {
      showRegular();
    } else {
      setFilterSpaces(parkingSpaces);
      setTimeout(() => {
        setLoader("response");
      }, 3000);
    }
  

  };
  
    useEffect(() => {
  filtration(selectFilter);
  setDataTableKey((prevKey) => prevKey + 1);
}, [selectFilter]);

  const [allUnit,setAllUnit] = useState([]);
  const [allTenant,setAllTenant] = useState([]);
  const getAllTenant=async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-my-tenants')
    if(response?.status == 200)
    {
      setAllTenant(response?.data?.data?.tenants)
    }
  }
  const getAllUnit=async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-my-units')
    if(response?.status == 200)
    {
      setAllUnit(response?.data?.data)
    }
  }
  const exportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Parking Spaces";
    const headers = [["Space", "Compact","Tandem","EV","Handicap","Assignee"]];

    const data = parkingSpaces?.map((elt) => [
      parkingSpacePrefix?.map((row)=>{
        if(elt.parking_space_prefix_id == row?.id)
        {
          return row?.prefix;
        }
      }) + elt.space_number+ parkingSpaceSuffix?.map((row)=>{
        if(elt.parking_space_suffix_id == row?.id)
        {
          return row?.suffix;
        }
      }),
      elt.compact === 0 ? "No" : "Yes",
      elt.tandem === 0 ? "No" : "Yes",
      elt.electric_vehicle_charging === 0 ? "No" : "Yes",
      elt.handicap === 0 ? "No" : "Yes",
      elt.assigned_to?.map((row) => {
        return row?.tenant_id === null ? row?.unit_name : `${row?.firstname} ${row?.lastname}`;
      }).join(", "), // Join multiple assigned tenants with a comma
    ]);
    

    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Property Parking Spaces.pdf")
  }
  const exportCSV = () => {
    // Get data from data table
    const TotalData = parkingSpaces?.map((elt) => [
      parkingSpacePrefix?.map((row)=>{
        if(elt.parking_space_prefix_id == row?.id)
        {
          return row?.prefix;
        }
      }) + elt.space_number+ parkingSpaceSuffix?.map((row)=>{
        if(elt.parking_space_suffix_id == row?.id)
        {
          return row?.suffix;
        }
      }),
      elt.compact === 0 ? "No" : "Yes",
      elt.tandem === 0 ? "No" : "Yes",
      elt.electric_vehicle_charging === 0 ? "No" : "Yes",
      elt.handicap === 0 ? "No" : "Yes",
      elt.assigned_to?.map((row) => {
        return row?.tenant_id === null ? row?.unit_name : `${row?.firstname} ${row?.lastname}`;
      }).join(", "), // Join multiple assigned tenants with a comma
    ]);
    // Convert the data to a CSV string

    const headers = ["Space", "Compact","Tandem","EV","Handicap","Assignee"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Property Parking Spaces.csv");
    link.click();
  };
  //   const dispatch = useDispatch();
  //   const allProperties = useSelector(
  //     (state) => state.properties.properties.data
  //   );
  //   const loading = useSelector((state) => state.properties.status);
  //   const allPropertiesTypes = useSelector(
  //     (state) => state.properties.propertiesTypes.data
  //   );
  useEffect(() => {
    getParkingSpacePrefixes();
    getParkingSpaceSuffixes();
    setParkingSpaces(data);
    setParkingSpace(initialParkingData)
    setBulkSpaces(initialBulkData)
    getAllTenant();
    getAllUnit();
    // editSpacesData();
  }, []);
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  const editSpacesData = () =>{
    const array = [...parkingSpaces];
    array[indexEdit] = editSpace;
    setParkingSpaces(array);
    handleTrue(array);
  }
  const indexFind = (index)=>
  {
    // var index = $(this).attr('data-index');
    // var modal = $(this).attr('data-bs-toggle');
    setDisabled(false);
    const findIndex = parkingSpaces.length -1 - index ;
    setIndexEdit(index);
    setEditSpace(parkingSpaces[index]);
    console.log("index",findIndex);
    // console.log("modal",modal);
  }
  const viewData = (index)=>{
    // setShowForm(false);
    getAssignHistory(parkingSpaces[index]?.id)
    setDisabled(true);
    setEditSpace(parkingSpaces[index]);
  }
  const deleteData = (index)=>{
    // setShowForm(false);
      // setViewForm(false);
    const newArray = [...parkingSpaces];
    newArray.splice(index, 1);
    setParkingSpaces(newArray);
    setParkingSpace(initialParkingData)
    handleTrue(newArray);
  }

  const bulkDeleteData = () => {
    const newArray = [...parkingSpaces];
    bulkIndex.sort((a, b) => b - a);
    bulkIndex.forEach((index) => newArray.splice(index, 1));
    setParkingSpaces(newArray);
    setParkingSpace(initialParkingData);
    setSelectedRows([]);
    setBulkIndex([]);
  };
  //   const newArray1 = allProperties?.map((a) => ({ ...a }));
  //   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));

  const columns = [
    {
      name: "Space",
      selector: (data) => {
        const prefix = parkingSpacePrefix?.find((prefix) => data?.parking_space_prefix_id == prefix?.id)?.prefix ?? "";
        const spaceNumber = data?.space_number !== "" ? data?.space_number : "";
        const suffix = parkingSpaceSuffix?.find((suffix) => data?.parking_space_suffix_id == suffix?.id)?.suffix ?? "";
    
        return prefix + spaceNumber + suffix;
      },
      sortable: true
    },    
    {
      name: "Compact",
      selector: (data) => data.compact == 1 ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-remove text-red-600"></i>,
      sortable: true,
    },
    {
      name: "Tandem",
      selector: (data) => data.tandem == 1 ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-remove text-red-600"></i>,
      sortable: true,
    },
    {
      name: "EV",
      selector: (data) => data.electric_vehicle_charging == 1 ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-remove text-red-600"></i>,
      sortable: true,
    },
    {
      name: "Handicap",
      selector: (data) => data.handicap == 1 ? <i className="fa fa-check text-green-600"></i> : <i className="fa fa-remove text-red-600"></i>,
      sortable: true,
    },
    {
      name: "Assignee",
      selector: (data) =>
        data?.assigned_to
          ?.map((row) => {
            return row?.tenant_id == null ? row?.unit_name : row?.firstname +" "+row?.lastname;
          })
          .join(", "),
      sortable: true,
    },
    
    // {
    //   name: "Assignee",
    //   selector : (data) => 'UnAssign'
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Options",
      cell: (row,index) => {
        return (
          <>
            {hiddenData != true ?
            (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewstaticBackdrop`}
                data-bs-toggle="modal"
                title="View"
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mx-2"
                data-bs-target={`#staticBackdrop`}
                data-bs-toggle="modal"
                data-index={index}
                title="Edit"
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                {row?.is_assigned == 1 ?
                <button type="button" class="bg-transparent border-none md:w-1/6 p-0 "
                title="Unassign"
                // data-bs-target={`#staticBackdrop`}
                // data-index={index}
                onClick={(e)=>{
                  unAssignData(row?.id);
                }}
                >
    
                  <img src='/assets/svgs/system-unassign.svg' />
                  
                </button>:
                <button type="button" class="bg-transparent border-none md:w-1/6 p-0 "
                // data-bs-target={`#staticBackdrop`}
                // data-index={index}
                title="Assign"
                onClick={(e)=>{
                  assignData(row?.id);
                }}
                >
    
                  <img src='/assets/svgs/system-assign.svg' />
                  
                </button>}
                <button type="button" class="bg-transparent border-none md:w-1/6 p-0 mx-2"
                // data-bs-target={`#staticBackdrop`}
                title="Delete"
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
                
               
               
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewstaticBackdrop`}
                data-bs-toggle="modal"
                title="View"
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
            {/* <div className="md:w-1/12">
            <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
              <i class="fa-regular fa-eye mr-6"></i>
            </button>
            </div> */}
          </>
        );
      },
    },
  ];
  const tableData = {
    columns : columns,
    data : filterSpaces,
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkIndex, setBulkIndex] = useState([]);
  console.log("@selectedRows",selectedRows);
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  
    const allIndex = state.selectedRows.map((selectedRow) => {
      return parkingSpaces.findIndex((row) => row.space_number === selectedRow.space_number);
    });
    setBulkIndex(allIndex);
    // console.log('@Selected row indexes:', allIndex);
  };
  


  const bulkExportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Parking Spaces";
    const headers = [["Space", "Compact","Tandem","EV","Handicap","Assignee"]];

    const data = selectedRows?.map((elt) => [
      parkingSpacePrefix?.map((row)=>{
        if(elt.parking_space_prefix_id == row?.id)
        {
          return row?.prefix;
        }
      }) + elt.space_number+ parkingSpaceSuffix?.map((row)=>{
        if(elt.parking_space_suffix_id == row?.id)
        {
          return row?.suffix;
        }
      }),
      elt.compact === 0 ? "No" : "Yes",
      elt.tandem === 0 ? "No" : "Yes",
      elt.electric_vehicle_charging === 0 ? "No" : "Yes",
      elt.handicap === 0 ? "No" : "Yes",
      elt.assigned_to?.map((row) => {
        return row?.tenant_id === null ? row?.unit_name : `${row?.firstname} ${row?.lastname}`;
      }).join(", "), // Join multiple assigned tenants with a comma
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Property Parking Spaces.pdf")
  }
  const bulkExportCSV = () => {
    // Get data from data table
    const TotalData = selectedRows?.map((elt) => [
      parkingSpacePrefix?.map((row)=>{
        if(elt.parking_space_prefix_id == row?.id)
        {
          return row?.prefix;
        }
      }) + elt.space_number+ parkingSpaceSuffix?.map((row)=>{
        if(elt.parking_space_suffix_id == row?.id)
        {
          return row?.suffix;
        }
      }),
      elt.compact === 0 ? "No" : "Yes",
      elt.tandem === 0 ? "No" : "Yes",
      elt.electric_vehicle_charging === 0 ? "No" : "Yes",
      elt.handicap === 0 ? "No" : "Yes",
      elt.assigned_to?.map((row) => {
        return row?.tenant_id === null ? row?.unit_name : `${row?.firstname} ${row?.lastname}`;
      }).join(", "), // Join multiple assigned tenants with a comma
    ]);   // Convert the data to a CSV string

    const headers = ["Space", "Compact","Tandem","EV","Handicap","Assignee"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Property Parking Spaces.csv");
    link.click();
  };
  const bulkUnassign = async () => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Unassign</strong> the Parking Space/Parking Spaces. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, Inactivate the property
    const arrayId = []
    selectedRows?.map((row)=>{
      arrayId.push(row.id);
    })
    // const commaSeparatedIds = arrayId.join(',');
    console.log("ARRAYID",arrayId);
    if (isConfirmed) {
      // setLoading('loading');
      try {
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.post('/public/api/auth/bulk-unassign-parking-spaces',{
          parking_spaces_ids:arrayId,
      });
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Parking Space/Parking Spaces have been <strong>Unassigned</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Unassign</strong> the Parking Space/Parking Spaces. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        // setLoading('');
      }
    }
  };
  const assignData = async (id) => {
    console.warn("IDIDIDI",id);
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: `
        <div>
          <label>
            <input type="radio" name="selection" value="tenant" />
            Tenant
          </label>
          <select id="tenantSelect" class="swal2-select" style="display: none;" disabled>
            <option value="">Select Tenant</option>
            ${allTenant?.map((row) => (
              `<option value="${row?.id}">${row?.firstname}</option>`
            ))}
          </select>
        </div>
        <div>
          <label>
            <input type="radio" name="selection" value="unit" />
            Unit
          </label>
          <select id="unitSelect" class="swal2-select" style="display: none;" disabled>
            <option value="">Select Unit</option>
            ${allUnit?.map((row) => (
              `<option value="${row?.id}">${row?.name}</option>`
            ))}
          </select>
        </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      didOpen: () => {
        const radioButtons = document.querySelectorAll('input[name="selection"]');
        const tenantSelect = document.getElementById('tenantSelect');
        const unitSelect = document.getElementById('unitSelect');
  
        radioButtons.forEach((radioButton) => {
          radioButton.addEventListener('change', () => {
            if (radioButton.value === 'tenant') {
              tenantSelect.style.display = 'block';
              tenantSelect.disabled = false;
              unitSelect.style.display = 'none';
              unitSelect.disabled = true;
            } else if (radioButton.value === 'unit') {
              tenantSelect.style.display = 'none';
              tenantSelect.disabled = true;
              unitSelect.style.display = 'block';
              unitSelect.disabled = false;
            }
          });
        });
      },
      customClass: {
        confirmButton: 'btn3',
        cancelButton: 'swal2-cancel-button',
        actions: 'swal2-actions',
        content: 'swal2-content',
        popup: 'swal2-popup',
        title: 'swal2-title',
      },
    });
  
    if (isConfirmed) {
      const selectedRadioButton = document.querySelector('input[name="selection"]:checked');
      const tenantSelect = document.getElementById('tenantSelect');
      const unitSelect = document.getElementById('unitSelect');
  
      const selectedValue = selectedRadioButton ? selectedRadioButton.value : null;
      const selectedTenantId = selectedValue === 'tenant' ? tenantSelect.value : null;
      const selectedUnitId = selectedValue === 'unit' ? unitSelect.value : null;
  
      // Make the API request
      try {
        if(id == undefined || id == null)
        {
          Swal.fire({
            title:"Please Save the Information Before Assigning it",
            icon:"info",
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn3',
            },
          })
        }
        else{
          const response = await ApiRequest.post('/public/api/auth/assign-single-parking-space', {
          property_parking_space_id: id,
          tenant_id: parseInt(selectedTenantId) || null,
          unit_id: parseInt(selectedUnitId) || null,
        });
  
        
        // Show success message
        if (response.status === 200) {
          showData();
          Swal.fire({
            title: 'Success!',
            html: 'The Parking Space has been <strong>Assigned</strong>.',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn3',
            },
          });
        }}
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Assigning</strong> the Parking Space. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn3',
          },
        });
      }
    }
  };
  
  
  
  
  
  const unAssignData = async (id) => {
    // Show confirmation dialog
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Unassign</strong> the Parking Space. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, Inactivate the property
    const arrayId = []
    // selectedRows?.map((row)=>{
      arrayId.push(id);
    // })
    // const commaSeparatedIds = arrayId.join(',');
    console.log("ARRAYID",arrayId);
    if (isConfirmed) {
      // setLoading('loading');
      try {
        if(id == undefined || id == null)
        {
          Swal.fire({
            title:"Please Save the Information Before Unassign it",
            icon:"info",
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn3',
            },
          })
        }
        else {

        
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.post('/public/api/auth/bulk-unassign-parking-spaces',{
          parking_spaces_ids:arrayId,
      });
  
        // Show success message
        if(response.status == 200)
        {
          showData();
          Swal.fire({
          title: 'Success!',
          html: 'The Parking Space have been <strong>Unassigned</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
    }
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Unassign</strong> the Parking Space. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        // setLoading('');
      }
    }
  };


  const totalCompact = parkingSpaces?.map(row=>row.compact).filter(compact=>compact == 1).length;
  const totalAssign = parkingSpaces?.map(row=>row.is_assigned).filter(is_assigned=>is_assigned == 1).length;
  const totalUnassign = parkingSpaces?.map(row=>row.is_assigned).filter(is_assigned=>is_assigned == 0).length;
  const totalTandem = parkingSpaces?.map(row=>row.tandem).filter(tandem=>tandem == 1).length;
  const totalHandicap = parkingSpaces?.map(row=>row.handicap).filter(handicap=>handicap == 1).length;
  const totalEV = parkingSpaces?.map(row=>row.electric_vehicle_charging).filter(electric_vehicle_charging=>electric_vehicle_charging == 1).length;

  return (
    <>
     <div
              class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
              id={`staticBackdrop`}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog relative w-auto pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5
                      class="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModalLabel"
                    >
                      Edit
                    </h5>
                    <button
                      type="button"
                      class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ><i className='fa fa-remove'></i></button>
                  </div>
                  <div class="modal-body relative p-4">
                   
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space prefix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-prefix`}
                      value={editSpace?.parking_space_prefix_id}
                      required={false}
                      disabled={disabled}
                      placeholder="12"
                      onChange={(e) => {
                        // spaces[index].parking_space_prefix_id = parseInt(e.target.value)
                        setEditSpace({...editSpace,parking_space_prefix_id: parseInt(e.target.value)})
                        // setSpaceData({parkingSpaces:parkingSpaces,parking_space_prefix_id : parseInt(e.target.value)})
                        // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Prefix</option>
                       {parkingSpacePrefix?.map((row) => (
                        <option value={row?.id}>{row?.prefix}</option>
                      ))}
                    </select>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-prefix`}
											type="text"
											value={parkingSpace?.parking_space_prefix_id}
											placeholder="456"
											onChange={(e) => {
												// parkingSpace.parking_space_prefix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_prefix_id : e.target.value})
												// setParkingSpaces(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
                
              </div>
              <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Number:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-number`}
                      type="number"
                      value={editSpace?.space_number}
                      disabled={disabled}
                      placeholder="456"
                      onChange={(e) => {
                      //   // row.space_number = e.target.value
                      //   // parkingSpaces.space_number = e.target.value
                      //   spaces[index].space_number = e.target.value
                      setEditSpace({...editSpace,space_number:parseInt(e.target.value)})
                      //   setParkingSpaces(spaces)
                      //   // setSpaceData({parkingSpaces:parkingSpaces,space_number : parseInt(e.target.value)})
                      //   // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                        // parkingSpaces:parkingSpaces.push(parkingSpace)
                        // setFormData({...formData,parking_spaces:parking_spaces})
                     
                    />
                  </div>
                </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Suffix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-suffix`}
                      type="text"
                      value={editSpace?.parking_space_suffix_id}
                      disabled={disabled}
                      required={false}
                      placeholder="12"
                      onChange={(e) => {
                      //   // parkingSpace.parking_space_suffix_id = e.target.value
                      //   // row.parking_space_suffix_id = parseInt(e.target.value) 
                      //   // parkingSpaces.parking_space_suffix_id = parseInt(e.target.value) 
                      //   spaces[index].parking_space_suffix_id = parseInt(e.target.value)
                      //   setParkingSpaces(spaces)
                      setEditSpace({...editSpace,parking_space_suffix_id: parseInt(e.target.value)})
                      //   // setSpaceData({parkingSpaces:parkingSpaces,parking_space_suffix_id : parseInt(e.target.value)})
                      //   // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Suffix</option>
                      {parkingSpaceSuffix?.map((row) => (
                        <option value={row?.id}>{row?.suffix}</option>
                      ))}
                    </select>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-suffix`}
											type="text"
											placeholder="456"
											value={parkingSpace?.parking_space_suffix_id}
											onChange={(e) => {
												// parkingSpace.parking_space_suffix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_suffix_id : e.target.value})
												// parkingSpaces:parkingSpaces.push(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
                </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`compact`}
                    type="checkbox"
                    checked={editSpace?.compact === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,compact: checkedValue})
                      // spaces[index].compact =checkedValue
                      //   setParkingSpaces(spaces)
                      // parkingSpaces.compact = checkedValue
                      // setSpaceData({parkingSpaces:parkingSpaces,compact : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    COMPACT
                  </label>
                </div>
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`tandem`}
                    type="checkbox"
                    checked={editSpace?.tandem === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,tandem: checkedValue})
                      // row.tandem = checkedValue
                      // parkingSpaces.tandem = checkedValue
                      // spaces[index].tandem =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,tandem : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    TANDEM
                  </label>
                </div>
                
              </div>
              <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/2 form-check ">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`electric_vehicle_charging`}
                    type="checkbox"
                    checked={editSpace?.electric_vehicle_charging === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,electric_vehicle_charging: checkedValue})
                      // row.electric_vehicle_charging = checkedValue
                      // parkingSpaces.electric_vehicle_charging = checkedValue
                      // spaces[index].electric_vehicle_charging =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,electric_vehicle_charging : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    ELECTRIC VEHICLE CHARGING
                  </label>
                </div>
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`handicap`}
                    type="checkbox"
                    // value={parkingSpace?.handicap}
                    checked={editSpace?.handicap === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,handicap: checkedValue})
                      // row.handicap = checkedValue
                      // parkingSpaces.handicap = checkedValue
                      // spaces[index].handicap =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,handicap : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    HANDICAP
                  </label>
                </div>
                </div>
            </div>
          </div>
                  </div>
                  <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    {disabled == false ? 
                    <button
                      type="button"
                      class="btn3"
                      data-bs-dismiss="modal"
                      onClick={editSpacesData}
                      // disabled={disabled}
                    >
                      Update
                    </button>
                    :
                    <button
                      type="button"
                      class="btn3"
                      data-bs-dismiss="modal"
                      onClick={()=>{
                        setDisabled(false);
                      }}
                      // disabled={disabled}
                    >
                      Close
                    </button>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
     <div
              class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
              id={`ViewstaticBackdrop`}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="ViewstaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog relative w-auto pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5
                      class="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModalLabel"
                    >
                      View
                    </h5>
                    <button
                      type="button"
                      class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>{
                        setHistory([]);
                      }}
                    ><i className='fa fa-remove'></i></button>
                  </div>
                  <div class="modal-body relative p-4">
                   
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space prefix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    {/* <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-prefix`}
                      value={editSpace?.parking_space_prefix_id}
                      required={false}
                      disabled={disabled}
                      placeholder="12"
                      onChange={(e) => {
                        // spaces[index].parking_space_prefix_id = parseInt(e.target.value)
                        setEditSpace({...editSpace,parking_space_prefix_id: parseInt(e.target.value)})
                        // setSpaceData({parkingSpaces:parkingSpaces,parking_space_prefix_id : parseInt(e.target.value)})
                        // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Prefix</option>
                       {parkingSpacePrefix?.map((row) => (
                        <option value={row?.id}>{row?.prefix}</option>
                      ))}
                    </select> */}
                    <span>{parkingSpacePrefix?.map((row)=>{
                      if(editSpace?.parking_space_prefix_id == row?.id)
                      {
                        return row?.prefix
                      }
                    })}</span>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-prefix`}
											type="text"
											value={parkingSpace?.parking_space_prefix_id}
											placeholder="456"
											onChange={(e) => {
												// parkingSpace.parking_space_prefix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_prefix_id : e.target.value})
												// setParkingSpaces(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
                
              </div>
              <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Number:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    {/* <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-number`}
                      type="text"
                      value={editSpace?.space_number}
                      disabled={disabled}
                      placeholder="456"
                      onChange={(e) => {
                      //   // row.space_number = e.target.value
                      //   // parkingSpaces.space_number = e.target.value
                      //   spaces[index].space_number = e.target.value
                      setEditSpace({...editSpace,space_number:e.target.value})
                      //   setParkingSpaces(spaces)
                      //   // setSpaceData({parkingSpaces:parkingSpaces,space_number : parseInt(e.target.value)})
                      //   // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                        // parkingSpaces:parkingSpaces.push(parkingSpace)
                        // setFormData({...formData,parking_spaces:parking_spaces})
                     
                    /> */}
                    <span>{editSpace?.space_number}</span>
                  </div>
                </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-full mb-6">
                  <div className="md:w-1/2">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Suffix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    {/* <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-suffix`}
                      type="text"
                      value={editSpace?.parking_space_suffix_id}
                      disabled={disabled}
                      required={false}
                      placeholder="12"
                      onChange={(e) => {
                      //   // parkingSpace.parking_space_suffix_id = e.target.value
                      //   // row.parking_space_suffix_id = parseInt(e.target.value) 
                      //   // parkingSpaces.parking_space_suffix_id = parseInt(e.target.value) 
                      //   spaces[index].parking_space_suffix_id = parseInt(e.target.value)
                      //   setParkingSpaces(spaces)
                      setEditSpace({...editSpace,parking_space_suffix_id: parseInt(e.target.value)})
                      //   // setSpaceData({parkingSpaces:parkingSpaces,parking_space_suffix_id : parseInt(e.target.value)})
                      //   // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Suffix</option>
                      {parkingSpaceSuffix?.map((row) => (
                        <option value={row?.id}>{row?.suffix}</option>
                      ))}
                    </select> */}
                    <span>{parkingSpaceSuffix?.map((row)=>{
                      if(editSpace?.parking_space_suffix_id == row?.id)
                      {
                        return row?.suffix
                      }
                    })}</span>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-suffix`}
											type="text"
											placeholder="456"
											value={parkingSpace?.parking_space_suffix_id}
											onChange={(e) => {
												// parkingSpace.parking_space_suffix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_suffix_id : e.target.value})
												// parkingSpaces:parkingSpaces.push(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
                </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`compact`}
                    type="checkbox"
                    checked={editSpace?.compact === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,compact: checkedValue})
                      // spaces[index].compact =checkedValue
                      //   setParkingSpaces(spaces)
                      // parkingSpaces.compact = checkedValue
                      // setSpaceData({parkingSpaces:parkingSpaces,compact : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    COMPACT
                  </label>
                </div>
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`tandem`}
                    type="checkbox"
                    checked={editSpace?.tandem === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,tandem: checkedValue})
                      // row.tandem = checkedValue
                      // parkingSpaces.tandem = checkedValue
                      // spaces[index].tandem =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,tandem : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    TANDEM
                  </label>
                </div>
                
              </div>
         
              <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/2 form-check ">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`electric_vehicle_charging`}
                    type="checkbox"
                    checked={editSpace?.electric_vehicle_charging === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,electric_vehicle_charging: checkedValue})
                      // row.electric_vehicle_charging = checkedValue
                      // parkingSpaces.electric_vehicle_charging = checkedValue
                      // spaces[index].electric_vehicle_charging =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,electric_vehicle_charging : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    ELECTRIC VEHICLE CHARGING
                  </label>
                </div>
                <div className="md:w-1/2 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`handicap`}
                    type="checkbox"
                    // value={parkingSpace?.handicap}
                    checked={editSpace?.handicap === 1}
                    disabled={disabled}
                    onChange={(e) => {
                      // parkingSpace.parking_space_prefix_id = e.target.value
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setEditSpace({...editSpace,handicap: checkedValue})
                      // row.handicap = checkedValue
                      // parkingSpaces.handicap = checkedValue
                      // spaces[index].handicap =checkedValue
                      //   setParkingSpaces(spaces)
                      // setSpaceData({parkingSpaces:parkingSpaces,handicap : checkedValue})
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    HANDICAP
                  </label>
                </div>
                </div>
                <hr />
                {history?.map((history)=>(
                <>
                
                <h6>Assignee history</h6>
                <div className="md:flex md:items-center mt-3">
                    <h6>{editSpace?.space_number}</h6>
                </div>
                <div className="md:flex md:items-center mt-3">

                    <span>{history?.start_date} - {history?.end_date == "" ? "Present" : history?.end_date}</span>
                </div>
                <div className="md:flex md:items-center mt-3">

                    <span>{history?.firstname} {history?.lastname}</span>
                </div>
                    <hr />
                </>))}
              
            </div>
          </div>
                  </div>
                  <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    {disabled == false ? 
                    <button
                      type="button"
                      class="btn3"
                      data-bs-dismiss="modal"
                      onClick={editSpacesData}
                      // disabled={disabled}
                    >
                      Update
                    </button>
                    :
                    <button
                      type="button"
                      class="btn3"
                      data-bs-dismiss="modal"
                      onClick={()=>{
                        setDisabled(false);
                        setHistory([]);
                      }}
                      // disabled={disabled}
                    >
                      Close
                    </button>
                    
                    }
                  </div>
                </div>
              </div>
            </div>
    {/* <PDFExport data={spaceData}>
        <button>Download PDF</button>
    </PDFExport> */}
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/listings-about-parking-spot.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Parking Spaces:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="List and assign your parking spaces here. You can assign a space to a Unit (this will not be unassigned when the tenant leaves) or to an individual tenant (this will become unassigned once they leave)."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"List and assign your parking spaces here. You can assign a space to a Unit (this will not be unassigned when the tenant leaves) or to an individual tenant (this will become unassigned once they leave)."}/>
            </div>
          </div>

          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  hidden={hiddenData}
                  className="btn3"
                  data-bs-toggle="modal"
                  data-bs-target={`#BulkSpaces`}
                  // onClick={() => {
                  // 	setParkingSpaces([
                  // 		...parkingSpaces,
                  // 		initialSpaceData,
                  // 	]);
                  // }}
                >
                  <i class="fa fa-plus"></i> Add Bulk Spaces
                </button>
                <div
                  class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                  id={`BulkSpaces`}
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="BulkSpacesLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5
                          class="text-xl font-medium leading-normal text-gray-800"
                          id="exampleModalLabel"
                        >
                          Bulk Spaces
                        </h5>
                        <button
                          type="button"
                          class="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ><i className='fa fa-remove'></i></button>
                      </div>
                      <div class="modal-body relative p-4">
                      <div className="md:items-center w-full mt-3">
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-2/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Spaces To Add:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="bulk-space-to-add"
                              type="number"
                              placeholder="1"
                              required={false}
                              value={bulkSpaces?.spaces_add}
                              onChange={(e) => {
                                setBulkSpaces({...bulkSpaces,spaces_add:parseInt(e.target.value)})
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-2/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Starting Number:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="bulk-space-starting-number"
                              type="number"
                              placeholder="1"
                              required={false}
                              value={bulkSpaces?.starting_number}
                              onChange={(e) => {
                                setBulkSpaces({
                                  ...bulkSpaces,
                                  starting_number: parseInt(e.target.value),
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-2/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Space Number:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="bulk-space-starting-number"
                              type="number"
                              placeholder="25"
                              required={false}
                              value={parkingSpace?.space_number}
                              onChange={(e) => {
                                setParkingSpace({
                                  ...parkingSpace,
                                  space_number: parseInt(e.target.value),
                                });
                              }}
                            />
                          </div>
                        </div> */}
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-2/4">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Prefix:
                            </label>
                          </div>
                          <div className="md:w-1/2">
                            {/* <input
													className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="bulk-space-prefix"
													type="text"
													placeholder="Steve"
													required={false}
													value={bulkSpaces?.prefix}
													onChange={(e) => {
														setBulkSpaces({...bulkSpaces,prefix : e.target.value})
													}}
													/> */}
                            <select
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="bulk-space-prefix"
                              value={parkingSpace?.parking_space_prefix_id}
                              required={false}
                              placeholder="12"
                              onChange={(e) => {
                                setParkingSpace({
                                  ...parkingSpace,
                                  parking_space_prefix_id: parseInt(e.target.value),
                                });
                              }}
                            >
                              <option selected value=" ">Select Prefix</option>
                              {parkingSpacePrefix?.map((row) => (
                                <option value={row?.id}>{row?.prefix}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-2/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Suffix:
                            </label>
                          </div>
                          <div className="md:w-2/4">
                            {/* <input
													className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="bulk-space-suffix"
													type="text"
													placeholder="Steve"
													required={false}
													value={bulkSpaces?.suffix}
													onChange={(e) => {
														setBulkSpaces({...bulkSpaces,suffix : e.target.value})
													}}
													/> */}
                            <select
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="bulk-space-suffix"
                              value={parkingSpace?.parking_space_suffix_id}
                              required={false}
                              placeholder="12"
                              onChange={(e) => {
                                setParkingSpace({
                                  ...parkingSpace,
                                  parking_space_suffix_id: parseInt(e.target.value),
                                });
                              }}
                            >
                              <option selected value=" ">Select Suffix</option>
                              {parkingSpaceSuffix?.map((row) => (
                                <option value={row?.id}>{row?.suffix}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                        
                          <div className="md:flex md:items-center w-full mt-3">
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`parking_type`}
                              type="checkbox"
                              checked={parkingSpace?.compact === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.compact = checkedValue
                                setParkingSpace({
                                  ...parkingSpace,
                                  compact: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2">
                              COMPACT
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`parking_type`}
                              type="checkbox"
                              checked={parkingSpace?.tandem === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.tandem = checkedValue
                                setParkingSpace({
                                  ...parkingSpace,
                                  tandem: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2">
                              TANDEM
                            </label>
                          </div>
                          
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/2 form-check ">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`parking_type`}
                              type="checkbox"
                              checked={parkingSpace?.electric_vehicle_charging === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.electric_vehicle_charging = checkedValue
                                setParkingSpace({
                                  ...parkingSpace,
                                  electric_vehicle_charging: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2">
                              ELECTRIC VEHICLE CHARGING
                            </label>
                          </div>
                          <div className="md:w-1/2 form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                              id={`parking_type`}
                              type="checkbox"
                              // value={parkingSpace?.handicap}
                              checked={parkingSpace?.handicap === 1}
                              onChange={(e) => {
                                const checkedValue = e.target.checked === true ? 1 : 0;
                                // parkingSpace.handicap = checkedValue
                                setParkingSpace({
                                  ...parkingSpace,
                                  handicap: parseInt(checkedValue),
                                });
                                // parkingSpaces.push(parkingSpace)
                                // setFormData({...formData,parking_spaces:parking_spaces})
                              }}
                            />
                            <label className="input-label text-gray-700 font-bold mb-2">
                              HANDICAP
                            </label>
                          </div>
                        </div>
                        </div>
                      <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                          type="button"
                          class="btn3"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn3"
                          onClick={(e)=>{
                            // if(!parkingSpace?.space_number)
                            // {
                            //   Toast.fire({
                            //     icon:'warning',
                            //     title:'Space Number is Required',
                            //   })
                            // }
                            // else
                            // {
                              bulkData(e)
                            }}
                            // }
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  {" "}
                </div>
              </div>
            </div>
          </div>

          {/* {parkingSpace.map((parking_space, index) => {
						const parking_spaces = [...parkingSpace];

						return ( */}

          {hiddenData != true ?
           <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space prefix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-prefix`}
                      value={parkingSpace?.parking_space_prefix_id}
                      // //hidden={hiddenData}
                      required={false}
                      placeholder="12"
                      onChange={(e) => {
                        // parkingSpace.parking_space_prefix_id = e.target.value
                        setParkingSpace({
                          ...parkingSpace,
                          parking_space_prefix_id: parseInt(e.target.value),
                        });
                        // setParkingSpaces(parkingSpace)
                        // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Prefix</option>
                      {parkingSpacePrefix?.map((row) => (
                        <option value={row?.id}>{row?.prefix}</option>
                      ))}
                    </select>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-prefix`}
											type="text"
											value={parkingSpace?.parking_space_prefix_id}
											placeholder="456"
											onChange={(e) => {
												// parkingSpace.parking_space_prefix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_prefix_id : e.target.value})
												// setParkingSpaces(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-3 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Number:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-number`}
                      //hidden={hiddenData}
                      type="number"
                      value={parkingSpace?.space_number}
                      placeholder="456"
                      onChange={(e) => {
                        // parkingSpace.space_number = e.target.value
                        setBulkSpaces({...bulkSpaces,spaces_add:1})
                        setParkingSpace({
                          ...parkingSpace,
                          space_number: parseInt(e.target.value),
                        });
                        // parkingSpaces.push(parkingSpace)
                        // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Space Suffix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`parking_space-suffix`}
                      type="text"
                      placeholder="456"
                      // //hidden={hiddenData}
                      value={parkingSpace?.parking_space_suffix_id}
                      onChange={(e) => {
                        // parkingSpace.parking_space_suffix_id = e.target.value
                        setParkingSpace({
                          ...parkingSpace,
                          parking_space_suffix_id: parseInt(e.target.value),
                        });
                        // parkingSpaces.push(parkingSpace)
                        // setFormData({...formData,parking_spaces:parking_spaces})
                      }}
                    >
                      <option selected value=" ">Select Suffix</option>
                      {parkingSpaceSuffix?.map((row) => (
                        <option value={row?.id}>{row?.suffix}</option>
                      ))}
                    </select>
                    {/* <input
											className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id={`parking_space-suffix`}
											type="text"
											placeholder="456"
											value={parkingSpace?.parking_space_suffix_id}
											onChange={(e) => {
												// parkingSpace.parking_space_suffix_id = e.target.value
												setParkingSpace({...parkingSpace,parking_space_suffix_id : e.target.value})
												// parkingSpaces.push(parkingSpace)
												// setFormData({...formData,parking_spaces:parking_spaces})
											}}
										/> */}
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`parking_type`}
                    type="checkbox"
                    checked={parkingSpace?.compact === 1}
                    //hidden={hiddenData}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      // parkingSpace.compact = checkedValue
                      setParkingSpace({
                        ...parkingSpace,
                        compact: parseInt(checkedValue),
                      });
                      // parkingSpaces.push(parkingSpace)
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    COMPACT
                  </label>
                </div>
                <div className="md:w-1/5 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`parking_type`}
                    type="checkbox"
                    checked={parkingSpace?.tandem === 1}
                    //hidden={hiddenData}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      // parkingSpace.tandem = checkedValue
                      setParkingSpace({
                        ...parkingSpace,
                        tandem: parseInt(checkedValue),
                      });
                      // parkingSpaces.push(parkingSpace)
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    TANDEM
                  </label>
                </div>
                <div className="md:w-2/5 form-check ">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`parking_type`}
                    type="checkbox"
                    checked={parkingSpace?.electric_vehicle_charging === 1}
                    //hidden={hiddenData}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      // parkingSpace.electric_vehicle_charging = checkedValue
                      setParkingSpace({
                        ...parkingSpace,
                        electric_vehicle_charging: parseInt(checkedValue),
                      });
                      // parkingSpaces.push(parkingSpace)
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    ELECTRIC VEHICLE CHARGING
                  </label>
                </div>
                <div className="md:w-1/5 form-check">
                  <input
                    className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
                    id={`parking_type`}
                    type="checkbox"
                    // value={parkingSpace?.handicap}
                    checked={parkingSpace?.handicap === 1}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      //hidden={hiddenData}
                      // parkingSpace.handicap = checkedValue
                      setParkingSpace({
                        ...parkingSpace,
                        handicap: parseInt(checkedValue),
                      });
                      // parkingSpaces.push(parkingSpace)
                      // setFormData({...formData,parking_spaces:parking_spaces})
                    }}
                  />
                  <label className="input-label text-gray-700 font-bold mb-2">
                    HANDICAP
                  </label>
                </div>
              </div>
            </div>
          </div>:
          null}
          {/* )})} */}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={(e)=>{
                    if(!parkingSpace?.space_number)
                    {
                      Toast.fire({
                        icon:'warning',
                        title:'Space Number is Required',
                      })
                    }
                    else
                    {
                      bulkData(e)
                    }}
                    }
                >
                  <i class="fa fa-plus"></i> Add Space
                </button>
              </div>
            </div>
          </div>
          <hr />
      {parkingSpaces?.length > 0 ?<><div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      {loader == undefined ? <Spinner/> : <>
      
      <div className="flex justify-center">
        <div className="flex-1">
          <span className="data">Total Spaces : {parkingSpaces?.length}</span>
        </div>
        <div className="flex-1">
          <span className="data">Total Assigned: <span className="text-red-700">{totalAssign}</span></span>
        </div>
        <div className="flex-1">
          <span className="data">Total Unassigned: <span className="text-green-700">{totalUnassign}</span></span>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-1">
          <span className="data">Total Compact : {totalCompact}</span>
        </div>
        <div className="flex-1">
          <span className="data">Total Tandem: {totalTandem}</span>
        </div>
        <div className="flex-1">
          <span className="data">Total EV Charging: {totalEV}</span>
        </div>
        <div className="flex-1">
          <span className="data">Total Handicap: {totalHandicap}</span>
        </div>
      </div>
      <div className="flex justify-end mt-2">

        <div className="flex w-1/4">
        <label className="block capitalize text-gray-700 font-bold mb-2 mr-2 mt-2">Filter: </label>
        <select
        className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        value={selectFilter}
        id="filtrationData"
        onChange={(e)=>{
          setSelectFilter(e.target.value);
          filtration(e.target.value);
        }}
        >
          <option value="">Select</option>
          <option value="Regular">Regular</option>
          <option value="Compact">Compact</option>
          <option value="Tandem">Tandem</option>
          <option value="EV">EV</option>
          <option value="Handicap">Handicap</option>
          
        </select>
        </div>

      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
        key={dataTableKey}
        columns={columns}
        data={filterSpaces}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        selectableRows
        selectableRowsHighlight
        onSelectedRowsChange={handleRowSelected}
        />
        </DataTableExtensions>
        </>}
        
          {hiddenData != true ?
          <>
          {selectedRows?.length > 0 ?
          <div className="md:flex md:items-center w-full md:w-9/12 mb-6">
            <div className="md:w-1/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-1/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected value=" ">Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"UNASSIGN"}>UNASSIGN</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-auto mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "UNASSIGN" ? bulkUnassign : bulkAction?.bulk_action == "DELETE" ? bulkDeleteData: null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
          </>:null}
        </>:null}
    </>
  );
};

export default ParkingSpaceTable;
