import React, { useEffect, useState } from "react";
import imgUrl from "../ImageUrl";
import { useParams } from "react-router-dom";
import ApiRequest from "../../utility/http";
import Loading from "../Loader/Loading";

export const TenantHistory = () => {
  const {id} = useParams();
  const [tenantHistory,setTenantHistory] = useState([]);
  const [loader,setLoader] = useState();

  console.log('tenantHistory',tenantHistory);

  const getTenantHistory = async()=>{
    setLoader(undefined)
    const response = await ApiRequest.get(`/public/api/auth/get-tenant-history-logs?tenant_id=${id}`)
    if(response?.status == 200)
    {
      setTenantHistory(response?.data?.data);
      setLoader(response);
    }
  }

  function getDateFromTimestamp(timestamp) {
    const createdAt = new Date(timestamp);
  
    // Extract date components
    const year = createdAt.getFullYear();
    const month = String(createdAt.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(createdAt.getDate()).padStart(2, '0');
  
    // Extract time components
    let hours = createdAt.getHours();
    const minutes = String(createdAt.getMinutes()).padStart(2, '0');
    const seconds = String(createdAt.getSeconds()).padStart(2, '0');
  
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours = hours % 12 || 12;
  
    // Format the date and time
    const date = `${year}-${month}-${day}`;
    const time = `${hours}:${minutes}:${seconds} ${ampm}`;
  
    return date;
  }
  function getTimeFromTimestamp(timestamp) {
    const createdAt = new Date(timestamp);
  
    // Extract date components
    const year = createdAt.getFullYear();
    const month = String(createdAt.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(createdAt.getDate()).padStart(2, '0');
  
    // Extract time components
    let hours = createdAt.getHours();
    const minutes = String(createdAt.getMinutes()).padStart(2, '0');
    const seconds = String(createdAt.getSeconds()).padStart(2, '0');
  
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours = hours % 12 || 12;
  
    // Format the date and time
    const date = `${year}-${month}-${day}`;
    const time = `${hours}:${minutes} ${ampm}`;
  
    return time;
  }
  
  const getDataByFieldName = (fieldName) => {
    return tenantHistory.filter(item => item.field_name === fieldName);
  };
  
  

  useEffect(()=>{
    getTenantHistory();
  },[])
  return (
    <>
  {loader === undefined ? (
    <Loading />
  ) : (
    <>
      {tenantHistory?.map((history, index) => {
        // Check if it's the first entry or the date has changed
        const isFirstEntry = index === 0;
        const isDateChanged =
          index > 0 &&
          Math.floor(new Date(history.created_at).getTime() / (1000 * 60)) !==
            Math.floor(new Date(tenantHistory[index - 1].created_at).getTime() / (1000 * 60));
            const isTimeChanged =
            index > 0 &&
            history.created_at.slice(0, 10) === tenantHistory[index - 1].created_at.slice(0, 10) &&
            getTimeFromTimestamp(history.created_at) !== getTimeFromTimestamp(tenantHistory[index - 1].created_at);
          

          const isUserChanged = index > 0 && history.user_id !== tenantHistory[index - 1].user_id;

        const fieldName = history?.field_name.replace(/_/g, ' ');

        return (
          <div key={history.id}>
            {!isFirstEntry && (isDateChanged || isUserChanged || isTimeChanged) && <hr className="mb-6 mt-6"/>}
            {isFirstEntry || isDateChanged || isUserChanged || isTimeChanged ? (
              <div className="flex">
                <div className="w-1/2">
                  <div className="w-3/4 flex justify-between">
                    <label className="font-bold">{getDateFromTimestamp(history.created_at)}</label>
                    <label className="font-bold">{getTimeFromTimestamp(history?.created_at)}</label>
                    <label className="font-bold">{history?.firstname} {history?.lastname}</label>
                  </div>
                  <div className="w-full mt-2">
                    {history?.field_name === 'picture' || history?.field_name === 'dl_id_picture' ? (
                      <label className="mt-1">
                        {history?.type === 'added' ? (
                          <>
                            <span className="font-bold capitalize">{fieldName}</span> Added{' '}
                            <a href={`${imgUrl}/${history?.new_data}`} target="_blank">View Added Image</a>
                          </>
                        ) : history?.type === 'deleted' ? (
                          <>
                            <span className="font-bold capitalize">{fieldName}</span> Deleted{' '}
                            <a href={`${imgUrl}/${history?.old_data}`} target="_blank">View Deleted Image</a>
                          </>
                        ) : (
                          <>
                            <span className="font-bold capitalize">{fieldName}</span> Updated{' '}
                            <a href={`${imgUrl}/${history?.new_data}`} target="_blank">View Updated Image</a>
                          </>
                        )}
                      </label>
                    ) : (
                      <>
                    {history?.type === 'updated' ? (
                      <>
                      <label className="mt-1">

                        <span className="font-bold capitalize">{fieldName}</span> Changed from{' '}
                        <span className="font-bold">{history?.old_data_title ? history?.old_data_title : history.old_data}</span> to{' '}
                        <span className="font-bold">{history?.new_data_title ? history?.new_data_title : history.new_data}</span>
                      </label>
                      </>
                    ) : (
                      <>
                        {history?.new_data !== '' && (
                          <>
                          <label className="mt-1">

                            <span className="font-bold capitalize">{fieldName}</span> Added{' '}
                            <span className="font-bold">{history?.new_data_title ? history?.new_data_title : history.new_data}</span>
                          </label>
                          </>
                        )}
                      </>
                    )}
                  </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full mt-2">
                {history?.field_name === 'picture' || history?.field_name === 'dl_id_picture' ? (
                  <label className="mt-1">
                    {history?.type === 'added' ? (
                      <>
                        <span className="font-bold capitalize">{fieldName}</span> Added{' '}
                        <a href={`${imgUrl}/${history?.new_data}`} target="_blank">View Added Image</a>
                      </>
                    ) : history?.type === 'deleted' ? (
                      <>
                        <span className="font-bold capitalize">{fieldName}</span> Deleted{' '}
                        <a href={`${imgUrl}/${history?.old_data}`} target="_blank">View Deleted Image</a>
                      </>
                    ) : (
                      <>
                        <span className="font-bold capitalize">{fieldName}</span> Updated{' '}
                        <a href={`${imgUrl}/${history?.new_data}`} target="_blank">View Updated Image</a>
                      </>
                    )}
                  </label>
                ) : (
                  <>
                    {history?.type === 'updated' ? (
                      <>
                      <label className="mt-1">

                        <span className="font-bold capitalize">{fieldName}</span> Changed from{' '}
                        <span className="font-bold">{history?.old_data_title ? history?.old_data_title : history.old_data}</span> to{' '}
                        <span className="font-bold">{history?.new_data_title ? history?.new_data_title : history.new_data}</span>
                      </label>
                      </>
                    ) : (
                      <>
                        {history?.new_data !== '' && (
                          <>
                          <label className="mt-1">

                            <span className="font-bold capitalize">{fieldName}</span> Added{' '}
                            <span className="font-bold">{history?.new_data_title ? history?.new_data_title : history.new_data}</span>
                          </label>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  )}
</>

  
  
  );
};
