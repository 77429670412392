import React from 'react';

export const UnderDevelopmentPage = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#f1f1f1",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
          padding: "50px"
        }}
      >
        <h1 style={{ color: "#555555" }}>Under Development</h1>
        <p style={{ color: "#888888" }}>We're sorry, but this page is currently under development.</p>
        <p style={{ color: "#888888" }}>Please check back later.</p>
      </div>
    </>
  );
};
