import React, { useRef } from "react";
import SignaturePad from "react-signature-pad";

const MySignaturePad = () => {
  const signaturePad = useRef(null);

  const clear = () => {
    signaturePad.current.clear();
  };

  const save = () => {
    console.log(signaturePad.current.toDataURL());
  };

  return (
    <>
      <div className="md:flex flex-column md:items-center w-[90%] m-auto">
        <div className="w-full">
          <div className="md:flex mt-3">
            <div className="md:w-1/5 bg-gray-200">
              <SignaturePad ref={signaturePad} />
            </div>
          </div>
          <div className="md:flex mt-3">
            <div className="md:w-1/5">
              <button onClick={clear}>Clear</button>
            </div>
            <div className="md:w-1/5">
              <button onClick={save}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySignaturePad;
