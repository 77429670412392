import React from "react";

export const FinanceReceiptTemplate = () => {
  return (
    <>
      <div className="flex-col w-[90%] items-center m-auto mt-5">
        <div className="flex w-1/5 m-auto items-center justify-between">
          <img src="/assets/svgs/finance-receipt.svg" className="w-1/3" alt="img" />
          <label className="uppercase tracking-wider text-gray-700 text-4xl font-bold">
            receipt
          </label>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex ">
            <div className="flex w-1/5">
              <img
                className="logo w-9/12 mr-5"
                src="/assets/svgs/property.svg"
                alt=""
              />
            </div>
            <div className="flex-col w-1/2">
              <label className="block uppercase tracking-wider text-gray-700 text-2xl font-bold mb-2">
                new heights
              </label>
              <p className="block tracking-normal text-gray-700 text-base font-semibold mb-1">
                8320 North Peyton Way
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                West Hills, CA 91304
              </p>
            </div>
          </div>
          <div className="flex-col text-right ">
            <label className="block uppercase tracking-wider text-gray-700 text-2xl font-bold mb-2">
              payement #001256
            </label>
            <p className="block tracking-normal uppercase text-gray-700 text-base font-semibold mb-1">
              invoice date: 7/29/2021
            </p>
            <p className="block tracking-normal uppercase text-gray-700 text-base font-semibold ">
              room b
            </p>
          </div>
        </div>
        <div className="flex-col mt-5">
          <div className="flex-col text-right items-center">
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              Sammye Alix
            </p>
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              sammye@gmail.com
            </p>
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              (652) 854-4412
            </p>
          </div>
          <div className="flex-col text-right items-center mt-4">
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              Oscar Rojas
            </p>
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              oscar@gmail.com
            </p>
            <p className="block tracking-normal text-gray-700 text-base font-semibold">
              (718) 254-2546
            </p>
          </div>
        </div>
        <div className="flex-col m-auto w-1/5 items-center">
          <p className="text-center">INV00214</p>
          <label className="uppercase tracking-wide text-gray-700 text-2xl font-bold">
            RENT: AUGUST 2021
          </label>
        </div>
        <div className="flex-col float-right mr-10 w-1/5 mt-4">
          <div className="flex justify-between tracking-normal text-gray-700 text-base font-semibold">
            <p>Subtotal:</p>
            <p>$1,603.76</p>
          </div>
          <div className="flex justify-between tracking-normal text-gray-700 text-base font-semibold">
            <p>Sales Tax:</p>
            <p>$10.00</p>
          </div>
          <div className="flex justify-between tracking-normal text-gray-700 text-xl font-bold">
            <p>TOTAL:</p>
            <p>$1,613.76</p>
          </div>
        </div>
        <div className="flex-col ">
          <div className="flex justify-between mt-36">
            <div className="flex w-1/4 justify-between tracking-normal text-gray-700 text-xl font-bold">
              <p>PAYMENT METHOD:</p>
              <p>Venmo</p>
            </div>
            <div className="flex w-1/5 mr-10 justify-between tracking-normal text-gray-700 text-xl font-bold">
              <p>AMOUNT PAID:</p>
              <p>$500.00</p>
            </div>
          </div>
          <div className="flex w-1/5 mr-10 mt-4 float-right justify-between tracking-normal text-gray-700 text-xl font-bold">
            <p>BALANCE:</p>
            <p>$1,113.76</p>
          </div>
          <div className="flex-col m-auto w-9/12 mt-36">
            <label className="tracking-normal text-gray-700 text-base font-bold">
              PAYEMENT NOTES:
            </label>
            <p className="tracking-normal text-gray-700 text-base font-semibold mt-2">
              From Oscar
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <p className="tracking-normal text-gray-700 text-base font-semibold">
            Receipt generated by <span>RentBlaze.com</span>
          </p>
        </div>
      </div>
    </>
  );
};
