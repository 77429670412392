import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../../utility/http";
export const parkingGetPrefix = createAsyncThunk(
  "auth/parkingPrefixes",
  async () => {
    try {
      const response = await ApiRequest.get(
        "/public/api/auth/get-parking-space-prefixes"
      );
      return response.data;
    } catch (error) {
      console.log("parkingPrefixes api has error");
    }
  }
);
export const parkingGetSuffix = createAsyncThunk(
  "auth/parkingSuffixes",
  async () => {
    try {
      const response = await ApiRequest.get(
        "/public/api/auth/get-parking-space-suffixes"
      );
      return response.data;
    } catch (error) {
      console.log("parkingSuffixes api has error");
    }
  }
);
