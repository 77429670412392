const dataList = [
  {
    email: 'renter@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'owner@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'testing@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'test.account@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'test.abc@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'testing@gmail.com',
    password: '123456789'
  },
  {
    email: 'test.app@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'owner.test@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'testrenter@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'entery@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'user.renter@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'hibye_renter@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'hibye_owner@rentblaze.com',
    password: '123456789'
  },
  {
    email: 'hibye@rentblaze.com',
    password: '123456789'
  },
]

export default dataList;
