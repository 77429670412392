export const convertToFormData = (obj, namespace = null, form = null) => {
  //namespace && form used for recursive call
  const newFormData = form || new FormData();
  let formKey;

  for (const property in obj) {
    if (typeof obj[property] === "boolean") {
      obj[property] = obj[property].toString();
    }

    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        newFormData.append(formKey, obj[property].toISOString());
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        convertToFormData(obj[property], formKey, newFormData);
      } else {
        // if it's a string or a File object
        newFormData.append(formKey, obj[property]);
      }
    }
  }

  return newFormData;
};
