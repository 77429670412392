import React, { useEffect, useState } from "react";
import imgUrl from "../ImageUrl";
import ApiRequest from "../../utility/http";

export const List = ({ data, ownerSetting }) => {
  const [numberType, setNumberType] = useState([]);
  const getNumberType = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-number-types')
    if(response.status == 200)
    {
      setNumberType(response?.data?.data?.number_types);
    }
  }
  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, '') || '';
    let formattedNumber = '';
    let digitIndex = 0;
  
    for (let i = 0; i < format?.length; i++) {
      if (format[i] === '#') {
        formattedNumber += digitsOnly[digitIndex] || '';
        digitIndex++;
      } else if (format[i] === '_') {
        formattedNumber += ' ';
      } else {
        formattedNumber += format[i];
      }
    }
  
    return formattedNumber;
  };
  useEffect(()=>{
    getNumberType();
  },[])
  return (
    <div className="w-full">
      <div className="bg-gray-400 p-3 rounded shadow flex">
        <div className="w-1/5">Last Name</div>
        <div className="w-1/5">First Name</div>
        <div className="w-1/5">Title</div>
        <div className="w-1/5">Number 1</div>
        <div className="w-1/5">Email</div>
      </div>
      {data?.map((list, index) => (
        <div key={index} className={` ${index%2 == 0 ? "bg-gray-100" : "bg-gray-200"} p-3 rounded shadow flex`}>
          <div className="w-1/5">{list?.lastname}</div>
          <div className="w-1/5">{list?.firstname}</div>
          <div className="w-1/5">{list?.title}</div>
          <div className="w-1/5"><div className="flex">
                  <span>{numberType?.map((row)=>{
                  if(list?.number1_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className="ml-2 text-black no-underline" href={`tel:${list?.number1}`}>{list?.number1_type == 8 ? list?.number1: formatPhoneNumber(list?.number1,ownerSetting?.phone_number_format)}</a>
                </div></div>
          <div className="w-1/5 italic"><a className="text-black no-underline" href={`mailto:${list?.email}`}>{list?.email}</a></div>
        </div>
      ))}
    </div>
  );
};
