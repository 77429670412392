import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import Swal from 'sweetalert2';
import imgUrl from '../ImageUrl';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';


export const EmergencyContacts = ({data,handleEmergencyContactTrue,hiddenData,ownerSettings}) => {
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    let imageApi = "/public/api/auth/upload-image";
    const [showForm, setShowForm] = useState(false);
    const [emergencyContact,setEmergencyContact] = useState([]);
    const [emergencyContactPictures,setEmergencyContactPictures] = useState([]);
    const [viewEmergencyContact,setViewEmergencyContact] = useState([]);
    const [ownerSetting,setOwnerSetting] = useState([]);
    console.log('emergencyContact',emergencyContact);
    const [emergencyContacts,setEmergencyContacts] = useState([]);
    const [userRelationship, setUserRelationship] = useState([]);
    const [viewForm,setViewForm] =useState(false);
    const [dataDisabled,setDataDisabled] = useState(false);
    const initialEmergencyContactData = {
      firstname: "",
      lastname: "",
      user_relationship_id: "",
      other_user_relationship: "",
      address_line1: "",
      address_line2: "",
      city: "",
      country:"",
      state: "",
      zip_code: "",
      number1: "",
      number1_type: "",
      number2: "",
      number2_type: "",
      number3: "",
      number3_type: "",
      email: "",
      pictures: "",
    };
    const picTypes = useAllowedPicMimeTypes();
    function removeNonNumeric(inputString) {
      var numericString = inputString.replace(/\D/g, "");
      return numericString;
    }
    const formatPhoneNumber = (number, format) => {
      const digitsOnly = number?.replace(/\D/g, '') || '';
      let formattedNumber = '';
      let digitIndex = 0;
    
      for (let i = 0; i < format?.length; i++) {
        if (format[i] === '#') {
          formattedNumber += digitsOnly[digitIndex] || '';
          digitIndex++;
        } else if (format[i] === '_') {
          formattedNumber += ' ';
        } else {
          formattedNumber += format[i];
        }
      }
    
      return formattedNumber;
    };
    const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const getCountries = async () => {
      const response = await ApiRequest.get("public/api/auth/get-countries");
      if (response.status == 200) {
        setCountries(response.data?.data?.countries);
      }
    };
    const getStates = async (id) => {
      const response = await ApiRequest.get(`public/api/auth/get-states?country_id=${id}`);
      if (response.status == 200) {
        setStates(response.data?.data?.states);
      }
    };
    const [city,setCity] = useState([]);
    const [zip,setZip] = useState([]);
    const [state,setState] = useState([]);
    const getState = async (id) => {
      const response = await ApiRequest.get(`/public/api/auth/get-state?id=${id}`)
      if(response?.status == 200)
      {
        setState(response?.data?.data?.state?.state);
      }
    }
    const getCity = async (id) => {
      const response = await ApiRequest.get(`/public/api/auth/get-city?id=${id}`)
      if(response?.status == 200)
      {
        setCity(response?.data?.data?.city?.city)
        setZip(response?.data?.data?.city?.zip_code)
      }
    }
    const getCities = async (data) => {
      const state = states?.map((col) =>{
        if(col?.id == data)
        {
          return col?.state_code
        }
      })
      const filteredArr = state.filter((value) => value !== undefined);
      const stateCode = filteredArr.join(', ');
    
      
      const response = await ApiRequest.get(`public/api/auth/get-cities?state_code=${stateCode}`);
      
      if (response.status == 200) {
        setCities(response.data?.data?.cities);
      }
    };
    const imageComponent = ({
      image = {},
      key = 0,
      src = "",
      inputFieldId = "",
      trashAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
      ...restProps
    }) => {
      const confirmEdit = (image) => {
        Swal.fire({
          title: 'Are you sure you want to edit this image?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // Modify the image object here
            const editedImage = { ...image, title: 'New Title' };
            onEditClick(editedImage);
          }
        });
      };
    
      return image ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
        >
          <div className="relative h-fit w-full">
            <img
              title={image?.title}
              key={key}
              alt="property image"
              className="border block object-contain w-80 h-auto rounded-lg"
              src={src}
            />
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
            >
              {trashAble ? (
                <img
                  title="Remove image"
                  type="button"
                  className="logo w-5 mx-auto my-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((file) => {
                        if (file !== image) {
                          dataTransfer.items.add(file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
              {/* <button
                type="button"
                title="Edit image"
                className="logo w-5 mx-auto my-1"
                onClick={() => confirmEdit(image)}
              >
                <img src="/assets/img/system-edit.png" alt="edit" />
              </button> */}
            </div>
          </div>
        </div>
      ) : (
        <img {...restProps} />
      );
    };
    const myDivRef = useRef(null);
    const bulkData = ()=>{
        // setEmergencyContact({...emergencyContact,notes:editorData}); 
        const newDataArray  = [];
        newDataArray.push(emergencyContact);
        handleEmergencyContactTrue(prevArray => [...prevArray, ...newDataArray]);
        setEmergencyContacts(prevArray => [...prevArray, ...newDataArray]);
        setEmergencyContact(initialEmergencyContactData);
        setStates(undefined);
        setCities(undefined);
        setEmergencyContactPictures([])
        // setEditorData('');
        setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...emergencyContacts];
        array[indexEdit] = emergencyContact;
        setEmergencyContacts(array);
    
        handleEmergencyContactTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setEmergencyContact(initialEmergencyContactData);
        setEmergencyContactPictures([])
      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        getStates(emergencyContacts[index]?.country)
        getCities(emergencyContacts[index]?.state)
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setDataDisabled(false);
        setEmergencyContact(emergencyContacts[index]);
        setEmergencyContactPictures(emergencyContacts[index]?.pictures)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        getStates(emergencyContacts[index]?.country)
        getCities(emergencyContacts[index]?.state)
        setViewEmergencyContact(emergencyContacts[index]);
        getState(emergencyContacts[index]?.state)
        getCity(emergencyContacts[index]?.city)
        setEmergencyContactPictures(emergencyContacts[index]?.pictures)
        setDataDisabled(true);
        setViewForm(true);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      const closeViewData = ()=>{
        setEmergencyContact(initialEmergencyContactData);
        setViewForm(false);
        setDataDisabled(false);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        setDataDisabled(false);
        const newArray = [...emergencyContacts];
        newArray.splice(index, 1);
        setEmergencyContacts(newArray);
        handleEmergencyContactTrue(newArray);
      }
    const columns = [
        {
          name: "Last Name",
          selector: (data) => data.lastname,
          sortable: true,
        },
        {
          name: "First Name",
          selector: (data) => data.firstname,
          sortable: true,
        },
        {
          name: "Relationship",
          selector: (data) => userRelationship.map((row)=>{
            if(data.user_relationship_id == row?.id)
            {
              return row?.relationship;
            }
          }),
          sortable: true,
        },
        {
          name: "Number 1",
          selector: (data) => data.number1 != "" ? data?.number1_type == 8 ? data?.number1 : formatPhoneNumber(data.number1,ownerSetting?.phone_number_format):"",
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData != true ? (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewEmergencyContactForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/12 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
               
               
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/12  p-0"
                data-bs-target={`#ViewEmergencyContactForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : emergencyContacts,
      };
      
      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Emergency Contacts";
        const headers = [["Last Name", "First Name","Relationship","Number 1"]];
    
        const data = emergencyContacts.map(elt => [elt.lastname,elt.firstname,userRelationship.map((row)=>{
          if(elt.user_relationship_id == row?.id)
          {
            return row?.relationship
          }
        }) ,elt.number1])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Emergency Contacts.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = emergencyContacts.map(elt => [elt.lastname,elt.firstname,userRelationship.map((row)=>{
          if(elt.user_relationship_id == row?.id)
          {
            return row?.relationship
          }
        }) ,elt.number1])
        // Convert the data to a CSV string
    
        const headers = ["Last Name", "First Name","Relationship","Number 1"];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Emergency Contacts.csv");
        link.click();
      };


//APi

const getUserRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-relationships"
    );
    if (response.status == 200) {
      setUserRelationship(response?.data?.data?.relationships);
    }
  };
  

  useEffect(() => {
    getUserRelationships();
    getNumberType();
    getCountries();
    setEmergencyContact(initialEmergencyContactData);
    setEmergencyContacts(data);
    setOwnerSetting(ownerSettings);
  }, [])
  


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewEmergencyContactForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewEmergencyContactFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Emergency Contacts
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    closeViewData();
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="flex">
                      <div className="w-4/12 mr-3">
                      {viewEmergencyContact?.pictures?.length > 0 ?
                      (<img className="logo w-full" src={`${imgUrl}/${viewEmergencyContact?.pictures[0]?.picture}`} alt="" />)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                      </div>
                      <div className="w-8/12">
                        <div className="flex font-bold">
                          {viewEmergencyContact?.firstname != "" ? <h4 className='mr-2'>{viewEmergencyContact?.firstname}</h4> :null}
                          {viewEmergencyContact?.lastname != "" ? <h4 className='mr-2'>{viewEmergencyContact?.lastname}</h4> :null}
                          {viewEmergencyContact?.user_relationship_id != "" ? <span>( {userRelationship?.map((row)=>{
                            if(viewEmergencyContact?.user_relationship_id == row?.id)
                            {
                              return row?.relationship
                            }
                          })} )</span> :null}
                        </div>
                        {viewEmergencyContact?.email != "" ?<div className="flex mt-2">
                          <a className='text-black no-underline' href={`mailto:${viewEmergencyContact?.email}`}>{viewEmergencyContact?.email}</a>
                        </div>:null}
                        {viewEmergencyContact?.number1 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(viewEmergencyContact?.number1_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${viewEmergencyContact?.number1}`}>{viewEmergencyContact?.number1_type == 8 ? viewEmergencyContact?.number1 : formatPhoneNumber(viewEmergencyContact?.number1,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {viewEmergencyContact?.number2 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(viewEmergencyContact?.number2_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${viewEmergencyContact?.number2}`}>{viewEmergencyContact?.number2_type == 8 ? viewEmergencyContact?.number2 : formatPhoneNumber(viewEmergencyContact?.number2,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {viewEmergencyContact?.number3 != "" ?<div className="flex mt-2">
                        <span className='mr-2'>{numberType?.map((row)=>{
                          if(viewEmergencyContact?.number3_type == row?.id)
                          {
                            return (<img className='logo w-6' title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                          }
                        })}</span> 
                        <a className='text-black no-underline' href={`tel:${viewEmergencyContact?.number3}`}>{viewEmergencyContact?.number3_type == 8 ? viewEmergencyContact?.number3 : formatPhoneNumber(viewEmergencyContact?.number3,ownerSetting?.phone_number_format)}</a>

                        </div>:null}
                        {viewEmergencyContact?.address_line1 != "" ?<div className="flex mt-2">
                            <span>{viewEmergencyContact?.address_line1}</span>
                        </div>:null}
                        {viewEmergencyContact?.address_line2 != "" ?<div className="flex mt-2">
                            <span>{viewEmergencyContact?.address_line2}</span>
                        </div>:null}
                        <div className="flex mt-2">
                        {viewEmergencyContact?.city != "" ?
                        <><span>{city}</span> {viewEmergencyContact?.state != ""?<span className='ml-2'>|</span>:null}</>:null}
                        {viewEmergencyContact?.state != "" ?<span className='ml-2'>{state}</span>:null}
                        </div>
                        {viewEmergencyContact?.country != "" ?<div className="flex mt-2">
                        <span>{zip}</span>
                        </div>:null}
                        {viewEmergencyContact?.country != "" ?<div className="flex mt-2">
                        <span>{countries?.map((country)=>{
                              if(viewEmergencyContact?.country == country?.id)
                              {
                                return country?.country
                              }
                            })}</span>
                        </div>:null}
                       
                        
                      </div>
                    </div>

                    
                    
                    
                  </div>
                </div>
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                closeViewData();
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
 
                <div className="md:items-center w-full mt-4" ref={myDivRef}>
                  <div className="flex">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/system-emergency-contact.svg"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Emergency Contacts:
                    </label>
                  </div>
                </div>
                {showForm == false ? (
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="flex justify-end w-full mt-4">
            <div className="w-auto mt-2 ">
              <button
                type="button"
                className="btn btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <i class="fa fa-plus"></i> Add Emergency Contact
              </button>
            </div>
          </div>
        </div>
      ) : null}
                {showForm == true ?
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            First Name:<span title="Field is required.">*</span>
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="firstname"
                            type="text"
                            placeholder="John"
                            required={false}
                            disabled={dataDisabled}
                            value={emergencyContact?.firstname}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,firstname:e.target.value});
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Last Name:<span title="Field is required.">*</span>
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="lastname"
                            type="text"
                            placeholder="Deo"
                            required={false}
                            disabled={dataDisabled}
                            value={emergencyContact?.lastname}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,lastname:e.target.value});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/3">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Relationship:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`user_relationship_id`}
                            value={emergencyContact?.user_relationship_id}
                            disabled={dataDisabled}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,user_relationship_id:parseInt(e.target.value)})
                            }}
                          >
                            <option value="">Select Relation</option>
                            {userRelationship.map((relation) => (
                              <option value={relation?.id}>
                                {relation?.relationship}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        {emergencyContact?.user_relationship_id == 6 ?
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:flex md:items-center w-full md:w-full mb-6">
                          <div className="md:w-1/2">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Other Relationship Description:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`other_user_relationship`}
                              value={emergencyContact?.other_user_relationship}
                              disabled={dataDisabled}
                              onChange={(e) => {
                                setEmergencyContact({...emergencyContact,other_user_relationship:e.target.value})
                              }}
                              placeholder='Other'
                            />
                          </div>
                        </div>
                      </div>:null}
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 1:
                          
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="emergency-grid-address-line-1"
                          type="text"
                          placeholder="6284 Locust Road"
                          required={false}
                          disabled={dataDisabled}
                          value={emergencyContact?.address_line1}
                          onChange={(e) => {
                           setEmergencyContact({...emergencyContact,address_line1:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 2:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="emergency-grid-address-line-2"
                          type="text"
                          placeholder="Unit #5"
                          value={emergencyContact?.address_line2}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,address_line2:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Country:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={emergencyContact?.country}
                            disabled={dataDisabled}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,country:e.target.value});
                              getStates(e.target.value)
                            }}
                          >
                            <option value=" ">Select Country</option>
                            {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-state"
                            required={false}
                            value={emergencyContact?.state}
                            disabled={dataDisabled}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,state:e.target.value});
                              getCities(e.target.value)
                            }}
                          >
                            <option value="">Select State</option>
                            {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                      <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={emergencyContact?.city}
                            disabled={dataDisabled}
                            onChange={(e) => {
                              const zip = cities?.map((col) =>{
                                if(col?.id == e.target.value)
                                {
                                  return col?.zip_code
                                }
                              })
                              const filteredArr = zip.filter((value) => value !== undefined);
                              const zipCode = filteredArr.join(', ');
                              setEmergencyContact({...emergencyContact,city:e.target.value,zip_code:zipCode});
                            }}
                          >
                            <option value=" ">Select City</option>
                            {cities?.map((city)=>(
                              <option value={city?.id}>{`${city?.city} (${city?.zip_code})`}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="emergency-grid-zip"
                            type="text"
                            placeholder="90210"
                            required={false}
                            value={emergencyContact?.zip_code}
                            disabled={dataDisabled}
                            onChange={(e) => {
                              setEmergencyContact({...emergencyContact,zip_code:e.target.value});
                            }}
                          />
                        </div>
                      </div>

                      </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 1:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        {emergencyContact?.number1_type == 8 ?
                        <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`emergency-tenant-num-l`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={emergencyContact?.number1}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setEmergencyContact({...emergencyContact,number1:removeNonNumeric(e.target.value)});
                        }}
                      />
                        :<input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-l`}
                          type="tel"
                          placeholder="(999) 999-9999"
                          value={formatPhoneNumber(emergencyContact?.number1,ownerSetting?.phone_number_format)}
                          maxLength={ownerSetting?.phone_number_format?.length}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number1:removeNonNumeric(e.target.value)});
                          }}
                        />}
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-1-type`}
                          value={emergencyContact?.number1_type}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number1_type:e.target.value})
                          }}
                        >
                          <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 2:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                      {emergencyContact?.number2_type == 8 ?
                        <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`emergency-tenant-num-2`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={emergencyContact?.number2}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setEmergencyContact({...emergencyContact,number2:removeNonNumeric(e.target.value)});
                        }}
                      />
                        :<input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-2`}
                          type="tel"
                          placeholder="(999) 999-9999"
                          value={formatPhoneNumber(emergencyContact?.number2,ownerSetting?.phone_number_format)}
                          maxLength={ownerSetting?.phone_number_format?.length}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number2:removeNonNumeric(e.target.value)});
                          }}
                        />}
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-2-type`}
                          value={emergencyContact?.number2_type}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number2_type:e.target.value})
                          }}
                        >
                          <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 3:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                      {emergencyContact?.number3_type == 8 ?
                        <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`emergency-tenant-num-3`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={emergencyContact?.number3}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setEmergencyContact({...emergencyContact,number3:removeNonNumeric(e.target.value)});
                        }}
                      />
                        :<input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-3`}
                          type="tel"
                          placeholder="(999) 999-9999"
                          value={formatPhoneNumber(emergencyContact?.number3,ownerSetting?.phone_number_format)}
                          maxLength={ownerSetting?.phone_number_format?.length}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number3:removeNonNumeric(e.target.value)});
                          }}
                        />}
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`emergency-tenant-num-3-type`}
                          value={emergencyContact?.number3_type}
                          disabled={dataDisabled}
                          onChange={(e) => {
                            setEmergencyContact({...emergencyContact,number3_type:e.target.value})
                          }}
                        >
                          <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email:
                          
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="emergency-grid-email"
                          type="email"
                          placeholder="john.deo@gmail.com"
                          required={false}
                          disabled={dataDisabled}
                          value={emergencyContact?.email}
                          onChange={(e) => {
                           setEmergencyContact({...emergencyContact,email:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pictures:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id={`pet-pictures`}
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            emergencyContactPictures?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = emergencyContactPictures.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1 + emergencyContactPictures.length,
                                    url:imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...emergencyContactPictures];
                                    pictureLists = [...pictureLists, ...newImages];
                                    setEmergencyContactPictures(pictureLists);
                                    let tenantCopy = [...emergencyContactPictures];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        order_number: picObj.order_number,
                                      })
                                    );
                                    setEmergencyContact({ ...emergencyContact, pictures: tenantCopy?.pictures });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              emergencyContactPictures?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div >
              {emergencyContactPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {emergencyContactPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...emergencyContactPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                emergencyContact.pictures = pictureLists;
                                setEmergencyContactPictures(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setEmergencyContact({
                                  ...emergencyContact,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                 
                    <div className="flex flex-row-reverse justify-between items-center mt-3">
                      <div className="flex justify-between items-center gap-4">
                        <button className="btn3" type='button' 
                        hidden={hiddenData}
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if(!emergencyContact?.firstname || !emergencyContact?.lastname)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              })
                            }
                            else 
                            {
                            editSpacesData();
                            }
                          } else {
                            if(!emergencyContact?.firstname || !emergencyContact?.lastname)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              })
                            }
                            else 
                            {
                              
                              bulkData();
                            }
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>:null}
                    <hr />
                    {emergencyContacts?.length > 0?<><div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={emergencyContacts}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        <hr/>
        </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>
          <hr /> */}
    </>
  )
}
