import React, { useEffect, useState } from "react";
import ApiRequest from "../utility/http";
import "../components/button.css";
import useAlerts from "../utility/alerts";
import { useDispatch, useSelector } from "react-redux";



export const FolderTemplateForm = () => {
    const [name,setName] = useState();
    const [duplicateName,setDuplicateName] = useState();
    const [templateId,setTemplateId] = useState();
    const [message,setMessage] = useState();
    const [addMessage,setAddMessage] = useState();
    const [getID,setGetID] = useState();
    const dispatch = useDispatch();
    const Alert = useAlerts();
    const { alertList } = useSelector((state) => state.alerts);
    const getTemplate = async () => {
      const response = await ApiRequest.get("/public/api/auth/get-my-files-templates");
      if (response.status == 200) {      
        setGetID(response?.data?.data?.templates);
      }
    };

    useEffect(() => {
     getTemplate();
    }, [])
    

    const formSubmitHandler = async(e)=>{
        e.preventDefault();
        try {
        const response = await ApiRequest.post('/public/api/auth/file-template',{
            name:name
        })
        if(response.status == 200)
        {
            setName('');
            setAddMessage(response?.data?.message);
            getTemplate();
            Alert.addSuccess("Template Added Successfully");
        }
    } catch (err) {
        console.log(err);
        Alert.addFailure(err?.response?.data?.name);
      }
    }
    const duplicateSubmitHandler = async(e)=>{
        e.preventDefault();
        try {
        const response = await ApiRequest.post('/public/api/auth/duplicate-file-template',{
            name:duplicateName,
            picture_template_id:templateId,
        })
        console.warn('response',response);
        if(response.status == 200)
        {
          setMessage(response?.data?.message);
            setDuplicateName('');
            setTemplateId('');
            Alert.addSuccess("Duplicate Template Added Successfully");
        }
    } catch (err) {
        console.log(err);
        Alert.addFailure([err?.response?.data?.name,err?.response?.data?.picture_template_id]);
      }
    }
    const handleCloseAlert = ()=>{
      setMessage('close');
    }
    const handleCloseAddAlert = ()=>{
      setAddMessage('close');
    }
    return (
    <>
    {message == 'Success' ? 
    <div>
      <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Save!</strong>
        <span class="block sm:inline"> Duplicate Template Added Successfully</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg class="fill-current h-6 w-6 text-green-500" role="button" onClick={handleCloseAlert} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    </div>
    :null}
    {addMessage == 'Success' ? 
    <div>
      <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Save!</strong>
        <span class="block sm:inline"> Template Added Successfully</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg class="fill-current h-6 w-6 text-green-500" role="button" onClick={handleCloseAddAlert} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    </div>
    :null}
      <form
        className="w-full"
        id="add-folder-template-form"
        onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Add Template Name
                  </label>
            <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-3/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name:
                  </label>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="name"
                      type="text"
                      placeholder="John"
                      required={false}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 mt-6 flex-row-reverse mr-20">Submit</button>
        </div>
      </form>
      <form
        className="w-full mt-4"
        id="duplicate-folder-template-form"
        onSubmit={duplicateSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Duplicate Template
                  </label>
            <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-3/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name:
                  </label>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="name"
                      type="text"
                      placeholder="John"
                      required={false}
                      value={duplicateName}
                      onChange={(e) => {
                        setDuplicateName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-3/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Select Template:
                  </label>
                  <div className="md:w-3/4">
                  <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`Template`}
                      value={templateId}
                      onChange={(e) => {
                        setTemplateId(parseInt(e.target.value));
                      }}
                    >
                      <option value="undefined">Select Template</option>
                      {getID?.map((row) => (
                        <option value={row?.id}>{row?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <button id={"form-submit-button-duplicate"} type={"submit"} hidden={false} className="btn2 flex-row-reverse mr-20 mt-6">Submit</button>
        </div>
      </form>
    </>
  );
};
