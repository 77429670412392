import {createSlice} from "@reduxjs/toolkit";
import { properties, propertiesTypes } from "./propertiesThunk";
const propertiesSlice = createSlice({
    name: 'properties',
    initialState: {
      status: null,
      properties:{},
      propertiesTypes:{},
    },
    extraReducers: {
      
      [properties.pending]: (state) => {
        state.status = 'loading';
      },
      [properties.fulfilled]: (state, {payload}) => {
        state.status = 'success';
   state.properties=payload
      },
      [properties.rejected]: (state, _error) => {
        state.status = 'failed';
        state.properties={}
      },
            
      [propertiesTypes.pending]: (state) => {
        state.status = 'loading';
      },
      [propertiesTypes.fulfilled]: (state, {payload}) => {
        state.status = 'success';
   state.propertiesTypes=payload
      },
      [propertiesTypes.rejected]: (state, _error) => {
        state.status = 'failed';
        state.propertiesTypes={}
      }
    }
  });
  
  export default propertiesSlice.reducer;