import React from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

export const FinanceAccountForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-finance-account-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex flex justify-end md:items-center mt-3">
              <div className="flex items-center w-1/2 mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Status:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                    </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-1/2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Opened Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-1/2 mt-3 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Account Type:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-1/2 mt-3 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Account Name:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="flex items-center w-1/2 mt-3 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Registered Under:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-full mt-3 mr-1">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Address Line 1:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="addresses-address-line-1"
                  type="text"
                  placeholder="6284 Locust Road"
                  required={false}
                  // value={formData?.addresses[0]?.address_line1}
                  // onChange={(e) => {
                  //   const addresses = [...tenantAddress];
                  //   addresses[0].address_line1 = e.target.value;
                  //   setCreateLog({...createLog,address_line1:1});
                  //   setTenantAddress(addresses);
                  //   setFormData({ ...formData, addresses: addresses });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Address Line 2:
                </label>
              </div>
              <div className="md:w-4/5">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="addresses-address_line2"
                  type="text"
                  placeholder="Unit #5"
                  // value={formData?.addresses[0]?.address_line2}
                  // onChange={(e) => {
                  //   const addresses = [...tenantAddress];
                  //   setCreateLog({...createLog,address_line2:1});
                  //   addresses[0].address_line2 = e.target.value;
                  //   setTenantAddress(addresses);
                  //   setFormData({ ...formData, addresses: addresses });
                  // }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Country:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-country"
                    type="text"
                    placeholder="Albuquerque"
                    required={false}
                    // value={formData?.addresses[0]?.country}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].country = e.target.value;
                    //   setTenantAddress(addresses);
                    //   setCreateLog({...createLog,country:1})
                    //   setFormData({
                    //     ...formData,
                    //     addresses: addresses,
                    //   });
                    //   getStates(e.target.value);
                    // }}
                  >
                    <option value=" ">Select Country</option>
                    {/* {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    State:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-state"
                    required={false}
                    // value={formData?.addresses[0]?.state}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].state = e.target.value;
                    //   setTenantAddress(addresses);
                    //   setCreateLog({...createLog,state:1})
                    //   setFormData({
                    //     ...formData,
                    //     addresses: addresses,
                    //   });
                    //   getCities(e.target.value);
                    // }}
                  >
                    <option value=" ">Select State</option>
                    {/* {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))} */}
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    City:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-city"
                    type="text"
                    placeholder="Albuquerque"
                    required={false}
                    // value={formData?.addresses[0]?.city}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,city:1})
                    //   const addresses = [...tenantAddress];
                    //   const zip = cities?.map((col) =>{
                    //     if(col?.id == e.target.value)
                    //     {
                    //       return col?.zip_code
                    //     }
                    //   })
                    //   const filteredArr = zip.filter((value) => value !== undefined);
                    //   const zipCode = filteredArr.join(', ');
                    //   addresses[0].city = e.target.value;
                    //   addresses[0].zip_code = zipCode;
                    //   setTenantAddress(addresses);
                    //   setFormData({
                    //     ...formData,
                    //     addresses: addresses,
                    //   });

                    // }}
                  >
                    <option value=" ">Select City</option>
                    {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Zip:
                  </label>
                </div>
                <div className="md:w-2/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-zip"
                    type="text"
                    placeholder="90210"
                    required={false}
                    // value={formData?.addresses[0]?.zip_code}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].zip_code = e.target.value;
                    //   setTenantAddress(addresses);
                    //   setCreateLog({...createLog,zip_code:1})
                    //   setFormData({
                    //     ...formData,
                    //     addresses: addresses,
                    //   });
                    // }}
                  />
                </div>
              </div>
            </div>
            {/* </>
                );
              })} */}
            <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Number 1:
                </label>
              </div>
              <div className="md:w-3/5">
                {/* {formData?.numbers[0]?.number_type == 8 ? */}
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-l`}
                  type="tel"
                  placeholder="(999) 999-9999"
                  // value={number?.[0]?.phone_number}
                  // onChange={(e) => {
                  //   const phone = [...number];
                  //   setCreateLog({...createLog,phone_number_1:1});
                  //   phone[0].phone_number = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                  // maxLength={ownerSettings?.phone_number_format?.length}
                />
                {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[0]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
              </div>
              <div className="md:w-1/5 pl-3">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-1-type`}
                  // value={formData?.numbers[0]?.number_type}
                  // onChange={(e) => {
                  //   setCreateLog({...createLog,number_type_1:1});
                  //   const phone = [...number];
                  //   phone[0].number_type = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                >
                  <option value=" ">Select Type</option>
                  {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Number 2:
                </label>
              </div>
              <div className="md:w-3/5">
                {/* {formData?.numbers[1]?.number_type == 8 ? */}
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-2`}
                  type="tel"
                  placeholder="(999) 999-9999"
                  // value={number?.[1]?.phone_number}
                  // onChange={(e) => {
                  //   const phone = [...number];
                  //   setCreateLog({...createLog,phone_number_1:1});
                  //   phone[1].phone_number = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                  // maxLength={ownerSettings?.phone_number_format?.length}
                />
                {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[1]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
              </div>
              <div className="md:w-1/5 pl-3">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-2-type`}
                  // value={formData?.numbers[1]?.number_type}
                  // onChange={(e) => {
                  //   setCreateLog({...createLog,number_type_2:1});
                  //   const phone = [...number];
                  //   phone[1].number_type = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                >
                  <option value=" ">Select Type</option>
                  {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Number 3:
                </label>
              </div>
              <div className="md:w-3/5">
                {/* {formData?.numbers[2]?.number_type == 8 ? */}
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-3`}
                  type="tel"
                  placeholder="(999) 999-9999"
                  // value={number?.[2]?.phone_number}
                  // onChange={(e) => {
                  //   const phone = [...number];
                  //   setCreateLog({...createLog,phone_number_1:1});
                  //   phone[2].phone_number = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                  // maxLength={ownerSettings?.phone_number_format?.length}
                />
                {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={formatPhoneNumber(number?.[2]?.phone_number,ownerSettings?.phone_number_format)}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = removeNonNumeric(e.target.value);
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
              </div>
              <div className="md:w-1/5 pl-3">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`tenant-num-3-type`}
                  // value={formData?.numbers[2]?.number_type}
                  // onChange={(e) => {
                  //   setCreateLog({...createLog,number_type_3:1});
                  //   const phone = [...number];
                  //   phone[2].number_type = e.target.value;
                  //   setNumber(phone);
                  //   setFormData({ ...formData, numbers: phone });
                  // }}
                >
                  <option value=" ">Select Type</option>
                  {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                </select>
              </div>
            </div>

            <div className="md:flex md:items-center mt-3 mb-6">
              <div className="flex md:items-center w-full mr-1">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`email`}
                    type="email"
                    placeholder="john.doe@email.com"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3 mb-6">
              <div className="flex md:items-center w-full mr-1">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Website:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`url`}
                    type="url"
                    placeholder="www.example.com"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3 mb-6">
              <div className="flex md:items-center w-1/2 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Opening Balance:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3 mb-6">
              <div className="flex md:items-center w-1/2 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Account Number:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/2 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Routing Number:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Set as Default Deposit Account for Rent Payments?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    // value={formData?.hoa_show_to_tenant}
                    // onChange={(e) => {
                    //   const checkedValue = e.target.checked === true ? 1 : 0;
                    //   setFormData({
                    //     ...formData,
                    //     hoa_show_to_tenant: checkedValue,
                    //   });
                    // }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:w-full">
                <div className="md:w-auto">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Set as Default Deposit Account for Other Payments:
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    // value={formData?.hoa_show_to_tenant}
                    // onChange={(e) => {
                    //   const checkedValue = e.target.checked === true ? 1 : 0;
                    //   setFormData({
                    //     ...formData,
                    //     hoa_show_to_tenant: checkedValue,
                    //   });
                    // }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3 mb-6">
              <div className="flex md:items-center w-1/2 mr-1">
                <div className="md:w-3/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Account Number:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/3 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Expiration:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/4 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    CVC:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
              <div className="flex md:items-center w-1/4 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    APR:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`text`}
                    type="text"
                    // placeholder="$2890"
                    // value={formData?.email}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,email:1});
                    //   setFormData({ ...formData, email: e.target.value });
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-1/2 mt-3 mr-1">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Statement Closing Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
              <div className="flex items-center w-1/2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Statement Due Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex mt-4">
              <div className="md:w-1/3">
                <div className="flex">
                  {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Account Files:
                  </label>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="property-owner-files"
                        type="file"
                        // accept={fileTypes}
                        multiple={true}
                        hidden={true}
                        // onChange={(e) => {
                        //   let files = e.target.files;
                        //   let newImages = [];
                        //   let maxOrderNumber = 0;

                        //   // Find the highest order number in the current images
                        //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                        //     if (imageObject.order_number > maxOrderNumber) {
                        //       maxOrderNumber = imageObject.order_number;
                        //     }
                        //   });

                        //   // Calculate the total number of images (existing images + new images)
                        //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                        //   // Loop through each file and convert to base64
                        //   files.forEach((file, i) => {
                        //     const reader = new FileReader();
                        //     reader.addEventListener("load", async () => {
                        //       const fileBase64 = reader.result;
                        //       try {
                        //         const res = await ApiRequest.post(fileApi, {
                        //           file: fileBase64,
                        //         });

                        //         // Add the new image to the array with a unique order number
                        //         newImages.push({
                        //           file: res?.data?.data?.file,
                        //           title: res?.data?.data?.file,
                        //           url:fileBase64
                        //         });

                        //         // If all new images have been processed, update the state
                        //         if (newImages.length === files.length) {
                        //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                        //           fileLists = [...fileLists, ...newImages];
                        //           setPropertyOwnerDocumentsWithUrl(fileLists);
                        //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                        //           propertyCopy.files = fileLists.map(
                        //             (picObj) => ({
                        //               file: picObj.file,
                        //               title: picObj.title,
                        //             })
                        //           );
                        //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                        //         }
                        //       } catch (err) {
                        //         // console.log(err);
                        //       }
                        //     });
                        //     reader.readAsDataURL(file);
                        //   });
                        // }}
                      />
                    </label>
                  </div>
                  {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                </div>
              </div>
            </div>

            {/* Gallery  */}

            {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Account Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData}
                  // onChange={(e) => {
                  //   setEditorData(e.editor.getData());
                  //   // setPropertyMeasurement({...propertyMeasurement,notes:e.editor.getData()});
                  // }}
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-end items-center mt-3">
              <div class="flex justify-between items-center gap-4">
                <button type="button" class="btn btn3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
