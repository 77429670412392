import React, { useState, useRef, useEffect, useCallback } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll
} from "react-sortable-tree";
import "./treestyle.css";
import ApiRequest from "../utility/http";

import $ from 'jquery';
import Loading from "../components/Loader/Loading";
import Spinner from "../components/Spinner/Spinner";
import { useDropzone } from 'react-dropzone';
import { FileUploader } from "react-drag-drop-files";

function Tree() {
  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  // const [seed,setSeed] = useState();
  const [treeData, setTreeData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [createNodeModal, setCreateNodeModal] = useState(false);
  const [updateNodeModal, setUpdateNodeModal] = useState(false);
  const [childNodeModal, setChildNodeModal] = useState(false);
  const [siblingNodeModal, setSiblingNodeModal] = useState(false);
  const [activeNode, setActiveNode] = useState(null);
  const [loader,setLoader]= useState();
  const [spinner,setSpinner] = useState();
  const [getID,setGetID] = useState();
  const [templateID,setTemplateID] = useState();
  const [icon,setIcon] = useState();
  const [image,setImage] = useState(null);


const fileTypes = ["JPG", "PNG", "GIF"];
  // console.log('treeData',treeData);
  // console.log('GET ID',getID);
  // console.log('Template ID',templateID);
  // console.log('spinner',spinner);

  const [files, setFiles] = useState([]);
  console.log('files',files)
  const handleChange = (files) => {
    setFiles(files);
  };

  // const onDrop = useCallback((acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setImage({
  //       name: file.name,
  //       dataUrl: reader.result
  //     });
  //   };
  //   reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

  function handleNodeClick(event) {
      // $('.rst__row').removeClass('active');
      // $(`.rst__node`).each(function(){
      //   if($(this).find('.rst__row .rst__rowTitle').text() === nodeData2?.data?.name)
      //   {
      //     console.warn(true);
      //     $(this).find('.rst__row').addClass('active');
      //   }
      // })
      // ('.rst__row').removeClass('active');
      // $(document).on('click', '.rst__rowLabel', function() {
      //   $(this).css("cursor","pointer");
      //   $('.rst__row').removeClass('active')
      //   // var elementClass = $(this).attr('class');
      //     var parentElement = $(this).closest('.rst__node');
      //     parentElement.find('.rst__row').addClass('active');
      // });
      $(document).on('click', '.rst__rowContents .info', function() {
        $('.rst__rowContents').removeClass('active')
        // var elementClass = $(this).attr('class');
          var parentElement = $(this).closest('.rst__node');
          parentElement.find('.rst__rowContents').addClass('active');
      });
      
    
  }
  function addImage (array)
  {
    console.log("DATATATAT",array);
    $(`.rst__rowLabel`).addClass('flex');
    for (let i = 0; i < array?.length; i++) {
      // const index = $(`.rst__rowLabel:eq(${i})`);
      if(array[i].picture == "")
      {
        $(`.rst__rowLabel:eq(${i})`).prepend(`<img src="https://apis.rentblaze.com/public/uploads/640065553a002.png" style="width:25px"/>`);
      }
      else{

        $(`.rst__rowLabel:eq(${i})`).prepend(`<img src="https://apis.rentblaze.com/public/uploads/${array[i]?.picture}" style="width:25px"/>`);
      }
    }
    // treeData?.map((row)=>{
    //   $(`.rst__rowLabel`).prepend(`<img src="https://apis.rentblaze.com/public/uploads/${row?.picture}" style="width:25px" />`);
    // })
  }
  function handleCloseModal() {
    setShowModal(false);
  }

  function handleShowModal() {
    setShowModal(true);
  }
  function handleCreateCloseModal() {
    setCreateNodeModal(false);
  }

  function handleCreateShowModal() {
    setCreateNodeModal(true);
  }
  function handleUpdateCloseModal() {
    setUpdateNodeModal(false);
  }

  function handleUpdateShowModal() {
    setUpdateNodeModal(true);
  }
  function handleChildCloseModal() {
    setChildNodeModal(false);
  }

  function handleChildShowModal() {
    setChildNodeModal(true);
  }
  function handleSiblingCloseModal() {
    setSiblingNodeModal(false);
  }

  function handleSiblingShowModal() {
    setSiblingNodeModal(true);
  }


  
  const getTemplate = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-files-templates");
    setLoader(response.status)
    if (response.status == 200) {      
      setGetID(response?.data?.data?.templates);
    }
  };

  const singleData = async(id)=>{
    const Id = id;
    setSpinner();
    const res = await ApiRequest.get(`/public/api/auth/get-file-template?template_id=${Id}`);
    if(res.status =200)
    {
      setTreeData(res?.data?.data?.template?.folders)
      setSpinner(true);
      addImage(res?.data?.data?.template?.folders);
      setSearchString('');
      setNodeData2("");
    }
    // const data =  getID?.map((row)=>{
    //   if(row?.id == Id) {
    //     setTreeData(row?.folders)
    //   }
    // });
  }

  useEffect(() => {
    getTemplate();
    singleData();
    setIcon('640065553a002.png');
  }, [])
  

  const inputEl = useRef();


  const addNodeData = async(value,parent_id)=>{
    const response = await ApiRequest.post('/public/api/auth/file-template-folder',{
      name:value,
      order_number:1,
      parent_id:parent_id == 'null' || parent_id == 'undefined' ? '' : parent_id,
      picture_template_id:templateID,
      picture:icon,
    })
    if(response.status == 200)
    {
      getTemplate();
      singleData(templateID);
    }
  }
  const addChildNodeData = async(value,id)=>{
    const response = await ApiRequest.post('/public/api/auth/file-template-folder',{
      name:value,
      order_number:1,
      parent_id:id,
      picture_template_id:templateID,
      picture:icon
    })
    if(response.status == 200)
    {
      getTemplate();
      singleData(templateID);
    }
  }
  const UpdateNodeData = async(value,id,parent_id,order_number)=>{
    const response = await ApiRequest.post('/public/api/auth/file-template-folder',{
      id:id,
      name:value,
      order_number:order_number,
      parent_id:parent_id == 'null' ? '' : parent_id,
      picture_template_id:templateID,
      picture:icon
    })
    if(response.status == 200)
    {
      getTemplate();
      singleData(templateID);
    }
  }
  const createNode = ()=> {
    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: null,
      expandParent: true,
      getNodeKey,
      newNode: addNodeData(value,nodeData?.data?.parent_id)
    });


    setTreeData(newTree.treeData);

    inputEl.current.value = "";
  }

  function updateNode(rowInfo) {
    const { node, path } = rowInfo;
    const { children } = node;

    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: UpdateNodeData(value,nodeData?.data?.id,nodeData?.data?.parent_id,nodeData?.data?.order_number)
    });

    setTreeData(newTree);

    inputEl.current.value = "";
  }

  const addNodeChild = (rowInfo)=> {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: addChildNodeData(value,nodeData?.data?.id).then(response=>{console.log(response)})
    });

    console.log('newTree',newTree);

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  }

  function addNodeSibling(rowInfo) {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 2],
      expandParent: true,
      getNodeKey,
      newNode: addNodeData(value,nodeData?.data?.parent_id)
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  }
const deleteFolder = async(id)=>{
  const response = await ApiRequest.delete(`/public/api/auth/delete-file-template-folder?file_folder_id=${id}`)
  if(response.status == 200)
  {
    getTemplate();
    singleData(templateID);
  }
}
  function removeNode (rowInfo) {
    const { path } = rowInfo;
    deleteFolder(nodeData?.data?.id)
    // setTreeData(
    //   removeNodeAtPath({
    //     treeData,
    //     path,
    //     getNodeKey
    //   })
    // );
  }

  function updateTreeData(treeData) {
    let order_number = 1;
    const updateOrder = (node) => {
      node.order_number = order_number++;
      if (node.children) {
        node.children.forEach(updateOrder);
      }
    };
    treeData.forEach(updateOrder);
    setTreeData(treeData);
  }
  const onMoveNode = (node) => {
    const newParentId = node.parentNode ? node.parentNode.id : null;
    console.log("Parent ID",newParentId);
    const updatedTreeData = treeData.map((parent) => {
      if (parent.id === node.node.id) {
        return { ...node.node, parent_id: newParentId };
      }
      return parent;
    });
    setTreeData(updatedTreeData);
  };
  function expand(expanded) {
    setTreeData(
      toggleExpandedForAll({
        treeData,
        expanded
      })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }
const [nodeData,setNodeData] = useState({
  data:[],
});

console.log('NODEDATA',nodeData);
const [nodeData2,setNodeData2] = useState({
  data:[],
});
const [index,setIndex] =useState();
console.warn('index',index);
const [child,setChild] = useState();
console.warn('child',child);
console.warn('NodeData2',nodeData2);
  const alertNodeInfo = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
    .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
    .join(",");
    

      // const obj = JSON.parse("{"+objectString.replace(/'/g, '"') + "}")
      const newStr = objectString.replace(/'/g, '"');
      const obj = {};
      newStr.split(',').forEach((pair) => {
        const [key, value] = pair.split(':');
        obj[key.trim()] = value.replace(/"/g, '').trim();
      });

      setNodeData({...nodeData,data : obj})
      
    // global.alert(
    //   "Info passed to the icon and button generators:\n\n" +
    //     `node: {\n   ${objectString}\n},\n` +
    //     `path: [${path.join(", ")}],\n` +
    //     `treeIndex: ${treeIndex}`
    // );
  };
  const alertNodeInfo1 = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
    .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
    .join(",");
    

      // const obj = JSON.parse("{"+objectString.replace(/'/g, '"') + "}")
      // global.alert(
      //   "Info passed to the icon and button generators:\n\n" +
      //     `node: {\n   ${objectString}\n},\n` +
      //     `path: [${path.join(", ")}],\n` +
      //     `treeIndex: ${treeIndex}`
      // );
      const newStr = objectString.replace(/'/g, '"');
      const obj = {};
      newStr.split(',').forEach((pair) => {
        const [key, value] = pair.split(':');
        obj[key.trim()] = value.replace(/"/g, '').trim();
      });

      setNodeData2({...nodeData2,data : obj})
      setIndex(treeIndex);
  };

  const getChild = ()=>{
    treeData.map((tree)=>{

      if(tree?.id == nodeData2?.data?.id)
      {
        setChild(tree)
      }
      tree?.children.map((child)=>{
        if(child?.id == nodeData2?.data?.id)
        {
          setChild(child)
        }
      })
    })
    console.log('true');
  }

  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  return (
    <>
    {loader === undefined ? <Loading/> :
   
    <div>
      <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Template:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`Template`}
                      value={templateID}
                      onChange={(e) => {
                        setTemplateID(parseInt(e.target.value));
                        const id = e.target.value == undefined ? "1" : e.target.value;
                        singleData(parseInt(id));
                      }}
                    >
                      <option value="undefined">Select Template</option>
                      {getID?.map((row) => (
                        <option value={row?.id}>{row?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {
                spinner == undefined ? <Spinner/> :
                treeData != undefined ? 
      <>
      <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
        {/* <h2 className="text-center text-lg font-bold">Rent Blaze Tree View</h2> */}
        <br />
        {/* <button onClick={handleCreateShowModal} className="bg-green-300 px-4 align-items-center rounded text-black-800 mt-1" data-bs-toggle="modal"
                data-bs-target='#staticBackdrop1'>Create Node</button> */}
        {createNodeModal ?
        <div class="fixed z-10 inset-0 overflow-hidden" style={{marginTop:"15%"}}>
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Create Node</h3>
              <div class="mt-2">
                <input className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Add Node Name" ref={inputEl} type="text" />
                </div>
                <div className="flex w-full">
                <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              // setFormData({
                              //   ...formData,
                              //   dl_id_picture: res?.data?.data?.image,
                              // });
                              setIcon(res?.data?.data?.image);
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img src={`https://apis.rentblaze.com/public/uploads/${icon}`} alt="icon" width={50} className="px-3 w-2/12 h-1/6 pt-3"/>
                    </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={()=>{createNode();handleCreateCloseModal()}}>Add Node</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleCreateCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :null}
        <br />
        <button onClick={expandAll} className="bg-green-300 px-4 mx-1 align-items-center rounded text-black-800">Expand All</button>
        <button onClick={collapseAll} className="bg-green-300 px-4 align-items-center rounded text-black-800">Collapse All</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <form
          style={{ display: "inline-block" }}
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <label htmlFor="find-box">
            Search:&nbsp;
            <input
              id="find-box"
              type="text"
              placeholder="Search"
              className="mr-1 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
            />
          </label>

          <button
            type="button"
            className="mr-1"
            disabled={!searchFoundCount}
            onClick={selectPrevMatch}
          >
            <i class="fa fa-angle-left"></i>
          </button>

          <button
            type="button"
            disabled={!searchFoundCount}
            onClick={selectNextMatch}
          >
            <i class="fa fa-angle-right"></i>
          </button>

          <span>
            &nbsp;
            {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
            &nbsp;/&nbsp;
            {searchFoundCount || 0}
          </span>
        </form>
      </div>

      {treeData.length != 0 ? 
      <div style={{ height: "100vh" }}>
        <SortableTree
          treeData={treeData}
          onChange={(treeData) => updateTreeData(treeData)}
          onMoveNode={onMoveNode}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={(matches) => {
            setSearchFoundCount(matches.length);
            setSearchFocusIndex(
              matches.length > 0 ? searchFocusIndex % matches.length : 0
            );
          }}
          canDrag={({ node }) => !node.dragDisabled}
          onClick={(e)=>{handleNodeClick(e)}}
          generateNodeProps={(rowInfo) => ({
            // title: rowInfo.node.label,
            // subtitle: rowInfo.node.subTitle,
         
            buttons: [
              <div>
                <button
                  label="Add Sibling"
                  // className="bg-green-300 align-items-center rounded text-black-800"
                  className="px-3 align-items-center"
                  onClick={(event) => {alertNodeInfo(rowInfo);handleSiblingShowModal(event)}}

                >
                  <i className="fa fa-folder" title="Add Sibling"></i>
                </button>
                {siblingNodeModal ?
        <div class="fixed z-10 inset-0 overflow-hidden">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Sibling Node</h3>
              <div class="mt-2">
                <input className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Add Node Name" ref={inputEl} type="text" />
                </div>
                <div className="flex w-full">
                <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              // setFormData({
                              //   ...formData,
                              //   dl_id_picture: res?.data?.data?.image,
                              // });
                              setIcon(res?.data?.data?.image);
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img src={`https://apis.rentblaze.com/public/uploads/${icon}`} alt="icon" width={50} className="px-3 w-2/12 h-1/6 pt-3"/>
                    </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={()=>{addNodeSibling(rowInfo);handleSiblingCloseModal()}}>Add Sibling</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleSiblingCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :null}
                <button
                  label="Add Child"
                  // className="bg-green-300 px-4 align-items-center rounded text-black-800 mx-3"
                  className="px-3 align-items-center"
                  onClick={(event) => {alertNodeInfo(rowInfo);handleChildShowModal(event);}}
                >
                  <i className="fa fa-folder" title="Add Child"></i>
                </button>
                {childNodeModal ?
        <div class="fixed z-10 inset-0 overflow-hidden">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Child Node</h3>
              <div class="mt-2">
                <input className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Add Node Name" ref={inputEl} type="text" />
                </div>
                <div className="flex w-full">
                <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              // setFormData({
                              //   ...formData,
                              //   dl_id_picture: res?.data?.data?.image,
                              // });
                              setIcon(res?.data?.data?.image);
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img src={`https://apis.rentblaze.com/public/uploads/${icon}`} alt="icon" width={50} className="px-3 w-2/12 h-1/6 pt-3"/>
                    </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={()=>{addNodeChild(rowInfo);handleChildCloseModal()}}>Add Child</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleChildCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :null}
                <button label="Update" 
                // className="bg-green-300 px-4 align-items-center rounded text-black-800"
                className="px-3 align-items-center"
                  data-bs-toggle="modal"
                data-bs-target='#staticBackdrop2'
                onClick={(event) => {alertNodeInfo(rowInfo);handleUpdateShowModal(event);}}>
                  	<i className="fa fa-edit" title="Update"></i>
                </button>
                {updateNodeModal ?
        <div class="fixed z-10 inset-0 overflow-hidden">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Update Node</h3>
              <div class="mt-2">
                <input className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Update Name" ref={inputEl} type="text" />
                </div>
                <div className="flex w-full">
                <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              // setFormData({
                              //   ...formData,
                              //   dl_id_picture: res?.data?.data?.image,
                              // });
                              setIcon(res?.data?.data?.image);
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img src={`https://apis.rentblaze.com/public/uploads/${icon}`} alt="icon" width={50} className="px-3 w-2/12 h-1/6 pt-3"/>
                    </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={()=>{updateNode(rowInfo);handleUpdateCloseModal()}}>Update Node</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleUpdateCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :null}
                <button label="Delete" 
                // className="bg-green-300 px-4 align-items-center rounded text-black-800 mx-3"
                className="px-3 align-items-center"
                data-bs-toggle="modal"
                data-bs-target='#staticBackdrop'
                onClick={(event) => {alertNodeInfo(rowInfo);handleShowModal(event)}}>
                  <i className="fa fa-remove" title="Remove"> </i>
                </button>
                {showModal && (
        <div class="fixed z-10 inset-0 overflow-hidden">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Remove</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Are you want to sure.</p>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={(event) =>{removeNode(rowInfo);handleCloseModal(event)}}>Remove</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      
      )}      
                {/* <button
                  label="Alert"
                  // className="bg-green-300 px-4 align-items-center rounded text-black-800 info"
                  className="info px-3 align-items-center"
                  onClick={(event) => {alertNodeInfo1(rowInfo);handleNodeClick(event)}}
                >
                  <i className="fa fa-info-circle" title="Info"></i>
                </button> */}
              </div>
            ],
            style: {
              height: "50px"
            }
          })}
        />
    {/* <button className="bg-green-200" onClick={handleNodeClick}>Detail</button> */}
        
      </div>:"No Result Found"}
      </>:"Please Select Template First"}
    </div>}
    {nodeData2?.data?.name != undefined ?  <div className="info" style={{border: "1px solid black",
}}>
          {/* <h2 className="font-bold text-center"></h2> */}
          {/* <div
            draggable="true" onDragStart={(e) => console.log('Dragging started!')}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ width: '300px', height: '300px', border: '2px dashed gray',alignItems:"center" }}
          >
            {image ? (
              <>
                <img src={image.dataUrl} alt={image.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <p>{image.name}</p>
              </>
            ) : (
              <p>Drag and drop an image here</p>
            )}
          </div> */}
          {/* <DragDropFile /> */}
          {/* <div {...getRootProps()} style={{ width: '300px', height: '300px', border: `2px dashed ${isDragActive ? 'green' : 'gray'}` }}>
      <input {...getInputProps()} />
      {image ? (
        <>
          <img src={image.dataUrl} alt={image.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
          <p>{image.name}</p>
        </>
      ) : (
        <p>Drag and drop an image here</p>
      )}
    </div> */}
    <div>
      <h1>{nodeData2?.data?.name}</h1>
      <FileUploader handleChange={handleChange} multiple name="file" types={fileTypes} />
      {files?.map((row) => (
        <>
          {/* <img
            key={row.name}
            src={URL.createObjectURL(row)}
            alt={row.name}
            style={{ maxWidth: 300 }}
          /> */}
          <p>{row ? `File name: ${row?.name}` : "no files uploaded yet"}</p>
          </>
        ))}
    </div>
        </div>:null}
     </>
  );

}

export default Tree;
