import React from "react";

export const FinanceInvoiceTemplate = () => {
  return (
    <>
      <div className="items-center w-[90%] m-[auto] mt-4">
        <div className="flex justify-between items-center">
          <div className="flex ">
            <div className="flex w-1/5">
              <img
                className="logo w-9/12 mr-5"
                src="/assets/svgs/property.svg"
                alt=""
              />
            </div>
            <div className="flex-col w-1/2">
              <label className="block uppercase tracking-wider text-gray-700 text-2xl font-bold mb-2">
                new heights
              </label>
              <p className="block tracking-normal text-gray-700 text-base font-semibold mb-1">
                8320 North Peyton Way
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                West Hills, CA 91304
              </p>
            </div>
          </div>
          <div className="flex-col text-right ">
            <label className="block uppercase tracking-wider text-gray-700 text-2xl font-bold mb-2">
              INV001256
            </label>
            <p className="block tracking-normal uppercase text-gray-700 text-base font-semibold mb-1">
              invoice date: 7/29/2021
            </p>
            <p className="block tracking-normal uppercase text-gray-700 text-base font-semibold ">
              room b
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex-col">
            <div className="flex-col items-center ">
              <label className="block tracking-normal capitalize text-gray-700 text-base font-semibold mb-1">
                Submit Payment to:
              </label>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                Chris Knabenshue
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                18034 Ventura Blvd. #484
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                Encino, CA 91316
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold ">
                chri@saviodesigns.com
              </p>
            </div>
            <div className="flex-col items-center mt-5">
              <label className="block tracking-normal uppercase text-gray-700 text-xl font-bold mb-1">
                DUE DATE: 8/15/2021
              </label>
              <p className="tracking-normal text-gray-700 text-base font-bold">
                Late fees may be charged if not paid in full by the Due Date!
              </p>
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-col text-right items-center">
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                Sammye Alix
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                sammye@gmail.com
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                (652) 854-4412
              </p>
            </div>
            <div className="flex-col text-right items-center mt-4">
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                Oscar Rojas
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                oscar@gmail.com
              </p>
              <p className="block tracking-normal text-gray-700 text-base font-semibold">
                (718) 254-2546
              </p>
            </div>
          </div>
        </div>
        <div className="flex-col mt-4">
          <label className="tracking-normal text-gray-700 text-xl font-bold">
            RENT: AUGUST 2021
          </label>
          <table className="w-full items-center mt-4">
            <thead className="bg-gray-500 text-white h-10">
              <tr>
                <th className="text-left capitalize text-base font-semibold">
                  item
                </th>
                <th className="text-left w-1/2 capitalize text-base font-semibold">
                  description
                </th>
                <th className="text-left capitalize text-base font-semibold">
                  amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-10">
                <td>Monthly Rent</td>
                <td></td>
                <td>$1,250.00</td>
              </tr>
              <tr className="bg-gray-200 h-10">
                <td>Electric Bill</td>
                <td></td>
                <td>$263.53</td>
              </tr>
              <tr className="h-10">
                <td>Water Bill </td>
                <td></td>
                <td>$75.23</td>
              </tr>
              <tr className="bg-gray-200 h-10">
                <td>Other Bill </td>
                <td>Cleaning Fee </td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex-col float-right mr-10 w-1/5 mt-4">
          <div className="flex justify-between tracking-normal text-gray-700 text-base font-semibold">
            <p>Subtotal:</p>
            <p>$1,603.76</p>
          </div>
          <div className="flex justify-between tracking-normal text-gray-700 text-base font-semibold">
            <p>Sales Tax:</p>
            <p>$10.00</p>
          </div>
          <div className="flex justify-between tracking-normal text-gray-700 text-xl font-bold">
            <p>TOTAL:</p>
            <p>$1,613.76</p>
          </div>
        </div>
        <div className="flex-col m-auto w-9/12 mt-36">
          <label className="tracking-normal text-gray-700 text-base font-bold">
            INVOICE NOTES:
          </label>
          <p className="tracking-normal text-gray-700 text-base font-semibold mt-2">
            This is a sample of the notes that might be on the invoice. This box
            would expand to fit all the notes that might be written. The box
            would appear on the invoice even if there were no notes.
          </p>
          <div className="flex justify-between w-7/12 mt-2">
            <label className="tracking-normal text-gray-700 text-base font-bold">
              INVOICE FILES:
            </label>
            <p className="tracking-normal text-gray-700 text-base font-semibold">
              WATERBILL.pdf
            </p>
            <p className="tracking-normal text-gray-700 text-base font-semibold">
              ELECTRICBILL.pdf
            </p>
            <p className="tracking-normal text-gray-700 text-base font-semibold">
              CLEANING BILL
            </p>
          </div>
        </div>
        <div className="flex-col mt-4">
          <label className="tracking-normal text-gray-700 text-xl font-bold">
            PAYMENT OPTIONS:
          </label>
          <p className="tracking-normal text-gray-700 text-base font-bold">
            To remit payment, please send a check to owner or pay digitally
            using Venmo @CLDSK or Zelle
          </p>
        </div>
        <hr className="mx-auto border-2 border-black mt-4" />
        <div className="flex-col mt-4">
          <label className="text-xl tracking-normal text-gray-700 font-bold">
            PAYMENT HISTORY
          </label>
          <table className="w-full items-center mt-4">
            <thead className="bg-gray-500 text-white h-10">
              <tr>
                <th className="text-left capitalize text-base font-semibold">
                  payement date
                </th>
                <th className="text-left capitalize text-base font-semibold">
                  payement iD
                </th>
                <th className="text-left capitalize text-base font-semibold">
                  amount
                </th>
                <th className="text-left capitalize text-base font-semibold">
                  type
                </th>
                <th className="text-left capitalize text-base font-semibold">
                  payement notes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-10">
                <td>8/10/2021</td>
                <td>120445</td>
                <td>$200</td>
                <td>Check</td>
                <td>1675</td>
              </tr>
              <tr className="bg-gray-200 h-10">
                <td>8/12/2021</td>
                <td>120448</td>
                <td>$200 From multiple invoice payment of $1000</td>
                <td>Zelle</td>
                <td></td>
              </tr>
              <tr className="h-10">
                <td>8/15/2023</td>
                <td>120469</td>
                <td>$200</td>
                <td>Cash</td>
                <td>From Oscar</td>
              </tr>
            </tbody>
          </table>
          <div className="flex w-1/5 float-right font-bold justify-between mt-4 mb-3 mr-10">
            <p className="text-xl capitalize tracking-normal text-gray-700">
              Balance:
            </p>
            <p className="text-xl text-gray-700">$1,013.76</p>
          </div>
        </div>
      </div>
    </>
  );
};
