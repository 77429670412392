import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import pdf from "../../Images/pdf.png";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/button.css";
import "./UnitForm.css";
import { properties } from "../../store/properties/propertiesThunk";
import { useDispatch, useSelector } from "react-redux";
import { getUnitesStatuses } from "../../store/units/unitThunk";
import {
  getUnitMeasurement,
  getUnitMeasurementTypes,
} from "../../store/measurement/Thunk";
import { useParams } from "react-router-dom";
import imgUrl from "../ImageUrl";
import Loading from "../Loader/Loading";
import { UnitMeasurements } from "./UnitMeasurements";
import { UnitMaterials } from "./UnitMaterials";
import { UnitBoards } from "./UnitBoards";
import { UnitNotifications } from "./UnitNotifications";
import FrontTree from "../../tree/FrontTree";
import { UnitLocations } from "./UnitLocations";
import { Lightbox } from "../lightbox/Lightbox";
import { Notes } from "../property/Notes";
import Swal from "sweetalert2";
import { Accordions } from "../multiPurposeComponent/Accordions";
import { CarouselWithLightbox } from "../carousel/CarouselWithLightbox";
import { Tooltip } from "../Loader/Tooltip";
export const ShowUnitForm = ({
  initialData = {},
  verticalScroll = 0,
  updateFormData,
}) => {
  //  CONSTANTS
  // console.log("initialData",initialData)
  const formElement = document.getElementById("add-property-form");
  const formSubmitButton = document.getElementById("form-submit-button");

  const dispatch = useDispatch();
  const state = useSelector((state) => state?.properties?.properties?.data);
  const measurementUnit = useSelector(
    (state) =>
      state?.GetUnitMeasurement?.measurementUnit?.data?.measurements_units
  );
  const measurementType = useSelector(
    (state) => state?.GetUnitMeasurement?.UnitMeasurementTypes?.data
  );
  console.log("measurementUnit", measurementUnit);
  console.log("measurementType", measurementType);

  const [features,setFeatures] = useState([]);
	const [addFeature, setAddFeature] = useState([]);
	const [utilities, setUtilities] = useState();
  const [ckEditor, setCkEditor] = useState();
  const [ckEditor1, setCkEditor1] = useState();
  const [ckEditor2, setCkEditor2] = useState();
  const [ckEditor3, setCkEditor3] = useState();
  const [uniNotesFilesCkEditor, setUniNotesFilesCkEditor] = useState();
  const [propertyParkingSpaces, setPropertyParkingSpaces] = useState([]);
  const [publicNotes,setPublicNotes] = useState([]);
  const [staffNotes,setStaffNotes] = useState([]);
  const [managerNotes,setManagerNotes] = useState([]);
  const [ownerNotes,setOwnerNotes] = useState([]);
  const [files, setFiles] = useState();
  console.log("files", files);
  console.log(ckEditor);
  console.log(uniNotesFilesCkEditor);

  const unitStatuses = useSelector(
    (state) => state?.unitStatuses?.unitStatutes?.data
  );
  console.log("unitStatuses", unitStatuses);

  const [floorData] = useState(() => {
    const numberArray = [];
    for (let i = -5; i <= 200; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });
  const [bedRoomsData] = useState(() => {
    const optionsArray = ["Shared","Other","Studio"];
    for (let i = 1; i <= 200; i++) {
      optionsArray.push(i + " bedrooms");
    }
    // for (let x = 1; x<=14; x++)
    // {
    //   optionsArray.push(x + " studio");
    // }
    return optionsArray;
  });
  const [bathRoomsData] = useState(() => {
    const optionsArray = [];
    for (let i = 0.5; i <= 50; i+=0.5) {
      optionsArray.push(i + " bathrooms");
    }
    return optionsArray;
  });
  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialContactData = {
    firstname: "",
    lastname: "",
    title: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    email: "",
    info: "",
    picture: null,
  };
  const initialAddUnitData = {
    unitname: "",
    listingactive: "",
    property: "",
    floor: "",
    status: "",
    type: "",
    bedroom: "",
    bathroom: "",
    stairstounit: "",
    stairsinunit: "",
    elevatortounit: "",
    elevatorinunit: "",
    wifinetwork: "",
    wifipassword: "",
    unitalarmcode: "",
    unitgatecode: "",
    unitdoorcode: "",
    unitmailinfo: "",
    unitpropertyinfo: "",
  };

  const initialMeasurementData = {
    name: "",
    location: "",
    type: "",
    measurement_unit: "ft",
    measurement_unit_id:1,
    unit_location_id:1,
    length: "",
    width: "",
    height: "",
    depth: "",
    area: "",
    volume: "",
    pictures: [],
    files: [],
    notes: "",
  };

  const initialMaterialData = {
    material_type_id: "",
    material_location_id: "",
    material_brand_id: "",
    material_identifier_id: "",
    material_vendor_id: "",
    last_installed: "",
    pictures: [
      // {
      // 	picture: null,
      // 	order_number: "",
      // },
    ],
    files: [
      // {
      // 	file: null,
      // },
    ],
    notes: "",
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };

  const initialBoardData = {
    title: "",
    icon: "",
    board: "",
  };

  // STATES

  const [lastEndLine, setLastEndLine] = useState();
  const [unitTypes, setUnitTypes] = useState([]);
  const [addUnit, setAddUnites] = useState([]);
  const [unitMeasurementsType, setUnitMeasurementsTypes] = useState([]);
  const [genderPreference, setGenderPreference] = useState([]);
  // console.log("unitMeasurementsType", unitMeasurementsType);
  const [noticePopUp, setNoticePopUp] = useState([]);
  const [unitMaterialTypes, setUnitMaterialTypes] = useState([]);
  // console.log("unitMaterialTypes", unitMaterialTypes);
  const [unitMaterialLocations, setUnitMaterialLocations] = useState([]);
  const [unitMaterialBrands, setUnitMaterialBrands] = useState([]);
  const [unitMaterialIdentifiers, setUnitMateriaIdentifiers] = useState([]);
const [loading,setLoading] = useState();
  const [newUnitType, setNewUnitType] = useState({
    id: null,
    type: "",
  });

  const [newMaterialType, setNewMaterialType] = useState({
    id: null,
    type: "",
  });

  console.log("newMaterialType", newMaterialType);
  const [newMaterialLocation, setNewMaterialLocation] = useState({
    id: null,
    location: "",
  });
  const [newMaterialBrand, setNewMaterialBrand] = useState({
    id: null,
    brand: "",
  });
  const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
    id: null,
    identifier: "",
  });

  const [formData, setFormData] = useState();
  const [securedFields, setSecuredFields] = useState({
    propertyWifiPassword: "password",
    propertyAlarmCode: "password",
    propertyGateCode: "password",
    propertyDoorCode: "password",
  });
  console.log("formData", formData);

  const [propertyFormImagesWithUrl, setPropertyFormImagesWithUrl] = useState(
    []
  );

  const [propertyContacts, setPropertyContacts] = useState([]);
  const [propertyAssistantPictures, setPropertyAssistantPictures] = useState(
    []
  );

  const [unitMeasurements, setUnitMeasurements] = useState([]);

  const [measurementsTypes, setMeasurementsTypes] = useState([]);
  // console.log("measurementsTypes", measurementsTypes);
  const [unitMeasurementsPictures, setUnitMeasurementsPictures] = useState([]);
  const [unitMeasurementsFiles, setUnitMeasurementsFiles] = useState([]);

  const [unitMaterials, setUnitMaterials] = useState([]);
  const [unitMaterialPictures, setUnitMaterialPictures] = useState([]);
  const [unitMaterialFiles, setUnitMaterialFiles] = useState([]);

  const [propertyHoaFiles, setPropertyHoaFiles] = useState([]);
  const [propertyMortageFiles, setPropertyMortageFiles] = useState([]);
  const [propertyBuilderFiles, setPropertyBuilderFiles] = useState([]);
  const [unitPublicFiles, setUnitPublicFiles] = useState([]);
  const [unitStaffFiles, setUnitStaffFiles] = useState([]);
  const [unitNotesAndFiles, setUnitNotesAndFiles] = useState([]);
  const [unitListingInfo, setUnitListingInfo] = useState([]);
  const [allUtilities, setAllUtilities] = useState([]);
  const [utilitiesIncluded, setUtilitiesIncluded] = useState([]);
  const [utilitiesAddition, setUtilitiesAddition] = useState([]);
  const [handleFile,setHandleFile] = useState();
  const [templateID,setTemplateID] = useState();
  const [unitLocations,setUnitLocations] = useState([]);
  console.log("HandleFile",handleFile);
  console.log("templateID",templateID);
  // console.log('handleFile',handleFile);
  const handlePicturesData = (message) => {
    setPropertyFormImagesWithUrl(message);
    let unitCopy = [...message]; // Use the updated 'message' parameter instead of 'propertyFormImagesWithUrl'
    unitCopy.pictures = message.map((picObj, index) => ({
      picture: picObj.picture,
      title: picObj.title,
      caption: picObj.caption,
      order_number: index + 1, // Update the order number to ensure consecutive numbers
      is_cover: picObj.is_cover,
    }));
    setFormData({ ...formData, pictures: unitCopy?.pictures });
  };
  const handleFiles = (message)=>{
    setHandleFile(message);
  }
  const getPictureTemplateID = (message)=>{
    setTemplateID(message);
  }
  const handleLocationTrue=(message)=>{
    setUnitLocations(message);
  }
  // console.log("allUtilities", allUtilities);

  // console.log("unitPublicFiles", unitPublicFiles);
  // console.log("unitStaffFiles", unitStaffFiles);
  const [unitNotices, setUnitNotices] = useState([]);
  const [unitBoardsData, setUnitBoardsData] = useState([]);
  // console.log("unitBoardsData", unitBoardsData);
  const [
    propertyClaimResolutionDocumentsWithUrl,
    setPropertyClaimResolutionDocumentsWithUrl,
  ] = useState([]);

  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [unitManagerFiles, setUnitManagerFiles] = useState([]);
  // console.log("unitManagerFiles", unitManagerFiles);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [unitOnwerFiles, setUnitOnwerFiles] = useState([]);
  // console.log("unitOnwerFiles", unitOnwerFiles);
  const [unitBoardsIcon, setUnitBoardsIcon] = useState();
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [result,setResult] = useState();
	const [dateTaken,setDateTaken] = useState(new Date())
  const handleMeasurementTrue=(message)=>{
    setUnitMeasurements(message);
  }
  const handleMaterialTrue=(message)=>{
    setUnitMaterials(message);
  }
  const handleBoardTrue=(message)=>{
    setUnitBoardsData(message);
  }
  const handleNotificationTrue=(message)=>{
    setUnitNotices(message);
  }
  const handleNoteTrue = (message)=>{
    setPublicNotes(message)
  }
  const handleStaffNoteTrue = (message)=>{
    setStaffNotes(message)
  }
  const handleManagerNoteTrue = (message)=>{
    setManagerNotes(message)
  }
  const handleOwnerNoteTrue = (message)=>{
    setOwnerNotes(message)
  }
  //  METHODS
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);
  
    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;
  
    return TotalTime;
    };
    function stringToTime(timeString) {
    console.warn("timeString",timeString)
    if(timeString === null || timeString === undefined || timeString >= 0) return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1]; 
    let dayNight = ampm[1];

    let time = hours+":"+minutes+" "+dayNight
    let isPM = false;

    if(time.endsWith("am")){
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time

  }
    const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
    };
    function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if(dateString === null || dateString === undefined || dateString >= 0) return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year+"-"+month +"-"+day;
    // console.warn("UnformatDate",date)
    return date;
  }
  const DateCalculator = (date)=>{
    const currentDate = new Date();
    const cDate = new Date(date);
    const diffInMilliseconds = currentDate - cDate;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInMonths / 12;
    const years = Math.floor(diffInYears);
    const months = Math.floor((diffInYears - years) * 12);
    const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
    const StringYear = years === 1 ? 'year' : "years";
    const StringMonth = months === 1 ? 'month' : "months";
    const StringDay = days === 1 ? 'day' : "days";
    var result;
    if (days === 0 && years !== 0 && months !== 0) {
      result = years+" "+StringYear+", "+months+" "+StringMonth;
      } 
      else if (years === 0 && months !== 0 && days !== 0) {
      result = months+" "+StringMonth+", "+ days+" "+ StringDay;
      } 
      else if (months === 0 && years !== 0 && days !== 0) {
      result = years+" "+ StringYear+", "+ days +" "+StringDay;
      } 
      else if(days === 0 && months === 0 && years !==0)
      {
      result = years +" "+StringYear
      }
      else if(days === 0 && years === 0 && months !==0)
      {
      result = months+" "+ StringMonth
      }
      else if(years === 0 && months === 0 && days !==0)
      {
      result = days+" "+ StringDay
      }
      else if (years !== 0 || months !== 0 || days !== 0) {
      result = years+" "+ StringYear+", "+months +" "+StringMonth+", "+ days +" "+StringDay;
      } 

      else {
      result = "";
      } 
      return result;
  }
  const removeByAttr = (arr, attr, value)=>{
		var i = arr.length;
		while(i--){
		   if( arr[i] 
			   && arr[i].hasOwnProperty(attr) && (arr[i][attr] === value ) ){ 
	
			   arr.splice(i,1);
	
		   }
		}
		return arr;
	}
  const base64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        formData?.unit_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        // console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };

  const staffFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        // console.log("res", res?.data?.data.file);
        //  console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()
        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        //  setUnitPublicFiles({...unitPublicFiles, unit_files:{file:res?.data?.data?.file}});
        formData?.unit_staff_files?.push({ file: res?.data?.data?.file });

        //  setFormData({...formData, unit_files :unitPublicFiles})
        // console.log("base64converted files",files)
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };

  const unitManagerFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        // console.log("res", res?.data?.data.file);

        formData?.unit_manager_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        // console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };
  const unitOnwerFIleBase64Converter = async (file1, index) => {
    console.log("file 237", file1);
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      console.log(imgBase64);
      try {
        const res = await ApiRequest.post("public/api/auth/upload-file", {
          file: imgBase64,
        });
        // console.log("res", res?.data?.data.file);

        formData?.unit_manager_files?.push({ file: res?.data?.data?.file });
      } catch (err) {
        console.log("error in upload-file api");
      }
    });
    reader.readAsDataURL(file1);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };

  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };

  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit board icon"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={`${imgUrl}/${image}`}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;

  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };

  // APIs
  const getUnitTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-all-units-types"
    );
    if (response.status == 200) {
      setUnitTypes(response.data?.data);
    }
  };

  const getUnitMeasurementTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-measurement-types"
    );
    if (response.status == 200) {
      setUnitMeasurementsTypes(response?.data?.data);
    }
  };

  // const getGenderPreferences = async () => {
  //   window?.alert()
  //   const response = await ApiRequest.get(
  //     "public/api/auth/get-genders-preferences"
  //   );
  //   console.log("here")
  //   console.log("response?.data?.genders_preferences",response)
  //   if (response.status==200) {
  //     setGenderPreference(response?.data?.data);
  //   }
  // };

  const getGenderPreferences2 = async () => {
    try {
      const res = await ApiRequest.get(
        "/public/api/auth/get-genders-preferences"
      );
      console.log("response?.data?.data", res?.data?.data?.genders_preferences);
      setGenderPreference(res?.data?.data?.genders_preferences);
    } catch (err) {
      // console.log("get-genders-preferences api has error");
    }
  };
  const getFeatures = async ()=>{
		const response = await ApiRequest.get(
			"/public/api/auth/get-features-for-units"
		);
		if(response.status == 200)
		{
			setFeatures(response?.data?.data?.features)
		}
	}

  const getUtilities = async () => {
    try {
      const res = await ApiRequest.get("/public/api/auth/get-all-utilities");
      console.log("res.data", res?.data?.data?.utilities);
      setAllUtilities(res?.data?.data?.utilities);
    } catch (err) {
      // console.log("get-all-utilities api has error");
    }
  };
  const PropertyID =parseInt(localStorage.getItem("Property ID"));
  const getPropertyParkingSpaces = async ()=>{
    const response = await ApiRequest.get(`public/api/auth/get-parking-spaces-by-property?property_id=${PropertyID}`);
    if(response.status == 200){
      setPropertyParkingSpaces(response?.data?.data);
    }
  }
  const [parkingSpacePrefix, setParkingSpacePrefix] = useState([]);
const [parkingSpaceSuffix, setParkingSpaceSuffix] = useState([]);
  const getParkingSpacePrefixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-prefixes"
    );
    if (response.status == 200) {
      setParkingSpacePrefix(response?.data?.data?.prefixes);
    }
  };
  const getParkingSpaceSuffixes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-parking-space-suffixes"
    );
    if (response.status == 200) {
      setParkingSpaceSuffix(response?.data?.data?.suffixes);
    }
  };
  const getUnitMaterialsTypes = async () => {
    const response = await ApiRequest.get("public/api/auth/get-material-types");
    if (response.status == 200) {
      setUnitMaterialTypes(response?.data?.data);
    }
  };
  const getUnitMaterialLocations = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-units-locations"
    );
    if (response.status == 200) {
      setUnitMaterialLocations(response.data?.data);
    }
  };

  const getUnitMaterialBrands = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-brands"
    );
    if (response.status == 200) {
      setUnitMaterialBrands(response.data?.data);
    }
  };

  const getUnitMateriaIdentifiers = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-identifiers"
    );
    if (response.status == 200) {
      setUnitMateriaIdentifiers(response.data?.data);
    }
  };

  const addEditUnitType = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/measurement-type",
      payload
    );
    if (response.status == 200) {
      getUnitMeasurementTypes();
    }
  };
  const addEditMaterialsType = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/material-type",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialsTypes();
    }
  };

  const addEditUnitLocation = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/units-location",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialLocations();
    }
  };
  function removeCurrencyAndCommas(str) {
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  function convertCurrencyToFormat(currency, number) {
    let formattedCurrency = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
    }).format(number);
  
    return formattedCurrency;
  }
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }

  const addEditMaterialBrand = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-brand",
      payload
    );
    if (response.status == 200) {
      getUnitMaterialBrands();
    }
  };

  const addEditMaterialIdentifier = async (payload) => {
    const response = await ApiRequest.post(
      "public/api/auth/material-identifier",
      payload
    );
    if (response.status == 200) {
      getUnitMateriaIdentifiers();
    }
  };
  const [smoking,setSmoking] = useState([]);
  const getSmoking = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-smoking-permissions')
    {
      setSmoking(response?.data?.data?.smoking_permissions);
    }
  }
  const {id} =useParams();
  const getApi = async ()=>{
        const response = await ApiRequest.get(`/public/api/auth/get-unit?unit_id=${id}`)
        setLoading(response.status)
        if (response.status === 200) {
            


            setFormData({
            ...formData, name: response?.data?.data?.name,
            ...formData, is_active: response?.data?.data?.is_active,
            ...formData, property_id: response?.data?.data?.property_id,
            ...formData, floor: response?.data?.data?.floor,
            ...formData, unit_status_id: response?.data?.data?.unit_status_id,
            ...formData, type_id: response?.data?.data?.type_id,
            ...formData, bedrooms: response?.data?.data?.bedrooms,
            ...formData, bathrooms: response?.data?.data?.bathrooms,
            ...formData, stairs_to_unit: response?.data?.data?.stairs_to_unit,
            ...formData, stairs_in_unit: response?.data?.data?.stairs_in_unit,
            ...formData, elevator_to_unit: response?.data?.data?.elevator_to_unit,
            ...formData, elevator_in_unit: response?.data?.data?.elevator_in_unit,
            ...formData, wifi_network: response?.data?.data?.wifi_network,
            ...formData, wifi_password: response?.data?.data?.wifi_password,
            ...formData, alarm_code: response?.data?.data?.alarm_code,
            ...formData, gate_code: response?.data?.data?.gate_code,
            ...formData, door_code: response?.data?.data?.door_code,
            ...formData, mailbox_info: response?.data?.data?.mailbox_info,
      
            ...formData, is_parking_space_assigned: response?.data?.data?.is_parking_space_assigned,
            ...formData, property_parking_space_id: response?.data?.data?.property_parking_space_id,
            ...formData, picture_template_id: response?.data?.data?.picture_template_id,
            ...formData, unit_files: response?.data?.data?.files,
            ...formData, unit_staff_files: response?.data?.data?.staff_files,
            ...formData, unit_manager_files: response?.data?.data?.managers_files,
            ...formData, unit_owner_files: response?.data?.data?.owners_files,
            ...formData, listing_title: response?.data?.data?.listing_title,
            ...formData, listing_description: response?.data?.data?.listing_description,
            ...formData, monthly_rent: response?.data?.data?.monthly_rent,
            ...formData, security_deposit: response?.data?.data?.security_deposit,
            ...formData, last_month_rent_required: response?.data?.data?.last_month_rent_required,
            ...formData, couples_allowed: response?.data?.data?.couples_allowed,
            ...formData, childern_allowed: response?.data?.data?.childern_allowed,
            ...formData, pets_allowed: response?.data?.data?.pets_allowed,
            ...formData, pets_deposit: response?.data?.data?.pets_deposit,
            ...formData, pets_rent: response?.data?.data?.pets_rent,
            ...formData, lgbtq_friendly: response?.data?.data?.lgbtq_friendly,
            ...formData,smoking_permission_id:response?.data?.data?.smoking_permission_id,
            ...formData, gender_preference_id: response?.data?.data?.gender_preference_id,
            ...formData, embed: response?.data?.data?.embed,
            ...formData, utilities:response?.data?.data?.utilities,
            ...formData, measurements: response?.data?.data?.measurements,
            ...formData, materials: response?.data?.data?.materials,
            ...formData, features: response?.data?.data?.features,
            ...formData, pictures:response?.data?.data?.pictures,
            
            })
            setCkEditor3({
                ...ckEditor3, unit_notes : response?.data?.data?.unit_notes,
                ...ckEditor3, other_unit_features: response?.data?.data?.other_unit_features,

            })
            setCkEditor({
                ...ckEditor, other_info : response?.data?.data?.other_info,
                ...ckEditor, pets_more_info: response?.data?.data?.pets_more_info,
                ...ckEditor, unit_staff_notes: response?.data?.data?.unit_staff_notes,
            })
            setCkEditor1({
              ...ckEditor1, unit_manager_notes: response?.data?.data?.unit_manager_notes,
              ...ckEditor1,listing_description : response?.data?.data?.listing_description
            })
            setCkEditor2({
              ...ckEditor2, unit_owner_notes: response?.data?.data?.unit_owner_notes,

            })
            setUnitNotices(response?.data?.data?.notifications)
            setUnitBoardsData(response?.data?.data?.boards)
            setUnitMeasurements(response?.data?.data?.measurements)
            setUnitMaterials(response?.data?.data?.materials)
            setHandleFile(response?.data?.data?.files)
            setPropertyFormImagesWithUrl(response?.data?.data?.pictures)
            setTemplateID(response?.data?.data?.file_template_id)
            setUnitLocations(response?.data?.data?.unit_locations)
            setPublicNotes(response?.data?.data?.unit_notes)
            setStaffNotes(response?.data?.data?.unit_staff_notes)
            setManagerNotes(response?.data?.data?.unit_managers_notes)
            setOwnerNotes(response?.data?.data?.unit_owners_notes)
            setUnitStaffFiles(response?.data?.data?.staff_files)
            setUnitManagerFiles(response?.data?.data?.managers_files)
            setUnitOnwerFiles(response?.data?.data?.owners_files)
        }
  }

  const addUnitApi = async (payload) => {
    console.log("payload", payload);
    const response = await ApiRequest.post("/public/api/auth/unit", {
      id:id,
      name: formData?.name,
      is_active: formData?.is_active,
      property_id: formData?.property_id,
      floor: formData?.floor,
      unit_status_id: formData?.unit_status_id,
      type_id: formData?.type_id,
      bedrooms: formData?.bedrooms,
      bathrooms: formData?.bathrooms,
      stairs_to_unit: formData?.stairs_to_unit,
      stairs_in_unit: formData?.stairs_in_unit,
      elevator_to_unit: formData?.elevator_to_unit,
      elevator_in_unit: formData?.elevator_in_unit,
      wifi_network: formData?.wifi_network,
      wifi_password: formData?.wifi_password,
      alarm_code: formData?.alarm_code,
      gate_code: formData?.gate_code,
      door_code: formData?.door_code,
      mailbox_info: formData?.mailbox_info,
      other_info: ckEditor?.other_info,

      property_parking_space_id: 114,
      picture_template_id: formData?.picture_template_id,
      unit_files: formData?.unit_files,
      unit_staff_files: formData?.unit_staff_files,
      unit_manager_files: formData?.unit_manager_files,
      unit_owner_files: formData?.unit_owner_files,
      unit_staff_notes: ckEditor?.unit_staff_notes,
      unit_notes: ckEditor3?.unit_notes,
      unit_manager_notes: ckEditor1?.unit_manager_notes,
      unit_owner_notes: ckEditor2?.unit_owner_notes,
      listing_title: formData?.listing_title,
      listing_description: formData?.listing_description,
      monthly_rent: formData?.monthly_rent,
      security_deposit: formData?.security_deposit,
      last_month_rent_required: formData?.last_month_rent_required,
      couples_allowed: formData?.couples_allowed,
      childern_allowed: formData?.childern_allowed,
      pets_allowed: formData?.pets_allowed,
      pets_deposit: formData?.pets_deposit,
      pets_rent: formData?.pets_rent,
      pets_more_info: ckEditor?.pets_more_info,
      lgbtq_friendly: formData?.lgbtq_friendly,
      smoking_permission_id:formData?.smoking_permission_id,
      gender_preference_id: formData?.gender_preference_id,
      other_unit_features: ckEditor3?.other_unit_features,
      embed: formData?.embed,
      notifications: formData?.notifications,
      features: formData?.features,
      pictures:formData?.pictures,
      boards:formData?.boards,
      measurements:formData?.measurements,
      materials:formData?.materials,
      utilities_included_with_rent:formData?.utilities_included_with_rent,
      utilities_addition_to_rent:formData?.utilities_addition_to_rent,


    });
    console.log("response", response);
    if (response.status == 200) {
      console.log("true");
    }
  };
  const formSubmitHandler = (e) => {
    addUnitApi();
    e.preventDefault();

    // updateFormData(formData);
  };
  //  useEffect HOOKS
  useEffect(() => {
    getPropertyParkingSpaces();
  }, [PropertyID])

  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const propertyPictures = [];
      propertyFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        propertyPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });

      const contacts = [
        ...propertyContacts
          .map((contact, index) => {
            const pictures = [...propertyAssistantPictures];
            contact.picture = pictures[index]?.image?.picture;
            return contact;
          })
          .filter(
            (contact) =>
              JSON.stringify(contact) !== JSON.stringify(initialContactData)
          ),
      ];

      const measurements = [
        ...unitMeasurements
          .map((measurement, index) => {
            const pictures = [...unitMeasurementsPictures];
            measurement.pictures = pictures[index]?.map(
              (pictureObj, index) => ({
                picture: pictureObj?.image?.picture,
                order_number: index,
              })
            );
            const files = [...unitMeasurementsFiles];
            measurement.files = files[index]?.map((fileObj) => fileObj?.file);
            return measurement;
          })
          .filter(
            (measurement) =>
              JSON.stringify(measurement) !==
              JSON.stringify(initialMeasurementData)
          ),
      ];

      const materials = [
        ...unitMaterials
          .map((material, index) => {
            const pictures = [...unitMaterialPictures];
            material.pictures = pictures[index]?.map((pictureObj, index) => ({
              picture: pictureObj?.image?.picture,
              order_number: index,
            }));
            const files = [...unitMaterialFiles];
            material.files = files[index]?.map((fileObj) => fileObj?.file);
            return material;
          })
          .filter(
            (material) =>
              JSON.stringify(material) !== JSON.stringify(initialMaterialData)
          ),
      ];

      const notices = [
        ...unitNotices.filter(
          (notice) =>
            JSON.stringify(notice) !== JSON.stringify(initialNoticeData)
        ),
      ];

      const boards = [
        ...unitBoardsData.filter(
          (board) => JSON.stringify(board) !== JSON.stringify(initialBoardData)
        ),
      ];

      data.pictures = [...propertyPictures];

      if (JSON.stringify(initialData?.contacts) !== JSON.stringify(contacts)) {
        data.contacts = contacts;
      }

      if (
        JSON.stringify(initialData?.measurements) !==
        JSON.stringify(measurements)
      ) {
        data.measurements = measurements;
      }

      if (
        JSON.stringify(initialData?.materials) !== JSON.stringify(materials)
      ) {
        data.materials = materials;
      }

      data.hoa_files = [
        ...propertyHoaFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.mortage_files = [
        ...propertyMortageFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];
      data.builder_files = [
        ...propertyBuilderFiles.map((fileObj) => ({ file: fileObj?.file })),
      ];

      if (JSON.stringify(initialData?.notices) !== JSON.stringify(notices)) {
        data.notices = notices;
      }

      if (JSON.stringify(initialData?.boards) !== JSON.stringify(boards)) {
        data.boards = boards;
      }

      data.property_tenant_files = [
        ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.property_staff_files = [
        ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.unit_manager_files = [
        ...unitManagerFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.unit_owner_files = [
        ...unitOnwerFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      data.unit_files = [
        ...unitPublicFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      data.unit_staff_files = [
        ...unitStaffFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);

  useEffect(() => {
    if (formElement) {
      formSubmitButton.click();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    getGenderPreferences2();
    getParkingSpacePrefixes();
    getParkingSpaceSuffixes();
    getUnitTypes();
    getApi();
    getFeatures();
    getSmoking();
    // addUnitApi();
    getUtilities();
    getUnitMeasurementTypes();
    getUnitMaterialsTypes();
    getUnitMaterialLocations();
    getOwnerSettings();
    getUnitMaterialBrands();
    getUnitMateriaIdentifiers();

    setFormData(initialData);
    dispatch(properties());
    dispatch(getUnitMeasurement());
    // dispatch(getUnitMeasurementTypes());
    dispatch(getUnitesStatuses());
    setPropertyFormImagesWithUrl(
      initialData?.pictures?.map((obj) => ({
        image: {
          picture: obj?.picture,
          title: "",
          caption: "",
          is_cover: "",
        },
        url: obj?.picture,
      }))
    );

    if (initialData?.contacts?.length) {
      setPropertyContacts(initialData.contacts);
      setPropertyAssistantPictures(
        initialData.contacts.map((contact) => contact?.picture)
      );
    } else {
      setPropertyContacts([{ ...initialContactData }]);
      setPropertyAssistantPictures([{ ...initialImageData }]);
    }

    setUnitBoardsIcon({
      image: {
        picture: initialData?.security_picture,
        title: "",
        caption: "",
        is_cover: "",
      },
      url: initialData?.security_picture,
    });

    if (initialData?.measurements?.length) {
      setUnitMeasurements(initialData.measurements);
      setUnitMeasurementsPictures(
        initialData.measurements.map((measurement) =>
          measurement?.pictures.map((pictureObj) => pictureObj?.picture)
        )
      );
      setUnitMeasurementsFiles(
        initialData.measurements.map((measurement) => measurement?.files)
      );
    } else {
      setUnitMeasurements([{ ...initialMeasurementData }]);
      setUnitMeasurementsPictures([[]]);
      setUnitMeasurementsFiles([[]]);
    }

    if (initialData?.materials?.length) {
      setUnitMaterials(initialData?.materials);
      setUnitMaterialPictures(
        initialData.materials.map((material) =>
          material?.pictures?.map((pictureObj) => pictureObj?.picture)
        )
      );
      setUnitMaterialFiles(
        initialData.materials.map((material) => material?.files)
      );
    } else {
      setUnitMaterials([{ ...initialMaterialData }]);
      setUnitMaterialPictures([[]]);
      setUnitMaterialFiles([[]]);
    }
    setUnitPublicFiles(
      initialData?.unit_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyHoaFiles(
      initialData?.hoa_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyMortageFiles(
      initialData?.mortage_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyBuilderFiles(
      initialData?.builder_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );

    setUnitNotices(
      initialData?.notices?.length
        ? initialData?.notices
        : [{ ...initialNoticeData }]
    );

    setUnitBoardsData(
      initialData?.boards?.length
        ? initialData?.boards
        : [{ ...initialBoardData }]
    );

    setPropertyPublicDocumentsWithUrl(
      initialData?.property_tenant_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    setPropertyStaffDocumentsWithUrl(
      initialData?.property_staff_files?.map((fileObject) => ({
        file: fileObject?.file,
        url: "",
      }))
    );
    // setUnitManagerFiles(
    //   initialData?.unit_manager_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
    // setUnitOnwerFiles(
    //   initialData?.unit_owner_files?.map((fileObject) => ({
    //     file: fileObject?.file,
    //     url: "",
    //   }))
    // );
  }, []);

  useEffect(() => {
    if (formElement) {
      formElement.addEventListener("keypress", (e) => {
        if (e.key === "Enter") e.preventDefault();
      });

      return () => formElement.removeEventListener("keypress", () => {});
    }
  }, [formElement]);
  console.log("formData?.unit_owner_notes", formData?.unit_owner_notes);
  return (
    <>
      {/* Add Property Form  */}
      {loading === undefined ? <Loading/> :
      <>
      
      <div className="flex align-items-center">
            <img
                className="logo w-12 mr-5"
                src="/assets/img/units/unit.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">View {formData?.name}</h2>
            </div>
    
      <form
        className="w-full"
        id="add-property-form"
        onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Notifications" {...a11yProps(1)} />
          <Tab label="Unit Boards" {...a11yProps(2)} />
          <Tab label="Unit Notes & Files" {...a11yProps(3)} />
          <Tab label="Unit Measurements" {...a11yProps(4)} />
          <Tab label="Unit Materials" {...a11yProps(5)} />
          <Tab label="Listing Information" {...a11yProps(6)} />
        </Tabs>

        {/* add unit */}
        <TabPanel value={tabValue} index={0}>
          <div className="md:items-center w-full mt-4">
            <div className="flex items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/unit-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                General information:
              </label>
            </div>
          </div>
          <div className="w-[90%] m-auto main-fields-wrapper mt-5">
            <div className="w-full mt-3">
              <div className="flex">
                <div className="w-1/3">
                <CarouselWithLightbox data={propertyFormImagesWithUrl}/>
                </div>
                <div className="w-1/2">
                  <div className="flex flex-column">
                    <div className="flex items-center">
                    {formData.name != '' ?<span>{formData?.name}</span>:null} {formData?.name != "" && formData?.property_id != "" ?<span className="mx-1">|</span>:null} {formData.property_id != '' ?<span>{localStorage.getItem('Property Name')}</span>:null}
                    </div>
                    <div className="flex items-center mt-1">
                    {formData.type_id != '' ?<span>{unitTypes?.filter((type)=>type?.id == formData?.type_id)?.map((filteredType)=>filteredType?.type)}</span>:null} {formData?.type_id != "" && formData?.floor != "" ? <span className="mx-1">|</span> : null} {formData?.floor != '' ?<span>Floor: {formData?.floor}</span>:null}
                    </div>
                    <div className="flex items-center mt-1">
                    {formData.bedrooms != '' ?<span>Bedrooms: {formData?.bedrooms}</span>:null} {formData?.bedrooms != "" && formData?.bathrooms != "" ?<span className="mx-1">|</span>:null} {formData?.bathrooms != '' ?<span>Bathrooms :{formData?.bathrooms}</span>:null}
                    </div>
                    <div className="flex items-center mt-1">
                        {formData?.stairs_in_unit == 1 ?
                      <div className="md:w-1/4 flex items-center">
                        <img src="/assets/svgs/listings-about-stairs.svg" className="w-1/3 mr-1"></img> in Unit
                      </div>:null}
                        {formData?.stairs_to_unit == 1 ?
                      <div className="md:w-1/4 flex items-center">
                        <img src="/assets/svgs/listings-about-stairs.svg" className="w-1/3 mr-1"></img> to Unit
                      </div>:null}
                      {formData?.elevator_in_unit == 1 ?
                      <div className="md:w-1/4 flex items-center">
                        <img src="/assets/svgs/listings-about-elevator.svg" className="w-1/3 mr-1"></img> in Unit
                      </div>:null}
                      {formData?.elevator_to_unit == 1 ?
                      <div className="md:w-1/4 flex items-center">
                        <img src="/assets/svgs/listings-about-elevator.svg" className="w-1/3 mr-1"></img> to  Unit
                      </div>:null}
                    </div>
                    {formData?.property_parking_space_id != '' && formData?.property_parking_space_id != null && formData?.is_parking_space_assigned == 1 ?
                      <div className="flex items-center mt-1">
                        <div className="flex items-center">
                          <img src="/assets/svgs/listings-about-parking-spot.svg" alt="Parking Space" className="w-1/6 mr-2" /> 
                          <span className="font-bold mr-1">Parking Space:</span>
                          <span>{propertyParkingSpaces?.map((space)=>{
                            if(formData?.property_parking_space_id == space?.id)
                            {
                              const prefix = parkingSpacePrefix?.find(
                                (prefix) => space?.parking_space_prefix_id == prefix?.id
                              )?.prefix;
                              const suffix = parkingSpaceSuffix?.find(
                                (suffix) => space?.parking_space_suffix_id == suffix?.id
                              )?.suffix;
                              const spaceNumber = space?.space_number;
                              return prefix + spaceNumber + suffix
                            }
                          })}</span>
                        </div>
                      </div>:null}
                  </div>
                </div>
                <div className="w-1/4 flex flex-column">
                  {formData?.is_active == 1 ? <span className="status-active uppercase mb-1">Unit Active</span> : formData?.is_active == 0 ? <span className="status-inactive uppercase mb-1">Listing Inactive</span> : null}
                  {formData?.is_active == 5 ? <span className="status-inactive uppercase">Inactive</span> : formData?.unit_status_id == 1 ? <span className="status-active uppercase">Vacant</span> : formData?.unit_status_id == 3 ? <span className="status-active uppercase">Rented</span> : formData?.unit_status_id == 2 ? <span className="status-warning uppercase">Prepping</span>:formData?.unit_status_id == 4 ? <span className="status-blue uppercase">Moving Out</span>: null}        
                </div>
              </div>
            </div>
          </div>
            
            <hr />
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                {formData.wifi_network != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                  <div className="md:w-1/12">
                    <img src="/assets/svgs/system-wifi.svg" className="w-1/2" alt="Wifi Network" title="Wifi Network" />
                  </div>
                  <div className="md:w-4/5">
                    <span>{formData?.wifi_network}</span>
                  </div>
                </div>:null}
                {formData.wifi_password != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-wifi-password.svg" className="w-1/2" alt="Wifi Password" title="Wifi Password" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyWifiPassword == true ?<span>{formData?.wifi_password}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyWifiPassword:
                            securedFields.propertyWifiPassword === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyWifiPassword === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.alarm_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-alarm-code.svg" className="w-1/2" alt="Alarm Code" title="Alarm Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyAlarmCode == true ?<span>{formData?.alarm_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyAlarmCode:
                            securedFields.propertyAlarmCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyAlarmCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.gate_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-gate-code.svg" className="w-1/2" alt="Gate Code" title="Gate Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyGateCode == true ?<span>{formData?.gate_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyGateCode:
                            securedFields.propertyGateCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyGateCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.door_code != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-door-code.svg" className="w-1/2" alt="Door Code" title="Door Code" />
                  </div>
                  <div className="md:w-1/5 relative">
                    
                    {securedFields?.propertyDoorCode == true ?<span>{formData?.door_code}</span>:"*********"}
                    <span
                      className="absolute right-0 top-0 "
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          propertyDoorCode:
                            securedFields.propertyDoorCode === false
                              ? true
                              : false,
                        });
                      }}
                    >
                      {securedFields.propertyDoorCode === false ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                </div>:null}
                {formData.mailbox_info != '' ?<div className="md:flex md:items-center mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/system-mailbox.svg" className="w-1/2" alt="Mailbox Info" title="Mailbox Info" />
                  </div>
                  <div className="md:w-4/5">
                  
                    <span>{formData?.mailbox_info}</span>
                  </div>
                </div>:null}
                {ckEditor?.other_info != '' ?<div className="md:flex mt-3 ml-5">
                <div className="md:w-1/12">
                    <img src="/assets/svgs/property-information.svg" className="w-1/2" alt="Other Property Info" title="Other Property Info" />
                  </div>
                  <div className="md:w-4/5">
                    <span>{ckEditor?.other_info}</span>
                  </div>
                </div>:null}
                <hr />
              {unitLocations?.length > 0 ?<UnitLocations data={unitLocations} handleLocationTrue={handleLocationTrue} hiddenData={true}/>:null}
              </div>
            </div>
        </TabPanel>

        {/* Notification */}
        <TabPanel value={tabValue} index={1}>
          <UnitNotifications data={unitNotices} handleNotificationTrue={handleNotificationTrue} hiddenData={true} ownerSettings={ownerSettings}/>
        </TabPanel>

        {/* Property Assistant */}
        <TabPanel value={tabValue} index={2}>
        <div className="md:items-center w-full mt-4">
        <div className="flex">
          <img
            className="logo w-12 mr-5"
            src="/assets/img/properties/system-boards.png"
            alt=""
          />
          <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
            Unit Boards:
          </label>
          {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="This is a great place to post House Rules and other information you want everyone in your unit to have access to. The Boards will appear on their dashboard."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"This is a great place to post House Rules and other information you want everyone in your unit to have access to. The Boards will appear on their dashboard."}/>
        </div>
      </div>
          {/* <UnitBoards data={unitBoardsData} handleBoardTrue={handleBoardTrue} hiddenData={true} u_id={id}/> */}
          <Accordions data={unitBoardsData}/>
        </TabPanel>
        {/* Property Security */}
        <TabPanel value={tabValue} index={3}>
        <div className="md:items-center w-full mt-4">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-and-files.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Notes & Files:
							</label>
						</div>
					</div>
          {formData.unit_files != '' ?<>
					<div className="md:flex mt-4">
					<div className="md:items-center w-full mt-4">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-files.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Unit Public Files:
							</label>
						</div>
					</div>
						{/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    // id={`p-measurement-pictures-${index}`}
                    type="file"
                    disabled
                    multiple={true}
                    hidden={true}
                    onChange={(e) => {
                      let files = e.target.files;
                      files.forEach((file) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            console.log(fileBase64);
                            const res = await ApiRequest.post(
                              "public/api/auth/upload-file",
                              { file: fileBase64 }
                            );

                            formData?.unit_files?.push({
                              file: res?.data?.data?.file,
                            });
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });

                      setUnitPublicFiles(getFilesAndURLs(e));
                    }}
                  />
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount = formData?.unit_files?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
									</label>
								</div>
							</div>
						</div> */}
					</div>
          <FrontTree handleFiles={handleFiles} folderData={handleFile} getPictureTemplateID = {getPictureTemplateID} setPictureTemplateID = {templateID}/>

					{/* Gallery  */}

					{/* <div className="md:flex flex-column md:items-center w-full max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
              {formData?.unit_files.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id="p-HOA-documents-block"
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {formData?.unit_files.map((fileObject, index) =>
                          fileComponent({
                            file: fileObject?.file,
                            key: index,
                            inputFieldId: "u-public-files",
                            trashAble: false,
                            onTrashClick: () => {
                              const files = [...unitPublicFiles];
                              const file = [...formData.unit_files];
                              files.splice(index, 1);
                              file.splice(index, 1);
                              setUnitPublicFiles(files);
                              setFormData({ ...formData, unit_files: file });
                            },
                          })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div> */}
            </>:null}
					{ckEditor3?.unit_notes != '' ? <><div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/8">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-red.png"
								alt=""
							/>
							</div>
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Unit Notes:
									</label>
								</div>
								{/* <div className="md:w-4/5"> */}
                {/* <CKEditor
                  initData={ckEditor3?.unit_notes}
                  config={
                    {
                      readOnly: true
                    }
                  }
                  onChange={(e) => {
                    setCkEditor3({...ckEditor3,unit_notes:e.editor.getData()})
                  }}
                /> */}
                {/* <span>{ckEditor3?.unit_notes}</span> */}
								{/* </div> */}

							</div>
              <Notes data={publicNotes} handleNoteTrue={handleNoteTrue} hiddenData={false} type={"Public"}/>
						</div>
					</div>
          
					<hr/>
          </>:null}

          {formData.unit_staff_files != '' ?<>
					<div className="md:flex mt-4">
					<div className="md:items-center w-full mt-4">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-files-staff.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Staff Files:
							</label>
						</div>
					</div>
						{/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    // id={`p-measurement-pictures-${index}`}
                    type="file"
                    disabled
                    multiple={true}
                    hidden={true}
                    onChange={(e) => {
                      let files = e.target.files;
                      files.forEach((file) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            console.log(fileBase64);
                            const res = await ApiRequest.post(
                              "public/api/auth/upload-file",
                              { file: fileBase64 }
                            );

                            formData?.unit_staff_files?.push({
                              file: res?.data?.data?.file,
                            });
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });

                      setUnitStaffFiles(getFilesAndURLs(e));
                    }}
                  />
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount = formData?.unit_staff_files?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
									</label>
								</div>
							</div>
						</div> */}
					</div>

					{/* Gallery  */}

					<div >
            {unitStaffFiles.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-staff-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {unitStaffFiles.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "unit-staff-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.unit_staff_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.unit_staff_files = fileLists;
                            setUnitStaffFiles(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.unit_staff_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.unit_staff_files[fileIndex].title = title;
                                setUnitStaffFiles(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            </>:null}
					{ckEditor?.unit_staff_notes != '' ? 
          <>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/8">
								<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-staff.png"
								alt=""
							/>
								</div>
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Staff Notes:
									</label>
								</div>
								{/* <div className="md:w-4/5">
                <CKEditor
                  initData={ckEditor?.unit_staff_notes}
                  config={
                    {
                      readOnly: true
                    }
                  }
                  onChange={(e) => {
                    setCkEditor({
                      ...ckEditor,
                      unit_staff_notes: e.editor.getData(),
                    });
                  }}
                />
                <span>{ckEditor?.unit_staff_notes}</span>
								</div> */}
							</div>
              <Notes data={staffNotes} handleNoteTrue={handleStaffNoteTrue} hiddenData={false} type={"Staff"}/>
						</div>
					</div>
          
					<hr/>
          </>
          :null}

          {formData.unit_manager_files != '' ? <>
					<div className="md:flex mt-4">
						<div className="md:w-2/3">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-files-property-manager.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Unit Manager Files:
							</label>
						</div>
						</div>
						{/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    // id={`p-measurement-pictures-${index}`}
                    type="file"
                    multiple={true}
                    hidden={true}
                    disabled
                    onChange={(e) => {
                      let files = e.target.files;
                      files.forEach((file) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            console.log(fileBase64);
                            const res = await ApiRequest.post(
                              "public/api/auth/upload-file",
                              { file: fileBase64 }
                            );

                            formData?.unit_manager_files?.push({
                              file: res?.data?.data?.file,
                            });
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });

                      setUnitManagerFiles(getFilesAndURLs(e));
                    }}
                  />
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount = formData?.unit_manager_files?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
									</label>
								</div>
							</div>
						</div> */}
					</div>

					{/* Gallery  */}

					<div >
            {unitManagerFiles.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="unit-manager-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {unitManagerFiles.map(
                        (fileObject, fileIndex) =>
                          fileComponent({
                            file: fileObject?.file,
                            title: fileObject?.title,
                            key: fileIndex,
                            inputFieldId: "unit-manager-files",
                            trashAble: false,
                            editAble: false,
                            onTrashClick: () => {
                              const fileLists = [
                                ...formData?.unit_manager_files,
                              ];
                              fileLists.splice(fileIndex, 1);
                              formData.unit_manager_files = fileLists;
                              setUnitManagerFiles(fileLists);
                            },
                            onEditClick: () => {
                              const fileLists = [...formData?.unit_manager_files];
                              const currentTitle = fileLists[fileIndex].title || "";
                            
                              Swal.fire({
                                title: "Edit File",
                                html: `
                                  <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                `,
                                focusConfirm: false,
                                preConfirm: () => {
                                  const title = document.getElementById('swal-input1').value;
                                  return title;
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  const title = result.value;
                                  fileLists[fileIndex].title = title;
                                  formData.unit_manager_files[fileIndex].title = title;
                                  setUnitManagerFiles(fileLists);
                                }
                              });
                            }
                          })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            </>:null}
					{ckEditor1?.unit_manager_notes != '' ?<><div className="md:flex flex-column md:items-center w-[90%] m-auto">
						<div className="w-full">
							<div className="md:flex mt-3">
								<div className="md:w-1/8">
								<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-property-manager.png"
								alt=""
							/>
								</div>
								<div className="md:w-2/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Unit Manager Notes:
									</label>
								</div>
								{/* <div className="md:w-4/5">
                <CKEditor
                  initData={ckEditor1?.unit_manager_notes}
                  config={
                    {
                      readOnly: true
                    }
                  }
                  onChange={(e) => {
                    setCkEditor1({
                      ...ckEditor1,
                      unit_manager_notes: e.editor.getData(),
                    });
                  }}
                />
                <span>{ckEditor1?.unit_manager_notes}</span>
								</div> */}
							</div>
              <Notes data={managerNotes} handleNoteTrue={handleManagerNoteTrue} hiddenData={false} type={"Manager"}/>
						</div>
					</div>
          
					<hr/>
          </>:null}

          {formData.unit_owner_files != '' ?<>
					<div className="md:flex mt-4">
						<div className="md:w-1/3">
						<div className="flex">
							<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-files-private.png"
								alt=""
							/>
							<label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
								Owner Files:
							</label>
						</div>
						</div>
						{/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
							<div className="flex md:items-center md:justify-start md:w-2/3">
								<div className="md:w-1/2">
									<label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
										<span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
											Upload files
										</span>
										<input
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    // id={`p-measurement-pictures-${index}`}
                    type="file"
                    multiple={true}
                    disabled
                    hidden={true}
                    // onChange={(e) => {
                    //   let files = e.target.files;
                    //   console.log(files);
                    //   files?.forEach((file, index) => {
                    //     unitOnwerFIleBase64Converter(file, index);
                    //     console.log("file123", file);
                    //   });
                    //   console.log("here 142");
                    //   setUnitOnwerFiles(getFilesAndURLs(e));
                    // }}

                    onChange={(e) => {
                      let files = e.target.files;
                      files.forEach((file) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const fileBase64 = reader.result;
                          try {
                            console.log(fileBase64);
                            const res = await ApiRequest.post(
                              "public/api/auth/upload-file",
                              { file: fileBase64 }
                            );

                            formData?.unit_owner_files?.push({
                              file: res?.data?.data?.file,
                            });
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      });

                      setUnitOnwerFiles(getFilesAndURLs(e));
                    }}
                  />
									</label>
								</div>
								<div className="md:w-1/2">
									<label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                  <span className="text-gray-600">
                    {(() => {
                      const filesCount = formData?.unit_owner_files?.length || 0;
                      return filesCount > 1
                        ? filesCount + " Documents"
                        : filesCount == 1
                        ? filesCount + " Document"
                        : null;
                    }).call()}
                  </span>
									</label>
								</div>
							</div>
						</div> */}
					</div>

					{/* Gallery  */}

					<div >
            {unitOnwerFiles.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="unit-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {unitOnwerFiles.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "unit-owner-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.unit_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.unit_owner_files = fileLists;
                            setUnitOnwerFiles(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.unit_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.unit_owner_files[fileIndex].title = title;
                                setUnitOnwerFiles(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            </>:null}
					{ckEditor2?.unit_owner_notes != '' ?<div className="md:flex flex-column md:items-center w-[90%] m-auto">
					<div className="w-full">
						<div className="md:flex mt-3">
						<div className="md:w-1/8">
								<img
								className="logo w-12 mr-5"
								src="/assets/img/properties/system-notes-private.png"
								alt=""
							/>
								</div>
							<div className="md:w-1/5">
								<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
									Owner Notes:
								</label>
							</div>
							{/* <div className="md:w-4/5">
              <CKEditor
                  initData={ckEditor2?.unit_owner_notes}
                  config={
                    {
                      readOnly: true
                    }
                  }
                  onChange={(e) => {
                    setCkEditor2({
                      ...ckEditor2,
                      unit_owner_notes: e.editor.getData(),
                    });
                  }}
                />
                <span>{ckEditor2?.unit_owner_notes}</span>
							</div> */}
						</div>
            <Notes data={ownerNotes} handleNoteTrue={handleOwnerNoteTrue} hiddenData={false} type={"Owner"}/>
					</div>
				</div>:null}
        </TabPanel>
        {/* Property Features */}
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/system-measurements.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Unit Measurements:
              </label>
            </div>
          </div>
          {/* {console.log(" unitMeasurements[0]", unitMeasurements[0]?.type)} */}

         
          <UnitMeasurements data={unitMeasurements} handleMeasurementTrue={handleMeasurementTrue} hiddenData={true} unitID={id}/>

          {/* Add more */}
{/* 
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setUnitMeasurements([
                        ...unitMeasurements,
                        initialMeasurementData,
                      ]);
                      setUnitMeasurementsPictures([
                        ...unitMeasurementsPictures,
                        [],
                      ]);
                      setUnitMeasurementsFiles([...unitMeasurementsFiles, []]);
                    }}
                  >
                    <i className="fa fa-plus"></i> Add Measurement
                  </button>
              </div>
            </div>
          </div> */}

         
        </TabPanel>
        {/* Property Measurements */}
        <TabPanel value={tabValue} index={5}>
          {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/system-materials.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Unit Materials:
              </label>
            </div>
          </div> */}


          {/* Add more */}

          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setUnitMaterials([...unitMaterials, initialMaterialData]);
                      setUnitMaterialPictures([...unitMaterialPictures, []]);
                      setUnitMaterialFiles([...unitMaterialFiles, []]);
                    }}
                  >
                    <i className="fa fa-plus"></i> Add Material
                  </button>
              </div>
            </div>
          </div> */}
          <UnitMaterials data={unitMaterials} handleMaterialTrue={handleMaterialTrue} hiddenData={true} ownerSettings={ownerSettings} unitID={id}/>
         
        </TabPanel>
        {/* Property Materials */}
        <TabPanel value={tabValue} index={6}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/units/listings.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Listing Information:
              </label>
            </div>
          </div>

          <>
                <div className="flex justify-content-end">
                  <div className="w-1/2 flex items-center">
                    <div className="w-1/2 mr-1">
                      {formData?.is_active == 1 ? <span className="status-active uppercase mb-1">Unit Active</span> : formData?.is_active == 0 ? <span className="status-inactive uppercase mb-1">Listing Inactive</span> : null}
                    </div>
                    <div className="w-1/2">
                      <button className="btn3">Preview Listing</button>
                    </div>
                  </div>
                </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="w-1/2 flex items-center mt-3">
                  {formData?.name ? <span className="font-bold">{formData?.name}</span>:null}
                  {formData?.is_furnished == 0 ?
                  <img src="/assets/svgs/listings-about-unfurnished.svg" alt="" />
                  : 
                  formData?.is_furnished == 1 ?
                  <img src="/assets/svgs/listings-about-unit-furnished.svg" alt="" />
                  :null
                  }
                  {formData?.smoking_permission_id == 1 ? <img src="/assets/svgs/listings-about-smoking-allowed-outside.svg" alt="" /> : 
                  formData?.smoking_permission_id == 2 ? <img src="/assets/svgs/listings-about-smoking-not-allowed.svg" alt="" /> :
                  formData?.smoking_permission_id == 3 ? <img src="/assets/svgs/listings-about-smoking-allowed.svg" alt="" /> :
                  null}
                  {formData?.lgbtq_friendly == 1 ? <img src="/assets/svgs/listings-about-lgbtq-friendly.svg" alt="" /> : null}
                  {formData?.cannabis_friendly == 1 ? <img src="/assets/svgs/listings-about-cannabis-friendly.svg" alt="" /> : null}
                </div>
                <div className="flex items-center mt-3">
                  {formData?.monthly_rent != "" ?<div className="w-1/3">
                    <span className="font-bold">Monthly Rent:</span> {formData?.monthly_rent}
                  </div>:null}
                  {formData?.security_deposit ?<div className="w-1/3">
                    <span className="font-bold">Security Deposit:</span> {formData?.security_deposit}
                  </div>:null}
                  {formData?.last_month_rent_required == 1 ?
                  <div className="w-1/3">
                    <span className="status-blue uppercase">Last Month's Rent Required</span>
                  </div>:null}
                </div>
                <div className="flex items-center mt-3">
                  {formData?.gender_preference_id != "" ? 
                  <div className="w-1/3 items-center flex">
                    <span className="font-bold mr-1">Gender: </span>
                    <span className="status-warning">{genderPreference?.map((gender)=>{
                      if(formData?.gender_preference_id == gender?.id)
                      {
                        return gender?.gender
                      }
                    })}</span>
                  </div>:null}
                  {formData?.couples_allowed == 1 || formData?.childern_allowed == 1 || formData?.pets_allowed == 1 ?
                  <div className="w-1/2 flex items-center">
                    <span className="font-bold mr-1">Allowed:</span>
                    {formData?.couples_allowed == 1 ? <span className="status-purple uppercase mr-1">Couples</span>:null}
                    {formData?.childern_allowed == 1 ? <span className="status-teal uppercase mr-1">Children</span>:null}
                    {formData?.pets_allowed == 1 ? <span className="status-orange uppercase mr-1">Pets</span>:null}
                  </div>:null}
                </div>
                {formData?.listing_title != '' ?<h6>{formData?.listing_title}</h6>:null}
                {ckEditor1?.listing_description != "" ? <span>{ckEditor1?.listing_description}</span>:null}
              </div>
              </div>
              <hr />
              <>
              <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/utilities.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Utilities:
                      </label>
                    </div>
                </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">

              
              {formData.utilities_included_with_rent != '' ?<>
              <div className="form-input-wrapper mt-4">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label italic text-gray-700 font-bold">
                    Included:
   
                  </label>
                </div>
              </div>
              <div className="md:flex flex-wrap md:items-center mt-3">
                {allUtilities
                  ?.sort((a, b) => a.order_number - b.order_number)
                  ?.map((item, index) => {
                    const isChecked = formData?.utilities?.find(
                      (utility) => utility.utility_id === item.id
                    );

                    if (isChecked) {
                      return (
                        <div className="w-1/5" key={index}>
                          <div>
                            <div className="md:w-auto">
                              <img src={`${imgUrl}/${item.icon}`} width={"30px"} height={"auto"} className="object-cover rounded" alt={item.utility} />
                              <label className="input-label text-gray-700 mb-2">
                                {item.utility}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    
                    return null; // Don't render anything if not checked
                  })}
              </div>

              </>:null}

              {formData.utilities_addition_to_rent != '' ?<>
              <div className="form-input-wrapper mt-4">
                <div className="w-full flex justify-start gap-3">
                  <label className="input-label text-gray-700 italic font-bold">
                    Addition:
            
                  </label>
                </div>
              </div>
              <div className="md:flex flex-wrap md:items-center mt-3">
                  {allUtilities
                    ?.sort((a, b) => a.order_number - b.order_number)
                    ?.map((item, index) => {
                      const isChecked = formData?.utilities?.find(
                        (utility) => utility.utility_id === item.id
                      );

                      if (isChecked) {
                        return (
                          <div className="w-1/5" key={index}>
                            <div>
                              <div className="md:w-auto">
                                <img src={`${imgUrl}/${item.icon}`} width={"30px"} height={"auto"} className="object-cover rounded" alt={item.utility} />
                                <label className="input-label text-gray-700 mb-2">
                                  {item.utility}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      
                      return null; // Don't render anything if not checked
                    })}
                </div>

              </>:null}
              </div>
              </div>
              </>
             {/* {formData?.pets_allowed == 1 ? 
              <>
              <div className="form-input-wrapper mt-4 mb-3">
              {formData.pets_deposit != '' ?
                <div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Pet Deposit:
                  </label>
                  {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.pets_deposit}
                    disabled
                    required={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        pets_deposit: e.target.value,
                      });
                    }}
                  /> 
                  <span>{formData?.pets_deposit}</span>
                </div>:null}
                {formData.pets_rent != '' ?<div className="w-full">
                  <label className=" input-label text-gray-700 font-bold">
                    Pet Rent:
                  </label>
                  {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="hoa_zip_code-property-name"
                    type="text"
                    value={formData?.pets_rent}
                    required={true}
                    disabled
                    onChange={(e) => {
                      setFormData({ ...formData, pets_rent: e.target.value });
                    }}
                  /> 
                  <span>{formData?.pets_rent}</span>
                </div>:null}
              </div>
              {ckEditor?.pets_more_info != '' ?<div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Info:
                      </label>
                    </div>
                    <div className="w-full ">
                      {/* <CKEditor
                        // initData={formData?.pets_more_info}
                        // onChange={(e) => {
                        //  setFormData({...formData,pets_more_info:e.target.getData()})
                        // }}
                        initData={ckEditor?.pets_more_info}
                        config={
                            {
                              readOnly: true
                            }
                          }
                        onChange={(e) => {
                          setCkEditor({
                            ...ckEditor,
                            pets_more_info: e.editor.getData(),
                          });
                        }}
                      /> 
                      <span>{ckEditor?.pets_more_info}</span>
                    </div>
                  </div>
                </div>
              </div>:null}
              </>
              :null} */}

                <hr />
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/listings-about-luxury.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Feature:
                      </label>
                    </div>
                </div>
                
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                      {formData?.features?.length > 0 ?
                      <div className="md:flex flex-wrap md:items-center mt-3">
                      {features
                        .filter((feature) => formData?.features?.some((item) => item.feature_id === feature.id))
                        .map((feature, index) => {
                          return (
                            <div key={index} className="md:w-1/5">
                              <div className="md:w-auto">
                                <img src={`${imgUrl}/${feature.icon}`} width={"30px"} height={"auto"} alt={feature.feature} />
                                <label className="input-label text-gray-700 mb-2">{feature.feature}</label>
                              </div>
                            </div>
                          );
                        })}
                      </div>:null}
                      {ckEditor3?.other_unit_features != '' ?
                      <div className="md:flex flex-column mt-3">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Other Unit Features:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <span>{ckEditor3?.other_unit_features}</span>
                        </div>
                      </div>:null}
                  </div>
                  </div>
                
                </>
              {ckEditor3?.other_unit_features != '' ?<div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="md:flex mt-3">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Other Unit Features:
                      </label>
                    </div>
                    <div className="w-full ">
                      {/* <CKEditor
                        // initData={formData?.pets_more_info}
                        // onChange={(e) => {
                        //  setFormData({...formData,pets_more_info:e.target.getData()})
                        // }}
                        initData={ckEditor3?.other_unit_features}
                        config={
                            {
                              readOnly: true
                            }
                          }
                        onChange={(e) => {
                          setCkEditor3({
                            ...ckEditor3,
                            other_unit_features: e.editor.getData(),
                          });
                        }}
                      /> */}
                      <span>{ckEditor3?.other_unit_features}</span>
                    </div>
                  </div>
                </div>
              </div>:null}
              <hr />
                <>
                  <div className="md:items-center w-full mt-4">
                    <div className="flex">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/svgs/system-pictures-and-embed.svg"
                        alt=""
                      />
                      <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                        Unit Pictures & Embed:
                      </label>
                    </div>
                </div>
                
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full flex">
                        <div className="w-1/2">

                          {formData.pictures != '' ? 
                          <>
                            {/* <Lightbox data={propertyFormImagesWithUrl} handleFiles={handlePicturesData} hiddenData={true}/> */}
                            <CarouselWithLightbox data={propertyFormImagesWithUrl}/>
                          </>:null}
                        </div>
                        <div className="w-1/2">
                          {formData.embed != '' ? 
                          <div className="md:items-center w-full mt-4">
                            
                              <span>{formData?.embed}</span>
                            
                          </div>:null}
                        </div>

                
                  </div>
                  </div>
                  </>
            
          </>

          <hr/>
          <button id={"form-submit-button"} type={"submit"} hidden={true} disabled={true} className="btn2">Submit</button>
        </TabPanel>
      </form>
      </>
      }
    </>
  );
};
