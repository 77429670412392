import React from 'react'
import { DatePicker } from '../genericFunctions.js/DatePicker'
import { CKEditor } from 'ckeditor4-react'

export const FinanceBudgetAccountForm = () => {
  return (
    <>
        <form>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="md:w-1/6">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Name:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Type:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Track in Budget Total:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Give Access To:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex justify-end items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
    </>
  )
}
