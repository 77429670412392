import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";
import imgUrl from "../ImageUrl";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";

const FinancePaymentHistoryTable = ({ userRoles, status }) => {
  // console.log("userRoles", userRoles)
  //   const dispatch = useDispatch();
  //   const allProperties = useSelector(
  //     (state) => state.properties.properties.data
  //   );
  //   const loading = useSelector((state) => state.properties.status);
  //   const allPropertiesTypes = useSelector(
  //     (state) => state.properties.propertiesTypes.data
  //   );

  //   const newArray1 = allProperties?.map((a) => ({ ...a }));
  //   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
  // const [unit,setUnit] = useState([]);
  // const [loader,setLoader]= useState();
  // const showUnit = async ()=>{
  //     const response = await ApiRequest.get(
  //         "public/api/auth/get-my-tenants"
  //     );
  //     setLoader(response.status)
  //     if(response.status == 200)
  //     {
  //         setUnit(response?.data?.data)
  //         console.log("Response table",response);
  //     }
  // }
  // useEffect(() => {
  //   showUnit();
  // }, [])

  const [bulkAction,setBulkAction]= useState({
    bulk_action:''
  });
  

  const data = ["131231", "nvvxcvsd", "1234323", "dfsdfsd", "534534"];

  const columns = [
    {
      name: "System ID",
      selector: (data) => "T-7589273948798273",
    },
    {
      name: "Paid Date",
      selector: (data) => "6/07/2022",
    },
    {
      name: "Invoice ID",
      selector: (data) => "INV45213",
    },
    {
      name: "Receipt ID",
      selector: (data) => "R1532521",
    },
    {
      name: "Type",
      selector: (data) => "Tenant",
    },
    {
      name: "Name",
      selector: (data) => "Edwards, James",
    },
    {
      name: "Description",
      selector: (data) => "Security Deposit",
    },
    {
      name: "Unit",
      selector: (data) => "Unit B",
    },
    {
      name: "Amount",
      selector: (data) => "$1,000.00",
    },
    {
      name: "Budget",
      selector: (data) => "$1,000.00",
    },
    {
      name: "Payment Type",
      selector: (data) => "Zelle",
    },
    {
      name: "Ref",
      selector: (data) => "",
    },
    {
      name: "",
      selector: (data) => {
        return (<img src="/assets/svgs/system-paperclip.svg" className="w-1/6"/>)
      },
    },
    {
      name: "Notes",
      selector: (data) => "Waived late fee.",
    },
    {
      name: "Created Date",
      selector: (data) => "5/30/2022",
    },
    {
      name: "Options",
      selector: (data) => (
        <div className="flex">
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/img/system-view.png" title="View Deposit" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-edit.svg" title="Edit Payment History" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-go-to.svg" title="Go-to" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-download.svg" title="Download" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-print.svg" title="Print" />
          </button>
          <button
            type="button"
            className="bg-transparent border-none md:w-6 ml-2  p-0"
          >
            <img src="/assets/svgs/system-trashcan.svg" title="Delete" />
          </button>
        </div>
      ),
    },
    // {
    //   name: "Options",
    //   cell: (data) => {
    //     return (
    //       <>
    //            <Link to={`/edit-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //         <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
    //           <i class="fa-solid fa-pen mr-6"></i>
    //           <span>Edit</span>
    //         </button>
    //         </Link>
    //         <Link to={`/show-users-tenant/${data.id}`} style={{textDecoration:"none"}}>
    //           <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
    //             <i class="fa-regular fa-eye mr-6"></i>
    //             <span>View</span>
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

  //   const data =[
  //     {
  //         id:"355821647853265",
  //         sent:"5/24/2022 TIME",
  //         sender:"Sapp, Sean (UNIT A)",
  //         recipient:"SYSTEM",
  //         subject:"Question on Rent Due Date",
  //         viewed:"5/24/2022 TIME",
  //       },
  //     {
  //         id:"745852146932145",
  //         sent:"5/24/2022 TIME",
  //         sender:"SYSTEM",
  //         recipient:"Sapp, Sean (UNIT A)",
  //         subject:"Information requested about the pool ",
  //         viewed:"Not Viewed",
  //       },
  //     {
  //         id:"985632452185635",
  //         sent:"5/24/2022 TIME",
  //         sender:"Knabenshue, Chris(OWNER)",
  //         recipient:"SYSTEM",
  //         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
  //         viewed:"Not Viewed",
  //       },

  //   ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleLogSelectedRows = () => {
    console.log(selectedRows);
  };

  return (
    <>
      <div className="flex items-center md:w-full mt-3 mb-3">
        <div className="flex-1">
          <div className="flex flex-column">
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
              Total Tenant Payments:{" "}
                <span className="text-green-400">$3,051.54</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
              Total Staff Payments:{" "}
                <span className="text-green-400">$0.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
                Total Payments to Vendors:{" "}
                <span className="text-red-400">$0.00</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-column">
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
              Year to Date:{" "}
                <span className="text-green-400">$2,351.54</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
              Year to Date:{" "}
                <span className="text-green-400">$0.00</span>{" "}
              </span>
            </div>
            <div className="flex mt-1">
              {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
              <span className="mr-2 data">
              Year to Date:{" "}
                <span className="text-red-400">$0.00</span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <img
              className="w-8 mr-3 cursor-pointer"
              src="/assets/img/properties/system-download-csv.png"
              alt="CSV"
            />
            <img
              className="w-8 mr-3 cursor-pointer"
              src="/assets/img/properties/system-download-pdf.png"
              alt="PDF"
            />
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
        onSelectedRowsChange={handleRowSelected}
      />
      {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
      <div className="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ? (
          <div className="md:flex md:items-center w-full md:w-1/2 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option value = "">
                  Select
                </option>
                <option value={"PAY MULTIPLE INVOICES"}>
                  PAY MULTIPLE INVOICES
                </option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"DOWNLOAD"}>DOWNLOAD</option>
                <option value={"PRINT"}>PRINT</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-1/2 ml-1 ">
              <button
                type="button"
                className="btn2"
                //   onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE PROSPECT ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE PROSPECT INACTIVE" ? bulkMakeInActive : bulkAction?.bulk_action == "DELETE" ? bulkDelete : bulkAction?.bulk_action == "MERGE" ? bulkMakeMerge :  null}
              >
                <i className="fa fa-arrow-alt-circle-right"></i>
                GO
              </button>
            </div>
          </div>
        ) : null}
        <div class="bg-indigo-300 p-2 rounded  unit-wrapper">
          <div class="flex flex-column flex-wrap gap-x-3">
            <p class="flex-auto">Total Payment: 5</p>
            <p class="flex-auto">
              Total Rent Payment:3
            </p>
            <p class="flex-auto">
              Total Other Payment: 2
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancePaymentHistoryTable;
