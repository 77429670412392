import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { convertToFormData } from "../../utility/helperFunctions";

// import { PropertyForm } from "../../components/property/PropertyForm";
import { EditUnitForm } from "../../components/UnitForm/EditUnitForm";
import ApiRequest from "../../utility/http";
import { OwnerHeader } from "../OwnerHeader";
import { Footer } from "../../components/Dashboard/Footer";

export const EditUnitPage = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
  // CONSTANTS

  const initialData = {
    name: "",
    is_active: 1,
    property_id: 30,
    floor: 5,
    unit_status_id: 1,
    type_id: 1,
    bedrooms: "",
    bathrooms: "",
    stairs_to_unit: 1,
    stairs_in_unit: 1,
    elevator_to_unit: 1,
    elevator_in_unit: 0,
    wifi_network: "",
    wifi_password: "",
    alarm_code: "",
    gate_code: "",
    door_code: "",
    mailbox_info: "",
    other_info: "",
    property_parking_space_id: 10,
    notifications: [
      // {
      //   title: "Notification 1",
      //   notification: "Notification 1 description",
      //   is_popup_notification: 0,
      //   posting_date: "",
      //   posting_time: "",
      // },
      // {
      //   title: "Notification 2",
      //   notification: "Notification 2 description",
      //   is_popup_notification: 1,
      //   posting_date: "01-12-2022",
      //   posting_time: "10:00:00 am",
      // },
    ],
    boards: [
      // {
      //   title: "board title",
      //   icon: "",
      //   board: "board description",
      // },
      // {
      //   title: "board title 1",
      //   icon: "",
      //   board: "board 1 description",
      // },
    ],
    picture_template_id: 1,
    pictures: [
      // {
      //   picture: "62a6d24b73169.png",
      //   title: "t1",
      //   caption: "c1",
      //   is_cover: 1,
      //   order_number: 1,
      //   picture_folder_id: 4,
      // },
      // {
      //   picture: "62a6e8396cbf0.png",
      //   title: "t2",
      //   caption: "c2",
      //   is_cover: 0,
      //   order_number: 2,
      //   picture_folder_id: 5,
      // },
      // {
      //   picture: "62a6e8396cbf0.png",
      //   title: "t3",
      //   caption: "c3",
      //   is_cover: 0,
      //   order_number: 3,
      //   picture_folder_id: 6,
      // },
    ],
    unit_files: [
      // {
      //   file: "62a6d24b73169.png",
      // },
      // {
      //   file: "62a6d24b73169.png",
      // },
    ],
    unit_notes: "",
    unit_staff_files: [
      // {
      //   file: "62a6e8396cbf0.png",
      // },
      // {
      //   file: "62a6e8396cbf0.png",
      // },
    ],
    unit_staff_notes: "",
    unit_manager_files: [
      // {
      //   file: "62a6e8396cbf0.png",
      // },
      // {
      //   file: "62a6e8396cbf0.png",
      // },
    ],
    unit_manager_notes: "",
    unit_owner_files: [
      // {
      //   file: "62a6e8396cbf0.png",
      // },
      // {
      //   file: "62a6e8396cbf0.png",
      // },
    ],
    unit_owner_notes: "",
    measurements: [
      // {
      //   name: "Measurement 1",
      //   measurement_type_id: 1,
      //   unit_location_id: 2,
      //   measurement_unit_id: 5,
      //   length: "10",
      //   width: "5",
      //   height: "3",
      //   depth: "22",
      //   notes: "measurements 1 notes",
      //   pictures: [
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "1",
      //     // },
      //   ],
      //   files: [
      //     // {
      //     //   file: "62a6e8396cbf0.png",
      //     // },
      //     // {
      //     //   file: "62a6e8396cbf0.png",
      //     // },
      //   ],
      // },
      // {
      //   name: "Measurement 2",
      //   measurement_type_id: 2,
      //   unit_location_id: 3,
      //   measurement_unit_id: 8,
      //   length: "10",
      //   width: "5",
      //   height: "3",
      //   depth: "22",
      //   notes: "measurements 2 notes",
      //   pictures: [
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "1",
      //     // },
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "2",
      //     // },
      //   ],
      //   files: [
      //     // {
      //     //   file: "62a6e8396cbf0.png",
      //     // },
      //   ],
      // },
    ],
    materials: [
      // {
      //   // material_type_id: 3,
      //   // unit_location_id: 3,
      //   // material_brand_id: 3,
      //   // material_identifier_id: 4,
      //   // last_installed: "12-06-2022",
      //   // notes: "materials notes",
      //   pictures: [
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "1",
      //     // },
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "2",
      //     // },
      //   ],
      //   files: [
      //     // {
      //     //   file: "62a6e8396cbf0.png",
      //     // },
      //   ],
      // },
      // {
      //   material_type_id: 1,
      //   unit_location_id: 2,
      //   material_brand_id: 2,
      //   material_identifier_id: 1,
      //   last_installed: "04-12-2021",
      //   notes: "materials notesss",
      //   pictures: [
      //     // {
      //     //   picture: "62a6e8396cbf0.png",
      //     //   order_number: "1",
      //     // },
      //   ],
      //   files: [
      //     {
      //       file: "62a6e8396cbf0.png",
      //     },
      //     {
      //       file: "62a6e8396cbf0.png",
      //     },
      //   ],
      // },
    ],
    listing_title: "",
    listing_description: "",
    monthly_rent: 800,
    security_deposit: 1000,
    last_month_rent_required: 0,
    utilities_included_with_rent: [
      // {
      //   utility_id: 1,
      // },
      // {
      //   utility_id: 2,
      // },
    ],
    utilities_addition_to_rent: [
      // {
      //   utility_id: 5,
      // },
    ],
    couples_allowed: 1,
    childern_allowed: 1,
    pets_allowed: 1,
    pets_deposit: 100,
    pets_rent: 100,
    pets_more_info: "",
    lgbtq_friendly: 0,
    gender_preference_id: 1,
    features: [
      // {
      //   feature_id: 2,
      // },
      // {
      //   feature_id: 3,
      // },
    ],
    other_unit_features: "",
    embed: "",
  };

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [formData, setFormData] = useState({});

  const { alertList } = useSelector((state) => state.alerts);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  // const updateFormData = async (data = {}) => {
  //   if (JSON.stringify(formData) !== JSON.stringify(data)) {
  //     const newData = { ...formData, ...data };
  //     setFormData(newData);

  //     const apiBody = convertToFormData(newData);
  //     const response = await ApiRequest.post(
  //       "public/api/auth/unit",
        
       
  //       apiBody
  //     );

  //     if (response == 200) {
  //       debugger;
  //     } else {
  //       debugger;
  //     }
  //   }
  // };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  useEffect(() => {
    setFormData(initialData);
  }, []);

  useEffect(() => {
    if (alertList?.length) {
      alertList.map((alertEl) => {
        Alert.show(alertEl);
        Alert.remove(alertEl);
      });
    }
  }, [alertList]);
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  const [name,setName] = useState();
  const handleName = (message)=>{
    setName(message)
  }
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Units" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>
          {/* search wraper  */}
          {/* <section className="search-wraper">
            <div className="row">
              <div className="col-xl-8 order-lg-2">
                <div className="search-right-side-wrap">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="serch-bar-side">
                    <div className="form-group search">
                      <input
                        type="search"
                        name
                        id
                        placeholder="Type here to Search..."
                      />
                      <i className="fas fa-search" />
                    </div>
                  </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-4 order-lg-1">
                <div
                  className={`welcome-wrap ${
                    showPropertyDropdown ? "z-0" : ""
                  }`}
                >
                  <div className="welcome-inner">
                    <h4>
                      Welcome Back <span>Anddy’s Makeover</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <div className="px-5">
          <section className="search-wraper">
            <div className="row">
              <div className="col-xl-4 order-lg-2">
                <div className="search-right-side-wrap flex justify-content-evenly">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-8 order-lg-1">
                <div
                  // className={`welcome-wrap ${
                  //   showPropertyDropdown ? "z-0" : ""
                  // }`}
                >
                  <div className="welcome-inner flex align-items-center">
                    <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/property.png"
                alt=""
                /> 
                <h4>
                <h2>Editing {name}</h2>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <EditUnitForm
              initialData={
                Object.keys(formData).length ? formData : initialData
              }
              verticalScroll={verticalScroll}
              handleName={handleName}
              // updateFormData={updateFormData}
            />
          </div>
          <div className="bottom-gap" />
        </div>
        {showFooter ? <Footer/> : null}
        {/* <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Reserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </div>
  );
};
