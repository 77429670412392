import React from 'react'

export const CompanyRegister = () => {
  return (
    <>
    <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 min-h-full">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex justify-center">
                <div className="m-0 mb-5">
                  <img
                    className="logo"
                    src="/assets/img/logo-light.png"
                    alt=""
                  />
                  <img
                    className="logo-text"
                    src="/assets/img/logo-text-light.png"
                    alt=""
                  />
                </div>
              </div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Register as Company
              </h2>
              {/* <p className="mt-2 text-sm text-gray-600">
                Or{" "}
                <a
                  onClick={(e) => goToRegister(e)}
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  start your 14-day free trial
                </a>
              </p> */}
            </div>

            <div className="mt-8">
              {/* <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Sign in with
                  </p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Facebook</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with GitHub</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="mt-6">
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        // onChange={(e) => setEmail(e.target.value)}
                        // onKeyPress = {(e)=>{
                        //   if (e.key === "Enter") {
                        //   login(e)}}}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="address_line_1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address line 1
                    </label>
                    <div className="mt-1">
                      <input
                        id="address_line_1"
                        name="address_line_1"
                        type="address_line_1"
                        autoComplete="current-address_line_1"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        // onChange={(e) => setPassword(e.target.value)}
                        // onKeyPress = {(e)=>{
                        //   if (e.key === "Enter") {
                        //   login(e)}}}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="address_line_1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address line 2
                    </label>
                    <div className="mt-1">
                      <input
                        id="address_line_1"
                        name="address_line_1"
                        type="address_line_1"
                        autoComplete="address_line_1"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        // onChange={(e) => setPassword(e.target.value)}
                        // onKeyPress = {(e)=>{
                        //   if (e.key === "Enter") {
                        //   login(e)}}}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                        
                            <div className="flex-1">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
                                <input
                                id="city"
                                name="city"
                                type="text"
                                autoComplete="city"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                           
                            <div className="flex-1">
                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">State</label>
                                <input
                                id="state"
                                name="state"
                                type="text"
                                autoComplete="state"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                          
                            <div className="flex-1">
                                <label htmlFor="zip" className="block text-sm font-medium text-gray-700">Zip Code</label>
                                <input
                                id="zip"
                                name="zip"
                                type="text"
                                autoComplete="zip-code"
                                required
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        // onChange={(e) => setPassword(e.target.value)}
                        // onKeyPress = {(e)=>{
                        //   if (e.key === "Enter") {
                        //   login(e)}}}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Website
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        // onChange={(e) => setPassword(e.target.value)}
                        // onKeyPress = {(e)=>{
                        //   if (e.key === "Enter") {
                        //   login(e)}}}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="number1" className="block text-sm font-medium text-gray-700">Number 1</label>
                        <input
                        id="number1"
                        name="number1"
                        type="text"
                        autoComplete="off"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="w-1/4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                        <select
                        id="type"
                        name="type"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                        <option value="">Select</option>
                        </select>
                    </div>
                    </div>
                  <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="number1" className="block text-sm font-medium text-gray-700">Number 2</label>
                        <input
                        id="number1"
                        name="number1"
                        type="text"
                        autoComplete="off"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="w-1/4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                        <select
                        id="type"
                        name="type"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                        <option value="">Select</option>
                        </select>
                    </div>
                    </div>
                  <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="number1" className="block text-sm font-medium text-gray-700">Number 3</label>
                        <input
                        id="number1"
                        name="number1"
                        type="text"
                        autoComplete="off"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="w-1/4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                        <select
                        id="type"
                        name="type"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                        <option value="">Select</option>
                        </select>
                    </div>
                    </div>
                    <div class="relative">
                        <button class="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow-md flex items-center space-x-2">
                            <span>Regular Logo</span>
                            <svg class="h-5 w-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13.414v3.586c0 .442-.448.793-.993.793H3.993C3.448 18.793 3 18.442 3 18V5.586c0-.552.449-1 .993-1H7V2c0-.552.449-1 .993-1h3.014c.544 0 .993.448.993 1v2h3.007c.544 0 .993.448.993 1v7c0 .552-.449 1-.993 1h-3.007v2c0 .442-.448.793-.993.793h-2.014c-.544 0-.993-.351-.993-.793zM9 2.992H6v2.003h3V2.992zm-3 13.422V14h3v2.414l1.293-1.293 1.414 1.414L10 19.828l-3.707-3.707 1.414-1.414L8 15.414zM13 8V4.998h-2V8h2zm0-3c0-.553-.448-1.001-.993-1.001-.548 0-.993.448-.993 1.001v1.999c0 .553.448 1.001.993 1.001.548 0 .993-.448.993-1.001V5zm2 7.586L14.293 13H11.71L10 11.293 8.293 13H5.708L4 12.586V6h10v6.586z"/>
                            </svg>
                        </button>
                        <input type="file" class="opacity-0 absolute inset-0 w-full h-full cursor-pointer"/>
                    </div>
                    <div class="relative">
                        <button class="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow-md flex items-center space-x-2">
                            <span>Dark Mode Logo</span>
                            <svg class="h-5 w-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13.414v3.586c0 .442-.448.793-.993.793H3.993C3.448 18.793 3 18.442 3 18V5.586c0-.552.449-1 .993-1H7V2c0-.552.449-1 .993-1h3.014c.544 0 .993.448.993 1v2h3.007c.544 0 .993.448.993 1v7c0 .552-.449 1-.993 1h-3.007v2c0 .442-.448.793-.993.793h-2.014c-.544 0-.993-.351-.993-.793zM9 2.992H6v2.003h3V2.992zm-3 13.422V14h3v2.414l1.293-1.293 1.414 1.414L10 19.828l-3.707-3.707 1.414-1.414L8 15.414zM13 8V4.998h-2V8h2zm0-3c0-.553-.448-1.001-.993-1.001-.548 0-.993.448-.993 1.001v1.999c0 .553.448 1.001.993 1.001.548 0 .993-.448.993-1.001V5zm2 7.586L14.293 13H11.71L10 11.293 8.293 13H5.708L4 12.586V6h10v6.586z"/>
                            </svg>
                        </button>
                        <input type="file" class="opacity-0 absolute inset-0 w-full h-full cursor-pointer"/>
                    </div>
                    <div class="relative">
                        <button class="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow-md flex items-center space-x-2">
                            <span>Square Logo</span>
                            <svg class="h-5 w-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13.414v3.586c0 .442-.448.793-.993.793H3.993C3.448 18.793 3 18.442 3 18V5.586c0-.552.449-1 .993-1H7V2c0-.552.449-1 .993-1h3.014c.544 0 .993.448.993 1v2h3.007c.544 0 .993.448.993 1v7c0 .552-.449 1-.993 1h-3.007v2c0 .442-.448.793-.993.793h-2.014c-.544 0-.993-.351-.993-.793zM9 2.992H6v2.003h3V2.992zm-3 13.422V14h3v2.414l1.293-1.293 1.414 1.414L10 19.828l-3.707-3.707 1.414-1.414L8 15.414zM13 8V4.998h-2V8h2zm0-3c0-.553-.448-1.001-.993-1.001-.548 0-.993.448-.993 1.001v1.999c0 .553.448 1.001.993 1.001.548 0 .993-.448.993-1.001V5zm2 7.586L14.293 13H11.71L10 11.293 8.293 13H5.708L4 12.586V6h10v6.586z"/>
                            </svg>
                        </button>
                        <input type="file" class="opacity-0 absolute inset-0 w-full h-full cursor-pointer"/>
                    </div>
                    <div class="relative">
                        <button class="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow-md flex items-center space-x-2">
                            <span>Favicon</span>
                            <svg class="h-5 w-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13.414v3.586c0 .442-.448.793-.993.793H3.993C3.448 18.793 3 18.442 3 18V5.586c0-.552.449-1 .993-1H7V2c0-.552.449-1 .993-1h3.014c.544 0 .993.448.993 1v2h3.007c.544 0 .993.448.993 1v7c0 .552-.449 1-.993 1h-3.007v2c0 .442-.448.793-.993.793h-2.014c-.544 0-.993-.351-.993-.793zM9 2.992H6v2.003h3V2.992zm-3 13.422V14h3v2.414l1.293-1.293 1.414 1.414L10 19.828l-3.707-3.707 1.414-1.414L8 15.414zM13 8V4.998h-2V8h2zm0-3c0-.553-.448-1.001-.993-1.001-.548 0-.993.448-.993 1.001v1.999c0 .553.448 1.001.993 1.001.548 0 .993-.448.993-1.001V5zm2 7.586L14.293 13H11.71L10 11.293 8.293 13H5.708L4 12.586V6h10v6.586z"/>
                            </svg>
                        </button>
                        <input type="file" class="opacity-0 absolute inset-0 w-full h-full cursor-pointer"/>
                    </div>



                  {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div> */}

                  <div>
                    {/* {isSubmitting ? (
                      <button
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled="disabled"
                      >
                        <svg
                          className="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        Logging in...
                      </button>
                    ) : ( */}
                      <button
                        type="button"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        // onClick={(e) => login(e)}
                        
                      >
                        Register
                      </button>
                    {/* )} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative min-h-full w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://picsum.photos/1440"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
