import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import { ProfileEmergencyContacts } from "./ProfileEmergencyContacts";
import { ProfileEmployments } from "./ProfileEmployments";
import { ProfileReferences } from "./ProfileReferences";
import { ProfileChildren } from "./ProfileChildren";
import { ProfileOtherLivings } from "./ProfileOtherLivings";
import { ProfilePets } from "./ProfilePets";
import { ProfileVehicles } from "./ProfileVehicles";
import { userProfile } from "../../store/user/thunk";
import { useDispatch } from "react-redux";
import Loading from "../Loader/Loading";
import imgUrl from "../ImageUrl";
import useAlerts from "../../utility/alerts";
import { Tooltip } from "../Loader/Tooltip";
import Select from "react-select";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import CurrencyInput from "../genericFunctions.js/CurrencyInput";
export const TenantProfile = ({ initialData = {} }) => {
  const Alert = useAlerts();
  const initialAddressData = {
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
  };
  const initialNumberData = [
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
  ];
  const initialListingData = {
    show_listing: "",
    max_monthly_rent: "",
    areas: "",
    type_id: "",
    bedrooms: "",
    bathrooms: "",
    place_with_id: "",
    private_bedroom: "",
    private_bathroom: "",
    lgbtq_friendly: "",
    pets_ok: "",
    desired_features: "",
  };
  const initialCreateLogData = {
    role_id: parseInt(localStorage.getItem("Role")),
    firstname: 0,
    middlename: 0,
    lastname: 0,
    address_line1: 0,
    address_line2: 0,
    country_code_1: 0,
    country_code_2: 0,
    country_code_3: 0,
    phone_number_1: 0,
    phone_number_2: 0,
    phone_number_3: 0,
    number_type_1: 0,
    number_type_2: 0,
    number_type_3: 0,
    email: 0,
    country: 0,
    city: 0,
    state: 0,
    zip_code: 0,
    dl_id_picture: 0,
    dln_id: 0,
    dln_id_issue_country: 0,
    dln_id_issue_state: 0,
    pictures: {
      added: [],
      deleted: [],
    },
    ssn: 0,
    gender_id: 0,
    ethnicity_id: 0,
    date_of_birth: 0,
    moveout_address1: 0,
    moveout_address2: 0,
    moveout_email: 0,
    moveout_number: 0,
    moveout_number_type: 0,
    moveout_country: 0,
    moveout_city: 0,
    moveout_state: 0,
    moveout_zip_code: 0,
  };
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(undefined);
  const [tenantEmergencyContacts, setTenantEmergencyContacts] = useState([]);
  const [TenantFormImagesWithUrl, setTenantFormImagesWithUrl] = useState([]);
  const [tenantInteraction, setTenantInteraction] = useState([]);
  const [tenantEmployment, setTenantEmployment] = useState([]);

  const [tenantReference, setTenantReference] = useState([]);
  const [tenantOtherLiving, setTenantOtherLiving] = useState([]);
  const [tenantPet, setTenantPet] = useState([]);
  const [tenantAddress, setTenantAddress] = useState([]);
  const [tenantListing, setTenantListing] = useState([]);
  const [features, setFeatures] = useState([]);
  const [unitFeatures, setUnitFeatures] = useState([]);
  const [addFeature, setAddFeature] = useState([]);
  const [number, setNumber] = useState([]);
  const [dlidPicture, setDlidPicture] = useState([]);
  const [gender, setGender] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [editorData, setEditorData] = useState();
  const [editorData1, setEditorData1] = useState();
  const [editorData2, setEditorData2] = useState();
  const [editorData3, setEditorData3] = useState();
  const [unitTypes, setUnitTypes] = useState([]);
  const [places, setPlaces] = useState([]);
  const [tenantNotification, setTenantNotification] = useState([]);
  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [propertyManagerDocumentsWithUrl, setPropertyManagerDocumentsWithUrl] =
    useState([]);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [propertyOwnerDocumentsWithUrl, setPropertyOwnerDocumentsWithUrl] =
    useState([]);
  const [securedFields, setSecuredFields] = useState({
    ssn: "password",
    dln_id: "password",
  });
  const [tenantKey, setTenantKey] = useState([]);
  const [loanableItem, setLoanableItem] = useState([]);
  const [tenantVehicle, setTenantVehicle] = useState([]);
  const [tenantChildren, setTenantChildren] = useState([]);
  const [tenantItem, setTenantItem] = useState([]);
  // console.log("@FormData",formData);
  console.log("@tenantListing", tenantListing);
  // console.log("@TenantFormImagesWithUrl",TenantFormImagesWithUrl);
  // console.log("@initialData",initialData);
  const [tabValue, setTabValue] = useState(0);
  const [numberType, setNumberType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [issueStates, setIssueStates] = useState([]);
  const [cities, setCities] = useState([]);
  const getCountries = async () => {
    const response = await ApiRequest.get("public/api/auth/get-countries");
    if (response.status == 200) {
      setCountries(response.data?.data?.countries);
    }
  };
  const getStates = async (id) => {
    const response = await ApiRequest.get(
      `public/api/auth/get-states?country_id=${id}`
    );
    if (response.status == 200) {
      setStates(response.data?.data?.states);
    }
  };
  const getIssueStates = async (id) => {
    const response = await ApiRequest.get(
      `public/api/auth/get-states?country_id=${id}`
    );
    if (response.status == 200) {
      setIssueStates(response.data?.data?.states);
    }
  };
  const getCities = async (data) => {
    const state = states?.map((col) => {
      if (col?.id == data) {
        return col?.state_code;
      }
    });
    const filteredArr = state.filter((value) => value !== undefined);
    const stateCode = filteredArr.join(", ");

    console.warn("data", data);
    console.warn("states", states);
    console.warn("state_code", stateCode);

    const response = await ApiRequest.get(
      `public/api/auth/get-cities?state_code=${stateCode}`
    );

    if (response.status == 200) {
      setCities(response.data?.data?.cities);
    }
  };
  const getNumberType = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-number-types");
    if (response.status == 200) {
      setNumberType(response?.data?.data?.number_types);
    }
  };
  const handleEmergencyContactTrue = (message) => {
    setTenantEmergencyContacts(message);
  };
  const handleInteractionTrue = (message) => {
    setTenantInteraction(message);
  };
  const handleReferenceTrue = (message) => {
    setTenantReference(message);
  };
  const handleEmploymentTrue = (message) => {
    setTenantEmployment(message);
  };
  const handleOtherLivingTrue = (message) => {
    setTenantOtherLiving(message);
  };
  const handleChildrenTrue = (message) => {
    setTenantChildren(message);
  };
  const handleVehicleTrue = (message) => {
    setTenantVehicle(message);
  };
  const handlePetTrue = (message) => {
    setTenantPet(message);
  };
  const handleKeyTrue = (message) => {
    setTenantKey(message);
  };
  const handleItemTrue = (message) => {
    setTenantItem(message);
  };
  const handleNotificationTrue = (message) => {
    setTenantNotification(message);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const [bedRoomsData] = useState(() => {
    const optionsArray = ["Shared", "Other", "Studio"];
    for (let i = 1; i <= 200; i++) {
      optionsArray.push(i + " bedrooms");
    }
    // for (let x = 1; x<=14; x++)
    // {
    //   optionsArray.push(x + " studio");
    // }
    return optionsArray;
  });
  const [bathRoomsData] = useState(() => {
    const optionsArray = [];
    for (let i = 0.5; i <= 50; i += 0.5) {
      optionsArray.push(i + " bathrooms");
    }
    return optionsArray;
  });
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };
  function removeCurrencyAndCommas(str) {
    if (str === null) {
      return '';
    }
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  const imageComponent = ({
    image = {},
    key = "",
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="property image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

  const [ownerSettings, setOwnerSettings] = useState([]);
  console.warn("ownerSettings", ownerSettings);

  const getOwnerSettings = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-current-owner-settings"
    );
    if (response?.status == 200) {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  };
  const [listingAreas, setListingAreas] = useState([]);
  const [selectedListingAreas, setSelectedListingAreas] = useState([]);
  console.warn('lisitngArea',listingAreas);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSelectAreaChange = (selectedOptions) => {
    setSelectedOptions((prevOptions) => [...prevOptions, ...selectedOptions]);
    const areasString = selectedOptions.map((option) => option.value).join(',');
  
    const areasArray = areasString.split(",").map(Number);
  
    setTenantListing({
      ...tenantListing,
      areas: areasString,
    });
  
    getSelectedCities(areasArray);
  };
  
  
  

  const getSearchCities = async (data) => {
    const response = await ApiRequest.get(
      `/public/api/auth/search-cities?term=${data}`
    );
    if(response.status == 200)
    {
      const cities = response?.data?.data?.$cities || [];
    const options = cities.map((city) => ({
      value: city.id,
      label: `${city.city} (${city.zip_code})`,
    }));    
    setListingAreas(options);
    }
  };

  const getSelectedCities = async (arr)=>{
    const response = await ApiRequest.post('/public/api/auth/get-cities-by-ids',{
      ids:arr
    })
    if(response.status == 200)
    {
      const cities = response?.data?.data?.cities || [];
      const options = cities.map((city) => ({
        value: city.id,
        label: `${city.city} (${city.zip_code})`,
      }));    
      setSelectedListingAreas(options);
    }
  }

  useEffect(() => {
    getSearchCities(searchValue);
  }, [searchValue]);
  const handleSearchInputChange = (value) => {
    // Perform API search based on the search value
    setSearchValue(value);
    // getSearchCities(searchValue);
  };
  const getGenders = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-genders");
    if (response.status == 200) {
      setGender(response?.data?.data?.genders);
    }
  };
  const getEthnicity = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-ethnicities");
    if (response.status == 200) {
      setEthnicity(response?.data?.data?.ethnicities);
    }
  };
  const getUnitTypes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-all-units-types"
    );
    if (response.status == 200) {
      setUnitTypes(response.data?.data);
    }
  };
  const getPlacesWith = async () => {
    const response = await ApiRequest.get("public/api/auth/get-places-withs");
    if (response.status == 200) {
      setPlaces(response.data?.data?.places_withs);
    }
  };
  const getFeatures = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-features-for-properties"
    );
    if (response.status == 200) {
      setFeatures(response?.data?.data?.features);
    }
  };
  const getUnitFeatures = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-features-for-units"
    );
    if (response.status == 200) {
      setUnitFeatures(response?.data?.data?.features);
    }
  };
  const getData = async () => {
    const response = await dispatch(userProfile());
    setLoading(undefined);
    if (response.meta.requestStatus == "fulfilled") {
      const res = await ApiRequest.get(
        `/public/api/auth/get-tenant?tenant_id=${response?.payload?.id}`
      );
      if (res.status === 200) {
        setTenantID(response?.payload?.id);
        // const data = res?.data?.data?.tenants?.map(obj => obj?.email .filter(email == localStorage.getItem('email')));
        setFormData(res?.data?.data);
        setTenantListing(res?.data?.data?.listings_infos[0]);
        const areasArr = res?.data?.data?.listings_infos[0]?.areas?.split(',').map(Number);
        getSelectedCities(areasArr);
        setTenantFormImagesWithUrl(res?.data?.data?.pictures);
        dlidPicture?.pictures?.push({
          picture: res?.data?.data?.dl_id_picture,
        });
        setEditorData({
          ...editorData,
          user_notes: res?.data?.data?.profile?.user_notes,
          ...editorData,
          dln_id_issue_notes: res?.data?.data?.profile?.dln_id_issue_notes,
        });
        setEditorData1({
          ...editorData1,
          user_manager_notes: res?.data?.data?.profile?.user_manager_notes,
          ...editorData1,
          smoker_notes: res?.data?.data?.profile?.smoker_notes,
        });
        setEditorData2({
          ...editorData2,
          user_staff_notes: res?.data?.data?.profile?.user_staff_notes,
          ...editorData2,
          about_me: res?.data?.data?.about_me,
        });
        setEditorData3({
          ...editorData3,
          user_owner_notes: res?.data?.data?.profile?.user_owner_notes,
        });
        setTenantNotification(res?.data?.data?.notifications);
        setPropertyPublicDocumentsWithUrl(res?.data?.data?.files);
        setPropertyStaffDocumentsWithUrl(res?.data?.data?.staff_files);
        setPropertyManagerDocumentsWithUrl(res?.data?.data?.managers_files);
        setPropertyOwnerDocumentsWithUrl(res?.data?.data?.owners_files);
        setTenantInteraction(res?.data?.data?.interactions);
        setTenantKey(res?.data?.data?.keys_trackings);
        setTenantItem(res?.data?.data?.loanable_items_trackings);
        setTenantVehicle(res?.data?.data?.vehicles);
        setTenantChildren(res?.data?.data?.childrens);
        setTenantPet(res?.data?.data?.pets);
        setTenantOtherLiving(res?.data?.data?.other_livings);
        setTenantEmployment(res?.data?.data?.employments);
        setTenantReference(res?.data?.data?.references);
        setTenantEmergencyContacts(res?.data?.data?.emergencies_contacts);
        setTenantAddress(res?.data?.data?.addresses);
        setNumber(res?.data?.data?.numbers);
        setLoading(res);
        getStates(res?.data?.data?.addresses[0]?.country);
        getIssueStates(res?.data?.data?.profile?.dln_id_issue_country);
        // const response = await ApiRequest.get()
      }
    }
  };
  const removeByAttr = (arr, attr, value) => {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    // console.warn("UnformatDate",date)
    return date;
  }
  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, "") || "";
    let formattedNumber = "";
    let digitIndex = 0;

    for (let i = 0; i < format?.length; i++) {
      if (format[i] === "#") {
        formattedNumber += digitsOnly[digitIndex] || "";
        digitIndex++;
      } else if (format[i] === "_") {
        formattedNumber += " ";
      } else {
        formattedNumber += format[i];
      }
    }

    return formattedNumber;
  };
  useEffect(() => {
    getData();
    getOwnerSettings();
    getCountries();
    getNumberType();
    getFeatures();
    getUnitFeatures();
    getEthnicity();
    getGenders();
    getUnitTypes();
    getPlacesWith();
    setFormData(initialData);
    setTenantListing(initialListingData);
    setCreateLog(initialCreateLogData);
    setDlidPicture([]);
    setTenantAddress(
      initialData?.addresses?.length
        ? initialData?.addresses
        : [{ ...initialAddressData }]
    );
    // if (initialData?.shots?.length) {
    //   setTenantKey(initialData.shots);
    //   setTenantShotPicture(
    //     initialData.shots.map((shot) => shot?.proof_picture)
    //   );
    // } else {
    //   setTenantShot([{ ...initialShotData }]);
    //   setTenantShotPicture([]);
    // }
    setNumber(initialNumberData);
  }, []);
  const [tenantID, setTenantID] = useState(null);
  const [createLog, setCreateLog] = useState();
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(userProfile());

      if (response.meta.requestStatus == "fulfilled") {
        const res = await ApiRequest.post("/public/api/auth/tenant", {
          id: response?.payload?.id,
          create_log: createLog,
          enable_portal: formData?.enable_portal,
          send_notifications: formData?.send_notifications,
          user_prefix_id: formData?.user_prefix_id,
          firstname: formData?.firstname,
          middlename: formData?.middlename,
          lastname: formData?.lastname,
          user_suffix_id: formData?.user_suffix_id,
          user_role_type_id: 5,
          addresses: formData?.addresses,
          numbers: formData?.numbers,
          email: formData?.email,
          ssn: formData?.ssn,
          date_of_birth: formData?.date_of_birth,
          dln_id: formData?.dln_id,
          dl_id_picture: formData?.dl_id_picture,
          dln_id_issue_country: formData?.dln_id_issue_country,
          dln_id_issue_state: formData?.dln_id_issue_state,
          dln_id_issue_notes: editorData?.dln_id_issue_notes,
          gender_id: formData?.gender_id,
          ethnicity_id: formData?.ethnicity_id,
          smoker: formData?.smoker,
          smoker_notes: editorData1?.smoker_notes,
          pictures: formData?.pictures,
          emergencies_contacts: tenantEmergencyContacts,
          known_allergies: formData?.known_allergies,
          other_emergency_info: formData?.other_emergency_info,
          notifications: tenantNotification,
          user_files: formData?.user_files,
          user_notes: editorData?.user_notes,
          user_staff_files: formData?.user_staff_files,
          user_staff_notes: editorData2?.user_staff_notes,
          user_manager_files: formData?.user_manager_files,
          user_manager_notes: editorData1?.user_manager_notes,
          user_owner_files: formData?.user_owner_files,
          user_owner_notes: editorData3?.user_owner_notes,
          interactions: tenantInteraction,
          keys_trackings: tenantKey,
          loanable_items_trackings: tenantItem,
          vehicles: tenantVehicle,
          childrens: tenantChildren,
          pets: tenantPet,
          other_livings: tenantOtherLiving,
          employments: tenantEmployment,
          references: tenantReference,
          moveout_address1: formData?.moveout_address1,
          moveout_address2: formData?.moveout_address2,
          moveout_city: formData?.moveout_city,
          moveout_state: formData?.moveout_state,
          moveout_zip_code: formData?.moveout_zip_code,
          moveout_number: formData?.moveout_number,
          moveout_number_type: formData?.moveout_number_type,
          moveout_email: formData?.moveout_email,
          moveout_date: formData?.moveout_date,
          moveout_reason_id: formData?.moveout_reason_id,
          listings_infos: tenantListing,
        });
        console.log("Result formData", formData);
        if (res.status == 200) {
          Alert.addSuccess("Tenant Profile Edited Successfully");
          setTenantID(res?.data?.data);
          getData();
        }
      }
    } catch (err) {
      console.log(err);
      Alert.addFailure(
        <ul>
          {err?.response?.data?.email ? (
            <li>{err?.response?.data?.email}</li>
          ) : null}
          {err?.response?.data?.firstname ? (
            <li>{err?.response?.data?.firstname}</li>
          ) : null}
          {err?.response?.data?.lastname ? (
            <li>{err?.response?.data?.lastname}</li>
          ) : null}
        </ul>
      );
    }
  };

  return (
    <>
      {loading == undefined ? (
        <Loading />
      ) : (
        <form
          className="w-full"
          id="tenant-profile-form"
          onSubmit={formSubmitHandler}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Tenant Profile" {...a11yProps(0)} />
            <Tab label="Listing Information" {...a11yProps(1)} />
            <Tab label="Emergency Information" {...a11yProps(2)} />
            <Tab label="Notes & Files" {...a11yProps(3)} />
            <Tab label="Employment" {...a11yProps(4)} />
            <Tab label="References" {...a11yProps(5)} />
            <Tab label="Children" {...a11yProps(6)} />
            <Tab label="Pets" {...a11yProps(7)} />
            <Tab label="Others Living" {...a11yProps(8)} />
            <Tab label="Vehicles" {...a11yProps(9)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        First Name:
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="firstname"
                        type="text"
                        placeholder="John"
                        required={false}
                        value={formData?.firstname}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, firstname: 1 });
                          setFormData({
                            ...formData,
                            firstname: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Middle Name:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="middlename"
                        type="text"
                        placeholder="John"
                        required={false}
                        value={formData?.middlename}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, middlename: 1 });
                          setFormData({
                            ...formData,
                            middlename: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <div className="md:w-3/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Last Name:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="lastname"
                        type="text"
                        placeholder="Ben"
                        required={false}
                        value={formData?.lastname}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, lastname: 1 });
                          setFormData({
                            ...formData,
                            lastname: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Deo"
                      required={false}
                      value={formData?.lastname}
                      onChange={(e) => {
                        setFormData({ ...formData, lastname: e.target.value });
                      }}
                    />
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                {/* {tenantAddress?.map((row, index) => {
                const addresses = [...tenantAddress];
                return (
                  <> */}
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 1:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-address-line-1"
                      type="text"
                      placeholder="6284 Locust Road"
                      required={false}
                      value={formData?.addresses[0]?.address_line1}
                      onChange={(e) => {
                        const addresses = [...tenantAddress];
                        addresses[0].address_line1 = e.target.value;
                        setTenantAddress(addresses);
                        setCreateLog({ ...createLog, address_line1: 1 });
                        setFormData({ ...formData, addresses: addresses });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Address Line 2:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-address_line2"
                      type="text"
                      placeholder="Unit #5"
                      value={formData?.addresses[0]?.address_line2}
                      onChange={(e) => {
                        const addresses = [...tenantAddress];
                        addresses[0].address_line2 = e.target.value;
                        setTenantAddress(addresses);
                        setCreateLog({ ...createLog, address_line2: 1 });
                        setFormData({ ...formData, addresses: addresses });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Country:
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="addresses-country"
                        type="text"
                        placeholder="Albuquerque"
                        required={false}
                        value={formData?.addresses[0]?.country}
                        onChange={(e) => {
                          const addresses = [...tenantAddress];
                          addresses[0].country = e.target.value;
                          setTenantAddress(addresses);
                          setCreateLog({ ...createLog, country: 1 });
                          setFormData({
                            ...formData,
                            addresses: addresses,
                          });
                          getStates(e.target.value);
                        }}
                      >
                        <option value=" ">Select Country</option>
                        {countries?.map((country) => (
                          <option value={country?.id}>
                            {country?.country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        State:
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="addresses-state"
                        required={false}
                        value={formData?.addresses[0]?.state}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, state: 1 });
                          const addresses = [...tenantAddress];
                          addresses[0].state = e.target.value;
                          setTenantAddress(addresses);
                          setFormData({
                            ...formData,
                            addresses: addresses,
                          });
                          getCities(e.target.value);
                        }}
                      >
                        <option value=" ">Select State</option>
                        {states?.map((state) => (
                          <option
                            value={state?.id}
                          >{`${state?.state} (${state?.state_code})`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        City:
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="addresses-city"
                        type="text"
                        placeholder="Albuquerque"
                        required={false}
                        value={formData?.addresses[0]?.city}
                        onChange={(e) => {
                          const addresses = [...tenantAddress];
                          const zip = cities?.map((col) => {
                            if (col?.id == e.target.value) {
                              return col?.zip_code;
                            }
                          });
                          setCreateLog({ ...createLog, city: 1 });
                          const filteredArr = zip.filter(
                            (value) => value !== undefined
                          );
                          const zipCode = filteredArr.join(", ");
                          addresses[0].city = e.target.value;
                          addresses[0].zip_code = zipCode;
                          setTenantAddress(addresses);
                          setFormData({
                            ...formData,
                            addresses: addresses,
                          });
                        }}
                      >
                        <option value=" ">Select City</option>
                        {cities?.map((city) => (
                          <option
                            value={city?.id}
                          >{`${city?.city} (${city?.zip_code})`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Zip:
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-zip"
                        type="text"
                        placeholder="90210"
                        required={false}
                        value={formData?.addresses[0]?.zip_code}
                        onChange={(e) => {
                          const addresses = [...tenantAddress];
                          addresses[0].zip_code = e.target.value;
                          setCreateLog({ ...createLog, zip_code: 1 });
                          setTenantAddress(addresses);
                          setFormData({
                            ...formData,
                            addresses: addresses,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* </>
                 );
              })}  */}
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Number 1:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {formData?.numbers[0]?.number_type == 8 ? (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-l`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={number?.[0]?.phone_number}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[0].phone_number = e.target.value;
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    ) : (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-l`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(
                          number?.[0]?.phone_number,
                          ownerSettings?.phone_number_format
                        )}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[0].phone_number = removeNonNumeric(
                            e.target.value
                          );
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    )}
                  </div>
                  <div className="md:w-1/5 pl-3">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`tenant-num-1-type`}
                      value={formData?.numbers[0]?.number_type}
                      onChange={(e) => {
                        setCreateLog({ ...createLog, number_type_1: 1 });
                        const phone = [...number];
                        phone[0].number_type = e.target.value;
                        setNumber(phone);
                        setFormData({ ...formData, numbers: phone });
                      }}
                    >
                      <option value=" ">Select Type</option>
                      {numberType?.map((row) => (
                        <option value={row?.id}>{row?.type}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Number 2:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {formData?.numbers[1]?.number_type == 8 ? (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-2`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={number?.[1]?.phone_number}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[1].phone_number = e.target.value;
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    ) : (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-2`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(
                          number?.[1]?.phone_number,
                          ownerSettings?.phone_number_format
                        )}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[1].phone_number = removeNonNumeric(
                            e.target.value
                          );
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    )}
                  </div>
                  <div className="md:w-1/5 pl-3">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`tenant-num-2-type`}
                      value={formData?.numbers[1]?.number_type}
                      onChange={(e) => {
                        setCreateLog({ ...createLog, number_type_2: 1 });
                        const phone = [...number];
                        phone[1].number_type = e.target.value;
                        setNumber(phone);
                        setFormData({ ...formData, numbers: phone });
                      }}
                    >
                      <option value=" ">Select Type</option>
                      {numberType?.map((row) => (
                        <option value={row?.id}>{row?.type}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Number 3:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    {formData?.numbers[2]?.number_type == 8 ? (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-3`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={number?.[2]?.phone_number}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[2].phone_number = e.target.value;
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    ) : (
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`tenant-num-3`}
                        type="tel"
                        placeholder="(999) 999-9999"
                        value={formatPhoneNumber(
                          number?.[2]?.phone_number,
                          ownerSettings?.phone_number_format
                        )}
                        onChange={(e) => {
                          const phone = [...number];
                          setCreateLog({ ...createLog, phone_number_1: 1 });
                          phone[2].phone_number = removeNonNumeric(
                            e.target.value
                          );
                          setNumber(phone);
                          setFormData({ ...formData, numbers: phone });
                        }}
                        maxLength={ownerSettings?.phone_number_format?.length}
                      />
                    )}
                  </div>
                  <div className="md:w-1/5 pl-3">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`tenant-num-3-type`}
                      value={formData?.numbers[2]?.number_type}
                      onChange={(e) => {
                        setCreateLog({ ...createLog, number_type_3: 1 });
                        const phone = [...number];
                        phone[2].number_type = e.target.value;
                        setNumber(phone);
                        setFormData({ ...formData, numbers: phone });
                      }}
                    >
                      <option value=" ">Select Type</option>
                      {numberType?.map((row) => (
                        <option value={row?.id}>{row?.type}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="md:flex md:items-center mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`email`}
                      type="email"
                      placeholder="jhon.doe@email.com"
                      value={formData?.email}
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                        setCreateLog({ ...createLog, email: 1 });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4 flex">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        SSN:
                      </label>
                      <Tooltip
                        data={
                          "Please note that we take every precaution to safeguard this information."
                        }
                      />
                    </div>
                    <div className="md:w-3/4 relative">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="ssn"
                        type={securedFields.ssn}
                        value={formData?.ssn}
                        placeholder="18293213"
                        required={false}
                        onChange={(e) => {
                          setFormData({ ...formData, ssn: e.target.value });
                        }}
                      />
                      <span
                        className="absolute right-0 top-0 py-3 px-3"
                        onClick={() => {
                          setSecuredFields({
                            ...securedFields,
                            ssn:
                              securedFields.ssn === "password"
                                ? "text"
                                : "password",
                          });
                        }}
                      >
                        {securedFields.ssn === "password" ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Date of Birth:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <DatePicker
                        value={stringToDate(formData?.date_of_birth)}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, date_of_birth: 1 });
                          const date = formatDate(e);
                          setFormData({ ...formData, date_of_birth: date });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4 flex">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        DLN/ID:
                      </label>
                      <Tooltip
                        data={
                          "Please note that we take every precaution to safeguard this information."
                        }
                      />
                    </div>
                    <div className="md:w-3/4 relative">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="dln_id"
                        type={securedFields.dln_id}
                        value={formData?.dln_id}
                        placeholder="18293213"
                        required={false}
                        onChange={(e) => {
                          setFormData({ ...formData, dln_id: e.target.value });
                        }}
                      />
                      <span
                        className="absolute right-0 top-0 py-3 px-3"
                        onClick={() => {
                          setSecuredFields({
                            ...securedFields,
                            dln_id:
                              securedFields.dln_id === "password"
                                ? "text"
                                : "password",
                          });
                        }}
                      >
                        {securedFields.dln_id === "password" ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        DLN/ID Country:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="dln_id_issue_country"
                        value={formData?.dln_id_issue_country}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            dln_id_issue_country: e.target.value,
                          });
                          setCreateLog({
                            ...createLog,
                            dln_id_issue_country: 1,
                          });
                          getIssueStates(parseInt(e.target.value));
                        }}
                      >
                        <option value="">Select country</option>
                        {countries?.map((country) => (
                          <option value={country?.id}>
                            {country?.country}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-3/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        DLN/ID issue state:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="dln_id_issue_state"
                        value={formData?.dln_id_issue_state}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            dln_id_issue_state: e.target.value,
                          });
                          setCreateLog({ ...createLog, dln_id_issue_state: 1 });
                        }}
                      >
                        <option value="">Select State</option>
                        {issueStates?.map((state) => (
                          <option value={state?.id}>{state?.state}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        DL/ID Picture:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload ID
                        </span>
                        <input
                          accept="image/*"
                          id={`dl/id-pic`}
                          type="file"
                          hidden={true}
                          onChange={async (e) => {
                            let file = e.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const imgBase64 = reader.result;
                              try {
                                console.log(imgBase64);
                                const res = await ApiRequest.post(
                                  "/public/api/auth/upload-image",
                                  { image: imgBase64 }
                                );
                                // console.log("res", res?.data?.data?.image);
                                // formData?.dl_id_picture = res?.data?.data?.image
                                setFormData({
                                  ...formData,
                                  dl_id_picture: res?.data?.data?.image,
                                });
                                setCreateLog({
                                  ...createLog,
                                  dl_id_picture: 1,
                                });
                                // setDlidPicture(res?.data?.data?.image)
                                // setFeatureIcon(res?.data?.data?.image);
                              } catch (err) {
                                console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                            const pictureWithURL = getImagesAndURLs(e)[0];
                            setDlidPicture(pictureWithURL);

                            // console.log("here 142");
                            // const pictures = [...propertyAssistantPictures];
                            // pictures[index] = getImagesAndURLs(e)[0];
                            // setPropertyAssistantPictures(pictures);
                            // setPropertyContacts(contacts);
                            // setFormData({...formData, contacts : contacts})
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {dlidPicture[0]?.pictures?.picture != null ? (
                  <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                    <div className="container w-full">
                      <div
                        id="image-block"
                        className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                      >
                        {imageComponent({
                          image: dlidPicture?.image?.picture,
                          src: dlidPicture?.url,
                          inputFieldId: "dlid-picture",
                          trashAble: true,
                          onTrashClick: () => {
                            formData.dl_id_picture = null;
                            setDlidPicture(null);
                          },
                        })}
                      </div>
                    </div>
                  </section>
                ) : null}
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      DLN/ID Issue Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                    // initData={editorData?.dln_id_issue_notes}
                    // onChange={(e) => {
                    //   setEditorData({
                    //     ...editorData,
                    //     dln_id_issue_notes: e.editor.getData(),
                    //   });
                    // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4 flex">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Gender:
                      </label>
                      <Tooltip
                        data={
                          "This is used for demographic data. Most governing areas prohibit the use of gender to be a deciding factor when leasing a property."
                        }
                      />
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`gender`}
                        value={formData?.gender_id}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            gender_id: parseInt(e.target.value),
                          });
                          setCreateLog({ ...createLog, gender_id: 1 });
                        }}
                      >
                        <option value="">Select Gender</option>
                        {gender.map((row) => (
                          <option value={row?.id}>{row?.gender}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/3 flex">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Ethnicity:
                      </label>
                      <Tooltip
                        data={
                          "This is used for demographic data. Most governing areas prohibit the use of ethnicity to be a deciding factor when leasing a property."
                        }
                      />
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`ethnicity`}
                        value={formData?.ethnicity_id}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            ethnicity_id: parseInt(e.target.value),
                          });
                          setCreateLog({ ...createLog, ethnicity_id: 1 });
                        }}
                      >
                        <option value="">Select Ethnicity</option>
                        {ethnicity.map((row) => (
                          <option value={row?.id}>{row?.ethnicity}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Smoker:
                        </label>
                      </div>
                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          value={formData?.smoker}
                          onChange={(e) => {
                            const checkedValue =
                              e.target.checked === true ? 1 : 0;
                            setFormData({
                              ...formData,
                              smoker: checkedValue,
                            });
                          }}
                          class="sr-only peer"
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                </div>
                {formData?.smoker ? (
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Smoking Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData1?.smoking_notes}
                        onChange={(e) => {
                          const cleanText = e.editor
                            .getData()
                            ?.replace(/<\/?[^>]+(>|$)/g, "");
                          setEditorData1({
                            ...editorData1,
                            smoking_notes: cleanText,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {/* <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                    Pictures:
                  </label>
                </div>
                <div className="md:flex flex-column md:items-start w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept="image/*"
                          id="tenant-images"
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                             files.forEach((file, index) => {
                               base64Converter(file, index);
                             });
                             setTenantFormImagesWithUrl(getImagesAndURLs(e));
                           }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              TenantFormImagesWithUrl?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}

                {/* Gallery  */}

                {/* <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                {TenantFormImagesWithUrl?.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="images-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {TenantFormImagesWithUrl.map((imageObject, index) =>
                            imageComponent({
                              image: imageObject?.image?.picture,
                              key: index,
                              src: imageObject?.url,
                              inputFieldId: "property-images",
                              trashAble: true,
                              onTrashClick: () => {
                                let images = [...formData?.pictures];
                                let image = [...TenantFormImagesWithUrl];
                                images.splice(index, 1);
                                image.splice(index, 1);
                                setTenantFormImagesWithUrl(image);
                                setFormData({ ...formData, pictures: images });
                              },
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div> */}
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex justify-end w-full mt-4">
                    <div className="w-auto mt-2 ">
                      <button
                        type="button"
                        className="btn2"
                        // onClick={() => {
                        //     setShowForm(true)
                        // }}
                      >
                        Preview Listing
                      </button>
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Looking For:
                      </label>
                    </div>
                    {/* <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.enable_portal}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          enable_portal: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label> */}
                  </div>
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-3/5 show-to-manager">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Show Listing on Rent Blaze:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={tenantListing?.show_listing}
                        checked={tenantListing?.show_listing == 1}
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setTenantListing({
                            ...tenantListing,
                            show_listing: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Maximum Monthly Rent<span>*</span> :
                      </label>
                    </div>
                    <div className="md:w-1/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="max_monthly_rent"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantListing?.max_monthly_rent != null ? tenantListing?.max_monthly_rent : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantListing({ ...tenantListing, max_monthly_rent: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantListing({ ...tenantListing, max_monthly_rent: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Areas<span>*</span> :
                      </label>
                    </div>
                    <div className="md:w-full">
                    <Select
                      options={listingAreas}
                      value={selectedListingAreas?.filter(option => tenantListing.areas.includes(option.value))}
                      onChange={handleSelectAreaChange}
                      onInputChange={handleSearchInputChange}
                      inputValue={searchValue}
                      isSearchable
                      isMulti
                    />


                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <label className=" input-label text-gray-700 font-bold mx-1">
                      Type:<span title="Field is required.">*</span>
                    </label>
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-property-type"
                      value={tenantListing?.type_id}
                      required={true}
                      placeholder="Name"
                      onChange={(e) => {
                        setTenantListing({
                          ...tenantListing,
                          type_id: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Type</option>
                      {unitTypes.map((unitType) => (
                        <option value={unitType?.id}>{unitType?.type}</option>
                      ))}
                    </select>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                    <label className="input-label text-gray-700 font-bold mx-1">
                      Bedrooms:
                      <span title="Field is required.">*</span>
                    </label>

                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-property-type"
                      value={tenantListing?.bedrooms}
                      required={true}
                      //   placeholder="Name"
                      onChange={(e) => {
                        setTenantListing({
                          ...tenantListing,
                          bedrooms: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Bedroom</option>
                      {bedRoomsData?.map((bedRoomData) => (
                        <option value={bedRoomData}>{bedRoomData}</option>
                      ))}
                    </select>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                    <label className="input-label text-gray-700 font-bold mx-1">
                      Bathrooms:
                      <span title="Field is required.">*</span>
                    </label>

                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-property-type"
                      value={tenantListing?.bathrooms}
                      required={true}
                      //   placeholder="Name"
                      onChange={(e) => {
                        setTenantListing({
                          ...tenantListing,
                          bathrooms: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Bathroom</option>
                      {bathRoomsData?.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        I want a place with<span>*</span> :
                      </label>
                    </div>
                    <div className="md:w-2/4">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="place_with_id"
                        required={true}
                        value={tenantListing?.place_with_id}
                        onChange={(e) => {
                          setTenantListing({
                            ...tenantListing,
                            place_with_id: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value="">Select With</option>
                        {places?.map((row) => (
                          <option value={row?.id}>{row?.with}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-input-wrapper">
                  {/* <div className="w-full border-2">
							
						</div> */}

                  <div className="w-full">
                    <label className="input-label text-gray-700 font-bold">
                      Private Bedroom:
                    </label>

                    <label class="inline-flex relative items-center cursor-pointer">
                      {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                      <input
                        type="checkbox"
                        value={tenantListing?.private_bedroom}
                        checked={tenantListing?.private_bedroom == 1}
                        class="sr-only peer"
                        onChange={(e) => {
                          const isChecked = e.target.checked;

                          setTenantListing({
                            ...tenantListing,
                            private_bedroom: isChecked ? 1 : 0,
                          });
                        }}
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="w-full">
                    <label className="input-label text-gray-700 font-bold">
                      Private Bathroom:
                    </label>

                    <label class="inline-flex relative items-center cursor-pointer">
                      {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                      <input
                        type="checkbox"
                        value={tenantListing?.private_bathroom}
                        checked={tenantListing?.private_bathroom == 1}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setTenantListing({
                            ...tenantListing,
                            private_bathroom: isChecked ? 1 : 0,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="w-full">
                    <label className="input-label text-gray-700 font-bold">
                      LGBTQ+ Friendly:
                    </label>

                    <label class="inline-flex relative items-center cursor-pointer">
                      {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                      <input
                        type="checkbox"
                        value={tenantListing?.lgbtq_friendly}
                        checked={tenantListing?.lgbtq_friendly == 1}
                        class="sr-only peer"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setTenantListing({
                            ...tenantListing,
                            lgbtq_friendly: isChecked ? 1 : 0,
                          });
                        }}
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="w-full">
                    <label className="input-label text-gray-700 font-bold">
                      Pets OK:
                    </label>

                    <label class="inline-flex relative items-center cursor-pointer">
                      {/* <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                              Large toggle
                            </span> */}
                      <input
                        type="checkbox"
                        value={tenantListing?.pets_ok}
                        checked={tenantListing?.pets_ok == 1}
                        class="sr-only peer"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setTenantListing({
                            ...tenantListing,
                            pets_ok: isChecked ? 1 : 0,
                          });
                        }}
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full mt-3">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Desired Features :
                  </label>
                </div>
                <div className="md:flex flex-wrap md:items-center mt-3">
  {features?.map((feature, index) => (
    <div className="w-1/5 form-check" key={index}>
      <input
        className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
        id={`feature_type-${index}`}
        type="checkbox"
        value={tenantListing?.desired_features ? JSON?.parse(tenantListing?.desired_features).property_features : ""}
        checked={
          feature?.id &&
          tenantListing?.desired_features &&
          JSON.parse(tenantListing.desired_features).property_features.includes(
            feature.id
          )
        }
        onChange={(e) => {
          const featureId = feature.id;
          const isChecked = e.target.checked;

          let updatedFeatures = {
            ...JSON.parse(tenantListing.desired_features),
            property_features: [
              ...(JSON.parse(tenantListing.desired_features).property_features || [])
            ]
          };

          if (isChecked) {
            updatedFeatures.property_features.push(featureId);
          } else {
            updatedFeatures.property_features = updatedFeatures.property_features.filter(
              (id) => id !== featureId
            );
          }

          setTenantListing({
            ...tenantListing,
            desired_features: JSON.stringify(updatedFeatures)
          });
        }}
      />
      <div className="md:w-auto">
        <img
          src={`${imgUrl}/${feature.icon}`}
          width={"30px"}
          height={"auto"}
          className="object-cover rounded"
          alt={feature.feature}
        />
        <label className="input-label text-gray-700 mb-2">
          {feature.feature}
        </label>
      </div>
    </div>
  ))}
</div>

<div className="md:flex flex-wrap md:items-center mt-3">
  {unitFeatures?.map((feature, index) => (
    <div className="w-1/5 form-check" key={index}>
      <input
        className="form-check-input appearance-none h-4 w-4 bg-gray-300 border border-gray-200 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200  bg-center align-top mt-1 bg-no-repeat bg-contain cursor-pointer focus:outline-none float-left mr-2"
        id={`feature_type-${index}`}
        type="checkbox"
        value={ tenantListing?.desired_features ? JSON.parse(tenantListing?.desired_features).unit_features : ""}
        checked={
          feature?.id &&
          tenantListing?.desired_features &&
          JSON.parse(tenantListing.desired_features).unit_features.includes(
            feature.id
          )
        }
        onChange={(e) => {
          const featureId = feature.id;
          const isChecked = e.target.checked;

          let updatedFeatures = {
            ...JSON.parse(tenantListing.desired_features),
            unit_features: [
              ...(JSON.parse(tenantListing.desired_features).unit_features || [])
            ]
          };

          if (isChecked) {
            updatedFeatures.unit_features.push(featureId);
          } else {
            updatedFeatures.unit_features = updatedFeatures.unit_features.filter(
              (id) => id !== featureId
            );
          }

          setTenantListing({
            ...tenantListing,
            desired_features: JSON.stringify(updatedFeatures)
          });
        }}
      />
      <div className="md:w-auto">
        <img
          src={`${imgUrl}/${feature.icon}`}
          width={"30px"}
          height={"auto"}
          className="object-cover rounded"
          alt={feature.feature}
        />
        <label className="input-label text-gray-700 mb-2">
          {feature.feature}
        </label>
      </div>
    </div>
  ))}
</div>



                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      About me:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData2?.about_me}
                      onChange={(e) => {
                        setEditorData2({
                          ...editorData2,
                          about_me: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex mt-4">
                  <div className="md:w-1/3">
                    <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                      Pictures:
                    </label>
                  </div>
                  <div className="md:flex flex-column md:items-start w-[90%] m-auto">
                    <div className="flex md:items-center md:justify-start md:w-2/3">
                      <div className="md:w-1/2">
                        <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                          <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                            Upload pictures
                          </span>
                          <input
                            accept="image/*"
                            id="tenant-images"
                            type="file"
                            multiple={true}
                            hidden={true}
                            onChange={(e) => {
                              let files = e.target.files;
                              let newImages = [];
                              let maxOrderNumber = 0;

                              // Find the highest order number in the current images
                              TenantFormImagesWithUrl?.forEach(
                                (imageObject) => {
                                  if (
                                    imageObject.order_number > maxOrderNumber
                                  ) {
                                    maxOrderNumber = imageObject.order_number;
                                  }
                                }
                              );

                              // Calculate the total number of images (existing images + new images)
                              const totalImages =
                                TenantFormImagesWithUrl.length + files.length;

                              // Loop through each file and convert to base64
                              files.forEach((file, i) => {
                                const reader = new FileReader();
                                reader.addEventListener("load", async () => {
                                  const imgBase64 = reader.result;
                                  try {
                                    const res = await ApiRequest.post(
                                      imageApi,
                                      {
                                        image: imgBase64,
                                      }
                                    );

                                    // Add the new image to the array with a unique order number
                                    newImages.push({
                                      picture: res?.data?.data?.image,
                                      order_number:
                                        maxOrderNumber +
                                        i +
                                        1 +
                                        TenantFormImagesWithUrl.length,
                                      url: imgBase64,
                                      is_cover: i === 0 ? 1 : 0,
                                    });

                                    // If all new images have been processed, update the state
                                    if (newImages.length === files.length) {
                                      let pictureLists = [
                                        ...TenantFormImagesWithUrl,
                                      ];
                                      pictureLists = [
                                        ...pictureLists,
                                        ...newImages,
                                      ];
                                      setTenantFormImagesWithUrl(pictureLists);
                                      let tenantCopy = [
                                        ...TenantFormImagesWithUrl,
                                      ];
                                      tenantCopy.pictures = pictureLists.map(
                                        (picObj) => ({
                                          picture: picObj.picture,
                                          order_number: picObj.order_number,
                                          is_cover: picObj.is_cover,
                                        })
                                      );
                                      setFormData({
                                        ...formData,
                                        pictures: tenantCopy?.pictures,
                                      });
                                    }
                                  } catch (err) {
                                    // console.log(err);
                                  }
                                });
                                reader.readAsDataURL(file);
                              });
                            }}
                          />
                        </label>
                      </div>
                      <div className="md:w-1/2">
                        <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                          <span className="text-gray-600">
                            {(() => {
                              const imagesCount =
                                TenantFormImagesWithUrl?.length || 0;
                              return imagesCount > 1
                                ? imagesCount + " Pictures"
                                : imagesCount == 1
                                ? imagesCount + " Picture"
                                : null;
                            }).call()}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gallery  */}

                <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
                  {TenantFormImagesWithUrl?.length ? (
                    <div>
                      <section className="overflow-hidden w-full text-gray-700 mt-4">
                        <div className="container w-full px-3 m-auto">
                          <div
                            id="images-block"
                            className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                          >
                            {TenantFormImagesWithUrl.map((imageObject, index) =>
                              imageComponent({
                                image: imageObject?.picture,
                                key: index,
                                src: imgUrl + "/" + imageObject?.picture,
                                inputFieldId: "property-images",
                                trashAble: true,
                                onTrashClick: () => {
                                  let images = [...formData?.pictures];
                                  let image = [...TenantFormImagesWithUrl];
                                  images.splice(index, 1);
                                  image.splice(index, 1);
                                  setTenantFormImagesWithUrl(image);
                                  setFormData({
                                    ...formData,
                                    pictures: images,
                                  });
                                },
                              })
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="assets/img/tenants/system-emergency-information.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Emergency Information:
                </label>
              </div>
            </div>
            <ProfileEmergencyContacts
              data={tenantEmergencyContacts}
              handleEmergencyContactTrue={handleEmergencyContactTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/tenant-allergies.svg"
                      alt=""
                    />
                  </div>

                  <div className="md:w-1/5 flex">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Known Allergies:
                    </label>
                    <Tooltip
                      data={
                        "It can be beneficial to know certain allergies (like bee stings or nuts) that can have potential life-threatening implications. This information can help us to respond better in an emergency."
                      }
                    />
                  </div>
                  <div className="md:w-4/5 ml-2">
                    <CKEditor
                      initData={editorData?.known_allergies}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          known_allergies: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
                <hr />

                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="assets/img/tenants/system-emergency-information.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Other Emergency Info:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData2?.other_emergency_info}
                      onChange={(e) => {
                        setEditorData2({
                          ...editorData2,
                          other_emergency_info: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/tenants/system-notes-and-files.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Notes & Files:
                </label>
                <Tooltip
                  data={
                    "These are notes and files that have been shared with you."
                  }
                />
              </div>
            </div>
            <div className="flex items-center mt-4">
              <img
                className="w-12 mr-5"
                src="/assets/img/tenants/system-files.png"
                alt="System Files"
              />
              <div className="w-1/3">
                <h2 className="text-2xl font-bold text-gray-700">Files:</h2>
              </div>
              <div className="w-full">
                <ul className="flex flex-wrap justify-start items-center">
                  {propertyPublicDocumentsWithUrl?.map((row) => (
                    <li key={row.id} className="mr-4 mb-4">
                      <a href={`${imgUrl}/${row.file}`} download={row.file}>
                        <span className="sr-only">Download </span>
                        {row.file} <i className="fa fa-download"></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Gallery  */}

            {/* <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
            {propertyPublicDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, index) =>
                        fileComponent({
                          file: fileObject?.file,
                          key: index,
                          inputFieldId: "property-public-files",
                          trashAble: true,
                          onTrashClick: () => {
                            let files = [...propertyPublicDocumentsWithUrl];
                            let file = [...formData.user_files];
                            files.splice(index, 1);
                            file.splice(index, 1);
                            setPropertyPublicDocumentsWithUrl(files);
                            setFormData(file);
                          },
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="w-full">
                <div className="md:flex mt-3">
                  <div className="md:w-1/8">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/tenants/system-notes-red.png"
                      alt=""
                    />
                  </div>
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.user_notes}
                      config={{
                        readOnly: true,
                      }}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          user_notes: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <ProfileEmployments
              data={tenantEmployment}
              handleEmploymentTrue={handleEmploymentTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <ProfileReferences
              data={tenantReference}
              handleReferenceTrue={handleReferenceTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <ProfileChildren
              data={tenantChildren}
              handleChildrenTrue={handleChildrenTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <ProfilePets
              data={tenantPet}
              handlePetTrue={handlePetTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={8}>
            <ProfileOtherLivings
              data={tenantOtherLiving}
              handleOtherLivingTrue={handleOtherLivingTrue}
              hiddenData={false}
              ownerSettings={ownerSettings}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={9}>
            <ProfileVehicles
              data={tenantVehicle}
              handleVehicleTrue={handleVehicleTrue}
              hiddenData={false}
            />
            <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
              <button
                id={"form-submit-button"}
                type={"submit"}
                hidden={false}
                className="btn2 flex justify-between items-center gap-4"
              >
                Save Information
              </button>
            </div>
          </TabPanel>
        </form>
      )}
    </>
  );
};
