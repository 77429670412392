import {createSlice} from "@reduxjs/toolkit";
import { materialType, materialBrand, materialIdentifier } from "./materialmeasurementsThunk";
const materialmeasurementSlice = createSlice({
    name: 'materialmeasurement',
    initialState: {
      status: null,
      materialType:{},
      materialBrand:{},
      materialIdentifier:{},
    },
    extraReducers: {
      [materialType.pending]: (state) => {
        state.status = 'loading';
      },
      [materialType.fulfilled]: (state, {payload}) => {
        state.status = 'success';
   state.materialType=payload
      },
      [materialType.rejected]: (state, _error) => {
        state.status = 'failed';
        state.materialType={}
      },
            
      [materialBrand.pending]: (state) => {
        state.status = 'loading';
      },
      [materialBrand.fulfilled]: (state, {payload}) => {
        console.warn("payload",payload);
        state.status = 'success';
   state.materialBrand=payload  
      },
      [materialBrand.rejected]: (state, _error) => {
        state.status = 'failed';
        state.materialBrand={}
      },
      [materialIdentifier.pending]: (state) => {
        state.status = 'loading';
      },
      [materialIdentifier.fulfilled]: (state, {payload}) => {
        state.status = 'success';
   state.materialIdentifier=payload
      },
      [materialIdentifier.rejected]: (state, _error) => {
        state.status = 'failed';
        state.materialIdentifier={}
      }
    }
  });
  
  export default materialmeasurementSlice.reducer;