import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";



// import { AddApplicationForm } from "../../components/application/AddApplicationForm";
import { Footer } from "../../components/Dashboard/Footer";
import { OwnerHeader } from "../OwnerHeader";
import { SideNavBar } from "../../components/SideNavBar";
import useAlerts from "../../utility/alerts";
import { convertToFormData } from "../../utility/helperFunctions";
import ApiRequest from "../../utility/http";
import { KnowledgebaseForm } from "../../components/knowledgebase/KnowledgebaseForm";

export const KnowledgeBasePage = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();

  // CONSTANTS

  const initialData = {
    
      property_id: 81,
      unit_id: 189,
      is_public: 0,
      previous_housings_years: 2,
      employers_years: 2,
      minimum_references: 0,
      additional_information: ""
  
  }

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [formData, setFormData] = useState({});
  // console.log("formData",formData)
  const { alertList } = useSelector((state) => state.alerts);

  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.remove("access_token");
    localStorage.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  // const updateFormData = async (data = {}) => {
  //   if (JSON.stringify(formData) !== JSON.stringify(data)) {
  //     const newData = { ...formData, ...data };
  //     setFormData(newData);

  //     const apiBody = convertToFormData(newData);
  //     const response = await ApiRequest.post(
  //       "public/api/auth/property",
  //       apiBody
  //     );
  //     if (response == 200) {
  //       debugger;
  //     } else {
  //       debugger;
  //     }
  //   }
  // };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  useEffect(() => {
    setFormData(initialData);
  }, []);

  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);

  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
  setCollapsed(message)
  };
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Knowledgebase" onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>

          <div className="px-5">
            <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/knowledgebase.svg"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                Knowledgebase
              </h2>
            </div>
            <KnowledgebaseForm/>
            {/* <LeaseRentForm/> */}
            {/* <AddAnApplicationForm
              initialData={
                Object.keys(formData).length ? formData : initialData
              }
              verticalScroll={verticalScroll}
              // updateFormData={updateFormData}
            /> */}
          </div>
          <div className="bottom-gap" />
        </div>

        {/* <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Reserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer> */}
        {showFooter?<Footer/>:null}
      </div>
    </div>
  );
};
