import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";

// import Loading from "../Loader/Loading";
// import Loading from "../Loader/Loading";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
const TestingTable = ({userRoles, status}) => {
    console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));

  const columns = [
    {
      name: "Message ID",
      selector : (data) => data.id
      
    },
    {
      name: "Sent",
      selector : (data) => data.sent
    },
    {
      name: "Sender",
      selector : (data) => data.sender
    },
    {
      name: "Recipient",
      selector : (data) => data.recipient
    },
    {
      name: "Subject",
      selector : (data) => data.subject
    },
    {
      name: "Viewed",
      selector : (data) => data.viewed
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Options",
      cell: (row) => {
        return (
          <>
               <Link to={`/edit-property/${row.id}`} style={{textDecoration:"none"}}>
            <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
            </Link>
            <Link to={`/show-property/${row.id}`} style={{textDecoration:"none"}}>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
                <span>Delete</span>
              </button>
            </Link>
          </>
        );
      },
    },
  ];

  const data =[
    {
        id:"355821647853265",
        sent:"5/24/2022 TIME",
        sender:"Sapp, Sean (UNIT A)",
        recipient:"SYSTEM",
        subject:"Question on Rent Due Date",
        viewed:"5/24/2022 TIME",
      },
    {
        id:"745852146932145",
        sent:"5/24/2022 TIME",
        sender:"SYSTEM",
        recipient:"Sapp, Sean (UNIT A)",
        subject:"Information requested about the pool ",
        viewed:"Not Viewed",
      },
    {
        id:"985632452185635",
        sent:"5/24/2022 TIME",
        sender:"Knabenshue, Chris(OWNER)",
        recipient:"SYSTEM",
        subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
        viewed:"Not Viewed",
      },

  ];
  

 

  return (
    <>
     
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
        />

    </>
  );
};

export default TestingTable;
