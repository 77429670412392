import React from "react";
import "./style.css";
const Loading = () => {
  return (
    <>
    
    <div class="pre-loader">
    <div class="logo">
        <div class="logo-image">
            <img src="/assets/img/logo.png" alt=""/>
        </div>
        {/* <div class="logo-text-image">
            <img src="/assets/img/logo-text-light.png" alt=""/>
        </div> */}
        {/* <div class="logo-text-image">
            <span>Loading ...</span>
        </div> */}
    </div>
    </div>
    </>
  );
};

export default Loading;
