import { useNavigate, Navigate } from "react-router-dom";
import { checkAuthentication } from "../utility/auth";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    return <Component navigate={navigate}{...props}/>;
  };
  return Wrapper;
};
export const PrivateRoute = ({ children }) => {
  console.log("children",children)
  return checkAuthentication() ? children : <Navigate to={"/login"} />;
};

export const PublicRoute = ({ children }) => {
  return children;
};
