import React from "react";
import { CKEditor } from "ckeditor4-react";
import { DatePicker } from "../genericFunctions.js/DatePicker";

export const FinancePurchaseOrderForm = () => {
  return (
    <>
      <form>
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
          <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/2 mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Status:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Date:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <DatePicker
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Title<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/2 mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Vendor<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center w-full mt-3">
              <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Amount Authorized<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-1/2 mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Budget:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`lease-date-to-send`}
                    // value={propertyMaterial?.material_type_id}
                    // onChange={(e) => {
                    //   const value = parseInt(e.target.value);
                    //   setPropertyMaterial({
                    //     ...propertyMaterial,
                    //     material_type_id: value,
                    //   });
                    //   getPropertyAllMaterials(e.target.value);
                    // }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Notes:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.dln_id_issue_notes}
                // onChange={(e) => {
                //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                //   setEditorData({
                //     ...editorData,
                //     dln_id_issue_notes: cleanText,
                //   });
                // }}
                />
              </div>
            </div>
            <div class="flex justify-end items-center mt-3">
              <div class="flex justify-between items-center gap-4">
                <button type="button" class="btn btn3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
