import React, { useState, useEffect } from 'react';
import ApiRequest from '../../utility/http';

const useAllowedPicMimeTypes = () => {
  const [allowedMimeTypes, setAllowedMimeTypes] = useState('');

  const mimeTypes = {
    "jpeg": "image/jpeg",
    "eps": "application/postscript",
    "gif": "image/gif",
    "heic": "image/heic",
    "jpg": "image/jpeg",
    "png": "image/png",
    "raw": "application/octet-stream",
    "svg": "image/svg+xml",
    "tiff": "image/tiff"
  };

  useEffect(() => {
    const fetchFileTypes = async () => {
      try {
        const response = await ApiRequest.get('public/api/auth/get-allowed-pictures-types');
        if (response.status === 200) {
          const allowedTypes = response.data?.data?.allowed_pictures_types || [];
          const allowedMimeTypes = allowedTypes.map(fileType => mimeTypes[fileType.type]).join(',');
          setAllowedMimeTypes(allowedMimeTypes);
        }
      } catch (error) {
        console.error('Error fetching file types:', error);
      }
    };

    fetchFileTypes();
  }, []);

  return allowedMimeTypes;
};

export default useAllowedPicMimeTypes;
