import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { Tab, Tabs } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

export const KnowledgebaseForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const [expanded, setExpanded] = useState(null);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lead-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Frequently Asked Questions (FAQs)" {...a11yProps(1)} />
          <Tab label="User Guides" {...a11yProps(2)} />
          <Tab label="Help Forum" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className=" flex-col w-[90%] m-auto mt-5">
            <div className="mt-5">
              <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-base font-semibold ">
                {"{"}Welcome_Message{"}"}
              </label>
              <section className="service-area mt-2">
            <div className="row">
              {/* single service  */}
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="single-service bg-green-600 border border-green-700">
                  <div className="service-content">
                    <img
                      src="/assets/svgs/knowledgebase-faq.svg"
                      alt=""
                      className="service-icon w-1/12"
                    />
                    <h3>Frequently asked question(FAQ)</h3>
                    <h5>{"{"}FAQ_Description{"}"}</h5>
                  </div>
                  <div className="service-bottom">
                    <a href="#" className="learn-more">
                      Learn More <img src="/assets/img/long-arrow.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              {/* single service  */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                <div className="single-service service-style-2">
                  <div className="service-content">
                    <img
                      src="/assets/svgs/knowledgebase-guides.svg"
                      alt=""
                      className="service-icon w-1/6"
                    />
                    <h3>User Guides</h3>
                    <h5>{"{"}User_Guides_Description{"}"}</h5>
                  </div>
                  <div className="service-bottom">
                    <a href="#" className="learn-more">
                      Learn More <img src="/assets/img/long-arrow.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              {/* single service  */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                <div className="single-service service-style-3">
                  <div className="service-content">
                    <img
                      src="/assets/svgs/knowledgebase-help-forum.svg"
                      alt=""
                      className="service-icon w-1/6"
                    />
                    <h3>Help Forum</h3>
                    <h5>{"{"}Help_Forum_Description{"}"}</h5>
                  </div>
                  <div className="service-bottom">
                    <a href="#" className="learn-more">
                      Learn More <img src="/assets/img/long-arrow.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
              {/* <div className=" flex mt-5 justify-center items-center p-5 w-full rounded-md bg-gray-300">
                <div className="w-1/4  flex justify-end">
                  <img
                    src="/assets/svgs/knowledgebase-faq.svg"
                    className="w-1/5 justify-items-end"
                    alt=""
                  />
                </div>
                
                <div className="flex-col ml-3 justify-start w-1/2">
                  <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-lg font-bold ">
                    Frequently asked question(FAQ)
                  </label>
                  <p className="block w-1/2 uppercase tracking-wide text-gray-700 text-base font-semibold ">
                    {"{"}FAQ_description{"}"}
                  </p>
                </div>
              </div> */}
              {/* <div className="flex items-center gap-5">
                <div className=" flex mt-5 justify-center p-5 w-1/2 h-56 rounded-md bg-gray-300">
                  <div className="w-1/4 ">
                    <img
                      src="/assets/svgs/knowledgebase-guides.svg"
                      className="w-1/2 flex float-right"
                      alt=""
                    />
                  </div>
                  <div className="flex-col ml-3 justify-start w-1/2">
                    <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-lg font-bold ">
                      user guides
                    </label>
                    <p className="block w-1/2 uppercase tracking-wide text-gray-700 text-base font-semibold ">
                      {"{"}user_guides_description{"}"}
                    </p>
                  </div>
                </div>
                <div className=" flex mt-5 justify-center p-5 w-1/2 h-56 rounded-md bg-gray-300">
                  <div className="w-1/4 ">
                    <img
                      src="/assets/svgs/knowledgebase-help-forum.svg"
                      className="w-1/2 flex float-right"
                      alt=""
                    />
                  </div>
                  <div className="flex-col ml-3 justify-start w-1/2">
                    <label className="block w-1/2 capitalize tracking-wide text-gray-700 text-lg font-bold ">
                      help forum
                    </label>
                    <p className="block w-1/2 uppercase tracking-wide text-gray-700 text-base font-semibold ">
                      {"{"}help_forum_description{"}"}
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="w-[90%] flex-col m-auto mt-5">
            <div className="flex">
              <div className="flex w-full">
                <img src="/assets/svgs/knowledgebase-faq.png" className="w-1/6" alt="" />
                <label className="block ml-5 w-1/2 tracking-wide text-gray-700 text-xl font-bold">
                  Frequently Asked Questions (FAQs)
                </label>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
              <div className="flex-col border-2 border-black rounded-3xl w-1/3">
                <div className="flex items-center">
                  <img src="/assets/svgs/property.svg" className="w-1/6 m-2" alt="" />
                  <label className="ml-4 tracking-wide uppercase text-lg text-gray-700 font-bold">
                    Topic
                  </label>
                </div>
                <div className="flex flex-col w-full uppercase text-lg font-semibold tracking-wide text-gray-700 ">
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                  <label className="border-2 w-full p-2 bg-gray-100 rounded-lg text-center">
                    faq questions
                  </label>
                </div>
                <div className="flex justify-center m-3">
                  <select className="w-1/2 p-1 rounded-lg text-center focus: outline-none text-lg font-bold tracking-wide text-gray-700 ">
                    <option>View All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="flex flex-col w-[90%] m-auto mt-5">
            <div className="flex justify-start">
              <div className="flex w-1/3 items-center">
                <img
                  src="/assets/svgs/knowledgebase-guides.svg"
                  className="w-1/5"
                  alt=""
                />
                <div className="flex flex-col">
                  <label className="block ml-5 w-1/2 capitalize tracking-wide text-gray-700 text-xl font-bold">
                    User guides
                  </label>
                  <label className="block ml-5 w-1/2 uppercase tracking-wide text-gray-700 text-base font-semibold">
                    {"{"}user_guides_description{"}"}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
        <div className="flex flex-col w-1/3">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeAccordion("panel1")}
          >
            <AccordionSummary
              expandIcon={<i className="fa fa-angle-down"></i>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="flex items-center w-full border-4 bg-gray-200 border-gray-400 p-2"
            >
              <div className="flex items-center w-full p-2">
                <img
                  src="/assets/svgs/property.svg"
                  alt="Accordion 1"
                  className="w-1/6"
                />
                <label className="block ml-5 w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">category</label>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChangeAccordion("panel2")}
          >
            <AccordionSummary
              expandIcon={<i className="fa fa-angle-down"></i>}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className="flex items-center w-full border-4 bg-gray-200 border-gray-400 p-2"
           
            >
           <div className="flex items-center w-full p-2">
                <img
                  src="/assets/svgs/finance.svg"
                  alt="finance"
                  className="w-1/6"
                />
                <label className="block ml-5 w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">category</label>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChangeAccordion("panel3")}
          >
            <AccordionSummary
              expandIcon={<i className="fa fa-angle-down"></i>}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="flex items-center w-full border-4 bg-gray-200 border-gray-400 p-2"
              
            >
              <div className="flex items-center w-full p-2">
                <img
                  src='/assets/svgs/calendar.svg'
                  alt="finance"
                  className="w-1/6"
                />
                <label className="block ml-5 w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">category</label>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChangeAccordion("panel4")}
          >
            <AccordionSummary
              expandIcon={<i className="fa fa-angle-down"></i>}
              aria-controls="panel4a-content"
              id="panel4a-header"
              className="flex items-center w-full border-4 bg-gray-200 border-gray-400 p-2"
            >
              <div className="flex items-center w-full p-2">
                <img
                  src='/assets/svgs/lease.svg' 
                  alt="finance"
                  className="w-1/6"
                />
                <label className="block ml-5 w-1/2 tracking-wide text-gray-700 text-xl uppercase font-bold ">category</label>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
                <label className="block w-full p-2 tracking-wide text-gray-700 text-lg uppercase text-center font-bold border-2 w-full">
                  Guide
                </label>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
        <div className="flex flex-col w-[90%] m-auto mt-5">
            <div className="flex justify-start">
              <div className="flex w-1/3 items-center">
                <img
                  src="/assets/svgs/knowledgebase-help-forum.svg"
                  className="w-1/5"
                  alt=""
                />
                <div className="flex flex-col">
                  <label className="block ml-5 w-1/2 capitalize tracking-wide text-gray-700 text-xl font-bold">
                    Help Forum
                  </label>
                  <label className="block ml-5 w-1/2 uppercase tracking-wide text-gray-700 text-base font-semibold">
                    {"{"}Help_forum_description{"}"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
