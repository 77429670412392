import { createAsyncThunk } from "@reduxjs/toolkit";

import ApiRequest from "../../utility/http";
export const getUnitMeasurement = createAsyncThunk("auth/measurementUnits", async () => {
  try {
  
    const response = await ApiRequest.get("/public/api/auth/get-measurements-units");
    return response?.data;
  } catch (error) { 
    console.log("get-measurements-units api has error");
  }
});
export const getUnitMeasurementTypes = createAsyncThunk("auth/measurementTypes", async () => {
    try {
    
      const response = await ApiRequest.get("/public/api/auth/get-measurement-types");
      
      return response?.data;
    } catch (error) { 
      console.log("get-measurement-types api has error");
    }
  });
