import React, { useState, useRef, useEffect, useCallback } from "react";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree";
import "./treestyle.css";
import ApiRequest from "../utility/http";
// import SimpleLightbox from 'simplelightbox';
// import 'simplelightbox/dist/simple-lightbox.min.css';
import $ from "jquery";
import Loading from "../components/Loader/Loading";
import Spinner from "../components/Spinner/Spinner";
import { useDropzone } from "react-dropzone";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import { ProgressBar } from "react-loader-spinner";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import imgUrl from "../components/ImageUrl";
import { ContextMenu } from "./ContextMenu";

function FrontTree({
  folderData,
  setPictureTemplateID,
  handleFiles,
  getPictureTemplateID,
}) {
  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  // const [seed,setSeed] = useState();
  const [treeData, setTreeData] = useState();
  const [rowDataInfo,setRowDataInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [createNodeModal, setCreateNodeModal] = useState(false);
  const [updateNodeModal, setUpdateNodeModal] = useState(false);
  const [childNodeModal, setChildNodeModal] = useState(false);
  const [siblingNodeModal, setSiblingNodeModal] = useState(false);
  const [activeNode, setActiveNode] = useState(null);
  const [loader, setLoader] = useState();
  const [spinner, setSpinner] = useState();
  const [getID, setGetID] = useState();
  const [templateID, setTemplateID] = useState();
  const [icon, setIcon] = useState();
  const [image, setImage] = useState(null);
  const [without, setWithout] = useState(false);
  const [progress, setProgress] = useState(false);
  console.log("PROGRESS", treeData);
  let imageApi = "/public/api/auth/upload-file";

  const fileTypes = ["PDF", "DOCX", "CSV", "DOC", "XLS"];

  // console.log('treeData',treeData);
  // console.log('GET ID',getID);
  // console.log('Template ID',templateID);
  // console.log('spinner',spinner);

  const [files, setFiles] = useState([]);
  const [withOutFiles, setWithOutFiles] = useState([]);
  // const [filesName, setFilesName] = useState([]);
  console.log("All files", files);
  // console.log('files',files[0]?.name)
  // const handleChange = (files) => {
  //   setFiles(files);
  // };

  const handleDrop = (droppedFiles) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      const newOrderNumber =
        prevFiles.length > 0
          ? prevFiles[prevFiles.length - 1].order_number + 1
          : 1;
      let orderNumber = newOrderNumber;
      let pictureFolderId = null;
      let currentFileIndex = 0;
      const handleNextFile = () => {
        setProgress(false);
        if (currentFileIndex < droppedFiles.length) {
          setProgress(true);
          const file = droppedFiles[currentFileIndex];
          setProgress(true);
          const reader = new FileReader();
          setProgress(true);
          reader.addEventListener("load", async () => {
            setProgress(true);
            const imgBase64 = reader.result;
            try {
              const res = await ApiRequest.post(imageApi, { file: imgBase64 });
              setProgress(true);
              console.warn("resOFPICTURE", res);
              // const { value: formValues } = await Swal.fire({
              //   title: 'Add Title and Caption',
              //   html: `
              //     <input id="swal-input1" class="swal2-input" placeholder="Title" value=${file?.name} autofocus>
              //     <textarea id="swal-input2" class="swal2-textarea" placeholder="Caption">${file?.name}</textarea>
              //   `,
              //   focusConfirm: false,
              //   preConfirm: () => {
              //     return [
              //       document.getElementById('swal-input1').value,
              //       document.getElementById('swal-input2').value,
              //     ];
              //   },
              // });
              // const title = formValues[0] || file?.name;
              // const caption = formValues[1] || file?.name ;
              const newFile = {
                file: res?.data?.data?.file,
                order_number: orderNumber,
                title: file?.name,

                file_folder_id: parseInt(nodeData2?.data?.id),
              };
              newFiles.push(newFile);
              pictureFolderId = pictureFolderId || newFile.file_folder_id;
              if (pictureFolderId !== newFile.file_folder_id) {
                pictureFolderId = newFile.file_folder_id;
                orderNumber = newOrderNumber;
              } else {
                orderNumber++;
              }
              newFiles.sort((a, b) => a.order_number - b.order_number);
              newFiles.forEach((file, index) => {
                file.order_number = index + 1;
              });
              setFiles(newFiles);
              handleFiles(newFiles);
              currentFileIndex++;
              handleNextFile();
            } catch (err) {
              console.log(err);
              setProgress(true);
            }
          });
          reader.readAsDataURL(file);
        }
      };
      handleNextFile();
    });
  };

  const handleDelete = (index) => {
    console.warn(true);
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      handleFiles(newFiles);
      return newFiles;
    });
  };
  const handleEdit = (index) => {
    const file = files[index];
    Swal.fire({
      title: "Edit File",
      html: `
        <input id="swal-input1" class="swal2-input" placeholder="Title" value="${file.title}">
      `,
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value];
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const [title] = result.value;
        const newFiles = [...files];
        newFiles[index] = {
          ...file,
          title: title,
        };
        setFiles(newFiles);
        handleFiles(newFiles);
      }
    });
  };

  // const onDrop = useCallback((acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setImage({
  //       name: file.name,
  //       dataUrl: reader.result
  //     });
  //   };
  //   reader.readAsDataURL(file);
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

  function handleNodeClick(event) {
    // $('.rst__rowLabel').removeClass('active');
    // var parentElement = $(event.currentTarget).closest('.rst__rowLabel').addClass('active');
    var infoName = $(".info-name").text();
    // $('.rst__rowLabel').each(function() {
    //   var rstRowTitle = $(this).find('.rst__rowTitle').text();

    //   if (rstRowTitle === infoName) {
    //     $(this).addClass('active');
    //   } else {
    //     $(this).removeClass('active');
    //   }
    // });
  }

  const fileComponent = ({
    file = null,
    title = null,
    key = 0,
    inputFieldId = "",
    trashAble = false,
    editAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
  }) =>
    file ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
      >
        <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
          <div title={title} key={key} className="block min-w-[600rem] px-2">
            <a href={`${imgUrl}/${file}`} target="_blank">
              {title}
            </a>
          </div>
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
          >
            {editAble ? (
              <img
                title="Edit file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/system-edit.png"
                onClick={(e) => {
                  onEditClick();
                }}
                alt=""
              />
            ) : null}
            {trashAble ? (
              <img
                title="Remove file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((input_file) => {
                      if (input_file !== file) {
                        dataTransfer.items.add(input_file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  function addImage(array) {
    // console.log("DATATATAT",array);
    $(`.rst__rowLabel`).addClass("flex");
    for (let i = 0; i < array?.length; i++) {
      // const index = $(`.rst__rowLabel:eq(${i})`);
      if (array[i].picture == "") {
        $(`.rst__rowLabel:eq(${i})`).prepend(
          `<img src="https://apis.rentblaze.com/public/uploads/640065553a002.png" style="width:25px"/>`
        );
      } else {
        $(`.rst__rowLabel:eq(${i})`).prepend(
          `<img src="https://apis.rentblaze.com/public/uploads/${array[i]?.picture}" style="width:25px"/>`
        );
      }
    }
    // treeData?.map((row)=>{
    //   $(`.rst__rowLabel`).prepend(`<img src="https://apis.rentblaze.com/public/uploads/${row?.picture}" style="width:25px" />`);
    // })
  }
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const [rightClickedFileIndex, setRightClickedFileIndex] = useState(null);

  const getFileTypeImage = (fileName) => {
    const fileExtension = fileName?.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return <img src="/assets/img/properties/system-download-pdf.png" className="w-8 h-8 mx-1"  alt="PDF" />;
      case 'csv':
        return <img src="/assets/img/properties/system-download-csv.png" className="w-8 h-8 mx-1"  alt="CSV" />;
      // Add more cases for other file types if needed
      default:
        return null;
    }
  };
  const handleRightClick = (e, i) => {
    e.preventDefault();
    setRightClickedFileIndex(i);
    setContextMenuVisible(true);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    // Additional logic related to the right-clicked file if needed
  };

  const handleContextMenuClose = () => {
    setContextMenuVisible(false);
    setRightClickedFileIndex(null);
  };
  const handleFileDownload = (i) => {
    const link = document.createElement('a');
    link.href = `https://apis.rentblaze.com/public/uploads/${files[i]?.file}`;
    link.target = '_blank';
    link.download = true; // Set the download attribute to trigger the download
    link.click();
  };
  

  const handleFileView = () => {
    // Handle view logic
  };
  function handleCloseModal() {
    setShowModal(false);
  }

  function handleShowModal(rowInfo) {
    setShowModal(true);
    setRowDataInfo(rowInfo)
  }
  function handleCreateCloseModal() {
    setCreateNodeModal(false);
  }

  function handleCreateShowModal() {
    setCreateNodeModal(true);
  }
  function handleUpdateCloseModal() {
    setUpdateNodeModal(false);
  }

  function handleUpdateShowModal(rowInfo) {
    setUpdateNodeModal(true);
    setRowDataInfo(rowInfo);
  }
  function handleChildCloseModal() {
    setChildNodeModal(false);
  }

  function handleChildShowModal(rowInfo) {
    setChildNodeModal(true);
    setRowDataInfo(rowInfo)
  }
  function handleSiblingCloseModal() {
    setSiblingNodeModal(false);
  }

  function handleSiblingShowModal(rowInfo) {
    setSiblingNodeModal(true);
    setRowDataInfo(rowInfo)
  }

  const getTemplate = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-my-files-templates"
    );
    setLoader(response.status);
    if (response.status == 200) {
      setGetID(response?.data?.data?.templates);
    }
  };

  const singleData = async (id) => {
    const Id = id != undefined ? id : setPictureTemplateID;
    setSpinner();
    const res = await ApiRequest.get(
      `/public/api/auth/get-file-template?template_id=${Id}`
    );
    if ((res.status = 200)) {
      setTreeData(res?.data?.data?.template?.folders);
      setSpinner(true);
      addImage(res?.data?.data?.template?.folders);
      setSearchString("");
      setNodeData2("");
    }
    // const data =  getID?.map((row)=>{
    //   if(row?.id == Id) {
    //     setTreeData(row?.folders)
    //   }
    // });
  };

  useEffect(() => {
    getTemplate();
    setWithout(
      folderData?.filter((obj) => obj.file_folder_id === null)?.length > 0
    );
    singleData();
    setTemplateID(setPictureTemplateID);
    setIcon("640065553a002.png");
    $(".rst__moveHandle").removeAttr("draggable");
    setFiles(folderData != undefined ? folderData : []);
  }, []);

  const inputEl = useRef();

  const addNodeData = async (value, parent_id) => {
    const response = await ApiRequest.post(
      "/public/api/auth/file-template-folder",
      {
        name: value,
        order_number: 1,
        parent_id:
          parent_id == "null" || parent_id == "undefined" ? "" : parent_id,
        file_template_id: templateID,
        picture: icon,
      }
    );
    if (response.status == 200) {
      getTemplate();
      singleData(templateID);
    }
  };
  const addChildNodeData = async (value, id) => {
    const response = await ApiRequest.post(
      "/public/api/auth/file-template-folder",
      {
        name: value,
        order_number: 1,
        parent_id: parseInt(id),
        file_template_id: templateID,
        picture: icon,
      }
    );
    if (response.status == 200) {
      getTemplate();
      singleData(templateID);
    }
  };
  const UpdateNodeData = async (value, id, parent_id, order_number) => {
    const response = await ApiRequest.post(
      "/public/api/auth/file-template-folder",
      {
        id: parseInt(id),
        name: value,
        order_number: order_number,
        parent_id: parent_id == "null" ? "" : parseInt(parent_id),
        file_template_id: templateID,
        picture: icon,
      }
    );
    if (response.status == 200) {
      getTemplate();
      singleData(templateID);
    }
  };
  const createNode = () => {
    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: null,
      expandParent: true,
      getNodeKey,
      newNode: addNodeData(value, nodeData?.data?.parent_id),
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
  };

  function updateNode(rowInfo) {
    const { node, path } = rowInfo;
    const { children } = node;

    const value = inputEl.current.value;

    if (value === "") {
      inputEl.current.focus();
      return;
    }

    let newTree = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: UpdateNodeData(
        value,
        nodeData?.data?.id,
        nodeData?.data?.parent_id,
        nodeData?.data?.order_number
      ),
    });

    setTreeData(newTree);

    inputEl.current.value = "";
  }

  const addNodeChild = (rowInfo) => {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: addChildNodeData(value, nodeData?.data?.id).then((response) => {
        console.log(response);
      }),
    });

    // console.log('newTree',newTree);

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  };

  function addNodeSibling(rowInfo) {
    let { path } = rowInfo;

    const value = inputEl.current.value;
    // const value = inputEls.current[treeIndex].current.value;

    if (value === "") {
      inputEl.current.focus();
      // inputEls.current[treeIndex].current.focus();
      return;
    }

    let newTree = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 2],
      expandParent: true,
      getNodeKey,
      newNode: addNodeData(value, nodeData?.data?.parent_id),
    });

    setTreeData(newTree.treeData);

    inputEl.current.value = "";
    // inputEls.current[treeIndex].current.value = "";
  }
  const deleteFolder = async (id) => {
    const response = await ApiRequest.delete(
      `/public/api/auth/delete-file-template-folder?file_folder_id=${id}`
    );
    if (response.status == 200) {
      getTemplate();
      singleData(templateID);
    }
  };
  function removeNode(rowInfo) {
    const { path } = rowInfo;
    deleteFolder(nodeData?.data?.id);
    // setTreeData(
    //   removeNodeAtPath({
    //     treeData,
    //     path,
    //     getNodeKey
    //   })
    // );
  }

  function updateTreeData(treeData) {
    let order_number = 1;
    const updateOrder = (node) => {
      node.order_number = order_number++;
      if (node.children) {
        node.children.forEach(updateOrder);
      }
    };
    treeData.forEach(updateOrder);
    setTreeData(treeData);
  }
  const onMoveNode = (node) => {
    const newParentId = node.parentNode ? node.parentNode.id : null;
    console.log("Parent ID", newParentId);
    const updatedTreeData = treeData.map((parent) => {
      if (parent.id === node.node.id) {
        return { ...node.node, parent_id: newParentId };
      }
      return parent;
    });
    setTreeData(updatedTreeData);
  };
  function expand(expanded) {
    setTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }
  const [nodeData, setNodeData] = useState({
    data: [],
  });

  // console.log('NODEDATA',nodeData);
  const [nodeData2, setNodeData2] = useState({
    data: [],
  });
  const [index, setIndex] = useState();
  // console.warn('index',index);
  const [child, setChild] = useState();
  // console.warn('child',child);
  // console.warn('NodeData2',nodeData2);
  const alertNodeInfo = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
      .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
      .join(",");

    // const obj = JSON.parse("{"+objectString.replace(/'/g, '"') + "}")
    const newStr = objectString.replace(/'/g, '"');
    const obj = {};
    newStr.split(",").forEach((pair) => {
      const [key, value] = pair.split(":");
      obj[key.trim()] = value.replace(/"/g, "").trim();
    });

    setNodeData({ ...nodeData, data: obj });

    // global.alert(
    //   "Info passed to the icon and button generators:\n\n" +
    //     `node: {\n   ${objectString}\n},\n` +
    //     `path: [${path.join(", ")}],\n` +
    //     `treeIndex: ${treeIndex}`
    // );
  };
  const alertNodeInfo1 = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
      .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
      .join(",");

    // const obj = JSON.parse("{"+objectString.replace(/'/g, '"') + "}")
    // global.alert(
    //   "Info passed to the icon and button generators:\n\n" +
    //     `node: {\n   ${objectString}\n},\n` +
    //     `path: [${path.join(", ")}],\n` +
    //     `treeIndex: ${treeIndex}`
    // );
    const newStr = objectString.replace(/'/g, '"');
    const obj = {};
    newStr.split(",").forEach((pair) => {
      const [key, value] = pair.split(":");
      obj[key.trim()] = value.replace(/"/g, "").trim();
    });

    console.log("OBJECT", obj);
    setNodeData2({ ...nodeData2, data: obj });
    setIndex(treeIndex);
  };

  const getChild = () => {
    treeData.map((tree) => {
      if (tree?.id == nodeData2?.data?.id) {
        setChild(tree);
      }
      tree?.children.map((child) => {
        if (child?.id == nodeData2?.data?.id) {
          setChild(child);
        }
      });
    });
    // console.log('true');
  };

  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;
  // useEffect(() => {
  //   // Initialize the lightbox
  //   const lightbox = new SimpleLightbox('.gallery a',{
  //     nav: false,
  //     close:true,
  //     disableNavigation: true,
  //     captions: false,
  //     download: false
  //   });
  //   // return () => {
  //   //   // Clean up SimpleLightbox on unmount
  //   //   lightbox.destroy();
  //   // };
  // }, []);
  // useEffect(() => {
  //   Fancybox.defaults.buttons = [
  //     "zoom",
  //     "share",
  //     "slideShow",
  //     "fullScreen",
  //     "download",
  //     "thumbs",
  //     "close"
  //   ];

  //   Fancybox.defaults.thumbs.autoStart = true;

  //   Fancybox.defaults.clickContent = function(current, event) {
  //     return current.type === "image" ? "zoom" : false;
  //   };

  //   Fancybox.defaults.wheel = "auto";

  //   Fancybox.defaults.zoom = {
  //     maxRatio: 5
  //   };

  //   Fancybox.bind("[data-fancybox]", {
  //     // Set your options here
  //   });

  //   return () => {
  //     Fancybox.destroy();
  //   }
  // }, []);
  //   useEffect(() => {
  //     const options = {
  //         // fancybox options go here
  //     };
  //     Fancybox.bind(`[data-fancybox]`, options);
  // }, []);
  const [showLightbox, setShowLightbox] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const AddFolderButton = (rowInfo) => {
    const updatedNode = {
      ...rowInfo.node,
      buttonsVisible: false, // Toggle buttonsVisible property
    };
    const updatedTreeData = changeNodeAtPath({
      treeData: treeData,
      path: rowInfo.path,
      newNode: updatedNode,
      getNodeKey: getNodeKey,
    });
    updateTreeData(updatedTreeData);
  };
  const sideBar = () => {
    const imageSidebar = $(".image-sidebar");
    const lightBox = $(".lightbox-container");
    if (imageSidebar.hasClass("open")) {
      imageSidebar.css("display", "none");
      lightBox.css({ width: "100vw" });
    } else {
      imageSidebar.css({
        height: "100vh",
        background: "#6c757d",
        color: "white",
        width: "300px",
        position: "fixed",
        right: "0",
        top: "0",
        overflow: "hidden  scroll",
        display: " block",
        // "grid-template-columns":" repeat(2, 1fr)",
        // "grid-gap":" 10px",
        padding: "10px",
        "padding-bottom": "200px",
        "z-index": "99999",
      });
      lightBox.css({ width: "calc(100vw - 300px)" });
    }
    imageSidebar.toggleClass("open");
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const selectImage = (index) => {
    setSelectedImageIndex(index);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setShowLightbox(true);
  };

  const handlePrevClick = () => {
    if (selectedImageIndex === 0) {
      return;
    }

    setSelectedImageIndex(selectedImageIndex - 1);
  };

  const handleNextClick = () => {
    if (selectedImageIndex === files.length - 1) {
      return;
    }

    setSelectedImageIndex(selectedImageIndex + 1);
  };

  const [scale, setScale] = useState(1);
  const handleZoomIn = () => {
    if (scale <= 2) {
      setScale(scale + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "ArrowRight") {
      // move to next image
      if (files.length > selectedImageIndex + 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      }
    } else if (e.key === "ArrowLeft") {
      // move to previous image
      if (0 < selectedImageIndex) {
        setSelectedImageIndex(selectedImageIndex - 1);
      }
    } else if (e.key === "+" || e.key === "=") {
      // zoom in
      handleZoomIn();
    } else if (e.key === "-") {
      // zoom out
      handleZoomOut();
    } else if (e.key === "Esc" || e.key === "Escape") {
      // zoom out
      setShowLightbox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });
  const [isSlideshowActive, setIsSlideshowActive] = useState(false);
  useEffect(() => {
    const handleSlideshow = () => {
      // move to next image
      // const currentIndex = images.findIndex((image) => image === selectedImage);
      if (selectedImageIndex < files.length - 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      } else {
        setSelectedImageIndex(0);
      }
    };

    let intervalId;

    if (isSlideshowActive) {
      intervalId = setInterval(handleSlideshow, 3000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedImageIndex, isSlideshowActive]);
  return (
    <>
      {loader === undefined ? (
        <Loading />
      ) : (
        <div>
          <div className="md:flex md:items-center mt-3">
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mr-1">
              <div className="md:w-auto mr-1">
                <label className="block capitalize text-gray-700 font-bold mb-2">
                  Template:
                </label>
              </div>
              <div className="md:w-auto">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id={`Template`}
                  value={templateID}
                  onChange={(e) => {
                    setTemplateID(parseInt(e.target.value));
                    getPictureTemplateID(parseInt(e.target.value));
                    const id =
                      e.target.value == undefined ? "1" : e.target.value;
                    singleData(parseInt(id));
                  }}
                >
                  <option value="undefined">Select Template</option>
                  {getID?.map((row) => (
                    <option value={row?.id}>{row?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
              <span className="mr-4">OR</span>
              <div className="flex justify-between items-center gap-3">
                <input
                  type="checkbox"
                  value={without}
                  checked={without}
                  onChange={(e) => {
                    const value = e.target.checked ? true : false;
                    setWithout(value);
                  }}
                />
                <label className="input-label text-gray-700 font-bold">
                  Without Template
                </label>
              </div>
            </div>
          </div>
          {without ? (
            <>
              <div className="md:flex flex-row-reverse md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-1/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        // id={`p-measurement-pictures-${index}`}
                        type="file"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          let newImages = [];
                          let maxOrderNumber = 0;

                          // Find the highest order number in the current images
                          withOutFiles?.forEach((imageObject) => {
                            if (imageObject.order_number > maxOrderNumber) {
                              maxOrderNumber = imageObject.order_number;
                            }
                          });

                          // Calculate the total number of images (existing images + new images)
                          const totalImages =
                            withOutFiles.length + files.length;

                          // Loop through each file and convert to base64
                          files.forEach((file, i) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const fileBase64 = reader.result;
                              try {
                                const res = await ApiRequest.post(imageApi, {
                                  file: fileBase64,
                                });

                                // Add the new image to the array with a unique order number
                                newImages.push({
                                  file: res?.data?.data?.file,
                                  title: res?.data?.data?.file,
                                  file_folder_id: null,
                                  url: fileBase64,
                                });

                                // If all new images have been processed, update the state
                                if (newImages.length === files.length) {
                                  let fileLists = [...withOutFiles];
                                  fileLists = [...fileLists, ...newImages];
                                  setWithOutFiles(fileLists);
                                  let unitCopy = [...withOutFiles];
                                  unitCopy.files = fileLists.map((picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                    file_folder_id: picObj.file_folder_id,
                                  }));
                                  setFiles(unitCopy?.files);
                                  handleFiles(unitCopy?.files);
                                }
                              } catch (err) {
                                // console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            files?.filter((obj) => obj.file_folder_id === null)
                              ?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                {files?.filter((obj) => obj.file_folder_id === null)?.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="property-staff-documents-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {files
                            ?.filter((obj) => obj.file_folder_id === null)
                            ?.map((fileObject, fileIndex) =>
                              fileComponent({
                                file: fileObject?.file,
                                title: fileObject?.title,
                                key: fileIndex,
                                inputFieldId: "unit-staff-files",
                                trashAble: true,
                                editAble: true,
                                onTrashClick: () => {
                                  const fileLists = [...files];
                                  fileLists.splice(fileIndex, 1);
                                  setFiles(fileLists);
                                  handleFiles(fileLists);
                                },
                                onEditClick: () => {
                                  const fileLists = [...files];
                                  const currentTitle =
                                    fileLists[fileIndex].title || "";

                                  Swal.fire({
                                    title: "Edit File",
                                    html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                                    focusConfirm: false,
                                    preConfirm: () => {
                                      const title =
                                        document.getElementById(
                                          "swal-input1"
                                        ).value;
                                      return title;
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      const title = result.value;
                                      fileLists[fileIndex].title = title;
                                      setFiles(fileLists);
                                      handleFiles(fileLists);
                                    }
                                  });
                                },
                              })
                            )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {spinner == undefined && without == false ? (
            <Spinner />
          ) : treeData != undefined ? (
            <>
              <div style={{ flex: "0 0 auto", padding: "0 15px" }}>
                {/* <h2 className="text-center text-lg font-bold">Rent Blaze Tree View</h2> */}
                <br />
                {/* <button onClick={handleCreateShowModal} className="bg-green-300 px-4 align-items-center rounded text-black-800 mt-1" data-bs-toggle="modal"
                data-bs-target='#staticBackdrop1'>Create Node</button>
        {createNodeModal ?
        <div class="fixed index-z inset-0 overflow-hidden" style={{marginTop:"15%"}}>
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   
          <div class="fixed inset-0 " aria-hidden="true"></div>
      
    
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Create Node</h3>
              <div class="mt-2">
                <input className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Add Node Name" ref={inputEl} type="text" />
                </div>
                <div className="flex w-full">
                <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              // setFormData({
                              //   ...formData,
                              //   dl_id_picture: res?.data?.data?.image,
                              // });
                              setIcon(res?.data?.data?.image);
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img src={`https://apis.rentblaze.com/public/uploads/${icon}`} alt="icon" width={50} className="px-3 w-2/12 h-1/6 pt-3"/>
                    </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={()=>{createNode();handleCreateCloseModal()}}>Add Node</button>
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm" data-dismiss="modal" onClick={handleCreateCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :null}
        <br /> */}
                <button
                  type="button"
                  onClick={expandAll}
                  className="bg-green-300 px-4 mx-1 align-items-center rounded text-black-800"
                >
                  Expand All
                </button>
                <button
                  type="button"
                  onClick={collapseAll}
                  className="bg-green-300 px-4 align-items-center rounded text-black-800"
                >
                  Collapse All
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <form
                  style={{ display: "inline-block" }}
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <label htmlFor="find-box">
                    Search:&nbsp;
                    <input
                      id="find-box"
                      type="text"
                      placeholder="Search"
                      className="mr-1 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={searchString}
                      onChange={(event) => setSearchString(event.target.value)}
                    />
                  </label>

                  <button
                    type="button"
                    className="mr-1"
                    disabled={!searchFoundCount}
                    onClick={selectPrevMatch}
                  >
                    <i class="fa fa-angle-left"></i>
                  </button>

                  <button
                    type="button"
                    disabled={!searchFoundCount}
                    onClick={selectNextMatch}
                  >
                    <i class="fa fa-angle-right"></i>
                  </button>

                  <span>
                    &nbsp;
                    {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                    &nbsp;/&nbsp;
                    {searchFoundCount || 0}
                  </span>
                </form>
              </div>

              {treeData.length != 0 && without == false ? (
                <div style={{ height: "150vh", display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <SortableTree
                      treeData={treeData}
                      onChange={(treeData) => updateTreeData(treeData)}
                      onMoveNode={onMoveNode}
                      searchQuery={searchString}
                      searchFocusOffset={searchFocusIndex}
                      searchFinishCallback={(matches) => {
                        setSearchFoundCount(matches.length);
                        setSearchFocusIndex(
                          matches.length > 0
                            ? searchFocusIndex % matches.length
                            : 0
                        );
                      }}
                      canDrag={({ node }) => !node.dragDisabled}
                      onClick={(e) => {
                        handleNodeClick(e);
                      }}
                      generateNodeProps={(rowInfo) => ({
                        // title: rowInfo.node.label,
                        // subtitle: rowInfo.node.subTitle,

                        buttons: [
                          <>
                            {rowInfo.buttons}
                            {rowInfo.node.buttonsVisible ? (
                              <ul className="button-container">
                                <li>
                                  <button
                                    label="Add Sibling"
                                    type="button"
                                    // className="bg-green-300 align-items-center rounded text-black-800"
                                    className="px-3 align-items-center"
                                    onClick={(event) => {
                                      alertNodeInfo(rowInfo);
                                      handleSiblingShowModal(rowInfo);
                                    }}
                                  >
                                    <i
                                      className="fa fa-folder mr-3"
                                      title="Add Sibling"
                                    ></i>
                                    Add Folder
                                  </button>
                                </li>
                              
                                <li>
                                  <button
                                    label="Add Child"
                                    type="button"
                                    // className="bg-green-300 px-4 align-items-center rounded text-black-800 mx-3"
                                    className="px-3 align-items-center"
                                    onClick={(event) => {
                                      alertNodeInfo(rowInfo);
                                      handleChildShowModal(rowInfo);
                                    }}
                                  >
                                    <i
                                      className="fa fa-folder mr-3"
                                      title="Add Child"
                                    ></i>
                                    Add SubFolder
                                  </button>
                                </li>
                                
                                <li>
                                  <button
                                    label="Update"
                                    type="button"
                                    className="px-3 align-items-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateNodeName"
                                    onClick={(event) => {
                                      alertNodeInfo(rowInfo);
                                      handleUpdateShowModal(rowInfo);
                                    }}
                                  >
                                    <i className="fa fa-edit mr-3" title="Update"></i>
                                    Rename
                                  </button>
                                </li>
                                

                                <li>
                                  <button
                                    label="Delete"
                                    type="button"
                                    // className="bg-green-300 px-4 align-items-center rounded text-black-800 mx-3"
                                    className="px-3 align-items-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    onClick={(event) => {
                                      alertNodeInfo(rowInfo);
                                      handleShowModal(rowInfo);
                                    }}
                                  >
                                    <i
                                      className="fa fa-remove mr-3"
                                      title="Remove"
                                    >
                                      {" "}
                                    </i>
                                    Delete
                                  </button>
                                </li>
                                
                                <li className="info">
                                  <button
                                    label="Alert"
                                    // className="bg-green-300 px-4 align-items-center rounded text-black-800 info"
                                    type="button"
                                    className="info px-3 align-items-center"
                                    onClick={(event) => {
                                      alertNodeInfo1(rowInfo);
                                      handleNodeClick(event);
                                      AddFolderButton(rowInfo);
                                    }}
                                  >
                                    <i
                                      className="fa fa-info-circle mr-3"
                                      title="Info"
                                    ></i>
                                    Open
                                  </button>
                                </li>
                              </ul>
                            ) : null}
                          </>,
                        ],
                        onContextMenu: (event) => {
                          event.preventDefault();
                          console.log("RowInfo", rowInfo);
                          console.log("treeData", treeData);
                        
                          // Remove "active" class from all .rst__rowLabel elements
                          const activeRowLabels = document.querySelectorAll('.rst__rowLabel.active');
                          activeRowLabels.forEach((rowLabel) => {
                            rowLabel.classList.remove('active');
                          });
                        
                          if (rowInfo && rowInfo.node) {
                            const clickedNodeId = rowInfo.node.id;
                            const parentNodeId = rowInfo.node.parent_id;
                        
                            // Function to update the visibility of buttons for nodes
                            const updateButtonVisibility = (nodes, parentIds, isParentClicked) => {
                              return nodes.map((node) => {
                                const updatedNode = {
                                  ...node,
                                  buttonsVisible: false, // Disable buttons by default
                                };
                        
                                if (node.id === clickedNodeId && !isParentClicked) {
                                  updatedNode.buttonsVisible = true; // Enable button for the clicked child node
                                } else if (parentIds.includes(node.parent_id) && !isParentClicked) {
                                  updatedNode.buttonsVisible = true; // Enable button for the parent node
                                }
                        
                                if (node.children && node.children.length > 0) {
                                  const isNodeClicked = node.id === clickedNodeId;
                                  updatedNode.children = updateButtonVisibility(node.children, [clickedNodeId, ...parentIds], isNodeClicked);
                                }
                        
                                return updatedNode;
                              });
                            };
                        
                            // Update tree data to enable button for the clicked child node and disable buttons for other nodes
                            const updatedTreeData = updateButtonVisibility(treeData, [], false);
                            updateTreeData(updatedTreeData);
                        
                            // Pass active class to .rst__rowLabel of the clicked node
                            const rowLabelElement = event.target.closest('.rst__rowLabel');
                            if (rowLabelElement) {
                              rowLabelElement.classList.add('active');
                            }
                          }
                        },
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        

                        style: {
                          height: "50px",
                        },
                      })}
                    />
                  </div>
                  {nodeData2?.data?.name != undefined && without == false ? (
                    <div className="front mt-3" style={{ flex: 1 }} onClick={handleContextMenuClose}>
                      {/* <h2 className="font-bold text-center"></h2> */}
                      {/* <div
                        draggable="true" onDragStart={(e) => console.log('Dragging started!')}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        style={{ width: '300px', height: '300px', border: '2px dashed gray',alignItems:"center" }}
                      >
                        {image ? (
                          <>
                            <img src={image.dataUrl} alt={image.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            <p>{image.name}</p>
                          </>
                        ) : (
                          <p>Drag and drop an image here</p>
                        )}
                      </div> */}
                      {/* <DragDropFile /> */}
                      {/* <div {...getRootProps()} style={{ width: '300px', height: '300px', border: `2px dashed ${isDragActive ? 'green' : 'gray'}` }}>
                  <input {...getInputProps()} />
                  {image ? (
                    <>
                      <img src={image.dataUrl} alt={image.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      <p>{image.name}</p>
                    </>
                  ) : (
                    <p>Drag and drop an image here</p>
                  )}
                </div> */}
                      <div>
                        <h1
                          className="info-name"
                          style={{ background: "#f3f3f3", textAlign: "center" }}
                        >
                          {nodeData2?.data?.name}
                        </h1>
                        <FileUploader
                          handleChange={handleDrop}
                          multiple
                          name="file"
                          types={fileTypes}
                        />
                        <div className="flex flex-wrap mt-3 gallery">
                          {(() => {
                            const listItems = [];
                            for (let i = 0; i < files?.length; i++) {
                              if (files[i]?.file_folder_id == nodeData2?.data?.id) {
                                listItems.push(
                                  <div
                                    key={i}
                                    className="mt-3 mb-3"
                                    style={{ position: "relative" }}
                                  >
                                    {progress ? (
                                      <ProgressBar />
                                    ) : (
                                      <>
                                        <div
                                          style={{ display: "flex" }}
                                          onContextMenu={(e)=>{handleRightClick(e,i)}}
                                        >
                                          {getFileTypeImage(files[i]?.file)}
                                          <span
                                            className="px-2 mb-2 cursor-pointer"
                                            width={100}
                                            height={50}
                                            title={`${files[i]?.title}`}
                                          >
                                            {files[i]?.title}
                                          </span>
                                          {contextMenuVisible && rightClickedFileIndex === i && (
                                            <ContextMenu
                                              position={contextMenuPosition}
                                              onClose={handleContextMenuClose}
                                              onDownload={() => handleFileDownload(i)}
                                              onView={handleFileView}
                                              onRename={() => handleEdit(i)}
                                              onDelete={() => handleDelete(i)}
                                            />
                                          )}
                                        </div>
                                        
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            }
                            return listItems;
                          })()}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                "No Result Found"
              )}
            </>
          ) : (
            "Please Select Template First"
          )}
        </div>
      )}
      
      {/* {showLightbox && (
          <>
          <div className={`image-sidebar`}>
          <div className="image-sidebar-header ">
            {/* <button className="menu-icon" onClick={sideBar}>
              <i className="fa fa-bars"></i>
            </button> 
            <h2 className="text-white text-center">All Images</h2>
          </div>
          <div className="image-sidebar-content" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '10px',
            width: '100%',
          }}>
            {files?.map((image,index) => (
              <img
                key={index}
                src={`https://apis.rentblaze.com/public/uploads/${image?.picture}`}
                alt=""
                className={`img-sidebar-image ${selectedImageIndex === index ? 'border border-slate-50 p-3' : ''}`}
                onClick={() => selectImage(index)}
              />
            ))}
          </div>
        </div>
        <div className="lightbox-container">
          <div className="lightbox">
            <span>{`${selectedImageIndex + 1}/${files?.length}`}</span>
          <div className="flex flex-row-reverse justify-between items-center mr-4">
                    <div className="flex justify-between items-center gap-4">
            <button type="button" onClick={handleZoomIn} title="Zoom In"><i className="fa fa-search-plus"></i></button>
            <button type="button" onClick={handleZoomOut} title="Zoom Out"><i className="fa fa-search-minus"></i></button>
            <button type="button" onClick={() => setIsSlideshowActive(!isSlideshowActive)}>
              {isSlideshowActive ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
            </button>
            <button type="button" className="menu-icon" onClick={sideBar} title="All images">
              <i className="fa fa-bars"></i>
            </button>
            <button type="button" onClick={() => setShowLightbox(false)} title="Close"><i className="fa fa-close"></i></button>
            </div>
            </div>
            <div className="light">
              <button type="button" onClick={handlePrevClick} title="Prev"><i className="fa fa-angle-left"></i></button>
            <img src={`https://apis.rentblaze.com/public/uploads/${files[selectedImageIndex]?.picture}`} alt={`Image ${selectedImageIndex}`} style={{ transform: `scale(${scale})` }} />
            <button type="button" onClick={handleNextClick} title="Next"><i className="fa fa-angle-right"></i></button>
            </div>
          </div>
        </div>
        </>
      )} */}
        {siblingNodeModal ? (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add Folder</h3>
                <div className="mt-2">
                  <input
                    className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Add Node Name"
                    ref={inputEl}
                    type="text"
                  />
                </div>
                  <div className="flex w-full">
                    <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener(
                            "load",
                            async () => {
                              const imgBase64 =
                                reader.result;
                              try {
                                console.log(imgBase64);
                                const res =
                                  await ApiRequest.post(
                                    "/public/api/auth/upload-image",
                                    { image: imgBase64 }
                                  );
                                // console.log("res", res?.data?.data?.image);
                                // formData?.dl_id_picture = res?.data?.data?.image
                                // setFormData({
                                //   ...formData,
                                //   dl_id_picture: res?.data?.data?.image,
                                // });
                                setIcon(
                                  res?.data?.data?.image
                                );
                                // setDlidPicture(res?.data?.data?.image)
                                // setFeatureIcon(res?.data?.data?.image);
                              } catch (err) {
                                console.log(err);
                              }
                            }
                          );
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img
                      src={`https://apis.rentblaze.com/public/uploads/${icon}`}
                      alt="icon"
                      width={50}
                      className="px-3 w-2/12 h-1/6 pt-3"
                    />
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    data-dismiss="modal"
                    onClick={() => {
                      addNodeSibling(rowDataInfo);
                      handleSiblingCloseModal();
                      AddFolderButton(rowDataInfo);
                    }}
                  >
                    Add Sibling
                  </button>
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                    data-dismiss="modal"
                    onClick={() => {
                      handleSiblingCloseModal();
                      AddFolderButton(rowDataInfo);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
        ) : null}
        {childNodeModal ? (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Add SubFolder</h3>
              <div className="mt-2">
                <input
                  className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Add Node Name"
                  ref={inputEl}
                  type="text"
                />
              </div>
                  <div className="flex w-full">
                    <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Icon
                      </span>
                      <input
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener(
                            "load",
                            async () => {
                              const imgBase64 =
                                reader.result;
                              try {
                                console.log(imgBase64);
                                const res =
                                  await ApiRequest.post(
                                    "/public/api/auth/upload-image",
                                    { image: imgBase64 }
                                  );
                                // console.log("res", res?.data?.data?.image);
                                // formData?.dl_id_picture = res?.data?.data?.image
                                // setFormData({
                                //   ...formData,
                                //   dl_id_picture: res?.data?.data?.image,
                                // });
                                setIcon(
                                  res?.data?.data?.image
                                );
                                // setDlidPicture(res?.data?.data?.image)
                                // setFeatureIcon(res?.data?.data?.image);
                              } catch (err) {
                                console.log(err);
                              }
                            }
                          );
                          reader.readAsDataURL(file);
                          // const pictureWithURL = getImagesAndURLs(e)[0];
                          // setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                    <img
                      src={`https://apis.rentblaze.com/public/uploads/${icon}`}
                      alt="icon"
                      width={50}
                      className="px-3 w-2/12 h-1/6 pt-3"
                    />
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    data-dismiss="modal"
                    onClick={() => {
                      addNodeChild(rowDataInfo);
                      handleChildCloseModal();
                      AddFolderButton(rowDataInfo);
                    }}
                  >
                    Add Child
                  </button>
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                    data-dismiss="modal"
                    onClick={() => {
                      handleChildCloseModal();
                      AddFolderButton(rowDataInfo);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
          </div>
        ) : null}
        {updateNodeModal ? (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/4">
              <div className="bg-white px-4 pt-5 pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Rename</h3>
                <div className="mt-2">
                  <input
                    className="appearance-none block md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Update Name"
                    ref={inputEl}
                    type="text"
                    defaultValue={rowDataInfo.node.name}
                  />
                </div>
                <div className="flex w-full">
                  <label className="mt-3 block appearance-none text-center w-1/2 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload Icon
                    </span>
                    <input
                      accept="image/*"
                      id={`icon`}
                      type="file"
                      hidden={true}
                      onChange={async (e) => {
                        let file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", async () => {
                          const imgBase64 = reader.result;
                          try {
                            console.log(imgBase64);
                            const res = await ApiRequest.post("/public/api/auth/upload-image", { image: imgBase64 });
                            setIcon(res?.data?.data?.image);
                          } catch (err) {
                            console.log(err);
                          }
                        });
                        reader.readAsDataURL(file);
                      }}
                    />
                  </label>
                  <img
                    src={`https://apis.rentblaze.com/public/uploads/${icon}`}
                    alt="icon"
                    width={50}
                    className="px-3 w-2/12 h-1/6 pt-3"
                  />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  data-dismiss="modal"
                  onClick={() => {
                    updateNode(rowDataInfo);
                    handleUpdateCloseModal();
                    AddFolderButton(rowDataInfo);
                  }}
                >
                  Update Node
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                  data-dismiss="modal"
                  onClick={() => {
                    handleUpdateCloseModal();
                    AddFolderButton(rowDataInfo);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Remove</h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">Are you sure you want to remove?</p>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  data-dismiss="modal"
                  onClick={(event) => {
                    removeNode(rowDataInfo);
                    handleCloseModal(event);
                    AddFolderButton(rowDataInfo);
                  }}
                >
                  Remove
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                  data-dismiss="modal"
                  onClick={() => {
                    handleCloseModal();
                    AddFolderButton(rowDataInfo);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}


    </>
  );
}

export default FrontTree;
