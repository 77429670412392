import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { adminPrefix, adminSuffix } from "../../store/admin/adminThunk";
import {
  parkingGetPrefix,
  parkingGetSuffix,
} from "../../store/admin/parking/thunk";
import { getMoveOutReasons } from "../../store/admin/moveOutReason/Thunk";
import { getEthnicities } from "../../store/admin/Ethnicities/Thunk";
import { getFeatures } from "../../store/admin/Features/Thunk";
export const TableNamePrefix = ({
  loading,
  setDisplayNamePrefixForm,
  setNamePrefix,
  setApiIdNamePrefix,
}) => {
  const dispatch = useDispatch();

  const prefixes = useSelector((state) => {
    return state.adminPrefix.Prefixes.data?.prefixes;
  });

  const handleEdit = (id) => {
    const filterPrefixes = prefixes?.filter((item) => {
      return item.id == id;
    });
    dispatch(adminPrefix());
    setNamePrefix(() => filterPrefixes && filterPrefixes[0]?.prefix);
    setDisplayNamePrefixForm(true);
    setApiIdNamePrefix(id);
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Prefix",
      selector: (row) => row.prefix,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button
              class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"
              onClick={() => handleEdit(row.id)}
            >
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={prefixes}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
      />
    </>
  );
};
export const TableNameSuffix = ({
  loading,
  setNameSuffix,
  setApiIdNameSuffix,
  setDisplayNameSuffixForm,
}) => {
  const suffixes = useSelector(
    (state) => state.adminSuffix.Suffixes?.data?.suffixes
  );
  const dispatch = useDispatch();
  const handleEdit = (id) => {
    const filterSufixes = suffixes?.filter((item) => {
      return item.id == id;
    });
    dispatch(adminSuffix());
    setNameSuffix(() => filterSufixes && filterSufixes[0]?.suffix);
    setDisplayNameSuffixForm(true);
    setApiIdNameSuffix(id);
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Suffix",
      selector: (row) => row.suffix,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button
              class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"
              onClick={() => handleEdit(row.id)}
            >
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={suffixes}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
      />
    </>
  );
};

export const TableParkingPrefix=({
  prefixes,
  loading,
  setDisplayParkingPrefixForm,
  setParkingPrefix,
  setApiIdParkingPrefix,
})=>{
  const parkingPrefixes = useSelector((state) => {
    return state?.parkingPrefix?.parkingPrefixes?.data?.prefixes;
  });
  const dispatch = useDispatch();
  const handleEdit = (id) => {
    const filtered = parkingPrefixes?.filter((item) => {
      return item.id == id;
    });

    setParkingPrefix(() => filtered && filtered[0]?.prefix);
    setDisplayParkingPrefixForm(true);
    setApiIdParkingPrefix(id);
  };
  useEffect(() => {
    dispatch(parkingGetPrefix());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Prefix",
      selector: (row) => row.prefix,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button
              class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"
              onClick={() => handleEdit(row.id)}
            >
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={parkingPrefixes}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
      />
    </>
  );
};
export const TableParkingSuffix = ({
  suffixes,
  loading,
  setParkingSuffix,
  setApiIdParkingSuffix,
  setDisplayParkingSuffixForm,
}) => {
  const parkingSuffixes = useSelector((state) => {
    return state?.parkingPrefix?.parkingSuffixes?.data?.suffixes;
  });
  const dispatch = useDispatch();
  const handleEdit = (id) => {
    const filtered = parkingSuffixes?.filter((item) => {
      return item.id == id;
    });
    setParkingSuffix(() => filtered && filtered[0]?.suffix);
    setDisplayParkingSuffixForm(true);
    setApiIdParkingSuffix(id);
  };
  useEffect(() => {
    dispatch(parkingGetSuffix());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Suffix",
      selector: (row) => row.suffix,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button
              class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"
              onClick={() => handleEdit(row.id)}
            >
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={parkingSuffixes}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
      />
    </>
  );
};

export const MoveOutReasonTable = ({
  moveout,
  loading,
  setDisplayMoveOutForm,
  setMoveOutReasons,
  setApiIdMoveOutReasons,
}) => {
  const dispatch = useDispatch();
  const moveOutReasons = useSelector((state) => {
    return state?.moveOutReasons?.moveOutReasons?.data?.reasons;
  });
  const handleEdit = (id) => {
    const filtered = moveOutReasons?.filter((item) => {
      return item.id == id;
    });
    setMoveOutReasons(() => filtered && filtered[0]?.reason);
    setDisplayMoveOutForm(true);
    setApiIdMoveOutReasons(id);
  };
  useEffect(() => {
    dispatch(getMoveOutReasons());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <button
              class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"
              onClick={() => handleEdit(row.id)}
            >
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={moveOutReasons}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        defaultSortFieldId={1}
      />
    </>
  );
};

export const EthnicitiesTable = ({ Ethnicities, loading, setDisplayEtchForm, setEtch, setApiIEtch}) => {
  const dispatch = useDispatch();
  const [ethnicity,setEthnicity] = useState();
  const ethnicities = useSelector((state) => {
    return state?.ethnicities?.ethnicities?.data?.ethnicities
  });
  const handleEdit = (id) => {
    const filtered = ethnicities?.filter((item) => {
      return item.id == id;
    });
    console.log("filtered", filtered)
    setEtch(() => filtered && filtered[0]?.ethnicity);
    setDisplayEtchForm(true);
    setApiIEtch(id);
  };
  const addEthnicitiesData = async()=>{
    const response = await ApiRequest.post("/public/api/auth/ethnicity",{
      ethnicity:ethnicity
    })
    if(response.status == 200)
    {
      dispatch(getEthnicities());
    }
  }
  useEffect(() => {
    dispatch(getEthnicities());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Ethnicity",
      selector: (row) => row.ethnicity,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            
              <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"  onClick={() => handleEdit(row.id)}>
                <i class="fa-solid fa-pen mr-6"></i>
                <span>Edit</span>
              </button>
           
          </>
        );
      },
    },
  ];
  return (
    <>
    <div className="flex flex-row-reverse">
      <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800" 
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#Ethnicities"
              >
                <i class="fa-solid fa-plus mr-6"></i>
                <span>Add</span>
              </button>
              <div
                    class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                    id="Ethnicities"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="EthnicitiesLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog relative w-auto pointer-events-none">
                      <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                          <h5
                            class="text-xl font-medium leading-normal text-gray-800"
                            id="exampleModalLabel"
                          >
                            Add Ethnicity
                          </h5>
                          <button
                            type="button"
                            class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body relative p-4">
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/4">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Ethnicity:
                              </label>
                            </div>

                            <div className="md:w-1/2">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="ethnicity"
                                type="text"
                                placeholder="Steve"
                                required={false}
                                onChange={(e) => {
                                  setEthnicity(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            onClick={addEthnicitiesData}
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
        <DataTable
          columns={columns}
          data={ethnicities}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
        />
    </>
  );
};
export const FeaturesTable = ({setDisplayFeaturesForm, setFeatures, setOrderNumber, setFeatureIcon,setApiIdFeatures, }) => {
  const dispatch = useDispatch();
  const features = useSelector((state) => {
    return state?.features?.features?.data?.features

  });
 
  const handleEdit = (id) => {
    const filtered = features?.filter((item) => {
      return item.id == id;
    });
    // console.log("filtered", filtered)
    setFeatures(() => filtered && filtered[0]?.feature);
    setOrderNumber(() => filtered && filtered[0]?.order_number)
    setFeatureIcon(() => filtered && filtered[0]?.icon)
    setDisplayFeaturesForm(true);
    setApiIdFeatures(id);
  };
  useEffect(() => {
    dispatch(getFeatures());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "feature",
      selector: (row) => row.feature,
    },
    {
      name: "order number",
      selector: (row) => row.order_number,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            
              <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800"  onClick={() => handleEdit(row.id)}>
                <i class="fa-solid fa-pen mr-6"></i>
                <span>Edit</span>
              </button>
           
          </>
        );
      },
    },
  ];
  return (
    <>
        <DataTable
          columns={columns}
          data={features}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
        />
    </>
  );
};
