import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
	name: "alert",
	initialState: {
		alertList: [],
	},
	reducers: {
		createAlert: (state, action) => {
			state.alertList.push({
				message: action.payload.message,
				type: action.payload.type,
			});
		},
    removeAlert: (state, { payload }) => {
			if (payload)
				state.alertList.splice(
					state.alertList.indexOf(payload, 1)
				);
			else state.alertList.shift();
		},
	},
});

export const alertActions = alertSlice.actions;

export default alertSlice.reducer;
