import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { CKEditor } from "ckeditor4-react";
import ApiRequest from "../../utility/http";
import Swal from "sweetalert2";
import imgUrl from "../ImageUrl";
import { PatternFormat } from 'react-number-format';
import { Tooltip } from "../Loader/Tooltip";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";

export const PropertySecurityContacts = ({data,handleSecurityContactTrue,hiddenData,ownerSettings}) => {

    const initialContactData = {
        firstname: "",
        lastname: "",
        title: "",
        number1: "",
        number1_type: "",
        number2: "",
        number2_type: "",
        number3: "",
        number3_type: "",
        email: "",
        info: "",
        picture: null,
      };

    const [propertyContacts, setPropertyContacts] = useState([]);
    const [propertyContact, setPropertyContact] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    const [editorData, setEditorData] = useState("");
    const [propertyAssistantPictures, setPropertyAssistantPictures] = useState([]
      );
    const [viewPropertyContact, setViewPropertyContact] = useState([]);
    const [viewEditorData, setViewEditorData] = useState([]);
    const [viewPropertyAssistantPictures, setViewPropertyAssistantPictures] = useState(
      []
    );
    const picTypes = useAllowedPicMimeTypes();
    const [viewForm,setViewForm] =useState(false);
    const [dataDisabled,setDataDisabled] = useState(hiddenData);
    const [indexEdit,setIndexEdit ]= useState();
    console.log('@propertyContact',propertyContact);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
    function removeNonNumeric(inputString) {
      var numericString = inputString.replace(/\D/g, "");
      return numericString;
    }
    const formatPhoneNumber = (number, format) => {
      const digitsOnly = number?.replace(/\D/g, '') || '';
      let formattedNumber = '';
      let digitIndex = 0;
    
      for (let i = 0; i < format?.length; i++) {
        if (format[i] === '#') {
          formattedNumber += digitsOnly[digitIndex] || '';
          digitIndex++;
        } else if (format[i] === '_') {
          formattedNumber += ' ';
        } else {
          formattedNumber += format[i];
        }
      }
    
      return formattedNumber;
    };
    
    
    
    const imageComponent = ({
        image = {},
        key = 0,
        src = "",
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
        onEditClick = () => {},
        ...restProps
      }) => {
        const confirmEdit = (image) => {
          Swal.fire({
            title: 'Are you sure you want to edit this image?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              // Modify the image object here
              const editedImage = { ...image, title: 'New Title' };
              onEditClick(editedImage);
            }
          });
        };
      
        return image ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
          >
            <div className="relative h-fit w-full">
              <img
                title={image?.title}
                key={key}
                alt="property image"
                className="border block object-contain w-80 h-auto rounded-lg"
                src={src}
              />
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove image"
                    type="button"
                    className="logo w-5 mx-auto my-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
      
                      if (fileInputField) {
                        fileInputField.files.forEach((file) => {
                          if (file !== image) {
                            dataTransfer.items.add(file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
                {/* <button
                  type="button"
                  title="Edit image"
                  className="logo w-5 mx-auto my-1"
                  onClick={() => confirmEdit(image)}
                >
                  <img src="/assets/img/system-edit.png" alt="edit" />
                </button> */}
              </div>
            </div>
          </div>
        ) : (
          <img {...restProps} />
        );
      };

      const myDivRef = useRef(null);
      const notes = ()=>{
        const data = [];
        data.push(propertyContact);
        const array = [...data];
        // console.log('@array',array);
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[0].info = editorData;
        setPropertyContact(array);
      }
    const bulkData = ()=>{
      notes();
        // setPropertyContact({...emergencyContact,notes:editorData}); 
        const newDataArray  = [];
        newDataArray.push(propertyContact);
        handleSecurityContactTrue(prevArray => [...prevArray, ...newDataArray]);
        setPropertyContacts(prevArray => [...prevArray, ...newDataArray]);
        setPropertyContact(initialContactData);
        setPropertyAssistantPictures([]);
        setEditorData(null);
        setEditorData("");
        // setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...propertyContacts];
        array[indexEdit] = propertyContact;
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[indexEdit].info = editorData;
        setPropertyContacts(array);
    
        handleSecurityContactTrue(array);
        // setShowForm(false);
        setIndexEdit(undefined);
        setPropertyContact(initialContactData);
        setPropertyAssistantPictures([]);
        setEditorData(null);
        setEditorData("");

      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setViewForm(false);
        setDataDisabled(false);
        setPropertyContact(propertyContacts[index]);
        setEditorData(propertyContacts[index]?.info);
        setPropertyAssistantPictures(propertyContacts[index].picture)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        // setShowForm(false)
        setViewPropertyContact(propertyContacts[index]);
        setViewEditorData(propertyContacts[index].info);
        setViewPropertyAssistantPictures(propertyContacts[index].picture)
        // setDataDisabled(true);
        setViewForm(false);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      // const closeViewData = ()=>{
      //   setPropertyContact(initialContactData);
      //   setPropertyAssistantPictures([]);
      //   setEditorData(null);
      //   setViewForm(false);
      //   setDataDisabled(false);
      // }
      const deleteData = (index)=>{
        // setShowForm(false);
        setViewForm(true);
        setDataDisabled(false);
        setIndexEdit(undefined);
        const newArray = [...propertyContacts];
        newArray.splice(index, 1);
        setPropertyContacts(newArray);
        setPropertyAssistantPictures([]);
        setPropertyContact(initialContactData);
        setEditorData(null);
        handleSecurityContactTrue(newArray);
      }
    const columns = [
        {
          name: "Last Name",
          selector: (data) => data.lastname,
          sortable: true,
        },
        {
          name: "First Name",
          selector: (data) => data.firstname,
          sortable: true,
        },
        {
          name: "Title",
          selector: (data) => data.title,
          sortable: true,
        },
        {
          name: "Number 1",
          selector: (data) => data.number1 != "" ? data?.number1_type == 8 ? data?.number1 : formatPhoneNumber(data.number1,ownerSetting?.phone_number_format):"",
          sortable: true,
        },
        {
          name: "Email",
          selector: (data) => data.email,
          sortable: true,
        },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
              {/* <div className="flex"> */}
                {hiddenData != true ? (
                  <div className="flex">
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0"
                      data-bs-target={`#ViewSecurityForm`}
                      data-bs-toggle='modal'
                      data-index={index}
                      onClick={(e) => {
                        viewData(index);
                        setEditorData(" ")
                      }}
                    >
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0 mx-3"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        indexFind(index);
                      }}
                    >
                      <img src="/assets/img/system-edit.png" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/6 p-0"
                      data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        deleteData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-trashcan.svg" />
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-index={index}
                      data-bs-target={`#ViewSecurityForm`}
                      data-bs-toggle='modal'
                      onClick={(e) => {
                        viewData(index);
                        setEditorData(" ")
                      }}
                    >
                      
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                  </>
                )}
              {/* </div> */}

              {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
            </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : propertyContacts,
      };
      
      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Security Information";
        const headers = [["Last Name", "First Name","Title","Number 1","Email"]];
    
        const data = propertyContacts.map(elt => [elt.lastname,elt.firstname,elt.title ,elt.number1,elt.email])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Security Information.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = propertyContacts.map(elt => [elt.lastname,elt.firstname,elt.title ,elt.number1,elt.email])
        // Convert the data to a CSV string
    
        const headers = ["Last Name", "First Name","Title","Number 1","Email"];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Security Information.csv");
        link.click();
      };
      useEffect(() => {
        getNumberType();
        setPropertyContact(initialContactData);
        setPropertyContacts(data)
        setOwnerSetting(ownerSettings);
      }, [])
  return (
    <>
     <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewSecurityForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewSecurityFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Security Information
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setViewPropertyContact([]);
                    setViewPropertyAssistantPictures([]);
                    setEditorData(" ")
                    setViewEditorData(null);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              <div className="md:flex flex-column md:items-center w-full m-auto">
                <div className="w-full">
                  <div className="flex">
                    <div className="w-4/12">
                      {viewPropertyAssistantPictures != null && viewPropertyAssistantPictures != "" ?
                      (<img className="logo w-full" src={`${imgUrl}/${viewPropertyAssistantPictures}`} alt="" />)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                    </div>
                    <div className="w-8/12 ml-6">
                      <div className="flex">
                        {viewPropertyContact?.firstname !="" ? <h4 className="font-bold capitalize mr-2">{viewPropertyContact?.firstname}</h4>:null}
                        {viewPropertyContact?.lastname !="" ?<h4 className="font-bold capitalize">{viewPropertyContact?.lastname}</h4>:null}
                      </div>
                      {viewPropertyContact?.title != "" ?<div className="flex mt-2 italic">
                        <span>{viewPropertyContact?.title}</span></div>:null}
                      {viewPropertyContact?.email != "" ?<div className="flex mt-2"><a className="text-black no-underline" href={`mailto:${viewPropertyContact?.email}`}>{viewPropertyContact?.email}</a></div>:null}
                      {viewPropertyContact?.number1 != ""?<div className="flex mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(viewPropertyContact?.number1_type == row?.id)
                        {
                          return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold ml-2 text-black no-underline" href={`tel:${viewPropertyContact?.number1}`}>{viewPropertyContact?.number1_type == 8 ? viewPropertyContact?.number1: formatPhoneNumber(viewPropertyContact?.number1,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                      {viewPropertyContact?.number2 != ""?<div className="flex mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(viewPropertyContact?.number2_type == row?.id)
                        {
                          return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold ml-2 text-black no-underline" href={`tel:${viewPropertyContact?.number2}`}>{viewPropertyContact?.number2_type == 8 ? viewPropertyContact?.number2: formatPhoneNumber(viewPropertyContact?.number2,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                      {viewPropertyContact?.number3 != ""?<div className="flex mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(viewPropertyContact?.number3_type == row?.id)
                        {
                          return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold ml-2 text-black no-underline" href={`tel:${viewPropertyContact?.number3}`}>{viewPropertyContact?.number3_type == 8 ? viewPropertyContact?.number3: formatPhoneNumber(viewPropertyContact?.number3,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                    </div>
                  </div>
                      <hr />
                  {viewEditorData!= "" ?<div className="flex mt-2">
                  <div dangerouslySetInnerHTML={{ __html: viewEditorData }} />
                  </div>:null}
                  
                </div>
                <hr />
              </div>
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setPropertyContact([]);
                                setPropertyAssistantPictures([]);
                                setEditorData(null);
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
            </div>
          </div>
        </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-help-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Security Information:
              </label>

              
              <Tooltip data={"Here you can list your Security personnel and Services that the Tenant should contact if the need arises."}/>
              </div>
          </div>

              {hiddenData != true ?<>

              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center w-full mt-4">
                    <div className="md:flex md:items-center md:w-1/2">
                      <div className="md:w-2/5">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-first-name`}
                          type="text"
                          value={propertyContact?.firstname}
                          disabled={dataDisabled}
                          placeholder="John"
                          onChange={(e) => {
                            setPropertyContact({...propertyContact,firstname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center md:w-1/2 mx-3">
                      <div className="md:w-2/5">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-assistant-last-name`}
                          type="text"
                          disabled={dataDisabled}
                          value={propertyContact?.lastname}
                          placeholder="Doe"
                          onChange={(e) => {
                            setPropertyContact({...propertyContact,lastname:e.target.value})
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Title:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-title`}
                        type="text"
                        disabled={dataDisabled}
                        value={propertyContact?.title}
                        placeholder="Title"
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,title : e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 1:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      {/* <PatternFormat
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={propertyContact?.number1} 
                      allowEmptyFormatting
                      format="(###) ###-####"
                      mask="_" 
                      onChange={(e) => {
                        setPropertyContact({...propertyContact,number1:removeNonNumeric(e.target.value)});
                      }}
                      /> */}
                      {propertyContact?.number1_type == 8  ? 
                      <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-assistant-num-1`}
                      type="tel"
                      disabled={dataDisabled}
                      value={propertyContact?.number1}
                      placeholder="999 999-9999"
                      maxLength={ownerSetting?.phone_number_format?.length}
                      onChange={(e) => {
                        setPropertyContact({...propertyContact,number1:e.target.value});
                      }}
                    />
                      :<input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-1`}
                        type="tel"
                        disabled={dataDisabled}
                        value={formatPhoneNumber(propertyContact?.number1,ownerSetting?.phone_number_format)}
                        placeholder={`${ownerSetting?.phone_number_format}`}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number1:removeNonNumeric(e.target.value)});
                        }}
                      />}
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-1-type`}
                        value={propertyContact?.number1_type}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number1_type:e.target.value});
                        }}
                      >
                        <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 2:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                    {propertyContact?.number2_type == 8  ? 
                      <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-assistant-num-2`}
                      type="tel"
                      disabled={dataDisabled}
                      value={propertyContact?.number2}
                      placeholder="999 999-9999"
                      maxLength={ownerSetting?.phone_number_format?.length}
                      onChange={(e) => {
                        setPropertyContact({...propertyContact,number2:e.target.value});
                      }}
                    />
                      :<input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-2`}
                        type="tel"
                        disabled={dataDisabled}
                        value={formatPhoneNumber(propertyContact?.number2,ownerSetting?.phone_number_format)}
                        placeholder={`${ownerSetting?.phone_number_format}`}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number2:removeNonNumeric(e.target.value)});
                        }}
                      />}
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-2-type`}
                        value={propertyContact?.number2_type}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number2_type:e.target.value})
                        }}
                      >
                        <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 3:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                    {propertyContact?.number3_type == 8  ? 
                      <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`p-assistant-num-3`}
                      type="tel"
                      disabled={dataDisabled}
                      value={propertyContact?.number3}
                      placeholder="999 999-9999"
                      maxLength={ownerSetting?.phone_number_format?.length}
                      onChange={(e) => {
                        setPropertyContact({...propertyContact,number3:e.target.value});
                      }}
                    />
                      :<input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-3`}
                        type="tel"
                        disabled={dataDisabled}
                        value={formatPhoneNumber(propertyContact?.number3,ownerSetting?.phone_number_format)}
                        placeholder={`${ownerSetting?.phone_number_format}`}
                        maxLength={ownerSetting?.phone_number_format?.length}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number3:removeNonNumeric(e.target.value)});
                        }}
                      />}
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-num-3-type`}
                        value={propertyContact?.number3_type}
                        disabled={dataDisabled}
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,number3_type:e.target.value})
                        }}
                      >
                        <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Email:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`p-assistant-email`}
                        type="email"
                        value={propertyContact?.email}
                        disabled={dataDisabled}
                        placeholder="john.doe@email.com"
                        onChange={(e) => {
                          setPropertyContact({...propertyContact,email:e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Info:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        config={{
                            readOnly:dataDisabled
                        }}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:items-center md:justify-start md:w-2/5 mt-3">
                    <div className="md:w-1/2">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Contact Picture:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload picture
                        </span>
                        <input
                          accept={picTypes}
                          id={`property-assistant-picture`}
                          type="file"
                          disabled={dataDisabled}
                          hidden={true}
                          onChange={async (e) => {
                            let file = e.target.files[0];
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const imgBase64 = reader.result;
                              try {
                                console.log(imgBase64);
                                const res = await ApiRequest.post(
                                  "/public/api/auth/upload-image",
                                  { image: imgBase64 }
                                );
                                // console.log("res", res?.data?.data?.image);
                                setPropertyContact({...propertyContact,picture:res?.data?.data?.image})
                                setPropertyAssistantPictures(res?.data?.data?.image)
                                // setFeatureIcon(res?.data?.data?.image);
                              } catch (err) {
                                console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);

                            // console.log("here 142");
                            // const pictures = [...propertyBoardPicture];
                            // pictures[index] = getImagesAndURLs(e)[0];
                            // setPropertyBoardPicture(pictures);
                            // setPropertyBoard({propertyBoard,icon:pictures});
                            // setFormData({ ...formData, boards: boards });
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {propertyAssistantPictures != null && propertyAssistantPictures != "" ? (
                    <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                      <div className="container w-full">
                        <div
                          id="image-block"
                          className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                        >
                          {imageComponent({
                            image: propertyAssistantPictures,
                            src: imgUrl+"/"+propertyAssistantPictures,
                            inputFieldId: `tenant-item-verification-picture`,
                            trashAble: dataDisabled == true ? false : true,
                            onTrashClick: () => {
                            //   const pictures = [...propertyBoardPicture];
                            //   pictures = null;
                              
                              setPropertyAssistantPictures(null);
                              setPropertyContact({...propertyContact,picture:null});
                            //   setFormData({ ...formData, boards: boards });
                            },
                          })}
                        </div>
                      </div>
                    </section>
                  ) : null}
                </div>
                <hr />
              </div>


          {/* Add more */}


                    <div className="flex flex-row-reverse justify-between items-center mt-3">
                      <div className="flex justify-between items-center gap-4">
                        <button className="btn3" type='button' 
                        hidden={hiddenData}
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            editSpacesData();
                          } else {
                            bulkData();
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                      </div>
                    </div>
                    </>:null}
                    

          <hr />
          {propertyContacts?.length > 0 ? <>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={propertyContacts}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        className="striped"
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
          <hr/>
          </>:null}
    </>
  )
}
