import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import SignaturePad from "react-signature-pad";
import { useRowSelect } from "react-table";
import Webcam from "react-webcam";
import ReactSignatureCanvas from "react-signature-canvas";
import "../../components/button.css";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loading";
import imgUrl from "../ImageUrl";
import { EmergencyContacts } from "./EmergencyContacts";
import { Interactions } from "./Interactions";
import { References } from "./References";
import { Employments } from "./Employments";
import { OtherLivings } from "./OtherLivings";
import { Children } from "./Children";
import { Vehicles } from "./Vehicles";
import { Pets } from "./Pets";
import { Keys } from "./Keys";
import { LoanableItems } from "./LoanableItems";
import { TenantNotifications } from "./TenantNotifications";
import Swal from "sweetalert2";
import { Lightbox } from "../lightbox/Lightbox";
import { CarouselWithLightbox } from "../carousel/CarouselWithLightbox";
import { ContactDetails } from "../multiPurposeComponent/ContactDetails";

export const ShowTenantForm = (
  initialData = {},
  validationData = {},
  verticalScroll = 0,
  updateFormData
) => {
  // console.log("initialData",initialData)

  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialPictureData = {
    picutres: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
      order_number: "",
    },
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };
  const initialEmergencyContactData = {
    firstname: "",
    lastname: "",
    user_relationship_id: null,
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
  };
  const initialAddressData = {
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
  };
  const initialNumberData = [
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
  ];
  const initialInteractionData = {
    date: "",
    time: "",
    interaction_type_id: "",
    other_type_description: "",
    notes: "",
    files: [],
  };
  const initialKeyData = {
    key_id: "",
    assigned_date: "",
    assigned_verification_picture: "",
    is_lost_damaged: 0,
    lost_damaged_date: "",
    send_replacement_cost_invoice: 0,
    replacement_additional_fees: 0,
    replacement_reason: "",
    is_returned: 0,
    returned_date: "",
    returned_verification_picture: "",
    notes: "",
    replacement_files: [],
  };
  const initialItemData = {
    loanable_item_id: "",
    loanable_item_serial_number_id: "",
    assigned_date: "",
    assigned_verification_picture: "",
    is_lost_damaged: 0,
    lost_damaged_date: "",
    send_replacement_cost_invoice: 0,
    replacement_additional_fees: 0,
    replacement_reason: "",
    is_returned: 0,
    returned_date: "",
    returned_verification_picture: "",
    notes: "",
    replacement_files: [],
    item_given_pictures: [],
    item_return_pictures: [],
  };
  const initialVehicleData = {
    status: 1,
    year: "",
    vehicle_make_id: null,
    vehicle_model_id: null,
    color: "",
    vin: "",
    license_plate: "",
    assign_parking_space: 0,
    property_parking_space_id: null,
    pictures: [],
    files: [],
    notes: "",
  };
  const initialChildrenData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    gender_id: 1,
    date_of_birth: "",
    public_note: "",
    private_note: "",
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialShotData = {
    type: "",
    is_shot_applied: 1,
    applied_date: "",
    expiry_date: "",
    proof_picture: "",
    is_approved_by_owner: 1,
    is_rejected_by_owner: 0,
    rejected_reason: "",
  };
  const [tenantShot, setTenantShot] = useState([]);
  // console.warn("tenantShot",tenantShot)
  const initialPetData = {
    status: 1,
    name: "",
    pet_type_id: 1,
    pet_gender_id: 1,
    breed: "",
    color: "",
    weight: "",
    date_of_birth: "",
    support_animal: 0,
    deposit: 0,
    rent: 0,
    license: "",
    location_of_license: "",
    public_note: "",
    private_note: "",
    shots: [],
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialOtherLivingData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    date_of_birth: "",
    gender_id: 1,
    user_relationship_id: 1,
    other_relationship: "",
    additional_deposit: 0,
    additional_rent: 0,
    reason_for_movein: "",
    public_note: "",
    private_note: "",
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialEmploymentData = {
    company_name: "",
    position: "",
    annual_salary: "",
    start_year: "",
    end_year: "",
    present: 0,
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    website: "",
    supervisor: "",
    number: "",
    number_type: "",
    leaving_reason: "",
    notes: "",
  };
  const initialReferenceData = {
    firstname: "",
    lastname: "",
    user_reference_relationship_id: 1,
    other_reference_relationship: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    notes: "",
  };
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  const [securedFields, setSecuredFields] = useState({
    dln_id: "false",
    ssn: "false",
  });
  const [viewField,setViewField] = useState(false);
  const [TenantFormImagesWithUrl, setTenantFormImagesWithUrl] = useState([]);
  const formElement = document.getElementById("add-tenant-form");
  const formSubmitButton = document.getElementById("form-submit-button");
  const [lastEndLine, setLastEndLine] = useState();
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const [dlidPicture, setDlidPicture] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState();
  const [editorData, setEditorData] = useState();
  const [editorData1, setEditorData1] = useState();
  const [editorData2, setEditorData2] = useState();
  const [editorData3, setEditorData3] = useState();
  console.log("formdata", formData);
  console.log("editorData", editorData);
  console.log("editorData1", editorData1);
  console.log("editorData2", editorData2);
  console.log("editorData3", editorData3);
  const [tenantNotification, setTenantNotification] = useState([]);
  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [propertyManagerDocumentsWithUrl, setPropertyManagerDocumentsWithUrl] =
    useState([]);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [propertyOwnerDocumentsWithUrl, setPropertyOwnerDocumentsWithUrl] =
    useState([]);
  const [tenantAddress, setTenantAddress] = useState([]);

  const [tenantShotPicture, setTenantShotPicture] = useState([]);
  const [number, setNumber] = useState([]);
  const [tenantEmergencyContacts, setTenantEmergencyContacts] = useState([]);

  const [tenantInteraction, setTenantInteraction] = useState([]);
  const [tenantInteractionFiles, setTenantInteractionFiles] = useState([]);
  // console.warn("tenantInteractionFiles",tenantInteractionFiles);

  const [tenantKey, setTenantKey] = useState([]);
  const [tenantKeyFiles, setTenantKeyFiles] = useState([]);
  const [tenantKeyPicture, setTenantKeyPicture] = useState([]);
  const [tenantKeyReturnPicture, setTenantKeyReturnPicture] = useState([]);

  const [tenantItem, setTenantItem] = useState([]);
  const [tenantItemFiles, setTenantItemFiles] = useState([]);
  const [tenantItemPicture, setTenantItemPicture] = useState([]);
  const [
    tenantItemRetrunVerificationPicture,
    setTenantItemRetrunVerificationPicture,
  ] = useState([]);
  const [tenantItemGivenPicture, setTenantItemGIvenPicture] = useState([]);
  const [tenantItemReturnPicture, setTenantItemReturnPicture] = useState([]);

  const [tenantVehicle, setTenantVehicle] = useState([]);
  const [tenantVehicleFiles, setTenantVehicleFiles] = useState([]);
  const [tenantVehiclePicture, setTenantVehiclePicture] = useState([]);

  const [tenantChildren, setTenantChildren] = useState([]);
  const [tenantChildrenPicture, setTenantChildrenPicture] = useState([]);
  const [tenantChildrenFile, setTenantChildrenFile] = useState([]);
  const [tenantChildrenPrivateFile, setTenantChildrenPrivateFile] = useState(
    []
  );

  const [tenantPet, setTenantPet] = useState([]);
  const [tenantPetPicture, setTenantPetPicture] = useState([]);
  const [tenantPetFile, setTenantPetFile] = useState([]);
  const [tenantPetPrivateFile, setTenantPetPrivateFile] = useState([]);

  const [tenantOtherLiving, setTenantOtherLiving] = useState([]);
  const [tenantOtherLivingPicture, setTenantOtherLivingPicture] = useState([]);
  const [tenantOtherLivingFile, setTenantOtherLivingFile] = useState([]);
  const [tenantOtherLivingPrivateFile, setTenantOtherLivingPrivateFile] =
    useState([]);

  const [tenantEmployment, setTenantEmployment] = useState([]);
  const [loading, setLoading] = useState();

  const [tenantReference, setTenantReference] = useState([]);

  const [age, setAge] = useState([]);
  const [childrenAge, setChildrenAge] = useState([]);
  const [petAge, setPetAge] = useState([]);

  const [genderPreference, setGenderPreference] = useState([]);
  const [gender, setGender] = useState([]);
  const [userRelationship, setUserRelationship] = useState([]);
  const [interactionType, setInteractionType] = useState([]);
  const [vehicleMake, setVehicleMake] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [petGender, setPetGender] = useState([]);
  const [petType, setPetType] = useState([]);
  const [userReferenceRelationship, setUserReferenceRelationship] = useState(
    []
  );
  const [ethnicity, setEthnicity] = useState([]);
  const [moveoutReason, setMoveoutReason] = useState([]);
  const [key, setKey] = useState([]);
  const [loanableItem, setLoanableItem] = useState([]);
  const [prefix, setPrefix] = useState([]);
  const [suffix, setSuffix] = useState([]);
  const [keyName, setKeyName] = useState([]);
  const [replacementCost, setReplacementCost] = useState([]);

  const [newSerialNumber, setNewSerialNumber] = useState({
    id: null,
    loanable_item_id: null,
    serial_numbers: "",
  });
  const [imgSrc, setImgSrc] = useState(null);
  const [returnImgSrc, setReturnImgSrc] = useState(null);
  const keyWebcamRef = React.useRef(null);
  const returnKeyWebcamRef = React.useRef(null);
  const keyTenantCapture = React.useCallback(async () => {
    const imageSrc = keyWebcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [keyWebcamRef]);
  const ReturnKeyTenantCapture = React.useCallback(async () => {
    const imageSrc = returnKeyWebcamRef.current.getScreenshot();
    setReturnImgSrc(imageSrc);
  }, [returnKeyWebcamRef]);
  const [itemImgSrc, setItemImgSrc] = useState(null);
  const [returnItemImgSrc, setReturnItemImgSrc] = useState(null);
  const ItemWebcamRef = React.useRef(null);
  const returnItemWebcamRef = React.useRef(null);
  const ItemTenantCapture = React.useCallback(async () => {
    const imageSrc = ItemWebcamRef.current.getScreenshot();
    setItemImgSrc(imageSrc);
  }, [ItemWebcamRef]);
  const ReturnItemTenantCapture = React.useCallback(async () => {
    const imageSrc = returnItemWebcamRef.current.getScreenshot();
    setReturnItemImgSrc(imageSrc);
  }, [returnItemWebcamRef]);
  const [ip, setIp] = useState("");

  //  METHODS
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error(error));
  }, []);
  const keyVerification = useRef(null);
  const keyVerificationClear = () => {
    keyVerification.current.clear();
  };
  const keyVerificationReturn = useRef(null);
  const keyVerificationReturnClear = () => {
    keyVerificationReturn.current.clear();
  };
  const itemVerification = useRef(null);
  const itemVerificationClear = () => {
    itemVerification.current.clear();
  };
  const itemVerificationReturn = useRef(null);
  const itemVerificationReturnClear = () => {
    itemVerificationReturn.current.clear();
  };
  const signaturePad = useRef(null);
  const clear = () => {
    signaturePad.current.clear();
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };
  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit board icon"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={`${imgUrl}/${image}`}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;

    const calculate_days_until_next_birthday = (dob) => {
      const today = new Date();
      const birthDate = new Date(dob);
    
      // Calculate the next birthday
      const nextBirthday = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
      if (today > nextBirthday) {
        nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
      }
    
      // Calculate the difference in days
      const timeDiff = nextBirthday.getTime() - today.getTime();
      const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
      return daysLeft;
    };
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    const confirmPassword =()=>{
      Swal.fire({
        title: 'Confirm Password',
        customClass:{
          confirmButton:"btn2"
        },
        html: `
          <input id="swal-input1" type="password" class="swal2-input" placeholder="Enter Password" value="">
        `,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
          ];
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const [password] = result.value;
          
          if(password == "123456789")
          {
            Toast.fire({
              icon:"success",
              title:"You can View ID Now",
            })
            setViewField(true);
          }
          else{
            Toast.fire({
              icon:"error",
              title:"Wrong Password",
            })
            setViewField(false)
          }
        }
      });
    }

  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);

    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

    return TotalTime;
  };
  function stringToTime(timeString) {
    console.warn("timeString", timeString);
    if (timeString === null || timeString === undefined || timeString >= 0)
      return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1];
    let dayNight = ampm[1];

    let time = hours + ":" + minutes + " " + dayNight;
    let isPM = false;

    if (time.endsWith("am")) {
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time;
  }
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    // console.warn("UnformatDate",date)
    return date;
  }
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now;
  };

  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, '') || '';
    let formattedNumber = '';
    let digitIndex = 0;
  
    for (let i = 0; i < format?.length; i++) {
      if (format[i] === '#') {
        formattedNumber += digitsOnly[digitIndex] || '';
        digitIndex++;
      } else if (format[i] === '_') {
        formattedNumber += ' ';
      } else {
        formattedNumber += format[i];
      }
    }
  
    return formattedNumber;
  };
  function ShowFormatDate(data, format) {
    if (data === null || data === undefined)
      return;
    const date = new Date(stringToDate(data));
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const year = date.getFullYear();
  
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    format = format.replace('dd', formattedDay);
    format = format.replace('DD', formattedDay);
    format = format.replace('d', day);
    format = format.replace('D', day);
    format = format.replace('mm', formattedMonth);
    format = format.replace('MM', formattedMonth);
    format = format.replace('m', month);
    format = format.replace('M', month);
    format = format.replace('yyyy', year);
    format = format.replace('YYYY', year);
  
    return format;
  }

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 80;
  const Years50 = currentYear - 80;
  const years = [...Array(currentYear - startYear + 1).keys()]
    .map((x, i) => i + startYear)
    .reverse();
  const years50 = [...Array(currentYear - Years50 + 2).keys()]
    .map((x, i) => i + startYear)
    .reverse();

  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const base64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { image: imgBase64 });
        console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        // let array = [];
        // array.push({picture:res?.data?.data?.image})
        formData?.pictures?.push({ picture: res?.data?.data?.image });
        // setFormData({...formData, pictures :[...array]})

        console.log("base64converted files", res?.data?.data?.image);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Publicbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Staff64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_staff_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const getCountries = async () => {
    const response = await ApiRequest.get("public/api/auth/get-countries");
    if (response.status == 200) {
      setCountries(response.data?.data?.countries);
    }
  };
  const getStates = async (id) => {
    const response = await ApiRequest.get(
      `public/api/auth/get-states?country_id=${id}`
    );
    if (response.status == 200) {
      setStates(response.data?.data?.states);
    }
  };
  const getCities = async (data) => {
    const state = states?.map((col) => {
      if (col?.id == data) {
        return col?.state_code;
      }
    });
    const filteredArr = state.filter((value) => value !== undefined);
    const stateCode = filteredArr.join(", ");

    const response = await ApiRequest.get(
      `public/api/auth/get-cities?state_code=${stateCode}`
    );

    if (response.status == 200) {
      setCities(response.data?.data?.cities);
    }
  };
  const Managerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_manager_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Ownerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_owner_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleEmergencyContactTrue = (message) => {
    setTenantEmergencyContacts(message);
  };
  const handleInteractionTrue = (message) => {
    setTenantInteraction(message);
  };
  const handleReferenceTrue = (message) => {
    setTenantReference(message);
  };
  const handleEmploymentTrue = (message) => {
    setTenantEmployment(message);
  };
  const handleOtherLivingTrue = (message) => {
    setTenantOtherLiving(message);
  };
  const handleChildrenTrue = (message) => {
    setTenantChildren(message);
  };
  const handleVehicleTrue = (message) => {
    setTenantVehicle(message);
  };
  const handlePetTrue = (message) => {
    setTenantPet(message);
  };
  const handleKeyTrue = (message) => {
    setTenantKey(message);
  };
  const handleItemTrue = (message) => {
    setTenantItem(message);
  };
  const handleNotificationTrue = (message) => {
    setTenantNotification(message);
  };

  // API
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }
  const [numberType, setNumberType] = useState([]);
  const getNumberType = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-number-types");
    if (response.status == 200) {
      setNumberType(response?.data?.data?.number_types);
    }
  };
  const getGenderPreferences = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-genders-preferences"
    );
    if (response.status == 200) {
      setGenderPreference(response?.data?.data?.genders_preferences);
    }
  };
  const getGenders = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-genders");
    if (response.status == 200) {
      setGender(response?.data?.data?.genders);
    }
  };
  const getUserRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-relationships"
    );
    if (response.status == 200) {
      setUserRelationship(response?.data?.data?.relationships);
    }
  };

  const getInteractionTypes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-interactions-types"
    );
    if (response.status == 200) {
      setInteractionType(response?.data?.data?.interactions_types);
    }
  };
  const getVehicleMakes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-vehicles-makes"
    );
    if (response.status == 200) {
      setVehicleMake(response?.data?.data?.vehicles_makes);
    }
  };
  const getVehicleModels = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-vehicles-models"
    );
    if (response.status == 200) {
      setVehicleModel(response?.data?.data?.vehicles_models);
    }
  };
  const getPetGenders = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-pets-genders");
    if (response.status == 200) {
      setPetGender(response?.data?.data?.pets_genders);
    }
  };
  const getPetTypes = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-pets-types");
    if (response.status == 200) {
      setPetType(response?.data?.data?.types);
    }
  };
  const getUserReferenceRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-references-relationships"
    );
    if (response.status == 200) {
      setUserReferenceRelationship(response?.data?.data?.relationships);
    }
  };
  const getEthnicity = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-ethnicities");
    if (response.status == 200) {
      setEthnicity(response?.data?.data?.ethnicities);
    }
  };
  const getMoveoutReason = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-moveout-reasons"
    );
    if (response.status == 200) {
      setMoveoutReason(response?.data?.data?.reasons);
    }
  };
  const getMyKeys = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-keys");
    if (response.status == 200) {
      setKey(response?.data?.data);
    }
  };
  const getMyLoanableItems = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-my-loanable-items"
    );
    if (response.status == 200) {
      setLoanableItem(response?.data?.data);
    }
  };
  const addSerialNumber = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/add-loanable-item-serial-number",
      payload
    );
    if (response.status == 200) {
      getMyLoanableItems();
    }
  };
  const getPrefix = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-name-prefixes");
    if (response.status == 200) {
      setPrefix(response?.data?.data?.prefixes);
    }
  };
  const getSuffix = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-name-suffixes");
    if (response.status == 200) {
      setSuffix(response?.data?.data?.suffixes);
    }
  };

  const { id } = useParams();
  const getApi = async () => {
    const response = await ApiRequest.get(
      `/public/api/auth/get-tenant?tenant_id=${id}`
    );
    setLoading(response.status);
    console.log("@TenantREsponse", response);
    if (response.status === 200) {
      setFormData({
        ...formData,
        enable_portal: response?.data?.data?.profile?.enable_portal,
        ...formData,
        send_notifications: response?.data?.data?.profile?.send_notifications,
        ...formData,
        user_prefix_id: response?.data?.data?.user_prefix_id,
        ...formData,
        firstname: response?.data?.data?.firstname,
        ...formData,
        middlename: response?.data?.data?.middlename,
        ...formData,
        lastname: response?.data?.data?.lastname,
        ...formData,
        user_suffix_id: response?.data?.data?.user_suffix_id,
        ...formData,
        addresses: response?.data?.data?.addresses,
        ...formData,
        numbers: response?.data?.data?.numbers,
        ...formData,
        email: response?.data?.data?.email,
        ...formData,
        ssn: response?.data?.data?.ssn,
        ...formData,
        date_of_birth: response?.data?.data?.profile?.date_of_birth,
        ...formData,
        dln_id: response?.data?.data?.profile?.dln_id,
        ...formData,
        dl_id_picture: response?.data?.data?.profile?.dl_id_picture,
        ...formData,
        dln_id_issue_state: response?.data?.data?.profile?.dln_id_issue_state,
        ...formData,
        dln_id_issue_country:
          response?.data?.data?.profile?.dln_id_issue_country,
        ...formData,
        gender_id: response?.data?.data?.profile?.gender_id,
        ...formData,
        ethnicity_id: response?.data?.data?.profile?.ethnicity_id,
        ...formData,
        smoker: response?.data?.data?.profile?.smoker,
        ...formData,
        pictures: response?.data?.data?.pictures,
        ...formData,
        emergencies_contacts: response?.data?.data?.emergencies_contacts,
        ...formData,
        known_allergies: response?.data?.data?.profile?.known_allergies,
        ...formData,
        other_emergency_info:
          response?.data?.data?.profile?.other_emergency_info,
        ...formData,
        notifications: response?.data?.data?.notifications,
        ...formData,
        user_files: response?.data?.data?.files,
        ...formData,
        user_staff_files: response?.data?.data?.staff_files,
        ...formData,
        user_manager_files: response?.data?.data?.managers_files,
        ...formData,
        user_owner_files: response?.data?.data?.owners_files,
        ...formData,
        interactions: response?.data?.data?.interactions,
        ...formData,
        keys_trackings: response?.data?.data?.key_trackings,
        ...formData,
        loanable_items_trackings:
          response?.data?.data?.loanable_items_trackings,
        ...formData,
        vehicles: response?.data?.data?.vehicles,
        ...formData,
        childrens: response?.data?.data?.childrens,
        ...formData,
        pets: response?.data?.data?.pets,
        ...formData,
        other_livings: response?.data?.data?.other_livings,
        ...formData,
        references: response?.data?.data?.references,
        ...formData,
        moveout_address1: response?.data?.data?.profile?.moveout_address1,
        ...formData,
        moveout_address2: response?.data?.data?.profile?.moveout_address2,
        ...formData,
        moveout_city: response?.data?.data?.profile?.moveout_city,
        ...formData,
        moveout_country: response?.data?.data?.profile?.moveout_country,
        ...formData,
        moveout_state: response?.data?.data?.profile?.moveout_state,
        ...formData,
        moveout_zip_code: response?.data?.data?.profile?.moveout_zip_code,
        ...formData,
        moveout_number: response?.data?.data?.profile?.moveout_number,
        ...formData,
        moveout_number_type: response?.data?.data?.profile?.moveout_number_type,
        ...formData,
        moveout_email: response?.data?.data?.profile?.moveout_email,
        ...formData,
        moveout_date: response?.data?.data?.profile?.moveout_date,
        ...formData,
        moveout_reason_id: response?.data?.data?.profile?.moveout_reason_id,
      });
      setTenantFormImagesWithUrl(response?.data?.data?.pictures);
      dlidPicture?.pictures?.push({
        picture: response?.data?.data?.dl_id_picture,
      });
      setEditorData({
        ...editorData,
        user_notes: response?.data?.data?.profile?.user_notes,
        ...editorData,
        dln_id_issue_notes: response?.data?.data?.profile?.dln_id_issue_notes,
      });
      setEditorData1({
        ...editorData1,
        user_manager_notes: response?.data?.data?.profile?.user_manager_notes,
        ...editorData1,
        smoker_notes: response?.data?.data?.profile?.smoker_notes,
      });
      setEditorData2({
        ...editorData2,
        user_staff_notes: response?.data?.data?.profile?.user_staff_notes,
      });
      setEditorData3({
        ...editorData3,
        user_owner_notes: response?.data?.data?.profile?.user_owner_notes,
      });
      setTenantNotification(response?.data?.data?.notifications);
      setPropertyPublicDocumentsWithUrl(response?.data?.data?.files);
      setPropertyStaffDocumentsWithUrl(response?.data?.data?.user_staff_files);
      setPropertyManagerDocumentsWithUrl(
        response?.data?.data?.user_manager_files
      );
      setPropertyOwnerDocumentsWithUrl(response?.data?.data?.user_owner_files);
      setTenantInteraction(response?.data?.data?.interactions);
      setTenantKey(response?.data?.data?.keys_trackings);
      setLoanableItem(response?.data?.data?.loanable_items_trackings);
      setTenantVehicle(response?.data?.data?.vehicles);
      setTenantChildren(response?.data?.data?.children);
      setTenantPet(response?.data?.data?.pets);
      setTenantOtherLiving(response?.data?.data?.other_livings);
      setTenantEmployment(response?.data?.data?.employments);
      setTenantReference(response?.data?.data?.references);
      setTenantEmergencyContacts(response?.data?.data?.emergencies_contacts);
      setTenantAddress(response?.data?.data?.addresses);
      setNumber(response?.data?.data?.numbers);
    }
  };
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await ApiRequest.post("/public/api/auth/tenant", {
        enable_portal: formData?.enable_portal,
        send_notifications: formData?.send_notifications,
        user_prefix_id: formData?.user_prefix_id,
        firstname: formData?.firstname,
        middlename: formData?.middlename,
        lastname: formData?.lastname,
        user_role_type_id: formData?.user_role_type_id,
        user_suffix_id: formData?.user_suffix_id,
        addresses: formData?.addresses,
        numbers: formData?.numbers,
        email: formData?.email,
        date_of_birth: formData?.date_of_birth,
        dln_id: formData?.dln_id,
        dl_id_picture: formData?.dl_id_picture,
        dln_id_issue_state: formData?.dln_id_issue_state,
        dln_id_issue_notes: editorData?.dln_id_issue_notes,
        gender_id: formData?.gender_id,
        ethnicity_id: formData?.ethnicity_id,
        smoker: formData?.smoker,
        smoker_notes: editorData1?.smoker_notes,
        pictures: formData?.pictures,
        emergencies_contacts: tenantEmergencyContacts,
        known_allergies: formData?.known_allergies,
        other_emergency_info: formData?.other_emergency_info,
        notifications: tenantNotification,
        user_files: formData?.user_files,
        user_notes: editorData?.user_notes,
        user_staff_files: formData?.user_staff_files,
        user_staff_notes: editorData2?.user_staff_notes,
        user_manager_files: formData?.user_manager_files,
        user_manager_notes: editorData1?.user_manager_notes,
        user_owner_files: formData?.user_owner_files,
        user_owner_notes: editorData3?.user_owner_notes,
        interactions: tenantInteraction,
        keys_trackings: tenantKey,
        loanable_items_trackings: tenantItem,
        vehicles: tenantVehicle,
        childrens: tenantChildren,
        pets: tenantPet,
        other_livings: tenantOtherLiving,
        employments: tenantEmployment,
        references: tenantReference,
        moveout_address1: formData?.moveout_address1,
        moveout_address2: formData?.moveout_address2,
        moveout_city: formData?.moveout_city,
        moveout_state: formData?.moveout_state,
        moveout_zip_code: formData?.moveout_zip_code,
        moveout_number: formData?.moveout_number,
        moveout_number_type: formData?.moveout_number_type,
        moveout_email: formData?.moveout_email,
        moveout_date: formData?.moveout_date,
        moveout_reason_id: formData?.moveout_reason_id,
      });
      console.log("Result formData", formData);
      if (res.status == 200) {
        console.log("Added");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const TenantPictures = [];
      TenantFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        TenantPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });
      // const notifications = [
      //   ...tenantNotification.filter(
      //     (notifications) =>
      //       JSON.stringify(notifications) !== JSON.stringify(initialNoticeData)
      //   ),
      // ];
      // const references = [
      //   ...tenantReference.filter(
      //     (references) =>
      //       JSON.stringify(references) !== JSON.stringify(initialReferenceData)
      //   ),
      // ];
      // const employments = [
      //   ...tenantEmployment.filter(
      //     (employments) =>
      //       JSON.stringify(employments) !==
      //       JSON.stringify(initialEmploymentData)
      //   ),
      // ];
      const addresses = [
        ...tenantAddress.filter(
          (address) =>
            JSON.stringify(address) !== JSON.stringify(initialAddressData)
        ),
      ];
      // const shots = [
      //   ...tenantShot
      //     .map((shot, index) => {
      //       const pictures = [...tenantShotPicture];
      //       shot.proof_picture = pictures[index]?.image?.picture;
      //     })
      //     .filter(
      //       (shot) => JSON.stringify(shot) !== JSON.stringify(initialShotData)
      //     ),
      // ];
      // const emergencies_contacts = [
      //   ...tenantEmergencyContacts.filter(
      //     (emergencies_contacts) =>
      //       JSON.stringify(emergencies_contacts) !==
      //       JSON.stringify(initialEmergencyContactData)
      //   ),
      // ];
      // const interactions = [
      //   ...tenantInteraction
      //     .map((interaction, index) => {
      //       const files = [...tenantInteractionFiles];
      //       interaction.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return interaction;
      //     })
      //     .filter(
      //       (interaction) =>
      //         JSON.stringify(interaction) !==
      //         JSON.stringify(initialInteractionData)
      //     ),
      // ];
      // const keys_trackings = [
      //   ...tenantKey
      //     .map((key, index) => {
      //       const pictures = [...tenantKeyPicture];
      //       key.assigned_verification_picture = pictures[index]?.image?.picture;
      //       const returnpictures = [...tenantKeyReturnPicture];
      //       key.returened_verification_picture =
      //         returnpictures[index]?.image?.picture;
      //       const files = [...tenantKeyFiles];
      //       key.replacement_files = files[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return key;
      //     })
      //     .filter(
      //       (key) => JSON.stringify(key) !== JSON.stringify(initialKeyData)
      //     ),
      // ];
      // const loanable_items_trackings = [
      //   ...tenantItem
      //     .map((item, index) => {
      //       const pictures = [...tenantItemPicture];
      //       item.assigned_verification_picture =
      //         pictures[index]?.image?.picture;
      //       const returnpictures = [...tenantItemRetrunVerificationPicture];
      //       item.returened_verification_picture =
      //         returnpictures[index]?.image?.picture;
      //       const itemGivenPictures = [...tenantItemGivenPicture];
      //       item.pictures = itemGivenPictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const itemReturnPictures = [...tenantItemReturnPicture];
      //       item.pictures = itemGivenPictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...tenantItemFiles];
      //       item.replacement_files = files[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return item;
      //     })
      //     .filter(
      //       (item) => JSON.stringify(item) !== JSON.stringify(initialItemData)
      //     ),
      // ];
      // const other_livings = [
      //   ...tenantOtherLiving
      //     .map((otherLiving, index) => {
      //       const pictures = [...tenantOtherLivingPicture];
      //       otherLiving.pictures = pictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...tenantOtherLivingFile];
      //       otherLiving.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const privateFiles = [...tenantOtherLivingPrivateFile];
      //       otherLiving.private_files = privateFiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return otherLiving;
      //     })
      //     .filter(
      //       (otherLiving) =>
      //         JSON.stringify(otherLiving) !==
      //         JSON.stringify(initialOtherLivingData)
      //     ),
      // ];
      // const vehicles = [
      //   ...tenantVehicle
      //     .map((vehicle, index) => {
      //       const pictures = [...tenantVehiclePicture];
      //       vehicle.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...tenantVehicleFiles];
      //       vehicle.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return vehicle;
      //     })
      //     .filter(
      //       (vehicle) =>
      //         JSON.stringify(vehicle) !== JSON.stringify(initialVehicleData)
      //     ),
      // ];
      // const childrens = [
      //   ...tenantChildren
      //     .map((children, index) => {
      //       const pictures = [...tenantChildrenPicture];
      //       children.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...tenantChildrenFile];
      //       children.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const Privatefiles = [...tenantChildrenPrivateFile];
      //       children.private_files = Privatefiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return children;
      //     })
      //     .filter(
      //       (children) =>
      //         JSON.stringify(children) !== JSON.stringify(initialVehicleData)
      //     ),
      // ];
      // const pets = [
      //   ...tenantPet
      //     .map((pet, index) => {
      //       const pictures = [...tenantPetPicture];
      //       pet.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));

      //       const files = [...tenantPetFile];
      //       pet.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const Privatefiles = [...tenantPetPrivateFile];
      //       pet.private_files = Privatefiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return pet;
      //     })
      //     .filter(
      //       (pet) => JSON.stringify(pet) !== JSON.stringify(initialPetData)
      //     ),
      // ];
      const numbers = [
        ...tenantAddress.filter(
          (number) =>
            JSON.stringify(number) !== JSON.stringify(initialNumberData)
        ),
      ];
      data.pictures = [...TenantPictures];
      // if (
      //   JSON.stringify(initialData?.notifications) !==
      //   JSON.stringify(notifications)
      // ) {
      //   data.notifications = notifications;
      // }
      if (
        JSON.stringify(initialData?.addresses) !== JSON.stringify(addresses)
      ) {
        data.addresses = addresses;
      }
      // if (
      //   JSON.stringify(initialData?.references) !== JSON.stringify(references)
      // ) {
      //   data.references = references;
      // }
      // if (
      //   JSON.stringify(initialData?.employments) !== JSON.stringify(employments)
      // ) {
      //   data.employments = employments;
      // }
      // if (
      //   JSON.stringify(initialData?.emergencies_contacts) !==
      //   JSON.stringify(emergencies_contacts)
      // ) {
      //   data.emergencies_contacts = emergencies_contacts;
      // }
      // if (
      //   JSON.stringify(initialData?.interactions) !==
      //   JSON.stringify(interactions)
      // ) {
      //   data.interactions = interactions;
      // }
      // if (
      //   JSON.stringify(initialData?.keys_trackings) !==
      //   JSON.stringify(keys_trackings)
      // ) {
      //   data.keys_trackings = keys_trackings;
      // }
      // if (
      //   JSON.stringify(initialData?.loanable_items_trackings) !==
      //   JSON.stringify(loanable_items_trackings)
      // ) {
      //   data.loanable_items_trackings = loanable_items_trackings;
      // }
      // if (
      //   JSON.stringify(initialData?.other_livings) !==
      //   JSON.stringify(other_livings)
      // ) {
      //   data.other_livings = other_livings;
      // }
      // if (JSON.stringify(initialData?.vehicles) !== JSON.stringify(vehicles)) {
      //   data.vehicles = vehicles;
      // }
      // if (
      //   JSON.stringify(initialData?.childrens) !== JSON.stringify(childrens)
      // ) {
      //   data.childrens = childrens;
      // }
      // if (JSON.stringify(initialData?.pets) !== JSON.stringify(pets)) {
      //   data.pets = pets;
      // }
      if (JSON.stringify(initialData?.numbers) !== JSON.stringify(numbers)) {
        data.numbers = numbers;
      }
      data.user_files = [
        ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_staff_files = [
        ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_manager_files = [
        ...propertyManagerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_owner_files = [
        ...propertyOwnerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);
  useEffect(() => {
    getOwnerSettings();
    getGenderPreferences();
    getNumberType();
    getApi();
    getGenders();
    getUserRelationships();
    getInteractionTypes();
    getVehicleMakes();
    getVehicleModels();
    getPetGenders();
    getPetTypes();
    getUserReferenceRelationships();
    getMyKeys();
    getMyLoanableItems();
    getEthnicity();
    getPrefix();
    getSuffix();
    getMoveoutReason();
    setFormData(initialData.initialData);
    setTenantShot(initialShotData);
    setTenantFormImagesWithUrl(
      initialData?.pictures?.map((obj) => ({
        image: {
          picture: obj?.picture,
          title: "",
          caption: "",
          is_cover: "",
        },
        url: obj?.picture,
      }))
    );
    // setTenantNotification(
    //   initialData?.notifications?.length
    //     ? initialData?.notifications
    //     : [{ ...initialNoticeData }]
    // );
    // setTenantReference(
    //   initialData?.references?.length
    //     ? initialData?.references
    //     : [{ ...initialReferenceData }]
    // );
    // setTenantEmployment(
    //   initialData?.employments?.length
    //     ? initialData?.employments
    //     : [{ ...initialEmploymentData }]
    // );
    setTenantAddress(
      initialData?.addresses?.length
        ? initialData?.addresses
        : [{ ...initialAddressData }]
    );
    // if (initialData?.shots?.length) {
    //   setTenantKey(initialData.shots);
    //   setTenantShotPicture(
    //     initialData.shots.map((shot) => shot?.proof_picture)
    //   );
    // } else {
    //   setTenantShot([{ ...initialShotData }]);
    //   setTenantShotPicture([]);
    // }
    setNumber(
      initialData?.numbers?.length
        ? initialData?.numbers
        : [{ ...initialNumberData }]
    );
    setDlidPicture([]);

    // setTenantEmergencyContacts(
    //   initialData?.emergencies_contacts?.length
    //     ? initialData?.emergencies_contacts
    //     : [{ ...initialEmergencyContactData }]
    // );
    // setTenantInteraction( initialData?.interactions?.length
    //   ? initialData?.interactions
    //   : [{ ...initialInteractionData }])
    // if (initialData?.interactions?.length) {
    //   setTenantInteraction(initialData.interactions);

    //   setTenantInteractionFiles(
    //     initialData.interactions.map((interaction) => interaction?.files)
    //   );
    // } else {
    //   setTenantInteraction([{ ...initialInteractionData }]);
    //   setTenantInteractionFiles([[]]);
    // }
    // if (initialData?.keys_trackings?.length) {
    //   setTenantKey(initialData.keys_trackings);
    //   setTenantKeyPicture(
    //     initialData.keys_trackings.map(
    //       (key) => key?.assigned_verification_picture
    //     )
    //   );
    //   setTenantKeyReturnPicture(
    //     initialData.keys_trackings.map(
    //       (key) => key?.returened_verification_picture
    //     )
    //   );
    //   setTenantKeyFiles(initialData.keys_trackings.map((key) => key?.files));
    // } else {
    //   setTenantKey([{ ...initialKeyData }]);
    //   setTenantKeyPicture([]);
    //   setTenantKeyReturnPicture([]);
    //   setTenantKeyFiles([[]]);
    // }
    // if (initialData?.loanable_items_trackings?.length) {
    //   setTenantItem(initialData.loanable_items_trackings);
    //   setTenantItemPicture(
    //     initialData.loanable_items_trackings.map(
    //       (item) => item?.assigned_verification_picture
    //     )
    //   );
    //   setTenantItemRetrunVerificationPicture(
    //     initialData.loanable_items_trackings.map(
    //       (item) => item?.returened_verification_picture
    //     )
    //   );
    //   setTenantItemGIvenPicture(
    //     initialData.loanable_items_trackings.map((item) =>
    //       item?.item_given_pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantItemReturnPicture(
    //     initialData.loanable_items_trackings.map((item) =>
    //       item?.item_return_pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantItemFiles(
    //     initialData.loanable_items_trackings.map((key) => key?.files)
    //   );
    // } else {
    //   setTenantItem([{ ...initialItemData }]);
    //   setTenantItemPicture([]);
    //   setTenantItemRetrunVerificationPicture([]);
    //   setTenantItemFiles([[]]);
    //   setTenantItemReturnPicture([[]]);
    //   setTenantItemGIvenPicture([[]]);
    // }
    // if (initialData?.other_livings?.length) {
    //   setTenantOtherLiving(initialData.other_livings);
    //   setTenantOtherLivingPicture(
    //     initialData.other_livings.map((other_living) =>
    //       other_living?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantOtherLivingFile(
    //     initialData.other_livings.map((other_living) => other_living?.files)
    //   );
    //   setTenantOtherLivingPrivateFile(
    //     initialData.other_livings.map(
    //       (other_living) => other_living?.private_files
    //     )
    //   );
    // } else {
    //   setTenantOtherLiving([{ ...initialOtherLivingData }]);
    //   setTenantOtherLivingPicture([[]]);
    //   setTenantOtherLivingPrivateFile([[]]);
    //   setTenantOtherLivingFile([[]]);
    // }
    // if (initialData?.vehicles?.length) {
    //   setTenantVehicle(initialData.vehicles);
    //   setTenantVehiclePicture(
    //     initialData.vehicles.map((vehicle) =>
    //       vehicle?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantVehicleFiles(
    //     initialData.vehicles.map((vehicle) => vehicle?.files)
    //   );
    // } else {
    //   setTenantVehicle([{ ...initialVehicleData }]);
    //   setTenantVehiclePicture([[]]);
    //   setTenantVehicleFiles([[]]);
    // }
    // if (initialData?.childrens?.length) {
    //   setTenantChildren(initialData.childrens);
    //   setTenantChildrenPicture(
    //     initialData.childrens.map((children) =>
    //       children?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantChildrenFile(
    //     initialData.childrens.map((children) => children?.files)
    //   );
    //   setTenantChildrenPrivateFile(
    //     initialData.childrens.map((children) => children?.private_files)
    //   );
    // } else {
    //   setTenantChildren([{ ...initialChildrenData }]);
    //   setTenantChildrenPicture([[]]);
    //   setTenantChildrenFile([[]]);
    //   setTenantChildrenPrivateFile([[]]);
    // }
    // if (initialData?.pets?.length) {
    //   setTenantPet(initialData.pets);
    //   setTenantPetPicture(
    //     initialData.pets.map((pet) =>
    //       pet?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );

    //   setTenantPetFile(initialData.pets.map((pet) => pet?.files));
    //   setTenantPetPrivateFile(
    //     initialData.pets.map((pet) => pet?.private_files)
    //   );
    // } else {
    //   setTenantPet([{ ...initialPetData }]);
    //   setTenantPetPicture([[]]);
    //   setTenantPetFile([[]]);
    //   setTenantPetPrivateFile([[]]);
    // }
  }, []);

  return (
    <>
      {loading === undefined ? (
        <Loading />
      ) : (
        <>
          <div className="flex align-items-center">
            <img
              className="logo w-12 mr-5"
              src="/assets/img/tenants/users-tenant.png"
              alt=""
            />
            <h2 className="text-3xl font-bold text-black py-3">
              Show {formData?.firstname}
            </h2>
          </div>
          <form
            className="w-full"
            id="add-tenant-form"
            // onSubmit={formSubmitHandler}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="General Information" {...a11yProps(0)} />
              <Tab label="Emergency Information" {...a11yProps(1)} />
              <Tab label="Notifications" {...a11yProps(2)} />
              <Tab label="Notes & Files" {...a11yProps(3)} />
              <Tab label="Interactions" {...a11yProps(4)} />
              <Tab label="Keys" {...a11yProps(5)} />
              <Tab label="Loanable Items" {...a11yProps(6)} />
              <Tab label="Vehicles" {...a11yProps(7)} />
              <Tab label="Children" {...a11yProps(8)} />
              <Tab label="Pets" {...a11yProps(9)} />
              <Tab label="Others Living" {...a11yProps(10)} />
              <Tab label="Employment" {...a11yProps(11)} />
              <Tab label="References" {...a11yProps(12)} />
              <Tab label="Move-Out Address" {...a11yProps(13)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <div className="flex">
                <div class="w-1/2 p-4">
                  <div class="flex">
                    <div class="w-1/4">
                      {TenantFormImagesWithUrl?.map(
                        (image) =>
                          image.is_cover === 1 && (
                            <img
                              src={imgUrl + "/" + image.picture}
                              alt="Image"
                              className="h-16 w-16 object-cover rounded-full"
                            />
                          )
                      )}
                    </div>
                    <div class="w-3/4 ml-4">
                      <div class="text-left">
                        <span class="text-gray-600 font-semibold text-sm block">
                          {prefix?.map((row) => {
                            if (formData?.user_prefix_id == row?.id) {
                              return row?.prefix;
                            }
                          })}{" "}
                          {formData?.firstname} {formData?.lastname}
                        </span>
                      </div>
                      {formData?.addresses[0]?.address_line1 != "" ? (
                        <div class="text-left mt-1">
                          <span class="text-gray-600 font-semibold text-sm block">
                            {formData?.addresses[0]?.address_line1}
                          </span>
                        </div>
                      ) : null}
                      {formData?.addresses[0]?.address_line2 != "" ? (
                        <div class="text-left mt-1">
                          <span class="text-gray-600 font-semibold text-sm block">
                            {formData?.addresses[0]?.address_line2}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-1/4 mt-2">
                    {formData?.numbers[0]?.phone_number != "" ? (
                      <div class="text-left flex">
                        {numberType?.map(
                          (row) =>
                            formData?.numbers[0]?.number_type == row?.id && (
                              <img
                                src={imgUrl + "/" + row.icon}
                                alt="Icon"
                                className="h-6 w-6 object-cover mr-2"
                              />
                            )
                        )}{" "}
                        <span class="text-gray-600 font-semibold text-sm block">
                          {" "}
                          <a
                            className="text-black no-underline"
                            href={`tel:${formData?.numbers[0]?.phone_number}`}
                          >
                            {formData?.numbers[0]?.number_type == 8 ? formData?.numbers[0]?.phone_number :formatPhoneNumber(formData?.numbers[0]?.phone_number,ownerSettings?.phone_number_format)}
                          </a>
                        </span>
                      </div>
                    ) : null}
                    {formData?.numbers[1]?.phone_number != "" ? (
                      <div class="text-left mt-1 flex">
                        {numberType?.map(
                          (row) =>
                            formData?.numbers[1]?.number_type == row?.id && (
                              <img
                                src={imgUrl + "/" + row.icon}
                                alt="Icon"
                                className="h-6 w-6 object-cover mr-2"
                              />
                            )
                        )}
                        <span class="text-gray-600 font-semibold text-sm block">
                          <a
                            className="text-black no-underline"
                            href={`tel:${formData?.numbers[1]?.phone_number}`}
                          >
                            {formData?.numbers[1]?.number_type == 8 ? formData?.numbers[1]?.phone_number :formatPhoneNumber(formData?.numbers[1]?.phone_number,ownerSettings?.phone_number_format)}
                          </a>
                        </span>
                      </div>
                    ) : null}
                    {formData?.numbers[2]?.phone_number != "" ? (
                      <div class="text-left mt-1 flex">
                        {numberType?.map(
                          (row) =>
                            formData?.numbers[2]?.number_type == row?.id && (
                              <img
                                src={imgUrl + "/" + row.icon}
                                alt="Icon"
                                className="h-6 w-6 object-cover mr-2"
                              />
                            )
                        )}
                        <span class="text-gray-600 font-semibold text-sm block">
                          <a
                            className="text-black no-underline"
                            href={`tel:${formData?.numbers[2]?.phone_number}`}
                          >
                            {formData?.numbers[2]?.number_type == 8 ? formData?.numbers[2]?.phone_number :formatPhoneNumber(formData?.numbers[2]?.phone_number,ownerSettings?.phone_number_format)}
                          </a>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="w-1/4 mt-2">
                    {formData?.email != "" ? (
                      <div class="text-left">
                        <span class="text-gray-600 font-semibold text-sm block">
                          <a
                            className="text-black no-underline"
                            href={`mailto:${formData?.email}`}
                          >
                            {formData?.email}
                          </a>
                        </span>
                      </div>
                    ) : null}
                    {/* <div class="text-left mt-1">
        <span class="text-gray-600 font-semibold text-sm block">Label 2</span>
      </div> */}
                  </div>
                </div>
                <div class="w-1/2 p-4">
                  <div class="flex">
                    {/* <div class="w-1/4">
                      <img
                        src="your-image.jpg"
                        alt="Image"
                        class="h-16 w-16 object-cover rounded-full"
                      />
                    </div> */}
                    <div class="w-3/4">
                      {formData?.date_of_birth != "" ? <div class="text-left">
                        <span class="text-gray-600 font-semibold text-sm block">
                          Date of Birth: {ShowFormatDate(formData?.date_of_birth,ownerSettings?.date_format)} {`( ${calculate_days_until_next_birthday(stringToDate(formData?.date_of_birth))} days left)`}
                        </span>
                      </div>:null}
                      <div class="text-left mt-1">
                        <span class="text-gray-600 font-semibold text-sm block">
                          {ethnicity?.map((ethnicity)=>{
                            if(formData?.ethnicity_id == ethnicity?.id)
                            {
                              return ethnicity?.ethnicity
                            }
                          })} | {gender?.map((gender)=>{
                            if(formData?.gender_id == gender?.id)
                            {
                              return gender?.gender
                            }
                          })}
                           
                        </span>
                      </div>
                      <div class="text-left mt-1">
                        <span class="text-gray-600 font-semibold text-sm block">
                          {formData?.smoker == 1 ? "Smoker" : "Non-smoker"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {formData?.dln_id != '' ?<div className="w-1/4 mt-2">
                    <div class="text-left">
                      <span class="text-gray-600 font-semibold text-sm block relative">
                        DLN: {securedFields?.dln_id == "false" ? "********" :  formData?.dln_id}
                        <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          dln_id:
                            securedFields.dln_id === "false"
                              ? "true"
                              : "false",
                        });
                      }}
                    >
                      {securedFields.dln_id === "false" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                      </span>
                    </div>
                  </div>:null}
                  <div className="w-3/4 mt-2">
                    <div class="text-left">
                      <span class="text-gray-600 font-semibold text-sm block">
                        Issue Country: {countries?.map((country)=>{
                          if(formData?.country == country?.id)
                          {
                            return country?.country
                          }
                        })}
                      </span>
                    </div>
                    <div class="text-left mt-1">
                      <span class="text-gray-600 font-semibold text-sm block">
                        Issue State: {states?.map((state)=>{
                          if(formData?.state == state?.id)
                          {
                            return state?.state
                          }
                        })}
                      </span>
                    </div>
                    
                  </div>
                  {viewField == false ? <button type="button" className="btn2 mt-1 justify-content-end" onClick={confirmPassword}>View ID</button>:
                    <div className="w-1/4 mt-2">
                    {formData?.ssn != '' ?<div class="text-left">
                      <span class="text-gray-600 font-semibold text-sm block relative">
                        SSN: {securedFields?.ssn == "false" ? "********" :  formData?.ssn}
                        <span
                      className="absolute right-0 top-0"
                      onClick={() => {
                        setSecuredFields({
                          ...securedFields,
                          ssn:
                            securedFields.ssn === "false"
                              ? "true"
                              : "false",
                        });
                      }}
                    >
                      {securedFields.ssn === "false" ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </span>
                      </span>
                    </div>:null}
                  </div>
                    }
                </div>
              </div>
              <div className="flex">
                <h6 className="font-bold">Pictures:</h6>
                <CarouselWithLightbox data={formData?.pictures}/>
              </div>
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    {formData.enable_portal != 0 &&
                    formData.enable_portal != undefined ? (
                      <div className="md:flex md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Enable Portal:
                          </label>
                        </div>
                        <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.enable_portal}
                      disabled
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          enable_portal: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                        {formData?.enable_portal == 1 ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-remove"></i>
                        )}
                      </div>
                    ) : null}
                    {formData.send_notifications != 0 &&
                    formData.send_notifications != undefined ? (
                      <div className="md:flex md:w-1/2">
                        <div className="md:w-3/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Send Notifications:
                          </label>
                        </div>
                        <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.send_notifications}
                      disabled
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          send_notifications: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                        {formData?.send_notifications == 1 ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-remove"></i>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    {formData.user_prefix_id != "" &&
                    formData.user_prefix_id != null ? (
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Prefix:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_prefix_id"
                      required={false}
                      disabled
                      value={formData?.user_prefix_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_prefix_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {prefix.map((row) => (
                        <option value={row?.id}>{row?.prefix}</option>
                      ))}
                    </select>
                          <span>
                            {prefix?.map((row) => {
                              if (formData?.user_prefix_id == row?.id) {
                                return row?.prefix;
                              }
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {formData.firstname != "" ? (
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            First Name:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="firstname"
                      type="text"
                      placeholder="John"
                      required={false}
                      disabled
                      value={formData?.firstname}
                      onChange={(e) => {
                        setFormData({ ...formData, firstname: e.target.value });
                      }}
                    />
                          <span>{formData?.firstname}</span>
                        </div>
                      </div>
                    ) : null}
                    {formData.middlename != "" ? (
                      <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                        <div className="md:w-3/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Middle Name:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="middlename"
                      type="text"
                      placeholder="Ben"
                      required={false}
                      disabled
                      value={formData?.middlename}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          middlename: e.target.value,
                        });
                      }}
                    />
                          <span>{formData?.middlename}</span>
                        </div>
                      </div>
                    ) : null}
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Deo"
                      required={false}
                      value={formData?.lastname}
                      onChange={(e) => {
                        setFormData({ ...formData, lastname: e.target.value });
                      }}
                    />
                  </div>
                </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    {formData.lastname != "" ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Last Name:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Deo"
                      required={false}
                      disabled
                      value={formData?.lastname}
                      onChange={(e) => {
                        setFormData({ ...formData, lastname: e.target.value });
                      }}
                    />
                          <span>{formData?.lastname}</span>
                        </div>
                      </div>
                    ) : null}
                    <div className="md:flex md:items-center w-full md:w-6/12 lg:mx-20 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Prefix:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_prefix_id"
                      required={false}
                      value={formData?.user_prefix_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_prefix_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {prefix.map((row) => (
                        <option value={row?.id}>{row?.prefix}</option>
                      ))}
                    </select>
                  </div>
                </div>
                    {formData.user_suffix_id != "" &&
                    formData.user_suffix_id != null ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Suffix:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_suffix_id"
                      required={false}
                      value={formData?.user_suffix_id}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_suffix_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {suffix.map((row) => (
                        <option value={row?.id}>{row?.suffix}</option>
                      ))}
                    </select>
                          <span>
                            {suffix?.map((row) => {
                              if (formData?.user_suffix_id == row?.id) {
                                return row?.suffix;
                              }
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {formData?.user_role_type_id != "" ||
                  formData?.user_role_type_id != undefined ? (
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mt-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_role_type_id"
                      required={false}
                      value={formData?.user_role_type_id}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_role_type_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value=" ">Select</option>
                      <option value={5}>Tenant</option>
                      <option value={7}>Prospect</option>
                    </select>
                        <span>
                          {formData?.user_role_type_id == 5
                            ? "Tenant"
                            : formData?.user_role_type_id == 7
                            ? "Prospect"
                            : null}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  {tenantAddress?.map((row, index) => {
                    const addresses = [...tenantAddress];
                    return (
                      <>
                        {row.address_line1 != "" ? (
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Address Line 1:
                              </label>
                            </div>
                            <div className="md:w-4/5">
                              <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address-line-1"
                          type="text"
                          placeholder="Apt. suite no. 1"
                          required={false}
                          value={row?.address_line1}
                          disabled
                          onChange={(e) => {
                            addresses[index].address_line1 = e.target.value;
                            setTenantAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                              <span>{row?.address_line1}</span>
                            </div>
                          </div>
                        ) : null}
                        {row.address_line2 != "" ? (
                          <div className="md:flex md:items-center w-full mt-3">
                            <div className="md:w-1/5">
                              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                Address Line 2:
                              </label>
                            </div>
                            <div className="md:w-4/5">
                              <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address_line2"
                          type="text"
                          placeholder="Jeff Street"
                          value={row?.address_line2}
                          disabled
                          onChange={(e) => {
                            addresses[index].address_line2 = e.target.value;
                            setTenantAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                              <span>{row?.address_line2}</span>
                            </div>
                          </div>
                        ) : null}
                        <div className="md:flex md:items-center w-full mt-3">
                          {row.country != "" ? (
                            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  City:
                                </label>
                              </div>
                              <div className="md:w-3/4">
                                <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={row?.city}
                            disabled
                            onChange={(e) => {
                              addresses[index].city = e.target.value;
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                                <span>{row?.country}</span>
                              </div>
                            </div>
                          ) : null}
                          {row.state != "" && row.state != undefined ? (
                            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  State:
                                </label>
                              </div>
                              <div className="md:w-3/4">
                                <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-state"
                            required={false}
                            value={row?.state}
                            disabled
                            onChange={(e) => {
                              addresses[index].state = e.target.value;
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          >
                            <option value={"New Mexico"}>New Mexico</option>
                            <option value={"Missouri"}>Missouri</option>
                            <option value={"Texas"}>Texas</option>
                          </select>
                                <span>{formData?.state}</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="md:flex md:items-center w-full mt-3">
                          {row.city != "" ? (
                            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  City:
                                </label>
                              </div>
                              <div className="md:w-3/4">
                                <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={row?.city}
                            disabled
                            onChange={(e) => {
                              addresses[index].city = e.target.value;
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                                <span>{row?.city}</span>
                              </div>
                            </div>
                          ) : null}
                          {row.zip_code != "" ? (
                            <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  Zip:
                                </label>
                              </div>
                              <div className="md:w-3/4">
                                <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-zip"
                            type="text"
                            placeholder="90210"
                            required={false}
                            disabled
                            value={row?.zip_code}
                            onChange={(e) => {
                              addresses[index].zip_code = e.target.value;
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                                <span>{row?.zip_code}</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
                  {formData?.numbers[0]?.phone_number != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 1:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[0]?.phone_number}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][0].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                        <a
                          className="text-black"
                          href={`tel:${formData?.numbers[0]?.phone_number}`}
                        >
                          {formData?.numbers[0]?.phone_number}
                        </a>
                      </div>
                      <div className="md:w-1/5 pl-3 flex">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                        <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[0]?.number_type}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][0].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                        <span>
                          {numberType?.map((row) => {
                            if (formData?.numbers[0]?.number_type == row?.id) {
                              return row?.type;
                            }
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {formData?.numbers[1]?.phone_number != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 2:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[1]?.phone_number}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][1].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                        <a
                          className="text-black"
                          href={`tel:${formData?.numbers[1]?.phone_number}`}
                        >
                          {formData?.numbers[1]?.phone_number}
                        </a>
                      </div>
                      <div className="md:w-1/5 pl-3 flex">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                        <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[1]?.number_type}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][1].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                        <span>
                          {numberType?.map((row) => {
                            if (formData?.numbers[1]?.number_type == row?.id) {
                              return row?.type;
                            }
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {formData?.numbers[2]?.phone_number != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 3:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[2]?.phone_number}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][2].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                        <a
                          className="text-black"
                          href={`tel:${formData?.numbers[2]?.phone_number}`}
                        >
                          {formData?.numbers[2]?.phone_number}
                        </a>
                      </div>
                      <div className="md:w-1/5 pl-3 flex">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                        <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[2]?.number_type}
                    disabled
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][2].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                        <span>
                          {numberType?.map((row) => {
                            if (formData?.numbers[2]?.number_type == row?.id) {
                              return row?.type;
                            }
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {formData?.email != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`email`}
                    type="email"
                    placeholder="jhon.doe@email.com"
                    value={formData?.email}
                    disabled
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                        <a
                          className="text-black "
                          href={`mailto:${formData?.email}`}
                        >
                          {formData?.email}
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {formData?.ssn != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            SSN:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="ssn"
                      type="text"
                      value={formData?.ssn}
                      disabled
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, ssn: e.target.value });
                      }}
                    />
                          <span>{formData?.ssn}</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formData?.date_of_birth != "" &&
                  formData.date_of_birth != undefined ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Date of Birth:
                          </label>
                        </div>
                        <div className="md:w-1/2">
                          <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="date_of_birth"
                      type="date"
                      value={stringToDate(formData?.date_of_birth)}
                      disabled
                      // placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        const date = formatDate(e.target.value);
                        setFormData({ ...formData, date_of_birth: date });
                      }}
                    />
                          <span>{formData?.date_of_birth}</span>
                        </div>
                      </div>
                      {formData?.dln_id != "" &&
                      formData.dln_id != undefined ? (
                        <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                          <div className="md:w-1/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              DLN/ID:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id"
                      type="number"
                      value={formData?.dln_id}
                      disabled
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, dln_id: e.target.value });
                      }}
                    />
                            <span>{formData?.dln_id}</span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {formData?.dln_id_issue_country != "" ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            DLN/ID Country:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_country"
                      disabled
                      // value={formData?.dln_id_issue_state}
                      // onChange={(e) => {
                      //   setFormData({
                      //     ...formData,
                      //     dln_id_issue_state: e.target.value,
                      //   });
                      // }}
                    >
                      <option value={"USA"}>USA</option>
                      <option value={"Canada"}>Canada</option>
                      <option value={"UAE"}>UAE</option>
                      <option value={"UK"}>UK</option>
                    </select>
                          <span>
                            {countries?.map((country) => {
                              if (
                                formData?.dln_id_issue_country == country?.id
                              ) {
                                return country?.country;
                              }
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex md:items-center mt-3">
                    {formData?.dl_id_picture != "" &&
                    formData.dl_id_picture != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            DL/ID Picture:
                          </label>
                        </div>
                        <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload ID
                      </span>
                      <input
                        accept="image/*"
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        disabled
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              setFormData({
                                ...formData,
                                dl_id_picture: res?.data?.data?.image,
                              });
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          const pictureWithURL = getImagesAndURLs(e)[0];
                          setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                  </div>
                      </div>
                    ) : null}

                    {formData?.dln_id_issue_state != "" &&
                    formData.dln_id_issue_state != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-3/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            DLN/ID issue state:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_state"
                      value={formData?.dln_id_issue_state}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          dln_id_issue_state: e.target.value,
                        });
                      }}
                    >
                      <option value={"America"}>America</option>
                      <option value={"Canada"}>Canada</option>
                      <option value={"UAE"}>UAE</option>
                    </select>
                          <span>{formData?.dln_id_issue_state}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {formData?.dl_id_picture != null ? (
                    <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                      <div className="container w-full">
                        <div
                          id="image-block"
                          className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                        >
                          {imageComponent({
                            image: formData?.dl_id_picture,
                            // src: dlidPicture?.url,
                            inputFieldId: "dlid-picture",
                            trashAble: false,
                            onTrashClick: () => {
                              formData.dl_id_picture = null;
                              setDlidPicture(null);
                            },
                          })}
                        </div>
                      </div>
                    </section>
                  ) : null}
                  {editorData?.dln_id_issue_notes != "" &&
                  editorData?.dln_id_issue_notes != undefined ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          DLN/ID Issue Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                    initData={editorData?.dln_id_issue_notes}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData({
                        ...editorData,
                        dln_id_issue_notes: e.editor.getData(),
                      });
                    }}
                  />
                        <span>{editorData?.dln_id_issue_notes}</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex md:items-center mt-3">
                    {formData.gender_id != "" &&
                    formData.gender_id != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Gender:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`gender`}
                      value={formData?.gender_id}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          gender_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {gender.map((row) => (
                        <option value={row?.id}>{row?.gender}</option>
                      ))}
                    </select>
                          <span>
                            {gender?.map((row) => {
                              if (formData?.gender_id == row?.id) {
                                return row?.gender;
                              }
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {formData.ethnicity_id != "" &&
                    formData.ethnicity_id != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Ethnicity:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`ethnicity`}
                      value={formData?.ethnicity_id}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ethnicity_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {ethnicity.map((row) => (
                        <option value={row?.id}>{row?.ethnicity}</option>
                      ))}
                    </select>
                          <span>
                            {ethnicity?.map((row) => {
                              if (formData?.ethnicity_id == row?.id) {
                                return row?.ethnicity;
                              }
                            })}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {formData.smoker != 0 && formData.smoker != undefined ? (
                    <div className="w-full">
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:flex md:w-1/2">
                          <div className="md:w-2/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Smoker:
                            </label>
                          </div>
                          <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={formData?.smoker}
                        disabled
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setFormData({
                            ...formData,
                            smoker: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                          {formData?.smoker == 1 ? (
                            <i className="fa fa-check"></i>
                          ) : (
                            <i className="fa fa-remove"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formData?.smoker ? (
                    <>
                      {editorData1?.smoking_notes != "" &&
                      editorData1?.smoking_notes != undefined ? (
                        <div className="md:flex mt-3">
                          <div className="md:w-1/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Smoking Notes:
                            </label>
                          </div>
                          <div className="md:w-4/5">
                            <CKEditor
                      initData={editorData1?.smoking_notes}
                      config={{
                        readOnly:true
                      }}
                      onChange={(e) => {
                        setEditorData1({
                          ...editorData1, smoking_notes: e.editor.getData(),
                        });
                      }}
                    />
                            <span>{editorData1?.smoking_notes}</span>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {formData.pictures != "" ? (
                    <>
                      <div className="md:flex mt-4">
                        <div className="md:w-1/3">
                          <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                            Pictures:
                          </label>
                        </div>
                        <div className="md:flex flex-column md:items-start w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept="image/*"
                          id="tenant-images"
                          type="file"
                          multiple={true}
                          disabled
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            files.forEach((file, index) => {
                              base64Converter(file, index);
                            });
                            setTenantFormImagesWithUrl(getImagesAndURLs(e));
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              TenantFormImagesWithUrl?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                      </div>

                      <div >
                        {TenantFormImagesWithUrl?.length ? (
                          <div>
                            <section className="overflow-hidden w-full text-gray-700 mt-4">
                              <div className="container w-full px-3 m-auto">
                                <div
                                  id="images-block"
                                  className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                                >
                                  {TenantFormImagesWithUrl.map(
                                    (imageObject, index) =>
                                      imageComponent({
                                        image: imageObject?.picture,
                                        key: index,
                                        inputFieldId: "tenant-images",
                                        trashAble: false,
                                        onTrashClick: () => {
                                          let images = [...formData?.pictures];
                                          let image = [
                                            ...TenantFormImagesWithUrl,
                                          ];
                                          images.splice(index, 1);
                                          image.splice(index, 1);
                                          setTenantFormImagesWithUrl(image);
                                          setFormData({
                                            ...formData,
                                            pictures: images,
                                          });
                                        },
                                      })
                                  )}
                                </div>
                              </div>
                            </section>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div> 
              
              <div >
					{TenantFormImagesWithUrl?.length ? (
						<div>
							<section className="overflow-hidden w-full text-gray-700 mt-4">
								<div className="container w-full px-3 m-auto">
									<div
										id="images-block"
										className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
									>
										{TenantFormImagesWithUrl.map((imageObject, index) =>
											imageComponent({
												image: imageObject?.image?.picture,
												key: index,
												src: imageObject?.url,
												inputFieldId: "tenant-images",
												trashAble: false,
												onTrashClick: () => {
													let images = [...formData?.pictures];
													let image = [...TenantFormImagesWithUrl]
													images.splice(index, 1);
													image.splice(index, 1);
													setTenantFormImagesWithUrl(image);
													setFormData({...formData,pictures : images})
												},
											})
										)}
									</div>
								</div>
							</section>
						</div>
					) : null}
				</div> */}
              <hr />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/tenants/system-emergency-information.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Emergency Information:
                  </label>
                </div>
              </div>
              {/* <div className="md:items-center w-full mt-4">
            <div className="md:w-full">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Emergency Contacts:
              </label>
            </div>
          </div> */}
              {/* <EmergencyContacts
                data={tenantEmergencyContacts}
                handleEmergencyContactTrue={handleEmergencyContactTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              /> */}
              <ContactDetails data={tenantEmergencyContacts} ownerSettings={ownerSettings}/>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  {editorData?.known_allergies != "" &&
                  editorData?.known_allergies != undefined ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/svgs/tenant-allergies.svg"
                          alt=""
                        />
                      </div>

                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Known Allergies:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData?.known_allergies}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData({
                        ...editorData,
                        known_allergies: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData?.known_allergies}</span>
                      </div>
                    </div>
                  ) : null}
                  <hr />

                  {editorData2?.other_emergency_info != "" &&
                  editorData2?.other_emergency_info != undefined ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/img/tenants/system-emergency-information.png"
                          alt=""
                        />
                      </div>
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Emergency Info:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData2?.other_emergency_info}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData2({
                        ...editorData2,
                        other_emergency_info: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData2?.other_emergency_info}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <TenantNotifications
                data={tenantNotification}
                handleNotificationTrue={handleNotificationTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/tenants/system-notes-and-files.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Notes & Files:
                  </label>
                </div>
              </div>
              {formData.user_files != "" ? (
                <>
                  <div className="md:flex mt-4">
                    <div className="md:w-1/8">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/img/tenants/system-files.png"
                        alt=""
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                        Public Files:
                      </label>
                    </div>
                    {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="tenant-public-files"
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                      multiple={true}
                      hidden={true}
                      disabled
                      onChange={(e) => {
                        let files = e.target.files;
                        files.forEach((file, index) => {
                          Publicbase64Converter(file, index);
                        });
                        setPropertyPublicDocumentsWithUrl(getFilesAndURLs(e));
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                        formData?.user_files?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
                  </div>

                  {/* Gallery  */}

                  <div >
            {propertyPublicDocumentsWithUrl?.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-public-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_files = fileLists;
                            setPropertyPublicDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_files[fileIndex].title = title;
                                setPropertyPublicDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
                </>
              ) : null}
              {editorData?.user_notes != "" &&
              editorData?.user_notes != undefined ? (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/img/tenants/system-notes-red.png"
                          alt=""
                        />
                      </div>
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Public Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData?.user_notes}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData({
                        ...editorData,
                        user_notes: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData?.user_notes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <hr />
              {formData.user_staff_files != "" ? (
                <>
                  <div className="md:flex mt-4">
                    <div className="md:w-1/8">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/img/tenants/system-files-staff.png"
                        alt=""
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                        Staff Files:
                      </label>
                    </div>
                    {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="tenant-staff-files"
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                      multiple={true}
                      hidden={true}
                      disabled
                      onChange={(e) => {
                        let files = e.target.files;
                        files.forEach((file, index) => {
                          Staff64Converter(file, index);
                        });
                        setPropertyStaffDocumentsWithUrl(getFilesAndURLs(e));
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          formData?.user_staff_files?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
                  </div>

                  {/* Gallery  */}

                  <div >
            {propertyStaffDocumentsWithUrl?.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-staff-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyStaffDocumentsWithUrl?.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-staff-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_staff_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_staff_files = fileLists;
                            setPropertyStaffDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_staff_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_staff_files[fileIndex].title = title;
                                setPropertyStaffDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
                </>
              ) : null}
              {editorData2?.user_staff_notes != "" &&
              editorData2?.user_staff_notes != undefined ? (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/img/tenants/system-notes-staff.png"
                          alt=""
                        />
                      </div>
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Staff Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData2?.user_staff_notes}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData2({
                        ...editorData2,
                        user_staff_notes: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData2?.user_staff_notes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <hr />
              {formData.user_manager_files != "" ? (
                <>
                  <div className="md:flex mt-4">
                    <div className="md:w-1/8">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/img/tenants/system-files-property-manager.png"
                        alt=""
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                        Property Manager Files:
                      </label>
                    </div>
                    {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-manager-files"
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                      multiple={true}
                      hidden={true}
                      disabled
                      onChange={(e) => {
                        let files = e.target.files;
                        files.forEach((file, index) => {
                          Managerbase64Converter(file, index);
                        });
                        setPropertyManagerDocumentsWithUrl(getFilesAndURLs(e));
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          formData?.user_manager_files?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
                  </div>

                  {/* Gallery  */}

                  <div >
            {propertyManagerDocumentsWithUrl?.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-manager-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyManagerDocumentsWithUrl.map(
                        (fileObject, fileIndex) =>
                          fileComponent({
                            file: fileObject?.file,
                            title: fileObject?.title,
                            key: fileIndex,
                            inputFieldId: "property-manager-files",
                            trashAble: false,
                            editAble: false,
                            onTrashClick: () => {
                              const fileLists = [
                                ...formData?.property_manager_files,
                              ];
                              fileLists.splice(fileIndex, 1);
                              formData.property_manager_files = fileLists;
                              setPropertyManagerDocumentsWithUrl(fileLists);
                            },
                            onEditClick: () => {
                              const fileLists = [...formData?.property_manager_files];
                              const currentTitle = fileLists[fileIndex].title || "";
                            
                              Swal.fire({
                                title: "Edit File",
                                html: `
                                  <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                `,
                                focusConfirm: false,
                                preConfirm: () => {
                                  const title = document.getElementById('swal-input1').value;
                                  return title;
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  const title = result.value;
                                  fileLists[fileIndex].title = title;
                                  formData.property_manager_files[fileIndex].title = title;
                                  setPropertyManagerDocumentsWithUrl(fileLists);
                                }
                              });
                            }
                          })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
                </>
              ) : null}
              {editorData1?.user_manager_notes != "" &&
              editorData1?.user_manager_notes != undefined ? (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/img/tenants/system-notes-property-manager.png"
                          alt=""
                        />
                      </div>
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Property Manager Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData1?.user_manager_notes}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData1({
                        ...editorData1,
                        user_manager_notes: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData1?.user_manager_notes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <hr />
              {formData.user_owner_files != "" ? (
                <>
                  <div className="md:flex mt-4">
                    <div className="md:w-1/8">
                      <img
                        className="logo w-12 mr-5"
                        src="/assets/img/tenants/system-files-private.png"
                        alt=""
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                        Owner Files
                      </label>
                    </div>
                    {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                      multiple={true}
                      hidden={true}
                      disabled
                      onChange={(e) => {
                        let files = e.target.files;
                        files.forEach((file, index) => {
                          Ownerbase64Converter(file, index);
                        });
                        setPropertyOwnerDocumentsWithUrl(getFilesAndURLs(e));
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          formData?.user_owner_files?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
                  </div>

                  {/* Gallery  */}

                  <div >
            {propertyOwnerDocumentsWithUrl?.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: false,
                          editAble: false,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
                </>
              ) : null}
              {editorData3?.user_owner_notes != "" &&
              editorData3?.user_owner_notes != undefined ? (
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/8">
                        <img
                          className="logo w-12 mr-5"
                          src="/assets/img/tenants/system-notes-private.png"
                          alt=""
                        />
                      </div>
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Owner Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <CKEditor
                    initData={editorData3?.user_owner_notes}
                    config={{
                      readOnly:true
                    }}
                    onChange={(e) => {
                      setEditorData3({
                        ...editorData3,
                        user_owner_notes: e.editor.getData(),
                      });
                    }}
                  /> */}
                        <span>{editorData3?.user_owner_notes}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
              {/* {tenantInteraction?.map((row, index) => {
            const interactions = [...tenantInteraction];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="date"
                          type="date"
                          placeholder="Steve"
                          required={false}
                          value={stringToDate(row?.date)}
                          onChange={(e) => {
                            interactions[index].date = formatDate(e.target.value);
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-2">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Time:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="time"
                          type="time"
                          placeholder="Steve"
                          required={false}
                          value={stringToTime(row?.time)}
                          onChange={(e) => {
                            console.warn("time", e.target.value);
                            const tTime = formatAMPM(e.target.value);
                            interactions[index].time = tTime;
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction_type_id`}
                          value={row?.interaction_type_id}
                          onChange={(e) => {
                            interactions[index].interaction_type_id = parseInt(
                              e.target.value
                            );
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        >
                          {interactionType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {row?.interaction_type_id === 7 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.other_type_description}
                          onChange={(e) => {
                            interactions[index].other_type_description =
                              e.editor.getData();
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={row?.notes}
                        onChange={(e) => {
                          interactions[index].notes = e.editor.getData();
                          setTenantInteraction(interactions);
                          setFormData({
                            ...formData,
                            interactions: interactions,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:items-center md:justify-start md:w-6/12 mt-3">
                    <div className="md:w-2/3">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Files:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload Files
                        </span>
                        <input
                          id={`t-interation-files-${index}`}
                          type="file"
                          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            files.forEach((file) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  console.log(fileBase64);
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                                  // console.log("res", res?.data?.data?.file);
                                  // console.log("index",index);
                                  // let index = [index];
                                  // [...index].sort()

                                  tenantInteraction[index].files.push({
                                    file: res?.data?.data?.file,
                                  });
                                  setFormData({
                                    ...formData,
                                    interactions: interactions,
                                  });

                                  // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                                  // console.warn("base64converted files",formData)
                                } catch (err) {
                                  console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                            const fileLists = [...tenantInteractionFiles];
                            fileLists[index] = getFilesAndURLs(e);
                            setTenantInteractionFiles(fileLists);
                            // propertyMeasurements[index].files =
                            // 		getFilesAndURLs(e)
                            // setFormData({...formData, measurements : measurements})
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              formData?.interactions[index]?.files?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
                    {formData?.interactions[index]?.files?.length ? (
                      <div>
                        <section className="overflow-hidden w-full text-gray-700 mt-4">
                          <div className="container w-full px-3 m-auto">
                            <div
                              id={`p-tenant-documents-block-${index}`}
                              className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                            >
                              {formData?.interactions[index]?.files?.map(
                                (fileObject, fileIndex) =>
                                  fileComponent({
                                    file: fileObject?.file,
                                    key: fileIndex,
                                    inputFieldId: `t-tenant-files-${index}`,
                                    trashAble: false,
                                    onTrashClick: () => {
                                      const fileLists = [
                                        ...tenantInteractionFiles,
                                      ];
                                      fileLists[index].splice(fileIndex, 1);
                                      interactions[index].files = fileLists;
                                      setTenantInteractionFiles(fileLists);
                                      setFormData({
                                        ...formData,
                                        interactions: interactions,
                                      });
                                    },
                                  })
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })} */}
              {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                  <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setTenantInteraction([
                        ...tenantInteraction,
                        initialInteractionData,
                      ]);

                      setTenantInteractionFiles([
                        ...tenantInteractionFiles,
                        [],
                      ]);
                    }}
                  >
                    <i class="fa fa-plus"></i> Add Interaction
                  </button>
              </div>
            </div>
          </div> */}
              <Interactions
                data={tenantInteraction}
                handleInteractionTrue={handleInteractionTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <Keys
                data={tenantKey}
                handleKeyTrue={handleKeyTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <LoanableItems
                data={tenantItem}
                handleItemTrue={handleItemTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              <Vehicles
                data={tenantVehicle}
                handleVehicleTrue={handleVehicleTrue}
                hiddenData={true}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={8}>
              <Children
                data={tenantChildren}
                handleChildrenTrue={handleChildrenTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={9}>
              <Pets
                data={tenantPet}
                handlePetTrue={handlePetTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={10}>
              <OtherLivings
                data={tenantOtherLiving}
                handleOtherLivingTrue={handleOtherLivingTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={11}>
              <Employments
                data={tenantEmployment}
                handleEmploymentTrue={handleEmploymentTrue}
                hiddenData={true}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={12}>
              <References
                data={tenantReference}
                handleReferenceTrue={handleReferenceTrue}
                hiddenData={true}
                ownerSettings={ownerSettings}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={13}>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/tenants/tenant-moving-address.png"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Move-out Address:
                  </label>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  {formData.moveout_address1 != "" &&
                  formData.moveout_address1 != undefined ? (
                    <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 1:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    placeholder="Apt. suite no. 1"
                    required={false}
                    disabled
                    value={formData?.moveout_address1}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        moveout_address1: e.target.value,
                      });
                    }}
                  /> */}
                        <span>{formData?.moveout_address1}</span>
                      </div>
                    </div>
                  ) : null}
                  {formData.moveout_address2 != "" &&
                  formData.moveout_address2 != undefined ? (
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 2:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address2"
                    type="text"
                    placeholder="Jeff Street"
                    value={formData?.moveout_address2}
                    disabled
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        moveout_address2: e.target.value,
                      });
                    }}
                  /> */}
                        <span>{formData?.moveout_address2}</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex md:items-center w-full mt-3">
                    {formData.moveout_countryy != "" &&
                    formData.moveout_countryy != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_countryy"
                      type="text"
                      value={formData?.moveout_countryy}
                      disabled
                      placeholder="Albuquerque"
                      required={false}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_countryy: e.target.value,
                        });
                      }}
                    /> */}
                          <span>{formData?.moveout_countryy}</span>
                        </div>
                      </div>
                    ) : null}
                    {formData.moveout_state != "" &&
                    formData.moveout_state != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_state"
                      required={false}
                      value={formData?.moveout_state}
                      disabled
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_state: e.target.value,
                        });
                      }}
                    >
                      <option value={"New Mexico"}>New Mexico</option>
                      <option value={"Missouri"}>Missouri</option>
                      <option value={"Texas"}>Texas</option>
                    </select> */}
                          <span>{formData?.moveout_state}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    {formData.moveout_city != "" &&
                    formData.moveout_city != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_city"
                      type="text"
                      value={formData?.moveout_city}
                      disabled
                      placeholder="Albuquerque"
                      required={false}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_city: e.target.value,
                        });
                      }}
                    /> */}
                          <span>{formData?.moveout_city}</span>
                        </div>
                      </div>
                    ) : null}
                    {formData.moveout_zip_code != "" &&
                    formData.moveout_zip_code != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_zip_code"
                      type="text"
                      placeholder="90210"
                      required={false}
                      disabled
                      value={formData?.moveout_zip_code}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_zip_code: e.target.value,
                        });
                      }}
                    /> */}
                          <span>{formData?.moveout_zip_code}</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {formData.moveout_number != "" &&
                  formData.moveout_number != undefined ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="text"
                    required={false}
                    disabled
                    placeholder="123456789"
                    value={formData?.moveout_number}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        moveout_number: e.target.value,
                      });
                    }}
                  /> */}
                        <a
                          className="text-black no-underline"
                          href={`tel:${formData?.moveout_number}`}
                        >
                          {formData?.moveout_number_type == 8 ? formData?.moveout_number :formatPhoneNumber(formData?.moveout_number,ownerSettings?.phone_number_format)}
                        </a>
                      </div>
                      {/* <div className="md:w-1/6">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div> */}
                      <div className="md:w-1/5 pl-3 flex">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                        {/* <select
                    required={false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number_type"
                    disabled
                    value={formData?.moveout_number_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        moveout_number_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select> */}
                        <span>
                          {numberType?.map((row) => {
                            if (formData?.moveout_number_type == row?.id) {
                              return row?.type;
                            }
                          })}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {formData.moveout_email != "" &&
                  formData.moveout_email != undefined ? (
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_email"
                    type="email"
                    placeholder="jhon.doe@email.com"
                    disabled
                    value={formData?.moveout_email}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        moveout_email: e.target.value,
                      });
                    }}
                  /> */}
                        <a
                          className="text-black no-underline"
                          href={`mailto:${formData?.moveout_email}`}
                        >
                          {formData?.moveout_email}
                        </a>
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex md:items-center mt-4">
                    {formData.moveout_date != "" &&
                    formData.moveout_date != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                        <div className="md:w-2/4">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Move-Out Date:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_date"
                      type="date"
                      placeholder="Labrador"
                      required={false}
                      disabled
                      value={stringToDate(formData?.moveout_date)}
                      onChange={(e) => {
                        const date = formatDate(e.target.value);
                        setFormData({
                          ...formData,
                          moveout_date: date,
                        });
                      }}
                    /> */}
                          <span>{ShowFormatDate(formData?.moveout_date,ownerSettings?.date_format)}</span>
                        </div>
                      </div>
                    ) : null}
                    {formData.moveout_reason_id != "" &&
                    formData.moveout_reason_id != undefined ? (
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/4">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Move-out Reason:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          {/* <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_reason_id"
                      value={formData?.moveout_reason_id}
                      disabled
                      required={false}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_reason_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {moveoutReason.map((reason) => (
                        <option value={reason?.id}>{reason?.reason}</option>
                      ))}
                    </select> */}
                          {moveoutReason?.map((row) => {
                            if (formData?.moveout_reason_id == row?.id) {
                              return row?.reason;
                            }
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 mt-6">Submit</button> */}
            </TabPanel>
          </form>
        </>
      )}
    </>
  );
};
