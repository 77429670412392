import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import { Tooltip } from '../Loader/Tooltip';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';
import { DatePicker } from '../genericFunctions.js/DatePicker';

export const ProfileChildren = ({data,handleChildrenTrue,hiddenData,ownerSettings}) => {

    const initialChildrenData = {
        status: 0,
        firstname: "",
        middlename: "",
        lastname: "",
        gender_id: "",
        date_of_birth: "",
        public_note: "",
        private_note: "",
        pictures: [],
        files: [],
        private_files: [],
      };

      const picTypes = useAllowedPicMimeTypes();
    let fileApi = "/public/api/auth/upload-file";
    let imageApi = "/public/api/auth/upload-image";
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [tenantChildrens, setTenantChildrens] = useState([]);
    const [tenantChildren, setTenantChildren] = useState([]);
    const [tenantChildrenPicture, setTenantChildrenPicture] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    const [tenantChildrenFile, setTenantChildrenFile] = useState([]);
    const [tenantChildrenPrivateFile, setTenantChildrenPrivateFile] = useState(
      []
    );
    const [childrenAge, setChildrenAge] = useState([]);
    const [showForm,setShowForm] = useState(false);
    const [viewForm,setViewForm] = useState(false);
    const [editorData,setEditorData] = useState("");
    const [editorData1,setEditorData1] = useState("");
    const [editorData2,setEditorData2] = useState("");
    const [editorData3,setEditorData3] = useState("");
    const [genderPreference, setGenderPreference] = useState([]);

      console.log("@tenantChilders",tenantChildren);

    const myDivRef = useRef(null);

    const notes = ()=>{
      const data = [];
      data.push(tenantChildren);
      const array = [...data];
      // console.log('@array',array);
      // const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");
      // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
      array[0].public_note = editorData;
      array[0].private_note = editorData1;
      setTenantChildren(array);
    }

    const bulkData = ()=>{
      //   tenantChildren({...tenantChildren,notes:editorData1,other_type_description:editorData1}); 
      notes();
        const newDataArray  = [];
        newDataArray.push(tenantChildren);
        handleChildrenTrue(prevArray => [...prevArray, ...newDataArray]);
        setTenantChildrens(prevArray => [...prevArray, ...newDataArray]);
        setTenantChildren(initialChildrenData);
        setTenantChildrenPicture([]);
        setTenantChildrenFile([]);
        setTenantChildrenPrivateFile([]);
        // setEditorData('');
        setShowForm(false);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...tenantChildrens];
        array[indexEdit] = tenantChildren;
        // const cleanText = editorData?.replace(/<\/?[^>]+(>|$)/g, "");
        // const cleanText1 = new DOMParser().parseFromString(editorData1, 'text/html').body.textContent;
        array[indexEdit].public_note = editorData;
        array[indexEdit].private_note = editorData1;
        setTenantChildrens(array);
    
        handleChildrenTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantChildren(initialChildrenData);
        setTenantChildrenPicture([]);
        setTenantChildrenFile([]);
        setTenantChildrenPrivateFile([]);

      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setShowForm(true);
        setTenantChildren(tenantChildrens[index]);
        setTenantChildrenFile(tenantChildrens[index]?.files)
        setTenantChildrenPicture(tenantChildrens[index]?.pictures);
        setTenantChildrenPrivateFile(tenantChildrens[index]?.private_files);
        setEditorData(tenantChildrens[index]?.public_note)
        setEditorData1(tenantChildrens[index]?.private_note)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setTenantChildren(tenantChildrens[index]);
        setTenantChildrenFile(tenantChildrens[index]?.files)
        setTenantChildrenPicture(tenantChildrens[index]?.pictures);
        setTenantChildrenPrivateFile(tenantChildrens[index]?.private_files);
        setEditorData(tenantChildrens[index]?.public_note)
        setEditorData1(tenantChildrens[index]?.private_note)
        setViewForm(true);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...tenantChildrens];
        newArray.splice(index, 1);
        setTenantChildrens(newArray);
        handleChildrenTrue(newArray);
      }

      const formatDate = (date) => {
          date = new Date(date);
          const year = date.getFullYear();
          const day = ("0" + date.getDate()).slice(-2);
          const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
          const TotalDate = day + "-" + month + "-" + year;
          return TotalDate;
        };
        function stringToDate(dateString) {
          // console.warn("dateString",dateString)
          if (dateString === null || dateString === undefined || dateString >= 0)
            return;
          let parts = dateString.split("-");
          let day = parts[0];
          let month = parts[1]; // months are 0-based
          let year = parts[2];
          let date = year + "-" + month + "-" + day;
          // console.warn("UnformatDate",date)
          return date;
        }
        function ShowFormatDate(data, format) {
          if (data === null || data === undefined)
            return;
          const date = new Date(stringToDate(data));
          const day = date.getDate();
          const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
          const year = date.getFullYear();
        
          const formattedDay = day < 10 ? '0' + day : day;
          const formattedMonth = month < 10 ? '0' + month : month;
        
          format = format.replace('dd', formattedDay);
          format = format.replace('DD', formattedDay);
          format = format.replace('d', day);
          format = format.replace('D', day);
          format = format.replace('mm', formattedMonth);
          format = format.replace('MM', formattedMonth);
          format = format.replace('m', month);
          format = format.replace('M', month);
          format = format.replace('yyyy', year);
          format = format.replace('YYYY', year);
        
          return format;
        }
        const calculate_age = (dob1) => {
          var today = new Date();
          var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }
          // console.log(age_now);
          return age_now;
        };
        const calculate_days_until_next_birthday = (dob) => {
          const today = new Date();
          const birthDate = new Date(dob);
        
          // Check if the birthdate is today
          if (
            today.getDate() === birthDate.getDate() &&
            today.getMonth() === birthDate.getMonth()
          ) {
            return "Today";
          }
        
          // Calculate the next birthday
          const nextBirthday = new Date(
            today.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );
          if (today > nextBirthday) {
            nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
          }
        
          // Calculate the difference in days
          const timeDiff = nextBirthday.getTime() - today.getTime();
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
        
          return daysLeft+" "+ `${daysLeft == 1 ? "day" : "days"}`;
        };
        
        
        
        
        const getGenderPreferences = async () => {
            const response = await ApiRequest.get(
              "/public/api/auth/get-genders"
            );
            if (response.status == 200) {
              setGenderPreference(response?.data?.data?.genders);
            }
          };
          useEffect(() => {
            getGenderPreferences();
            setTenantChildren(initialChildrenData);
            setTenantChildrens(data);
            setOwnerSetting(ownerSettings)
          }, [])
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
    const columns = [
        {
          name: "Picture",
          selector: (data) => (
              <>
              <img className='logo w-1/2' src={`${imgUrl}/${data.pictures[0]?.picture}`} alt="" />
              </>
          ),
          sortable: true,
        },
        {
          name: "First Name",
          selector: (data) => data.firstname,
          sortable: true,
        },
        {
          name: "Last Name",
          selector: (data) => data.lastname,
          sortable: true,
        },
        {
          name: "Date Of Birth",
          selector: (data) => data.date_of_birth != "" ? ShowFormatDate(data.date_of_birth,ownerSetting?.date_format):"",
          sortable: true,
        },
        {
          name: "Age",
          selector: (data) => {
            const dateOfBirth = stringToDate(data?.date_of_birth);
            const age = calculate_age(dateOfBirth);
          
            if (isNaN(age)) {
              return "";
            } else {
              return (<span className='flex flex-column'><span className='text-center'>{age}</span>{calculate_days_until_next_birthday(dateOfBirth)}</span>);
            }
          },
          sortable: true,
        },
        {
          name: "Gender",
          selector: (data) => genderPreference.map((row)=>{
              if(data.gender_id == row?.id)
              {
                  return row?.gender
              }
          }),
          sortable: true,
        },
        {
          name: "Status",
          selector: (data) => data.status == 0 ? <span className='status-inactive uppercase'>Inactive</span> : data.status == 1 ? <span className='status-active uppercase'>Active</span>:null,
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                 {hiddenData != true ? 
                 (<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewChildrenForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0 mx-3"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                {/* <button type="button" class="bg-transparent border-none md:w-1/6 p-0"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button> */}
               
               
                 </div>):(<>
                  <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewChildrenForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                 </>)}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : tenantChildrens,
      };

      const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
      
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
      
        doc.setFontSize(15);
      
        const title = "Tenant Children";
        const headers = [
          ["First Name", "Last Name", "Date Of Birth", "Age", "Gender", "Status"]
        ];
      
        const data = tenantChildrens.map((elt) => [
          elt.firstname,
          elt.lastname,
          elt.date_of_birth,
          calculate_age(stringToDate(elt.date_of_birth)),
          genderPreference.find((row) => elt.gender_id == row.id)?.gender || '',
          elt.status == 0 ? "Inactive" : elt.status == 1 ? "Active" : null
        ]);
      
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Children.pdf");
      };
      const exportCSV = () => {
        // Get data from data table
        const TotalData = tenantChildrens.map((elt) => [
          elt.firstname,
          elt.lastname,
          elt.date_of_birth,
          calculate_age(stringToDate(elt.date_of_birth)),
          genderPreference.find((row) => elt.gender_id == row.id)?.gender || '',
          elt.status == 0 ? "Inactive" : elt.status == 1 ? "Active" : null
        ]);
        // Convert the data to a CSV string
    
        const headers = ["First Name","Last Name","Date Of Birth",'Age','Gender','Status'];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Children.csv");
        link.click();
      };
      const imageComponent = ({
          image = {},
          key = 0,
          src = "",
          inputFieldId = "",
          trashAble = false,
          onTrashClick = () => {},
          onEditClick = () => {},
          ...restProps
        }) => {
          const confirmEdit = (image) => {
            Swal.fire({
              title: 'Are you sure you want to edit this image?',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel'
            }).then((result) => {
              if (result.isConfirmed) {
                // Modify the image object here
                const editedImage = { ...image, title: 'New Title' };
                onEditClick(editedImage);
              }
            });
          };
        
          return image ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
            >
              <div className="relative h-fit w-full">
                <img
                  title={image?.title}
                  key={key}
                  alt="property image"
                  className="border block object-contain w-80 h-auto rounded-lg"
                  src={src}
                />
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
                >
                  {trashAble ? (
                    <img
                      title="Remove image"
                      type="button"
                      className="logo w-5 mx-auto my-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
        
                        if (fileInputField) {
                          fileInputField.files.forEach((file) => {
                            if (file !== image) {
                              dataTransfer.items.add(file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                  {/* <button
                    type="button"
                    title="Edit image"
                    className="logo w-5 mx-auto my-1"
                    onClick={() => confirmEdit(image)}
                  >
                    <img src="/assets/img/system-edit.png" alt="edit" />
                  </button> */}
                </div>
              </div>
            </div>
          ) : (
            <img {...restProps} />
          );
        };
        const fileComponent = ({
          file = null,
          key = 0,
          inputFieldId = "",
          trashAble = false,
          onTrashClick = () => {},
        }) =>
          file ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
            >
              <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
                <div
                  title={file}
                  key={key}
                  className="block min-w-[600rem] px-2"
                >
                  <a href={`${imgUrl}/${file}`} target="_blank">{file}</a>
                </div>
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
                >
                  {trashAble ? (
                    <img
                      title="Remove file"
                      className="logo w-5 my-auto mx-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
      
                        if (fileInputField) {
                          fileInputField.files.forEach((input_file) => {
                            if (input_file !== file) {
                              dataTransfer.items.add(input_file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null;
         
  return (
    <>
         <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewChildrenForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewChildrenFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Children
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantChildren([]);
                    setTenantChildrenFile([]);
                    setTenantChildrenPicture([]);
                    setTenantChildrenPrivateFile([]);
                    setEditorData(null);
                    setEditorData1(null);
                    setEditorData2(null);
                    setEditorData3(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ?
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex">
                  <div className="w-4/12 mr-3">
                      {tenantChildren?.pictures?.length > 0 ?
                      (<>
                      <img className="logo w-full" src={`${imgUrl}/${tenantChildren?.pictures[0]?.picture}`} alt="" />
                      </>)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                      </div>
                    <div className="w-8/12">
                      <div className="flex font-bold">
                        {tenantChildren?.firstname != "" ? <h4 className='mr-2 capitalize'>{tenantChildren?.firstname}</h4> : null}
                        {tenantChildren?.middlename != "" ? <h4 className='mr-2 capitalize'>{tenantChildren?.middlename}</h4> : null}
                        {tenantChildren?.lastname != "" ? <h4 className='capitalize'>{tenantChildren?.lastname}</h4> : null}
                      </div>
                      {tenantChildren?.status != "" ? 
                        <div className='flex mt-2'> 
                            {tenantChildren?.status == 1 ? <span className='status-active uppercase'>Active</span> : tenantChildren?.status == 0 ? <span className='status-inactive uppercase'>Inactive</span> :null}  
                        </div> 
                      :null}
                      <div className="flex mt-2">
                        {tenantChildren?.gender_id != "" ?
                        <>
                        <span>
                        {genderPreference?.map((gender)=>{
                          if(tenantChildren?.gender_id == gender?.id)
                          {
                            return gender?.gender
                          }
                        })}</span> {tenantChildren?.date_of_birth != "" ? <span className='mx-1'>|</span> :null}</> :null}  {tenantChildren?.date_of_birth != "" ?<><span>{ShowFormatDate(tenantChildren.date_of_birth,ownerSetting?.date_format)}</span> <span className='ml-2'>( {calculate_days_until_next_birthday(stringToDate(tenantChildren?.date_of_birth))} )</span></>:null}
                      </div>
                    </div>
                  </div>
                  {tenantChildren?.public_note != "" ?
                  <>
                    <hr />
                    <h6>Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantChildren?.public_note }} />
                    </div>

                  </>:null}
                  {tenantChildren?.files?.length > 0 ?
                  <>
                    <hr />
                    <h6>Files:</h6>
                    {tenantChildren?.files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                  {tenantChildren?.private_note != "" ?
                  <>
                    <hr />
                    <h6>Private Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantChildren?.private_note }} />
                    </div>

                  </>:null}
                  {tenantChildren?.private_files?.length > 0 ?
                  <>
                    <hr />
                    <h6>Private Files:</h6>
                    {tenantChildren?.private_files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                  {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          setViewForm(false)
                        }}>
                          <i className='fa fa-remove'></i>Close
                 
                        </button>
                        </div>
                        </div>
                        </div> */}
                </div>
              </div>
              :null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantChildren([]);
                                setTenantChildrenFile([]);
                                setTenantChildrenPicture([]);
                                setTenantChildrenPrivateFile([]);
                                setEditorData(null);
                                setEditorData1(null);
                                setEditorData2(null);
                                setEditorData3(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-kids.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Children:
              </label>
              <Tooltip data={"This is an area to add information regarding children living with the Tenant."}/>
            </div>
          </div>

          {showForm == false? 
          <div className="flex flex-row-reverse justify-between items-center mt-3">
            <div className="flex justify-between items-center gap-4">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true)
                }}
              >
                <i class="fa fa-plus"></i> Add Child
              </button>
            </div>
          </div>
          :null}

              {showForm == true ?
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  {/* <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-status`}
                          required={false}
                          value={tenantChildren?.status}
                          onChange={(e) => {
                            setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                          }}
                        >
                          <option value="">Select Status</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-firstname`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={tenantChildren?.firstname}
                          onChange={(e) => {
                            setTenantChildren({...tenantChildren,firstname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Middle Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-middlename-index`}
                          type="text"
                          placeholder="Ben"
                          required={false}
                          value={tenantChildren?.middlename}
                          onChange={(e) => {
                            setTenantChildren({...tenantChildren,middlename:e.target.value})
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-lastname`}
                          type="text"
                          placeholder="Deo"
                          required={false}
                          value={tenantChildren?.lastname}
                          onChange={(e) => {
                            setTenantChildren({...tenantChildren,lastname:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Gender:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-gender-id`}
                          value={tenantChildren?.gender_id}
                          onChange={(e) => {
                            setTenantChildren({...tenantChildren,gender_id:parseInt(e.target.value)});
                          }}
                        >
                          <option value=" ">Select Gender</option>
                          {genderPreference.map((row) => (
                            <option value={row?.id}>{row?.gender}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <DatePicker
                          value={stringToDate(tenantChildren?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            const FullAge = [...childrenAge];
                            const TotalAge = calculate_age(e);
                            FullAge[0] = TotalAge;
                            console.warn("FullAge", FullAge);
                            setChildrenAge(FullAge);
                            setTenantChildren({...tenantChildren,date_of_birth:date});
                          }}
                        />
                      </div>
                      {childrenAge[0] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {childrenAge[0]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Child Pictures:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id={`child-pictures`}
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantChildrenPicture?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantChildrenPicture.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1 + tenantChildrenPicture.length,
                                    url:imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...tenantChildrenPicture];
                                    pictureLists = [...pictureLists, ...newImages];
                                    setTenantChildrenPicture(pictureLists);
                                    let tenantCopy = [...tenantChildrenPicture];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        order_number: picObj.order_number,
                                        is_cover: picObj.is_cover,
                                      })
                                    );
                                    setTenantChildren({ ...tenantChildren, pictures: tenantCopy?.pictures });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantChildrenPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div >
              {tenantChildrenPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantChildrenPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantChildrenPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantChildren.pictures = pictureLists;
                                setTenantChildrenPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantChildrenPicture({
                                  ...tenantChildren,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if(tenantChildren?.status != 0 && tenantChildren?.status != 1)
                            {
                              Toast.fire({
                                title:"Please Select the Status",
                                icon:"warning"
                              })
                            }
                            else{
                            editSpacesData();
                            }
                          } else {
                            if(tenantChildren?.status != 0 && tenantChildren?.status != 1)
                            {
                              Toast.fire({
                                title:"Please Select the Status",
                                icon:"warning"
                              })
                            }
                            else{
                              
                              bulkData();
                            }
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>
              :null}
              
           
          <hr />
          {tenantChildrens?.length > 0?<>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantChildrens}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  )
}
