import { createSlice } from "@reduxjs/toolkit";
import { adminPrefix } from "./adminThunk";
const adminSlice = createSlice({
  name: "adminPrefix",
  initialState: {
    status: null,
    Prefixes: {},

  },
  extraReducers: {
    [adminPrefix.pending]: (state) => {
      state.status = "loading";
    },
    [adminPrefix.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.Prefixes = payload;
    },
    [adminPrefix.rejected]: (state, _error) => {
      state.status = "failed";
      state.Prefixes = {};
    },

  

    
  },
});

export default adminSlice.reducer;
