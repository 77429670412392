import { CKEditor } from 'ckeditor4-react'
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ApiRequest from '../../utility/http';
import imgUrl from '../ImageUrl';

export const InfoContactForm = ({data,handleContactTrue,hiddenData,ownerSettings}) => {
    const initialContactData = {
      user_prefix_id:'',
        firstname:'',
        lastname:'',
        title:'',
        number1:'',
        number1_type:'',
        number2:'',
        number2_type:'',
        number3:'',
        number3_type:'',
        email:''
    };
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [showForm, setShowForm] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    const [contacts,setContacts] = useState([]);
    const [contact,setContact] = useState([]);
    const [ownerSetting,setOwnerSetting] = useState([]);
    const [prefix,setPrefix] = useState([]);
    const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
    console.warn('@CONTACTS',contacts);
    const getPrefix = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-name-prefixes')

      if(response.status == 200)
      {
        setPrefix(response?.data?.data?.prefixes)
      }
    }
    function removeNonNumeric(inputString) {
      var numericString = inputString.replace(/\D/g, "");
      return numericString;
    }
    const formatPhoneNumber = (number, format) => {
      const digitsOnly = number?.replace(/\D/g, '') || '';
      let formattedNumber = '';
      let digitIndex = 0;
    
      for (let i = 0; i < format?.length; i++) {
        if (format[i] === '#') {
          formattedNumber += digitsOnly[digitIndex] || '';
          digitIndex++;
        } else if (format[i] === '_') {
          formattedNumber += ' ';
        } else {
          formattedNumber += format[i];
        }
      }
    
      return formattedNumber;
    };
    useEffect(()=>{
      getNumberType();
      getPrefix();
      setContacts(data);
      setContact(initialContactData)
      setOwnerSetting(ownerSettings)
    },[])
    const bulkData = ()=>{
        // setPropertyMaterial({...propertyMaterial,notes:editorData}); 
        const newDataArray  = [];
        newDataArray.push(contact);
        handleContactTrue(prevArray => [...prevArray, ...newDataArray]);
        setContacts(prevArray => [...prevArray, ...newDataArray]);
        setContact(initialContactData);
        setShowForm(false);
        // setBulkSpaces("");
      }
    const editSpacesData = () =>{
        const array = [...contacts];
        array[indexEdit] = contact;
        setContacts(array);
    
        handleContactTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setContact(initialContactData);
      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setContact(contacts[index]);
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false);
        setContact(contacts[index]);
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
          setViewForm(false);
        const newArray = [...contacts];
        newArray.splice(index, 1);
        setContacts(newArray);
        setContact(initialContactData);
        handleContactTrue(newArray);
      }
    const columns = [
        {
          name: "Last Name",
          selector: (data) => data.lastname,
          sortable: true,
        },
        {
          name: "First Name",
          selector: (data) => data.firstname,
          sortable: true,
        },
        {
          name: "Title",
          selector: (data) => data.title,
          sortable: true,
        },
        {
          name: "Number 1",
          selector: (data) => data.number1 != "" ? data?.number1_type == 8 ? data?.number1 : formatPhoneNumber(data.number1,ownerSetting?.phone_number_format):"",
          sortable: true,
        },
        {
          name: "Email",
          selector: (data) => data.email,
          sortable: true,
        },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {/* <div className="flex"> */}
                  {hiddenData != true ? (
                    <div className="flex">
                      <button
                        type="button"
                        class="bg-transparent border-none md:w-1/6 p-0"
                        data-bs-target={`#ViewHoaContactForm`}
                        data-bs-toggle='modal'
                        data-index={index}
                        onClick={(e) => {
                          viewData(index);
                        }}
                      >
                        <img src="/assets/svgs/system-view.svg" />
                      </button>
                      <button
                        type="button"
                        class="bg-transparent border-none md:w-1/6 p-0 mx-3"
                        data-index={index}
                        onClick={(e) => {
                          indexFind(index);
                        }}
                      >
                        <img src="/assets/img/system-edit.png" />
                      </button>
                      <button
                        type="button"
                        class="bg-transparent border-none md:w-1/6 p-0"
                        data-index={index}
                        onClick={(e) => {
                          deleteData(index);
                        }}
                      >
                        <img src="/assets/svgs/system-trashcan.svg" />
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        type="button"
                        class="bg-transparent border-none md:w-1/12 p-0"
                        data-index={index}
                        data-bs-target={`#ViewHoaContactForm`}
                        data-bs-toggle='modal'
                        onClick={(e) => {
                          viewData(index);
                        }}
                      >
                        
                        <img src="/assets/svgs/system-view.svg" />
                      </button>
                    </>
                  )}
                {/* </div> */}
  
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : contacts,
      };
  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewHoaContactForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewHoaContactFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-l w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Contacts
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setContact([]);
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ?
      <>
            <div className="md:flex flex-column md:items-center w-full m-auto">
              <div className="w-full">
                <div className="flex items-center justify-center font-bold">
                    {contact?.user_prefix_id != "" ?<span className='mr-2'>{prefix?.map((row)=>{
                        if(contact?.user_prefix_id == row?.id)
                        {
                          return row?.prefix
                        }
                      })}</span>:null}
                      {contact?.firstname != "" ?<span className='mr-2 capitalize'>{contact?.firstname}</span>:null}
                      {contact?.lastname != "" ?<span className='mr-2 capitalize'>{contact?.lastname}</span>:null}
                  </div>
                  {contact?.title != "" ?<div className="flex items-center justify-center mt-2">
                      <span>{contact?.title}</span>
                  </div>:null}
                  {contact?.email != "" ?<div className="flex items-center justify-center mt-2">
                      <a className='text-black no-underline' href={`mailto:${contact?.email}`}>{contact?.email}</a>
                  </div>:null}
                  {contact?.number1 != ""?<div className="flex items-center justify-center mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(contact?.number1_type == row?.id)
                        {
                          return (<img className="logo w-6" src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold text-black no-underline ml-2" href={`tel:${contact?.number1}`}>{contact?.number1_type == 8 ? contact?.number1: formatPhoneNumber(contact?.number1,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                      {contact?.number2 != ""?<div className="flex items-center justify-center mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(contact?.number2_type == row?.id)
                        {
                          return (<img className="logo w-6" src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold text-black no-underline ml-2" href={`tel:${contact?.number2}`}>{contact?.number2_type == 8 ? contact?.number2: formatPhoneNumber(contact?.number2,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                      {contact?.number3 != ""?<div className="flex items-center justify-center mt-2">
                        <span className="font-bold">{numberType?.map((row)=>{
                        if(contact?.number3_type == row?.id)
                        {
                          return (<img className="logo w-6" src={`${imgUrl}/${row?.icon}`}/>)
                        }
                      })}</span>
                        <a className="font-bold text-black no-underline ml-2" href={`tel:${contact?.number3}`}>{contact?.number3_type == 8 ? contact?.number3: formatPhoneNumber(contact?.number3,ownerSetting?.phone_number_format)}</a>
                      </div>:null}
                
              </div>
            </div>
            </>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setContact([])
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    {showForm == false ? 
    <>
    <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  hidden={hiddenData}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Contact
                </button>
              </div>
            </div>
          </div>
          
          </>
          :null}
      {showForm == true ?
      <>
      <div className="md:flex flex-column md:items-center w-full m-auto">
        <div className="w-full">
          <div className="md:flex md:items-center w-full mt-3">
            <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
              <div className="md:w-1/4">
                <label className="block capitalize text-gray-700 font-bold mb-2">
                  Prefix:
                </label>
              </div>
              <div className="md:w-3/4">
                <select
                  className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="prefix"
                  type="text"
                  value={contact?.user_prefix_id}
                  placeholder="Albuquerque"
                  onChange={(e) => {
                    setContact({ ...contact, user_prefix_id: parseInt(e.target.value) });
                  }}
                >
                  <option value={null}>Select Prefix</option>
                  {prefix?.map((row)=>(
                    <option value={row?.id}>{row?.prefix}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="md:flex md:items-center w-full md:w-4/12 mx-2 mb-6">
              <div className="md:w-2/4">
                <label className="block capitalize text-gray-700 font-bold mb-2">
                  First Name:
                </label>
              </div>
              <div className="md:w-3/4">
                <input
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="firstname"
                  placeholder='John'
                  type="text"
                  value={contact?.firstname}
                  onChange={(e) => {
                    setContact({ ...contact, firstname: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
              <div className="md:w-2/4">
                <label className="block capitalize text-gray-700 font-bold mb-2">
                  Last Name:
                </label>
              </div>
              <div className="md:w-3/4">
                <input
                  className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="lastname"
                  type="text"
                  value={contact?.lastname}
                  placeholder="Deo"
                  onChange={(e) => {
                    setContact({
                      ...contact,
                      lastname: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Title:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="title"
                    type="text"
                    value={contact?.title}
                    placeholder="Title"
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
          <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {contact?.number1_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-1"
                    type="tel"
                    value={contact?.number1}
                    maxLength={ownerSetting?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number1: e.target.value,
                      });
                    }}
                  />
                  :<input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="num-1"
                  type="tel"
                  value={formatPhoneNumber(contact?.number1,ownerSetting?.phone_number_format)}
                  maxLength={ownerSetting?.phone_number_format?.length}
                  placeholder="(999) 999-9999"
                  onChange={(e) => {
                    setContact({
                      ...contact,
                      number1: removeNonNumeric(e.target.value),
                    });
                  }}
                />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-1-type"
                    value={contact?.number1_type}
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number1_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                {contact?.number2_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-2"
                    type="tel"
                    value={contact?.number2}
                    maxLength={ownerSetting?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number2: e.target.value,
                      });
                    }}
                  />
                  :<input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="num-2"
                  type="tel"
                  value={formatPhoneNumber(contact?.number2,ownerSetting?.phone_number_format)}
                  maxLength={ownerSetting?.phone_number_format?.length}
                  placeholder="(999) 999-9999"
                  onChange={(e) => {
                    setContact({
                      ...contact,
                      number2: removeNonNumeric(e.target.value),
                    });
                  }}
                />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-2-type"
                    value={contact?.number2_type}
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number2_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                {contact?.number3_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-3"
                    type="tel"
                    value={contact?.number3}
                    maxLength={ownerSetting?.phone_number_format?.length}
                    placeholder="(999) 999-9999"
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number3: e.target.value,
                      });
                    }}
                  />
                  :<input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="num-3"
                  type="tel"
                  value={formatPhoneNumber(contact?.number3,ownerSetting?.phone_number_format)}
                  maxLength={ownerSetting?.phone_number_format?.length}
                  placeholder="(999) 999-9999"
                  onChange={(e) => {
                    setContact({
                      ...contact,
                      number3: removeNonNumeric(e.target.value),
                    });
                  }}
                />}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="num-3-type"
                    value={contact?.number3_type}
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        number3_type: e.target.value,
                      });
                    }}
                  >
                    <option value=" ">Select Type</option>
                        {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    type="email"
                    value={contact?.email}
                    placeholder="john.doe@email.com"
                    onChange={(e) => {
                      setContact({
                        ...contact,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
        </div>
      </div>
      <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  onClick={() => {
                    if (indexEdit !== undefined) {
                      editSpacesData();
                    } else {
                      bulkData();
                    }
                  }}
                >
                  {indexEdit !== undefined ? (
                    <><i className='fa fa-edit'></i>Update Contact</>
                  ) : (
                    <><i className='fa fa-save'></i>Save Contact</>
                  )}
                </button>
              </div>
            </div>
          </div>
      </>:null}
      
      {/* <hr /> */}
          {/* <div className="flex flex-row-reverse mt-3">
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-12 mr-5" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div> */}
      {contacts?.length > 0 ?<DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={contacts}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection='asc'
        />
        </DataTableExtensions>:null}
    <hr />
    </>
    
  );
};
