import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../genericFunctions.js/DatePicker';
import { CKEditor } from 'ckeditor4-react';
import ApiRequest from '../../../utility/http';
import CurrencyInput from '../../genericFunctions.js/CurrencyInput';
import Swal from 'sweetalert2';
import imgUrl from '../../ImageUrl';
import { useParams } from 'react-router-dom';
import useAlerts from '../../../utility/alerts';
import Loading from '../../Loader/Loading';
import useAllowedPicMimeTypes from '../../genericFunctions.js/picMimeType';
import useAllowedMimeTypes from '../../genericFunctions.js/mimeType';

export const Material = (
  {initialData}
) => {
  const picTypes = useAllowedPicMimeTypes();
  const fileTypes = useAllowedMimeTypes();
  const [formData,setFormData] = useState();
  console.log("formData",formData);
  const [materialTypes,setMaterialTypes] = useState([]);
  const [materialLocations,setMaterialLocations] = useState([]);
  const [materialBrands,setMaterialBrands] = useState([]);
  const [materialIdentifiers,setMaterialIdentifiers] = useState([]);
  const [materialFinishes,setMaterialFinishes] = useState([]);
  const [materialUnitSoldBys,setMaterialUnitSoldBys] = useState([]);
  const [materialCoverageUnits,setMaterialCoverageUnits] = useState([]);
  const [propertyMaterialPictures,setPropertyMaterialPictures] = useState([]);
  const [propertyMaterialFiles,setPropertyMaterialFiles] = useState([]);
  const [editorData,setEditorData] = useState([]);
  const [result,setResult] = useState([]);
  const [ownerSettings,setOwnerSettings] = useState([]);
  const [loading,setLoading] = useState(false);

  const [newMaterialType, setNewMaterialType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialLocation, setNewMaterialLocation] = useState({
    id: null,
    location: "",
  });
  const [newMeasuremnetType, setNewMeasuremnetType] = useState({
    id: null,
    type: "",
  });
  const [newMaterialBrand, setNewMaterialBrand] = useState({
    id: null,
    brand: "",
  });
  const [newMaterialIdentifier, setNewMaterialIdentifier] = useState({
    id: null,
    identifier: "",
  });
  const [newMaterialFinish, setNewMaterialFinish] = useState({
    id: null,
    finish: "",
  });

  const PropertyID = localStorage.getItem('Property ID');
  const formElement = document.getElementById("add-material-form");

  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";


  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
    ...restProps
  }) => {
    const confirmEdit = (image) => {
      Swal.fire({
        title: 'Are you sure you want to edit this image?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          // Modify the image object here
          const editedImage = { ...image, title: 'New Title' };
          onEditClick(editedImage);
        }
      });
    };
  
    return image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="property image"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={src}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                type="button"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
            {/* <button
              type="button"
              title="Edit image"
              className="logo w-5 mx-auto my-1"
              onClick={() => confirmEdit(image)}
            >
              <img src="/assets/img/system-edit.png" alt="edit" />
            </button> */}
          </div>
        </div>
      </div>
    ) : (
      <img {...restProps} />
    );
  };
  const fileComponent = ({
    file = null,
    title = null,
    key = 0,
    inputFieldId = "",
    trashAble = false,
    editAble = false,
    onTrashClick = () => {},
    onEditClick = () => {},
  }) =>
    file ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
      >
        <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
          <div title={title} key={key} className="block min-w-[600rem] px-2">
            <a href={`${imgUrl}/${file}`} target="_blank">
              {title}
            </a>
          </div>
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
          >
            
            {editAble ? (
              <img
                title="Edit file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/system-edit.png"
                onClick={(e) => {
                  onEditClick();
                }}
                alt=""
              />
            ) : null}
            {trashAble ? (
              <img
                title="Remove file"
                className="logo w-5 my-auto mx-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();
  
                  if (fileInputField) {
                    fileInputField.files.forEach((input_file) => {
                      if (input_file !== file) {
                        dataTransfer.items.add(input_file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
   



  const DateCalculator = (date) => {
    const currentDate = new Date();
    const cDate = new Date(date);
    const diffInMilliseconds = currentDate - cDate;
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30.44;
    const diffInYears = diffInMonths / 12;
    const years = Math.floor(diffInYears);
    const months = Math.floor((diffInYears - years) * 12);
    const days = Math.floor(((diffInMonths - months) * 30.44) % 30.44);
    const StringYear = years === 1 ? "year" : "years";
    const StringMonth = months === 1 ? "month" : "months";
    const StringDay = days === 1 ? "day" : "days";
    var result;
    if (days === 0 && years !== 0 && months !== 0) {
      result = years + " " + StringYear + ", " + months + " " + StringMonth;
    } else if (years === 0 && months !== 0 && days !== 0) {
      result = months + " " + StringMonth + ", " + days + " " + StringDay;
    } else if (months === 0 && years !== 0 && days !== 0) {
      result = years + " " + StringYear + ", " + days + " " + StringDay;
    } else if (days === 0 && months === 0 && years !== 0) {
      result = years + " " + StringYear;
    } else if (days === 0 && years === 0 && months !== 0) {
      result = months + " " + StringMonth;
    } else if (years === 0 && months === 0 && days !== 0) {
      result = days + " " + StringDay;
    } else if (years !== 0 || months !== 0 || days !== 0) {
      result =
        years +
        " " +
        StringYear +
        ", " +
        months +
        " " +
        StringMonth +
        ", " +
        days +
        " " +
        StringDay;
    } else {
      result = "";
    }
    return result;
  };

  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    // console.warn("UnformatDate",date)
    return date;
  }
  function ShowFormatDate(data, format) {
    if (data === null || data === undefined)
      return;
    const date = new Date(stringToDate(data));
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const year = date.getFullYear();
  
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    format = format.replace('dd', formattedDay);
    format = format.replace('DD', formattedDay);
    format = format.replace('d', day);
    format = format.replace('D', day);
    format = format.replace('mm', formattedMonth);
    format = format.replace('MM', formattedMonth);
    format = format.replace('m', month);
    format = format.replace('M', month);
    format = format.replace('yyyy', year);
    format = format.replace('YYYY', year);
  
    return format;
  }
  function removeCurrencyAndCommas(str) {
    // Remove currency symbols ($, €, £, ¥, etc.)
    str = str.replace(/[$€£¥]/g, '');
    
    // Remove commas
    str = str.replace(/,/g, '');
    
    // Return the modified string
    return str;
  }
  function convertCurrencyToFormat(currency, number) {
    let formattedCurrency = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
    }).format(number);
  
    return formattedCurrency;
  }


const Alert = useAlerts();
const {id} = useParams();

const addEditForm = async(e)=>{
  e.preventDefault();
    try {
  const addPayload = {
    name: formData?.name,
    material_status_id: formData?.material_status_id,
    material_type_id: formData?.material_type_id,
    material_brand_id: formData?.material_brand_id,
    color: formData?.color,
    material_finish_id: formData?.material_finish_id,
    material_identifier_id: formData?.material_identifier_id,
    material_vendor_id: formData?.material_vendor_id,
    website: formData?.website,
    material_unit_sold_by_id: formData?.material_unit_sold_by_id,
    coverage_per_unit: formData?.coverage_per_unit,
    material_coverage_unit_id: formData?.material_coverage_unit_id,
    last_purchased: formData?.last_purchased,
    units_purchased: formData?.units_purchased,
    price_per_unit: formData?.price_per_unit,
    coverage_purchased: formData?.coverage_purchased,
    material_on_hand: formData?.material_on_hand,
    coverage_on_hand: formData?.coverage_on_hand,
    property_location_id: formData?.property_location_id,
    notes:editorData,
    pictures: formData?.pictures,
    material_files: formData?.files
  };
  const editPayload = {
    id:id,
    name: formData?.name,
    material_status_id: formData?.material_status_id,
    material_type_id: formData?.material_type_id,
    material_brand_id: formData?.material_brand_id,
    color: formData?.color,
    material_finish_id: formData?.material_finish_id,
    material_identifier_id: formData?.material_identifier_id,
    material_vendor_id: formData?.material_vendor_id,
    website: formData?.website,
    material_unit_sold_by_id: formData?.material_unit_sold_by_id,
    coverage_per_unit: formData?.coverage_per_unit,
    material_coverage_unit_id: formData?.material_coverage_unit_id,
    last_purchased: formData?.last_purchased,
    units_purchased: formData?.units_purchased,
    price_per_unit: formData?.price_per_unit,
    coverage_purchased: formData?.coverage_purchased,
    material_on_hand: formData?.material_on_hand,
    coverage_on_hand: formData?.coverage_on_hand,
    property_location_id: formData?.property_location_id,
    notes:editorData,
    pictures: formData?.pictures,
    material_files: formData?.files
  };

  const data = id !== null && id !== undefined && id !== "" ? editPayload : addPayload;
  const response = await ApiRequest.post('public/api/auth/material',
    data
  )
  if(response.status == 200)
  {
    Alert.addSuccess("Property Added Successfully");
  }
  }
  catch(err)
  {
    Alert.addFailure("Something Went Wrong");
  }
};

const getForm = async()=>
{
  setLoading(true);
  try{
    const response = await ApiRequest.get(`public/api/auth/get-material?material_id=${id}`);
    if(response.status == 200)
    {
      setFormData({
        ...formData,
        name:response?.data?.data[0]?.name,
        material_status_id: response?.data?.data[0]?.material_status_id,
        material_type_id: response?.data?.data[0]?.material_type_id,
        material_brand_id: response?.data?.data[0]?.material_brand_id,
        color: response?.data?.data[0]?.color,
        material_finish_id: response?.data?.data[0]?.material_finish_id,
        material_identifier_id: response?.data?.data[0]?.material_identifier_id,
        material_vendor_id: response?.data?.data[0]?.material_vendor_id,
        website: response?.data?.data[0]?.website,
        material_unit_sold_by_id: response?.data?.data[0]?.material_unit_sold_by_id,
        coverage_per_unit: response?.data?.data[0]?.coverage_per_unit,
        material_coverage_unit_id: response?.data?.data[0]?.material_coverage_unit_id,
        last_purchased: response?.data?.data[0]?.last_purchased,
        units_purchased: response?.data?.data[0]?.units_purchased,
        price_per_unit: response?.data?.data[0]?.price_per_unit,
        coverage_purchased: response?.data?.data[0]?.coverage_purchased,
        material_on_hand: response?.data?.data[0]?.material_on_hand,
        coverage_on_hand: response?.data?.data[0]?.coverage_on_hand,
        property_location_id: response?.data?.data[0]?.property_location_id,
        pictures: response?.data?.data[0]?.pictures,
        material_files: response?.data?.data[0]?.files
      });
      setPropertyMaterialPictures(response?.data?.data[0]?.pictures);
      setPropertyMaterialFiles(response?.data?.data[0]?.files);
      setEditorData(response?.data?.data[0]?.notes)
      setLoading(false);
    }
  }
  catch(err){
    console.log(err);
  }
}





  const getMaterialTypes = async () => {
        const response = await ApiRequest.get("public/api/auth/get-material-types");
        if (response.status == 200) {
          setMaterialTypes(response.data?.data);
        }
      };
      const getMaterialLocations = async () => {
        const response = await ApiRequest.get(
          `/public/api/auth/get-properties-locations?property_id=${PropertyID}`
        );
        if (response.status == 200) {
          setMaterialLocations(response?.data?.data);
        }
      };
      const getMaterialBrands = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-brands"
        );
        if (response.status == 200) {
          setMaterialBrands(response.data?.data);
        }
      };
    
      const getMaterialIdentifiers = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-identifiers"
        );
        if (response.status == 200) {
          setMaterialIdentifiers(response.data?.data);
        }
      };
      const getMaterialFinishes = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-finishes"
        );
        if (response.status == 200) {
          setMaterialFinishes(response.data?.data);
        }
      };
      const getMaterialUnitSoldBys = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-units-sold-bys"
        );
        if (response.status == 200) {
          setMaterialUnitSoldBys(response.data?.data);
        }
      };
      const getMaterialCoverageUnits = async () => {
        const response = await ApiRequest.get(
          "public/api/auth/get-material-coverage-units"
        );
        if (response.status == 200) {
          setMaterialCoverageUnits(response.data?.data?.units);
        }
      };
    
      const addEditMaterialType = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-type",
          payload
        );
        if (response.status == 200) {
          getMaterialTypes();
        }
      };
    
      const addEditMaterialLocation = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/properties-location",
          payload
        );
        if (response.status == 200) {
          getMaterialLocations();
        }
      };
    
      const addEditMaterialBrand = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-brand",
          payload
        );
        if (response.status == 200) {
          getMaterialBrands();
        }
      };
    
      const addEditMaterialIdentifier = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-identifier",
          payload
        );
        if (response.status == 200) {
          getMaterialIdentifiers();
        }
      };
      const addEditMaterialFinish = async (payload) => {
        const response = await ApiRequest.post(
          "public/api/auth/material-finish",
          payload
        );
        if (response.status == 200) {
          getMaterialFinishes();
        }
      };
      const getOwnerSettings = async()=>{
        const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
        if(response?.status == 200)
        {
          setOwnerSettings(response?.data?.data?.owners_settings);
        }
      }

  useEffect(() => {
    setFormData(initialData);
    getOwnerSettings();
    getMaterialTypes();
    getMaterialLocations();
    getMaterialIdentifiers();
    getMaterialFinishes();
    getMaterialBrands();
    getMaterialUnitSoldBys();
    getMaterialCoverageUnits();
    
  }, [])
  useEffect(()=>{
    if (id !== undefined) {
      getForm();
    }
  },[id])
  
  return (
    <>
    {loading ? (<Loading/>) :(
      <>
      <form id='add-material-form'
    onSubmit={addEditForm}
    >

    
    {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-materials.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Property Materials:
              </label>
            </div>
          </div> */}


                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Name:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-name`}
                            type="text"
                            value={formData?.name}
                            placeholder="Main Paint"
                            onChange={(e) => {
                              setFormData({...formData,name:e.target.value});
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-type`}
                          value={formData?.material_type_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_type_id:value});
                          }}
                        >
                          <option value="">Select Type</option>
                          {materialTypes?.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `a-material-new-type-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-new-type-field`}
                          type="text"
                          value={newMaterialType.type}
                          hidden={true}
                          placeholder="Wall"
                          onInput={(e) => {
                            setNewMaterialType({
                              ...newMaterialType,
                              type: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialType.id
                                ? newMaterialType
                                : { type: newMaterialType.type };
                              addEditMaterialType(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Brand:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-brand`}
                          value={formData?.material_brand_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_brand_id:value});
                          }}
                        >
                          <option value="">Select Brand</option>
                          {materialBrands?.map((brand) => (
                            <option value={brand?.id}>{brand?.brand}</option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `a-material-new-brand-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-new-brand-field`}
                          type="text"
                          value={newMaterialBrand.brand}
                          hidden={true}
                          placeholder="Brand"
                          onInput={(e) => {
                            setNewMaterialBrand({
                              ...newMaterialBrand,
                              brand: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialBrand.id
                                ? newMaterialBrand
                                : { brand: newMaterialBrand.brand };
                              addEditMaterialBrand(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Color/Pattern:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-color`}
                            type="text"
                            value={formData?.color}
                            placeholder="Black"
                            onChange={(e) => {
                              
                              setFormData({...formData,color:e.target.value});
                              
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Finish:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-finish`}
                          value={
                            formData?.material_finish_id
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_finish_id:value});
                          }}
                        >
                          <option value="">Select Finish</option>
                          {materialFinishes.map((row) => (
                            <option value={row?.id}>{row?.finish}</option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `a-material-new-finish-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-new-finish-field`}
                          type="text"
                          value={newMaterialFinish.finish}
                          placeholder="Finish"
                          hidden={true}
                          onInput={(e) => {
                            setNewMaterialFinish({
                              ...newMaterialFinish,
                              finish: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialFinish.id
                                ? newMaterialFinish
                                : { finish: newMaterialFinish.finish };
                              addEditMaterialFinish(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Identifier:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-identifier`}
                          value={
                            formData?.material_identifier_id
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_identifier_id:value});
                          }}
                        >
                          <option value="">Select Identifier</option>
                          {materialIdentifiers.map((identifier) => (
                            <option value={identifier?.id}>
                              {identifier?.identifier}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `a-material-new-identifier-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-new-identifier-field`}
                          type="text"
                          value={newMaterialIdentifier.identifier}
                          hidden={true}
                          placeholder="Identifier"
                          onInput={(e) => {
                            setNewMaterialIdentifier({
                              ...newMaterialIdentifier,
                              identifier: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialIdentifier.id
                                ? newMaterialIdentifier
                                : {
                                    identifier:
                                      newMaterialIdentifier.identifier,
                                  };
                              addEditMaterialIdentifier(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Vendor:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-vendor`}
                          value={formData?.material_vendor_id}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFormData({...formData,material_vendor_id:value});
                          }}
                        >
                          <option value="">Select Vendor</option>
                          <option value={"vendor_1"}>vendor 1</option>
                          <option value={"vendor_2"}>vendor 2</option>
                          <option value={"vendor_3"}>vendor 3</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Material Website:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-website`}
                            type="url"
                            value={formData?.website}
                            placeholder="www.example.com"
                            onChange={(e) => {
                              
                              setFormData({...formData,website:e.target.value});
                              
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Unit Sold by:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-unit-sold-by`}
                          value={formData?.material_unit_sold_by_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_unit_sold_by_id:value});
                          }}
                        >
                          <option value="">Select Unit</option>
                          {materialUnitSoldBys?.map((unit)=>(
                            <option value={unit?.id}>{unit?.sold_by}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="w-1/3 md:items-center flex">
                      <div className="md:w-4/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Coverage Per Unit:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-coverage-per-unit`}
                          value={formData?.coverage_per_unit}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,coverage_per_unit:value});
                          }}
                        />
                      </div>
                      </div>
                      <div className="w-1/12"></div>
                      <div className="w-1/2 md:items-center flex">
                      <div className="md:w-4/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Coverage Area Measurement:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-coverage-unit-id`}
                          value={formData?.material_coverage_unit_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,material_coverage_unit_id:value});
                          }}
                        >
                            <option value="">Select Area Measurement</option>
                            {materialCoverageUnits?.map((unit)=>(
                              <option value={unit.id}>{unit.unit}</option>
                            ))}
                            </select>
                      </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Purchased:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <DatePicker
                          value={stringToDate(formData?.last_purchased)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            const Result = [...result];
                            const TotalDate = DateCalculator(e.target.value);
                            console.warn("TotalDate", TotalDate);
                            Result[0] = TotalDate;
                            console.warn("RESult", Result);
                            setResult(Result);
                            formData.last_purchased = date;
                            // setDateTaken(e.target.value);
                            setFormData({...formData,last_purchased: date});
                          }}
                        />
                      </div>
                      {formData?.last_purchased ? (
                        <div className="md:w-2/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            {result[0] !== "" ? (
                              <span className="text-gray-600">
                                {result[0]}
                              </span>
                            ) : null}
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Units Purchased:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="material-unit-purchased"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.units_purchased != null ? formData?.units_purchased : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, units_purchased: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, units_purchased: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Price Per Unit:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                        <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="material-price-per-unit"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={formData?.price_per_unit != null ? formData?.price_per_unit : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setFormData({ ...formData, price_per_unit: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setFormData({ ...formData, price_per_unit: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-3/7 md:w-1/2">
                        <div className="md:w-1/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Coverage Purchased:
                          </label>
                        </div>
                        <div className="md:w-1/5"></div>
                        <div className="md:w-3/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-coverage-purchased`}
                          type="text"
                          value={((formData?.units_purchased || 0) * (formData?.coverage_per_unit || 0)) + " " + (materialCoverageUnits?.find(unit => formData?.material_coverage_unit_id === unit.id)?.unit || "")}
                          disabled
                          placeholder="Coverage Purchased"
                          // onChange={(e)=>{
                          //   setFormData({...formData,coverage_purchased:((formData?.units_purchased || 0) * (formData?.coverage_per_unit || 0))});
                          // }}
                        />


                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-1/2 md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Material On Hand:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-material_on_hand`}
                            type="text"
                            value={formData?.material_on_hand}
                            placeholder="Material On Hand"
                            onChange={(e) => {
                              
                              setFormData({...formData,material_on_hand:parseInt(e.target.value)});
                              
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-4">
                      <div className="md:flex md:items-center w-1/2 md:w-1/2">
                        <div className="md:w-2/5">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Coverage On Hand:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`p-material-coverage_on_hand`}
                            type="text"
                            value={((formData?.material_on_hand || 0) * (formData?.coverage_per_unit || 0)) + " " + (materialCoverageUnits?.find(unit => formData?.material_coverage_unit_id === unit.id)?.unit || "")}
                            placeholder="Coverage On Hand"
                            // onChange={(e) => {
                              
                            //   setFormData({...formData,coverage_on_hand:e.target.value});
                              
                            // }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Storage Location:
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`p-material-identifier`}
                          value={
                            formData?.property_location_id
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFormData({...formData,property_location_id:value});
                          }}
                        >
                          <option value="">Select Location</option>
                          {materialLocations?.map((location) => (
                            <option value={location?.id}>
                              {location?.location}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="p-3">
                        <label className="cursor-pointer">
                          <span
                            className="text-sky-500"
                            onClick={() => {
                              const inputField = document.getElementById(
                                `a-material-new-storage-field`
                              );
                              formElement
                                .querySelectorAll("input")
                                .forEach((field) => {
                                  if (
                                    inputField != field &&
                                    field.id.includes("material-new")
                                  )
                                    field.hidden = true;
                                });
                              inputField.hidden = !inputField.hidden;
                            }}
                          >
                            +
                          </span>
                        </label>
                      </div>
                      <div className="md:w-1/5">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`a-material-new-storage-field`}
                          type="text"
                          value={newMaterialLocation.location}
                          placeholder="Location"
                          hidden={true}
                          onInput={(e) => {
                            setNewMaterialLocation({
                              ...newMaterialLocation,
                              location: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const payload = newMaterialLocation.id
                                ? newMaterialLocation
                                : { location: newMaterialLocation.location };
                              addEditMaterialLocation(payload);
                              e.target.hidden = true;
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Property Material Pictures */}

                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Material Pictures:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload pictures
                      </span>
                      <input
                        accept={picTypes}
                        id={`a-material-pictures`}
                        type="file"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          let newImages = [];
                          let maxOrderNumber = 0;
                        
                          // Find the highest order number in the current images
                          propertyMaterialPictures?.forEach((imageObject) => {
                            if (imageObject.order_number > maxOrderNumber) {
                              maxOrderNumber = imageObject.order_number;
                            }
                          });
                        
                          // Calculate the total number of images (existing images + new images)
                          const totalImages = propertyMaterialPictures.length + files.length;
                        
                          // Loop through each file and convert to base64
                          files.forEach((file, i) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const imgBase64 = reader.result;
                              try {
                                const res = await ApiRequest.post(imageApi, {
                                  image: imgBase64,
                                });
                        
                                // Add the new image to the array with a unique order number
                                newImages.push({
                                  picture: res?.data?.data?.image,
                                  order_number: maxOrderNumber + i + 1 + propertyMaterialPictures.length,
                                  url:imgBase64
                                });
                        
                                // If all new images have been processed, update the state
                                if (newImages.length === files.length) {
                                  let pictureLists = [...propertyMaterialPictures];
                                  pictureLists = [...pictureLists, ...newImages];
                                  setPropertyMaterialPictures(pictureLists);
                                  let materialCopy = [...propertyMaterialPictures];
                                  materialCopy.pictures = pictureLists.map(
                                    (picObj) => ({
                                      picture: picObj.picture,
                                      order_number: picObj.order_number,
                                    })
                                  );
                                  setFormData({ ...formData, pictures: materialCopy?.pictures });
                                }
                              } catch (err) {
                                // console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                          });
                        }}
                        
                        
                        
                        
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const imagesCount =
                            propertyMaterialPictures?.length || 0;
                          return imagesCount > 1
                            ? imagesCount + " Pictures"
                            : imagesCount == 1
                            ? imagesCount + " Picture"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>

                {/* Gallery  */}

                <div >
              {propertyMaterialPictures?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`a-material-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMaterialPictures?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `a-material-pictures`,
                              trashAble: true,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...propertyMaterialPictures,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                formData.pictures = pictureLists;
                                setPropertyMaterialPictures(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setFormData({
                                  ...formData,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>


                {/* Material Files */}

                <div className="md:flex md:items-center w-[90%] m-auto mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Material Files:
                    </label>
                  </div>
                  <div className="md:w-1/5">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id={`a-material-files`}
                        type="file"
                        accept={fileTypes}
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          let newImages = [];
                          let maxOrderNumber = 0;
                        
                          // Find the highest order number in the current images
                          propertyMaterialFiles?.forEach((imageObject) => {
                            if (imageObject.order_number > maxOrderNumber) {
                              maxOrderNumber = imageObject.order_number;
                            }
                          });
                        
                          // Calculate the total number of images (existing images + new images)
                          const totalImages = propertyMaterialFiles.length + files.length;
                        
                          // Loop through each file and convert to base64
                          files.forEach((file, i) => {
                            const reader = new FileReader();
                            reader.addEventListener("load", async () => {
                              const fileBase64 = reader.result;
                              try {
                                const res = await ApiRequest.post(fileApi, {
                                  file: fileBase64,
                                });
                        
                                // Add the new image to the array with a unique order number
                                newImages.push({
                                  file: res?.data?.data?.file,
                                  title: res?.data?.data?.file,
                                  url:fileBase64
                                });
                        
                                // If all new images have been processed, update the state
                                if (newImages.length === files.length) {
                                  let fileLists = [...propertyMaterialFiles];
                                  fileLists = [...fileLists, ...newImages];
                                  setPropertyMaterialFiles(fileLists);
                                  let materialCopy = [...propertyMaterialFiles];
                                  materialCopy.files = fileLists.map(
                                    (picObj) => ({
                                      file: picObj.file,
                                      title: picObj.title,
                                    })
                                  );
                                  setFormData({ ...formData, files: materialCopy?.files });
                                }
                              } catch (err) {
                                // console.log(err);
                              }
                            });
                            reader.readAsDataURL(file);
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                      <span className="text-gray-600">
                        {(() => {
                          const filesCount =
                            propertyMaterialFiles?.length || 0;
                          return filesCount > 1
                            ? filesCount + " Documents"
                            : filesCount == 1
                            ? filesCount + " Document"
                            : null;
                        }).call()}
                      </span>
                    </label>
                  </div>
                </div>

                {/* Gallery  */}

                <div >
              {propertyMaterialFiles?.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`a-measurement-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {propertyMaterialFiles?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `a-measurement-files`,
                              trashAble: true,
                              editAble: true,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...propertyMaterialFiles,
                                ];
                                fileLists.splice(fileIndex, 1);
                                formData.files = fileLists;
                                setPropertyMaterialFiles(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...propertyMaterialFiles];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    formData.files[fileIndex].title = title;
                                    setPropertyMaterialFiles(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>

                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="w-full">
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Material Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={editorData}
                          onChange={(e) => {
                            setEditorData(e.editor.getData());
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="submit"
                  className="btn3"
                //   onClick={() => {
                //     if (indexEdit !== undefined) {
                //       editSpacesData();
                //     } else {
                //       bulkData();
                //     }
                //   }}
                >
                    <><i className='fa fa-save'></i>Save</>
                  
                </button>
              </div>
            </div>
          </div>
          </form>
      </>
    )}
          </>
  )
}
