import { AdminDashboardPage } from "../pages/admin/AdminDashboardPage";
import { StaffDashboardPage } from "../pages/staff/StaffDashboardPage";
import { SuperAdminDashboardPage } from "../pages/super_admin/SuperAdminDashboardPage";
import { OwnerDashboardPage } from "../pages/owner/OwnerDashboardPage";
import { TenantDashboardPage } from "../pages/tenant/TenantDashboardPage";
import { LoginPage } from "../pages/auth/LoginPage";
import { RegisterPage } from "../pages/auth/registerPage";
import PeerboardCommunity from "../pages/portal/PeerboardCommunity";
import { PortalDashboardPage } from "../pages/portal/PortalDashboardPage";
import { AddPropertyPage } from "../pages/properties/AddPropertyPage";
import { PropertyTable } from "../pages/property/PropertyTable";
import { ShowPrefixSuffix } from "../pages/admin/show-prefix-suffix";
import { EditPrefixSuffix } from "../pages/admin/Edit-prefix-suffix-page";
import { Leads } from "../pages/admin/Leads";
import { Ledger } from "../pages/admin/ledger";
import { Invoice } from "../pages/admin/Invoice";
import { PaymentHistory } from "../pages/admin/PaymentHistory";
import { PurchaseOrders } from "../pages/admin/PurchaseOrders";
import { Budget } from "../pages/admin/Budget";
import { Account } from "../pages/admin/Account";
import { SupportTicket } from "../pages/admin/SupportTicket";
import { AdminMarketing } from "../pages/admin/AdminMarketing";
import { Vendors } from "../pages/admin/Vendors";
import { SystemMessage } from "../pages/admin/SystemMessage";
import { SystemFiles } from "../pages/admin/SystemFiles";
import { SystemForumGuides } from "../pages/admin/SystemForumGuides";
import { AdminCms } from "../pages/admin/AdminCms";
import { Plans } from "../pages/admin/Plans";
import { Reports } from "../pages/admin/Reports";
import { RoadMap } from "../pages/admin/RoadMap";
import { ChangeLog } from "../pages/admin/ChangeLog";
import { AdminFooter } from "../pages/admin/AdminFooter";
import { PlanStorage } from "../pages/admin/PlanStorage";
import { SystemBackup } from "../pages/admin/SystemBackup";
import { SystemKnowledgeBase } from "../pages/admin/SystemKnowledgeBase";
import { ContentWriters } from "../pages/admin/ContentWriters";
import { SupportIssue } from "../pages/admin/SupportIssue";
import { SupportGetScreen } from "../pages/admin/SupportGetScreen";
import { WiserNotify } from "../pages/admin/WiserNotify";
import { SiteSecurity } from "../pages/admin/SiteSecurity";
import { SystemIntegrations } from "../pages/admin/SystemIntegrations";
import { SystemLock } from "../pages/admin/SystemLock";
import { SystemSetting } from "../pages/admin/SystemSetting";
import { PaymentGateways } from "../pages/admin/PaymentGateways";
import { Languages } from "../pages/admin/Languages";
import { UserOnwer } from "../pages/admin/UserOnwer";
import { UserTenant } from "../pages/admin/UserTenant";
import { Testing } from "../pages/admin/Testing";
import { Activity } from "../pages/admin/Activity";
import { SystemForum } from "../pages/admin/SystemForum";
import { TenantInfo } from "../pages/admin/TenantInfo";
import { NameEdit } from "../pages/admin/NameEdit";
import { ParkingEdit } from "../pages/admin/ParkingEdit";
import { Permissions } from "../pages/permission/Permissions";
import { MoveOutReason } from "../pages/admin/MoveoutReasonsEdit";
import { EthnicitiesEdit } from "../pages/admin/EthnicitiesEdit";
import { WebsiteSettings } from "../pages/admin/WebsiteSettings";
import { TestPage } from "../pages/Test";
import { ViewPrefixSuffix } from "../pages/admin/ViewPrefixSuffix";
import { UserRoles } from "../pages/UserRoles/UserRoles";
import { EmailTemplates } from "../pages/admin/EmailTemplates";
import { EditableList } from "../pages/admin/EditableList";
import { TaxFeeSettings } from "../pages/admin/TaxFeeSettings";
import { EditPropertyPage } from "../pages/properties/EditPropertyPage";
import { Unites } from "../pages/properties/Unites";
import { ShowUnites } from "../pages/owner/ShowUnits";
import { EditUnites } from "../pages/owner/EditUnits";
import {EditUnitPage} from "../pages/properties/EditUnitPage"
import { GeneralApplicationPage } from "../pages/owner/GeneralApplicationPage";
import { AddApplicationPage } from "../pages/owner/AddApplicationPage";
import { AddTenantPage } from "../pages/owner/AddTenantPage";
import { EditTenantPage } from "../pages/owner/EditTenantPage";
import { ProcessingPage } from "../pages/auth/ProcessingPage";
// import { Tree } from "../pages/owner/Tree";
import MySignaturePad from "../pages/owner/SignaturePad";
import { ShowPropertyPage } from "../pages/properties/ShowPropertyPage";
import { ShowUnitPage } from "../pages/owner/ShowUnitPage";
import { TenantTablePage } from "../pages/owner/TenantTablePage";
import UnitTable from "../pages/owner/UnitTable";
import { ShowTenantPage } from "../pages/owner/ShowTenantPage";
import { FolderTemplatePage } from "../pages/owner/FolderTemplatePage";
import { AddFolderTemplatePage } from "../pages/owner/AddFolderTemplatePage";
import { ApplicationTablePage } from "../pages/owner/ApplicationTablePage";
import { EditApplicationPage } from "../pages/owner/EditApplicationPage";
import { ApplicationPage } from "../pages/owner/ApplicationPage";
import { FinalTable } from "../components/FinalTable";
import { AddCommunicationPage } from "../pages/owner/AddCommunicationPage";
import { CommunicationTablePage } from "../pages/owner/CommunicationTablePage";
import { CalendarPage } from "../pages/calendar/CalendarPage";
import { EditableListPage } from "../pages/owner/EditableListPage";
import { AddProspectPage } from "../pages/owner/AddProspectPage";
import { EditProspectPage } from "../pages/owner/EditProspectPage";
import { ShowProspectPage } from "../pages/owner/ShowProspectPage";
import TableApp from "../components/application/TableApp";
import { TenantProfilePage } from "../pages/owner/TenantProfilePage";
import { ImpersonationPage } from "../components/tenant/ImpersonationPage";
import { TenantHistory } from "../components/tenant/TenantHistory";
import { TenantHistoryPage } from "../pages/owner/TenantHistoryPage";
import { UnderDevelopmentPage } from "../pages/UnderDevelopment/UnderDevelopmentPage";
import { AddMaterialPage } from "../pages/assets/material/AddMaterialPage";
import { AddPlanPage } from "../pages/plans/AddPlanPage";
import { PlanTablePage } from "../pages/plans/PlanTablePage";
import { MaterialTablePage } from "../pages/assets/material/MaterialTablePage";
import { CompanyRegister } from "../pages/auth/CompanyRegister";
import { EditMaterialPage } from "../pages/assets/material/EditMaterialPage";
import { UnitHistoryPage } from "../pages/owner/UnitHistoryPage";
import { CommunicationBannerPage } from "../pages/owner/CommunicationBannerPage";
import { AddLeasePage } from "../pages/lease/AddLeasePage";
import { AddLeaseEvictionPage } from "../pages/lease/AddLeaseEvictionPage";
import { LeaseTablePage } from "../pages/lease/LeaseTablePage";
import { LeaseEvictionTablePage } from "../pages/lease/LeaseEvictionTablePage";
import { AddLeaseMovingPage } from "../pages/lease/AddLeaseMovingPage";
import { LeaseMovingTablePage } from "../pages/lease/LeaseMovingTablePage";
import { AddLeaseRentPage } from "../pages/lease/AddLeaseRentPage";
import { AddLeaseMovingPropertyUnitPage } from "../pages/lease/AddLeaseMovingPropertyUnitPage";
import { AddLeaseMovingTenantPage } from "../pages/lease/AddLeaseMovingTenantPage";
import { AddCustomerPage } from "../pages/customer/AddCustomerPage";
import { CustomerTablePage } from "../pages/customer/CustomerTablePage";
import { VendorProfilePage } from "../pages/vendor/VendorProfilePage";
import { AddVendorPage } from "../pages/vendor/AddVendorPage";
import { VendorTablePage } from "../pages/vendor/VendorTablePage";
import { AddStaffPage } from "../pages/staff/AddStaffPage";
import { StaffAdminPage } from "../pages/staff/StaffAdminPage";
import { StaffTablePage } from "../pages/staff/StaffTablePage";
import { StaffDepartmentTablePage } from "../pages/staff/StaffDepartmentTablePage";
import { AddStaffDepartmentPage } from "../pages/staff/AddStaffDepartmentPage";
import { StaffRoleTablePage } from "../pages/staff/StaffRoleTablePage";
import { AddStaffRolePage } from "../pages/staff/AddStaffRolePage";
import { CalendarSchedulerPage } from "../pages/calendar/CalendarSchedulerPage";
import { FinanceAccountPage } from "../pages/finance/FinanceAccountPage";
import { FinanceAccountTablePage } from "../pages/finance/FinanceAccountTablePage";
import { FinanceTransferMoneyPage } from "../pages/finance/FinanceTransferMoneyPage";
import { FinanceInvoicePage } from "../pages/finance/FinanceInvoicePage";
import { FinanceInvoiceTablePage } from "../pages/finance/FinanceInvoiceTablePage";
import { FinanceBudgetTablePage } from "../pages/finance/FinanceBudgetTablePage";
import { FinanceBudgetAccountPage } from "../pages/finance/FinanceBudgetAccountPage";
import { FinanceLineItemPage } from "../pages/finance/FinanceLineItemPage";
import { FinanceDepositTablePage } from "../pages/finance/FinanceDepositTablePage";
import { FinancePayInvoicesPage } from "../pages/finance/FinancePayInvoicesPage";
import { FinanceLedgerTablePage } from "../pages/finance/FinanceLedgerTablePage";
import { FinanceLedgerPage } from "../pages/finance/FinanceLedgerPage";
import { FinancePaymentHistoryTablePage } from "../pages/finance/FinancePaymentHistoryTablePage";
import { FinancePaymentHistoryPage } from "../pages/finance/FinancePaymentHistoryPage";
import { FinancePurchaseOrderTablePage } from "../pages/finance/FinancePurchaseOrderTablePage";
import { FinancePurchaseOrderPage } from "../pages/finance/FinancePurchaseOrderPage";
import { FinanceInvoiceTemplate } from "../components/finance/FinanceInvoiceTemplate";
import { FinanceReceiptTemplate } from "../components/finance/FinanceReceiptTemplate";
import { AddIssueTrackerPage } from "../pages/issuetracker/AddIssueTrackerPage";
import { EditIssueTrackerPage } from "../pages/issuetracker/EditIssueTrackerPage";
import { IssueTrackerTablePage } from "../pages/issuetracker/IssueTrackerTablePage";
import { MessageTemplate } from "../components/changelog/MessageTemplate";
import { Changelog } from "../components/changelog/Changelog";
import { ChangeLogPage } from "../pages/changelog/ChangelogPage";
import { AddSafetyPage } from "../pages/safety/AddSafetyPage";
import { SafetyTablePage } from "../pages/safety/SafetyTablePage";
import { AddLeadPage } from "../pages/lead/AddLeadPage";
import { EditLeadPage } from "../pages/lead/EditLeadPage";
import { LeadTablePage } from "../pages/lead/LeadTablePage";
import { KnowledgeBasePage } from "../pages/knowledgebase/KnowledgeBasePage";
import { KnowledgeBaseSettingPage } from "../pages/knowledgebase/KnowledgeBaseSettingPage";
import { DirectoryListingPage } from "../pages/directory/DirectoryListingPage";
import { AddForumSettingPage } from "../pages/forum/AddForumSettingPage";
import { AddForumPage } from "../pages/forum/AddForumPage";
// import Tree from "../tree/tree";
// import { EthnicitiesEdit } from "../components/admin/EthnicitiesEdit";
// import { Permissions } from "../pages/permission/permissions";
// import {Permissions} from "../pages/permissions/Permissions"

export const mainRoutes = {
  register: {
    path: "/register",
    restricted: false,
    passCookies: true,
    component: RegisterPage,
    roles:"All"
  },
  propertyTable: {
    path: "/property",
    restricted: true,
    passCookies: true,
    component: PropertyTable,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
 
  Unites: {
    path: "/add-units",
    restricted: true,
    passCookies: true,
    component: Unites,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  UnitHistory: {
    path: "/unit-history",
    restricted: true,
    passCookies: true,
    component: UnitHistoryPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditableLists: {
    path: "/editableList",
    restricted: true,
    passCookies: true,
    component: EditableListPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  UnderDevelopment:{
    path: "/under-development",
    restricted: true,
    passCookies: true,
    component: UnderDevelopmentPage,
    roles:["owner","admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
 

  // EditUnites:{
  //   path: "/edit-unites",
  //   restricted: true,
  //   passCookies: true,
  //   component: EditUnites,
  // },
  UnitTable : {
    path: "/unit",
    restricted: true,
    passCookies: true,
    component: ShowUnites,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FolderTemplatePage : {
    path: "/tree-view",
    restricted: true,
    passCookies: true,
    component: FolderTemplatePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddFolderTemplatePage : {
    path: "/add-template",
    restricted: true,
    passCookies: true,
    component: AddFolderTemplatePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  editUnit: {
    path: "/units/edit-unit/:id",
    restricted: true,
    passCookies: true,
    component: EditUnitPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  TableApp: {
    path: "table-app",
    restricted: true,
    passCookies: true,
    component: TableApp,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ShowUnit: {
    path: "/units/show-unit/:id",
    restricted: true,
    passCookies: true,
    component: ShowUnitPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  GeneralApplication:{
    path:"/general-applications",
    restricted:true,
    passCookies:true,
    component: GeneralApplicationPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ApplicationTable:{
    path:"/application",
    restricted:true,
    passCookies:true,
    component: ApplicationTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddApplication:{
    path:"/add-application",
    restricted:true,
    passCookies:true,
    component: ApplicationPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Application:{
    path:"/application/:slug",
    restricted:true,
    passCookies:true,
    component: AddApplicationPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinalTable:{
    path:"/finaltable",
    restricted:true,
    passCookies:true,
    component: FinalTable,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditApplication:{
    path:"/edit-application/:id",
    restricted:true,
    passCookies:true,
    component: EditApplicationPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  TenantTable:{
    path:"/tenant",
    restricted:true,
    passCookies:true,
    component: TenantTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Materials:{
    path:"/assets/materials",
    restricted:true,
    passCookies:true,
    component: MaterialTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddMaterial:{
    path:"/assets/add-material",
    restricted:true,
    passCookies:true,
    component: AddMaterialPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditMaterial:{
    path:"/assets/edit-material/:id",
    restricted:true,
    passCookies:true,
    component: EditMaterialPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Impersonate:{
    path:"/impersonation",
    restricted:true,
    passCookies:true,
    component: ImpersonationPage,
    roles:["owner",'tenant',"All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  
  TenantProfile:{
    path:"/tenant-profile",
    restricted:true,
    passCookies:true,
    component: TenantProfilePage,
    roles:["tenant","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  AddTenant:{
    path:"/users-tenant",
    restricted:true,
    passCookies:true,
    component: AddTenantPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  TenantHistory:{
    path:"/tenant-history/:id",
    restricted:true,
    passCookies:true,
    component: TenantHistoryPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditTenant:{
    path:"/edit-users-tenant/:id",
    restricted:true,
    passCookies:true,
    component: EditTenantPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ShowTenant:{
    path:"/show-users-tenant/:id",
    restricted:true,
    passCookies:true,
    component: ShowTenantPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddProspect:{
    path:"/users-prospect",
    restricted:true,
    passCookies:true,
    component: AddProspectPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditProspect:{
    path:"/edit-users-prospect/:id",
    restricted:true,
    passCookies:true,
    component: EditProspectPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ShowProspect:{
    path:"/show-users-prospect/:id",
    restricted:true,
    passCookies:true,
    component: ShowProspectPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddCommunication:{
    path:"/add-communication",
    restricted:true,
    passCookies:true,
    component: AddCommunicationPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  CommunicationBanner:{
    path:"/add-banner",
    restricted:true,
    passCookies:true,
    component: CommunicationBannerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Lease:{
    path:"/lease",
    restricted:true,
    passCookies:true,
    component: LeaseTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  LeaseEviction:{
    path:"/lease-eviction",
    restricted:true,
    passCookies:true,
    component: LeaseEvictionTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  LeaseMoving:{
    path:"/lease-moving",
    restricted:true,
    passCookies:true,
    component: LeaseMovingTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  LeaseRent:{
    path:"/lease-rent",
    restricted:true,
    passCookies:true,
    component: AddLeaseRentPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLease:{
    path:"/add-lease",
    restricted:true,
    passCookies:true,
    component: AddLeasePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLeaseEviction:{
    path:"/add-lease-eviction",
    restricted:true,
    passCookies:true,
    component: AddLeaseEvictionPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLeaseMoving:{
    path:"/add-lease-moving",
    restricted:true,
    passCookies:true,
    component: AddLeaseMovingPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLeaseMovingPropertyUnit:{
    path:"/add-lease-moving-property-unit",
    restricted:true,
    passCookies:true,
    component: AddLeaseMovingPropertyUnitPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLeaseMovingTenant:{
    path:"/add-lease-moving-tenant",
    restricted:true,
    passCookies:true,
    component: AddLeaseMovingTenantPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddCustomer:{
    path:"/add-customer",
    restricted:true,
    passCookies:true,
    component: AddCustomerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Customer:{
    path:"/customer",
    restricted:true,
    passCookies:true,
    component: CustomerTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddSafety:{
    path:"/add-safety",
    restricted:true,
    passCookies:true,
    component: AddSafetyPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  SafetyTable:{
    path:"/safety",
    restricted:true,
    passCookies:true,
    component: SafetyTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddLead:{
    path:"/add-lead",
    restricted:true,
    passCookies:true,
    component: AddLeadPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditLead:{
    path:"/edit-lead",
    restricted:true,
    passCookies:true,
    component: EditLeadPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  LeadTable:{
    path:"/lead",
    restricted:true,
    passCookies:true,
    component: LeadTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  KnowledgeBase:{
    path:"/knowledgebase",
    restricted:true,
    passCookies:true,
    component: KnowledgeBasePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  KnowledgeBaseSetting:{
    path:"/knowledgebase-setting",
    restricted:true,
    passCookies:true,
    component: KnowledgeBaseSettingPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  VendorProfile:{
    path:"/vendor-profile",
    restricted:true,
    passCookies:true,
    component: VendorProfilePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddVendor:{
    path:"/add-vendor",
    restricted:true,
    passCookies:true,
    component: AddVendorPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Vendor:{
    path:"/vendor",
    restricted:true,
    passCookies:true,
    component: VendorTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Staff:{
    path:"/staff",
    restricted:true,
    passCookies:true,
    component: StaffTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  StaffDepartment:{
    path:"/staff-department",
    restricted:true,
    passCookies:true,
    component: StaffDepartmentTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  
  AddStaffDepartment:{
    path:"/add-staff-department",
    restricted:true,
    passCookies:true,
    component: AddStaffDepartmentPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  StaffRole:{
    path:"/staff-role",
    restricted:true,
    passCookies:true,
    component: StaffRoleTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  
  AddStaffRole:{
    path:"/add-staff-role",
    restricted:true,
    passCookies:true,
    component: AddStaffRolePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddStaff:{
    path:"/add-staff",
    restricted:true,
    passCookies:true,
    component: AddStaffPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  StaffAdmin:{
    path:"/staff-admin",
    restricted:true,
    passCookies:true,
    component: StaffAdminPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Communication:{
    path:"/communication",
    restricted:true,
    passCookies:true,
    component: CommunicationTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Calendar:{
    path:"/calendar",
    restricted:true,
    passCookies:true,
    component: CalendarPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  CalendarScheduler:{
    path:"/calendar-scheduler",
    restricted:true,
    passCookies:true,
    component: CalendarSchedulerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddFinanceAccount:{
    path:"/add-finance-account",
    restricted:true,
    passCookies:true,
    component: FinanceAccountPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceAccount:{
    path:"/finance-account",
    restricted:true,
    passCookies:true,
    component: FinanceAccountTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceTransferMoney:{
    path:"/transfer-money",
    restricted:true,
    passCookies:true,
    component: FinanceTransferMoneyPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceInvoiceAdd:{
    path:"/add-finance-invoice",
    restricted:true,
    passCookies:true,
    component: FinanceInvoicePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceInvoice:{
    path:"/finance-invoice",
    restricted:true,
    passCookies:true,
    component: FinanceInvoiceTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinancePayInvoices:{
    path:"/add-finance-pay-invoice",
    restricted:true,
    passCookies:true,
    component: FinancePayInvoicesPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceBudget:{
    path:"/finance-budget",
    restricted:true,
    passCookies:true,
    component: FinanceBudgetTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceBudgetAccount:{
    path:"/add-finance-budget-account",
    restricted:true,
    passCookies:true,
    component: FinanceBudgetAccountPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceLineItem:{
    path:"/add-finance-line-item",
    restricted:true,
    passCookies:true,
    component: FinanceLineItemPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceDeposit:{
    path:"/finance-deposit",
    restricted:true,
    passCookies:true,
    component: FinanceDepositTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceLedger:{
    path:"/finance-ledger",
    restricted:true,
    passCookies:true,
    component: FinanceLedgerTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddFinanceLedger:{
    path:"/add-finance-ledger",
    restricted:true,
    passCookies:true,
    component: FinanceLedgerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinancePaymentHistory:{
    path:"/finance-payment-history",
    restricted:true,
    passCookies:true,
    component: FinancePaymentHistoryTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddFinancePaymentHistoryAccount:{
    path:"/finance-payment-history-account",
    restricted:true,
    passCookies:true,
    component: FinancePaymentHistoryPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinancePurchaseOrder:{
    path:"/finance-purchase-order",
    restricted:true,
    passCookies:true,
    component: FinancePurchaseOrderTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddFinancePurchaseOrder:{
    path:"/add-finance-purchase-order",
    restricted:true,
    passCookies:true,
    component: FinancePurchaseOrderPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceInvoiceTemplate:{
    path:"/finance-invoice-template",
    restricted:true,
    passCookies:true,
    component: FinanceInvoiceTemplate,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  FinanceReceiptTemplate:{
    path:"/finance-receipt-template",
    restricted:true,
    passCookies:true,
    component: FinanceReceiptTemplate,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddIssueTracker:{
    path:"/add-issue-tracker",
    restricted:true,
    passCookies:true,
    component: AddIssueTrackerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  IssueTracker:{
    path:"/issue-tracker",
    restricted:true,
    passCookies:true,
    component: IssueTrackerTablePage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  MessageTemplate:{
    path:"/message-template",
    restricted:true,
    passCookies:true,
    component: MessageTemplate,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditChangeLog:{
    path:"/changelog",
    restricted:true,
    passCookies:true,
    component: ChangeLogPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  DirectoryListing:{
    path:"/directory-listing",
    restricted:true,
    passCookies:true,
    component: DirectoryListingPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ForumSetting:{
    path:"/forum-setting",
    restricted:true,
    passCookies:true,
    component: AddForumSettingPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ForumPost:{
    path:"/forum-post",
    restricted:true,
    passCookies:true,
    component: AddForumPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  EditIssueTracker:{
    path:"/edit-issue-tracker",
    restricted:true,
    passCookies:true,
    component: EditIssueTrackerPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  AddPlan :{
    path: "/admin/add-plan",
    restricted: true,
    passCookies: true,
    component: AddPlanPage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  WebsiteSettings: {
    path: "/admin/website-settings",
    restricted: true,
    passCookies: true,
    component: WebsiteSettings,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  NameEdit: {
    path: "/admin/edit-names/:id",
    restricted: true,
    passCookies: true,
    component: NameEdit,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  MoveOutReason: {
    path: "/admin/edit-reasons/:id",
    restricted: true,
    passCookies: true,
    component: MoveOutReason,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  ParkingEdit: {
    path: "/admin/edit-parking/:id",
    restricted: true,
    passCookies: true,
    component: ParkingEdit,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  EthnicitiesEdit: {
    path: "/admin/edit-Ethnicities/:id",
    restricted: true,
    passCookies: true,
    component: EthnicitiesEdit,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },

  ShowPrefixSuffix: {
    path: "/admin/show-prefix-suffix",
    restricted: true,
    passCookies: true,
    component: ShowPrefixSuffix,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  ViewPrefixSuffix: {
    path: "/admin/show-settings",
    restricted: true,
    passCookies: true,
    component: ViewPrefixSuffix,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  EditPrefixSuffix: {
    path: "/EditPrefixSuffix",
    restricted: true,
    passCookies: true,
    component: EditPrefixSuffix,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  login: {
    path: "/login",
    restricted: false,
    passCookies: true,
    component: LoginPage,
    roles:"All"
  },
  CompanyRegister: {
    path: "/company/register",
    restricted: false,
    passCookies: true,
    component: CompanyRegister,
    roles:"All"
  },
  processing: {
    path: "/auth",
    restricted: false,
    passCookies: true,
    component: ProcessingPage,
    roles:"All"
  },
    home: {
    path: "/",
    restricted: true,
    passCookies: true,
    component: PortalDashboardPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  portal: {
    path: "/portal/dashboard",
    restricted: true,
    passCookies: true,
    component: PortalDashboardPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  adminPortal: {
    path: "/admin",
    restricted: true,
    passCookies: true,
    component: AdminDashboardPage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  TestPagePortal: {
    path: "/test-page",
    restricted: true,
    passCookies: true,
    component: TestPage,
  },
  // Tree:{
  //   path:"/tree-folder",
  //   restricted:true,
  //   passCookies:true,
  //   component:Tree,
  //   roles:['owner','All']
  // },
  MySignaturePad:{
    path:"/signature-pad",
    restricted:true,
    passCookies:true,
    component:MySignaturePad,
    roles:['owner','All']
  },

  StaffDashboardPage: {
    path: "/admin/users-staff-admin",
    restricted: true,
    passCookies: true,
    component: StaffDashboardPage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  superAdminPortal: {
    path: "/super_admin",
    restricted: true,
    passCookies: true,
    component: SuperAdminDashboardPage,
  },
  editProperty: {
    path: "/edit-property/:id",
    restricted: true,
    passCookies: true,
    component: EditPropertyPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  showProperty : {
    path: "/show-property/:id",
    restricted: true,
    passCookies: true,
    component: ShowPropertyPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  Leads: {
    path: "/admin/users-lead",
    restricted: true,
    passCookies: true,
    component: Leads,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Ledger: {
    path: "/admin/finance-ledger",
    restricted: true,
    passCookies: true,
    component: Ledger,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Invoice: {
    path: "/admin/finance-invoice-other",
    restricted: true,
    passCookies: true,
    component: Invoice,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  PaymentHistory: {
    path: "/admin/finance-payment-history",
    restricted: true,
    passCookies: true,
    component: PaymentHistory,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  PurchaseOrders: {
    path: "/admin/finance-purchase-order",
    restricted: true,
    passCookies: true,
    component: PurchaseOrders,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Budget: {
    path: "/admin/finance-budget",
    restricted: true,
    passCookies: true,
    component: Budget,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Account: {
    path: "/admin/finance-accounts",
    restricted: true,
    passCookies: true,
    component: Account,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SupportTicket: {
    path: "/admin/support-ticket",
    restricted: true,
    passCookies: true,
    component: SupportTicket,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  WiserNotify: {
    path: "/admin/admin-wisernotify",
    restricted: true,
    passCookies: true,
    component: WiserNotify,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SupportIssue: {
    path: "/admin/support-issues",
    restricted: true,
    passCookies: true,
    component: SupportIssue,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SupportGetScreen: {
    path: "/admin/support-getscreenme",
    restricted: true,
    passCookies: true,
    component: SupportGetScreen,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  AdminMarketing: {
    path: "/admin/admin-marketing",
    restricted: true,
    passCookies: true,
    component: AdminMarketing,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Vendors: {
    path: "/admin/users-vendors-admin",
    restricted: true,
    passCookies: true,
    component: Vendors,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  UserOnwer: {
    path: "/admin/users-owner",
    restricted: true,
    passCookies: true,
    component: UserOnwer,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemForum: {
    path: "/admin/system-forum",
    restricted: true,
    passCookies: true,
    component: SystemForum,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemMessage: {
    path: "/admin/system-message",
    restricted: true,
    passCookies: true,
    component: SystemMessage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemFiles: {
    path: "/admin/system-files",
    restricted: true,
    passCookies: true,
    component: SystemFiles,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Reports: {
    path: "/admin/reports",
    restricted: true,
    passCookies: true,
    component: Reports,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemForumGuides: {
    path: "/admin/system-forum-guides",
    restricted: true,
    passCookies: true,
    component: SystemForumGuides,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  AdminCms: {
    path: "/admin/admin-cms",
    restricted: true,
    passCookies: true,
    component: AdminCms,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Plans: {
    path: "/admin/plans",
    restricted: true,
    passCookies: true,
    component: PlanTablePage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  RoadMap: {
    path: "/admin/admin-roadmap",
    restricted: true,
    passCookies: true,
    component: RoadMap,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  ChangeLog: {
    path: "/admin/admin-changelog",
    restricted: true,
    passCookies: true,
    component: ChangeLog,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  AdminFooter: {
    path: "/admin/admin-footer",
    restricted: true,
    passCookies: true,
    component: AdminFooter,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  PlanStorage: {
    path: "/admin/plans-storage",
    restricted: true,
    passCookies: true,
    component: PlanStorage,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemBackup: {
    path: "/admin/system-backup",
    restricted: true,
    passCookies: true,
    component: SystemBackup,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemKnowledgeBase: {
    path: "/admin/system-knowledgebase",
    restricted: true,
    passCookies: true,
    component: SystemKnowledgeBase,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  ContentWriters: {
    path: "/admin/admin-marketing-content-writers",
    restricted: true,
    passCookies: true,
    component: ContentWriters,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SiteSecurity: {
    path: "/admin/admin-site-security",
    restricted: true,
    passCookies: true,
    component: SiteSecurity,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Testing: {
    path: "/admin/admin-testing",
    restricted: true,
    passCookies: true,
    component: Testing,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Activity: {
    path: "/admin/admin-activity",
    restricted: true,
    passCookies: true,
    component: Activity,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  SystemLock: {
    path: "/admin/system-lock",
    restricted: true,
    passCookies: true,
    component: SystemLock,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  // SystemIntegrations: {
  //   path: "/admin/system-integrations",
  //   restricted: true,
  //   passCookies: true,
  //   component: SystemIntegrations,
  // roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  // },
  // SystemSetting: {
  //   path: "/admin/system-settings",
  //   restricted: true,
  //   passCookies: true,
  //   component: SystemSetting,
  // roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  // },
  PaymentGateways: {
    path: "/admin/finance-payment-gateways",
    restricted: true,
    passCookies: true,
    component: PaymentGateways,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  Languages: {
    path: "/admin/system-language",
    restricted: true,
    passCookies: true,
    component: Languages,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  UserTenant: {
    path: "/admin/users-tenant",
    restricted: true,
    passCookies: true,
    component: UserTenant,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  // TenantInfo: {
  //   path: "/admin/tenant-information",
  //   restricted: true,
  //   passCookies: true,
  //   component: TenantInfo,
  // roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  // },
  UserRoles: {
    path: "/admin/users-user-roles",
    restricted: true,
    passCookies: true,
    component: UserRoles,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  EditableList: {
    path: "/admin/system-lists",
    restricted: true,
    passCookies: true,
    component: EditableList,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  EmailTemplates: {
    path: "/admin/system-template",
    restricted: true,
    passCookies: true,
    component: EmailTemplates,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  TaxFeeSettings: {
    path: "/admin/finance-tax",
    restricted: true,
    passCookies: true,
    component: TaxFeeSettings,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },

  Permissions: {
    path: "/admin/permissions",
    restricted: true,
    passCookies: true,
    component: Permissions,
    roles:["super admin","All",localStorage.getItem("MultiRole") == "Yes" ? 'owner' : '']
  },
  tenantPortal: {
    path: "/tenantDashboard",
    restricted: true,
    passCookies: true,
    component: TenantDashboardPage,
    roles:["tenant","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  ownerPortal: {
    path: "/owner",
    restricted: true,
    passCookies: true,
    component: OwnerDashboardPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  addProperty: {
    path: "/properties/add",
    restricted: true,
    passCookies: true,
    component: AddPropertyPage,
    roles:["owner","All",localStorage.getItem("MultiRole") == "Yes" ? 'super admin' : '']
  },
  community: {
    path: "/community",
    restricted: false,
    passCookies: true,
    component: PeerboardCommunity,
  },
  communityNested: {
    path: "/community/*",
    restricted: false,
    passCookies: true,
    component: PeerboardCommunity,
  },
};
