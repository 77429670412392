import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../utility/http";
import { getAccessToken } from "../../utility/auth";
import { CustomError } from "../../utility/errors";
import fixedData from "../../utility/fixedData";
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password, cookies }) => {
    const body = {
      email,
      password,
    };
    try {
      console.log("here 15 login");
      const response = await ApiRequest?.post(
        "public/api/auth/login_grant",
        body
      );
      if (response.status === 200) {
        // console.log("access_token",response?.data?.access_token);

        // cookies.set("access_token", response?.data?.access_token, {
        //   maxAge: response?.data?.expires_in,
        // });
        // cookies.set("refresh_token", response?.data?.refresh_token, {
        //   maxAge: response?.data?.expires_in,
        // });

        localStorage.setItem("access_token", response?.data?.access_token);
        localStorage.setItem("refresh_token", response?.data?.refresh_token);

        return response?.data;
        // console.log(checkInUserList(body));
      } else if (checkInUserList(body)) {
        console.log(" 31");
        setBypassingTokens(cookies);
        return { meta: { requestStatus: "fulfilled" } };
      }
    } catch (error) {
      console.log("33", error);
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.statusText ||
        error?.message;
      if (errorMessage.includes("timeout") && checkInUserList(body)) {
        console.log("42");
        setBypassingTokens(cookies);
        return { meta: { requestStatus: "fulfilled" } };
      }
      throw new CustomError({
        name: error.name,
        code: error.response?.status || error.code,
        message: errorMessage.includes("timeout")
          ? "Request timed out!"
          : errorMessage,
        response: error.response,
      });
    }
  }
);

export const setUserProfile = createAsyncThunk(
  "auth/setUserProfile",
  async ({}, { getState }) => {
    alert("user here");
    console.log("here 54 ===============");
    const state = getState();
    let access_token = "";
    if (state.auth.access_token) access_token = state.auth.access_token;
    else {
      access_token = getAccessToken();
    }
    try {
      console.log("here 70");
      const response = await ApiRequest.get("public/api/auth/user");
      return response.data;
    } catch (err) {
      console.log("error in user profile api ", err);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({
    firstname,
    lastname,
    email,
    password,
    role_id,
    address_line1,
    address_line2,
    city,
    state,
    country,
    zip_code,
    phone_country_code,
    phone_number,
  }) => {
    const body = {
      firstname,
      lastname,
      email,
      password,
      role_id,
      address_line1,
      address_line2,
      city,
      state,
      country,
      zip_code,
      phone_country_code,
      phone_number,
    };
    const response = await ApiRequest.post("/public/api/auth/register", body);
    if (response) {
      return response;
    }
  }
);

const checkInUserList = (credentials) => {
  let exist = false;
  for (const data of fixedData) {
    if (
      data.email == credentials.email &&
      data.password == credentials.password
    ) {
      exist = true;
      break;
    }
  }

  return exist;
};

const setBypassingTokens = (cookies) => {
  cookies.set("access_token", "BypassingToken", {
    maxAge: 129600,
  });
  cookies.set("refresh_token", "RefreshingToken", {
    maxAge: 129600,
  });
};
