import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import useAlerts from "../../utility/alerts";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
export const EthnicitiesEditForm = ({
  //   initialData = {},
  verticalScroll = 0,
  etch,
  setEtch,
  setApiIEtch,
  apiIEtch
}) => {
  //  CONSTANTS

  /////// local states
  const Alert = useAlerts();
  ////METHODS
  const handleSubmitEthnicities = async (e) => {
    e.preventDefault();
    try {
      const res = await ApiRequest.post("/public/api/auth/ethnicity",{
        ethnicity:etch,
        id:apiIEtch,
      });
      if (res.status == 200) {
        await Alert.addSuccess("Ethnicity is edited!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* Edit  Ethnicities  Form  */}
        <form
          className="w-full"
          id="add-property-form"
          onSubmit={handleSubmitEthnicities}
        >
         <div className="md:flex flex-column md:items-center w-100 m-auto">
          <div className="md:flex md:items-center w-full mt-6">
            <div className="md:w-1/3">
              <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Ethnicities:<span title="Field is required."></span>
                </label>
              </div>
              <div className="md:w-1/3">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="hoa_zip_code-property-name"
                  type="text"
                  value={etch}
                  // required={true}
                  onChange={(e) => {
                    setEtch(e.target.value);
                  }}
                />
              </div>
              <input
                id={"form-submit-button"}
                type={"submit"}
                value="Edit ethnicities"
                hidden={false}
                className="ml-6 py-3 px-6 border-solid border-2  border-red-200 rounded"
              />
            </div>
          </div>
        </form>
    </>
  );
};
