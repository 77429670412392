import { CKEditor } from 'ckeditor4-react';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useAlerts from '../../utility/alerts';
import ApiRequest from '../../utility/http';
import Loading from '../Loader/Loading';


export const GeneralAppForm = ({
    initialData = {},
}) => {
  const dispatch = useDispatch();
  const Alert = useAlerts();
  const { alertList } = useSelector((state) => state.alerts);
  const [yearData] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
  const [employerYearData] = useState(() => {
    const optionsArray = [];
    for (let i = 1; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
  const [minYearData] = useState(() => {
    const optionsArray = [];
    for (let i = 0; i <= 50; i+=1) {
      optionsArray.push(i);
    }
    return optionsArray;
  });
    const [formData,setFormData] = useState();
    const [validation,setValidation] = useState("");
    const [success,setSuccess] = useState(false);
    console.log("formData",formData);
    const [editorData,setEditorData] = useState();
    console.log("EditoData",editorData);
    const [triggerSubmit, triggerFormSubmit] = useState(false);
    
    const [loader, setLoader] = useState(undefined);

    const formElement = document.getElementById("add-tenant-form");
    useEffect(() => {
        setFormData(initialData)
    }, [])
    
    const getGeneralSetting= async()=>
    {
      setLoader(undefined)
      const response = await ApiRequest.get('/public/api/auth/get-applications-settings');
      // console.log('res',response?.data?.data[0]?.previous_housings_years);
      if(response.status == 200)
      {
        setFormData({
          ...formData,previous_housings_years: response?.data?.data[0]?.previous_housings_years,
          ...formData,employers_years: response?.data?.data[0]?.employers_years,
          ...formData,minimum_references: response?.data?.data[0]?.minimum_references,
        });
        setEditorData({...editorData,additional_information:response?.data?.data[0]?.additional_information});
        setLoader(response)
      }
  }
    const formSubmitHandler = async (e) => {
      e.preventDefault();
      try {
        setLoader(undefined)
        const res = await ApiRequest.post("/public/api/auth/application-setting", {
          owner_id: 30,
          previous_housings_years: formData?.previous_housings_years,
          employers_years: formData?.employers_years,
          minimum_references: formData?.minimum_references,
          additional_information: editorData?.additional_information
        });
        console.log("Result formData", formData);
        console.log("Result Response", res);
        if (res.status == 200) {
        setLoader(res)
          console.log("Added");
          setValidation("");
          setSuccess(res?.data?.message);
          Alert.addSuccess("General Application Added Successfully!")
        }
        if(res.status == 400)
        {
          console.log('res',res);
        }
      } catch (err) {
        console.log(err);
        setValidation(err?.response?.data);
        setSuccess(false)
        Alert.addFailure(
          <ul>
              {err?.response?.data?.employers_years ? <li>{err?.response?.data?.employers_years}</li>:null}
            {err?.response?.data?.previous_housings_years ? <li>{err?.response?.data?.previous_housings_years}</li>:null}
              {err?.response?.data?.minimum_references ? <li>{err?.response?.data?.minimum_references}</li> :null}
        </ul>)
        // if(validation != "")
        // {
          
        // }
        // if(err?.response?.data?.employers_years)
        // {
        //   Alert.addFailure(err?.response?.data?.employers_years)
        // }
        // if(err?.response?.data?.minimum_references)
        // {
        //   Alert.addFailure(err?.response?.data?.minimum_references)
        // }
        // err?.response?.data?.previous_housings_years ?  : null
        // err?.response?.data?.employers_years ? Alert.addFailure(err?.response?.data?.employers_years) : null
        // err?.response?.data?.minimum_references ? Alert.addFailure(err?.response?.data?.minimum_references) : null
      }
    };

    useEffect(() => {
      const data = {...formData};
      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }, [])

    const handleCloseAlert = ()=>{
      setSuccess(false);
    }

    
  // useEffect(() => {
  //   if (alertList?.length) {
  //     alertList.map((alertEl) => {
  //       Alert.show(alertEl);
  //       Alert.remove(alertEl);
  //     });
  //   }
  // }, [alertList]);
  useEffect(() => {
    getGeneralSetting();
  }, [])
  
    


    return (
    <div>
      {/* {validation != "" ?
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Error!</strong> 
      <ul style={{listStyleType:"disc"}}>
          {validation?.employers_years ? <li>{validation?.employers_years}</li>:null}
         {validation?.previous_housings_years ? <li>{validation?.previous_housings_years}</li>:null}
          {validation?.minimum_references ? <li>{validation?.minimum_references}</li> :null}
        </ul> */}
      {/* <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
        <svg class="fill-current h-6 w-6 text-red-500" role="button" onClick={handleCloseAlert} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
      </span> */}
    {/* </div>
      :null} */}
          {/* {success == "success" ? 
    <div>
      <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Save!</strong>
        <span class="block sm:inline"> General Application Added Successfully</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg class="fill-current h-6 w-6 text-green-500" role="button" onClick={handleCloseAlert} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    </div>
    :null} */}
      {loader == undefined ? <Loading/> :<form
      className="w-full"
      id="add-general-app-form"
      onSubmit={formSubmitHandler}
      >
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Years of Past Housing Information Required:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`past-housing-info`}
                          value={formData?.previous_housings_years}
                          onChange ={(e)=>{
                            setFormData({ ...formData, previous_housings_years: e.target.value });
                          }}
                          >
                            {yearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Years of Past Employer Information Required:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`past-employer-info`}
                          value={formData?.employers_years}
                          onChange ={(e)=>{
                            setFormData({ ...formData, employers_years: e.target.value });
                          }}
                          >
                            {employerYearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>
        <div className="md:flex md:items-center w-full mt-5">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Minimum Required References:
                        </label>
                      </div>
                      <div className="md:w-2/5">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`min-reference`}
                          value={formData?.minimum_references}
                          onChange ={(e)=>{
                            setFormData({ ...formData, minimum_references: e.target.value });
                          }}
                          >
                            {minYearData?.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                      </div>
                    </div>                    
                    <div className="md:flex mt-5">
								<div className="md:w-1/5">
									<label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
										Additional Information Instructions:
									</label>
								</div>
								<div className="md:w-4/5">
									{/* <CKEditor
										initData={editorData?.additional_information.replace(/(<([^>]+)>)/ig, '')}
										onChange={(e) => {
											setEditorData({...editorData,additional_information:e.editor.getData()});
										}}
									/> */}
                  <textarea
                    className="appearance-none block w-[90%] m-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="additional-info"
                    value={editorData?.additional_information.replace(/(<([^>]+)>)/ig, '')}
                    placeholder="Unit #5"
                    onChange={(e) => {
                      setEditorData({...editorData,additional_information:e.target.value});
                    }}
                  />
								</div>
							</div>
              <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 mt-6">Submit</button>
              </form>}
    </div>
  )
}
