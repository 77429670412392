import React, { useEffect, useState } from 'react'
import imgUrl from '../ImageUrl'
import ApiRequest from '../../utility/http';

export const Card = ({data,ownerSetting}) => {
  const [numberType, setNumberType] = useState([]);
    const getNumberType = async()=>{
      const response = await ApiRequest.get('/public/api/auth/get-number-types')
      if(response.status == 200)
      {
        setNumberType(response?.data?.data?.number_types);
      }
    }
    function removeNonNumeric(inputString) {
      var numericString = inputString.replace(/\D/g, "");
      return numericString;
    }
    const formatPhoneNumber = (number, format) => {
      const digitsOnly = number?.replace(/\D/g, '') || '';
      let formattedNumber = '';
      let digitIndex = 0;
    
      for (let i = 0; i < format?.length; i++) {
        if (format[i] === '#') {
          formattedNumber += digitsOnly[digitIndex] || '';
          digitIndex++;
        } else if (format[i] === '_') {
          formattedNumber += ' ';
        } else {
          formattedNumber += format[i];
        }
      }
    
      return formattedNumber;
    };
    useEffect(()=>{
      getNumberType();
    },[])
  return (
    <>
        {data?.map((card)=>(
          <div className="md:flex flex-column md:items-center w-1/3 border-black border p-4 hover:border-blue-500 rounded">
          <div className="w-full">
            <div className="flex">
              <div className="w-4/12">
                {card?.picture != null && card?.picture != "" ?
                (<img className="logo w-full" src={`${imgUrl}/${card?.picture}`} alt="" />)
                :
                (<img
                  className="logo w-full"
                  src="/assets/img/system-no-picture.png"
                  alt=""
                />)
                }
              </div>
              <div className="w-8/12 ml-4">
                <div className="flex">
                  {card?.firstname !="" ? <h4 className="font-bold capitalize mr-2">{card?.firstname}</h4>:null}
                  {card?.lastname !="" ?<h4 className="font-bold capitalize">{card?.lastname}</h4>:null}
                </div>
                {card?.title != "" ?<div className="flex mt-2 italic">
                  <span>{card?.title}</span></div>:null}
                {card?.email != "" ?<div className="flex mt-2"><a className="text-black no-underline" href={`mailto:${card?.email}`}>{card?.email}</a></div>:null}
                {card?.number1 != ""?<div className="flex mt-2">
                  <span className="font-bold">{numberType?.map((row)=>{
                  if(card?.number1_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className="font-bold ml-2 text-black no-underline" href={`tel:${card?.number1}`}>{card?.number1_type == 8 ? card?.number1: formatPhoneNumber(card?.number1,ownerSetting?.phone_number_format)}</a>
                </div>:null}
                {card?.number2 != ""?<div className="flex mt-2">
                  <span className="font-bold">{numberType?.map((row)=>{
                  if(card?.number2_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className="font-bold ml-2 text-black no-underline" href={`tel:${card?.number2}`}>{card?.number2_type == 8 ? card?.number2: formatPhoneNumber(card?.number2,ownerSetting?.phone_number_format)}</a>
                </div>:null}
                {card?.number3 != ""?<div className="flex mt-2">
                  <span className="font-bold">{numberType?.map((row)=>{
                  if(card?.number3_type == row?.id)
                  {
                    return (<img className="logo w-6" title={row?.type} src={`${imgUrl}/${row?.icon}`}/>)
                  }
                })}</span>
                  <a className="font-bold ml-2 text-black no-underline" href={`tel:${card?.number3}`}>{card?.number3_type == 8 ? card?.number3: formatPhoneNumber(card?.number3,ownerSetting?.phone_number_format)}</a>
                </div>:null}
              </div>
            </div>
                <hr />
            {card?.info != "" ?<div className="flex mt-2">
              <div dangerouslySetInnerHTML={{ __html: card?.info }} />
            </div>:null}
            
          </div>
          <hr />
        </div>
        ))}
    </>
  )
}
