import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { LoginIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import "../../components/button.css"
import useAlerts from "../../utility/alerts";
import { SideNavBar } from "../../components/SideNavBar";
import { convertToFormData } from "../../utility/helperFunctions";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
// import { PropertyForm } from "../../components/property/PropertyForm";
import { UnitForm } from "../../components/UnitForm/UnitForm";
import ApiRequest from "../../utility/http";
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { MdDeleteOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { FiEdit2 } from "react-icons/fi";
import pdf from "../../Images/pdf.png";
import csv from "../../Images/csv.png";
// import UnitTable from "./UnitTable";
import { OwnerHeader } from "../OwnerHeader";
// import CommunicationTable from "../../components/OwnerCommunication/CommunicationTable";
import { Footer } from "../../components/Dashboard/Footer";
import LeaseTable from "../../components/lease/LeaseTable";
import FinancePurchaseOrderTable from "../../components/finance/FinancePurchaseOrderTable";
export const FinancePurchaseOrderTablePage = ({ cookies }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const Alert = useAlerts();

  // CONSTANTS

  // STATES

  let [showFooter, setShowFooter] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  let [showPropertyDropdown, setShowPropertyDropdown] = useState(false);

  const { alertList } = useSelector((state) => state.alerts);
const [searchValue,setSearchValue]=useState();
  const handleContentScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let show = scrollTop + e.target.clientHeight >= e.target.scrollHeight;
    if (show) setShowFooter(() => (showFooter = show));
    else setShowFooter(() => (showFooter = false));

    setVerticalScroll(scrollTop);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    cookies.remove("access_token");
    cookies.remove("refresh_token");
    Alert.addSuccess("Logged out successfully!");
    navigate("/login");
  };

  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  useEffect(() => {
    if (alertList?.length) {
      alertList.map((alertEl) => {
        Alert.show(alertEl);
        Alert.remove(alertEl);
      });
    }
  }, [alertList]);
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        select: (data) => data.id // select is the "key" in the data
      },
      {
        Header: "Unit Name",
        select: (data) => data.name
      },
      {
        Header: "Action",
        Cell: (row) => (
          <>
          <Link to={`/edit-property/${row.id}`} style={{textDecoration:"none"}}>
            <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
            </Link>
            <Link to={`/show-property/${row.id}`} style={{textDecoration:"none"}}>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
                <span>Delete</span>
              </button>
            </Link>
          </>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () => [
      {
        id: "Maintenance",
        name: "General Supplies",
      },
      {
        id: "Maintenance",
        name: "General Supplies",
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      initialState: { pageIndex: 0 },
    },

    useGlobalFilter,
    usePagination,
    useRowSelect,
    useExportData,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state,
    setGlobalFilter,
    exportData,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize, selectedRowIds } = state;

  console.log("globalFilter", globalFilter);

  //   useEffect(()=>{
  //     setGlobalFilter("this is filter")
  //   },[])
  const [collapsed,setCollapsed] = useState(true)
  const handleChildMessage = (message) => {
    setCollapsed(message)
    console.log("message",message);
  };
  const [propertyId,setPropertyId] = useState(true)
  const handlePropertyID = (message) => {
  setPropertyId(message)
  };
  return (
    <div>
      <Toaster />
      <div className="main-dashboard">
        <Helmet>
          <link rel="stylesheet" href="/assets/css/main.css" />
          <link rel="stylesheet" href="/assets/css/fontawesome-all.min.css" />
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="/assets/css/slick.css" />
          <link rel="stylesheet" href="/assets/css/table.css" />
          <link rel="stylesheet" href="/assets/css/responsive.css" />
        </Helmet>
        {/* side bar */}
        <SideNavBar active="Finance" child={"Purchase Orders"} onChildMessage={handleChildMessage}/>
        <div
          className={`main-content ${collapsed ? "collapsed" : ""}`}
          onScroll={(e) => handleContentScroll(e)}
        >
          {/* header bar */}
          <OwnerHeader setPropertyId={handlePropertyID}/>
          {/* search wraper  */}
          <section className="search-wraper">
            <div className="row">
              <div className="col-xl-8 order-lg-2">
                <div className="search-right-side-wrap">
                  <div className="mobile-menu-trigger">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="serch-bar-side">
                    <div className="form-group search">
                      <input
                        type="search"
                        name
                        id
                        placeholder="Type here to Search..."
                      />
                      <i className="fas fa-search" />
                    </div>
                  </div>
                  <label className="theme-switch">
                    <span className="switch-dark">dark</span>
                    <input type="checkbox" id="check-slider" />
                    <span className="check-slider round" />
                    <span className="switch-light">light</span>
                  </label>
                </div>
              </div>
              <div className="col-xl-4 order-lg-1">
                <div
                  className={`welcome-wrap ${
                    showPropertyDropdown ? "z-0" : ""
                  }`}
                >
                  <div className="welcome-inner flex align-items-center">
                    <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-purchase-order.svg"
                alt=""
                /> 
                <h4>
                <span>Purchase Orders</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* filert table */}
          <div className="flex justify-between items-center mb-3 ">
            <div></div>
            {/* <div className="flex justify-between items-center gap-2">
              <button
                onClick={() => {
                  exportData("csv", true);
                }}
              >
                <img src={csv} alt="" className="w-20 h-15 object-cover" />
              </button>
              <button
                onClick={() => {
                  exportData("pdf", true);
                }}
              > */}
                {/* <img src={pdf} alt="" className="w-20 h-15 object-cover" /> */}
              {/* </button>{" "} */}
              {/* <div className="w-full flex justify-start items-center gap-2">
                <label className="input-label w-100 text-gray-700 w-auto">
                  Show:
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  //   id="hoa-state"
                  value={globalFilter || " "}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                >
                      <option value=" ">All</option>
                  <option value="Maintenance">Maintenance</option>
                
                  <option value="Expense">Expense</option>
                  <option value="dsfdsf">dsfdsf</option>
                </select>
              </div> */}
              {/* <div className="w-full flex justify-start items-center gap-2">
                <label className="input-label w-100 text-gray-700 w-auto">
                  Filter:
                </label>

                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  //   id="hoa-state"
                  value={globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                >
                  <option value="Maintenance">Maintenance</option>
                  <option value="Expense">Expense</option>
                  <option value="dsfdsf">dsfdsf</option>
                </select>
              </div> */}
               {/* <div className="w-full flex justify-start items-center gap-2">
               <label className="input-label w-100 text-gray-700 w-auto">
                            Search:
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="hoa_zip_code-property-name"
                            type="text"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} */}
                            {/* // value="Autogenrated"
                            // placeholder="Amount"
                          /> */}
                          {/* <button className="bg-gray-200 py-1 px-2 rounded" onClick={()=>{
                            setGlobalFilter(()=>searchValue)
                          }}>Go</button>
                        </div> */}
                
                      
            {/* </div> */}
            <div className="flex justify-between items-center gap-2">
            <Link to={"/add-finance-purchase-order"}>
                <button className="btn2">
                <i className="fa fa-plus"></i>
                  Add Purchase Order  
                </button>
            </Link>
              </div>
          </div>
                  <FinancePurchaseOrderTable/>
          {/* <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-x-auto">
                </div>
              </div>
            </div>
            <div className="pagination flex justify-end items-center mt-4">
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                class="bg-gray-300 py-1 mr-2  w-12 flex justify-center align-items-center rounded text-black-800"
              >
                {">>"}
              </button>{" "}
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                {" "}
                Go to page:{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div> */}

          <div className="bottom-gap" />
        </div>
        {showFooter?<Footer/>:null}
        {/* <footer className={`footer-area ${showFooter ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-4 order-xxl-1">
                <ul className="footer-menu">
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-3">
                <ul className="footer-text-list">
                  <li>Frature Request/Change Log</li>
                  <li> Version: 1.1.0</li>
                </ul>
              </div>
              <div className="col-xxl-4 order-xxl-2">
                <p className="copyright">
                  Copyright 2021. All Rights Serserved by Rent Blaze
                </p>
              </div>
            </div>
            <div className="row footer-pera justify-content-center">
              <div className="col-lg-8">
                <p className="text-center">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestiae, qui doloribus repudiandae, excepturi ex optio
                  aliquid quaerat dolorem numquam eligendi voluptatem laborum in
                  amet, id earum quasi cum saepe magni!
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </div>
  );
};
