import React, { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";

export const AddStaffRoleForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="flex md:items-center mt-5">
              <div className="md:flex md:items-center w-full md:w-4/12">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Status:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  >
                    <option value="">Select Status</option>
                    {/* <option value={1}>Active</option>
                          <option value={0}>Inactive</option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex md:items-center mt-3">
              <div className="md:flex md:items-center w-4/5">
                <div className="md:w-1/4">
                  <label className="block capitalize text-gray-700 font-bold mb-2">
                    Role Name<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <input
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  />
                </div>
              </div>
            </div>
            <div className="flex md:items-center mt-3">
              <div className="md:flex md:items-center w-1/2">
                <div className="md:w-1/4">
                  <label className="block  text-gray-700 font-bold mb-2">
                    Department(s)<span title="Field is Required">*</span>:
                  </label>
                </div>
                <div className="md:w-3/4">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`staff-status`}
                    required={false}
                    //   value={tenantChildren?.status}
                    //   onChange={(e) => {
                    //     setTenantChildren({...tenantChildren,status:parseInt(e.target.value)})
                    //   }}
                  >
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex mt-3">
              <div className="md:w-1/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Job Description:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                // initData={editorData?.dln_id_issue_notes}
                // onChange={(e) => {
                //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                //   setEditorData({
                //     ...editorData,
                //     dln_id_issue_notes: cleanText,
                //   });
                // }}
                />
              </div>
            </div>
            <div class="flex justify-end items-center mt-3">
              <div class="flex items-center gap-4">
                <button type="button" class="btn btn3">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
