import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from '../../utility/http'
import { CKEditor } from 'ckeditor4-react';
import imgUrl from '../ImageUrl';
import Swal from 'sweetalert2';
import { Shots } from './Shots';
import { PetTypes } from '../EditableLists/PetTypes';
import useAllowedPicMimeTypes from '../genericFunctions.js/picMimeType';
import useAllowedMimeTypes from '../genericFunctions.js/mimeType';
import { DatePicker } from '../genericFunctions.js/DatePicker';
import CurrencyInput from '../genericFunctions.js/CurrencyInput';

export const Pets = ({data,handlePetTrue,hiddenData,ownerSettings}) => {


    const initialPetData = {
        status: "",
        name: "",
        pet_type_id: "",
        pet_gender_id: "",
        breed: "",
        color: "",
        weight: "",
        date_of_birth: "",
        support_animal: "",
        deposit: "",
        rent: "",
        license: "",
        location_of_license: "",
        public_note: "",
        private_note: "",
        shots: [],
        pictures: [],
        files: [],
        private_files: [],
      };

      const picTypes = useAllowedPicMimeTypes();
      const fileTypes = useAllowedMimeTypes();

    const [tenantPetShots,setTenantPetShots] = useState([]);
    const [tenantPets,setTenantPets] = useState([]);
    const [tenantPet,setTenantPet] = useState([]);
    const [ownerSetting,setOwnerSetting] = useState([]);
    const [shotType,setShotType] = useState([]);
    console.log('tenantPet',tenantPet);
    console.log('@tenantPetShots',tenantPetShots);
    const [tenantPetPicture, setTenantPetPicture] = useState([]);
    const [tenantPetFile, setTenantPetFile] = useState([]);
    const [tenantPetPrivateFile, setTenantPetPrivateFile] = useState([]);
    const [petGender, setPetGender] = useState([]);
    const [petType, setPetType] = useState([]);
    const [petAge, setPetAge] = useState([]);
    const [showForm,setShowForm] = useState(false);
    const [viewForm,setViewForm] = useState(false);
    const [indexEdit,setIndexEdit ]= useState();
    const [editorData,setEditorData] = useState("");
    const [editorData2,setEditorData2] = useState("");
    // // console.log('@indexEdit',indexEdit);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const handleShotTrue = (message)=>{
        setTenantPetShots(message);
    }
    const handleTypeTrue = (message)=>{
        setShotType(message);
    }
    

    const formatDate = (date) => {
      date = new Date(date);
      const year = date.getFullYear();
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
      const TotalDate = day + "-" + month + "-" + year;
      return TotalDate;
    };
    function stringToDate(dateString) {
      // console.warn("dateString",dateString)
      if (dateString === null || dateString === undefined || dateString >= 0)
        return;
      let parts = dateString.split("-");
      let day = parts[0];
      let month = parts[1]; // months are 0-based
      let year = parts[2];
      let date = year + "-" + month + "-" + day;
      // console.warn("UnformatDate",date)
      return date;
    }
    function ShowFormatDate(data, format) {
        if (data === null || data === undefined)
          return;
        const date = new Date(stringToDate(data));
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
        const year = date.getFullYear();
      
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
      
        format = format.replace('dd', formattedDay);
        format = format.replace('DD', formattedDay);
        format = format.replace('d', day);
        format = format.replace('D', day);
        format = format.replace('mm', formattedMonth);
        format = format.replace('MM', formattedMonth);
        format = format.replace('m', month);
        format = format.replace('M', month);
        format = format.replace('yyyy', year);
        format = format.replace('YYYY', year);
      
        return format;
      }
      function removeCurrencyAndCommas(str) {
        if (str === null) {
          return '';
        }
        // Remove currency symbols ($, €, £, ¥, etc.)
        str = str.replace(/[$€£¥]/g, '');
        
        // Remove commas
        str = str.replace(/,/g, '');
        
        // Return the modified string
        return str;
      }
      function convertCurrencyToFormat(currency, number) {
        let formattedCurrency = Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: currency,
        }).format(number);
      
        return formattedCurrency;
      }
    const calculate_age = (dob1) => {
      var today = new Date();
      var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      // console.log(age_now);
      return age_now;
    };
    const calculate_days_until_next_birthday = (dob) => {
          const today = new Date();
          const birthDate = new Date(dob);
        
          // Check if the birthdate is today
          if (
            today.getDate() === birthDate.getDate() &&
            today.getMonth() === birthDate.getMonth()
          ) {
            return <span style={{ color: "#009900" }}>Today</span>
          }
        
          // Calculate the next birthday
          const nextBirthday = new Date(
            today.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );
          if (today > nextBirthday) {
            nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
          }
        
          // Calculate the difference in days
          const timeDiff = nextBirthday.getTime() - today.getTime();
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
        
          // Apply color based on days left
          let color;
          if (daysLeft <= 365 && daysLeft >= 315) {
            color = "#FF0000"
          } else if (daysLeft <= 314 && daysLeft >= 265) {
            color = "#FF9999"
          } else if (daysLeft <= 264 && daysLeft >= 215) {
            color = "#FF8000"
          } else if (daysLeft <= 214 && daysLeft >= 165) {
            color = "#FFB266"
          }
          else if (daysLeft <= 164 && daysLeft >= 115) {
            color = "#FFFF00"
          }
          else if (daysLeft <= 114 && daysLeft >= 65) {
            color = "#FFFF66"
          }
          else if (daysLeft <= 64 && daysLeft >= 5) {
            color = "#33FFFF"
          }
          
           else {
            color = "green";
          }
          if(isNaN(daysLeft))
          {
            return "";
          }
          return (
            <span style={{ color: color }}>
              {daysLeft} {daysLeft === 1 ? "day" : "days"}
            </span>
          );
        };

    let fileApi = "/public/api/auth/upload-file";
    let imageApi = "/public/api/auth/upload-image";
    const myDivRef = useRef(null);
    const notes = ()=>{
      const data = [];
      data.push(tenantPet);
      const array = [...data];
      // console.log('@array',array);
      // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      // const cleanText2 = new DOMParser().parseFromString(editorData2, 'text/html').body.textContent;
      array[0].public_note = editorData;
      array[0].private_note = editorData2;
      setTenantPet(array);
    }
    const bulkData = ()=>{
      //   tenantPet({...tenantPet,public_note:editorData2,other_type_description:editorData2}); 
      notes();
        const newDataArray  = [];
        newDataArray.push(tenantPet);
        handlePetTrue(prevArray => [...prevArray, ...newDataArray]);
        setTenantPets(prevArray => [...prevArray, ...newDataArray]);
        setTenantPet(initialPetData);
        setTenantPetPicture([]);
        setTenantPetFile([]);
        setTenantPetPrivateFile([]);
        // setEditorData('');
        setShowForm(false);
        setPetAge([])
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...tenantPets];
        array[indexEdit] = tenantPet;
        // const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        // const cleanText2 = new DOMParser().parseFromString(editorData2, 'text/html').body.textContent;
        array[indexEdit].public_note = editorData;
        array[indexEdit].private_note = editorData2;
        setTenantPets(array);
    
        handlePetTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantPet(initialPetData);
        setTenantPetPicture([]);
        setTenantPetFile([]);
        setTenantPetPrivateFile([]);
        setPetAge([])


      }
    const approveData = (index) =>{
      console.log('trueDAta',true);
        const array = [...tenantPets];
        array[index].status = 1;
        setTenantPets(array);
    
        handlePetTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantPet(initialPetData);
        setTenantPetPicture([]);
        setTenantPetFile([]);
        setTenantPetPrivateFile([]);


      }
    const rejectData = (index) =>{
      console.log('trueDAta',true);
        const array = [...tenantPets];
        array[index].status = 0;
        setTenantPets(array);
    
        handlePetTrue(array);
        setShowForm(false);
        setIndexEdit(undefined);
        setTenantPet(initialPetData);
        setTenantPetPicture([]);
        setTenantPetFile([]);
        setTenantPetPrivateFile([]);


      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setShowForm(true);
        setTenantPet(tenantPets[index]);
        setTenantPetFile(tenantPets[index]?.files)
        setTenantPetPrivateFile(tenantPets[index]?.private_files)
        setTenantPetPicture(tenantPets[index]?.pictures);
        setEditorData(tenantPets[index]?.public_note)
        setEditorData2(tenantPets[index]?.private_note)
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setTenantPet(tenantPets[index]);
        setTenantPetFile(tenantPets[index]?.files)
        setTenantPetPrivateFile(tenantPets[index]?.private_files)
        setTenantPetPicture(tenantPets[index]?.pictures);
        setEditorData(tenantPets[index]?.public_note)
        setEditorData2(tenantPets[index]?.private_note)
        setViewForm(true);
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...tenantPets];
        newArray.splice(index, 1);
        setTenantPets(newArray);
        handlePetTrue(newArray);
      }
      function remainingExpiryDays(expiryDate) {
        // Get the current date and the expiry date
        const currentDate = new Date();
        const expiry = new Date(expiryDate);
      
        // Calculate the time difference between the current date and the expiry date
        const timeDiff = expiry.getTime() - currentDate.getTime();
      
        // Convert the time difference from milliseconds to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
        // Return the number of days remaining until the expiry date
        return daysDiff;
      }
      const currentDate = new Date();
      const columns = [
        {
          name: "Picture",
          selector: (data) => (
              <>
              <img src={`${imgUrl}/${data?.pictures[0]?.picture}`} className='w-1/2' alt="" />
              </>
          ),
          sortable: true,
        },
        {
          name: "Name",
          selector: (data) => data.name,
          sortable: true,
        },
        {
          name: "Gender",
          selector: (data) => petGender.map((row)=>{
            if(row?.id == data.pet_gender_id)
            {
              return row?.gender;
            }
          }),
          sortable: true,
        },
        {
          name: "Type",
          selector: (data) => petType.map((row)=>{
            if(row?.id == data.pet_type_id)
            {
              return row?.type;
            }
          }),
          sortable: true,
        },
        {
          name: "Breed",
          selector: (data) => data.breed,
          sortable: true,
        },
        {
          name: "Date of Birth",
          selector: (data) => data.date_of_birth != "" ? ShowFormatDate(data.date_of_birth,ownerSetting?.date_format) : "",
          sortable: true,
        },
        {
          name: "Age",
          selector: (data) => {
            const dateOfBirth = stringToDate(data?.date_of_birth);
            const age = calculate_age(dateOfBirth);
          
            if (isNaN(age)) {
              return "";
            } else {
              return (<span className='flex flex-column'><span className='text-center'>{age}</span>{calculate_days_until_next_birthday(dateOfBirth)}</span>);
            }
          },
          sortable: true,
        },
        {
          name: "Expired Req Shots",
          selector: (data) => {
            const expiredShots = data.shots.filter((shot) => new Date(stringToDate(shot.expiry_date)) < currentDate);
            return expiredShots.length;
          },
          sortable: true,
        },
        {
          name: "Status",
          selector: (data) => data.status == 0 ? <span class="status-inactive uppercase">INACTIVE</span>: data.status == 1 ? <span class="status-active uppercase">ACTIVE</span> :<span class="status-warning">Request Pending</span>,
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData != true ?(<div className="flex">
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewPetForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6  p-0 ml-1"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  indexFind(index);
                }}
                >
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-1/6 p-0 ml-1"
                data-bs-target={`#staticBackdrop`}
                data-index={index}
                onClick={(e)=>{
                  deleteData(index);
                }}
                >
    
                  <img src='/assets/svgs/system-trashcan.svg' />
                  
                </button>
                {row.status == 2 ?
                    <>
                      <button
                        type="button"
                        className="bg-transparent border-none md:w-1/6 p-0 ml-1"
                        data-bs-target={`#staticBackdrop`}
                        onClick={(e) => {
                          rejectData(index);
                        }}
                      >
                        <img src="/assets/img/system-reject.png" alt="Reject" />
                      </button>
                      <button
                        type="button"
                        className="bg-transparent border-none md:w-1/6 p-0 ml-1"
                        data-bs-target={`#staticBackdrop`}
                        onClick={(e) => {
                          approveData(index);
                        }}
                      >
                        <img src="/assets/img/system-approve.png" alt="Approve" />
                      </button>
                    </>
                   : null
                }

               
               
                 </div>):(
                  <>
                  <button type="button" class="bg-transparent border-none md:w-1/6  p-0"
                data-bs-target={`#ViewPetForm`}
                data-bs-toggle='modal'
                data-index={index}
                onClick={(e)=>{
                  viewData(index);
                }}
                >
    
                    <img src='/assets/svgs/system-view.svg' />
                  
                </button>
                  </>
                 )}
                {/* <div className="md:w-1/12">
                <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                  <i class="fa-regular fa-eye mr-6"></i>
                </button>
                </div> */}
              </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : tenantPets,
      };

      const exportPDF = ()=>{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Tenant Pets";
        const headers = [["Name","Gender","Type",'Breed','Date of Birth','Age','Status']];
    
        const data = tenantPets.map(elt => 
          [
          elt.name,
          petGender?.find(row=>elt.pet_gender_id == row?.id)?.gender,
          petType?.find(row=> elt.pet_type_id == row?.id)?.type,
          elt.breed,
          elt.date_of_birth,
          calculate_age(stringToDate(elt.date_of_birth)),
          elt.status == 0 ? "Inactive": elt.status == 1 ?"Active":null])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Pets.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = tenantPets.map(elt => 
          [
          elt.name,
          petGender?.find(row=>elt.pet_gender_id == row?.id)?.gender,
          petType?.find(row=> elt.pet_type_id == row?.id)?.type,
          elt.breed,
          elt.date_of_birth,
          calculate_age(stringToDate(elt.date_of_birth)),
          elt.status == 0 ? "Inactive": elt.status == 1 ?"Active":null])
        // Convert the data to a CSV string
    
        const headers = [ "Name","Gender","Type",'Breed','Date of Birth','Age','Status'];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Pets.csv");
        link.click();
      };
      const imageComponent = ({
          image = {},
          key = 0,
          src = "",
          inputFieldId = "",
          trashAble = false,
          onTrashClick = () => {},
          onEditClick = () => {},
          ...restProps
        }) => {
          const confirmEdit = (image) => {
            Swal.fire({
              title: 'Are you sure you want to edit this image?',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel'
            }).then((result) => {
              if (result.isConfirmed) {
                // Modify the image object here
                const editedImage = { ...image, title: 'New Title' };
                onEditClick(editedImage);
              }
            });
          };
        
          return image ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
            >
              <div className="relative h-fit w-full">
                <img
                  title={image?.title}
                  key={key}
                  alt="property image"
                  className="border block object-contain w-80 h-auto rounded-lg"
                  src={src}
                />
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
                >
                  {trashAble ? (
                    <img
                      title="Remove image"
                      type="button"
                      className="logo w-5 mx-auto my-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
        
                        if (fileInputField) {
                          fileInputField.files.forEach((file) => {
                            if (file !== image) {
                              dataTransfer.items.add(file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                  {/* <button
                    type="button"
                    title="Edit image"
                    className="logo w-5 mx-auto my-1"
                    onClick={() => confirmEdit(image)}
                  >
                    <img src="/assets/img/system-edit.png" alt="edit" />
                  </button> */}
                </div>
              </div>
            </div>
          ) : (
            <img {...restProps} />
          );
        };
        const fileComponent = ({
          file = null,
          title = null,
          key = 0,
          inputFieldId = "",
          trashAble = false,
          editAble = false,
          onTrashClick = () => {},
          onEditClick = () => {},
        }) =>
          file ? (
            <div
              onMouseEnter={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = false;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.childNodes[0].childNodes[1].hidden = true;
              }}
              className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
            >
              <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
                <div title={title} key={key} className="block min-w-[600rem] px-2">
                  <a href={`${imgUrl}/${file}`} target="_blank">
                    {title}
                  </a>
                </div>
                <div
                  hidden={true}
                  className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
                >
                  
                  {editAble ? (
                    <img
                      title="Edit file"
                      className="logo w-5 my-auto mx-1"
                      src="/assets/img/system-edit.png"
                      onClick={(e) => {
                        onEditClick();
                      }}
                      alt=""
                    />
                  ) : null}
                  {trashAble ? (
                    <img
                      title="Remove file"
                      className="logo w-5 my-auto mx-1"
                      src="/assets/img/trash-icon.png"
                      onClick={(e) => {
                        const fileInputField = document.getElementById(inputFieldId);
                        let dataTransfer = new DataTransfer();
        
                        if (fileInputField) {
                          fileInputField.files.forEach((input_file) => {
                            if (input_file !== file) {
                              dataTransfer.items.add(input_file);
                            }
                          });
                          fileInputField.files = dataTransfer.files;
                        }
                        onTrashClick(e);
                      }}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null;
         



          const getPetGenders = async () => {
            const response = await ApiRequest.get("/public/api/auth/get-pets-genders");
            if (response.status == 200) {
              setPetGender(response?.data?.data?.pets_genders);
            }
          };
          const getPetTypes = async () => {
            const response = await ApiRequest.get("/public/api/auth/get-my-pets-types");
            if (response.status == 200) {
              setPetType(response?.data?.data?.types);
            }
          };

          useEffect(() => {
            getPetGenders();
            getPetTypes();
            setTenantPet(initialPetData);
            setTenantPets(data);
            setOwnerSetting(ownerSettings)
          }, [])
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })


  return (
    <>
    <div
          class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id={`ViewPetForm`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="ViewPetFormLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalLabel"
                >
                  Pets
                </h5>
                <button
                  type="button"
                  class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setViewForm(false);
                    setTenantPet([]);
                    setTenantPetFile([]);
                    setTenantPetPicture([]);
                    setTenantPetPrivateFile([]);
                    setEditorData(null)
                    setEditorData2(null)
                }}
                >
                  <i className="fa fa-remove"></i>
                </button>
              </div>
              <div class="modal-body relative p-4">
              {viewForm == true ?
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="flex">
                    <div className="w-4/12 mr-3">
                    {tenantPet?.pictures?.length > 0 ?
                      (<>
                      <img className="logo w-full" src={`${imgUrl}/${tenantPet?.pictures[0]?.picture}`} alt="" />
                      </>)
                      :
                      (<img
                        className="logo w-full"
                        src="/assets/img/system-no-picture.png"
                        alt=""
                      />)
                      }
                    </div>
                    <div className="w-8/12">
                    {tenantPet?.name != "" ?<div className="flex">
                          <span className='capitalize'>{tenantPet?.name}</span>
                        </div> : null}
                        {tenantPet?.status !== "" && (
                          <div className="flex mt-2">
                            {tenantPet?.status === 1 && <span className='status-active uppercase'> Active</span>}
                            {tenantPet?.status === 0 && <span className='status-inactive uppercase'>Inactive</span>}
                            {tenantPet?.status === 2 && <span className='status-warning uppercase'> Request Pending</span>}
                          </div>
                        )}
                        <div className="flex mt-2">
                        {tenantPet?.pet_type_id != "" ?
                        <>
                        <span className='capitalize'>{petType?.map((type)=>{
                            if(tenantPet?.pet_type_id == type?.id)
                            {
                              return type?.type
                            }
                          })}</span>
                          {tenantPet?.pet_gender_id != "" ? <span className='ml-2'>|</span> :null}
                          </>
                          :null}
                        {tenantPet?.pet_gender_id != "" ?
                        <span className='capitalize ml-2'>{petGender?.map((gender)=>{
                            if(tenantPet?.pet_gender_id == gender?.id)
                            {
                              return gender?.gender
                            }
                          })}</span>
                          :null}
                        </div>
                        <div className="flex mt-2">
                          {tenantPet?.breed != "" ? <><span className='capitalize'>{tenantPet?.breed}</span>{tenantPet?.color != "" ? <span className='ml-2'>|</span> :null}</>:null}
                          {tenantPet?.color != "" ? <><span className='capitalize ml-2'>{tenantPet?.color}</span>{tenantPet?.weight != "" ? <span className='ml-2'>|</span> :null}</>:null}
                          {tenantPet?.weight != "" ? <span className='capitalize ml-2'>{tenantPet?.weight}</span>:null}
                        </div>
                        {tenantPet?.date_of_birth != "" ?
                        <div className="flex mt-2">
                          <span>{ShowFormatDate(tenantPet?.date_of_birth,ownerSetting?.date_format)}</span>
                          <span className='ml-2'>( {calculate_days_until_next_birthday(stringToDate(tenantPet?.date_of_birth))} )</span>
                        </div>:null}
                        <div className="flex mt-2">
                          {tenantPet?.license != "" ? <><span className='capitalize'>{tenantPet?.license}</span>{tenantPet?.location_of_license != "" ? <span className='ml-2'>|</span> :null}</>:null}
                          {tenantPet?.location_of_license != "" ? <><span className='capitalize ml-2'>{tenantPet?.location_of_license}</span></>:null}
                        </div>
                        <div className="flex mt-2">
                          {tenantPet?.deposit != "" ? <><span className='capitalize'>Deposit: {tenantPet?.deposit}</span>{tenantPet?.rent != "" ? <span className='ml-2'>|</span> :null}</>:null}
                          {tenantPet?.rent != "" ? <><span className='capitalize ml-2'>Rent: {tenantPet?.rent}</span></>:null}
                        </div>

                    </div>
                  </div>
                  {tenantPet?.public_note != "" ?
                  <>
                    <hr />
                    <h6>Notes:</h6>
                    <div className="flex">
                    <div dangerouslySetInnerHTML={{ __html: tenantPet?.public_note }} />
                    </div>

                  </>:null}
                  {tenantPet?.files?.length > 0 ?
                  <>
                    <hr />
                    <h6>Files:</h6>
                    {tenantPet?.files?.map((file)=>(
                    <div className="flex">
                      <a className='text-black no-underline' href={`${imgUrl}/${file?.file}`} download={`${imgUrl}/${file?.file}`} target="_blank">{file?.file}</a>
                    </div>))}

                  </>:null}
                  {tenantPet?.shots?.length > 0 ? <><hr /><Shots data={tenantPet?.shots} typeTrue={shotType} handleTypeTrue={handleTypeTrue} handleShotTrue={handleShotTrue} hiddenData={true} ownerSettings={ownerSettings} petType={tenantPet?.pet_type_id} petTypes={petType}/></>:null}
                </div>
              </div>:null}
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(false);
                                setTenantPet([]);
                                setTenantPetFile([]);
                                setTenantPetPicture([]);
                                setTenantPetPrivateFile([]);
                                setEditorData(null)
                                setEditorData2(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-pets.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Pets:
              </label>
            </div>
          </div>
          {showForm == false ? 
          <div className="flex flex-row-reverse justify-between items-center mt-3">
            <div className="flex justify-between items-center gap-4">
              <button
                type="button"
                className="btn3"
                hidden={hiddenData}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <i class="fa fa-plus"></i> Add Pet
              </button>
            </div>
          </div>:null}
              {showForm == true ?<div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/3">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status: <span title='Field is Required'>*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-status`}
                          required={false}
                          value={tenantPet?.status}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,status:parseInt(e.target.value)})
                          }}
                        >
                          <option value="">Select Status</option>
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                          <option value={2}>Request Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Pet Name:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-name`}
                          type="text"
                          placeholder="Scooby"
                          required={true}
                          value={tenantPet?.name}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,name:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Type:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4 mx-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-type`}
                          required={true}
                          value={tenantPet?.pet_type_id}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            const selectedPetType = petType.find(row => value === row.id);
                            
                            if (selectedPetType) {
                              const { default_deposit, default_rent } = selectedPetType;
                              setTenantPet({
                                ...tenantPet,
                                pet_type_id: value,
                                deposit: default_deposit,
                                rent: default_rent
                              });
                            }
                          }}
                          
                        >
                          <option value="">Select Type</option>
                          {petType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Gender:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet_gender_id`}
                          value={tenantPet?.pet_gender_id}
                          onChange={(e) => {
                          setTenantPet({...tenantPet,pet_gender_id:parseInt(e.target.value)});
                          }}
                        >
                          <option value="">Select Gender</option>
                          {petGender.map((gender) => (
                            <option value={gender?.id}>{gender?.gender}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Breed:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-breed`}
                          type="text"
                          placeholder="Labrador"
                          required={true}
                          value={tenantPet?.breed}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,breed:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Color:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-color`}
                          type="text"
                          placeholder="White"
                          required={true}
                          value={tenantPet?.color}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,color:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Weight:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-weight`}
                          type="text"
                          placeholder="15lbs"
                          required={false}
                          value={tenantPet?.weight}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,weight:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <DatePicker
                          value={stringToDate(tenantPet?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e);
                            const FullAge = [...petAge];
                            const TotalAge = calculate_age(e);
                            FullAge[0] = TotalAge;
                            // console.warn("FullAge", FullAge);
                            setPetAge(FullAge);
                            
                            // age[index].dAge.push(TotalAge)
                            // setAge({...age,dAge : TotalAge})
                            setTenantPet({...tenantPet,date_of_birth:date});
                           
                          }}
                        />
                      </div>
                      {petAge[0] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {petAge[0]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Support Animal:
                          <span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-1/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-support_animal`}
                          required={true}
                          value={tenantPet?.support_animal}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,support_animal:parseInt(e.target.value)});
                          }}
                        >
                          <option value="">Select</option>
                          <option value={0}>No</option>
                          <option value={1}>Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Pet Deposit:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="pet-deposit"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantPet?.deposit != null ? tenantPet?.deposit : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantPet({ ...tenantPet, deposit: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantPet({ ...tenantPet, deposit: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Pet Rent:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                      <CurrencyInput
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="pet-rent"
                          required={false}
                          type="text"
                          placeholder={`${ownerSettings?.currency_symbol} 100`}
                          value={tenantPet?.rent != null ? tenantPet?.rent : ""}
                          currencyCode={ownerSettings?.currency_format} // Set the appropriate currency code
                          currencySymbol={ownerSettings?.currency_symbol} // Set the appropriate currency symbol
                          onChange={(e) => {
                            setTenantPet({ ...tenantPet, rent: parseFloat(removeCurrencyAndCommas(e.target.value)) });
                          }}
                          onKeyPress={(e)=>{
                            if (e.key === '.') {
                              e.preventDefault();
                              const inputElement = e.target;
                              const inputValue = inputElement.value;
                              const selectionStart = inputElement.selectionStart;
                              const selectionEnd = inputElement.selectionEnd;
                        
                              // Insert the decimal point at the current cursor position
                              const updatedValue =
                                inputValue.substring(0, selectionStart) +
                                '.' +
                                inputValue.substring(selectionEnd);
                        
                              // Update the input value and move the cursor position
                              setTenantPet({ ...tenantPet, rent: updatedValue }, () => {
                                inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-4">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          License:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-license`}
                          type="text"
                          placeholder="12312"
                          required={false}
                          value={tenantPet?.license}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,license:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Location of License:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-location_of_license`}
                          type="text"
                          placeholder="Canada"
                          required={false}
                          value={tenantPet?.location_of_license}
                          onChange={(e) => {
                            setTenantPet({...tenantPet,location_of_license:e.target.value});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <Shots data={tenantPet?.shots} handleShotTrue={handleShotTrue} hiddenData={false} ownerSettings={ownerSettings} petType={tenantPet?.pet_type_id} petTypes={petType}/>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Pictures:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id={`pet-pictures`}
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantPetPicture?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantPetPicture.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1 + tenantPetPicture.length,
                                    url:imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...tenantPetPicture];
                                    pictureLists = [...pictureLists, ...newImages];
                                    setTenantPetPicture(pictureLists);
                                    let tenantCopy = [...tenantPetPicture];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        order_number: picObj.order_number,
                                        is_cover: picObj.is_cover,
                                      })
                                    );
                                    setTenantPet({ ...tenantPet, pictures: tenantCopy?.pictures,shots:tenantPetShots });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/5">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              tenantPetPicture?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div >
              {tenantPetPicture?.length ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`p-measurement-images-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantPetPicture?.map(
                          (imageObject, imageIndex) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: imageIndex + "-" + imageIndex,
                              src: imgUrl +"/"+imageObject?.picture,
                              inputFieldId: `p-measurement-pictures`,
                              trashAble: !hiddenData,
                              onTrashClick: () => {
                                const pictureLists = [
                                  ...tenantPetPicture,
                                ];
                                pictureLists.splice(imageIndex, 1);
                                tenantPet.pictures = pictureLists;
                                setTenantPetPicture(
                                  pictureLists
                                );
                                // setPropertyMaterials(measurements)
                                setTenantPet({
                                  ...tenantPet,
                                  pictures: pictureLists,
                                });
                              },
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Public Files:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id={`pet-public-files`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantPetFile?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantPetFile.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    title: res?.data?.data?.file,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...tenantPetFile];
                                    fileLists = [...fileLists, ...newImages];
                                    setTenantPetFile(fileLists);
                                    let tenantCopy = [...tenantPetFile];
                                    tenantCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        title: picObj.title,
                                      })
                                    );
                                    setTenantPet({ ...tenantPet, files: tenantCopy?.files,public_note:editorData });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantPetFile?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
              {tenantPetFile?.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantPetFile?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantPetFile,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantPet.files = fileLists;
                                setTenantPetFile(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...tenantPetFile];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    tenantPet.files[fileIndex].title = title;
                                    setTenantPetFile(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Public Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full m-auto mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Private Files:
                      </label>
                    </div>
                    <div className="md:w-2/5">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id={`pet-Private-files`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            tenantPetPrivateFile?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = tenantPetPrivateFile.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    file: res?.data?.data?.file,
                                    title: res?.data?.data?.file,
                                    url:fileBase64
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let fileLists = [...tenantPetPrivateFile];
                                    fileLists = [...fileLists, ...newImages];
                                    setTenantPetPrivateFile(fileLists);
                                    let tenantCopy = [...tenantPetPrivateFile];
                                    tenantCopy.files = fileLists.map(
                                      (picObj) => ({
                                        file: picObj.file,
                                        title: picObj.title,
                                      })
                                    );
                                    setTenantPet({ ...tenantPet, private_files: tenantCopy?.files });
                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                          />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              tenantPetPrivateFile?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
              {tenantPetPrivateFile?.length > 0 ? (
                <div>
                  <section className="overflow-hidden w-full text-gray-700 mt-4">
                    <div className="container w-full px-3 m-auto">
                      <div
                        id={`t-public-documents-block`}
                        className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                      >
                        {tenantPetPrivateFile?.map(
                          (fileObject, fileIndex) =>
                            fileComponent({
                              file: fileObject?.file,
                              title: fileObject?.title,
                              key: fileIndex,
                              inputFieldId: `t-public-files`,
                              trashAble: !hiddenData,
                              editAble: !hiddenData,
                              onTrashClick: () => {
                                const fileLists = [
                                  ...tenantPetPrivateFile,
                                ];
                                fileLists.splice(fileIndex, 1);
                                tenantPet.private_files = fileLists;
                                setTenantPetPrivateFile(fileLists);
                              },
                              onEditClick: () => {
                                const fileLists = [...tenantPetPrivateFile];
                                const currentTitle = fileLists[fileIndex].title || "";
                              
                                Swal.fire({
                                  title: "Edit File",
                                  html: `
                                    <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                  `,
                                  focusConfirm: false,
                                  preConfirm: () => {
                                    const title = document.getElementById('swal-input1').value;
                                    return title;
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const title = result.value;
                                    fileLists[fileIndex].title = title;
                                    tenantPet.private_files[fileIndex].title = title;
                                    setTenantPetPrivateFile(fileLists);
                                  }
                                });
                              }
                            })
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Pet Private Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData2}
                        onChange={(e) => {
                          setEditorData2(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
              <button className="btn3" type='button' 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                            if(tenantPet?.status != 0 && tenantPet?.status != 1 && tenantPet?.status != 2  ||!tenantPet?.name || !tenantPet?.pet_type_id || !tenantPet?.color || tenantPet?.support_animal != 0 && tenantPet?.support_animal != 1)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{
                            editSpacesData();
                          } 
                        }else {
                            if(tenantPet?.status != 0 && tenantPet?.status != 1 && tenantPet?.status != 2  ||!tenantPet?.name || !tenantPet?.pet_type_id || !tenantPet?.color || tenantPet?.support_animal != 0 && tenantPet?.support_animal != 1)
                            {
                              Toast.fire({
                                icon: 'warning',
                                title: 'Please fill in the required fields',
                              });
                            }
                            else{

                              bulkData();
                            }
                          }
                        }}>
                          {indexEdit !== undefined ? (
                            <><i className='fa fa-edit'></i>Update</>

                  ) : (
                      <> <i className='fa fa-save'></i>Save</>

                  )}
                        </button>
                        </div>
                        </div>
                        </div>
                </div>
              </div>:null}
              
         
          
          <hr />
         {tenantPets?.length > 0 ?<>
          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
      className="striped"
        columns={columns}
        data={tenantPets}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortFieldId={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  )
}
