import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { alertActions } from "../store/alert/alertSlice";

const useAlerts = () => {
  const dispatch = useDispatch();
  return {
    show: ({ message, type }) => {
      switch (type) {
        case "success":
          toast.success(message);
          break;
        case "error":
          toast.error(message);
          break;
      }
    },
    addSuccess: (message = "") => {
      dispatch(
        alertActions.createAlert({
          message: message,
          type: "success",
        })
      );
    },
    addFailure: (message = "") => {
      dispatch(
        alertActions.createAlert({
          message: message,
          type: "error",
        })
      );
    },
    remove: (alert) => {
      dispatch(alertActions.removeAlert(alert));
    },
  };
};

export default useAlerts;
