import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Swal from "sweetalert2";
import ApiRequest from "../../../utility/http";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Loading from "../../Loader/Loading";
export const MaterialTable = ({filterText,data}) => {

  const [preferenceData,setPreferenceData] = useState();
  const [loading,setLoading] = useState('loading');
  const [materialTypes,setMaterialTypes] = useState([]);
  const [materialLocations,setMaterialLocations] = useState([]);
  const [materialBrands,setMaterialBrands] = useState([]);
  const [materialIdentifiers,setMaterialIdentifiers] = useState([]);
  const [materialFinishes,setMaterialFinishes] = useState([]);
  const [materialUnitSoldBys,setMaterialUnitSoldBys] = useState([]);
  const [materialCoverageUnits,setMaterialCoverageUnits] = useState([]);
  const [materialStatus,setMaterialStatus] = useState([]);
  const [ownerSettings,setOwnerSettings] = useState();

  const [materials,setMaterials] = useState([]);
  const [bulkAction , setBulkAction] = useState({
    bulk_action:""
  });
  const PropertyID = localStorage.getItem('Property ID');

  const showMaterials = async()=>{
    const response = await ApiRequest.get('public/api/auth/get-my-materials')
    if(response.status == 200)
    {
      setMaterials(response?.data?.data)
      setLoading(undefined)
    }
  }
  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }
  const getMaterialTypes = async () => {
    const response = await ApiRequest.get("public/api/auth/get-material-types");
    if (response.status == 200) {
      setMaterialTypes(response.data?.data);
    }
  };
  const getMaterialLocations = async () => {
    const response = await ApiRequest.get(
      `/public/api/auth/get-properties-locations?property_id=${PropertyID}`
    );
    if (response.status == 200) {
      setMaterialLocations(response?.data?.data);
    }
  };
  const getMaterialBrands = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-brands"
    );
    if (response.status == 200) {
      setMaterialBrands(response.data?.data);
    }
  };

  const getMaterialIdentifiers = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-identifiers"
    );
    if (response.status == 200) {
      setMaterialIdentifiers(response.data?.data);
    }
  };
  const getMaterialFinishes = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-finishes"
    );
    if (response.status == 200) {
      setMaterialFinishes(response.data?.data);
    }
  };
  const getMaterialUnitSoldBys = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-units-sold-bys"
    );
    if (response.status == 200) {
      setMaterialUnitSoldBys(response.data?.data);
    }
  };
  const getMaterialCoverageUnits = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-coverage-units"
    );
    if (response.status == 200) {
      setMaterialCoverageUnits(response.data?.data?.units);
    }
  };
  const getMaterialStatus = async () => {
    const response = await ApiRequest.get(
      "public/api/auth/get-material-statuses"
    );
    if (response.status == 200) {
      setMaterialStatus(response.data?.data);
    }
  };
  const getMaterialStatusOptions = () => {
    const materialStatuses = materialStatus;
    return materialStatuses?.map((status) => ({ value: status.id, text: status.status }));
  };

  const deleteMaterial = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Delete</strong> the Material. Do you want to proceed? It would delete in any Properties/Units as well',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    });
  
    // If user confirms, Inactivate the property
    if (isConfirmed) {
      setLoading('loading');
      try {
        // const ArrayId = [];
        // ArrayId.push(id);
        const response = await ApiRequest.delete(
          `public/api/auth/delete-material?material_id=${id}`
          
        );
      showMaterials()
  
        // Show success message
        if(response.status == 200)
        {
          Swal.fire({
          title: 'Success!',
          html: 'The Material have been <strong>Deleted</strong>.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
  
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Deleting</strong> the Material. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  }
	const bulkChangeStatus = async () => {
    // Get the material status options
    const materialStatusOptions = materialStatus;
  
    // Show confirmation dialog with a custom select input
    const { isConfirmed, value } = await Swal.fire({
      title: 'Are you sure?',
      html: 'This will <strong>Change</strong> the Status. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const selectElement = Swal.getPopup().querySelector('#swal-material-status');
        return selectElement.value;
      },
      html: `
        <select id="swal-material-status" class="swal2-select">
          ${materialStatusOptions.map((option) => `<option value="${option.id}">${option.status}</option>`)}
        </select>
      `,
      focusConfirm: false,
    });
  
    // If user confirms and selects a status, inactivate the property
    const arrayId = [];
    selectedRows?.map((row) => {
      arrayId.push(row.id);
    });
  
    if (isConfirmed && value) {
      setLoading('loading');
      try {
        const response = await ApiRequest.post('public/api/auth/change-materials-status', {
          ids: arrayId,
          material_status_id: parseInt(value), // Use the selected value as the material_status_id
        });
        showMaterials();
  
        // Show success message
        if (response.status === 200) {
          Swal.fire({
            title: 'Success!',
            html: 'The Status have been <strong>Changed</strong>.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        // Show error message
        Swal.fire({
          title: 'Error!',
          html: 'An error occurred while <strong>Changing</strong> the Status. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading('');
      }
    }
  };
  const exportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Asset Materials";
    const headers = [["Name", "Type","Locations","Brand","Color/Pattern","Finish","Identifier","Vendor","Last Purchased","Price per Unit","Coverage per unit","On Hand","On-Hand Coverage","Status"]];

    const data = filteredData.map(elt => [elt.name, materialTypes?.find((type)=> type?.id == elt.material_type_id )?.type, materialLocations?.find((location)=>location?.id == elt.property_location_id)?.location,materialBrands?.find((brand)=>brand?.id == elt.material_brand_id)?.brand,elt.color,materialFinishes?.find((finish)=>finish?.id == elt.material_finish_id)?.finish,materialIdentifiers?.find((identifier)=>identifier?.id == elt.material_identifier_id)?.identifier,"",elt.last_purchased,elt.price_per_unit,elt.coverage_purchased,elt.material_on_hand,elt.coverage_on_hand,elt.status == 1 ? "In Use":elt.status == 2 ? "Phasing Out" : "Not In Use"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Asset Material.pdf")
  }
  const exportCSV = () => {
    // Get data from data table
    const TotalData = filteredData.map(elt => [elt.name, materialTypes?.find((type)=> type?.id == elt.material_type_id )?.type, materialLocations?.find((location)=>location?.id == elt.property_location_id)?.location,materialBrands?.find((brand)=>brand?.id == elt.material_brand_id)?.brand,elt.color,materialFinishes?.find((finish)=>finish?.id == elt.material_finish_id)?.finish,materialIdentifiers?.find((identifier)=>identifier?.id == elt.material_identifier_id)?.identifier,"",elt.last_purchased,elt.price_per_unit,elt.coverage_purchased,elt.material_on_hand,elt.coverage_on_hand,elt.status == 1 ? "In Use":elt.status == 2 ? "Phasing Out" : "Not In Use"]);
    // Convert the data to a CSV string

    const headers = ["Name", "Type","Locations","Brand","Color/Pattern","Finish","Identifier","Vendor","Last Purchased","Price per Unit","Coverage per unit","On Hand","On-Hand Coverage","Status"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Asset Material.csv");
    link.click();
  };
  const bulkExportPDF = ()=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Asset Materials";
    const headers = [["Name", "Type","Locations","Brand","Color/Pattern","Finish","Identifier","Vendor","Last Purchased","Price per Unit","Coverage per unit","On Hand","On-Hand Coverage","Status"]];

    const data = selectedRows.map(elt => [elt.name, materialTypes?.find((type)=> type?.id == elt.material_type_id )?.type, materialLocations?.find((location)=>location?.id == elt.property_location_id)?.location,materialBrands?.find((brand)=>brand?.id == elt.material_brand_id)?.brand,elt.color,materialFinishes?.find((finish)=>finish?.id == elt.material_finish_id)?.finish,materialIdentifiers?.find((identifier)=>identifier?.id == elt.material_identifier_id)?.identifier,"",elt.last_purchased,elt.price_per_unit,elt.coverage_purchased,elt.material_on_hand,elt.coverage_on_hand,elt.status == 1 ? "In Use":elt.status == 2 ? "Phasing Out" : "Not In Use"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Asset Material.pdf")
  }
  const bulkExportCSV = () => {
    // Get data from data table
    const TotalData = selectedRows.map(elt => [elt.name, materialTypes?.find((type)=> type?.id == elt.material_type_id )?.type, materialLocations?.find((location)=>location?.id == elt.property_location_id)?.location,materialBrands?.find((brand)=>brand?.id == elt.material_brand_id)?.brand,elt.color,materialFinishes?.find((finish)=>finish?.id == elt.material_finish_id)?.finish,materialIdentifiers?.find((identifier)=>identifier?.id == elt.material_identifier_id)?.identifier,"",elt.last_purchased,elt.price_per_unit,elt.coverage_purchased,elt.material_on_hand,elt.coverage_on_hand,elt.status == 1 ? "In Use":elt.status == 2 ? "Phasing Out" : "Not In Use"]);
    // Convert the data to a CSV string

    const headers = ["Name", "Type","Locations","Brand","Color/Pattern","Finish","Identifier","Vendor","Last Purchased","Price per Unit","Coverage per unit","On Hand","On-Hand Coverage","Status"];
    // let content = {
    //   head:headers,
    //   body:TotalData,
    // }
    TotalData.unshift(headers)
    const csv = Papa.unparse(TotalData);
    // Create a blob of the data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // Create a link to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Asset Material.csv");
    link.click();
  };




  
  const filteredColumns = [
    preferenceData?.name === 1
    ? {
        name: "Name",
        selector: (row) => row.name,
        sortable: preferenceData?.name === 1,
      }
    : null,
  preferenceData?.type === 1
    ? {
        name: "Type",
        selector: (row) => materialTypes?.find((type)=>type?.id == row.material_type_id)?.type,
        sortable: preferenceData?.type === 1,
      }
    : null,
  preferenceData?.locations === 1
    ? {
        name: "Location",
        selector: (row) => materialLocations?.find((location)=>location?.id == row.property_location_id)?.location,
        sortable: preferenceData?.locations === 1,
      }
    : null,
  preferenceData?.brand === 1
    ? {
        name: "Brand",
        selector: (row) => materialBrands?.find((brand)=>brand?.id == row.material_brand_id)?.brand,
        sortable: preferenceData?.brand === 1,
      }
    : null,
  preferenceData?.color === 1
    ? {
        name: "Color/Pattern",
        selector: (row) => row.color,
        sortable: preferenceData?.color === 1,
      }
    : null,
  
  preferenceData?.finish === 1
    ? {
        name: "Finish",
        selector: (row) => materialFinishes?.find((finish)=>finish?.id == row.material_finish_id)?.finish,
        sortable: preferenceData?.finish === 1,
      }
    : null,
  preferenceData?.identifier === 1
    ? {
        name: "Identifier",
        selector: (row) => materialIdentifiers?.find((identifier)=>identifier?.id == row.material_identifier_id)?.identifier,
        sortable: preferenceData?.identifier === 1,
      }
    : null,
  preferenceData?.vendor === 1
    ? {
        name: "Vendor",
        selector: (row) => "",
        sortable: preferenceData?.vendor === 1,
      }
    : null,
  preferenceData?.last_purchased === 1
    ? {
        name: "Last Purchased",
        selector: (row) => row.last_purchased,
        sortable: preferenceData?.last_purchased === 1,
      }
    : null,
  preferenceData?.price_per_unit === 1
    ? {
        name: "Price per Unit",
        selector: (row) => row.price_per_unit,
        sortable: preferenceData?.price_per_unit === 1,
      }
    : null,
  preferenceData?.coverage_per_unit === 1
    ? {
        name: "Coverage per Unit",
        selector: (row) => row?.coverage_purchased,
        sortable: preferenceData?.coverage_per_unit === 1,
      }
    : null,
  preferenceData?.on_hand === 1
    ? {
        name: "On Hand",
        selector: (row) => row?.material_on_hand,
        sortable: preferenceData?.on_hand === 1,
      }
    : null,
  preferenceData?.on_hand_coverage === 1
    ? {
        name: "On-Hand Coverage",
        selector: (row) => row?.coverage_on_hand,
        sortable: preferenceData?.on_hand_coverage === 1,
      }
    : null,
    preferenceData?.status === 1
    ? {
        name: "Status",
        selector: (row) => row.material_status_id == 1 ? <span class="status-active">
          <button className="uppercase" type="button">
          In use
          </button>
          </span> 
          : row.material_status_id == 2 ?
        <span class="status-warning">
          <button className="uppercase" 
          type="button">
                  Phasing out
                  </button>
          </span>
          :
          <span class="status-inactive">
          <button className="uppercase" 
          type="button">
                  Not in use
                  </button>
          </span>,
        sortable: preferenceData?.status === 1,
      }
    : null,
    // {
    //   name: "Property Type",
    //   selector: (row) => {
    //     return newArray2?.map((item, index) => {
    //       if (row.type_id === item.id) {
    //         return (row.name = item.type);
    //       }
    //     });
    //   },
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
           <div className="flex">
            <Link to={`/assets/show-material//${row.id}`}>
            
                <button type="button" class="bg-transparent border-none md:w-6  p-0" title="View">
    
                    <img src='/assets/img/system-view.png' />
                  
                </button>
            </Link>
            <Link to={`/assets/edit-material/${row.id}`}>

                <button type="button" class="bg-transparent border-none md:w-6 p-0"  title="Edit">
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
            </Link>              
               

                <button type="button" onClick={()=>{deleteMaterial(row.id)}} class="bg-transparent border-none md:w-6 p-0" title="Delete">
    
                  <img src='/assets/img/system-trashcan.png' />
                  
                </button>

                {/* {row.is_active == 1 ?
                <button type="button" onClick={()=>{
                  makeInActive(row.id)
                }} class="bg-green-700 border-none font-bold md:w-12 p-0">
    
                  ACTIVE
                  
                </button>
                :
                <button type="button" onClick={()=>{
                  makeActive(row.id)
                }} class="bg-red-700 border-none font-bold md:w-16 p-0">
    
                  INACTIVE
                  
                </button>} */}
               
               
                 </div>
          </>
        );
      },
    },
  ];

  const columns = filteredColumns.filter((column) => column !== null && column.selector !== null);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleLogSelectedRows = () => {
    console.log(selectedRows);
  };
  let filteredData = '';
  filteredData = materials?.filter(item =>
    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())));

  useEffect(() => {
    setPreferenceData(data);
  }, [data])

  useEffect(()=>{
    showMaterials();
    getOwnerSettings();
    getMaterialTypes();
    getMaterialLocations();
    getMaterialIdentifiers();
    getMaterialFinishes();
    getMaterialBrands();
    getMaterialUnitSoldBys();
    getMaterialCoverageUnits();
    getMaterialStatus();
  },[])
    const tableData = {
        columns : columns,
        data : materials,
        fixedHeader: true,
        fixedHeaderScrollHeight: '500px',
        sortFieldId: 1,
        sortDirection:"asc"
      }

      const materialsInuse = materials.filter((material)=>material.material_status_id == 1).length;
      const materialsPhasingOut = materials.filter((material)=>material.material_status_id == 2).length;
      const materialsNotInUse = materials.filter((material)=>material.material_status_id == 3).length;
  return (
    <>
    {loading === 'loading' ? (
        <Loading />
      ) : (
        <>
        <div className="flex md:w-full mt-3">
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-5 mt-2 data">Total Materials: {materials?.length}</span>
            <span className="mr-5 mt-2 data">Total IN-USE Materials: <span className="text-green-600">{materialsInuse}</span></span>
            <span className="mr-5 mt-2 data">Total PHASING OUT Materials: <span className="text-yellow-400">{materialsPhasingOut}</span></span>
            <span className="mr-5 mt-2 data">Total NOT IN USE Materials: <span className="text-red-600">{materialsNotInUse}</span></span>

            <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} />
            <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} />


          </div>
        </div>
        {/* <div className="flex-1">
        <div className="flex">
        <label className="block capitalize text-gray-700 font-bold mb-2 mr-2 mt-2">Filter: </label>
        <select
        className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        value={selectFilter}
        id="filtrationData"
        onChange={(e)=>{
          setSelectFilter(parseInt(e.target.value));
          filtration(parseInt(e.target.value));
        }}
        >
          <option value="3">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        </div>
        </div> */}
      </div>
    <DataTableExtensions filter={false} {...tableData}>
        <DataTable
        className="striped"
          // columns={columns}
          // data={newArray1}
          // pagination
          // fixedHeader
          // fixedHeaderScrollHeight="500px"
          selectableRows
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={handleRowSelected}
          // defaultSortFieldId={1}
        />
        </DataTableExtensions>
        {/* <button onClick={handleLogSelectedRows}>Log Selected Rows</button> */}
        <div class="flex justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ?<div className="md:flex md:items-center w-full md:w-8/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected value=' '>Select</option>
                <option value={"CHANGE STATUS"}>CHANGE STATUS</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-1/2 ml-1">
               <button
                  type="button"
                  className="btn2"
                  onClick={bulkAction?.bulk_action == "CHANGE STATUS" ? bulkChangeStatus : bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV :  null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
            </div>
        </>
    )}
    </>
  )
}
