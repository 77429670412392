import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import $ from 'jquery';
import { ProgressBar } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const Lightbox = ({data,handleFiles,hiddenData,handleLogDelete}) => {

  console.warn("FILES",data);
    const [showLightbox, setShowLightbox] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [Files,setFiles] = useState([]);
    
    const sideBar = ()=>{
      const imageSidebar = $('.image-sidebar');
      const lightBox = $('.lightbox-container');
      if (imageSidebar.hasClass('open')) {
        imageSidebar.css('display', 'none');
        lightBox.css({ 'width': '100vw'})
      } else {
        imageSidebar.css({
          "height": "100vh",
          "background": "#6c757d",
        'color':"white",
        "width": "300px",
        "position": "fixed",
        "right": "0",
        "top": "0",
        "overflow": "hidden  scroll",
        "display":" block",
        // "grid-template-columns":" repeat(2, 1fr)",
        // "grid-gap":" 10px",
        'padding':"10px",
        "padding-bottom": "200px",
        "z-index": "99999999",
      });
      lightBox.css({'width': 'calc(100vw - 300px)'})
      }
      imageSidebar.toggleClass('open');
    }
    
    
    
      const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    
      const selectImage = (index)=>{
        setSelectedImageIndex(index);
      }
    
      const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowLightbox(true);
      };
    
      const handlePrevClick = () => {
        if (selectedImageIndex === 0) {
          return;
        }
    
        setSelectedImageIndex(selectedImageIndex - 1);
      };
    
      const handleNextClick = () => {
        if (selectedImageIndex === Files.length - 1) {
          return;
        }
    
        setSelectedImageIndex(selectedImageIndex + 1);
      };
      
      const [scale, setScale] = useState(1);
      const handleZoomIn = () => {
        if(scale <= 2)
        {
          setScale(scale + 0.1);
        }
      };
    
      const handleZoomOut = () => {
        if (scale > 0.2) {
          setScale(scale - 0.1);
        }
      };
      const handleKeyPress = (e) => {
        if (e.key === 'ArrowRight') {
          // move to next image
          if(Files.length > selectedImageIndex +1){
          setSelectedImageIndex(selectedImageIndex+1);
          }
        } else if (e.key === 'ArrowLeft') {
          // move to previous image
          if(0 < selectedImageIndex){
            setSelectedImageIndex(selectedImageIndex-1);
          }
        } else if (e.key === '+' || e.key === '=') {
          // zoom in
          handleZoomIn();
        } else if (e.key === '-') {
          // zoom out
          handleZoomOut();
        }
        else if (e.key === 'Esc' || e.key === 'Escape') {
          // zoom out
          setShowLightbox(false);
        }
      };
      const handleDelete = (index) => {
        const deletedFile = Files[index]?.picture;
        console.warn(true);
        handleLogDelete(deletedFile);
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(index, 1);
          handleFiles(newFiles);
          return newFiles;
        });
      };
      
      
      const handleEdit = (index) => {
        const file = Files[index];
        Swal.fire({
          title: 'Edit Image',
          customClass:{
            confirmButton:"btn2"
          },
          html: `
            <input id="swal-input1" class="swal2-input" placeholder="Title" value="${file.title}">
            <input id="swal-input2" class="swal2-input" placeholder="Caption" value="${file.caption}">
          `,
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById('swal-input1').value,
              document.getElementById('swal-input2').value,
            ];
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const [title, caption] = result.value;
            const newFiles = [...Files];
            // handleLogEdit(newFiles[index])
            newFiles[index] = {
              ...file,
              title: title,
              caption: caption,
            };
            setFiles(newFiles);
            handleFiles(newFiles);
          }
        });
      };
      useEffect(()=>{
        setFiles(data);
      },[data])
      useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      });
      const [isSlideshowActive, setIsSlideshowActive] = useState(false);
      useEffect(() => {
        const handleSlideshow = () => {
          // move to next image
          // const currentIndex = images.findIndex((image) => image === selectedImage);
          if (selectedImageIndex < Files.length - 1) {
            setSelectedImageIndex(selectedImageIndex + 1);
          } else {
            setSelectedImageIndex(0);
          }
        };
    
        let intervalId;
    
        if (isSlideshowActive) {
          intervalId = setInterval(handleSlideshow, 3000);
        }
    
        return () => {
          clearInterval(intervalId);
        };
      }, [selectedImageIndex, isSlideshowActive]);
      const handleDragEnd = (result) => {
        if (!result.destination) {
          // Return if the item was dropped outside a valid droppable area
          return;
        }
      
        const { source, destination } = result;
      
        // Create a copy of the Files array
        const newFiles = [...Files];
      
        // Reorder the array based on the drag and drop result
        const [draggedImage] = newFiles.splice(source.index, 1);
        newFiles.splice(destination.index, 0, draggedImage);
      
        // Update the order number based on the new position
        newFiles.forEach((file, index) => {
          file.order_number = index + 1;
        });
      
        // Set the updated Files array
        setFiles(newFiles);
      };
  return (
    <>
    {hiddenData == false ? <DragDropContext onDragEnd={handleDragEnd}>
  <div className="flex flex-wrap mt-3 gallery">
    <Droppable droppableId="image-gallery">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="grid-gallery" // Updated class for grid view
        >
          {Files?.map((file, index) => (
            <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="grid-item" // Updated class for grid view
                  style={{ ...provided.draggableProps.style }}
                >
                  <div className="image-container">
                    <a
                      href={`https://apis.rentblaze.com/public/uploads/${file.picture}`}
                      key={index}
                      data-fancybox={`gallery-${index}`}
                      className={`img-${index}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleImageClick(index);
                      }}
                    >
                      <img
                        src={`https://apis.rentblaze.com/public/uploads/${file.picture}`}
                        className="px-2 mb-2"
                        width={100}
                        height={50}
                        title={`${file.title}`}
                      />
                    </a>
                  </div>
                  <div className="action-buttons">
                    <button
                      type="button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleEdit(index)}
                    >
                      <img
                        title="Edit image details"
                        className="logo w-5 mx-3 my-1"
                        src="/assets/img/system-edit.png"
                      />
                    </button>
                    <button
                      type="button"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(index)}
                    >
                      <img
                        title="Remove image"
                        className="logo w-5 mx-3 my-1"
                        src="/assets/svgs/system-trashcan.svg"
                      />
                    </button>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
</DragDropContext>:
<>
<div className="flex flex-wrap mt-3 gallery">
  {Files?.map((file, index) => (
    <div key={index} className="mt-3 mb-3" style={{ position: 'relative' }}>
      <>
        <a
          href={`https://apis.rentblaze.com/public/uploads/${file.picture}`}
          key={index}
          data-fancybox={`gallery-${index}`}
          className={`img-${index}`}
          onClick={(e) => {
            e.preventDefault();
            handleImageClick(index);
          }}
        >
          <img
            src={`https://apis.rentblaze.com/public/uploads/${file.picture}`}
            className="px-2 mb-2"
            width={100}
            height={50}
            title={`${file.title}`}
          />
        </a>
      </>
      <button
        type="button"
        style={{ position: 'absolute', top: '70%', right: '9%', cursor: 'pointer', zIndex: "999" }}
        hidden
        onClick={() => handleDelete(index)}
      >
        <img title="Remove image" className="logo w-5 mx-3 my-1" src="/assets/svgs/system-trashcan.svg" />
      </button>
      <button
        type="button"
        style={{ cursor: "pointer", position: 'relative', zIndex: "999" }}
        hidden
        onClick={() => handleEdit(index)}
      >
        <img title="Edit image details" className="logo w-5 mx-3 my-1" src="/assets/img/system-edit.png" />
      </button>
    </div>
  ))}
</div>

</>
}





     {showLightbox && (
          <>
          <div className={`image-sidebar`}>
          <div className="image-sidebar-header ">
            {/* <button className="menu-icon" onClick={sideBar}>
              <i className="fa fa-bars"></i>
            </button> */}
            <h2 className="text-white text-center">All Images</h2>
          </div>
          <div className="image-sidebar-content" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '10px',
            width: '100%',
          }}>
            {Files?.map((image,index) => (
              <img
                key={index}
                src={`https://apis.rentblaze.com/public/uploads/${image?.picture}`}
                alt=""
                className={`img-sidebar-image ${selectedImageIndex === index ? 'border border-slate-50 p-3' : ''}`}
                onClick={() => selectImage(index)}
              />
            ))}
          </div>
        </div>
        <div className="lightbox-container">
          <div className="lightbox">
            <span>{`${selectedImageIndex + 1}/${Files?.length}`}</span>
          <div className="flex flex-row-reverse justify-between items-center mr-4">
                    <div className="flex justify-between items-center gap-4">
            <button type="button" onClick={handleZoomIn} title="Zoom In"><i className="fa fa-search-plus"></i></button>
            <button type="button" onClick={handleZoomOut} title="Zoom Out"><i className="fa fa-search-minus"></i></button>
            <button type="button" onClick={() => setIsSlideshowActive(!isSlideshowActive)}>
              {isSlideshowActive ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
            </button>
            <button type="button" className="menu-icon" onClick={sideBar} title="All images">
              <i className="fa fa-bars"></i>
            </button>
            <button type="button" onClick={() => setShowLightbox(false)} title="Close"><i className="fa fa-close"></i></button>
            </div>
            </div>
            <div className="light">
              <button type="button" onClick={handlePrevClick} title="Prev"><i className="fa fa-angle-left"></i></button>
            <img src={`https://apis.rentblaze.com/public/uploads/${Files[selectedImageIndex]?.picture}`} alt={`Image ${selectedImageIndex}`} style={{ transform: `scale(${scale})` }} />
            <button type="button" onClick={handleNextClick} title="Next"><i className="fa fa-angle-right"></i></button>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  )
}
