import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";

// import Loading from "../Loader/Loading";
import Loading from "../Loader/Loading";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
const RolesTable = ({userRoles, status}) => {
    console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "User Roles",
      selector: (row) => {
        return row.role
      },
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.is_active===1?"Active":"Inactive",
    // },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
               <Link to={`/edit-property/${row.id}`} style={{textDecoration:"none"}}>
            <button class="bg-gray-300 py-1 mr-2  w-24 flex justify-center align-items-center rounded text-black-800">
              <i class="fa-solid fa-pen mr-6"></i>
              <span>Edit</span>
            </button>
            </Link>
            <Link to={`/show-property/${row.id}`} style={{textDecoration:"none"}}>
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
                <span>Delete</span>
              </button>
            </Link>
          </>
        );
      },
    },
  ];

 

  return (
    <>
      {status !== "success" ? (
        <Loading />
      ) : (
        <DataTable
          columns={columns}
          data={userRoles}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="500px"
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          defaultSortFieldId={1}
        />
      )}
    </>
  );
};

export default RolesTable;