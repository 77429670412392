import { createSlice } from "@reduxjs/toolkit";
import { parkingGetPrefix, parkingGetSuffix } from "./thunk";
const parkingSlice = createSlice({
  name: "parking",
  initialState: {
    status: null,
    parkingPrefixes: {},
    parkingSuffixes: {},
  },
  extraReducers: {
    [parkingGetPrefix.pending]: (state) => {
      state.status = "loading";
    },
    [parkingGetPrefix.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.parkingPrefixes = payload;
    },
    [parkingGetPrefix.rejected]: (state, _error) => {
      state.status = "failed";
      state.parkingPrefixes = {};
    },
    [parkingGetSuffix.pending]: (state) => {
      state.status = "loading";
    },
    [parkingGetSuffix.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.parkingSuffixes = payload;
    },
    [parkingGetSuffix.rejected]: (state, _error) => {
      state.status = "failed";
      state.parkingSuffixes = {};
    },
  },
});

export default parkingSlice.reducer;
