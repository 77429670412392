import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import ReactDatePicker from "react-datepicker";
import SignaturePad from "react-signature-pad";
import { useRowSelect } from "react-table";
import Webcam from "react-webcam";
import ReactSignatureCanvas from "react-signature-canvas";
import "../../components/button.css";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loading";
import imgUrl from "../ImageUrl";
import { EmergencyContacts } from "./EmergencyContacts";
import { Interactions } from "./Interactions";
import { References } from "./References";
import { Employments } from "./Employments";
import { OtherLivings } from "./OtherLivings";
import { Children } from "./Children";
import { Vehicles } from "./Vehicles";
import { Pets } from "./Pets";
import { Keys } from "./Keys";
import { LoanableItems } from "./LoanableItems";
import { TenantNotifications } from "./TenantNotifications";
import { Lightbox } from "../lightbox/Lightbox";
import useAlerts from "../../utility/alerts";
import { Tooltip } from "../Loader/Tooltip";
import Swal from "sweetalert2";
import useAllowedPicMimeTypes from "../genericFunctions.js/picMimeType";
import useAllowedMimeTypes from "../genericFunctions.js/mimeType";
import { DatePicker } from "../genericFunctions.js/DatePicker";

export const EditTenantForm = (
  {
    initialData = {},
  validationData = {},
  verticalScroll = 0,
  handleFirstName,
  handleLastName
}
) => {
  // console.log("initialData",initialData)

  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialPictureData = {
    picutres: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
      order_number: "",
    },
  };

  const initialNoticeData = {
    title: "",
    notification: "",
    is_popup_notification: "",
    posting_date: "",
    posting_time: "",
  };
  const initialEmergencyContactData = {
    firstname: "",
    lastname: "",
    user_relationship_id: null,
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
  };
  const initialAddressData = {
    address_line1: "",
    address_line2: "",
    city: "",
    country:"",
    state: "",
    zip_code: "",
  };
  const initialNumberData = [
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
  ];
  const initialInteractionData = {
    date: "",
    time: "",
    interaction_type_id: "",
    other_type_description: "",
    notes: "",
    files: [],
  };
  const initialKeyData = {
    key_id: "",
    assigned_date: "",
    assigned_verification_picture: "",
    is_lost_damaged: 0,
    lost_damaged_date: "",
    send_replacement_cost_invoice: 0,
    replacement_additional_fees: 0,
    replacement_reason: "",
    is_returned: 0,
    returned_date: "",
    returned_verification_picture: "",
    notes: "",
    replacement_files: [],
  };
  const initialItemData = {
    loanable_item_id: "",
    loanable_item_serial_number_id: "",
    assigned_date: "",
    assigned_verification_picture: "",
    is_lost_damaged: 0,
    lost_damaged_date: "",
    send_replacement_cost_invoice: 0,
    replacement_additional_fees: 0,
    replacement_reason: "",
    is_returned: 0,
    returned_date: "",
    returned_verification_picture: "",
    notes: "",
    replacement_files: [],
    item_given_pictures: [],
    item_return_pictures: [],
  };
  const initialVehicleData = {
    status: 1,
    year: "",
    vehicle_make_id: null,
    vehicle_model_id: null,
    color: "",
    vin: "",
    license_plate: "",
    assign_parking_space: 0,
    property_parking_space_id: null,
    pictures: [],
    files: [],
    notes: "",
  };
  const initialChildrenData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    gender_id: 1,
    date_of_birth: "",
    public_note: "",
    private_note: "",
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialShotData = {
    type: "",
    is_shot_applied: 1,
    applied_date: "",
    expiry_date: "",
    proof_picture: "",
    is_approved_by_owner: 1,
    is_rejected_by_owner: 0,
    rejected_reason: "",
  };
  const [tenantShot, setTenantShot] = useState([]);
  // console.warn("tenantShot",tenantShot)
  const initialPetData = {
    status: 1,
    name: "",
    pet_type_id: 1,
    pet_gender_id: 1,
    breed: "",
    color: "",
    weight: "",
    date_of_birth: "",
    support_animal: 0,
    deposit: 0,
    rent: 0,
    license: "",
    location_of_license: "",
    public_note: "",
    private_note: "",
    shots: [],
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialCreateLogData={
    role_id:parseInt(localStorage.getItem("Role")),
    firstname:0,
    middlename:0,
    lastname:0,
    address_line1:0,
    address_line2:0,
    country_code_1:0,
    phone_number_1:0,
    number_type_1:0,
    country_code_2:0,
    phone_number_2:0,
    number_type_2:0,
    country_code_3:0,
    phone_number_3:0,
    number_type_3:0,
    email:0,
    country:0,
    city:0,
    state:0,
    zip_code:0,
    dl_id_picture:0,
    dln_id:0,
    dln_id_issue_country:0,
    dln_id_issue_state:0,
    pictures:{
      added:[],
      deleted:[],
  },
    ssn:0,
    gender_id:0,
    ethnicity_id:0,
    date_of_birth:0,
    moveout_address1:0,
    moveout_address2:0,
    moveout_email:0,
    moveout_number:0,
    moveout_number_type:0,
    moveout_country:0,
    moveout_city:0,
    moveout_state:0,
    moveout_zip_code:0,
  };
  const initialOtherLivingData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    date_of_birth: "",
    gender_id: 1,
    user_relationship_id: 1,
    other_relationship: "",
    additional_deposit: 0,
    additional_rent: 0,
    reason_for_movein: "",
    public_note: "",
    private_note: "",
    pictures: [],
    files: [],
    private_files: [],
  };
  const initialEmploymentData = {
    company_name: "",
    position: "",
    annual_salary: "",
    start_year: "",
    end_year: "",
    present: 0,
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    website: "",
    supervisor: "",
    number: "",
    number_type: "",
    leaving_reason: "",
    notes: "",
  };
  const initialReferenceData = {
    firstname: "",
    lastname: "",
    user_reference_relationship_id: 1,
    other_reference_relationship: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    notes: "",
  };
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  const initialLogData = {
    added:[],
    deleted:[]
    }
    const Alert = useAlerts();
  const [createLog,setCreateLog] = useState();
  const [createLogPictures,setCreateLogPictures] = useState();
  const [createLogAddPictures,setCreateLogAddPictures] = useState([]);
  const [createLogEditPictures,setCreateLogEditPictures] = useState();
  const [createLogDeletePictures,setCreateLogDeletePictures] = useState([]);
  const [TenantFormImagesWithUrl, setTenantFormImagesWithUrl] = useState([]);
  const formElement = document.getElementById("add-tenant-form");
  const formSubmitButton = document.getElementById("form-submit-button");
  const [lastEndLine, setLastEndLine] = useState();
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const [dlidPicture, setDlidPicture] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState();
  const [editorData, setEditorData] = useState();
  const [editorData1, setEditorData1] = useState();
  const [editorData2, setEditorData2] = useState();
  const [editorData3, setEditorData3] = useState();
  // console.log("createLogPictures", createLog);
  console.log("editorData", formData);
  // console.log("editorData1", editorData1);
  // console.log("editorData2", editorData2);
  // console.log("editorData3", editorData3);
  const [securedFields, setSecuredFields] = useState({
    ssn: "password",
    dln_id: "password"
  });
  const [tenantNotification, setTenantNotification] = useState([]);
  const [propertyPublicDocumentsWithUrl, setPropertyPublicDocumentsWithUrl] =
    useState([]);
  const [propertyManagerDocumentsWithUrl, setPropertyManagerDocumentsWithUrl] =
    useState([]);
  const [propertyStaffDocumentsWithUrl, setPropertyStaffDocumentsWithUrl] =
    useState([]);
  const [propertyOwnerDocumentsWithUrl, setPropertyOwnerDocumentsWithUrl] =
    useState([]);
  const [tenantAddress, setTenantAddress] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const getCountries = async () => {
    const response = await ApiRequest.get("public/api/auth/get-countries");
    if (response.status == 200) {
      setCountries(response.data?.data?.countries);
    }
  };
  const getStates = async (id) => {
    const response = await ApiRequest.get(`public/api/auth/get-states?country_id=${id}`);
    if (response.status == 200) {
      setStates(response.data?.data?.states);
    }
  };
  const getCities = async (data) => {
    const state = states?.map((col) =>{
      if(col?.id == data)
      {
        return col?.state_code
      }
    })
    const filteredArr = state.filter((value) => value !== undefined);
    const stateCode = filteredArr.join(', ');
    
    console.warn('data', data);
    console.warn('states', states);
    console.warn('state_code', stateCode);
    
    const response = await ApiRequest.get(`public/api/auth/get-cities?state_code=${stateCode}`);
    
    if (response.status == 200) {
      setCities(response.data?.data?.cities);
    }
  };

  const [tenantShotPicture, setTenantShotPicture] = useState([]);
  const [number, setNumber] = useState([]);
  const [tenantEmergencyContacts, setTenantEmergencyContacts] = useState([]);

  const [tenantInteraction, setTenantInteraction] = useState([]);
  const [tenantInteractionFiles, setTenantInteractionFiles] = useState([]);
  // console.warn("tenantInteractionFiles",tenantInteractionFiles);

  const [tenantKey, setTenantKey] = useState([]);
  const [tenantKeyFiles, setTenantKeyFiles] = useState([]);
  const [tenantKeyPicture, setTenantKeyPicture] = useState([]);
  const [tenantKeyReturnPicture, setTenantKeyReturnPicture] = useState([]);

  const [tenantItem, setTenantItem] = useState([]);
  const [tenantItemFiles, setTenantItemFiles] = useState([]);
  const [tenantItemPicture, setTenantItemPicture] = useState([]);
  const [
    tenantItemRetrunVerificationPicture,
    setTenantItemRetrunVerificationPicture,
  ] = useState([]);
  const [tenantItemGivenPicture, setTenantItemGIvenPicture] = useState([]);
  const [tenantItemReturnPicture, setTenantItemReturnPicture] = useState([]);

  const [tenantVehicle, setTenantVehicle] = useState([]);
  const [tenantVehicleFiles, setTenantVehicleFiles] = useState([]);
  const [tenantVehiclePicture, setTenantVehiclePicture] = useState([]);

  const [tenantChildren, setTenantChildren] = useState([]);
  const [tenantChildrenPicture, setTenantChildrenPicture] = useState([]);
  const [tenantChildrenFile, setTenantChildrenFile] = useState([]);
  const [tenantChildrenPrivateFile, setTenantChildrenPrivateFile] = useState(
    []
  );

  const [tenantPet, setTenantPet] = useState([]);
  const [tenantPetPicture, setTenantPetPicture] = useState([]);
  const [tenantPetFile, setTenantPetFile] = useState([]);
  const [tenantPetPrivateFile, setTenantPetPrivateFile] = useState([]);

  const [tenantOtherLiving, setTenantOtherLiving] = useState([]);
  const [tenantOtherLivingPicture, setTenantOtherLivingPicture] = useState([]);
  const [tenantOtherLivingFile, setTenantOtherLivingFile] = useState([]);
  const [tenantOtherLivingPrivateFile, setTenantOtherLivingPrivateFile] =
    useState([]);

  const [tenantEmployment, setTenantEmployment] = useState([]);
  const [loading,setLoading] = useState();

  const [tenantReference, setTenantReference] = useState([]);

  const [age, setAge] = useState([]);
  const [childrenAge, setChildrenAge] = useState([]);
  const [petAge, setPetAge] = useState([]);

  const [genderPreference, setGenderPreference] = useState([]);
  const [gender, setGender] = useState([]);
  const [userRelationship, setUserRelationship] = useState([]);
  const [interactionType, setInteractionType] = useState([]);
  const [vehicleMake, setVehicleMake] = useState([]);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [petGender, setPetGender] = useState([]);
  const [petType, setPetType] = useState([]);
  const [userReferenceRelationship, setUserReferenceRelationship] = useState(
    []
  );
  const [ethnicity, setEthnicity] = useState([]);
  const [moveoutReason, setMoveoutReason] = useState([]);
  const [key, setKey] = useState([]);
  const [loanableItem, setLoanableItem] = useState([]);
  const [prefix, setPrefix] = useState([]);
  const [suffix, setSuffix] = useState([]);
  const [keyName,setKeyName] = useState([]);
  const [replacementCost,setReplacementCost] = useState([]);

  const [newSerialNumber, setNewSerialNumber] = useState({
    id: null,
    loanable_item_id: null,
    serial_numbers: "",
  });
  const picTypes = useAllowedPicMimeTypes();
      const fileTypes = useAllowedMimeTypes();
  const [imgSrc, setImgSrc] = useState(null);
  const [returnImgSrc, setReturnImgSrc] = useState(null);
  const keyWebcamRef = React.useRef(null);
  const returnKeyWebcamRef = React.useRef(null);
  const keyTenantCapture = React.useCallback(async () => {
    const imageSrc = keyWebcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [keyWebcamRef]);
  const ReturnKeyTenantCapture = React.useCallback(async () => {
    const imageSrc = returnKeyWebcamRef.current.getScreenshot();
    setReturnImgSrc(imageSrc);
  }, [returnKeyWebcamRef]);
  const [itemImgSrc, setItemImgSrc] = useState(null);
  const [returnItemImgSrc, setReturnItemImgSrc] = useState(null);
  const ItemWebcamRef = React.useRef(null);
  const returnItemWebcamRef = React.useRef(null);
  const ItemTenantCapture = React.useCallback(async () => {
    const imageSrc = ItemWebcamRef.current.getScreenshot();
    setItemImgSrc(imageSrc);
  }, [ItemWebcamRef]);
  const ReturnItemTenantCapture = React.useCallback(async () => {
    const imageSrc = returnItemWebcamRef.current.getScreenshot();
    setReturnItemImgSrc(imageSrc);
  }, [returnItemWebcamRef]);
  const [ip, setIp] = useState("");

  //  METHODS
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.error(error));
  }, []);
  const keyVerification = useRef(null);
  const keyVerificationClear = () => {
    keyVerification.current.clear();
  };
  const keyVerificationReturn = useRef(null);
  const keyVerificationReturnClear = () => {
    keyVerificationReturn.current.clear();
  };
  const itemVerification = useRef(null);
  const itemVerificationClear = () => {
    itemVerification.current.clear();
  };
  const itemVerificationReturn = useRef(null);
  const itemVerificationReturnClear = () => {
    itemVerificationReturn.current.clear();
  };
  const signaturePad = useRef(null);
  const clear = () => {
    signaturePad.current.clear();
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };
  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit board icon"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={`${imgUrl}/${image}`}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
      file = null,
      title = null,
      key = 0,
      inputFieldId = "",
      trashAble = false,
      editAble = false,
      onTrashClick = () => {},
      onEditClick = () => {},
    }) =>
      file ? (
        <div
          onMouseEnter={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = false;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.childNodes[0].childNodes[1].hidden = true;
          }}
          className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
        >
          <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
            <div title={title} key={key} className="block min-w-[600rem] px-2">
              <a href={`${imgUrl}/${file}`} target="_blank">
                {title}
              </a>
            </div>
            <div
              hidden={true}
              className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
            >
              
              {editAble ? (
                <img
                  title="Edit file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/system-edit.png"
                  onClick={(e) => {
                    onEditClick();
                  }}
                  alt=""
                />
              ) : null}
              {trashAble ? (
                <img
                  title="Remove file"
                  className="logo w-5 my-auto mx-1"
                  src="/assets/img/trash-icon.png"
                  onClick={(e) => {
                    const fileInputField = document.getElementById(inputFieldId);
                    let dataTransfer = new DataTransfer();
    
                    if (fileInputField) {
                      fileInputField.files.forEach((input_file) => {
                        if (input_file !== file) {
                          dataTransfer.items.add(input_file);
                        }
                      });
                      fileInputField.files = dataTransfer.files;
                    }
                    onTrashClick(e);
                  }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null;

    const formatAMPM = (date) => {
      // const time = date.toLocaleTimeString();
      date = new Date("2021-09-23T" + date);
      var Hours = date.getHours();
      var ampm = Hours >= 12 ? "pm" : "am";
      Hours = Hours % 12;
      Hours = Hours ? Hours : 12;
      Hours = ("0" + Hours).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const second = ("0" + date.getSeconds()).slice(-2);
    
      const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;
    
      return TotalTime;
      };
      function stringToTime(timeString) {
      console.warn("timeString",timeString)
      if(timeString === null || timeString === undefined || timeString >= 0) return;
      let parts = timeString.split(":");
      let ampm = timeString.split(" ");
      let hours = parts[0];
      let minutes = parts[1]; 
      let dayNight = ampm[1];
  
      let time = hours+":"+minutes+" "+dayNight
      let isPM = false;
  
      if(time.endsWith("am")){
        isPM = false;
        time = time.replace(/\s(am|pm)/, "");
      } else {
        isPM = true;
        time = time.replace(/\s(am|pm)/, "");
        let timeArr = time.split(":");
        timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
        time = timeArr.join(":");
      }
  
      return time
  
    }
      const formatDate = (date) => {
      date = new Date(date);
      const year = date.getFullYear();
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
      const TotalDate = day + "-" + month + "-" + year;
      return TotalDate;
      };
      function stringToDate(dateString) {
      // console.warn("dateString",dateString)
      if(dateString === null || dateString === undefined || dateString >= 0) return;
      let parts = dateString.split("-");
      let day = parts[0];
      let month = parts[1]; // months are 0-based
      let year = parts[2];
      let date = year+"-"+month +"-"+day;
      // console.warn("UnformatDate",date)
      return date;
    }
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now;
  };
  function removeNonNumeric(inputString) {
    var numericString = inputString.replace(/\D/g, "");
    return numericString;
  }
  const formatPhoneNumber = (number, format) => {
    const digitsOnly = number?.replace(/\D/g, '') || '';
    let formattedNumber = '';
    let digitIndex = 0;
  
    for (let i = 0; i < format?.length; i++) {
      if (format[i] === '#') {
        formattedNumber += digitsOnly[digitIndex] || '';
        digitIndex++;
      } else if (format[i] === '_') {
        formattedNumber += ' ';
      } else {
        formattedNumber += format[i];
      }
    }
  
    return formattedNumber;
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 80;
  const Years50 = currentYear - 80;
  const years = [...Array(currentYear - startYear + 1).keys()]
    .map((x, i) => i + startYear)
    .reverse();
  const years50 = [...Array(currentYear - Years50 + 2).keys()]
    .map((x, i) => i + startYear)
    .reverse();

  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const base64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const imgBase64 = reader.result;
      try {
        console.log(imgBase64);
        const res = await ApiRequest.post(imageApi, { image: imgBase64 });
        console.log("res", res?.data?.data?.image);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        // files.push({picutre:res?.data?.data?.image});
        // setFiles(res?.data?.data?.image);
        // let array = [];
        // array.push({picture:res?.data?.data?.image})
        formData?.pictures?.push({ picture: res?.data?.data?.image });
        // setFormData({...formData, pictures :[...array]})

        console.log("base64converted files", res?.data?.data?.image);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Publicbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Staff64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_staff_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Managerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_manager_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const Ownerbase64Converter = async (file, index) => {
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      const fileBase64 = reader.result;
      try {
        console.log(fileBase64);
        const res = await ApiRequest.post(fileApi, { file: fileBase64 });
        // console.log("res", res?.data?.data?.file);
        // console.log("index",index);
        // let index = [index];
        // [...index].sort()

        formData.user_owner_files.push({ file: res?.data?.data?.file });
        // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
        console.log("base64converted files", files);
      } catch (err) {
        console.log(err);
      }
    });
    reader.readAsDataURL(file);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleFiles = (message) => {
    setTenantFormImagesWithUrl(message);
    let tenantCopy = [...message]; // Use the updated 'message' parameter instead of 'TenantFormImagesWithUrl'
    tenantCopy.pictures = message.map((picObj, index) => ({
      picture: picObj.picture,
      title: picObj.title,
      caption: picObj.caption,
      order_number: index + 1, // Update the order number to ensure consecutive numbers
      is_cover: picObj.is_cover,
    }));
    setFormData({ ...formData, pictures: tenantCopy?.pictures });
  };
  const handleEmergencyContactTrue = (message)=>{
    setTenantEmergencyContacts(message)
  }
  const handleLogDelete = (message) => {
    let createLogPicturesCopy = { ...createLogPictures };
    createLogPicturesCopy.deleted = [...createLogPicturesCopy.deleted, message];
    setCreateLogPictures(createLogPicturesCopy);
  
    setCreateLog((prevCreateLog) => ({
      ...prevCreateLog,
      pictures: {
        added: [...prevCreateLog.pictures.added],
        deleted: [...prevCreateLog.pictures.deleted, message],
      },
    }));
  };
  

  const handleInteractionTrue = (message)=>{
    setTenantInteraction(message)
  }
  const handleReferenceTrue = (message)=>{
    setTenantReference(message)
  }
  const handleEmploymentTrue = (message)=>{
    setTenantEmployment(message)
  }
  const handleOtherLivingTrue = (message)=>{
    setTenantOtherLiving(message)
  }
  const handleChildrenTrue = (message)=>{
    setTenantChildren(message)
  }
  const handleVehicleTrue = (message)=>{
    setTenantVehicle(message)
  }
  const handlePetTrue = (message)=>{
    setTenantPet(message)
  }
  const handleKeyTrue = (message)=>{
    setTenantKey(message)
  }
  const handleItemTrue = (message)=>{
    setTenantItem(message)
  }
  const handleNotificationTrue = (message)=>{
    setTenantNotification(message)
  }
  // API
  const [numberType, setNumberType] = useState([]);
  const getNumberType = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-number-types')
    if(response.status == 200)
    {
      setNumberType(response?.data?.data?.number_types);
    }
  }
  const getGenderPreferences = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-genders-preferences"
    );
    if (response.status == 200) {
      setGenderPreference(response?.data?.data?.genders_preferences);
    }
  };
  const getGenders = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-genders");
    if (response.status == 200) {
      setGender(response?.data?.data?.genders);
    }
  };
  const getUserRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-relationships"
    );
    if (response.status == 200) {
      setUserRelationship(response?.data?.data?.relationships);
    }
  };

  const getInteractionTypes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-interactions-types"
    );
    if (response.status == 200) {
      setInteractionType(response?.data?.data?.interactions_types);
    }
  };
  const getVehicleMakes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-vehicles-makes"
    );
    if (response.status == 200) {
      setVehicleMake(response?.data?.data?.vehicles_makes);
    }
  };
  const getVehicleModels = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-vehicles-models"
    );
    if (response.status == 200) {
      setVehicleModel(response?.data?.data?.vehicles_models);
    }
  };
  const getPetGenders = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-pets-genders");
    if (response.status == 200) {
      setPetGender(response?.data?.data?.pets_genders);
    }
  };
  const getPetTypes = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-pets-types");
    if (response.status == 200) {
      setPetType(response?.data?.data?.types);
    }
  };
  const getUserReferenceRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-references-relationships"
    );
    if (response.status == 200) {
      setUserReferenceRelationship(response?.data?.data?.relationships);
    }
  };
  const getEthnicity = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-ethnicities");
    if (response.status == 200) {
      setEthnicity(response?.data?.data?.ethnicities);
    }
  };
  const getMoveoutReason = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-moveout-reasons"
    );
    if (response.status == 200) {
      setMoveoutReason(response?.data?.data?.reasons);
    }
  };
  const getMyKeys = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-keys");
    if (response.status == 200) {
      setKey(response?.data?.data);
    }
  };
  const getMyLoanableItems = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-my-loanable-items"
    );
    if (response.status == 200) {
      setLoanableItem(response?.data?.data);
    }
  };
  const addSerialNumber = async (payload) => {
    const response = await ApiRequest.post(
      "/public/api/auth/add-loanable-item-serial-number",
      payload
    );
    if (response.status == 200) {
      getMyLoanableItems();
    }
  };
  const [ownerSettings,setOwnerSettings] = useState([]);

  const getOwnerSettings = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-current-owner-settings');
    if(response?.status == 200)
    {
      setOwnerSettings(response?.data?.data?.owners_settings);
    }
  }
  const getPrefix = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-name-prefixes");
    if (response.status == 200) {
      setPrefix(response?.data?.data?.prefixes);
    }
  };
  const getSuffix = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-name-suffixes");
    if (response.status == 200) {
      setSuffix(response?.data?.data?.suffixes);
    }
  };

  const {id} =useParams();
  handleFirstName(formData?.firstname);
  handleLastName(formData?.lastname);
  const getApi = async ()=>{
        const response = await ApiRequest.get(`/public/api/auth/get-tenant?tenant_id=${id}`)
        setLoading(response.status)
        if (response.status === 200) {
          setFormData({
            ...formData,enable_portal: response?.data?.data?.profile?.enable_portal,
            ...formData,send_notifications: response?.data?.data?.profile?.send_notifications,
            ...formData,user_prefix_id: response?.data?.data?.user_prefix_id,
            ...formData,user_role_type_id: response?.data?.data?.role_id,
            ...formData,firstname: response?.data?.data?.firstname,
            ...formData,middlename: response?.data?.data?.middlename,
            ...formData,lastname: response?.data?.data?.lastname,
            ...formData,user_suffix_id: response?.data?.data?.user_suffix_id,
            ...formData,addresses: response?.data?.data?.addresses,
            ...formData,numbers: response?.data?.data?.numbers,
            ...formData,email: response?.data?.data?.email,
            ...formData,ssn: response?.data?.data?.ssn,
            ...formData,date_of_birth: response?.data?.data?.profile?.date_of_birth,
            ...formData,dln_id: response?.data?.data?.profile?.dln_id,
            ...formData,dl_id_picture: response?.data?.data?.profile?.dl_id_picture,
            ...formData,dln_id_issue_state: response?.data?.data?.profile?.dln_id_issue_state,
            ...formData,dln_id_issue_country: response?.data?.data?.profile?.dln_id_issue_country,
            ...formData,gender_id: response?.data?.data?.profile?.gender_id,
            ...formData,ethnicity_id: response?.data?.data?.profile?.ethnicity_id,
            ...formData,smoker: response?.data?.data?.profile?.smoker,
            ...formData,pictures: response?.data?.data?.pictures,
            ...formData,emergencies_contacts: response?.data?.data?.emergencies_contacts,
            ...formData,known_allergies: response?.data?.data?.profile?.known_allergies,
            ...formData,other_emergency_info: response?.data?.data?.profile?.other_emergency_info,
            ...formData,notifications: response?.data?.data?.notifications,
            ...formData,user_files: response?.data?.data?.files,
            ...formData,user_staff_files: response?.data?.data?.staff_files,
            ...formData,user_manager_files: response?.data?.data?.managers_files,
            ...formData,user_owner_files: response?.data?.data?.owners_files,
            ...formData,interactions: response?.data?.data?.interactions,
            ...formData,keys_trackings: response?.data?.data?.key_trackings,
            ...formData,loanable_items_trackings: response?.data?.data?.loanable_items_trackings,
            ...formData,vehicles: response?.data?.data?.vehicles,
            ...formData,childrens: response?.data?.data?.childrens,
            ...formData,pets: response?.data?.data?.pets,
            ...formData,other_livings: response?.data?.data?.other_livings,
            ...formData,references: response?.data?.data?.references,
            ...formData,moveout_address1: response?.data?.data?.profile?.moveout_address1,
            ...formData,moveout_address2: response?.data?.data?.profile?.moveout_address2,
            ...formData,moveout_city: response?.data?.data?.profile?.moveout_city,
            ...formData,moveout_country: response?.data?.data?.profile?.moveout_country,
            ...formData,moveout_state: response?.data?.data?.profile?.moveout_state,
            ...formData,moveout_zip_code: response?.data?.data?.profile?.moveout_zip_code,
            ...formData,moveout_number: response?.data?.data?.profile?.moveout_number,
            ...formData,moveout_number_type: response?.data?.data?.profile?.moveout_number_type,
            ...formData,moveout_email: response?.data?.data?.profile?.moveout_email,
            ...formData,moveout_date: response?.data?.data?.profile?.moveout_date,
            ...formData,moveout_reason_id: response?.data?.data?.profile?.moveout_reason_id,
          })
          setTenantFormImagesWithUrl(response?.data?.data?.pictures)
          dlidPicture?.pictures?.push({picture:response?.data?.data?.dl_id_picture})
          setEditorData({
            ...editorData,user_notes: response?.data?.data?.profile?.user_notes,
            ...editorData,dln_id_issue_notes: response?.data?.data?.profile?.dln_id_issue_notes,

          })
          setEditorData1({
            ...editorData1,user_manager_notes: response?.data?.data?.profile?.user_manager_notes,
            ...editorData1,smoker_notes: response?.data?.data?.profile?.smoker_notes,

          })
          setEditorData2({
            ...editorData2,user_staff_notes: response?.data?.data?.profile?.user_staff_notes,

          })
          setEditorData3({
            ...editorData3,user_owner_notes: response?.data?.data?.profile?.user_owner_notes,

          })
          setTenantNotification(response?.data?.data?.notifications)
          setPropertyPublicDocumentsWithUrl(response?.data?.data?.files)
          setPropertyStaffDocumentsWithUrl(response?.data?.data?.staff_files)
          setPropertyManagerDocumentsWithUrl(response?.data?.data?.managers_files)
          setPropertyOwnerDocumentsWithUrl(response?.data?.data?.owners_files)
          setTenantInteraction(response?.data?.data?.interactions)
          setTenantKey(response?.data?.data?.keys_trackings)
          setTenantItem(response?.data?.data?.loanable_items_trackings)
          setTenantVehicle(response?.data?.data?.vehicles)
          setTenantChildren(response?.data?.data?.childrens)
          setTenantPet(response?.data?.data?.pets)
          setTenantOtherLiving(response?.data?.data?.other_livings)
          setTenantEmployment(response?.data?.data?.employments)
          setTenantReference(response?.data?.data?.references)
          setTenantEmergencyContacts(response?.data?.data?.emergencies_contacts)
          setTenantAddress(response?.data?.data?.addresses)
          setNumber(response?.data?.data?.numbers)
        }}
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await ApiRequest.post("/public/api/auth/tenant", {
        id:parseInt(id),
        create_log:createLog,
        enable_portal: formData?.enable_portal,
        send_notifications: formData?.send_notifications,
        user_prefix_id: formData?.user_prefix_id,
        firstname: formData?.firstname,
        middlename: formData?.middlename,
        lastname: formData?.lastname,
        user_suffix_id: formData?.user_suffix_id,
        user_role_type_id:formData?.user_role_type_id,
        addresses: formData?.addresses,
        numbers: formData?.numbers,
        email: formData?.email,
        ssn:formData?.ssn,
        date_of_birth: formData?.date_of_birth,
        dln_id: formData?.dln_id,
        dl_id_picture: formData?.dl_id_picture,
        dln_id_issue_state: formData?.dln_id_issue_state,
        dln_id_issue_country: formData?.dln_id_issue_country,
        dln_id_issue_notes: editorData?.dln_id_issue_notes,
        gender_id: formData?.gender_id,
        ethnicity_id: formData?.ethnicity_id,
        smoker: formData?.smoker,
        smoker_notes : editorData1?.smoker_notes,
        pictures: formData?.pictures,
        emergencies_contacts: tenantEmergencyContacts,
        known_allergies: formData?.known_allergies,
        other_emergency_info: formData?.other_emergency_info,
        notifications: tenantNotification,
        user_files: formData?.user_files,
        user_notes: editorData?.user_notes,
        user_staff_files: formData?.user_staff_files,
        user_staff_notes: editorData2?.user_staff_notes,
        user_manager_files: formData?.user_manager_files,
        user_manager_notes: editorData1?.user_manager_notes,
        user_owner_files: formData?.user_owner_files,
        user_owner_notes: editorData3?.user_owner_notes,
        interactions: tenantInteraction,
        keys_trackings: tenantKey,
        loanable_items_trackings: tenantItem,
        vehicles: tenantVehicle,
        childrens: tenantChildren,
        pets: tenantPet,
        other_livings: tenantOtherLiving,
        employments:tenantEmployment,
        references: tenantReference,
        moveout_address1: formData?.moveout_address1,
        moveout_address2: formData?.moveout_address2,
        moveout_city: formData?.moveout_city,
        moveout_country: formData?.moveout_country,
        moveout_state: formData?.moveout_state,
        moveout_zip_code: formData?.moveout_zip_code,
        moveout_number: formData?.moveout_number,
        moveout_number_type: formData?.moveout_number_type,
        moveout_email: formData?.moveout_email,
        moveout_date: formData?.moveout_date,
        moveout_reason_id: formData?.moveout_reason_id,
      });
      console.log("Result formData", formData);
      if (res.status == 200) {
        console.log("Added");
        Alert.addSuccess(`${formData?.user_role_type_id === 5 ? "Tenant" : "Prospect"} Edited Successfully`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (formElement) {
      formElement.querySelectorAll(".end-line").forEach((elem) => {
        if (hasBeenScrolled(elem)) {
          setLastEndLine(elem);
        }
      });
    }
  }, [formElement]);

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const TenantPictures = [];
      TenantFormImagesWithUrl.forEach((fileObject, index) => {
        const file = fileObject?.image?.picture;
        TenantPictures.push({
          picture: file,
          title: file.name,
          caption: "c" + index,
          is_cover: index == 0,
        });
      });
      // const notifications = [
      //   ...tenantNotification.filter(
      //     (notifications) =>
      //       JSON.stringify(notifications) !== JSON.stringify(initialNoticeData)
      //   ),
      // ];
      // const references = [
      //   ...tenantReference.filter(
      //     (references) =>
      //       JSON.stringify(references) !== JSON.stringify(initialReferenceData)
      //   ),
      // ];
      // const employments = [
      //   ...tenantEmployment.filter(
      //     (employments) =>
      //       JSON.stringify(employments) !==
      //       JSON.stringify(initialEmploymentData)
      //   ),
      // ];
      const addresses = [
        ...tenantAddress.filter(
          (address) =>
            JSON.stringify(address) !== JSON.stringify(initialAddressData)
        ),
      ];
      // const shots = [
      //   ...tenantShot
      //     .map((shot, index) => {
      //       const pictures = [...tenantShotPicture];
      //       shot.proof_picture = pictures[index]?.image?.picture;
      //     })
      //     .filter(
      //       (shot) => JSON.stringify(shot) !== JSON.stringify(initialShotData)
      //     ),
      // ];
      // const emergencies_contacts = [
      //   ...tenantEmergencyContacts.filter(
      //     (emergencies_contacts) =>
      //       JSON.stringify(emergencies_contacts) !==
      //       JSON.stringify(initialEmergencyContactData)
      //   ),
      // ];
      // const interactions = [
      //   ...tenantInteraction
      //     .map((interaction, index) => {
      //       const files = [...tenantInteractionFiles];
      //       interaction.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return interaction;
      //     })
      //     .filter(
      //       (interaction) =>
      //         JSON.stringify(interaction) !==
      //         JSON.stringify(initialInteractionData)
      //     ),
      // ];
      // const keys_trackings = [
      //   ...tenantKey
      //     .map((key, index) => {
      //       const pictures = [...tenantKeyPicture];
      //       key.assigned_verification_picture = pictures[index]?.image?.picture;
      //       const returnpictures = [...tenantKeyReturnPicture];
      //       key.returened_verification_picture =
      //         returnpictures[index]?.image?.picture;
      //       const files = [...tenantKeyFiles];
      //       key.replacement_files = files[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return key;
      //     })
      //     .filter(
      //       (key) => JSON.stringify(key) !== JSON.stringify(initialKeyData)
      //     ),
      // ];
      // const loanable_items_trackings = [
      //   ...tenantItem
      //     .map((item, index) => {
      //       const pictures = [...tenantItemPicture];
      //       item.assigned_verification_picture =
      //         pictures[index]?.image?.picture;
      //       const returnpictures = [...tenantItemRetrunVerificationPicture];
      //       item.returened_verification_picture =
      //         returnpictures[index]?.image?.picture;
      //       const itemGivenPictures = [...tenantItemGivenPicture];
      //       item.pictures = itemGivenPictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const itemReturnPictures = [...tenantItemReturnPicture];
      //       item.pictures = itemGivenPictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...tenantItemFiles];
      //       item.replacement_files = files[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return item;
      //     })
      //     .filter(
      //       (item) => JSON.stringify(item) !== JSON.stringify(initialItemData)
      //     ),
      // ];
      // const other_livings = [
      //   ...tenantOtherLiving
      //     .map((otherLiving, index) => {
      //       const pictures = [...tenantOtherLivingPicture];
      //       otherLiving.pictures = pictures[index]?.map(
      //         (pictureObj, index) => ({
      //           picture: pictureObj?.image?.picture,
      //           order_number: index,
      //         })
      //       );
      //       const files = [...tenantOtherLivingFile];
      //       otherLiving.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const privateFiles = [...tenantOtherLivingPrivateFile];
      //       otherLiving.private_files = privateFiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return otherLiving;
      //     })
      //     .filter(
      //       (otherLiving) =>
      //         JSON.stringify(otherLiving) !==
      //         JSON.stringify(initialOtherLivingData)
      //     ),
      // ];
      // const vehicles = [
      //   ...tenantVehicle
      //     .map((vehicle, index) => {
      //       const pictures = [...tenantVehiclePicture];
      //       vehicle.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...tenantVehicleFiles];
      //       vehicle.files = files[index]?.map((fileObj) => fileObj?.file);
      //       return vehicle;
      //     })
      //     .filter(
      //       (vehicle) =>
      //         JSON.stringify(vehicle) !== JSON.stringify(initialVehicleData)
      //     ),
      // ];
      // const childrens = [
      //   ...tenantChildren
      //     .map((children, index) => {
      //       const pictures = [...tenantChildrenPicture];
      //       children.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));
      //       const files = [...tenantChildrenFile];
      //       children.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const Privatefiles = [...tenantChildrenPrivateFile];
      //       children.private_files = Privatefiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return children;
      //     })
      //     .filter(
      //       (children) =>
      //         JSON.stringify(children) !== JSON.stringify(initialVehicleData)
      //     ),
      // ];
      // const pets = [
      //   ...tenantPet
      //     .map((pet, index) => {
      //       const pictures = [...tenantPetPicture];
      //       pet.pictures = pictures[index]?.map((pictureObj, index) => ({
      //         picture: pictureObj?.image?.picture,
      //         order_number: index,
      //       }));

      //       const files = [...tenantPetFile];
      //       pet.files = files[index]?.map((fileObj) => fileObj?.file);
      //       const Privatefiles = [...tenantPetPrivateFile];
      //       pet.private_files = Privatefiles[index]?.map(
      //         (fileObj) => fileObj?.file
      //       );
      //       return pet;
      //     })
      //     .filter(
      //       (pet) => JSON.stringify(pet) !== JSON.stringify(initialPetData)
      //     ),
      // ];
      const numbers = [
        ...tenantAddress.filter(
          (number) =>
            JSON.stringify(number) !== JSON.stringify(initialNumberData)
        ),
      ];
      data.pictures = [...TenantPictures];
      // if (
      //   JSON.stringify(initialData?.notifications) !==
      //   JSON.stringify(notifications)
      // ) {
      //   data.notifications = notifications;
      // }
      if (
        JSON.stringify(initialData?.addresses) !== JSON.stringify(addresses)
      ) {
        data.addresses = addresses;
      }
      // if (
      //   JSON.stringify(initialData?.references) !== JSON.stringify(references)
      // ) {
      //   data.references = references;
      // }
      // if (
      //   JSON.stringify(initialData?.employments) !== JSON.stringify(employments)
      // ) {
      //   data.employments = employments;
      // }
      // if (
      //   JSON.stringify(initialData?.emergencies_contacts) !==
      //   JSON.stringify(emergencies_contacts)
      // ) {
      //   data.emergencies_contacts = emergencies_contacts;
      // }
      // if (
      //   JSON.stringify(initialData?.interactions) !==
      //   JSON.stringify(interactions)
      // ) {
      //   data.interactions = interactions;
      // }
      // if (
      //   JSON.stringify(initialData?.keys_trackings) !==
      //   JSON.stringify(keys_trackings)
      // ) {
      //   data.keys_trackings = keys_trackings;
      // }
      // if (
      //   JSON.stringify(initialData?.loanable_items_trackings) !==
      //   JSON.stringify(loanable_items_trackings)
      // ) {
      //   data.loanable_items_trackings = loanable_items_trackings;
      // }
      // if (
      //   JSON.stringify(initialData?.other_livings) !==
      //   JSON.stringify(other_livings)
      // ) {
      //   data.other_livings = other_livings;
      // }
      // if (JSON.stringify(initialData?.vehicles) !== JSON.stringify(vehicles)) {
      //   data.vehicles = vehicles;
      // }
      // if (
      //   JSON.stringify(initialData?.childrens) !== JSON.stringify(childrens)
      // ) {
      //   data.childrens = childrens;
      // }
      // if (JSON.stringify(initialData?.pets) !== JSON.stringify(pets)) {
      //   data.pets = pets;
      // }
      if (JSON.stringify(initialData?.numbers) !== JSON.stringify(numbers)) {
        data.numbers = numbers;
      }
      data.user_files = [
        ...propertyPublicDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_staff_files = [
        ...propertyStaffDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_manager_files = [
        ...propertyManagerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      data.user_owner_files = [
        ...propertyOwnerDocumentsWithUrl.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];

      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
    }
  }, [lastEndLine]);
  useEffect(() => {
    getOwnerSettings();
    getCountries();
    getGenderPreferences();
    getNumberType();
    getApi();
    getGenders();
    getUserRelationships();
    getInteractionTypes();
    getVehicleMakes();
    getVehicleModels();
    getPetGenders();
    getPetTypes();
    getUserReferenceRelationships();
    getMyKeys();
    getMyLoanableItems();
    getEthnicity();
    getPrefix();
    getSuffix();
    getMoveoutReason();
    setCreateLog(initialCreateLogData)
    setCreateLogPictures(initialLogData)
    setFormData(initialData.initialData);
    setTenantShot(initialShotData);
    setTenantFormImagesWithUrl(
      initialData?.pictures?.map((obj) => ({
        image: {
          picture: obj?.picture,
          title: "",
          caption: "",
          is_cover: "",
        },
        url: obj?.picture,
      }))
    );
    // setTenantNotification(
    //   initialData?.notifications?.length
    //     ? initialData?.notifications
    //     : [{ ...initialNoticeData }]
    // );
    // setTenantReference(
    //   initialData?.references?.length
    //     ? initialData?.references
    //     : [{ ...initialReferenceData }]
    // );
    // setTenantEmployment(
    //   initialData?.employments?.length
    //     ? initialData?.employments
    //     : [{ ...initialEmploymentData }]
    // );
    setTenantAddress(
      initialData?.addresses?.length
        ? initialData?.addresses
        : [{ ...initialAddressData }]
    );
    // if (initialData?.shots?.length) {
    //   setTenantKey(initialData.shots);
    //   setTenantShotPicture(
    //     initialData.shots.map((shot) => shot?.proof_picture)
    //   );
    // } else {
    //   setTenantShot([{ ...initialShotData }]);
    //   setTenantShotPicture([]);
    // }
    setNumber(initialNumberData);
    setDlidPicture([]);

    // setTenantEmergencyContacts(
    //   initialData?.emergencies_contacts?.length
    //     ? initialData?.emergencies_contacts
    //     : [{ ...initialEmergencyContactData }]
    // );
    // setTenantInteraction( initialData?.interactions?.length
    //   ? initialData?.interactions
    //   : [{ ...initialInteractionData }])
    // if (initialData?.interactions?.length) {
    //   setTenantInteraction(initialData.interactions);

    //   setTenantInteractionFiles(
    //     initialData.interactions.map((interaction) => interaction?.files)
    //   );
    // } else {
    //   setTenantInteraction([{ ...initialInteractionData }]);
    //   setTenantInteractionFiles([[]]);
    // }
    // if (initialData?.keys_trackings?.length) {
    //   setTenantKey(initialData.keys_trackings);
    //   setTenantKeyPicture(
    //     initialData.keys_trackings.map(
    //       (key) => key?.assigned_verification_picture
    //     )
    //   );
    //   setTenantKeyReturnPicture(
    //     initialData.keys_trackings.map(
    //       (key) => key?.returened_verification_picture
    //     )
    //   );
    //   setTenantKeyFiles(initialData.keys_trackings.map((key) => key?.files));
    // } else {
    //   setTenantKey([{ ...initialKeyData }]);
    //   setTenantKeyPicture([]);
    //   setTenantKeyReturnPicture([]);
    //   setTenantKeyFiles([[]]);
    // }
    // if (initialData?.loanable_items_trackings?.length) {
    //   setTenantItem(initialData.loanable_items_trackings);
    //   setTenantItemPicture(
    //     initialData.loanable_items_trackings.map(
    //       (item) => item?.assigned_verification_picture
    //     )
    //   );
    //   setTenantItemRetrunVerificationPicture(
    //     initialData.loanable_items_trackings.map(
    //       (item) => item?.returened_verification_picture
    //     )
    //   );
    //   setTenantItemGIvenPicture(
    //     initialData.loanable_items_trackings.map((item) =>
    //       item?.item_given_pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantItemReturnPicture(
    //     initialData.loanable_items_trackings.map((item) =>
    //       item?.item_return_pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantItemFiles(
    //     initialData.loanable_items_trackings.map((key) => key?.files)
    //   );
    // } else {
    //   setTenantItem([{ ...initialItemData }]);
    //   setTenantItemPicture([]);
    //   setTenantItemRetrunVerificationPicture([]);
    //   setTenantItemFiles([[]]);
    //   setTenantItemReturnPicture([[]]);
    //   setTenantItemGIvenPicture([[]]);
    // }
    // if (initialData?.other_livings?.length) {
    //   setTenantOtherLiving(initialData.other_livings);
    //   setTenantOtherLivingPicture(
    //     initialData.other_livings.map((other_living) =>
    //       other_living?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantOtherLivingFile(
    //     initialData.other_livings.map((other_living) => other_living?.files)
    //   );
    //   setTenantOtherLivingPrivateFile(
    //     initialData.other_livings.map(
    //       (other_living) => other_living?.private_files
    //     )
    //   );
    // } else {
    //   setTenantOtherLiving([{ ...initialOtherLivingData }]);
    //   setTenantOtherLivingPicture([[]]);
    //   setTenantOtherLivingPrivateFile([[]]);
    //   setTenantOtherLivingFile([[]]);
    // }
    // if (initialData?.vehicles?.length) {
    //   setTenantVehicle(initialData.vehicles);
    //   setTenantVehiclePicture(
    //     initialData.vehicles.map((vehicle) =>
    //       vehicle?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantVehicleFiles(
    //     initialData.vehicles.map((vehicle) => vehicle?.files)
    //   );
    // } else {
    //   setTenantVehicle([{ ...initialVehicleData }]);
    //   setTenantVehiclePicture([[]]);
    //   setTenantVehicleFiles([[]]);
    // }
    // if (initialData?.childrens?.length) {
    //   setTenantChildren(initialData.childrens);
    //   setTenantChildrenPicture(
    //     initialData.childrens.map((children) =>
    //       children?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );
    //   setTenantChildrenFile(
    //     initialData.childrens.map((children) => children?.files)
    //   );
    //   setTenantChildrenPrivateFile(
    //     initialData.childrens.map((children) => children?.private_files)
    //   );
    // } else {
    //   setTenantChildren([{ ...initialChildrenData }]);
    //   setTenantChildrenPicture([[]]);
    //   setTenantChildrenFile([[]]);
    //   setTenantChildrenPrivateFile([[]]);
    // }
    // if (initialData?.pets?.length) {
    //   setTenantPet(initialData.pets);
    //   setTenantPetPicture(
    //     initialData.pets.map((pet) =>
    //       pet?.pictures.map((pictureObj) => pictureObj?.picture)
    //     )
    //   );

    //   setTenantPetFile(initialData.pets.map((pet) => pet?.files));
    //   setTenantPetPrivateFile(
    //     initialData.pets.map((pet) => pet?.private_files)
    //   );
    // } else {
    //   setTenantPet([{ ...initialPetData }]);
    //   setTenantPetPicture([[]]);
    //   setTenantPetFile([[]]);
    //   setTenantPetPrivateFile([[]]);
    // }
  }, []);
  

  return (
    
    <>
    {loading === undefined ? <Loading/> :
      <>
       {/* <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/users-tenant.png"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                Editing {formData?.firstname} {formData?.lastname}
              </h2>
            </div> */}
      <form
        className="w-full"
        id="add-tenant-form"
        onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Emergency Information" {...a11yProps(1)} />
          <Tab label="Notifications" {...a11yProps(2)} />
          <Tab label="Notes & Files" {...a11yProps(3)} />
          <Tab label="Interactions" {...a11yProps(4)} />
          <Tab label="Keys" {...a11yProps(5)} />
          <Tab label="Loanable Items" {...a11yProps(6)} />
          <Tab label="Vehicles" {...a11yProps(7)} />
          <Tab label="Children" {...a11yProps(8)} />
          <Tab label="Pets" {...a11yProps(9)} />
          <Tab label="Others Living" {...a11yProps(10)} />
          <Tab label="Employment" {...a11yProps(11)} />
          <Tab label="References" {...a11yProps(12)} />
          <Tab label="Move-Out Address" {...a11yProps(13)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Enable Portal:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.enable_portal}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          enable_portal: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Send Notifications:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={formData?.send_notifications}
                      onChange={(e) => {
                        const checkedValue = e.target.checked === true ? 1 : 0;
                        setFormData({
                          ...formData,
                          send_notifications: checkedValue,
                        });
                      }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:flex md:items-center w-full md:w-2/12 mb-6">
                    {/* <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Prefix:
                      </label>
                    </div> */}
                    <div className="md:w-full">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="user_prefix_id"
                        required={false}
                        value={formData?.user_prefix_id}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            user_prefix_id: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value=" ">Select</option>
                        {prefix.map((row) => (
                          <option value={row?.id}>{row?.prefix}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-3/12 mb-6 mx-2">
                    <div className="md:w-2/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        First<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="firstname"
                        type="text"
                        placeholder="John"
                        required={false}
                        value={formData?.firstname}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, firstname: 1 });
                          setFormData({ ...formData, firstname: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-3/12 mb-6">
                    <div className="md:w-2/3">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Middle
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="middlename"
                        type="text"
                        placeholder="Ben"
                        required={false}
                        value={formData?.middlename}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, middlename: 1 });
                          setFormData({
                            ...formData,
                            middlename: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-4/12 mb-6 mx-2">
                    <div className="md:w-1/3">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Last<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="lastname"
                        type="text"
                        placeholder="Deo"
                        required={false}
                        value={formData?.lastname}
                        onChange={(e) => {
                          setCreateLog({ ...createLog, lastname: 1 });
                          setFormData({ ...formData, lastname: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full md:w-2/12 mb-6">
                    {/* <div className="md:w-1/4">
                      <label className="block capitalize text-gray-700 font-bold mb-2">
                        Suffix:
                      </label>
                    </div> */}
                    <div className="md:w-full">
                      <select
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="user_suffix_id"
                        required={false}
                        value={formData?.user_suffix_id}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            user_suffix_id: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value=" ">Select</option>
                        {suffix.map((row) => (
                          <option value={row?.id}>{row?.suffix}</option>
                        ))}
                      </select>
                    </div>
                  </div>
              </div>
              {/* <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mt-3">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Type:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="user_role_type_id"
                      required={false}
                      value={formData?.user_role_type_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_role_type_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value="">Select</option>
                      <option value={5}>Tenant</option>
                      <option value={7}>Prospect</option>
                    </select>
                  </div>
                </div> */}
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              {/* {tenantAddress?.map((row) => {
                const addresses = [...tenantAddress];
                return (
                  <> */}
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 1:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address-line-1"
                          type="text"
                          placeholder="Apt. suite no. 1"
                          required={false}
                          value={formData?.addresses[0]?.address_line1}
                          onChange={(e) => {
                            const addresses = [...tenantAddress];
                            setCreateLog({...createLog,address_line1:1});
                            addresses[0].address_line1 = e.target.value;
                            setTenantAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 2:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address_line2"
                          type="text"
                          placeholder="Jeff Street"
                          value={formData?.addresses[0]?.address_line2}
                          onChange={(e) => {
                            const addresses = [...tenantAddress];
                            setCreateLog({...createLog,address_line2:1});
                            addresses[0].address_line2 = e.target.value;
                            setTenantAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Country:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-country"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={formData?.addresses[0]?.country}
                            onChange={(e) => {
                              const addresses = [...tenantAddress];
                              addresses[0].country = e.target.value;
                              setTenantAddress(addresses);
                              setCreateLog({...createLog,country:1})
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                              getStates(e.target.value);
                            }}
                          >
                            <option value=" ">Select Country</option>
                            {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-state"
                            required={false}
                            value={formData?.addresses[0]?.state}
                            onChange={(e) => {
                              const addresses = [...tenantAddress];
                              addresses[0].state = e.target.value;
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                              setCreateLog({...createLog,state:1})
                              getCities(e.target.value);
                            }}
                          >
                            <option value=" ">Select State</option>
                            {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                      <div className="md:flex md:items-center w-full mt-3">

                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={formData?.addresses[0]?.city}
                            onChange={(e) => {
                              const addresses = [...tenantAddress];
                              const zip = cities?.map((col) =>{
                                if(col?.id == e.target.value)
                                {
                                  return col?.zip_code
                                }
                              })
                              const filteredArr = zip.filter((value) => value !== undefined);
                              const zipCode = filteredArr.join(', ');
                              addresses[0].city = e.target.value;
                              addresses[0].zip_code = zipCode;
                              setCreateLog({...createLog,city:1})
                              setTenantAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                              
                            }}
                          >
                            <option value=" ">Select City</option>
                            {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-zip"
                            type="text"
                            placeholder="90210"
                            required={false}
                            value={formData?.addresses[0]?.zip_code}
                            onChange={(e) => {
                              const addresses = [...tenantAddress];
                              addresses[0].zip_code = e.target.value;
                              setTenantAddress(addresses);
                              setCreateLog({...createLog,zip_code:1})
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                        </div>
                      </div>
                      </div>
                  {/* </>
                );
              })} */}
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.numbers[0]?.number_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={number?.[0]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[0]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[0]?.number_type}
                    onChange={(e) => {
                      setCreateLog({...createLog,number_type_1:1});
                      const phone = [...number];
                      phone[0].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value="">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.numbers[1]?.number_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={number?.[1]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[1]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[1]?.number_type}
                    onChange={(e) => {
                      setCreateLog({...createLog,number_type_2:1});
                      const phone = [...number];
                      phone[1].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value="">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.numbers[2]?.number_type == 8 ?
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={number?.[2]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[2].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[2]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[2].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  }
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[2]?.number_type}
                    onChange={(e) => {
                      setCreateLog({...createLog,number_type_3:1});
                      const phone = [...number];
                      phone[2].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value="">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:<span title="Field is required">*</span>
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`email`}
                    type="email"
                    placeholder="jhon.doe@email.com"
                    value={formData?.email}
                    onChange={(e) => {
                      setCreateLog({...createLog,email:1});
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
              <div className="md:w-1/4 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      SSN:
                    </label>
                    <Tooltip data={"Please be careful when storing sensitive information. We take every precaution to safeguard this information, but please be advised there is always a risk when storing sensitive information."}/>
                  </div>
                  <div className="md:w-3/4 relative">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="ssn"
                      type={securedFields.ssn}
                      value={formData?.ssn}
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, ssn: e.target.value });
                        setCreateLog({...createLog,ssn:1});
                      }}
                    />
                    <span
                    className="absolute right-0 top-0 py-3 px-3"
                    onClick={() => {
                      setSecuredFields({
                        ...securedFields,
                        ssn:
                          securedFields.ssn === "password"
                            ? "text"
                            : "password",
                      });
                    }}
                  >
                    {securedFields.ssn === "password" ? (
                      <i className="fas fa-eye"></i>
                    ) : (
                      <i className="fas fa-eye-slash"></i>
                    )}
                  </span>
                  </div>
                </div>
                </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date of Birth:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <DatePicker
                      value={stringToDate(formData?.date_of_birth)}
                      onChange={(e) => {
                        setCreateLog({...createLog,date_of_birth:1});
                        const date = formatDate(e);
                        setFormData({ ...formData, date_of_birth: date });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID:
                    </label>
                    <Tooltip data={"Please be careful when storing sensitive information. We take every precaution to safeguard this information, but please be advised there is always a risk when storing sensitive information."}/>
                  </div>
                  <div className="md:w-3/4 relative">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id"
                      type={securedFields.dln_id}
                      value={formData?.dln_id}
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, dln_id: e.target.value });
                        setCreateLog({...createLog,dln_id:1});
                      }}
                    />
                    <span
                    className="absolute right-0 top-0 py-3 px-3"
                    onClick={() => {
                      setSecuredFields({
                        ...securedFields,
                        dln_id:
                          securedFields.dln_id === "password"
                            ? "text"
                            : "password",
                      });
                    }}
                  >
                    {securedFields.dln_id === "password" ? (
                      <i className="fas fa-eye"></i>
                    ) : (
                      <i className="fas fa-eye-slash"></i>
                    )}
                  </span>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
              <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID Country:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_country"
                      value={formData?.dln_id_issue_country}
                      onChange={(e) => {
                        setCreateLog({...createLog,dln_id_issue_country:1});
                        setFormData({
                          ...formData,
                          dln_id_issue_country: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6 ml-2">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID issue state:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_state"
                      value={formData?.dln_id_issue_state}
                      onChange={(e) => {
                        setCreateLog({...createLog,dln_id_issue_state:1});
                        setFormData({
                          ...formData,
                          dln_id_issue_state: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select State</option>
                      {states?.map((state)=>(
                        <option value={state?.id}>{state?.state}</option>
                      ))}
                    </select>
                  </div>
                </div>
                </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      DL/ID Picture:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload ID
                      </span>
                      <input
                        accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              setFormData({
                                ...formData,
                                dl_id_picture: res?.data?.data?.image,
                              });
                              setCreateLog({...createLog,dl_id_picture:1});
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          const pictureWithURL = getImagesAndURLs(e)[0];
                          setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                  </div>
                </div>

               
              </div>
              {formData?.dl_id_picture != null ? (
                <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                  <div className="container w-full">
                    <div
                      id="image-block"
                      className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                    >
                      {imageComponent({
                        image: formData?.dl_id_picture,
                        // src: dlidPicture?.url,
                        inputFieldId: "dlid-picture",
                        trashAble: true,
                        onTrashClick: () => {
                          formData.dl_id_picture = null;
                          setDlidPicture(null);
                        },
                      })}
                    </div>
                  </div>
                </section>
              ) : null}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    DLN/ID Issue Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData?.dln_id_issue_notes}
                    onChange={(e) => {
                     const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                        ...editorData,
                        dln_id_issue_notes: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/4 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Gender:
                    </label>
                    <Tooltip data={"This is used for demographic data. Most governing areas prohibit the use of gender to be a deciding factor when leasing a property"}/>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`gender`}
                      value={formData?.gender_id}
                      onChange={(e) => {
                        setCreateLog({...createLog,gender_id:1});
                        setFormData({
                          ...formData,
                          gender_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value="">Select Gender</option>
                      {gender.map((row) => (
                        <option value={row?.id}>{row?.gender}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                <div className="md:w-1/3 flex">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Ethnicity:
                    </label>
                    <Tooltip data={"This is used for demographic data. Most governing areas prohibit the use of ethnicity to be a deciding factor when leasing a property."}/>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`ethnicity`}
                      value={formData?.ethnicity_id}
                      onChange={(e) => {
                        setCreateLog({...createLog,ethnicity_id:1});
                        setFormData({
                          ...formData,
                          ethnicity_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      <option value="">Select Ethnicity</option>
                      {ethnicity.map((row) => (
                        <option value={row?.id}>{row?.ethnicity}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Smoker:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={formData?.smoker}
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setFormData({
                            ...formData,
                            smoker: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              {formData?.smoker ? (
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Smoking Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData1?.smoking_notes}
                      onChange={(e) => {
                       const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData1({
                          ...editorData1, smoking_notes: cleanText,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                    Pictures:
                  </label>
                </div>
                <div className="md:flex flex-column md:items-start w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload pictures
                        </span>
                        <input
                          accept={picTypes}
                          id="tenant-images"
                          type="file"
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            let newImages = [];
                            let maxOrderNumber = 0;
                          
                            // Find the highest order number in the current images
                            TenantFormImagesWithUrl?.forEach((imageObject) => {
                              if (imageObject.order_number > maxOrderNumber) {
                                maxOrderNumber = imageObject.order_number;
                              }
                            });
                          
                            // Calculate the total number of images (existing images + new images)
                            const totalImages = TenantFormImagesWithUrl.length + files.length;
                          
                            // Loop through each file and convert to base64
                            files.forEach((file, i) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const imgBase64 = reader.result;
                                try {
                                  const res = await ApiRequest.post(imageApi, {
                                    image: imgBase64,
                                  });
                          
                                  // Add the new image to the array with a unique order number
                                  newImages.push({
                                    picture: res?.data?.data?.image,
                                    title: res?.data?.data?.image,
                                    caption: res?.data?.data?.image,
                                    order_number: maxOrderNumber + i + 1,
                                    url: imgBase64,
                                    is_cover: i === 0 ? 1 : 0,
                                  });
                          
                                  // If all new images have been processed, update the state
                                  if (newImages.length === files.length) {
                                    let pictureLists = [...TenantFormImagesWithUrl];
                                    pictureLists = [...pictureLists, ...newImages];
                          
                                    // Update the order number of the existing images
                                    pictureLists.forEach((imageObject, index) => {
                                      imageObject.order_number = index + 1;
                                    });
                          
                                    setTenantFormImagesWithUrl(pictureLists);
                                    let tenantCopy = [...TenantFormImagesWithUrl];
                                    tenantCopy.pictures = pictureLists.map(
                                      (picObj) => ({
                                        picture: picObj.picture,
                                        title: picObj.title,
                                        caption: picObj.caption,
                                        order_number: picObj.order_number,
                                        is_cover: picObj.is_cover,
                                      })
                                    );
                                    setFormData({ ...formData, pictures: tenantCopy?.pictures });
                                    
                                    
                                    let createLogAddCopy = [...createLogAddPictures];
                                    createLogAddCopy.push(res?.data?.data?.image);
setCreateLogAddPictures(createLogAddCopy);

// Update the createLogPictures state
let createLogPicturesCopy = { ...createLogPictures };
createLogPicturesCopy.added = [...createLogPicturesCopy.added, res?.data?.data?.image];
setCreateLogPictures(createLogPicturesCopy);
setCreateLog((prevCreateLog) => ({
  ...prevCreateLog,
  pictures: {
    added: [...prevCreateLog.pictures.added,res?.data?.data?.image],
    deleted: [...prevCreateLog.pictures.deleted],
  },
}));

                                  }
                                } catch (err) {
                                  // console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const imagesCount =
                              TenantFormImagesWithUrl?.length || 0;
                            return imagesCount > 1
                              ? imagesCount + " Pictures"
                              : imagesCount == 1
                              ? imagesCount + " Picture"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
                {TenantFormImagesWithUrl?.length ? (
                  <div>
                    <section className="overflow-hidden w-full text-gray-700 mt-4">
                      <div className="container w-full px-3 m-auto">
                        <div
                          id="images-block"
                          className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                        >
                          {TenantFormImagesWithUrl.map((imageObject, index) =>
                            imageComponent({
                              image: imageObject?.picture,
                              key: index,
                              inputFieldId: "tenant-images",
                              trashAble: true,
                              onTrashClick: () => {
                                let images = [...formData?.pictures];
                                let image = [...TenantFormImagesWithUrl];
                                images.splice(index, 1);
                                image.splice(index, 1);
                                setTenantFormImagesWithUrl(image);
                                setFormData({ ...formData, pictures: images });
                              },
                            })
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : null}
              </div> */}
              <Lightbox data={formData?.pictures} handleFiles={handleFiles} hiddenData={false} handleLogDelete={handleLogDelete}/>

            </div>
          </div>
          {/* <div >
					{TenantFormImagesWithUrl?.length ? (
						<div>
							<section className="overflow-hidden w-full text-gray-700 mt-4">
								<div className="container w-full px-3 m-auto">
									<div
										id="images-block"
										className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
									>
										{TenantFormImagesWithUrl.map((imageObject, index) =>
											imageComponent({
												image: imageObject?.image?.picture,
												key: index,
												src: imageObject?.url,
												inputFieldId: "tenant-images",
												trashAble: true,
												onTrashClick: () => {
													let images = [...formData?.pictures];
													let image = [...TenantFormImagesWithUrl]
													images.splice(index, 1);
													image.splice(index, 1);
													setTenantFormImagesWithUrl(image);
													setFormData({...formData,pictures : images})
												},
											})
										)}
									</div>
								</div>
							</section>
						</div>
					) : null}
				</div> */}
          <hr />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-emergency-information.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Emergency Information:
              </label>
            </div>
          </div>
          {/* <div className="md:items-center w-full mt-4">
            <div className="md:w-full">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Emergency Contacts:
              </label>
            </div>
          </div> */}
          <EmergencyContacts data={tenantEmergencyContacts} handleEmergencyContactTrue={handleEmergencyContactTrue} hiddenData={false} ownerSettings={ownerSettings}/>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-allergies.svg"
                alt=""
              />  
                </div> 
                
                <div className="md:w-1/5 flex">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Known Allergies:
                  </label>
                  <Tooltip data={"It can be beneficial to know certain allergies (like bee stings or nuts) that can have potential life-threatening implications. This information can help you be a better landlord and to be able to respond better in an emergency."}/>
                </div>
                <div className="md:w-4/5 ml-2">
                  <CKEditor
                    initData={editorData?.known_allergies}
                    onChange={(e) => {
                     const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                        ...editorData,
                        known_allergies: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
              <hr />

              <div className="md:flex mt-3">
              <div className="md:w-1/8">
                <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-emergency-information.png"
                alt=""
              />  
                </div> 
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Other Emergency Info:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData2?.other_emergency_info}
                    onChange={(e) => {
                     const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData2({
                        ...editorData2,
                        other_emergency_info: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
        <TenantNotifications data={tenantNotification} handleNotificationTrue={handleNotificationTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-notes-and-files.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Notes & Files:
              </label>
              <Tooltip data={" You can add notes and files with different levels of access in order to keep and share the information that you need to!"}/>
            </div>
          </div>
          <div className="md:flex mt-4">
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Public Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-public-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyPublicDocumentsWithUrl?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyPublicDocumentsWithUrl.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyPublicDocumentsWithUrl];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyPublicDocumentsWithUrl(fileLists);
                                let propertyCopy = [...propertyPublicDocumentsWithUrl];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, property_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyPublicDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery  */}

          <div >
            {propertyPublicDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-public-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_files = fileLists;
                            setPropertyPublicDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_files[fileIndex].title = title;
                                setPropertyPublicDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-red.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Public Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData?.property_tenant_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData({
                        ...editorData,
                        property_tenant_notes: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="md:flex mt-4">
            <div className="md:items-center w-full mt-4">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-staff.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Staff Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-staff-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyStaffDocumentsWithUrl?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyStaffDocumentsWithUrl.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyStaffDocumentsWithUrl];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyStaffDocumentsWithUrl(fileLists);
                                let propertyCopy = [...propertyStaffDocumentsWithUrl];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, property_staff_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyStaffDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery  */}

          <div >
            {propertyStaffDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-staff-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyStaffDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-staff-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_staff_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_staff_files = fileLists;
                            setPropertyStaffDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_staff_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_staff_files[fileIndex].title = title;
                                setPropertyStaffDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-staff.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Staff Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData2?.property_staff_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData2({
                        ...editorData2,
                        property_staff_notes: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="md:flex mt-4">
            <div className="md:w-2/3">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-property-manager.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Property Manager Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-manager-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyManagerDocumentsWithUrl?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyManagerDocumentsWithUrl.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyManagerDocumentsWithUrl];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyManagerDocumentsWithUrl(fileLists);
                                let propertyCopy = [...propertyManagerDocumentsWithUrl];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, property_manager_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyManagerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery  */}

          <div >
            {propertyManagerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-manager-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyManagerDocumentsWithUrl.map(
                        (fileObject, fileIndex) =>
                          fileComponent({
                            file: fileObject?.file,
                            title: fileObject?.title,
                            key: fileIndex,
                            inputFieldId: "property-manager-files",
                            trashAble: true,
                            editAble: true,
                            onTrashClick: () => {
                              const fileLists = [
                                ...formData?.property_manager_files,
                              ];
                              fileLists.splice(fileIndex, 1);
                              formData.property_manager_files = fileLists;
                              setPropertyManagerDocumentsWithUrl(fileLists);
                            },
                            onEditClick: () => {
                              const fileLists = [...formData?.property_manager_files];
                              const currentTitle = fileLists[fileIndex].title || "";
                            
                              Swal.fire({
                                title: "Edit File",
                                html: `
                                  <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                                `,
                                focusConfirm: false,
                                preConfirm: () => {
                                  const title = document.getElementById('swal-input1').value;
                                  return title;
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  const title = result.value;
                                  fileLists[fileIndex].title = title;
                                  formData.property_manager_files[fileIndex].title = title;
                                  setPropertyManagerDocumentsWithUrl(fileLists);
                                }
                              });
                            }
                          })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-property-manager.png"
                    alt=""
                  />
                </div>
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Property Manager Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData1?.property_manager_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData1({
                        ...editorData1,
                        property_manager_notes: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="md:flex mt-4">
            <div className="md:w-1/3">
              <div className="flex">
                <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                  Owner Files:
                </label>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] m-auto">
              <div className="flex md:items-center md:justify-start md:w-2/3">
                <div className="md:w-1/2">
                  <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                      Upload files
                    </span>
                    <input
                      id="property-owner-files"
                      type="file"
                      accept={fileTypes}
                      multiple={true}
                      hidden={true}
                      onChange={(e) => {
                        let files = e.target.files;
                        let newImages = [];
                        let maxOrderNumber = 0;
                      
                        // Find the highest order number in the current images
                        propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          if (imageObject.order_number > maxOrderNumber) {
                            maxOrderNumber = imageObject.order_number;
                          }
                        });
                      
                        // Calculate the total number of images (existing images + new images)
                        const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;
                      
                        // Loop through each file and convert to base64
                        files.forEach((file, i) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const fileBase64 = reader.result;
                            try {
                              const res = await ApiRequest.post(fileApi, {
                                file: fileBase64,
                              });
                      
                              // Add the new image to the array with a unique order number
                              newImages.push({
                                file: res?.data?.data?.file,
                                title: res?.data?.data?.file,
                                url:fileBase64
                              });
                      
                              // If all new images have been processed, update the state
                              if (newImages.length === files.length) {
                                let fileLists = [...propertyOwnerDocumentsWithUrl];
                                fileLists = [...fileLists, ...newImages];
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                                let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                                propertyCopy.files = fileLists.map(
                                  (picObj) => ({
                                    file: picObj.file,
                                    title: picObj.title,
                                  })
                                );
                                setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                              }
                            } catch (err) {
                              // console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                        });
                      }}
                    />
                  </label>
                </div>
                <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Gallery  */}

          <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/8">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/img/properties/system-notes-private.png"
                    alt=""
                  />
                </div>
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Owner Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                    initData={editorData3?.property_owner_notes}
                    onChange={(e) => {
                      const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                      setEditorData3({
                        ...editorData3,
                        property_owner_notes: cleanText,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
        {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/system-interactions.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Interactions:
              </label>
            </div>
          </div> */}
          {/* {tenantInteraction?.map((row, index) => {
            const interactions = [...tenantInteraction];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="date"
                          type="date"
                          placeholder="Steve"
                          required={false}
                          value={stringToDate(row?.date)}
                          onChange={(e) => {
                            interactions[index].date = formatDate(e.target.value);
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-2">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Time:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="time"
                          type="time"
                          placeholder="Steve"
                          required={false}
                          value={stringToTime(row?.time)}
                          onChange={(e) => {
                            console.warn("time", e.target.value);
                            const tTime = formatAMPM(e.target.value);
                            interactions[index].time = tTime;
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction_type_id`}
                          value={row?.interaction_type_id}
                          onChange={(e) => {
                            interactions[index].interaction_type_id = parseInt(
                              e.target.value
                            );
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        >
                          {interactionType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {row?.interaction_type_id === 7 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.other_type_description}
                          onChange={(e) => {
                            interactions[index].other_type_description =
                              e.editor.getData();
                            setTenantInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={row?.notes}
                        onChange={(e) => {
                          interactions[index].notes = e.editor.getData();
                          setTenantInteraction(interactions);
                          setFormData({
                            ...formData,
                            interactions: interactions,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:items-center md:justify-start md:w-6/12 mt-3">
                    <div className="md:w-2/3">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Files:
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload Files
                        </span>
                        <input
                          id={`t-interation-files-${index}`}
                          type="file"
                          accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          onChange={(e) => {
                            let files = e.target.files;
                            files.forEach((file) => {
                              const reader = new FileReader();
                              reader.addEventListener("load", async () => {
                                const fileBase64 = reader.result;
                                try {
                                  console.log(fileBase64);
                                  const res = await ApiRequest.post(fileApi, {
                                    file: fileBase64,
                                  });
                                  // console.log("res", res?.data?.data?.file);
                                  // console.log("index",index);
                                  // let index = [index];
                                  // [...index].sort()

                                  tenantInteraction[index].files.push({
                                    file: res?.data?.data?.file,
                                  });
                                  setFormData({
                                    ...formData,
                                    interactions: interactions,
                                  });

                                  // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
                                  // console.warn("base64converted files",formData)
                                } catch (err) {
                                  console.log(err);
                                }
                              });
                              reader.readAsDataURL(file);
                            });
                            const fileLists = [...tenantInteractionFiles];
                            fileLists[index] = getFilesAndURLs(e);
                            setTenantInteractionFiles(fileLists);
                            // propertyMeasurements[index].files =
                            // 		getFilesAndURLs(e)
                            // setFormData({...formData, measurements : measurements})
                          }}
                        />
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                        <span className="text-gray-600">
                          {(() => {
                            const filesCount =
                              formData?.interactions[index]?.files?.length || 0;
                            return filesCount > 1
                              ? filesCount + " Documents"
                              : filesCount == 1
                              ? filesCount + " Document"
                              : null;
                          }).call()}
                        </span>
                      </label>
                    </div>
                  </div>

                  <div >
                    {formData?.interactions[index]?.files?.length ? (
                      <div>
                        <section className="overflow-hidden w-full text-gray-700 mt-4">
                          <div className="container w-full px-3 m-auto">
                            <div
                              id={`p-tenant-documents-block-${index}`}
                              className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                            >
                              {formData?.interactions[index]?.files?.map(
                                (fileObject, fileIndex) =>
                                  fileComponent({
                                    file: fileObject?.file,
                                    key: fileIndex,
                                    inputFieldId: `t-tenant-files-${index}`,
                                    trashAble: true,
                                    onTrashClick: () => {
                                      const fileLists = [
                                        ...tenantInteractionFiles,
                                      ];
                                      fileLists[index].splice(fileIndex, 1);
                                      interactions[index].files = fileLists;
                                      setTenantInteractionFiles(fileLists);
                                      setFormData({
                                        ...formData,
                                        interactions: interactions,
                                      });
                                    },
                                  })
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })} */}
          {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                  <button
                    type="button"
                    className="btn2"
                    onClick={() => {
                      setTenantInteraction([
                        ...tenantInteraction,
                        initialInteractionData,
                      ]);

                      setTenantInteractionFiles([
                        ...tenantInteractionFiles,
                        [],
                      ]);
                    }}
                  >
                    <i class="fa fa-plus"></i> Add Interaction
                  </button>
              </div>
            </div>
          </div> */}
          <Interactions data={tenantInteraction} handleInteractionTrue={handleInteractionTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
        <Keys data={tenantKey} handleKeyTrue={handleKeyTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
        <LoanableItems data={tenantItem} handleItemTrue={handleItemTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
        <Vehicles data={tenantVehicle} handleVehicleTrue={handleVehicleTrue} hiddenData={false} />
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
        <Children data={tenantChildren} handleChildrenTrue = {handleChildrenTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={9}>
        <Pets data={tenantPet} handlePetTrue={handlePetTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={10}>
        <OtherLivings data={tenantOtherLiving} handleOtherLivingTrue= {handleOtherLivingTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={11}>
        <Employments data={tenantEmployment} handleEmploymentTrue={handleEmploymentTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={12}>
        <References data={tenantReference} handleReferenceTrue={handleReferenceTrue} hiddenData={false} ownerSettings={ownerSettings}/>
        </TabPanel>
        <TabPanel value={tabValue} index={13}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/tenants/tenant-moving-address.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-out Address:
              </label>
              <Tooltip data={"When they move out, this is the address they are moving to. The Owner and the Tenant can update this address and there are a few places where this address can be updated in the system"}/> 
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-5">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address1"
                    type="text"
                    placeholder="Apt. suite no. 1"
                    required={false}
                    value={formData?.moveout_address1}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_address1:1});
                      setFormData({
                        ...formData,
                        moveout_address1: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_address2"
                    type="text"
                    placeholder="Jeff Street"
                    value={formData?.moveout_address2}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_address2:1});
                      setFormData({
                        ...formData,
                        moveout_address2: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_country"
                      type="text"
                      value={formData?.moveout_country}
                      placeholder="Albuquerque"
                      required={false}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_country: e.target.value,
                        });
                        setCreateLog({...createLog,moveout_country:1})
                        getStates(e.target.value)
                      }}
                    >
                      <option value=" ">Select Country</option>
                      {countries?.map((country)=>(
                        <option value={country?.id}>{country?.country}</option>
                      ))}
                    </select>
                  </div>
                </div>                
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_state"
                      required={false}
                      value={formData?.moveout_state}
                      onChange={(e) => {
                        setCreateLog({...createLog,moveout_state:1})
                        setFormData({
                          ...formData,
                          moveout_state: e.target.value,
                        });
                        getCities(e.target.value)
                      }}
                    >
                      <option value="">Select State</option>
                      {states?.map((state)=>(
                        <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
                <div className="md:flex md:items-center w-full mt-3">

                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_city"
                      type="text"
                      value={formData?.moveout_city}
                      placeholder="Albuquerque"
                      required={false}
                      onChange={(e) => {
                        const zip = cities?.map((col) =>{
                          if(col?.id == e.target.value)
                          {
                            return col?.zip_code
                          }
                        })
                        setCreateLog({...createLog,moveout_city:1})
                        const filteredArr = zip.filter((value) => value !== undefined);
                        const zipCode = filteredArr.join(', ');
                        setFormData({
                          ...formData,
                          moveout_city: e.target.value,
                          moveout_zip_code:zipCode
                        });
                      }}
                    >
                      <option value=" ">Select City</option>
                      {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_zip_code"
                      type="text"
                      placeholder="90210"
                      required={false}
                      value={formData?.moveout_zip_code}
                      onChange={(e) => {
                        setCreateLog({...createLog,moveout_zip_code:1})
                        setFormData({
                          ...formData,
                          moveout_zip_code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number:
                  </label>
                </div>
                <div className="md:w-3/5">
                {formData?.moveout_number_type == 8 ?
                    <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    maxLength={ownerSettings?.phone_number_format?.length}
                    value={formData?.moveout_number}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number:1});
                      setFormData({
                        ...formData,
                        moveout_number: e.target.value,
                      });
                    }}
                  />:
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number"
                    type="tel"
                    required={false}
                    placeholder="(999) 999-9999"
                    maxLength={ownerSettings?.phone_number_format?.length}
                    value={formatPhoneNumber(formData?.moveout_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number:1});
                      setFormData({
                        ...formData,
                        moveout_number: removeNonNumeric(e.target.value),
                      });
                    }}
                  />
                  }
                </div>
                {/* <div className="md:w-1/6">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Type:
                  </label>
                </div> */}
                <div className="md:w-1/5 pl-3">
                  <select
                    required={false}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_number_type"
                    value={formData?.moveout_number_type}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_number_type:1});
                      setFormData({
                        ...formData,
                        moveout_number_type: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Type</option>
                    {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="moveout_email"
                    type="email"
                    placeholder="jhon.doe@email.com"
                    value={formData?.moveout_email}
                    onChange={(e) => {
                      setCreateLog({...createLog,moveout_email:1});
                      setFormData({
                        ...formData,
                        moveout_email: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-4">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                  <div className="md:w-2/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Move-Out Date:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <DatePicker
                      value={stringToDate(formData?.moveout_date)}
                      onChange={(e) => {
                        const date = formatDate(e);
                        setFormData({
                          ...formData,
                          moveout_date: date,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Move-out Reason:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="moveout_reason_id"
                      value={formData?.moveout_reason_id}
                      required={false}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          moveout_reason_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {moveoutReason.map((reason) => (
                        <option value={reason?.id}>{reason?.reason}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <div className="flex flex-row-reverse justify-between items-center mt-6 mb-3">
            <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 flex justify-between items-center gap-4">Save Information</button>
          </div>
      </form>
    </>}
    </>
  );
};
