import React from 'react'
import { useState,useEffect } from 'react';
import ApiRequest from '../../../utility/http';
import imgUrl from '../../ImageUrl';
import Spinner from '../../Spinner/Spinner';
import Swal from 'sweetalert2';
export const WebsiteSetting = () => {
    const [websiteSetting,setWebsiteSetting] = useState();
    const [loader,setLoader] = useState();
    const [editWebsiteSetting,setEditWebsiteSetting] = useState();
    const [editWebsiteSettingImage,setEditWebsiteSettingImage] = useState();
    const [editWebsiteSettingFile,setEditWebsiteSettingFile] = useState();
    const [editID,setEditID] = useState();
    const [editOrder,setEditOrder] = useState();
    const [allWebsiteSetting,setAllWebsiteSetting] = useState([]);

    console.log("@ALLMATERIAL",allWebsiteSetting);
    const getWebsiteSetting = async()=>{
        const response = await ApiRequest.get('/public/api/auth/get-website-settings')
        if(response.status == 200)
        {
          setAllWebsiteSetting(response?.data?.data?.settings);
          setEditWebsiteSetting(response?.data?.data?.settings?.website_name);
            setEditID(response?.data?.data?.settings?.id);
            setEditOrder(response?.data?.data?.settings?.order_number);
            setEditWebsiteSettingImage(response?.data?.data?.settings?.max_image_upload_size)
            setEditWebsiteSettingFile(response?.data?.data?.settings?.max_file_upload_size)
          setLoader(response);
        }
      }
    
    //   const indexFind = (id) => {
    //     const editType = allWebsiteSetting.find(websiteSetting => websiteSetting.id === id);
    //     // console.log("editType", editType);
        
    //   }
    //   const deleteData =async(id)=>{
    //     const { isConfirmed } = await Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'This will Delete the Material Coverage Unit. Do you want to proceed?',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes',
    //       cancelButtonText: 'Cancel',
    //     });
      
    //     // If user confirms, Inactivate the Nearby
    //     if (isConfirmed) {
    //       setLoader(undefined);
    //       try {
    //         // const ArrayId = [];
    //         // ArrayId.push(id);
    //         const response = await ApiRequest.delete(
    //           `/public/api/auth/delete-material-coverage-unit?id=${id}`
              
    //         );
    //       getWebsiteSetting();
      
    //         // Show success message
    //         if(response.status == 200)
    //         {
    //           Swal.fire({
    //           title: 'Success!',
    //           text: 'The Material Coverage Unit have been Deleted.',
    //           icon: 'success',
    //           confirmButtonText: 'OK',
    //         });
    //       }
      
    //       } catch (error) {
    //         // Show error message
    //         Swal.fire({
    //           title: 'Error!',
    //           text: 'An error occurred while Deleting the Material Coverage Unit. Please try again later.',
    //           icon: 'error',
    //           confirmButtonText: 'OK',
    //         });
    //       } finally {
    //         setLoader('');
    //       }
    //     }
    //   }
    // const moveUp = ()=>{
    //     console.log('up');
    // }
    // const moveDown = ()=>{
    //     console.log('Down');
    // }
    // const handleOrderChange = (id, direction) => {
    //     const index = allWebsiteSetting?.findIndex(item => item.id === id);
    //     const newListItems = [...allWebsiteSetting];
    //     const currentItem = newListItems[index];
    
    //     if (direction === "up" && index > 0) {
    //         const previousItem = newListItems[index - 1];
    //         currentItem.order_number = previousItem.order_number;
    //         previousItem.order_number = index + 1;
    //         newListItems[index - 1] = currentItem;
    //         newListItems[index] = previousItem;
    //     } else if (direction === "down" && index < newListItems.length - 1) {
    //         const nextItem = newListItems[index + 1];
    //         currentItem.order_number = nextItem.order_number;
    //         nextItem.order_number = index + 1;
    //         newListItems[index + 1] = currentItem;
    //         newListItems[index] = nextItem;
    //     }
    
    //     setAllWebsiteSetting(newListItems);
    // };
    
    // const sortedData = allWebsiteSetting?.sort((a, b) => a.order_number - b.order_number);
      const AddWebsiteSetting = async ()=>{

        setLoader(undefined)
        // const sortedFeatures = allWebsiteSetting?.sort((a, b) => b.order_number - a.order_number);
        // const lastOrderNumber = sortedFeatures[0]?.order_number + 1;
        // const payload = {
        //   unit:websiteSetting,
        //   order_number:lastOrderNumber
        // }
        const editPayload = {
          id:editID,
          website_name:editWebsiteSetting,
          max_image_upload_size:editWebsiteSettingImage,
          max_file_upload_size:editWebsiteSettingFile,
        }
        const response = await ApiRequest.post('/public/api/auth/website-settings',
        editPayload
        )
        if(response?.status == 200)
        {
          getWebsiteSetting();
        //   setWebsiteSetting('')
        }
      }
      useEffect(() => {
        getWebsiteSetting();
      }, [])
      
  return (
    <>
    
        <div class="md:flex md:items-center w-full mt-4 mx-2">
          <div class="md:w-2/5">
            <label class="block capitalize text-gray-700 font-bold mb-2">
            Maximum File Upload Size
            </label>
            <span class="block text-gray-700 font-light mb-2 mr-2">
            Determines how many MB is the max file upload.
            </span>
          </div>
          <div class="md:flex-1 mr-4 pr-4 mb-3">
            <div class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow">
              {loader == undefined ? <Spinner/> :
              <div className="flex">
                <form
                onSubmit={AddWebsiteSetting}
                >
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Website Name:
                      </label>
                    </div>
                    <div class="md:w-1/2">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="websiteSetting"
                        type="text"
                        value={editWebsiteSetting}
                        placeholder="Name"
                        onChange={(e)=>{
                          setEditWebsiteSetting(e.target.value);
                        }}
                      />
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        Image Size:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="websiteSettingImage"
                        type="Number"
                        value={editWebsiteSettingImage}
                        placeholder="10"
                        onChange={(e)=>{
                          setEditWebsiteSettingImage(e.target.value);
                        }}
                      />
                    </div>
                    
                  </div>
                  <div class="md:flex md:items-center w-full mt-4 mx-2">
                    <div class="md:w-1/2">
                      <label class="block capitalize text-gray-700 font-bold mb-2">
                        File Size:
                      </label>
                    </div>
                    <div class="md:w-1/2 ">
                      <input
                        class="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="websiteSettingFile"
                        type="number"
                        value={editWebsiteSettingFile}
                        placeholder="10"
                        onChange={(e)=>{
                          setEditWebsiteSettingFile(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                    <button
                        type="button"
                        class="bg-blue-500 text-white py-2 px-4 rounded ml-2"
                        onClick={()=>{
                            AddWebsiteSetting();
                        }}
                      >
                        Update
                      </button>
                </form>
              </div>}
              
            </div>
          </div>
        </div>
  </>
  )
}
