const getCookie = (name) =>
  Object.fromEntries(
    document.cookie.split(";").map((cookie_el) => cookie_el.split("="))
  )[name];

const checkAuthentication = () => !!localStorage.getItem("access_token");
// console.log(checkAuthentication());
// const getAccessToken = () => getCookie("access_token");
// const getRefreshToken = () => getCookie("refresh_token");
const getAccessToken = () => localStorage.getItem("access_token");
const getRefreshToken = () => localStorage.getItem("refresh_token");
export { checkAuthentication, getAccessToken, getRefreshToken };
