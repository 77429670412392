import { createSlice } from "@reduxjs/toolkit";
import {getFeatures, addEditFeaturesApi} from "./Thunk";
const FeaturesSlice = createSlice({
  name: "features",
  initialState: {
    status: null,
    features: {},
    addEditFeatures:{}
  },
  extraReducers: {
    [getFeatures.pending]: (state) => {
      state.status = "loading";
    },
    [getFeatures.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.features = payload;
    },
    [getFeatures.rejected]: (state, _error) => {
      state.status = "failed";
      state.features = {};
    },
    [addEditFeaturesApi.pending]: (state) => {  
      state.status = "loading";
    },
    [addEditFeaturesApi.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.addEditFeatures = payload;
    },
    [addEditFeaturesApi.rejected]: (state, _error) => {
      state.status = "failed";
      state.addEditFeatures = {};
    }
  },
});
export default FeaturesSlice.reducer;
