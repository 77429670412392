import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import ApiRequest from "../../utility/http";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { style } from "@mui/system";
import Loading from "../../components/Loader/Loading";
import Swal from "sweetalert2";
import { getUnit } from "@mui/material/styles/cssUtils";
import CustomLoader from "../../components/Loader/CustomLoader";


const UnitTable = ({filterText,data,Data}) => {
    // console.log("userRoles", userRoles)
//   const dispatch = useDispatch();
//   const allProperties = useSelector(
//     (state) => state.properties.properties.data
//   );
//   const loading = useSelector((state) => state.properties.status);
//   const allPropertiesTypes = useSelector(
//     (state) => state.properties.propertiesTypes.data
//   );

//   const newArray1 = allProperties?.map((a) => ({ ...a }));
//   const newArray2 = allPropertiesTypes?.map((a) => ({ ...a }));
const [unit,setUnit] = useState([]);
const [preferenceData,setPreferenceData] = useState();
const [loader,setLoader]= useState();
const [selectFilter,setSelectFilter] = useState();
const [totalUnit,setTotalUnit] = useState();
const [bulkAction , setBulkAction] = useState({
  bulk_action:""
});
console.log("@units",unit);
const PropertyID = localStorage.getItem('Property ID');
const showUnit = async ()=>{
  setLoader(undefined);
    const response = await ApiRequest.get(
        "public/api/auth/get-my-units"
    );
    setLoader(response.status)
    if(response.status == 200)
    {
        setUnit(response?.data?.data.filter(obj=>obj.property_id == PropertyID))
        setTotalUnit(response?.data?.data.filter(obj=>obj.property_id == PropertyID))
        console.log("Response table",response);
    }
}
const showVacantUnit = async () => {
  setLoader(undefined);
  // const PropertyID = localStorage.getItem('Property ID');
  const response = await ApiRequest.get("public/api/auth/get-my-units");
  setLoader(response.status);
  if (response.status == 200) {
    const filteredData = response?.data?.data?.filter(obj => obj.unit_status_id == 1 && obj.property_id == PropertyID);
    setUnit(filteredData);
    console.log("Response table", response);
  }
};
const showPreppingUnit = async () => {
  setLoader(undefined);
  // const PropertyID = localStorage.getItem('Property ID');
  const response = await ApiRequest.get("public/api/auth/get-my-units");
  setLoader(response.status);
  if (response.status == 200) {
    const filteredData = response?.data?.data?.filter(obj => obj.unit_status_id == 2 && obj.property_id == PropertyID);
    setUnit(filteredData);
    console.log("Response table", response);
  }
};
const showRentedUnit = async () => {
  setLoader(undefined);
  // const PropertyID = localStorage.getItem('Property ID');
  const response = await ApiRequest.get("public/api/auth/get-my-units");
  setLoader(response.status);
  if (response.status == 200) {
    const filteredData = response?.data?.data?.filter(obj => obj.unit_status_id == 3 && obj.property_id == PropertyID);
    setUnit(filteredData);
    console.log("Response table", response);
  }
};

const showInActiveUnit = async () => {
  setLoader(undefined);
  // const PropertyID = localStorage.getItem('Property ID');
  const response = await ApiRequest.get("public/api/auth/get-my-units");
  setLoader(response.status);
  if (response.status == 200) {
    const filteredData = response?.data?.data?.filter(obj => obj.unit_status_id == 4 && obj.property_id == PropertyID);
    setUnit(filteredData);
    console.log("Response table", response);
  }}
const showActiveUnit = async () => {
  setLoader(undefined);
  // const PropertyID = localStorage.getItem('Property ID');
  const response = await ApiRequest.get("public/api/auth/get-my-units");
  setLoader(response.status);
  if (response.status == 200) {
    const filteredData = response?.data?.data?.filter(obj => obj.is_active == 1 && obj.property_id == PropertyID);
    setUnit(filteredData);
  }
};


useEffect(() => {
  showUnit();
}, [data])
useEffect(() => {
  setPreferenceData(Data);
}, [Data])
const makeInActive = async (id) => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Inactivate</strong> the Unit. Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, Inactivate the Unit
  const arrayId = []
    arrayId.push(id);
  if (isConfirmed) {
    setLoader(undefined);
    try {
      // const ArrayId = [];
      // ArrayId.push(id);
      const response = await ApiRequest.post('/public/api/auth/mark-units-as-inactive',{
        ids:arrayId,
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Unit have been <strong>Inactivated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Inactivating</strong> the Unit. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const makeActive = async (id) => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Activate</strong> the Unit. Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });
  const arrayId = []
  arrayId.push(id);
  // If user confirms, activate the Unit
  if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('/public/api/auth/mark-units-as-active',{
        ids:arrayId        
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Unit have been <strong>Activated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Activating</strong> the Unit. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const exportPDF = ()=>{
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Units";
  const headers = [["System ID", "Unit Name","Stage","Status"]];

  const data = filteredData.map(elt => [elt.unique_id, elt.name, unitStatus?.filter(row => elt.unit_status_id === row?.id)?.map(row => row?.status),elt?.is_active == 1 ? "Acitve":"Inactive"]);

  

  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Units.pdf")
}
const exportCSV = () => {
  // Get data from data tables
  const TotalData = filteredData.map(elt => [elt.unique_id, elt.name, unitStatus?.filter(row => elt.unit_status_id === row?.id)?.map(row => row?.status),elt?.is_active == 1 ? "Acitve":"Inactive"]);

  // Convert the data to a CSV string

  const headers = ["System ID", "Unit Name","Stage","Status"];
  // let content = {
  //   head:headers,
  //   body:TotalData,
  // }
  TotalData.unshift(headers)
  const csv = Papa.unparse(TotalData);
  // Create a blob of the data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  // Create a link to trigger the download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "Units.csv");
  link.click();
};
const [selectedRow, setSelectedRow] = useState([]);
const [selectedRows, setSelectedRows] = useState([]);

console.log("SELECTED ROW",selectedRow);

const handleRowSelected = (state) => {
  setSelectedRows(state.selectedRows);
};
const handleRowDoubleClick = row => {
  setSelectedRow(row);
  Swal.fire({
    title: 'Edit Unit Name',
    customClass: {
      confirmButton: "btn2"
    },
    html: `
      <input id="swal-input1" class="swal2-input" placeholder="Title" value="${row.name}">
    `,
    focusConfirm: false,
    preConfirm: async () => {
      const title = document.getElementById('swal-input1').value;
      if (title.trim() === "") {
        Swal.showValidationMessage("Please enter a name");
        return false;
      }
      return title;
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      const title = result.value;
      const response = await ApiRequest.post('/public/api/auth/inline-change-unit-name', {
        id: row?.id,
        name: title
      });
      if (response.status === 200) {
        
        Swal.fire({
          title: "Name Change",
          icon: "success",
          customClass:{
            confirmButton:"btn2"
          }
        });
        showUnit();
      }
    }
  });
};



const bulkExportPDF = ()=>{
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Units";
  const headers = [["System ID", "Unit Name","Stage","Status"]];

  const data = selectedRows?.map(elt => [elt.unique_id, elt.name, unitStatus?.filter(row => elt.unit_status_id === row?.id)?.map(row => row?.status),elt?.is_active == 1 ? "Acitve":"Inactive"]);

  let content = {
    startY: 50,
    head: headers,
    body: data
  };
  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("Units.pdf")
}
const bulkExportCSV = () => {
  // Get data from data table
  const TotalData = selectedRows?.map(elt => [elt.unique_id, elt.name, unitStatus?.filter(row => elt.unit_status_id === row?.id)?.map(row => row?.status),elt?.is_active == 1 ? "Acitve":"Inactive"]);
  // Convert the data to a CSV string

  const headers = ["System ID", "Unit Name","Stage","Status"];
  // let content = {
  //   head:headers,
  //   body:TotalData,
  // }
  TotalData.unshift(headers)
  const csv = Papa.unparse(TotalData);
  // Create a blob of the data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  // Create a link to trigger the download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "Units.csv");
  link.click();
};
const bulkMakeInActive = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Inactivate</strong> the Unit(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, Inactivate the Unit
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  // console.log("ARRAYID",arrayId);
  if (isConfirmed) {
    setLoader(undefined);
    try {
      // const ArrayId = [];
      // ArrayId.push(id);
      const response = await ApiRequest.post('/public/api/auth/mark-units-as-inactive',{
        ids:arrayId,
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Unit(s) have been <strong>Inactivated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Inactivating</strong> the Unit(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const bulkMakeActive = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Activate</strong> the Unit(s). Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, activate the Unit
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
  if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('/public/api/auth/mark-units-as-active',{
        ids:arrayId          
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Unit(s) have been <strong>Activated</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Activating</strong> the Unit(s). Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const deleteUnit = async (id) => {
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Delete</strong> the Unit. Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, Inactivate the Unit
  if (isConfirmed) {
    setLoader(undefined);
    try {
      // const ArrayId = [];
      // ArrayId.push(id);
      const response = await ApiRequest.delete(
        `public/api/auth/delete-unit?unit_id=${id}`
        
      );
    showUnit();

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Unit have been <strong>Deleted</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Deleting</strong> the Unit. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
}
const duplicateUnit = async (id) => {
  try {
    const result = await Swal.fire({
      title: 'How many Duplicate Units should be created?',
      html: `
        <input id="swal-input1" class="swal2-input" placeholder="Number">
      `,
      focusConfirm: false,
      showCancelButton: true, // added this to show the cancel button
      confirmButtonClass:'btn2',
      customClass: {
        cancelButton: "bg-red-700"
      },
      cancelButtonText: 'Cancel', // added this to change the text of the cancel button
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
        ];
      },
    });

    if (result.isConfirmed) {
      const number_of_units = result.value[0];
      // console.log('@number_of_units',number_of_units);
      const response = await ApiRequest.post(`/public/api/auth/duplicate-unit?unit_id=${id}&number_of_units=${number_of_units}`)
      if (response.status === 200) {
        showUnit();
        Swal.fire({
          title: 'Success!',
          html: 'The Unit have been Duplicated.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonClass:'btn2',
        });
      }
    }
    else { // added this block to handle the cancel button
      Swal.fire({
        title: 'Cancelled',
        html: 'The Unit Duplication have been cancelled.',
        icon: 'info',
        confirmButtonText: 'OK',
        confirmButtonClass:'btn2',
      });
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: 'Error',
      html: 'An error occurred while Duplicating the Unit.',
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonClass:'btn2',
    });
  }
};
const bulkDelete = async () => {
  // Show confirmation dialog
  const { isConfirmed } = await Swal.fire({
    title: 'Are you sure?',
    html: 'This will <strong>Delete</strong> the Units. Do you want to proceed?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  });

  // If user confirms, activate the property
  const arrayId = []
  selectedRows?.map((row)=>{
    arrayId.push(row.id);
  })
   console.log('arrayId',arrayId);
  // const commaSeparatedIds = arrayId.join(',');

  if (isConfirmed) {
    setLoader(undefined);
    try {
      const response = await ApiRequest.post('public/api/auth/delete-units',{
        ids:arrayId       
    });
    showUnit()

      // Show success message
      if(response.status == 200)
      {
        Swal.fire({
        title: 'Success!',
        html: 'The Units have been <strong>Deleted</strong>.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    } catch (error) {
      // Show error message
      Swal.fire({
        title: 'Error!',
        html: 'An error occurred while <strong>Deleting</strong> the Units. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoader('');
    }
  }
};
const [unitStatus,setUnitStatus] = useState([]);
const getAllUnits = async()=>{
  const response = await ApiRequest.get('/public/api/auth/get-units-statuses');
  if(response.status === 200)
  {
    setUnitStatus(response?.data?.data);
  }
}
useEffect(()=>{
  getAllUnits()
},[])
  const filteredColumns = [
    preferenceData?.system_id === 1
    ? {
      name: "System ID",
      selector : (data) => data.unique_id,
      sortable: true,
      
    }:null,
    preferenceData?.name === 1
    ? {
      name: "Unit Name",
      selector : (data) => data.name,
      sortable: true,
    }:null,
    preferenceData?.type === 1
    ? {
      name: "Type",
      selector : (data) => "Room",
      sortable: true,
    }:null,
    preferenceData?.stages === 1
    ? {
      name: "Stage",
      selector: (data) => {
        const row = unitStatus.find(row => row?.id === data.unit_status_id);
        return row?.status === 'Vacant' 
  ? <span className="status-inactive uppercase">{row?.status}</span> 
  : row?.status === 'Rented' 
    ? <span className="status-active uppercase">{row?.status}</span> 
        : row?.status === 'Prepping' 
          ? <span className="status-warning uppercase">{row?.status}</span> 
          : row?.status === 'Inactive' 
          ? <span className="status-inactive uppercase">{row?.status}</span> 
          :
          row?.status === 'Moving Out' 
          ? <span className="status-blue uppercase">{row?.status}</span> 
          :
           null;

      },      
      sortable: true,
    }:null,
    preferenceData?.issue === 1
    ? {
      name: "Issues",
      selector : (data) => "0",
      sortable: true,
    }:null,
    preferenceData?.last === 1
    ? {
      name: "Tenant Last",
      selector : (data) => "Robenshire",
      sortable: true,
    }:null,
    preferenceData?.first === 1
    ? {
      name: "Tenant First",
      selector : (data) => "James",
      sortable: true,
    }:null,
    preferenceData?.monthly_rent === 1
    ? {
      name: "Monthly Rent",
      selector : (data) => "$1400",
      sortable: true,
    }:null,
    preferenceData?.lease_start === 1
    ? {
      name: "Lease Start",
      selector : (data) => "03/16/20",
      sortable: true,
    }:null,
    preferenceData?.lease_expiry === 1
    ? {
      name: "Lease Expiry",
      selector : (data) => "MONTHLY",
      sortable: true,
    }:null,
    preferenceData?.balance === 1
    ? {
      name: "Balance",
      selector : (data) => "-$400.0",
      sortable: true,
    }:null,
    preferenceData?.last_inspection === 1
    ? {
      name: "Last Inspection",
      selector : (data) => "08/12/2022",
      sortable: true,
    }:null,
    preferenceData?.status === 1
    ? {
        name: "Status",
        selector: (row) => row.is_active == 1 ? <span class="status-active">
          <button className="uppercase" type="button" onClick={()=>{
          makeInActive(row.id)
        }}>
          Active
          </button>
          </span> 
          : 
        <span class="status-inactive">
          <button className="uppercase" 
          type="button" onClick={()=>{
                  makeActive(row.id)
                }}>
                  Inactive
                  </button>
          </span>,
        sortable: preferenceData?.status === 1,
      }
    : null,
    {
      name: "Options",
      cell: (row) => {
        return (
          <>
              <div className="flex">
            <Link to={`/units/show-unit/${row.id}`}>
            
                <button type="button" class="bg-transparent border-none md:w-6  p-0" title="View">
    
                    <img src='/assets/img/system-view.png' />
                  
                </button>
            </Link>
            <Link to={`/units/edit-unit/${row.id}`}>

                <button type="button" class="bg-transparent border-none md:w-6 p-0" title="Edit">
    
                    <img src='/assets/img/system-edit.png' />
                  
                </button>
            </Link>
                <button type="button" class="bg-transparent border-none md:w-6 p-0 mx-1" title="Inspection Report">
    
                  <img src='/assets/img/inspection-report.png' />
                  
                </button>
                <button type="button" class="bg-transparent border-none md:w-6 p-0 mx-1" title="Duplicate" onClick={()=>{
                  duplicateUnit(row.id)
                }}>
    
                  <img src='/assets/img/system-duplicate.png' />
                  
                </button>
               
               

                <button type="button" class="bg-transparent border-none md:w-6 p-0" title="Message">
    
                  <img src='/assets/img/system-message.png' />
                  
                </button>
               
               

                <button type="button" onClick={()=>{
                  deleteUnit(row.id)
                }} class="bg-transparent border-none md:w-6 p-0" title="Delete">
    
                  <img src='/assets/img/system-trashcan.png' />
                  
                </button>

                {/* {row.is_active == 1 ?
                <button type="button" onClick={()=>{
                  makeInActive(row.id)
                }} class="bg-green-700 border-none font-bold md:w-12 p-0">
    
                  ACTIVE
                  
                </button>
                :
                <button type="button" onClick={()=>{
                  makeActive(row.id)
                }} class="bg-red-700 border-none font-bold md:w-16 p-0">
    
                  INACTIVE
                  
                </button>} */}
               
               
                 </div>
          </>
        );
      },
    },
  ];
  
  const columns = filteredColumns.filter((column) => column !== null && column.selector !== null);
  const filteredData = unit?.filter(item =>
    item.name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.unique_id.toLowerCase().includes(filterText.toLowerCase())
  );
  const filtration = (data) => {
    // let filter;
    // console.log('@data',data);
    if (data == 4) {
      showInActiveUnit();
    }
     else if (data == 1) {
      // filter = unit.filter(obj => obj.is_active == 1);
      showVacantUnit();
    }
     else if (data == 2) {
      // filter = unit.filter(obj => obj.is_active == 1);
      showPreppingUnit();
    }
     else if (data == 3) {
      // filter = unit;
      showRentedUnit();
    } 
     else if (data == 'All') {
      // filter = unit;
      showUnit();
    } 
     else if (data == 'Active') {
      // filter = unit;
      showActiveUnit();
    } 
    else {
      // filter = unit;
      showUnit();
    }
  };
  
  
  const tableData = {
    columns : columns,
    data : filteredData,
    pagination: true,
    fixedHeader: true,
    fixedHeaderScrollHeight: '500px',
    sortFieldId: 1,
    sortDirection:"asc",
  };
//   const data =[
//     {
//         id:"355821647853265",
//         sent:"5/24/2022 TIME",
//         sender:"Sapp, Sean (UNIT A)",
//         recipient:"SYSTEM",
//         subject:"Question on Rent Due Date",
//         viewed:"5/24/2022 TIME",
//       },
//     {
//         id:"745852146932145",
//         sent:"5/24/2022 TIME",
//         sender:"SYSTEM",
//         recipient:"Sapp, Sean (UNIT A)",
//         subject:"Information requested about the pool ",
//         viewed:"Not Viewed",
//       },
//     {
//         id:"985632452185635",
//         sent:"5/24/2022 TIME",
//         sender:"Knabenshue, Chris(OWNER)",
//         recipient:"SYSTEM",
//         subject:"JUNE 2022 RENT INVOICE NOW AVAILABLE!",
//         viewed:"Not Viewed",
//       },

//   ];
  
const countActive = totalUnit?.map(obj => obj.is_active).filter(isActive => isActive === 1).length;
 

  return (
    <>
        {loader === undefined ? <Loading/>
        
      :
      <>
        <div className="flex md:w-full mt-3">
        <div className="flex-1">
          <div className="flex">
            {/* <img className="w-10 mr-5 cursor-pointer" src="/assets/img/system-settings-wheel.png" alt="Setting" /> */}
            <span className="mr-3 mt-2 data">{countActive} of {totalUnit?.length} Active Units</span>
            <img className="w-28 mr-5 cursor-pointer" src="/assets/img/system-upgrade-button.png" alt="Upgrade" />
          </div>
        </div>
        <div className="flex-1">
        <div className="flex flex-column">
        <span className="data">Total Monthly Incoming Rent: <span className="text-green-600">$3,100</span></span>
        </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <span className="mr-2 data">Total Open Issue Tickets: <span className="text-red-600">1</span></span>
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-csv.png" alt="CSV" title="CSV" onClick={exportCSV} />
        <img className="w-8 mr-3 cursor-pointer" src="/assets/img/properties/system-download-pdf.png" alt="PDF" title="PDF" onClick={exportPDF} />
        </div>
        </div>
        <div className="flex-1">
        <div className="flex">
        <label className="block capitalize text-gray-700 font-bold mb-2 mr-2 mt-2">Filter: </label>
        <select
        className="block appearance-none w-1/2 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        value={selectFilter}
        id="filtrationData"
        onChange={(e)=>{
          setSelectFilter(e.target.value);
          filtration(e.target.value);
        }}
        >
          <option value="All">All</option>
          <option value="Active">Active</option>
          {unitStatus?.map((row)=>(
            <option value={row?.id}>{row?.status}</option>
          ))}
        </select>
        </div>
        </div>
      </div>
      <DataTableExtensions filter={false} {...tableData}>
        <DataTable
        className="striped"
          // columns={columns}
          // data={newArray1}
          // pagination
          // fixedHeader
          // fixedHeaderScrollHeight="500px"
          // selectableRows
          // selectableRowsHighlight
          // highlightOnHover
          // defaultSortFieldId={1}
          pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
          selectableRows
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={handleRowSelected}
          onRowDoubleClicked={handleRowDoubleClick}
        />
        </DataTableExtensions>
        <div class="flex justify-end justify-items-center text-center gap-x-8 mt-3 mb-4">
        {selectedRows?.length > 0 ?<div className="md:flex md:items-center w-full md:w-8/12 px-3 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected value=" ">Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
                <option value={"MAKE ACTIVE"}>MAKE ACTIVE</option>
                <option value={"MAKE INACTIVE"}>MAKE INACTIVE</option>
                <option value={"DELETE"}>DELETE</option>
              </select>
            </div>
            <div className="w-1/2 ml-1 ">
               <button
                  type="button"
                  className="btn2"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? bulkExportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? bulkExportCSV : bulkAction?.bulk_action == "MAKE ACTIVE" ? bulkMakeActive : bulkAction?.bulk_action == "MAKE INACTIVE" ? bulkMakeInActive : bulkAction?.bulk_action == "DELETE" ? bulkDelete :  null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div>:null}
        <div class="bg-indigo-300 p-2 rounded unit-wrapper">
              <div class="flex flex-wrap gap-x-3">
                <p class="flex-auto">
                  Total Units: <span>{totalUnit?.length}</span>
                </p>
                <p class="flex-auto">
                  Total Active Units:{" "}
                  <span class="text-green-600">{countActive}</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Monthly Income:{" "}
                  <span class="text-green-600">$3,100</span>
                </p>
                <br></br>
                <p class="flex-auto">
                  Total Anticipated Monthly Income: <span class="text-green-600">$6,700</span>
                </p>
              </div>
            </div>
            </div>
        </>
    }
    </>
  );
};

export default UnitTable;
