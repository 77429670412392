import { CKEditor } from 'ckeditor4-react';
import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Tooltip } from '../Loader/Tooltip';
export const UnitLocations = ({data,handleLocationTrue,hiddenData}) => {

    const initialUnitLocationData = {
        location:'',
        description:''
    }
    const [unitLocation,setUnitLocation] = useState([]);
    const [unitLocations,setUnitLocations] = useState([]);
    const [editorData,setEditorData] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [viewForm,setViewForm] =useState(false);
    const [indexEdit,setIndexEdit ]= useState();

console.log('@unitLocation',unitLocations);
    const notes = ()=>{
      const data = [];
      data.push(unitLocation);
      const array = [...data];
      // console.log('@array',array);
      const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
      array[0].description = cleanText;
      setUnitLocation(array);
    }
    const bulkData = ()=>{
      notes();
        const newDataArray  = [];
        newDataArray.push(unitLocation);
        setShowForm(false)
        handleLocationTrue(prevArray => [...prevArray, ...newDataArray])
        setUnitLocations(prevArray => [...prevArray, ...newDataArray]);
        setUnitLocation(initialUnitLocationData);
        setEditorData(unitLocation.data);
      }
    
    const editSpacesData = () =>{
        const array = [...unitLocations];
        array[indexEdit] = unitLocation;
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[indexEdit].description = cleanText;
        setUnitLocations(array);
        setShowForm(false)
        handleLocationTrue(array);
        setIndexEdit(undefined);
        setUnitLocation(initialUnitLocationData);
      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setUnitLocation(unitLocations[index]);
        setEditorData(unitLocations[index].description);
        // myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setUnitLocation(unitLocations[index]);
        setEditorData(unitLocations[index].description);
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...unitLocations];
        newArray.splice(index, 1);
        setUnitLocations(newArray);
        handleLocationTrue(newArray);
      }
      const columns = [
        {
          name: "Name",
          selector: (data) => data.location,
          sortable: true,
        },
        {
          name: "Description",
          selector: (data) => data.description,
          sortable: true,
        },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
              {/* <div className="flex"> */}
                {hiddenData != true ? (
                  <div className="flex">
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-bs-target={`#ViewForm`}
                      data-bs-toggle='modal'
                      data-index={index}
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0 mx-3"
                      // data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        indexFind(index);
                      }}
                    >
                      <img src="/assets/img/system-edit.png" />
                    </button>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      // data-bs-target={`#staticBackdrop`}
                      data-index={index}
                      onClick={(e) => {
                        deleteData(index);
                      }}
                    >
                      <img src="/assets/svgs/system-trashcan.svg" />
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      type="button"
                      class="bg-transparent border-none md:w-1/12 p-0"
                      data-index={index}
                      data-bs-target={`#ViewForm`}
                      data-bs-toggle='modal'
                      onClick={(e) => {
                        viewData(index);
                      }}
                    >
                      
                      <img src="/assets/svgs/system-view.svg" />
                    </button>
                  </>
                )}
              {/* </div> */}

              {/* <div className="md:w-1/12">
              <button class="bg-gray-300 py-1 w-24 flex justify-center align-items-center rounded text-black-800 no-underline">
                <i class="fa-regular fa-eye mr-6"></i>
              </button>
              </div> */}
            </>
            );
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : unitLocations,
      };
    useEffect(() => {
      setUnitLocation(initialUnitLocationData);
      setUnitLocations(data);
    }, [])
  return (
    <>
    <div className="md:items-center w-full mt-4">
            <div className="flex">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Unit Locations:
              </label>
              <Tooltip data={"Add different locations within your unit (Bathroom, Kitchen, Bedroom, etc.) that will be used for maintenance tickets and other areas in the system!"}/>
            </div>
          </div>
    {showForm == false ? 
          <div className="md:flex flex-column md:items-center w-full m-auto" >
                <div className="flex flex-row-reverse w-full mt-4">
                <div className="w-auto mt-2 ">
                    <button
                    type="button"
                    hidden={hiddenData}
                    className="btn3"
                    onClick={() => {
                     setShowForm(true);
                    }}
                    >
                    <i class="fa fa-plus"></i>Add Unit Location
                    </button>
                </div>
                </div>
            </div>:null}
          {showForm == true ? 
          <>
    <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name: <span>*</span>                  
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-location-name"
                    type="text"
                    placeholder="Location Name"
                    value={unitLocation?.location}
                    onChange={(e) => {
                      setUnitLocation({
                        ...unitLocation,
                        location: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                <CKEditor
                    initData={editorData}
                    onChange={(e) => {
                      setEditorData( e.editor.getData());
                    }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn3"
                  onClick={() => {
                    if (indexEdit !== undefined) {
                      editSpacesData();
                    } else {
                      bulkData();
                    }
                  }}
                >
                  {indexEdit !== undefined ? (
                    <><i className='fa fa-edit'></i>Update</>
                  ) : (
                    <><i className='fa fa-save'></i>Save</>
                  )}
                </button>
              </div>
            </div>
          </div>
    </>:null}
    <div
                                  class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                                  id={`ViewForm`}
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabindex="-1"
                                  aria-labelledby="ViewFormLabel"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog relative w-auto pointer-events-none">
                                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                        <h5
                                          class="text-xl font-medium leading-normal text-gray-800"
                                          id="exampleModalLabel"
                                        >
                                          View
                                        </h5>
                                        <button
                                          type="button"
                                          class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => {
                                            setViewForm(true);
                                            setUnitLocation(initialUnitLocationData);
                                            setEditorData(null)
                                        }}
                                        ><i className='fa fa-remove'></i></button>
                                      </div>
                                      <div class="modal-body relative p-4">
         
          <>
    {unitLocation?.location != '' ?<div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Name:                
                  </label>
                </div>
                <div className="md:w-4/5">
                  {/* <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="p-location-name"
                    type="text"
                    placeholder="Location Name"
                    value={unitLocation?.location}
                    disabled
                    onChange={(e) => {
                      setUnitLocation({
                        ...unitLocation,
                        location: e.target.value,
                      });
                    }}
                  /> */}
                  <span>{unitLocation?.location}</span>
                </div>
              </div>:null}
              {editorData != '' ?<div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                {/* <CKEditor
                    initData={editorData}
                    config={{
                      readOnly:true,
                    }}
                    onChange={(e) => {
                      setEditorData( e.editor.getData());
                    }}
                  /> */}
                  <span>{editorData}</span>
                </div>
              </div>:null}
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                      <div className="flex justify-end w-full mt-4">
                        <div className="w-1/4 pl-5 ml-5 mt-2 ">
                          <button
                            type="button"
                            className="btn3"
                            onClick={() => {
                              setViewForm(false);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
    </>
    </div>
    </div>
    </div>
    </div>
    {unitLocations?.length > 0 ?
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={unitLocations}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped mt-3"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
        :null}
    </>
  )
}
