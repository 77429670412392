import React from 'react'
import { useEffect } from 'react';
import { Rings, ThreeDots } from 'react-loader-spinner'
import { useDispatch } from 'react-redux';
import { userProfile } from '../../store/user/thunk';
import ApiRequest from '../../utility/http';

export const ImpersonationPage = () => {

    const dispatch = useDispatch();
    const impersonation = async() => {
        const rolesResponse = await ApiRequest.get('/public/api/auth/get-all-roles');
        
        if (rolesResponse.status === 200) {
          
          
          const res2 = await dispatch(userProfile());
          
          if (res2.meta.requestStatus === "fulfilled") {
            const roleArray = [];
            
            rolesResponse?.data?.data?.roles.map((role) => {
              res2?.payload?.assigned_roles?.map((assignedRole) => {
                if (role?.id === assignedRole?.role_id) {
                  roleArray.push(role?.role);
                  localStorage.setItem('Name', `${res2?.payload?.firstname} ${res2?.payload?.lastname}`);
                  localStorage.setItem("Role Name", role?.role);
                  localStorage.setItem("Role", role?.id);
                }
              });
            });
          }
        //   console.error('@res',res2);
        //   localStorage.setItem('redirect_url', '/tenantDashboard');
          window.location.href = '/tenantDashboard';
        }
      };
      useEffect(() => {
        impersonation();
      }, [])
      
    return (
        <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <span>Impersonating</span>
        <Rings
  height="80"
  width="80"
  color="#4fa94d"
  radius="6"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="rings-loading"
/>
    </div>
      )
}
