import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "ckeditor4-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../property/TabPanel";
import ApiRequest from "../../utility/http";
import "../../components/button.css";
import { tab } from "@testing-library/user-event/dist/tab";
import zIndex from "@mui/material/styles/zIndex";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loading";
import imgUrl from "../ImageUrl";


export const EditApplicationForm = (
  initialData = {},
  validationData = {},
  verticalScroll = 0,
  updateFormData
) => {
  const initialImageData = {
    image: {
      picture: null,
      title: "",
      caption: "",
      is_cover: "",
    },
    url: "",
  };

  const initialAddressData = {
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
  };
  const initialNumberData = [
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
    {
      phone_number: "",
      number_type: "",
    },
  ];
  const initialPreviousHouseData = {
    property_name: "",
    montly_rent: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    property_manager: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    move_in_date: "",
    move_out_date: "",
    reason_for_moving: "",
    housing_notes: "",
  };
  const initialEmploymentData = {
    company_name: "",
    position: "",
    annual_salary: "",
    start_year: "",
    end_year: "",
    present: 0,
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    website: "",
    supervisor: "",
    number: "",
    number_type: "",
    leaving_reason: "",
    notes: "",
  };
  const initialChildData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    gender_id: 1,
    date_of_birth: "",
  };
  const initialPetData = {
    status: 1,
    name: "",
    pet_type_id: 3,
    breed: "",
    date_of_birth: "",
  };
  const initialOtherLivingData = {
    status: 1,
    firstname: "",
    middlename: "",
    lastname: "",
    date_of_birth: "",
    gender_id: 1,
    user_relationship_id: 3,
    other_relationship: "",
  };
  const initialReferenceData = {
    firstname: "",
    lastname: "",
    user_reference_relationship_id: 2,
    other_reference_relationship: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    number3: "",
    number3_type: "",
    notes: "",
  };
  const initialCosignerData = {
    firstname: "",
    middelname: "",
    lastname: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    number1: "",
    number1_type: "",
    number2: "",
    number2_type: "",
    email: "",
    date_of_birth: "",
    user_relationship_id: 5,
    employer: "",
    position: "",
    annual_salary: "",
  };
  const initialInteractionData = {
    date: "",
    time: "",
    interaction_type_id: 2,
    other_type_description: "",
    notes: ""
  }

  //UseStates
  const formElement = document.getElementById("add-application-form");
  const formSubmitButton = document.getElementById("form-submit-button");
  const [lastEndLine, setLastEndLine] = useState();
  const [triggerSubmit, triggerFormSubmit] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState();
  const [appAddress, setAppAddress] = useState([]);
  const [number, setNumber] = useState([]);
  const [value, onChange] = useState(1);
  const [dlidPicture, setDlidPicture] = useState([]);
  const [genderPreference, setGenderPreference] = useState([]);
  const [editorData, setEditorData] = useState();
  const [editorData1, setEditorData1] = useState();
  const [previousHousing, setPreviousHousing] = useState();
  const [appEmployment, setAppEmployment] = useState([]);
  const [appChild, setAppChild] = useState([]);
  const [childrenAge, setChildrenAge] = useState([]);
  const [appPet, setAppPet] = useState([]);
  const [petType, setPetType] = useState([]);
  const [petAge, setPetAge] = useState([]);
  const [appOtherLiving, setAppOtherLiving] = useState([]);
  const [otherAge, setOtherAge] = useState([]);
  const [userRelationship, setUserRelationship] = useState([]);
  const [appReference, setAppReference] = useState([]);
  const [userReferenceRelationship, setUserReferenceRelationship] = useState(
    []
  );
  const [appCoSigner, setAppCoSigner] = useState([]);
  const [appInteraction, setAppInteraction] = useState([]);
  const [interactionType, setInteractionType] = useState([]);
  const [appFiles, setAppFiles] =
  useState([]);
  const [appStatus,setAppStatus] = useState([]);
  const [loading,setLoading] = useState();
  const [generalSetting,setGeneralSetting] = useState([]);
  console.log("formData", formData);
  console.log("editorData", editorData);
  console.log("editorData1", editorData1);

  //Methods
  const getFilesAndURLs = (e) => {
    let files = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file) =>
        files.push({
          file: file,
          url: URL.createObjectURL(file),
        })
      );
    }

    return files;
  };
  let imageApi = "/public/api/auth/upload-image";
  let fileApi = "/public/api/auth/upload-file";
  const getImagesAndURLs = (e) => {
    let images = [];
    const inputField = e.target;

    if (inputField?.type === "file") {
      inputField.files.forEach((file, index) =>
        images.push({
          image: {
            picture: file,
            title: file.name,
            caption: "c" + index,
            is_cover: inputField?.id === "property-images" && index == 0,
          },
          url: URL.createObjectURL(file),
        })
      );
    }

    return images;
  };
  const imageComponent = ({
    image = {},
    key = 0,
    src = "",
    inputFieldId = "",
    trashAble = false,
    onTrashClick = () => {},
  }) =>
    image ? (
      <div
        onMouseEnter={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = false;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.childNodes[0].childNodes[1].hidden = true;
        }}
        className="flex flex-wrap xs:w-full sm:w-1/3 md:w-1/4 lg:w-1/5 md:m-2 align-items-center"
      >
        <div className="relative h-fit w-full">
          <img
            title={image?.title}
            key={key}
            alt="unit board icon"
            className="border block object-contain w-80 h-auto rounded-lg"
            src={`${imgUrl}/${image}`}
          />
          <div
            hidden={true}
            className="absolute bg-gray-100 opacity-50 hover:opacity-[85%] flex w-full bottom-0 left-0 right-0 bg-grey"
          >
            {trashAble ? (
              <img
                title="Remove image"
                className="logo w-5 mx-auto my-1"
                src="/assets/img/trash-icon.png"
                onClick={(e) => {
                  const fileInputField = document.getElementById(inputFieldId);
                  let dataTransfer = new DataTransfer();

                  if (fileInputField) {
                    fileInputField.files.forEach((file) => {
                      if (file !== image) {
                        dataTransfer.items.add(file);
                      }
                    });
                    fileInputField.files = dataTransfer.files;
                  }
                  onTrashClick(e);
                }}
                alt=""
              />
            ) : null}
          </div>
        </div>
      </div>
    ) : null;

    const fileComponent = ({
        file = null,
        key = 0,
        inputFieldId = "",
        trashAble = false,
        onTrashClick = () => {},
      }) =>
        file ? (
          <div
            onMouseEnter={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = false;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.childNodes[0].childNodes[1].hidden = true;
            }}
            className="flex flex-wrap xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:m-2 align-items-center"
          >
            <div className="border relative overflow-hidden flex align-items-center my-auto rounded-lg max-h-[3rem] min-h-[3rem] w-full">
              <div
                            title={file}
                            key={key}
                            className="block min-w-[600rem] px-2"
                        >
                            <a href={`${imgUrl}/${file}`} target="_blank">{file}</a>
                        </div>
              <div
                hidden={true}
                className="absolute bg-gray-100 opacity-50 hover:opacity-[85] flex h-full top-0 bottom-0 right-0 bg-grey"
              >
                {trashAble ? (
                  <img
                    title="Remove file"
                    className="logo w-5 my-auto mx-1"
                    src="/assets/img/trash-icon.png"
                    onClick={(e) => {
                      const fileInputField = document.getElementById(inputFieldId);
                      let dataTransfer = new DataTransfer();
    
                      if (fileInputField) {
                        fileInputField.files.forEach((input_file) => {
                          if (input_file !== file) {
                            dataTransfer.items.add(input_file);
                          }
                        });
                        fileInputField.files = dataTransfer.files;
                      }
                      onTrashClick(e);
                    }}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null;
    
    
    const Publicbase64Converter = async (file, index) => {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        const fileBase64 = reader.result;
        try {
          console.log(fileBase64);
          const res = await ApiRequest.post(fileApi, { file: fileBase64 });
          // console.log("res", res?.data?.data?.file);
          // console.log("index",index);
          // let index = [index];
          // [...index].sort()
  
          formData.application_files.push({ file: res?.data?.data?.file });
          // setFormData({...formData, pictures :{picture:res?.data?.data?.file}})
          // console.log("base64converted files", files);
        } catch (err) {
          console.log(err);
        }
      });
      reader.readAsDataURL(file);
    };
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    date = new Date("2021-09-23T" + date);
    var Hours = date.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);

    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

    return TotalTime;
  };
  function stringToTime(timeString) {
    // console.warn("timeString",timeString)
    if (timeString === null || timeString === undefined || timeString >= 0)
      return;
    let parts = timeString.split(":");
    let ampm = timeString.split(" ");
    let hours = parts[0];
    let minutes = parts[1];
    let dayNight = ampm[1];

    let time = hours + ":" + minutes + " " + dayNight;
    let isPM = false;

    if (time.endsWith("am")) {
      isPM = false;
      time = time.replace(/\s(am|pm)/, "");
    } else {
      isPM = true;
      time = time.replace(/\s(am|pm)/, "");
      let timeArr = time.split(":");
      timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
      time = timeArr.join(":");
    }

    return time;
  }
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    // console.warn("UnformatDate",date)
    return date;
  }
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    return age_now;
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 80;
  const years = [...Array(currentYear - startYear + 1).keys()]
    .map((x, i) => i + startYear)
    .reverse();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const hasBeenScrolled = (elem) => {
    const { bottom } = elem.getBoundingClientRect();

    return bottom <= 0;
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const {id} =useParams();
  const formSubmitHandler = async(e) => {
    e.preventDefault();
    try {
    const response = await ApiRequest.post('/public/api/auth/application',{
    id:id,
    property_id: 81,
    unit_id: 189,
    firstname: formData?.firstname,
    middlename:formData?.middlename,
    lastname: formData?.lastname,
    addresses: formData?.addresses,
    numbers: formData?.numbers,
    email: formData?.email,
    date_of_birth: formData?.date_of_birth,
    dln_id: formData?.dln_id,
    dl_id_picture: formData?.dl_id_picture,
    dln_id_issue_state: formData?.dln_id_issue_state,
    dln_id_issue_notes: formData?.dln_id_issue_notes,
    gender_id: formData?.gender_id,
    ethnicity_id: formData?.ethnicity_id,
    smoker: formData?.smoker,
    smoker_notes: editorData1?.smoker_notes,
    previous_housings: formData?.previous_housings,
    employments: formData?.employments,
    childrens: formData?.childrens,
    pets: formData?.pets,
    other_livings: formData?.other_livings,
    references: formData?.references,
    ever_evicted: formData?.ever_evicted,
    eviction_notes: editorData1?.eviction_notes,
    ever_convicted_of_felony: formData?.ever_convicted_of_felony,
    convicted_of_felony_notes: editorData?.convicted_of_felony_notes,
    has_cosigners: formData?.has_cosigners,
    cosigners: formData?.cosigners,
    messy_clean: formData?.messy_clean,
    loud_quiet: formData?.loud_quiet,
    extra_fields_json: "",
    application_files: formData?.application_files,
    additional_information:editorData?.additional_information,
    interactions: formData?.interactions,
    notes: editorData?.notes,
    application_status_id: formData?.application_status_id
    })
  }
    catch (err) {
      console.log(err);
    }
  };

  //Api

  const getApi = async ()=>{
        const response = await ApiRequest.get(`/public/api/auth/get-application?application_id=${id}`)
        setLoading(response.status)
        if (response.status === 200) {
          setFormData({
            ...formData,property_id:response?.data?.data?.property_id,
            ...formData,unit_id:response?.data?.data?.unit_id,
            ...formData,firstname:response?.data?.data?.user[0]?.firstname,
            ...formData,middlename:response?.data?.data?.user[0]?.middlename,
            ...formData,lastname:response?.data?.data?.user[0]?.lastname,
            ...formData,addresses:response?.data?.data?.addresses,
            ...formData,numbers:response?.data?.data?.numbers,
            ...formData,email:response?.data?.data?.user[0]?.email,
            ...formData,date_of_birth:response?.data?.data?.profile?.date_of_birth,
            ...formData,dln_id:response?.data?.data?.profile?.dln_id,
            ...formData,dl_id_picture:response?.data?.data?.profile?.dl_id_picture,
            ...formData,dln_id_issue_state:response?.data?.data?.profile?.dln_id_issue_state,
            ...formData,dln_id_issue_notes:response?.data?.data?.profile?.dln_id_issue_notes,
            ...formData,gender_id:response?.data?.data?.profile?.gender_id,
            ...formData,ethnicity_id:response?.data?.data?.profile?.ethnicity_id,
            ...formData,smoker:response?.data?.data?.profile?.smoker,
            ...formData,previous_housings:response?.data?.data?.previous_housings,
            ...formData,employments:response?.data?.data?.employments,
            ...formData,childrens:response?.data?.data?.childrens,
            ...formData,pets:response?.data?.data?.pets,
            ...formData,other_livings:response?.data?.data?.other_livings,
            ...formData,references:response?.data?.data?.references,
            ...formData,ever_evicted:response?.data?.data?.ever_evicted,
            ...formData,ever_convicted_of_felony:response?.data?.data?.ever_convicted_of_felony,
            ...formData,has_cosigners:response?.data?.data?.has_cosigners,
            ...formData,cosigners:response?.data?.data?.cosigners,
            ...formData,messy_clean:response?.data?.data?.messy_clean,
            ...formData,loud_quiet:response?.data?.data?.loud_quiet,
            ...formData,extra_fields_json:response?.data?.data?.extra_fields_json,
            ...formData,application_files:response?.data?.data?.files,
            ...formData,interactions:response?.data?.data?.interactions,
            ...formData,application_status_id:response?.data?.data?.application_status_id
          })
          dlidPicture?.pictures?.push({picture:response?.data?.data?.profile?.dl_id_picture})
          setAppAddress(response?.data?.data?.addresses);
          setAppChild(response?.data?.data?.childrens);
          setNumber(response?.data?.data?.numbers);
          setAppPet(response?.data?.data?.pets);
          setAppOtherLiving(response?.data?.data?.other_livings)
          setAppEmployment(response?.data?.data?.employments);
          setAppReference(response?.data?.data?.references);
          setPreviousHousing(response?.data?.data?.previous_housings);
          setAppCoSigner(response?.data?.data?.cosigners);
          setAppInteraction(response?.data?.data?.interactions);
          setAppFiles(response?.data?.data?.files);
          setEditorData1({
            ...editorData1,smoker_notes:response?.data?.data?.profile[0]?.smoker_notes,
            ...editorData1,eviction_notes:response?.data?.data?.eviction_notes,
        
        });
        setEditorData({
            ...editorData,convicted_of_felony_notes:response?.data?.data?.convicted_of_felony_notes,
            ...editorData,additional_information:response?.data?.data?.additional_information,
            ...editorData,notes:response?.data?.data?.notes,
        })


        }}

  const showStatus = async() =>{
    const response = await ApiRequest.get('/public/api/auth/get-applications-statuses');
    if(response.status == 200)
    {
      setAppStatus(response?.data?.data)
    }
  }

  const getGenderPreferences = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-genders-preferences"
    );
    if (response.status == 200) {
      setGenderPreference(response?.data?.data?.genders_preferences);
    }
  };
  const getPetTypes = async () => {
    const response = await ApiRequest.get("/public/api/auth/get-my-pets-types");
    if (response.status == 200) {
      setPetType(response?.data?.data?.types);
    }
  };
  const getUserRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-relationships"
    );
    if (response.status == 200) {
      setUserRelationship(response?.data?.data?.relationships);
    }
  };
  const getGeneralSetting = async ()=>{
    const response = await ApiRequest.get('/public/api/auth/get-applications-settings?unit_id=189');
    if(response.status == 200)
    {
      setGeneralSetting(response?.data?.data);
    }
  }

  const getUserReferenceRelationships = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-users-references-relationships"
    );
    if (response.status == 200) {
      setUserReferenceRelationship(response?.data?.data?.relationships);
    }
  };

  const getInteractionTypes = async () => {
    const response = await ApiRequest.get(
      "/public/api/auth/get-interactions-types"
    );
    if (response.status == 200) {
      setInteractionType(response?.data?.data?.interactions_types);
    }
  };
  //UseEffects
  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(value / 4)}px`;
    }
  });

  useEffect(() => {
    if (lastEndLine) {
      const data = { ...formData };
      const addresses = [
        ...appAddress.filter(
          (address) =>
            JSON.stringify(address) !== JSON.stringify(initialAddressData)
        ),
      ];
      const numbers = [
        ...numbers.filter(
          (number) =>
            JSON.stringify(number) !== JSON.stringify(initialNumberData)
        ),
      ];
      const previous_housings = [
        ...previousHousing.filter(
          (previous_housing) =>
            JSON.stringify(previous_housing) !==
            JSON.stringify(initialPreviousHouseData)
        ),
      ];
      const employments = [
        ...appEmployment.filter(
          (employment) =>
            JSON.stringify(employment) !== JSON.stringify(initialEmploymentData)
        ),
      ];
      const childrens = [
        ...appChild.filter(
          (children) =>
            JSON.stringify(children) !== JSON.stringify(initialChildData)
        ),
      ];
      const pets = [
        ...appPet.filter(
          (pet) => JSON.stringify(pet) !== JSON.stringify(initialPetData)
        ),
      ];
      const other_livings = [
        ...appOtherLiving.filter(
          (other_living) =>
            JSON.stringify(other_living) !==
            JSON.stringify(initialOtherLivingData)
        ),
      ];
      const references = [
        ...appReference.filter(
          (reference) =>
            JSON.stringify(reference) !== JSON.stringify(initialReferenceData)
        ),
      ];
      const cosigners = [
        ...appCoSigner.filter(
          (cosigner) =>
            JSON.stringify(cosigner) !== JSON.stringify(initialCosignerData)
        ),
      ];
      const interactions = [
        ...appInteraction.filter(
          (interaction) =>
            JSON.stringify(interaction) !== JSON.stringify(initialInteractionData)
        ),
      ];

      if (
        JSON.stringify(initialData?.addresses) !== JSON.stringify(addresses)
      ) {
        data.addresses = addresses;
      }
      if (JSON.stringify(initialData?.numbers) !== JSON.stringify(numbers)) {
        data.numbers = numbers;
      }
      if (
        JSON.stringify(initialData?.previous_housings) !==
        JSON.stringify(previous_housings)
      ) {
        data.previous_housings = previous_housings;
      }
      if (
        JSON.stringify(initialData?.employments) !== JSON.stringify(employments)
      ) {
        data.employments = employments;
      }
      if (
        JSON.stringify(initialData?.childrens) !== JSON.stringify(childrens)
      ) {
        data.childrens = childrens;
      }
      if (JSON.stringify(initialData?.pets) !== JSON.stringify(pets)) {
        data.pets = pets;
      }
      if (
        JSON.stringify(initialData?.other_livings) !==
        JSON.stringify(other_livings)
      ) {
        data.other_livings = other_livings;
      }
      if (
        JSON.stringify(initialData?.references) !== JSON.stringify(references)
      ) {
        data.references = references;
      }
      if (
        JSON.stringify(initialData?.cosigners) !== JSON.stringify(cosigners)
      ) {
        data.cosigners = cosigners;
      }
      if (
        JSON.stringify(initialData?.interactions) !== JSON.stringify(interactions)
      ) {
        data.interactions = interactions;
      }
      data.application_files = [
        ...appFiles.map((fileObj) => ({
          file: fileObj?.file,
        })),
      ];
      if (JSON.stringify(data) !== JSON.stringify(initialData)) {
        console.log(JSON.stringify(data), JSON.stringify(initialData));
        setFormData(data);
        triggerFormSubmit(!triggerSubmit);
      }
      
    }
  }, [lastEndLine]);

  useEffect(() => {
    getGenderPreferences();
    getPetTypes();
    getApi();
    getGeneralSetting();
    showStatus();
    getUserRelationships();
    getUserReferenceRelationships();
    getInteractionTypes();
    setFormData(initialData.initialData);

    setAppAddress(
      initialData?.addresses?.length
        ? initialData?.addresses
        : [{ ...initialAddressData }]
    );
    setNumber(
      initialData?.numbers?.length
        ? initialData?.numbers
        : [{ ...initialNumberData }]
    );
    setPreviousHousing(
      initialData?.previous_housings?.length
        ? initialData?.previous_housings
        : [{ ...initialPreviousHouseData }]
    );
    setAppEmployment(
      initialData?.employments?.length
        ? initialData?.employments
        : [{ ...initialEmploymentData }]
    );
    setAppChild(
      initialData?.childrens?.length
        ? initialData?.childrens
        : [{ ...initialChildData }]
    );
    setAppPet(
      initialData?.pets?.length ? initialData?.pets : [{ ...initialPetData }]
    );
    setAppOtherLiving(
      initialData?.other_livings?.length
        ? initialData?.other_livings
        : [{ ...initialOtherLivingData }]
    );
    setAppReference(
      initialData?.references?.length
        ? initialData?.references
        : [{ ...initialReferenceData }]
    );
    setAppCoSigner(
      initialData?.cosigners?.length
        ? initialData?.cosigners
        : [{ ...initialCosignerData }]
    );
    setAppInteraction(
      initialData?.interactions?.length
        ? initialData?.interactions
        : [{ ...initialInteractionData }]
    );
    setDlidPicture([]);
  }, []);

  return (
    <>
        {loading === undefined ? <Loading/> :
      <>
       <div className="flex align-items-center">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/application.svg"
                alt=""
              />
              <h2 className="text-3xl font-bold text-black py-3">
                Edit {formData?.firstname}
              </h2>
            </div>
      <form
        className="w-full"
        id="add-application-form"
        onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Current/Previous Housing" {...a11yProps(1)} />
          <Tab label="Employment" {...a11yProps(2)} />
          <Tab label="Children Living With You" {...a11yProps(3)} />
          <Tab label="Pets" {...a11yProps(4)} />
          <Tab label="Other Living With You" {...a11yProps(5)} />
          <Tab label="References" {...a11yProps(6)} />
          <Tab label="Background and Credit Check" {...a11yProps(7)} />
          <Tab label="Additional Information" {...a11yProps(8)} />
          <Tab label="Review Notes and Decision" {...a11yProps(8)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              {/* <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:w-1/2">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Enable Portal:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      // value={formData?.enable_portal}
                      // onChange={(e) => {
                      //   const checkedValue = e.target.checked === true ? 1 : 0;
                      //   setFormData({
                      //     ...formData,
                      //     enable_portal: checkedValue,
                      //   });
                      // }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/2">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Send Notifications:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      // value={formData?.send_notifications}
                      // onChange={(e) => {
                      //   const checkedValue = e.target.checked === true ? 1 : 0;
                      //   setFormData({
                      //     ...formData,
                      //     send_notifications: checkedValue,
                      //   });
                      // }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div> */}
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      First Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="firstname"
                      type="text"
                      placeholder="John"
                      required={false}
                      value={formData?.firstname}
                      onChange={(e) => {
                        setFormData({ ...formData, firstname: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                  <div className="md:w-5/6">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Middle Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="middlename"
                      type="text"
                      placeholder="Ben"
                      required={false}
                      value={formData?.middlename}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          middlename: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Joe"
                      required={false}
                      value={formData?.lastname}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          lastname: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                  <div className="md:w-2/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Last Name:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="lastname"
                      type="text"
                      placeholder="Deo"
                      required={false}
                      value={formData?.lastname}
                      onChange={(e) => {
                        setFormData({ ...formData, lastname: e.target.value });
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              {appAddress?.map((row, index) => {
                const addresses = [...appAddress];
                return (
                  <>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 1:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address-line-1"
                          type="text"
                          placeholder="Apt. suite no. 1"
                          required={false}
                          value={row?.address_line1}
                          onChange={(e) => {
                            addresses[index].address_line1 = e.target.value;
                            setAppAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 2:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="addresses-address_line2"
                          type="text"
                          placeholder="Jeff Street"
                          value={row?.address_line2}
                          onChange={(e) => {
                            addresses[index].address_line2 = e.target.value;
                            setAppAddress(addresses);
                            setFormData({ ...formData, addresses: addresses });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6  px-3">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Country:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-country"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={row?.country}
                            onChange={(e) => {
                              addresses[index].country = e.target.value;
                              setAppAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-city"
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={row?.city}
                            onChange={(e) => {
                              addresses[index].city = e.target.value;
                              setAppAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 px-3 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="addresses-state"
                            required={false}
                            value={row?.state}
                            onChange={(e) => {
                              addresses[index].state = e.target.value;
                              setAppAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          >
                            <option value={"New Mexico"}>New Mexico</option>
                            <option value={"Missouri"}>Missouri</option>
                            <option value={"Texas"}>Texas</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-zip"
                            type="text"
                            placeholder="90210"
                            required={false}
                            value={row?.zip_code}
                            onChange={(e) => {
                              addresses[index].zip_code = e.target.value;
                              setAppAddress(addresses);
                              setFormData({
                                ...formData,
                                addresses: addresses,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[0]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][0].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[0]?.number_type}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][0].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[1]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][1].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[1]?.number_type}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][1].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="text"
                    placeholder="123456789"
                    value={formData?.numbers[2]?.phone_number}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][2].phone_number = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    value={formData?.numbers[2]?.number_type}
                    onChange={(e) => {
                      const phone = [...number];
                      phone[0][2].number_type = e.target.value;
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Email:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`email`}
                    type="email"
                    placeholder="jhon.doe@email.com"
                    value={formData?.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Date of Birth:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="date_of_birth"
                      type="date"
                      value={stringToDate(formData?.date_of_birth)}
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        const date = formatDate(e.target.value);
                        setFormData({ ...formData, date_of_birth: date });
                      }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id"
                      type="number"
                      value={formData?.dln_id}
                      placeholder="18293213"
                      required={false}
                      onChange={(e) => {
                        setFormData({ ...formData, dln_id: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      DL/ID Picture:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload ID
                      </span>
                      <input
                        accept="image/*"
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            const imgBase64 = reader.result;
                            try {
                              console.log(imgBase64);
                              const res = await ApiRequest.post(
                                "/public/api/auth/upload-image",
                                { image: imgBase64 }
                              );
                              // console.log("res", res?.data?.data?.image);
                              // formData?.dl_id_picture = res?.data?.data?.image
                              setFormData({
                                ...formData,
                                dl_id_picture: res?.data?.data?.image,
                              });
                              // setDlidPicture(res?.data?.data?.image)
                              // setFeatureIcon(res?.data?.data?.image);
                            } catch (err) {
                              console.log(err);
                            }
                          });
                          reader.readAsDataURL(file);
                          const pictureWithURL = getImagesAndURLs(e)[0];
                          setDlidPicture(pictureWithURL);

                          // console.log("here 142");
                          // const pictures = [...propertyAssistantPictures];
                          // pictures[index] = getImagesAndURLs(e)[0];
                          // setPropertyAssistantPictures(pictures);
                          // setPropertyContacts(contacts);
                          // setFormData({...formData, contacts : contacts})
                        }}
                      />
                    </label>
                  </div>
                </div>

                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-3/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      DLN/ID issue state:
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="dln_id_issue_state"
                      value={formData?.dln_id_issue_state}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          dln_id_issue_state: e.target.value,
                        });
                      }}
                    >
                      <option value={"America"}>America</option>
                      <option value={"Canada"}>Canada</option>
                      <option value={"UAE"}>UAE</option>
                    </select>
                  </div>
                </div>
              </div>
              {formData?.dl_id_picture != null ? (
                <section className="overflow-hidden w-full text-gray-700 mt-4 ml-[19%]">
                  <div className="container w-full">
                    <div
                      id="image-block"
                      className="flex flex-wrap justify-start w-full -m-1 md:-m-2"
                    >
                      {imageComponent({
                        image: formData?.dl_id_picture,
                        // src: dlidPicture?.url,
                        inputFieldId: "dlid-picture",
                        trashAble: true,
                        onTrashClick: () => {
                          formData.dl_id_picture = null;
                          setDlidPicture(null);
                        },
                      })}
                    </div>
                  </div>
                </section>
              ) : null}
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Gender:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`gender`}
                      value={formData?.gender_id}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          gender_id: parseInt(e.target.value),
                        });
                      }}
                    >
                      {genderPreference.map((row) => (
                        <option value={row?.id}>{row?.gender}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="md:flex md:items-center mt-3">
                  <div className="md:flex md:w-1/2">
                    <div className="md:w-2/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Smoker:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={formData?.smoker}
                        checked={formData?.smoker == 1}
                        onChange={(e) => {
                          const checkedValue =
                            e.target.checked === true ? 1 : 0;
                          setFormData({
                            ...formData,
                            smoker: checkedValue,
                          });
                        }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              {formData?.smoker ? (
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Smoking Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData1?.smoker_notes}
                      onChange={(e) => {
                        setEditorData1({
                          ...editorData1,
                          smoker_notes: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
					{TenantFormImagesWithUrl?.length ? (
						<div>
							<section className="overflow-hidden w-full text-gray-700 mt-4">
								<div className="container w-full px-3 m-auto">
									<div
										id="images-block"
										className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
									>
										{TenantFormImagesWithUrl.map((imageObject, index) =>
											imageComponent({
												image: imageObject?.image?.picture,
												key: index,
												src: imageObject?.url,
												inputFieldId: "tenant-images",
												trashAble: true,
												onTrashClick: () => {
													let images = [...formData?.pictures];
													let image = [...TenantFormImagesWithUrl]
													images.splice(index, 1);
													image.splice(index, 1);
													setTenantFormImagesWithUrl(image);
													setFormData({...formData,pictures : images})
												},
											})
										)}
									</div>
								</div>
							</section>
						</div>
					) : null}
				</div> */}
          <hr />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Current/Previous Housing:
              </label>
            </div>
            <label className="block capitalize tracking-wide text-gray-700 text-sm font-bold mb-2">
                Please provide information from your last {generalSetting[0]?.previous_housings_years} years of housing
              </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              {previousHousing?.map((row, index) => {
                const previous_housings = [...previousHousing];
                return (
                  <>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Property Name:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`propertyname-${index}`}
                            type="text"
                            placeholder="John"
                            required={false}
                            value={row?.property_name}
                            onChange={(e) => {
                              previous_housings[index].property_name =
                                e.target.value;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Monthly Rent:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`monthlyrent-${index}`}
                            type="text"
                            placeholder="John"
                            required={false}
                            value={row?.montly_rent}
                            onChange={(e) => {
                              previous_housings[index].montly_rent =
                                e.target.value;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 1:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`address-line-1-${index}`}
                          type="text"
                          placeholder="Apt. suite no. 1"
                          required={false}
                          value={row?.address_line1}
                          onChange={(e) => {
                            previous_housings[index].address_line1 =
                              e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Address Line 2:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`address_line2-${index}`}
                          type="text"
                          placeholder="Jeff Street"
                          value={row?.address_line2}
                          onChange={(e) => {
                            previous_housings[index].address_line2 =
                              e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            City:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`city-${index}`}
                            type="text"
                            placeholder="Albuquerque"
                            required={false}
                            value={row?.city}
                            onChange={(e) => {
                              previous_housings[index].city = e.target.value;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            State:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`state-${index}`}
                            required={false}
                            value={row?.state}
                            onChange={(e) => {
                              previous_housings[index].state = e.target.value;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          >
                            <option value={"New Mexico"}>New Mexico</option>
                            <option value={"Missouri"}>Missouri</option>
                            <option value={"Texas"}>Texas</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                        <div className="md:w-1/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Zip:
                          </label>
                        </div>
                        <div className="md:w-3/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`zip-code-${index}`}
                            type="text"
                            placeholder="90210"
                            required={false}
                            value={row?.zip_code}
                            onChange={(e) => {
                              previous_housings[index].zip_code =
                                e.target.value;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-2/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Landlord/Property Manager:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`property_manager-${index}`}
                          type="text"
                          placeholder="Property Manager"
                          value={row?.property_manager}
                          onChange={(e) => {
                            previous_housings[index].property_manager =
                              e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 1:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`num-1-${index}`}
                          type="text"
                          placeholder="123456789"
                          value={row?.number1}
                          onChange={(e) => {
                            previous_housings[index].number1 = e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`num-1-type-${index}`}
                          value={row?.number1_type}
                          onChange={(e) => {
                            previous_housings[index].number1_type =
                              e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        >
                          <option value={"Office"}>Office</option>
                          <option value={"Phone"}>Phone</option>
                          <option value={"Home"}>Home</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Number 2:
                        </label>
                      </div>
                      <div className="md:w-3/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`num-2-${index}`}
                          type="text"
                          placeholder="123456789"
                          value={row?.number2}
                          onChange={(e) => {
                            previous_housings[index].number2 = e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                      <div className="md:w-1/5 pl-3">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`num-2-type-${index}`}
                          value={row?.number2_type}
                          onChange={(e) => {
                            previous_housings[index].number2_type =
                              e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        >
                          <option value={"Office"}>Office</option>
                          <option value={"Phone"}>Phone</option>
                          <option value={"Home"}>Home</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`num-3-${index}`}
                    type="text"
                    placeholder="123456789"
                    value={row?.number3}
                          onChange={(e) => {
                            previous_housings[index].number3 = e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({ ...formData, previous_housings: previous_housings });
                          }}
                  />
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`num-3-type-${index}`}
                    value={row?.number3_type}
                          onChange={(e) => {
                            previous_housings[index].number3_type = e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({ ...formData, previous_housings: previous_housings });
                          }}
                  >
                    <option value={"Office"}>Office</option>
                    <option value={"Phone"}>Phone</option>
                    <option value={"Home"}>Home</option>
                  </select>
                </div>
              </div> */}
                    <div className="md:flex md:items-center mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Email:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`email-${index}`}
                          type="email"
                          placeholder="jhon.doe@email.com"
                          value={row?.email}
                          onChange={(e) => {
                            previous_housings[index].email = e.target.value;
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full mt-3">
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Move-in Date:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`moveindate-${index}`}
                            type="date"
                            placeholder="John"
                            required={false}
                            value={stringToDate(row?.move_in_date)}
                            onChange={(e) => {
                              const date = formatDate(e.target.value);
                              previous_housings[index].move_in_date = date;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                        <div className="md:w-2/4">
                          <label className="block capitalize text-gray-700 font-bold mb-2">
                            Move-out Date:
                          </label>
                        </div>
                        <div className="md:w-2/4">
                          <input
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`moveoutdate-${index}`}
                            type="date"
                            placeholder="John"
                            required={false}
                            value={stringToDate(row?.move_out_date)}
                            onChange={(e) => {
                              const date = formatDate(e.target.value);
                              previous_housings[index].move_out_date = date;
                              setPreviousHousing(previous_housings);
                              setFormData({
                                ...formData,
                                previous_housings: previous_housings,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Reason For Moving Out:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.reason_for_moving}
                          onChange={(e) => {
                            previous_housings[index].reason_for_moving =
                              e.editor.getData();
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Housing Notes:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.housing_notes}
                          onChange={(e) => {
                            previous_housings[index].housing_notes =
                              e.editor.getData();
                            setPreviousHousing(previous_housings);
                            setFormData({
                              ...formData,
                              previous_housings: previous_housings,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setPreviousHousing([
                      ...previousHousing,
                      initialPreviousHouseData,
                    ]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Another
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-employer.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Employment:
              </label>
            </div>
              <label className="block capitalize tracking-wide text-gray-700 text-sm font-bold mb-2">
              Please provide information from your last {generalSetting[0]?.employers_years} years of employment
              </label>
           
          </div>

          {appEmployment?.map((row, index) => {
            const employments = [...appEmployment];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Company Name:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-company-name-${index}`}
                        type="text"
                        placeholder="J Height"
                        value={row?.company_name}
                        onChange={(e) => {
                          employments[index].company_name = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Title/Position:
                          <span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-position-${index}`}
                          type="text"
                          placeholder="CEO"
                          required={true}
                          value={row?.position}
                          onChange={(e) => {
                            employments[index].position = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-2/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Annual Salary:<span title="Field is required">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-annual-salary-${index}`}
                          type="text"
                          placeholder="123"
                          required={true}
                          value={row?.annual_salary}
                          onChange={(e) => {
                            employments[index].annual_salary = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Start Year:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          required={true}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-start-year-${index}`}
                          value={row?.start_year}
                          onChange={(e) => {
                            employments[index].start_year = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        >
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          End Year:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          required={true}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-end-year-${index}`}
                          value={row?.end_year}
                          onChange={(e) => {
                            const PresentValue =
                              e.target.value === "Present" ? 1 : 0;
                            const EndYearValue =
                              e.target.value === "Present"
                                ? ""
                                : e.target.value;
                            employments[index].end_year = EndYearValue;
                            employments[index].present = PresentValue;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        >
                          <option value={"Present"}>Present</option>
                          {years.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Address Line 1:<span title="Field is required.">*</span>
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-address-line-1-${index}`}
                        type="text"
                        placeholder="Apt. suite no. 1"
                        required={true}
                        value={row?.address_line1}
                        onChange={(e) => {
                          employments[index].address_line1 = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Address Line 2:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-address-line-2-${index}`}
                        type="text"
                        placeholder="Apt. suite no. 2"
                        required={true}
                        value={row?.address_line2}
                        onChange={(e) => {
                          employments[index].address_line2 = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          City:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-city-${index}`}
                          type="text"
                          placeholder="Albuquerque"
                          required={true}
                          value={row?.city}
                          onChange={(e) => {
                            employments[index].city = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          State:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-state-${index}`}
                          required={true}
                          value={row?.state}
                          onChange={(e) => {
                            employments[index].state = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        >
                          <option value={"New Mexico"}>New Mexico</option>
                          <option value={"Missouri"}>Missouri</option>
                          <option value={"Texas"}>Texas</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Zip:<span title="Field is required.">*</span>
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`employment-zip-${index}`}
                          type="text"
                          placeholder="90210"
                          required={true}
                          value={row?.zip_code}
                          onChange={(e) => {
                            employments[index].zip_code = e.target.value;
                            setAppEmployment(employments);
                            setFormData({
                              ...formData,
                              employments: employments,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Website:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-website-${index}`}
                        type="url"
                        placeholder="www.jhon-doe.com"
                        value={row?.website}
                        onChange={(e) => {
                          employments[index].website = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Supervisor:<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-supervisor-${index}`}
                        type="text"
                        placeholder="Ben"
                        value={row?.supervisor}
                        onChange={(e) => {
                          employments[index].supervisor = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number:<span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-number-${index}`}
                        type="text"
                        required={true}
                        placeholder="123456789"
                        value={row?.number}
                        onChange={(e) => {
                          employments[index].number = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                    <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type: <span title="Field is required">*</span>
                      </label>
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={true}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`employment-number-type-${index}`}
                        value={row?.number_type}
                        onChange={(e) => {
                          employments[index].number_type = e.target.value;
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      >
                        <option value={"Office"}>Office</option>
                        <option value={"Phone"}>Phone</option>
                        <option value={"Home"}>Home</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reason For Leaving/other Comments:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={row?.leaving_reason}
                        onChange={(e) => {
                          employments[index].leaving_reason =
                            e.editor.getData();
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Employment Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={row?.notes}
                        onChange={(e) => {
                          employments[index].notes = e.editor.getData();
                          setAppEmployment(employments);
                          setFormData({
                            ...formData,
                            employments: employments,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setAppEmployment([...appEmployment, initialEmploymentData]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Another
                </button>
              </div>
            </div>
          </div>

          <hr />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-kids.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Children Living With You:
              </label>
            </div>
          </div>
          <div className="md:flex flex-row-reverse md:items-center w-[90%] m-auto mb-4">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setAppChild([...appChild, initialChildData]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Child
                </button>
              </div>
            </div>
          </div>
          {appChild?.map((row, index) => {
            const childrens = [...appChild];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`children-status-${index}`}
                          required={false}
                          value={row?.status}
                          onChange={(e) => {
                            childrens[index].status = parseInt(e.target.value);
                            setAppChild(childrens);
                            setFormData({ ...formData, childrens: childrens });
                          }}
                        >
                          <option value={0}>Inactive</option>
                          <option value={1}>Active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`childfirstname-${index}`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={row?.firstname}
                          onChange={(e) => {
                            childrens[index].firstname = e.target.value;
                            setAppChild(childrens);
                            setFormData({ ...formData, childrens: childrens });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                      <div className="md:w-5/6">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Middle Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`childmiddlename-${index}`}
                          type="text"
                          placeholder="Ben"
                          required={false}
                          value={row?.middlename}
                          onChange={(e) => {
                            childrens[index].middlename = e.target.value;
                            setAppChild(childrens);
                            setFormData({ ...formData, childrens: childrens });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`childlastname-${index}`}
                          type="text"
                          placeholder="Joe"
                          required={false}
                          value={row?.lastname}
                          onChange={(e) => {
                            childrens[index].lastname = e.target.value;
                            setAppChild(childrens);
                            setFormData({ ...formData, childrens: childrens });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-8/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`childdob-${index}`}
                          type="date"
                          placeholder="John"
                          required={false}
                          value={stringToDate(row?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            const FullAge = [...childrenAge];
                            const TotalAge = calculate_age(e.target.value);
                            FullAge[index] = TotalAge;
                            setChildrenAge(FullAge);
                            childrens[index].date_of_birth = date;
                            // age[index].dAge.push(TotalAge)
                            // setAge({...age,dAge : TotalAge})
                            setAppChild(childrens);
                            setFormData({
                              ...formData,
                              childrens: childrens,
                            });
                          }}
                        />
                      </div>
                      {childrenAge[index] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {childrenAge[index]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Gender:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`childgender-${index}`}
                          value={row?.gender_id}
                          onChange={(e) => {
                            childrens[index].gender_id = parseInt(
                              e.target.value
                            );
                            setAppChild(childrens);
                            setFormData({
                              ...formData,
                              childrens: childrens,
                            });
                          }}
                        >
                          {genderPreference.map((row) => (
                            <option value={row?.id}>{row?.gender}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-pets.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Pets:
              </label>
            </div>
          </div>
          <div className="md:flex flex-row-reverse md:items-center w-[90%] m-auto mb-4">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setAppPet([...appPet, initialPetData]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Pet
                </button>
              </div>
            </div>
          </div>
          {appPet?.map((row, index) => {
            const pets = [...appPet];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex flex justify-end md:items-center mt-5">
                    <div className="md:flex md:items-center w-full md:w-4/12">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Status:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`pet-status-${index}`}
                          required={false}
                          value={row?.status}
                          onChange={(e) => {
                            pets[index].status = parseInt(e.target.value);
                            setAppPet(pets);
                            setFormData({ ...formData, pets: pets });
                          }}
                        >
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Pet Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`petname-${index}`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={row?.name}
                          onChange={(e) => {
                            pets[index].name = e.target.value;
                            setAppPet(pets);
                            setFormData({ ...formData, pets: pets });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`petgender-${index}`}
                          value={row?.pet_type_id}
                          onChange={(e) => {
                            pets[index].pet_type_id = parseInt(e.target.value);
                            setAppPet(pets);
                            setFormData({ ...formData, pets: pets });
                          }}
                        >
                          {petType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Breed:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`petbreed-${index}`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={row?.breed}
                          onChange={(e) => {
                            pets[index].breed = e.target.value;
                            setAppPet(pets);
                            setFormData({ ...formData, pets: pets });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`petdob-${index}`}
                          type="date"
                          placeholder="John"
                          required={false}
                          value={stringToDate(row?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            const FullAge = [...petAge];
                            const TotalAge = calculate_age(e.target.value);
                            FullAge[index] = TotalAge;
                            console.warn("FullAge", FullAge);
                            setPetAge(FullAge);
                            pets[index].date_of_birth = date;
                            // age[index].dAge.push(TotalAge)
                            // setAge({...age,dAge : TotalAge})
                            setAppPet(pets);
                            setFormData({
                              ...formData,
                              pets: pets,
                            });
                          }}
                        />
                      </div>
                      {petAge[index] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {petAge[index]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-others-living.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Other Living With You:
              </label>
            </div>
          </div>
          {appOtherLiving?.map((row, index) => {
            const other_livings = [...appOtherLiving];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`otherfirstname-${index}`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={row?.firstname}
                          onChange={(e) => {
                            other_livings[index].firstname = e.target.value;
                            setAppPet(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                      <div className="md:w-5/6">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Middle Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`othermiddlename-${index}`}
                          type="text"
                          placeholder="Ben"
                          required={false}
                          value={row?.middlename}
                          onChange={(e) => {
                            other_livings[index].middlename = e.target.value;
                            setAppPet(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                      <div className="md:w-3/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`otherlastname-${index}`}
                          type="text"
                          placeholder="Joe"
                          required={false}
                          value={row?.lastname}
                          onChange={(e) => {
                            other_livings[index].lastname = e.target.value;
                            setAppPet(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Date of Birth:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`otherdob-${index}`}
                          type="type"
                          placeholder="John"
                          required={false}
                          value={stringToDate(row?.date_of_birth)}
                          onChange={(e) => {
                            const date = formatDate(e.target.value);
                            const FullAge = [...otherAge];
                            const TotalAge = calculate_age(e.target.value);
                            FullAge[index] = TotalAge;
                            console.warn("FullAge", FullAge);
                            setOtherAge(FullAge);
                            other_livings[index].date_of_birth = date;
                            // age[index].dAge.push(TotalAge)
                            // setAge({...age,dAge : TotalAge})
                            setAppOtherLiving(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        />
                      </div>
                      {otherAge[index] > 0 ? (
                        <div className="md:w-1/5">
                          <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                            <span className="text-gray-600">
                              Age: {otherAge[index]}
                            </span>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Gender:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`othergender-${index}`}
                          value={row?.gender_id}
                          onChange={(e) => {
                            other_livings[index].gender_id = parseInt(
                              e.target.value
                            );
                            setAppPet(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        >
                          {genderPreference.map((row) => (
                            <option value={row?.id}>{row?.gender}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center w-full mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Relationship:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`otherrelationship-${index}`}
                          value={formData?.user_relationship_id}
                          onChange={(e) => {
                            other_livings[index].user_relationship_id =
                              parseInt(e.target.value);
                            setAppPet(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        >
                          {userRelationship.map((relation) => (
                            <option value={relation?.id}>
                              {relation?.relationship}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {row?.user_relationship_id === 6 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.other_relationship}
                          onChange={(e) => {
                            other_livings[index].other_relationship =
                              e.editor.getData();
                            setAppOtherLiving(other_livings);
                            setFormData({
                              ...formData,
                              other_livings: other_livings,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/tenant-references.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                References:
              </label>
            </div>
              <label className="block capitalize tracking-wide text-gray-700 text-sm font-bold mb-2">
                A minimum of {generalSetting[0]?.minimum_references} references are required:
              </label>
          </div>
          {appReference?.map((row, index) => {
            const references = [...appReference];
            return (
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          First Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reference-firstname-${index}`}
                          type="text"
                          placeholder="John"
                          required={false}
                          value={row?.firstname}
                          onChange={(e) => {
                            references[index].firstname = e.target.value;
                            setAppReference(references);
                            setFormData({
                              ...formData,
                              references: references,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-3">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Last Name:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reference-lastname-${index}`}
                          type="text"
                          placeholder="Deo"
                          required={false}
                          value={row?.lastname}
                          onChange={(e) => {
                            references[index].lastname = e.target.value;
                            setAppReference(references);
                            setFormData({
                              ...formData,
                              references: references,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Relationship:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <select
                          required={false}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`relationship`}
                          value={row?.user_reference_relationship_id}
                          onChange={(e) => {
                            references[index].user_reference_relationship_id =
                              parseInt(e.target.value);
                            setAppReference(references);
                            setFormData({
                              ...formData,
                              references: references,
                            });
                          }}
                        >
                          {userReferenceRelationship.map((relation) => (
                            <option value={relation?.id}>
                              {relation?.relationship}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {row?.user_reference_relationship_id === 5 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Reference Relationship:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.other_reference_relationship}
                          onChange={(e) => {
                            references[index].other_reference_relationship =
                              e.editor.getData();
                            setAppReference(references);
                            setFormData({
                              ...formData,
                              references: references,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 1:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-1-${index}`}
                        type="text"
                        required={false}
                        placeholder="123456789"
                        value={row?.number1}
                        onChange={(e) => {
                          references[index].number1 = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      />
                    </div>
                    <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-1-type-${index}`}
                        value={row?.number1_type}
                        onChange={(e) => {
                          references[index].number1_type = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      >
                        <option value={"Office"}>Office</option>
                        <option value={"Phone"}>Phone</option>
                        <option value={"Home"}>Home</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 2:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-2-${index}`}
                        type="text"
                        required={false}
                        placeholder="123456789"
                        value={row?.number2}
                        onChange={(e) => {
                          references[index].number2 = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      />
                    </div>
                    <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-2-type-${index}`}
                        value={row?.number2_type}
                        onChange={(e) => {
                          references[index].number2_type = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      >
                        <option value={"Office"}>Office</option>
                        <option value={"Phone"}>Phone</option>
                        <option value={"Home"}>Home</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Number 3:
                      </label>
                    </div>
                    <div className="md:w-3/5">
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-3-${index}`}
                        type="text"
                        required={false}
                        placeholder="123456789"
                        value={row?.number3}
                        onChange={(e) => {
                          references[index].number3 = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      />
                    </div>
                    <div className="md:w-1/6">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Type:
                      </label>
                    </div>
                    <div className="md:w-1/5 pl-3">
                      <select
                        required={false}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id={`reference-number-3-type-${index}`}
                        value={row?.number3_type}
                        onChange={(e) => {
                          references[index].number3_type = e.target.value;
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      >
                        <option value={"Office"}>Office</option>
                        <option value={"Phone"}>Phone</option>
                        <option value={"Home"}>Home</option>
                      </select>
                    </div>
                  </div>
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Reference Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={row?.notes}
                        onChange={(e) => {
                          references[index].notes = e.editor.getData();
                          setAppReference(references);
                          setFormData({
                            ...formData,
                            references: references,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-1/4 pl-5 ml-5 mt-2 ">
                <button
                  type="button"
                  className="btn2"
                  onClick={() => {
                    setAppReference([...appReference, initialReferenceData]);
                  }}
                >
                  <i class="fa fa-plus"></i> Add Another
                </button>
              </div>
            </div>
          </div>

          <hr />
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Background and Credit Check:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Have you ever been evicted?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={formData?.ever_evicted}
                    checked={formData?.ever_evicted == 1}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setFormData({
                        ...formData,
                        ever_evicted: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              {formData?.ever_evicted === 1 ? (
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Eviction Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData1?.eviction_notes}
                      onChange={(e) => {
                        setEditorData1({
                          ...editorData1,
                          eviction_notes: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Have you ever been convicted of a Felony?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={formData?.ever_convicted_of_felony}
                    checked={formData?.ever_convicted_of_felony == 1}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setFormData({
                        ...formData,
                        ever_convicted_of_felony: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              {formData?.ever_convicted_of_felony === 1 ? (
                <div className="md:flex mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Convicted of Felony Notes:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <CKEditor
                      initData={editorData?.convicted_of_felony_notes}
                      onChange={(e) => {
                        setEditorData({
                          ...editorData,
                          convicted_of_felony_notes: e.editor.getData(),
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-2/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Will there be a Co-Signer for the lease?
                  </label>
                </div>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={formData?.has_cosigners}
                    checked={formData?.has_cosigners == 1}
                    onChange={(e) => {
                      const checkedValue = e.target.checked === true ? 1 : 0;
                      setFormData({
                        ...formData,
                        has_cosigners: checkedValue,
                      });
                    }}
                    class="sr-only peer"
                  />
                  <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          {formData?.has_cosigners === 1 ? (
            <>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  <img
                    className="logo w-12 mr-5"
                    src="/assets/svgs/staff-information.svg"
                    alt=""
                  />
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                    Co-Signer Information:
                  </label>
                </div>
              </div>
              {appCoSigner?.map((row, index) => {
                const cosigners = [...appCoSigner];
                return (
                  <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                    <div className="w-full">
                      <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              First Name:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosignerfirstname-${index}`}
                              type="text"
                              placeholder="John"
                              required={false}
                              value={row?.firstname}
                              onChange={(e) => {
                                cosigners[index].firstname = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                          <div className="md:w-5/6">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Middle Name:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosignermiddlename-${index}`}
                              type="text"
                              placeholder="Ben"
                              required={false}
                              value={row?.middlename}
                              onChange={(e) => {
                                cosigners[index].middlename = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Last Name:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosignerlastname-${index}`}
                              type="text"
                              placeholder="Joe"
                              required={false}
                              value={row?.lastname}
                              onChange={(e) => {
                                cosigners[index].lastname = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Address Line 1:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-address-line-1-${index}`}
                            type="text"
                            placeholder="Apt. suite no. 1"
                            required={false}
                            value={row?.address_line1}
                            onChange={(e) => {
                              cosigners[index].address_line1 = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Address Line 2:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-address_line2-${index}`}
                            type="text"
                            placeholder="Jeff Street"
                            value={row?.address_line2}
                            onChange={(e) => {
                              cosigners[index].address_line2 = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-1/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              City:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-city-${index}`}
                              type="text"
                              placeholder="Albuquerque"
                              required={false}
                              value={row?.city}
                              onChange={(e) => {
                                cosigners[index].city = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                          <div className="md:w-1/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              State:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <select
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-state-${index}`}
                              required={false}
                              value={row?.state}
                              onChange={(e) => {
                                cosigners[index].state = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            >
                              <option value={"New Mexico"}>New Mexico</option>
                              <option value={"Missouri"}>Missouri</option>
                              <option value={"Texas"}>Texas</option>
                            </select>
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-1/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Zip:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-zip-${index}`}
                              type="text"
                              placeholder="90210"
                              required={false}
                              value={row?.zip_code}
                              onChange={(e) => {
                                cosigners[index].zip_code = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="md:flex md:items-center mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Number 1:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-num-1-${index}`}
                            type="text"
                            placeholder="123456789"
                            value={row?.number1}
                            onChange={(e) => {
                              cosigners[index].number1 = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          />
                        </div>
                        <div className="md:w-1/5 pl-3">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-num-1-type-${index}`}
                            value={row?.number1_type}
                            onChange={(e) => {
                              cosigners[index].number1_type = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          >
                            <option value={"Office"}>Office</option>
                            <option value={"Phone"}>Phone</option>
                            <option value={"Home"}>Home</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Number 2:
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-num-2-${index}`}
                            type="text"
                            placeholder="123456789"
                            value={row?.number2}
                            onChange={(e) => {
                              cosigners[index].number2 = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          />
                        </div>
                        <div className="md:w-1/5 pl-3">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-num-2-type-${index}`}
                            value={row?.number2_type}
                            onChange={(e) => {
                              cosigners[index].number2_type = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          >
                            <option value={"Office"}>Office</option>
                            <option value={"Phone"}>Phone</option>
                            <option value={"Home"}>Home</option>
                          </select>
                        </div>
                      </div>
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:w-1/5">
                          <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                            Email:
                          </label>
                        </div>
                        <div className="md:w-4/5">
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`cosigner-email-${index}`}
                            type="email"
                            placeholder="jhon.doe@email.com"
                            value={row?.email}
                            onChange={(e) => {
                              cosigners[index].email = e.target.value;
                              setAppCoSigner(cosigners);
                              setFormData({
                                ...formData,
                                cosigners: cosigners,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mt-3">
                        <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                          <div className="md:w-2/5">
                            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                              Date of Birth:
                            </label>
                          </div>
                          <div className="md:w-1/2">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-date_of_birth-${index}`}
                              type="date"
                              // value={stringToDate(formData?.date_of_birth)}
                              // placeholder="18293213"
                              required={false}
                              value={stringToDate(row?.date_of_birth)}
                              onChange={(e) => {
                                cosigners[index].date_of_birth = formatDate(
                                  e.target.value
                                );
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                          <div className="md:w-2/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Relationship:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <select
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-relationship-${index}`}
                              value={row?.user_relationship_id}
                              onChange={(e) => {
                                cosigners[index].user_relationship_id =
                                  parseInt(e.target.value);
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            >
                              {userRelationship.map((row) => (
                                <option value={row.id}>
                                  {row?.relationship}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="md:flex md:items-center w-full mt-3">
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Employer:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-employer-${index}`}
                              type="text"
                              placeholder="John"
                              required={false}
                              value={row?.employer}
                              onChange={(e) => {
                                cosigners[index].employer = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 px-3 lg:mx-20 mb-6">
                          <div className="md:w-5/6">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Title/Position:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosigner-position-${index}`}
                              type="text"
                              placeholder="CEO"
                              required={false}
                              value={row?.position}
                              onChange={(e) => {
                                cosigners[index].position = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:flex md:items-center w-full md:w-4/12 mb-6">
                          <div className="md:w-3/4">
                            <label className="block capitalize text-gray-700 font-bold mb-2">
                              Annual Salary:
                            </label>
                          </div>
                          <div className="md:w-3/4">
                            <input
                              className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id={`cosinger-annual-salary-${index}`}
                              type="number"
                              placeholder="1223"
                              required={false}
                              value={row?.annual_salary}
                              onChange={(e) => {
                                cosigners[index].annual_salary = e.target.value;
                                setAppCoSigner(cosigners);
                                setFormData({
                                  ...formData,
                                  cosigners: cosigners,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <>
            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
              How Messy or Clean do you consider yourself:
            </label>
            <input
              type="range"
              className="w-full"
              min="0"
              max="10"
              step="1"
              id="messy_clean"
              value={formData?.messy_clean}
              onChange={(e)=>{
                  setFormData({...formData,messy_clean:parseInt(e.target.value)})
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="md:w-1/12 text-center">A complete slob.</span>
              <span className="md:w-1/12 text-center">Obsessively Clean.</span>
            </div>
            <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2 mt-4">
              How Loud or Quite do you consider yourself:
            </label>
            <input
              type="range"
              className="w-full"
              min="0"
              max="10"
              step="1"
              value={formData?.loud_quiet}
              onChange={(e)=>{
                  setFormData({...formData,loud_quiet:parseInt(e.target.value)})
              }}
              id="loud_quiet"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="md:w-1/12 text-center">
                Crank it up as loud as possible!
              </span>
              <span className="md:w-1/12 text-center">
                Couldn't even tell I was there.
              </span>
            </div>

            <div className="md:flex mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Instruction from Application Setting:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                initData={generalSetting[0]?.additional_information}
                config= {
                  {
                    readOnly: true
                  }
                }
                onChange={(e) => {
                  setGeneralSetting({
                    ...generalSetting[0],
                    additional_information:  e.editor.getData(),
                  });
                }}
                
                />
                
              </div>
            </div>

            <div className="md:flex mt-4">
              <div className="md:w-1/3">
                <label className="block capitalize text-gray-700 text-xl font-bold mb-2">
                  Application Files:
                </label>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex md:items-center md:justify-start md:w-2/3">
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload files
                      </span>
                      <input
                        id="application-files"
                        type="file"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                        multiple={true}
                        hidden={true}
                        onChange={(e) => {
                          let files = e.target.files;
                          files.forEach((file, index) => {
                            Publicbase64Converter(file, index);
                          });
                          setAppFiles(getFilesAndURLs(e));
                        }}
                      />
                    </label>
                  </div>
                  <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          appFiles?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div>
                </div>
              </div>
            </div>
            <div className="md:flex flex-column md:items-center w-[90%] max-h-[50vh] overflow-x-hidden overflow-y-scroll m-auto my-3">
            {formData?.application_files?.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {formData?.application_files?.map((fileObject, index) =>
                        fileComponent({
                          file: fileObject?.file,
                          key: index,
                          inputFieldId: "application-files",
                          trashAble: true,
                          onTrashClick: () => {
                            let files = [...appFiles];
                            let file = [...formData.application_files];
                            files.splice(index, 1);
                            file.splice(index, 1);
                            setAppFiles(files);
                            setFormData(file);
                          },
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div>
            <div className="md:flex mt-3">
              <div className="md:w-2/5">
                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                  Additional Information:
                </label>
              </div>
              <div className="md:w-4/5">
                <CKEditor
                initData={editorData?.additional_information}
                onChange={(e) => {
                  setEditorData({
                    ...editorData,
                    additional_information:  e.editor.getData(),
                  });
                }}
                />
              </div>
            </div>
          </>
        </TabPanel>
        <TabPanel value={tabValue} index={9}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/staff-information.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Review Notes and Decision:
              </label>
            </div>
          </div>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Personal Interactions:
              </label>
            </div>
          </div>
          {appInteraction?.map((row, index) => {
                const interactions = [...appInteraction];
                return (
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="w-full">
                  <div className="md:flex md:items-center mt-3">
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Date:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction-date-${index}`}
                          type="date"
                          placeholder="Steve"
                          required={false}
                          value={stringToDate(row?.date)}
                          onChange={(e) => {
                            interactions[index].date = formatDate(e.target.value);
                            setAppInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6 mx-2">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Time:
                        </label>
                      </div>
                      <div className="md:w-2/4">
                        <input
                          className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`iteraction-time-${index}`}
                          type="time"
                          placeholder="Steve"
                          required={false}
                          value={stringToTime(row?.time)}
                          onChange={(e) => {
                            // console.warn("time", e.target.value);
                            const tTime = formatAMPM(e.target.value);
                            interactions[index].time = tTime;
                            setAppInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                      <div className="md:w-1/4">
                        <label className="block capitalize text-gray-700 font-bold mb-2">
                          Type:
                        </label>
                      </div>
                      <div className="md:w-3/4">
                        <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`interaction_type_id`}
                          value={row?.interaction_type_id}
                          onChange={(e) => {
                            interactions[index].interaction_type_id = parseInt(
                              e.target.value
                            );
                            setAppInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        >
                          {interactionType.map((type) => (
                            <option value={type?.id}>{type?.type}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {row?.interaction_type_id === 7 ? (
                    <div className="md:flex mt-3">
                      <div className="md:w-1/5">
                        <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                          Other Type Description:
                        </label>
                      </div>
                      <div className="md:w-4/5">
                        <CKEditor
                          initData={row?.other_type_description}
                          onChange={(e) => {
                            interactions[index].other_type_description =
                              e.editor.getData();
                            setAppInteraction(interactions);
                            setFormData({
                              ...formData,
                              interactions: interactions,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Interaction Notes:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                      initData={row?.notes}
                      onChange={(e) => {
                        interactions[index].notes = e.editor.getData();
                        setAppInteraction(interactions);
                        setFormData({
                          ...formData,
                          interactions: interactions,
                        });
                      }}
                      />
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>)})}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-1/4 mt-2 ">
                    <button
                      type="button"
                      className="btn2"
                      onClick={() => {
                        setAppInteraction([
                          ...appInteraction,
                          initialInteractionData,
                        ]);

                      }}
                    >
                      <i class="fa fa-plus"></i> Add Interaction
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Application Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  initData={editorData?.notes}
                  onChange={(e) => {
                    setEditorData({
                      ...editorData,
                      notes: e.editor.getData(),
                    });
                  }}
                  />
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Status:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`interaction_type_id`}
                      value={formData?.application_status_id}
                      onChange={(e) => {
                        setFormData({...formData,application_status_id : parseInt(e.target.value)})
                      }}
                    >
                      {appStatus.map((status)=>(
                        <option value={status?.id}>{status?.status}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              </div>
              </div>
              <button id={"form-submit-button"} type={"submit"} hidden={false} className="btn2 mt-6">Submit</button>
        </TabPanel>
      </form>
      </>
      }
    </>
  );
};
