import { createAsyncThunk } from "@reduxjs/toolkit";

import ApiRequest from "../../utility/http";
export const getUnitesStatuses = createAsyncThunk("auth/UnitesStatuses", async () => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-units-statuses");
    console.log(response?.data);
    return response?.data;
  } catch (error) { 
    console.log("unitesStatuses api has error");
  }
});
