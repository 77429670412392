import React, { useEffect } from "react";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../property/TabPanel";
import { CKEditor } from "ckeditor4-react";

export const FinanceInvoiceForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-Eviction-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Tenant Rent Property" {...a11yProps(0)} />
          <Tab label="Tenant Rent Unit" {...a11yProps(1)} />
          <Tab label="Tenant Other" {...a11yProps(2)} />
          <Tab label="Staff" {...a11yProps(3)} />
          <Tab label="From Vendor" {...a11yProps(4)} />
          <Tab label="To Customer" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-property.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice TENANT RENT (Property)
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Type<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent Month<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Rent Year<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Override Due Date in Lease?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Monthly Utilities and Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Divide Bills by<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Include Flat-Rate Leases in Division Count?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="w-1/2 flex items-center">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/utilities-water.svg"
                      alt=""
                    />
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Water
                    </label>
                    <label className="text-gray-700 mb-2 mr-2">Amount: </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="text"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="w-1/2 flex items-center">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/utilities-electric.svg"
                      alt=""
                    />
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Electric
                    </label>
                    <label className="text-gray-700 mb-2 mr-2">Amount: </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="text"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate/Send out a Late Fee invoice if
                        not paid in full by the end of the Grace Period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">
                  Invoice Balance:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  <button type="button" class="btn btn3">
                    Save as Individual Drafts
                  </button>
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-unit.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice TENANT RENT (Unit)
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Type<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Unit<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Rent Month<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Rent Year<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Is this a Prorated Invoice?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Prorated Days<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Override Due Date in Lease?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Monthly Utilities and Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Divide Bills by<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Include Flat-Rate Leases in Division Count?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="w-1/2 flex items-center">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/utilities-water.svg"
                      alt=""
                    />
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Water
                    </label>
                    <label className="text-gray-700 mb-2 mr-2">Amount: </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="text"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="w-1/2 flex items-center">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/svgs/utilities-electric.svg"
                      alt=""
                    />
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Electric
                    </label>
                    <label className="text-gray-700 mb-2 mr-2">Amount: </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="text"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate/Send out a Late Fee invoice if
                        not paid in full by the end of the Grace Period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">
                  Invoice Balance:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-other.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice TENANT Other
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Type<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Unit or Tenant<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Grace Period (Days)
                      <span className="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate/Send out a Late Fee invoice if
                        not paid in full by the end of the Grace Period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Late Fee Amount<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Title<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                {/* <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Invoice Line Items:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">Total:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>

              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">Total Due:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  <button type="button" class="btn btn3">
                    Save as Individual Drafts
                  </button>
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-staff.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice: Staff
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Staff Name<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Grace Period (Days)
                      <span className="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate/Send out a Late Fee invoice if
                        not paid in full by the end of the Grace Period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Late Fee Amount<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Title<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reference:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                {/* <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Invoice Line Items:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">Total:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>

              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">Total Due:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  {/* <button type="button" class="btn btn3">
                Save as Individual Drafts
              </button> */}
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-vendor.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice: From Vendor
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Vendor<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Grace Period (Days)
                      <span className="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Title<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reference:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                {/* <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Invoice Line Items:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">Total:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>

              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">Total Due:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  {/* <button type="button" class="btn btn3">
                Save as Individual Drafts
              </button> */}
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <div className="flex items-center">
            <img
              className="logo w-14 mr-5"
              src="/assets/svgs/finance-invoice-other.svg"
              alt=""
            />
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              Add Invoice: To Customer
            </label>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Customer<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-2/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Date<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center w-1/2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block tracking-wide text-gray-700 font-bold mb-2">
                      Budget to Deposit into:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Due Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Grace Period (Days)
                      <span className="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:flex md:w-full">
                    <div className="md:w-auto show-to-tenant">
                      <label className="block tracking-wide text-gray-700 font-bold mb-2">
                        Automatically Generate/Send out a Late Fee invoice if
                        not paid in full by the end of the Grace Period?:
                      </label>
                    </div>
                    <label class="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked
                        // value={formData?.hoa_show_to_tenant}
                        // onChange={(e) => {
                        //   const checkedValue = e.target.checked === true ? 1 : 0;
                        //   setFormData({
                        //     ...formData,
                        //     hoa_show_to_tenant: checkedValue,
                        //   });
                        // }}
                        class="sr-only peer"
                      />
                      <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-auto after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Late Fee Amount<span className="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Title<span title="Field is required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-1/2 mr-2 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reference:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`lease-date-to-send`}
                      // value={propertyMaterial?.material_type_id}
                      // onChange={(e) => {
                      //   const value = parseInt(e.target.value);
                      //   setPropertyMaterial({
                      //     ...propertyMaterial,
                      //     material_type_id: value,
                      //   });
                      //   getPropertyAllMaterials(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <hr />
              <div className="flex items-center">
                {/* <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/utilities.svg"
                  alt=""
                /> */}
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Invoice Line Items:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-1/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2 mr-1">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Description:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2 mr-2">
                        Amount<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">Total:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-tax.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Taxes and Other Fees:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Taxes/Other Fees Due Per Unit:
                </span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="flex items-center">
                <img
                  className="logo w-14 mr-5"
                  src="/assets/svgs/finance-promo-code.svg"
                  alt=""
                />
                <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2 italic">
                  Discounts and Credits:
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full mr-2 mt-3">
                  <div className="w-1/5 flex items-center">
                    <label className="text-gray-700 text-xl font-bold mb-2 mr-2">
                      Other:
                    </label>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-1/5 mr-1">
                      <label className="text-gray-700 mb-2">
                        Name<span title="Field is required">*</span>:
                      </label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3 mr-2">
                    <div className="w-2/5">
                      <label className="text-gray-700 mb-2">Description:</label>
                    </div>
                    <div className="w-3/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-1/3">
                    <div className="w-3/5 mr-2">
                      <select
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="">Select</option>
                      </select>
                    </div>
                    <div className="w-2/5">
                      <input
                        type="text"
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 mr-2">
                  Total Discounts/Credits Per Unit:
                </span>
                <span className="text-green-600">AUTOGENERATED</span>
              </div>

              <hr />
              <div className="md:flex mt-4">
                <div className="md:w-1/3">
                  <div className="flex">
                    {/* <img
                  className="logo w-12 mr-5"
                  src="/assets/img/properties/system-files-private.png"
                  alt=""
                /> */}
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Invoice Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-owner-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyOwnerDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyOwnerDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           title: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyOwnerDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyOwnerDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyOwnerDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               title: picObj.title,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_owner_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyOwnerDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyOwnerDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-owner-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyOwnerDocumentsWithUrl.map((fileObject, fileIndex) =>
                        fileComponent({
                          file: fileObject?.file,
                          title: fileObject?.title,
                          key: fileIndex,
                          inputFieldId: "property-owner-files",
                          trashAble: true,
                          editAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_owner_files,
                            ];
                            fileLists.splice(fileIndex, 1);
                            formData.property_owner_files = fileLists;
                            setPropertyOwnerDocumentsWithUrl(fileLists);
                          },
                          onEditClick: () => {
                            const fileLists = [...formData?.property_owner_files];
                            const currentTitle = fileLists[fileIndex].title || "";
                          
                            Swal.fire({
                              title: "Edit File",
                              html: `
                                <input id="swal-input1" class="swal2-input" placeholder="Title" value="${currentTitle}">
                              `,
                              focusConfirm: false,
                              preConfirm: () => {
                                const title = document.getElementById('swal-input1').value;
                                return title;
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const title = result.value;
                                fileLists[fileIndex].title = title;
                                formData.property_owner_files[fileIndex].title = title;
                                setPropertyOwnerDocumentsWithUrl(fileLists);
                              }
                            });
                          }
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Invoice Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.dln_id_issue_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     dln_id_issue_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>

              <div className="flex justify-content-end items-center mt-3">
                <span className="text-gray-600 font-bold mr-2">Total Due:</span>
                <span className="text-red-600">AUTOGENERATED</span>
              </div>
              <div class="flex justify-evenly items-center mt-3">
                <div class="flex justify-between items-center gap-4">
                  <button type="button" class="btn btn3">
                    Save as Draft
                  </button>
                  {/* <button type="button" class="btn btn3">
                Save as Individual Drafts
              </button> */}
                  <button type="button" class="btn btn3 px-5">
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </TabPanel>
      </form>
    </>
  );
};
