import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { CKEditor } from "ckeditor4-react";
import ApiRequest from "../../utility/http";
import { Tooltip } from "../Loader/Tooltip";
import { DatePicker } from "../genericFunctions.js/DatePicker";

export const TenantNotifications = ({data,handleNotificationTrue,hiddenData,ownerSettings}) => {
    const initialNotificationData = {
        title: "",
        notification: "",
        is_popup_notification: "",
        posting_date: "",
        posting_time: "",
      };
    const [tenantNotification, setTenantNotification] = useState([]);
    const [ownerSetting, setOwnerSetting] = useState([]);
    const [tenantNotifications, setTenantNotifications] = useState([]);
    const [editorData,setEditorData] = useState("");
    const [indexEdit,setIndexEdit ]= useState();
    // // console.log('@indexEdit',indexEdit);
    const [timeZone,setTimeZone] = useState([]);
    const [bulkAction , setBulkAction] = useState({
      bulk_action:""
    });
    const [showForm, setShowForm] = useState(false);
    const [viewForm,setViewForm] =useState(false);
      console.log("@tenantNotifications",tenantNotifications);


      function stringToTime(timeString) {
        // console.warn("timeString",timeString)
        if (timeString === null || timeString === undefined || timeString >= 0)
          return;
        let parts = timeString.split(":");
        let ampm = timeString.split(" ");
        let hours = parts[0];
        let minutes = parts[1];
        let dayNight = ampm[1];
    
        let time = hours + ":" + minutes + " " + dayNight;
        let isPM = false;
    
        if (time.endsWith("am")) {
          isPM = false;
          time = time.replace(/\s(am|pm)/, "");
        } else {
          isPM = true;
          time = time.replace(/\s(am|pm)/, "");
          let timeArr = time.split(":");
          timeArr[0] = (parseInt(timeArr[0]) + 12).toString();
          time = timeArr.join(":");
        }
        return time;
      }
  const formatAMPM = (date) => {
    // const time = date.toLocaleTimeString();
    const formattedDate = new Date();
    formattedDate.setHours(date.getHours());
    formattedDate.setMinutes(date.getMinutes());
    formattedDate.setSeconds(date.getSeconds());

    var Hours = formattedDate.getHours();
    var ampm = Hours >= 12 ? "pm" : "am";
    Hours = Hours % 12;
    Hours = Hours ? Hours : 12;
    Hours = ("0" + Hours).slice(-2);
    const minutes = ("0" + formattedDate.getMinutes()).slice(-2);
    const second = ("0" + formattedDate.getSeconds()).slice(-2);

    const TotalTime = Hours + ":" + minutes + ":" + second + " " + ampm;

    return TotalTime;
  };
  
  function convertToGMT(time, gmt, timeZone) {
    // Extract the GMT offset from the GMT string
    const gmtOffsetMatch = gmt.match(/([+-])(\d{2}):(\d{2})/);
    const gmtSign = gmtOffsetMatch[1] === '+' ? -1 : 1;
    const gmtHours = parseInt(gmtOffsetMatch[2], 10);
    const gmtMinutes = parseInt(gmtOffsetMatch[3], 10);
  
    // Get the current local date and time
    const currentDate = new Date();
  
    // Split the provided time into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);
  
    // Set the current local date and time to the specified hours and minutes
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
  
    // Get the local time zone offset in minutes
    const localOffset = currentDate.getTimezoneOffset();
  
    // Calculate the target offset in minutes
    const targetOffset = gmtSign * (gmtHours * 60 + gmtMinutes) - localOffset;
  
    // Create a new Date object with the adjusted time zone
    const targetTime = new Date(currentDate.getTime() + targetOffset * 60 * 1000);
  
    // Convert the target time to the specified time zone
    const targetOffsetMs = targetTime.getTimezoneOffset() * 60 * 1000;
    const convertedTime = new Date(targetTime.getTime() + targetOffsetMs);
  
    // Return the converted time
    console.warn('convertedTime',convertedTime)
    return formatAMPM(convertedTime);
  }
  function reverseConvertToGMT(date) {
    const aa = "Fri, 18 Oct 2013 " + date + " GMT";
    date = new Date(aa);
    const time = date.toLocaleTimeString(undefined, { hour12: false, hour: '2-digit', minute: '2-digit' });
    console.log("time",time);
    return time;
  }
  
  const gmtRow = timeZone?.find(row => ownerSetting?.time_zone_id === row?.id);
  const gmt = gmtRow ? gmtRow.name : null;


  const timeRow = timeZone?.find(row => ownerSetting?.time_zone_id === row?.id);
  const time = timeRow ? timeRow.timezone : null;
  console.warn('gmt', gmt);
  console.warn('time', time);
  
  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + parseInt(date.getMonth() + 1)).slice(-2);
    const TotalDate = day + "-" + month + "-" + year;
    return TotalDate;
  };
  function stringToDate(dateString) {
    // console.warn("dateString",dateString)
    if (dateString === null || dateString === undefined || dateString >= 0)
      return;
    let parts = dateString.split("-");
    let day = parts[0];
    let month = parts[1]; // months are 0-based
    let year = parts[2];
    let date = year + "-" + month + "-" + day;
    // console.warn("UnformatDate",date)
    return date;
  }
  function ShowFormatDate(data, format) {
    if (data === null || data === undefined)
      return;
    const date = new Date(stringToDate(data));
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const year = date.getFullYear();
  
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    format = format.replace('dd', formattedDay);
    format = format.replace('DD', formattedDay);
    format = format.replace('d', day);
    format = format.replace('D', day);
    format = format.replace('mm', formattedMonth);
    format = format.replace('MM', formattedMonth);
    format = format.replace('m', month);
    format = format.replace('M', month);
    format = format.replace('yyyy', year);
    format = format.replace('YYYY', year);
  
    return format;
  }
  function ShowFormatTime(data, format) {
    if (data === null || data === undefined || !isValidTimeString(data)) {
      return "Invalid time";
    }
  
    const time = parseTime(data);
    const dateObj = new Date(time);
  
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
  
    const formattedHours = formatDigits(hours % 12 || 12);
    const amPm = hours < 12 ? 'AM' : 'PM';
    const formattedMinutes = formatDigits(minutes);
    const formattedSeconds = formatDigits(seconds);
  
    format = format.replace('HH', formattedHours);
    format = format.replace('H', formattedHours);
    format = format.replace('hh', formattedHours);
    format = format.replace('h', formattedHours);
    format = format.replace('mm', formattedMinutes);
    format = format.replace('MM', formattedMinutes);
    format = format.replace('m', formattedMinutes);
    format = format.replace('M', formattedMinutes);
    format = format.replace('ss', formattedSeconds);
    format = format.replace('SS', formattedSeconds);
    format = format.replace('s', formattedSeconds);
    format = format.replace('S', formattedSeconds);
    format = format.replace('I', formattedSeconds);
    format = format.replace('i', formattedSeconds);
    format = format.replace('A', amPm);
    format = format.replace('a', amPm);
    format = format.replace('tt', amPm);
    format = format.replace('TT', amPm);
  
    return format;
  }
  
  function isValidTimeString(timeString) {
    const timeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9])(:[0-5][0-9])? [AP]M$/i;
    return timeRegex.test(timeString);
  }
  
  function parseTime(timeString) {
    const [time, amPm] = timeString.split(' ');
    const [hours, minutes, seconds] = time.split(':').map(Number);
  
    let parsedHours = hours % 12;
    if (/pm/i.test(amPm)) {
      parsedHours += 12;
    }
  
    return new Date().setHours(parsedHours, minutes, seconds || 0, 0);
  }
  
  function formatDigits(value) {
    return value.toString().padStart(2, '0');
  }
  

  const getTimeZone = async ()=>{
    const response = await ApiRequest.get("/public/api/auth/get-timezones")
    if(response?.status == 200)
    {
      setTimeZone(response?.data?.data?.timezones)
    }
  }




      const notes = ()=>{
        const data = [];
        data.push(tenantNotification);
        const array = [...data];
        // console.log('@array',array);
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[0].notification = cleanText;
        setTenantNotification(array);
      }
      const bulkData = ()=>{
        notes();
        const newDataArray  = [];
        newDataArray.push(tenantNotification);
        setShowForm(false)
        handleNotificationTrue(prevArray => [...prevArray, ...newDataArray])
        setTenantNotifications(prevArray => [...prevArray, ...newDataArray]);
        setTenantNotification(initialNotificationData);
        setEditorData(null);
        // setBulkSpaces("");
      }
    
    const editSpacesData = () =>{
        const array = [...tenantNotifications];
        array[indexEdit] = tenantNotification;
        const cleanText = new DOMParser().parseFromString(editorData, 'text/html').body.textContent;
        array[indexEdit].notification = cleanText;
        setTenantNotifications(array);
        setShowForm(false)
        handleNotificationTrue(array);
        setIndexEdit(undefined);
        setTenantNotification(initialNotificationData);
        setEditorData(null);
      }
      const indexFind = (index)=>
      {
        // var index = $(this).attr('data-index');
        // var modal = $(this).attr('data-bs-toggle');
        // const findIndex = parkingSpaces.length -1 - index ;
        setIndexEdit(index);
        setViewForm(false);
        setShowForm(true);
        setTenantNotification(tenantNotifications[index]);
        setEditorData(tenantNotifications[index]?.notification)
        // myDivRef.current.scrollIntoView({ behavior: 'smooth' });
        // // console.log("index",findIndex);
        // // console.log("modal",modal);
      }
      const viewData = (index)=>{
        setShowForm(false)
        setTenantNotification(tenantNotifications[index]);
        setEditorData(tenantNotifications[index].notification)
        setViewForm(true);
      }
      const deleteData = (index)=>{
        setShowForm(false);
        setViewForm(false);
        const newArray = [...tenantNotifications];
        newArray.splice(index, 1);
        setTenantNotifications(newArray);
        setTenantNotification(initialNotificationData);
        handleNotificationTrue(newArray);
      }
      function convertTo12HourFormat(time) {
        // Splitting the time into hours and minutes
        var [hours, minutes] = time.split(':');
      
        // Parsing the hours and minutes into integers
        hours = parseInt(hours);
        minutes = parseInt(minutes);
      
        // Determining the period (AM or PM)
        var period = (hours >= 12) ? 'PM' : 'AM';
      
        // Converting the hours to 12-hour format
        if (hours === 0) {
          hours = 12; // 12 midnight
        } else if (hours > 12) {
          hours -= 12;
        }
      
        // Formatting the time with leading zeros for single-digit hours and minutes
        var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':00';
      
        // Combining the formatted time with the period
        formattedTime += ' ' + period;
        console.log("Format Time",formattedTime)
        return formattedTime;
      }
      const now = new Date();
      const currentDate = now.toISOString().slice(0, 10);
      const currentTime = now.toLocaleTimeString('en-US', { hour12: false });
    const columns = [
        {
          name: "Date",
          selector: (data) => data.posting_date == '' ? ShowFormatDate(formatDate(currentDate) ,ownerSetting?.date_format) : ShowFormatDate(data?.posting_date ,ownerSetting?.date_format),
          sortable: true,
        },
        {
          name: "Time",
          selector: (data) => data.posting_time == '' ? ShowFormatTime(formatAMPM(currentTime) ,ownerSetting?.time_format) : ShowFormatTime(convertTo12HourFormat(reverseConvertToGMT(data.time)),ownerSetting?.time_format),
          sortable: true,
        },
        {
          name: "Title",
          selector: (data) => data.title,
          sortable: true,
        },
        {
          name: "Notification",
          selector: (data) => data.notification,
          sortable: true,
        },
        // {
        //   name: "Location",
        //   selector: (data) => tenantNotificationLocations.map((row)=>{
        //     if(data.tenant_location_id == row?.id)
        //     {
        //       return row?.location
        //     }
        //   }),
        // },
        // {
        //   name: "Measurements",
        //   selector: (data) => tenantNotificationTenant.map((row)=>{
        //     if(data.measurement_tenant_id == row?.id)
        //     {
        //       return data.width ? "W: "+ data.width+" "+row?.tenant : "A: " + data.amount+" "+ row?.tenant
        //     }
        //   }),
        // },
        // {
        //   name: "Handicap",
        //   selector: (data) => data.handicap,
        // },
        // {
        //   name: "Assignee",
        //   selector : (data) => 'UnAssign'
        // },
        // {
        //   name: "Status",
        //   selector: (row) => row.is_active===1?"Active":"Inactive",
        // },
        {
          name: "Options",
          cell: (row,index) => {
            return (
              <>
                {hiddenData !== true ? (
                  <div className="flex">
                    {tenantNotifications && tenantNotifications[index]?.posting_date !== '' ? 
                      <>
                        <button
                          type="button"
                          className="bg-transparent border-none md:w-1/12 p-0"
                          data-bs-toggle="modal"
                          data-bs-target={`#ViewForm`}
                          data-index={index}
                          onClick={(e) => {
                            viewData(index);
                          }}
                        >
                          <img src="/assets/svgs/system-view.svg" />
                        </button>
                        <button
                          type="button"
                          className="bg-transparent border-none md:w-1/12 p-0 mx-3"
                          data-index={index}
                          onClick={(e) => {
                            indexFind(index);
                          }}
                        >
                          <img src="/assets/img/system-edit.png" />
                        </button>
                        <button
                          type="button"
                          className="bg-transparent border-none md:w-1/12 p-0"
                          data-index={index}
                          onClick={(e) => {
                            deleteData(index);
                          }}
                        >
                          <img src="/assets/svgs/system-trashcan.svg" />
                        </button>
                      </>
                    :<button
                    type="button"
                    className="bg-transparent border-none md:w-1/12 p-0"
                    style={{minWidth:'15px'}}
                    data-bs-toggle="modal"
                    data-bs-target={`#ViewForm`}
                    data-index={index}
                    onClick={(e) => {
                      viewData(index);
                    }}
                  >
                    <img src="/assets/svgs/system-view.svg" />
                  </button>}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="bg-transparent border-none md:w-1/12 p-0"
                    data-index={index}
                    data-bs-toggle="modal"
                    data-bs-target={`#ViewForm`}
                    onClick={(e) => {
                      viewData(index);
                    }}
                  >
                    <img src="/assets/svgs/system-view.svg" />
                  </button>
                )}
              </>
            );
            
            
          },
        },
      ];
    const tableData = {
        columns : columns,
        data : tenantNotifications,
      };
      
      const exportPDF = ()=>{
        const tenant = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, tenant, size);
    
        doc.setFontSize(15);
    
        const title = "Tenant Notifications";
        const headers = [["Title", "Notification","Date","Time"]];
    
        const data = tenantNotifications.map(elt => [elt.title,elt.notification,elt.posting_date == '' ? formatDate(currentDate) : elt.posting_date,elt.posting_time == '' ? formatAMPM(currentTime) : elt.posting_time])
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Tenant Notification.pdf")
      }
      const exportCSV = () => {
        // Get data from data table
        const TotalData = tenantNotifications.map(elt => [elt.title,elt.notification,elt.posting_date == '' ? formatDate(currentDate) : elt.posting_date,elt.posting_time == '' ? formatAMPM(currentTime) : elt.posting_time])
        // Convert the data to a CSV string
    
        const headers = ["Title", "Notification","Date","Time"];
        // let content = {
        //   head:headers,
        //   body:TotalData,
        // }
        TotalData.unshift(headers)
        const csv = Papa.unparse(TotalData);
        // Create a blob of the data
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // Create a link to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Tenant Notification.csv");
        link.click();
      };





      useEffect(() => {
        getTimeZone();
        setTenantNotification(initialNotificationData)
        setTenantNotifications(data)
        setOwnerSetting(ownerSettings)
      }, [])
      

  return (
    <>
        <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/img/properties/system-notifications.png"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2" >
                Notifications:
              </label>
              {/* <img
                    className="logo w-4 h-4 ml-1"
                    src="/assets/img/system-tooltip.png"
                    title="Post Notification that go to everyone in the Tenant! You can even choose to have the notification appear as a pop-up when they log into the system next."
                    alt="Tooltip"
                  /> */}
                  <Tooltip data={"Post Notification that go to everyone in the Tenant! You can even choose to have the notification appear as a pop-up when they log into the system next."}/>
            </div>
          </div>


          {showForm == false ?  
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                hidden={hiddenData}
                  type="button"
                  className="btn3"
                  onClick={() => {
                    setShowForm(true)
                  }}
                >
                  <i class="fa fa-plus"></i> Add Notification
                </button>
              </div>
            </div>
          </div>:null}

 
              {showForm == true ?
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-title"
                      type="text"
                      placeholder="Title"
                      value={tenantNotification?.title}
                      onChange={(e) => {
                        setTenantNotification({...tenantNotification,title:e.target.value});
                      }}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Notification:
                      </label>
                    </div>
                    <div className="md:w-4/5">
                      <CKEditor
                        initData={editorData}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      />
                    </div>
                  </div>

                  <div className="md:flex md:items-center mt-3 justify-content-between">
                    <div className="w-auto flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold" >
                        Pop-Up Notification:
                      </label>
                      {/* <img
                        className="logo w-4 h-4 ml-1"
                        src="/assets/img/system-tooltip.png"
                        title="This will cause the notification to display in a pop-up the next time they log into their account. Use this for important notifications."
                        alt="Tooltip"
                      /> */}
                      <Tooltip data={"This will cause the notification to display in a pop-up the next time they log into their account. Use this for important notifications."}/>

                      <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          value={tenantNotification?.is_popup_notification}
                          checked = {tenantNotification.is_popup_notification == 1}
                          onChange={(e) => {
                            const checkedValue =
                              e.target.checked === true ? 1 : 0;
                            setTenantNotification({...tenantNotification,is_popup_notification:checkedValue})
                          }}
                          class="sr-only peer"
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex justify-end">
                      <div className="md:w-auto">
                        <button class="btn3" type="button" 
                        onClick={() => {
                          if (indexEdit !== undefined) {
                          editSpacesData();
                          } else {
                          bulkData();
                          }
                      }}
                        >Post Now</button>
                      </div>
                      <div className="md:w-auto">
                        <button
                          class="btn3"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop`}
                        >
                          Post Later
                        </button>
                      </div>
                    </div>
                    
                  </div>
                    <div
                      class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                      id={`staticBackdrop`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog relative w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                            <h5
                              class="text-xl font-medium leading-normal text-gray-800"
                              id="exampleModalLabel"
                            >
                              Post Later
                            </h5>
                            <button
                              type="button"
                              class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ><i className='fa fa-remove'></i></button>
                          </div>
                          <div class="modal-body relative p-4">
                            
                            <div className="md:flex md:items-center w-full mt-3">
                              <div className="md:w-1/4">
                                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                  Date:
                                </label>
                              </div>
                              <div className="md:w-1/2">
                                <DatePicker
                                  value={stringToDate(tenantNotification?.posting_date)}
                                  onChange={(e) => {
                                    const Date = formatDate(e);
                                    setTenantNotification({...tenantNotification,posting_date:Date})
                                  }}
                                />
                              </div>
                            </div>
                            <div className="md:flex md:items-center w-full mt-3">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  Time:
                                </label>
                              </div>
                              <div className="md:w-2/4">
                              <input
                                className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="time"
                                type="time"
                                placeholder="Steve"
                                required={false}
                                value={tenantNotification?.posting_time == "" ? "" :reverseConvertToGMT(tenantNotification?.posting_time)}
                                onChange={(e) => {
                                  const convertedTime = convertToGMT(e.target.value, gmt, time);
                                  setTenantNotification({...tenantNotification, posting_time: convertedTime});
                                }}
                              />
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                if (indexEdit !== undefined) {
                                editSpacesData();
                                } else {
                                bulkData();
                                }
                            }}
                              data-bs-dismiss="modal"
                            >
                              Schedule
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  {/* <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                        <div className="flex justify-end w-full mt-4">
                        <div className="w-1/4 pl-5 ml-5 mt-2 ">
                            <button
                            type="button"
                            className="btn3"
                            onClick={() => {
                                if (indexEdit !== undefined) {
                                editSpacesData();
                                } else {
                                bulkData();
                                }
                            }}
                            >
                            {indexEdit !== undefined ? (
                                <><i className='fa fa-edit'></i>Update</>
                            ) : (
                                <><i className='fa fa-save'></i>Save</>
                            )}
                            </button>
                        </div>
                        </div>
                    </div> */}
                </div>
              </div>:null}
              
                                  <div
                                  class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
                                  id={`ViewForm`}
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabindex="-1"
                                  aria-labelledby="ViewFormLabel"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog relative w-auto pointer-events-none">
                                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                                      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                        <h5
                                          class="text-xl font-medium leading-normal text-gray-800"
                                          id="exampleModalLabel"
                                        >
                                          View
                                        </h5>
                                        <button
                                          type="button"
                                          class="btn3-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() => {
                                            setViewForm(true);
                                            setTenantNotification(initialNotificationData);
                                            setEditorData(null)
                                        }}
                                        ><i className='fa fa-remove'></i></button>
                                      </div>
                                      <div class="modal-body relative p-4">
              <div className="md:flex flex-column md:items-center w-full m-auto">
                {tenantNotification?.title != '' ?<div className="md:flex md:items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    {/* <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="p-notice-title"
                      type="text"
                      placeholder="Title"
                      disabled
                      value={tenantNotification?.title}
                      onChange={(e) => {
                        setTenantNotification({...tenantNotification,title:e.target.value});
                      }}
                    /> */}
                    <span>
                      {tenantNotification?.title}
                      </span>
                  </div>
                </div>:null}
                <div className="w-full">
                {tenantNotification?.notification != '' ?<div className="md:flex mt-3">
                    <div className="md:w-1/5">
                      <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                        Notification:
                      </label>
                    </div>
                    <div className="md:w-4/5  mx-2">
                      {/* <CKEditor
                        initData={editorData}
                        config={{
                            readOnly:true
                        }}
                        onChange={(e) => {
                          setEditorData(e.editor.getData());
                        }}
                      /> */}
                      <span>
                      {editorData}
                      </span>
                    </div>
                  </div>:null}

                  <div className="md:flex md:items-center mt-3">
                  {tenantNotification?.is_popup_notification != 0 && tenantNotification?.is_popup_notification != '' ?<div className="w-full flex justify-start gap-3">
                      <label className="input-label text-gray-700 font-bold" title="This will cause the notification to display in a pop-up the next time they log into their account. Use this for important notifications.">
                        Pop-Up Notification:
                      </label>

                      {/* <label class="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          value={tenantNotification?.is_popup_notification}
                          checked = {tenantNotification.is_popup_notification == 1}
                          disabled
                          onChange={(e) => {
                            const checkedValue =
                              e.target.checked === true ? 1 : 0;
                            setTenantNotification({...tenantNotification,is_popup_notification:checkedValue})
                          }}
                          class="sr-only peer"
                        />
                        <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label> */}
                      <span>{tenantNotification?.is_popup_notification == 1 ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i>  }</span>
                    </div>:null}
                  </div>
                    {tenantNotification?.posting_date != '' ? <>
                    <div className="h6 text-center">Post Later</div>
                    <div className="md:flex md:items-center w-full mt-3">
                              <div className="md:w-1/4">
                                <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                                  Date:
                                </label>
                              </div>
                              <div className="md:w-1/2">
                                {/* <input
                                  className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="date"
                                  type="date"
                                  placeholder="Steve"
                                  required={false}
                                  disabled
                                  value={stringToDate(tenantNotification?.posting_date)}
                                  onChange={(e) => {
                                    const Date = formatDate(e.target.value);
                                    setTenantNotification({...tenantNotification,posting_date:Date})
                                  }}
                                /> */}
                                <span>
                      {tenantNotification?.posting_date}
                      </span>
                              </div>
                            </div>
                            <div className="md:flex md:items-center w-full mt-3">
                              <div className="md:w-1/4">
                                <label className="block capitalize text-gray-700 font-bold mb-2">
                                  Time:
                                </label>
                              </div>
                              <div className="md:w-2/4">
                                {/* <input
                                  className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  id="time"
                                  type="time"
                                  disabled
                                  placeholder="Steve"
                                  required={false}
                                  value={stringToTime(tenantNotification?.posting_time)}
                                  onChange={(e) => {
                                    const tTime = formatAMPM(e.target.value);
                                    setTenantNotification({...tenantNotification,posting_time:tTime})
                                  }}
                                /> */}
                                <span>
                      {tenantNotification?.posting_time}
                      </span>
                              </div>
                            </div>
                    </>:<><div className="h6 text-center mt-2">Posted</div></>}
                </div>
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                              type="button"
                              class="btn3"
                              onClick={() => {
                                setViewForm(true);
                                setTenantNotification(initialNotificationData);
                                setEditorData(null)
                            }}
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
              </div>
              </div>
              </div>
              </div>
              </div>
           

          

          <hr />
          {tenantNotifications?.length > 0 ?<>

          <div className="flex flex-row-reverse mt-3">
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-pdf.png" alt="PDF" onClick={exportPDF} style={{cursor:"pointer"}}/>
        <img className="logo w-8 mr-3" src="/assets/img/properties/system-download-csv.png" alt="CSV" onClick={exportCSV} style={{cursor:"pointer"}}/>
      </div>
      <DataTableExtensions {...tableData}>

      <DataTable
        columns={columns}
        data={tenantNotifications}
        pagination
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        className="striped"
        fixedHeader
        fixedHeaderScrollHeight="500px"
        highlightOnHover
        sortField={indexEdit}
        sortDirection="asc"
        />
        </DataTableExtensions>
          </>:null}
          {/* <div className="md:flex md:items-center w-full md:w-6/12 px-3 lg:mx-20 mb-6">
            <div className="md:w-2/4">
              <label className="block capitalize text-gray-700 font-bold mb-2">
                Bulk Actions:
              </label>
            </div>
            <div className="md:w-3/4">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="bulk-actions"
                value={bulkAction.bulk_action}
                onChange={(e) => {
                  setBulkAction({ ...bulkAction, bulk_action: e.target.value });
                }}
              >
                <option selected disabled>Select</option>
                <option value={"EXPORT TO CSV"}>EXPORT TO CSV</option>
                <option value={"EXPORT TO PDF"}>EXPORT TO PDF</option>
              </select>
            </div>
            <div className="w-2/4 pl-5 ml-5 mt-2 ">
               <button
                  type="button"
                  className="btn3"
                  onClick={bulkAction?.bulk_action == "EXPORT TO PDF" ? exportPDF : bulkAction?.bulk_action == "EXPORT TO CSV" ? exportCSV : bulkAction?.bulk_action == "DELETE" ? deleteData : null}
                >
                  <i className="fa fa-arrow-alt-circle-right"></i>
                  GO
                </button>
            </div>
          </div> */}
    </>
  )
}
