import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiRequest from "../../utility/http";
export const adminPrefix = createAsyncThunk("auth/adminPrefixes", async () => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-name-prefixes");
    return response.data;
  } catch (error) {
    console.log("adminPrefixes api has error");
  }
});
export const adminSuffix= createAsyncThunk("auth/adminSuffixes", async () => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-name-suffixes");
    return response.data;
  } catch (error) {
    console.log("adminSuffixes api has error");
  }
});
export const viewPrefix= createAsyncThunk("auth/adminTask", async (id) => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-name-prefixes");
    return response.data;
  } catch (error) {
    console.log("properties api has error");
  }
});
export const editPrefix= createAsyncThunk("auth/adminTask", async (id) => {
  try {
    const response = await ApiRequest.get("/public/api/auth/get-name-suffixes",{id});
    return response.data;
  } catch (error) {
    console.log("properties api has error");
  }
});
