import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import alertReducer from "./alert/alertSlice";
import propertiesReducer from "./properties/propertiesSlice";
import ShowPropertyReducer from "./properties/ShowPropertySlice";
import adminReducer from "./admin/adminSlice";
import suffixReducer from "./admin/suffixSlice";
import viewPrefixReducer from "./admin/viewPrefixSlice";
import parkingSlice from "./admin/parking/parkingSlice";
import moveOutReasonSlice from "./admin/moveOutReason/moveOutReasonSlice";
import EthnicitiesSlice from "./admin/Ethnicities/EthnicitiesSlice";
import FeaturesSlice from "./admin/Features/FeaturesSlice";
// import getPermissionSlice from "./permission/getPermissionSlice";
// import assignPermissionToRoleSlice from "./permission/permissionSlice";
// import delegatePermissions from "./permission/delegatePermissionsSlice";
// import tekeDelegatePermissionSlice from "./permission/tekeDelegatePermissionSlice";
import authUserProfileSlice from "./auth/userProfileSlice";
// import userProfileSlice from "./user/userSlice"
// import  userProfileSlice from "./user/userProfileSlice";
import UserRolesSlice from "./userRoles/UserRolesSlice";
import userProfileSlice from "./user/userSlice";
import unitSlice from "./units/unitSlice"; 
import materialmeasurementSlice from "./properties/materialmeasurementSlice";
import GetUnitMeasurement  from "./measurement/MeasurementSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    alerts: alertReducer,
    properties: propertiesReducer,
    showProperty: ShowPropertyReducer,
    adminPrefix: adminReducer,
    adminSuffix: suffixReducer,
    viewPrefix: viewPrefixReducer,
    parkingPrefix: parkingSlice,
    userProfile: userProfileSlice,
    UserRoles: UserRolesSlice,
    moveOutReasons:moveOutReasonSlice,
    ethnicities:EthnicitiesSlice,
    features:FeaturesSlice,
    unitStatuses:unitSlice,
    GetUnitMeasurement:GetUnitMeasurement,
    materialmeasurement:materialmeasurementSlice,
    //UserProfile:authUserProfileSlice
    // userProfile:userProfileSlice
    // assignPermissionToRoleSlice,
    // delegatePermissions,
    // getPermissionSlice,
    // tekeDelegatePermissions: tekeDelegatePermissionSlice,
  },
});
