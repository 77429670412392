import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../property/TabPanel";
import { DatePicker } from "../genericFunctions.js/DatePicker";
import { CKEditor } from "ckeditor4-react";
import { Notes } from "../property/Notes";
import FolderStructure from "../../tree/FolderStructure";
import { Interactions } from "../tenant/Interactions";
export const VendorForm = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <form
        className="w-full"
        id="add-lease-moving-form"
        // onSubmit={formSubmitHandler}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="General Information" {...a11yProps(0)} />
          <Tab label="Vendor Contacts" {...a11yProps(1)} />
          <Tab label="Notes and Files" {...a11yProps(2)} />
          <Tab label="Interactions" {...a11yProps(3)} />
          <Tab label="Quotes" {...a11yProps(4)} />
          <Tab label="Expenses" {...a11yProps(5)} />
          <Tab label="Reviews" {...a11yProps(6)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {/* <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Move-in Walkthrough:
              </label>
            </div>
          </div> */}
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Browse Local Vendors
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:w-1/3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Enable Portal:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      //   value={formData?.enable_portal}
                      //   onChange={(e) => {
                      //     const checkedValue = e.target.checked === true ? 1 : 0;
                      //     setFormData({
                      //       ...formData,
                      //       enable_portal: checkedValue,
                      //     });
                      //   }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/3">
                  <div className="md:w-3/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Send Notifications:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      //   value={formData?.send_notifications}
                      //   onChange={(e) => {
                      //     const checkedValue = e.target.checked === true ? 1 : 0;
                      //     setFormData({
                      //       ...formData,
                      //       send_notifications: checkedValue,
                      //     });
                      //   }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="md:flex md:w-1/3">
                  <div className="md:w-auto">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Vendor is also a Customer:
                    </label>
                  </div>
                  <label class="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      //   value={formData?.send_notifications}
                      //   onChange={(e) => {
                      //     const checkedValue = e.target.checked === true ? 1 : 0;
                      //     setFormData({
                      //       ...formData,
                      //       send_notifications: checkedValue,
                      //     });
                      //   }}
                      class="sr-only peer"
                    />
                    <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
              <div className="flex items-center w-full mt-3">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Account #:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Company<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Category:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center w-1/2">
                  <div className="flex">
                    <div className="w-1/2 item-center mr-3">
                      <label className="flex items-center mr-3">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          //   checked={sharingValue?.pms == 2}
                          //   value = {sharingValue?.pms}
                          //   onChange={(e)=>{
                          //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                          //   }}
                        />
                        <span className="ml-2">Commercial</span>
                      </label>
                    </div>
                    <div className="w-1/2 item-center">
                      <label className="flex items-center mr-3">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          //   checked={sharingValue?.pms == 2}
                          //   value = {sharingValue?.pms}
                          //   onChange={(e)=>{
                          //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                          //   }}
                        />
                        <span className="ml-2">Residential</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="flex items-center w-4/5">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Tags:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 1:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-address-line-1"
                    type="text"
                    placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.addresses[0]?.address_line1}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].address_line1 = e.target.value;
                    //   setCreateLog({...createLog,address_line1:1});
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Address Line 2:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="addresses-address_line2"
                    type="text"
                    placeholder="Unit #5"
                    // value={formData?.addresses[0]?.address_line2}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   setCreateLog({...createLog,address_line2:1});
                    //   addresses[0].address_line2 = e.target.value;
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Country:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-country"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={formData?.addresses[0]?.country}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].country = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,country:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      //   getStates(e.target.value);
                      // }}
                    >
                      <option value=" ">Select Country</option>
                      {/* {countries?.map((country)=>(
                              <option value={country?.id}>{country?.country}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      State:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-state"
                      required={false}
                      // value={formData?.addresses[0]?.state}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].state = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,state:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      //   getCities(e.target.value);
                      // }}
                    >
                      <option value=" ">Select State</option>
                      {/* {states?.map((state)=>(
                              <option value={state?.id}>{`${state?.state} (${state?.state_code})`}</option>
                            ))} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      City:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <select
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="addresses-city"
                      type="text"
                      placeholder="Albuquerque"
                      required={false}
                      // value={formData?.addresses[0]?.city}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,city:1})
                      //   const addresses = [...tenantAddress];
                      //   const zip = cities?.map((col) =>{
                      //     if(col?.id == e.target.value)
                      //     {
                      //       return col?.zip_code
                      //     }
                      //   })
                      //   const filteredArr = zip.filter((value) => value !== undefined);
                      //   const zipCode = filteredArr.join(', ');
                      //   addresses[0].city = e.target.value;
                      //   addresses[0].zip_code = zipCode;
                      //   setTenantAddress(addresses);
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });

                      // }}
                    >
                      <option value=" ">Select City</option>
                      {/* {cities?.map((city)=>(
                      <option value={city?.id}>{city?.city}</option>
                    ))} */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-1/4">
                    <label className="block capitalize text-gray-700 font-bold mb-2">
                      Zip:
                    </label>
                  </div>
                  <div className="md:w-2/4">
                    <input
                      className="block appearance-none w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-zip"
                      type="text"
                      placeholder="90210"
                      required={false}
                      // value={formData?.addresses[0]?.zip_code}
                      // onChange={(e) => {
                      //   const addresses = [...tenantAddress];
                      //   addresses[0].zip_code = e.target.value;
                      //   setTenantAddress(addresses);
                      //   setCreateLog({...createLog,zip_code:1})
                      //   setFormData({
                      //     ...formData,
                      //     addresses: addresses,
                      //   });
                      // }}
                    />
                  </div>
                </div>
              </div>
              {/* </>
                );
              })} */}
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[0]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[0]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[0].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[0]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    // value={formData?.numbers[0]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_1:1});
                    //   const phone = [...number];
                    //   phone[0].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[1]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[1]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[1].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[1]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2-type`}
                    // value={formData?.numbers[1]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_2:1});
                    //   const phone = [...number];
                    //   phone[1].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[2]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[2]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={formatPhoneNumber(number?.[2]?.phone_number,ownerSettings?.phone_number_format)}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = removeNonNumeric(e.target.value);
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3-type`}
                    // value={formData?.numbers[2]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_3:1});
                    //   const phone = [...number];
                    //   phone[2].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3 mb-6">
                <div className="flex md:items-center w-1/2 mr-2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`email`}
                      type="email"
                      placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    />
                  </div>
                </div>
                <div className="flex md:items-center w-1/2">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Website:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`website`}
                      type="url"
                      placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3 mb-6">
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Licensed</span>
                </label>
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Bonded</span>
                </label>
                <label className="flex items-center mr-3">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    //   checked={sharingValue?.pms == 2}
                    //   value = {sharingValue?.pms}
                    //   onChange={(e)=>{
                    //     setSharingValue({...sharingValue,pms : e.target.checked ? 2 : 0});
                    //   }}
                  />
                  <span className="ml-2">Insured</span>
                </label>
              </div>
              <div className="md:flex md:items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Certifications:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="certification"
                    type="text"
                    // placeholder="6284 Locust Road"
                    required={false}
                    // value={formData?.addresses[0]?.address_line1}
                    // onChange={(e) => {
                    //   const addresses = [...tenantAddress];
                    //   addresses[0].address_line1 = e.target.value;
                    //   setCreateLog({...createLog,address_line1:1});
                    //   setTenantAddress(addresses);
                    //   setFormData({ ...formData, addresses: addresses });
                    // }}
                  />
                </div>
              </div>
              <div className="md:items-center w-full mt-4">
                <div className="flex">
                  {/* <img
                className="logo w-12 mr-5"
                src="/assets/svgs/lease-walkthrough-start.svg"
                alt=""
              /> */}
                  <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold italic mb-2">
                    Social Media:
                  </label>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      LinkedIn:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Facebook:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Twitter:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Instagram:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      WhatsApp:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      YouTube:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Reddit:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Skype:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Snap Chat:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Discord:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Etsy:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Pinterest:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5 flex items-center">
                    <img
                      src="/assets/svgs/system-picture.svg"
                      alt="Picture"
                      className="w-1/3"
                    />
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Logo:
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Logo
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    About Your Company:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/vendor-information.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Vendor Contacts:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="flex justify-end w-full mt-4">
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Load Contact From Vendor
                </button>
              </div>
              <div className="w-auto mt-2 ">
                <button
                  type="button"
                  className="btn btn3"
                  // hidden={hiddenData}
                  // onClick={() => {
                  //   setShowForm(true);
                  // }}
                >
                  Add Contact
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      First Name<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Middle Name:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Last Name<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex md:items-center w-4/5 ">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Title:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`title`}
                      type="text"
                      //   placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 1:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[0]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[0]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[0].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-l`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[0]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[0].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-1-type`}
                    // value={formData?.numbers[0]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_1:1});
                    //   const phone = [...number];
                    //   phone[0].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 2:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[1]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[1]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[1].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    value={formatPhoneNumber(number?.[1]?.phone_number,ownerSettings?.phone_number_format)}
                    onChange={(e) => {
                      const phone = [...number];
                      setCreateLog({...createLog,phone_number_1:1});
                      phone[1].phone_number = removeNonNumeric(e.target.value);
                      setNumber(phone);
                      setFormData({ ...formData, numbers: phone });
                    }}
                    maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-2-type`}
                    // value={formData?.numbers[1]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_2:1});
                    //   const phone = [...number];
                    //   phone[1].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Number 3:
                  </label>
                </div>
                <div className="md:w-3/5">
                  {/* {formData?.numbers[2]?.number_type == 8 ? */}
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={number?.[2]?.phone_number}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  {/* :
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3`}
                    type="tel"
                    placeholder="(999) 999-9999"
                    // value={formatPhoneNumber(number?.[2]?.phone_number,ownerSettings?.phone_number_format)}
                    // onChange={(e) => {
                    //   const phone = [...number];
                    //   setCreateLog({...createLog,phone_number_1:1});
                    //   phone[2].phone_number = removeNonNumeric(e.target.value);
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                    // maxLength={ownerSettings?.phone_number_format?.length}
                  />
                  } */}
                </div>
                <div className="md:w-1/5 pl-3">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`tenant-num-3-type`}
                    // value={formData?.numbers[2]?.number_type}
                    // onChange={(e) => {
                    //   setCreateLog({...createLog,number_type_3:1});
                    //   const phone = [...number];
                    //   phone[2].number_type = e.target.value;
                    //   setNumber(phone);
                    //   setFormData({ ...formData, numbers: phone });
                    // }}
                  >
                    <option value=" ">Select Type</option>
                    {/* {numberType?.map((row)=>(
                          <option value={row?.id}>{row?.type}</option>
                        ))} */}
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center mt-3">
                <div className="flex md:items-center w-full ">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Email:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`email`}
                      type="email"
                      placeholder="john.doe@email.com"
                      // value={formData?.email}
                      // onChange={(e) => {
                      //   setCreateLog({...createLog,email:1});
                      //   setFormData({ ...formData, email: e.target.value });
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex flex-column md:items-center w-full md:w-6/12 mb-6">
                  {/* <div className="md:w-2/5 flex items-center">
                    <img
                      src="/assets/svgs/system-picture.svg"
                      alt="Picture"
                      className="w-1/3"
                    />
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Logo:
                    </label>
                  </div> */}
                  <div className="md:w-auto">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Pictures
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex md:items-center w-full md:w-6/12 mb-6">
                  <div className="md:w-2/5 flex items-center">
                    <img
                      src="/assets/svgs/vendor-business-card.svg"
                      alt="Picture"
                      className="w-1/3"
                    />
                  </div>
                  <div className="md:w-auto">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Business Card
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Contact Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/system-notes-and-files.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Notes and Files:
              </label>
            </div>
          </div>
          <Notes />
          <FolderStructure />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Interactions />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/vendor-quote.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Quotes:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Add Quote
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Quote Date:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Quote Number:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/3 mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Status:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <select
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    >
                      <option value="">Select</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-4/5 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Quote Title<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Description:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Amount:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Quote Expiry:
                    </label>
                  </div>
                  <div className="md:w-3/5">
                    <DatePicker
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="J Height"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-4">
                <div className="md:items-center w-1/3 mt-4">
                  <div className="flex items-center">
                    <img
                      className="logo w-12 mr-5"
                      src="/assets/img/properties/system-files.png"
                      alt=""
                    />
                    <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                      Files:
                    </label>
                  </div>
                </div>
                <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                  <div className="flex md:items-center md:justify-start md:w-2/3">
                    <div className="md:w-1/2">
                      <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                          Upload files
                        </span>
                        <input
                          id="property-public-files"
                          type="file"
                          // accept={fileTypes}
                          multiple={true}
                          hidden={true}
                          // onChange={(e) => {
                          //   let files = e.target.files;
                          //   let newImages = [];
                          //   let maxOrderNumber = 0;

                          //   // Find the highest order number in the current images
                          //   propertyPublicDocumentsWithUrl?.forEach((imageObject) => {
                          //     if (imageObject.order_number > maxOrderNumber) {
                          //       maxOrderNumber = imageObject.order_number;
                          //     }
                          //   });

                          //   // Calculate the total number of images (existing images + new images)
                          //   const totalImages = propertyPublicDocumentsWithUrl.length + files.length;

                          //   // Loop through each file and convert to base64
                          //   files.forEach((file, i) => {
                          //     const reader = new FileReader();
                          //     reader.addEventListener("load", async () => {
                          //       const fileBase64 = reader.result;
                          //       try {
                          //         const res = await ApiRequest.post(fileApi, {
                          //           file: fileBase64,
                          //         });

                          //         // Add the new image to the array with a unique order number
                          //         newImages.push({
                          //           file: res?.data?.data?.file,
                          //           url:fileBase64
                          //         });

                          //         // If all new images have been processed, update the state
                          //         if (newImages.length === files.length) {
                          //           let fileLists = [...propertyPublicDocumentsWithUrl];
                          //           fileLists = [...fileLists, ...newImages];
                          //           setPropertyPublicDocumentsWithUrl(fileLists);
                          //           let propertyCopy = [...propertyPublicDocumentsWithUrl];
                          //           propertyCopy.files = fileLists.map(
                          //             (picObj) => ({
                          //               file: picObj.file,
                          //               // order_number: picObj.order_number,
                          //             })
                          //           );
                          //           setFormData({ ...formData, property_files: propertyCopy?.files });
                          //         }
                          //       } catch (err) {
                          //         // console.log(err);
                          //       }
                          //     });
                          //     reader.readAsDataURL(file);
                          //   });
                          // }}
                        />
                      </label>
                    </div>
                    {/* <div className="md:w-1/2">
                  <label className="block appearance-none px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer w-full">
                    <span className="text-gray-600">
                      {(() => {
                        const filesCount =
                          propertyPublicDocumentsWithUrl?.length || 0;
                        return filesCount > 1
                          ? filesCount + " Documents"
                          : filesCount == 1
                          ? filesCount + " Document"
                          : null;
                      }).call()}
                    </span>
                  </label>
                </div> */}
                  </div>
                </div>
              </div>

              {/* Gallery  */}

              {/* <div >
            {propertyPublicDocumentsWithUrl.length ? (
              <div>
                <section className="overflow-hidden w-full text-gray-700 mt-4">
                  <div className="container w-full px-3 m-auto">
                    <div
                      id="property-tenant-documents-block"
                      className="flex flex-wrap justify-center w-full -m-1 md:-m-2"
                    >
                      {propertyPublicDocumentsWithUrl.map((fileObject, index) =>
                        fileComponent({
                          file: fileObject?.file,
                          key: index,
                          inputFieldId: "property-public-files",
                          trashAble: true,
                          onTrashClick: () => {
                            const fileLists = [
                              ...formData?.property_files,
                            ];
                            fileLists.splice(index, 1);
                            formData.property_files = fileLists;
                            setPropertyPublicDocumentsWithUrl(fileLists);
                          },
                        })
                      )}
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
          </div> */}
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Notes:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/finance-expense-vendor.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Expenses:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Add Expense
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <div className="md:items-center w-full mt-4">
            <div className="flex">
              <img
                className="logo w-12 mr-5"
                src="/assets/svgs/reviews.svg"
                alt=""
              />
              <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
                Reviews:
              </label>
            </div>
          </div>
          <div className="md:flex flex-column md:items-center w-[90%] m-auto">
            <div className="w-full">
              <label className="block capitalize tracking-wide text-gray-700 mt-4 italic font-bold">
                Write a Review:
              </label>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-1/2 mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold">
                      Rating<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-3/5 flex  items-center">
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="flex items-center w-full mt-3 mr-1">
                  <div className="md:w-1/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Review Title<span title="Field is Required">*</span>:
                    </label>
                  </div>
                  <div className="md:w-4/5">
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id={`employment-company-name`}
                      type="text"
                      placeholder="Title"
                      // value={tenantEmployment?.company_name}
                      // onChange={(e) => {
                      //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="md:flex mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Review:
                  </label>
                </div>
                <div className="md:w-4/5">
                  <CKEditor
                  // initData={editorData?.property_tenant_notes}
                  // onChange={(e) => {
                  //   const cleanText = new DOMParser().parseFromString(e.editor.getData(), 'text/html').body.textContent;
                  //   setEditorData({
                  //     ...editorData,
                  //     property_tenant_notes: cleanText,
                  //   });
                  // }}
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mt-3">
                <div className="md:flex flex-column md:items-center w-full md:w-6/12 mb-6">
                  {/* <div className="md:w-2/5 flex items-center">
                    <img
                      src="/assets/svgs/system-picture.svg"
                      alt="Picture"
                      className="w-1/3"
                    />
                    <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                      Logo:
                    </label>
                  </div> */}
                  <div className="md:w-auto">
                    <label className="block appearance-none text-center w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer">
                      <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-700 from-sky-400 hover:bg-sky-500">
                        Upload Images
                      </span>
                      <input
                        // accept={picTypes}
                        id={`dl/id-pic`}
                        type="file"
                        hidden={true}
                        // onChange={async (e) => {
                        //   let file = e.target.files[0];
                        //   const reader = new FileReader();
                        //   reader.addEventListener("load", async () => {
                        //     const imgBase64 = reader.result;
                        //     try {
                        //       console.log(imgBase64);
                        //       const res = await ApiRequest.post(
                        //         "/public/api/auth/upload-image",
                        //         { image: imgBase64 }
                        //       );
                        //       // console.log("res", res?.data?.data?.image);
                        //       // formData?.dl_id_picture = res?.data?.data?.image
                        //       setCreateLog({...createLog,dl_id_picture:1});
                        //       setFormData({
                        //         ...formData,
                        //         dl_id_picture: res?.data?.data?.image,
                        //       });
                        //       // setDlidPicture(res?.data?.data?.image)
                        //       // setFeatureIcon(res?.data?.data?.image);
                        //     } catch (err) {
                        //       console.log(err);
                        //     }
                        //   });
                        //   reader.readAsDataURL(file);
                        //   const pictureWithURL = getImagesAndURLs(e)[0];
                        //   setDlidPicture(pictureWithURL);

                        //   // console.log("here 142");
                        //   // const pictures = [...propertyAssistantPictures];
                        //   // pictures[index] = getImagesAndURLs(e)[0];
                        //   // setPropertyAssistantPictures(pictures);
                        //   // setPropertyContacts(contacts);
                        //   // setFormData({...formData, contacts : contacts})
                        // }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex items-center justify-content-center w-full mt-3">
                  <div className="md:w-2/5">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold">
                      Reviews for Vendor:
                    </label>
                  </div>
                </div>
              <div className="md:flex flex-column md:items-center w-[90%] m-auto">
                <div className="flex justify-end w-full mt-4">
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Sort
                    </button>
                  </div>
                  <div className="w-auto mt-2 ">
                    <button
                      type="button"
                      className="btn btn3"
                      // hidden={hiddenData}
                      // onClick={() => {
                      //   setShowForm(true);
                      // }}
                    >
                      Show
                    </button>
                  </div>
                </div>
              </div>
              <div className="md:flex flex-column px-4 mb-3 bg-gray-200 rounded border mt-3">
                <div className="flex mt-3">
                  <div className="md:w-1/6">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold">
                      Rating:
                    </label>
                  </div>
                  <div className="md:w-3/5 flex">
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                  </div>
                </div>
                <div className="flex mt-3">
                  <span>Review_title</span>
                </div>
                <div className="flex mt-3">
                  <span>Review</span>
                </div>
              </div>
              <div className="md:flex flex-column px-4 mb-3 bg-gray-200 rounded border mt-3">
                <div className="flex mt-3">
                  <div className="md:w-1/6">
                    <label className="block capitalize tracking-wide text-gray-700 font-bold">
                      Rating:
                    </label>
                  </div>
                  <div className="md:w-3/5 flex">
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                    <img
                      src="/assets/svgs/reviews-flame-l.svg"
                      alt=""
                      className="w-4"
                    />
                  </div>
                </div>
                <div className="flex mt-3">
                  <span>Review_title</span>
                </div>
                <div className="flex mt-3">
                  <span>Review</span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
    </>
  );
};
