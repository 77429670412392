import { useEffect, useState } from "react";
import useAlerts from "../../utility/alerts";
import { useNavigate } from "react-router-dom";
import ApiRequest from "../../utility/http";
import { useDispatch } from "react-redux";
import { userProfile } from "../../store/user/thunk";
export const ProfileSelect = () => {
  const optionsDropdown = [
    { name: "My Profile", img: "/assets/svgs/users-my-account.svg" },
    { name: "My Plan", img: "/assets/svgs/plans.svg" },
    { name: "Billing", img: "/assets/svgs/finance-billing.svg" },
    { name: "Help & Support", img: "/assets/svgs/support-issues.svg" },
    { name: "System Settings", img: "/assets/svgs/system-settings.svg" },
  ];
  const Alert = useAlerts();
  const navigate = useNavigate();

  let [showDropdown, setShowDropdown] = useState(false);

  

  const handleDropdownValue = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogoutClick = () => {
    // e.preventDefault();
    // e.stopPropagation();

    Alert.addSuccess("Logged out successfully!");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("Role");
    localStorage.removeItem("Role Name");
    localStorage.removeItem("Name");
    localStorage.removeItem("MultiRole");
    window.location.href = '/login';
  };
  const dispatch = useDispatch();
  const [role,setRole] =useState();
  const [allRole,setAllRole] =useState();
  const [selectedRole,setSelectedRole] =useState();
  // console.error('@role',role);
  // console.error('@AllRole',allRole);
  // console.error('@selectedRole',selectedRole);
  const getRole = async()=>{
    const response = await dispatch(userProfile());
    // console.error('@response',response);
    if(response.meta.requestStatus == "fulfilled")
    {
      setRole(response?.payload?.assigned_roles)
    }
  }
  const getAllRole = async()=>{
    const response = await ApiRequest.get('/public/api/auth/get-all-roles');
    if(response.status == 200)
    {
      setAllRole(response?.data?.data?.roles);
    }
  }
  const getSelectedRole = (id)=>{
    allRole?.map((row)=>{
       if(row?.id == id)
      {
        // console.error("@selectRoles",row?.role);
        localStorage.setItem("Role", id);
        if(id == 1){
          navigate('/admin');
        }
        if(id == 2)
        {
          navigate('/')
        }
      }
    })
  }
  useEffect(() => {
    getRole();
    getAllRole();
    // getSelectedRole();
  }, [])
  
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "/admin/js/jquery-3.6.0-min.js";
    jquery.async = true;
    document.body.appendChild(jquery);
    const bootstrap = document.createElement("script");
    bootstrap.src = "/admin/js/bootstrap.min.js";
    bootstrap.async = true;
    document.body.appendChild(bootstrap);
    const main = document.createElement("script");
    main.src = "/assets/js/main.js";
    main.async = true;
    document.body.appendChild(main);
    return () => {
      document.body.removeChild(jquery);
      document.body.removeChild(bootstrap);
      document.body.removeChild(main);
    };
  }, []);
  return (
    
    <div className="account-details">
    {localStorage.getItem('MultiRole') == 'Yes' ? <div>
    {role?.length > 1 ? (
    <select name="" id=""
    onChange={(e)=>{
      // console.error("@Switch Role",e.target.value);
      setSelectedRole(parseInt(e.target.value));
      getSelectedRole(parseInt(e.target.value))
    }}
    >
      <option value="">
        Switch Role
      </option>
    {
        (() => {
          const listItems = [];
          for (let i = 0; i < allRole?.length; i++) {
            for(let j = 0; j < role?.length; j++)
            {
              if(allRole[i]?.id == role[j]?.role_id)
            {
              listItems.push(
                <option value={role[j]?.role_id}>{allRole[i]?.role}</option>
              );
            }
          }
          }
          return listItems;
        })()
      }
    </select>):null}
    </div>:null}
      <div className="account-author">
        <img src="/assets/img/author.png" alt="" />
      </div>
      <div
        className="dropdown inline-block relative my-account-dropdown w-55"
        onMouseEnter={handleDropdownValue}
        onMouseLeave={handleDropdownValue}
      >
        <button className="bg-white text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
          <span className="mr-1">{localStorage.getItem("Name")}</span>
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
          </svg>
        </button>
        <div
          className={`my-account  ${
            showDropdown ? "" : "hidden"
          }`}
          
        >
          <span className="triCone" />
          {optionsDropdown.map((v, i) => (
           
              <div
              key={i}
              className="rounded-t bg-gray-200 hover:bg-gray-400 border-orange-600 py-2 whitespace-no-wrap row items-center "
            >
              <img
                src={v.img}
                alt={v.name}
                className={`col-4 ${v.img === "" ? "d-none" : "w-14"}`}
              />
              <a className="text-decoration-none col-8" href="#">
                <span className="my-account-text">{v.name}</span>
              </a>
            </div>
          ))}
          <div
              className="rounded-t bg-gray-200 hover:bg-gray-400 row items-center "
            >

          <button
              className="btn btn-primary" 
              onClick={handleLogoutClick}
            >
              Sign Out
            </button>
            </div>
      </div> 
   </div>
         
    </div>
  );
};
