import React from "react";

export const SystemBackupForm = () => {
  return (
    <>
      <form
        className="w-full"
        id="add-finance-account-form"
        // onSubmit={formSubmitHandler}
      >
        <div className="md:flex flex-column md:items-center w-[90%] m-auto">
          <div className="w-full">
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Download:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                    <option value="Database">Database</option>
                    <option value="Site">Site</option>
                    <option value="Both">Both</option>
                  </select>
                </div>
                <div className="w-1/5">
                  <button type="button" class="btn btn3">
                    Download
                  </button>
                </div>
              </div>
            </div>
            <label className="block capitalize tracking-wide text-gray-700 text-xl font-bold mb-2">
              System Restore:
            </label>
            <div className="md:flex md:items-center mt-3">
              <div className="flex items-center w-full mt-3">
                <div className="md:w-1/5">
                  <label className="block capitalize tracking-wide text-gray-700 font-bold mb-2">
                    Upload:
                  </label>
                </div>
                <div className="md:w-3/5">
                  <select
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id={`employment-company-name`}
                    type="text"
                    placeholder="J Height"
                    // value={tenantEmployment?.company_name}
                    // onChange={(e) => {
                    //   setTenantEmployment({...tenantEmployment,company_name:e.target.value});
                    // }}
                  >
                    <option value="">Select</option>
                    <option value="Database">Database</option>
                    <option value="Site">Site</option>
                    <option value="Both">Both</option>
                  </select>
                </div>
                <div className="w-1/5">
                  <button type="button" class="btn btn3">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
